import { react as autoBind } from "auto-bind";
import { PureComponent } from "react";

export default class RootFormElement extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  hasWarnings() {
    const {
      meta: { touched, warning },
    } = this.props;
    return touched && warning ? "has-warning" : "";
  }

  hasErrors() {
    const {
      meta: { touched, error },
    } = this.props;
    return touched && error ? "has-error" : "";
  }
}
