import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactDatePicker from "react-datepicker";

/**
 * see https://www.npmjs.com/package/react-datepicker
 * ...You will also need to require the CSS file from this package (or provide your own).
 */
import "react-datepicker/dist/react-datepicker.css";

export default class DatePicker extends PureComponent {
  static propTypes = {
    value: PropTypes.object,
  };

  render() {
    const { value, ...restProps } = this.props;
    return (
      <ReactDatePicker
        className="form-control"
        {...restProps}
        dateFormat="P"
        locale={navigator.userLanguage || navigator.language}
        selected={value}
      />
    );
  }
}
