import { isEmpty } from "ramda";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { error } from "modules/messages/actions";

import { getDataVersions, getSoftwareVersions } from "../api/versions";

import * as actions from "./actions";
import { fetchVersions } from "./actions";
import { getVersionsSummary } from "./selectors";

export function* init(): Generator<any, void, any> {
  yield takeLatest(
    actions.actionType.GET_OR_RELOAD_VERSIONS,
    getOrReloadVersions
  );
}

export function* getOrReloadVersions(): Generator<any, void, any> {
  try {
    const loadedVersions = yield select(getVersionsSummary);
    if (loadedVersions && !isEmpty(loadedVersions)) {
      return;
    }
    yield put(fetchVersions.start());

    const [dataVersions, softwareVersions] = yield all([
      call(getDataVersions),
      call(getSoftwareVersions),
    ]);

    const versions: Array<Version> = dataVersions.concat(softwareVersions);

    yield put(fetchVersions.success(versions));
  } catch ({ message }) {
    yield put(fetchVersions.failure());
    yield put(error(message));
  }
}
