import PropTypes from "prop-types";
import { identity, memoizeWith } from "ramda";
import React, { memo } from "react";

import { getAccessorFn } from "./getAccessorFn";

const defaultFormat = memoizeWith(identity, item => item);

const VariantColumn = ({
  parse = false,
  data,
  colKey,
  accessor,
  format = defaultFormat,
}) => {
  // We assume we are just passed a string key
  // but we check if it's an array and let that work
  const accessorFn = getAccessorFn(accessor, parse);

  return (
    <div className={`variant-column variant-column--${colKey}`}>
      {format(accessorFn(data)) || ""}
    </div>
  );
};

VariantColumn.propTypes = {
  accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  colKey: PropTypes.string.isRequired,
  data: PropTypes.any,
  parse: PropTypes.bool,
  format: PropTypes.func,
};

export default memo(VariantColumn);
