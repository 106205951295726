import Genoverse from "genoverse";

import { Colors, createMenu } from "../utils";

export const Controller = {
  populateMenu(feature) {
    const {
      browser: { chr },
      track: {
        featureMenu: { decipherLinks, actions },
      },
    } = this;

    const {
      start,
      end,
      overlap_length,
      reciprocal_overlap_length,
      curated_data,
    } = feature;

    const {
      VARIANT_CLASS: variantClass,
      INSERTION_SIZE: insertionSize,
      TRANSLOCATION_MATE_BREAKPOINTS: translocationMateBreakpoints,
    } = curated_data;

    const notAvailable = "N/A";
    const none = "-";

    const hasAdditionalValues = ![
      "Insertion",
      "Translocation",
      "Inversion",
    ].includes(variantClass);

    const location = `chr${chr}:${start}-${end}`;
    const size = hasAdditionalValues ? end - start : notAvailable;

    const translocation =
      variantClass === "Translocation"
        ? translocationMateBreakpoints
        : notAvailable;

    const overlapPercentage = value =>
      hasAdditionalValues
        ? value
          ? (parseFloat(value) * 100).toFixed(2)
          : none
        : notAvailable;

    return createMenu(
      [
        {
          label: "title",
          value: `(${location})${variantClass || "Unknown"}`,
        },
        {
          label: "Location",
          value: location,
        },
        {
          label: "Size",
          value: size,
        },
        {
          label: "Variant class",
          value: variantClass,
        },
        {
          label: "% overlap",
          value: overlapPercentage(overlap_length),
        },
        {
          label: "% reciprocal overlap",
          value: overlapPercentage(reciprocal_overlap_length),
        },
        {
          label: "Insertion size",
          value: insertionSize || none,
        },
        {
          label: "Translocation mate breakpoints",
          value: translocation,
        },
        {
          label: "Decipher Links",
          value: decipherLinks && decipherLinks(feature),
        },
        {
          label: "",
          value: actions && actions(this.prop("urlParams"), feature),
        },
      ],
      { divider: "<br />" }
    );
  },
};

export const Model = {
  insertFeature(feature) {
    const {
      curated_data: { VARIANT_CLASS: variantClass },
    } = feature;

    const featureParams = this.track.featureParams.find(param =>
      param.predicate(feature)
    );

    this.base({
      ...feature,
      ...featureParams,
      label: variantClass || "Unknown",
      labelColor: Colors.WHITE,
    });
  },
};

export const Config = {
  id: "Congenica.StructuralVariantsCuratedListSV",
  namespace: "Congenica.StructuralVariantsCuratedListSV",
  height: 55,
  featureHeight: 10,
  labels: "overlay",
  repeatLabels: false,
  legend: true,
  bump: true,
  controller: Controller,
  model: Model,
};

export const Track = Genoverse.Track.extend({
  ...Config,
  model: Genoverse.Track.Model.extend(Config.model),
  controller: Genoverse.Track.Controller.extend(Config.controller),
});
