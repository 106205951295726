import PropTypes from "prop-types";
import React, { memo } from "react";

import { Heading, LabelField } from "pattern-library";

import VariantColumn from "modules/variants/components/columns/VariantColumn";

import FailedFilters from "./noScores/FailedFilters";
import FrequencyDetails from "./noScores/FrequencyDetails";

const NoScoresData = ({
  variant,
  knownFrequencies,
  failedFilterTypes,
  ...rest
}) => (
  <div {...rest} className="exomiser-no-scores">
    <Heading type="h4">No scores are provided by Exomiser:</Heading>
    <LabelField
      label="Variant:"
      value={<VariantColumn variant={{ snv: variant }} />}
      isFormField
      className="exomiser-variant exomiser-gap"
    />
    <LabelField
      label="Exomiser failed filters:"
      value={<FailedFilters failedFilterTypes={failedFilterTypes} />}
      isFormField
    />
    {knownFrequencies && (
      <LabelField
        label="Frequency details:"
        value={<FrequencyDetails knownFrequencies={knownFrequencies} />}
        isFormField
      />
    )}
  </div>
);

NoScoresData.propTypes = {
  /**
   * Variant data
   */
  variant: PropTypes.shape({
    chr: PropTypes.string.isRequired,
    start: PropTypes.number.isRequired,
    refAllele: PropTypes.string.isRequired,
    altAllele: PropTypes.string.isRequired,
  }),
  /**
   * Array containing constants for failed filters
   */
  failedFilterTypes: PropTypes.arrayOf(PropTypes.string),
  /**
   * Array containing known frequencies data
   */
  knownFrequencies: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      frequency: PropTypes.number,
    })
  ),
};

export default memo(NoScoresData);
