import { createAction } from "../utils";

import * as constants from "./constants";

export type ReadProjectAction = FSA<
  typeof constants.READ_PROJECT,
  { projectId: number | string; rewriteExisting: boolean }
>;

export const readProject = (
  projectId: number | string,
  rewriteExisting = false
): ReadProjectAction =>
  createAction(constants.READ_PROJECT, {
    projectId,
    rewriteExisting,
  });

export type ReadProjectSuccessAction = FSA<
  typeof constants.READ_PROJECT_SUCCESS,
  Project
>;

export const readProjectSuccess = (
  project: Project
): ReadProjectSuccessAction =>
  createAction(constants.READ_PROJECT_SUCCESS, project);

export type ReadProjectFailureAction = FSA<
  typeof constants.READ_PROJECT_FAILURE,
  Index
>;

export const readProjectFailure = (): ReadProjectFailureAction =>
  createAction(constants.READ_PROJECT_FAILURE);

export type ReadProjectCountSuccessAction = FSA<
  typeof constants.READ_PROJECT_TAB_COUNTS_SUCCESS,
  ProjectCounts
>;

export type ReadProjectCountAction = FSA<
  typeof constants.READ_PROJECT_TAB_COUNTS,
  { projectId: number | string }
>;

export const readProjectCount = (
  projectId: number | string
): ReadProjectCountAction =>
  createAction(constants.READ_PROJECT_TAB_COUNTS, {
    projectId,
  });

export const readProjectCountSuccess = (
  count: ProjectCounts
): ReadProjectCountSuccessAction =>
  createAction(constants.READ_PROJECT_TAB_COUNTS_SUCCESS, count);

export type ReadProjectCountFailureAction = FSA<
  typeof constants.READ_PROJECT_TAB_COUNTS_FAILURE,
  Index
>;

export const readProjectCountFailure = (): ReadProjectCountFailureAction =>
  createAction(constants.READ_PROJECT_TAB_COUNTS_FAILURE);

export type ReadProjectCurrentUserAction = FSA<
  typeof constants.READ_PROJECT_CURRENT_USER,
  { projectId: number | string }
>;

export const readProjectCurrentUser = (
  projectId: number | string
): ReadProjectCurrentUserAction =>
  createAction(constants.READ_PROJECT_CURRENT_USER, { projectId });

export type ReadProjectCurrentUserSuccessAction = FSA<
  typeof constants.READ_PROJECT_CURRENT_USER_SUCCESS,
  ProjectCurrentUser
>;

export const readProjectCurrentUserSuccess = (
  projectRoles: ProjectCurrentUser
): ReadProjectCurrentUserSuccessAction =>
  createAction(constants.READ_PROJECT_CURRENT_USER_SUCCESS, projectRoles);

export type ReadProjectCurrentUserFailureAction = FSA<
  typeof constants.READ_PROJECT_CURRENT_USER_FAILURE,
  Index
>;

export const readProjectCurrentUserFailure =
  (): ReadProjectCurrentUserFailureAction =>
    createAction(constants.READ_PROJECT_CURRENT_USER_FAILURE);

export const reloadCurrentProjectCurrentUser =
  (): ReloadCurrentProjectCurrentUserAction =>
    createAction(constants.RELOAD_CURRENT_PROJECT_CURRENT_USER);

export type ReloadCurrentProjectCurrentUserAction = FSA<
  typeof constants.RELOAD_CURRENT_PROJECT_CURRENT_USER,
  ProjectCurrentUser
>;

export type Action =
  | ReadProjectAction
  | ReadProjectSuccessAction
  | ReadProjectFailureAction
  | ReadProjectCountAction
  | ReadProjectCountSuccessAction
  | ReadProjectCountFailureAction
  | ReadProjectCurrentUserAction
  | ReadProjectCurrentUserSuccessAction
  | ReadProjectCurrentUserFailureAction
  | ReloadCurrentProjectCurrentUserAction;
