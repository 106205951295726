import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const ListItemText = ({ text, highlighted = false, className }) => {
  if (!text) {
    return null;
  }
  return (
    <span
      className={classNames("list-item-text", className, {
        highlighted,
      })}
    >
      {text}
    </span>
  );
};

ListItemText.propTypes = {
  text: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  className: PropTypes.string,
};

export default ListItemText;
