import React, { useEffect, memo } from "react";
import { connect } from "react-redux";

import { Loading } from "pattern-library";

import * as actions from "modules/hpoTerms/actions";
import HPOTermList from "modules/hpoTerms/components/HPOTermList";
import * as selectors from "modules/hpoTerms/selectors";

import HPOTermSearchBar from "./HPOTermSearchBar";
import SelectedHPOTerms from "./SelectedHPOTerms";

export const HPOTermsForm = memo(
  ({
    loadingInProgress,
    loadingSuccessful,
    loadingFailed,
    selectedRowNumber,
    resetHPOTermsTree,
    disabled = false,
  }) => {
    useEffect(() => {
      if (loadingSuccessful) {
        resetHPOTermsTree();
      }
    }, [loadingSuccessful, selectedRowNumber, resetHPOTermsTree]);

    if (loadingInProgress) {
      return <Loading />;
    }

    if (loadingFailed) {
      return (
        <div className="text-danger">
          <span>HPO Terms loading failed.</span>
        </div>
      );
    }

    if (loadingSuccessful) {
      return (
        <div className="row">
          <div className="col-md-6">
            <HPOTermSearchBar />
            <HPOTermList />
          </div>
          <div className="col-md-6 selected-hpo-terms">
            <SelectedHPOTerms
              selectedRowNumber={selectedRowNumber}
              disabled={disabled}
            />
          </div>
        </div>
      );
    }

    return null;
  }
);

const mapStateToProps = state => ({
  loadingInProgress: selectors.hpoTermsLoadingInProgress(state),
  loadingSuccessful: selectors.hpoTermsLoadingSuccessful(state),
  loadingFailed: selectors.hpoTermsLoadingFailed(state),
});

const mapDispatchToProps = {
  resetHPOTermsTree: actions.resetHPOTermsTree,
};

export default connect(mapStateToProps, mapDispatchToProps)(HPOTermsForm);
