import { isEmpty, isNil } from "ramda";
import React, { memo } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { getGeneOptions } from "modules/api/genes";
import ReduxFormField from "modules/forms/components/ReduxFormField";
import SelectReduxField from "modules/forms/components/SelectReduxField";
import { minAndMaxNumber } from "modules/forms/rules";
import { getFormValues } from "modules/forms/selectors";
import { GNOMAD_SV_FEATURE } from "modules/project/constants";
import { isProjectFeatureActive } from "modules/project/selectors";

import { LOCATION_TYPES } from "../constants";
import * as selectors from "../selectors";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

const prepareFieldName = name => `config.filters.${name}`;
type FilterPresetSelectProps = {
  name: string;
  label?: string;
  options: PresetOptions;
  isMulti?: boolean;
  hasGroups?: boolean;
  isDisabled?: boolean;
};

const FilterPresetSelect = ({
  name,
  label,
  options,
  isMulti,
  hasGroups,
  isDisabled,
  ...rest
}: FilterPresetSelectProps) => (
  <SelectReduxField
    name={prepareFieldName(name)}
    narrow
    isMulti={isMulti}
    hasGroups={hasGroups}
    options={options}
    label={label}
    isDisabled={isDisabled || isNil(options) || isEmpty(options)}
    {...rest}
  />
);

type Props = {
  form: string;
  projectId: string;
  location: FilterPresetLocation;
  gnomadEnabled: boolean;
  isDisabled: boolean;
  genotypeOptions: PresetOptions;
  sizeOptions: PresetOptions;
  inheritanceOptions: PresetOptions;
  qualityOptions: PresetOptions;
  subtypeOptions: PresetOptions;
  genePanelOptions: PresetOptions;
  tierOptions: PresetOptions;
  cvlOptions: PresetOptions;
};

export const SVConfigEditor = memo((props: Props) => {
  const {
    genotypeOptions,
    sizeOptions,
    inheritanceOptions,
    qualityOptions,
    subtypeOptions,
    genePanelOptions,
    tierOptions,
    cvlOptions,
    isDisabled,
    location,
    gnomadEnabled,
  } = props;

  const { ensemblVersion } = useEnsemblVersion();

  const specificGenesLabel = (
    <>
      {LOCATION_TYPES.genes.label}
      {ensemblVersion && <small> (Ensembl release {ensemblVersion})</small>}
    </>
  );

  return (
    <div className="form-horizontal">
      <div className="row">
        <div className="col-sm-6">
          <FilterPresetSelect
            name="genotype"
            isMulti
            options={genotypeOptions}
            label="Genotype"
            isDisabled={isDisabled}
          />
          <FilterPresetSelect
            name="size"
            options={sizeOptions}
            label="Size"
            isDisabled={isDisabled}
          />
          <FilterPresetSelect
            name="tier"
            options={tierOptions}
            label="Tier"
            isMulti
            isDisabled={isDisabled}
          />
          <label>Only show variants in:</label>
          <Field
            component={ReduxFormField}
            type="radio"
            name={prepareFieldName("location")}
            narrow
            disabled={isDisabled}
            {...{
              boxLabel: specificGenesLabel,
              value: LOCATION_TYPES.genes.value,
            }}
          />
          {location === LOCATION_TYPES.genes.value && (
            <Field
              component={ReduxFormField}
              type="select"
              async
              noResultsText="No genes found"
              key="no-genes-found-field"
              name={prepareFieldName("genes")}
              isDisabled={isDisabled}
              placeholder="Type to search genes..."
              loadOptions={getGeneOptions(ensemblVersion)}
            />
          )}

          <Field
            component={ReduxFormField}
            type="radio"
            name={prepareFieldName("location")}
            narrow
            disabled={isDisabled}
            {...{
              boxLabel: LOCATION_TYPES.panels.label,
              value: LOCATION_TYPES.panels.value,
            }}
          />
          {location === LOCATION_TYPES.panels.value && (
            <FilterPresetSelect
              name="genePanel"
              options={genePanelOptions}
              isMulti
              hasGroups
              isDisabled={isDisabled}
            />
          )}
        </div>
        <div className="col-sm-6">
          <FilterPresetSelect
            name="inheritance"
            options={inheritanceOptions}
            label="Inheritance"
            isMulti
            isDisabled={isDisabled}
          />
          <FilterPresetSelect
            name="quality"
            options={qualityOptions}
            label="Quality"
            isDisabled={isDisabled}
          />
          <Field
            className="input-group-fix"
            name={prepareFieldName("bayesFactor")}
            narrow
            type="text"
            component={ReduxFormField}
            normalize={minAndMaxNumber(0, Number.MAX_VALUE)}
            label="Bayes factor"
            prefix="≥"
            disabled={isDisabled}
          />
          <FilterPresetSelect
            name="subtype"
            options={subtypeOptions}
            label="Type"
            isMulti
            isDisabled={isDisabled}
          />
          <FilterPresetSelect
            name="cvl"
            options={cvlOptions}
            label="Curated variant lists"
            isMulti
            isDisabled={isDisabled}
          />
          {gnomadEnabled && (
            <Field
              className="input-group-fix"
              name={prepareFieldName("gnomadAf")}
              narrow
              type="text"
              component={ReduxFormField}
              label="gnomAD Allele Frequency"
              normalize={minAndMaxNumber(0, 1)}
              prefix="≤"
              disabled={isDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
});

SVConfigEditor.displayName = "SVConfigEditor";

const mapStateToProps = (state, { projectId, form }) => ({
  location: getFormValues(state, form, "config", "filters", "location"),
  genotypeOptions: selectors.getDictionaryOptions(state, "genotypes"),
  sizeOptions: selectors.getDictionaryOptions(state, "sizes"),
  inheritanceOptions: selectors.getDictionaryOptions(state, "inheritance"),
  qualityOptions: selectors.getDictionaryOptions(state, "qualities"),
  subtypeOptions: selectors.getDictionaryOptions(state, "subtypes"),
  tierOptions: selectors.getDictionaryOptions(state, "tiers"),
  genePanelOptions: selectors.getFilterGenePanelOptions(state, projectId),
  cvlOptions: selectors.getCVLsForProjectOptions(state),
  gnomadEnabled: isProjectFeatureActive(state, GNOMAD_SV_FEATURE),
});

export default connect(mapStateToProps, null)(SVConfigEditor);
