import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import Loading from "pattern-library/elements/base/Loading";

import { configuration } from "./actions";
import FilterSidebar from "./components/FilterSidebar";
import Layout from "./components/Layout";
import StrTable from "./components/StrTable";
import { configInitialized } from "./selectors";

const Container = props => {
  const { patientId, variantId, initConfig, initialized } = props;

  useEffect(() => {
    initConfig({ patientId, variantId });
  }, [initConfig, patientId, variantId]);

  if (!initialized) {
    return <Loading />;
  }

  return (
    <Layout
      {...props}
      leftSideComponent={FilterSidebar}
      rightSideComponent={StrTable}
    />
  );
};

Container.propTypes = {
  patientId: PropTypes.number,
  variantId: PropTypes.number,
  geneId: PropTypes.number,
};

const mapStateToProps = state => ({
  initialized: configInitialized(state),
});

const mapDispatchToProps = {
  initConfig: configuration.init,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
