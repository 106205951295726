import React from "react";

import { Actions } from "pattern-library";

import {
  CATEGORY,
  DELETE_METADATA_CATEGORY,
  DELETE_METADATA_FIELD,
  EDIT_METADATA_CATEGORY,
  EDIT_METADATA_FIELD,
  FIELD,
} from "../constants";

const projectCodeAccessor = (
  { inherited, sourceProjectCode, projectCode },
  inheritedColumns
) => {
  if (inheritedColumns) {
    return projectCode || "-";
  }
  return inherited ? sourceProjectCode : "-";
};

const getFieldsCommonColumns = (inheritedFieldsColumns = false) => [
  {
    Header: "Metadata field name",
    accessor: "fieldName",
  },
  {
    Header: "Code",
    Cell: ({
      row: {
        original: { fieldCode },
      },
    }) => <em>{fieldCode}</em>,
  },
  {
    Header: "Category",
    accessor: "categoryName",
  },
  {
    Header: "Parent project",
    accessor: field => projectCodeAccessor(field, inheritedFieldsColumns),
  },
  {
    Header: "Visible in list?",
    accessor: ({ visibleInList }) => (visibleInList ? "Yes" : "No"),
  },
  {
    Header: "Required?",
    accessor: ({ required }) => (required ? "Yes" : "No"),
  },
  {
    Header: "Type",
    accessor: "fieldType",
  },
];

const getCategoriesCommonColumns = inheritedCategoriesColumns => [
  {
    Header: "Metadata category name",
    accessor: "name",
  },
  {
    Header: "Parent project",
    accessor: field => projectCodeAccessor(field, inheritedCategoriesColumns),
  },
  {
    Header: "Visible?",
    accessor: ({ visible }) => (visible ? "Yes" : "No"),
  },
];

export const getFieldsColumns = setMetadataInfo => [
  {
    Header: "Position",
    accessor: "visiblePosition",
  },
  ...getFieldsCommonColumns(),
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original: patientMetadataField } }) => {
      const actions = [
        {
          icon: "pencil",
          className: "btn-default btn--small",
          action: e => {
            e.stopPropagation();
            setMetadataInfo({ ...patientMetadataField }, EDIT_METADATA_FIELD);
          },
        },
        {
          icon: "trash",
          className: "btn-default btn--small",
          action: e => {
            e.stopPropagation();
            setMetadataInfo({ ...patientMetadataField }, DELETE_METADATA_FIELD);
          },
        },
      ];
      return <Actions sticky justifyContent="start" actions={actions} />;
    },
  },
];

export const getInheritedFieldsColumns = addInheritedMetadata => [
  ...getFieldsCommonColumns(true),
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original: patientMetadataField } }) => {
      const actions = [
        {
          icon: "plus",
          label: " Add to project",
          className: "btn-default btn--small",
          action: e => {
            e.stopPropagation();
            addInheritedMetadata(FIELD, { ...patientMetadataField });
          },
        },
      ];
      return <Actions sticky justifyContent="start" actions={actions} />;
    },
  },
];

export const getCategoriesColumns = setMetadataInfo => [
  {
    Header: "Position",
    accessor: "visiblePosition",
  },
  ...getCategoriesCommonColumns(),
  {
    Header: "Actions",
    id: "actions",
    Cell: ({ row: { original: patientMetadataCategory } }) => {
      const actions = [
        {
          icon: "pencil",
          className: "btn-default btn--small",
          action: e => {
            e.stopPropagation();
            setMetadataInfo(
              { ...patientMetadataCategory },
              EDIT_METADATA_CATEGORY
            );
          },
        },
        {
          icon: "trash",
          className: "btn-default btn--small",
          action: e => {
            e.stopPropagation();
            setMetadataInfo(
              { ...patientMetadataCategory },
              DELETE_METADATA_CATEGORY
            );
          },
        },
      ];
      return <Actions sticky justifyContent="start" actions={actions} />;
    },
  },
];

export const getInheritedCategoriesColumns = addInheritedMetadata => [
  ...getCategoriesCommonColumns(true),
  {
    Header: "Total fields",
    accessor: "fieldCount",
  },

  {
    Header: "Actions",
    id: "actions",

    Cell: ({ row: { original: patientMetadataCategory } }) => {
      const actions = [
        {
          icon: "plus",
          label: " Add to project",
          className: "btn-default btn--small",
          action: e => {
            e.stopPropagation();
            addInheritedMetadata(CATEGORY, { ...patientMetadataCategory });
          },
        },
      ];
      return <Actions sticky justifyContent="start" actions={actions} />;
    },
  },
];
