import React, { memo } from "react";

import { CommonContainer, CommonProps } from "../components/CommonContainer";

import congenicaApi from "api/congenica-api";

const { useGetOncologyVariantsCNVsQuery } = congenicaApi;

const columns = [
  {
    Header: "Gene",
    accessor: "gene",
    id: "gene",
  },
  {
    Header: "Chromosome",
    accessor: "chrom",
    id: "chrom",
  },
  {
    Header: "Start",
    accessor: "l_0",
    id: "pos",
  },
  {
    Header: "End",
    accessor: "h_0",
    id: "h_0",
  },
  {
    Header: "Score",
    accessor: "score",
    id: "score",
  },
  {
    Header: "Variant Type",
    accessor: "variant_type",
    id: "variant_type",
  },
  {
    Header: "Gene Type",
    accessor: "gene_type",
    id: "gene_type",
  },
];

const ConnectedContainer: React.FC<CommonProps> = props => {
  const { patientId } = props;
  const { isLoading, data } = useGetOncologyVariantsCNVsQuery({
    patientId,
  });

  return (
    <CommonContainer
      {...props}
      title="Copy number variants"
      data={data}
      columns={columns}
      isLoading={isLoading}
    />
  );
};

export default memo(ConnectedContainer);
