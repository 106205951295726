//@flow

import type { Saga } from "redux-saga";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { getAuthProviderByUser } from "modules/api/authProviders";
import {
  addPermissions,
  addUser,
  editUser,
  getExistingUserDetails,
  getExistingUsers,
  getUsers,
  removeProjectUser,
} from "modules/api/users";
import { error } from "modules/messages/actions";
import { getOrReloadCurrentProject } from "modules/project/saga";
import { getOrFetchProjectSettings } from "modules/projectSettings/saga";
import { getUser } from "modules/projectUsers/selectors";
import { getRoles, readRoles } from "modules/roles";
import { getOrReloadAuthProviders } from "modules/systemConfig";
import type { FetchDataResponse } from "modules/utils/fetchData";
import { processHeaders } from "modules/utils/sagas";

import { getOrReloadCurrentUser } from "../auth/saga";

import type {
  AddPermissionsProjectUserAction,
  AddProjectUserAction,
  DeleteProjectUserAction,
  InitializeAction,
  UpdateProjectUserAction,
} from "./actions";
import * as actions from "./actions";
import {
  fetchExistingUsers,
  fetchUsers,
  setProjectUsersLoading,
  setDoubleUserCheck,
  fetchCurrentUserAuthProviders,
  fetchExistingUserDetails,
  setUserInfo,
} from "./actions";
import { ADD_EXISTING_USER } from "./constants";
import type { User } from "./flow-types";

export function* init(): Saga<void> {
  yield takeLatest(actions.actionType.INIT, initialize);
  yield takeLatest(actions.actionType.FETCH_USERS_START, reloadUsers);
  yield takeLatest(
    actions.actionType.FETCH_EXISTING_USERS_START,
    reloadExistingUsers
  );
  yield takeLatest(
    actions.actionType.FETCH_EXISTING_USER_DETAILS_START,
    reloadExistingUserDetails
  );
  yield takeLatest(
    actions.actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_START,
    reloadCurrentUserAuthProviders
  );
  yield takeLatest(actions.actionType.ADD_PROJECT_USER, addProjectUser);
  yield takeLatest(actions.actionType.UPDATE_PROJECT_USER, updateProjectUser);
  yield takeLatest(
    actions.actionType.ADD_PERMISSIONS_PROJECT_USER,
    addPermissionsProjectUser
  );
  yield takeLatest(actions.actionType.DELETE_PROJECT_USER, deleteProjectUser);
}

export function* initialize({
  payload: projectId,
}: InitializeAction): Saga<void> {
  yield call(getOrReloadCurrentProject, projectId);
  yield call(getOrReloadCurrentUser);
  yield call(getOrReloadAuthProviders);
  yield put(readRoles());
  yield put(actions.fetchUsers.start(projectId));
}

export function* reloadUsers({
  payload: projectId,
}: fetchUsers.start): Saga<void> {
  try {
    yield put(setProjectUsersLoading(true));

    const [
      result: FetchDataResponse<{
        projectUsers: Array<User>,
        inheritedUsers: Array<User>,
      }>,
      settings,
    ] = yield all([
      call(getUsers, projectId),
      call(getOrFetchProjectSettings, projectId),
    ]);

    if (result.ok && settings) {
      yield put(setDoubleUserCheck(settings.doubleUserCheck.enabled));
      yield put(fetchUsers.success(result.payload));
    } else {
      yield put(fetchUsers.failure());
      yield put(error("Cannot reload users"));
    }
  } catch (e) {
    yield put(fetchUsers.failure());
    yield put(error(e));
  } finally {
    yield put(setProjectUsersLoading(false));
  }
}

export function* reloadExistingUsers({
  payload: projectId,
}: fetchExistingUsers.start): Saga<void> {
  try {
    const result: FetchDataResponse<Array<User>> = yield call(
      getExistingUsers,
      projectId
    );
    if (result.ok) {
      yield put(fetchExistingUsers.success(result.payload));
    } else {
      yield put(fetchExistingUsers.failure());
      yield put(error("Cannot reload existing users"));
    }
  } catch (e) {
    yield put(fetchExistingUsers.failure());
    yield put(error(e));
  }
}

export function* reloadExistingUserDetails({
  payload: { projectId, userId },
}: fetchExistingUserDetails.start): Saga<void> {
  try {
    const result: FetchDataResponse<User> = yield call(
      getExistingUserDetails,
      projectId,
      userId
    );
    if (result.ok) {
      yield put(fetchExistingUserDetails.success(result.payload));
      yield put(setUserInfo({ ...result.payload }, ADD_EXISTING_USER));
    } else {
      yield put(fetchExistingUserDetails.failure());
      yield put(error("Cannot get user details"));
    }
  } catch (e) {
    yield put(fetchExistingUserDetails.failure());
    yield put(error(e));
  }
}

export function* reloadCurrentUserAuthProviders(): Saga<void> {
  try {
    const { userId } = yield select(getUser);
    const result: FetchDataResponse<{ data: Array<AuthProvider> }> = yield call(
      getAuthProviderByUser,
      userId
    );
    if (result.ok) {
      yield put(fetchCurrentUserAuthProviders.success(result.payload));
    } else {
      yield put(fetchCurrentUserAuthProviders.failure());
      yield put(error("Cannot get user auth providers"));
    }
  } catch (e) {
    yield put(fetchCurrentUserAuthProviders.failure());
    yield put(error(e));
  }
}

export function* deleteProjectUser({
  payload: { projectId, userId },
}: DeleteProjectUserAction): Saga<void> {
  try {
    yield put(actions.resetUserInfo());
    yield put(setProjectUsersLoading(true));
    const response: FetchDataResponse<any> = yield call(
      removeProjectUser,
      projectId,
      userId
    );
    yield call(processHeaders, response);
    if (response.ok || response?.messages?.success) {
      yield put(actions.fetchUsers.start(projectId));
    }
  } catch (e) {
    yield put(error(e.message));
  } finally {
    yield put(setProjectUsersLoading(false));
  }
}

export function* addPermissionsProjectUser({
  payload: user,
}: AddPermissionsProjectUserAction): Saga<void> {
  try {
    yield put(actions.resetUserInfo());
    yield put(setProjectUsersLoading(true));
    const allRoles = yield select(getRoles);
    const response: FetchDataResponse<any> = yield call(
      addPermissions,
      allRoles,
      user
    );
    yield call(processHeaders, response);
    if (response.ok || response?.messages?.success) {
      const { projectId } = user;
      yield put(actions.fetchUsers.start(projectId));
    }
  } catch (e) {
    yield put(error(e.message));
  } finally {
    yield put(setProjectUsersLoading(false));
  }
}

export function* addProjectUser({
  payload: user,
}: AddProjectUserAction): Saga<void> {
  try {
    yield put(actions.resetUserInfo());
    yield put(setProjectUsersLoading(true));
    const allRoles = yield select(getRoles);
    const response: FetchDataResponse<any> = yield call(
      addUser,
      allRoles,
      user
    );
    yield call(processHeaders, response);
    if (response.ok || response?.messages?.success) {
      const { projectId } = user;
      yield put(actions.fetchUsers.start(projectId));
    }
  } catch (e) {
    yield put(error(e.message));
  } finally {
    yield put(setProjectUsersLoading(false));
  }
}

export function* updateProjectUser({
  payload: user,
}: UpdateProjectUserAction): Saga<void> {
  try {
    yield put(actions.resetUserInfo());
    yield put(setProjectUsersLoading(true));
    const allRoles = yield select(getRoles);
    const response: FetchDataResponse<any> = yield call(
      editUser,
      allRoles,
      user
    );
    yield call(processHeaders, response);
    if (response.ok || response?.messages?.success) {
      const { projectId } = user;
      yield put(actions.fetchUsers.start(projectId));
    }
  } catch (e) {
    yield put(error(e.message));
  } finally {
    yield put(setProjectUsersLoading(false));
  }
}
