import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

export default class Pane extends PureComponent {
  static displayName = "Pane";

  static propTypes = {
    /**
     * list of pane items
     */
    data: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * id for unique identifier
         */
        id: PropTypes.string,
        /**
         * key to display in a pane row
         */
        key: PropTypes.node.isRequired,
        /**
         * value for the key to display in a pane row
         */
        value: PropTypes.node,
        /**
         * value click handler. If specified the value will be clickable
         */
        onClick: PropTypes.func,
      })
    ).isRequired,
    /**
     * Add border around pane
     */
    border: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    border: false,
  };

  render() {
    const { data = [], border } = this.props;
    return (
      <div className={classNames(["pane", { "pane-border": border }])}>
        {data.map(({ id, key, value, onClick, colspan = false }) => {
          const clickable = !isNil(onClick);
          return (
            <div key={id || key} data-testid={id} className="pane-row">
              {!colspan && (
                <div className="pane-row-item pane-row-key">{key}</div>
              )}
              <div className="pane-row-item pane-row-value">
                {colspan && <div className="pane-row-key">{key}</div>}
                {!clickable && value}
                {clickable && value && (
                  <span className="pane-row-value-clickable" onClick={onClick}>
                    {value}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
