import classNames from "classnames";
import type { FC } from "react";
import React, { useMemo, useRef } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { connect, ConnectedProps } from "react-redux";

import { Button, Icon, Label } from "pattern-library";

import * as messageActions from "modules/messages/actions";

import styles from "./FileUploader.module.scss";

interface Props extends PropsFromRedux {
  fileName?: string;
  allowedFilesExtensions?: Array<string>;
  onChange: (file: File) => void;
  disabled?: boolean;
  error: (message: string) => void;
  title?: string;
}

export const FileUploader: FC<Props> = ({
  allowedFilesExtensions = [],
  onChange,
  disabled = false,
  error,
  title = "Choose file",
  fileName,
}: Props) => {
  const selectFile = useRef<HTMLInputElement | null>(null);

  const onClickSelectFile = (e: Event) => {
    e.preventDefault();
    if (selectFile.current) {
      selectFile.current.click();
    }
  };

  const onSelectFileChange = ({ target: { files } }) => {
    process(files);
  };

  const allowedFilesExtensionsAsStr = useMemo<string>(
    () =>
      allowedFilesExtensions?.map(extension => extension.substr(1)).join(","),
    [allowedFilesExtensions]
  );

  const process = (files: Array<File>) => {
    if (files.length === 0) {
      return;
    }
    if (files.length > 1) {
      error("Only single file uploading is accepted");
      return;
    }
    const file: File = files[0];
    const { name } = file;

    if (
      allowedFilesExtensions.length > 0 &&
      !allowedFilesExtensions?.find(allowedFilesExtension =>
        name.endsWith(allowedFilesExtension)
      )
    ) {
      error(
        `${name} - Unsupported file type "${name.substr(
          name.indexOf(".")
        )}". Supported format${allowedFilesExtensions.length > 1 ? "s" : ""} ${
          allowedFilesExtensions.length > 1 ? "are" : "is"
        }: ${allowedFilesExtensionsAsStr}`
      );
      return;
    }
    onChange(file);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    canDrop: () => !disabled,
    accept: [NativeTypes.FILE],

    async drop(item: any, monitor) {
      try {
        const fileSystemEntry = item.items?.[0]?.webkitGetAsEntry();
        if (fileSystemEntry.isDirectory) {
          error("Folders are not accepted");
        } else if (fileSystemEntry.isFile) {
          process(monitor.getItem().files);
        } else {
          error("Unexpected object");
        }
      } catch (_) {
        //if webkitGetAsEntry is not supported by the user browser version we should still process files
        process(monitor.getItem().files);
      }
    },

    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const acceptedFilesExtensions = useMemo<string>(
    () =>
      allowedFilesExtensions.length > 0
        ? allowedFilesExtensions.join(",")
        : "*.*",
    [allowedFilesExtensions]
  );

  return (
    <div className={styles.fileUploader}>
      <Label>{title}</Label>
      <div
        ref={drop}
        className={classNames(
          {
            [styles.fileUploader__drop__active]: canDrop && isOver,
          },
          "dnd-target-area",
          styles.fileUploader__drop
        )}
      >
        <div className={styles.fileUploader__drop__indicator}>
          <Icon type="saveFile" />
        </div>
        <div className={styles.fileUploader__drop__label}>Drop files here</div>
      </div>
      <Button className={styles.fileUploader__file} onClick={onClickSelectFile}>
        Open from local system...
      </Button>
      <input
        data-testid="select-files"
        ref={selectFile}
        className={styles.fileUploader__file}
        type="file"
        accept={acceptedFilesExtensions}
        style={{ display: "none" }}
        onChange={onSelectFileChange}
      />
      {fileName && <div>File name: {fileName}</div>}
    </div>
  );
};

const mapDispatchToProps = {
  error: messageActions.error,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FileUploader);
