import React from "react";

import catalystApi from "../../../api/catalyst-api";

import styles from "./RoleAccessibleComponent.module.scss";

const { useGetProjectCurrentUserQuery } = catalystApi;

export const RoleAccessibleComponent = ({
  disabledOnNoRole = false,
  permittedRoles = [],
  projectId,
  children,
}) => {
  const { userRoles } = useGetProjectCurrentUserQuery(
    { projectId },
    {
      selectFromResult: ({
        data: { canAdminProject, roles = {} } = {},
        error,
      }) => ({
        userRoles: canAdminProject ? { ...roles, admin: true } : roles,
        error,
      }),
    }
  );

  const hasAccess =
    userRoles.admin || permittedRoles.find(role => userRoles[role]);

  if (hasAccess) {
    return children;
  }
  return !disabledOnNoRole ? null : (
    <div className={styles.disabled}>{children}</div>
  );
};
