import React, { PureComponent } from "react";

import congenicaLogo from "../../../images/congenica-logo.svg";

export default class CongenicaLogo extends PureComponent {
  static displayName = "CongenicaLogo";

  render() {
    return <img src={congenicaLogo} alt="Congenica" {...this.props} />;
  }
}
