// @flow
import { createAction } from "../utils";

import * as constants from "./constants";

type ReadRolesAction = FSA<typeof constants.READ_ROLES>;

export const readRoles = (): ReadRolesAction =>
  createAction(constants.READ_ROLES);

export type ReadRolesSuccessAction = FSA<
  typeof constants.READ_ROLES_SUCCESS,
  Array<Role>
>;

export const readRolesSuccess = (roles: Array<Role>): ReadRolesSuccessAction =>
  createAction(constants.READ_ROLES_SUCCESS, roles);

export type ReadRolesFailureAction = FSA<typeof constants.READ_ROLES_FAILURE>;

export const readRolesFailure = (): ReadRolesFailureAction =>
  createAction(constants.READ_ROLES_FAILURE);

export type Action =
  | ReadRolesAction
  | ReadRolesSuccessAction
  | ReadRolesFailureAction;
