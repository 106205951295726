import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  RadioButtons,
} from "pattern-library";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import { exportGenePanel } from "../actions";
import {
  EXPORT_GENE_PANEL_FORM,
  GenePanelContentOptions,
  GenePanelFormatOptions,
} from "../constants";

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.EXPORT_GENE_PANEL_MODAL, {
//      projectId: 123,
//      genePanelId: 123,
//    });

interface Props extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
  exportGenePanel: (
    projectId: number,
    genePanelId: number,
    values: unknown
  ) => void;
  hideModal: (modalType: string) => void;
}

export const ExportGenePanelModal = ({
  projectId,
  genePanelId,
  handleSubmit,
  exportGenePanel,
  hideModal,
}: Props & InjectedFormProps) => {
  const onCloseHandler = useCallback(() => {
    hideModal(modalTypes.EXPORT_GENE_PANEL_MODAL);
  }, [hideModal]);

  const onSubmit = useCallback(
    values => {
      exportGenePanel(projectId, genePanelId, values);
      onCloseHandler();
    },
    [projectId, genePanelId, exportGenePanel, onCloseHandler]
  );

  return (
    <form id="form_gene_panel_download" onSubmit={handleSubmit(onSubmit)}>
      <Modal show close={onCloseHandler}>
        <div className="modal-header">
          <h3 className="header-container pull-left">Export gene panel</h3>
          <div className="pull-right">
            <Button
              type="button"
              className="close"
              onClick={onCloseHandler}
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </Button>
          </div>
        </div>
        <ModalBody>
          <Field
            id="panelContent"
            name="panelContent"
            component={RadioButtons}
            items={GenePanelContentOptions}
            condensed
          />

          <hr />

          <Field
            id="panelFormat"
            name="panelFormat"
            component={RadioButtons}
            items={GenePanelFormatOptions}
            condensed
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseHandler}>Cancel</Button>
          <Button context="primary" type="submit">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </form>
  );
};

const mapDispatchToProps = {
  exportGenePanel: exportGenePanel.start,
  hideModal,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default reduxForm<Index, Props>({
  form: EXPORT_GENE_PANEL_FORM,
  destroyOnUnmount: true,
  initialValues: {
    panelContent: "gene",
    panelFormat: "bed",
  },
  enableReinitialize: true,
})(connector(ExportGenePanelModal));
