import { formatDate } from "modules/utils";

export const filterGenePanels = (
  data: (ProjectGenePanel | CopyProjectGenePanel)[] = [],
  filterValue: string,
  ensemblVersion: string | "ALL"
) =>
  data.filter(genePanel => {
    if (
      ensemblVersion &&
      ensemblVersion !== "ALL" &&
      genePanel.ensemblVersion !== ensemblVersion
    )
      return false;

    for (const key of Object.keys(genePanel)) {
      if (
        key === "updated" &&
        "updated" in genePanel &&
        formatDate(genePanel.updated * 1000).includes(filterValue)
      ) {
        return true;
      }
      if (
        genePanel[key] &&
        genePanel[key]
          .toString()
          .toUpperCase()
          .includes(filterValue.toUpperCase())
      ) {
        return true;
      }
    }
    return false;
  });

export const convertGenePanelPatientsResponse = (
  projectPatients: Array<GenePanelPatientResponse>
) =>
  projectPatients.map(patient => {
    const tempPatient = patient;
    tempPatient.genePanelTitles = patient.genePanelTitles
      ? JSON.parse(patient.genePanelTitles)
      : [];
    tempPatient.phenotypeNames = patient.phenotypeNames
      ? JSON.parse(patient.phenotypeNames)
      : [];
    tempPatient.familyRefs = patient.familyRefs
      ? JSON.parse(patient.familyRefs)
      : [];
    return tempPatient;
  });
