// @flow
import { createFormTypeString } from "./utils";

export const submitForm = (
  formName: string,
  payload: {} = {}
): { type: string, payload: {}, meta: { formName: string } } => ({
  type: createFormTypeString(formName),
  payload,
  meta: {
    formName,
  },
});

export const prefilledSubmitForm =
  (formName: string, payload: {} = {}) =>
  (): {
    type: string,
    payload: {},
    meta: { formName: string },
  } => ({
    type: createFormTypeString(formName),
    payload,
    meta: {
      formName,
    },
  });
