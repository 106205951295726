import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { TimelineComment } from "pattern-library";

import { fetchVariantHistory } from "./actions";
import { AuditDescription } from "./helpers";
import { getVariantHistoryRecords } from "./selectors";

import congenicaApi from "api/congenica-api";

const { useGetPatientQuery } = congenicaApi;

const VariantHistoryContainer = ({
  requestId,
  patientId,
  patientVariantId,
  variantId,
  variantType,
  geneName,
  fetchAudit,
  list,
  showAssociatedTranscript,
}) => {
  useEffect(() => {
    fetchAudit(
      requestId,
      patientId,
      patientVariantId,
      variantId,
      variantType,
      geneName
    );
  }, [
    requestId,
    patientId,
    patientVariantId,
    variantId,
    variantType,
    geneName,
    fetchAudit,
  ]);

  let prevTimestamp = 0;

  if (!list || list.length === 0) return <p>No items found.</p>;

  return (
    <div className="patient-audit-container-list audit-items col-md-8">
      {list.map(item => {
        const currTimestamp = item.dateEpoch * 1000;
        const showDate = !moment(prevTimestamp).isSame(currTimestamp, "day");
        prevTimestamp = currTimestamp;
        return (
          <TimelineComment
            key={currTimestamp}
            showDate={showDate}
            timestamp={currTimestamp}
            username={item.userName}
          >
            <AuditDescription
              item={item}
              showAssociatedTranscript={showAssociatedTranscript}
            />
          </TimelineComment>
        );
      })}
    </div>
  );
};

VariantHistoryContainer.propTypes = {
  requestId: PropTypes.string.isRequired,
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  patientVariantId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  geneName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { requestId }) => ({
  list: getVariantHistoryRecords(requestId)(state),
});

const mapDispatchToProps = {
  fetchAudit: fetchVariantHistory.start,
};

export const VariantHistory = VariantHistoryContainer;

const ConnectedVariantHistory = props => {
  const { data, isLoading } = useGetPatientQuery({
    patientId: props.patientId,
  });

  if (isLoading) return null;

  // Only show the associated transcript in the ACMG panel
  // if we can't find a third party record for Exomiser ACMG Annotation,
  // as the Exomiser annotation uses a mismatched Ensembl version
  const showAssociatedTranscript = !data?.annotation_sources.find(
    ({ attribute }) => attribute === "EXOMISER_ACMG_ANNOTATION"
  );
  return (
    <VariantHistory
      {...props}
      showAssociatedTranscript={showAssociatedTranscript}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedVariantHistory);
