import PropTypes from "prop-types";
import { isEmpty, isNil } from "ramda";
import React, { PureComponent } from "react";

import ButtonGroup from "pattern-library/elements/form/ButtonGroup";

import { Button } from "../../elements";

export class Switch extends PureComponent {
  static displayName = "Switch";

  static propTypes = {
    /**
     * Class to apply to the  component
     */
    className: PropTypes.string,

    /**
     * Disable the control
     */
    isDisabled: PropTypes.bool.isRequired,

    /**
     * Selected case
     */
    selectedCase: PropTypes.string,

    /**
     *  Selected decision
     */
    selectedDecision: PropTypes.oneOf(["Approved", "Rejected"]),

    /**
     * Function to handle the Approve button click
     */
    approveHandler: PropTypes.func.isRequired,

    /**
     * Function to handle the Reject button click
     */
    rejectHandler: PropTypes.func.isRequired,

    /**
     * Function to handle the Unlock button click
     */
    unlockHandler: PropTypes.func.isRequired,
    /**
     * The mode of the component
     */
    mode: PropTypes.oneOf(["SELECT_CASE", "SELECT_CASE_ONLY", "REVIEW"])
      .isRequired,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const {
      className,
      selectedDecision,
      rejectHandler,
      approveHandler,
      unlockHandler,
      isDisabled,
      mode,
      selectedCase,
    } = this.props;

    const isDecisionSelected =
      selectedDecision === "Approved" || selectedDecision === "Rejected";
    const isCaseSelected = !isNil(selectedCase) && !isEmpty(selectedCase);
    const isAcceptRejectDisabled = isDisabled || !isCaseSelected;

    return (
      <ButtonGroup className={className}>
        {selectedDecision === "Approved" && (
          <Button disabled context="success">
            Approved
          </Button>
        )}
        {selectedDecision === "Rejected" && (
          <Button disabled context="danger">
            Rejected
          </Button>
        )}

        {!isDecisionSelected && (
          <Button
            context={isAcceptRejectDisabled ? "default" : "success"}
            disabled={isAcceptRejectDisabled}
            onClick={approveHandler}
          >
            <span className="glyphicon glyphicon-ok-sign" />
          </Button>
        )}
        {!isDecisionSelected && (
          <Button
            context={isAcceptRejectDisabled ? "default" : "danger"}
            disabled={isAcceptRejectDisabled}
            onClick={rejectHandler}
          >
            <span className="glyphicon  glyphicon-remove-sign" />
          </Button>
        )}
        {isCaseSelected &&
          isDecisionSelected &&
          !isDisabled &&
          mode === "REVIEW" && (
            <Button context="danger" onClick={unlockHandler}>
              <span className="glyphicon  glyphicon-lock" />
            </Button>
          )}
      </ButtonGroup>
    );
  }
}
