import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import { ReaderFactory } from "modules/utils/files";

const useDropStatus = (
  processReaderResult,
  disabled,
  allowedFilesExtensions,
  setFilesProcessing
) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    canDrop: () => !disabled,
    accept: [NativeTypes.FILE],

    async drop(item, monitor) {
      if (setFilesProcessing) {
        setFilesProcessing(true);
      }
      const fileList = monitor.getItem().files || [];
      const files = await ReaderFactory(fileList, allowedFilesExtensions);
      processReaderResult(files);
    },

    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  return [drop, isActive];
};

export default useDropStatus;
