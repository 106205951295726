import PropTypes from "prop-types";
import React from "react";

export const AlertTitle = ({ children }) => (
  <div data-testid="alert-title" className="alert-title">
    <b>{children}</b>
  </div>
);

AlertTitle.propTypes = {
  children: PropTypes.node,
};
