import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { LoadingOverlay } from "pattern-library";

import FilterPresetsList from "./filterPresets/FilterPresetsList";
import {
  initFilterPresets,
  removeFilterPreset,
  submitInheritanceSettings,
  submitFilterPreset,
} from "./filterPresets/actions";
import { getFilterPresets, isLoading } from "./filterPresets/selectors";

export const FilterPresets = memo(
  ({
    initFilterPresets,
    removeFilterPreset,
    submitFilterPreset,
    submitInheritanceSettings,
    projectId,
    filterPresets,
    loading,
  }) => {
    useEffect(() => {
      initFilterPresets(projectId);
    }, [projectId, initFilterPresets]);

    const removePreset = useCallback(
      presetToRemove => {
        const { id, title } = presetToRemove;
        removeFilterPreset(projectId, id, title);
      },
      [projectId, removeFilterPreset]
    );

    const submitPreset = useCallback(
      preset => {
        submitFilterPreset(projectId, preset);
      },
      [projectId, submitFilterPreset]
    );

    const submitSettings = useCallback(
      isConfigurationInherited => {
        submitInheritanceSettings(projectId, isConfigurationInherited);
      },
      [submitInheritanceSettings, projectId]
    );

    return (
      <LoadingOverlay loading={loading}>
        <FilterPresetsList
          projectId={projectId}
          filterPresets={filterPresets}
          onRemoveSelectedPreset={removePreset}
          onPresetSubmit={submitPreset}
          onInheritanceSettingsSubmit={submitSettings}
        />
      </LoadingOverlay>
    );
  }
);

FilterPresets.displayName = "FilterPresets";

FilterPresets.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const mapStateToProps = state => ({
  filterPresets: getFilterPresets(state),
  loading: isLoading(state),
});

const mapDispatchToProps = {
  initFilterPresets,
  removeFilterPreset,
  submitFilterPreset,
  submitInheritanceSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPresets);
