import * as yup from "yup";

import { NAME_TAKEN } from "../../../../common/constants";
import { REQUIRED_FIELD_MESSAGE } from "../../../messages/constants";

export const getValidationSchema = filterPresets =>
  yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(REQUIRED_FIELD_MESSAGE)
      .test("titleUnique", NAME_TAKEN, function (val) {
        return !filterPresets.some(({ title, id }) => {
          const trimmedTitle = title.trim();
          return val === trimmedTitle && this.parent.id !== id;
        });
      }),
    isActive: yup
      .boolean()
      .test(
        "defaultShouldBeActive",
        "The default preset cannot be inactive",
        function (val) {
          return !(!val && this.parent.isDefault);
        }
      ),
  });
