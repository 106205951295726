import { Track } from "../../core/SNV/PatientVCF";
import { Colors, getRelativeTrackBaseConfig } from "../../utils";

export const createTrackConfig = ({
  snvId,
  patientId,
  trackType,
  trackTypeId,
  transcriptId,
  genome,
} = {}) => ({
  id: "Congenica.SNV.PatientVCF",
  namespace: "Congenica.SNV.PatientVCF",
  name: "Patient VCF",
  url:
    `/catalyst/api/patient/${patientId}` +
    (trackType ? `/track/${trackType}` : "") +
    "/files/snv_vcf?chr=__CHR__&start=__START__&end=__END__" +
    (trackTypeId ? `&trackTypeId=${trackTypeId}` : "") +
    (transcriptId ? `&transcript_id=${transcriptId}` : ""),
  height: 90,
  snvId: parseInt(snvId),
  labels: true,
  featureColor: Colors.BLACK,
  threshold: 1e5,
  legend: true,
  genome,
});

export const createRelativeTrackConfig = (
  config,
  { patient = {}, relation } = {}
) => {
  const { reference, patientId } = patient;
  const baseConfig = createTrackConfig({
    ...config,
    patientId,
  });

  return {
    ...baseConfig,
    ...getRelativeTrackBaseConfig(
      "Congenica.SNV.PatientVCF",
      relation,
      patientId
    ),
    name: `${relation} (${reference}): VCF`,
    configName: ["Patient VCF", `Relation: ${relation} (${reference})`],
    info: "Sequence variants observed in patient relative's VCF",
    legend: false,
  };
};

export const PatientVCF = config => Track.extend(createTrackConfig(config));

export const RelativeVCF = (config, relative = {}) =>
  Track.extend(createRelativeTrackConfig(config, relative));
