import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { not } from "ramda";
import React, { PureComponent } from "react";

import Icon from "../base/Icon";

export default class Input extends PureComponent {
  static displayName = "Input";

  static propTypes = {
    /**
     * Class to apply to the input
     */
    className: PropTypes.string,

    /**
     * Type of input to render
     */
    type: PropTypes.string,

    /**
     * Whether this field should require input
     */
    required: PropTypes.bool,

    /**
     * Id of the input
     */
    id: PropTypes.string,

    /**
     * Ref to be forwarded to the DOM element
     */
    forwardRef: PropTypes.instanceOf(Element),

    /**
     * Handle Change Function
     */
    handleChange: PropTypes.func,

    /**
     * Handle Change Function
     */
    handleClear: PropTypes.func,

    /**
     *  Regular expression to validate user input
     *  Examples
     *  Integer (positive only): /^\d*$/
     *  Integer (both positive and negative): /^-?\d*$/
     *  Floating point (use . or , as decimal separator): /^-?\d*[.,]?\d*$/
     *  Currency (at most two decimal places): /^-?\d*[.,]?\d{0,2}$/
     *  A-Z only: /^[a-z]*$/
     *  Latin letters only (most European languages): /^[a-z\u00c0-\u024f]*$/
     *  Hexadecimal: /^[0-9a-f]*$/
     */
    inputFilter: PropTypes.any,
  };

  static defaultProps = {
    className: "",
    type: "text",
    required: false,
    forwardRef: null,
    inputFilter: false,
  };

  static excludedInputTypes = ["file", "checkbox", "radio"];

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const {
      type,
      className,
      forwardRef,
      handleChange,
      handleClear,
      value,
      id,
      inputFilter,
      ...otherProps
    } = this.props;

    let componentProps = {
      className: classNames(
        {
          "form-control": not(Input.excludedInputTypes.includes(type)),
          "input-clear": handleClear,
        },
        className
      ),
      id,
      ref: forwardRef,
      type,
      onChange: handleChange,
    };

    if (type === "checkbox") {
      componentProps.checked = value;
    } else {
      componentProps.value = value === null ? "" : value;
    }

    if ((type === "text" || type === "number") && inputFilter) {
      const onKeyPress = e => {
        const {
          target: { value },
          which,
        } = e;
        if (!inputFilter.test(value + String.fromCharCode(which))) {
          e.preventDefault();
        }
      };

      componentProps = {
        ...componentProps,
        onKeyPress,
      };
    }

    return (
      <>
        <input {...componentProps} {...otherProps} />
        {handleClear && value !== "" && (
          <Icon
            aria-hidden="true"
            type="cross"
            caption="Clear filter"
            className="form-control-feedback input-clear-btn"
          />
        )}
      </>
    );
  }
}

// This is the wrapper that provide access to underlying input DOM element
export const InputWithForwardRef = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref} />
));
