import { FC, memo } from "react";

import { ButtonDropdown, Download, Link } from "pattern-library";

import {
  CATALYST_BASE_URL,
  API_ENTITIES_BASE_URL,
} from "modules/utils/baseUrls";

import catalystApi from "../../../api/catalyst-api";

const { useGetPatientAndTabsQuery } = catalystApi;

interface Props {
  disabled?: boolean;
  patientId: number;
}
const PatientDataDownload: FC<Props> = ({ patientId, disabled = false }) => {
  const { reportDataFileName, secondaryAnalysisDataFileName } =
    useGetPatientAndTabsQuery(
      { patientId },
      {
        selectFromResult: ({
          data: { patient: { reference } = {} as Patient } = {},
        }) => {
          const prefix =
            reference && reference.length <= 200 ? reference : patientId;
          return {
            reportDataFileName: `${prefix}_report_data.json`,
            secondaryAnalysisDataFileName: `${prefix}_secondary_analysis_data.zip`,
          };
        },
      }
    );

  return (
    <ButtonDropdown
      containerClassName="oncology-report__actions__download"
      disabled={disabled}
      btnContent="Download Patient Data"
      list={[
        {
          key: "report-tertiary-data-download",
          content: (
            <Download
              Component={Link}
              url={`${CATALYST_BASE_URL}/patient/${patientId}/report/patient-data/oncology`}
              filename={reportDataFileName}
              caption="Report Data"
              dataTestId="report-data-download"
              disabled={disabled}
            />
          ),
        },
        {
          key: "report-secondary-data-download",
          content: (
            <Download
              Component={Link}
              url={`${API_ENTITIES_BASE_URL}/patients/${patientId}/oncology/secondary_analysis_data/download`}
              filename={secondaryAnalysisDataFileName}
              caption="Secondary Analysis Data"
              dataTestId="secondary-analysis-data-download"
              disabled={disabled}
            />
          ),
        },
      ]}
    />
  );
};

export default memo(PatientDataDownload);
