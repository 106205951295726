export interface SampleFileProps {
  id: string; //sampleId
  name: string; //fileName
}

export type CSVFileRows = Array<Record<string, any>>;
export interface CSVFileResult {
  fileName: string;
  data: CSVFileRows;
  headers: Array<string>;
}

export enum FILE_READER_TYPES {
  VCF = "vcf",
  SNV_VCF = "snv-vcf",
  SV_VCF = "sv-vcf",
  CNV_VCF = "cnv-vcf",
  BAM = "bam",
  FASTQ = "fastq",
  CSV = "csv",
  UNKNOWN = "unknown",
}

export interface ReaderFactoryBase {
  name: string;
  file: File;
  type: FILE_READER_TYPES;
}

export interface ReaderFactoryFile extends ReaderFactoryBase {
  reader: BaseReader;
}

export interface FileObjectWithResult extends ReaderFactoryBase {
  result: Record<string, any>;
}

export interface FileReaderError extends ReaderFactoryBase {
  error: string;
}

export type ObjectForFile = FileObjectWithResult | FileReaderError;

export type FileReaderErrors = {
  [key in FILE_READER_TYPES]?: Array<FileReaderError>;
};

export interface FileReaderResult {
  error: FileReaderErrors;
  [FILE_READER_TYPES.VCF]?: Array<SampleFileProps>;
  [FILE_READER_TYPES.SNV_VCF]?: Array<SampleFileProps>;
  [FILE_READER_TYPES.CNV_VCF]?: Array<SampleFileProps>;
  [FILE_READER_TYPES.SV_VCF]?: Array<SampleFileProps>;
  [FILE_READER_TYPES.BAM]?: Array<SampleFileProps>;
  [FILE_READER_TYPES.FASTQ]?: Array<SampleFileProps>;
  [FILE_READER_TYPES.CSV]?: Array<CSVFileResult>;
}

export interface BaseReader {
  getResult: () => Promise<Record<string, any>>;
}

export interface FileReaderConfig {
  extensions?: Array<string>;
  reader: { new (file: File): BaseReader };
  type: FILE_READER_TYPES;
}

export interface FileReaderObject {
  reader: BaseReader;
  type: FILE_READER_TYPES;
}
