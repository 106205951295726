import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import { not, isNil } from "ramda";
import React, { Component } from "react";

import { Loading } from "pattern-library";

export default class LegacySapientiaTemplate extends Component {
  static propTypes = {
    templateUrl: PropTypes.string.isRequired,
    htmlUpdated: PropTypes.func,
    /**
     * a custom wrapper css class
     */
    containerClassName: PropTypes.string,
  };

  static defaultProps = {
    htmlUpdated: null,
    containerClassName: "",
  };

  constructor(props) {
    super(props);

    autoBind(this);

    // We use this to generate a unique id for this component for jquery to reference
    const array = new Uint32Array(3);
    this.elId = window.crypto.getRandomValues(array).join("-");

    this.createNewControllerSignal();
  }

  componentDidMount() {
    const { templateUrl } = this.props;
    if (templateUrl) {
      this.updateHtml();
    }
  }

  componentDidUpdate(prevProps) {
    const { templateUrl } = this.props;
    if (prevProps.templateUrl !== templateUrl) {
      // we abort the current fetch
      this.abortFetch();

      // we create a new controller so that the previous abort won't also abort
      // the new request we are about to make
      this.createNewControllerSignal();

      // make a request using the new controller's signal
      this.updateHtml();
    }
  }

  componentWillUnmount() {
    this.abortFetch();
  }

  createNewControllerSignal() {
    const AbortController = window.AbortController || false; // eslint-disable-line

    // For cancelling the fetch
    if (AbortController) {
      this.controller = new AbortController();
      this.signal = this.controller.signal;
    }
  }

  abortFetch() {
    if (not(isNil(this.controller))) {
      this.controller.abort();
    }
  }

  updateHtml() {
    const { htmlUpdated, templateUrl: url } = this.props;

    fetch(`${url}`, { credentials: "include", signal: this.signal })
      .then(response => response.text())
      .then(newHtml => {
        const $el = window.$(`#${this.elId}`);
        if ($el) {
          $el.html(newHtml).addClass("foobar");
          try {
            // This is very important
            // Legacy sapientia js will use the jquery instance passed down
            // for the underlying code, therefore we must give them the window
            // instance otherwise none of the plugins exist
            window.Sapientia.contentLoaded($el);
            if (htmlUpdated) {
              htmlUpdated();
            }
          } catch (e) {
            // TODO: push this into the messages component
            console.log(e);
          }
        }
      })
      // TODO: push this into the messages component
      .catch(e => console.log(e));
  }

  render() {
    const { templateUrl } = this.props;
    if (isNil(templateUrl))
      throw new Error("LegacySapientiaTemplate requires a templateUrl prop");
    const { containerClassName } = this.props;

    return (
      <>
        <div key={templateUrl} id={this.elId} className={containerClassName}>
          <Loading />
        </div>
      </>
    );
  }
}
