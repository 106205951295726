import { path } from "ramda";
import { takeLatest, call, put } from "redux-saga/effects";

import { getFilterPresets } from "modules/api/filterPresets";

import {
  COMMON_ERROR_MESSAGE,
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";
import { error } from "../messages/actions";
import { requestFilterDictionaries } from "../projectSettings/components/filterPresets/apiUtils";
import { SV } from "../projectSettings/components/filterPresets/constants";

import * as actions from "./actions";
import { LoadPresetsAction, LoadDictionariesAction } from "./actions";
import { LOAD_PRESETS, LOAD_DICTIONARIES } from "./constants";
import { SvPreset } from "./types";

export function* init(): Generator<any, any, any> {
  yield takeLatest(LOAD_PRESETS, loadPresets);
  yield takeLatest(LOAD_DICTIONARIES, loadDictionaries);
}

export function* loadPresets(
  action: LoadPresetsAction
): Generator<any, any, any> {
  yield put(actions.setPresetsInfo({ requestStatus: IN_PROGRESS_STATUS }));
  try {
    const {
      ok,
      payload = {},
      statusText,
      errors,
    } = yield call(getFilterPresets, {
      projectId: action.meta.projectId,
      variantType: SV,
      active: true,
    });

    if (ok) {
      const presets: Array<SvPreset> = payload.data;
      const successStatusUpdate = { requestStatus: SUCCESS_STATUS, presets };
      yield put(actions.setPresetsInfo(successStatusUpdate));
    } else {
      const payloadError = path(["error"], payload);
      const apiError =
        payloadError || errors || statusText || COMMON_ERROR_MESSAGE;
      const errorMessage = `Could not load SV Presets:\n${apiError}`;
      yield put(error(errorMessage));
      const errorStatusUpdate = {
        requestStatus: FAILURE_STATUS,
      };
      yield put(actions.setPresetsInfo(errorStatusUpdate));
    }
  } catch (e) {
    yield put(error(e.message || COMMON_ERROR_MESSAGE));
    const unknownErrorStatusUpdate = {
      requestStatus: FAILURE_STATUS,
    };
    yield put(actions.setPresetsInfo(unknownErrorStatusUpdate));
  }
}

export function* loadDictionaries(
  action: LoadDictionariesAction
): Generator<any, any, any> {
  try {
    const { payload } = yield call(
      requestFilterDictionaries,
      action.meta.projectId
    );
    yield put(actions.setPresetDictionaries(payload));
  } catch (e) {
    yield put(error(e.message || COMMON_ERROR_MESSAGE));
    const unknownErrorStatusUpdate = {
      requestStatus: FAILURE_STATUS,
    };
    yield put(actions.setPresetsInfo(unknownErrorStatusUpdate));
  }
}
