import BAMReader from "./BAMReader";
import CSVReader from "./CSVReader";
import FASTQReader from "./FASQReader";
import VCFReader from "./VCFReader";
import { FILE_EXTENSIONS } from "./constants";
import {
  FILE_READER_TYPES,
  FileObjectWithResult,
  FileReaderConfig,
  FileReaderError,
  FileReaderObject,
  FileReaderResult,
  ObjectForFile,
  ReaderFactoryFile,
} from "./types";

const allExtensions: Array<string> = Object.values(FILE_EXTENSIONS).reduce(
  (accumulator, extensions) => [...accumulator, ...extensions],
  []
);

const getExtensionsRegExp = (extensions: Array<string>): RegExp =>
  new RegExp(`(.*?)(${extensions.join("|")})$`);

const fileReadersConfig: Array<FileReaderConfig> = [
  {
    extensions: FILE_EXTENSIONS.VCF,
    reader: VCFReader,
    type: FILE_READER_TYPES.VCF,
  },
  {
    extensions: FILE_EXTENSIONS.SNV_VCF,
    reader: VCFReader,
    type: FILE_READER_TYPES.SNV_VCF,
  },
  {
    extensions: FILE_EXTENSIONS.SV_VCF,
    reader: VCFReader,
    type: FILE_READER_TYPES.SV_VCF,
  },
  {
    extensions: FILE_EXTENSIONS.CNV_VCF,
    reader: VCFReader,
    type: FILE_READER_TYPES.CNV_VCF,
  },
  {
    extensions: FILE_EXTENSIONS.BAM,
    reader: BAMReader,
    type: FILE_READER_TYPES.BAM,
  },
  {
    extensions: FILE_EXTENSIONS.FASTQ,
    reader: FASTQReader,
    type: FILE_READER_TYPES.FASTQ,
  },
  {
    extensions: FILE_EXTENSIONS.CSV,
    reader: CSVReader,
    type: FILE_READER_TYPES.CSV,
  },
];

function getAllowedFileExtension(file: File, allowedExtensions: Array<string>) {
  const { name } = file;

  const exec = getExtensionsRegExp(allowedExtensions).exec(name);
  return exec ? exec[2] : null;
}

const getUnknownFileTypeReaderConfig = (file: File): FileReaderObject => {
  const { name } = file;
  return {
    reader: {
      // This needs brackets for syntactical reasons
      getResult: () => {
        throw new Error(`Unknown file type ${name.substr(name.indexOf("."))}`);
      },
    },
    type: FILE_READER_TYPES.UNKNOWN,
  };
};

const getFileReaderConfig = (
  file: File,
  allowedExtensions: Array<string>
): FileReaderObject => {
  const allowedExtension = getAllowedFileExtension(file, allowedExtensions);

  let readerConfig;

  if (allowedExtension) {
    readerConfig = fileReadersConfig.find(({ extensions = [] }) =>
      extensions.includes(allowedExtension)
    );
  }

  return readerConfig
    ? {
        type: readerConfig.type,
        reader: new readerConfig.reader(file),
      }
    : getUnknownFileTypeReaderConfig(file);
};

async function getObjectsForFiles(
  filesToParse: Array<ReaderFactoryFile>
): Promise<Array<ObjectForFile>> {
  return Promise.all(
    filesToParse.map(async ({ reader, ...other }) => {
      try {
        const result = await reader.getResult();
        const objectForFile: ObjectForFile = {
          result,
          ...other,
        };
        return objectForFile;
      } catch (e) {
        const objectForFile: ObjectForFile = {
          error: e.message,
          ...other,
        };
        return objectForFile;
      }
    })
  );
}

function formatFiles(
  filesToParse: Array<ReaderFactoryFile>
): Promise<FileReaderResult> {
  return new Promise(async resolve => {
    const filesWithResults: Array<ObjectForFile> = await getObjectsForFiles(
      filesToParse
    );

    resolve(
      filesWithResults.reduce(
        (accumulator, item) => {
          const { result } = item as FileObjectWithResult;
          if (result) {
            accumulator[item.type] = accumulator[item.type] || [];
            accumulator[item.type].push(result);
          } else {
            const { error } = accumulator;
            error[item.type] = error[item.type] || [];
            error[item.type]?.push(item as FileReaderError);
          }

          return accumulator;
        },
        { error: {} } as FileReaderResult
      )
    );
  });
}

// Take in a set of files
export default async function ReaderFactory(
  files: Array<File>,
  allowedExtensions: Array<string> = allExtensions
): Promise<FileReaderResult> {
  // List of promises for the files
  const filesToParse: Array<ReaderFactoryFile> = [];

  files.forEach((file: File) => {
    // determine what type of file it is
    const { type, reader } = getFileReaderConfig(file, allowedExtensions);

    // store the data
    filesToParse.push({
      name: file.name,
      file,
      reader,
      type,
    });
  });

  return await formatFiles(filesToParse);
}
