import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { ComposableTabs, Icon, Tab } from "pattern-library";

import { isCurrentProjectOncology } from "modules/project/selectors";

import EU from "./EU";
import UK from "./UK";
import USA from "./USA";

const getTabs = tabs =>
  tabs.map(({ name, icon, contentComponent: Component, props }) => (
    <Tab key={name} name={name} icon={icon}>
      <Component {...props} />
    </Tab>
  ));

export const RegulatoryInfo = ({ isOncologyProject }: PropsFromRedux) => {
  const ceMarkingTabs = useMemo(
    () =>
      [
        {
          name: "European Union",
          contentComponent: EU,
          icon: <Icon type="euFlag" />,
        },
        {
          name: "United Kingdom",
          contentComponent: UK,
          icon: <Icon type="ukFlag" />,
        },
        !isOncologyProject
          ? {
              name: "United States of America",
              contentComponent: USA,
              icon: <Icon type="usaFlag" />,
            }
          : null,
      ].filter(Boolean),
    [isOncologyProject]
  );
  return (
    <ComposableTabs navStyle={false} stacked>
      {getTabs(ceMarkingTabs)}
    </ComposableTabs>
  );
};

const mapStateToProps = state => ({
  isOncologyProject: isCurrentProjectOncology(state),
});

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RegulatoryInfo);
