import classNames from "classnames";
import { isEmpty, mergeDeepRight } from "ramda";
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  CongenicaLogo,
  Link,
  StickyToLeftWrapper,
} from "pattern-library";

import styles from "./Header.module.scss";
import { HeaderLink, HeaderProps, NavKeys } from "./Header.types";
import defaultLinkData from "./default-link-data";

const Header: FunctionComponent<HeaderProps> = ({
  devLabel = false,
  alwaysHideLoginLink = false,
  limitWidth = false,
  navData = {},
  user = null,
}) => {
  const dropDownRef = useRef<HTMLLIElement>(null);

  const { name, admin = false } = user || {};
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [navbarCollapsed, setNavbarCollapsed] = useState<boolean>(true);
  const loggedIn = user && !isEmpty(user);

  const closeDropdownOnOutsideClick = useCallback((event: MouseEvent) => {
    if (
      !dropDownRef.current ||
      dropDownRef.current.contains(event.target as HTMLElement) ||
      // Take a look at the comment inside the `.dropdown` of `Header.module.scss`.
      getComputedStyle(dropDownRef.current).maxWidth !== "none"
    ) {
      return;
    }
    setDropdownActive(false);
  }, []);

  useEffect(() => {
    document.addEventListener("click", closeDropdownOnOutsideClick);
    return () => {
      document.removeEventListener("click", closeDropdownOnOutsideClick);
    };
  }, [closeDropdownOnOutsideClick]);

  const renderNavItem = (
    key: NavKeys,
    className?: string
  ): null | ReactElement => {
    const { action, attributes, icon, label }: HeaderLink = navData[key]
      ? mergeDeepRight(defaultLinkData[key], navData[key])
      : defaultLinkData[key];

    if (!action) {
      return null;
    }

    let item: ReactElement;
    const allAttributes = {
      ...attributes,
      href: typeof action !== "function" ? (action as string) : undefined,
      onClick: typeof action === "function" ? action : undefined,
    };

    const iconElement = icon && (
      <span className={`glyphicon glyphicon-${icon}`} />
    );

    if (typeof action === "function") {
      item = (
        <a {...allAttributes}>
          {label} {iconElement}
        </a>
      );
    } else {
      item = (
        <Link {...allAttributes}>
          {label} {iconElement}
        </Link>
      );
    }

    return <li className={className}>{item}</li>;
  };

  const navClassNames = classNames(
    styles.navbarNav,
    "nav",
    "navbar-nav",
    "navbar-right"
  );

  return (
    <div
      className={classNames(styles.component, "navbar", "navbar-default", {
        [styles.withLimitedWidth]: limitWidth,
      })}
      data-testid="page-header"
    >
      <div
        className={classNames(styles.navbarHeader, "navbar-header", {
          "pull-left": !loggedIn,
        })}
      >
        <Link className={styles.componentLogoLink} href="/">
          <CongenicaLogo className={styles.componentLogo} />
          {devLabel && (
            <span
              className={classNames(
                styles.componentDevLabel,
                "label",
                "label-danger"
              )}
            >
              {(devLabel as string | true) === true ? "DEV (REACT)" : devLabel}
            </span>
          )}
        </Link>
        {/* If `!loggedIn`, there is only one nav item, so it's enough place for it on `xs` size (collapse button is redundant). */}
        {loggedIn && (
          <button
            className="navbar-toggle collapsed"
            onClick={() => setNavbarCollapsed(!navbarCollapsed)}
            type="button"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
        )}
      </div>
      <div
        className={classNames(styles.navbarCollapse, "navbar-collapse", {
          // If `!loggedIn`, there is only one nav item, so it's enough place for it on `xs` size
          // and there is no need to hide it (there is no need to collapse the block).
          collapse: loggedIn && navbarCollapsed,
        })}
      >
        {alwaysHideLoginLink || loggedIn ? null : (
          <ul className={navClassNames}>
            {renderNavItem("login", "text-right")}
          </ul>
        )}
        {loggedIn && (
          <ul className={navClassNames}>
            {renderNavItem("projects")}
            <li
              className={classNames(styles.dropdown, "dropdown", {
                open: dropdownActive,
              })}
              ref={dropDownRef}
            >
              <Button
                context="link"
                onClick={() => setDropdownActive(!dropdownActive)}
              >
                {name} <span className="caret" />
              </Button>
              <ul className="dropdown-menu">
                {renderNavItem("profile")}
                {renderNavItem("logout")}
                {admin && renderNavItem("admin")}
              </ul>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export const StickyToLeftHeader: FunctionComponent<HeaderProps> = props => (
  <StickyToLeftWrapper className="z-index-overflow">
    <Header {...props} />
  </StickyToLeftWrapper>
);

export default Header;
