import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import { values } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  setCurationIdCuratedVariantModal,
  setCuratedVariantVisibleModal,
} from "../../../curatedVariant/actions";

import RenderCuration from "./RenderCuration";

export class CuratedVariantsColumn extends PureComponent {
  static propTypes = {
    variant: PropTypes.object.isRequired,
    header: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onCurationClicked(curatedVariantId) {
    const { setCurationIdCuratedVariantModal, setCuratedVariantVisibleModal } =
      this.props;

    setCurationIdCuratedVariantModal(curatedVariantId);
    setCuratedVariantVisibleModal(true);
  }

  render() {
    const {
      variant: { curations = {} },
    } = this.props;
    return (
      <div className="variant-column curated_variants_list">
        {values(curations).map(curation => {
          const [{ curatedVariantId }] = curation;

          return (
            <RenderCuration
              key={curatedVariantId}
              curations={curation}
              onClick={this.onCurationClicked}
            />
          );
        })}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setCurationIdCuratedVariantModal,
  setCuratedVariantVisibleModal,
};

export default connect(null, mapDispatchToProps)(CuratedVariantsColumn);
