import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { NAMESPACE } from "./constants";

export const getModalsObject = path([NAMESPACE, "byId"]);
export const getModalsList = path([NAMESPACE, "list"]);
export const getModals = createSelector(
  getModalsObject,
  getModalsList,
  (modalObject, modalList) => modalList.map(modalKey => modalObject[modalKey])
);
