import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Icon from "../../elements/base/Icon";

export default class Rule extends PureComponent {
  static propTypes = {
    description: PropTypes.string.isRequired,
    pass: PropTypes.bool.isRequired,
  };

  render() {
    const { description, pass } = this.props;

    const type = pass ? "tick" : "cross";
    const classes = pass ? "icon--success" : "icon--fail";

    return (
      <li className="rule">
        <Icon type={type} className={classes} />
        {description}
      </li>
    );
  }
}
