import { fetchData, fetchCamelizeData } from "modules/utils";
import { CATALYST_BASE_URL } from "modules/utils/baseUrls";

const getBaseUrl = ({ patientId, variantId, geneId }) =>
  `${CATALYST_BASE_URL}/patient/${patientId}/variant/${variantId}/gene/${geneId}/acmg/criteria`;

const getAcmgSuggestedCriteria = payload =>
  fetchCamelizeData(`${getBaseUrl(payload)}/suggestions`, {
    method: "GET",
  });

const setAcceptedAcmgSuggestedCriteria = payload =>
  fetchData(`${getBaseUrl(payload)}/apply_suggestions`, {
    method: "POST",
    body: JSON.stringify({ criteria_codes: payload.selectedCriteria }),
  });

const setRejectedAcmgSuggestedCriteria = payload =>
  fetchData(`${getBaseUrl(payload)}/reject_suggestions`, {
    method: "POST",
  });

export {
  getAcmgSuggestedCriteria,
  setAcceptedAcmgSuggestedCriteria,
  setRejectedAcmgSuggestedCriteria,
};
