import { createAction } from "../utils";

import * as constants from "./constants";

export const isUserLoggedIn = () => ({
  type: constants.IS_USER_LOGGED_IN,
  payload: {},
  meta: {},
});

export const loadInitialUserFailed = () => ({
  type: constants.LOAD_INITIAL_USER_FAILED,
  payload: {},
  meta: {},
});

export const userLoggingIn = () => ({
  type: constants.USER_LOGGING_IN,
  payload: {},
  meta: {},
});

export const userLoginFailed = () => ({
  type: constants.USER_LOGIN_FAILED,
  payload: {},
  meta: {},
});

export const userLoggedIn = (admin, name, id, email) => ({
  type: constants.USER_LOGGED_IN,
  payload: {
    result: {
      admin,
      name,
      id,
      email,
    },
  },
  meta: {},
});

export const fetchProfileApiToken = () =>
  createAction(constants.FETCH_PROFILE_API_TOKEN);

export const fetchProfileApiTokenSuccess = token =>
  createAction(constants.FETCH_PROFILE_API_TOKEN_SUCCESS, { result: token });

export const fetchProfileApiTokenFailure = () =>
  createAction(constants.FETCH_PROFILE_API_TOKEN_FAILURE);

export const resetProfileApiToken = () =>
  createAction(constants.RESET_PROFILE_API_TOKEN);
