// There are size restrictions for HTMLCanvasElement. When a customer have a lot of data for particular track section
// this track overflows the restrictions (SAP-23198). In such cases we have to render data in parts (render-groups):
// the most top part -> one or more middle parts -> the most bottom part.
import { Track } from "../core/SequenceAlignment";
import { Colors, getRelativeTrackBaseConfig } from "../utils";

export const checkBasePair = value => basePair =>
  basePair.toUpperCase() === value.toUpperCase();

export const createTrackConfig = ({ patientId }) => ({
  url: `/catalyst/api/patient/${patientId}/files/bam_reads?chr=__CHR__&start=__START__&end=__END__`,
  info: "The pile-up of individual sequence reads in the sample",
  bump: true,
  // Take a look at the comment at the beginning of file.
  // And some numbers here: https://github.com/jhildenbiddle/canvas-size#desktop
  maxCanvasHeight: 8e3,
  resizable: true,
  minHeight: 45,
  autoHeight: true,
  featureMargin: { top: 0, right: 0, bottom: 1, left: 0 },
  featureHeight: 15,
  colors: [
    {
      predicate: checkBasePair("A"),
      color: Colors.GREEN,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkBasePair("T"),
      color: Colors.LIGHT_RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkBasePair("C"),
      color: Colors.DARK_CYAN,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkBasePair("G"),
      color: Colors.LIGHT_YELLOW,
      labelColor: Colors.BLACK,
    },
    {
      predicate: checkBasePair("X"),
      color: Colors.PURPLE_BRIGHT,
      labelColor: Colors.BLACK,
    },
    {
      predicate: checkBasePair("<"),
      color: Colors.SILVER,
      labelColor: Colors.WHISPER,
    },
    {
      predicate: checkBasePair(">"),
      color: Colors.DUSTY_GREY,
      labelColor: Colors.WHISPER,
    },
    {
      predicate: checkBasePair(" "),
      color: Colors.SILVER,
      labelColor: Colors.WHISPER,
    },
    {
      predicate: () => true, // Default Color
      color: Colors.SILVER,
      labelColor: Colors.BLACK,
    },
  ],
  // When features of all render-groups except the first one are rendered at the beginning of the canvas,
  // some of their parts may be truncated. We need a couple of pixels at the beginning to avoid it.
  // So we have to use this value (for all groups except the first one):
  // - to increase canvas height, it'll increase a respective image as well;
  // - to use as a negative shift for images;
  // - to consider during calculation of the `fixedY` value for each feature.
  seamOverlap: 3,
});

export const createRelativeTrackConfig = ({ patient = {}, relation } = {}) => {
  const { reference, patientId } = patient;
  const baseConfig = createTrackConfig(patient);

  return {
    ...baseConfig,
    ...getRelativeTrackBaseConfig(
      "Congenica.SequenceAlignment",
      relation,
      patientId
    ),
    configName: ["Sequence alignment", `Relation: ${relation} (${reference})`],
    name: `${relation} (${reference}): Sequence alignment`,
    info: "The pile up of individual sequence reads in patient relative.",
  };
};

export const SequenceAlignment = config =>
  Track.extend(createTrackConfig(config));

export const RelativeSequenceAlignment = (relative = {}) =>
  Track.extend(createRelativeTrackConfig(relative));
