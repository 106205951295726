import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Field } from "redux-form";

import { Divider } from "pattern-library";

import { getGeneOptions } from "modules/api/genes";
import { ReduxFormField } from "modules/forms/components";
import SelectReduxField from "modules/forms/components/SelectReduxField";
import { LOCATION_TYPES } from "modules/projectSettings/components/filterPresets/constants";

import * as selectors from "../selectors";
import { Filters } from "../types";
import usePatientGenePanelsOptions from "../usePatientGenePanelsOptions";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

const getEnsemblSpecificLabel = (label, ensemblVersion) => (
  <>
    {label}
    {ensemblVersion && <small> (Ensembl release {ensemblVersion})</small>}
  </>
);

type Props = {
  onChange?: () => void;
  patientId: number;
};

export const SVLocationContainer: React.FC<Props> = ({
  onChange,
  patientId,
}) => {
  const { ensemblVersion } = useEnsemblVersion(patientId);

  const filters = useSelector(selectors.getFilters) as Filters;
  const genePanelOptions = usePatientGenePanelsOptions(patientId);

  const onChangeHandler = useCallback(() => {
    setTimeout(() => {
      if (onChange) {
        onChange();
      }
    }, 0);
  }, [onChange]);

  const specificGenesLabel = getEnsemblSpecificLabel(
    LOCATION_TYPES.genes.label,
    ensemblVersion
  );
  const genePanelsLabel = getEnsemblSpecificLabel(
    LOCATION_TYPES.panels.label,
    ensemblVersion
  );

  return (
    <>
      <div className="structural-variants-filter-row row">
        <label className="col-xs-12">Only show variants in:</label>
        <div className="col-sm-6 col-md-12">
          <Field
            component={ReduxFormField}
            type="radio"
            name="sv_location_filter"
            boxLabel={specificGenesLabel}
            value={LOCATION_TYPES.genes.value}
            onChange={onChangeHandler}
          />
          {filters.sv_location_filter === LOCATION_TYPES.genes.value && (
            <div data-testid="sv_genes_filter">
              <Field
                component={ReduxFormField}
                type="select"
                async
                noResultsText="No genes found"
                key="no-genes-found-field"
                name="sv_genes_filter"
                placeholder="Type to search genes..."
                loadOptions={getGeneOptions(ensemblVersion)}
                onChange={onChangeHandler}
              />
            </div>
          )}
        </div>
        <div className="col-sm-6 col-md-12">
          <Field
            component={ReduxFormField}
            type="radio"
            name="sv_location_filter"
            boxLabel={genePanelsLabel}
            value={LOCATION_TYPES.panels.value}
            onChange={onChangeHandler}
          />
          {filters.sv_location_filter === LOCATION_TYPES.panels.value && (
            <div data-testid="sv_genePanel_filter">
              <SelectReduxField
                name="sv_genePanel_filter"
                narrow
                hasGroups
                isMulti={genePanelOptions && genePanelOptions.length > 0}
                options={genePanelOptions}
                onChange={onChangeHandler}
              />
            </div>
          )}
        </div>
      </div>
      <Divider />
    </>
  );
};

export default SVLocationContainer;
