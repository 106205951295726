/* File contains old track code, could be refactored in the future */

import { Colors, createBadge } from "../utils";

import { Track as SequenceVariants } from "./SequenceVariants";

export const Config = {
  category: "Population",
  tags: ["Population"],
  labels: false,
  legend: true,
  bump: true,
  colorMap: {
    5: {
      color: Colors.RED_BRIGHT,
      highlight: Colors.GREY,
    },
    "Clearly pathogenic": {
      color: Colors.RED_BRIGHT,
      highlight: Colors.GREY,
    },
    Pathogenic: {
      color: Colors.RED_BRIGHT,
      highlight: Colors.RED_BRIGHT,
    },
    4: {
      color: Colors.ORANGE_DARK,
      highlight: Colors.GREY,
    },
    "Likely to be pathogenic": {
      color: Colors.ORANGE_DARK,
      highlight: Colors.GREY,
    },
    "Likely pathogenic": {
      color: Colors.ORANGE_DARK,
      highlight: Colors.ORANGE_DARK,
    },
    3: {
      color: Colors.LIGHT_YELLOW,
      highlight: Colors.GREY,
    },
    "Unknown significance (VUS)": {
      color: Colors.LIGHT_YELLOW,
      highlight: Colors.GREY,
    },
    "Uncertain significance": {
      color: Colors.LIGHT_YELLOW,
      highlight: Colors.LIGHT_YELLOW,
    },
    2: {
      color: Colors.WHISPER,
      highlight: Colors.GREY,
    },
    "Unlikely to be pathogenic": {
      color: Colors.WHISPER,
      highlight: Colors.GREY,
    },
    "Likely benign": {
      color: Colors.LIGHT_BLUE,
      highlight: Colors.LIGHT_BLUE,
    },
    1: {
      color: Colors.WHISPER,
      highlight: Colors.GREY,
    },
    "Clearly not pathogenic": {
      color: Colors.WHISPER,
      highlight: Colors.GREY,
    },
    Benign: {
      color: Colors.LIGHT_BLUE,
      highlight: Colors.LIGHT_BLUE,
    },
    0: {
      color: Colors.WHISPER,
      highlight: Colors.GREY,
    },
    Excluded: {
      color: Colors.WHISPER,
      highlight: Colors.GREY,
    },
  },
  url: "/API/Variant/CuratedVariantList.json?chr=__CHR__&start=__START__&end=__END__",

  getFeatureColor(feature) {
    const pathogenicity = this.prop("getPathogenicity")(feature);
    if (pathogenicity === "Pathogenicity not available") {
      return {
        color: Colors.SILVER,
        highlight: Colors.GREY,
      };
    }
    return this.prop("colorMap")[pathogenicity];
  },

  getPathogenicity(feature) {
    if (!feature.show_pathogenicity) {
      return false;
    }
    return feature.legend;
  },

  getMenuItems(feature) {
    const popup = {
      title: feature.title + " - " + feature.list_name,
      HGVSg: `Chr${feature.chr}:g.${feature.start}${feature.ref_allele}>${feature.alt_allele}`,
    };
    const pathogenicity = this.prop("getPathogenicity")(feature);
    if (
      pathogenicity &&
      this.prop("getFeatureColor").call(this, feature) &&
      this.prop("getFeatureColor").call(this, feature).color
    ) {
      const badgeColor = this.prop("getFeatureColor").call(this, feature).color;
      popup.Pathogenicity = createBadge(badgeColor) + pathogenicity;
    }
    return popup;
  },

  insertFeature(feature) {
    const newFeature = {
      ...feature,
      ...this.prop("getFeatureColor").call(this, feature),
    };
    newFeature.legend =
      this.prop("getPathogenicity").call(this, feature) ||
      "Pathogenicity not available";
    this.base(newFeature);
  },

  // Will not work in standalone React (depends on Sapientia modal)
  populateMenu(feature) {
    const popup = this.prop("getMenuItems").call(this, feature);
    const patientId = this.prop("urlParams").patient_id;
    const data = `{ action: 'modal-ajax', href:'/patient/${patientId}/variants/curated_variant/${feature.curated_variant_id}'}`;
    const options = "{ fireContentLoaded: true }";
    const link = `<a href="javascript:void(0)" onclick="Sapientia.events.loadModal(${data}, ${options})">Show all curated data</a>`;
    popup[""] = `<br/>${link}`;
    return this.base(feature, popup);
  },
};

export const Track = SequenceVariants.extend(Config);
