import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const UIOverflow = ({ visible, center, children }) => (
  <div className={classNames("ui-overflow", { visible, center })}>
    {children}
  </div>
);

UIOverflow.displayName = "UIOverflow";

UIOverflow.propTypes = {
  visible: PropTypes.bool,
  center: PropTypes.bool,
};

UIOverflow.defaultProps = {
  visible: false,
  center: false,
};

export default UIOverflow;
