import { isEmpty } from "ramda";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { CheckboxGroup, Button, Divider } from "pattern-library";

import { SV_COLUMNS } from "modules/projectSettings/components/filterPresets/constants";

import { SV_COLUMNS_FORM } from "../constants";
import * as selectors from "../selectors";

const ColumnsCheckboxGroup = ({
  disabled,
  meta: { pristine },
  input: { value, onChange },
  options,
}) => {
  const columnsValue = isEmpty(value) ? [] : value;
  const changeHandler = value => {
    const orderedValue = SV_COLUMNS.map(({ key }) => key).filter(key =>
      value.includes(key)
    );
    onChange(orderedValue);
  };

  return (
    <CheckboxGroup
      options={options}
      onChange={changeHandler}
      disabled={disabled}
      value={columnsValue}
      valueSort={false}
      pristine={pristine}
    />
  );
};

export const SVColumnsFormComponent = ({
  handleSubmit,
  onSubmit,
  closeDropdown,
  columns,
}) => (
  <form onSubmit={handleSubmit(onSubmit(closeDropdown))}>
    <div className="col-md-12">
      <Field
        name="columns"
        component={ColumnsCheckboxGroup}
        options={columns}
      />
    </div>
    <Divider direction="horizontal" className="col-md-12 pull-left" />
    <div className="col-md-12">
      <div className="pull-right">
        <Button type="submit" context="primary">
          Apply
        </Button>{" "}
        <Button
          type="button"
          onClick={() => {
            closeDropdown();
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  </form>
);

const mapStateToProps = state => ({
  initialValues: { columns: selectors.getSVTableColumns(state) },
});

const FormComponent = reduxForm({
  form: SV_COLUMNS_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SVColumnsFormComponent);

export default connect(mapStateToProps)(FormComponent);
