import moment from "moment";

const momentLocale = date =>
  moment(date).locale(navigator.userLanguage || navigator.language);

export const formatDate = date => momentLocale(date).format("L");

export const formatDateTime = date => momentLocale(date).format("L, HH:mm");

export const formatTime = date => momentLocale(date).format("HH:mm");

export const getStartOfTheDay = date =>
  date ? moment(date).startOf("day").format("X") : null;
export const getEndOfTheDay = date =>
  date ? moment(date).endOf("day").format("X") : null;

export const supportedDateFormats = [
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "YYYY/MM/DD",
  moment.ISO_8601,
];

export const isSupportedDateFormat = (
  d,
  dateFormats = supportedDateFormats
) => {
  const customDate = moment(d, dateFormats, true);
  return customDate.isValid();
};

export const guessDate = (d, dateFormats = supportedDateFormats) => {
  const customDate = moment(d, dateFormats, true);
  if (customDate.isValid()) {
    return customDate;
  }
  return null;
};

export const sortDates = (itemA, itemB, columnId) => {
  const valueA = itemA.values[columnId];
  const valueB = itemB.values[columnId];
  const dateA = guessDate(valueA, supportedDateFormats);
  const dateB = guessDate(valueB, supportedDateFormats);
  if (dateA > dateB) return 1;
  return -1;
};
