import { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";

import { ButtonDropdown, Input, Divider } from "pattern-library";

import { useDebounce } from "common/utils";

import { setFilterColumns } from "./actions";
import SVColumnsForm from "./components/SVColumnsForm";
import { SV_COLUMNS_FORM } from "./constants";
import * as selectors from "./selectors";

export const LEGACY_COLUMNS_CONNECTOR_ID = "legacy-sv-columns-connector";
const DEBOUNCE_DELAY = 400;

type Props = {
  projectId: number | string;
  patientId: number | string;
  acceptableColumns: Array<string>;
  setFilterColumns: typeof setFilterColumns;
  resetForm: typeof reset;
  columns: Options;
};

export const SVColumnsDropdown = ({
  setFilterColumns,
  resetForm,
  columns,
}: Props) => {
  const [columnFilter, setColumnFilter] = useState("");
  const debouncedColumnSearch = useDebounce(columnFilter, DEBOUNCE_DELAY);
  const [filteredColumns, setFilteredColumns] = useState(columns);

  useEffect(() => {
    if (!debouncedColumnSearch || debouncedColumnSearch === "") {
      setFilteredColumns(columns);
      return;
    }
    const filteredColumns = columns.filter(({ label }) =>
      label.toUpperCase().includes(debouncedColumnSearch.toUpperCase())
    );
    setFilteredColumns(filteredColumns);
  }, [debouncedColumnSearch, columns]);

  const filterChangeHandler = useCallback(({ currentTarget: { value } }) => {
    setColumnFilter(value);
  }, []);

  const dispatchColumnsChanged = useCallback(() => {
    const element = document.getElementById(LEGACY_COLUMNS_CONNECTOR_ID);
    if (element) {
      element.dispatchEvent(
        new CustomEvent("columnChangeEvent", { bubbles: true })
      );
    }
  }, []);

  const applyHandler = useCallback(
    closeDropdown =>
      ({ columns: inputColumnsValue }) => {
        setFilterColumns(inputColumnsValue);
        dispatchColumnsChanged();
        closeDropdown();
      },
    [dispatchColumnsChanged, setFilterColumns]
  );

  const onCloseHandler = useCallback(() => {
    resetForm(SV_COLUMNS_FORM);
    setColumnFilter("");
  }, [resetForm]);

  const renderDropdownContent = closeDropdown => (
    <div className="pull-left my-1 sv-columns-menu-container ">
      <div className="col-md-12">
        <Input
          placeholder="Search columns..."
          onChange={filterChangeHandler}
          value={columnFilter}
        />
      </div>
      <Divider direction="horizontal" className="col-md-12 pull-left" />
      <SVColumnsForm
        columns={filteredColumns}
        onSubmit={applyHandler}
        closeDropdown={closeDropdown}
      />
    </div>
  );

  return (
    <div
      /*This id is necessary for communicating with the Perl SV table code, as that isn't yet written in React*/
      id={LEGACY_COLUMNS_CONNECTOR_ID}
    >
      <ButtonDropdown
        btnContent="Columns"
        containerClassName="pull-right"
        menuClassName="py-0"
        destroyOnClose
        onClose={onCloseHandler}
        render={renderDropdownContent}
      />
    </div>
  );
};

const mapStateToProps = (state, { acceptableColumns }) => ({
  columns: selectors.getFilteredColumns(state, acceptableColumns),
});

const mapDispatchToProps = {
  setFilterColumns,
  resetForm: reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(SVColumnsDropdown);
