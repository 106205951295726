import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

export class RouteTabHeader extends Component {
  static displayName = "RouteTabHeader";

  static propTypes = {
    tab: PropTypes.shape({
      /**
       * Name of the tab
       */
      name: PropTypes.string.isRequired,
      /**
       * Url for the NavLink component
       */
      url: PropTypes.string,
      /**
       * Hash for the anchor tag - used for linking outside of the scope of the React app
       */
      hash: PropTypes.string,
      /**
       * Class for the NavLink component
       */
      navLinkClassName: PropTypes.string,
      /**
       * Flag to load the count number or a number to display
       */
      count: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      /**
       * Url to load an entity's count
       */
      countUrl: PropTypes.string,
    }),
    /**
     * Base url for nesting routes
     */
    baseUrl: PropTypes.string,
  };

  static defaultProps = {
    navLinkClassName: "",
    countUrl: "",
    baseUrl: "",
    count: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const {
      baseUrl,
      tab: {
        name,
        countUrl,
        navLinkClassName,
        count,
        url = "",
        hash,
        exact,
        tabBaseUrl,
      },
    } = this.props;

    const countElement = Number.isInteger(count) && (
      <span className="ajax nocache label label-default">{count}</span>
    );

    if (hash) {
      // this check is needed so we can override the base url of a specific tab in an array,
      // as otherwise we would be unable to route the project settings and other pieces
      // between Perl & React
      const base = tabBaseUrl || baseUrl;
      return (
        <a href={`${base}${url}#${hash}`}>
          {name} {countElement}
        </a>
      );
    }

    return (
      <NavLink to={baseUrl + url} className={navLinkClassName} exact={exact}>
        {name}{" "}
        {countUrl && (
          <span
            className="ajax nocache label label-default"
            data-href={countUrl}
          />
        )}
        {countElement}
      </NavLink>
    );
  }
}
// Because the PureComponent doesn't know about the context and the change of the location
// We need to explicitly pass this data as props
export default withRouter(RouteTabHeader);
