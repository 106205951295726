const getFunctionName = fn => {
  const { name } = fn || {};
  return name ? name : "anonymous function";
};

export const checkResponse = (fn, msg) => {
  if (typeof fn !== "function") throw new Error("Not a function");
  return async (...args) => {
    const result = await fn(...args);
    if (result.hasOwnProperty("ok")) {
      const { ok } = result;
      if (!ok)
        throw new Error(
          `${msg ? msg : `Error with calling ${getFunctionName(fn)}`}${
            result.statusText ? "\nDetails: " + result.statusText : ""
          }`
        );
    }
    return result;
  };
};
