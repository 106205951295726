//@flow

import type { Saga } from "redux-saga";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import * as api from "modules/api/patientMetadata";
import { error } from "modules/messages/actions";
import { FIELD } from "modules/patientMetadata/constants";
import { PROCESSORS } from "modules/patientMetadata/processors";
import { getOrReloadCurrentProject } from "modules/project/saga";
import { getCurrentProject } from "modules/project/selectors";
import type { FetchDataResponse } from "modules/utils/fetchData";
import { processError, processSuccess } from "modules/utils/sagas";
import { capitalizeFirstLetter } from "modules/utils/string";

import * as actions from "./actions";

export function* init(): Saga<void> {
  yield takeLatest(actions.actionType.INIT, initialize);
  yield takeLatest(
    actions.actionType.FETCH_PATIENT_METADATA_FIELDS_START,
    reloadMetaDataFields
  );
  yield takeLatest(
    actions.actionType.FETCH_PATIENT_METADATA_CATEGORIES_START,
    reloadMetaDataCategories
  );
  yield takeLatest(actions.actionType.ADD_METADATA, addMetadata);
  yield takeLatest(actions.actionType.UPDATE_METADATA, updateMetadata);
  yield takeLatest(actions.actionType.DELETE_METADATA, deleteMetadata);
  yield takeLatest(
    actions.actionType.ADD_INHERITED_METADATA,
    addInheritedMetadata
  );
  yield takeLatest(
    actions.actionType.ADD_ALL_INHERITED_METADATA,
    addAllInheritedMetadata
  );
}

export function* initialize({
  payload: projectId,
}: actions.InitialiseAction): Saga<void> {
  try {
    yield put(actions.setLoading(true));
    yield call(getOrReloadCurrentProject, projectId);
    yield all([
      put(actions.fetchPatientMetadataFields.start(projectId)),
      put(actions.fetchPatientMetadataCategories.start(projectId)),
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* reloadMetaData<T>(
  projectId: number,
  metadata: string,
  asyncActionCreator: AsyncActionCreator
): Saga<void> {
  try {
    const [
      result: FetchDataResponse<{
        data: Array<T>,
      }>,
      inheritedResult: FetchDataResponse<{
        data: Array<T>,
      }>,
    ] = yield all([
      call(api.getPatientMetadata, projectId, metadata),
      call(api.getPatientMetadata, projectId, metadata, true),
    ]);

    if (result.ok && inheritedResult.ok) {
      yield put(
        asyncActionCreator.success({
          [`${metadata}`]: result.payload,
          [`inherited${capitalizeFirstLetter(metadata)}`]:
            inheritedResult.payload,
        })
      );
    } else {
      yield put(asyncActionCreator.failure());
      if (!result.ok) {
        yield call(processError, result, `Cannot reload metadata ${metadata}`);
      }
      if (!inheritedResult.ok) {
        yield call(
          processError,
          inheritedResult,
          `Cannot reload inherited metadata ${metadata}`
        );
      }
    }
  } catch (e) {
    yield put(asyncActionCreator.failure());
    yield put(error(e));
  }
}

export function* reloadMetaDataFields({
  payload: projectId,
}: actions.fetchPatientMetadataFields.start): Saga<void> {
  yield call(
    reloadMetaData,
    projectId,
    "fields",
    actions.fetchPatientMetadataFields
  );
}

export function* reloadMetaDataCategories({
  payload: projectId,
}: actions.fetchPatientMetadataCategories.start): Saga<void> {
  yield call(
    reloadMetaData,
    projectId,
    "categories",
    actions.fetchPatientMetadataCategories
  );
}

export const getSuccessMessage = (metadata: string, action: string) =>
  `Patient metadata ${metadata.toLowerCase()} successfully ${action}`;

export function* onFieldActionSuccess(
  projectId: number,
  response: FetchDataResponse<any>,
  successMessage: string
): Saga<void> {
  yield put(actions.fetchPatientMetadataFields.start(projectId));
  yield call(processSuccess, response, successMessage);
}

export function* onCategoryActionSuccess(
  projectId: number,
  response: FetchDataResponse<any>,
  successMessage: string
): Saga<void> {
  yield all([
    put(actions.fetchPatientMetadataCategories.start(projectId)),
    put(actions.fetchPatientMetadataFields.start(projectId)),
  ]);
  yield call(processSuccess, response, successMessage);
}

export function* processMetadata(
  type: string,
  actionType: string,
  metadata: ?MetadataField | ?MetadataCategory,
  successMessage: ?string = null
): Saga<void> {
  const { projectId } = yield select(getCurrentProject);
  yield call(
    processor,
    projectId,
    PROCESSORS[type][actionType](projectId, metadata),
    type === FIELD ? onFieldActionSuccess : onCategoryActionSuccess,
    successMessage
  );
}

export function* addMetadata({
  type: actionType,
  payload: { type, metadata },
}: actions.AddMetadataAction): Saga<void> {
  yield call(
    processMetadata,
    type,
    actionType,
    metadata,
    getSuccessMessage(type, "created")
  );
}

export function* addInheritedMetadata({
  type: actionType,
  payload: { type, metadata },
}: actions.AddInheritedMetadataAction): Saga<void> {
  yield call(
    processMetadata,
    type,
    actionType,
    metadata,
    `Metadata ${type.toLowerCase()} added to the project`
  );
}

export function* addAllInheritedMetadata({
  type: actionType,
  payload: type,
}: actions.AddAllInheritedMetadataAction): Saga<void> {
  yield call(
    processMetadata,
    type,
    actionType,
    null,
    `Metadata ${type === FIELD ? "fields" : "categories"} added to the project`
  );
}

export function* updateMetadata({
  type: actionType,
  payload: { type, metadata, successMessage },
}: actions.UpdateMetadataAction): Saga<void> {
  yield call(
    processMetadata,
    type,
    actionType,
    metadata,
    successMessage ? successMessage : getSuccessMessage(type, "updated")
  );
}

export function* deleteMetadata({
  type: actionType,
  payload: { type, metadata },
}: actions.DeleteMetadataAction): Saga<void> {
  yield call(
    processMetadata,
    type,
    actionType,
    metadata,
    getSuccessMessage(type, "deleted")
  );
}

export function* processor(
  projectId: number,
  run: () => Saga<void>,
  onSuccess: (
    projectId: number,
    response: FetchDataResponse<any>,
    successMessage: string
  ) => Saga<void>,
  successMessage: ?string = null,
  onError: (
    response: FetchDataResponse<any>,
    defaultErrorMessage: string
  ) => Saga<void> = processError
): Saga<void> {
  try {
    yield put(actions.resetMetadataInfo());
    yield put(actions.setLoading(true));
    // $FlowFixMe
    const response: FetchDataResponse = yield call(run);
    if (response.ok) {
      yield call(onSuccess, projectId, response, successMessage);
    } else {
      yield call(onError, response);
    }
  } catch (e) {
    yield put(error(e.message));
  } finally {
    yield put(actions.setLoading(false));
  }
}
