// @flow
import { createSelector } from "@reduxjs/toolkit";
import { path, isNil, not } from "ramda";

import {
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";

import * as constants from "./constants";
import type { SelectedHPOTerm, HPOTerm } from "./reducer";

const uiPath = (...items) => [constants.NAME, "ui", ...items];
const dataPath = (...items) => [constants.NAME, "data", ...items];

export const hpoTermsSelector: (state: {}) => Array<HPOTerm> = path(
  dataPath("terms")
);

export const hasLoaded: (state: {}) => boolean = path(uiPath("loaded"));
export const selectedHPOTermsSelector: (state: {}) => Array<SelectedHPOTerm> =
  path(uiPath("selectedHPOTerms"));
export const getExpandedNodeIds: (state: {}) => Array<string> = path(
  uiPath("expandedNodeIds")
);
export const getVisibleNodeIds: (state: {}) => Array<string> = path(
  uiPath("visibleNodeIds")
);
export const getQuery: (state: {}) => string = path(uiPath("query"));

export const selectedHPOTermCodes: (state: {}) => Array<string> =
  createSelector(selectedHPOTermsSelector, (hpoTerms: Array<SelectedHPOTerm>) =>
    hpoTerms.map(({ code }) => code)
  );

export const getHPOTermsByTermId: (state: {}) => {} = createSelector(
  hpoTermsSelector,
  (terms: Array<HPOTerm> | void) => {
    if (isNil(terms)) return {};

    return terms.reduce((accumulator, term) => {
      accumulator[term.hpoTermId] = term;

      return accumulator;
    }, {});
  }
);

export const getHpoTermsTree = createSelector(
  hpoTermsSelector,
  (data): Array<HPOTerm> | void => {
    const termsById = {};
    data.forEach(term => {
      const hashed = termsById[term.termId];
      if (hashed) {
        if (!hashed.parentIds.includes(term.parentId)) {
          hashed.parentIds.push(term.parentId);
        }
      } else {
        const { parentId, ...data } = term;
        termsById[term.termId] = { data, parentIds: [parentId], children: [] };
      }
    });
    Object.keys(termsById)
      .map(key => termsById[key])
      .forEach(node => {
        node.parentIds.forEach(parentId => {
          if (termsById[parentId]) {
            termsById[parentId].children.push(node);
          }
        });
      });

    const setId = (node, parentId, index) => {
      node.id = parentId ? `${parentId}/${index}` : String(index);
      node.children.sort((a, b) => a.data.name.localeCompare(b.data.name));
      node.children.forEach((child, i) => {
        setId(child, node.id, i);
      });
    };

    setId(termsById[constants.TOP_LEVEL_TERM], null, 0);

    return termsById[constants.TOP_LEVEL_TERM];
  }
);

export const getLowerCaseQuery = createSelector(getQuery, (query): string =>
  query.toLowerCase()
);

export const getMatchingNodeIds = createSelector(
  getHpoTermsTree,
  getLowerCaseQuery,
  (root, query) => {
    const ids = [];
    if (!query || query.length < 2) return ids;

    const search = node => {
      const {
        id,
        data: { hpoTermId, name, description = "" },
        children,
      } = node;

      if (`${hpoTermId}${name}${description}`.toLowerCase().includes(query)) {
        ids.push(id);
      }

      children.forEach(child => {
        search(child);
      });
    };

    search(root);

    return ids.sort();
  }
);

// removes parent ids as they can be deduced from the child
// e.g. ['0/4/2', '0/4/2/7'] is reduced to ['0/4/2/7'] only
export const getMatchingChildIds = createSelector(getMatchingNodeIds, ids =>
  ids
    .reduce((uniq, id) => {
      if (uniq.some(i => i.startsWith(id))) return uniq;

      return [...uniq.filter(i => not(id.startsWith(i))), id];
    }, [])
    .sort()
);

const hpoTermsLoadingStatus = path(uiPath("status"));

export const hpoTermsLoadingInProgress = createSelector(
  hpoTermsLoadingStatus,
  status => status === IN_PROGRESS_STATUS
);

export const hpoTermsLoadingSuccessful = createSelector(
  hpoTermsLoadingStatus,
  status => status === SUCCESS_STATUS
);

export const hpoTermsLoadingFailed = createSelector(
  hpoTermsLoadingStatus,
  status => status === FAILURE_STATUS
);
