import React, { Fragment, memo } from "react";

import { ExternalUrl } from "modules/utils/externalUrls";

const parseIds = (ids: number | string | Array<any>): Array<any> => {
  if (typeof ids === "number") {
    return [ids.toString()];
  }

  if (typeof ids === "string") {
    // Split by commas and remove any trailing spaces
    return ids.split(/\s*,\s*/);
  }

  return ids || [];
};

export enum OmimLinksDividers {
  SPACE = " ",
  COMMA = ", ",
}

interface OmimLinksProps {
  ids: number | string | Array<any>;
  divider?: OmimLinksDividers;
  ending?: string;
}

export const OmimLinks = ({
  ids,
  divider = OmimLinksDividers.SPACE,
  ending = "",
}: OmimLinksProps) => {
  const parsedIds = parseIds(ids);
  return (
    <>
      {parsedIds.map((id, index) => (
        <Fragment key={`omim-${id}`}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${ExternalUrl("omim.entry")}${id}`}
            onClick={e => e.stopPropagation()}
          >
            {id}
          </a>
          {ending}
          {index !== parsedIds.length - 1 && divider}
        </Fragment>
      ))}
    </>
  );
};

export default memo(OmimLinks);
