import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import FormControlWrapper from "./FormControlWrapper";
import RootFormElement from "./RootFormElement";

export default class FormGroup extends RootFormElement {
  static propTypes = {
    children: PropTypes.any.isRequired,
    narrow: PropTypes.bool,
    meta: PropTypes.shape({
      error: PropTypes.string,
      warning: PropTypes.string,
    }),
  };

  static defaultProps = {
    meta: {},
  };

  render() {
    const {
      label = "",
      children,
      meta: { error = false, warning = false },
      narrow = false,
    } = this.props;

    let message = false;

    if (this.hasWarnings()) message = warning;
    if (this.hasErrors()) message = error;

    return (
      <div
        className={classNames(
          "form-group row",
          this.hasErrors(),
          this.hasWarnings()
        )}
      >
        {children}
        <FormControlWrapper
          className={classNames({
            "col-md-offset-4": narrow === true && label.length > 0,
            "col-md-offset-2": narrow === false && label.length > 0,
          })}
        >
          {message && <span className="help-block">{message}</span>}
        </FormControlWrapper>
      </div>
    );
  }
}
