import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import { actionType } from "./actions";
import type { AcmgAuditInfo, AuditInfo } from "./types";

type State = {
  data: {
    auditInfo: AsyncData<AuditInfo>;
    acmgAuditInfo: AsyncData<AcmgAuditInfo>;
  };
};

export const initialState: State = {
  data: {
    auditInfo: { data: {}, loading: false },
    acmgAuditInfo: { data: {}, loading: false },
  },
};

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  if (!action) return state;
  switch (action.type) {
    case actionType.FETCH_AUDIT_START:
      return set(state, "data.auditInfo", {
        data: {},
        loading: true,
      });

    case actionType.FETCH_AUDIT_SUCCESS:
      return set(state, "data.auditInfo", {
        data: action.payload,
        loading: false,
      });

    case actionType.FETCH_AUDIT_FAILURE:
      return set(state, "data.auditInfo", {
        data: {},
        loading: false,
      });

    case actionType.FETCH_ACMG_AUDIT_START:
      return set(state, "data.acmgAuditInfo", {
        data: {},
        loading: true,
      });

    case actionType.FETCH_ACMG_AUDIT_SUCCESS:
      return set(state, "data.acmgAuditInfo", {
        data: action.payload,
        loading: false,
      });

    case actionType.FETCH_ACMG_AUDIT_FAILURE:
      return set(state, "data.acmgAuditInfo", {
        data: {},
        loading: false,
      });

    default:
      return state;
  }
}
