// @flow
import { COMMON_ERROR_MESSAGE } from "../../common/constants";

import type { MessageType } from "./constants";
import * as constants from "./constants";
import type { MessageDetails, MessagesPayload } from "./flow-types";

type MessageAction = FSA<
  typeof constants.SET_MESSAGE,
  {
    message: string,
    messageType: MessageType,
  }
>;

export function message(
  type: MessageType,
  messageText: string,
  meta: {} = {}
): MessageAction {
  // if error message is empty, set defaults
  const mt =
    type === "danger" && !messageText ? COMMON_ERROR_MESSAGE : messageText;
  return {
    type: constants.SET_MESSAGE,
    payload: {
      message: mt,
      messageType: type,
    },
    meta,
  };
}

type MessagesAction = FSA<typeof constants.SET_MESSAGES, MessagesPayload>;

export const messages = (
  messageType: MessageType,
  messagesList: Array<any>,
  meta: {} = {}
): MessagesAction => ({
  type: constants.SET_MESSAGES,
  payload: {
    messageType,
    messagesList,
  },
  meta,
});

const messageWithTypeDefined =
  (type: MessageType) =>
  (messageText: string, meta: {} = {}): MessageAction =>
    message(type, messageText, meta);

// Shorthand helpers for the message method
export const error = messageWithTypeDefined("danger");
export const warning = messageWithTypeDefined("warning");
export const info = messageWithTypeDefined("info");
export const success = messageWithTypeDefined("success");

const messagesWithType =
  (type: MessageType) =>
  (messagesList: Array<MessageDetails>): MessagesAction =>
    messages(type, messagesList);
export const errors = messagesWithType("danger");

type DismissAction = FSA<typeof constants.DISMISS_MESSAGE, number>;

export function dismiss(index: number, meta: {} = {}): DismissAction {
  return {
    type: constants.DISMISS_MESSAGE,
    payload: index,
    meta,
  };
}

type DismissAllAction = FSA<typeof constants.DISMISS_ALL>;

export function dismissAll() {
  return {
    type: constants.DISMISS_ALL,
    payload: {},
    meta: {},
  };
}

type ToggleCollapseAction = FSA<typeof constants.TOGGLE_COLLAPSE>;

export function toggleCollapse(): ToggleCollapseAction {
  return {
    type: constants.TOGGLE_COLLAPSE,
    payload: {},
    meta: {},
  };
}

type CollapseAction = FSA<typeof constants.COLLAPSE>;

export function collapse(): CollapseAction {
  return {
    type: constants.COLLAPSE,
    payload: {},
    meta: {},
  };
}

export type Action =
  | MessageAction
  | MessagesAction
  | DismissAction
  | ToggleCollapseAction
  | CollapseAction
  | DismissAllAction;
