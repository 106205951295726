import { mapObjIndexed, type, equals } from "ramda";

const convertIds = mapObjIndexed((val, key) => {
  if (equals(type(val), "Object")) return convertIds(val);
  if (key.endsWith("Id") && equals(type(val), "String")) {
    const parsed = parseInt(val, 10);
    if (!isNaN(parsed)) return parsed;
  }
  return val;
});

export default convertIds;
