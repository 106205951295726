import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "pattern-library";

import { REQUIRED_FIELD_MESSAGE } from "../../messages/constants";
import { getMetadataFields } from "../../metadata/selectors";
import { COLUMNS } from "../constants";

import {
  FILE_TYPES_WARNING,
  VALID_FILE_COMBINATIONS_MESSAGE,
} from "./componentConstants";

export class WarningsModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    /**
     * metadata dynamic field definitions
     */
    fields: PropTypes.array,
    /**
     * form errors object
     */
    errors: PropTypes.object.isRequired,
  };

  static defaultProps = {
    fields: [],
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  getAdjustedMessage(message) {
    // for warnings modal the file combinations message needs to be extended
    if (message === VALID_FILE_COMBINATIONS_MESSAGE) {
      return `${FILE_TYPES_WARNING}. ${message}`;
    }

    return message;
  }

  generateWarnings = errors => {
    let warnings = [];

    if (errors.identifier === REQUIRED_FIELD_MESSAGE) {
      warnings.push({
        key: "identifier",
        message: (
          <span>
            Required <strong>IR reference</strong> is missing
          </span>
        ),
      });
    }

    if (typeof errors.samples === "string") {
      // when there are no samples
      warnings.push({
        key: "samples",
        message: errors.samples,
      });
      return warnings;
    }

    const samplesErrors = errors.samples || [];
    samplesErrors.forEach((error, index) => {
      if (!error) {
        return;
      } else {
        warnings.push({
          isGroupRow: true,
          message: `Sample at row ${index + 1}:`,
        });
      }

      Object.entries(error).forEach(([key, value]) => {
        if (key === "metadata") {
          warnings = warnings.concat(this.generateMetadataWarnings(value));
        } else if (key === "references") {
          warnings = warnings.concat(this.generateReferencesWarnings(value));
        } else if (key === "files") {
          warnings = warnings.concat(this.generateFilesWarnings(value));
        } else {
          warnings.push({
            message: (
              <span>
                Field <strong>{COLUMNS[key]}</strong>
                {` - `}
                {this.getAdjustedMessage(value)}
              </span>
            ),
          });
        }
      });
    });

    return warnings;
  };

  generateMetadataWarnings(metadata) {
    const warnings = [];
    Object.keys(metadata).forEach(metadataKey => {
      warnings.push({
        message: (
          <span>
            Metadata Field <b>{this.findMetadataLabel(metadataKey)}</b>
            {` - ${metadata[metadataKey]}`}
          </span>
        ),
      });
    });

    return warnings;
  }

  generateFilesWarnings(files) {
    const warnings = [];

    files.forEach(file => {
      Object.entries(file).forEach(([, value]) => {
        warnings.push({
          message: <span>{value}</span>,
        });
      });
    });

    return warnings;
  }

  generateReferencesWarnings(references) {
    const warnings = [];
    if (references && references.length > 0) {
      warnings.push({
        message: (
          <span>
            <strong>CNV Reference Panel </strong>contains invalid data
          </span>
        ),
      });
    }

    return warnings;
  }

  findMetadataLabel(metadataKey) {
    const { fields } = this.props;
    const field = fields.find(field => field.name === metadataKey);
    if (!field) {
      return "";
    }
    return field.label;
  }

  render() {
    const { show = false, closeModal, errors } = this.props;

    if (!show) {
      return null;
    }

    const warnings = this.generateWarnings(errors);

    return (
      <Modal show={show} close={closeModal}>
        <ModalHeader close={closeModal} className="text-warning">
          <h5>Warning</h5>
        </ModalHeader>
        <ModalBody>
          <ul className="list-group">
            {warnings.map((warning, index) => {
              const { message, isGroupRow } = warning;
              const classes = classNames("list-group-item", {
                "text-danger": isGroupRow,
              });
              const key = `modal-warning-${index}`;
              return (
                <li className={classes} key={key}>
                  {isGroupRow && <strong>{message}</strong>}
                  {!isGroupRow && message}
                </li>
              );
            })}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            context="primary"
            className="button"
            onClick={closeModal}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  fields: getMetadataFields(state),
});

export default connect(mapStateToProps, null)(WarningsModal);
