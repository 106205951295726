import { Button, Icon } from "pattern-library";

import { FAILED, REVIEW_COMPLETE } from "../constants";

import catalystApi from "api/catalyst-api";

const { useGetPatientStatusQuery } = catalystApi;

const DEFAULT = {
  context: "default",
};

const StatusAttributesMap = {
  [REVIEW_COMPLETE]: {
    context: "success",
  },
  [FAILED]: {
    context: "danger",
  },
};

const Status = ({ patientId }: any) => {
  const { status, isLoading } = useGetPatientStatusQuery(
    { patientId },
    {
      selectFromResult: ({ data: { status = "" } = {}, isLoading }) => ({
        status,
        isLoading,
      }),
    }
  );
  const { context } = StatusAttributesMap[status] ?? DEFAULT;
  return (
    <Button
      className="oncology-report__actions__button__status"
      context={context}
      disabled
    >
      {isLoading && <Icon type="spinner" />}
      {!isLoading && (
        <Icon type="infoSign" className="oncology-report__button__icon" />
      )}

      {status}
    </Button>
  );
};

export default Status;
