import { takeLatest } from "redux-saga/effects";

import * as constants from "../auth/constants";

import { setUser } from "./preferences-hook";

export function* init() {
  yield takeLatest(constants.USER_LOGGED_IN, userLoggedIn);
}

export function userLoggedIn(action) {
  setUser(action.payload.result.id);
}
