import PropTypes from "prop-types";
import React, { memo } from "react";
import { Field } from "redux-form";

import { ReduxFormField } from "modules/forms/components";

import {
  ADD_METADATA_CATEGORY,
  DELETE_METADATA_CATEGORY,
  EDIT_METADATA_CATEGORY,
} from "../constants";

const MetadataCategoryForm = memo(({ mode }) => (
  <div className="form-horizontal">
    <Field
      data-testid="name"
      label="Name *"
      disabled={mode === DELETE_METADATA_CATEGORY}
      component={ReduxFormField}
      type="text"
      name="name"
      narrow
    />
    <Field
      label="Visible?"
      className="patient_metadata-form-editor-toggle"
      disabled={mode === DELETE_METADATA_CATEGORY}
      data-testid="visible"
      component={ReduxFormField}
      type="toggle"
      name="visible"
      narrow
    />
  </div>
));

MetadataCategoryForm.propTypes = {
  mode: PropTypes.oneOf([
    ADD_METADATA_CATEGORY,
    EDIT_METADATA_CATEGORY,
    DELETE_METADATA_CATEGORY,
  ]),
};

export default MetadataCategoryForm;
