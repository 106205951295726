import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { PLVariantRow } from "pattern-library";

import * as configSelectors from "modules/config/selectors";

import { selectContextForVariantPanel } from "../actions";

import ColumnContainer from "./ColumnContainer";

export class VariantRow extends PureComponent {
  static propTypes = {
    gene: PropTypes.shape({
      geneId: PropTypes.number.isRequired,
    }).isRequired,
    variant: PropTypes.shape({
      variantId: PropTypes.number.isRequired,
      patientVariantId: PropTypes.number,
      defaultVisibleTranscript: PropTypes.shape({
        transcriptId: PropTypes.number.isRequired,
      }),
    }),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
      })
    ),
    patientId: PropTypes.number.isRequired,
    selectContextForVariantPanel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    columns: [],
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  loadVariant(e) {
    e.stopPropagation();

    const {
      gene: { name: geneName, geneId },
      variant: { variantId },
      patientId,
      selectContextForVariantPanel,
    } = this.props;

    if (!patientId || !geneId || !geneName || !variantId) {
      throw new Error("VariantRow insufficient data");
    }

    return selectContextForVariantPanel(patientId, geneId, geneName, variantId);
  }

  render() {
    const { columns = [], gene, variant, ...otherProps } = this.props;

    return (
      <PLVariantRow {...otherProps} onClick={this.loadVariant}>
        {columns.map(column => (
          <ColumnContainer
            key={`gene-${gene.geneId}-variant-${variant.variantId}-column-${column.key}-container`}
            gene={gene}
            variant={variant}
            column={column}
          />
        ))}
      </PLVariantRow>
    );
  }
}

const mapDispatchToProps = {
  selectContextForVariantPanel,
};

export default connect((state, props) => {
  const isCustomDataTabEnabled = configSelectors.isCustomDataTabEnabled(
    state,
    props
  );
  return {
    columns: configSelectors.getTableColumns(state, isCustomDataTabEnabled),
  };
}, mapDispatchToProps)(VariantRow);
