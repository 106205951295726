import React, { memo, useEffect, useState } from "react";

import { Alert, LoadingOverlay } from "pattern-library";

import GenePanelsFilterBar from "./GenePanelsFilterBar";
import GenePanelsTable from "./GenePanelsTable";
import GenePanelsTableContainer from "./GenePanelsTableContainer";

import { useEnsemblOptions } from "hooks/useEnsemblOptions";
import useProjectGenePanels from "hooks/useProjectGenePanels";

type Props = {
  selectedRowNumber: number;
  projectGenePanels: ProjectGenePanel[];
  disabled?: boolean;
  activeEnsemblVersion?: string;
  showWarning?: boolean;
};
export const GenePanelsForm: React.FC<Props> = memo(
  ({
    selectedRowNumber,
    projectGenePanels,
    disabled = false,
    activeEnsemblVersion,
    showWarning,
  }) => {
    const [genePanels, setGenePanels] =
      useState<ProjectGenePanel[]>(projectGenePanels);

    useEffect(() => {
      if (activeEnsemblVersion) {
        const filtered = projectGenePanels.filter(panel =>
          // Only perform gene panel filtering once the version property has been added to the gene panels response
          panel.ensemblVersion
            ? panel.ensemblVersion === activeEnsemblVersion
            : true
        );
        setGenePanels(filtered);
      }
    }, [activeEnsemblVersion, projectGenePanels]);

    return (
      <div>
        {showWarning && (
          <Alert severity="info">
            Only gene panels created in version {activeEnsemblVersion} of
            Ensembl (the active system version) can be used for newly processed
            patients
          </Alert>
        )}
        <div className="form-group row">
          <div className="col-md-3 col-md-offset-9">
            <GenePanelsFilterBar
              initialValues={projectGenePanels}
              setFilteredValues={setGenePanels}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-12">
            <GenePanelsTableContainer
              genePanels={genePanels}
              selectedRowNumber={selectedRowNumber}
            >
              {tableProps => (
                <GenePanelsTable {...tableProps} disabled={disabled} />
              )}
            </GenePanelsTableContainer>
          </div>
        </div>
      </div>
    );
  }
);

const ConnectedGenePanelsForm = (props: Props & ProjectDependant) => {
  const { genePanels, isLoading: genePanelsIsLoading } = useProjectGenePanels({
    projectId: Number(props.projectId),
  });

  const {
    activeVersion,
    ensemblOptions,
    isLoading: ensemblIsLoading,
  } = useEnsemblOptions();

  return (
    <LoadingOverlay loading={genePanelsIsLoading || ensemblIsLoading}>
      <GenePanelsForm
        {...props}
        projectGenePanels={genePanels}
        activeEnsemblVersion={activeVersion}
        showWarning={!ensemblIsLoading && ensemblOptions.length > 1}
      />
    </LoadingOverlay>
  );
};

export default ConnectedGenePanelsForm;
