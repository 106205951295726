import { isNil } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Actions,
  Download,
  Dropdown,
  FormGroup,
  Icon,
  Input,
  Label,
  Link,
  LoadingOverlay,
  Tooltip,
} from "pattern-library";
import { FormFieldDescription } from "pattern-library/modules/form-field/FormFieldDescription";

import { useDebounce } from "common/utils";
import {
  ACTION_FAIL_QC,
  ACTION_PASS_QC,
  ACTION_UNDO_QC,
  QC_CHECKED,
} from "modules/oncologyReport/constants";
import { formatDate, formatTime } from "modules/utils";
import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";
import { toDecimalPlaces } from "modules/utils/format";

import { ONCOLOGY_SECONDARY_PIPELINE_DETAILS } from "../constants";
import { OncologyAction, Rule } from "../types";
import { useOncologyReportData } from "../useOncologyQCData";
import {
  getGeneCoverageQcByThreshold,
  isOncologyQCDataOutOfRange,
} from "../utils";

import catalystApi from "api/catalyst-api";

const { useSetPatientStatusMutation } = catalystApi;

const DEBOUNCE_DELAY = 400;
const FULL_COVERAGE = 100;

interface Props {
  patientId: number;
  qcJsonFileName?: string;
}

const getRow = (
  row: { [key: string]: any },
  header: string,
  description: string,
  field: string,
  decimalPlaces: number,
  message?: string,
  rule?: Rule
): JSX.Element => {
  let warning: JSX.Element | null = null;
  let warningMessage: JSX.Element | null = null;
  if (rule && row[field] !== undefined) {
    if (!rule(Number(row[field]))) {
      warning = <Icon type="warning" />;
      warningMessage = (
        <span className="qc__oncology__json_table__warning">
          {message ? `\n${message}` : ""}
        </span>
      );
    }
  }
  return (
    <Tooltip
      key={field}
      content={
        <span>
          {description}
          {warningMessage}
        </span>
      }
      placement="right"
    >
      <tr>
        <th>{header}</th>
        <td>{toDecimalPlaces(row[field], decimalPlaces)}</td>
        <td>{warning}</td>
      </tr>
    </Tooltip>
  );
};

export const Oncology = ({ patientId }: Props) => {
  const [data, isDataLoading, isQCMetricsUnavailable] =
    useOncologyReportData(patientId);
  const {
    qc,
    patientStatus: { status, validActions = [] },
    significantStatusChanges,
    qcJsonFileName,
  } = data;

  const [setPatientStatus, { isLoading: isSetPatientStatusLoading }] =
    useSetPatientStatusMutation();

  const isLoading = useMemo(
    () => isDataLoading || isSetPatientStatusLoading,
    [isDataLoading, isSetPatientStatusLoading]
  );

  const [pctCovered, setPctCovered] = useState<number>(FULL_COVERAGE);
  const [geneCoverageQc, setGeneCoverageQc] = useState<
    Array<GeneCoverageQcGene>
  >([]);
  const debouncedPctCovered = useDebounce(pctCovered, DEBOUNCE_DELAY);

  const [coverageThreshold, setCoverageThreshold] = useState<number>();
  //this is object for 'Coverage threshold' dropdown options
  const [coverageThresholds, setCoverageThresholds] = useState<{
    [key: string]: number;
  }>({});

  const significantStatusInfo = useMemo(() => {
    if (status === QC_CHECKED && significantStatusChanges[QC_CHECKED]) {
      const { action, email, fullname, updated } = significantStatusChanges[
        QC_CHECKED
      ] as SignificantStatusChangeNode;
      const time: number = updated * 1000;
      return (
        <span
          data-testid="significant-status"
          className="qc__oncology__actions__significant-status"
        >
          QC {action === ACTION_PASS_QC ? "passed" : "failed"} by{" "}
          <a href={`mailto:${email}`}> {fullname}</a> at {formatTime(time)} on{" "}
          {formatDate(time)}
        </span>
      );
    }
    return "";
  }, [significantStatusChanges, status]);

  useEffect(() => {
    if (qc.geneCoverageQc) {
      setCoverageThresholds(
        qc.geneCoverageQc.reduce((acc, { coverageThreshold }) => {
          acc[coverageThreshold] = coverageThreshold;
          return acc;
        }, {})
      );
    }
  }, [qc.geneCoverageQc]);

  useEffect(() => {
    if (coverageThresholds.hasOwnProperty("100")) {
      setCoverageThreshold(100);
    } else {
      const values: Array<any> = Object.values(coverageThresholds);

      if (values.length > 0) {
        setCoverageThreshold(values[0]);
      }
    }
  }, [coverageThresholds]);

  useEffect(() => {
    if (qc.geneCoverageQc) {
      const geneCoverageQcByThreshold = getGeneCoverageQcByThreshold(
        debouncedPctCovered,
        qc.geneCoverageQc,
        coverageThreshold
      );
      setGeneCoverageQc(geneCoverageQcByThreshold);
    }
  }, [coverageThreshold, debouncedPctCovered, qc.geneCoverageQc]);

  const onPctCoveredChange = ({ target: { value } }) => {
    if (value >= 0 && value <= FULL_COVERAGE) setPctCovered(value);
  };

  const onCoverageThresholdChange = ({ target: { value } }) => {
    setCoverageThreshold(Number.parseInt(value));
  };

  const onChangeStatus = useCallback(
    (action: string) => setPatientStatus({ patientId, action }),
    [patientId, setPatientStatus]
  );

  useEffect(() => {
    if (!isNil(window.Sapientia))
      window.Sapientia.getContent("#patient-title-status");
  }, [status]);

  /**
   * GET /catalyst/api/patient/:patient_id/status will return a different list of valid_actions depending on the patient's QC status, for oncology projects only:
   *
   * If a patient is in status Ready for review, valid actions are
   * pass_qc -> status QC passed
   * fail_qc -> status QC failed
   * On reaching QC passed or QC failed, the patient immediately advances to QC checked.
   * This can be undone by the action undo_qc
   * If a patient is in status QC checked, the actions previously available for status Ready for review apply instead.
   * There will be no changes to valid actions for rare disease projects, which continue to be:
   *
   * fail_sample (which can be undone by undo)
   * review_complete (which can be undone by undo_review_complete)
   * There are three new patient statuses:
   *
   * QC passed
   * QC failed
   * QC checked
   */
  const ACTIONS = useMemo<Record<string, OncologyAction>>(
    () => ({
      [ACTION_FAIL_QC]: {
        label: "QC Fail",
        icon: "removeSign",
        disabled: isLoading,
        onClick: () => {
          onChangeStatus(ACTION_FAIL_QC);
        },
        context: "danger",
      },
      [ACTION_PASS_QC]: {
        label: "QC Pass",
        icon: "okSign",
        disabled: isLoading,
        onClick: () => {
          onChangeStatus(ACTION_PASS_QC);
        },
        context: "success",
      },
      [ACTION_UNDO_QC]: {
        icon: "repeat",
        label: "Undo",
        disabled: isLoading,
        onClick: () => {
          onChangeStatus(ACTION_UNDO_QC);
        },
        context: "default",
      },
    }),
    [isLoading, onChangeStatus]
  );

  const showWarning = useMemo<boolean>(
    () => isOncologyQCDataOutOfRange(qc, ONCOLOGY_SECONDARY_PIPELINE_DETAILS),
    [qc]
  );

  const oncologySecondaryPipelineRows = useMemo<Array<JSX.Element>>(
    () =>
      ONCOLOGY_SECONDARY_PIPELINE_DETAILS.map(
        ({ header, description, field, decimalPlaces = 0, rule, message }) =>
          getRow(qc, header, description, field, decimalPlaces, message, rule)
      ),
    [qc]
  );

  if (isQCMetricsUnavailable)
    return (
      <div data-testid="oncology-error-msg">
        No QC metrics available. Please wait for the pipeline to complete and
        then refresh this tab.
      </div>
    );

  return (
    <LoadingOverlay loading={isLoading}>
      <div className="qc__oncology container">
        <div className="row">
          <div className="col-md-6">
            <h1>Assay QC</h1>
            <table className="qc__oncology__json_table table table-striped">
              <tbody>{oncologySecondaryPipelineRows}</tbody>
            </table>
            {showWarning && (
              <div
                data-testid="qc__oncology__json_warning"
                className="qc__oncology__json_warning"
              >
                <Icon
                  className="qc__oncology__json_warning_icon"
                  type="warning"
                />
                QC metrics are outside recommended range. See the
                <Link
                  className="qc__oncology__json_table__warning__link"
                  target="_blank"
                  href="/user-guide/oncology/row/en-us/Default.htm#Oncology/5%20Reviewing%20patient%20data/Reviewing%20oncology%20QC%20data.htm%3FTocPath%3DReviewing%2520patient%2520data%7C_____2"
                >
                  user guide
                </Link>
              </div>
            )}
          </div>
          <div className="col-md-6">
            <h1>Genomic feature coverage QC</h1>
            <div>
              <FormGroup className="row">
                <Label htmlFor="coverageThreshold" className="col-sm-2">
                  Coverage threshold
                </Label>
                <div className="col-sm-3">
                  <Dropdown
                    id="coverageThreshold"
                    data-testid="coverageThreshold"
                    options={coverageThresholds}
                    value={coverageThreshold}
                    onChange={onCoverageThresholdChange}
                  />
                  <FormFieldDescription description="Coverage Threshold is the minimum depth of coverage required" />
                </div>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label htmlFor="threshold" className="col-sm-2">
                Percent Covered
              </Label>
              <div id="threshold" className="col-sm-3">
                <div className="input-group">
                  <span className="input-group-addon">≤</span>
                  <Input
                    type="number"
                    value={pctCovered}
                    onChange={onPctCoveredChange}
                  />
                </div>
                <FormFieldDescription description="The percentage of the genomic footprint that must be covered to at least the specified coverage threshold" />
              </div>
            </FormGroup>

            <h5 style={{ display: "flex", alignItems: "center" }}>
              <span>{`The following genes had less than ${pctCovered}% coverage at the specified depth`}</span>
            </h5>
            <table className="qc__oncology__json_table qc__oncology__json_table__fixed table table-striped">
              <thead>
                <tr>
                  <th>Gene</th>
                  <th>{`% covered${
                    coverageThreshold
                      ? ` to at least ${coverageThreshold}x`
                      : ""
                  }`}</th>
                </tr>
              </thead>
              <tbody>
                {geneCoverageQc.map(({ gene, coveredPct }) => (
                  <tr key={gene}>
                    <th>{gene}</th>
                    <td data-testid={`gene-${gene}`}>
                      {toDecimalPlaces(coveredPct, 1)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" />
        <div className="col-md-6">
          <div className="row">
            <small className="text-muted col-md-6">
              Note that deletions, particularly homozygous deletions and/or
              deletions in high cellularity samples, can cause low coverage.
            </small>
            {!isLoading && (
              <div className="col-md-6 qc__oncology__actions">
                <Download
                  className="qc__oncology__actions__qc_download"
                  url={`${API_ENTITIES_BASE_URL}/patients/${patientId}/oncology/qc_metrics/download`}
                  filename={qcJsonFileName}
                  caption="QC Metrics"
                  dataTestId="qc-metric-download"
                  context="default"
                  disabled={isLoading}
                />
                <Actions
                  actions={validActions.reduce((acc, action) => {
                    if (ACTIONS.hasOwnProperty(action)) {
                      acc.push(ACTIONS[action]);
                    }
                    return acc;
                  }, [] as OncologyAction[])}
                />
                {significantStatusInfo}
              </div>
            )}
            {isLoading && (
              <div className="col-md-offset-10">
                <Icon type="spinner" />
                Processing...
              </div>
            )}
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Oncology;
