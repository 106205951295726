import { createAction } from "../utils";

import * as constants from "./constants";

export const processLegacyDecisionValues = (
  decisionType,
  decisionVal,
  params
) =>
  createAction(constants.PROCESS_LEGACY_DECISION_VALUES, {
    decisionType,
    decisionVal,
    params,
  });

export const processLegacyStrDecisionValues = (
  decisionType,
  decisionVal,
  params
) =>
  createAction(constants.PROCESS_LEGACY_STR_DECISION_VALUES, {
    decisionType,
    decisionVal,
    params,
  });
