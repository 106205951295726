// @flow
import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import { actionTypes } from "./actions";
import { REPORT_TEMPLATES, SETTINGS, VARIANT_PANEL } from "./constants";

type PanelDef = Array<String>;

type State = {
  misc: {
    projectInheritors: string[],
  },
  REPORT_TEMPLATES: {
    ui: {
      loading: boolean,
      templatesLoading: { [string]: boolean },
      enabledTemplates: { [string]: boolean },
    },
    data: ?ReportTemplates,
  },
  SETTINGS: {
    ui: {
      loading: boolean,
    },
    data?: ProjectSettings,
  },
  VARIANT_PANEL: {
    ui: {
      loading: boolean,
    },
    data?: Array<PanelDef>,
  },
};

export const initialState: State = {
  misc: {
    projectInheritors: [],
  },
  [REPORT_TEMPLATES]: {
    ui: {
      loading: false,
      templatesLoading: {},
      enabledTemplates: {},
    },
    data: null,
  },
  [SETTINGS]: {
    ui: {
      loading: false,
    },
  },
  [VARIANT_PANEL]: {
    ui: {
      loading: false,
    },
    data: [[], []],
  },
};

const combineReducerFunctions =
  functions =>
  (state: State): State =>
    functions.reduce((state, f) => f(state), { ...state });

/**
 * General purpose
 */
const setLoading =
  (subModule: string, loading: boolean) =>
  (state: State): State =>
    set(state, `${subModule}.ui.loading`, loading);

/**
 * Report Templates:
 */
const setProjectReportTemplates =
  (data: Array<ReportTemplate>) =>
  (state: State): State =>
    set(state, `${REPORT_TEMPLATES}.data`, data);

// Turns array of projects into an object hash with the enabled state for
// each template, for the given project ID
const getReportTemplatesEnabledMap = (reportTemplates, projectId) =>
  reportTemplates.reduce((map, template) => {
    map[template.templateUuid] = Boolean(
      String(projectId) === String(template.projectId)
    );

    return map;
  }, {});

const setProjectReportTemplatesEnabled =
  (data: Array<ReportTemplate>, projectId) =>
  (state: State): State => {
    const enabledMap = getReportTemplatesEnabledMap(data, projectId);
    return set(state, `${REPORT_TEMPLATES}.ui.enabledTemplates`, enabledMap);
  };

// Set the loading state of a given report template
// i.e. is an async action for a particular template in progress
const setReportTemplateLoading =
  (templateUuid: string, loading: boolean) =>
  (state: State): State =>
    set(
      state,
      `${REPORT_TEMPLATES}.ui.templatesLoading.${templateUuid}`,
      loading
    );

const setReportTemplateEnabled =
  (templateUuid: string, enabled: boolean) =>
  (state: State): State =>
    set(
      state,
      `${REPORT_TEMPLATES}.ui.enabledTemplates.${templateUuid}`,
      enabled
    );

export default function reducer(
  state: State = initialState,
  { type, payload, meta }: Action
): State {
  switch (type) {
    case actionTypes.FETCH_REPORT_TEMPLATES_START:
      return combineReducerFunctions([
        setProjectReportTemplates([]),
        setLoading(REPORT_TEMPLATES, true),
      ])(state);

    case actionTypes.FETCH_REPORT_TEMPLATES_SUCCESS:
      return combineReducerFunctions([
        setProjectReportTemplates(payload),
        setProjectReportTemplatesEnabled(payload.list, meta.projectId),
        setLoading(REPORT_TEMPLATES, false),
      ])(state);

    case actionTypes.FETCH_REPORT_TEMPLATES_FAILURE:
      return combineReducerFunctions([
        setProjectReportTemplates([]),
        setLoading(REPORT_TEMPLATES, false),
      ])(state);

    case actionTypes.TOGGLE_TEMPLATE_START:
      return setReportTemplateLoading(payload.templateUuid, true)(state);

    case actionTypes.TOGGLE_TEMPLATE_SUCCESS:
      return combineReducerFunctions([
        setReportTemplateLoading(payload.templateUuid, false),
        setReportTemplateEnabled(payload.templateUuid, payload.enabled),
      ])(state);

    case actionTypes.TOGGLE_TEMPLATE_FAILURE:
      return setReportTemplateLoading(payload.templateUuid, false)(state);

    case actionTypes.SET_PROJECT_SETTINGS_LOADING:
      return set(state, `${SETTINGS}.ui.loading`, payload);

    case actionTypes.FETCH_PROJECT_SETTINGS_SUCCESS:
      return set(state, `${SETTINGS}.data`, payload);

    case actionTypes.FETCH_PROJECT_SETTINGS_FAILURE:
      return set(state, `${SETTINGS}.data`, null);

    case actionTypes.FETCH_VARIANT_PANEL_SUCCESS:
      return set(state, `${VARIANT_PANEL}.data`, payload.value);

    case actionTypes.SET_VARIANT_PANEL_LOADING:
      return set(state, `${VARIANT_PANEL}.ui.loading`, payload);

    case actionTypes.SET_VARIANT_PANEL_LOADING_ERROR:
      return set(state, `${VARIANT_PANEL}.ui.loadingError`, payload);

    case actionTypes.SET_PROJECT_HAS_INHERITORS:
      return set(state, "misc.projectInheritors", payload);

    default:
      return state;
  }
}
