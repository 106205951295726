import { decamelize } from "humps";

import { ARIADNE_KEYS } from "../ariadne/constants";
import { TRIO_INHERITANCE_FEATURE } from "../project/constants";

export function getPathogenicityClass(showPathogenicity, pathogenicity) {
  return !showPathogenicity || !pathogenicity
    ? "unknown"
    : decamelize(pathogenicity, { split: /\s/, separator: "-" });
}

export function normalizeCurrentFilter(currentFilter, options = {}) {
  const normalisedFilters = {};

  const isTrioInheritanceEnabled = () =>
    options.featureFlags && options.featureFlags[TRIO_INHERITANCE_FEATURE];

  const isMissingPutativeDeNovo = value =>
    Array.isArray(value) &&
    value.includes("de-novo-fp") &&
    !value.includes("putative de-novo");

  const isAriadneKey = key =>
    Object.values(ARIADNE_KEYS).map(decamelize).includes(key);

  Object.entries(currentFilter).forEach(([filter, value]) => {
    let normalised = decamelize(filter);

    if (
      (normalised === "gene_panels" && currentFilter.location !== "panels") ||
      (normalised === "genes" && currentFilter.location !== "genes") ||
      (normalised === "coords" && currentFilter.location !== "coords")
    ) {
      return;
    }

    if (normalised === "gene_panels") normalised = "gene_panel";

    // Adding "putative de-novo" filter value
    if (
      normalised === "inheritance" &&
      isTrioInheritanceEnabled() &&
      isMissingPutativeDeNovo(value)
    ) {
      normalisedFilters[normalised] = [...value, "putative de-novo"];
      return;
    }

    // Remove Ariadne keys
    if (!options.isAriadneEnabled && isAriadneKey(normalised)) {
      return;
    }

    normalisedFilters[normalised] = value;
  });

  const { max_ent_scan, rf, ada, splice_ai, cssf, ...restFilter } =
    normalisedFilters;

  if (max_ent_scan || rf || ada || splice_ai || cssf) {
    return {
      ...restFilter,
      splicing_scores: { max_ent_scan, rf, ada, splice_ai, cssf },
    };
  }

  return normalisedFilters;
}

export const getActiveVariantsByGenes = (
  genes: Array<{ geneId: number, variants: Array<{}> }>
) =>
  genes.reduce(
    (acc, { geneId, variants }) => ({
      ...acc,
      [geneId]: variants.map(({ variantId, patientVariantId }) => ({
        variantId,
        patientVariantId,
      })),
    }),
    {}
  );

export const toSnakeCase = word => word.toLowerCase().replace(/\s/g, "_");

const listSortFunction = order => (a, b) => {
  const aSnake = toSnakeCase(a);
  const bSnake = toSnakeCase(b);

  const aIndex = order.findIndex(item => aSnake === item);
  const bIndex = order.findIndex(item => bSnake === item);

  // when both values are not in the list they are sorted alphabetically
  if (aIndex === -1 && bIndex === -1) return aSnake.localeCompare(bSnake);

  // when one of values is found that value is prioritized to go first
  if (aIndex === -1 && bIndex !== -1) return 1;
  if (aIndex !== -1 && bIndex === -1) return -1;

  // when both values are found then they sorted by their index in the list
  return aIndex - bIndex;
};

export const pathogenicitySortFunction = listSortFunction([
  "pathogenic",
  "likely_pathogenic",
  "likely_benign",
  "benign",
]);

export const phenotypeContributionSortFunction = listSortFunction([
  "full",
  "partial",
  "uncertain",
  "none",
]);

export const getProjectArchivedGenePanelsMap = project => {
  const { genePanels = [] } = project || {};
  return genePanels.reduce((panelMap, panel) => {
    panelMap.set(panel.genePanelId, Boolean(panel.archived));
    return panelMap;
  }, new Map());
};
