import PropTypes from "prop-types";

export const itemShape = PropTypes.shape({
  /**
   * the identifier of an item
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * draggable item text
   */
  text: PropTypes.string.isRequired,
  /**
   * a category the item belongs to
   */
  category: PropTypes.shape({
    /**
     * the name of the category
     */
    name: PropTypes.string.isRequired,
    /**
     * the className applied to the category label
     */
    className: PropTypes.string,
  }),
});

export const dataShape = PropTypes.arrayOf(itemShape);

export const wellIdShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]).isRequired;

export const wellShape = PropTypes.shape({
  /**
   * the id of the well (list of draggable items)
   */
  wellId: wellIdShape,
  /**
   * an optional well title
   */
  title: PropTypes.string,
  /**
   * items with categories within a well
   */
  items: dataShape,
  /**
   * the className applied to the well container
   */
  className: PropTypes.string,

  /**
   * The columns count. This parameter are used for the bootstrap grid layout. The value mast be between 1 to 12
   */
  columns: PropTypes.number,
});

export const groupShape = PropTypes.shape({
  /**
   * an optional group title
   */
  title: PropTypes.string,
  /**
   * wells in a group
   */
  items: wellShape,
  /**
   * the className applied to the well container
   */
  className: PropTypes.string,

  /**
   * The columns count. This parameter are used for the bootstrap grid layout. The value mast be between 1 to 12
   */
  columns: PropTypes.number,
});

/**
 * check if the mouse position is over the node
 * @param node - the DOM node of the item
 * @param monitor - see http://react-dnd.github.io/react-dnd/docs/api/drop-target-monitor
 * @return {boolean}
 */
const isMouseOverNode = (node, monitor) => {
  // mouse position
  const { y: pageY } = monitor.getClientOffset();
  const { top, bottom } = node.getBoundingClientRect();

  const style = node.currentStyle || window.getComputedStyle(node);
  const marginBottom = parseFloat(style.marginBottom);
  const marginTop = parseFloat(style.marginTop);

  // X coordinates should not be used because of container X padding.
  // This function is used to handle well hover event which makes it enough to check only Y.
  // not including the lower edge where either next node or empty space after container begins
  return top - marginTop <= pageY && pageY < bottom + marginBottom;
};

const isMouseBeforeNode = (node, monitor) => {
  // Mouse vertical position
  const { y: mouseY } = monitor.getClientOffset();

  const { top: nodeUpperEdge } = node.getBoundingClientRect();
  // node top margin is not included in comparison.
  // If mouse Y position is less than its upper edge,
  // hover position will be at the top anyway
  return mouseY < nodeUpperEdge;
};

const isMouseAfterNode = (node, monitor) => {
  // Mouse vertical position
  const { y: mouseY } = monitor.getClientOffset();

  const { bottom: nodeLowerEdge } = node.getBoundingClientRect();
  // node bottom margin is not included in comparison.
  // if mouse Y position is equal or greater that its lower edge,
  // hover position will at the bottom of the list anyway
  // >= is because we don't include the lower edge in isMouseOverNode
  return mouseY >= nodeLowerEdge;
};

/**
 * find the position in the well the mouse hovers. If mouse is over a node also return that node
 * @param itemNodes - well items DOM nodes
 * @param monitor - see http://react-dnd.github.io/react-dnd/docs/api/drop-target-monitor
 * @param isItemInWell - the flag indicating whether the item is in the well data and in the DOM of the well element
 */
export const findHoverPosition = (itemNodes, monitor, isItemInWell) => {
  let hoverIndex = -1;
  let node = null;

  const itemsCountInWell = itemNodes.length;
  if (itemsCountInWell === 0) {
    // if the well is empty the item should be placed at index 0
    return { node: null, hoverIndex: 0 };
  }

  for (let i = 0; i < itemsCountInWell; i++) {
    const n = itemNodes[i];
    if (i === 0 && isMouseBeforeNode(n, monitor)) {
      // the mouse hovers before the first item
      hoverIndex = 0;
      break;
    }

    if (i === itemsCountInWell - 1 && isMouseAfterNode(n, monitor)) {
      // the mouse hovers after the last item
      // if the item if already in well its index will be equal to last position existing position,
      // otherwise - items number + 1
      hoverIndex = isItemInWell ? itemsCountInWell - 1 : itemsCountInWell;
      break;
    }

    if (isMouseOverNode(n, monitor)) {
      // hover node is found
      node = n;
      hoverIndex = i;
      break;
    }
  }
  return { node, hoverIndex };
};

/**
 * determine if item needs to be moved to a different position
 * @param node - item DOM node
 * @param monitor - see http://react-dnd.github.io/react-dnd/docs/api/drop-target-monitor
 * @param hoverIndex - target index to place item at, determined by mouse position
 * @param currentWellId - the id of the target well, where mouse is currently over
 * @return {boolean}
 */
export const needToMoveItem = (node, monitor, hoverIndex, currentWellId) => {
  if (!node) {
    // if no node is hovered, there is no need to check mouse position relative to the node as done below
    return false;
  }

  const { index: dragIndex, wellId: previousHoverWellId } = monitor.getItem();
  if (dragIndex === hoverIndex) {
    return false;
  }

  // Determine rectangle on screen
  const hoverBoundingRect = node.getBoundingClientRect();

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  // Mouse position
  const { y: mouseY } = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = mouseY - hoverBoundingRect.top;

  // Only perform the move when the mouse has crossed half of the items height.
  // When dragging downwards, only move when the cursor is below 50%.
  // When dragging upwards, only move when the cursor is above 50%.
  // The rule applies for adjacent dragIndex and hoverIndex (like 1 and 2, 5 and 4).
  // Exclusion: if the item last position in the original well was, for example,
  // 2, then it is dragged somewhere else and returns back at hover position 4 (that is, indices are not adjacent),
  // then the 50% threshold does not matter, we need to change the item position anyway
  const diff = Math.abs(dragIndex - hoverIndex);
  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && diff <= 1) {
    return false;
  }

  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && diff <= 1) {
    return false;
  }

  if (currentWellId !== previousHoverWellId) {
    // item is not in well, nothing to move
    return false;
  }

  return true;
};
