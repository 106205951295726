// @flow
import { react as autoBind } from "auto-bind";
import { isNil, not } from "ramda";

import EOF from "../EOF";
import ChunkedBgzipFileReader from "../bgzip/ChunkedBgzipFileReader";
import ChunkedTextFileReader from "../text/ChunkedTextFileReader";

// this is a convenience class to determine the type of a file
// and return you the content line by line as plain text
// all the heavy lifting for each file type should be defined in
// a chunked file reader class, which MUST provide a getNextChunk method
// chunkerOptions is an entirely optional argument passed to the ChunkedFileReader
export default class SimpleFileReader {
  file: File;
  _fileChunker: ChunkedBgzipFileReader | ChunkedTextFileReader;
  _lineCache: Array<string> = [];
  _buffer: string = "";

  constructor(file: File, chunkerOptions: { chunkSize?: number } = {}) {
    this.file = file;

    if (this.file.size === 0) {
      throw new Error("Empty file provided");
    }

    autoBind(this);

    // note that this will only support bgzip files NOT gzip!
    if (
      this.file.name.endsWith(".gz") ||
      this.file.name.endsWith(".bgz") ||
      this.file.name.endsWith(".bam")
    ) {
      this._fileChunker = new ChunkedBgzipFileReader(this.file);
    } else {
      // just assume text for now, validation of the file name/types
      // should be done before calling SimpleFileReader
      this._fileChunker = new ChunkedTextFileReader(this.file, chunkerOptions);
    }
  }

  eof(): boolean {
    return (
      this._fileChunker.eof() &&
      this._lineCache.length === 0 &&
      this._buffer.length === 0
    );
  }

  // fetch data from the file until we reach a \n or eof
  async parseSingleLine(chunk?: string, err?: string) {
    if (err) {
      throw new Error(err);
    }

    if (isNil(chunk)) throw new Error("chunk is not defined");

    this._buffer += chunk;
    const lines = this._buffer.split(/\r?\n/);

    // if we got any lines in the last read add them to the lineCache
    if (lines.length > 0) {
      // reset the buffer to be whatever is left at the end as it
      // may not have a \n
      this._buffer = lines.pop();

      this._lineCache.push(...lines);
    }

    // we need this inside the callback too for files that don't have
    // a single \n
    const cachedLine = this._nextCachedLine();
    if (cachedLine !== undefined) {
      return cachedLine;
    }

    // if we didn't find a line yet we need to recursively call getNextChunk
    // until lines is > 0 or we reach eof. the contents we just ran are in
    // buffer but not _lineCache so this method will be triggered again,
    // and we push the calling of the users callback function deeper down
    // the recursion stack.
    const returnedChunk: string = await this._getNextChunk();
    const returnedCachedLine: string | null = await this.parseSingleLine(
      returnedChunk
    );

    return returnedCachedLine;
  }

  // will provide the given callback with an empty EOF object when no lines are left
  async getNextLine() {
    // if we have a cached line then send it to the users callback
    // and return immediately
    const cachedLine = this._nextCachedLine();
    if (not(isNil(cachedLine))) {
      return cachedLine;
    }

    // If we have reached the end of the file end here
    if (this.eof()) {
      return new EOF();
    }
    // no lines left from the last read so fetch some more
    const chunk = await this._getNextChunk();
    const line = await this.parseSingleLine(chunk);

    return line;
  }

  // we read the files in chunks which may or may not have \n in,
  // so whenever we split a chunk by lines we keep any remaining
  // lines stored on the object in either _buffer or _lineCache:
  // this method deals with determining if we have any stored lines left,
  // and will return undefined if we do not
  _nextCachedLine() {
    // if we have some lines left from the last read return that first
    if (this._lineCache.length > 0) {
      return this._lineCache.shift();
    }

    if (this._fileChunker.eof()) {
      // we need to return anything left on the buffer
      if (this._buffer.length > 0) {
        const lastLine = this._buffer;
        this._buffer = ""; // next call that enters this block will raise eof
        return lastLine;
      }
    }

    // we have nothing cached left so a new read will be required
    return null;
  }

  // this is private because if you call it outside of getNextLine
  // it will break the stateful reading of the file
  _getNextChunk() {
    return this._fileChunker.getNextChunk();
  }
}
