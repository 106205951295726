import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Loading } from "pattern-library";

import { readVariantOld } from "data-layer/orm/variant/actions";

import ErrorBoundary from "../../../../ErrorBoundary";
import * as actions from "../../actions";
import * as selectors from "../../selectors";
import AddEvidenceModal from "../AddEvidenceModal";

import AcmgGridRow from "./Row";

const patientVarintDefault = {};

export class AcmgGrid extends PureComponent {
  static propTypes = {
    classificationTypes: PropTypes.array,
    categories: PropTypes.array,
    patientId: PropTypes.number,
    patientVariant: PropTypes.object,
    locked: PropTypes.bool,
    canEdit: PropTypes.bool,
    activeVariant: PropTypes.number.isRequired,
    updateCriteriaToAdd: PropTypes.func.isRequired,
    markAsNoEvidence: PropTypes.func.isRequired,
    readVariantOld: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { readVariantOld, patientId, activeVariant } = this.props;
    readVariantOld(patientId, activeVariant);
  }

  componentDidUpdate(prevProps) {
    const { readVariantOld, patientId, activeVariant } = this.props;
    if (
      prevProps.patientId !== patientId ||
      prevProps.activeVariant !== activeVariant
    ) {
      readVariantOld(patientId, activeVariant);
    }
  }

  render() {
    const {
      classificationTypes = [],
      categories = [],
      patientId,
      activeVariant,
      patientVariant: pv,
      locked,
      canEdit,
      updateCriteriaToAdd,
      markAsNoEvidence,
      disabled,
      hasSuggestedCriteriaPending = false,
    } = this.props;

    if (classificationTypes.length === 0 || categories.length === 0)
      return <Loading />;

    // a workaround for non-React unmount issue
    const patientVariant = pv || patientVarintDefault;

    return (
      <div>
        <table
          className={`${
            locked ? "locked" : ""
          } table table-striped table-bordered acmg-table`}
        >
          <thead>
            <tr>
              <th rowSpan="2">Criteria Category</th>
              {classificationTypes.map(classificationType => {
                const { id, name, strengths = [] } = classificationType;

                return (
                  <th
                    className="classification-type"
                    key={id}
                    colSpan={strengths.length}
                  >
                    {name}
                  </th>
                );
              })}
              <th rowSpan="2">No Evidence</th>
            </tr>
            <tr>
              {classificationTypes.map(classificationType =>
                classificationType.strengths.map(strength => (
                  <th
                    className="classification-type-strength"
                    key={`${classificationType.id}-${strength.id}`}
                  >
                    {strength.name}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {categories.map(category => (
              <AcmgGridRow
                key={category.id}
                updateCriteriaToAdd={updateCriteriaToAdd}
                classificationTypes={classificationTypes}
                category={category}
                locked={locked}
                markAsNoEvidence={markAsNoEvidence}
                criteria={category.criteria}
                patientId={patientId}
                variantId={activeVariant}
                patientVariant={patientVariant}
                canEdit={canEdit}
                disabled={disabled}
                hasSuggestedCriteriaPending={hasSuggestedCriteriaPending}
              />
            ))}
          </tbody>
        </table>
        <ErrorBoundary>
          <AddEvidenceModal />
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  classificationTypes: selectors.getPopulatedClassificationTypes(state),
  categories: selectors.getAllCategories(state),
  patientVariant: selectors.getPatientVariantDetails(state),
  locked: selectors.isLocked(state),
  canEdit: selectors.canEdit(state),
});

const mapDispatchToProps = {
  updateCriteriaToAdd: actions.updateCriteriaToAdd,
  markAsNoEvidence: actions.markAsNoEvidence,
  readVariantOld,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcmgGrid);
