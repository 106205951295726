import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Table } from "pattern-library/elements/table7";

import { arrayMove } from "modules/utils/array";

export const DraggableTable = memo(
  ({ data = [], getRowId, onDropRow, getRowProps, ...otherTableProps }) => {
    const [records, setRecords] = useState(data);
    useEffect(() => {
      setRecords(data);
    }, [data]);

    const getDraggableRowProps = useCallback(() => {
      let rowProps = {};
      if (getRowProps) {
        rowProps = getRowProps();
      }

      return {
        ...rowProps,
        style: {
          cursor: "pointer",
        },
      };
    }, [getRowProps]);

    const moveRow = useCallback(
      (dragIndex, hoverIndex) => {
        setRecords(arrayMove(records, dragIndex, hoverIndex));
      },
      [setRecords, records]
    );

    const onDropRowHandler = useCallback(
      ({ index }) => onDropRow(records[index], index),
      [onDropRow, records]
    );

    return (
      <DndProvider backend={HTML5Backend}>
        <Table
          {...otherTableProps}
          data={records}
          getRowProps={getDraggableRowProps}
          moveRow={moveRow}
          getRowId={getRowId}
          onDropRow={onDropRowHandler}
        />
      </DndProvider>
    );
  }
);

DraggableTable.propTypes = {
  data: PropTypes.array,
  getRowProps: PropTypes.func,
  onDropRow: PropTypes.func.isRequired,
  getRowId: PropTypes.func.isRequired,
};
