import { groupBy } from "ramda";
import { connect } from "react-redux";

import { ConfigSection, Divider, Loading } from "pattern-library";

import {
  EXAC_LEGACY_VERSION,
  GNOMAD_LEGACY_VERSION,
} from "modules/config/constants";
import { getAssemblyConfig } from "modules/systemConfig/selectors";
import { GenomeAssembly, GenomeAssemblyType } from "modules/systemConfig/types";

import * as selectors from "../../selectors";

type PopulationItem = {
  key: string;
  label: string;
  comparator: string;
  group?: string;
  prefix: string;
  placeholder: string;
  validate: any;
  normalize: any;
};

type Props = {
  patientId?: number;
  genome: GenomeAssemblyType;
  disabled: boolean;
  patientGnomadAnnotationData?: AnnotationSource;
  populationAf: PopulationItem[];
  isPatientEntityLoading: boolean;
};

const getGroupedPopulations = groupBy(
  (population: PopulationItem) => population.group || "other"
);

const getSections = (
  populationAf: PopulationItem[],
  genome: GenomeAssemblyType,
  patientId?: number,
  patientGnomadAnnotationData?: AnnotationSource
) => {
  const groupedPopulations = getGroupedPopulations(populationAf);

  const otherSection = {
    header: "Population frequencies",
    key: "common",
    showDividers: false,
    controls: groupedPopulations.other || [],
  };

  const gnomadSection = {
    showDividers: false,
    header: "gnomAD Exomes Allele Frequencies",
    key: "gnomad",
    subtitle:
      patientId && patientGnomadAnnotationData
        ? `v${patientGnomadAnnotationData.value}`
        : undefined,
    information:
      //  Shows only for Filter Presets
      !patientId
        ? {
            description:
              "Please note, the filters defined here will apply to the version of gnomAD that the patient was processed with.",
          }
        : undefined,
    controls: groupedPopulations.gnomad || [],
  };

  const exacSection = {
    showDividers: false,
    header: "gnomAD Exomes Allele Frequencies (legacy) ",
    key: "gnomad-legacy",
    subtitle: GNOMAD_LEGACY_VERSION,
    information:
      //  Shows only for Filter Presets
      !patientId
        ? {
            description:
              "Please note, the filters defined here will only apply to patients with legacy gnomAD data from the time of processing.",
          }
        : undefined,
    controls: groupedPopulations.exac || [],
  };

  if (genome === GenomeAssembly.GRCH37) {
    exacSection.header = "ExAC Allele Frequencies";
    exacSection.subtitle = EXAC_LEGACY_VERSION;

    //  Shows only for Filter Presets
    if (!patientId && exacSection.information)
      exacSection.information.description =
        "Please note, the filters defined here will only apply to patients with legacy ExAC data from the time of processing.";
  }
  return [otherSection, gnomadSection, exacSection];
};

export const PopulationFrequencyForm: React.FC<Props> = ({
  patientId,
  disabled = false,
  populationAf,
  genome,
  patientGnomadAnnotationData,
  isPatientEntityLoading,
}: Props) => {
  let sections: ReturnType<typeof getSections> = [];

  if (populationAf.length) {
    sections = getSections(
      populationAf,
      genome,
      patientId,
      patientGnomadAnnotationData
    );
  }

  if (patientId && isPatientEntityLoading) {
    return <Loading />;
  }

  return (
    <>
      {sections.reduce((reducedSections, sectionConfig, index) => {
        if (!sectionConfig.controls.length) return reducedSections;

        if (index !== 0) {
          reducedSections.push(
            <Divider key={`divider-${sectionConfig.key}`} />
          );
        }

        reducedSections.push(
          <ConfigSection {...sectionConfig} disabled={disabled} />
        );

        return reducedSections;
      }, [] as Array<JSX.Element>)}
    </>
  );
};

const mapStateToProps = (state, { patientId }) => ({
  genome: getAssemblyConfig(state),
  populationAf: selectors.getConfigFormPopulationAf(state, patientId),
  isPatientEntityLoading: selectors.isPatientEntityLoading(state),
  patientGnomadAnnotationData: selectors.getSinglePatientAnnotationSource(
    state,
    "GNOMAD_EXOMES"
  ),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopulationFrequencyForm);
