import { decamelizeKeys } from "humps";
import { isEmpty, isNil } from "ramda";
import React, { useCallback } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import {
  Modal,
  ModalBody,
  ModalFooter,
  PrimaryModalHeader,
  Button,
  OmimLinks,
  OmimLinksDividers,
} from "pattern-library";

import { ExternalUrl } from "modules/utils/externalUrls";

import { EDIT_GENE_IN_GENE_PANEL_FORM_NAME } from "../constants";
import { Gene } from "../types";

export interface Props {
  projectId: number;
  genePanelId: number;
  geneName: string;
  updateGene: (
    projectId: number,
    genePanelId: number,
    geneName: string,
    decamelizeKeys: unknown
  ) => void;
  assembly: string;
  hideModal: () => void;
  gene: Gene;
  columns: Array<string>;
  fields: {
    [FieldName: string]: any;
  };
  searchGenes?: (
    projectId: number,
    genePanelId: number,
    addGeneSearchQuery: string
  ) => void;
  addGeneSearchQuery?: string;
}
export const EditGeneForm = ({
  projectId,
  genePanelId,
  geneName,
  hideModal,
  gene,
  fields,
  columns,
  assembly,
  handleSubmit,
  updateGene,
  searchGenes,
  addGeneSearchQuery,
}: Props & InjectedFormProps) => {
  const onCloseHandler = useCallback(() => {
    hideModal();
  }, [hideModal]);

  const onFormSubmit = useCallback(
    data => {
      updateGene(projectId, genePanelId, geneName, decamelizeKeys(data));
      if (searchGenes && !!addGeneSearchQuery) {
        searchGenes(projectId, genePanelId, addGeneSearchQuery);
      }
      onCloseHandler();
    },
    [
      projectId,
      genePanelId,
      geneName,
      updateGene,
      onCloseHandler,
      searchGenes,
      addGeneSearchQuery,
    ]
  );

  const getDescription = useCallback(() => {
    if (!assembly || !gene) return null;
    const descriptions: Array<string | JSX.Element> = [];
    const { omimId, morbidId, ensemblId, refseqId, uniprotId } = gene;

    const descriptionParts = [
      {
        id: omimId,
        component: (
          <OmimLinks
            key="omim"
            ids={omimId}
            divider={OmimLinksDividers.COMMA}
            ending="(OMIM)"
          />
        ),
      },
      {
        id: morbidId,
        component: (
          <OmimLinks
            key="morbid"
            ids={morbidId}
            divider={OmimLinksDividers.COMMA}
            ending="(MORBID)"
          />
        ),
      },
      { id: ensemblId, externalKey: "gene_summary.ensembl" },
      { id: refseqId, externalKey: "gene_summary.refseq" },
      { id: uniprotId, externalKey: "uniprot", ending: "(UNIPROT)" },
    ];

    descriptionParts
      .filter(({ id }) => !isNil(id) && !isEmpty(id))
      .forEach(({ id, externalKey, ending, component }) => {
        if (descriptions.length > 0) {
          descriptions.push(", ");
        }
        descriptions.push(
          component ? (
            component
          ) : (
            <a
              key={id}
              href={`${ExternalUrl(externalKey, assembly)}${id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {id}
            </a>
          )
        );
        if (ending) descriptions.push(ending);
      });

    return descriptions;
  }, [gene, assembly]);

  const getAdditionalFields = useCallback(() => {
    const inputFields: Array<JSX.Element> = [];
    columns.forEach(column => {
      const field = fields[column];
      if (field && field.input && field.optional !== "No") {
        const { input, dataType, size, options, isNumeric: _, ...rest } = field;

        const inputProps = {
          component: dataType === "boolean" ? "input" : input,
          type: dataType === "boolean" ? "checkbox" : undefined,
          maxLength: size ? size : undefined,
          children: options
            ? options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))
            : undefined,
          ...rest,
        };
        inputProps.name = column;
        inputProps.className =
          dataType !== "boolean" ? "form-control" : undefined;
        inputFields.push(
          <tr key={column}>
            <td>
              {field.label || column}
              {field.required && "*"}
            </td>
            <td>
              <Field {...inputProps} />
            </td>
          </tr>
        );
      }
    });

    return inputFields;
  }, [fields, columns]);

  return gene ? (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Modal show close={onCloseHandler}>
        <PrimaryModalHeader close={onCloseHandler}>
          Edit gene: {gene.name}
        </PrimaryModalHeader>
        <ModalBody>
          <table className="table table-striped table-hover">
            <tbody>
              <tr>
                <td className="col-md-6">Gene name</td>
                <td className="col-md-6">{gene.name}</td>
              </tr>
              {(gene.synonyms || gene.old_name) && (
                <tr>
                  <td className="col-xs-6">Other names</td>
                  <td className="col-xs-6">
                    {gene.old_name}
                    {gene.synonyms && gene.old_name && `, ${gene.synonyms}`}
                  </td>
                </tr>
              )}
              {(gene.chr || gene.start || gene.end) && (
                <tr>
                  <td className="col-xs-6">Location</td>
                  <td className="col-xs-6">
                    {`${gene.chr}:${gene.start}-${gene.end}`}
                  </td>
                </tr>
              )}
              <tr>
                <td className="col-md-6">Description</td>
                <td className="col-md-6">{getDescription()}</td>
              </tr>
              {getAdditionalFields()}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseHandler}>Close</Button>
          <Button context="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </form>
  ) : null;
};

export default reduxForm<Index, Props>({
  form: EDIT_GENE_IN_GENE_PANEL_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditGeneForm);
