import { fetchCamelizeData } from "modules/utils";
import { CATALYST_BASE_URL } from "modules/utils/baseUrls";

export const getPatientOverlaps = async (patientId, patientSvId) => {
  const result: Object = await fetchCamelizeData(
    `${CATALYST_BASE_URL}/patient/${patientId}/variants/sv/${patientSvId}/other-patients`
  );
  if (result.ok) {
    return {
      ...result,
      payload: (result.payload || []).reduce(
        (
          acc,
          { name, affectedStatus, hpoTerms, projectCode, patientId, svsCnvs }
        ) => {
          svsCnvs.forEach(sv => {
            const { patientVariantId, variantId, ...additionalSvProperties } =
              sv;

            // Add coordinates so that the overlap table can provide a
            // basic text match search for client side filtering
            // This is not meant to provide any advanced searching as is seen in the main SNV table, etc
            const coordinates = `chr${sv.chr}:${sv.start}-${sv.end}`;
            const item = {
              patientId,
              name,
              affectedStatus,
              hpoTerms,
              projectCode,
              coordinates,
              ...additionalSvProperties,
            };
            acc.push(item);
          });
          return acc;
        },
        []
      ),
    };
  }
  return result;
};
