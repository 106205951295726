import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class LoadingComponent extends PureComponent {
  static displayName = "Loading";

  static propTypes = {
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
    /**
     * Content to render inside the component
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, loading, loadingText, ...rest } = this.props;
    return (
      <div
        style={{ display: loading ? "block" : "none" }}
        className={classNames(
          "table-loading",
          { "table-active": loading },
          className
        )}
        {...rest}
      >
        <div className="loading">{loadingText}</div>
      </div>
    );
  }
}
