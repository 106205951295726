import React, { useState, useCallback, memo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button } from "pattern-library";

import { updateGenePanelInfo } from "../../../actions";
import { GENE_PANEL_OVERVIEW_FORM_NAME } from "../../../constants";
import { getGenePanelInfo, getGenePanelInfoFormData } from "../../../selectors";
import EditNotification from "../EditNotification";

interface ActionButtonsProps {
  canEdit: boolean;
  editing: boolean;
  setEditing: (editing: boolean) => void;
}
const ActionButtons = ({
  canEdit = false,
  editing = false,
  setEditing,
}: ActionButtonsProps) => {
  if (!canEdit) {
    return null;
  }

  const saveButtons = (
    <>
      <Button
        type="button"
        onClick={() => {
          setEditing(false);
        }}
      >
        Cancel
      </Button>
      <Button context="primary" type="submit">
        Save Overview
      </Button>
    </>
  );

  const editButtons = (
    <Button
      context="primary"
      type="button"
      onClick={() => {
        setEditing(true);
      }}
    >
      Edit overview
    </Button>
  );

  const activeButtons = editing ? saveButtons : editButtons;

  return <div className="pull-right">{activeButtons}</div>;
};

interface Props extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
  canEdit: boolean;
}
const OverviewTab = memo(
  ({
    handleSubmit,
    genePanelInfo,
    projectId,
    genePanelId,
    canEdit,
    updateGenePanelInfo,
  }: Props & InjectedFormProps<Index, Props>) => {
    const [editing, setEditing] = useState(false);

    const onFormSubmit = useCallback(
      ({ title, description }) => {
        setEditing(false);
        updateGenePanelInfo(projectId, genePanelId, { title, description });
      },
      [setEditing, projectId, genePanelId, updateGenePanelInfo]
    );

    if (!genePanelInfo) return null;

    return (
      <>
        {!canEdit && (
          <EditNotification projectId={projectId} genePanelId={genePanelId} />
        )}
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div id="gene-panel-overview-info">
            <table className="table table-striped table-hover">
              <tbody>
                <tr>
                  <td className="col-md-6">Gene panel</td>
                  <td className="col-md-6">
                    {editing ? (
                      <Field
                        name="title"
                        className="form-control"
                        type="text"
                        component="input"
                      />
                    ) : (
                      genePanelInfo.title
                    )}
                  </td>
                </tr>

                <tr>
                  <td className="col-md-6">Description</td>
                  <td className="col-md-6">
                    {editing ? (
                      <Field
                        name="description"
                        className="form-control"
                        component="textarea"
                      />
                    ) : (
                      genePanelInfo.description
                    )}
                  </td>
                </tr>
                {!editing ? (
                  <tr>
                    <td className="col-md-6">Total genes</td>
                    <td className="col-md-6">{genePanelInfo.genesCount}</td>
                  </tr>
                ) : null}
                {!editing && genePanelInfo.ensemblVersion && (
                  <tr>
                    <td className="col-md-6">Ensembl version</td>
                    <td className="col-md-6">{genePanelInfo.ensemblVersion}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <ActionButtons
              canEdit={canEdit}
              editing={editing}
              setEditing={setEditing}
            />
            <div className="clearfix" />
          </div>
        </form>
      </>
    );
  }
);

const mapStateToProps = state => ({
  genePanelInfo: getGenePanelInfo(state),
  initialValues: getGenePanelInfoFormData(state),
});

const mapDispatchToProps = {
  updateGenePanelInfo: updateGenePanelInfo.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  reduxForm<Index, Props>({
    form: GENE_PANEL_OVERVIEW_FORM_NAME,
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(OverviewTab)
);
