import { groupBy } from "ramda";
import React, { Fragment, memo } from "react";

import { Icon } from "pattern-library";

import { IrSample, IrFile } from "../types";
import { getIRExpectedFiles } from "../utils";

import { ON_PREM_MESSAGES } from "./componentConstants";

const expectedFilesMessages = [
  ON_PREM_MESSAGES.SPECIFIED_FILES_PRESENT,
  ON_PREM_MESSAGES.VALID_FILE_COMBINATIONS,
  ON_PREM_MESSAGES.ACCEPTED_FILE_TYPES,
];

interface OnPremFileChecklistProps {
  samples?: IrSample[];
  rootFolder?: string;
  isModal?: boolean;
}

const groupBySampleId = groupBy(({ sampleId }) => sampleId);

const groupedByNamedAndUnnamed = groupBy(({ sampleId }) => {
  if (sampleId) {
    return "files";
  } else {
    return "otherFiles";
  }
});
const groupSamplesByName = samples => {
  const grouped = groupedByNamedAndUnnamed(samples);
  return {
    otherFiles: grouped.otherFiles || [],
    files: groupBySampleId(grouped.files || []) || {},
  };
};

const ExpectedIrFile = ({ fileName }: IrFile) => (
  <div className="file-entry" data-testid="expected-ir-file">
    <Icon type="file" />
    <span className="file-entry-name">{fileName}</span>
  </div>
);

const OnPremFileChecklist = ({
  samples = [],
  rootFolder,
  isModal = false,
}: OnPremFileChecklistProps) => {
  const expectedFiles = samples.reduce(
    (accumulator: IrFile[], { fileTypes = {}, name }, index) => {
      const fallbackName = name || `{sample ${index + 1}}`;
      const filesForSample: IrFile[] = getIRExpectedFiles(
        { fileTypes, name: fallbackName },
        true,
        rootFolder,
        { addFileNames: true, addIndexFiles: true }
      );

      const filesWithUiMetadata = filesForSample.map(file => ({
        ...file,
        sampleId: name,
        test: 1,
      }));

      return accumulator.concat(filesWithUiMetadata);
    },
    []
  );
  const groupedExpectedFiles: {
    files: Record<string, IrFile[]>;
    otherFiles: IrFile[];
  } = groupSamplesByName(expectedFiles);

  const groupedSampleFiles = Object.keys(groupedExpectedFiles.files).map(
    sample => (
      <Fragment key={sample}>
        <h5>Files for {sample}:</h5>
        <div>
          {groupedExpectedFiles.files[sample].map((file: IrFile, ind) => {
            const key = `${file.fileName}-${ind}`;
            return <ExpectedIrFile {...file} key={key} />;
          })}
        </div>
      </Fragment>
    )
  );
  const otherFiles = groupedExpectedFiles.otherFiles.map((file, ind) => {
    const key = `${file.fileName}-${ind}`;
    return <ExpectedIrFile {...file} key={key} />;
  });

  const anyFilesAdded = groupedSampleFiles.length > 0 || otherFiles.length > 0;

  const header = (
    <div className="file-checklist-header">
      <h4>Expected data files</h4>
      <h5>
        The below files must all be located directly within the root of the
        pipeline source folder, on the mounted local data storage.
      </h5>
    </div>
  );

  const footer = (
    <div className="footer-note text-danger">
      {expectedFilesMessages.map(msg => (
        <Fragment key={msg}>
          <span>
            <Icon type="infoCircle" />
          </span>
          <span>{msg}</span>
        </Fragment>
      ))}
    </div>
  );

  return (
    <div className="file-checklist-container">
      <div className="file-checklist">
        {!isModal && header}
        {!anyFilesAdded && (
          <div className="no-files-message">
            <div className="message">
              <strong>No files currently expected</strong>
              <br />
              Please add samples to the interpretation request and select file
              types
            </div>
          </div>
        )}
        {groupedSampleFiles}
        {otherFiles.length > 0 && (
          <>
            <h5>Sample files without IDs:</h5>
            {otherFiles}
          </>
        )}
      </div>
      {!isModal && footer}
    </div>
  );
};

export default memo(OnPremFileChecklist);
