import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import LegacySapientiaTemplate from "modules/legacy/LegacySapientiaTemplate";
import { appendStrings } from "modules/utils";

import { resetActiveGene } from "../actions";
import * as selectors from "../selectors";

export class VariantPanel extends PureComponent {
  static propTypes = {
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    resetActiveGene: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      unselected: false,
    };
  }

  componentWillUnmount() {
    const { resetActiveGene } = this.props;
    const { unselected } = this.state;
    if (unselected === false) {
      resetActiveGene();
    }
  }

  unselectGene(e) {
    const { resetActiveGene } = this.props;
    e.stopPropagation();
    resetActiveGene();
    this.setState({
      unselected: true,
    });
  }

  render() {
    const {
      patientVariantId,
      patientId,
      activeGene: { name } = {},
      activeTranscriptId,
    } = this.props;

    const templateUrl = appendStrings(
      `/patient/${patientId}/variants`,
      [
        [`/gene/${name}`, name],
        [`/transcript/${activeTranscriptId}`, activeTranscriptId],
        [`/snv/${patientVariantId}`, patientVariantId],
      ],
      "/tabs"
    );

    const path = appendStrings(`/patient/${patientId}/variants/sequence`, [
      [`/gene/${name}`, name],
      [`/transcript/${activeTranscriptId}`, activeTranscriptId],
      [`/variants/${patientVariantId}`, patientVariantId],
    ]);

    return (
      <div>
        <button
          className="btn btn-link"
          type="button"
          onClick={this.unselectGene}
        >
          <span className="caret false" />
          {" Expand table"}
        </button>
        <LegacySapientiaTemplate
          templateUrl={templateUrl}
          path={path}
          hash=""
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeTranscriptId: selectors.activeVariantPanelTranscript(state),
  patientVariantId: selectors.activeVariantPanelPatientVariant(state),
  activeGene: selectors.getSelectedGene(state),
});

const mapDispatchToProps = {
  resetActiveGene,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantPanel);
