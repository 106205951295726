// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
import {
  AWSFileDataOutput,
  AWSPart,
  AWSUploadedPart,
  S3UploaderProps,
} from "../../types";
import { BaseUploader } from "../BaseUploader";

import { Api } from "./api";

export class Uploader extends BaseUploader {
  private readonly chunkSize: number;
  // number of parallel uploads
  private readonly threadsQuantity: number;
  private aborted: boolean;
  private uploadedSize: number;
  private readonly progressCache: any;
  private readonly activeConnections: any;
  private readonly parts: Array<AWSPart>;
  private readonly uploadedParts: Array<AWSUploadedPart>;
  private fileId?: string | null;
  private fileKey?: string | null;
  private readonly api: Api;

  constructor({
    file,
    chunkSize,
    threadsQuantity,
    onStart,
    onProgress,
    onError,
    onSuccess,
    initializeMultipartUploadUrl,
    getMultipartPreSignedUrlsUrl,
    finalizeMultipartUploadUrl,
  }: S3UploaderProps) {
    super({ file, onStart, onProgress, onError, onSuccess });
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = chunkSize || 1024 * 1024 * 5;
    // number of parallel uploads
    this.threadsQuantity = Math.min(threadsQuantity || 5, 15);
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.api = new Api(
      initializeMultipartUploadUrl,
      getMultipartPreSignedUrlsUrl,
      finalizeMultipartUploadUrl
    );
  }

  start() {
    this.onStart();
    return this.initialize();
  }

  private async initialize() {
    try {
      // initializing the multipart request
      const initializationUploadInput = {
        name: this.file.name,
      };
      const awsFileDataOutput: AWSFileDataOutput = this.checkResponse(
        await this.api.initializeMultipartUpload(initializationUploadInput),
        "initialize multipart upload"
      );

      this.fileId = awsFileDataOutput.fileId;
      this.fileKey = awsFileDataOutput.fileKey;

      // retrieving the pre-signed URLs
      const numberOfParts = Math.ceil(this.file.size / this.chunkSize);

      const awsMultipartFileDataInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: numberOfParts,
      };

      const { parts = [] } = this.checkResponse(
        await this.api.getMultipartPreSignedUrls(awsMultipartFileDataInput),
        "get multipart pre-signed urls"
      );
      this.parts.push(...parts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  private sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }
      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch(error => {
          this.parts.push(part);
          return this.complete(error);
        });
    }
  }

  private async complete(error?: Error) {
    if (error) {
      this.onError(error);
    } else {
      try {
        await this.sendCompleteRequest();
      } catch (error) {
        this.onError(error);
      }
    }
  }

  private async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const finalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      try {
        this.checkResponse(
          await this.api.finalizeMultipartUpload(finalizationMultiPartInput),
          "finalize multipart upload"
        );
        this.onSuccess();
      } catch (error) {
        this.onError(error);
      }
    }
  }

  private sendChunk(chunk, part, sendChunkStarted) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then(status => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  private handleProgress(part, event) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((acc, id) => {
          acc += this.progressCache[id];
          return acc;
        }, 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgress({
        sent,
        total,
        percentage,
      });
    }
  }

  private upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const partNumber = part.PartNumber - 1;

        this.activeConnections[partNumber] = new XMLHttpRequest();
        const xhr = this.activeConnections[partNumber];

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, partNumber);

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const eTag: string = xhr.getResponseHeader("ETag");

            if (eTag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                //we have not replaceAll function in tests
                ETag: eTag.replaceAll ? eTag.replaceAll('"', "") : eTag,
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[partNumber];
            }
          }
        };

        xhr.onerror = error => {
          reject(error);
          delete this.activeConnections[partNumber];
        };

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"));
          delete this.activeConnections[partNumber];
        };

        xhr.send(file);
      }
    });
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach(id => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }

  private checkResponse(response, action = "upload file") {
    const { ok, payload } = response;
    if (ok) {
      return payload;
    } else {
      throw new Error(
        `Cannot ${action}${
          response.statusText ? "\nDetails: " + response.statusText : ""
        }`
      );
    }
  }
}
