import { bindActionCreators } from "@reduxjs/toolkit";
import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Icon, Loading, IconLoading, Tooltip } from "pattern-library";

import { readVariantOld } from "data-layer/orm/variant/actions";
import * as variantActions from "modules/variants/actions";

import * as variantSelectors from "../../variants/selectors";
import * as actions from "../actions";
import { readAcmgCriteriaList } from "../actions";
import * as selectors from "../selectors";
import {
  hasSuggestedCriteria,
  isAcmgCriteriaEditDisabledDueToSuggestions,
} from "../selectors";
import { formatCriteriaName, sortByCriteriaData } from "../utils";

import AcmgDecisionModal from "./AcmgDecisionModal";
import AcmgGrid from "./Grid/Grid";

export class AcmgClassification extends PureComponent {
  static patientVariantDefault = {};

  constructor(props) {
    super(props);

    autoBind(this);
  }

  componentDidMount() {
    const {
      allCriteria,
      setActiveVariantPanelValues,
      geneName,
      geneId,
      variantId,
      patientId,
      patientVariantId,
      transcriptId,
      readVariantOld,
      readAcmgClassificationList,
      readAcmgCriteriaList,
    } = this.props;
    readVariantOld(patientId, variantId);
    readAcmgClassificationList();
    if (!allCriteria || !allCriteria.length) {
      readAcmgCriteriaList();
    }

    if (
      geneName &&
      geneId &&
      variantId &&
      patientId &&
      patientVariantId &&
      transcriptId
    ) {
      setActiveVariantPanelValues(
        geneName,
        geneId,
        variantId,
        patientVariantId,
        transcriptId
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { readVariantOld, patientId, variantId, locked } = this.props;
    if (
      patientId !== prevProps.patientId ||
      variantId !== prevProps.variantId
    ) {
      readVariantOld(patientId, variantId);
    }

    if (locked !== prevProps.locked) {
      window.Sapientia.getContent(".variant_decision");
    }
  }

  handleOutsideClick() {
    const { overlayActive, toggleOverlay } = this.props;
    if (overlayActive) toggleOverlay();
  }

  openAcmgGuidelinesTab() {
    const { geneName, patientVariantId } = this.props;
    // The reason of doing that in non-react way is that we have to interact with perl template here
    // and it is the easiest way to do it since LegacySapientiaTemplate doesn't support hash change at this moment
    const acmgGuidelinesTab = document.querySelector(
      `[data-target="#acmg-guidelines-${geneName}-${patientVariantId}"]`
    );
    if (acmgGuidelinesTab) {
      acmgGuidelinesTab.click();
      acmgGuidelinesTab.scrollIntoView();
    }
  }

  handleClick() {
    const { isAcmgCriteriaEditDisabledDueToSuggestions } = this.props;
    if (isAcmgCriteriaEditDisabledDueToSuggestions) {
      this.openAcmgGuidelinesTab();
      return;
    }
    const { toggleOverlay } = this.props;
    toggleOverlay();
  }

  handleLockClick() {
    const { showConfirmationModal } = this.props;
    showConfirmationModal();
  }

  handleLockConfirm() {
    const {
      patientId,
      variantId,
      activeTranscriptId,
      locked = false,
      lock,
      unlock,
      hideConfirmationModal,
    } = this.props;

    if (locked) {
      unlock({
        patientId,
        variantId,
        transcriptId: activeTranscriptId,
      });
    } else {
      lock({
        patientId,
        variantId,
        transcriptId: activeTranscriptId,
      });
    }
    hideConfirmationModal();
  }

  withTooltip(container, wrapIntoTooltip, tooltipText) {
    if (wrapIntoTooltip) {
      return (
        <Tooltip content={tooltipText} placement="bottom">
          {container}
        </Tooltip>
      );
    }
    return container;
  }

  render() {
    const {
      overlayActive = false,
      canLock = false,
      makingLockRequest = false,
      isDecisionAlreadySetAgainstAnotherTranscript = true,
      patientId,
      patientVariantId,
      variantId,
      allCriteria = [],
      isRequestingClassification,
      patientVariant: pv,
      disabled,
      warningsModalActive,
      hideConfirmationModal,
      locked,
      warnings,
      hasSuggestedCriteria,
      isAcmgCriteriaEditDisabledDueToSuggestions,
    } = this.props;

    if (!patientId || !patientVariantId) return null;
    // this is to keep ACMGGrid from more mounting/unmouting
    const patientVariant = pv || AcmgClassification.patientVariantDefault;

    const isInteractionDisabled =
      disabled ||
      canLock === false ||
      makingLockRequest === true ||
      isDecisionAlreadySetAgainstAnotherTranscript === true ||
      isAcmgCriteriaEditDisabledDueToSuggestions;

    if (allCriteria.length === 0) {
      return <Loading />;
    }
    const { selectedCriteria = [] } = patientVariant;
    const classificationName = patientVariant.classification
      ? patientVariant.classification.name
      : "Uncertain Significance";

    const acmgClassificationContainer = this.withTooltip(
      <div
        onClick={this.handleClick}
        className="acmg-classification-container form-control"
      >
        <span>
          {classificationName}{" "}
          {isRequestingClassification ? <Icon type="spinner" /> : null}
        </span>

        <div className="criteria-list">
          {selectedCriteria.sort(sortByCriteriaData).map(singleCriteria => (
            <span
              key={singleCriteria.criteriaId + "-" + singleCriteria.name}
              className={`label criteria label-${
                singleCriteria.classificationType
                  ? singleCriteria.classificationType.style
                  : "default"
              }`}
            >
              {formatCriteriaName(
                singleCriteria,
                allCriteria.find(({ id }) => id === singleCriteria.criteriaId)
              )}
            </span>
          ))}
        </div>
      </div>,
      isAcmgCriteriaEditDisabledDueToSuggestions,
      "Selection is inactive. Please, go to ACMG Guidelines and use/reject auto-suggested criteria"
    );

    return (
      <div>
        <div className="row variant-decision-option-title">
          <div className="col-md-8">
            ACMG Classification
            {hasSuggestedCriteria && (
              <>
                &nbsp;
                <Tooltip
                  content="Auto-suggested ACMG criteria exists"
                  placement="bottom"
                >
                  <Icon
                    type="lightbulb"
                    className="acmg-suggestion-icon"
                    onClick={this.openAcmgGuidelinesTab}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="overlay-container">
            <div className="col-md-8">
              {acmgClassificationContainer}
              <div
                onClick={this.handleClick}
                className={classNames("overlay-hidden", {
                  active: overlayActive,
                })}
              />
              <div className={classNames("overlay", { active: overlayActive })}>
                <AcmgGrid
                  patientId={patientId}
                  activeVariant={variantId}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div
                className={classNames("btn-group", { active: overlayActive })}
                role="group"
              >
                <button
                  className="btn btn-sm btn-default"
                  type="button"
                  disabled={
                    isInteractionDisabled || patientVariant.acmgLocked === true
                  }
                  onClick={this.handleLockClick}
                >
                  <IconLoading loading={makingLockRequest} icon="lock" />
                </button>
                <button
                  className="btn btn-sm btn-default"
                  type="button"
                  disabled={
                    isInteractionDisabled || patientVariant.acmgLocked === false
                  }
                  onClick={this.handleLockClick}
                >
                  <IconLoading loading={makingLockRequest} icon="unlock" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <AcmgDecisionModal
          showConfirmationModal={warningsModalActive}
          yesClickHandler={this.handleLockConfirm}
          closeClickHandler={hideConfirmationModal}
          locked={locked}
          warnings={warnings}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    ...createStructuredSelector({
      patientVariant: selectors.getPatientVariantDetails,
      allCriteria: selectors.getACMGCriteria,
      overlayActive: selectors.getOverlayActiveSelector,
      canLock: selectors.isAbleToLock,
      locked: selectors.isLocked,
      makingLockRequest: selectors.isMakingLockRequest,
      isRequestingClassification: selectors.isRequestingClassification,
      warnings: selectors.getWarnings,
      warningsModalActive: selectors.isConfirmationModalActive,
      activeTranscriptId: variantSelectors.activeVariantPanelTranscript,
      isDecisionAlreadySetAgainstAnotherTranscript:
        selectors.isDecisionAlreadySetAgainstAnotherTranscript,
      hasSuggestedCriteria,
      isAcmgCriteriaEditDisabledDueToSuggestions,
    })(state),
  }),
  dispatch =>
    bindActionCreators(
      {
        ...actions,
        ...variantActions,
        readVariantOld,
        readAcmgCriteriaList,
      },
      dispatch
    )
)(AcmgClassification);
