import { fetchData } from "modules/utils";

import { FetchDataResponse } from "../utils/fetchData";

export const cvlRequest = (
  patientId: number
): Promise<FetchDataResponse<APIEntity>> =>
  fetchData(
    `/webapi/entities/patients/${patientId}/automation_curated_variant_list`
  );

export const genePanelsRequest = (
  patientId: number
): Promise<FetchDataResponse<APIEntityArray>> =>
  fetchData(`/webapi/entities/patients/${patientId}/automation_gene_panels`);

export const patientDetailsRequest = (
  patientId: number
): Promise<FetchDataResponse<APIEntity>> =>
  fetchData(`/webapi/entities/patients/${patientId}`);

export const acmgAuditInfoRequest = (
  patientId: number
): Promise<FetchDataResponse<APIEntity>> =>
  fetchData(`/webapi/entities/patients/${patientId}/acmg_audit_info`);
