// @flow
import { getChunk } from "../util";

export default class ChunkedTextFileReader {
  file: File;
  chunkSize: number;
  _currentFilePos: number = 0; // how far we have seeked into the file

  constructor(file: File, userOptions: { chunkSize?: number }) {
    if (file.size === 0) {
      throw new Error("Empty file provided");
    }

    const defaultOptions = { chunkSize: 1024 };
    const options = {
      ...defaultOptions,
      ...userOptions,
    };

    this.file = file;
    this.chunkSize = options.chunkSize; // number of bytes to read at once
  }

  eof() {
    return this._currentFilePos >= this.file.size;
  }

  getNextChunk(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      // raise an error if someone accidentally reads past the end of the file
      // it is the callers responsibility to check if it has been reached
      if (this.eof()) {
        return reject("getNextChunk called but EOF has been reached");
      }

      // don't read past the end of the file
      const end = Math.min(
        this._currentFilePos + this.chunkSize,
        this.file.size
      );

      let error = false;
      const reader: FileReader = await getChunk(
        this.file,
        this._currentFilePos,
        end
      ).catch(err => (error = err));

      if (error) {
        return reject(error);
      }

      try {
        // update the _currentFilePos variable _before_ calling the callback,
        // so the value can be trusted as soon as a read has happened
        this._currentFilePos = end;
        const { result } = reader;

        if (typeof result === "string")
          return reject("Expected an ArrayBuffer not a string");
        // convert the data to utf8
        const decoder = new TextDecoder();
        return resolve(decoder.decode(result));
      } catch (decodeError) {
        return reject(decodeError.message);
      }
    });
  }
}
