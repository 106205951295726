import {
  SUMMARY_STATE_PATH,
  SummaryProps,
  SummaryRequestParameters,
} from "./types";

export const NAMESPACE = "sequenceVariantsSummary";

export const ComparisonOutcomes = {
  POSSIBLE_CAUSAL: "Possible causal",
  POSSIBLE_CAUSAL_DOMINANT: "Possible causal if dominant",
  POSSIBLE_CAUSAL_X_LINKED: "Possible causal if X-linked",
  POSSIBLE_CAUSAL_ARCH: "Possible causal if autosomal recessive compound het",
  UNLIKELY_CAUSAL: "Unlikely causal",
};

export const SPLICE_SCORE_NOT_AVAILABLE = "Not Available";
export const SPLICE_SCORE_NOT_CALCULATED = "Not Calculated";
export const SPLICE_SCORE_EMPTY_VALUES = [
  SPLICE_SCORE_NOT_AVAILABLE,
  SPLICE_SCORE_NOT_CALCULATED,
];

/* Unique Idex  mation fields */
export enum Fields {
  AMINO_ACID = "AMINO_ACID",
  BASE_CHANGE = "BASE_CHANGE",
  CODONS = "CODONS",
  COMPARISON_OUTCOME = "COMPARISON_OUTCOME",
  DEPTH = "DEPTH",
  GENE_NAME = "GENE_NAME",
  GENOTYPE = "GENOTYPE",
  GERP = "GERP",
  GRANTHAM_SUBSTITUTION = "GRANTHAM_SUBSTITUTION",
  HGVSC = "HGVSC",
  HGVSP = "HGVSP",
  HI_SCORE = "HI_SCORE",
  INHERITANCE = "INHERITANCE",
  MAX_AF = "MAX_AF",
  POLYPHEN_PREDICTION = "POLYPHEN_PREDICTION",
  PROTEIN_POSITION = "PROTEIN_POSITION",
  QC_STATUS = "QC_STATUS",
  QUALITY_SCORE = "QUALITY_SCORE",
  READ_SPLIT = "READ_SPLIT",
  REVEL = "REVEL",
  SIFT_PREDICTION = "SIFT_PREDICTION",
  TRANSCRIPT = "TRANSCRIPT",
  VEP_CONSEQUENCE = "VEP_CONSEQUENCE",
  COLOCATED_VARIANTS = "COLOCATED_VARIANTS",
  OTHER_PATIENTS_SNV_COUNTS = "OTHER_PATIENTS_SNV_COUNTS",
  SPLICE_SITE = "SPLICE_SITE",
}

export const GeneFields = [Fields.GENE_NAME, Fields.HI_SCORE];

export const SnvFields = Object.values(Fields);

export enum Cases {
  DATA_NOT_FOUND = "DATA_NOT_AVAILABLE",
  DATA_NOT_CALCULATED = "DATA_NOT_CALCULATED",
}

/* All configurable labels */
export const Labels: { [key in Fields | Cases]: string } = {
  [Fields.AMINO_ACID]: "Amino acid",
  [Fields.BASE_CHANGE]: "Base change",
  [Fields.CODONS]: "Codons",
  [Fields.COMPARISON_OUTCOME]: "Comparison outcome",
  [Fields.DEPTH]: "Depth",
  [Fields.GENE_NAME]: "Gene name",
  [Fields.GENOTYPE]: "Genotype",
  [Fields.GERP]: "GERP",
  [Fields.GRANTHAM_SUBSTITUTION]: "Grantham substitution",
  [Fields.HGVSC]: "HGVSc",
  [Fields.HGVSP]: "HGVSp",
  [Fields.HI_SCORE]: "Haploinsufficiency score",
  [Fields.INHERITANCE]: "Inheritance",
  [Fields.MAX_AF]: "Max AF",
  [Fields.POLYPHEN_PREDICTION]: "PolyPhen prediction",
  [Fields.PROTEIN_POSITION]: "Protein position",
  [Fields.QC_STATUS]: "QC status",
  [Fields.QUALITY_SCORE]: "Quality",
  [Fields.READ_SPLIT]: "Reads split",
  [Fields.REVEL]: "REVEL",
  [Fields.SIFT_PREDICTION]: "SIFT prediction",
  [Fields.TRANSCRIPT]: "Transcript",
  [Fields.VEP_CONSEQUENCE]: "VEP Consequence",
  [Fields.COLOCATED_VARIANTS]: "Co-located variants",
  [Fields.OTHER_PATIENTS_SNV_COUNTS]: "Other patients with this variant",
  [Fields.SPLICE_SITE]: "Splice site",
  [Cases.DATA_NOT_FOUND]: "",
  [Cases.DATA_NOT_CALCULATED]: "Not Calculated",
};

/* All configurable icons */
export const Icons: {
  [key in Fields]?: {
    type: string;
    tooltip:
      | string
      | ((props: SummaryProps & SummaryRequestParameters) => string);
  };
} = {
  [Fields.OTHER_PATIENTS_SNV_COUNTS]: {
    type: "help",
    tooltip: ({ geneName }) =>
      `Counts only take into account patients who have ${geneName} in their gene panels`,
  },
};

/* Defines how fields will be ordered */
export const Order: Fields[] = [
  // First column
  Fields.GENE_NAME,
  Fields.HI_SCORE,
  Fields.TRANSCRIPT,
  Fields.BASE_CHANGE,
  Fields.GENOTYPE,
  Fields.CODONS,
  Fields.AMINO_ACID,
  Fields.HGVSC,
  Fields.HGVSP,
  Fields.PROTEIN_POSITION,

  // Second column
  Fields.VEP_CONSEQUENCE,
  Fields.GRANTHAM_SUBSTITUTION,
  Fields.POLYPHEN_PREDICTION,
  Fields.SIFT_PREDICTION,
  Fields.REVEL,
  Fields.GERP,
  Fields.SPLICE_SITE,
  Fields.INHERITANCE,
  Fields.MAX_AF,
  Fields.COLOCATED_VARIANTS,
  Fields.COMPARISON_OUTCOME,
  Fields.OTHER_PATIENTS_SNV_COUNTS,
  Fields.DEPTH,
  Fields.READ_SPLIT,
  Fields.QUALITY_SCORE,
  Fields.QC_STATUS,
];

/* Fields that are "more visible" in UI */
export const PrimaryFields: Fields[] = [
  Fields.GENE_NAME,
  Fields.HI_SCORE,
  Fields.TRANSCRIPT,
  Fields.BASE_CHANGE,
  Fields.GENOTYPE,
  Fields.CODONS,
  Fields.AMINO_ACID,
  Fields.HGVSC,
  Fields.HGVSP,
  Fields.PROTEIN_POSITION,
];

/* Fields to hide from the summary if their values are falsy */
export const HIDE_IF_NULL: Fields[] = [
  Fields.GRANTHAM_SUBSTITUTION,
  Fields.HI_SCORE,
];

/* Fields that have single column for label and value */
export const ColspanFields: Fields[] = [Fields.SPLICE_SITE];

export const DEFAULT_SUMMARY_STATE_PATH = SUMMARY_STATE_PATH.VARIANTS;

/* All Variant panel keys */
export const VARIANT_PANEL_KEYS = {
  vep_consequence: Fields.VEP_CONSEQUENCE,
  genotype: Fields.GENOTYPE,
  colocated_variants: Fields.COLOCATED_VARIANTS,
  base_change: Fields.BASE_CHANGE,
  protein_position: Fields.PROTEIN_POSITION,
  grantham: Fields.GRANTHAM_SUBSTITUTION,
  transcript_name: Fields.TRANSCRIPT,
  hgvs_c: Fields.HGVSC,
  comparison_outcome: Fields.COMPARISON_OUTCOME,
  hgvs_p: Fields.HGVSP,
  amino_acid: Fields.AMINO_ACID,
  max_af: Fields.MAX_AF,
  inheritance: Fields.INHERITANCE,
  polyphen: Fields.POLYPHEN_PREDICTION,
  codons: Fields.CODONS,
  filter: Fields.QC_STATUS,
  quality: Fields.QUALITY_SCORE,
  gene_name: Fields.GENE_NAME,
  other_patients: Fields.OTHER_PATIENTS_SNV_COUNTS,
  splice_site: Fields.SPLICE_SITE,
  gene_hi_score: Fields.HI_SCORE,
  sift: Fields.SIFT_PREDICTION,
  gerp: Fields.GERP,
  read_split: Fields.READ_SPLIT,
  depth: Fields.DEPTH,
  revel: Fields.REVEL,
};
