/* eslint react/no-danger: 0 */ // The rule was disabled for this file cos Perl sends HTML string to `customFooter` prop.

import classnames from "classnames";
import { mergeDeepRight } from "ramda";
import React, { FC, FunctionComponent, ReactNode, useMemo } from "react";

import { Icon, Link, StickyToLeftWrapper } from "pattern-library";

import styles from "./Footer.module.scss";
import { FooterLink, FooterProps } from "./Footer.types";
import { defaultLinkData, linksOrder } from "./default-link-data";

import { useAppVersion } from "hooks/useAppVersion";

export const Footer: FC<FooterProps> = props => {
  const {
    customFooter,
    limitWidth = false,
    navData = {},
    shaHash = "",
    versionText,
  } = props;

  const linksConfig = useMemo<FooterLink[]>(
    () =>
      linksOrder.reduce<FooterLink[]>((acc, key) => {
        const linkData: FooterLink = navData[key]
          ? mergeDeepRight(defaultLinkData[key], navData[key])
          : defaultLinkData[key];

        if (!linkData.action) {
          return acc;
        }

        if (
          linkData.conditionToShow &&
          !linkData.conditionToShow.every(
            ({ propName, value }) => props[propName] === value
          )
        ) {
          return acc;
        }

        acc.push(linkData);
        return acc;
      }, []),
    [navData, props]
  );

  const renderFooterLinks = () =>
    linksConfig.map(({ action, attributes, icon, label }, index) => {
      const elementsToRender: ReactNode[] = [index > 0 && " | "];

      const allAttributes = {
        ...attributes,
        className: styles.footerLink,
        href: typeof action !== "function" ? (action as string) : undefined,
        key: label,
        onClick: typeof action === "function" ? action : undefined,
      };

      const renderLinkContent = () => (
        <>
          {label}
          {icon && (
            <>
              {" "}
              <Icon type={icon} />
            </>
          )}
        </>
      );

      if (typeof action === "function") {
        elementsToRender.push(<a {...allAttributes}>{renderLinkContent()}</a>);
      } else {
        elementsToRender.push(
          <Link target="_blank" rel="noopener noreferrer" {...allAttributes}>
            {renderLinkContent()}
          </Link>
        );
      }

      return elementsToRender;
    });

  return (
    <div
      id="footer"
      className={classnames("container-fluid", styles.footer)}
      data-testid="page-footer"
    >
      <div className={classnames({ [styles.footerWidthLimiter]: limitWidth })}>
        <div className={styles.footerSection}>
          {versionText && (
            <p
              className="text-muted"
              data-congenica-sha={shaHash}
              id="footer-version"
            >
              {versionText}
            </p>
          )}
          {typeof customFooter === "string" && (
            <div dangerouslySetInnerHTML={{ __html: customFooter }} />
          )}
          {typeof customFooter !== "string" && customFooter}
        </div>
        <div className={styles.footerSection}>
          <ul>
            <li>{renderFooterLinks()}</li>
            <li>
              <Link href="mailto:support@congenica.com">
                support@congenica.com
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const ConnectedFooter: FC<FooterProps> = props => (
  <Footer {...props} versionText={useAppVersion()} />
);
export const StickyToLeftFooter: FunctionComponent<FooterProps> = props => (
  <StickyToLeftWrapper>
    <ConnectedFooter {...props} />
  </StickyToLeftWrapper>
);

export default ConnectedFooter;
