import { memo, useCallback } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { getFilterErrorState } from "modules/config/selectors";

import ConfigForm from "../../../config/components/ConfigForm";
import { CONFIG_MODE } from "../../../config/constants";
import validator from "../../../forms/validator";

import PresetBaseForm from "./PresetBaseForm";
import * as constants from "./constants";
import * as selectors from "./selectors";
import * as utils from "./utils";
import { getValidationSchema } from "./validation";

type Props = {
  className?: string;
  projectId: number | string;
  isDisabled: boolean;
  isDefaultDisabled: boolean;
  filterError?: boolean;
  submitCallback: FormSubmitCallback;
  filterPresets?: ReturnType<selectors.getSNVFilterPresets>;
};

export const SNVPresetForm = memo((props: Props) => {
  const { submitCallback, isDisabled, filterError } = props;

  const submitPreset = useCallback(
    preset => submitCallback(utils.convertFormValues(preset)),
    [submitCallback]
  );

  return (
    <div>
      <PresetBaseForm {...props} submitPreset={submitPreset}>
        <ConfigForm
          {...props}
          isDisabled={isDisabled}
          configMode={CONFIG_MODE.PRESET_EDIT}
          filterError={filterError}
        />
      </PresetBaseForm>
    </div>
  );
});

SNVPresetForm.displayName = "SNVPresetForm";

const mapStateToProps = state => ({
  isSubmitting: selectors.isPresetSubmitInProgress(state),
  filterPresets: selectors.getSNVFilterPresets(state),
  initialValues: selectors.getSNVInitialValues(state),
  filterError: getFilterErrorState(state, constants.SNV_PRESET_FORM_NAME),
});

const connectedForm = reduxForm({
  form: constants.SNV_PRESET_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,

  asyncValidate: (values, _, props: Props) =>
    validator(getValidationSchema(props.filterPresets))(values),
})(SNVPresetForm);

export default connect(mapStateToProps, null)(connectedForm);
