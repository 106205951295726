import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import * as yup from "yup";

import {
  Button,
  Heading,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "pattern-library";

import { ReduxFormField } from "modules/forms/components";
import validator from "modules/forms/validator";

import { deleteProjectIR } from "../actions";
import * as constants from "../constants";
import * as selectors from "../selectors";

export const validationSchema = yup.object({
  reason: yup
    .string("")
    .trim()
    .required(
      "You must give a reason for deleting this interpretation request"
    ),
});

export const DeleteIRModal = ({
  projectId,
  irId,
  handleSubmit,
  close,
  show,
  deleteProjectIR,
  deleting,
}) => {
  const submitHandler = useCallback(
    values => {
      const { reason } = values;
      deleteProjectIR(projectId, irId, reason);
    },
    [deleteProjectIR, projectId, irId]
  );

  return (
    <Modal data-testid="delete-ir-modal" close={close} show={show}>
      <ModalHeader close={close}>
        <Heading type="h4">{`Warning - Deleting Interpretation Request ${irId}`}</Heading>
      </ModalHeader>
      <ModalBody>
        <p>
          Warning, you are about to delete Interpretation Request{" "}
          <strong className="delete-ir-id">{irId}</strong>, this will delete all
          the associated Patients, Jobs and Data Files.
          <br />
          If you are sure you wish to continue enter a reason below and hit
          Confirm Delete.
        </p>
        <fieldset className="d-table">
          <Label>Reason</Label>
          <Field
            data-testid="reason"
            component={ReduxFormField}
            type="text"
            name="reason"
          />
        </fieldset>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          className="button btn-danger"
          disabled={deleting}
          onClick={handleSubmit(submitHandler)}
        >
          {deleting ? (
            <>
              <i className="fa fa-spinner fa-pulse" />
              ... deleting
            </>
          ) : (
            "Confirm Delete"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteIRModal.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  irId: PropTypes.number,
  close: PropTypes.func,
  show: PropTypes.bool,
};

const ConnectedForm = reduxForm({
  form: constants.IR_DELETE_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
  shouldAsyncValidate(params) {
    return params.trigger === "change" || params.trigger === "submit";
  },
  asyncValidate: values => validator(validationSchema)(values),
})(DeleteIRModal);

const mapStateToProps = state => {
  const selector = formValueSelector(constants.IR_DELETE_FORM);
  return {
    initialValues: selectors.getIrForDelete(state),
    deleting: selectors.isDeleting(state),
    irId: selector(state, "id"),
  };
};

const mapDispatchToProps = {
  deleteProjectIR,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);
