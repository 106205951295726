import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Icon } from "../../elements/base";

export class SearchResultItem extends PureComponent {
  static displayName = "SearchResultItem";

  static propTypes = {
    /**
     * Item of the search result element
     */
    item: PropTypes.shape({
      /**
       * Id of the item
       */
      id: PropTypes.string.isRequired,
      /**
       * Date of the item
       */
      date: PropTypes.number.isRequired,
      /**
       * Id of the related run(if the item is a sample)
       */
      run: PropTypes.string,
    }).isRequired,
    /**
     * Click handler of the item
     */
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick() {
    const { onClick, item } = this.props;
    onClick(item);
  }

  render() {
    const {
      item: { run, id, date },
    } = this.props;

    return (
      <div className="search-result-item" onClick={this.onClick}>
        <Icon type={run ? "filter" : "folderOpen"} />
        <div className="search-result-text">
          <div className="main-title">
            <span className="pre">{run ? "Sample" : "Run"}:</span>
            <span className="text">{id}</span>
          </div>
          {run && (
            <div className="sub-title">
              <span className="pre">Run:</span>
              <span className="text">{run}</span>
            </div>
          )}
        </div>
        <div className="date">{date}</div>
      </div>
    );
  }
}
