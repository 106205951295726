// @flow
// utility selectors used both in orm and modules

import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as variantsConstants from "../../modules/variants/constants";

export const variantsUIPath = (...items: any) => [
  variantsConstants.NAME,
  "ui",
  ...items,
];
export const variantsDataPath = (...items: any) => [
  variantsConstants.NAME,
  "data",
  ...items,
];
export const getActiveGenesFromState = path(variantsDataPath("activeGenes"));
export const activeVariantPanelGene = path(variantsUIPath("activeGeneId"));

export const getActiveGenes = createSelector(
  getActiveGenesFromState,
  activeVariantPanelGene,
  (activeGenes: Array<Object>, variantPanelGene: Object | boolean) => {
    if (variantPanelGene) {
      return [variantPanelGene];
    }
    return activeGenes;
  }
);
