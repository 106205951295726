import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import ReduxFormField from "modules/forms/components/ReduxFormField";
import { normalisePositiveInteger } from "modules/forms/rules";

import * as selectors from "../../selectors";

export class QualityForm extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    filterIncludeOptions: PropTypes.array,
    filterExcludeOptions: PropTypes.array,
  };

  isOptionDisabled(option) {
    return option.disabled || false;
  }

  render() {
    const {
      disabled = false,
      filterIncludeOptions,
      filterExcludeOptions,
    } = this.props;

    return (
      <fieldset disabled={disabled} className="form-horizontal">
        <Field
          name="depth"
          label="Depth"
          placeholder="Depth"
          narrow
          prefix="&ge;"
          component={ReduxFormField}
          normalize={normalisePositiveInteger}
          id="quality-depth"
        />
        <Field
          name="score"
          label="QC Score"
          placeholder="QC Score"
          narrow
          prefix="&ge;"
          component={ReduxFormField}
          normalize={normalisePositiveInteger}
          id="quality-score"
        />
        <Field
          name="filterInclude"
          label="Include QC Filters"
          placeholder="Select..."
          narrow
          component={ReduxFormField}
          type="select"
          noResultsText="No filters found"
          options={filterIncludeOptions}
          isOptionDisabled={this.isOptionDisabled}
        />
        <Field
          name="filterExclude"
          label="Exclude QC Filters"
          placeholder="Select..."
          narrow
          component={ReduxFormField}
          type="select"
          noResultsText="No filters found"
          options={filterExcludeOptions}
          isOptionDisabled={this.isOptionDisabled}
        />
      </fieldset>
    );
  }
}

const mapStateToProps = (state, { projectId, form }) => ({
  filterExcludeOptions: selectors.getPatientQcFilterStringsForCurrentProject(
    state,
    projectId,
    "filterExclude",
    form
  ),
  filterIncludeOptions: selectors.getPatientQcFilterStringsForCurrentProject(
    state,
    projectId,
    "filterInclude",
    form
  ),
});

export default connect(mapStateToProps, null)(QualityForm);
