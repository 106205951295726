import classNames from "classnames";
import { FieldArray } from "formik";
import React, { memo } from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";

import { Tooltip } from "pattern-library";
import { Table } from "pattern-library/elements/table7";

import { HPO_TERM_LIST_ITEM } from "../../../hpoTerms/constants";
import { info } from "../../../messages/actions";

export const SelectedHPOTerms = memo(
  ({
    disabled = false,
    selectedHPOTerms,
    addHPOTerm,
    removeHPOTerm,
    showInfo,
  }) => {
    const columns = [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Phenotype",
        accessor: "phenotype",
      },
      {
        Header: "Presence",
        accessor: "presence",
        sortable: false,
      },
      {
        width: 10,
        sortable: false,
        id: "actions",
        Cell: ({ index, row: { code } }) => {
          if (disabled) {
            return null;
          }

          return (
            <Tooltip
              content="Remove HPO term"
              placement="right"
              trigger="mouseenter"
            >
              <div className="selected-hpo-terms-remove">
                <i
                  className="glyphicon glyphicon-trash text-danger"
                  onClick={() => removeHPOTerm(index)}
                  data-code={code}
                />
              </div>
            </Tooltip>
          );
        },
      },
    ];

    const [dropTarget, isActive] = useDropTarget({
      addHPOTerm,
      showInfo,
      selectedHPOTerms,
      disabled,
    });

    return (
      <div
        ref={dropTarget}
        className={classNames("selected-hpo-terms", "dnd-target-area", {
          "dnd-target-area-active": isActive,
          "selected-hpo-terms-empty": !selectedHPOTerms.length,
        })}
      >
        <Table
          data={selectedHPOTerms}
          columns={columns}
          pageSize={selectedHPOTerms.length}
          noDataText="Drag HPO terms here."
        />
      </div>
    );
  }
);

const convertHPOTermItem = item => ({
  code: item.hpoTermId,
  phenotype: item.name,
  presence: "Present",
});

export const validateAndAddHPOTerm = (
  { addHPOTerm, showInfo, selectedHPOTerms },
  dropItem
) => {
  const newHPOTerm = convertHPOTermItem(dropItem);
  if (selectedHPOTerms.some(item => item.code === newHPOTerm.code)) {
    showInfo(`This HPO Term (${newHPOTerm.code}) was already added`);
  } else {
    addHPOTerm(newHPOTerm);
  }
};

export const useDropTarget = props => {
  // eslint-disable-next-line no-unused-vars
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [HPO_TERM_LIST_ITEM],
    canDrop: () => !props.disabled,
    drop: item => validateAndAddHPOTerm(props, item),

    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  return [drop, isActive];
};

const SelectedHPOTermsContainer = memo(props => (
  <FieldArray name={`samples.${props.selectedRowNumber}.hpoTerms`}>
    {fieldArrayProps => {
      const {
        remove,
        push,
        form: {
          values: { samples = [] },
        },
      } = fieldArrayProps;

      const selectedHPOTerms = samples[props.selectedRowNumber].hpoTerms || [];

      return (
        <SelectedHPOTerms
          removeHPOTerm={remove}
          addHPOTerm={push}
          selectedHPOTerms={selectedHPOTerms}
          {...props}
        />
      );
    }}
  </FieldArray>
));

const mapDispatchToProps = { showInfo: info };

export default connect(null, mapDispatchToProps)(SelectedHPOTermsContainer);
