import { takeEvery, takeLatest, select, put, call } from "redux-saga/effects";

import { getFormValues } from "../forms/selectors";
import { metadataAffectionStatus } from "../utils/enum/affectionStatus";
import { ageFormFields } from "../utils/enum/age";
import { ethnicityFormFields } from "../utils/enum/ethnicity";
import { genderFormFields } from "../utils/enum/gender";

import * as actions from "./actions";
import * as constants from "./constants";

export function* init() {
  yield takeEvery(`SUBMIT/FORM/${constants.METADATA_FORM}`, setMetadata);
  yield takeLatest(constants.FETCH_METADATA_FIELDS, setMetadataFields);
}

export function* setMetadata() {
  const form = yield select(getFormValues, constants.METADATA_FORM);
  yield put(actions.setMetadata(form));
}

export function* setMetadataFields(action) {
  const { projectId } = action.meta;
  yield put(actions.hasMadeRequest());
  // fetch the fields
  const response = yield call(
    fetch,
    `/catalyst/api/project/${projectId}/metadata`,
    { credentials: "include" }
  );
  let result;
  try {
    result = yield call([response, response.json]);
  } catch (e) {
    yield put(actions.metadataLoadFailed(e));
    return;
  }

  // Process the fields
  if (result.data) {
    result = result.data.map(item => {
      let type = item.field_type.toLowerCase();
      let options;
      if (item.field_type.toLowerCase().startsWith("select::")) {
        type = "select";

        switch (item.field_type.toLowerCase()) {
          case "select::sex":
            options = genderFormFields;
            break;

          case "select::ethnicity":
            options = ethnicityFormFields;
            break;

          case "select::age":
            options = ageFormFields;
            break;

          case "select::parentalphenotype":
            options = metadataAffectionStatus;
            break;

          default:
            // Throw an error here or do something to handle this?
            break;
        }
      }

      return {
        ...item,
        required: Boolean(item.required),
        key: item.patient_metadata_field_id,
        label: item.field_name,
        name: item.field_code,
        type,
        options,
      };
    });

    // Put them in the store
    yield put(actions.setMetadataFields(result, projectId));
  } else {
    yield put(actions.metadataLoadFailed(result));
  }
}
