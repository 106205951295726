import React, { memo } from "react";

const SamplesTableHeader = memo(({ fileTypesOptions }) => {
  const fileTypesHeaders = fileTypesOptions.map(({ title }) => title);
  const rowSpanProps = { rowSpan: 2 };
  return (
    <thead>
      <tr>
        <th {...rowSpanProps}>&nbsp;</th>
        <th {...rowSpanProps}>Sample ID</th>
        <th {...rowSpanProps}>Proband</th>
        <th {...rowSpanProps}>Sex</th>
        <th {...rowSpanProps}>Affection Status</th>
        <th {...rowSpanProps}>Mother ID</th>
        <th {...rowSpanProps}>Father ID</th>
        <th {...rowSpanProps}>Family</th>
        <th
          colSpan={fileTypesHeaders.length}
          className="ir-table-header-group-column"
        >
          Sample Files
        </th>
        <th {...rowSpanProps}>&nbsp;</th>
      </tr>
      <tr>
        {fileTypesHeaders.map(header => (
          <th key={header} className="ir-table-header-group-column">
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
});

export default SamplesTableHeader;
