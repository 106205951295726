import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { FormField } from "pattern-library";

export default class PatientMetadataField extends PureComponent {
  static displayName = "PatientMetadataField";

  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onBlur() {
    const {
      input: { value, onBlur },
    } = this.props;
    onBlur(value);
  }

  render() {
    const { input, meta, ...rest } = this.props;

    if (rest.type === "select") {
      // https://github.com/erikras/redux-form/issues/82
      rest.onBlur = this.onBlur;
      rest.formatGroupLabel = data => data.group;
      rest.onBlurResetsInput = false;
    }

    return <FormField {...input} {...meta} {...rest} />;
  }
}
