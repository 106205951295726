import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class Label extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    narrow: PropTypes.bool,
    required: PropTypes.bool,
  };

  render() {
    const { label, narrow = false, required = false } = this.props;

    return (
      <label className={`col-xs-${narrow ? 4 : 2} control-label`}>
        {label} {required ? "*" : ""}
      </label>
    );
  }
}
