import PropTypes from "prop-types";
import { always, isNil } from "ramda";
import React, { memo, useCallback } from "react";

import ListItemLabel from "./ListItemLabel";
import ListItemText from "./ListItemText";

const ListItem = memo(
  ({
    item,
    valuePropName,
    categoryName,
    isHighlighted,
    onClick = always(),
  }) => {
    const onItemClicked = useCallback(() => {
      onClick(item);
    }, [item, onClick]);

    return (
      <div className="list-item-container" onClick={onItemClicked}>
        <ListItemText
          text={item[valuePropName]}
          highlighted={!isNil(isHighlighted) && isHighlighted(item)}
        />
        {!isNil(categoryName) && <ListItemLabel label={item[categoryName]} />}
      </div>
    );
  }
);

ListItem.displayName = "ListItem";

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  valuePropName: PropTypes.string.isRequired,
  categoryName: PropTypes.string,
  onClick: PropTypes.func,
  isHighlighted: PropTypes.func,
};

export default ListItem;
