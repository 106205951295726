import PropTypes from "prop-types";
import React, { memo } from "react";

import { ComposableTabs, Tab } from "pattern-library";

import { CONFIG_MODE } from "../constants";
import { ConfigFormValuesProp } from "../prop-types";

import Filters from "./Filters";
import ColumnsForm from "./forms/ColumnsForm";
import CuratedVariantListsForm from "./forms/CuratedVariantListsForm";
import PrioritisationForm from "./forms/PrioritisationForm";

const ConfigForm = memo(
  ({
    projectId,
    patientId,
    change,
    form,
    isDisabled,
    configMode = CONFIG_MODE.CONFIG_EDIT,
    filterError,
  }) => (
    <ComposableTabs navStyle={false}>
      <Tab name="Filters" hasError={filterError}>
        <Filters
          disabled={isDisabled}
          projectId={projectId}
          patientId={patientId}
          change={change}
          form={form}
        />
      </Tab>
      <Tab name="Prioritisation">
        <PrioritisationForm disabled={isDisabled} patientId={patientId} />
      </Tab>
      <Tab name="Curated lists">
        <CuratedVariantListsForm disabled={isDisabled} />
      </Tab>
      <Tab name="Columns">
        <ColumnsForm
          disabled={isDisabled}
          configMode={configMode}
          patientId={patientId}
        />
      </Tab>
    </ComposableTabs>
  )
);

ConfigForm.displayName = "ConfigForm";
ConfigForm.propTypes = {
  /**
   * Optional patient id. If present, some of the form data will be patient specific
   * If not, then form data will be built based on the project only
   */
  patientId: PropTypes.number,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDisabled: PropTypes.bool,
  configMode: PropTypes.oneOf(Object.keys(CONFIG_MODE)),
  /**
   * the enclosing form name supplied by the redux-form
   */
  form: PropTypes.string.isRequired,
  /**
   * field change callback supplied by the redux-form
   */
  change: PropTypes.func.isRequired,
  /**
   * the form values supplied by the redux-form wrapper
   */
  initialValues: ConfigFormValuesProp.isRequired,
  /**
   * Whether the config form should show a error status on the filter tab
   */
  filterError: PropTypes.bool,
};

export default ConfigForm;
