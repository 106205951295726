import { camelizeKeys } from "humps";
import { call, put, takeLatest, select } from "redux-saga/effects";

import { getPatientOverlaps } from "modules/api/structuralVariants";
import { error } from "modules/messages/actions";
import { GNOMAD_SV_FEATURE } from "modules/project/constants";
import { getOrReloadCurrentProject } from "modules/project/saga";
import { isProjectFeatureActive } from "modules/project/selectors";
import { LEGACY_COLUMNS_CONNECTOR_ID } from "modules/structuralVariants/SVColumnsDropdown";
import { LEGACY_CONNECTOR_ID } from "modules/structuralVariants/SVFilterSidebar";
import { FILTER_DATA_MOCK } from "modules/structuralVariants/mock";
import { PatientOverlap } from "modules/structuralVariants/types";
import { FetchDataResponse } from "modules/utils/fetchData";

import { InitializeAction } from "./actions";
import * as actions from "./actions";
import * as constants from "./constants";

const gnomadFilter = gnomadFeatureEnabled => key =>
  !(!gnomadFeatureEnabled && key === "gnomadAf");

export function* init() {
  yield takeLatest(constants.INIT, initialize);
  yield takeLatest(
    constants.FETCH_PATIENT_OVERLAPS_START,
    reloadPatientOverlaps
  );
  yield takeLatest(constants.ON_SV_FILTER_CHANGE, onSvFilterChange);
  yield takeLatest(constants.ON_SV_COLUMNS_CHANGE, onSvColumnsChange);
}

export function* initialize({
  payload: { projectId, filterData },
}: ReturnType<InitializeAction>) {
  yield call(getOrReloadCurrentProject, projectId);
  const gnomadFeatureEnabled = yield select(
    isProjectFeatureActive,
    GNOMAD_SV_FEATURE
  );

  let filterDataProcessed = filterData
    ? camelizeKeys(filterData)
    : FILTER_DATA_MOCK;

  filterDataProcessed = Object.keys(filterDataProcessed)
    .filter(gnomadFilter(gnomadFeatureEnabled))
    .reduce((acc, curr) => ({ ...acc, [curr]: filterDataProcessed[curr] }), {});

  yield put(actions.setFilterData(filterDataProcessed));
}

export function* reloadPatientOverlaps({
  payload: { patientId, patientSvId },
}: ReturnType<typeof actions.fetchPatientOverlaps.start>) {
  try {
    yield put(actions.setLoading(true));

    const result: FetchDataResponse<
      { patients: Array<PatientOverlap> } | { error?: string }
    > = yield call(getPatientOverlaps, patientId, patientSvId);
    if (result.ok) {
      yield put(
        actions.fetchPatientOverlaps.success(
          result.payload as { patients: Array<PatientOverlap> }
        )
      );
    } else {
      yield put(actions.fetchPatientOverlaps.failure());

      const { error: errorMsg = "Cannot reload patient overlap" } =
        (result.payload as { error?: string }) || {};
      yield put(error(errorMsg));
    }
  } catch (e) {
    yield put(actions.fetchPatientOverlaps.failure());
    yield put(error(e.message));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* onSvFilterChange({ payload }: actions.OnSvFilterChangeAction) {
  yield put(actions.setFilters(payload));
  const element = document.getElementById(LEGACY_CONNECTOR_ID);
  if (element) {
    element.dispatchEvent(
      new CustomEvent("filterChangeEvent", { bubbles: true })
    );
  }
}

export function* onSvColumnsChange({
  payload,
}: actions.OnSvColumnsChangeAction) {
  yield put(actions.setFilterColumns(payload));
  const element = document.getElementById(LEGACY_COLUMNS_CONNECTOR_ID);
  if (element) {
    element.dispatchEvent(
      new CustomEvent("columnChangeEvent", { bubbles: true })
    );
  }
}
