import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import { memoizeWith, identity } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { DataTable, Pagination, Dropdown } from "pattern-library";

import { fetchTrackConfig } from "modules/genoverse/actions";
import Browser from "modules/refNoCall/components/Browser";

import { fetchVariants } from "../actions";
import {
  tableColums,
  filtration,
  INITIAL_PER_PAGE_COUNT,
  perPageOptions,
  PAGINATION_PAGES_AHEAD,
} from "../constants";
import {
  variantsSelector,
  loadingSelector,
  totalCountSelector,
} from "../selectors";

import Filter from "./Filter";
import Header from "./Header";
import Search from "./Search";

class RefNoCall extends PureComponent {
  static propTypes = {
    patientId: PropTypes.number.isRequired,
  };

  paramsHaveChanged = false;

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      currentPage: 0,
      perPage: INITIAL_PER_PAGE_COUNT,
      filter: filtration.ALL,
      searchText: "",
      sorting: [],
      selected: null,
    };
  }

  componentDidMount() {
    this.loadVariants();
    const { fetchTrackConfig, patientId } = this.props;
    fetchTrackConfig(patientId);
  }

  getRequestParams() {
    const { currentPage, perPage, filter, searchText, sorting } = this.state;
    const { patientId } = this.props;
    return {
      currentPage,
      perPage,
      filter,
      searchText,
      sorting: sorting[0],
      patientId,
    };
  }

  getParamsChangeHandler(paramName) {
    return memoizeWith(identity, value => {
      const { loading } = this.props;
      if (loading) {
        this.paramsHaveChanged = true;
      }
      const nextState = {
        [paramName]: value,
      };
      if (
        paramName === "filter" ||
        paramName === "perPage" ||
        paramName === "searchText"
      ) {
        nextState.currentPage = 0;
      }
      this.setState(nextState, this.loadVariants);
    });
  }

  loadVariants() {
    const { fetchVariants } = this.props;
    fetchVariants(this.getRequestParams());
  }

  handlePerPageChange({ target: { value } }) {
    this.getParamsChangeHandler("perPage")(+value);
  }

  unselect(e) {
    e.stopPropagation();
    this.setState({
      selected: null,
    });
  }

  renderSummary() {
    const { currentPage, perPage } = this.state;
    const { totalCount } = this.props;
    const start = currentPage * perPage + 1;
    const end = Math.min(start + perPage - 1, totalCount);
    return (
      <>
        Show: {start} &ndash; {end} from {totalCount}
      </>
    );
  }

  render() {
    const { currentPage, filter, sorting, searchText, perPage, selected } =
      this.state;
    const { variants, patientId, loading, totalCount } = this.props;
    const pageCount = Math.ceil(totalCount / perPage);
    return (
      <div className="ref-no-call">
        <Header patientId={patientId} />
        <div className="row">
          <div className="ref-no-call__filter-items col-sm-7">
            <Filter
              value={filter}
              onChange={this.getParamsChangeHandler("filter")}
            />
          </div>
          <div className="ref-no-call__filter-text col-sm-5 nopadding">
            <Search
              value={searchText}
              onChange={this.getParamsChangeHandler("searchText")}
            />
          </div>
        </div>
        <DataTable
          columns={tableColums}
          onSortedChange={this.getParamsChangeHandler("sorting")}
          manual
          multiSort={false}
          data={selected != null ? [selected] : variants}
          minRows={1}
          sorted={sorting}
          loading={loading}
          showPagination={false}
          getTdProps={(_, { original } = {}) => ({
            onClick: () => {
              if (original.callType === "NOCALL") {
                this.setState({
                  selected: original,
                });
              }
            },
            style:
              original && original.callType === "NOCALL"
                ? { cursor: "pointer" }
                : {},
          })}
        />
        {selected === null && (
          <div className="ref-no-call__footer">
            <div className="ref-no-call__footer-left">
              {this.renderSummary()}
            </div>
            <div className="ref-no-call__footer-center">
              <Pagination
                pageCount={pageCount}
                pageIndex={currentPage}
                pagesAhead={PAGINATION_PAGES_AHEAD}
                onPageChange={this.getParamsChangeHandler("currentPage")}
              />
            </div>
            <div className="ref-no-call__footer-right">
              <Dropdown
                onChange={this.handlePerPageChange}
                options={perPageOptions}
                value={perPage}
              />
              <span className="ref-no-call__footer-perpage-label">
                rows per page
              </span>
            </div>
          </div>
        )}
        {selected !== null && (
          <div>
            <button
              type="button"
              className="btn btn-link"
              onClick={this.unselect}
            >
              <span className="caret false" />
              {" Expand table"}
            </button>
            <Browser patientId={patientId} {...selected} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector,
  variants: variantsSelector,
  totalCount: totalCountSelector,
});

const mapDispatchToProps = {
  fetchVariants: fetchVariants.start,
  fetchTrackConfig: fetchTrackConfig.start,
};

const RefNoCallConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefNoCall);

export { RefNoCallConnected as RefNoCall };
