// @flow
import { createAction } from "../../utils";

import * as constants from "./constants";

type ReadVariantOldAction = FSA<
  typeof constants.READ_VARIANT_OLD,
  {},
  { patientId: number, variantId: number }
>;

export const readVariantOld = (
  patientId: number,
  variantId: number
): ReadVariantOldAction =>
  createAction(
    constants.READ_VARIANT_OLD,
    {},
    { modelId: variantId, pathParams: { patientId, variantId } }
  );

type CreateOrmVariantOldAction = FSA<
  typeof constants.CREATE_ORM_VARIANT_OLD,
  {}
>;

export const createOrmVariantOld = (
  variant: {} = {}
): CreateOrmVariantOldAction =>
  createAction(constants.CREATE_ORM_VARIANT_OLD, variant);

export type Action = ReadVariantOldAction | CreateOrmVariantOldAction;
