import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { connect } from "react-redux";

import { Button, Icon } from "pattern-library";

import { resetToDefaultPreset } from "modules/structuralVariantsPresets/actions";
import {
  getSelectedPresetOption,
  isDefaultPresetSelected,
} from "modules/structuralVariantsPresets/selectors";

import { isSelectedPresetCustomized } from "../selectors";

export const ResetFiltersButton = ({
  onPresetChange,
  isSelectedPresetCustomized,
  isDefaultPresetSelected,
  selectedPresetCustomized,
  resetToDefaultPreset,
  collapsed,
}) => {
  const resetFilterHandler = useCallback(() => {
    if (isDefaultPresetSelected) {
      onPresetChange(selectedPresetCustomized ?? {});
    } else {
      resetToDefaultPreset();
    }
  }, [
    onPresetChange,
    resetToDefaultPreset,
    selectedPresetCustomized,
    isDefaultPresetSelected,
  ]);

  return (
    <Button
      onClick={resetFilterHandler}
      disabled={isDefaultPresetSelected && !isSelectedPresetCustomized}
      title="Reset filters"
      className="btn-sm"
    >
      <Icon type="repeat" />
      {!collapsed && " Reset filters"}
    </Button>
  );
};
ResetFiltersButton.propTypes = {
  collapsed: PropTypes.bool,
};

const mapStateToProps = state => ({
  isDefaultPresetSelected: isDefaultPresetSelected(state),
  isSelectedPresetCustomized: isSelectedPresetCustomized(state),
  selectedPresetCustomized: getSelectedPresetOption(state),
});

const mapDispatchToProps = {
  resetToDefaultPreset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetFiltersButton);
