import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class TextCrumb extends PureComponent {
  static displayName = "TextCrumb";

  static propTypes = {
    /**
     * Text inside component
     */
    name: PropTypes.string.isRequired,
  };

  render() {
    const { name } = this.props;

    return (
      <div key={name} className="breadcrumbs-crumb">
        <strong>{name}</strong>
      </div>
    );
  }
}
