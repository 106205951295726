import { memo, useEffect } from "react";
import { connect } from "react-redux";

import { Modal, ModalHeader, ModalBody } from "pattern-library";

import { GNOMAD_SV_FEATURE } from "modules/project/constants";
import { isProjectFeatureActive } from "modules/project/selectors";

import { loadDictionaries } from "../actions";
import { getPresetDictionaries } from "../selectors";
import * as modulePropTypes from "../types";

import FilterPresetColumnsInfo from "./FilterPresetColumnsInfo";
import FilterPresetInfo from "./FilterPresetInfo";

const parseCvlData = curatedVariantList =>
  // Processes CVL into the specific same format as the other dictionaries for filter presets
  Object.keys(curatedVariantList).map(cvlId => {
    const { curated_variant_list_id, name } = curatedVariantList[cvlId];
    return {
      value: curated_variant_list_id,
      label: name,
    };
  });
export type Props = {
  close: () => void;
  cvls: {
    [key: string]: {
      curated_variant_list_id?: number;
      name?: string;
    };
  };
  dictionaries: modulePropTypes.FilterPresetDictionary | Index;
  loadDictionaries: (projectId: number) => void;
  name: string;
  preset: modulePropTypes.FilterPresetConfig | Index;
  columns: Array<string>;
  projectId: number;
  show: boolean;
  gnomadFeatureEnabled?: boolean;
};

export const FilterPresetModal = memo(
  ({
    close,
    cvls = {},
    dictionaries,
    loadDictionaries,
    name = "",
    preset = {},
    columns = [],
    projectId,
    show,
    gnomadFeatureEnabled,
  }: Props) => {
    useEffect(() => {
      loadDictionaries(projectId);
    }, [loadDictionaries, projectId]);

    const remappedCvls = parseCvlData(cvls);

    const combinedDictionaries = {
      ...dictionaries,
      cvls: remappedCvls,
    };

    return (
      <Modal close={close} show={show}>
        <ModalHeader close={close}>
          <strong>{name}</strong>
        </ModalHeader>
        <ModalBody>
          <FilterPresetInfo
            preset={preset}
            dictionaries={combinedDictionaries}
            gnomadFeatureEnabled={gnomadFeatureEnabled}
          />
          <FilterPresetColumnsInfo
            columns={columns}
            gnomadFeatureEnabled={gnomadFeatureEnabled}
          />
        </ModalBody>
      </Modal>
    );
  }
);

FilterPresetModal.displayName = "FilterPresetModal";

const mapStateToProps = state => ({
  dictionaries: getPresetDictionaries(state),
  gnomadFeatureEnabled: isProjectFeatureActive(state, GNOMAD_SV_FEATURE),
});

const mapDispatchToProps = {
  loadDictionaries,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPresetModal);
