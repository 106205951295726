import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import LegacySapientiaTemplate from "modules/legacy/LegacySapientiaTemplate";

import * as selectors from "../selectors";

export const VariantHistoryTab = ({
  patientId,
  variantId,
  decisionTimestamp,
}) => {
  const url = `/patient/${patientId}/variant/STR/${variantId}/audit?ts=${decisionTimestamp}`;

  return (
    <div data-href={url}>
      <LegacySapientiaTemplate templateUrl={url} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  decisionTimestamp: selectors.getDecisionTimestamp,
  patientId: selectors.getPatientId,
});

export default connect(mapStateToProps)(VariantHistoryTab);
