import { isEmpty, isNil } from "ramda";
import ReactDOMServer from "react-dom/server";

import {
  OmimLinks,
  OmimLinksDividers,
} from "pattern-library/modules/variants/gene/OmimLinks";

import { Track } from "../core/Gene";
import { getEnsemblUrl } from "../utils";

const getOmimLinks = ids => {
  if (isNil(ids) || isEmpty(ids)) {
    return "-";
  }
  return ReactDOMServer.renderToStaticMarkup(
    OmimLinks({ ids, divider: OmimLinksDividers.COMMA })
  );
};

export const populateHiMenu = (feature, genome) => ({
  title: `<a target="_blank" href="https://www.genenames.org/data/gene-symbol-report/#!/symbol/${feature.name}">${feature.name}</a>`,
  Name: feature.description,
  Synonyms: feature.synonyms,
  Location: `${feature.chr}:${feature.start}-${feature.end}`,
  OMIM: getOmimLinks(feature.omim_id),
  Morbid: getOmimLinks(feature.morbid_id),
  "UCSC ID": feature.ucsc_id
    ? `<a target="_blank" href="http://genome.cse.ucsc.edu/cgi-bin/hgGene?hgg_gene=${feature.ucsc_id}">${feature.ucsc_id}</a>`
    : "-",
  Ensembl: feature.ensembl_id
    ? `<a target="_blank" href="${getEnsemblUrl(
        genome
      )}/Homo_sapiens/Gene/Summary?g=${feature.ensembl_id}">${
        feature.ensembl_id
      }</a>`
    : "-",
  Protein: feature.uniprot_id
    ? `<a target="_blank" href="http://www.uniprot.org/uniprot/${feature.uniprot_id}">${feature.uniprot_id}</a>`
    : "-",
  "%HI": feature.hi_index,
});

export const Gene = ({ genome, ensemblVersion }) => {
  const queryParam = ensemblVersion ? `&ensembl_version=${ensemblVersion}` : "";
  const ensemblVersionDisplay = ensemblVersion
    ? `Ensembl ${ensemblVersion}`
    : null;
  return Track.extend({
    url: `/API/genoverse/Gene.json?chr=__CHR__&start=__START__&end=__END__${queryParam}`,
    configName: ["Genes", ensemblVersionDisplay],
    info: "Genes, coloured by haplo-insufficiency index (HI)",
    setFeatureColor(feature) {
      feature.color = feature.hiColor || this.color;
    },
    populateMenu(feature) {
      return populateHiMenu(feature, genome);
    },
  });
};
