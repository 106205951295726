import { lte, gte, between, lt } from "../utils/functions";

import { OncologySecondaryPipelineField } from "./types";

export const NAMESPACE = "qc";

export const ONCOLOGY_SECONDARY_PIPELINE_DETAILS: Array<OncologySecondaryPipelineField> =
  [
    {
      header: "Duplicates (%)",
      description:
        "The % of reads that are discarded due to being duplicate copies of other read pairs.",
      field: "duplicatesPct",
      decimalPlaces: 1,
      message:
        "The Congenica system recommends duplicate percentages of less than 80%.",
      rule: lt(80),
    },
    {
      header: "Unique Sequence Generated (Mb)",
      description:
        "The amount of sequencing data left for analysis after duplicates have been discarded.",
      field: "uniqueSequenceGeneratedMb",
      decimalPlaces: 0,
    },
    {
      header: "Unmapped (%)",
      description:
        "The % of reads that do not map to the reference genome used for analysis. These may be due to contamination with adapter sequences, non-human DNA such as viruses or PhiX, human DNA sequences that are not currently represented in the genome or other factors.",
      field: "unmappedPct",
      decimalPlaces: 2,
      message:
        "The Congenica system requires unmapped read percentages to be below 2%.",
      rule: lte(2),
    },
    {
      header: "Discordant (%)",
      description:
        "The % of read pairs that are structurally different to the reference genome, for example due to chimeras formed during library preparation, structural polymorphisms, somatic structural variants (SVs) or other factors.",
      field: "discordantPct",
      decimalPlaces: 2,
      message:
        "The Congenica system requires discordant read percentages to be below 3%.",

      rule: lte(3),
    },
    {
      header: "Non Reference (%)",
      description:
        "The % of bases that do not match the reference genome, for example due to PCR or sequencing errors, polymorphisms, mis-mappings, mutations or other factors.",
      field: "nonReferencePct",
      decimalPlaces: 2,
    },
    {
      header: "Average Read Length (bp)",
      description:
        "The average length of sequencing reads in the input file in base pairs.",
      field: "averageReadLengthBp",
      decimalPlaces: 0,
      rule: between(74, 76),
      message:
        "The Congenica system supports average read lengths between 74 and 76 base pairs.",
    },
    {
      header: "Average Insert Size (bp)",
      description:
        "The average insert size in the sequenced library in base pairs.",
      field: "averageInsertSizeBp",
      decimalPlaces: 0,
      //rule is calculated in `perl/sapientia-frontend/src/modules/qc/components/oncology.tsx`
      //in oncologySecondaryPipelineDetails
      //because it is special rule describes
      //that the average insert size should be at least twice the read length
      message:
        "The Congenica system requires the library insert size to be at least twice the read length.",
    },
    {
      header: "Standard Deviation of the Insert Size (bp)",
      description:
        "The standard deviation of the insert size in the sequenced library in base pairs.",
      field: "stDevInsertSizeBp",
      decimalPlaces: 0,
      message:
        "The Congenica system requires the standard deviation of the library insert size to be below 100bp.",
      rule: lte(100),
    },
    {
      header: "On Target (%)",
      description:
        "The % of reads that map to the regions targeted by the sequence capture assay. Note this is calculated after duplicates are removed.",
      field: "onTargetPct",
      decimalPlaces: 0,
    },
    {
      header: "Design Size (Mb)",
      description: "The footprint of the sequence capture assay.",
      field: "designSizeMb",
      decimalPlaces: 1,
    },
    {
      header: "Average Depth",
      description:
        "The average coverage across the entire footprint of the sequence capture assay. Genes that do not meet required coverage thresholds are displayed individually in the section below.",
      field: "avgDepth",
      decimalPlaces: 0,
      message:
        "The Congenica system requires an average depth across the design of at least 100x.",
      rule: gte(100),
    },
  ];
