import React from "react";
import { connect } from "react-redux";

import LegacySapientiaTemplate from "modules/legacy/LegacySapientiaTemplate";

import * as selectors from "../selectors";

export const CommentsTab = ({ patientId, variantId }) => {
  const url = `/patient/${patientId}/variant/STR/${variantId}/comments`;

  return (
    <div className="snv_audit" data-href={url}>
      <LegacySapientiaTemplate templateUrl={url} />
    </div>
  );
};

const mapStateToProps = state => ({
  patientId: selectors.getPatientId(state),
});

export default connect(mapStateToProps)(CommentsTab);
