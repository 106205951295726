import { set } from "dot-prop-immutable";

import { Action } from "./actions";
import * as constants from "./constants";
import { ProjectState } from "./types";

export const initialState: ProjectState = {
  data: null,
  counts: undefined,
  currentUser: undefined,
};

export default function reducer(
  state: ProjectState = initialState,
  action: Action
): ProjectState {
  switch (action.type) {
    case constants.READ_PROJECT_SUCCESS:
      return set(state, "data", action.payload);
    case constants.READ_PROJECT_TAB_COUNTS_SUCCESS:
      return set(state, "counts", action.payload);
    case constants.READ_PROJECT_CURRENT_USER_SUCCESS:
      return set(state, "currentUser", action.payload);
    default:
      return state;
  }
}
