import React, { memo } from "react";

import { Link } from "pattern-library";

export interface LegacyLinkProps {
  href: string;
  attrs?: Json;
  children: Node;
}

/**
 * Link to open legacy perl modal
 */
const LegacyLink = ({ href, attrs, children }: LegacyLinkProps) => (
  <Link
    onClick={() => {
      try {
        window.Sapientia.events.loadModal(
          {
            href,
          },
          attrs
        );
      } catch (e) {
        console.error("This function is not available in standalone react app");
        console.error("Legacy modal called with href:", href);
      }
    }}
  >
    {children}
  </Link>
);

export default memo(LegacyLink);
