import React from "react";
import { Field } from "redux-form";

import { CheckboxGroupButtons, LoadingOverlay } from "pattern-library";

import ReduxFormField from "modules/forms/components/ReduxFormField";

import { CheckboxOption } from "../../../utils/prop-types/CheckboxOptionProp";
import * as constants from "../../constants";

import congenicaApi from "api/congenica-api";
import { useEnsemblVersion } from "hooks/useEnsemblVersion";

const ensemblVersionMap: Record<string, string> = {
  "81": "https://may2015.archive.ensembl.org/info/genome/variation/predicted_data.html#consequence_type_table",
  "90": "https://apr2018.archive.ensembl.org/info/genome/variation/predicted_data.html#consequence_type_table",
  "108":
    "https://oct2022.archive.ensembl.org/info/genome/variation/prediction/predicted_data.html#consequence_type_table",
};
const DEFAULT_VEP_LINK =
  "https://www.ensembl.org/info/genome/variation/prediction/predicted_data.html#consequence_type_table";

const { useGetVEPConsequencesQuery } = congenicaApi;

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
  vepConsequences: CheckboxOption[];
  ensemblVersion?: string;
  change: any;
  patientId?: number;
};

export const ConsequencesForm: React.FC<Props> = ({
  disabled = false,
  isLoading = false,
  vepConsequences,
  ensemblVersion,
  patientId,
  change,
}) => {
  const clearChecked = () => {
    change(constants.CONSEQUENCE_FIELD, []);
  };

  const selectAll = () => {
    change(
      constants.CONSEQUENCE_FIELD,
      vepConsequences.map(({ key }) => key)
    );
  };

  const vepInfo = (version: string, patientSpecific: boolean) => (
    <div>
      <h4>Note:</h4>
      <em>
        {patientSpecific ? (
          <div>This patient was processed with Ensembl release {version}.</div>
        ) : null}
        <span>
          We use the Variant Effect Predictor to annotate variants against all
          Ensembl and RefSeq transcripts. For more information please visit the{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={ensemblVersionMap[version] || DEFAULT_VEP_LINK}
          >
            VEP documentation
          </a>{" "}
          for the closest matching release of Ensembl.
        </span>
      </em>
    </div>
  );

  return (
    <LoadingOverlay loading={isLoading}>
      <fieldset disabled={disabled} data-testid="config-consequence-form">
        <div className="row">
          <CheckboxGroupButtons
            onSelect={selectAll}
            onClear={clearChecked}
            className="col-xs-3"
          />
          <Field
            component={ReduxFormField}
            type="checkboxgroup"
            label="VEP Consequence"
            name="vepConsequence"
            options={vepConsequences}
            preserveOptionsOrderInValue
            className="col-xs-9"
            narrow
          />
        </div>
        {ensemblVersion ? vepInfo(ensemblVersion, Boolean(patientId)) : null}
      </fieldset>
    </LoadingOverlay>
  );
};

const ConnectedConsequencesForm: React.FC<Props> = props => {
  const { data: vepConsequences, isLoading } = useGetVEPConsequencesQuery();
  const { ensemblVersion, isLoading: isEnsemblVersionLoading } =
    useEnsemblVersion(props.patientId);

  return (
    <ConsequencesForm
      {...props}
      isLoading={isLoading || isEnsemblVersionLoading}
      vepConsequences={vepConsequences || []}
      ensemblVersion={ensemblVersion}
    />
  );
};

export default ConnectedConsequencesForm;
