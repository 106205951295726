import { fetchCamelizeData, checkResponse, fetchData } from "modules/utils";
import {
  API_ENTITIES_BASE_URL,
  CATALYST_BASE_URL,
} from "modules/utils/baseUrls";

import { camelizeObject } from "../utils/common";

export const getPatientInfo = async patientId => {
  const { payload } = await checkResponse(
    fetchData,
    `Cannot load patient with patientId ${patientId}`
  )(`${CATALYST_BASE_URL}/patient/${patientId}`);

  return camelizeObject(payload, "patient.thirdparty_software_versions");
};

/**
 * @deprecated Use RTK Query whenever possible instead of this method
 */
export const getPatientTrackInfo = patientId =>
  fetchCamelizeData(`${API_ENTITIES_BASE_URL}/patients/${patientId}`);

export const getPatientReports = patientId =>
  fetchCamelizeData(`${API_ENTITIES_BASE_URL}/patients/${patientId}/reports`);
