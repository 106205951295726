import { path, uniq } from "ramda";
import { change } from "redux-form";
import { call, put, select, take, takeEvery } from "redux-saga/effects";

import { getPatientTrackInfo as getPatientData } from "modules/api/patients";
import { ARIADNE_KEYS, ARIADNE_VALUES } from "modules/ariadne/constants";
import { getFormValues } from "modules/forms/selectors";
import { error } from "modules/messages/actions";
import { ReadPatientSuccessAction } from "modules/patient";
import { READ_PATIENT_SUCCESS } from "modules/patient/constants";
import * as patientSelectors from "modules/patient/selectors";
import { flattenApiEntity } from "modules/utils/formatApiResponse";
import * as variantsActions from "modules/variants/actions";

import { getOrReloadCurrentProject } from "../project/saga";
import { getCurrentProject } from "../project/selectors";
import type { SelectSNVPresetAction } from "../sequenceVariantsPresets/actions";
import * as snvPresetActions from "../sequenceVariantsPresets/actions";
import * as snvPresetsConstants from "../sequenceVariantsPresets/constants";
import { loadSNVPresets } from "../sequenceVariantsPresets/saga";
import * as snvPresetsSelectors from "../sequenceVariantsPresets/selectors";
import { getDefaultPreset } from "../utils/filter-presets";

import * as actions from "./actions";
import type {
  ApplyAriadneSuggestions,
  ResetConfigAction,
  UpdateConfigAction,
} from "./actions";
import {
  APPLY_ARIADNE_SUGGESTIONS,
  RESET_CONFIG,
  SNV_TABLE_CONFIG_FORM,
  UPDATE_CONFIG,
  FETCH_PATIENT_CONFIG_ENTITY_START,
} from "./constants";
import { CONSEQUENCES_TABLE_KEY, TRANSCRIPT_KEY } from "./reducer.data";
import { getConfigExclusions, getValues } from "./selectors";
import type { ConfigFormValues, ConfigValues } from "./types";
import {
  convertFormValuesToFilters,
  isFormPristine,
  snvPresetToConfig,
} from "./utils";

export function* init(): Generator<any, any, any> {
  yield takeEvery(UPDATE_CONFIG, updateConfig);
  yield takeEvery(FETCH_PATIENT_CONFIG_ENTITY_START, fetchPatientConfigEntity);
  yield takeEvery(RESET_CONFIG, resetConfig);
  yield takeEvery(snvPresetsConstants.SELECT_SNV_PRESET, presetChanged);
  yield takeEvery(APPLY_ARIADNE_SUGGESTIONS, applyAriadneSuggestions);
}

export function* fetchPatientConfigEntity(
  action: ReturnType<typeof actions.fetchPatientEntity.start>
): Generator<any, void, any> {
  const { patientId } = action.payload;
  try {
    const result = yield call(getPatientData, patientId);
    if (result.ok) {
      const { payload } = result;

      const patient = flattenApiEntity(payload);
      yield put(actions.fetchPatientEntity.success(patient));
      return;
    }

    yield put(actions.fetchPatientEntity.failure());
  } catch ({ message }) {
    yield put(actions.fetchPatientEntity.failure());
    yield put(error(message));
  }
}

export function* updateConfig(
  action: UpdateConfigAction
): Generator<any, void, any> {
  const formValues: ConfigValues = yield call(
    getConfigValues,
    SNV_TABLE_CONFIG_FORM
  );

  const stateValues: ConfigValues = yield select(getValues);

  if (isFormPristine(stateValues, formValues)) {
    return;
  }

  if (window.unmountACMGComponents) {
    window.unmountACMGComponents();
  }

  yield put(actions.updateConfigValues(formValues));
  yield put(variantsActions.resetVariantPanel());
  yield put(variantsActions.setPage(1));
  const { prioritisation, filters }: ConfigValues = formValues;
  yield put(
    variantsActions.reloadVariants(action.meta.patientId, {
      filters,
      prioritisation,
    })
  );
}

export function* presetChanged(
  action: SelectSNVPresetAction
): Generator<any, void, any> {
  if (window.unmountACMGComponents) {
    window.unmountACMGComponents();
  }

  const {
    payload: { preset },
    meta: { patientId },
  } = action;
  yield put(variantsActions.resetVariantPanel());
  yield put(variantsActions.setPage(1));
  const patient = yield select(patientSelectors.getSinglePatient, patientId);
  const project = yield select(getCurrentProject);
  const config = yield call(applyPreset, preset, project, patient);
  const { filters, prioritisation } = config;
  yield put(
    variantsActions.reloadVariants(action.meta.patientId, {
      filters,
      prioritisation,
    })
  );
}

export function* getConfigValues(
  formName: string
): Generator<any, ConfigValues, any> {
  const configValues: ConfigFormValues = yield select(getFormValues, formName);

  const { columns, prioritisation, ...rawFilters } = configValues;

  return {
    filters: convertFormValuesToFilters(rawFilters),
    prioritisation,
    ...columns,
  };
}

export function* applyPreset(
  preset: SNVPreset,
  project: Project,
  patient
): Generator<any, ConfigValues, any> {
  const { patientId } = patient;
  const configExclusions = yield select(getConfigExclusions, patientId);
  const result = snvPresetToConfig(preset, project, patient, configExclusions);
  yield put(actions.updateConfigValues(result));
  return result;
}

export function* getExaminedPatient(
  patientId: number
): Generator<any, any, any> {
  const patient = yield select(patientSelectors.getSinglePatient, patientId);
  if (patient && patient.patientId) {
    return patient;
  }

  const action: ReadPatientSuccessAction = yield take(READ_PATIENT_SUCCESS);
  return action.payload;
}

export function* loadPresets(patientId: number): Generator<any, void, any> {
  const patient = yield call(getExaminedPatient, patientId);
  const { projectId } = patient;
  const project = yield call(getOrReloadCurrentProject, projectId);
  const snvPresetsResult = yield call(loadSNVPresets, projectId) || {};
  if (!snvPresetsResult.ok) {
    return;
  }
  const presets: SNVPreset[] = path(["payload", "data"], snvPresetsResult);
  if (!presets || !presets.length) {
    return;
  }
  const defaultPreset: SNVPreset = getDefaultPreset(presets);

  yield call(applyPreset, defaultPreset, project, patient);
}

export function* resetConfig(
  action: ResetConfigAction
): Generator<any, any, any> {
  if (window.unmountACMGComponents) {
    window.unmountACMGComponents();
  }
  const {
    meta: { patientId },
  } = action;
  const defaultPreset = yield select(snvPresetsSelectors.getDefaultPreset);
  yield put(snvPresetActions.selectSNVPreset(defaultPreset, patientId));
}

export function* applyAriadneSuggestions({
  payload: { keepGenePanels, patientId },
}: ApplyAriadneSuggestions): Generator<any, any, any> {
  const { consequenceColumns, filters, variantColumns }: ConfigValues =
    yield select(getValues);
  const defaultPreset = yield select(snvPresetsSelectors.getDefaultPreset);

  const defaultPresetFilters = defaultPreset.attributes.config.filters;

  // Get an object with all filter keys between the default preset and the current settings
  const combinedFilters = {
    ...defaultPresetFilters,
    ...filters,
  };

  const filtersToResetTo: any = Object.keys(combinedFilters).reduce(
    (newFilterObject, key) => {
      newFilterObject[key] =
        defaultPresetFilters[key] || (Array.isArray(filters[key]) ? [] : "");
      return newFilterObject;
    },
    {}
  );

  if (keepGenePanels) {
    filtersToResetTo.genePanels = filters.genePanels;
  } else {
    const patient = yield select(patientSelectors.getSinglePatient, patientId);

    filtersToResetTo.genePanels = patient.genePanels.map(
      ({ genePanelId }) => genePanelId
    );
  }

  // Tell the form to reset back to the correct values for the default preset,
  // plus any gene panels changes
  for (const key of Object.keys(filtersToResetTo)) {
    const filter = filtersToResetTo[key];
    yield put(change(SNV_TABLE_CONFIG_FORM, key, filter));
  }

  // Need to filter by P & LP Ariadne prediction.
  yield put(
    change(SNV_TABLE_CONFIG_FORM, ARIADNE_KEYS.PREDICTED_PATHOGENICITY, [
      ARIADNE_VALUES.PREDICTED_PATHOGENICITY.PATHOGENIC,
      ARIADNE_VALUES.PREDICTED_PATHOGENICITY.LIKELY_PATHOGENIC,
    ])
  );

  yield put(
    change(SNV_TABLE_CONFIG_FORM, "prioritisation", [
      ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING,
    ])
  );

  // Add the per-transcript columns onto the active filters,
  // leaving them in the current place if already present,
  // or adding onto the end
  const adjustedVariantColumns = (() => {
    if (variantColumns.includes(CONSEQUENCES_TABLE_KEY)) {
      return variantColumns;
    }

    return [...variantColumns, CONSEQUENCES_TABLE_KEY];
  })();

  yield put(
    change(SNV_TABLE_CONFIG_FORM, "columns", {
      variantColumns: adjustedVariantColumns,
      consequenceColumns: uniq([
        ...consequenceColumns,
        // Ensure that the transcript is always shown when applying Congenica AI filters
        TRANSCRIPT_KEY,
        ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING,
        ARIADNE_KEYS.PREDICTED_PATHOGENICITY,
        ARIADNE_KEYS.PREDICTED_C2P,
        ARIADNE_KEYS.PREDICTED_C2P_EXTENDED,
      ]),
    })
  );

  yield put(actions.updateConfig(patientId));
}
