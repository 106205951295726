// @flow
import { set } from "dot-prop-immutable";
import { isNil } from "ramda";

import {
  ALREADY_DELETED,
  STATUSES,
} from "modules/projectInterpretationRequests/constants";

import type { Action } from "./actions";
import { actionType } from "./actions";

type State = {
  +ui: {
    +loading: boolean,
    +deleting: boolean,
    projectIRForDelete?: boolean,
  },
  data: ProjectInterpretationRequestsInfo,
};

export const initialState: State = {
  ui: {
    loading: false,
    deleting: false,
  },
  data: {
    irs: [],
    patients: {},
    audit: {},
    dataFiles: {},
  },
};

export default function reducer(
  state: State = initialState,
  { type, payload }: Action
): State {
  switch (type) {
    case actionType.FETCH_PROJECT_IRS_START:
    case actionType.FETCH_PROJECT_IRS_FAILURE:
      return set(state, "data", []);

    case actionType.FETCH_PROJECT_IRS_SUCCESS:
      return set(state, "data", payload);

    case actionType.SET_PROJECT_IR_FOR_DELETE:
      if (isNil(payload)) {
        return set(state, "ui.projectIRForDelete", undefined);
      } else {
        return set(state, "ui.projectIRForDelete", { id: payload, reason: "" });
      }

    case actionType.SET_PROJECT_IRS_LOADING:
      return set(state, "ui.loading", payload);

    case actionType.SET_PROJECT_IR_DELETING:
      return set(state, "ui.deleting", payload);

    case actionType.RELOAD_PROJECT_IR: {
      const irs = [...state.data.irs];
      const {
        interpretationRequestId,
        lastAuditHash,
        lastAuditHash: { status },
      } = payload;
      const audit = { ...state.data.audit };
      delete audit[interpretationRequestId];

      const idx = irs.findIndex(({ id }) => interpretationRequestId === id);
      if (idx === -1) {
        return state;
      }
      irs[idx] = {
        ...irs[idx],
        status,
        lastAudit: {
          ...lastAuditHash,
        },
        canDelete: { ...ALREADY_DELETED },
      };
      return {
        ...state,
        data: {
          ...state.data,
          audit,
          irs,
        },
      };
    }

    case actionType.REFRESH_IR_STATUS_SUCCESS:
      const {
        irId,
        dataFilesStatus,
      }: { irId: number, dataFilesStatus: DataFilesStatus } = payload;
      const irDataFiles = { ...state.data.dataFiles[`${irId}`] };
      if (!irDataFiles) {
        return state;
      }

      let needToChangeState = false;

      for (const [
        key,
        {
          //$FlowFixMe
          status,
          //$FlowFixMe
          lastRecordedSftpTransferProgressPercent: transferProgress,
          //$FlowFixMe
          lastRecordedSftpTransferErrorMessage: transferError,
          //$FlowFixMe
          size,
        },
      ] of Object.entries(dataFilesStatus)) {
        const dataFile = irDataFiles[key];
        if (
          dataFile &&
          (dataFile.status !== status ||
            dataFile.transferProgress !== transferProgress ||
            dataFile.transferError !== transferError ||
            dataFile.size !== size)
        ) {
          irDataFiles[key] = {
            ...dataFile,
            status,
            transferProgress,
            transferError,
            size,
          };
          needToChangeState = true;
        }
      }

      return needToChangeState
        ? set(state, `data.dataFiles.${irId}`, irDataFiles)
        : state;

    case actionType.RESET_DATA_FILE_SUCCESS:
      const dataFiles = { ...state.data.dataFiles };
      for (const [key, irDataFiles] of Object.entries(dataFiles)) {
        //$FlowFixMe
        const dataFile = irDataFiles[payload];
        if (dataFile) {
          return set(state, `data.dataFiles.${key}.${payload}`, {
            ...dataFile,
            status: STATUSES.PROMISED,
          });
        }
      }
      return state;

    default:
      return state;
  }
}
