import { Field } from "formik";
import React, { PureComponent } from "react";

import { Divider } from "pattern-library";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

export default class Miscellaneous extends PureComponent {
  render() {
    const { isInherited } = this.props;

    return (
      <div className="form-horizontal">
        <Field
          name="forceDeleteProjects.enabled"
          label="Skip checks when deleting project"
          narrow={9}
          disabled={isInherited}
          description="Ignore the usual checks when deleting a project and delete it completely"
          type="checkbox"
          component={FormikFormField}
        />
        <Divider />
        <Field
          name="maxAfFilter.loadLimit"
          label="MAF loading limit"
          narrow={9}
          title="Maximum Allele Frequency loading limit"
          disabled={isInherited}
          description="Do not load into the database any SNVs whose maximum allele frequency exceeds the limit specified. (Default: 1)"
          type="number"
          min="0"
          step="0.1"
          max="1"
          component={FormikFormField}
        />
      </div>
    );
  }
}
