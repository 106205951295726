import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import * as yup from "yup";

import { Button, LoadingOverlay } from "pattern-library";

import ReduxFormField from "modules/forms/components/ReduxFormField";
import validator from "modules/forms/validator";

import { clearAddState, addNewGenePanel } from "../../../actions";
import { ADD_NEW_GENE_PANEL_FORM_NAME } from "../../../constants";
import {
  isNewGenePanelAdded,
  isNewGenePanelHasError,
} from "../../../selectors";

import { useEnsemblOptions } from "hooks/useEnsemblOptions";

export const REQUIRED_TITLE_MESSAGE = "Gene panel field is required";
export const REQUIRED_ENSEMBL_MESSAGE = "Ensembl version field is required";

export const validationSchema = yup.object({
  title: yup.string().trim().required(REQUIRED_TITLE_MESSAGE),
  ensemblVersion: yup.string().required(REQUIRED_ENSEMBL_MESSAGE),
});

type AddNewGenePanelTabProps = PropsFromRedux & {
  projectId: number;
  onClose: () => void;
};

const AddNewGenePanelTab = ({
  projectId,
  handleSubmit,
  onClose,
  pristine,
  wasAdded,
  addingHasError,
  clearAddState,
  addNewGenePanel,
  initialize,
}: AddNewGenePanelTabProps & InjectedFormProps) => {
  const { ensemblOptions = [], isLoading } = useEnsemblOptions();

  useEffect(() => {
    if (wasAdded && !addingHasError) {
      clearAddState();
      initialize({});
      onClose();
    }
  }, [wasAdded, addingHasError, clearAddState, initialize, onClose]);

  const onFormSubmit = data =>
    addNewGenePanel(
      projectId,
      data.title,
      data.description,
      data.ensemblVersion
    );

  return (
    <LoadingOverlay
      data-testid="add-gene-panel-tab-loading-overlay"
      loading={isLoading}
    >
      <div className="tab-content">
        <div className="form-container">
          {addingHasError}
          <form
            className="form-horizontal"
            id="gene_panel"
            onSubmit={handleSubmit(onFormSubmit)}
          >
            {addingHasError && (
              <div className="alert alert-danger">
                <span className="error_message">
                  The form submission contained errors.
                </span>
              </div>
            )}

            <fieldset>
              <Field
                component={ReduxFormField}
                type="text"
                name="title"
                label="Gene panel"
                required
                id="title"
                maxLength="255"
                narrow
              />
              <Field
                component={ReduxFormField}
                type="textarea"
                label="Description"
                name="description"
                id="description"
                narrow
                rows="5"
                cols="10"
              />
              {ensemblOptions.length > 1 && (
                <Field
                  label="Ensembl version"
                  name="ensemblVersion"
                  component={ReduxFormField}
                  type="dropdown"
                  required
                  narrow
                  options={ensemblOptions}
                />
              )}
            </fieldset>
            <div className="pull-right">
              <Button type="button" context="default" onClick={onClose}>
                Close
              </Button>{" "}
              <Button type="submit" disabled={pristine} context="primary">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = state => ({
  wasAdded: isNewGenePanelAdded(state),
  addingHasError: isNewGenePanelHasError(state),
});

const mapDispatchToProps = {
  clearAddState,
  addNewGenePanel: addNewGenePanel.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default reduxForm<Index, AddNewGenePanelTabProps>({
  form: ADD_NEW_GENE_PANEL_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
  asyncValidate: validator(validationSchema),
})(connector(AddNewGenePanelTab));
