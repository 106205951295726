import type { Action } from "./actions";
import { fetchLicences } from "./actions";
import { State } from "./types";

export const initialState: State = {
  loading: false,
  data: [],
};

export default function reducer(
  state: State = initialState,
  { type, payload }: Action
): State {
  switch (type) {
    case fetchLicences.START_ACTION:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case fetchLicences.FAILURE_ACTION:
      return {
        ...state,
        data: [],
        loading: false,
      };
    case fetchLicences.SUCCESS_ACTION:
      const licences = payload as Array<SoftwareLicence>;

      return {
        ...state,
        data: licences || [],
        loading: false,
      };

    default:
      return state;
  }
}
