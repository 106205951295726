import PropTypes from "prop-types";
import React, { useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LoadingOverlay, Link, Icon } from "pattern-library";
import { Table, DEFAULT_PAGE_SIZE } from "pattern-library/elements/table7";

import * as actions from "modules/patients/actions";
import * as selectors from "modules/patients/selectors";
import { formatDate } from "modules/utils";

import { getPatientsExportFilteredData } from "./selectors";

export const PatientExportContainer = ({
  projectId,
  initialise,
  patientData = [],
  setPatientsFilter,
  loading,
}) => {
  const getActions = useCallback(
    patient => (
      <div className="btn-group">
        <Link
          className="btn btn-default btn-sm"
          role="button"
          href={`/project/${projectId}/admin/settings/export/patient/${patient.patientId}/report`}
          disabled={!patient.hasReports}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="file" /> Download Report
        </Link>
        <Link
          className="btn btn-default btn-sm"
          role="button"
          href={`/project/${projectId}/admin/settings/export/patient/${patient.patientId}.json`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon type="export" /> Download Patient JSON
        </Link>
      </div>
    ),
    [projectId]
  );

  const patientColumns = useMemo(
    () => [
      {
        Header: "Patient",
        accessor: "reference",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Last Updated",
        accessor: "updated",
        Cell: ({
          row: {
            original: { updated },
          },
        }) => formatDate(updated),
        sortType: "datetime",
      },
      {
        Header: "Actions",
        accessor: patient => getActions(patient),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    [getActions]
  );

  useEffect(() => {
    initialise(projectId);
    setPatientsFilter("");
  }, [projectId, initialise, setPatientsFilter]);

  const handleSetPatientFilter = useCallback(
    ({ filter = "" }) => {
      setPatientsFilter(filter);
    },
    [setPatientsFilter]
  );

  return (
    <LoadingOverlay loading={loading}>
      <Table
        data={patientData}
        columns={patientColumns}
        loading={loading}
        enableFilter
        fetchData={handleSetPatientFilter}
        title="Export patients"
        showPagination={patientData.length > DEFAULT_PAGE_SIZE}
        sortBy={[
          {
            id: "reference",
          },
        ]}
      />
    </LoadingOverlay>
  );
};

PatientExportContainer.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  initialise: PropTypes.func,
  setPatientsFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  patientData: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  patientData: getPatientsExportFilteredData,
  loading: selectors.isLoading,
});

const mapDispatchToProps = {
  initialise: actions.initialise,
  setPatientsFilter: actions.setPatientsFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientExportContainer);
