import classNames from "classnames";
import { Field, FieldArray } from "formik";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Icon, Link, LoadingOverlay } from "pattern-library";

import { FormikFormField } from "modules/forms/components";
import { hasMetadataRequestFailed } from "modules/metadata/selectors";
import {
  getDefaultSampleFilesRootFolder,
  isOnPrem,
} from "modules/systemConfig/selectors";

import { initIR } from "../actions";
import {
  filesProcessing as filesProcessingSelector,
  hasProjectRequestFailed,
  isIRFullSubmissionCompleted,
  isIRFullSubmissionSuccessful,
  isIRRequestSuccessful,
  isIRv2,
} from "../selectors";

import CNVFailedModal from "./CNVFailedModal";
import FileChecklist from "./FileChecklist";
import IRSubmittedModal from "./IRSubmittedModal";
import IRSubmittedToolbar from "./IRSubmittedToolbar";
import OnPremFileChecklist from "./OnPremFileChecklist";
import { TableForm } from "./TableForm";
import WarningsModal from "./WarningsModal";
import { default as OncologyFileChecklist } from "./oncology/FileChecklist";

export const IRFormGrid = ({
  values: { samples = [] },
  errors,
  handleSubmit,
  handleReset,
  isValid,
  isSubmitting,
  isIRRequestSuccessful,
  isIRFullSubmissionSuccessful,
  isIRFullSubmissionCompleted,
  setSubmitting,
  resetForm,
  projectId,
  initIR,
  isDataLoadError,
  isIRv2,
  isOnPrem,
  rootFolder,
  filesProcessing,
}) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (isIRFullSubmissionSuccessful) {
      window.onbeforeunload = undefined;
      setShowSuccessModal(true);
    }
  }, [isIRFullSubmissionSuccessful]);

  useEffect(() => {
    if (isIRFullSubmissionCompleted) {
      // mark Formik form submission end
      setSubmitting(false);
    }
  }, [isIRFullSubmissionCompleted, setSubmitting]);

  const closeIRSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const toggleWarningsModal = () => {
    setShowWarningModal(!showWarningModal);
  };

  const onSubmit = e => {
    handleSubmit(e);
    if (!isValid) {
      toggleWarningsModal();
    }
  };

  const createNewIR = React.useCallback(() => {
    resetForm(); // reset Formik form initialValues
    initIR(projectId); // reset request submission status, project, metadata, etc
  }, [resetForm, initIR, projectId]);

  // The submit button should be disabled if
  // - IR supporting data (project, metadata, etc.) is not loaded
  // - submission is in progress
  // - CNV was not requested and an IR request is successful
  // - CNV was requested and IR+CNV requests are both successful.
  const submitDisabled =
    isDataLoadError || isSubmitting || isIRFullSubmissionSuccessful;

  // The fields used to create an IR should be disabled if
  // - rules above for disabling the submit button apply
  // - if IR submission is successful and CNV failed.
  // note: CNVCallingForm has some specific rules
  const fieldsDisabled = submitDisabled || isIRRequestSuccessful;

  return (
    <form onReset={handleReset} onSubmit={onSubmit}>
      {isIRFullSubmissionSuccessful && (
        <IRSubmittedModal
          show={showSuccessModal}
          close={closeIRSuccessModal}
          samples={samples}
          createNewIR={createNewIR}
        />
      )}
      <IRSubmittedToolbar
        projectId={projectId}
        show={isIRFullSubmissionSuccessful}
        createNewIR={createNewIR}
      />
      <LoadingOverlay loading={isSubmitting || filesProcessing}>
        <div className="upload-grid">
          <div
            className={classNames("ir-content", {
              "ir-content-oncology": isIRv2,
            })}
          >
            <div className="ir-reference">
              <Field
                component={FormikFormField}
                type="text"
                name="identifier"
                id="ir_identifier"
                label="IR Reference"
                disabled={fieldsDisabled}
              />
            </div>
            <FieldArray name="samples">
              {props => (
                <TableForm
                  {...props}
                  fieldsDisabled={fieldsDisabled}
                  projectId={projectId}
                />
              )}
            </FieldArray>
            {isIRv2 && (
              <>
                <div className="gap" />
                <Link
                  className="ir-content-oncology-link_to_oncotree_page"
                  target="_blank"
                  href="http://oncotree.mskcc.org/#/home?version=oncotree_2019_12_01"
                >
                  <Icon type="newWindow" />
                  Explore Oncotree tumour types and codes
                </Link>
              </>
            )}
          </div>

          {isIRv2 && <OncologyFileChecklist samples={samples} />}
          {!isIRv2 && !isOnPrem && <FileChecklist samples={samples} />}
          {!isIRv2 && isOnPrem && (
            <OnPremFileChecklist samples={samples} rootFolder={rootFolder} />
          )}
        </div>
      </LoadingOverlay>
      {!isIRFullSubmissionSuccessful && (
        <div className="button-panel">
          <button
            type="submit"
            disabled={submitDisabled || !samples.length || filesProcessing}
            className="btn btn-primary pull-right"
          >
            Submit IR
          </button>
        </div>
      )}
      <IRSubmittedToolbar
        projectId={projectId}
        show={isIRFullSubmissionSuccessful}
        createNewIR={createNewIR}
      />
      <WarningsModal
        closeModal={toggleWarningsModal}
        show={showWarningModal}
        errors={errors}
      />
      {isIRv2 === false && <CNVFailedModal />}
    </form>
  );
};

IRFormGrid.propTypes = {
  /**
   * Formik form value object
   */
  values: PropTypes.shape({
    samples: PropTypes.array,
  }).isRequired,
  /**
   * Formik errors object
   */
  errors: PropTypes.object,
  /**
   * Formik submit function
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Formik form validation result
   */
  isValid: PropTypes.bool.isRequired,
  /**
   * Formik property automatically set to true when submit is started.
   * For synchronous forms has to be set back to false manually when submission is done
   */
  isSubmitting: PropTypes.bool.isRequired,
  /**
   * true if IR submission finished (failed or succeeded) and CNV calling finished or was skipped
   */
  isIRFullSubmissionCompleted: PropTypes.bool.isRequired,
  /**
   * indicates if two stage submit process was successful
   * (IR and CNV requests succeed, or IR succeeds and CNV is skipped)
   */
  isIRFullSubmissionSuccessful: PropTypes.bool.isRequired,
  /**
   * indicates if the first part of the submission (IR creation) was successful
   */
  isIRRequestSuccessful: PropTypes.bool.isRequired,
  /**
   * Formik function that allows to manually control isSubmitting value
   */
  setSubmitting: PropTypes.func.isRequired,
  /**
   * reset Formik forms
   */
  resetForm: PropTypes.func.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  /**
   * the action that triggers IR supporting data (e.g. Project metadata) to load
   */
  initIR: PropTypes.func.isRequired,
  /**
   * indicates if any IR supporting data (Project, Project Metadata) wasn't loaded
   */
  isDataLoadError: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isIRRequestSuccessful: isIRRequestSuccessful(state),
  isIRFullSubmissionSuccessful: isIRFullSubmissionSuccessful(state),
  isIRFullSubmissionCompleted: isIRFullSubmissionCompleted(state),
  isDataLoadError:
    hasMetadataRequestFailed(state) || hasProjectRequestFailed(state),
  isIRv2: isIRv2(state),
  isOnPrem: isOnPrem(state),
  rootFolder: getDefaultSampleFilesRootFolder(state),
  filesProcessing: filesProcessingSelector(state),
});

const mapDispatchToProps = {
  initIR,
};

export const IRFormGridMemoized = memo(IRFormGrid);

export default connect(mapStateToProps, mapDispatchToProps)(IRFormGrid);
