import {
  all,
  select,
  put,
  take,
  takeEvery,
  call,
  takeLatest,
} from "redux-saga/effects";

import { CREATE_ORM_VARIANT_OLD } from "data-layer/orm/variant";
import { readVariantOld } from "data-layer/orm/variant/actions";
import createSaga from "data-layer/utils/createSaga";
import {
  getAcmgSuggestedCriteria,
  setAcceptedAcmgSuggestedCriteria,
  setRejectedAcmgSuggestedCriteria,
} from "modules/api/acmgSuggestedCriteria";
import { CATALYST_BASE_URL } from "modules/utils/baseUrls";

import { deleteVariantDecision } from "../decisions/actions";
import { getFormValues } from "../forms/selectors";
import { createFormTypeString } from "../forms/utils";
import { error as errorMessage, errors, success } from "../messages/actions";
import { fetchDecisionsForPatient } from "../variants/saga";

import * as actions from "./actions";
import * as constants from "./constants";
import * as selectors from "./selectors";
import { getSingleACMGCriteria } from "./selectors";

export function* checkForOutOfSyncErrors(action = {}): boolean {
  const outOfStateErrors = [
    "ACMG decision already unlocked",
    "ACMG decision locked",
    "ACMG decision already locked",
    "Criteria already added",
    "Decision already made on transcript id",
  ];

  if (action.response && action.response.error) {
    const { error } = action.response;

    const isAnOutOfSyncError = outOfStateErrors.filter(item =>
      error.includes(item)
    );

    if (isAnOutOfSyncError.length) {
      // Call the api and refetch the variant data
      const { patientId, variantId } = action;
      yield put(readVariantOld(patientId, variantId));

      // We want to report out of sync errors with a better message to the user
      yield put(
        errorMessage(
          "Someone else is currently editing this variant, we have updated things to match their updates, as a result your changes may not have taken effect"
        )
      );

      // Wait for the api call to be made successfully
      yield take(CREATE_ORM_VARIANT_OLD);

      // Return true so the other saga's know we are out of state and it's been corrected
      return true;
    } else {
      // This is some other unknown error, report normally
      yield put(errorMessage(error));
      // Return false so that other saga's continue normally and assume we aren't out of state
      return false;
    }
  }
}

export function* toggleNoEvidence(action) {
  const {
    meta: { variantId, categoryId, patientId },
  } = action;
  const patientVariant = yield select(selectors.getCurrentPatientVariant);
  const { noEvidenceCategories = [] } = patientVariant;
  let method = "POST";
  let resultCategories = null;

  if (noEvidenceCategories.includes(categoryId)) {
    method = "DELETE";
    resultCategories = noEvidenceCategories.filter(id => id !== categoryId);
  } else {
    resultCategories = [...noEvidenceCategories, categoryId];
  }

  yield put(
    actions.updateVariantACMG({
      ...patientVariant,
      noEvidenceCategories: resultCategories,
    })
  );

  yield call(
    fetch,
    `/catalyst/api/patient/${patientId}/variant/${variantId}/acmg/category/${categoryId}/no-evidence`,
    {
      method,
      credentials: "include",
    }
  );
}

const retrieveClassification = (response, patientVariant) =>
  response ? response.classification : patientVariant.classification;

export function* getUpdatedCriteria(patientVariant, formValues, criteriaId) {
  const currentCriteria = yield select(getSingleACMGCriteria, criteriaId);
  const criteriaCopy = patientVariant.selectedCriteria || [];
  const ind = patientVariant.selectedCriteria.findIndex(
    c => c.criteriaId === currentCriteria.id
  );

  if (formValues.criteriaStrength !== currentCriteria.strength.id) {
    const selectedStrength = yield select(
      selectors.getStrength,
      formValues.criteriaStrength
    );
    currentCriteria.strength = selectedStrength;
  }
  const update = {
    comment: formValues.comment,
    criteriaId,
    strength: currentCriteria.strength,
    description: currentCriteria.description,
  };

  if (ind !== -1) {
    criteriaCopy[ind] = update;
  } else {
    criteriaCopy.push(update);
  }

  return criteriaCopy;
}

export function* submitCriteria(patientVariant, requestBody, method) {
  const response = yield call(
    fetch,
    `/catalyst/api/patient/${patientVariant.patientId}/variant/${patientVariant.id}/acmg/criteria`,
    {
      method,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }
  );

  const result = yield call([response, response.json]);
  return result;
}

export function* addOrUpdateCriteria(action = { payload: {} }) {
  yield put(actions.setClassificationLoading());

  const {
    payload: { criteriaId = false },
  } = action;
  const patientVariant = yield select(selectors.getCurrentPatientVariant);
  const formValues = yield select(getFormValues, "addCriteria");

  // close the modal
  yield put(actions.resetCriteriaToAdd());

  const currentCriteria = yield select(
    selectors.getSingleACMGCriteria,
    criteriaId
  );
  if (formValues.criteriaStrength !== currentCriteria.strength.id) {
    const selectedStrength = yield select(
      selectors.getStrength,
      formValues.criteriaStrength
    );
    currentCriteria.strength = selectedStrength;
  }

  const criteriaExists: boolean = !!patientVariant.selectedCriteria.find(
    item => item.criteriaId === criteriaId
  );

  const method = criteriaExists ? "PUT" : "POST";

  const response = yield call(
    submitCriteria,
    patientVariant,
    {
      code: criteriaId,
      comment: formValues.comment,
      strength: currentCriteria.strength.name,
    },
    method
  );

  const updatedCriteria = yield call(
    getUpdatedCriteria,
    patientVariant,
    formValues,
    criteriaId
  );
  yield put(
    actions.updateVariantACMG({
      ...patientVariant,
      classification: retrieveClassification(response, patientVariant),
      selectedCriteria: updatedCriteria,
    })
  );
  yield put(actions.setClassificationLoaded());
}

const getCriteriaAfterDeletion = (patientVariant, criteriaId) => {
  const selected = patientVariant.selectedCriteria;
  const deletionIndex = selected.findIndex(c => c.criteriaId === criteriaId);
  const firstArr = selected.slice(0, deletionIndex);
  const secondArr = selected.slice(deletionIndex + 1);
  return [...firstArr, ...secondArr];
};

export function* removeCriteria(action = {}) {
  yield put(actions.setClassificationLoading());

  const {
    payload: { criteriaId },
  } = action;
  const patientVariant = yield select(selectors.getCurrentPatientVariant);

  const criteria = patientVariant.selectedCriteria;

  const indexOfCriteria: number = criteria.findIndex(
    item => item.criteriaId === criteriaId
  );

  if (indexOfCriteria > -1) {
    const response = yield call(
      submitCriteria,
      patientVariant,
      {
        code: criteriaId,
      },
      "DELETE"
    );

    yield put(
      actions.updateVariantACMG({
        ...patientVariant,
        selectedCriteria: getCriteriaAfterDeletion(patientVariant, criteriaId),
        classification: retrieveClassification(response, patientVariant),
      })
    );
  } else {
    yield put(errorMessage("Error removing the criteria"));
  }
  yield put(actions.setClassificationLoaded());
}

export function* submitClassification(
  patientId,
  variantId,
  transcriptId,
  method
) {
  const url = `/catalyst/api/patient/${patientId}/variant/${variantId}/acmg/lock`;

  const response = yield call(fetch, url, {
    method,
    credentials: "include",
    body: JSON.stringify({
      transcriptId,
    }),
  });

  const result = yield call([response, response.json]);
  return result;
}

export function* lockAcmgClassification(action) {
  const { patientId, variantId, transcriptId } = action.meta;
  const patientVariant = yield select(selectors.getCurrentPatientVariant);
  const response = yield call(
    submitClassification,
    patientId,
    variantId,
    transcriptId,
    "POST"
  );

  if (response.error) {
    const checkForOutOfSyncErrorsAction = {
      response,
      patientId,
      variantId,
    };
    const outOfSync = yield call(
      checkForOutOfSyncErrors,
      checkForOutOfSyncErrorsAction
    );
    yield put(actions.lockOrUnlockFailed(response, action.meta));

    if (!outOfSync) {
      yield put(
        actions.updateVariantACMG({ ...patientVariant, acmgLocked: 0 })
      );
    }
  } else {
    yield call(fetchDecisionsForPatient, action);
    yield put(actions.lockSucceeded());
    yield put(
      actions.updateVariantACMG({
        ...patientVariant,
        acmgLocked: 1,
        classification: retrieveClassification(response, patientVariant),
      })
    );
  }
}

export function* unlockAcmgClassification(action = { meta: {} }) {
  const { variantId, transcriptId, patientId } = action.meta;
  const patientVariant = yield select(selectors.getCurrentPatientVariant);
  const response = yield call(
    submitClassification,
    patientId,
    variantId,
    transcriptId,
    "DELETE"
  );

  if (response.error) {
    const checkForOutOfSyncErrorsAction = {
      response,
      patientId,
      variantId,
    };
    const outOfSync = yield call(
      checkForOutOfSyncErrors,
      checkForOutOfSyncErrorsAction
    );
    yield put(actions.lockOrUnlockFailed(response, action.meta));

    if (!outOfSync) {
      yield put(
        actions.updateVariantACMG({ ...patientVariant, acmgLocked: 1 })
      );
    }
  } else {
    yield put(actions.unlockSucceeded());
    yield put(
      actions.updateVariantACMG({
        ...patientVariant,
        acmgLocked: 0,
        // use the existing classification
        classification: patientVariant.classification,
      })
    );

    yield put(deleteVariantDecision(variantId, "acmg"));
  }
}

export function* markAsLoaded({ payload: { patientVariant } }) {
  yield put(actions.markAsLoaded(patientVariant));
}

export const fetchACMGClassificationList = createSaga({
  urlTemplate: `${CATALYST_BASE_URL}/acmg/classifications`,
  modelName: "ACMGClassification",
  nextActionCreator: actions.createAcmgClassificationList,
});

export const fetchACMGCriteriaList = createSaga({
  urlTemplate: `${CATALYST_BASE_URL}/acmg/criteria`,
  modelName: "ACMGCriteria",
  nextActionCreator: actions.createAcmgCriteriaList,
});

export function* loadSuggestedAcmgCriteria({ payload }) {
  try {
    const response: FetchDataResponse<AcmgSuggestion> = yield call(
      getAcmgSuggestedCriteria,
      payload
    );
    if (response.ok) {
      yield put(
        actions.suggestedAcmgCriteriaLoaded(response.payload.suggestedACMG)
      );
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(errorMessage(e));
  }
}

export function* acceptSuggestedAcmgCriteria({ payload }) {
  yield put(actions.suggestedAcmgCriteriaProcessing(true));
  try {
    const response: FetchDataResponse<any> = yield call(
      setAcceptedAcmgSuggestedCriteria,
      payload
    );
    if (response.ok) {
      yield put(readVariantOld(payload.patientId, payload.variantId));
      yield take(constants.ACMG_MARK_AS_LOADED);
      yield call(loadSuggestedAcmgCriteria, { payload });
      yield put(success(constants.SUGGESTED_ACMG_CRITERIA_USED));
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(errorMessage(e));
  }
  yield put(actions.suggestedAcmgCriteriaProcessing(false));
}

export function* rejectSuggestedAcmgCriteria({ payload }) {
  yield put(actions.suggestedAcmgCriteriaProcessing(true));
  try {
    const response: FetchDataResponse<any> = yield call(
      setRejectedAcmgSuggestedCriteria,
      payload
    );
    if (response.ok) {
      yield call(loadSuggestedAcmgCriteria, { payload });
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(errorMessage(e));
  }
  yield put(actions.suggestedAcmgCriteriaProcessing(false));
}

export function* init() {
  yield all([
    takeEvery(
      createFormTypeString(constants.ACMG_ADD_CRITERIA_EVIDENCE_FORM),
      addOrUpdateCriteria
    ),
    takeEvery(
      createFormTypeString(constants.ACMG_UPDATE_CRITERIA_EVIDENCE_FORM),
      addOrUpdateCriteria
    ),
    takeEvery(
      createFormTypeString(constants.ACMG_REMOVE_CRITERIA_EVIDENCE_FORM),
      removeCriteria
    ),
    takeEvery(constants.ACMG_LOCK, lockAcmgClassification),
    takeEvery(constants.ACMG_UNLOCK, unlockAcmgClassification),
    takeEvery(constants.ACMG_NO_EVIDENCE_FOR_CATEGORY, toggleNoEvidence),
    takeEvery(CREATE_ORM_VARIANT_OLD, markAsLoaded),
    takeEvery(
      constants.LOAD_SUGGESTED_ACMG_CRITERIA,
      loadSuggestedAcmgCriteria
    ),
    yield takeLatest(
      constants.READ_ACMGCLASSIFICATION_LIST,
      fetchACMGClassificationList
    ),
    yield takeLatest(constants.READ_ACMGCRITERIA_LIST, fetchACMGCriteriaList),
    yield takeEvery(
      constants.USE_SUGGESTED_ACMG_CRITERIA,
      acceptSuggestedAcmgCriteria
    ),
    yield takeEvery(
      constants.REJECT_SUGGESTED_ACMG_CRITERIA,
      rejectSuggestedAcmgCriteria
    ),
  ]);
}
