import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { Divider } from "pattern-library";

import configData, { PLI_KEY } from "modules/config/reducer.data";
import * as selectors from "modules/config/selectors";
import ReduxFormField from "modules/forms/components/ReduxFormField";
import { isNumber } from "modules/forms/rules";

export const ScoreField = props => {
  const { disabled } = props;
  const fieldProps =
    disabled === true
      ? {
          ...props,
          tooltip: {
            placement: "top",
            content: "This option is unavailable for selected patient",
            trigger: "mouseenter",
          },
        }
      : props;

  return <Field {...fieldProps} />;
};

export class ScoresForm extends PureComponent {
  static propTypes = {
    excludedFilters: PropTypes.arrayOf(PropTypes.string),
    disabledFilters: PropTypes.arrayOf(PropTypes.string),
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  mapScoresFieldsProperties(fieldsConfig) {
    return fieldsConfig.map(
      ({ key, label, placeholder, normalize, comparator }) => ({
        key,
        name: key,
        label,
        placeholder,
        narrow: true,
        prefix: comparator,
        component: ReduxFormField,
        normalize,
      })
    );
  }

  hasConstraintScoresEnabled() {
    const { excludedFilters = [] } = this.props;
    // If pLI key is present in exluded filter don't show
    // any GnomAD Constraint score section
    return !excludedFilters.includes(PLI_KEY);
  }

  renderScoreFields(configDataFields) {
    const { excludedFilters = [], disabledFilters = [] } = this.props;

    const fieldsConfig = configDataFields.filter(
      ({ key }) => !excludedFilters.includes(key)
    );

    return this.mapScoresFieldsProperties(fieldsConfig).map(props => (
      <ScoreField
        {...props}
        disabled={disabledFilters.includes(props.key)}
        validate={isNumber}
      />
    ));
  }

  renderInSilicoFields() {
    return this.renderScoreFields(configData.allScoresValues.scoreFields);
  }

  renderSplicingFields() {
    return this.renderScoreFields(configData.allScoresValues.splicingFields);
  }

  renderConstraintFields() {
    return this.renderScoreFields(configData.allScoresValues.constraintsFields);
  }

  render() {
    const { disabled = false } = this.props;

    return (
      <fieldset disabled={disabled}>
        <h4>In silico scores:</h4>
        <div className="form-horizontal scores-section">
          {this.renderInSilicoFields()}
        </div>
        <Divider />
        <h4>Splicing scores:</h4>
        <div className="form-horizontal scores-section">
          {this.renderSplicingFields()}
        </div>
        {this.hasConstraintScoresEnabled() && (
          <>
            <Divider />
            <h4>Constraint scores:</h4>
            <div className="form-horizontal scores-section">
              {this.renderConstraintFields()}
            </div>
          </>
        )}
      </fieldset>
    );
  }
}

const mapStateToProps = (state, { patientId }) => ({
  excludedFilters: selectors.getProjectConfigExclusions(state).filters,
  disabledFilters: selectors.getPatientConfigExclusions(state, patientId)
    .filters,
});

export default connect(mapStateToProps, null)(ScoresForm);
