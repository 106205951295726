// @flow

import { decamelizeKeys } from "humps";
import { isNil, path } from "ramda";

import { getFilterPresets } from "modules/api/filterPresets";
import { fetchCamelizeData, headers } from "modules/utils";

import type { FetchDataResponse } from "../../../utils/fetchData";

import { SV, SNV } from "./constants";
import type {
  FilterPreset,
  FilterPresetsPayload,
  FilterDictionaries,
  InheritanceSettings,
} from "./types";

export const formatServerValues = (apiModel: any): any => {
  const formattedModel = {
    ...apiModel,
    attributes: {
      ...apiModel.attributes,
      config: {
        ...apiModel.attributes.config,
      },
    },
  };
  const config = path(["attributes", "config"], formattedModel);
  if (apiModel.attributes.variantType === SV) {
    config.filters.genePanel = config.filters.genePanel.map(genePanel =>
      typeof genePanel === "object" ? genePanel.genePanelId : genePanel
    );
  }

  return formattedModel;
};

export const formatFilterDictionaries = (
  apiModel: any
): FilterDictionaries => ({
  genotypes: path(["genotype", "values"], apiModel),
  sizes: path(["size", "values"], apiModel),
  inheritance: path(["inheritance", "values"], apiModel),
  qualities: path(["quality", "values"], apiModel),
  subtypes: path(["subtype", "values"], apiModel),
  tiers: path(["tier", "values"], apiModel),
});

export const formatFilterPreset = (apiModel: any): FilterPreset => {
  const { id, type } = apiModel;
  return {
    id,
    type,
    ...path(["attributes"], formatServerValues(apiModel)),
  };
};

export const formatFilterPresets = (apiModel: any): FilterPresetsPayload => ({
  data:
    apiModel && apiModel.data
      ? apiModel.data.map((m: any) => formatFilterPreset(m))
      : [],
});
export const convertToApiFormat = (preset: FilterPreset): any => {
  const { id, type, config } = preset;

  const apiModel = {
    data: {
      type,
      id,
      attributes: decamelizeKeys(preset),
    },
  };

  if (preset.variantType === SNV) {
    apiModel.data.attributes.config = config;
  }

  return apiModel;
};
export const formatInheritanceSetting = (
  apiModel: any
): InheritanceSettings => ({
  ...path(["attributes"], apiModel),
});

const processResponse = <T>(
  response: FetchDataResponse<any>,
  formatPayloadCallback?: (apiModel: any) => T
): ApiResponse<T> => {
  const { ok, payload } = response;
  const result: ApiResponse<T> = {
    ok,
  };

  if (payload && payload.data && formatPayloadCallback) {
    result.payload = formatPayloadCallback(payload.data);
  }

  processResponseErrors(result, response);

  return result;
};

const processResponseErrors = (
  result: ApiResponse<any>,
  response: FetchDataResponse<any>
): void => {
  const { ok, payload } = response;
  if (payload) {
    if (!ok) {
      result.error = payload.errors.map(({ detail }) => detail).join(" ");
    }
  }
};

const getFilterPresetsApiUrl = (
  projectId: number,
  filterPresetId?: number
): string => {
  const resourceUrl = `/webapi/entities/projects/${projectId}/variant-filter-presets`;
  return isNil(filterPresetId)
    ? `${resourceUrl}?sort=title`
    : `${resourceUrl}/${filterPresetId}`;
};

const getInheritanceSettingsApiUrl = (projectId: number): string =>
  `/webapi/entities/projects/${projectId}/variant-filter-presets/inheritance`;

export const requestFilterPresets = async (
  projectId: number
): Promise<ApiResponse<FilterPresetsPayload>> => {
  try {
    const response: FetchDataResponse<any> = await getFilterPresets({
      projectId,
    });

    const result: ApiResponse<FilterPresetsPayload> =
      processResponse<FilterPresetsPayload>(response);

    return {
      ...result,
      payload: formatFilterPresets(response.payload),
    };
  } catch (error) {
    return { ok: false, error };
  }
};

export const removeProjectPreset = async (
  projectId: number,
  presetId: number
) => {
  try {
    const response: FetchDataResponse<any> = await fetchCamelizeData(
      getFilterPresetsApiUrl(projectId, presetId),
      {
        method: "DELETE",
      }
    );
    return processResponse<FilterPresetsPayload>(response);
  } catch (error) {
    return { ok: false, error };
  }
};

export const saveProjectPreset = async (
  projectId: number,
  preset: FilterPreset
) => {
  try {
    const saveUrl = getFilterPresetsApiUrl(projectId, preset.id);

    const response: FetchDataResponse<any> = await fetchCamelizeData(saveUrl, {
      method: preset.id ? "PATCH" : "POST",
      body: JSON.stringify({
        ...convertToApiFormat(preset),
      }),
      headers,
    });
    return processResponse<InheritanceSettings>(response, formatFilterPreset);
  } catch (error) {
    return { ok: false, error };
  }
};

export const requestFilterDictionaries = async (
  projectId: number
): Promise<ApiResponse<FilterDictionaries>> => {
  try {
    const response: FetchDataResponse<any> = await fetchCamelizeData(
      `/catalyst/api/project/${projectId}/variants/sv/filters`
    );

    const result: ApiResponse<FilterDictionaries> = {
      ...response,
      payload: formatFilterDictionaries(response.payload),
    };

    return result;
  } catch (error) {
    return { ok: false, error };
  }
};

export const requestInheritanceSettings = async (
  projectId: number
): Promise<ApiResponse<InheritanceSettings>> => {
  try {
    const response: FetchDataResponse<any> = await fetchCamelizeData(
      getInheritanceSettingsApiUrl(projectId)
    );
    return processResponse<InheritanceSettings>(
      response,
      formatInheritanceSetting
    );
  } catch (error) {
    return { ok: false, error };
  }
};

export const saveInheritanceSettings = async (
  projectId: number,
  isConfigurationInherited: boolean
) => {
  try {
    const saveUrl = getInheritanceSettingsApiUrl(projectId);

    const response: FetchDataResponse<any> = await fetchCamelizeData(saveUrl, {
      method: "PATCH",
      body: JSON.stringify({
        data: {
          type: "variant_filter_preset_inheritance",
          id: projectId,
          attributes: {
            is_inherited: isConfigurationInherited,
          },
        },
      }),
      headers,
    });
    return processResponse<InheritanceSettings>(
      response,
      formatInheritanceSetting
    );
  } catch (error) {
    return { ok: false, error };
  }
};
