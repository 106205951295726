import PropTypes from "prop-types";
import React from "react";

import { ConfigSection, CongenicaAILogo } from "pattern-library";

import {
  ARIADNE_STRINGS,
  ARIADNE_KEYS,
  ARIADNE_VALUES,
} from "modules/ariadne/constants";

const CONTROL_TYPES = {
  PATHOGENICITY: "Pathogenicity",
  FIVE_STAR_RATING: "FiveStarRating",
  CHECKBOX_GROUP: "checkboxgroup",
};

const ARIADNE_FORM_CONFIG = {
  header: ARIADNE_STRINGS.FILTER.HEADER,
  information: {
    icon: <CongenicaAILogo />,
    header: ARIADNE_STRINGS.FILTER.INFORMATION_HEADER,
    description: `${ARIADNE_STRINGS.ARIADNE_DESC}\n\nAny filtering is applied to the default Congenica transcript`,
  },
  controls: [
    {
      key: ARIADNE_KEYS.PREDICTED_PATHOGENICITY,
      label: ARIADNE_STRINGS.FILTER.CONTROL.PREDICTED_PATHOGENICITY,
      description: ARIADNE_STRINGS.FILTER.CONTROL.PREDICTED_PATHOGENICITY_DESC,
      type: CONTROL_TYPES.PATHOGENICITY,
    },
    {
      key: ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING,
      label: ARIADNE_STRINGS.FILTER.CONTROL.PREDICTION_CONFIDENCE_RATING,
      description:
        ARIADNE_STRINGS.FILTER.CONTROL.PREDICTION_CONFIDENCE_RATING_DESC,
      type: CONTROL_TYPES.FIVE_STAR_RATING,
    },
    {
      key: ARIADNE_KEYS.PREDICTED_C2P,
      label: ARIADNE_STRINGS.FILTER.CONTROL.PREDICTED_CONTRIBUTION,
      description: ARIADNE_STRINGS.FILTER.CONTROL.PREDICTED_CONTRIBUTION_DESC,
      type: CONTROL_TYPES.CHECKBOX_GROUP,
      options: [
        {
          key: ARIADNE_VALUES.C_2_P.FULL,
          label: ARIADNE_VALUES.C_2_P.FULL,
          value: ARIADNE_VALUES.C_2_P.FULL,
        },
        {
          key: ARIADNE_VALUES.C_2_P.PARTIAL,
          label: ARIADNE_VALUES.C_2_P.PARTIAL,
          value: ARIADNE_VALUES.C_2_P.PARTIAL,
        },
        {
          key: ARIADNE_VALUES.C_2_P.UNCERTAIN,
          label: ARIADNE_VALUES.C_2_P.UNCERTAIN,
          value: ARIADNE_VALUES.C_2_P.UNCERTAIN,
        },
        {
          key: ARIADNE_VALUES.C_2_P.NONE,
          label: ARIADNE_VALUES.C_2_P.NONE,
          value: ARIADNE_VALUES.C_2_P.NONE,
        },
      ],
    },
    {
      key: ARIADNE_KEYS.PREDICTED_C2P_EXTENDED,
      label: ARIADNE_STRINGS.FILTER.CONTROL.PREDICTED_CONTRIBUTION_EXTENDED,
      description:
        ARIADNE_STRINGS.FILTER.CONTROL.PREDICTED_CONTRIBUTION_EXTENDED_DESC,
      type: CONTROL_TYPES.CHECKBOX_GROUP,
      options: [
        {
          key: ARIADNE_VALUES.C_2_P.FULL,
          label: ARIADNE_VALUES.C_2_P.FULL,
          value: ARIADNE_VALUES.C_2_P.FULL,
        },
        {
          key: ARIADNE_VALUES.C_2_P.PARTIAL,
          label: ARIADNE_VALUES.C_2_P.PARTIAL,
          value: ARIADNE_VALUES.C_2_P.PARTIAL,
        },
        {
          key: ARIADNE_VALUES.C_2_P.UNCERTAIN,
          label: ARIADNE_VALUES.C_2_P.UNCERTAIN,
          value: ARIADNE_VALUES.C_2_P.UNCERTAIN,
        },
        {
          key: ARIADNE_VALUES.C_2_P.NONE,
          label: ARIADNE_VALUES.C_2_P.NONE,
          value: ARIADNE_VALUES.C_2_P.NONE,
        },
      ],
    },
  ],
};

const AriadneFilterForm = ({ disabled }) => (
  <ConfigSection
    header={ARIADNE_FORM_CONFIG.header}
    information={ARIADNE_FORM_CONFIG.information}
    controls={ARIADNE_FORM_CONFIG.controls}
    disabled={disabled}
    inputsColSize={7}
    className="pre-line"
  />
);

AriadneFilterForm.propTypes = {
  disabled: PropTypes.bool,
};

export default AriadneFilterForm;
