import React, { memo, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Table } from "pattern-library/elements/table7";

import { formatDate } from "modules/utils/formatDate";

import { executeGenePanelPatientOperation } from "../../../actions";
import { PatientOperation } from "../../../flow-types";
import {
  getGenePanelWritePermissions,
  getGenePanelPatientsCombined,
  isAllGenePanelPatientsSelected,
  isGenePanelPatientListLoading,
} from "../../../selectors";

interface Props extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
}
export const PatientsTab = memo(
  ({
    patients,
    projectId,
    genePanelId,
    isLoading,
    writePermissions,
    executeGenePanelPatientOperation,
    allPatientsSelected,
  }: Props) => {
    const columns = useMemo(() => {
      const result: Array<unknown> = [
        {
          Header: "#",
          accessor: "reference",
          headerStyle: { width: "10em" },
        },
        {
          Header: "Status",
          accessor: "status",
          headerStyle: { width: "10em" },
        },
        {
          Header: "Phenotype summary",
          accessor: "phenotypeSummary",
          headerStyle: { width: "20em" },
        },
        {
          Header: "HPO Terms",
          headerStyle: { width: "20em" },
          accessor: patient => patient.phenotypeNames.join(", "),
        },
        {
          Header: "Last Update",
          accessor: "updated",
          headerStyle: { width: "10em" },
          Cell: ({
            row: {
              original: { updated },
            },
          }) => formatDate(new Date(updated * 1000)),
        },
      ];

      if (writePermissions) {
        result.unshift({
          Header: () => {
            const operation = allPatientsSelected
              ? PatientOperation.REMOVE_ALL
              : PatientOperation.ADD_ALL;
            return (
              <input
                type="checkbox"
                checked={allPatientsSelected}
                onChange={() => {
                  executeGenePanelPatientOperation(
                    operation,
                    projectId,
                    genePanelId
                  );
                }}
              />
            );
          },
          headerStyle: { width: "1em" },
          accessor: patient => {
            const operation = patient.isSelected
              ? PatientOperation.REMOVE_ONE
              : PatientOperation.ADD_ONE;
            return (
              <input
                type="checkbox"
                checked={patient.isSelected}
                onChange={() => {
                  executeGenePanelPatientOperation(
                    operation,
                    projectId,
                    genePanelId,
                    patient.patientId
                  );
                }}
              />
            );
          },
          id: "actions",
        });
      }

      return result;
    }, [
      writePermissions,
      executeGenePanelPatientOperation,
      allPatientsSelected,
      projectId,
      genePanelId,
    ]);

    return (
      <div className="gene-panel-patients-tab">
        <Table
          id="project-gene-panel-patients-table"
          columns={columns}
          data={patients}
          disableSortBy
          autoResetPage={false}
          loading={isLoading}
        />
      </div>
    );
  }
);

const mapStateToProps = state => ({
  patients: getGenePanelPatientsCombined(state),
  allPatientsSelected: isAllGenePanelPatientsSelected(state),
  writePermissions: getGenePanelWritePermissions(state),
  isLoading: isGenePanelPatientListLoading(state),
});

const mapDispatchToProps = {
  executeGenePanelPatientOperation: executeGenePanelPatientOperation.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PatientsTab);
