import deepFreeze from "deep-freeze";

import {
  REVEL_KEY,
  AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
} from "modules/config/reducer.data";

import { SNVFilterPresetConfig } from "./types";

export const MODULE_NAME = "filterPresets";
export const FORM = "svFilterPreset";

//Actions
export const INIT_FILTER_PRESETS = "INIT_FILTER_PRESETS";
export const SET_FILTER_PRESETS_REQUEST_STATUS =
  "SET_FILTER_PRESETS_REQUEST_STATUS";
export const SET_PRESET_SUBMIT_STATUS = "SET_PRESET_SUBMIT_STATUS";
export const SET_PRESET_REMOVE_STATUS = "SET_PRESET_REMOVE_STATUS";
export const SET_FILTER_PRESETS = "SET_FILTER_PRESETS";
export const REMOVE_FILTER_PRESET = "REMOVE_FILTER_PRESET";
export const FILTER_PRESET_REMOVED = "FILTER_PRESET_REMOVED";
export const SET_SELECTED_PRESET = "SET_SELECTED_PRESET";
export const SUBMIT_FILTER_PRESET = "SUBMIT_FILTER_PRESET";
export const SET_FILTER_DICTIONARIES = "SET_FILTER_DICTIONARIES";
export const SET_INHERITANCE_SETTINGS = "SET_INHERITANCE_SETTINGS";
export const SUBMIT_INHERITANCE_SETTINGS = "SUBMIT_INHERITANCE_SETTINGS";
export const SET_INHERITANCE_SETTINGS_SUBMIT_STATUS =
  "SET_INHERITANCE_SETTINGS_SUBMIT_STATUS";

export const GNOMAD_AF_KEY = "gnomad_af";

//Variant types
export const SV = "SV";
export const SNV = "SNV";
export const CNV = "CNV";
export const ALL = "ALL";

export const variantTypeOptions = {
  ALL: "All",
  SV,
  SNV,
};

export const ANY_GENE_PANEL_ID = "any_gene";

export const SNV_PRESET_FORM_NAME = "snvFilterPreset";

export const CVL_VARIANT_TYPES = {
  SNV,
  SV,
  CNV,
};

export const PRESET_ATTRIBUTES_NAMES = [
  "id",
  "type",
  "title",
  "isActive",
  "isDefault",
  "variantType",
  "projectId",
  "isInherited",
];

export const LOCATION_TYPE_GENES = "genes";
export const LOCATION_TYPE_GENE_PANELS = "panels";

export const LOCATION_TYPES = {
  genes: {
    label: "Specific genes",
    value: LOCATION_TYPE_GENES,
  },
  panels: {
    label: "Gene panels",
    value: LOCATION_TYPE_GENE_PANELS,
  },
};
const SV_PRESET_FILTER_DEFAULTS = deepFreeze({
  location: LOCATION_TYPE_GENE_PANELS,
  genes: [],
  genePanel: [],
  genotype: [],
  inheritance: [],
  quality: 0,
  size: 0,
  subtype: [],
  tier: [],
  cvl: [],
});

const SV_PRESET_COLUMNS_DEFAULTS = [
  "bands",
  "start",
  "end",
  "length",
  "subtype",
  "genotype",
  "cnv_ratio",
  "quality",
  "bayes_factor",
  "variant_gene",
  "hi_gene_name",
  "hi_index",
  "inheritance",
  "keyword",
  "pathogenicity",
  "translocation_chr",
  "translocation_pos",
  "variant",
  "custom_data",
];

export const SV_PRESET_DEFAULTS = deepFreeze({
  type: "variant_filter_preset",
  isActive: true,
  isDefault: false,
  isInherited: false,
  variantType: SV,
  config: {
    filters: SV_PRESET_FILTER_DEFAULTS,
    columns: SV_PRESET_COLUMNS_DEFAULTS,
  },
});

export const SNV_FILTERS_DEFAULTS: SNVConfigFilters = deepFreeze({
  location: "panels",
  genes: [],
  genePanels: [],

  afMax: "0.01",

  [REVEL_KEY]: "0.5",

  vepConsequence: [
    "transcript_ablation",
    "splice_donor_variant",
    "splice_acceptor_variant",
    "stop_gained",
    "frameshift_variant",
    "stop_lost",
    "start_lost",
    "initiator_codon_variant",
    "transcript_amplification",
    "inframe_insertion",
    "inframe_deletion",
    "missense_variant",
    "protein_altering_variant",
    "splice_region_variant",
    "incomplete_terminal_codon_variant",
    "stop_retained_variant",
    "synonymous_variant",
  ],

  zygosity: [],

  inheritance: [],
  moi: [],
  familyComparisons: [],
  denovoStatus: [],

  filterInclude: [{ label: "PASS", value: "PASS", disabled: false }],
  filterExclude: [],

  customTier: [],
  customPenetrance: [],

  curatedListKnowledgebase: [],
  curatedListBlacklist: [],
  curatedListWhitelist: [],

  [AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY]: [],
});

const SNV_COLUMNS_DEFAULTS: SNVColumns = deepFreeze({
  consequenceColumns: [
    "transcript",
    "vepConsequence",
    "hgvsC",
    "hgvsP",
    REVEL_KEY,
  ],
  variantColumns: [
    "variant",
    "curatedVariants",
    "__CONSEQUENCES_TABLE__",
    "afMax",
    "zygosity",
    "qcStatus",
  ],
});

const SNV_CONFIG_DEFAULTS: SNVFilterPresetConfig = deepFreeze({
  columns: SNV_COLUMNS_DEFAULTS,
  filters: SNV_FILTERS_DEFAULTS,
  prioritisation: [],
});

export const SNV_PRESET_DEFAULTS = deepFreeze({
  type: "variant_filter_preset",
  isInherited: false,
  isDefault: false,
  isActive: true,
  variantType: "SNV",
  config: SNV_CONFIG_DEFAULTS,
});

export const SV_COLUMNS = [
  { label: "Bands", key: "bands", disabled: true },
  { label: "Start", key: "start", disabled: true },
  { label: "End", key: "end", disabled: true },
  { label: "Length", key: "length", disabled: true },
  { label: "Type", key: "subtype", disabled: true },
  { label: "Genotype", key: "genotype" },
  { label: "Copy Number", key: "cnv_ratio" },
  { label: "Quality", key: "quality" },
  { label: "gnomAD Allele Frequency", key: GNOMAD_AF_KEY },
  { label: "Bayes Factor", key: "bayes_factor" },
  {
    label: "Genes",
    key: "variant_gene",
    disabled: true,
  },
  {
    label: "Haploinsufficient Gene",

    key: "hi_gene_name",
  },
  { label: "Haploinsufficiency Index %", key: "hi_index" },
  { label: "Inheritance", key: "inheritance" },
  { label: "Tags", key: "keyword" },
  { label: "Pathogenicity", key: "pathogenicity" },
  {
    label: "Translocation Chromosome",
    key: "translocation_chr",
  },
  {
    label: "Translocation Position",
    key: "translocation_pos",
  },
  { label: "Curated Variants", key: "variant" },
  { label: "Tiers", key: "custom_data" },
];
