import { isEmpty, isNil } from "ramda";
import React from "react";

import { THIRDPARTY_SOFTWARE } from "modules/patient/constants";
import { ThirdpartySoftwareVersions } from "modules/patient/types";
import { notEmpty } from "modules/utils/array";
import { toDecimalPlaces } from "modules/utils/format";
import { removePrefix } from "modules/utils/string";

import {
  DEFAULT_SUMMARY_STATE_PATH,
  SPLICE_SCORE_EMPTY_VALUES,
  SPLICE_SCORE_NOT_AVAILABLE,
  SPLICE_SCORE_NOT_CALCULATED,
} from "../constants";
import {
  ColocatedVariantSchema,
  ColocatedVariantsIds,
  LinkObject,
  SpliceDataApi,
  SpliceRowArgs,
  SpliceSitesDiffRowArgs,
  SpliceSitesRow,
  SUMMARY_STATE_PATH,
} from "../types";

import ScoreDetailedLabel from "./spliceSite/ScoreDetailedLabel";
import SpliceAiValue from "./spliceSite/SpliceAiValue";

const getColocatedVariantsSchema = (
  geneName?: string
): Array<ColocatedVariantSchema> => [
  {
    idProperty: "rsId",
    idPrefix: "rs",
    removeIdPrefix: true,
    href: id => `http://www.ncbi.nlm.nih.gov/SNP/snp_ref.cgi?type=rs&rs=${id}`,
  },
  {
    idProperty: "cosmicId",
    idPrefix: "COSM",
    removeIdPrefix: true,
    href: id => `http://cancer.sanger.ac.uk/cosmic/mutation/overview?id=${id}`,
  },
  {
    idProperty: "cosmicId",
    idPrefix: "COSV",
    href: id => `https://cancer.sanger.ac.uk/cosmic/search?q=${id}`,
  },
  {
    idProperty: "hgmdId",
    href: id =>
      `http://www.hgmd.cf.ac.uk/ac/gene.php?gene=${geneName}&accession=${id}`,
  },
  {
    idProperty: "espId",
    href: id => {
      //espId example: TMP_ESP_1_186114854_186114853
      const [, , chr, chrStart, chrEnd] = id.split("_");
      return `http://evs.gs.washington.edu/EVS/PopStatsServlet?searchBy=chromosome&chromosome=${chr}&chromoStart=${
        Number(chrStart) - 1
      }&chromoEnd=${chrEnd}&x=0&y=0`;
    },
  },
];

const getColocatedVariantLink = (
  idValue: string,
  { idPrefix, href, removeIdPrefix }: ColocatedVariantSchema
) => {
  // Special case for `cosmic_id` (COSV/COSM).
  // For var_id `cosmic_id`, we might have (a) variant (beginning with COSM or COSV,
  // depending on VEP cache used), and (b) two URL templates to use.
  // Generate 1 link (COSM url with COSM ID or COSV url with COSV ID)
  // rather than 2 (including the unhelpful e.g. COSM URL with COSV ID).
  if (
    isNil(idValue) ||
    isEmpty(idValue) ||
    (idPrefix && !idValue.startsWith(idPrefix))
  ) {
    return;
  }
  const id = removeIdPrefix ? removePrefix(idValue, idPrefix) : idValue;
  return {
    href: `${href(id)}`,
    label: idValue,
  };
};

const getColocatedVariantsLinksGroup = (
  colocatedVariantsIds: ColocatedVariantsIds,
  colocatedVariantSchema: ColocatedVariantSchema
) => {
  const { idProperty } = colocatedVariantSchema;
  const idValues = colocatedVariantsIds[idProperty]?.split("&") || [];

  return idValues
    .map(idValue => getColocatedVariantLink(idValue, colocatedVariantSchema))
    .filter(Boolean);
};

export const getColocatedVariantsLinksGroups = (
  colocatedVariantsIds: ColocatedVariantsIds,
  geneName?: string
): Array<Array<LinkObject>> =>
  getColocatedVariantsSchema(geneName)
    .map(colocatedVariantSchema =>
      getColocatedVariantsLinksGroup(
        colocatedVariantsIds,
        colocatedVariantSchema
      )
    )
    .filter(notEmpty);

export const otherPatientsSnvCountsSchema = [
  {
    name: "thisProject",
    description: "other patients in this project",
  },
  {
    name: "otherProjects",
    description: "other patients in your other projects",
    isHidden: isSuperAdmin => isSuperAdmin,
  },
  {
    name: "nonProjects",
    description: "other patients in 3rd party projects",
  },
  {
    name: "allProjects",
    description: "other patients in all projects",
    isHidden: isSuperAdmin => !isSuperAdmin,
  },
];

export const getFieldBaseHref = ({
  patientId,
  geneName,
  variantId,
  transcriptId,
}) => {
  const snvPart =
    variantId && transcriptId
      ? `/transcript/${transcriptId}/snv/${variantId}`
      : "";
  return `/patient/${patientId}/variants/gene/${geneName}${snvPart}/`;
};

export const snvCountsAddedToVariantPanel = ({ otherCount, totalCount }) =>
  otherCount !== "?" && totalCount !== "?";

const formatDiffText = (diff, percentChange, lost) => {
  if (isNil(diff)) {
    return "";
  }
  const diffParts = [toDecimalPlaces(diff)];
  if (!isNil(percentChange) && !lost) {
    diffParts.push(`${toDecimalPlaces(Math.abs(percentChange))}%`);
  }
  return ` (${diffParts.join(", ")})`;
};

export const getSpliceScoreValue = (
  score,
  software,
  thirdpartySoftwareVersions?
): string | number => {
  if (thirdpartySoftwareVersions?.[software] === undefined) {
    return SPLICE_SCORE_NOT_AVAILABLE;
  }
  return isNil(score) ? SPLICE_SCORE_NOT_CALCULATED : score;
};

const getSpliceRow = ({
  label,
  rScore,
  software,
  thirdpartySoftwareVersions,
  getValue,
  getDetailedLabel,
}: SpliceRowArgs): SpliceSitesRow => {
  const scoreValue = getSpliceScoreValue(
    rScore,
    software,
    thirdpartySoftwareVersions
  );

  if (
    typeof scoreValue === "string" &&
    SPLICE_SCORE_EMPTY_VALUES.includes(scoreValue)
  ) {
    return {
      label,
      value: scoreValue,
    };
  }

  return {
    label: getDetailedLabel ? getDetailedLabel() : label,
    value: getValue(),
  };
};

const getSpliceDiffRow = (
  {
    score,
    rScore,
    diff,
    percentChange,
    lost,
    label,
    software,
    spliceType,
    spliceMotifType,
    denovo,
  }: SpliceSitesDiffRowArgs,
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions
): SpliceSitesRow => {
  const detailedLabelProps = {
    label,
    spliceType,
    spliceMotifType,
    denovo,
    lost,
  };

  return getSpliceRow({
    label,
    rScore,
    software,
    thirdpartySoftwareVersions,
    getValue: () => {
      let value = lost ? "Δ" : toDecimalPlaces(rScore);
      if (score) {
        value = `${toDecimalPlaces(score)} > ${value}`;
      }
      value += formatDiffText(diff, percentChange, lost);
      return value;
    },
    getDetailedLabel: () => <ScoreDetailedLabel {...detailedLabelProps} />,
  });
};

const getMaxEntRow = (
  {
    spliceMaxEntScore,
    rScore,
    diff,
    mesPercentChange,
    seq,
    spliceType,
    spliceMotifType,
    denovo,
  }: SpliceDataApi,
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions
): SpliceSitesRow =>
  getSpliceDiffRow(
    {
      score: spliceMaxEntScore,
      rScore,
      diff,
      percentChange: mesPercentChange,
      lost: !seq && !rScore,
      label: "MaxEntScan",
      software: THIRDPARTY_SOFTWARE.MAXENTSCAN,
      spliceType,
      spliceMotifType,
      denovo,
    },
    thirdpartySoftwareVersions
  );

const getSsfRow = (
  {
    spliceSsfScore,
    ssfRScore,
    ssfDiff,
    ssfPercentChange,
    spliceType,
    spliceMotifType,
    denovo,
  }: SpliceDataApi,
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions
): SpliceSitesRow =>
  getSpliceDiffRow(
    {
      score: spliceSsfScore,
      rScore: ssfRScore,
      diff: ssfDiff,
      percentChange: ssfPercentChange,
      lost: !!spliceSsfScore && ssfRScore === 0,
      label: "CSSF [0-100]",
      software: THIRDPARTY_SOFTWARE.SSF,
      spliceType,
      spliceMotifType,
      denovo,
    },
    thirdpartySoftwareVersions
  );

export const formatDbscsnvValue = value => {
  const minValue = 0.01;
  if (value < 0) {
    return "GRCh38 inconsistency";
  }

  if (value < minValue) {
    return `Less than ${minValue}`;
  }

  return toDecimalPlaces(value);
};

const getDbscsnvRow = (
  labelPrefix,
  score,
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions
): SpliceSitesRow =>
  getSpliceRow({
    label: `${labelPrefix} [0-1]`,
    rScore: score,
    software: THIRDPARTY_SOFTWARE.DBSC_SNV,
    thirdpartySoftwareVersions,
    getValue: () => formatDbscsnvValue(score),
  });

const getSpliceAiRow = (
  { spliceAiDeltaScore, spliceAiDeltaPosition },
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions
): SpliceSitesRow =>
  getSpliceRow({
    label: "SpliceAI [0-1]",
    rScore: spliceAiDeltaScore,
    software: THIRDPARTY_SOFTWARE.SPLICE_AI,
    thirdpartySoftwareVersions,
    getValue: () => (
      <SpliceAiValue
        scores={spliceAiDeltaScore}
        positions={spliceAiDeltaPosition}
      />
    ),
  });

const getNotEmptyApiData = apiData =>
  isNil(apiData) || isEmpty(apiData) ? [{}] : apiData;

export const getSpliceData = (
  apiData: Array<SpliceDataApi> = [],
  isSpliceAiFeatureEnabled: boolean,
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions
): Array<SpliceSitesRow> => {
  const rows: Array<SpliceSitesRow> = [];
  const notEmptyData = getNotEmptyApiData(apiData);
  notEmptyData.forEach(spliceSiteApiItem => {
    rows.push(getMaxEntRow(spliceSiteApiItem, thirdpartySoftwareVersions));
    rows.push(getSsfRow(spliceSiteApiItem, thirdpartySoftwareVersions));
  });
  const firstItem = notEmptyData[0];
  const { spliceAdaScore, spliceRfScore } = firstItem;
  rows.push(getDbscsnvRow("ADA", spliceAdaScore, thirdpartySoftwareVersions));
  rows.push(getDbscsnvRow("RF", spliceRfScore, thirdpartySoftwareVersions));
  if (isSpliceAiFeatureEnabled) {
    rows.push(getSpliceAiRow(firstItem, thirdpartySoftwareVersions));
  }
  return rows;
};

export const getSummaryStatePath = (
  prefix: string | null
): SUMMARY_STATE_PATH => {
  if (!prefix) {
    return DEFAULT_SUMMARY_STATE_PATH;
  }
  const values: Array<string> = Object.values(SUMMARY_STATE_PATH);
  const valueIndex = values.indexOf(prefix);
  if (valueIndex > -1) {
    const enumKey = Object.keys(SUMMARY_STATE_PATH)[valueIndex];
    return SUMMARY_STATE_PATH[enumKey];
  }
  return DEFAULT_SUMMARY_STATE_PATH;
};

// eslint-disable-next-line eqeqeq
const nonStrictEqual = (a, b) => (!a && !b) || a == b;

export const shouldReloadSummary = (summaryFromState, requestParams) => {
  if (!summaryFromState) {
    return true;
  }

  const geneNameSaved = summaryFromState.gene?.name;
  const transcriptIdSaved = summaryFromState.snv?.transcriptId;
  const variantIdSaved = summaryFromState.snv?.patientVariantId;

  const isAlreadyInState =
    requestParams.geneName === geneNameSaved &&
    nonStrictEqual(requestParams.transcriptId, transcriptIdSaved) &&
    nonStrictEqual(requestParams.variantId, variantIdSaved);

  return !isAlreadyInState;
};
