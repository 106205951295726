import React, { useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";
import { getAssemblyConfig } from "modules/systemConfig/selectors";

import {
  fetchEditTempGene,
  updateEditTempGene,
  resetEditTempGeneState,
} from "../../../../actions";
import {
  getEditTempGene,
  getEditTempGeneKeys,
  getEditTempGeneFields,
  getEditTempGeneFormValues,
} from "../../../../selectors";
import EditGeneForm from "../../../EditGeneForm";

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.EDIT_TEMPORARY_GENE_MODAL, {
//      projectId: 123,
//      tempGenePaneId: 123,
//      geneName: "AARS",
//    });

interface Props extends PropsFromRedux {
  projectId: number;
  tempGenePanelId: number;
  geneName: string;
}

const EditTempGeneModal = ({
  projectId,
  tempGenePanelId,
  geneName,
  hideModal,
  fetchEditGene,
  gene,
  fields,
  columns,
  assembly,
  updateEditGene,
  resetEditGeneState,
  initialValues,
}: Props) => {
  useEffect(() => {
    fetchEditGene(projectId, tempGenePanelId, geneName);
    return () => {
      resetEditGeneState();
    };
  }, [projectId, tempGenePanelId, geneName, fetchEditGene, resetEditGeneState]);

  const hideModalHandler = useCallback(() => {
    hideModal(modalTypes.EDIT_TEMPORARY_GENE_MODAL);
  }, [hideModal]);

  return initialValues ? (
    <EditGeneForm
      projectId={projectId}
      genePanelId={tempGenePanelId}
      geneName={geneName}
      updateGene={updateEditGene}
      gene={gene}
      fields={fields}
      columns={columns}
      assembly={assembly}
      initialValues={initialValues}
      hideModal={hideModalHandler}
    />
  ) : null;
};

const mapStateToProps = state => ({
  gene: getEditTempGene(state),
  columns: getEditTempGeneKeys(state),
  fields: getEditTempGeneFields(state),
  assembly: getAssemblyConfig(state),
  initialValues: getEditTempGeneFormValues(state),
});
const mapDispatchToProps = {
  hideModal,
  fetchEditGene: fetchEditTempGene.start,
  updateEditGene: updateEditTempGene.start,
  resetEditGeneState: resetEditTempGeneState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditTempGeneModal);
