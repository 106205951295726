import { select, put, call, takeLatest } from "redux-saga/effects";

import {
  getCurrentUser,
  getProfileApiToken as makeProfileApiTokenRequest,
} from "modules/api/currentUser";

import callLogin from "../api/login";
import { getFormValues } from "../forms/selectors";

import * as actions from "./actions";
import * as constants from "./constants";
import { currentUser as currentUserSelector } from "./selectors";

export function* init() {
  yield takeLatest(`SUBMIT/FORM/${constants.LOGIN_FORM}`, login);
  yield takeLatest(constants.IS_USER_LOGGED_IN, isUserLoggedIn);
  yield takeLatest(constants.FETCH_PROFILE_API_TOKEN, getProfileApiToken);
}

export function* login(action) {
  yield put(actions.userLoggingIn());
  const { email = false, password = false } = yield select(
    getFormValues,
    action.meta.formName
  );

  if (email === false || password === false) {
    return yield put(actions.userLoginFailed());
  }

  const response = yield call(callLogin, email, password);

  if (response.ok) {
    const loggedIn = yield call(isUserLoggedIn);

    // If we logged in successfully then prevent us hitting the put below
    if (loggedIn) return;
  }
  return yield put(actions.userLoginFailed());
}

export function* isUserLoggedIn() {
  // We have logged in
  const currentUserResponse = yield call(getCurrentUser);

  if (currentUserResponse.ok) {
    const currentUser = yield call([
      currentUserResponse,
      currentUserResponse.json,
    ]);

    return yield put(
      actions.userLoggedIn(
        currentUser.data.attributes.is_admin,
        currentUser.data.attributes.full_name,
        currentUser.data.id,
        currentUser.data.attributes.email
      )
    );
  }

  yield put(actions.loadInitialUserFailed());

  return false;
}

export function* getOrReloadCurrentUser() {
  const currentUser = yield select(currentUserSelector);
  if (currentUser) {
    return currentUser;
  }
  return yield call(isUserLoggedIn);
}

export function* getProfileApiToken() {
  const apiTokenResult = yield call(makeProfileApiTokenRequest);

  if (!apiTokenResult.ok) {
    yield put(actions.fetchProfileApiTokenFailure());
    return;
  }

  yield put(
    actions.fetchProfileApiTokenSuccess(apiTokenResult.payload.apiToken)
  );
}
