import { useField } from "formik";
import React, { FC, memo } from "react";

import { COLUMNS, FORM_FILE_TYPES } from "modules/interpretationRequests";

import { FileType, FormFileTypes } from "../../../types";

interface Props {
  fileTypesOptions: Array<FormFileTypes>;
}

const SamplesTableHeader: FC<Props> = ({ fileTypesOptions = [] }: Props) => {
  const fileTypesHeaders: Array<string> = fileTypesOptions.map(
    ({ title }) => title
  );
  const rowSpanProps: any = { rowSpan: 2 };

  const [{ value: fileType }] = useField<FileType>(
    "samples[0].files[0].fileType"
  );

  return (
    <thead>
      <tr>
        <th {...rowSpanProps}> &nbsp;</th>
        <th {...rowSpanProps} className="ir-table-header-group-column-v2-name">
          {COLUMNS.name}
        </th>
        <th
          {...rowSpanProps}
          className="ir-table-header-group-column-v2-tumourType"
        >
          {COLUMNS.tumourType}
        </th>
        <th
          {...rowSpanProps}
          title="Neoplastic Cellularity is the pathology estimate of the percentage of the sample going into DNA extraction (after any sectioning and macrodissection) that is tumour cells as opposed to normal cells"
          className="ir-table-header-group-column-v2-neoplasticCellularity"
        >
          {COLUMNS.neoplasticCellularity}
        </th>
        <th {...rowSpanProps} className="ir-table-header-group-column-v2-sex">
          {COLUMNS.sex}
        </th>
        {fileType !== FORM_FILE_TYPES.VCF && (
          <th
            {...rowSpanProps}
            data-testid="baitset-header"
            className="ir-table-header-group-column-v2-baitset"
          >
            {COLUMNS.baitset}
          </th>
        )}
        <th
          colSpan={fileTypesHeaders.length}
          className="ir-table-header-group-column"
        >
          Sample Files
        </th>
        <th {...rowSpanProps}>&nbsp;</th>
      </tr>

      <tr>
        {fileTypesHeaders.map(header => (
          <th
            key={header}
            data-testid={header}
            className="ir-table-header-group-column"
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default memo<typeof SamplesTableHeader>(SamplesTableHeader);
