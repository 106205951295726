import React from "react";

import CustomTier from "modules/variants/components/columns/CustomTier";

import type { Str } from "../flow-types";

const subTable = rows => (
  <div className="col-md-4 str-record">
    <table className="table table-striped table-dictionary">
      <tbody>
        {rows &&
          rows.map(({ title, value }) => (
            <tr key={title}>
              <td>{title}</td>
              <td>{value}</td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

const getFirstSubTableRows = (strRecord: Str) => {
  const {
    gene: { name: geneName },
    chromosome,
    position,
    end,
    tieringData: tiers,
  } = strRecord;

  const customData = JSON.stringify({ tiers });

  return [
    { title: "Gene Name", value: geneName },
    { title: "Chromosome", value: chromosome },
    { title: "Position", value: position },
    { title: "End", value: end },
    {
      title: "Tier",
      value: <CustomTier variant={{ customData }} showTranscriptName={false} />,
    },
  ];
};

const getSecondSubTableRows = (strRecord: Str) => {
  const {
    repeatUnit,
    referenceRepeatCount,
    patientRepeatCount,
    confidenceInterval,
    genotype,
  } = strRecord;
  return [
    { title: "Repeat Unit", value: repeatUnit },
    { title: "Reference Repeat length", value: referenceRepeatCount },
    { title: "Patient Repeat length", value: patientRepeatCount },
    { title: "Confidence Interval", value: confidenceInterval },
    { title: "Genotype", value: genotype },
  ];
};

export default ({ strRecord }) => (
  <>
    {subTable(getFirstSubTableRows(strRecord))}
    {subTable(getSecondSubTableRows(strRecord))}
  </>
);
