import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { reduxForm } from "redux-form";

import * as constants from "../../constants";

export class ConfirmRemovalForm extends PureComponent {
  static propTypes = {
    criteria: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    close: PropTypes.func,
    submitForm: PropTypes.func,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      submitForm,
      close,
      criteria: { id: criteriaId },
    } = this.props;

    submitForm(constants.ACMG_REMOVE_CRITERIA_EVIDENCE_FORM, {
      criteriaId,
    });

    close(e);
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <button className="btn btn-danger" type="submit">
          Remove Criteria
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: constants.ACMG_REMOVE_CRITERIA_EVIDENCE_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ConfirmRemovalForm);
