/* @flow */
import { createSelector } from "@reduxjs/toolkit";
import {
  is,
  isEmpty,
  isNil,
  not,
  path,
  pick,
  prop,
  groupBy,
  mergeDeepWith,
  concat,
} from "ramda";

// ORM imports

import {
  configGenePanelsPath,
  getGeneWrappersByGeneId,
  mergeGenes,
} from "../../data-layer/entities";
import {
  getAllGenes,
  getSelectedORMGene,
} from "../../data-layer/orm/selectors";
import { getClassForPathogenicity } from "../../data-layer/orm/selectors/GenePanelSelectors";
import {
  variantsUIPath as uiPath,
  variantsDataPath as dataPath,
} from "../../data-layer/utils";
import * as configSelectors from "../config/selectors";
import { getCurrentPatientDecisions } from "../decisions/selectors";
import * as projectSelectors from "../project/selectors";
import { getVepConsequenceLabel } from "../utils/common";
import { cvlFilterKeys } from "../utils/enum/cvlFilterKeys";

import * as constants from "./constants";
import { type State, type Gene, type Curation } from "./reducer";
import { getProjectArchivedGenePanelsMap } from "./utils";

export const isInitialised = path(uiPath("init"));
export const isLoaded = path(uiPath("loaded"));
export const page = path(uiPath("pagination", "page"));
export const pager = path(uiPath("pagination", "pager"));
export const isVariantsTableLoaded = path(uiPath("variantsLoaded"));
export const geneToFocus = path(uiPath("geneIdToFocus"));
export const activeVariantPanelGene = path(uiPath("activeGeneId"));
export const activeGeneAllVariantsCount = path(
  uiPath("activeGeneAllVariantsCount")
);
export const activeVariantPanelGeneName = path(uiPath("activeGeneName"));
export const activeVariantPanelVariant = path(uiPath("activeVariant"));
export const activeVariantPanelPatientVariant = path(
  uiPath("activePatientVariant")
);
export const activeVariantPanelTranscript = path(uiPath("activeTranscript"));
export const getExpandedVariants = path(uiPath("expandedVariants"));

export const getActiveGenesFromState = path(dataPath("activeGenes"));
export const getFilterGrouping = path(dataPath("defaults", "filterGrouping"));
export const getGeneVariantCount = path(dataPath("variantCount"));
export const getAllInheritanceValues = path(
  dataPath("defaults", "allInheritanceValues")
);
export const getVariantCountByGene = path(uiPath("variantCountByGene"));
export const getFilteredVariantInfoByGene = path(
  uiPath("filteredVariantInfoByGene")
);
export const getFilteredOutVariantsDisplayed = path(
  uiPath("filteredOutVariantsDisplayed")
);

export const getDisableFilteredOutVariants = path([
  "config",
  "snvTable",
  "disableFilteredOutVariants",
]);

const getFilterPanels = createSelector(
  configGenePanelsPath,
  projectSelectors.getCurrentProject,
  configSelectors.getFilterGenePanels,
  (panels, currentProject, filteredGenePanelIds) => {
    const genePanelsArchivedMap =
      getProjectArchivedGenePanelsMap(currentProject);

    return panels.reduce((accum, panel) => {
      const { genePanelId } = panel;
      if (filteredGenePanelIds.includes(genePanelId)) {
        accum.push({
          ...panel,
          archived: genePanelsArchivedMap.get(genePanelId),
        });
      }
      return accum;
    }, []);
  }
);

export const groupCurations = (curations: Array<Curation> = []) => {
  const groupedByVisibility = groupBy(
    ({ curatedVariantList: { showPathogenicity } }) => showPathogenicity
  )(curations || []); // default param value does not replace null values

  const unknownPathoGroup = !isNil(groupedByVisibility["0"])
    ? groupedByVisibility["0"].map(item => {
        const {
          pathogenicity,
          curatedVariantList: { showPathogenicity },
        } = item;
        if (isNil(pathogenicity) || showPathogenicity === 0) {
          item.pathogenicity = "unknown";
          return item;
        }
        return item;
      })
    : [];

  const notVisiblePathogen = !isNil(groupedByVisibility["0"])
    ? groupBy(prop("pathogenicity"))(unknownPathoGroup)
    : {};

  const visiblePathogen = !isNil(groupedByVisibility["1"])
    ? groupBy(prop("pathogenicity"))(groupedByVisibility[1])
    : {};

  const groupedPathogen = mergeDeepWith(
    concat,
    { ...notVisiblePathogen },
    { ...visiblePathogen }
  );

  return groupedPathogen;
};

const getVisibleTranscripts = (variant: any, isExpanded: boolean) => {
  const { defaultVisibleTranscript, expandedTranscripts } = variant;
  if (!expandedTranscripts.length) {
    return [];
  }

  if (isExpanded) {
    return expandedTranscripts.sort(
      ({ isDefaultVisibleTranscript: a }, { isDefaultVisibleTranscript: b }) =>
        a === b ? 0 : a ? -1 : 1
    );
  }

  return [defaultVisibleTranscript];
};

const convertGene = (
  gene: Gene,
  decisionGroups: DecisionGroups,
  expandedVariants: {},
  geneWrappersByGeneId: {},
  location: string
) => {
  const { geneId } = gene;
  /* BUGFIX SAP-20933: Only merge gene with gene panel info if current filter
  location is "panels" */
  const moiData =
    location === "panels" ? mergeGenes(geneWrappersByGeneId, geneId) : {};
  const decisions = decisionGroups.decisionsByGeneId[geneId] || [];
  const variants = gene.variants.reduce((accVariant, variant) => {
    const isExpanded = expandedVariants[variant.variantId] || false;
    const visibleTranscripts = getVisibleTranscripts(variant, isExpanded).map(
      transcript => {
        const variantTranscript = variant.variantTranscripts.find(
          vt => vt.transcriptId === transcript.transcriptId
        );

        return {
          ...transcript,
          ...variantTranscript,
          vepConsequence:
            variantTranscript &&
            getVepConsequenceLabel(variantTranscript.vepConsequence),
        };
      }
    );

    accVariant.push({
      ...variant,
      curations: groupCurations(variant.curations),
      visibleTranscripts,
      isExpanded,
    });
    return accVariant;
  }, []);

  const resultingGene = {
    ...gene,
    ...moiData,
    decisions,
    variants,
  };

  return resultingGene;
};

// Variant panel selected gene with details
export const getSelectedGene = createSelector(
  getSelectedORMGene,
  getCurrentPatientDecisions,
  getExpandedVariants,
  getGeneWrappersByGeneId,
  configSelectors.getFiltersLocation,
  (
    ormGene,
    decisionGroups: DecisionGroups,
    expandedVariants,
    geneWrappersByGeneId,
    location
  ) => {
    if (!ormGene) {
      return null;
    }
    return convertGene(
      ormGene,
      decisionGroups,
      expandedVariants,
      geneWrappersByGeneId,
      location
    );
  }
);

export const getCurrentVisibleGenes = createSelector(
  getAllGenes,
  getCurrentPatientDecisions,
  getExpandedVariants,
  getGeneWrappersByGeneId,
  configSelectors.getFiltersLocation,
  (
    activeGenes: Array<Gene> = [],
    decisionGroups: DecisionGroups,
    expandedVariants: {},
    geneWrappersByGeneId: {} = {},
    location: string
  ) => {
    if (isNil(activeGenes)) return [];
    return activeGenes.reduce(
      (accGene: Array<{ name: string }>, gene: Gene) => {
        const resultingGene = convertGene(
          gene,
          decisionGroups,
          expandedVariants,
          geneWrappersByGeneId,
          location
        );
        accGene.push(resultingGene);
        return accGene;
      },
      []
    );
  }
);

const cvlToSectionValues = ({ curatedVariantListId, name }) => ({
  key: curatedVariantListId,
  label: name,
});

const getFilterValues = (
  filterGrouping,
  filters,
  projectId,
  curatedLists,
  allPopulationAf,
  allPopulationAc
) => {
  const groupedFilters = [];

  // Populate and remove empty values
  filterGrouping.forEach(
    (section: {
      key: string,
      label: string,
      values: Array<Object>,
      type: string,
      children?: Array<Object>,
      array?: boolean,
    }) => {
      if (section.type === "nested") {
        const nestedValues = getFilterValues(
          section.values,
          filters,
          projectId,
          curatedLists,
          allPopulationAf,
          allPopulationAc
        );
        if (!isEmpty(nestedValues)) {
          groupedFilters.push({
            ...section,
            values: nestedValues,
          });
        }
        return;
      }

      const sectionClone = { ...section };
      const activeValues = filters[section.key];
      const isFilterSet = !isNil(activeValues) && !isEmpty(activeValues);

      // Populate the values where appropriate
      let { values } = section;
      // if it's a non-empty CVL filter, compute its values dynamically
      if (Object.keys(cvlFilterKeys).includes(section.key)) {
        values = isFilterSet
          ? curatedLists
              .filter(({ type }) => type === cvlFilterKeys[section.key])
              .map(cvlToSectionValues)
          : [];
      }
      if (section.key === "populationFrequency") {
        values = allPopulationAf;
      }
      if (section.format) {
        if (activeValues) {
          sectionClone.values = activeValues.map(activeValue => ({
            key: activeValue,
            label: section.format(activeValue),
          }));
          groupedFilters.push(sectionClone);
        }

        return;
      }
      sectionClone.values = values.filter((option: Object) => {
        // Grab any array filters where we need just show the selected option, like Zygosity
        const filter = filters[option.key];
        if (
          section.type === "array" &&
          is(Array, filters[section.key]) &&
          not(isEmpty(filter))
        ) {
          if (activeValues.indexOf(option.key) > -1) {
            return option;
          }
          // Grab the values directory stored on filters, these are special cases like polyphen/SIFT
          // At this point we need to handle the special case and remove them if they are not populated
        } else if (
          section.type === "values" &&
          is(Array, filter) &&
          not(isEmpty(filter))
        ) {
          option.values = filter
            .reduce((acc, { value }) => acc.concat(value), [])
            .join(", ");
          return option;
        } else if (
          section.type === "values" &&
          (is(String, filter) || is(Number, filter)) &&
          not(isEmpty(filter)) &&
          not(isNil(filter))
        ) {
          option.values = filter;
          return option;
        }
        return false;
      });

      // If sections have content then we want to render it
      if (sectionClone.values.length > 0) {
        groupedFilters.push(sectionClone);
      }
    }
  );

  return groupedFilters;
};

export const getFiltersToShowInSidebar = createSelector(
  getFilterGrouping,
  state => configSelectors.getFilterValues(state),
  configSelectors.getCurrentProjectId,
  configSelectors.getCurrentProjectCuratedLists,
  configSelectors.getAllPopulationAfWithAssemblyLabel,
  (
    filterGrouping,
    filters,
    projectId,
    curatedLists,
    allPopulationAf,
    allPopulationAc
  ) =>
    getFilterValues(
      filterGrouping,
      filters,
      projectId,
      curatedLists,
      allPopulationAf,
      allPopulationAc
    )
);

export const choosePathogenicity = (decisions: Array<Decision>) => {
  // pathogeniciy takes precedence over ACMG classification
  const pathogenicity = getClassForPathogenicity(decisions);
  if (pathogenicity) {
    return pathogenicity;
  }
  return getClassForPathogenicity(decisions, "acmg");
};

export const combineActiveGenePanels = (
  genePanels: any,
  geneVariantCount: any,
  decisionGroups: DecisionGroups
) =>
  genePanels.map(genePanel => {
    const genes = genePanel.geneToGenePanel
      .map(({ gene, transcriptId }) => {
        const foundCount = geneVariantCount.list.find(
          ({ gene_id }) => gene_id === gene.geneId
        );

        const decisions = decisionGroups.decisionsByGeneId[gene.geneId] || [];

        return {
          ...pick(["geneId", "morbidId", "name"], gene),
          transcriptId,
          active: !!foundCount,
          variantCount: foundCount,
          pathogenicity: choosePathogenicity(decisions),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    return {
      ...genePanel,
      genes,
    };
  });

export const getActiveGenePanelsForProject = createSelector(
  getFilterPanels,
  getActiveGenesFromState,
  getGeneVariantCount,
  configSelectors.getFiltersLocation,
  getCurrentPatientDecisions,
  (
    genePanels,
    activeGenes,
    geneVariantCount,
    location,
    decisionGroups: DecisionGroups
  ) => {
    if (location !== "panels") {
      return [];
    }
    return combineActiveGenePanels(
      genePanels,
      geneVariantCount,
      decisionGroups
    );
  }
);

export const getTotalGeneVariantsCount = createSelector(
  getGeneVariantCount,
  (variantCount: any) =>
    variantCount?.list?.reduce((acc, curr) => acc + curr.variant_count, 0)
);

export const getFilteredVariantsOfGene = (
  state: { [typeof constants.NAME]: State },
  { gene: { geneId } }: { gene: { geneId: number } }
) => state[constants.NAME].data.filteredVariantsByGene[geneId];
