import classNames from "classnames";
import deepFreeze from "deep-freeze";
import PropTypes from "prop-types";
import React, { memo } from "react";

import { RadioButtons } from "pattern-library";

import { filtration } from "../constants";

export const filterRadioItems = deepFreeze([
  {
    label: "All",
    value: filtration.ALL,
  },
  {
    label: "Ref",
    value: filtration.REF,
  },
  {
    label: "NoCall",
    value: filtration.NOCALL,
  },
]);

const Filter = memo(({ value, onChange }: Props) => (
  <>
    <span className={classNames("ref-no-call__filter-label")}>Show items:</span>
    <RadioButtons
      name="dataFilter"
      items={filterRadioItems}
      value={value}
      onChange={onChange}
      disabled
      isInline
    />
  </>
));

Filter.displayName = "Filter";

Filter.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Filter;
