import PapaParse from "papaparse";

import { fetchCamelizeData, processErrors } from "modules/utils";
import { csvDataToObjectsArray } from "modules/utils/common";

import { FetchDataResponse } from "../utils/fetchData";

export const LICENCES_URL = `/licences.csv`;

export const getLicences = async (): Promise<SoftwareLicence[]> => {
  const { ok, errors, file }: FetchDataResponse<any> = await fetchCamelizeData(
    LICENCES_URL,
    undefined,
    undefined,
    true
  );

  if (!ok || errors) {
    const details = processErrors(errors) || "";
    throw new Error(`Couldn't retrieve licences.${details}`);
  }

  return new Promise((resolve, reject) => {
    PapaParse.parse(file?.blob as File, {
      complete(result): void {
        resolve(csvDataToObjectsArray(result?.data as Array<Array<string>>));
      },
      error(err) {
        reject(err);
      },
      skipEmptyLines: true,
    });
  });
};
