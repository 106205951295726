import classnames from "classnames";
import React, { FunctionComponent, ReactElement, useCallback } from "react";

import { Button, Tooltip } from "pattern-library";

import Message from "modules/messages/components/Message";

import styles from "./SuggestionBanner.module.scss";
import { SuggestionBannerProps } from "./SuggestionBanner.types";

export const TEST_ID = "ariadne-suggestion-banner";

const SuggestionBanner: FunctionComponent<SuggestionBannerProps> = ({
  applyFilters,
  children,
  className,
  level = "info",
}) => {
  const clazz = classnames(styles.component, className);

  const renderMessageContent = useCallback<() => ReactElement>(
    () => (
      <>
        {children}
        <div>There&nbsp;are variant predictions for this&nbsp;patient!</div>
        <Tooltip
          content="Click to clear any manually applied filters, and show variants within any applied gene panels that have Congenica AI predictions"
          boundary="viewport"
          maxWidth={250}
        >
          <Button context="primary" onClick={applyFilters}>
            Apply Congenica AI filters
          </Button>
        </Tooltip>
      </>
    ),
    [applyFilters, children]
  );

  return (
    <div className={clazz} data-testid={TEST_ID}>
      <Message
        className={styles.message}
        level={level}
        message={renderMessageContent()}
      />
    </div>
  );
};

export default SuggestionBanner;
