import React from "react";

import { Icon, Link } from "pattern-library";

import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";
import {
  formatDate,
  formatDateTime,
  guessDate,
  sortDates,
  supportedDateFormats,
} from "modules/utils";

import { reportsText } from "../utils";

import { getEnsemblVersion } from "hooks/useEnsemblVersion";

export const tumourTypeRenderer = ({ tumourType }) =>
  tumourType && tumourType.oncotreeCode && tumourType.name
    ? `${tumourType.name} (${tumourType.oncotreeCode})`
    : "";

export const CELL_VALUE_MAP = {
  tumourType: tumourTypeRenderer,
  updated: ({ updated }) => formatDateTime(updated),
  status: ({ status, hasReports }) =>
    hasReports ? `${status}  (Report available)` : status,
  genePanelTitles: ({ genePanelTitles }) => genePanelTitles.join(", "),
  phenotypeNames: ({ phenotypeNames }) => phenotypeNames.join(", "),
  familyRefs: ({ familyRefs }) => familyRefs.join(", "),
};

const COMMON_COLUMNS = {
  updated: {
    Header: "Last Update",
    accessor: "updated",
    Cell: ({ row: { original } }) => CELL_VALUE_MAP.updated(original),
    id: "updated",
    sortType: "datetime",
  },
  status: {
    Header: "Status",
    accessor: CELL_VALUE_MAP.status,
    id: "status",
  },
};

const processMetadata = (metaDada, columns) => {
  metaDada.forEach(({ id, name, type }) => {
    columns.push({
      Header: name,
      accessor: item => {
        const { metadataValues = {} } = item;
        const value = metadataValues[id] || "";
        if (type === "Date") {
          //we have to guess date format since different formats are supported by the system and dates are stored as strings
          //(see comments in https://jira.congenica.net/browse/SAP-13385)
          const foundDate = guessDate(value, supportedDateFormats);
          if (foundDate) {
            return formatDate(foundDate);
          }
        }
        return value;
      },
      id: `metadata_${name}`,
      sortType: type === "Date" ? sortDates : "alphanumeric",
    });
  });
};

export const getOncologyColumns = metaData => {
  const columns = [
    {
      Header: "Patient Reference",
      id: "reference",
      accessor: "reference",
    },
    {
      Header: "Tumour Type",
      id: "tumourType",
      accessor: CELL_VALUE_MAP.tumourType,
    },
    {
      Header: "Baitset",
      id: "baitset",
      accessor: "baitset",
    },
    {
      Header: "Neoplastic Cellularity (%)",
      id: "neoplasticCellularity",
      accessor: "neoplasticCellularity",
    },
    {
      ...COMMON_COLUMNS.status,
    },
  ];
  processMetadata(metaData, columns);
  columns.push({
    ...COMMON_COLUMNS.updated,
  });
  return columns;
};

export const getColumns = (
  isAdmin,
  metaData,
  resetStatus,
  showReports,
  isAutoAcmgActive,
  projectType,
  showEnsemblVersion
) => {
  if (projectType === ONCOLOGY_PROJECT_TYPE) {
    return getOncologyColumns(metaData);
  }
  const columns = [
    {
      Header: "#",
      id: "reference",
      Cell: ({
        row: {
          original: {
            reference,
            hasAutomatedAnnotation,
            hasAutoAcmgSuggestions,
          },
        },
      }) => (
        <div>
          {reference}
          {!!hasAutomatedAnnotation && (
            <>
              &nbsp;
              <Link>
                <Icon title="Processed via Automated Annotation" type="cogs" />
              </Link>
            </>
          )}
          {isAutoAcmgActive && !!hasAutoAcmgSuggestions && (
            <>
              &nbsp;
              <Link>
                <Icon title="Processed via ACMG Automation" type="lightbulb" />
              </Link>
            </>
          )}
        </div>
      ),
      accessor: "reference",
    },
    {
      ...COMMON_COLUMNS.status,
    },
    showEnsemblVersion && {
      Header: "Ensembl version",
      id: "ensembl",
      accessor: ({ annotationSources }) => {
        const sources = annotationSources || "[]";
        return getEnsemblVersion(JSON.parse(sources)) || "-";
      },
    },
    {
      Header: "Gene Panels",
      accessor: CELL_VALUE_MAP.genePanelTitles,
      id: "genePanelTitles",
    },
    {
      Header: "HPO terms",
      accessor: CELL_VALUE_MAP.phenotypeNames,
      Cell: ({
        row: {
          original: { hasSomeObsoletePhenotypes },
        },
        cell: { value },
      }) => {
        if (!!hasSomeObsoletePhenotypes) {
          return (
            <div>
              <Icon
                title="Some HPO Terms provided for this patient were obsolete; up-to-date HPO Terms were used instead"
                type="warningSign"
                className="text-warning"
              />
              <span> {value}</span>
            </div>
          );
        }
        return <span>{value}</span>;
      },
      id: "phenotypeNames",
    },
    {
      Header: "CNV Analysis Status",
      accessor: "cnvAnalysis",
      id: "cnvAnalysis",
    },
    {
      Header: "Protocol",
      accessor: "protocolName",
      id: "protocolName",
    },
    {
      Header: "Sample Type",
      accessor: "sampleTypeName",
      id: "sampleTypeName",
    },
    {
      Header: "Family",
      accessor: CELL_VALUE_MAP.familyRefs,
      id: "familyRefs",
      width: 240,
    },
  ];
  processMetadata(metaData, columns);

  columns.push({
    ...COMMON_COLUMNS.updated,
  });
  columns.push({
    Header: "Reports",
    id: "reports",
    accessor: ({
      reportsCount,
      reportsWithVariantsCount,
      reportsWithoutVariantsCount,
      patientId,
    }) =>
      reportsCount > 0 ? (
        <div className="patients-action">
          <Link
            className="btn btn-default btn actions-btn btn--small patients-action-reports"
            title={`Total: ${reportsCount}\nWith variants: ${reportsWithVariantsCount}\nUnknown: ${
              reportsCount -
              (reportsWithVariantsCount + reportsWithoutVariantsCount)
            }`}
            onClick={e => {
              e.stopPropagation();
              showReports(patientId);
            }}
          >
            <Icon type="newWindow" />{" "}
            {reportsText(
              reportsCount,
              reportsWithVariantsCount,
              reportsWithoutVariantsCount
            )}
          </Link>
        </div>
      ) : (
        ""
      ),
  });
  if (isAdmin) {
    columns.push({
      Header: "Actions",
      headerClassName: "patients-action-header",
      id: "actions",
      accessor: ({ patientId }) => (
        <div className="patients-action">
          <Link
            key="btn-reset-status"
            className="btn btn-default btn actions-btn btn--small"
            onClick={e => {
              e.stopPropagation();
              resetStatus(patientId);
            }}
          >
            <Icon type="repeat" />
            <span className="actions-label">Reset status</span>
          </Link>
        </div>
      ),
    });
  }
  return columns.filter(Boolean);
};
