import Genoverse from "genoverse";
import lodash from "lodash";

import { Colors } from "../utils";

let pairedReadsTempInitObj = {
  positionFeatures(features, params) {
    // Each group will contain links to features which should be rendered separately (group === separate rendering).
    params.renderGroups = {};
    // It's helps to calculate how to change canvas's height before render of each group
    // and how many pixels to subtract from the `y` value.
    params.renderGroupsBottom = {};

    return this.base(features, params);
  },

  positionFeature(feature, params) {
    this.base(feature, params);

    const groupNumber = Math.floor(
      feature.position[params.scale].bottom / this.track.maxCanvasHeight
    );
    // We have to clone feature object, cos Genoverse stores
    // and MODIFIES the original one during rendering (another phase of event loop).
    const clonedFeature = lodash.cloneDeep(feature);

    // See comments in `positionFeatures` method.
    if (!params.renderGroups[groupNumber]) {
      params.renderGroups[groupNumber] = [];
      params.renderGroupsBottom[groupNumber] = 0;
    }

    params.renderGroups[groupNumber].push(clonedFeature);
    params.renderGroupsBottom[groupNumber] = Math.max(
      params.renderGroupsBottom[groupNumber],
      feature.position[params.scale].bottom
    );
  },
};

export const featureMateHasSequence = ({ sequence } = {}) =>
  typeof sequence === "string";

export const featureMateHasStart = featMate =>
  featMate && featMate.hasOwnProperty("start");

export const featureMateHasInsertions = ({ insertions } = {}) =>
  !!insertions && !!insertions.length;

export const getBasePairValue = (bp, strand) => {
  let basePair = bp;
  if (basePair === " " && strand === 1) {
    basePair = ">";
  }
  if (basePair === " " && strand === -1) {
    basePair = "<";
  }

  return basePair;
};

// This track was ported from original version.
// Funcionaly the same just added needed changes to port it and refactored code duplications
// also made it more modern

export const View = {
  // Since we render group by group, each group should be rendered from the beginning of a canvas.
  // Offset should be stored here.
  currentRenderYFixValue: 0,
  // Genoverse logic allows us to use special models and views for different scales, but controller always is the same.
  // This value helps us to understand inside controller what scale is used.
  viewId: "PairedReads view",

  drawSequence(feature, context, scale, width) {
    const y = feature.position[scale].Y;
    feature.position[scale].Y = this.currentRenderYFixValue
      ? y - this.currentRenderYFixValue + this.track.seamOverlap
      : y;

    const { first, second } = feature;

    // Draw connecting line
    if (featureMateHasSequence(first) && featureMateHasSequence(second)) {
      this.drawConnectingLine(feature, context, scale, width);
    }

    // Draw second mate
    if (featureMateHasSequence(second)) {
      this.drawSequenceMate(feature, second, context, scale, width);
    }
    // Draw first mate
    if (featureMateHasSequence(first)) {
      this.drawSequenceMate(feature, first, context, scale, width, true);
    }
  },

  drawSequenceMate(feature, featureMate, context, scale, width, isFirst) {
    const drawLabels =
      this.labelWidth[this.widestLabel] < width - 1 && this.featureHeight > 2;
    const ySequence = feature.position[scale].Y;
    const { strand, sequence } = featureMate;
    const { first, second, position } = feature;
    const { colors } = this.track;

    let overlap = 0;
    // Check for overlap of first and second reads
    if (isFirst) {
      if (second && second.start && first.end >= second.start) {
        overlap = first.end - second.start + 1;
      }
    }

    for (let i = 0; i < sequence.length; i++) {
      const start =
        position[scale].X +
        i * scale +
        (featureMate.start - feature.start) * scale;

      if (start >= -scale && start <= context.canvas.width) {
        const basePair = getBasePairValue(sequence.charAt(i), strand);

        const { color, labelColor } = colors.find(({ predicate }) =>
          predicate(basePair)
        );

        context.fillStyle = color;
        context.fillRect(start, ySequence, width, this.featureHeight);

        if (!this.labelWidth[basePair]) {
          this.labelWidth[basePair] =
            Math.ceil(context.measureText(basePair).width) + 1;
        }

        if (drawLabels) {
          context.fillStyle = labelColor;
          context.fillText(
            basePair,
            start + (width - this.labelWidth[basePair]) / 2,
            ySequence + this.labelYOffset
          );
        }
      }
    }

    //Draw strand arrow head and tail
    if (this.featureHeight > 2) {
      this.drawStrandArrow(feature, featureMate, context, scale, width);
    }

    if (overlap > 0) {
      for (let i = 0; i < overlap; i++) {
        const start =
          position[scale].X +
          i * scale +
          (second.start - feature.start) * scale;

        if (start >= -scale && start <= context.canvas.width) {
          // Clip to half height
          context.save();
          context.beginPath();
          context.rect(start, ySequence, width, this.featureHeight / 2); // Half height
          context.clip();

          const basePair = getBasePairValue(
            second.sequence.charAt(i),
            second.strand
          );

          const { color, labelColor } = colors.find(({ predicate }) =>
            predicate(basePair)
          );

          context.fillStyle = color;
          context.fillRect(start, ySequence, width, this.featureHeight);

          if (!this.labelWidth[basePair]) {
            this.labelWidth[basePair] =
              Math.ceil(context.measureText(basePair).width) + 1;
          }

          if (drawLabels) {
            context.fillStyle = labelColor;
            // Display letter
            context.fillText(
              basePair,
              start + (width - this.labelWidth[basePair]) / 2,
              ySequence + this.labelYOffset
            );
          }

          //Restore state
          context.restore();
        }
        // Clip overlap region - only display top half

        //Redraw second arrow
        if (this.featureHeight > 2) {
          this.drawStrandArrow(feature, second, context, scale, width);
        }
      }
    }
    if (
      this.featureHeight > 2 &&
      featureMate.insertions &&
      featureMate.insertions.length
    ) {
      this.drawInsertions(feature, featureMate, context, scale, width);
    }
  },
  drawInsertions(feature, featureMate, context, scale) {
    const { position } = feature;
    const { insertions } = featureMate;
    for (let i = 0; i < insertions.length; i++) {
      const insertion = insertions[i];
      const start =
        position[scale].X +
        insertion[0] * scale +
        (featureMate.start - feature.start) * scale;
      if (start >= -scale && start <= context.canvas.width) {
        context.fillStyle = Colors.BLUE;
        context.fillRect(start - 1, position[scale].Y, 2, this.featureHeight);

        if (scale > 5)
          this.drawTriangles(
            context,
            start,
            position[scale].Y,
            this.featureHeight,
            4
          );
      }
    }
  },
  drawTriangles(context, x, y, height, width) {
    context.strokeStyle = "#428bca";
    //draw top triangle
    context.beginPath();
    context.moveTo(x - width, y); //Top left
    context.lineTo(x + width, y); //Top right
    context.lineTo(x, y + width * 1.5); //Bottom centre
    context.lineTo(x - width, y); //Top left
    context.fill();
    context.stroke();
    context.closePath();

    //draw bottom triangle
    context.beginPath();
    context.moveTo(x - width, y + height); //Bottom left
    context.lineTo(x + width, y + height); //Bottom right
    context.lineTo(x, y + height - width * 1.5); //Top centre
    context.lineTo(x - width, y + height); //Bottom left
    context.fill();
    context.stroke();
    context.closePath();
  },
  drawStrandArrow(feature, featureMate, context, scale, width) {
    const { position } = feature;
    const { strand, sequence } = featureMate;
    const ySequence = feature.position[scale].Y;
    //Arrow head
    let x =
      strand === 1
        ? position[scale].X + sequence.length * scale
        : position[scale].X;
    x += (featureMate.start - feature.start) * scale;
    this.drawArrowHead(
      context,
      x,
      ySequence,
      width / 3,
      this.featureHeight,
      strand === 1
    );

    //Arrow tail
    x =
      strand === 1
        ? position[scale].X
        : position[scale].X + sequence.length * scale;
    x += (featureMate.start - feature.start) * scale;
    this.drawArrowTail(
      context,
      x,
      ySequence,
      width / 3,
      this.featureHeight,
      strand === 1
    );
  },
  drawArrowHead(context, x, y, width, height, isForward) {
    context.beginPath();
    context.moveTo(x, y);
    if (isForward) {
      context.fillStyle = Colors.DUSTY_GREY;
      context.lineTo(x + width, y + height / 2);
    } else {
      context.fillStyle = Colors.SILVER;
      context.lineTo(x - width, y + height / 2);
    }
    context.lineTo(x, y + height);
    context.lineTo(x, y);
    context.fill();
    context.closePath();
  },
  drawArrowTail(context, x, y, width, height, isForward) {
    context.fillStyle = Colors.SILVER;
    context.beginPath();
    context.moveTo(x, y); //Top
    if (isForward) {
      context.fillStyle = Colors.DUSTY_GREY;
      context.lineTo(x - width, y);
      context.lineTo(x, y + height / 2);
      context.lineTo(x - width, y + height);
      context.lineTo(x, y + height);
    } else {
      context.fillStyle = Colors.SILVER;
      context.lineTo(x + width, y);
      context.lineTo(x, y + height / 2);
      context.lineTo(x + width, y + height);
      context.lineTo(x, y + height);
    }
    context.lineTo(x, y);
    context.fill();
    context.closePath();
  },
  drawConnectingLine(feature, context, scale, width) {
    const { position, first, second, start } = feature;
    const drawLine = this.labelWidth[this.widestLabel] < width;
    const x = position[scale].X + first.sequence.length * scale;
    const x2 = position[scale].X + (second.start - start) * scale;
    const y = position[scale].Y + this.featureHeight / 2;

    if (drawLine) {
      context.beginPath();
      context.setLineDash([1, 3]);
      context.moveTo(x, y);
      context.lineTo(x2, y);
      context.strokeStyle = Colors.BLUE;

      context.stroke();
    }
  },
  positionFeatures: pairedReadsTempInitObj.positionFeatures,
  positionFeature: pairedReadsTempInitObj.positionFeature,
};

const AlternateView = {
  currentRenderYFixValue: 0,
  viewId: "PairedReads view : 2000",
  drawFeature(feature, featureContext, labelContext, scale) {
    feature.y = this.currentRenderYFixValue
      ? feature.y - this.currentRenderYFixValue + this.track.seamOverlap
      : feature.y;

    const { x, y, color, first, second, height } = feature;

    if (x < 0 || x + feature.width > this.width) {
      this.truncateForDrawing(feature);
    }

    if (color !== false) {
      if (!color) {
        this.setFeatureColor(feature);
      }

      featureContext.fillStyle = color;
      if (featureMateHasStart(first)) {
        const start =
          feature.position[scale].X + (first.start - feature.start) * scale;
        const width = (first.end - first.start) * scale;

        featureContext.fillRect(start, y, width, height);
      }
      if (featureMateHasStart(second)) {
        const start =
          feature.position[scale].X + (second.start - feature.start) * scale;
        const width = (second.end - second.start) * scale;

        featureContext.fillStyle = Colors.SILVER;
        featureContext.fillRect(start, y, width, height);
      }
    }
  },
  positionFeatures: pairedReadsTempInitObj.positionFeatures,
  positionFeature: pairedReadsTempInitObj.positionFeature,
};

const Controller = {
  currentRenderGroup: null,

  render(features, $img) {
    const viewId = this.track.view.viewId || "";
    // We have `false` value for scales >= `10000` and due to base track logic
    // `public/genoverse/js/Track/View.js` is used for this scale (no `viewId`).
    if (viewId.indexOf("PairedReads view") !== 0) {
      this.checkHeight();
      return;
    }

    const $container = $img.parent();
    const params = $img.data();
    this.view.positionFeatures(
      this.view.scaleFeatures(features, params.scale),
      params
    );

    if (!Object.keys(params.renderGroups).length) {
      $container.removeClass("gv-loading");
      $img.remove();
      this.checkHeight();
      return;
    }

    // This track doesn't use a separate canvas for labels, we can ignore this feature here.

    $img.removeData();
    // Images for each group.
    Object.keys(params.renderGroups).forEach(function (_, i) {
      if (!i) return;
      $img
        .clone(true)
        .css("top", params.renderGroupsBottom[i - 1] - this.track.seamOverlap)
        .appendTo($container);
    }, this);

    const canvas = document.createElement("canvas");
    canvas.width = params.width;

    const that = this;
    setTimeout(
      function step(groupKey) {
        that.renderGroup($container, canvas, params, groupKey);
        that.checkHeight();
        if (params.renderGroups[groupKey + 1]) {
          setTimeout(step, 0, groupKey + 1);
        }
      },
      0,
      0
    );
  },

  renderGroup($container, canvas, params, groupKey) {
    canvas.height =
      +groupKey === 0
        ? params.renderGroupsBottom[groupKey]
        : params.renderGroupsBottom[groupKey] -
          params.renderGroupsBottom[groupKey - 1] +
          this.track.seamOverlap;

    this.track.view.currentRenderYFixValue =
      +groupKey === 0 ? 0 : params.renderGroupsBottom[groupKey - 1];

    this.view.draw(
      params.renderGroups[groupKey],
      canvas.getContext("2d"),
      null,
      params.scale
    );

    $container.children()[groupKey].src = canvas.toDataURL();
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
  },
};

export const Config = {
  id: "Congenica.SequenceAlignment",
  namespace: "Congenica.SequenceAlignment",
  name: "Sequence alignment",
  view: View,
  10000: false,
  2000: {
    featureHeight: 2,
    view: AlternateView,
  },
  100: {
    featureHeight: 2,
  },
};

export const Track = Genoverse.Track.extend({
  ...Config,
  model: Genoverse.Track.Model.extend(Config.model),
  view: Genoverse.Track.View.Sequence.extend(Config.view),
  controller: Genoverse.Track.Controller.extend(Controller),
  click(e) {
    const { pageX, offsetY, target } = e;
    const { scale, scaledStart, chr } = this.browser;

    const x = pageX - this.container.parent().offset().left + scaledStart;
    const y = offsetY;

    const feature = this[
      target.className === "gv-labels" ? "labelPositions" : "featurePositions"
    ]
      .search({ x, y, w: 1, h: 1 })
      .sort((a, b) => a.sort - b.sort)[0];

    if (feature) {
      const { first, second, position } = feature;
      let isInsertion = false;

      [first, second].forEach(featureMate => {
        if (featureMateHasInsertions(featureMate)) {
          const { insertions } = featureMate;
          const start =
            position[scale].start + parseInt(insertions[0][0]) * scale;
          const buffer = 10;
          if (x >= start - buffer && x <= start + buffer) {
            const featureMenu = {
              title: "Insertion",
              start: feature.start + insertions[0][0],
              end: feature.start + insertions[0][0],
              Position: insertions[0][0],
              Insertion: insertions[0][1],
              chr,
            };
            this.browser.makeMenu(featureMenu, e, this.track);
            isInsertion = true;
          }
        }
      });

      if (isInsertion === false) {
        const featureMenu = {
          title: feature.id,
          start: feature.start,
          end: feature.end,
          Length: feature.length,
          chr,
        };
        this.browser.makeMenu(featureMenu, e, this.track);
      }
    }
  },
  2000: {
    ...Config["2000"],
    view: Genoverse.Track.View.extend(Config["2000"]),
  },
});

pairedReadsTempInitObj = null;
