import Genoverse from "genoverse";

import { Colors, createMenu, createTable } from "../utils";

export const Controller = {
  populateMenu(feature) {
    const { filter, HGVSc, HGVSp, external_ids, chr, start } = feature;
    const { menuElements } = this.track;

    const validate = (value, result) => (value ? result || value : undefined);

    const menu = createMenu([
      {
        label: "title",
        value: menuElements.title(feature),
      },
      {
        label: "Location",
        value: `${chr}:${start}`,
      },
      {
        label: "Filter",
        value: filter,
      },
      {
        label: "HGVSc",
        value: validate(HGVSc),
      },
      {
        label: "HGVSp",
        value: validate(HGVSp),
      },
      {
        label: "IDs",
        value: validate(external_ids),
      },
    ]);

    const table = createTable({
      head: menuElements.populationsValues,
      body: (menuElements.populations || []).map(population =>
        population(feature)
      ),
    });

    return { ...menu, ...table };
  },
};

export const Model = {
  insertFeature(feature) {
    const { start, end } = feature;
    const { legend, color, labelColor } = (this.track.colors || []).find(
      color => color.predicate(feature)
    );

    this.base({
      ...feature,
      start: Number(start),
      end: Number(end),
      legend,
      color,
      labelColor,
    });
  },
};

export const Config = {
  id: "Congenica.Exac",
  namespace: "Congenica.Exac",
  name: "Exac",
  category: "Population",
  tags: ["Population", "SNV", "Sequence", "Variants"],
  labels: false,
  featureColor: Colors.BLUE,
  threshold: 1e5,
  legend: true,
  bump: true,
  model: Model,
  controller: Controller,
  height: 35,
  featureHeight: 15,
};

export const Track = Genoverse.Track.extend({
  ...Config,
  model: Genoverse.Track.Model.extend(Config.model),
  controller: Genoverse.Track.Controller.extend(Config.controller),
});
