// @flow
import { set } from "dot-prop-immutable";

import { modifyStateWith } from "modules/utils/reducers";

import type { Action } from "./actions";
import { actionType } from "./actions";
import type { MetadataInfo } from "./flow-types";

type State = {
  +data: {
    fields: Array<MetadataField>,
    inheritedFields: Array<MetadataField>,
    categories: Array<MetadataCategory>,
    inheritedCategories: Array<MetadataCategory>,
  },
  +ui: {
    loading: boolean,
    metadataInfo: MetadataInfo,
  },
};

export const initialState = {
  data: {
    fields: [],
    inheritedFields: [],
    categories: [],
    inheritedCategories: [],
  },
  ui: {
    loading: false,
    metadataInfo: {},
  },
};

const setFieldsData = fields => state => set(state, "data.fields", fields);
const setInheritedFieldsData = fields => state =>
  set(state, "data.inheritedFields", fields);
const setCategoriesData = fields => state =>
  set(state, "data.categories", fields);
const setInheritedCategoriesData = fields => state =>
  set(state, "data.inheritedCategories", fields);

export default function reducer(
  state: State = initialState,
  { type, payload }: Action
): State {
  switch (type) {
    case actionType.FETCH_PATIENT_METADATA_FIELDS_SUCCESS:
      return modifyStateWith(
        setFieldsData(payload.fields),
        setInheritedFieldsData(payload.inheritedFields)
      )(state);
    case actionType.FETCH_PATIENT_METADATA_FIELDS_FAILURE:
      return modifyStateWith(
        setFieldsData([]),
        setInheritedFieldsData([])
      )(state);
    case actionType.FETCH_PATIENT_METADATA_CATEGORIES_SUCCESS:
      return modifyStateWith(
        setCategoriesData(payload.categories),
        setInheritedCategoriesData(payload.inheritedCategories)
      )(state);
    case actionType.FETCH_PATIENT_METADATA_CATEGORIES_FAILURE:
      return modifyStateWith(
        setCategoriesData([]),
        setInheritedCategoriesData([])
      )(state);
    case actionType.SET_LOADING:
      return set(state, "ui.loading", payload);
    case actionType.SET_METADATA_INFO:
      return set(state, "ui.metadataInfo", payload);
    default:
      return state;
  }
}
