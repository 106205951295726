// @flow
import { createAction } from "../utils";

export const actionType = Object.freeze({
  INIT: "PROJECT_IRS_INIT",
  FETCH_PROJECT_IRS_START: "FETCH_PROJECT_IRS_START",
  FETCH_PROJECT_IRS_SUCCESS: "FETCH_PROJECT_IRS_SUCCESS",
  FETCH_PROJECT_IRS_FAILURE: "FETCH_PROJECT_IRS_FAILURE",
  SET_PROJECT_IR_FOR_DELETE: "SET_PROJECT_IR_FOR_DELETE",
  DELETE_PROJECT_IR: "DELETE_PROJECT_IR",
  RELOAD_PROJECT_IR: "RELOAD_PROJECT_IR",
  SET_PROJECT_IRS_LOADING: "SET_PROJECT_IRS_LOADING",
  SET_PROJECT_IR_DELETING: "SET_PROJECT_IR_DELETING",
  REFRESH_IR_STATUS_START: "REFRESH_IR_STATUS_START",
  REFRESH_IR_STATUS_SUCCESS: "REFRESH_IR_STATUS_SUCCESS",
  RESET_DATA_FILE_START: "RESET_DATA_FILE_START",
  RESET_DATA_FILE_SUCCESS: "RESET_DATA_FILE_SUCCESS",
});

export type InitializeAction = FSA<typeof actionType.INIT, number>;
export const initialize = (projectId: number): InitializeAction =>
  createAction(actionType.INIT, projectId);

export const fetchProjectIRs: AsyncActionCreator = {
  start: (
    projectId: number
  ): FSA<typeof actionType.FETCH_PROJECT_IRS_START, number> =>
    createAction(actionType.FETCH_PROJECT_IRS_START, projectId),
  success: (
    data: Array<ProjectInterpretationRequest>
  ): FSA<
    typeof actionType.FETCH_PROJECT_IRS_SUCCESS,
    Array<ProjectInterpretationRequest>
  > => createAction(actionType.FETCH_PROJECT_IRS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_PROJECT_IRS_FAILURE> =>
    createAction(actionType.FETCH_PROJECT_IRS_FAILURE),
};

export const refreshIRStatus: AsyncActionCreatorLite = {
  start: (
    irId: number
  ): FSA<typeof actionType.REFRESH_IR_STATUS_START, number> =>
    createAction(actionType.REFRESH_IR_STATUS_START, irId),
  success: (data: {
    irId: number,
    dataFilesStatus: DataFilesStatus,
  }): FSA<
    typeof actionType.REFRESH_IR_STATUS_SUCCESS,
    { irId: number, dataFilesStatus: DataFilesStatus }
  > => createAction(actionType.REFRESH_IR_STATUS_SUCCESS, data),
};

export const resetDataFile: AsyncActionCreatorLite = {
  start: (
    dataFileId: number
  ): FSA<typeof actionType.RESET_DATA_FILE_START, number> =>
    createAction(actionType.RESET_DATA_FILE_START, dataFileId),
  success: (
    irId: number
  ): FSA<typeof actionType.RESET_DATA_FILE_SUCCESS, number> =>
    createAction(actionType.RESET_DATA_FILE_SUCCESS, irId),
};

export type DeleteProjectIRAction = FSA<
  typeof actionType.DELETE_PROJECT_IR,
  { projectId: number, irId: number, reason: string }
>;
export const deleteProjectIR = (
  projectId: number,
  irId: number,
  reason: string
): DeleteProjectIRAction =>
  createAction(actionType.DELETE_PROJECT_IR, { projectId, irId, reason });

export type ReloadProjectIRAction = FSA<
  typeof actionType.RELOAD_PROJECT_IR,
  InterpretationRequest
>;
export const reloadProjectIR = (
  ir: InterpretationRequest
): ReloadProjectIRAction => createAction(actionType.RELOAD_PROJECT_IR, ir);

type SetProjectIRForDeleteAction = FSA<
  typeof actionType.SET_PROJECT_IR_FOR_DELETE,
  number
>;
export const setProjectIRForDelete = (
  irId: number
): SetProjectIRForDeleteAction =>
  createAction(actionType.SET_PROJECT_IR_FOR_DELETE, irId);

export const resetProjectIRForDelete = () =>
  createAction(actionType.SET_PROJECT_IR_FOR_DELETE, null);

type SetProjectIRsLoadingAction = FSA<
  typeof actionType.SET_PROJECT_IRS_LOADING,
  boolean
>;
export const setProjectIRsLoading = (
  loading: boolean
): SetProjectIRsLoadingAction =>
  createAction(actionType.SET_PROJECT_IRS_LOADING, loading);

type SetProjectIRDeletingAction = FSA<
  typeof actionType.SET_PROJECT_IR_DELETING,
  boolean
>;
export const setProjectIRDeleting = (
  deleting: boolean
): SetProjectIRDeletingAction =>
  createAction(actionType.SET_PROJECT_IR_DELETING, deleting);

export type Action =
  | fetchProjectIRs.start
  | fetchProjectIRs.success
  | fetchProjectIRs.failure
  | refreshIRStatus.start
  | refreshIRStatus.success
  | resetDataFile.start
  | resetDataFile.success
  | DeleteProjectIRAction
  | InitializeAction
  | SetProjectIRForDeleteAction
  | ReloadProjectIRAction
  | SetProjectIRsLoadingAction;
