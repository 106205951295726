import { path } from "ramda";

import { NAMESPACE } from "./constants";
import { AcmgAuditInfo, AuditInfo, CVLProps } from "./types";

const dataPath = (...args) => path([NAMESPACE, "data", ...args]);
const uiPath = (...args) => path([NAMESPACE, "ui", ...args]);

export const cvlSelector: (state: any) => CVLProps = dataPath(
  "auditInfo",
  "data",
  "cvl"
);
export const auditInfoSelector: (state: any) => AsyncData<AuditInfo> =
  dataPath("auditInfo");
export const acmgAuditInfoSelector: (state: any) => AsyncData<AcmgAuditInfo> =
  dataPath("acmgAuditInfo");
export const loading: (state: any) => boolean = uiPath("loading");
