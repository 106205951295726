// @flow
import { createAction } from "data-layer/utils";

import * as constants from "./constants";

type SetCurrentPatientDecisionsAction = FSA<
  typeof constants.SET_CURRENT_PATIENT_DECISIONS,
  Array<Decision>
>;

export const setCurrentPatientDecisions = (
  decisions: Array<Decision> = []
): SetCurrentPatientDecisionsAction =>
  createAction(constants.SET_CURRENT_PATIENT_DECISIONS, decisions);

type DeleteVariantDecisionAction = FSA<
  typeof constants.UPDATE_VARIANT_DECISION,
  { variantId: number, decisonType: DecisionType }
>;

export const deleteVariantDecision = (
  variantId: number,
  decisionType: DecisionType
): DeleteVariantDecisionAction =>
  createAction(constants.DELETE_VARIANT_DECISION, {
    variantId,
    decisionType,
  });

type UpdateVariantDecisionAction = FSA<
  typeof constants.DELETE_VARIANT_DECISION,
  DecisionInfo
>;

export const updateVariantDecision = (
  update: DecisionInfo
): UpdateVariantDecisionAction =>
  createAction(constants.UPDATE_VARIANT_DECISION, update);

export type Action =
  | SetCurrentPatientDecisionsAction
  | UpdateVariantDecisionAction;
