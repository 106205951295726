import * as yup from "yup";

import { NAME_TAKEN } from "../../common/constants";
import { REQUIRED_FIELD_MESSAGE } from "../messages/constants";

export const getCvlValidationSchema = lists =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(REQUIRED_FIELD_MESSAGE)
      .test("nameUnique", NAME_TAKEN, function (val) {
        return !lists.some(({ name, curatedVariantListId }) => {
          const trimmedTitle = name.trim();
          return (
            val === trimmedTitle &&
            this.parent.curatedVariantListId !== curatedVariantListId
          );
        });
      }),
    type: yup.string().required(REQUIRED_FIELD_MESSAGE),
    variantType: yup.string().required(REQUIRED_FIELD_MESSAGE),
    showPathogenicity: yup.boolean().required(REQUIRED_FIELD_MESSAGE),
    isAutomatedAnnotationDecisions: yup
      .boolean()
      .required(REQUIRED_FIELD_MESSAGE),
  });
