import { is, path, prop } from "ramda";

/**
 * @param accessor - a path to a value in a variant
 * @param parse
 * @return a function to retrieve a variant column based on the input params
 */
export const getAccessorFn = (accessor, parse = false): Function => {
  if (is(Array, accessor)) {
    if (parse) {
      return (value: any) => {
        const [jsonStringPath] = accessor;
        const { [jsonStringPath]: pathValue } = value;
        if (pathValue) {
          const parsedValue = JSON.parse(pathValue);
          return path(accessor.slice(1))(parsedValue);
        }
        return null;
      };
    } else {
      return path(accessor);
    }
  }

  return prop(accessor);
};
