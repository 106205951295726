import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Icon,
  Button,
  LoadingOverlay,
} from "pattern-library";

import { hideExportModal, exportCvlVariants } from "../actions";
import {
  getDisplayExportModal,
  getExportCVLId,
  getExportInvalidData,
  isExportLoading,
} from "../selectors";

export const CVLExportModal = ({
  hasInvalidData,
  curatedVariantListId,
  showExportModal,
  closeClickHandler,
  exportCvlVariants,
  loading,
}: PropsFromRedux) => {
  const downloadWithErrors = useCallback(() => {
    exportCvlVariants(curatedVariantListId, true);
  }, [exportCvlVariants, curatedVariantListId]);

  const downloadWithoutErrors = useCallback(() => {
    exportCvlVariants(curatedVariantListId, false);
  }, [exportCvlVariants, curatedVariantListId]);

  const getFooterContent = () => (
    <ModalFooter>
      <Button
        className={`btn btn-primary ${!hasInvalidData ? "hidden" : ""}`}
        type="button"
        onClick={downloadWithErrors}
        data-testid="exportWithInvalidBtn"
      >
        <Icon type="downloadAlt" /> Export with invalid variants
      </Button>
      <Button
        className={`btn btn-primary ${!hasInvalidData ? "hidden" : ""}`}
        type="button"
        onClick={downloadWithoutErrors}
        data-testid="exportWithoutInvalidBtn"
      >
        <Icon type="downloadAlt" /> Export without invalid variants
      </Button>
    </ModalFooter>
  );

  return (
    <Modal show={showExportModal} close={closeClickHandler}>
      <ModalHeader close={closeClickHandler}>
        <strong>Curated List Export</strong>
      </ModalHeader>
      <LoadingOverlay loading={loading}>
        <ModalBody>
          {!hasInvalidData && <p>Preparing file for download...</p>}
          {hasInvalidData && (
            <p>
              This curated list contains variants with missing mandatory ref or
              alt allele fields. If exported with these variants, the file will
              not be valid for import into Congenica. You can export the curated
              list with or without these invalid variants.
            </p>
          )}
        </ModalBody>
        {getFooterContent()}
      </LoadingOverlay>
    </Modal>
  );
};

const mapStateToProps = state => ({
  showExportModal: getDisplayExportModal(state),
  hasInvalidData: getExportInvalidData(state),
  curatedVariantListId: getExportCVLId(state),
  loading: isExportLoading(state),
});

const mapDispatchToProps = {
  closeClickHandler: hideExportModal,
  exportCvlVariants,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLExportModal);
