export enum GenomeAssembly {
  GRCH37 = "grch37",
  GRCH38 = "grch38",
}

export type GenomeAssemblyKeys = keyof typeof GenomeAssembly;
export type GenomeAssemblyType = typeof GenomeAssembly[GenomeAssemblyKeys];

export type AppVersions = {
  rareDisease?: string;
  oncology?: string;
};

export type UserGuide = {
  userGuide: string;
  instructionsForUse: string;
  userGuideUs?: string;
};

export type UserGuides = {
  rareDisease: UserGuide;
  oncology: UserGuide;
};
