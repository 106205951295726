export const MEMORY_STRATEGY_PERMANENT = "memory.strategy.permanent";
export const MEMORY_STRATEGY_TEMPORARY = "memory.strategy.temporary";

export const DATA_TYPE_BOOLEAN = "data.type.boolean";
export const DATA_TYPE_NUMBER = "data.type.number";
export const DATA_TYPE_STRING = "data.type.string";

export const ERROR_KEY = "Invalid preference key";
export const ERROR_READ = "Unable to read preference";
export const ERROR_WRITE = "Unable to write preference";
export const ERROR_ACCESS = "Accessing store before user is set";
