import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { FormField } from "pattern-library";

export default class PasswordField extends PureComponent {
  static displayName = "PasswordField";

  static propTypes = {
    input: PropTypes.object.isRequired,
  };

  render() {
    const { input, meta, ...rest } = this.props;

    return <FormField {...input} {...rest} {...meta} />;
  }
}
