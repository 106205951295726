import classnames from "classnames";
import PropTypes from "prop-types";
import React, { memo } from "react";

import { Button, ButtonGroup } from "pattern-library";

import styles from "./components.module.scss";

const AddMetadataButton = memo(({ action, content }) => (
  <div className={classnames("row", styles.addMetadataButton)}>
    <div className="col-sm-3 col-sm-offset-9 col-xs-12">
      <ButtonGroup className={styles.addMetadataButtonGroup}>
        <Button context="primary" onClick={action}>
          {content}
        </Button>
      </ButtonGroup>
    </div>
  </div>
));

AddMetadataButton.propTypes = {
  action: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default AddMetadataButton;
