import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";

import { Modal, Link } from "pattern-library";

import { SUPPORT_EMAIL } from "common/constants";
import { FORM_FILE_TYPES } from "modules/interpretationRequests";
import {
  getCNVCallingRequestError,
  isIRv2,
} from "modules/interpretationRequests/selectors";
import { isOnPrem } from "modules/systemConfig/selectors";

import FileChecklist from "./FileChecklist";
import OnPremFileChecklist from "./OnPremFileChecklist";
import { default as OncologyFileChecklist } from "./oncology/FileChecklist";

const ModalHeader = ({ uploadInfo, showAccessDetails }) => (
  <div className="text-danger attention-message">
    <span>
      <i className="fa fa-info-circle" aria-hidden="true" />
    </span>
    <span>{uploadInfo}</span>
    {showAccessDetails && (
      <div>
        Contact <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>{" "}
        for access details if you do not have user credentials.
      </div>
    )}
  </div>
);

const IRSubmittedModal = ({
  show,
  close,
  samples,
  createNewIR,
  submissionErrors,
  isOnPrem,
  isIRv2,
}) => {
  const uploadInfo = isOnPrem
    ? "The below files will be used from the mounted local data storage"
    : "Please upload the following files to the SFTP server.";

  const hideModalHeader = useMemo(
    () => isIRv2 && samples?.[0]?.files?.[0].fileType === FORM_FILE_TYPES.VCF,
    [isIRv2, samples]
  );

  return (
    <Modal
      title="Your interpretation Request has been successfully submitted"
      close={close}
      show={show}
    >
      <div className="alert alert-success">
        <h5 className="text-center">
          Your interpretation Request has been successfully submitted
        </h5>

        {!hideModalHeader && (
          <ModalHeader uploadInfo={uploadInfo} showAccessDetails={isOnPrem} />
        )}

        {!isOnPrem && !isIRv2 && <FileChecklist samples={samples} isModal />}
        {isIRv2 && <OncologyFileChecklist isModal />}
        {isOnPrem && !isIRv2 && (
          <OnPremFileChecklist samples={samples} isModal />
        )}

        {!!submissionErrors && (
          <div className="text-warning">
            <div className="attention-message">
              <div className="bg-warning padded-block">{submissionErrors}</div>
            </div>
          </div>
        )}

        <div className="button-panel">
          <button
            type="button"
            className="btn btn-success"
            onClick={createNewIR}
          >
            Create new IR
          </button>
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={close}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

IRSubmittedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  samples: PropTypes.array.isRequired,
  createNewIR: PropTypes.func.isRequired,
  submissionErrors: PropTypes.string,
  isIRv2: PropTypes.bool,
};

const mapStateToProps = state => ({
  submissionErrors: getCNVCallingRequestError(state),
  isOnPrem: isOnPrem(state),
  isIRv2: isIRv2(state),
});

export default connect(mapStateToProps, null)(IRSubmittedModal);
