import { react as autoBind } from "auto-bind";
import classnames from "classnames";
import { always, isNil, equals, or, not } from "ramda";
import React, { PureComponent } from "react";

import { makeSafeForCSS } from "common/utils";

import { Tab } from "./Tab";

const stackedLeft = equals("left");

type TabElement = {
  name: string;
  className?: string;
  component: Index | (() => void);
  isHidden: boolean | (() => void);
  componentProps: Index;
};

interface Props {
  // [name: string]: any;
  /**
   * Component to render in each tab
   */
  tabComponent: typeof React.Component;
  /**
   * Component to render in each content for a tab UNLESS a component is
   * specified in the tabs props
   */
  contentComponent: typeof React.Component;
  /**
   * Array of tabs
   */
  tabs: TabElement[];
  /**
   * Should the tabs be at the top or left/right from the content
   */
  stacked: boolean;
  /**
   * Position of tabs is side mode
   */
  stackedPosition: "left" | "right";
  /**
   * Index of active tab
   */
  active: number;
  /**
   * Default onClick callback for a tab
   */
  onClick: () => void;
  /**
   * on Tab switch it wont unmount previously rendered component
   */
  stopUnmount: boolean;
}

/**
 * @deprecated
 * please see pattern-library/modules/composable-tabs/ComposableTabs.js
 */
export default class Tabs extends PureComponent<Props> {
  static displayName = "Tabs";
  static defaultProps = {
    tabs: [],
    stacked: false,
    stackedPosition: "left",
    isActive: always(false),
    active: undefined,
    onClick: always(),
    stopUnmount: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  isHidden(tab) {
    if (typeof tab.isHidden === "function") {
      return tab.isHidden();
    }
    return tab.isHidden;
  }

  renderTabs(tabs) {
    const {
      tabComponent: Component,
      stacked,
      stackedPosition,
      active,
      onClick,
      ...rest
    } = this.props;

    return (
      <div className={classnames({ "col-md-4": stacked })}>
        <ul
          className={classnames("nav", {
            "nav-pills nav-stacked": stacked,
            "nav-tabs": !stacked,
            "stacked-left": stacked && stackedLeft(stackedPosition),
            "stacked-right": stacked && not(stackedLeft(stackedPosition)),
          })}
        >
          {tabs.map(tab => (
            <li
              key={`nav-item-${tab.name}`}
              data-testid={`tab-header-${makeSafeForCSS(tab.name)}`}
              className={classnames(tab.className, {
                active: !isNil(active) && tabs[active].name === tab.name,
              })}
            >
              {Component ? (
                <Component
                  tab={tab}
                  onClick={onClick}
                  active={!isNil(active) && tabs[active].name === tab.name}
                  {...rest}
                />
              ) : (
                <Tab tab={tab} onClick={onClick} />
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderContents(tabs, active, props, ContentComponent) {
    return tabs.map(tab => {
      const {
        name,
        component: DefaultContentComponent,
        componentProps = {},
      } = tab;

      return (
        <div
          key={`tab-content-${name}`}
          data-testid={`tab-content-${makeSafeForCSS(name)}`}
          className={classnames("content block", {
            hidden: !isNil(active) && tabs[active].name !== name,
          })}
        >
          {ContentComponent && (
            <ContentComponent
              tab={tab}
              {...props}
              stopUnmount={tab.stopUnmount ?? props.stopUnmount}
            />
          )}
          {!ContentComponent && DefaultContentComponent && (
            <DefaultContentComponent {...componentProps} />
          )}
          {!ContentComponent && !DefaultContentComponent && null}
        </div>
      );
    });
  }

  render() {
    const {
      stacked,
      stackedPosition,
      tabs,
      contentComponent,
      active,
      ...rest
    } = this.props;

    const filteredTabs = tabs.filter(tab => !this.isHidden(tab));

    return (
      <div className={classnames("tabs", { row: stacked })}>
        {or(!stacked, stacked && stackedLeft(stackedPosition)) &&
          this.renderTabs(filteredTabs)}
        <div className={classnames("tabs-contents", { "col-md-8": stacked })}>
          {this.renderContents(filteredTabs, active, rest, contentComponent)}
        </div>
        {stacked &&
          not(stackedLeft(stackedPosition)) &&
          this.renderTabs(filteredTabs)}
      </div>
    );
  }
}
