import { react as autoBind } from "auto-bind";
import { isEmpty } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { ReduxFormField } from "../../../forms/components";
import {
  CVL_BLACKLIST,
  CVL_KNOWLEDGEBASE,
  CVL_WHITELIST,
} from "../../../utils/enum/cvlFilterKeys";
import { CheckboxOptionArrayProp } from "../../../utils/prop-types/CheckboxOptionProp";
import * as selectors from "../../selectors";

export class CuratedVariantListsForm extends PureComponent {
  static propTypes = {
    [CVL_KNOWLEDGEBASE]: CheckboxOptionArrayProp.isRequired,
    [CVL_BLACKLIST]: CheckboxOptionArrayProp.isRequired,
    [CVL_WHITELIST]: CheckboxOptionArrayProp.isRequired,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const {
      disabled = false,
      curatedListKnowledgebase = [],
      curatedListBlacklist = [],
      curatedListWhitelist = [],
    } = this.props;
    return (
      <fieldset disabled={disabled} className="d-table">
        {isEmpty(curatedListKnowledgebase) ? (
          <p>There are no knowledgebase lists for this project</p>
        ) : (
          <Field
            component={ReduxFormField}
            type="checkboxgroup"
            label="Knowledgebase"
            name="curatedListKnowledgebase"
            options={curatedListKnowledgebase}
          />
        )}
        {isEmpty(curatedListBlacklist) ? (
          <p>There are no ExcludeLists for this project</p>
        ) : (
          <Field
            component={ReduxFormField}
            type="checkboxgroup"
            label="ExcludeList"
            name="curatedListBlacklist"
            options={curatedListBlacklist}
          />
        )}

        {isEmpty(curatedListWhitelist) ? (
          <p>There are no IncludeLists for this project</p>
        ) : (
          <Field
            component={ReduxFormField}
            type="checkboxgroup"
            label="IncludeList"
            name="curatedListWhitelist"
            options={curatedListWhitelist}
          />
        )}

        <div className="form-group">
          <div className="col-xs-12">
            <h4>Note</h4>
            <p>
              Curated list filters apply after the initial filter step and
              override all the other filters except genes and gene panels.
            </p>
            <p>
              Typically, Knowledgebase lists are commonly used variant sets such
              as Clinvar. Patient variants that are present within any enabled
              Knowledgebase lists are displayed in the variants table even if
              the consequence or frequency filters have otherwise filtered out
              these variants.
            </p>
            <p>
              Variants that are added to an ExcludeList are not displayed in the
              variant table regardless of the filters applied. This provides a
              mechanism for filtering out known off target variants in a gene of
              interest, or known sequencing artefacts from your validated
              pipeline.
            </p>
            <p>
              IncludeList curated lists contain users proprietary data and take
              precedence over ExcludeLists in filtering.
            </p>
            <p>
              When Gene panels are assigned to the patient, the Curated list
              filters will only be applied to the genes in the areas of interest
              defined by the gene panels. For example, an includelisted gene
              will not be shown if it is outside the areas of interest defined
              by the Gene panels.
            </p>
          </div>
        </div>
      </fieldset>
    );
  }
}

const mapStateToProps = state => selectors.getCuratedListsOptions(state);

export default connect(mapStateToProps, null)(CuratedVariantListsForm);
