import classNames from "classnames";
import React from "react";

const Loading = ({ className, ...otherProps }) => (
  <h1 className={classNames(className, "loading")} {...otherProps}>
    Loading...
  </h1>
);

export default Loading;
