import PropTypes from "prop-types";
import React from "react";

export const GenomicRegionFilterSidebar = ({ coords }) => (
  <div className="genomic-region-filter-sidebar">
    <span className="title">Genomic Region:</span>
    <strong>{coords}</strong>
  </div>
);

GenomicRegionFilterSidebar.propTypes = {
  coords: PropTypes.string.isRequired,
};
