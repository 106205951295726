import { useMemo } from "react";

import { usePrevious } from "../../../common/utils";

const getSettings = (pageIndex, pageSize, sortedBy, filter) => ({
  pageIndex,
  pageSize,
  sortedBy,
  filter,
});

export const useTableSettings = (pageIndex, pageSize, sortedBy, filter) => {
  const prevPageIndex = usePrevious(pageIndex);
  const prevPageSize = usePrevious(pageSize);
  const prevSortedBy = usePrevious(sortedBy);
  const prevFilter = usePrevious(filter);

  const prevSettings = useMemo(
    () => getSettings(prevPageIndex, prevPageSize, prevSortedBy, prevFilter),
    [prevPageIndex, prevPageSize, prevSortedBy, prevFilter]
  );

  const currentSettings = useMemo(
    () => getSettings(pageIndex, pageSize, sortedBy, filter),
    [pageIndex, pageSize, sortedBy, filter]
  );

  return [prevSettings, currentSettings];
};
