import { minAndMaxNumber } from "modules/forms/rules";

import { ViewMode } from "./types";

export const MODULE_NAME = "STRUCTURAL_VARIANTS";

export const INIT = "INIT_STRUCTURAL_VARIANTS";
export const ON_SV_FILTER_CHANGE = "ON_SV_FILTER_CHANGE";
export const ON_SV_COLUMNS_CHANGE = "ON_SV_COLUMNS_CHANGE";
export const SET_SV_FILTERS = "SET_SV_FILTERS";
export const SET_SV_FILTER_COLUMNS = "SET_SV_FILTER_COLUMNS";
export const SET_SV_FILTER_DATA = "SET_SV_FILTER_DATA";

export const FETCH_PATIENT_OVERLAPS_START = "FETCH_PATIENT_OVERLAPS_START";
export const FETCH_PATIENT_OVERLAPS_SUCCESS = "FETCH_PATIENT_OVERLAPS_SUCCESS";
export const FETCH_PATIENT_OVERLAPS_FAILURE = "FETCH_PATIENT_OVERLAPS_FAILURE";
export const SET_LOADING = "SET_LOADING";

export const SV_FILTERS_FORM = "SV_FILTERS_FORM";
export const SV_COLUMNS_FORM = "SV_COLUMNS_FORM";

export const SET_SV_VIEW_MODE = "SET_SV_VIEW_MODE";

export const VIEW_AS_VALUES: Record<ViewMode, ViewMode> = {
  list: "list",
  karyogram: "karyogram",
};

export const VIEW_AS: Record<ViewMode, string> = {
  list: "List",
  karyogram: "Karyogram",
};

export const FILTER_FIELDS: Array<{
  key: string;
  type?: string;
  hasGroups?: boolean;
  config?: {
    addon?: string;
    className?: string;
    placeholder?: string;
    normalize?: (value: string) => string;
  };
}> = [
  {
    key: "included",
  },
  {
    key: "keyword",
  },
  {
    key: "gnomadAf",
    type: "number",
    config: {
      addon: "≤",
      className: "input-group-fix",
      placeholder: "Enter frequency between 0 and 1...",
      normalize: (value: string) => minAndMaxNumber(0, 1)(value),
    },
  },
  {
    key: "genotype",
    type: "checkboxgroup",
  },
  {
    key: "quality",
  },
  {
    key: "bayesFactor",
    type: "number",
    config: {
      addon: "≥",
      // TODO: Check if we can generalise this fix onto all inputs
      className: "input-group-fix",
      placeholder: "Enter a positive number...",
      normalize: (value: string) => minAndMaxNumber(0, Number.MAX_VALUE)(value),
    },
  },
  {
    key: "size",
  },
  {
    key: "subtype",
  },
  {
    key: "cvl",
  },
  {
    key: "inheritance",
    type: "checkboxgroup",
  },
  {
    key: "tier",
  },
];

export const GENES_DISPLAYED = 3;

export const GNOMAD_SV_MAP = {
  afr: "African/African American",
  lat: "Latino",
  eas: "East Asian",
  eur: "European",
  oth: "Other",
  all: "Total",
};

/**
 * Mapping for a camelCased variant property to a display name
 */
export const SV_FIELD_DISPLAY_MAP = {
  subtype: "Type",
  coordinates: "Location",
  bands: "Band(s)",
  length: "Size",
  genotype: "Genotype",
  gnomadAf: "gnomAD Allelle Frequency",
  inheritance: "Inheritance",
  quality: "Quality",
  copyNumber: "Copy number",
  bayesFactor: "Bayes factor",
  filter: "Tags",
  translocationChr: "Translocation chromosome",
  translocationPos: "Translocation position",
  hiGeneName: "Haploinsufficient gene",
  hiIndex: "%HI",
  genesInPanels: "Genes in gene panels",
  genesOmim: "OMIM genes",
  genesMorbid: "Morbid genes",
  variantGene: "Total genes with consequences from variant",
  readsExpected: "Reads expected",
  readsObserved: "Reads observed",
  readsRatio: "Reads ratio",
};
