import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import * as yup from "yup";

import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "pattern-library";

import validator from "modules/forms/validator";

import { addMetadata, deleteMetadata, updateMetadata } from "../actions";
import {
  FIELD,
  ADD_METADATA_CATEGORY,
  ADD_METADATA_FIELD,
  CATEGORY,
  DELETE_METADATA_CATEGORY,
  DELETE_METADATA_FIELD,
  EDIT_METADATA_CATEGORY,
  EDIT_METADATA_FIELD,
  METADATA_CATEGORY_FORM,
  METADATA_FIELD_FORM,
} from "../constants";
import * as selectors from "../selectors";

import MetadataCategoryForm from "./MetadataCategoryForm";
import MetadataFieldForm from "./MetadataFieldForm";

export const fieldValidationSchema = yup.object({
  fieldName: yup.string("").trim().required("Name is a required field"),
  fieldCode: yup.string("").trim().required("Code is a required field"),
});

export const categoryValidationSchema = yup.object({
  name: yup.string("").trim().required("Name is a required field"),
});

export const MetadataModal = ({
  close,
  show,
  mode,
  handleSubmit,
  addMetadata,
  updateMetadata,
  deleteMetadata,
  reset,
}) => {
  const modalModeConfig = useMemo(
    () => ({
      ADD_METADATA_FIELD: {
        submitHandler: values => addMetadata(FIELD, values),
        heading: "Add patient metadata field",
        form: <MetadataFieldForm mode={ADD_METADATA_FIELD} />,
        closeButtonText: "Close",
        submitButtonText: "Save",
        dataTestId: "add-metadata-field-modal",
      },
      ADD_METADATA_CATEGORY: {
        submitHandler: values => addMetadata(CATEGORY, values),
        heading: "Add patient metadata category",
        form: <MetadataCategoryForm mode={ADD_METADATA_CATEGORY} />,
        closeButtonText: "Close",
        submitButtonText: "Save",
        dataTestId: "add-metadata-category-modal",
      },
      EDIT_METADATA_FIELD: {
        submitHandler: values => updateMetadata(FIELD, values),
        heading: "Edit patient metadata field",
        form: <MetadataFieldForm mode={EDIT_METADATA_FIELD} />,
        closeButtonText: "Close",
        submitButtonText: "Save",
        dataTestId: "edit-metadata-field-modal",
      },
      EDIT_METADATA_CATEGORY: {
        submitHandler: values => updateMetadata(CATEGORY, values),
        heading: "Edit patient metadata category",
        form: <MetadataCategoryForm mode={EDIT_METADATA_CATEGORY} />,
        closeButtonText: "Close",
        submitButtonText: "Save",
        dataTestId: "edit-metadata-category-modal",
      },
      DELETE_METADATA_FIELD: {
        submitHandler: values => deleteMetadata(FIELD, values),
        heading: "Are you sure you want to delete this project metadata field?",
        form: <MetadataFieldForm mode={DELETE_METADATA_FIELD} />,
        closeButtonText: "No",
        submitButtonText: "Yes",
        dataTestId: "delete-metadata-field-modal",
      },
      DELETE_METADATA_CATEGORY: {
        submitHandler: values => deleteMetadata(CATEGORY, values),
        heading:
          "Are you sure you want to delete this project metadata category?",
        form: <MetadataCategoryForm mode={DELETE_METADATA_CATEGORY} />,
        closeButtonText: "No",
        submitButtonText: "Yes",
        dataTestId: "delete-metadata-category-modal",
      },
    }),
    [addMetadata, updateMetadata, deleteMetadata]
  );

  const {
    submitHandler,
    heading,
    form,
    closeButtonText,
    submitButtonText,
    dataTestId,
  } = modalModeConfig[mode];

  const onClose = useCallback(() => {
    close();
    reset();
  }, [reset, close]);
  return (
    <Modal data-testid={dataTestId} close={onClose} show={show}>
      <ModalHeader close={onClose}>
        <Heading type="h3">{heading}</Heading>
      </ModalHeader>
      <ModalBody className="patient_metadata-form-body">
        <form onSubmit={handleSubmit(submitHandler)}>
          <>
            {form}
            <ModalFooter className="patient_metadata-form-footer">
              <Button data-testid="close" onClick={onClose}>
                {closeButtonText}
              </Button>
              <Button data-testid="save" type="submit" context="primary">
                {submitButtonText}
              </Button>
            </ModalFooter>
          </>
        </form>
      </ModalBody>
    </Modal>
  );
};

MetadataModal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.bool,
  mode: PropTypes.oneOf([
    ADD_METADATA_FIELD,
    EDIT_METADATA_FIELD,
    DELETE_METADATA_FIELD,
    ADD_METADATA_CATEGORY,
    EDIT_METADATA_CATEGORY,
    DELETE_METADATA_CATEGORY,
  ]),
};

const ConnectedFieldForm = reduxForm({
  form: METADATA_FIELD_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
  shouldAsyncValidate(params) {
    return params.trigger === "change" || params.trigger === "submit";
  },
  asyncValidate: values => validator(fieldValidationSchema)(values),
})(MetadataModal);

const ConnectedCategoryForm = reduxForm({
  form: METADATA_CATEGORY_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
  shouldAsyncValidate(params) {
    return params.trigger === "change" || params.trigger === "submit";
  },
  asyncValidate: values => validator(categoryValidationSchema)(values),
})(MetadataModal);

const mapStateToProps = state => ({
  initialValues: selectors.getSelectedMetadata(state),
  mode: selectors.getSelectedMetadataMode(state),
});

const mapDispatchToProps = {
  addMetadata,
  updateMetadata,
  deleteMetadata,
};

export const MetadataFieldModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedFieldForm);

export const MetadataCategoryModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedCategoryForm);
