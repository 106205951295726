import classNames from "classnames";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Button, LimitedInput, Textarea } from "pattern-library";

import { RoleAccessibleComponent } from "modules/utils/security/RoleAccessibleComponent";

import catalystApi from "../../api/catalyst-api";
import congenicaApi from "../../api/congenica-api";

import { Context } from "./Container";
import { FAILED, REVIEW_COMPLETE } from "./constants";

const { useGetPatientStatusQuery } = catalystApi;

const { useGetLatestReportProposalQuery, useSetReportProposalMutation } =
  congenicaApi;

const REPORT_SUMMARY_LIMIT = 1500;
interface Props {
  className?: string;
  templateUuid: string | null;
  selectedJurisdiction: string | null;
  projectId: string | number;
}

const ReportProposalEditor: FC<Props> = ({
  className,
  templateUuid,
  projectId,
  selectedJurisdiction,
}) => {
  const { patientId } = useContext(Context);

  const {
    reportSummary,
    reportServiceTemplateUuid,
    jurisdiction,
    isLoading: isGetLatestReportProposaLoading,
  } = useGetLatestReportProposalQuery(
    { patientId },
    {
      selectFromResult: ({
        data: {
          reportSummary = "",
          reportServiceTemplateUuid,
          jurisdiction,
        } = {},
        isLoading,
      }) => ({
        reportSummary,
        reportServiceTemplateUuid,
        jurisdiction,
        isLoading,
      }),
    }
  );

  const { status, isLoading: isGetPatientStatusLoading } =
    useGetPatientStatusQuery(
      { patientId },
      {
        selectFromResult: ({ data: { status } = {}, isLoading }) => ({
          status,
          isLoading,
        }),
      }
    );

  const [setReportProposal, { isLoading: isSetReportProposalLoading }] =
    useSetReportProposalMutation();

  const isLoading = useMemo(
    () =>
      isSetReportProposalLoading ||
      isGetPatientStatusLoading ||
      isGetLatestReportProposaLoading,
    [
      isSetReportProposalLoading,
      isGetPatientStatusLoading,
      isGetLatestReportProposaLoading,
    ]
  );

  const [uiReportSummary, setUIReportSummary] = useState<string>("");

  useEffect(() => {
    setUIReportSummary(reportSummary);
  }, [reportSummary]);

  const onSetReportProposalHandler = useCallback(() => {
    if (templateUuid && selectedJurisdiction) {
      setReportProposal({
        patientId,
        reportServiceTemplateUuid: templateUuid,
        reportSummary: uiReportSummary,
        jurisdiction: selectedJurisdiction,
      });
    }
  }, [
    selectedJurisdiction,
    patientId,
    setReportProposal,
    templateUuid,
    uiReportSummary,
  ]);

  const readOnly = status === REVIEW_COMPLETE || status === FAILED;
  return (
    <form className={classNames(className)}>
      <div className="form-group">
        <label htmlFor="comment">Report Summary</label>
        <RoleAccessibleComponent
          disabledOnNoRole
          permittedRoles={["createReportSummary"]}
          projectId={projectId}
        >
          <LimitedInput
            disabled={readOnly}
            className="oncology-report__comment"
            data-testid="oncology-report-comment"
            placeholder="Enter your comments"
            value={uiReportSummary}
            component={Textarea}
            limit={REPORT_SUMMARY_LIMIT}
            handleChange={setUIReportSummary}
          />
        </RoleAccessibleComponent>
      </div>
      {!readOnly && (
        <RoleAccessibleComponent
          projectId={projectId}
          permittedRoles={["createReportSummary"]}
        >
          <Button
            className="btn btn-primary pull-right"
            type="button"
            data-testid="oncology-report-comment-submit-btn"
            onClick={onSetReportProposalHandler}
            disabled={
              isLoading ||
              !templateUuid ||
              !selectedJurisdiction ||
              (reportSummary === uiReportSummary &&
                reportServiceTemplateUuid === templateUuid &&
                selectedJurisdiction === jurisdiction)
            }
          >
            Save
          </Button>
        </RoleAccessibleComponent>
      )}
    </form>
  );
};

export default ReportProposalEditor;
