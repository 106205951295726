import type { Action } from "./actions";
import { actionType } from "./actions";
import { State } from "./types";

export const initialState: State = {
  loading: false,
  data: [],
};

export default function reducer(
  state: State = initialState,
  { type, payload }: Action
): State {
  switch (type) {
    case actionType.FETCH_VERSIONS_START:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case actionType.FETCH_VERSIONS_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
      };
    case actionType.FETCH_VERSIONS_SUCCESS:
      const versions = payload as Array<Version>;

      return {
        ...state,
        data: versions,
        loading: false,
      };

    default:
      return state;
  }
}
