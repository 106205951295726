import { Track } from "../core/Coverage";

export const GERP = () =>
  Track.extend({
    id: "GERP",
    name: "GERP",
    namespace: "Congenica.GERP",
    height: 50,
    scoreMultiplier: 50,
    url: "/API/genoverse/GERP.json?chr=__CHR__&start=__START__&end=__END__",
    info: "Visualisation of nucleotide conservation score at each base",
  });
