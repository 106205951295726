import { react as autoBind } from "auto-bind";
import { camelizeKeys, decamelizeKeys } from "humps";
import PropTypes from "prop-types";
import { identity, memoizeWith, is } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Loading } from "pattern-library";

import { isCurrentUserAdmin } from "modules/auth/selectors";
import { headers, processErrors } from "modules/utils";

import { error, success } from "../../messages/actions";

export class ProjectSettingsInitialisationWrapper extends PureComponent {
  static propTypes = {
    /**
     * Type of project settings parameters (Admin Configuration, Project Defaults and etc.)
     */
    entityType: PropTypes.string,
    /**
     * id of a selected project
     */
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * This is a part of url path of wrapped setting page
     */
    path: PropTypes.string,
    /**
     * a callback to call on error
     */
    error: PropTypes.func,
    /**
     * a callback to call on success
     */
    success: PropTypes.func,

    /**
     * convert response json to a values
     */
    toValues: PropTypes.func,

    /**
     * convert values json to a request body
     */
    fromValues: PropTypes.func,

    /**
     * url creator
     */
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  };

  static defaultProps = {
    toValues: json => {
      const { isInherited, value, id, type } = camelizeKeys(
        json.data.attributes
      );

      // Customer settings can be strings instead of a JSON object
      // We need to handle this slightly differently otherwise
      // the value will be corrupted by camelizeKeys
      const decodedValue = is(Object)(value) ? value : { value };

      return {
        isInherited,
        id,
        type,
        ...decodedValue,
      };
    },
    fromValues: (projectId, entityType, values) => {
      const { isInherited, value, ...rest } = values;

      // We need to handle string `value`s here as well -
      // customer reference is stored as a string on the `value` property
      const encodedValue = value !== undefined ? value : decamelizeKeys(rest);
      const submitValue = isInherited ? null : encodedValue;

      return {
        data: {
          id: projectId,
          type: entityType,
          attributes: {
            value: submitValue,
            is_inherited: isInherited,
          },
        },
      };
    },
    url: (projectId, path) =>
      `/webapi/entities/projects/${projectId}/settings/${path}`,
  };

  static entityTypeToLabel(entityType) {
    switch (entityType) {
      case "default_settings":
        return "Project Defaults";
      case "project_admin_config":
        return "Admin Configuration";
      case "projects":
        return "Project Details";
      case "customer_settings":
        return "Customer";
      default:
        return "Project";
    }
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      loaded: false,
      error: false,
    };
  }

  async componentDidMount() {
    const { path, projectId, error, toValues, url } = this.props;
    const response = await fetch(url(projectId, path));

    if (response.ok) {
      const json = await response.json();
      this.setState({
        data: toValues(json),
        loaded: true,
        error: false,
      });
    } else {
      this.setState({
        error: true,
      });
      error("Unable to load settings");
    }
  }

  async handleSubmit(values, { resetForm, setStatus }) {
    const {
      path,
      projectId,
      entityType,
      success,
      error,
      toValues,
      fromValues,
      url,
      onSuccessUpdate,
    } = this.props;

    const body = fromValues(projectId, entityType, values);

    setStatus({ submitError: false });

    const response = await fetch(url(projectId, path), {
      method: "PATCH",
      headers,
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const json = await response.json();
      const values = toValues(json);
      this.setState(
        {
          error: false,
          loaded: true,
        },
        () => {
          setStatus({ submitError: false });
          return resetForm({ values });
        }
      );

      if (onSuccessUpdate) {
        onSuccessUpdate(values);
      }

      success(
        `${ProjectSettingsInitialisationWrapper.entityTypeToLabel(
          entityType
        )} saved successfully`
      );
    } else {
      const { errors } = await response.json();
      setStatus({ submitError: true });
      error(
        `Error saving ${ProjectSettingsInitialisationWrapper.entityTypeToLabel(
          entityType
        )}${processErrors(errors)}`
      );
    }
  }

  render() {
    const { component: Component, entityType, ...props } = this.props;
    const { error, data, loaded } = this.state;
    if (error === true)
      return (
        <p>{`Unable to load the ${memoizeWith(
          identity,
          ProjectSettingsInitialisationWrapper.entityTypeToLabel
        )(entityType)} Settings`}</p>
      );

    if (loaded === false) return <Loading />;

    return (
      <Component
        initialValues={data}
        handleSubmit={this.handleSubmit}
        {...props}
      />
    );
  }
}

const mapStateToProps = state => ({
  isCurrentUserSuperAdmin: isCurrentUserAdmin(state),
});

const mapDispatchToProps = {
  success,
  error,
};

const ConnectedInitialisationWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSettingsInitialisationWrapper);

export default ConnectedInitialisationWrapper;
