import Genoverse from "genoverse";
import { isNil } from "ramda";

import { aminoAcids, Colors } from "../utils";

export const View = Genoverse.Track.View.extend({
  featureHeight: 13,
  labels: true,
  repeatLabels: true,
  bump: true,
  lineWidth: 0.5,
  aminoInfo: aminoAcids,
  speciesMap: {
    hg19: { name: "Human" },
    hg38: { name: "Human" },
    panTro4: { name: "Chimp" },
    gorGor3: { name: "Gorilla" },
    ponAbe2: { name: "Orangutan" },
    nomLeu3: { name: "Gibbon" },
    rheMac3: { name: "Rhesus macaque" },
    macFas5: { name: "C. e. macaque", fullName: "Crab eating macaque" },
    papHam1: { name: "Baboon" },
    papAnu2: { name: "Baboon" },
    chlSab1: { name: "Green monkey" },
    chlSab2: { name: "Green monkey" },
    calJac3: { name: "Marmoset" },
    saiBol1: { name: "Squirrel monkey" },
    otoGar3: { name: "Bushbaby" },
    tupChi1: { name: "C. tree shrew", fullName: "Chinese tree shrew" },
    speTri2: { name: "Squirrel" },
    jacJac1: { name: "L. E. jerboa", fullName: "Lesser Egyptian jerboa" },
    micOch1: { name: "Prairie vole" },
    criGri1: { name: "Chinese hamster" },
    mesAur1: { name: "Golden hamster" },
    mm10: { name: "Mouse" },
    rn5: { name: "Rat" },
    rn6: { name: "Rat" },
    hetGla2: { name: "Naked mole rat" },
    cavPor3: { name: "Guinea pig" },
    chiLan1: { name: "Chinchilla" },
    octDeg1: { name: "Brush tailed rat" },
    oryCun2: { name: "Rabbit" },
    ochPri3: { name: "Pika" },
    susScr3: { name: "Pig" },
    vicPac2: { name: "Alpaca" },
    camFer1: { name: "Bactrian camel" },
    turTru2: { name: "Dolphin" },
    orcOrc1: { name: "Killer whale" },
    panHod1: { name: "Tibetan antelope" },
    bosTau7: { name: "Cow" },
    bosTau8: { name: "Cow" },
    oviAri3: { name: "Sheep" },
    capHir1: { name: "Domestic goat" },
    equCab2: { name: "Horse" },
    cerSim1: { name: "White rhinoceros" },
    felCat5: { name: "Cat" },
    felCat8: { name: "Cat" },
    canFam3: { name: "Dog" },
    musFur1: { name: "Ferret" },
    ailMel1: { name: "Panda" },
    odoRosDiv1: { name: "Pacific walrus" },
    lepWed1: { name: "Weddell seal" },
    pteAle1: { name: "Black flying fox" },
    pteVam1: { name: "Megabat" },
    myoDav1: { name: "David's myotis" },
    myoLuc2: { name: "Microbat" },
    eptFus1: { name: "Big brown bat" },
    eriEur2: { name: "Hedgehog" },
    sorAra2: { name: "Shrew" },
    conCri1: { name: "Star-nosed mole" },
    loxAfr3: { name: "Elephant" },
    eleEdw1: { name: "C. e. shrew", fullName: "Cape elephant shrew" },
    triMan1: { name: "Manatee" },
    chrAsi1: { name: "C. golden mole", fullName: "Cape golden mole" },
    echTel2: { name: "Tenrec" },
    oryAfe1: { name: "Aardvark" },
    dasNov3: { name: "Armadillo" },
    monDom5: { name: "Opossum" },
    sarHar1: { name: "Tasmanian devil" },
    macEug2: { name: "Wallaby" },
    ornAna1: { name: "Platypus" },
    falChe1: { name: "Saker falcon" },
    falPer1: { name: "Peregrine falcon" },
    ficAlb2: { name: "C. flycatcher", fullName: "Collared flycatcher" },
    zonAlb1: { name: "W. t. sparrow", fullName: "White throated sparrow" },
    geoFor1: { name: "M. g. finch", fullName: "Medium ground finch" },
    taeGut2: { name: "Zebra finch" },
    pseHum1: { name: "T. ground jay", fullName: "Tibetan ground jay" },
    melUnd1: { name: "Budgerigar" },
    amaVit1: { name: "P. Rican parrot", fullName: "Puerto Rican parrot" },
    araMac1: { name: "Scarlet macaw" },
    colLiv1: { name: "Rock pigeon" },
    anaPla1: { name: "Mallard duck" },
    galGal4: { name: "Chicken" },
    melGal1: { name: "Turkey" },
    allMis1: { name: "A. alligator", fullName: "American alligator" },
    cheMyd1: { name: "Green seaturtle" },
    chrPic1: { name: "Painted turtle" },
    chrPic2: { name: "Painted turtle" },
    pelSin1: { name: "C. s. turtle", fullName: "Chinese softshell turtle" },
    apaSpi1: { name: "S. s. turtle", fullName: "Spiny softshell turtle" },
    anoCar2: { name: "Lizard" },
    xenTro7: { name: "Frog X. tropicalis" },
    latCha1: { name: "Coelacanth" },
    tetNig2: { name: "Tetraodon" },
    fr3: { name: "Fugu" },
    takFla1: { name: "Y. pufferfish", fullName: "Yellowbelly pufferfish" },
    oreNil2: { name: "Nile tilapia" },
    neoBri1: { name: "P. of Burundi", fullName: "Princess of Burundi" },
    hapBur1: { name: "B. mouthbreeder", fullName: "Burton's mouthbreeder" },
    mayZeb1: { name: "Zebra mbuna" },
    punNye1: { name: "P. nyererei", fullName: "Pundamilia nyererei" },
    oryLat2: { name: "Medaka" },
    xipMac1: { name: "S. platyfish", fullName: "Southern platyfish" },
    gasAcu1: { name: "Stickleback" },
    gadMor1: { name: "Atlantic cod" },
    danRer7: { name: "Zebrafish" },
    danRer10: { name: "Zebrafish" },
    astMex1: { name: "Mexican tetra" },
    lepOcu1: { name: "Spotted gar" },
    petMar2: { name: "Lamprey" },
  },

  aminoLabelColors: {
    default: Colors.BLACK,
    H: Colors.WHITE,
    K: Colors.WHITE,
    R: Colors.WHITE,
    D: Colors.WHITE,
    E: Colors.WHITE,
    F: Colors.WHITE,
    W: Colors.WHITE,
    Y: Colors.WHITE,
    Z: Colors.WHITE,
    X: Colors.WHITE,
    "*": Colors.WHITE,
  },

  constructor(...args) {
    this.base(...args);
    this.labelWidth = {};
    this.widestLabel = "W";
  },

  measureText(c) {
    if (!this.labelWidth[c]) {
      this.labelWidth[c] = Math.ceil(this.context.measureText(c).width) + 1;
    }

    return this.labelWidth[c];
  },
  // get alignment but stranded and filled out, (also it's cached)
  getStrandedAlignment(feature, species) {
    if (isNil(feature.orthologs)) {
      return "-";
    }
    if (isNil(feature.strandedAlignmentCache)) {
      feature.strandedAlignmentCache = {};
    }
    if (isNil(feature.strandedAlignmentCache[species])) {
      const alignmentUnstranded = feature.orthologs[species];
      let alignment =
        feature.strand === "-"
          ? alignmentUnstranded.split("").reverse().join("")
          : alignmentUnstranded;

      // deal with empty alignments
      if (alignment.length === 0) {
        alignment = Array(
          feature.orthologs[this.track.humanReference].length + 1
        ).join("-");
      }
      feature.strandedAlignmentCache[species] = alignment;
    }
    return feature.strandedAlignmentCache[species];
  },
  // Get the final alignments to display, including stragglers
  getAlignments(feature) {
    const speciesToShow =
      this.track.groups[this.track.config.species] ||
      this.track.groups.ModelOrganisms;

    // Now we know what species to show, prepare an array with just those in it, reversed for strand if necessary
    const preparedAlignments = {};
    speciesToShow.forEach(species => {
      let alignment = this.getStrandedAlignment(feature, species);

      // get the left straggler (the closest amino acid from the exon to the left)
      if (
        feature.strand === "-" &&
        feature.end_frame === 1 &&
        typeof this.track.model.featuresById[feature.next_id] !== "undefined"
      ) {
        const neighbourFeature = this.track.model.featuresById[feature.next_id];
        const neighbourAlignment = this.getStrandedAlignment(
          neighbourFeature,
          species
        );
        alignment =
          neighbourAlignment.charAt(neighbourAlignment.length - 1) + alignment;
        feature.is_straggler_first = true;
      } else if (
        feature.strand === "+" &&
        feature.start_frame === 2 &&
        typeof this.track.model.featuresById[feature.prev_id] !== "undefined"
      ) {
        const neighbourFeature = this.track.model.featuresById[feature.prev_id];
        const neighbourAlignment = this.getStrandedAlignment(
          neighbourFeature,
          species
        );
        alignment =
          neighbourAlignment.charAt(neighbourAlignment.length - 1) + alignment;
        feature.is_straggler_first = true;
      }

      // get the right straggler (the closest amino acid from the exon to the right)
      if (
        feature.strand === "-" &&
        feature.start_frame === 2 &&
        typeof this.track.model.featuresById[feature.prev_id] !== "undefined"
      ) {
        const neighbourFeature = this.track.model.featuresById[feature.prev_id];
        const neighbourAlignment = this.getStrandedAlignment(
          neighbourFeature,
          species
        );
        alignment = alignment + neighbourAlignment.charAt(0);
        feature.is_straggler_last = true;
      } else if (
        feature.strand === "+" &&
        feature.end_frame === 1 &&
        typeof this.track.model.featuresById[feature.next_id] !== "undefined"
      ) {
        const neighbourFeature = this.track.model.featuresById[feature.next_id];
        const neighbourAlignment = this.getStrandedAlignment(
          neighbourFeature,
          species
        );
        alignment = alignment + neighbourAlignment.charAt(0);
        feature.is_straggler_last = true;
      }

      preparedAlignments[species] = alignment;
    }, this);

    return preparedAlignments;
  },

  drawFeature(feature, featureContext, labelContext, scale) {
    // if no orthologs are set this means the mapping is bad, just draw a block
    if (isNil(feature.orthologs)) {
      this.color = Colors.GREY;
      this.base(feature, featureContext, labelContext, scale);
      return;
    }

    const width = scale * 3;
    const label = document.querySelector(`[id="${this.track.id}-label"]`);

    let top = 0;

    //make sure the orthologs text is hidden, there's no room for it
    label.querySelector(".gv-name").style.display = "none";

    const preparedAlignments = this.getAlignments(feature);
    const speciesToShow = Object.keys(preparedAlignments);

    const container = label.querySelector("#species_container");

    // If the number of species is different to the number of labels then either this is the first feature we've drawn
    // or the number of species has changed, requiring us to redraw all the labels, so we should clear them out
    if (
      container.querySelectorAll("[data-species]").length !==
      speciesToShow.length
    ) {
      container
        .querySelectorAll("[data-species]")
        .forEach(element => element.remove());
    }

    speciesToShow.forEach(species => {
      const alignment = preparedAlignments[species];

      if (!container.querySelectorAll(`[data-species='${species}']`).length) {
        //we don't have a label for this track we're about to draw,
        //so create it and add a data attribute so we can refer to it later
        const speciesLabel = document.createElement("div");
        speciesLabel.setAttribute("data-species", species);
        const { fullName, name } = this.speciesMap[species];
        const displayedName = fullName || name;
        const tooltip = displayedName
          ? `${displayedName} (${species})`
          : species;
        speciesLabel.setAttribute("title", tooltip);
        speciesLabel.textContent = name || species;
        speciesLabel.style.position = "absolute";
        speciesLabel.style.fontSize = "10px";
        speciesLabel.style.top = `${top - 2}px`;
        speciesLabel.style.right = "2px";
        speciesLabel.style.textShadow = "0 1px 0 rgba(0, 0, 0, 0.75)";
        speciesLabel.style.fontWeight = "bold";
        container.append(speciesLabel);
      }

      //make sure we have a width for this
      const drawLabels = this.measureText(this.widestLabel) < scale * 3 - 1;

      // calculate the offset based ont he strand and the start/end frame
      let start = 0;
      if (feature.strand === "+") {
        start = feature.x - scale * feature.start_frame;
      } else if (feature.strand === "-") {
        // on the negative strand, align the amino acids to the end of the feature
        const featureLength = feature.position[scale].width;
        const codedLength = alignment.length * scale * 3;

        start = feature.x + (featureLength - codedLength);

        start += feature.start_frame * scale;
      }

      alignment.split("").forEach((_, j) => {
        // don't bother drawing amino acids outside of the current view
        if (start < 0 - width || start + width > this.width + width) {
          start += width;
          return;
        }

        const base = alignment.charAt(j);

        const fraction = this.getAminoPositionFraction(
          feature,
          preparedAlignments,
          base,
          j,
          this.track.humanReference
        );

        //monochrome by default
        let boxColor = this.shadeColour(Colors.BLUE, fraction);
        let textColor = Colors.BLACK;
        if (this.track.config.color === "amino") {
          boxColor = this.aminoInfo[base].color;
          textColor =
            this.aminoLabelColors[base] || this.aminoLabelColors.default;
        }

        // if the amino acids are big enough, display the full label, otherwise show the base
        const text = scale > 10 ? this.aminoInfo[base].code : base;

        const draw_params = {
          draw_labels: drawLabels,
          context: featureContext,
          box_colour: boxColor,
          text_colour: textColor,
          base: text,
          x: start,
          y: top,
          width,
          height: this.featureHeight,
        };

        // draw the straggler with a taper
        if (j === 0 && feature.is_straggler_first) {
          draw_params.taper = "left";
        }
        if (j === alignment.length - 1 && feature.is_straggler_last) {
          draw_params.taper = "right";
        }

        this.drawBase(draw_params);

        //update the start variable for the next base
        start += width;
      });

      top += 15;
    }, this);
  },

  //render an amino acid given a position and colour
  drawBase(args) {
    if (!args) return;

    args.context.fillStyle = args.box_colour;

    if (args.taper) {
      /*
        We're tryign to draw a shape like this:
          <__]
        or
          [__>
        */
      const taper_width = args.width > 26 ? 12 : args.width / 3;

      args.context.beginPath();

      // start at the top left where the left taper would join the top line
      args.context.moveTo(args.x + taper_width, args.y);
      args.context.lineTo(args.x + args.width - taper_width, args.y); //move to the same point on the right side
      if (args.taper === "right") {
        //draw the taper, eg >
        args.context.lineTo(args.x + args.width, args.y + args.height / 2); // move to the point of the taper
      } else {
        // no taper, draw the flat edge, eg ]
        args.context.lineTo(args.x + args.width, args.y);
        args.context.lineTo(args.x + args.width, args.y + args.height);
      }
      args.context.lineTo(
        args.x + args.width - taper_width,
        args.y + args.height
      );
      args.context.lineTo(args.x + taper_width, args.y + args.height);

      if (args.taper === "left") {
        args.context.lineTo(args.x, args.y + args.height / 2); // move to the point of the taper
      } else {
        args.context.lineTo(args.x, args.y + args.height);
        args.context.lineTo(args.x, args.y);
      }
      args.context.lineTo(args.x + taper_width, args.y);

      args.context.closePath();
      args.context.fill();
    } else {
      args.context.fillRect(args.x, args.y, args.width, args.height);
    }

    if (args.draw_labels) {
      args.context.fillStyle = args.text_colour;
      args.context.fillText(
        args.base,
        args.x + (args.width - this.measureText(args.base)) / 2,
        args.y + 2
      );
    }
  },

  // Calculate the frequency of this aa at this position matching the human protein at this position, also it's cached!
  getAminoPositionFraction(feature, alignments, aa, position, genome) {
    // Set up the cache if it doesn't exist
    if (isNil(feature.orthologFractionCache)) {
      feature.orthologFractionCache = {};
    }

    // Set up the cache at this position if it doesn't exist
    if (isNil(feature.orthologFractionCache[position])) {
      feature.orthologFractionCache[position] = {};
    }

    if (isNil(feature.orthologFractionCache[position][aa])) {
      if (aa !== alignments[genome].charAt(position)) {
        feature.orthologFractionCache[position][aa] = 1;
      } else {
        const species = Object.keys(alignments);

        const speciesMatchingHuman = species
          .map(orth => alignments[orth].charAt(position)) // get all the characters at the right position
          .filter(pro => pro === alignments[genome].charAt(position)); // filter out those that don't match human

        feature.orthologFractionCache[position][aa] =
          1 - speciesMatchingHuman.length / species.length;
      }
    }

    return feature.orthologFractionCache[position][aa];
  },

  //method to lighten a colour by an amount, taken from:
  //http://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  shadeColour(colour, percent) {
    /* eslint-disable no-bitwise */
    const f = parseInt(colour.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      "#" +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
    /* eslint-enable no-bitwise */
  },

  // This deals with the fact that the feature can be a weird height, this sets it up properly so that autoheight works
  positionFeature(feature, params) {
    this.base(feature, params);

    if (isNil(feature.orthologs)) {
      return;
    }

    const alignments = this.getAlignments(feature);

    feature.position[params.scale].bottom =
      12 + 15 * Object.keys(alignments).length;
    params.featureHeight = Math.max(
      params.featureHeight,
      feature.position[params.scale].bottom
    );
  },
});

export const Model = Genoverse.Track.Model.extend({
  parseData(data, start, end) {
    // set the next and previous id for all features
    // features come out of the api in strand order
    for (let i = 0; i < data.length; i++) {
      data[i].prev_id = data[i - 1] ? data[i - 1].id : null;
      data[i].next_id = data[i + 1] ? data[i + 1].id : null;
    }
    this.base(data, start, end);
  },
});

export const populateMenu = function (feature) {
  const menu = {
    "Exon ID": feature.id,
    Location: `${feature.start}-${feature.end}`,
    "Start/End Frame": `${feature.start_frame}/${feature.end_frame}`,
    Strand: feature.strand,
  };

  if (isNil(feature.orthologs)) {
    menu[""] = "This exon could not be mapped";
  }

  return menu;
};

export const Controller = Genoverse.Track.Controller.extend({
  init() {
    this.base();

    //set an id on our label so we can manipulate it later
    this.label.attr("id", `${this.track.id}-label`);

    this.addSpeciesContainer();
  },

  addSpeciesContainer() {
    const label = document.querySelector(`[id="${this.track.id}-label"]`);
    let speciesContainer = label.querySelector("#species_container");
    if (speciesContainer) speciesContainer = null;
    else {
      speciesContainer = document.createElement("div");
      speciesContainer.setAttribute("id", "species_container");
      label.append(speciesContainer);
    }
  },
  populateMenu,
});

export const Track = Genoverse.Track.extend({
  id: "Congenica.Orthologs",
  namespace: "Congenica.Orthologs",
  name: "Orthologs",
  info: "Evolutionary conservation of each amino acid in different species. The more highly conserved a residue the more likely it is to have an important functional role in the protein",
  category: "Orthologs",
  tags: ["Orthologs", "Homology"],
  resizable: true,
  autoHeight: true,
  minHeight: 34,
  model: Model,
  view: View,
  controller: Controller,
});
