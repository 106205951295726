import { react as autoBind } from "auto-bind";
import classnames from "classnames";
import PropTypes from "prop-types";
import { equals, or, not } from "ramda";
import React, { PureComponent } from "react";

import { makeSafeForCSS } from "common/utils";

import DefaultTabHeader from "./DefaultTabHeader";

const stackedLeft = equals("left");

export default class ComposableTabs extends PureComponent {
  static displayName = "Tabs";

  static propTypes = {
    /**
     * Custom container classname
     */
    className: PropTypes.string,
    /**
     * tabs content
     */
    children: PropTypes.arrayOf(PropTypes.element),
    /**
     * A custom component to render in each tab header
     */
    tabHeaderComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    /**
     * Should the tabs be at the top or left/right from the content
     */
    stacked: PropTypes.bool,
    /**
     * Position of tabs is side mode
     */
    stackedPosition: PropTypes.oneOf(["left", "right"]),
    /**
     * If navStyle is true selected tab is highlighted in blue
     */
    navStyle: PropTypes.bool,
    /**
     * If defaultActive is set to tab name value it will show that tab active by default
     */
    defaultActive: PropTypes.string,
  };

  static defaultProps = {
    stacked: false,
    stackedPosition: "left",
    navStyle: true,
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      currentTabName: this.getDefaultCurrentTab(props),
    };
  }

  getDefaultCurrentTab(props) {
    const { children, defaultActive } = props;
    if (defaultActive) {
      return defaultActive;
    }
    if (children && Array.isArray(children)) {
      const [
        {
          props: { name },
        },
      ] = children;
      return name;
    }

    return null;
  }

  setCurrentTab(name) {
    this.setState({
      currentTabName: name,
    });
  }

  isTabActive(name) {
    const { currentTabName } = this.state;
    return currentTabName === name;
  }

  renderTabs(filteredChildren) {
    const {
      tabHeaderComponent: Component,
      stacked,
      stackedPosition,
      navStyle,
    } = this.props;
    const onClick = this.setCurrentTab;
    const HeaderComponent = Component || DefaultTabHeader;

    return (
      <div className={classnames({ "col-xs-4": stacked })}>
        <ul
          className={classnames("nav", {
            "nav-pills nav-stacked": stacked,
            "nav-tabs": !stacked,
            "stacked-left": stacked && stackedLeft(stackedPosition),
            "stacked-right": stacked && not(stackedLeft(stackedPosition)),
          })}
        >
          {filteredChildren.map(({ props }) => {
            const {
              name,
              className,
              hasValue = false,
              label,
              count,
              icon,
              hasError,
            } = props;
            const active = this.isTabActive(name);
            return (
              <li
                key={`nav-item-${name}`}
                data-testid={`tab-header-${makeSafeForCSS(name)}`}
                className={classnames(
                  className,
                  {
                    active: active && !navStyle,
                  },
                  {
                    error: hasError,
                  }
                )}
              >
                <HeaderComponent
                  strong={hasValue}
                  name={name}
                  onClick={onClick}
                  label={label}
                  count={count}
                  icon={icon}
                  navLinkClassName={classnames({
                    "bg-info": hasValue,
                    active: active && navStyle,
                  })}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  renderContents(filteredChildren) {
    return filteredChildren.map(({ props: { name, children: tabContent } }) => {
      const active = this.isTabActive(name);
      return (
        <div
          key={`tab-content-${name}`}
          data-testid={`tab-content-${makeSafeForCSS(name)}`}
          className={classnames("content block", {
            hidden: !active,
          })}
        >
          {tabContent}
        </div>
      );
    });
  }

  render() {
    const { stacked, stackedPosition, children, className } = this.props;

    if (!children || !Array.isArray(children)) {
      return null;
    }

    const filteredTabs = children.filter(tab => !tab.props.isHidden);

    return (
      <div className={classnames("tabs", className, { row: stacked })}>
        {or(!stacked, stacked && stackedLeft(stackedPosition)) &&
          this.renderTabs(filteredTabs)}
        <div className={classnames("tabs-contents", { "col-xs-8": stacked })}>
          {this.renderContents(filteredTabs)}
        </div>
        {stacked &&
          not(stackedLeft(stackedPosition)) &&
          this.renderTabs(filteredTabs)}
      </div>
    );
  }
}
