import Tippy from "@tippy.js/react";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

export const TooltipProps = {
  /**
   * Text inside tooltip component
   */
  arrow: PropTypes.bool,
  /**
   * The time the transition animation takes
   */
  duration: PropTypes.number,
  /**
   * The position of tooltip to render
   */
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * Text inside tooltip component
   */
  content: PropTypes.node.isRequired,
  /**
   * Event that triggers the tooltip
   */
  trigger: PropTypes.string,
  /**
   * If you are wrapping a disabled element you cannot attach a tooltip to it.
   * This option adds a container to the element so the tooltip is still visible
   * without needing to wrap the element yourself.
   *
   * Common options will be "div" or "span"
   */
  container: PropTypes.string,
  /**
   * The boundary that Popper.js' preventOverflow modifier adheres to.
   */
  boundary: PropTypes.oneOf(["scrollParent", "window", "viewport"]),

  /**
   * Determines if the tooltip has interactive content inside of it,
   * so that it can be hovered over and clicked inside without hiding.
   */
  interactive: PropTypes.bool,
};

export default class Tooltip extends PureComponent {
  static displayName = "Tooltip";

  static propTypes = {
    ...TooltipProps,
    /**
     * The element to render
     */
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    trigger: "mouseenter",
    arrow: true,
    duration: 0,
    container: undefined,
    boundary: undefined,
    placement: "top",
    interactive: false,
  };

  render() {
    const {
      content,
      placement,
      trigger,
      arrow,
      duration,
      maxWidth,
      container: Container,
      boundary,
      interactive,
    } = this.props;

    let { children } = this.props;

    if (!isNil(Container)) children = <Container>{children}</Container>;

    return (
      <Tippy
        content={content}
        placement={placement}
        trigger={trigger}
        arrow={arrow}
        duration={duration}
        maxWidth={maxWidth}
        boundary={boundary}
        interactive={interactive}
      >
        {children}
      </Tippy>
    );
  }
}
