import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class Progress extends PureComponent {
  static propTypes = {
    /**
     *  The progress in percents to render inside  component
     */
    progress: PropTypes.number.isRequired,
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,

    /**
     * Class to apply to the children component
     */
    childrenClassName: PropTypes.string,

    /**
     * The content to render inside component
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
    childrenClassName: "",
    children: false,
  };

  render() {
    const { className, childrenClassName, progress, children, ...otherProps } =
      this.props;
    const width = `${Math.min(100, progress)}%`;
    return (
      <div className={classNames("progress", className)} {...otherProps}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {children === false && width}
        </div>
        {children !== false && (
          <div
            className={classNames("progress-bar-children", childrenClassName)}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}
