// @flow

import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { getCurrentProject } from "modules/project/selectors";
import type { EditableUser } from "modules/projectUsers/flow-types";
import { getRoles } from "modules/roles";

import { NAMESPACE } from "./constants";

const dataPath = (...args) => path([NAMESPACE, ...args]);
const uiPath = (...args) => path([NAMESPACE, "ui", ...args]);
const usersPath = (...args) => path([NAMESPACE, "users", ...args]);

export const getUsersLoading = uiPath("loading");
export const isExistingUserLoading = uiPath("existingUserLoading");

export const getProjectUsers = usersPath("projectUsers");
export const getInheritedUsers = usersPath("inheritedUsers");

export const getExistingUsers = dataPath("existingUsers", "data");
export const getExistingUsersLoading = dataPath("existingUsers", "loading");

export const isModalVisible = uiPath("modalVisible");
export const getUser = uiPath("userInfo", "user");
export const getUserMode = uiPath("userInfo", "mode");
export const getDoubleUserCheck = uiPath("doubleUserCheck");

export const getVisibilityLevelsOptions: { [string]: string } = createSelector(
  getCurrentProject,
  (currentProject: Project) => {
    const { defaults: { visibilityLevels = {} } = {} } = currentProject
      ? currentProject
      : {};
    const result = {};
    for (const [
      key: string,
      value: { name: string, description: string },
    ] of Object.entries(visibilityLevels)) {
      //$FlowFixMe
      result[key] = `${value.name} - ${value.description}`;
    }
    return result;
  }
);

export const getPatientVisibilityEnabled = createSelector(
  getCurrentProject,
  project => {
    const { defaults: { patientVisibility: { enabled } = {} } = {} } =
      project || {};
    return enabled;
  }
);

export const getEditableUser: EditableUser = createSelector(
  getUser,
  getCurrentProject,
  getRoles,
  (
    {
      userId,
      fullname,
      email,
      roles = [],
      visibilityLevelId,
      authenticationProviderId,
    } = {},
    currentProject,
    allRoles
  ) => {
    const { projectId, code: projectName } = currentProject || {};
    const user: EditableUser = {
      projectId,
      projectName,
      userId,
      fullname,
      email,
      visibilityLevelId,
      authenticationProviderId: authenticationProviderId || "internal",
    };
    user.roles = {};
    if (allRoles) {
      allRoles.forEach(({ name }) => {
        const selected = !!roles.find(({ name: e }) => e === name);
        user.roles[name] = selected;
      });
    }
    return user;
  }
);

export const getCurrentUserAuthProviders = uiPath("currentUserAuthProviders");
export const getCurrentUserAuthProviderOptions = createSelector(
  getCurrentUserAuthProviders,
  authProviders =>
    authProviders.reduce(
      (acc, currentAuthProvider) => {
        acc[currentAuthProvider.id] = currentAuthProvider.attributes.name;
        return acc;
      },
      { internal: "Internal" }
    )
);
