import React from "react";

import TabsLayout from "../TabsLayout";
import type { TabElement } from "../TabsLayout";

import Filters from "./Filters";

const tabs: Array<TabElement> = [
  {
    name: "Filters",
    component: Filters,
  },
];

const ConfigForm = props => <TabsLayout tabs={tabs} {...props} />;

export default ConfigForm;
