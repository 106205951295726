import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { createRef, PureComponent } from "react";
import { connect } from "react-redux";

import { Modal } from "pattern-library";

import LegacySapientiaTemplate from "../../legacy/LegacySapientiaTemplate";
import * as actions from "../actions";
import * as selectors from "../selectors";

const CloseButtonSelector = ".close";
const EditButtonSelector = "button.edit";
const CancelButtonSelector = `button.btn-default[data-action="load"]`;
const SaveButtonSelector = "button.save";
const LegacyTemplateRequestTime = 500;

export class CuratedVariantModal extends PureComponent {
  static propTypes = {
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    curatedVariantId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visible: PropTypes.bool,
    setCuratedVariantVisibleModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pathogenicities: [],
    visible: false,
    curatedVariantId: null,
  };

  buttonHandlersMap = {
    editButton: this.editButtonClicked.bind(this),
    cancelButton: this.cancelButtonClicked.bind(this),
    closeButton: this.closeButtonClicked.bind(this),
    saveButton: this.closeButtonClicked.bind(this),
  };

  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.state = {
      closeButton: null,
      saveButton: null,
      editButton: null,
      cancelButton: null,
    };
    autoBind(this);
  }

  componentDidUpdate() {
    this.removeButtonListeners();
    this.addButtonListeners();
  }

  componentWillUnmount() {
    this.removeButtonListeners();
  }

  getButton(selector) {
    const { current } = this.containerRef;
    return current.querySelector(selector);
  }

  setEditFormButtons() {
    this.setState({
      saveButton: this.getButton(SaveButtonSelector),
      cancelButton: this.getButton(CancelButtonSelector),
      closeButton: this.getButton(CloseButtonSelector),
    });
  }

  removeButtonListeners() {
    Object.keys(this.state).forEach(button => {
      const { [button]: buttonElement } = this.state;
      if (buttonElement && this.buttonHandlersMap[button]) {
        buttonElement.removeEventListener(
          "click",
          this.buttonHandlersMap[button]
        );
      }
    });
  }

  closeButtonClicked() {
    const { setCuratedVariantVisibleModal } = this.props;
    setCuratedVariantVisibleModal(false);
  }

  cancelButtonClicked() {
    window.setTimeout(() => {
      this.setState({
        closeButton: this.getButton(CloseButtonSelector),
        editButton: this.getButton(EditButtonSelector),
      });
    }, LegacyTemplateRequestTime);
  }

  addButtonListeners() {
    Object.keys(this.state).forEach(button => {
      const { [button]: buttonElement } = this.state;
      if (buttonElement && this.buttonHandlersMap[button]) {
        buttonElement.addEventListener("click", this.buttonHandlersMap[button]);
      }
    });
  }

  editButtonClicked() {
    window.setTimeout(this.setEditFormButtons, LegacyTemplateRequestTime);
  }

  legacyModalHtmlUpdated() {
    this.setState({
      editButton: this.getButton(EditButtonSelector),
      closeButton: this.getButton(CloseButtonSelector),
    });
  }

  render() {
    const { patientId, visible, curatedVariantId } = this.props;

    return (
      <div ref={this.containerRef}>
        {visible && curatedVariantId && (
          <Modal
            show={visible}
            close={this.closeButtonClicked}
            className="curated-variant-modal block"
          >
            <LegacySapientiaTemplate
              containerClassName="curated-variant-modal-container"
              templateUrl={`/patient/${patientId}/variants/curated_variant/${curatedVariantId}`}
              htmlUpdated={this.legacyModalHtmlUpdated}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  curatedVariantId: selectors.selectCurationIdForCuratedVariantModal(state),
  visible: selectors.selectCuratedVariantModalVisible(state),
});

const mapDispatchToProps = {
  setCuratedVariantVisibleModal: actions.setCuratedVariantVisibleModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CuratedVariantModal);
