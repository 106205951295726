import type { CVLListType } from "../../common/types";
import { createAction } from "../utils";

import * as constants from "./constants";
import type {
  CuratedListVariantsType,
  CvlOptionsResponse,
  ProjectCuratedVariantList,
  RequestParams,
} from "./types";
import { getEmptyCVL } from "./utils";

export type SetDataLoadingAction = FSA<
  typeof constants.SET_LOADING,
  { isLoading: boolean }
>;

export const startLoading = (): SetDataLoadingAction =>
  createAction(constants.SET_LOADING, { isLoading: true });

export const stopLoading = (): SetDataLoadingAction =>
  createAction(constants.SET_LOADING, { isLoading: false });

export type SetVariantsLoadingAction = FSA<
  typeof constants.SET_VARIANTS_LOADING,
  { variantsLoading: boolean }
>;

export const startVariantsLoading = (): SetVariantsLoadingAction =>
  createAction(constants.SET_VARIANTS_LOADING, {
    variantsLoading: true,
  });

export const endVariantsLoading = (): SetVariantsLoadingAction =>
  createAction(constants.SET_VARIANTS_LOADING, {
    variantsLoading: false,
  });

export type SetCVLAuditLoadingAction = FSA<
  typeof constants.SET_CVL_AUDIT_LOADING,
  { auditLoading: boolean }
>;

export const startCVLAuditLoading = (): SetCVLAuditLoadingAction =>
  createAction(constants.SET_CVL_AUDIT_LOADING, {
    auditLoading: true,
  });

export const endCVLAuditLoading = (): SetCVLAuditLoadingAction =>
  createAction(constants.SET_CVL_AUDIT_LOADING, {
    auditLoading: false,
  });

export type InitCuratedListsAction = FSA<
  typeof constants.INIT_CURATED_LISTS,
  { projectId: number }
>;

export const initCuratedLists = (projectId: number): InitCuratedListsAction =>
  createAction(constants.INIT_CURATED_LISTS, { projectId });

export type SetExistingListsAvailableAction = FSA<
  typeof constants.SET_EXISTING_LISTS_AVAILABLE,
  { existingListsAvailable: boolean }
>;

export const setExistingListsAvailable = (
  existingListsAvailable: boolean
): SetExistingListsAvailableAction =>
  createAction(constants.SET_EXISTING_LISTS_AVAILABLE, {
    existingListsAvailable,
  });

export type LoadExistingListsAction = FSA<
  typeof constants.LOAD_EXISTING_LISTS,
  { projectId: number }
>;

export const loadExistingLists = (projectId: number): LoadExistingListsAction =>
  createAction(constants.LOAD_EXISTING_LISTS, { projectId });

type SetCuratedListsActions = FSA<
  typeof constants.SET_CURATED_LISTS,
  { curatedLists: Array<ProjectCuratedVariantList> }
>;

export const setCuratedLists = (
  curatedLists: Array<ProjectCuratedVariantList>
) => createAction(constants.SET_CURATED_LISTS, { curatedLists });

type SetNeedUpdateAction = FSA<
  typeof constants.SET_NEED_UPDATE,
  { needUpdate: boolean }
>;

export const setNeedUpdate = (needUpdate: boolean): SetNeedUpdateAction =>
  createAction(constants.SET_NEED_UPDATE, { needUpdate });

type SetSelectedCVLAction = FSA<
  typeof constants.SET_SELECTED_CVL,
  { selectedCVL: ProjectCuratedVariantList | null }
>;

export const setSelectedCVL = (
  cvl: ProjectCuratedVariantList | null
): SetSelectedCVLAction =>
  createAction(constants.SET_SELECTED_CVL, { selectedCVL: cvl });

export const addNewCVL = (
  variantsType: CuratedListVariantsType
): SetSelectedCVLAction => setSelectedCVL(getEmptyCVL(variantsType));

type ShowExistingCVLsAction = FSA<typeof constants.SHOW_EXISTING_CVLS>;

export const showExistingCVLs = (): ShowExistingCVLsAction =>
  createAction(constants.SHOW_EXISTING_CVLS);

type ResetCVLsDisplayModeAction = FSA<typeof constants.RESET_DISPLAY_MODE>;

export const resetDisplayMode = (): ResetCVLsDisplayModeAction =>
  createAction(constants.RESET_DISPLAY_MODE);

export type SubmitCVLAction = FSA<
  typeof constants.SUBMIT_CVL,
  ProjectCuratedVariantList
>;

export const submitCVL = (
  cvlFormValues: ProjectCuratedVariantList
): SubmitCVLAction => createAction(constants.SUBMIT_CVL, cvlFormValues);

export type SetCVLOptionsAction = FSA<
  typeof constants.SET_CVL_OPTIONS,
  CvlOptionsResponse
>;

export const setCvlOptions = (
  options: CvlOptionsResponse
): SetCVLOptionsAction => createAction(constants.SET_CVL_OPTIONS, options);

export type SetExistingListAction = FSA<
  typeof constants.SET_EXISTING_LISTS,
  { existingCuratedLists: Array<ProjectCuratedVariantList> }
>;

export const setExistingLists = (
  existingCuratedLists: Array<ProjectCuratedVariantList>
): SetExistingListAction =>
  createAction(constants.SET_EXISTING_LISTS, { existingCuratedLists });

export type AddExistingListAction = FSA<
  typeof constants.ADD_EXISTING_LIST,
  {
    curatedVariantListId: string;
    type: CVLListType;
    showPathogenicity: boolean;
  }
>;

export const addExistingList = (
  curatedVariantListId: string,
  type: CVLListType,
  showPathogenicity: boolean
): AddExistingListAction =>
  createAction(constants.ADD_EXISTING_LIST, {
    curatedVariantListId,
    type,
    showPathogenicity,
  });

export type RemoveCVLFromProjectAction = FSA<
  typeof constants.REMOVE_CVL_FROM_PROJECT,
  {
    curatedVariantListId: string;
    name: string;
  }
>;

export const removeCVLFromProject = (
  curatedVariantListId: string,
  name: string
): RemoveCVLFromProjectAction =>
  createAction(constants.REMOVE_CVL_FROM_PROJECT, {
    curatedVariantListId,
    name,
  });

export type SetExistingListStatusAction = FSA<
  typeof constants.SET_EXISTING_LIST_STATUS,
  { curatedVariantListId: string; addToProjectStatus: RequestSubmissionStatus }
>;

export const setExistingListStatus = (
  curatedVariantListId: string,
  addToProjectStatus: RequestSubmissionStatus
): SetExistingListStatusAction =>
  createAction(constants.SET_EXISTING_LIST_STATUS, {
    curatedVariantListId,
    addToProjectStatus,
  });

export type GetCVLDetailsAction = FSA<
  typeof constants.GET_CVL_DETAILS,
  {
    curatedVariantListId: string;
    originProjectId: number;
    currentProjectId?: number;
  }
>;

export const getCVLDetails = (
  curatedVariantListId: string,
  originProjectId: number,
  currentProjectId?: number
): GetCVLDetailsAction =>
  createAction(constants.GET_CVL_DETAILS, {
    curatedVariantListId,
    originProjectId,
    currentProjectId,
  });

export type GetCVLVariantsAction = FSA<
  typeof constants.GET_CVL_VARIANTS,
  {
    curatedVariantListId: string;
    projectId: number;
    requestParams: RequestParams;
  }
>;

export const getCVLVariants = (
  curatedVariantListId: string,
  projectId: number,
  requestParams: RequestParams
): GetCVLVariantsAction =>
  createAction(constants.GET_CVL_VARIANTS, {
    curatedVariantListId,
    projectId,
    requestParams,
  });

type SetSelectedVariantsAction = FSA<
  typeof constants.SET_SELECTED_VARIANTS,
  { selectedVariants: CVLVariants | null }
>;

export const setSelectedVariants = (
  selectedVariants: CVLVariants | null
): SetSelectedVariantsAction =>
  createAction(constants.SET_SELECTED_VARIANTS, { selectedVariants });

export type RemoveCVLAction = FSA<
  typeof constants.REMOVE_CVL,
  {
    curatedVariantListId: string;
    name: string;
  }
>;

export const removeCVL = (
  curatedVariantListId: string,
  name: string
): RemoveCVLAction =>
  createAction(constants.REMOVE_CVL, {
    curatedVariantListId,
    name,
  });

export type ExportCVLVariantsAction = FSA<
  typeof constants.EXPORT_CVL_VARIANTS,
  { curatedVariantListId: string; includeErroneous?: boolean }
>;

export const exportCvlVariants = (
  curatedVariantListId: string,
  includeErroneous?: boolean
): ExportCVLVariantsAction =>
  createAction(constants.EXPORT_CVL_VARIANTS, {
    curatedVariantListId,
    includeErroneous,
  });

type SetExportModalAction = FSA<
  typeof constants.SET_EXPORT_MODAL,
  {
    displayExportModal: boolean;
    hasInvalidData: boolean | null;
    curatedVariantListId: string | null;
  }
>;

export const setExportModalInvalidData = (
  curatedVariantListId: string
): SetExportModalAction =>
  createAction(constants.SET_EXPORT_MODAL, {
    curatedVariantListId,
    displayExportModal: true,
    hasInvalidData: true,
  });

export const setExportModalValidData = (
  curatedVariantListId: string
): SetExportModalAction =>
  createAction(constants.SET_EXPORT_MODAL, {
    curatedVariantListId,
    displayExportModal: true,
    hasInvalidData: false,
  });

export const hideExportModal = (): SetExportModalAction =>
  createAction(constants.SET_EXPORT_MODAL, {
    displayExportModal: false,
    hasInvalidData: null,
    curatedVariantListId: null,
  });

type SetExportLoadingAction = FSA<
  typeof constants.SET_EXPORT_LOADING,
  {
    exportLoading: boolean;
    displayExportModal: boolean;
  }
>;

export const startExportLoading = (): SetExportLoadingAction =>
  createAction(constants.SET_EXPORT_LOADING, {
    exportLoading: true,
    displayExportModal: true,
  });

export const endExportLoading = (): SetExportLoadingAction =>
  createAction(constants.SET_EXPORT_LOADING, {
    exportLoading: false,
    displayExportModal: true,
  });

export type GetCVLAuditAction = FSA<
  typeof constants.GET_CVL_AUDIT,
  {
    curatedVariantListId: string;
    originProjectId: string | undefined;
    requestParams: CVLAuditRequestParams;
  }
>;

export const getCVLAudit = (
  curatedVariantListId: string,
  originProjectId: string | undefined,
  requestParams: CVLAuditRequestParams
): GetCVLAuditAction =>
  createAction(constants.GET_CVL_AUDIT, {
    curatedVariantListId,
    originProjectId,
    requestParams,
  });

type SetCVLAuditAction = FSA<
  typeof constants.SET_CVL_AUDIT,
  {
    cvlAudit: CVLAudit | null;
  }
>;

export const setCVLAudit = (cvlAudit: CVLAudit | null): SetCVLAuditAction =>
  createAction(constants.SET_CVL_AUDIT, { cvlAudit });

type SetResetDisplayMode = FSA<
  typeof constants.SET_RESET_DISPLAY_MODE,
  {
    resetDisplayMode: boolean;
  }
>;

export const setResetDisplayMode = (
  resetDisplayMode: boolean
): SetResetDisplayMode =>
  createAction(constants.SET_RESET_DISPLAY_MODE, { resetDisplayMode });

export type Action =
  | SetDataLoadingAction
  | SetVariantsLoadingAction
  | SetCuratedListsActions
  | SetNeedUpdateAction
  | InitCuratedListsAction
  | SetSelectedCVLAction
  | ShowExistingCVLsAction
  | SubmitCVLAction
  | LoadExistingListsAction
  | AddExistingListAction
  | SetExistingListStatusAction
  | SetExistingListsAvailableAction
  | SetExistingListAction
  | SetCVLOptionsAction
  | ResetCVLsDisplayModeAction
  | GetCVLDetailsAction
  | GetCVLVariantsAction
  | SetSelectedVariantsAction
  | RemoveCVLAction
  | GetCVLAuditAction
  | SetCVLAuditAction
  | SetCVLAuditLoadingAction
  | SetExportModalAction
  | SetExportLoadingAction
  | SetResetDisplayMode
  | RemoveCVLFromProjectAction;
