export const GenePanelContentOptions = [
  { label: "Just Gene", value: "gene" },
  { label: "Gene with exom", value: "exom" },
  { label: "Genome", value: "genome" },
];

export const GenePanelFormatOptions = [
  { label: "BED", value: "bed" },
  { label: "CSV", value: "csv" },
  { label: "TSV", value: "tsv" },
];

export const fileTypeOptions = [
  { value: "bed", label: "Gene panel BED file (.bed)" },
  { value: "tsv", label: "Gene panel TSV file (.tsv)" },
  { value: "csv", label: "Gene panel CSV file (.csv)" },
];
// FORMS
export const EXPORT_GENE_PANEL_FORM = "form_gene_panel_download";
export const GENE_PANEL_OVERVIEW_FORM_NAME = "gene_panel_oveview_form";
export const GENE_PANEL_EDIT_NOTIFICATION_FORM_NAME = "gene_panel_copy_form";
export const ADD_NEW_GENE_PANEL_FORM_NAME = "addNewGenePanel";
export const UPLOAD_FORM_NAME = "form-upload-gene-panel";
export const UPLOAD_GENE_PANEL_INFO_FORM_NAME = "upload-gene-panel-info-form";
export const EDIT_GENE_IN_GENE_PANEL_FORM_NAME = "edit-gene-in-gene-panel-form";
export const SEARCH_GENE_FOR_GENE_PANEL_FORM = "gene-search";
