// @flow
import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as constants from "./constants";
import type { Message, LevelsMap } from "./reducer";

export const getAllMessages = createSelector(
  path([constants.NAME, "data", "messages"]),
  messages =>
    messages
      .map((message, index) => ({
        ...message,
        id: index,
      }))
      .reverse()
);
export const isCollapsed = path([constants.NAME, "ui", "collapsed"]);
export const getLevels = path([constants.NAME, "data", "levels"]);

export const getDismissedMessages = createSelector(getAllMessages, messages =>
  messages.filter(item => item.dismissed === true)
);

export const getActiveMessages = createSelector(getAllMessages, messages =>
  messages.filter(item => item.dismissed === false)
);

export const getMostImportantMessageLevel = createSelector(
  getActiveMessages,
  getLevels,
  (messages: Array<Message>, levelMap: LevelsMap) => {
    if (messages.length) {
      let { level } = messages[0];

      messages.forEach(item => {
        if (levelMap[item.level] > levelMap[level]) {
          level = item.level;
        }
      });

      return level;
    }
    return null;
  }
);
