import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Button, ButtonGroup, LoadingOverlay } from "pattern-library";

import { currentUser } from "modules/auth/selectors";
import {
  getCurrentProject,
  getProjectCurrentUser as projectCurrentUser,
} from "modules/project/selectors";

import { fetchUsers, initialize, resetUserInfo, setUserInfo } from "./actions";
import DeleteUserModal from "./components/DeleteUserModal";
import UserModal from "./components/UserModal";
import { UsersTable } from "./components/UsersTable";
import { getColumns, canEdit } from "./components/columns";
import {
  ADD_EXISTING_USER,
  ADD_PROJECT_USER,
  DELETE_PROJECT_USER,
  EDIT_PROJECT_USER,
} from "./constants";
import {
  getProjectUsers,
  getInheritedUsers,
  getUserMode,
  getUsersLoading,
  getDoubleUserCheck,
} from "./selectors";

export const UsersContainer = ({
  projectId,
  inheritedUsers,
  projectUsers,
  usersLoading = false,
  initialize,
  currentProject,
  setUserInfo,
  resetUserInfo,
  userMode,
  currentUser,
  projectCurrentUser,
  doubleUserCheck,
}) => {
  const {
    code,
    defaults: {
      patientVisibility: { enabled: patientVisibilityEnabled } = {},
    } = {},
  } = currentProject || {};
  useEffect(() => {
    initialize(projectId);
  }, [projectId, initialize]);

  const closeUserModal = useCallback(() => {
    resetUserInfo();
  }, [resetUserInfo]);

  const projectUsersColumns = getColumns(
    false,
    patientVisibilityEnabled,
    currentUser,
    projectCurrentUser,
    setUserInfo
  );
  const inheritedUsersColumns = getColumns(
    true,
    patientVisibilityEnabled,
    currentUser,
    projectCurrentUser,
    setUserInfo
  );

  const onAddNewUser = useCallback(
    () => setUserInfo({}, ADD_PROJECT_USER),
    [setUserInfo]
  );

  const onRowClick = useCallback(
    (
      _,
      {
        row: {
          original: user,
          original: { userId },
        },
      }
    ) => ({
      key: userId,
      onClick: () => {
        if (canEdit(user, currentUser, projectCurrentUser)) {
          setUserInfo(user, EDIT_PROJECT_USER);
        }
      },
      style: {
        cursor: canEdit(user, currentUser, projectCurrentUser)
          ? "pointer"
          : "default",
      },
    }),
    [setUserInfo, currentUser, projectCurrentUser]
  );

  return (
    <LoadingOverlay loading={usersLoading}>
      <div className="users">
        <div className="users-container">
          <UsersTable
            columns={projectUsersColumns}
            items={projectUsers}
            isLoading={usersLoading}
            title={`Users in ${code}`}
            getRowProps={onRowClick}
          />
          {(currentUser.admin || projectCurrentUser.canAdminProject) && (
            <div className="row">
              <div className="col-sm-3 col-sm-offset-9 col-xs-12">
                <ButtonGroup className="users-add">
                  <Button context="primary" onClick={onAddNewUser}>
                    Add user
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}
        </div>
        <UsersTable
          columns={inheritedUsersColumns}
          items={inheritedUsers}
          isLoading={usersLoading}
          title="Users in parent projects"
        />
        {(userMode === EDIT_PROJECT_USER ||
          userMode === ADD_PROJECT_USER ||
          userMode === ADD_EXISTING_USER) && (
          <UserModal
            show={
              userMode === EDIT_PROJECT_USER ||
              userMode === ADD_PROJECT_USER ||
              userMode === ADD_EXISTING_USER
            }
            projectId={projectId}
            close={closeUserModal}
            doubleUserCheck={doubleUserCheck}
          />
        )}
        {userMode === DELETE_PROJECT_USER && (
          <DeleteUserModal
            show={userMode === DELETE_PROJECT_USER}
            close={closeUserModal}
          />
        )}
      </div>
    </LoadingOverlay>
  );
};

UsersContainer.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const mapStateToProps = createStructuredSelector({
  inheritedUsers: getInheritedUsers,
  projectUsers: getProjectUsers,
  usersLoading: getUsersLoading,
  currentProject: getCurrentProject,
  userMode: getUserMode,
  currentUser,
  projectCurrentUser,
  doubleUserCheck: getDoubleUserCheck,
});

const mapDispatchToProps = {
  initialize,
  fetchUsers: fetchUsers.start,
  setUserInfo,
  resetUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
