import { combineReducers } from "@reduxjs/toolkit";
import { reducer as FormReducer } from "redux-form";

import ormReducer from "data-layer/orm/reducer";
import { ARIADNE_NAMESPACE, AriadneReducer } from "modules/ariadne";
import {
  ModalRegistryReducer,
  MODAL_REGISTRY_NAMESPACE,
} from "modules/modalRegistry";
import {
  PatientMetadataReducer,
  PATIENT_METADATA_NAMESPACE,
} from "modules/patientMetadata";
import ProjectGenePanelsReducer from "modules/projectGenePanels/reducer";
import {
  ProjectIRsReducer,
  PROJECT_IRS_NAMESPACE,
} from "modules/projectInterpretationRequests";
import {
  ProjectSettingsReducer,
  PROJECT_SETTINGS_NAMESPACE,
} from "modules/projectSettings";
import { ProjectUsersReducer, USERS_NAMESPACE } from "modules/projectUsers";
import { STRsReducer, STRS_MODULE_NAME } from "modules/str";
import {
  STRUCTURAL_VARIANTS_NAMESPACE,
  StructuralVariantsReducer,
} from "modules/structuralVariants";

import api from "./api/index";
import entitiesReducer from "./data-layer/entities/reducer";
import ACMGReducer from "./modules/acmg/reducer";
import AuditReducer from "./modules/audit/reducer";
import AuthReducer from "./modules/auth/reducer";
import ConfigReducer from "./modules/config/reducer";
import { curatedVariantReducer } from "./modules/curatedVariant";
import { MODULE_NAME as CVL_MODULE_NAME } from "./modules/curatedVariantsLists/constants";
import CVLsReducer from "./modules/curatedVariantsLists/reducer";
import DecisionsReducer from "./modules/decisions/reducer";
import { FEEDBACK_NAMESPACE, FeedbackReducer } from "./modules/feedback";
import GenoverseReducer from "./modules/genoverse/reducer";
import HPOTermsReducer from "./modules/hpoTerms/reducer";
import InterpretationRequestsReducer from "./modules/interpretationRequests/reducer";
import { LicencesReducer, LICENCES_NAMESPACE } from "./modules/licences";
import MessagesReducer from "./modules/messages/reducer";
import MetadataReducer from "./modules/metadata/reducer";
import { PatientReducer, PATIENT_NAMESPACE } from "./modules/patient";
import PatientsReducer from "./modules/patients/reducer";
import { ProjectReducer, PROJECT_NAMESPACE } from "./modules/project";
import filterPresetsReducer from "./modules/projectSettings/components/filterPresets/reducer";
import ProjectsReducer from "./modules/projects/reducer";
import { refNoCallReducer, REFNOCALL_NAMESPACE } from "./modules/refNoCall";
import { RolesReducer, ROLES_NAMESPACE } from "./modules/roles";
import SeqVariantsPresetsReducer from "./modules/sequenceVariantsPresets/reducer";
import {
  SequenceVariantsSummaryReducer,
  SEQUENCE_VARIANTS_SUMMARY_NAMESPACE,
} from "./modules/sequenceVariantsSummary";
import StructuralVariantsPresetsReducer from "./modules/structuralVariantsPresets/reducer";
import { systemConfigReducer } from "./modules/systemConfig";
import {
  VariantHistoryReducer,
  VARIANT_HISTORY_NAMESPACE,
} from "./modules/variantHistory";
import VariantReducer from "./modules/variants/reducer";
import { VersionsReducer, VERSIONS_NAMESPACE } from "./modules/versions";

const rootReducer = combineReducers({
  entities: entitiesReducer,
  auth: AuthReducer,
  variants: VariantReducer,
  sequenceVariantsPresets: SeqVariantsPresetsReducer,
  structuralVariantsPresets: StructuralVariantsPresetsReducer,
  [CVL_MODULE_NAME]: CVLsReducer,
  config: ConfigReducer,
  orm: ormReducer,
  form: FormReducer,
  hpoTerms: HPOTermsReducer,
  acmg: ACMGReducer,
  messages: MessagesReducer,
  interpretationRequests: InterpretationRequestsReducer,
  metadata: MetadataReducer,
  [PROJECT_NAMESPACE]: ProjectReducer,
  projects: ProjectsReducer,
  systemConfig: systemConfigReducer,
  [USERS_NAMESPACE]: ProjectUsersReducer,
  projectGenePanels: ProjectGenePanelsReducer,
  patients: PatientsReducer,
  [REFNOCALL_NAMESPACE]: refNoCallReducer,
  curatedVariant: curatedVariantReducer,
  filterPresets: filterPresetsReducer,
  decisions: DecisionsReducer,
  audit: AuditReducer,
  genoverse: GenoverseReducer,
  [PATIENT_NAMESPACE]: PatientReducer,
  [ROLES_NAMESPACE]: RolesReducer,
  [PROJECT_IRS_NAMESPACE]: ProjectIRsReducer,
  [STRS_MODULE_NAME]: STRsReducer,
  [PROJECT_SETTINGS_NAMESPACE]: ProjectSettingsReducer,
  [PATIENT_METADATA_NAMESPACE]: PatientMetadataReducer,
  [MODAL_REGISTRY_NAMESPACE]: ModalRegistryReducer,
  [STRUCTURAL_VARIANTS_NAMESPACE]: StructuralVariantsReducer,
  [FEEDBACK_NAMESPACE]: FeedbackReducer,
  [VARIANT_HISTORY_NAMESPACE]: VariantHistoryReducer,
  [ARIADNE_NAMESPACE]: AriadneReducer,
  [SEQUENCE_VARIANTS_SUMMARY_NAMESPACE]: SequenceVariantsSummaryReducer,
  [VERSIONS_NAMESPACE]: VersionsReducer,
  [LICENCES_NAMESPACE]: LicencesReducer,

  // RTK Query API
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
