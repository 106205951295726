import { NavData, NavKeys } from "./Footer.types";

export const defaultLinkData: NavData = {
  about: {
    action: null,
    conditionToShow: [{ propName: "userLoggedIn", value: true }],
    icon: "help",
    label: "About",
  },
  bugReport: {
    action: null,
    icon: "pencil",
    label: "Report a Bug",
  },
  feedback: {
    action: null,
    icon: "envelope",
    label: "Feedback",
  },
  userManual: {
    action: "/user-guide/row/Default.htm",
    conditionToShow: [
      { propName: "userLoggedIn", value: true },
      { propName: "ceMarking", value: false },
    ],
    icon: "help",
    label: "User Guide",
  },
  v1Api: {
    action: "/assets/doc/index.html",
    conditionToShow: [{ propName: "userLoggedIn", value: true }],
    label: "v1 API Docs",
  },
  v2Api: {
    action: "/assets/public-api-docs/index.html",
    conditionToShow: [{ propName: "userLoggedIn", value: true }],
    label: "v2 API Docs",
  },
  v3Api: {
    action: "/api/v3/api_docs/index.html",
    conditionToShow: [{ propName: "userLoggedIn", value: true }],
    label: "v3 API Docs",
  },
};

export const linksOrder: NavKeys[] = [
  "userManual",
  "about",
  "v1Api",
  "v2Api",
  "v3Api",
  "bugReport",
  "feedback",
];
