export const NAME = "systemConfig";
export const LOAD_SYSTEM_CONFIG = `${NAME}/LOAD`;
export const SET_SYSTEM_CONFIG = `${NAME}/SET`;
export const BLOCK_UI_SYSTEM_CONFIG = `${NAME}/BLOCK_UI`;
export const SET_AUTH_PROVIDERS = `AUTH_PROVIDERS/SET`;

export const AllowedAssemblies = {
  grch37: "grch37",
  grch38: "grch38",
};

export const AllowedReferenceGenomes = {
  hg19: "hg19",
  hg38: "hg38",
};
