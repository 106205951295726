import classNames from "classnames";
import PropTypes from "prop-types";
import { pick } from "ramda";
import React, { useState, useEffect } from "react";

import { FormField, Heading } from "pattern-library";

import MoiData from "./MoiData";

const EXOMISER_INHERITANCE_MODES = {
  UNKNOWN: "Unknown",
  AUTOSOMAL_RECESSIVE: "Autosomal Recessive",
  AUTOSOMAL_DOMINANT: "Autosomal Dominant",
  AUTOSOMAL_DOMINANT_AND_RECESSIVE: "Autosomal Dominant and Recessive",
  X_LINKED: "X-linked",
  X_RECESSIVE: "X-linked Recessive",
  X_DOMINANT: "X-linked Dominant",
  Y_LINKED: "Y Linked",
  SOMATIC: "Somatic",
  MITOCHONDRIAL: "Mitochondrial",
  POLYGENIC: "Polygenic",
};

const MoiPane = ({ data = {}, className }) => {
  const variantMOIs = Object.keys(data);
  const highestMoi = variantMOIs.find(moi => data[moi].isHighestMoi);
  const values = pick(variantMOIs, EXOMISER_INHERITANCE_MODES);

  const [moi, setMOI] = useState(highestMoi);

  const onChange = e => setMOI(e.target.value);

  useEffect(() => {
    setMOI(highestMoi);
  }, [highestMoi]);

  return (
    <div
      className={classNames(
        className,
        "exomiser-pane",
        "exomiser-pane-moi",
        "form-horizontal"
      )}
    >
      {highestMoi ? (
        <>
          <Heading type="h4">
            MOI responsible for highest Combined Score:
          </Heading>
          <Heading type="h4">{EXOMISER_INHERITANCE_MODES[highestMoi]}</Heading>
          <FormField
            label="MOI"
            type="dropdown"
            value={moi}
            options={values}
            onChange={onChange}
            className="exomiser-gap"
          />
          <MoiData data={data[moi]} />
        </>
      ) : (
        <Heading type="h4">
          Variant does not contribute to any MOI-dependent scores
        </Heading>
      )}
    </div>
  );
};

MoiPane.propTypes = {
  /**
   * Additional CSS class for rendering the component
   */
  className: PropTypes.string,
  /**
   * Object containing data for all MOIs linked to this variant
   */
  data: PropTypes.object,
};

export default MoiPane;
