import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import TreeNode from "./TreeNode";

export default class Tree extends PureComponent {
  static propTypes = {
    TreeNodeComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.element,
      PropTypes.func,
    ]),
    root: PropTypes.shape({
      data: PropTypes.object,
      children: PropTypes.array,
    }),
  };

  render() {
    const {
      TreeNodeComponent = TreeNode,
      RenderComponent = false,
      root: { children = [] } = {},
    } = this.props;

    if (!RenderComponent) throw new Error("Render component not provided.");

    return children.map(child => (
      <TreeNodeComponent key={child.id} {...this.props} root={child} />
    ));
  }
}
