import { call, put, takeLatest } from "redux-saga/effects";

import {
  getProjects,
  getPossibleProjectTypesForChildProjects,
} from "modules/api/projects";
import { error } from "modules/messages/actions";

import {
  readProjectsFailure,
  readProjectsSuccess,
  readProjectTypesForChildProjectsSuccess,
  readProjectTypesForChildProjectsFailure,
} from "./actions";
import * as constants from "./constants";

export function* init() {
  yield takeLatest(constants.READ_PROJECTS, reloadProjects);
  yield takeLatest(
    constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS,
    reloadProjectTypesForChildProjects
  );
}

export function* reloadProjects() {
  try {
    const response = yield call(getProjects);
    yield put(readProjectsSuccess(response));
  } catch ({ message }) {
    yield put(readProjectsFailure());
    yield put(error(message ? message : "Cannot load projects"));
  }
}

export function* reloadProjectTypesForChildProjects({ payload }) {
  try {
    const response = yield call(
      getPossibleProjectTypesForChildProjects,
      payload
    );
    if (!response.ok) {
      yield put(readProjectTypesForChildProjectsFailure());
      const {
        error: errorMsg = "Cannot load project types for child projects",
      } = response.payload || {};
      yield put(error(errorMsg));
      return;
    }
    yield put(readProjectTypesForChildProjectsSuccess(response.payload));
  } catch ({ message }) {
    yield put(readProjectTypesForChildProjectsFailure());
    yield put(
      error(message ? message : "Cannot load project types for child projects")
    );
  }
}
