// @flow
import { path } from "ramda";

import { NAME } from "./constants";

const uiPath = (...items) => [NAME, "ui", ...items];
const dataPath = (...items) => [NAME, "data", ...items];

export const selectCuratedVariantModalVisible = path(
  uiPath("variantRowModal", "visible")
);

export const selectCurationIdForCuratedVariantModal = path(
  dataPath("curatedVariantModalCurationId")
);
