// @flow

import { decamelizeKeys } from "humps";
import { groupBy } from "ramda";

import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";
import { fetchCamelizeData, fetchData, processErrors } from "modules/utils";
import {
  API_ENTITIES_BASE_URL,
  CATALYST_BASE_URL,
} from "modules/utils/baseUrls";

const processProjects = projects => {
  const projectsMap = groupBy(({ id }) => id)(projects);

  // We add an additional property of all project codes in the hierarchy
  // so we can return all nested projects when searching by a keyword
  return projects.map(({ attributes, ...rest }) => {
    const projectPath = attributes.path.split(".");

    const keywordsArray = projectPath
      .filter(path => projectsMap[path] && projectsMap[path][0])
      .map(path => projectsMap[path][0].attributes.code);

    return {
      attributes: {
        ...attributes,
        keywords: keywordsArray.join(" "),
        keywordsPath: keywordsArray.join("."), // This format for search used inside QA integration tests
      },
      ...rest,
    };
  });
};

export const getProjects = async () => {
  const { payload } = await fetchCamelizeData(
    // TODO: Eventually remove the unlimited page size
    // This won't scale if a user has thousands and thousands of projects
    // We should implement some kind of pagination in the end, but that also requires a
    // keyword search endpoint for projects
    `${API_ENTITIES_BASE_URL}/projects?page[size]=0`,
    {
      credentials: "include",
    }
  );

  const { data } = payload;

  return processProjects(data);
};

export function getProject(projectId: number | string, args: Object) {
  return fetchCamelizeData(`${CATALYST_BASE_URL}/project/${projectId}`, args);
}

export const createProject = async (
  newProject: NewProject,
  parentProjectId: number
) => {
  if (newProject.projectTypeInternalName === ONCOLOGY_PROJECT_TYPE) {
    newProject.privateAf = "true";
  }
  const newProjectData = {
    data: {
      type: "projects",
      attributes: {
        ...decamelizeKeys(newProject),
        parent_id: parentProjectId,
      },
    },
  };

  return fetch(`${API_ENTITIES_BASE_URL}/projects`, {
    method: "POST",
    body: JSON.stringify(newProjectData),
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
  });
};

export const getPossibleProjectTypesForChildProjects = async (
  projectId: number | string
): Promise<Array<ProjectType>> =>
  fetchCamelizeData(
    `${CATALYST_BASE_URL}/project/${projectId}/project_types_allowed_in_child_project`
  );

/*
  Let say we have

  project-1 id=1 path=1
  |-- project-2 id=2 path=1.2
  |    |-- project-2.1 id=10 path=1.2.10
  |         |-- project-2.1.1 id=100 path=1.2.10.100
  |-- project-3 id=3 path=1.3

  If we will call /projects/2/children we will receive 2 records - with path=1.2.10 and path=1.2.10.100
  We are only interesting in kinda first level inheritors - in our case project with path=1.2.10 id=10
*/
const firstLevelChildrenRegExp = projectId =>
  `(^.*\\.${projectId}\\.\\d+$)|(^${projectId}\\.\\d+$)`;

export async function getProjectChildren(
  projectId: number
): Promise<Array<ProjectChild>> {
  const { ok, payload, errors } = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/children`
  );

  if (!ok || errors) {
    const details = processErrors(errors);
    throw new Error(`Couldn't retrieve project children. ${details}`);
  }
  return payload.data
    .map(
      ({
        id,
        attributes: { path, defaultsIsInherited, crmReferenceIsInherited },
      }) => ({
        id,
        path,
        defaultsIsInherited,
        crmReferenceIsInherited,
      })
    )
    .filter(({ path }) =>
      new RegExp(firstLevelChildrenRegExp(projectId)).test(path)
    );
}

export function getProjectTabCounts(projectId: number | string, args: Object) {
  return fetchCamelizeData(
    `${CATALYST_BASE_URL}/project/${projectId}/counts`,
    args
  );
}

export function getProtocols(projectId: number | string) {
  return fetch(`${API_ENTITIES_BASE_URL}/projects/${projectId}/protocols`, {
    credentials: "include",
  });
}

export function getBaitsets(projectId: number | string) {
  return fetchData(
    `${CATALYST_BASE_URL}/project/${projectId}/oncology/baitsets`
  );
}
