import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as actions from "../../actions";
import * as selectors from "../../selectors";

import AcmgCriteriaListSection from "./Section";

export class AcmgCriteriaList extends PureComponent {
  static propTypes = {
    categories: PropTypes.array,
  };

  render() {
    const {
      categories = [],
      locked = true,
      updateCriteriaToAdd,
      canEdit = false,
      disabled,
    } = this.props;

    return (
      <div className="acmg-criteria-list">
        {categories.map(category => (
          <AcmgCriteriaListSection
            locked={locked}
            updateCriteriaToAdd={updateCriteriaToAdd}
            key={category.id}
            category={category}
            canEdit={canEdit}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    categories: selectors.getPopulatedSelectedCriteriaByCategories,
    canEdit: selectors.canEdit,
    locked: selectors.isLocked,
  }),
  dispatch => bindActionCreators(actions, dispatch)
)(AcmgCriteriaList);
