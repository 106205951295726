import React, { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { IconLoading } from "pattern-library";

import { formatDateTime } from "modules/utils";

import { fetchAcmgAuditInfo } from "../actions";
import { acmgAuditInfoSelector } from "../selectors";

interface Props extends PropsFromRedux {
  patientId: number;
}
export const AcmgAuditInfo: FC<Props> = ({
  patientId,
  acmgAuditInfo: {
    data: { auditTime, version },
    loading,
  },
  fetchAcmgAuditInfo,
}: Props) => {
  useEffect(() => {
    fetchAcmgAuditInfo(patientId);
  }, [fetchAcmgAuditInfo, patientId]);

  return (
    <div className="audit__info">
      <div className="audit__info-section">
        <div className="audit__info-left">Auto ACMG API requested:</div>
        <div className="audit__info-right">
          {!!loading && <IconLoading loading />}
          {!loading && formatDateTime(auditTime * 1000)}
        </div>
      </div>
      <div className="audit__info-section">
        <div className="audit__info-left">Auto ACMG data version:</div>
        <div className="audit__info-right">
          {!!loading && <IconLoading loading />}
          {!loading && version}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  acmgAuditInfo: acmgAuditInfoSelector,
});

const mapDispatchToProps = {
  fetchAcmgAuditInfo: fetchAcmgAuditInfo.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AcmgAuditInfo);
