import PropTypes from "prop-types";
import { toUpper } from "ramda";
import React, { PureComponent } from "react";

import { Button, ButtonGroup, Tooltip } from "pattern-library";

export default class CustomTier extends PureComponent {
  static displayName = "CustomTier";

  static propTypes = {
    variant: PropTypes.shape({ customData: PropTypes.string }),
    showTranscriptName: PropTypes.bool,
  };

  render() {
    const { variant: { customData = "{}" } = {}, showTranscriptName = true } =
      this.props;

    const { tiers = [] } = JSON.parse(customData);

    return (
      <div className="variant-column variant-column--tier tier">
        {tiers.map(
          ({ tier, panelName, panelVersion, penetrance, transcriptName }) => {
            let name = panelName;
            if (panelVersion) {
              name += ` ver. ${panelVersion}`;
            }

            const content = (
              <div>
                <div>{name}</div>
                {showTranscriptName && (
                  <div>
                    {transcriptName
                      ? transcriptName.split(",").join(", ")
                      : "Transcript name is not available"}
                  </div>
                )}
              </div>
            );

            return (
              <Tooltip
                key={panelName}
                content={content}
                placement="left"
                trigger="mouseenter"
                arrow
                maxWidth={200}
              >
                <ButtonGroup size="xs" className="tier-container">
                  <Button context="primary">{tier}</Button>
                  <Button className={`tier-${penetrance}`}>{`${toUpper(
                    penetrance[0]
                  )}P`}</Button>
                </ButtonGroup>
              </Tooltip>
            );
          }
        )}
      </div>
    );
  }
}
