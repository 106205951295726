import classNames from "classnames";
import React from "react";

import congenicaAiLogo from "../../../images/ai-logo.svg";

import styles from "./CongenicaAILogo.module.scss";

const CongenicaAILogo = ({ className, ...props }) => (
  <div className={styles.wrapper}>
    <img
      src={congenicaAiLogo}
      alt="Congenica AI"
      className={classNames([className, styles.icon])}
      {...props}
    />
  </div>
);

CongenicaAILogo.displayName = "CongenicaAILogo";

export default CongenicaAILogo;
