import PropTypes from "prop-types";
import React, { memo, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Icon,
  Modal,
  MODAL_SIZES,
  ModalBody,
  ModalHeader,
} from "pattern-library";
import { Table, DEFAULT_PAGE_SIZE } from "pattern-library/elements/table7";

import * as selectors from "modules/patients/selectors";
import { formatDateTime } from "modules/utils";

export const ReportsModal = memo(
  ({ data = [], isLoading = false, patientId, close, show }) => {
    const columns = useMemo(
      () => [
        {
          Header: "Date",
          accessor: ({ updated }) => formatDateTime(updated * 1000),
        },
        {
          Header: "Report Template Name",
          accessor: "templateName",
        },
        {
          Header: "Variants",
          accessor: ({ variantCount }) => (!!variantCount ? variantCount : ""),
        },
        {
          Header: "Actions",
          accessor: ({ id }) => (
            <div className="patients-action">
              <a
                className="btn btn-default btn actions-btn btn--small"
                href={`/patient/${patientId}/report/view/${id}`}
                onClick={e => e.stopPropagation()}
                download
              >
                <Icon type="download" />
                <span className="actions-label">Download</span>
              </a>
            </div>
          ),
        },
      ],
      [patientId]
    );

    const onRowClick = useCallback(
      (
        _,
        {
          row: {
            original: { id },
          },
        }
      ) => ({
        key: id,
        onClick: () => {
          window.location = `/patient/${patientId}/report/view/${id}`;
        },
        style: {
          cursor: "pointer",
        },
      }),
      [patientId]
    );

    return (
      <Modal size={MODAL_SIZES.L} close={close} show={show}>
        <ModalHeader close={close}>
          <strong>Reports</strong>
        </ModalHeader>
        <ModalBody>
          {" "}
          <Table
            columns={columns}
            data={data}
            showPagination={data.length > DEFAULT_PAGE_SIZE}
            autoResetPage={false}
            noDataText="No reports found"
            getRowProps={onRowClick}
            loading={isLoading}
          />
        </ModalBody>
      </Modal>
    );
  }
);

ReportsModal.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  patientId: PropTypes.number,
  close: PropTypes.func,
  show: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectors.isLoading,
  data: selectors.getReports,
  patientId: selectors.getReportsPatientId,
});

export default connect(mapStateToProps, undefined)(ReportsModal);
