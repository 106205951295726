import PropTypes from "prop-types";
import { toLower } from "ramda";
import React, { memo, useState, useEffect } from "react";

import SearchBar from "../SearchBar";

import { genePanelShape } from "./propTypes";

const GenePanelFilterBar = memo(({ initialValues, setFilteredValues }) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const query = toLower(searchTerm);
    if (!query) {
      setFilteredValues(initialValues);
    } else {
      const filteredPanels = initialValues.filter(panel => {
        const { title = "", description = "" } = panel;
        return (
          toLower(title).includes(query) ||
          toLower(description || "").includes(query)
        );
      });
      setFilteredValues(filteredPanels);
    }
  }, [initialValues, searchTerm, setFilteredValues]);

  return <SearchBar setSearchTerm={setSearchTerm} id="genePanelsSearchTerm" />;
});

GenePanelFilterBar.propTypes = {
  initialValues: PropTypes.arrayOf(genePanelShape).isRequired,
  setFilteredValues: PropTypes.func.isRequired,
};

export default GenePanelFilterBar;
