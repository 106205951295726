import { useState, useCallback } from "react";

const useRowSelection = (samples, fieldsDisabled, setFieldValue, remove) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowForDelete, setRowForDelete] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
    useState("");

  const toggleDeleteConfirmationModal = useCallback(() => {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
  }, [showDeleteConfirmationModal]);

  const removeRow = useCallback(
    index => {
      if (selectedRow === index) {
        setSelectedRow(null);
      } else if (index < selectedRow) {
        setSelectedRow(selectedRow - 1);
      }
      remove(index);
    },
    [selectedRow, setSelectedRow, remove]
  );

  const clearDependenciesAndRemoveRow = useCallback(() => {
    const { name } = samples[rowForDelete];
    samples.forEach(({ motherName, fatherName }, sampleIndex) => {
      if (sampleIndex !== rowForDelete) {
        if (name === motherName) {
          setFieldValue(`samples.${sampleIndex}.motherName`, null);
        }
        if (name === fatherName) {
          setFieldValue(`samples.${sampleIndex}.fatherName`, null);
        }
      }
    });

    removeRow(rowForDelete);
    setRowForDelete(null);
    toggleDeleteConfirmationModal();
  }, [
    removeRow,
    rowForDelete,
    samples,
    setFieldValue,
    toggleDeleteConfirmationModal,
  ]);

  const removeRowHandler = useCallback(
    index => {
      if (fieldsDisabled) {
        return;
      }
      setRowForDelete(index);
      const { name } = samples[index];
      const occurrences = samples.reduce(
        (accumulator, { motherName, fatherName }, sampleIndex) => {
          if (
            sampleIndex !== index &&
            (name === motherName || name === fatherName)
          )
            accumulator.push(sampleIndex + 1);
          return accumulator;
        },
        []
      );

      if (occurrences.length > 0) {
        setDeleteConfirmationMessage(
          `The sample is used in sample${
            occurrences.length > 1 ? "s" : ""
          } in row${
            occurrences.length > 1 ? "s" : ""
          } ${occurrences.toString()}. These occurrences will be unset. Proceed?`
        );
      } else {
        setDeleteConfirmationMessage(
          `Are you sure you want to remove sample at row ${index + 1}?`
        );
      }

      setShowDeleteConfirmationModal(true);
    },
    [fieldsDisabled, samples, setShowDeleteConfirmationModal]
  );

  return {
    selectedRow,
    setSelectedRow,
    clearDependenciesAndRemoveRow,
    removeRowHandler,
    showDeleteConfirmationModal,
    toggleDeleteConfirmationModal,
    deleteConfirmationMessage,
  };
};

export default useRowSelection;
