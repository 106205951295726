// @flow

import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { getCurrentProjectTypeInternalName } from "modules/project/selectors";

import { NAMESPACE } from "./constants";

export const getRoles = path([NAMESPACE, "data"]) || [];

export const getProjectRoles: { [string]: string } = createSelector(
  getCurrentProjectTypeInternalName,
  getRoles,
  (projectTypeInternalNameTarget, roles) =>
    roles.filter(
      ({ projectTypeInternalName }) =>
        projectTypeInternalNameTarget === projectTypeInternalName
    )
);
