import { isNil } from "ramda";
import { memo, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Actions,
  List,
  Dropdown,
  Toggle,
  ConfirmationModal,
} from "pattern-library";

import { isRootProject } from "modules/project/selectors";

import SNVPresetForm from "./SNVPresetForm";
import SVPresetForm from "./SVPresetForm";
import { setSelectedPreset } from "./actions";
import {
  ALL,
  SNV,
  SV,
  SV_PRESET_DEFAULTS,
  variantTypeOptions,
} from "./constants";
import { FilterPresetProp } from "./prop-types";
import {
  getSelectedPreset,
  getSNVPresetDefaults,
  isConfigurationInherited,
} from "./selectors";

export const isItemHighlighted = (item?: Index) =>
  !!item && item.isDefault === true;

type Props = {
  projectId: number | string;
  presetValuePropName: string;
  presetKeyPropName: string;
  filterPresets: Array<FilterPresetProp>;
  selectedPreset?: FilterPresetProp;
  onInheritanceSettingsSubmit: (inherited: boolean) => void;
  onRemoveSelectedPreset: (preset: FilterPresetProp) => void;
  setSelectedPreset: typeof setSelectedPreset;
  onPresetSubmit: (formValues: Index) => void;
  isConfigurationInherited: boolean;
  isRootProject: boolean;
  snvPresetDefaults: ReturnType<getSNVPresetDefaults>;
};

export const FilterPresetsList = memo(
  ({
    projectId,
    filterPresets = [],
    isConfigurationInherited,
    selectedPreset,
    setSelectedPreset,
    onInheritanceSettingsSubmit,
    onRemoveSelectedPreset,
    onPresetSubmit,
    presetValuePropName = "title",
    presetKeyPropName = "id",
    isRootProject = false,
    snvPresetDefaults,
  }: Props) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [variantTypeFilter, setVariantTypeFilter] = useState(ALL);
    const [filteredPresets, setFilteredPresets] = useState(filterPresets);
    const [
      showInheritanceConfirmationModal,
      setShowInheritanceConfirmationModal,
    ] = useState(false);

    const toggleConfirmationModal = useCallback(
      show => {
        setShowConfirmationModal(show);
      },
      [setShowConfirmationModal]
    );

    const toggleInheritanceConfirmationModal = useCallback(
      show => {
        setShowInheritanceConfirmationModal(show);
      },
      [setShowInheritanceConfirmationModal]
    );

    const removeFilterPreset = useCallback(() => {
      if (selectedPreset) {
        onRemoveSelectedPreset(selectedPreset);
        toggleConfirmationModal(false);
      }
    }, [onRemoveSelectedPreset, toggleConfirmationModal, selectedPreset]);

    const inheritanceOnConfirmed = useCallback(() => {
      onInheritanceSettingsSubmit(true);
      toggleInheritanceConfirmationModal(false);
    }, [onInheritanceSettingsSubmit, toggleInheritanceConfirmationModal]);

    const onInheritanceChange = useCallback(
      e => {
        const newValue = e.target.checked;
        if (newValue) {
          toggleInheritanceConfirmationModal(true);
        } else {
          onInheritanceSettingsSubmit(newValue);
        }
      },
      [toggleInheritanceConfirmationModal, onInheritanceSettingsSubmit]
    );

    const onVariantTypeChange = useCallback(
      e => {
        setVariantTypeFilter(e.target.value);
      },
      [setVariantTypeFilter]
    );

    const filterPresetsByType = useCallback(
      type => {
        const filteredItems =
          type === ALL
            ? filterPresets
            : filterPresets.filter(preset => preset.variantType === type);
        setFilteredPresets(filteredItems);
      },
      [setFilteredPresets, filterPresets]
    );

    const addSVPreset = useCallback(() => {
      setSelectedPreset({
        ...SV_PRESET_DEFAULTS,
        projectId,
      });
    }, [setSelectedPreset, projectId]);

    const addSNVPreset = useCallback(() => {
      setSelectedPreset({
        ...snvPresetDefaults,
        projectId,
      });
    }, [setSelectedPreset, projectId, snvPresetDefaults]);

    const getDeleteBtnTooltip = useCallback(() => {
      if (!selectedPreset || isNil(selectedPreset.id)) {
        return "Please select a preset to delete";
      } else if (selectedPreset && selectedPreset.isInherited) {
        return "You cannot delete an inherited preset";
      } else if (selectedPreset && selectedPreset.isDefault) {
        return "You cannot delete a default preset";
      }
      return "Delete selected preset";
    }, [selectedPreset]);

    useEffect(() => {
      filterPresetsByType(variantTypeFilter);
    }, [variantTypeFilter, filterPresetsByType]);

    let presetFormParams;

    if (selectedPreset) {
      const { isInherited, isDefault } = selectedPreset;
      presetFormParams = {
        projectId,
        submitCallback: onPresetSubmit,
        isDisabled: isInherited,
        isDefaultDisabled: isDefault,
      };
    }

    return (
      <div className="filter-presets-container">
        <ConfirmationModal
          confirmationText="All filter presets will be deleted from this project and all inheriting child projects. Do you want to continue?"
          showConfirmationModal={showInheritanceConfirmationModal}
          yesClickHandler={inheritanceOnConfirmed}
          closeClickHandler={() => toggleInheritanceConfirmationModal(false)}
          className="inheritance-confirm-modal"
        />
        {!isRootProject && (
          <div className="filter-presets-inheritance row">
            <span className="col-xs-8 col-sm-5 col-md-4 col-lg-3">
              Inherit configuration from parent project:
            </span>
            <div className="col-sm-4">
              <Toggle
                name="inheritFilterPresets"
                value={isConfigurationInherited || false}
                onChange={onInheritanceChange}
              />
            </div>
          </div>
        )}
        <div className="filter-presets-list row">
          <div className="col-md-3">
            <div className="variant-type-filter row">
              <span className="col-xs-6">Filter preset type:</span>
              <div className="col-xs-6">
                <Dropdown
                  onChange={onVariantTypeChange}
                  options={variantTypeOptions}
                  value={variantTypeFilter}
                />
              </div>
            </div>
            <List
              items={filteredPresets}
              valuePropName={presetValuePropName}
              keyPropName={presetKeyPropName}
              selectedItem={selectedPreset}
              onClick={setSelectedPreset}
              categoryName="variantType"
              isItemHighlighted={isItemHighlighted}
              emptyText="No filter presets to show"
            />
            <Actions
              actions={[
                {
                  action: () => toggleConfirmationModal(true),
                  isDisabled:
                    isNil(selectedPreset) ||
                    (selectedPreset &&
                      (selectedPreset.isDefault ||
                        selectedPreset.isInherited ||
                        isNil(selectedPreset.id))),
                  className: "btn--small",
                  icon: "trash",
                  tooltip: {
                    content: getDeleteBtnTooltip(),
                    placement: "right",
                    container: "span",
                  },
                },
                {
                  className: "btn--small",
                  icon: "plus",
                  tooltip: {
                    content: "Add a new preset",
                    placement: "right",
                    container: "span",
                  },
                  dropdownMenu: [
                    {
                      key: 1,
                      content: "Add SV filter preset",
                      onClick: addSVPreset,
                    },
                    {
                      key: 2,
                      content: "Add SNV filter preset",
                      onClick: addSNVPreset,
                    },
                  ],
                },
              ]}
            />
            <ConfirmationModal
              confirmationText={`Are you sure you want to delete ${
                selectedPreset && selectedPreset[presetValuePropName]
              } filter preset?`}
              showConfirmationModal={showConfirmationModal}
              yesClickHandler={removeFilterPreset}
              closeClickHandler={() => toggleConfirmationModal(false)}
              className="remove-preset-confirm-modal"
            />
          </div>
          <div className="col-md-9 preset-form">
            {selectedPreset && selectedPreset.variantType === SV && (
              <SVPresetForm {...presetFormParams} className="sv-preset-form" />
            )}
            {selectedPreset && selectedPreset.variantType === SNV && (
              <SNVPresetForm
                {...presetFormParams}
                className="snv-preset-form"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

FilterPresetsList.displayName = "FilterPresetsList";

const mapStateToProps = state => ({
  selectedPreset: getSelectedPreset(state),
  isConfigurationInherited: isConfigurationInherited(state),
  isRootProject: isRootProject(state),
  snvPresetDefaults: getSNVPresetDefaults(state),
});

const mapDispatchToProps = {
  setSelectedPreset,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPresetsList);
