import classNames from "classnames";
import { isNil, path } from "ramda";
import { useMemo, useCallback, memo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Icon } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";
import { MAX_ROWS_COUNT } from "pattern-library/modules/data-table";

import {
  readProject,
  readProjectCount,
  readProjectCurrentUser,
} from "modules/project/actions";
import {
  ONCOLOGY_PROJECT_TYPE,
  RARE_DISEASE_PROJECT_TYPE,
} from "modules/projects/constants";

import { filterProjects } from "../selectors";

const getNodeId = ({ attributes: { path } }) => path;

const getParentNodeId = ({ attributes: { path } }) => {
  const array = path.split(".");
  array.pop();
  return array.join(".");
};

function unflatten(arr): Array<any> {
  const tree: Array<any> = [],
    mappedArr = {};
  let arrElem, mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    const id = getNodeId(arrElem);
    mappedArr[id] = arrElem;
    mappedArr[id].children = [];
  }

  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      const parentId = getParentNodeId(mappedElem);
      if (parentId && mappedArr[parentId]) {
        mappedArr[parentId].children.push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

const collect = (nodes, prop, desc) => {
  const projects: Array<any> = [];
  const sortedNodes = nodes.sort((a, b) => {
    if (isNil(desc)) {
      return 0;
    }
    const varA = path(prop, a);
    const varB = path(prop, b);

    if (typeof varA === "string" && typeof varB === "string") {
      return desc ? varB.localeCompare(varA) : varA.localeCompare(varB);
    }

    if (typeof varA === "number" && typeof varB === "number") {
      return desc ? varB - varA : varA - varB;
    }

    return 0;
  });

  sortedNodes.forEach(node => {
    projects.push(node);
    projects.push(...collect(node.children, prop, desc));
  });

  return projects;
};

const createIndentation = (id, depth) =>
  Array(depth)
    .fill(0)
    .map((_, index) => {
      const key = `${id}-${index}`;
      return <span key={key} className="sub-folder-space-indent" />;
    });

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

const getPageSizeOptions = totalProjectsCount => [
  ...DEFAULT_PAGE_SIZE_OPTIONS.filter(option => option < totalProjectsCount),
  MAX_ROWS_COUNT,
];

// If this component is loaded in the legacy app, then the old Sapientia.js code will
// handle adding the active class to rows in the table
// If we add this ourselves, then the (overcomplicated) logic in that file will exclude it from loading
// the content from data attributes
// The legacy JS only needs `update-page` present in the classes, as it will check the hash and the current URL
// and add `.active` if necessary
const activeProjectClass = window.Sapientia ? "update-page" : "active";

type Props = {
  projectId?: number | string;
  projects: Array<Index>;
  readProject: typeof readProject;
  readProjectCount: typeof readProjectCount;
  readProjectCurrentUser: typeof readProjectCurrentUser;
  filterTerm?: string;
  title: string;
};

const ProjectsTable = memo(
  ({
    projectId,
    projects,
    readProject,
    readProjectCount,
    readProjectCurrentUser,
    filterTerm,
    title,
  }: Props) => {
    const history = useHistory();
    const columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "attributes.code",
          Cell: ({
            cell: {
              row: {
                original: {
                  id,
                  children,
                  attributes: { path, code, projectTypeInternalName },
                },
              },
            },
          }) => {
            const depth = path.split(".").length - 1;
            const hasChildren = children && children.length > 0;
            return (
              <span
                className={classNames("sub-folder", {
                  "sub-folder-rare-disease":
                    projectTypeInternalName === RARE_DISEASE_PROJECT_TYPE,
                  "sub-folder-oncology":
                    projectTypeInternalName === ONCOLOGY_PROJECT_TYPE,
                })}
              >
                {createIndentation(id, depth)}
                {hasChildren || projectId === id ? (
                  <Icon type="folderOpen" />
                ) : (
                  <Icon type="folderClose" />
                )}
                <span key={id} className="sub-folder-name">
                  {code}
                </span>
              </span>
            );
          },
        },
        { Header: "Description", accessor: "attributes.description" },
        { Header: "Patients", accessor: "attributes.patientCount" },
        { Header: "Subprojects", accessor: "attributes.subProjectCount" },
      ],
      [projectId]
    );

    const [filterValue, setFilterValue] = useState("");
    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
      setFilteredProjects(filterProjects(projects, filterValue));
    }, [projects, filterValue, setFilteredProjects]);

    const onFilterChange = useCallback(
      ({ filter = "" }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    const pageSizeOptions = useMemo(
      () => getPageSizeOptions(filteredProjects.length),
      [filteredProjects]
    );

    const processProjects = (
      projects: Array<Index>,
      prop?: Array<string> | string | null,
      desc?: boolean | null
    ) => {
      const tree = unflatten([...projects]);
      return collect(tree, prop, desc);
    };

    const [sortedProjects, setSortedProjects] = useState(
      processProjects(filteredProjects)
    );

    const [initialPageIndex, setInitialPageIndex] = useState(0);

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const handleSort = useCallback(
      sortBy => {
        const { id, desc } = sortBy.length === 0 ? ({} as Index) : sortBy[0];
        const prop = id ? [...id.split(".")] : null;
        try {
          const projectsTree = processProjects(filteredProjects, prop, desc);
          setSortedProjects(projectsTree);
        } catch (e) {
          //expected
          setSortedProjects(filteredProjects);
        }
      },
      [filteredProjects, setSortedProjects]
    );

    useEffect(() => {
      const filteredProjectsTree = processProjects(
        filteredProjects,
        "id",
        null
      );

      setSortedProjects(filteredProjectsTree);
    }, [filterTerm, filteredProjects]);

    useEffect(() => {
      if (projectId) {
        readProject(projectId, true);
        readProjectCount(projectId);
        readProjectCurrentUser(projectId);
      }
    }, [projectId, readProject, readProjectCount, readProjectCurrentUser]);

    useEffect(() => {
      if (projectId) {
        const indexOfProject = sortedProjects.findIndex(
          ({ id }) => String(id) === String(projectId)
        );
        const pageOfProject = Math.floor(indexOfProject / pageSize);
        setInitialPageIndex(pageOfProject);
      }
    }, [projectId, sortedProjects, pageSize]);

    const reInitLegacyContent = () => {
      // This is a hacky way to make sure the legacy report templates tab still works properly in the Perl app
      // As the Perl still renders the projects table in isolation sometimes,
      // we need to still occasionally set up the event listeners etc that's handled by `contentLoaded`
      // TODO: Remove the below once we've removed legacy report templates tab
      if (window.Sapientia) {
        // We can "freely" use jQuery in here as we're in perl-application-land
        // The standalone React app should never have Sapientia or jQuery
        const projectsTable = window.jQuery("#react-projects-table");
        window.Sapientia.contentLoaded(projectsTable);
      }
    };

    const getRowProps = useCallback(
      (
        _,
        {
          row: {
            original: { id },
          },
        }
      ) => {
        const isSelectedProject = projectId === id.toString();
        return {
          key: id,
          onClick: () => {
            if (history) {
              history.push(`/projects/${id}/patients`);
            } else {
              window.location.href = `/projects/${id}/patients`;
            }
          },
          className: isSelectedProject ? activeProjectClass : "",
          ...(window.Sapientia && {
            "data-target": "#project-tabs",
            "data-scrolltotarget": "1",
            "data-hash": `my-projects/${id}`,
            "data-href": `project/${id}`,
          }),
        };
      },
      [history, projectId]
    );
    return (
      <Table
        id="react-projects-table"
        className="data-table projects-table"
        columns={columns}
        data={sortedProjects}
        enableFilter
        fetchData={onFilterChange}
        getRowProps={getRowProps}
        showPagination={sortedProjects.length > DEFAULT_PAGE_SIZE}
        pageSizeOptions={pageSizeOptions}
        autoResetPage={false}
        onSort={handleSort}
        manualSortBy
        initialPageIndex={initialPageIndex}
        onPageSizeChange={setPageSize}
        title={title}
        showTitleInfo
        onPageChange={reInitLegacyContent}
        sortBy={[
          {
            id: "attributes.code",
            desc: false,
          },
        ]}
      />
    );
  }
);

ProjectsTable.displayName = "ProjectsTable";

const mapDispatchToProps = {
  readProject,
  readProjectCount,
  readProjectCurrentUser,
};

export default connect(undefined, mapDispatchToProps)(ProjectsTable);
