import classNames from "classnames";
import { withFormik } from "formik";
import { isEmpty, isNil } from "ramda";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import * as yup from "yup";

import { Accordion, Actions } from "pattern-library";

import {
  reloadCurrentProjectCurrentUser,
  readProject,
} from "modules/project/actions";
import { getCurrentProjectTypeInternalName } from "modules/project/selectors";
import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";
import { isOnPrem as isOnPremSelector } from "modules/systemConfig";
import { sanitize } from "modules/utils/objects";

import * as constants from "../constants";
import { submitAndResetFormik, withProjectSettingsWrapper } from "../util";

import InheritanceToggle from "./InheritanceToggle";
import { CustomPatientTab } from "./adminConfiguration/CustomPatientTab";
import { CustomSnvTab } from "./adminConfiguration/CustomSnvTab";
import UploadService from "./adminConfiguration/UploadService";

const regExpMonitoredPath = /^[a-zA-Z0-9_/]*$/;

export const validationSchema = yup.object({
  isInherited: yup.boolean(),
  dataFileUpload: yup.object().when("isInherited", {
    is: false,
    then: yup.object({
      monitoredPath: yup
        .string()
        .nullable(true)
        .test({
          name: "isAcceptedPath",
          message:
            "The string must only contain the following characters: alphanumeric chars (A-Z, a-z, 0-9), underscore (_) and forward slash (/)",
          test(value) {
            const { enabled } = this.parent;
            return !enabled || !value || regExpMonitoredPath.test(value);
          },
        })
        .test("isRequired", "This field is required", function (value) {
          const { enabled } = this.parent;
          return !enabled || !!value;
        }),
      uploadServer: yup
        .string()
        .nullable(true)
        .test("uploadServer", "This field is required", function (value) {
          const { enabled } = this.parent;
          return !enabled || !!value;
        }),
    }),
  }),
});

export const AdminConfiguration = memo(
  ({
    values: { isInherited, dataFileUpload: { enabled = false } = {} },
    submitting,
    dirty,
    handleSubmit,
    submitForm,
    projectId,
    errors,
    touched,
    projectType,
    readProject,
    isOnPrem,
  }) => {
    const touchedErrors = sanitize(errors, touched);

    useEffect(() => {
      readProject(projectId);
    }, [readProject, projectId]);

    const settingsCustomIframe = {
      sectionName: "Custom SNV iframe tab",
      content: <CustomSnvTab isInherited={isInherited} />,
      initiallyOpen: true,
    };
    const settingsPatientIframe = {
      sectionName: "Custom Patient iframe tab",
      content: <CustomPatientTab isInherited={isInherited} />,
      initiallyOpen: true,
    };
    const settingsUploadService = {
      sectionName: "Upload Service",
      content: <UploadService isInherited={isInherited} enabled={enabled} />,
      initiallyOpen: true,
      showError: touchedErrors && !isNil(touchedErrors.dataFileUpload),
    };
    const isOncologyProject = projectType === ONCOLOGY_PROJECT_TYPE;

    return (
      <form onSubmit={handleSubmit} className="form-horizontal clearfix">
        <InheritanceToggle submitForm={submitForm} projectId={projectId} />
        <div className="row">
          {/*We should show Custom SNV and Custom Patient iframe tabs for container
            projects. These projects do not have project type. The root project is container*/}
          {!isOncologyProject && (
            <Accordion
              className="col-md-6"
              data={[settingsCustomIframe, settingsPatientIframe]}
            />
          )}
          {!isOnPrem && (
            <Accordion className="col-md-6" data={[settingsUploadService]} />
          )}
        </div>
        <Actions
          sticky
          className={classNames({
            "col-md-6": isOncologyProject,
          })}
          actions={[
            {
              label: submitting ? "Submitting" : "Save",
              disabled: isInherited || !dirty || submitting,
              className: classNames("btn", {
                "btn-primary": !submitting,
                "btn-info": submitting,
              }),
              type: "submit",
            },
          ]}
        />
      </form>
    );
  }
);

const prepareDataFileUpload = values => {
  const {
    dataFileUpload: { enabled },
  } = values;

  let {
    dataFileUpload: { monitoredPath = "", uploadServer = "" },
  } = values;

  if (!enabled) {
    if (
      isEmpty(monitoredPath) ||
      (!!monitoredPath && !regExpMonitoredPath.test(monitoredPath))
    ) {
      monitoredPath = null;
    }
    if (isEmpty(uploadServer)) {
      uploadServer = null;
    }
  }
  return {
    enabled,
    monitoredPath,
    uploadServer,
  };
};

const submitRareDiseaseAdminConfiguration = (values, formikBag) => {
  const {
    customSnvTab: { display: customSnvTabDisplay },
    customPatientTab: { display: customPatientTabDisplay },
  } = values;

  let {
    customSnvTab: { label: customSnvTabLabel, url: customSnvTabUrl },
    customPatientTab: {
      label: customPatientTabLabel,
      url: customPatientTabTabUrl,
    },
  } = values;

  if (!customSnvTabDisplay) {
    customSnvTabLabel = "";
    customSnvTabUrl = "";
  }

  if (!customPatientTabDisplay) {
    customPatientTabLabel = "";
    customPatientTabTabUrl = "";
  }

  submitAndResetFormik(
    {
      ...values,
      customSnvTab: {
        display: customSnvTabDisplay,
        label: customSnvTabLabel,
        url: customSnvTabUrl,
      },
      customPatientTab: {
        display: customPatientTabDisplay,
        label: customPatientTabLabel,
        url: customPatientTabTabUrl,
      },
      dataFileUpload: prepareDataFileUpload(values),
    },
    formikBag
  );
};

const submitOncologyAdminConfiguration = (values, formikBag) => {
  submitAndResetFormik(
    {
      ...values,
      dataFileUpload: prepareDataFileUpload(values),
    },
    formikBag
  );
};

const submitAdminConfiguration = (values, formikBag) => {
  const {
    props: { projectType },
  } = formikBag;
  if (projectType === ONCOLOGY_PROJECT_TYPE) {
    return submitOncologyAdminConfiguration(values, formikBag);
  }
  return submitRareDiseaseAdminConfiguration(values, formikBag);
};

const mapStateToProps = state => ({
  projectType: getCurrentProjectTypeInternalName(state),
  isOnPrem: isOnPremSelector(state),
});

const mapDispatchToProps = {
  readProject,
  onSuccessUpdate: reloadCurrentProjectCurrentUser,
};

const FormikForm = withFormik({
  displayName: constants.ADMIN_CONFIG_FORM,
  mapPropsToValues: ({ initialValues }) => initialValues,
  validationSchema,
  handleSubmit: submitAdminConfiguration,
})(AdminConfiguration);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withProjectSettingsWrapper({
    component: FormikForm,
    path: "admin",
    entityType: "admin_config_settings",
  })
);
