import classNames from "classnames";
import React, { useState, memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Button } from "pattern-library";

import { fetchGenePanels } from "modules/projectGenePanels/actions";

import GenePanelsTable from "../../GenePanelsTable";

import congenicaApi from "api/congenica-api";
import legacyApi from "api/legacy-api";

const { useGetThirdPartyDataVersionsQuery } = congenicaApi;
const {
  useGetExistingGenePanelsForProjectQuery,
  useAddExistingGenePanelToProjectMutation,
} = legacyApi;

const Actions: React.FC<{
  projectId: number;
  genePanel: CopyProjectGenePanel;
}> = ({ projectId, genePanel }) => {
  // useDispatch to trigger the reload of project gene panels, as this is a combination
  // of RTK query and old saga logic
  // When project gene panels have also been moved to RTK query, this can be handled by tag invalidation instead
  const dispatch = useDispatch();
  const [added, setAdded] = useState(false);

  const [addGenePanel, { isLoading: isGenePanelAdding }] =
    useAddExistingGenePanelToProjectMutation();

  const { genePanelId } = genePanel;

  const addClickHandler = useCallback(async () => {
    await addGenePanel({ projectId, genePanelId }).unwrap();
    setAdded(true);
    dispatch(fetchGenePanels.start(projectId));
  }, [addGenePanel, projectId, genePanelId, dispatch]);

  if (isGenePanelAdding) return <>Adding gene panel</>;

  return (
    <>
      {added ? (
        <em>Gene panel added</em>
      ) : (
        <Button context="primary" onClick={addClickHandler}>
          Add gene panel
        </Button>
      )}
    </>
  );
};

type Props = {
  projectId: number;
};

const CopyExistingPanelTab: React.FC<Props> = memo(({ projectId }) => {
  const { data: ensemblVersions = [], isLoading: ensemblVersionsLoading } =
    useGetThirdPartyDataVersionsQuery({
      name: "ENSEMBL",
    });

  const { data, isLoading } = useGetExistingGenePanelsForProjectQuery({
    projectId,
  });

  const genePanels = useMemo(() => data?.genePanels || [], [data?.genePanels]);
  const writePermissions = data?.writePermissions;

  const columns = useMemo(
    () =>
      [
        {
          Header: "Gene panel",
          accessor: "title",
        },
        ensemblVersions.length > 1 && {
          Header: "Ensembl Version",
          accessor: "ensemblVersion",
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: "Total genes",
          accessor: "genesCount",
        },
        writePermissions && {
          Header: "Actions",
          id: "actions",
          width: 124,
          accessor: (genePanel: CopyProjectGenePanel) => (
            <Actions projectId={projectId} genePanel={genePanel} />
          ),
        },
      ].filter(Boolean),
    [ensemblVersions.length, writePermissions, projectId]
  );

  return (
    <GenePanelsTable
      columns={columns}
      ensemblVersions={ensemblVersions}
      genePanels={genePanels}
      isLoading={isLoading || ensemblVersionsLoading}
      title="Gene panel templates"
      id="existingGenePanels"
      showTitleInfo
      className={classNames({
        "copy-existing-gene-panels-table": genePanels.length === 0,
      })}
      sortBy={[
        {
          id: "title",
          desc: false,
        },
      ]}
    />
  );
});

export default CopyExistingPanelTab;
