import type { Action } from "./actions";
import * as constants from "./constants";
import data, {
  AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
  CUSTOM_PENETRANCE_KEY,
  CUSTOM_TIER_KEY,
} from "./reducer.data";
import { PatientEntityPayload, State, ConfigValues } from "./types";

export const initialState = {
  data: {
    values: {
      filters: {
        vepConsequence: [],
        zygosity: [],
        genes: [],
        coords: "",
        genePanels: [],
        inheritance: [],
        moi: [],
        denovoStatus: [],
        familyComparisons: [],
        curatedListKnowledgebase: [],
        curatedListWhitelist: [],
        curatedListBlacklist: [],
        [CUSTOM_TIER_KEY]: [],
        [CUSTOM_PENETRANCE_KEY]: [],
        [AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY]: [],
      },
      prioritisation: [],
      variantColumns: [],
      consequenceColumns: [],
    },
    defaults: data,
  },
  patient: {
    loading: false,
    data: undefined,
  },
  vepConsequences: {
    loading: false,
    data: [],
  },
};

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  try {
    switch (action.type) {
      case constants.UPDATE_CONFIG_VALUES:
        const values: ConfigValues = action.payload as ConfigValues;
        const {
          filters = {},
          variantColumns = [],
          consequenceColumns = [],
          prioritisation = [],
        } = values;
        return {
          ...state,
          data: {
            ...state.data,
            values: {
              ...state.data.values,
              filters: {
                ...initialState.data.values.filters,
                ...filters,
              },
              variantColumns,
              consequenceColumns,
              prioritisation,
            },
          },
        };
      case constants.FETCH_PATIENT_CONFIG_ENTITY_START:
        return {
          ...state,
          patient: {
            ...state.patient,
            loading: true,
          },
        };
      case constants.FETCH_PATIENT_CONFIG_ENTITY_FAILURE:
        return {
          ...state,
          patient: {
            ...state.patient,
            data: undefined,
            loading: false,
          },
        };
      case constants.FETCH_PATIENT_CONFIG_ENTITY_SUCCESS:
        const { payload } = action as { payload: PatientEntityPayload };
        return {
          ...state,
          patient: {
            ...state.patient,
            data: payload.data,
            loading: false,
          },
        };
      default:
        return state;
    }
  } catch (error) {
    console.log(error);
    return state;
  }
}
