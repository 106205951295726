import { bindActionCreators } from "@reduxjs/toolkit";
import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import { curry } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { VariantColumn } from "pattern-library";

import { toggleVariantExpanded } from "../../actions";

import * as ColumnComponents from ".";

export class ConsequencesTable extends PureComponent {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  toggleExpanded(e) {
    e.stopPropagation();
    const { toggleVariantExpanded, variant } = this.props;
    toggleVariantExpanded(variant.variantId);
  }

  render() {
    const {
      variant: { variantId, visibleTranscripts = [], isExpanded },
      columns = [],
      header,
    } = this.props;

    if (!columns.length) {
      return null;
    }

    const decision = visibleTranscripts.find(
      transcript => transcript.isDecisionTranscript
    );
    const transcriptRows = visibleTranscripts.map(transcript => {
      const renderedCols = columns.map(column => {
        const { key, component, ...columnProps } = column;
        if (component && ColumnComponents[component]) {
          const Component = ColumnComponents[component];

          return (
            <Component
              key={column.key}
              className={`variant-column variant-column--${key}`}
              {...columnProps}
              transcript={transcript}
            />
          );
        }
        return (
          <VariantColumn
            key={column.key}
            {...column}
            colKey={column.key}
            data={transcript}
          />
        );
      });

      return (
        <div
          key={transcript.transcriptId}
          className={classNames("variant-column-consequences-row", {
            "variant-column-consequences-row--bold": decision
              ? transcript.isDecisionTranscript
              : transcript.isPreferred,
          })}
        >
          {renderedCols}
        </div>
      );
    });

    const footer = isExpanded ? (
      <div
        data-variant-id={variantId}
        onClick={this.toggleExpanded}
        className="variant-column-consequences-footer"
      >
        <span className="glyphicon glyphicon-triangle-top" />
        Hide alternative transcripts
      </div>
    ) : (
      <div
        data-variant-id={variantId}
        onClick={this.toggleExpanded}
        className="variant-column-consequences-footer"
      >
        <span className="glyphicon glyphicon-triangle-bottom" />
        Show alternative transcripts
      </div>
    );

    return (
      <div className={`${header.toLowerCase().replace(" ", "_")}`}>
        {transcriptRows}
        {footer}
      </div>
    );
  }
}

const bindDispatch = curry(bindActionCreators);

const mapDispatchToProps = bindDispatch({
  toggleVariantExpanded,
});

export default connect(null, mapDispatchToProps)(ConsequencesTable);
