//Idea based on article https://blog.logrocket.com/multipart-uploads-s3-node-js-react/#multipart-upload-in-react

import classNames from "classnames";
import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Icon } from "pattern-library";

import * as messageActions from "modules/messages/actions";

import styles from "./FileUploader.module.scss";
import { changeProgress, changeStatus, setFile } from "./actions";
import reducer, { initialState } from "./reducer";
import { Progress, STATUS, UploaderProps } from "./types";
import { createUploader } from "./uploaders/utils";

interface Props extends PropsFromRedux {
  file: File;
  label?: string;
  fileIsNotSelectedLabel?: string;
  uploaderProps: UploaderProps;
}

const STATUS_PRESENTATION = {
  [STATUS.EMPTY]: {
    type: "hourglassHalf",
    className: classNames(styles.fileUploader_file_icon),
  },
  [STATUS.UPLOADED]: {
    type: "check2",
    className: classNames("icon-success", styles.fileUploader_file_icon),
  },
  [STATUS.CORRUPTED]: {
    type: "exclamationSign",
    className: classNames("icon-error", styles.fileUploader_file_icon),
  },
  [STATUS.UPLOADING]: {
    type: "spinner",
    className: classNames(styles.fileUploader_file_icon),
  },
  [STATUS.SELECTED]: {
    type: "file",
    className: classNames(styles.fileUploader_file_icon),
  },
};

export const FileUploader: FC<Props> = ({
  file,
  label,
  fileIsNotSelectedLabel,
  error,
  uploaderProps,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onStart = useCallback(
    () => dispatch(changeStatus(STATUS.UPLOADING)),
    [dispatch]
  );

  const onProgress = useCallback(
    ({ percentage }: Progress) => dispatch(changeProgress(percentage)),
    [dispatch]
  );

  const onError = useCallback(
    ({ message }: Error) => {
      error(message);

      dispatch(changeStatus(STATUS.CORRUPTED));
    },
    [dispatch, error]
  );

  const onSuccess = useCallback(
    () => dispatch(changeStatus(STATUS.UPLOADED)),
    [dispatch]
  );

  useEffect(() => {
    if (state.file !== file) {
      dispatch(setFile(file ? file : null));
      if (file) {
        const uploader = createUploader({
          ...uploaderProps,
          file,
          onStart,
          onProgress,
          onError,
          onSuccess,
        });
        uploader.start();
      }
    }
  }, [
    state.file,
    file,
    uploaderProps,
    onStart,
    onProgress,
    onError,
    onSuccess,
  ]);

  const fileName = useMemo(() => {
    if (file) {
      if (label) {
        return label;
      } else {
        return file.name;
      }
    } else {
      if (fileIsNotSelectedLabel) {
        return fileIsNotSelectedLabel;
      } else {
        return "File is not selected";
      }
    }
  }, [file, label, fileIsNotSelectedLabel]);

  return (
    <div>
      <div className={styles.fileUploader_file}>
        <Icon
          data-testid="s3-uploader-icon"
          {...STATUS_PRESENTATION[state.status]}
        />
        <span
          data-testid="s3-uploader-file-name"
          className={styles.fileUploader_file_name}
        >
          {fileName}
        </span>
      </div>
      {state.status === STATUS.UPLOADING && (
        <div className={styles.fileUploader_progress}>
          Uploading
          <progress
            data-testid="s3-uploader-progress"
            className={styles.fileUploader_progress_component}
            id="file"
            value={state.progress}
            max="100"
          >
            {state.progress}
          </progress>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  error: messageActions.error,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FileUploader);
