import { trim } from "ramda";
import React, { memo, useCallback, useContext } from "react";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

import { SamplesTableContext } from "./table/SamplesTable";

/**
 If the family name is being changed for one sample in a family it should be reflected for the rest of the family.
 */
export const FamilyNameField = memo(props => {
  const {
    field: { name },
    form: { setFieldValue, setFieldTouched },
  } = props;
  //name is a string like "samples.2.familyName"
  //and we need to parse index as number
  const index = Number.parseInt(name.split(".")[1]);
  const { relativesPerSamples } = useContext(SamplesTableContext);

  const onChange = useCallback(
    e => {
      const {
        target: { value: targetValue },
      } = e;
      const family = relativesPerSamples[index];
      family.forEach(sampleIndex => {
        const fieldName = `samples.${sampleIndex}.familyName`;
        setFieldValue(fieldName, trim(targetValue));
        setFieldTouched(fieldName, true, true);
      });
    },
    [index, setFieldValue, relativesPerSamples, setFieldTouched]
  );
  return (
    <FormikFormField onChange={onChange} changeOnBlur {...props} trimOnBlur />
  );
});
