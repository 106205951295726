import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Rule from "./Rule";

export default class Rules extends PureComponent {
  static propTypes = {
    ruleSet: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        pass: PropTypes.bool.isRequired,
      })
    ).isRequired,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
  };

  render() {
    const { className, ruleSet, ...otherProps } = this.props;

    return (
      <ul className={classNames("rules", className)} {...otherProps}>
        {ruleSet.map(rule => (
          <Rule
            key={rule.description}
            description={rule.description}
            pass={rule.pass}
          />
        ))}
      </ul>
    );
  }
}
