import { useEffect } from "react";
import { connect } from "react-redux";

import { ComposableTabs, Tab } from "pattern-library";

import { ARIADNE_STRINGS } from "modules/ariadne/constants";
import { fetchPatientEntity } from "modules/config/actions";
import * as patientSelectors from "modules/patient/selectors";
import { GNOMAD_EXOMES_FEATURE } from "modules/project/constants";
import * as projectSelectors from "modules/project/selectors";

import { AUTO_ACMG_TAB } from "../constants";
import * as selectors from "../selectors";

import AriadneFilterForm from "./forms/AriadneFilterForm";
import AutoAcmgForm from "./forms/AutoAcmgForm";
import ConsequencesForm from "./forms/ConsequencesForm";
import CustomDataForm from "./forms/CustomDataForm";
import GenesLocationForm from "./forms/GenesLocationForm";
import InheritanceForm from "./forms/InheritanceForm";
import PopulationAcForm from "./forms/PopulationAcForm";
import PopulationFrequencyForm from "./forms/PopulationFrequencyForm";
import QualityForm from "./forms/QualityForm";
import ScoresForm from "./forms/ScoresForm";
import ZygosityForm from "./forms/ZygosityForm";

type Props = {
  projectId: number | string;
  /**
   * if patient is selected for analysis, the property should be present
   */
  patientId: number;
  /**
   * fields onChange callback supplied by the enclosing form
   */
  change: () => void;
  /**
   * dispatch method to fetch Patient Entity data
   */
  fetchPatientEntity: typeof fetchPatientEntity.start;
  /**
   * the enclosing redux form name
   */
  form: string;
  /**
   * if true, the underlying form fields should not be editable
   */
  disabled?: boolean;
  /**
   * indicates if custom data form should be displayed
   */
  isCustomDataTabEnabled: boolean;
  filterCategoriesWithValues: Record<
    | "ARIADNE"
    | "Genes Location"
    | "Population frequency"
    | "Consequence"
    | "Zygosity"
    | "Scores"
    | "Inheritance"
    | "Quality"
    | "Custom",
    boolean
  >;
  filterCategoriesWithErrors: Record<
    | "Genes Location"
    | "Scores"
    | "Population frequency"
    | "Population allele count",
    boolean
  >;
  isAutoAcmgTabEnabled: boolean;
  isAriadneEnabledForProject: boolean;
  isAriadneEnabledForPatient: boolean;
  patientFeatureDataFields: Record<string, any>;
};

type FilterTab = {
  name: string;
  contentComponent: React.ComponentType<any>;
  id?: string;
  label?: string;
  enabled?: boolean;
  disabled?: boolean;
  props?: any;
};

const getFilterTabs = (props: Props) => {
  const {
    form,
    disabled,
    filterCategoriesWithValues,
    filterCategoriesWithErrors,
    projectId,
    patientId,
    change,
    isCustomDataTabEnabled,
    isAutoAcmgTabEnabled,
    isAriadneEnabledForProject,
    isAriadneEnabledForPatient,
    patientFeatureDataFields = {},
  } = props;

  const isGnomadAcDataEnabledForPatient =
    patientFeatureDataFields[GNOMAD_EXOMES_FEATURE]?.length > 0;

  // If we don't have a patient ID, then we're rendering in the filter presets view,
  // so in this case we can just depend on the feature flag value instead
  const isAriadneEnabled = patientId
    ? isAriadneEnabledForProject && isAriadneEnabledForPatient
    : isAriadneEnabledForProject;

  const isGnomadAcEnabled = patientId ? isGnomadAcDataEnabledForPatient : true;

  const tabs: FilterTab[] = [
    {
      id: "ARIADNE",
      name: ARIADNE_STRINGS.ARIADNE,
      label: "New!",
      contentComponent: AriadneFilterForm,
      enabled: isAriadneEnabled,
    },
    {
      name: "Genes Location",
      contentComponent: GenesLocationForm,
      props: { patientId, form, change },
    },
    {
      name: "Population frequency",
      contentComponent: PopulationFrequencyForm,
      props: { patientId },
    },
    {
      name: "Population allele count",
      contentComponent: PopulationAcForm,
      enabled: isGnomadAcEnabled,
    },
    {
      name: "Consequence",
      contentComponent: ConsequencesForm,
      props: { change, patientId },
    },
    {
      name: "Zygosity",
      contentComponent: ZygosityForm,
    },
    {
      name: "Scores",
      contentComponent: ScoresForm,
      props: { patientId },
    },
    {
      name: "Inheritance",
      contentComponent: InheritanceForm,
      props: { form, change },
    },
    {
      name: "Quality",
      contentComponent: QualityForm,
      props: { projectId, form },
    },
    {
      name: AUTO_ACMG_TAB,
      contentComponent: AutoAcmgForm,
      enabled: isAutoAcmgTabEnabled,
    },
    {
      name: "Custom",
      contentComponent: CustomDataForm,
      enabled: isCustomDataTabEnabled,
    },
  ].filter(tab => !tab.hasOwnProperty("enabled") || tab.enabled);

  return tabs.map(({ id, name, label, contentComponent: Component, props }) => {
    const hasValue = filterCategoriesWithValues[id || name];
    const hasError = filterCategoriesWithErrors[id || name];
    return (
      <Tab
        key={name}
        name={name}
        label={label}
        hasValue={hasValue}
        hasError={hasError}
      >
        <Component {...props} disabled={disabled} />
      </Tab>
    );
  });
};

export const Filters = (props: Props) => {
  const { patientId, fetchPatientEntity } = props;

  useEffect(() => {
    if (patientId) {
      // this data required for PopulationFrequencyForm component
      fetchPatientEntity(patientId);
    }
  }, [patientId, fetchPatientEntity]);

  return (
    <ComposableTabs navStyle={false} stacked>
      {getFilterTabs(props)}
    </ComposableTabs>
  );
};

const mapStateToProps = (state, props) => ({
  isCustomDataTabEnabled: selectors.isCustomDataTabEnabled(state, props),
  isAutoAcmgTabEnabled: selectors.isAutoAcmgTabEnabled(state),
  isAriadneEnabledForProject:
    projectSelectors.isAriadnePredictionActiveOnCurrentProject(state),
  isAriadneEnabledForPatient:
    patientSelectors.isAriadnePredictionsDataAvailable(state),
  patientFeatureDataFields: patientSelectors.patientFeatureDataFields(state),
  filterCategoriesWithValues: selectors.getFilterCategoriesWithValues(
    state,
    props.form
  ),
  filterCategoriesWithErrors: selectors.getFilterCategoriesWithErrors(
    state,
    props.form
  ),
});

const mapDispatchToProps = {
  fetchPatientEntity: fetchPatientEntity.start,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
