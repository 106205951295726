import React from "react";
import { connect } from "react-redux";

import { Tooltip } from "pattern-library";

import { SuggestionBanner } from "modules/ariadne/components/SuggestionBanner";
import * as configActions from "modules/config/actions";
import * as configConstants from "modules/config/constants";
import * as configSelectors from "modules/config/selectors";
import SNVPresetsContainer from "modules/sequenceVariantsPresets/SNVPresetsContainer";

import * as actions from "../actions";
import * as selectors from "../selectors";

import { AriadneFilterSidebar } from "./AriadneFilterSidebar";
import FilterSection from "./FilterSection";
import GeneSidePanel from "./GeneSidePanel";
import { GenomicRegionFilterSidebar } from "./GenomicRegionFilterSidebar";
import ResetIcon from "./ResetIcon";
import { SpecificGenesFilterSidebar } from "./SpecificGenesFilterSidebar";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

type Props = {
  patientId: number;
  location: "coords" | "panels" | "genes";
  specificGenes?: any[];
  ariadnePredictions?: any;
  coords?: string;
  activeGenePanels?: any[];
  filters: any[];
  hasAriadneFilters?: boolean;
  selectContextForVariantPanel: any;
  collapseSidebar: any;
  isAriadneEnabled?: boolean;
  isAnyAriadneFilterActive?: boolean;
  applyAriadneSuggestions: any;
  emptyGenesList?: boolean;
  selectedPrioritisation?: any;
  showModal?: any;
  ensemblVersion?: string;
};

export const FilterSidebar: React.FC<Props> = ({
  patientId,
  location,
  specificGenes = [],
  ariadnePredictions,
  coords = "",
  activeGenePanels,
  filters,
  hasAriadneFilters,
  selectContextForVariantPanel,
  collapseSidebar,
  isAriadneEnabled,
  isAnyAriadneFilterActive,
  applyAriadneSuggestions,
  emptyGenesList,
  selectedPrioritisation,
  showModal,
  ensemblVersion,
}) => {
  const applySuggestions = () => applyAriadneSuggestions(patientId);
  return (
    <div className="filter-sidebar">
      <div className="filter-sidebar-title">
        <p className="filter-sidebar-title-text">Configuration</p>
        <div className="filter-sidebar-title-icons">
          <Tooltip content="Collapse" placement="right" trigger="mouseenter">
            <span
              onClick={() => collapseSidebar()}
              className="glyphicon glyphicon-chevron-left"
            />
          </Tooltip>
          <ResetIcon patientId={patientId} />
          <Tooltip content="Edit config" placement="right" trigger="mouseenter">
            <span className="glyphicon glyphicon-cog" onClick={showModal} />
          </Tooltip>
        </div>
      </div>

      {!emptyGenesList && isAriadneEnabled && !isAnyAriadneFilterActive && (
        <SuggestionBanner
          applyFilters={applySuggestions}
          className="suggestion-banner"
        />
      )}

      <SNVPresetsContainer patientId={patientId} />
      {hasAriadneFilters ? (
        <div>
          <h2 className="filter-section-header">
            Congenica AI prediction filters
          </h2>
          <AriadneFilterSidebar predictions={ariadnePredictions} />
        </div>
      ) : null}
      {location === "genes" && specificGenes.length ? (
        <div>
          <h2 className="filter-section-header">Showing variants in</h2>
          <SpecificGenesFilterSidebar genes={specificGenes} />
        </div>
      ) : null}
      {location === "coords" && coords ? (
        <div>
          <h2 className="filter-section-header">Showing variants in</h2>
          <GenomicRegionFilterSidebar coords={coords} />
        </div>
      ) : null}
      {location === "panels" && activeGenePanels?.length ? (
        <div>
          <h2 className="filter-section-header">
            Gene Panels
            {ensemblVersion && (
              <small style={{ fontSize: "85%" }}>
                {" "}
                (Ensembl version {ensemblVersion})
              </small>
            )}
          </h2>
          {activeGenePanels.map(panel => (
            <GeneSidePanel
              panel={panel}
              key={panel.genePanelId}
              patientId={patientId}
              selectContextForVariantPanel={selectContextForVariantPanel}
            />
          ))}
        </div>
      ) : null}
      {filters.map(section => (
        <FilterSection section={section} key={section.label} />
      ))}
      <FilterSection section={selectedPrioritisation} />
    </div>
  );
};

const ApiConnectedFilterSidebar: React.FC<Props> = props => {
  const { ensemblVersion } = useEnsemblVersion(props.patientId);
  return <FilterSidebar {...props} ensemblVersion={ensemblVersion} />;
};

const mapStateToProps = (state, props) => ({
  activeGenePanels: selectors.getActiveGenePanelsForProject(state),
  filters: selectors.getFiltersToShowInSidebar(state, props.patientId),
  selectedPrioritisation:
    configSelectors.getActivePrioritisationValuesSection(state),
  ariadnePredictions: configSelectors.getAriadnePredictions(state),
  specificGenes: configSelectors.getSpecificGenes(state),
  hasAriadneFilters: configSelectors.hasAriadneFilters(state),
  location: configSelectors.getFiltersLocation(state),
  coords: configSelectors.getCoords(state),
  isAriadneEnabled: configSelectors.isAriadneEnabled(state),
  isAnyAriadneFilterActive: configSelectors.getFilterCategoriesWithValues(
    state,
    configConstants.SNV_TABLE_CONFIG_FORM
  ).ARIADNE,
  emptyGenesList: !selectors.getCurrentVisibleGenes(state).length,
});

const mapDispatchToProps = {
  applyAriadneSuggestions: configActions.applyAriadneSuggestions,
  selectContextForVariantPanel: actions.selectContextForVariantPanel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiConnectedFilterSidebar);
