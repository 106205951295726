import PropTypes from "prop-types";
import { always } from "ramda";
import React, { PureComponent } from "react";

import { Icon } from "../base";

import Input from "./Input";

export default class InputGroup extends PureComponent {
  static displayName = "InputGroup";

  static propTypes = {
    /**
     * Class to apply to the container div
     */
    containerClass: PropTypes.string,
    /**
     * Text which is displayed to the left, either this field or icon is required
     */
    prefix: PropTypes.string,
    /**
     * Icon which displays on the left, see the Icon component
     *
     * Either this field or prefix is required
     */
    icon: PropTypes.string,
    /**
     * Sets if there is a submit button in the addon span, default value is false
     */
    hasButton: PropTypes.bool,
    /**
     * Button click handler
     */
    onButtonClick: PropTypes.func,
  };

  static defaultProps = {
    containerClass: "",
    hasButton: false,
    onButtonClick: always(),
  };

  render() {
    const {
      prefix,
      icon,
      containerClass = "",
      hasButton,
      onButtonClick,
      ...otherProps
    } = this.props;

    let addon = null;

    if (prefix) {
      addon = prefix;
    } else if (icon) {
      addon = <Icon type={icon} />;
    } else {
      throw new Error("Input group requires some content to prefix with");
    }

    return (
      <div className={`input-group ${containerClass}`}>
        {hasButton ? (
          <span className="input-group-btn">
            <button
              className="btn btn-default"
              type="submit"
              onClick={onButtonClick}
            >
              {addon}
            </button>
          </span>
        ) : (
          <span className="input-group-addon">{addon}</span>
        )}
        <Input {...otherProps} />
      </div>
    );
  }
}
