import React, { memo } from "react";

import { LegacyLink } from "pattern-library";

import { AminoAcidProps } from "../types";

const AminoAcid = ({ value }: AminoAcidProps) => {
  if (!value || value.length > 3 || value.match(/[Xx*]/) || value === "-") {
    return <span>{value}</span>;
  }

  const href =
    value.indexOf("/") >= 0 ? `/aa/compare/${value}` : `/aa/single/${value}`;

  return <LegacyLink href={href}>{value}</LegacyLink>;
};

export default memo(AminoAcid);
