import Genoverse from "genoverse";

import { limitValue } from "../utils";

// Tracks contains a lot data points, use for instead forEach for performance
// TODO: Change testing approach/track structure so we don't export twice here just for the purpose of tests
export const _View = {
  constructor(properties) {
    this.calcMinVisibleScatterSize();
    this.base(properties);
  },

  // The way browsers render sub-pixels differ, also an environment affect it.
  // https://jira.congenica.net/secure/attachment/35642/example.png
  calcMinVisibleScatterSize() {
    const canvas = document.createElement("canvas");
    canvas.width = 10;
    canvas.height = 10;
    const ctx = canvas.getContext("2d");

    this.minScatterSize = 1;

    for (let i = 1; i < 10; i++) {
      const scatterSizeToTest = i / 10;
      ctx.beginPath();
      // `x` and `y` are not integers in more than 99.99% cases when track is zoomed out.
      ctx.arc(5.5, 5.5, scatterSizeToTest, 0, 2 * Math.PI);
      ctx.fill();

      if (this.isDotClearlyVisible(ctx)) {
        this.minScatterSize = scatterSizeToTest;
        break;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  },

  isDotClearlyVisible(ctx) {
    const MIN_OPACITY_FOR_SINGLE_PIXEL = 150;
    const MIN_AVERAGE_OPACITY_FOR_SEVERAL_PIXELS = 50;

    // Color is `#000`, so only "opacity" elements in the `idata.data` may be greater than `0` here.
    const idata = ctx.getImageData(0, 0, 10, 10);
    // It looks like old browsers don't have `TypedArray.prototype.filter()` and other Array methods.
    const opacityValues = Array.prototype.filter.call(idata.data, v => v);

    // At least one pixel is opaque enough.
    if (opacityValues.some(v => v >= MIN_OPACITY_FOR_SINGLE_PIXEL)) {
      return true;
    }

    return (
      opacityValues.length > 1 &&
      opacityValues.reduce((acc, v) => acc + v) / opacityValues.length >
        MIN_AVERAGE_OPACITY_FOR_SEVERAL_PIXELS
    );
  },

  positionFeatures(features, params) {
    const { scale, scaledStart } = params;
    const add = (scale > 1 ? scale / 2 : 0) - scaledStart;
    const yScale = this.track.getYScale();
    const range = this.prop("range");
    const height = this.prop("height");
    const capValues = Boolean(this.prop("capValues"));
    const min = Math.min(...range);
    const max = Math.max(...range);
    const invertMin = min - min * 2;
    const zeroY = height * (invertMin / (max + invertMin));
    params.margin = 0;

    for (let i = 0; i < features.length; i++) {
      const { start, end, value } = features[i];
      const x = start + (start === end ? 0 : (end - start + 1) / 2);
      const y = !capValues ? value : limitValue(min, max, value);
      features[i].coordPositions = [x * scale + add, y * yScale + zeroY];
      this.positionFeature(features[i], params);
    }

    return features;
  },

  draw(features, featureContext) {
    const { colors } = this.track;
    let { scatterSize } = this.track;
    if (this.minScatterSize > scatterSize) {
      scatterSize = this.minScatterSize;
    }

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const [x, y] = feature.coordPositions;
      const { color } = colors.find(({ predicate }) => predicate(feature));
      featureContext.fillStyle = color;
      featureContext.beginPath();
      featureContext.arc(x, y, scatterSize, 0, 2 * Math.PI);
      featureContext.closePath();
      featureContext.fill();
    }
  },
};

export const View = Genoverse.Track.View.Graph.extend(_View);
