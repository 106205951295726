import { Field, useFormikContext } from "formik";
import React, { useMemo } from "react";
import { connect } from "react-redux";

import { Divider } from "pattern-library";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";
import { AUTO_ACMG_FEATURE } from "modules/project/constants";
import { isProjectFeatureActive } from "modules/project/selectors";

export const Decisions = ({ isInherited, isAutoAcmgFeatureActive }) => {
  const {
    values: { acmg: { enabled: acmgEnabled } = {} },
  } = useFormikContext();

  const acmgTooltip = useMemo(() => {
    if (!isInherited && acmgEnabled && isAutoAcmgFeatureActive) {
      return "Disable Auto-ACMG suggested criteria on Automation tab before disabling ACMG Evidence";
    }
    return "Enable ACMG evidence tab";
  }, [isInherited, acmgEnabled, isAutoAcmgFeatureActive]);

  return (
    <div className="form-horizontal">
      <Field
        name="doubleUserCheck.enabled"
        component={FormikFormField}
        label="Double user check"
        narrow={9}
        title="Enable double-user check"
        disabled={isInherited}
        description="Approval process for pathogenicity and contribution to phenotype assignments on variants"
        type="checkbox"
      />
      <Divider />
      <Field
        name="acmg.enabled"
        component={FormikFormField}
        label="ACMG evidence tab"
        narrow={9}
        title={acmgTooltip}
        disabled={isInherited || isAutoAcmgFeatureActive}
        description="Record evidence criteria as recommended by ACMG Guidelines for the interpretation of sequence variants"
        type="checkbox"
        data-testid="acmgEvidenceTab"
      />
    </div>
  );
};

Decisions.displayName = "Decisions";

const mapStateToProps = state => ({
  isAutoAcmgFeatureActive: isProjectFeatureActive(state, AUTO_ACMG_FEATURE),
});

const ConnectedWrapper = connect(mapStateToProps, undefined)(Decisions);

export default ConnectedWrapper;
