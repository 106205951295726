import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class ModalFooter extends PureComponent {
  static displayName = "ModalFooter";

  static propTypes = {
    /**
     * Class to apply to the modal-footer div
     */
    className: PropTypes.string,
    /**
     * Content to render inside the modal footer
     */
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children, ...otherProps } = this.props;

    return (
      <div {...otherProps} className={classNames("modal-footer", className)}>
        {children}
      </div>
    );
  }
}
