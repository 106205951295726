// @flow
import { react as autoBind } from "auto-bind";
import { not, isNil, is } from "ramda";

import EOF from "../EOF";

import SimpleFileReader from "./SimpleFileReader";

export default class BAMReader {
  file: File;
  fileReader: SimpleFileReader;
  _sampleName: string = "";

  // file must be a file object like event from file upload onChange
  constructor(file: File) {
    this.file = file;
    this.fileReader = new SimpleFileReader(file);
    autoBind(this);
  }

  checkLine(line: EOF | string | void): EOF | string | void {
    if (line instanceof EOF || isNil(line)) {
      return line;
    }

    if (!this.lineIsHeader(line)) {
      return new EOF();
    }

    // There is no structure to BAMs like VCFs
    // We have to read each header and look for a string that starts with
    // SM: and then everything after that is the id
    // This looks through all the fields in the line and finds one
    const sections = line.split("\t");

    const id = sections.find(line => /^SM:/.test(line));

    // Once we have an id we just end as we don't need to read the file
    // any further as the id has been found.
    // If there are duplicate id fields and they do not match it is not
    // a use case we are covering here by design.
    if (not(isNil(id)) && is(String, id)) {
      // This line starts with SM:ID we just need ID
      const trimmedId = id.substr(3);
      this._sampleName = trimmedId;
      return trimmedId;
    }
  }

  async extractSampleName() {
    let result;

    // We allow it to run until we get a sample name or until we hit the end of the file
    // TODO: stop this once we reach the end of the header as defined in the headers
    while (isNil(result)) {
      const line: EOF | string | void = await this.fileReader.getNextLine();

      result = this.checkLine(line);
    }

    return result;
  }

  // will call user callback with undefined and err string on failure
  async getResult() {
    if (this._sampleName) {
      // we already have the id so call the user provided callback
      // immediately
      return {
        name: this.file.name,
        id: this._sampleName,
      };
    } else {
      const result = await this.extractSampleName();

      if (result instanceof EOF || isNil(result)) {
        throw new Error("Reached the end of the bam header and found no id");
      } else {
        this._sampleName = result;
      }

      return {
        name: this.file.name,
        id: this._sampleName,
      };
    }
  }

  lineIsHeader(line: string): boolean {
    return line.charAt(0) === "@" || /^BAM\1/.test(line);
  }
}
