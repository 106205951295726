import PropTypes from "prop-types";
import React, { memo } from "react";

import { transferErrorExists } from "modules/projectInterpretationRequests/utils";

const DataFileMessages = memo(({ dataFile }) => {
  const { validationMessages, transferError } = dataFile;
  return dataFile ? (
    <div className="project-irs-table-sub-component">
      <h4 className="project-irs-table-sub-component-header">
        Validation Messages and Errors:
      </h4>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Type</th>
            <th>Stage</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {validationMessages &&
            validationMessages.map(({ type, stage, message }) => (
              <tr key={`${type}-${stage}-${message}`}>
                <td> {type} </td>
                <td> {stage} </td>
                <td> {message} </td>
              </tr>
            ))}
          {transferErrorExists(dataFile) && (
            <tr className="danger">
              <td>SFTP file transfer</td>
              <td>error</td>
              <td>{transferError}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  ) : null;
});

DataFileMessages.propTypes = {
  dataFile: PropTypes.object,
};

export default DataFileMessages;
