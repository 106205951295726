import React, { memo } from "react";

import { toDecimalPlaces } from "modules/utils/format";

interface SpliceAiValueProps {
  scores?: Array<number>;
  positions?: Array<number>;
}
const SpliceAiValue = ({ scores = [], positions = [] }: SpliceAiValueProps) => (
  <>
    {scores.map(score => toDecimalPlaces(score)).join(", ")}
    <br />
    {positions.join(", ")}
  </>
);

export default memo(SpliceAiValue);
