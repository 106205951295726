import { Track } from "../core/Coverage";
import { getRelativeTrackBaseConfig } from "../utils";

const getTrackConfig = ({ patientId } = {}) => ({
  id: "coverage",
  coverageThreshold: 30,
  url: `/catalyst/api/patient/${patientId}/files/bigwig_coverage?chr=__CHR__&start=__START__&end=__END__`,
  info: "An inverted histogram showing the read depth at each base. The red line shows the customisable minimum coverage threshold. Generated from BigWig file",
});

export const Coverage = config => Track.extend(getTrackConfig(config));

export const RelativeCoverage = ({
  patient: { patientId, reference } = {},
  relation,
} = {}) =>
  Track.extend({
    ...getTrackConfig({ patientId }),
    ...getRelativeTrackBaseConfig(
      "Congenica.RelativeCoverage",
      relation,
      patientId
    ),
    name: `${relation} (${reference}): Coverage`,
    configName: ["Coverage", `Relation: ${relation} (${reference})`],
    info: "Sequence coverage observed in patient relative: An inverted histogram showing the read depth at each base. The red line shows the customisable minimum coverage threshold.",
  });
