import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";

import {
  ACMG_ADD_CRITERIA_EVIDENCE_FORM,
  ACMG_UPDATE_CRITERIA_EVIDENCE_FORM,
} from "../../constants";

import ConfirmRemovalForm from "./ConfirmRemovalForm";

export class AddEvidenceForm extends PureComponent {
  static propTypes = {
    criteria: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      classificationType: PropTypes.shape({
        strengths: PropTypes.array,
      }).isRequired,
    }).isRequired,
    selectedCriteria: PropTypes.array,
    close: PropTypes.func,
    submitForm: PropTypes.func,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let alreadySelected = false;
    const {
      criteria: { id: criteriaId },
      selectedCriteria = [],
      close,
      submitForm,
    } = this.props;

    close(e);

    // Check if we have already added this item and are just updating the comment and strength
    alreadySelected = selectedCriteria.some(
      item => item.criteriaId === criteriaId
    );

    // if we already have it hit the update endpoints
    if (alreadySelected) {
      submitForm(ACMG_UPDATE_CRITERIA_EVIDENCE_FORM, {
        criteriaId,
      });
      // If we don't then add it like normal
    } else {
      submitForm(ACMG_ADD_CRITERIA_EVIDENCE_FORM, {
        criteriaId,
      });
    }
  }

  render() {
    const {
      criteria = { classificationType: {} },
      selectedCriteria = [],
      close,
      submitForm,
    } = this.props;
    const {
      id: criteriaId,
      name,
      description = "",
      classificationType: { strengths = [] },
    } = criteria;

    // This should be a dropdown of some sort but I couldn't get the right docs for the current version of react-bootstrap
    // so I've just manually coded the classes
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <div className="input-group">
              <span className="input-group-addon">{name}</span>
              <Field
                name="criteriaStrength"
                component="select"
                className="form-control"
              >
                {strengths
                  .slice()
                  .reverse()
                  .map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Field>
            </div>
          </div>
          <div className="form-group">
            <label>Description</label>
            <p>{description}</p>
          </div>
          <div className="form-group">
            <label>Comment</label>
            <Field
              component="textarea"
              className="form-control"
              type="text"
              name="comment"
            />
          </div>
          <div className="form-group">
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </form>
        {selectedCriteria.find(item => item.criteriaId === criteriaId) ? (
          <ConfirmRemovalForm
            close={close}
            submitForm={submitForm}
            criteria={criteria}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default reduxForm({
  form: "addCriteria",
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AddEvidenceForm);
