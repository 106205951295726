import React, { useState, memo, useEffect } from "react";
import { Field } from "redux-form";

import { Tooltip } from "pattern-library";

import ReduxFormField from "modules/forms/components/ReduxFormField";
import { CheckboxOption } from "modules/utils/prop-types/CheckboxOptionProp";

import * as constants from "../../constants";
import { filterArchivedGenePanels } from "../../utils";

export interface GenePanelsProps {
  genePanels: CheckboxOption[];
  change: (field: string, value: any) => void;
}

export const GenePanels: React.FC<GenePanelsProps> = ({
  genePanels: allGenePanels,
  change,
}) => {
  const [showArchived, setShowArchived] = useState(false);
  const [genePanels, setGenePanels] = useState(
    filterArchivedGenePanels(allGenePanels, showArchived)
  );
  const toggleArchivedPanels = () => {
    setShowArchived(!showArchived);
  };
  const clearChecked = () => {
    change(constants.GENE_PANELS_FIELD, []);
  };

  useEffect(() => {
    if (allGenePanels) {
      setGenePanels(filterArchivedGenePanels(allGenePanels, showArchived));
    }
  }, [allGenePanels, showArchived]);
  return (
    <div>
      <nav className="nav">
        <div className="btn-toolbar pull-right panels-toolbar">
          <div className="btn-group btn-group-sm">
            <Tooltip
              placement="top"
              content="Toggle display of archived panels. Panels applied to current patient will always be displayed"
            >
              <button
                type="button"
                className="btn btn-sm btn-default"
                onClick={toggleArchivedPanels}
              >
                {showArchived ? "Hide archived panels" : "Show archived panels"}
              </button>
            </Tooltip>
          </div>
          <div className="btn-group btn-group-sm">
            <button
              type="button"
              className="btn btn-sm btn-default"
              onClick={clearChecked}
            >
              Clear
            </button>
          </div>
        </div>
      </nav>
      <Field
        component={ReduxFormField}
        type="checkboxgroup"
        name={constants.GENE_PANELS_FIELD}
        showArchived
        options={genePanels}
      />
      {genePanels.length < 1 && !showArchived && (
        <p>No unarchived gene panels exist in this project</p>
      )}
      {genePanels.length < 1 && showArchived && (
        <p>No gene panels exist in this project</p>
      )}
      <h4>Note</h4>
      <p>
        <em>
          The list above shows all gene panels available in this project. By
          default selected gene panels are the ones that have been assigned to
          this patient in the overview tab. If no gene panels are selected then
          variants passing other filters will be displayed regardless of gene
          panels.
        </em>
      </p>
    </div>
  );
};

export default memo(GenePanels);
