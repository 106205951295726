import classNames from "classnames";
import React from "react";

const VariantRow = ({
  isActive = false,
  isFilteredOut = false,
  children,
  onClick,
}) => (
  <div
    className={classNames("variant-row", {
      "variant-row--is-active": isActive,
      "variant-row--filtered-out": isFilteredOut,
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

export default VariantRow;
