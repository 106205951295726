import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Cases } from "./Cases";
import { Switch } from "./Switch";

export class Decision extends PureComponent {
  static displayName = "Decision";

  static propTypes = {
    /**
     * Selected case
     */
    selectedCase: PropTypes.string,

    /**
     * Selected decision
     */
    selectedDecision: PropTypes.oneOf(["Approved", "Rejected"]),

    /**
     * Disable the control
     */
    isDisabled: PropTypes.bool,

    /**
     * Function to handle the case selection change
     */
    caseChangeHandler: PropTypes.func.isRequired,

    /**
     * Function to handle the Approve button click
     */
    approveHandler: PropTypes.func.isRequired,

    /**
     * Function to handle the Reject button click
     */
    rejectHandler: PropTypes.func.isRequired,

    /**
     * Function to handle the Unlock button click
     */
    unlockHandler: PropTypes.func.isRequired,

    /**
     *  Class to apply to the  component
     */
    className: PropTypes.string,

    /**
     * The mode of the component
     */
    mode: PropTypes.oneOf(["SELECT_CASE", "SELECT_CASE_ONLY", "REVIEW"])
      .isRequired,
  };

  static defaultProps = {
    className: "",
    selectedCase: null,
    selectedDecision: null,
    isDisabled: false,
    mode: "SELECT_CASE",
  };

  render() {
    const {
      className,
      approveHandler,
      unlockHandler,
      rejectHandler,
      caseChangeHandler,
      isDisabled,
      selectedCase,
      selectedDecision,
      mode,
      ...otherProps
    } = this.props;

    return (
      <div className={classNames("decision", className)}>
        <Cases
          {...otherProps}
          isDisabled={isDisabled || mode === "REVIEW"}
          selectedCase={selectedCase}
          selectedDecision={selectedDecision}
          handleChange={caseChangeHandler}
        />
        {mode !== "SELECT_CASE_ONLY" && (
          <Switch
            {...otherProps}
            mode={mode}
            isDisabled={isDisabled || mode === "SELECT_CASE"}
            selectedCase={selectedCase}
            selectedDecision={selectedDecision}
            approveHandler={approveHandler}
            rejectHandler={rejectHandler}
            unlockHandler={unlockHandler}
          />
        )}
      </div>
    );
  }
}
