export const ENTITY_NOT_FOUND = 2000;

/**
 *
 * @param errors [
 *  {
 *   "code": 10039,
 *   "detail": "Project name already used for parent or sibling project",
 *   "status": 400,
 *   "title": "Project name already used for parent or sibling project"
 *  }
 * ]
 */
export function processErrors(errors: any[] | undefined) {
  return errors
    ? errors.reduce(
        (details: string, error: { detail: any }) =>
          details + `\n${error.detail}`,
        ""
      )
    : null;
}
