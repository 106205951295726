import classNames from "classnames";
import { FC, useMemo } from "react";

import { Dropdown, Label } from "pattern-library";

import catalystApi from "../../../api/catalyst-api";

const { useGetJurisdictionsQuery } = catalystApi;

interface Props {
  projectId: number;
  jurisdiction: string | null;
  disabled: boolean;
  onChange: (value: string) => void;
}
export const JurisdictionSelector: FC<Props> = ({
  projectId,
  jurisdiction,
  disabled,
  onChange,
}) => {
  const { data: jurisdictions = [] } = useGetJurisdictionsQuery({
    projectId,
  });

  const options = useMemo(
    () =>
      jurisdictions.reduce((acc, { jurisdiction, displayJurisdiction }) => {
        acc[jurisdiction] = displayJurisdiction;
        return acc;
      }, {}),
    [jurisdictions]
  );

  const onChangeSource = ({ currentTarget: { value } }) => onChange(value);

  return (
    <div className="row">
      <Label
        htmlFor="source"
        className={classNames("control-label", "col-sm-3")}
      >
        Approval Authorities
      </Label>
      <div className="col-sm-9">
        <Dropdown
          disabled={disabled}
          name="source"
          data-testid="jurisdiction-selector"
          value={jurisdiction}
          emptyPlaceholder="Select from available approval authorities for therapy matching"
          onChange={onChangeSource}
          options={options}
        />
        <small className="text-muted">
          Select from available approval authorities for therapy matching
        </small>
      </div>
    </div>
  );
};
