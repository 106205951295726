// @flow
import { createAction } from "../../../utils";

import * as constants from "./constants";
import type {
  FilterDictionaries,
  FilterPreset,
  InheritanceSettings,
} from "./types";

type InitFilterPresetsAction = FSA<
  typeof constants.INIT_FILTER_PRESETS,
  {},
  { projectId: number | string }
>;
export const initFilterPresets = (projectId: number): InitFilterPresetsAction =>
  createAction(constants.INIT_FILTER_PRESETS, {}, { projectId });

type SetFilterPresetsRequestStatusAction = FSA<
  typeof constants.SET_FILTER_PRESETS_REQUEST_STATUS,
  RequestSubmissionStatus
>;
export const setFilterPresetsRequestStatus = (
  status: RequestSubmissionStatus
): SetFilterPresetsRequestStatusAction =>
  createAction(constants.SET_FILTER_PRESETS_REQUEST_STATUS, status);

type SetFilterPresetsAction = FSA<
  typeof constants.SET_FILTER_PRESETS,
  Array<FilterPreset>
>;
export const setFilterPresets = (
  filterPresets: Array<FilterPreset>
): SetFilterPresetsAction =>
  createAction(constants.SET_FILTER_PRESETS, filterPresets);

type RemoveFilterPresetAction = FSA<
  typeof constants.REMOVE_FILTER_PRESET,
  {},
  { projectId: number, filterPresetId: number }
>;
export const removeFilterPreset = (
  projectId: number,
  filterPresetId: number,
  filterPresetTitle: string
): RemoveFilterPresetAction =>
  createAction(
    constants.REMOVE_FILTER_PRESET,
    {},
    { projectId, filterPresetId, filterPresetTitle }
  );

type FilterPresetRemovedAction = FSA<
  typeof constants.FILTER_PRESET_REMOVED,
  number
>;
export const filterPresetRemoved = (
  filterPresetId: number
): FilterPresetRemovedAction =>
  createAction(constants.FILTER_PRESET_REMOVED, filterPresetId);

type SubmitFilterPresetAction = FSA<
  typeof constants.SUBMIT_FILTER_PRESET,
  {},
  { projectId: number, filterPreset: FilterPreset }
>;
export const submitFilterPreset = (
  projectId: number,
  filterPreset: FilterPreset
): SubmitFilterPresetAction =>
  createAction(constants.SUBMIT_FILTER_PRESET, {}, { projectId, filterPreset });

type SetPresetSubmitStatusAction = FSA<
  typeof constants.SET_PRESET_SUBMIT_STATUS,
  RequestSubmissionStatus
>;
export const setPresetSubmitStatus = (
  status: RequestSubmissionStatus
): SetPresetSubmitStatusAction =>
  createAction(constants.SET_PRESET_SUBMIT_STATUS, status);

type SetPresetRemoveStatusAction = FSA<
  typeof constants.SET_PRESET_REMOVE_STATUS,
  RequestSubmissionStatus
>;
export const setPresetRemoveStatus = (
  status: RequestSubmissionStatus
): SetPresetRemoveStatusAction =>
  createAction(constants.SET_PRESET_REMOVE_STATUS, status);

type SetSelectedPresetAction = FSA<
  typeof constants.SET_SELECTED_PRESET,
  {},
  FilterPreset
>;
export const setSelectedPreset = (
  filterPreset: FilterPreset
): SetSelectedPresetAction =>
  createAction(constants.SET_SELECTED_PRESET, filterPreset);

type SetFilterDictionariesAction = FSA<
  typeof constants.SET_FILTER_DICTIONARIES,
  {},
  FilterPreset
>;
export const setFilterDictionaries = (
  filterDictionaries: FilterDictionaries
): SetFilterDictionariesAction =>
  createAction(constants.SET_FILTER_DICTIONARIES, filterDictionaries);

type SetInheritanceSettingsAction = FSA<
  typeof constants.SET_INHERITANCE_SETTINGS,
  InheritanceSettings
>;
export const setInheritanceSettings = (
  inheritanceSettings: InheritanceSettings
): SetInheritanceSettingsAction =>
  createAction(constants.SET_INHERITANCE_SETTINGS, inheritanceSettings);

type SubmitInheritanceSettingsAction = FSA<
  typeof constants.SUBMIT_INHERITANCE_SETTINGS,
  {},
  { projectId: number, isConfigurationInherited: boolean }
>;
export const submitInheritanceSettings = (
  projectId: number,
  isConfigurationInherited: boolean
): SubmitInheritanceSettingsAction =>
  createAction(
    constants.SUBMIT_INHERITANCE_SETTINGS,
    {},
    { projectId, isConfigurationInherited }
  );

type SetInheritanceSettingsSubmitStatusAction = FSA<
  typeof constants.SET_INHERITANCE_SETTINGS_SUBMIT_STATUS,
  RequestSubmissionStatus
>;
export const setInheritanceSettingsSubmitStatus = (
  status: RequestSubmissionStatus
): SetInheritanceSettingsSubmitStatusAction =>
  createAction(constants.SET_INHERITANCE_SETTINGS_SUBMIT_STATUS, status);

export type Action =
  | InitFilterPresetsAction
  | SetFilterPresetsAction
  | SetFilterPresetsRequestStatusAction
  | RemoveFilterPresetAction
  | FilterPresetRemovedAction
  | SetPresetSubmitStatusAction
  | SetPresetRemoveStatusAction
  | SetFilterDictionariesAction
  | SetInheritanceSettingsAction
  | SetInheritanceSettingsSubmitStatusAction
  | SubmitInheritanceSettingsAction
  | SetSelectedPresetAction;
