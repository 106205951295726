export const NAMESPACE: "variantHistory" = "variantHistory";
export const auditTypes = {
  pathogenicity: { label: "Set pathogenicity", icon: undefined },
  pathogenicity_approve: {
    label: "Approved pathogenicity",
    icon: "glyphicon glyphicon-ok-sign icon_status_decision_approved",
  },
  pathogenicity_unlock: {
    label: "Unlocked pathogenicity",
    icon: "glyphicon glyphicon-lock icon_status_decision_unlocked",
  },
  pathogenicity_reject: {
    label: "Rejected pathogenicity",
    icon: "glyphicon glyphicon-remove-sign icon_status_decision_rejected",
  },
  contribution_to_phenotype: {
    label: "Set contribution to phenotype",
    icon: undefined,
  },
  contribution_to_phenotype_approve: {
    label: "Approved contribution to phenotype",
    icon: "glyphicon glyphicon-ok-sign icon_status_decision_approved",
  },
  contribution_to_phenotype_unlock: {
    label: "Unlocked contribution to phenotype",
    icon: "glyphicon glyphicon-lock icon_status_decision_unlocked",
  },
  contribution_to_phenotype_reject: {
    label: "Rejected contribution to phenotype",
    icon: "glyphicon glyphicon-remove-sign icon_status_decision_rejected",
  },
  report_category: { label: "Set report category", icon: undefined },
  report_category_approve: {
    label: "Approved report category",
    icon: "glyphicon glyphicon-ok-sign icon_status_decision_approved",
  },
  report_category_unlock: {
    label: "Unlocked report category",
    icon: "glyphicon glyphicon-lock icon_status_decision_unlocked",
  },
  report_category_reject: {
    label: "Rejected report category",
    icon: "glyphicon glyphicon-remove-sign icon_status_decision_rejected",
  },
  confirmation: { label: "Set confirmation", icon: undefined },
  confirmation_approve: {
    label: "Approved confirmation",
    icon: "glyphicon glyphicon-ok-sign icon_status_decision_approved",
  },
  confirmation_unlock: {
    label: "Unlocked confirmation",
    icon: "glyphicon glyphicon-lock icon_status_decision_unlocked",
  },
  confirmation_reject: {
    label: "Rejected confirmation",
    icon: "glyphicon glyphicon-remove-sign icon_status_decision_rejected",
  },
  acmg: { label: "Set ACMG classification", icon: undefined },
  acmg_pending: { label: "ACMG classification pending", icon: undefined },
  acmg_approve: {
    label: "Approved ACMG Classification",
    icon: "glyphicon glyphicon-ok-sign icon_status_decision_approved",
  },
  acmg_unlock: {
    label: "Unlocked ACMG Classification",
    icon: "glyphicon glyphicon-lock icon_status_decision_unlocked",
  },
  acmg_reject: {
    label: "Rejected ACMG Classification",
    icon: "glyphicon glyphicon-remove-sign icon_status_decision_rejected",
  },
  acmg_suggestions_used: {
    label: "Used Auto-suggested ACMG criteria",
    icon: "fa fa-lightbulb-o acmg-suggestion-icon",
  },
  acmg_suggestions_rejected: {
    label: "Rejected Auto-suggested ACMG criteria",
    icon: "fa fa-lightbulb-o acmg-suggestion-icon",
  },
};
