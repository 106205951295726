import {
  SNV_CVL_VARIANTS_TYPE,
  SUCCESS_STATUS,
} from "modules/curatedVariantsLists/constants";

import congenicaApi from "api/congenica-api";

const { useGetProjectCuratedListsQuery } = congenicaApi;

type QueryParams = Parameters<typeof useGetProjectCuratedListsQuery>;
type HookOptions = QueryParams[1];

/**
 * Retrieves Project CVLs and temporarily filters only automation ones in the UI
 */
const useProjectAutomationCVLs = (
  { projectId }: ProjectDependant,
  hookOptions: HookOptions = {}
) =>
  useGetProjectCuratedListsQuery(
    {
      projectId,
      filterOptions: {
        is_automated_annotation_decisions: true,
        variant_type: SNV_CVL_VARIANTS_TYPE,
        validation_status: SUCCESS_STATUS,
      },
    },
    {
      selectFromResult: response => ({
        ...response,
        //temporary client-side filtering, should be removed after SAP-34868 is implemented
        cvlList: (response?.data || []).filter(
          ({ isAutomatedAnnotationDecisions, variantType, validationStatus }) =>
            isAutomatedAnnotationDecisions &&
            variantType === SNV_CVL_VARIANTS_TYPE &&
            validationStatus === SUCCESS_STATUS
        ),
      }),
      ...hookOptions,
    }
  );

export default useProjectAutomationCVLs;
