import { createReducer } from "redux-orm";

import orm from ".";

export function ormReducer(sess, action) {
  // Session-specific Models are available
  // as properties on the Session instance.
  const { Gene } = sess;

  switch (action.type) {
    // TODO: when Gene ORM is removed this import should use an existing constant
    case "ADD_CONFIG_PANEL_GENE":
      Gene.parse(action.payload);
      break;

    case "API_READ_GENE_SUCCEEDED":
      const list = [...action.payload.list];
      if (list.length > 1) {
        // check length to avoid cleanup in the view gene details mode
        // a workaround to avoid infinitely growing the redux orm storage
        // this won't be needed after removing Gene ORM
        Gene.all().delete();
      }
      for (const gene of list) {
        Gene.parse(gene);
      }
      break;

    default:
  }

  sess.sessionBoundModels.forEach(modelClass => {
    if (typeof modelClass.reducer === "function") {
      modelClass.reducer(action, modelClass, sess);
    }
  });

  // the state property of Session always points to the current database.
  // Updates don't mutate the original state, so this reference is not
  // equal to `dbState` that was an argument to this reducer.
  return sess.state;
}

export default createReducer(orm, ormReducer);
