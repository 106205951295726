import { camelizeKeys } from "humps";
import { identity } from "ramda";
import React from "react";

import { Tag } from "pattern-library";

import { formatLength, commaFormatNumber } from "modules/utils/format";

import { SV_FIELD_DISPLAY_MAP } from "../constants";
import { StructuralVariant } from "../types";

import HaploinsufficiencyIndex from "./HaploinsufficiencyIndex";
import HiGeneNameColumn from "./HiGeneNameColumn";
import { PopulationFrequenciesTable } from "./PopulationFrequencies";

type VariantValue = {
  value: string[] | number[];
  variant: StructuralVariant;
};
type VariantFieldConfig = {
  attribute: keyof StructuralVariant;
  component?: string;
  format?: (val: any) => any;
};

interface VariantSubTableProps {
  header?: string;
  fieldsConfig?: VariantFieldConfig[];
  variant?: StructuralVariant;
}

const mapGenes = (geneNames: string[], variant: StructuralVariant): any[] =>
  geneNames.map(geneName => {
    const matchedGene = variant.variantGene.find(
      ({ gene }) => gene.name === geneName
    );

    const { gene } = matchedGene || {};

    return gene;
  });

/**
 * Provides a mapping for components to use when displaying
 * fields in the variant details tables
 *
 * e.g. instead of displaying a value as just plain text,
 * we might want to show it as a collection of labels
 *
 * The logic here should mainly be data manipulation
 * to pass the right props to a more sophisticated component
 */
const COMPONENT_MAP = {
  GenesInPanels: ({ value }: VariantValue) => Object.keys(value || {}).length,
  Tags: ({ value = [] }: VariantValue) =>
    value.map(val => <Tag key={val}>{val}</Tag>),
  HiGenes: ({ value = [], variant }: VariantValue) => {
    const hiGeneNames = value.map(String);
    const mappedGenes = mapGenes(hiGeneNames, variant);
    return <HiGeneNameColumn genes={mappedGenes} />;
  },
  Haploinsufficiency: ({ value, variant }: VariantValue) => {
    const hiRgb = (variant.hiRgb || "").split(",");
    return <HaploinsufficiencyIndex hiIndex={value} hiRgb={hiRgb} />;
  },
};

const VariantSubTable = ({
  header,
  fieldsConfig = [],
  variant,
}: VariantSubTableProps) => {
  const renderRows = () => {
    if (!variant) return null;
    return fieldsConfig.map(({ attribute, component, format = identity }) => {
      const value = format(variant[attribute]);
      const RenderingComponent = component ? COMPONENT_MAP[component] : null;

      const renderedValue = RenderingComponent ? (
        <RenderingComponent value={value} variant={variant} />
      ) : (
        value
      );

      const renderedLabel = SV_FIELD_DISPLAY_MAP[attribute] || attribute;

      return (
        <tr key={attribute}>
          <td>{renderedLabel}</td>
          <td>{renderedValue}</td>
        </tr>
      );
    });
  };

  return (
    <>
      {header && <h4>{header}</h4>}
      <table className="table table-striped table-dictionary">
        <tbody>{renderRows()}</tbody>
      </table>
    </>
  );
};

const variantFields: VariantFieldConfig[] = [
  { attribute: "subtype" },
  { attribute: "coordinates" },
  { attribute: "bands" },
  { attribute: "length", format: formatLength },
  { attribute: "genotype" },
  { attribute: "inheritance" },
  { attribute: "quality" },
  { attribute: "filter", component: "Tags" },
];

const geneFields: VariantFieldConfig[] = [
  { attribute: "hiGeneName", component: "HiGenes" },
  { attribute: "hiIndex", component: "Haploinsufficiency" },
  {
    attribute: "genesInPanels",
    format: value => Object.keys(value || {}).length,
  },
  { attribute: "genesOmim" },
  { attribute: "genesMorbid" },
  { attribute: "variantGene", format: value => value.length },
];

const cnvFields: VariantFieldConfig[] = [
  { attribute: "bayesFactor" },
  { attribute: "copyNumber" },
  // TODO: Add reads observed/expected/ratio to API response
  { attribute: "readsExpected" },
  { attribute: "readsObserved" },
  { attribute: "readsRatio" },
];

const translocationFields: VariantFieldConfig[] = [
  { attribute: "translocationChr" },
  { attribute: "translocationPos", format: commaFormatNumber },
];

interface StructuralVariantDetailsProps {
  variant: StructuralVariant;
  showPopulationFrequencies: boolean;
}
export const StructuralVariantDetails = ({
  variant: rawVariant,
  showPopulationFrequencies,
}: StructuralVariantDetailsProps) => {
  // FIXME: This is a workaround to ensure that we get camelCased data here
  // It's easier to pass in the snake_case from the Perl template and correct it here
  // Once we are pulling the data from an API, we can remove this logic
  const variant: StructuralVariant = camelizeKeys(rawVariant);

  // Get any configuration that should only be shown for certain variant types
  // e.g. only show translocation data for translocation variants(!)
  const typeSpecificConfig = ((): VariantSubTableProps | null => {
    if (variant.type === "CNV") {
      return { fieldsConfig: cnvFields, header: "CNV data:", variant };
    }
    if (variant.subtype === "Translocation") {
      return {
        fieldsConfig: translocationFields,
        header: "Translocation data:",
        variant,
      };
    }
    return null;
  })();

  const hasAdditionalData = Boolean(
    typeSpecificConfig &&
      typeSpecificConfig.fieldsConfig &&
      typeSpecificConfig.fieldsConfig.length
  );

  return (
    <>
      <h3>Structural variant details</h3>
      <div className="col-sm-6 col-md-6">
        <VariantSubTable
          header="Variant:"
          fieldsConfig={variantFields}
          variant={variant}
        />
        {hasAdditionalData && <VariantSubTable {...typeSpecificConfig} />}
      </div>
      <div className="col-sm-6 col-md-6">
        <VariantSubTable
          header="Genes:"
          fieldsConfig={geneFields}
          variant={variant}
        />
      </div>
      {showPopulationFrequencies && variant.gnomadAfPopulations && (
        <div className="col-sm-6 col-md-6">
          <PopulationFrequenciesTable
            gnomadVariantId={variant.gnomadVariantId}
            populations={variant.gnomadAfPopulations}
          />
        </div>
      )}
    </>
  );
};
