import React, { PureComponent } from "react";

import { ProjectRootTabs } from "../modules/projects/components";

export default class ProjectsLayout extends PureComponent {
  static displayName = "ProjectsLayout";
  render() {
    return <ProjectRootTabs />;
  }
}
