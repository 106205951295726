import PropTypes from "prop-types";
import React, { memo } from "react";

const Patients = memo(({ patients }) =>
  patients ? (
    <div className="project-irs-table-sub-component">
      <h4 className="project-irs-table-sub-component-header">Patients:</h4>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(patients).map(([key, value]) => (
            <tr key={key}>
              <td> {key} </td>
              <td> {value} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null
);

Patients.propTypes = {
  patients: PropTypes.object,
};

export default Patients;
