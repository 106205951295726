import { Field } from "formik";
import PropTypes from "prop-types";
import React, { memo } from "react";

import { FormikFormField } from "modules/forms/components";
import { normalisePositiveInteger } from "modules/forms/rules";
import RemovalCell from "modules/interpretationRequests/components/RemovalCell";

export const ReferencePanelsTableRow = ({
  sampleIndex,
  index,
  disabled = false,
  removeRowHandler,
}) => (
  <tr>
    <td className="number-cell">
      <span>{index + 1}</span>
    </td>
    <td>
      <Field
        key={`samples.${sampleIndex}.references.${index}.projectId`}
        component={FormikFormField}
        normalize={normalisePositiveInteger}
        name={`samples.${sampleIndex}.references.${index}.projectId`}
        id={`samples.${sampleIndex}.references.${index}.projectId`}
        disabled={disabled}
        type="number"
        inputFilter={/^\d*$/}
      />
    </td>
    <td>
      <Field
        key={`samples.${sampleIndex}.references.${index}.name`}
        component={FormikFormField}
        name={`samples.${sampleIndex}.references.${index}.name`}
        id={`samples.${sampleIndex}.references.${index}.name`}
        disabled={disabled}
        type="text"
      />
    </td>
    <td className="text-center">
      <RemovalCell
        index={index}
        tooltip="Remove reference panel"
        fieldsDisabled={disabled}
        removeRowHandler={removeRowHandler}
      />
    </td>
  </tr>
);

ReferencePanelsTableRow.propTypes = {
  /**
   * index of selected sample
   */
  sampleIndex: PropTypes.number.isRequired,
  /**
   * index of row in the Reference panel
   */
  index: PropTypes.number.isRequired,
  /**
   * true if editing IR fields is disabled
   */
  disabled: PropTypes.bool,
  /**
   * remove row handler
   */
  removeRowHandler: PropTypes.func.isRequired,
};

export default memo(ReferencePanelsTableRow);
