// To get round the genoverse bug that causes tracks to refuse to reload unless they have their own namespace, this function
// will extend the track AND set the namespace
export const extendTrack = (baseTrack, config, name) => {
  if (!baseTrack) {
    if (config.name) {
      throw new Error(
        `Cannot extend track ${config.name} which has not been defined. If there is an error in the track you might also see this message.`
      );
    } else {
      throw new Error(
        "Cannot extend track which has not been defined. If there is an error in the track you might also see this message."
      );
    }
  }
  // Actually extend the obj
  const newTrack = baseTrack.extend(config);
  // Set the global namespace
  baseTrack[name] = newTrack;
  return baseTrack[name];
};
