// @flow
import dotProps, { set } from "dot-prop-immutable";

import {
  actionsTypes,
  type Action,
  type ShowModalAction,
  type HideModalAction,
} from "./actions";
import type { ModalPayload } from "./flow-types";

export type ModalState = {
  // This normalized state data. What it gives all data indexed by modal type
  // all data is defined only once. An updating state of one item is much faster.
  // As search if much faster then just putting objects to array.
  // more info https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
  byId: { [type: string]: ModalPayload },
  // this way you can have as many lists but not duplicating objects itself
  // as list is only array of refences to data.
  list: Array<string>,
};

export const initialState: ModalState = {
  byId: {},
  list: [],
};

const setValue = (path: string, value: any) => state =>
  set(state, `${path}`, value);

export default function reducer(
  state: ModalState = initialState,
  action: Action
) {
  switch (action.type) {
    case actionsTypes.SHOW_MODAL:
      return showModal(state, action);

    case actionsTypes.HIDE_MODAL:
      return hideModal(state, action);
    default:
      return state;
  }
}

const showModal = (state: ModalState, { payload }: ShowModalAction) => {
  const newList = state.list.filter(type => type !== payload.type);
  newList.push(payload.type);
  return [
    setValue(`byId.${payload.type}`, payload),
    setValue("list", newList),
  ].reduce((state, f) => f(state), { ...state });
};

const hideModal = (state: ModalState, { payload }: HideModalAction) => ({
  ...dotProps.delete(state, `byId.${payload.type}`),
  list: state.list.filter(type => type !== payload.type),
});
