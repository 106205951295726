import classnames from "classnames";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import {
  Button,
  FormGroup,
  IconLoading,
  InputGroup,
  Modal,
  MODAL_SIZES,
  ModalBody,
  ModalHeader,
  Textarea,
} from "pattern-library";

import {
  FAILURE_STATUS,
  IDLE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "common/constants";
import { useFirstRender } from "common/utils";
import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import { saveFeedbackDraftAction, submitFeedbackAction } from "../actions";
import { NAMESPACE } from "../constants";

export const FeedbackModal = memo(
  ({
    closeModal,
    closeModalAndSaveDraft,
    feedbackSubmittingState,
    draftValues,
    feedbackType,
    fixedUserFieldsValues,
    submitFeedback,
  }) => {
    const firstRender = useFirstRender();

    const inputsRenderData = useMemo(
      () => [
        {
          icon: "user",
          label: "First Name:",
          name: "name",
          placeholder: "Enter Name",
        },
        {
          icon: "envelope",
          label: "Email address:",
          name: "email",
          placeholder: "Enter Email",
        },
        {
          icon: "infoSign",
          label: "Subject:",
          name: "subject",
          placeholder: "Enter Subject",
        },
      ],
      []
    );

    const validationSchema = useMemo(
      () =>
        Yup.object().shape({
          name: Yup.string().required(),
          email: Yup.string().email().required(),
          subject: Yup.string().required(),
          message: Yup.string().required(),
        }),
      []
    );

    const formik = useFormik({
      initialValues: {
        name: (draftValues && draftValues.name) || "",
        email: (draftValues && draftValues.email) || "",
        subject: (draftValues && draftValues.subject) || "",
        message: (draftValues && draftValues.message) || "",
      },
      validationSchema,
      onSubmit: submitFeedback,
    });

    // Formik doesn't launch validation during initialization, let's do it
    // to disable the submit button if necessary.
    useEffect(() => {
      if (firstRender) {
        formik.validateForm();
      }
    }, [firstRender, formik]);

    // Let's close the modal on success response.
    useEffect(() => {
      if (!firstRender && feedbackSubmittingState === SUCCESS_STATUS) {
        closeModal();
      }
    }, [closeModal, feedbackSubmittingState, firstRender]);

    useEffect(() => {
      if (!fixedUserFieldsValues) {
        return;
      }

      const { name, email } = fixedUserFieldsValues;
      if (name !== formik.values.name || email !== formik.values.email) {
        formik.setFieldValue("name", name);
        formik.setFieldValue("email", email);
      }
    }, [fixedUserFieldsValues, formik]);

    const closeModalSavingCurrentFormValues = useCallback(() => {
      closeModalAndSaveDraft(formik.values);
    }, [closeModalAndSaveDraft, formik.values]);

    const isFieldInvalid = useCallback(
      name => formik.errors[name] && formik.touched[name],
      [formik]
    );

    return (
      <Modal
        className="feedback-modal"
        close={closeModalSavingCurrentFormValues}
        show
        size={MODAL_SIZES.L}
      >
        <ModalHeader close={closeModalSavingCurrentFormValues}>
          <h3>{feedbackType === "bugReport" ? "Report a bug" : "Feedback"}</h3>
        </ModalHeader>

        <ModalBody>
          <form className="well" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-5">
                {inputsRenderData.map(({ icon, label, name, placeholder }) => (
                  <FormGroup
                    key={name}
                    className={classnames({
                      "has-error": isFieldInvalid(name),
                    })}
                  >
                    <label
                      htmlFor={`feedback-modal-${name}`}
                      className="control-label"
                    >
                      {label}
                    </label>
                    <InputGroup
                      id={`feedback-modal-${name}`}
                      disabled={
                        fixedUserFieldsValues && fixedUserFieldsValues[name]
                      }
                      icon={icon}
                      name={name}
                      placeholder={placeholder}
                      value={formik.values[name]}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                ))}
              </div>

              <div
                className={classnames(
                  "form-group",
                  "col-md-6",
                  "col-md-offset-1",
                  { "has-error": isFieldInvalid("message") }
                )}
              >
                <label
                  htmlFor="feedback-modal-message"
                  className="control-label"
                >
                  Message:
                </label>
                <div className="input-group col-md-12">
                  <Textarea
                    id="feedback-modal-message"
                    name="message"
                    rows="8"
                    value={formik.values.message}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
                <br />
                <div className="text-right">
                  {feedbackSubmittingState === IN_PROGRESS_STATUS && (
                    <IconLoading loading />
                  )}
                  &nbsp;
                  <Button
                    context="primary"
                    disabled={
                      feedbackSubmittingState === IN_PROGRESS_STATUS ||
                      !!Object.keys(formik.errors).length
                    }
                    type="submit"
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }
);

FeedbackModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  closeModalAndSaveDraft: PropTypes.func.isRequired,
  feedbackSubmittingState: PropTypes.oneOf([
    FAILURE_STATUS,
    IDLE_STATUS,
    IN_PROGRESS_STATUS,
    SUCCESS_STATUS,
  ]).isRequired,
  draftValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
  }),
  feedbackType: PropTypes.oneOf(["feedback", "bugReport"]),
  fixedUserFieldsValues: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ]),
  submitFeedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { type: feedbackType }) => {
  const { user } = state.auth.data;

  return {
    draftValues: state[NAMESPACE].data[`${feedbackType}Draft`],
    feedbackSubmittingState: state[NAMESPACE].ui.feedbackSubmittingState,
    fixedUserFieldsValues: user
      ? { name: user.name, email: user.email }
      : false,
  };
};

const mapDispatchToProps = (dispatch, { type: feedbackType }) => ({
  closeModal: () => dispatch(hideModal(modalTypes.FEEDBACK_MODAL)),
  closeModalAndSaveDraft: newDraftData => {
    dispatch(hideModal(modalTypes.FEEDBACK_MODAL));
    dispatch(saveFeedbackDraftAction(feedbackType, newDraftData));
  },
  submitFeedback: requestData => {
    dispatch(submitFeedbackAction(feedbackType, requestData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
