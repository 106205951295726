import { react as autoBind } from "auto-bind";
import { identity, memoizeWith } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import { Tabs } from "pattern-library";

import {
  NotFoundMessage,
  RouteTabContent,
  RouteTabHeader,
} from "layout/partials";
import * as authSelectors from "modules/auth/selectors";
import { getProjectsCount } from "modules/projects/selectors";

import ProjectsContainer from "../Container";

export class ProjectRootTabs extends PureComponent {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { projectsCount, user = {} } = this.props;
    const tabs = memoizeWith(identity, projectsCount => [
      {
        // PROJECTS
        component: ProjectsContainer,
        name: "Projects",
        routeUrl: "projects/:projectId?",
        url: `projects`,
        count: projectsCount,
      },
      {
        // ADMIN
        name: "Admin",
        url: "projects",
        hash: "admin",
        isHidden: !user.admin,
      },
      {
        // METRICS
        name: "Metrics",
        url: "projects",
        hash: "metrics",
        isHidden: !user.admin,
      },
    ])(projectsCount);

    return (
      <Switch>
        <Tabs
          tabs={tabs}
          baseUrl="/"
          routePath="/"
          tabComponent={RouteTabHeader}
          contentComponent={RouteTabContent}
        />
        <Route component={NotFoundMessage} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  projectsCount: getProjectsCount(state),
  user: authSelectors.currentUser(state),
});

export default connect(mapStateToProps)(withRouter(ProjectRootTabs));
