import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { ReduxFormField } from "../../../forms/components";
import { CheckboxOptionArrayProp } from "../../../utils/prop-types/CheckboxOptionProp";
import { getFilterDefaults } from "../../selectors";

export class ZygosityForm extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    zygosity: CheckboxOptionArrayProp.isRequired,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { disabled = false, zygosity = [] } = this.props;
    return (
      <fieldset disabled={disabled}>
        <Field
          component={ReduxFormField}
          type="checkboxgroup"
          label="Zygosity"
          name="zygosity"
          options={zygosity}
        />
      </fieldset>
    );
  }
}

const mapStateToProps = state => ({
  zygosity: getFilterDefaults(state).allZygosityValues,
});

export default connect(mapStateToProps)(ZygosityForm);
