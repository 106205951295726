// @flow
import { equals, isEmpty } from "ramda";
import type { Saga } from "redux-saga";
import { call, put, select, take, takeLatest, all } from "redux-saga/effects";

import { getStrs, getGeneStrCounts } from "modules/api/strs";
import * as legacyConstants from "modules/legacy/constants";
import { errors, error } from "modules/messages/actions";
import type { ReadPatientSuccessAction } from "modules/patient";
import { READ_PATIENT_SUCCESS, readPatient } from "modules/patient";
import * as patientSelectors from "modules/patient/selectors";
import {
  fetchDecisionsForPatient,
  retrieveGenePanels,
} from "modules/variants/saga";

import {
  fetchStrs,
  fetchSingleStr,
  configuration,
  setPatientId,
  setGeneStrCounts,
  setGenePanels,
  setGeneWrappersByGene,
} from "./actions";
import { actionType, configForm, DEFAULT_TIERS } from "./constants";
import type {
  ApiResult,
  FetchStrsRequestParams,
  GeneStrCounts,
  StrsPage,
} from "./flow-types";
import * as selectors from "./selectors";

export function* getRequestParams(): FetchStrsRequestParams {
  const { projectId, patientId } = yield call(retrievePatient);
  const { filters } = yield select(selectors.getConfigValues);

  return {
    patientId,
    projectId,
    filters,
  };
}

export function* fetchGeneWrappersByGenesForStrs({
  list: strs,
}: StrsPage): Saga<void> {
  const geneWrappers = yield select(selectors.getGeneWrappersByGeneId);

  if (isEmpty(geneWrappers)) {
    const patient = yield call(retrievePatient);
    const patientGenePanelIds = patient.genePanels.map(
      ({ genePanelId }) => genePanelId
    );

    const allGenePanelIds = strs
      .flatMap(({ gene: { genePanelIds } }) => genePanelIds || [])
      .filter(id => patientGenePanelIds.includes(id));

    const uniqueIds = [...new Set(allGenePanelIds)];

    const { list: genePanels } = yield call(retrieveGenePanels, uniqueIds);
    yield put(setGeneWrappersByGene(genePanels));
  }
}

export function* retrieveStrs({
  payload: pageParams,
}: fetchStrs.start): Saga<void> {
  try {
    const requestParams = yield call(getRequestParams);
    const result: ApiResult<StrsPage> = yield call(getStrs, {
      ...requestParams,
      pageParams,
    });

    if (result.errors) {
      yield put(fetchStrs.failure());
      yield put(errors(result.errors));
    } else {
      yield call(fetchGeneWrappersByGenesForStrs, result);
      yield put(fetchStrs.success(result));
    }
  } catch (e) {
    yield put(fetchStrs.failure());
    yield put(error(e.message));
  }
}

export function* retrieveSingleStr({
  payload: { variantId, geneId },
}: fetchSingleStr.start): Saga<void> {
  try {
    const { projectId, patientId } = yield call(retrievePatient);

    const result: StrsPage = yield call(getStrs, {
      projectId,
      patientId,
      filters: { variantId, geneId },
    });

    if (result.errors) {
      yield put(fetchSingleStr.failure());
      yield put(errors(result.errors));
    } else if (result.totalCount === 0) {
      yield put(fetchSingleStr.failure());

      const detail = variantId
        ? `STR record for variant_id= ${variantId} does not exist`
        : `STR record for gene_id= ${geneId} does not exist`;

      yield put(errors([{ detail }]));
    } else {
      yield call(fetchGeneWrappersByGenesForStrs, result);
      yield put(fetchSingleStr.success(result.list[0]));
    }
  } catch (e) {
    yield put(fetchSingleStr.failure());
    yield put(error(e.message));
  }
}

export function* retrievePatient(): Saga<Patient> {
  const patientId = yield select(selectors.getPatientId);
  const patient = yield select(patientSelectors.getSinglePatient, patientId);
  if (patient && patient.patientId) {
    return patient;
  }

  yield put(readPatient(patientId));

  const action: ReadPatientSuccessAction = yield take(READ_PATIENT_SUCCESS);
  return action.payload;
}

function createConfigDefaults({ genePanels = [], hasStrCustomDataTiers }) {
  return {
    filters: {
      [configForm.FILTER_GENE_PANELS]: genePanels.map(
        ({ genePanelId }) => genePanelId
      ),
      [configForm.FILTER_TIERS]: hasStrCustomDataTiers ? DEFAULT_TIERS : [],
    },
  };
}

export function* initConfig({
  payload: { patientId, variantId },
}: configuration.init): Saga<void> {
  const initialized = yield select(selectors.configInitialized);
  if (initialized) {
    return;
  }

  yield put(setPatientId(patientId));
  const [patient] = yield all([
    call(retrievePatient),
    call(fetchDecisionsForPatient, { meta: { patientId } }),
  ]);
  const configDefaults = createConfigDefaults(patient);
  yield put(configuration.setDefaultValues(configDefaults));
  if (!variantId) {
    yield call(resetConfig);
  }

  yield put(configuration.ready());
}

export function* fetchGeneStrCounts(genePanelIds: Array<number>): Saga<void> {
  if (!genePanelIds || !genePanelIds.length) {
    yield put(setGeneStrCounts([]));
    return;
  }

  try {
    const { projectId, patientId } = yield call(retrievePatient);
    const result: ApiResult<GeneStrCounts> = yield call(getGeneStrCounts, {
      projectId,
      patientId,
      genePanelIds,
    });

    if (result.errors) {
      yield put(errors(result.errors));
    } else {
      yield put(setGeneStrCounts(result));
    }
  } catch (e) {
    yield put(error(e.message));
  }
}

export function* fetchGenePanels(genePanelIds: Array<number>): Saga<void> {
  const { list: genePanels } = yield call(retrieveGenePanels, genePanelIds);
  yield put(setGenePanels(genePanels));
  yield put(setGeneWrappersByGene(genePanels));
}

export function* onSetNewConfigValues({
  payload,
}: configuration.setValues): Saga<void> {
  const {
    filters: { [configForm.FILTER_GENE_PANELS]: genePanelIds },
  } = payload;

  yield all([
    call(fetchGenePanels, genePanelIds),
    call(fetchGeneStrCounts, genePanelIds),
  ]);
}

export function* onDecisionChange(): Saga<void> {
  const patientId = yield select(selectors.getPatientId);
  yield call(fetchDecisionsForPatient, { meta: { patientId } });
}

export function* resetConfig(): Saga<void> {
  const defaults = yield select(selectors.getConfigDefaultValues);
  yield put(configuration.setValues(defaults));
}

export function* saveConfig(): Saga<void> {
  const formValues = yield select(selectors.getConfigFormValues);
  const stateValues = yield select(selectors.getConfigValues);

  if (equals(formValues, stateValues)) {
    return;
  }
  yield put(configuration.setValues(formValues));
}

export default function* sagas(): Saga<void> {
  yield takeLatest(actionType.FETCH_STRS_START, retrieveStrs);
  yield takeLatest(actionType.FETCH_SINGLE_STR_START, retrieveSingleStr);

  yield takeLatest(actionType.CONFIG_INIT, initConfig);
  yield takeLatest(actionType.CONFIG_RESET, resetConfig);
  yield takeLatest(actionType.CONFIG_SAVE, saveConfig);

  yield takeLatest(actionType.CONFIG_SET_VALUES, onSetNewConfigValues);

  yield takeLatest(
    legacyConstants.PROCESS_LEGACY_STR_DECISION_VALUES,
    onDecisionChange
  );
}
