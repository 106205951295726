//@flow

import type { Saga } from "redux-saga";
import { call, put, select, take, takeLatest } from "redux-saga/effects";

import { getProjectSettings } from "modules/api/projectSettings";
import { getProjectChildren } from "modules/api/projects";
import {
  getReportTemplatesForProject,
  toggleTemplateForProject,
} from "modules/api/reportTemplates";
import * as variantPanelApi from "modules/api/variantPanel";
import { error, errors, success } from "modules/messages/actions";

import {
  actionTypes,
  fetchProjectSettings,
  fetchReportTemplates,
  setProjectSettingsLoading,
  toggleTemplate,
  fetchVariantPanel,
  saveVariantPanel,
  setVariantPanelLoading,
  setVariantPanelLoadingError,
  setProjectHasInheritors,
} from "./actions";
import type {
  GetOrReloadProjectSettings,
  CheckProjectHasInheritors,
} from "./actions";
import { SETTINGS_TYPES } from "./constants";
import * as selectors from "./selectors";

export function* init(): Saga<void> {
  yield takeLatest(
    actionTypes.FETCH_REPORT_TEMPLATES_START,
    reloadProjectReportTemplates
  );

  yield takeLatest(
    actionTypes.TOGGLE_TEMPLATE_START,
    toggleProjectReportTemplate
  );

  yield takeLatest(
    actionTypes.FETCH_PROJECT_SETTINGS_START,
    reloadProjectSettings
  );

  yield takeLatest(actionTypes.FETCH_VARIANT_PANEL_START, reloadVariantPanel);

  yield takeLatest(
    actionTypes.SAVE_VARIANT_PANEL_START,
    saveVariantPanelSettings
  );

  yield takeLatest(
    actionTypes.GET_OR_RELOAD_PROJECT_SETTINGS,
    getOrReloadProjectSettings
  );

  yield takeLatest(
    actionTypes.CHECK_PROJECT_HAS_INHERITORS,
    checkProjectHasInheritors
  );
}

export function* reloadProjectReportTemplates({
  payload,
}: fetchReportTemplates.start): Saga<void> {
  const { projectId, requestParams } = payload;
  try {
    const reportTemplates: PaginatedTableResponse<ReportTemplate> = yield call(
      getReportTemplatesForProject,
      projectId,
      requestParams
    );
    if (reportTemplates.errors) {
      yield put(errors(reportTemplates.errors));
      yield put(fetchReportTemplates.failure());
    } else {
      yield put(fetchReportTemplates.success({ reportTemplates, projectId }));
    }
  } catch (e) {
    yield put(error(e));
    yield put(fetchReportTemplates.failure());
  }
}

export function* getOrFetchProjectSettings(
  projectId: number
): Generator<any, ProjectSettings, any> {
  const settings = yield select(selectors.getProjectSettings);
  if (settings && settings.id === projectId) {
    return settings;
  }
  yield put(fetchProjectSettings.start(projectId));

  const action: fetchProjectSettings.success = yield take(
    actionTypes.FETCH_PROJECT_SETTINGS_SUCCESS
  );
  return action.payload;
}

export function* getOrReloadProjectSettings(
  action: GetOrReloadProjectSettings
): Generator<any, ProjectSettings, any> {
  const {
    payload: { projectId },
  } = action;
  return yield call(getOrFetchProjectSettings, projectId);
}

export function* reloadProjectSettings({
  payload: projectId,
}: fetchProjectSettings.start): Saga<void> {
  try {
    yield put(setProjectSettingsLoading(true));
    const settings: Array<ProjectSettings> = yield call(
      getProjectSettings,
      projectId
    );
    yield put(fetchProjectSettings.success(settings));
  } catch (e) {
    yield put(error(e));
    yield put(fetchProjectSettings.failure());
  } finally {
    yield put(setProjectSettingsLoading(false));
  }
}

export function* toggleProjectReportTemplate({
  payload,
}: toggleTemplate.start): Saga<void> {
  try {
    const toggleResponse = yield call(toggleTemplateForProject, payload);
    yield put(toggleTemplate.success(toggleResponse));
  } catch (err) {
    const message =
      "We weren't able to save that change - please try again later" +
      (err.message ? `\nError message: ${err.message}` : "");
    yield put(error(message));
    yield put(toggleTemplate.failure(payload));
  }
}

export function* reloadVariantPanel({
  payload: projectId,
}: fetchVariantPanel.start): Saga<void> {
  yield put(setVariantPanelLoading(true));

  try {
    const variantPanelSettings = yield call(
      variantPanelApi.getVariantPanelSettings,
      projectId
    );
    yield put(fetchVariantPanel.success(variantPanelSettings));
    yield put(setVariantPanelLoadingError(false));
  } catch (e) {
    yield put(
      error(
        `Something went wrong when loading the variant panel settings: ${e}`
      )
    );
    yield put(fetchVariantPanel.failure());
    yield put(setVariantPanelLoadingError(true));
  } finally {
    yield put(setVariantPanelLoading(false));
  }
}

export function* saveVariantPanelSettings({
  payload: variantPanel,
  meta: projectId,
}: saveVariantPanel.start): Saga<void> {
  yield put(setVariantPanelLoading(true));
  try {
    const variantPanelSettings = yield call(
      variantPanelApi.saveVariantPanelSettings,
      projectId,
      variantPanel
    );
    yield put(success("Variant panel successfully saved"));
    yield put(fetchVariantPanel.success(variantPanelSettings));
  } catch (e) {
    yield put(error(e));
    yield put(saveVariantPanel.failure());
  } finally {
    yield put(setVariantPanelLoading(false));
  }
}

export function* checkProjectHasInheritors({
  payload: projectId,
}: CheckProjectHasInheritors): Saga<void> {
  try {
    const projectChildren: Array<ProjectChild> = yield call(
      getProjectChildren,
      projectId
    );

    const hasDefaultsInheritors = projectChildren.some(
      ({ defaultsIsInherited }) => defaultsIsInherited
    );

    const hasCustomerInheritors = projectChildren.some(
      ({ crmReferenceIsInherited }) => crmReferenceIsInherited
    );

    const typesOfInheritedSettings = [
      hasDefaultsInheritors ? SETTINGS_TYPES.DEFAULTS : null,
      hasCustomerInheritors ? SETTINGS_TYPES.CUSTOMER : null,
    ].filter(Boolean);

    yield put(setProjectHasInheritors(typesOfInheritedSettings));
  } catch (e) {
    yield put(error(e.message));
  }
}
