import { groupBy, isEmpty } from "ramda";
import * as yup from "yup";

import { REQUIRED_FIELD_MESSAGE } from "../messages/constants";

import {
  INCORRECT_PARENT_MESSAGE,
  IR_ID_ALLOWED_CHARS_MESSAGE,
  VALID_FILE_COMBINATIONS_MESSAGE,
  ON_PREM_MESSAGES,
} from "./components/componentConstants";
import { FORM_FILE_TYPES } from "./types";
import {
  getIndicesBySampleName,
  getProbandsPerFamilyCount,
  getRelativesPerSample,
  getSamplesByNames,
} from "./utils";

/**
 * cross-sample validation
 * @param values - form values
 * @return {{}}
 */

const validateReferences = (references = []) => {
  const byPair = groupBy(({ projectId, name }) => `${projectId}_${name}`);
  const pairs = byPair(references);
  const referencesErrors = references.map(({ name, projectId }) => {
    if (!isEmpty(projectId) && !isEmpty(name)) {
      if (pairs[`${projectId}_${name}`].length > 1) {
        return { name: `Duplicate Sample ID` };
      }
    }
    return null;
  });

  return referencesErrors.find(o => o) ? referencesErrors : null;
};

export const validate = ({ samples }) => {
  const errors = {};
  let samplesHaveErrors = false;

  if (samples) {
    const probandsCountByFamily = getProbandsPerFamilyCount(samples);
    const duplicatesByName = getIndicesBySampleName(samples);
    const relativesPerSamples = getRelativesPerSample(samples);
    const samplesByNames = getSamplesByNames(samples);

    const sampleParentCheck = (name, parentName) => {
      if (!name || !parentName) {
        return;
      }
      const parentSample = samplesByNames[parentName] || {};
      const { motherName, fatherName } = parentSample;
      if ([motherName, fatherName].includes(name)) {
        return INCORRECT_PARENT_MESSAGE;
      }
    };

    const samplesErrors = samples.map(
      (
        {
          name,
          isProband,
          familyName,
          affectionStatus,
          fatherName,
          motherName,
          fileTypes,
          references,
        },
        currentIndex
      ) => {
        const rowError = {};

        if (name && duplicatesByName[name].indexOf(currentIndex) > 0) {
          // set the error for all the duplicates except for the first occurrence
          rowError.name = "Duplicate ID";
        }

        const hasFamily = relativesPerSamples[currentIndex].size > 0;
        if (hasFamily && !familyName) {
          rowError.familyName = REQUIRED_FIELD_MESSAGE;
        }

        if (!isProband) {
          // proband must be set for each family
          if (familyName && !probandsCountByFamily[familyName]) {
            rowError.isProband = `Proband is not set for family '${familyName}'`;
          }

          // an individual (no relatives) non-proband sample is required to have files
          if (!hasFamily && !isAnyFileTypeChosen(fileTypes)) {
            rowError.fileTypes = "Required for a sample without relations";
          }
        } else {
          if (familyName && probandsCountByFamily[familyName] > 1) {
            rowError.isProband = `There should be only 1 proband per family '${familyName}'`;
          }

          if (affectionStatus !== "Affected") {
            rowError.affectionStatus = `Must be Affected for proband`;
          }
        }

        const fatherError = sampleParentCheck(name, fatherName);
        if (fatherError) {
          rowError.fatherName = fatherError;
        }

        const motherError = sampleParentCheck(name, motherName);
        if (motherError) {
          rowError.motherName = motherError;
        }

        const referencesErrors = validateReferences(references);
        if (referencesErrors) {
          rowError.references = referencesErrors;
        }

        if (Object.keys(rowError).length) {
          samplesHaveErrors = true;
          return rowError;
        }

        return undefined; //successful Formik validation is expected to return undefined
      }
    );

    if (samplesHaveErrors) {
      errors.samples = samplesErrors;
    }
  }

  return errors;
};

export const getFileTypesValidationMessage = isOnPrem => {
  if (isOnPrem) {
    return ON_PREM_MESSAGES.VALID_FILE_COMBINATIONS;
  }

  return VALID_FILE_COMBINATIONS_MESSAGE;
};

const checkFileTypes = (fileTypes, isOnPrem) => {
  if (!fileTypes) {
    return false;
  }

  const {
    vcf,
    bam,
    fastq,
    [FORM_FILE_TYPES.SNV_VCF]: snvVcf,
    [FORM_FILE_TYPES.SV_VCF]: svVcf,
    [FORM_FILE_TYPES.CNV_VCF]: cnvVcf,
  } = fileTypes;

  if (isOnPrem) {
    return !(
      bam === true &&
      snvVcf !== true &&
      svVcf !== true &&
      cnvVcf !== true
    );
  }

  return (
    ((bam === true || vcf === true) && fastq === false) ||
    (bam === false && vcf === false && fastq === true)
  );
};

const generateMetadataValidation = metadataFields => {
  const schema = {};
  metadataFields.forEach(field => {
    if (field.required) {
      schema[field.name] = yup.mixed().required(REQUIRED_FIELD_MESSAGE);
    }
  });
  return schema;
};

const isAnyFileTypeChosen = val =>
  !!val && !!Object.values(val).find(checked => checked);

const irIdRegex = /^([^\x00-\x7F]|[\w-. ])*$/; // eslint-disable-line no-control-regex

function checkCNVCallingParameter(value) {
  const { cnvAnalysisRequested } = this.parent;
  if (!cnvAnalysisRequested) {
    return true;
  }

  return !!value;
}

export const getValidationSchema = (metadataFields, isOnPrem) =>
  yup.object().shape({
    identifier: yup
      .string()
      .required(REQUIRED_FIELD_MESSAGE)
      .matches(irIdRegex, { message: IR_ID_ALLOWED_CHARS_MESSAGE }),
    projectId: yup.string().required(REQUIRED_FIELD_MESSAGE),
    samples: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(REQUIRED_FIELD_MESSAGE),
          sex: yup.string().required(REQUIRED_FIELD_MESSAGE),
          affectionStatus: yup.string().required(REQUIRED_FIELD_MESSAGE),
          familyName: yup.string(),
          protocol: yup
            .string()
            .test("protocolRequired", REQUIRED_FIELD_MESSAGE, function (value) {
              return checkCNVCallingParameter.call(this, value);
            }),
          sampleType: yup
            .string()
            .test(
              "sampleTypeRequired",
              REQUIRED_FIELD_MESSAGE,
              function (value) {
                return checkCNVCallingParameter.call(this, value);
              }
            ),
          fileTypes: yup
            .object()
            .test("probandRequired", "Required for proband", function (val) {
              const { isProband } = this.parent;
              if (!isProband) {
                return true;
              }

              return isAnyFileTypeChosen(val);
            })
            .test(
              "oneOfRequired",
              getFileTypesValidationMessage(isOnPrem),
              fileTypes => {
                if (isAnyFileTypeChosen(fileTypes)) {
                  return checkFileTypes(fileTypes, isOnPrem);
                }

                return true;
              }
            ),

          metadata: yup
            .object()
            .shape(generateMetadataValidation(metadataFields)),

          /**
           * please see https://github.com/jquense/yup#mixedwhenkeys-string--arraystring-builder-object--value-schema-schema-schema
           *
           * Adjust the schema based on a sibling or sibling children fields. You can provide an object literal where
           * the key is is value or a matcher function, then provides the true schema and/or otherwise for the
           * failure condition.
           * is conditions are strictly compared (===) if you want to use a different form of equality you can provide
           * a function like: is: (value) => value == true.
           *
           * In our case we will check values of references if cnvAnalysisRequested is true
           */
          references: yup.array().when(["cnvAnalysisRequested"], {
            is: value => value,
            then: yup.array().of(
              yup.object().shape({
                name: yup.string().required(REQUIRED_FIELD_MESSAGE),
                projectId: yup
                  .number()
                  .typeError(REQUIRED_FIELD_MESSAGE)
                  .required(REQUIRED_FIELD_MESSAGE),
              })
            ),
          }),
        })
      )
      .required("Samples list must not be empty"),
  });

export const IRV2_VALIDATION_SCHEMA = yup.object().shape({
  identifier: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .matches(irIdRegex, { message: IR_ID_ALLOWED_CHARS_MESSAGE }),
  projectId: yup.string().required(REQUIRED_FIELD_MESSAGE),
  samples: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(REQUIRED_FIELD_MESSAGE),
        sex: yup.string().required(REQUIRED_FIELD_MESSAGE),
        tumourType: yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
        neoplasticCellularity: yup.number().required(REQUIRED_FIELD_MESSAGE),
        baitset: yup.string().when(["files"], {
          is: ([{ fileType }]) => fileType === "fastq",
          then: yup.string().required(REQUIRED_FIELD_MESSAGE),
        }),
        files: yup.array().of(
          yup.object().shape({
            fileName: yup.string().when(["fileType"], {
              is: value => value === "vcf",
              then: yup.string().required("You must select VCF file"),
            }),
          })
        ),
      })
    )
    .required("Samples list must not be empty"),
});
