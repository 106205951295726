import { Schema } from "yup";

const validator =
  <T>(schema: Schema<T>) =>
  async formValues => {
    try {
      await schema.validate(formValues, { abortEarly: false });
      return {};
    } catch (errors) {
      throw errors.inner.reduce(
        (errors, err) => ({
          ...errors,
          [err.path]: err.message,
        }),
        {}
      );
    }
  };

export default validator;
