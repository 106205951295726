import React from "react";
import { Helmet } from "react-helmet";

import { useAppVersion } from "hooks/useAppVersion";

const BrowserTitle = () => (
  <Helmet>
    <title>{useAppVersion()}</title>
  </Helmet>
);

export default BrowserTitle;
