import { isNil } from "ramda";
import { useEffect, useState } from "react";

import { DataType, StoreType, ErrorMessages } from "./definitions";
import { PreferencesMetadata } from "./preferences-list";
import { temporaryStorage, permanentStorage } from "./storages";

const User = {
  id: null,
};

const setUser = id => {
  User.id = id;
};

const getStore = key => {
  if (PreferencesMetadata[key]) {
    switch (PreferencesMetadata[key].memory) {
      case StoreType.temporary:
        return temporaryStorage();
      case StoreType.permanent:
        return permanentStorage();
      default:
        break;
    }
  }
  throw Error(ErrorMessages.key);
};

const getStoreKey = key => {
  if (User.id) {
    return `${key}.user-${User.id}`;
  }
  throw Error(ErrorMessages.access);
};

const cast = (key, value) => {
  if (PreferencesMetadata[key]) {
    switch (PreferencesMetadata[key].type) {
      case DataType.number:
        return value !== null && !isNaN(value) ? Number(value) : null;
      case DataType.boolean:
        return value === true || value === false ? value : null;
      case DataType.string:
        return value !== undefined && value !== null ? String(value) : null;
      default:
        break;
    }
  }
  return value;
};

const get = tag => {
  try {
    const store = getStore(tag);
    const key = getStoreKey(tag);
    return cast(tag, JSON.parse(store.getItem(key)));
  } catch (error) {
    console.error(`${ErrorMessages.read} ${tag}: ${error}`);
  }
};

const set = (tag, value) => {
  try {
    const store = getStore(tag);
    const key = getStoreKey(tag);
    if (value === undefined || value === null) {
      store.setItem(key);
    } else {
      store.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    console.error(`${ErrorMessages.write} ${tag}: ${error}`);
  }
};

const usePreferences = key => {
  const update = value => set(key, value);
  const value = get(key) ?? undefined;
  return [value, update];
};

//this hook reuses usePreferences and wraps it into useState
const usePreferencesStateWrapper = (key, defaultValue) => {
  const [pref, setPref] = usePreferences(key);

  const [value, setValue] = useState(() =>
    !isNil(pref) ? pref : defaultValue
  );

  useEffect(() => {
    setPref(value);
  }, [key, value, setPref]);

  return [value, setValue];
};

export { usePreferences, setUser, usePreferencesStateWrapper };
