import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import * as projectSelectors from "modules/project/selectors";

import * as constants from "../constants";
import { CONFIG_MODE } from "../constants";
import { ConfigFormValuesProp } from "../prop-types";
import * as selectors from "../selectors";

import ConfigForm from "./ConfigForm";

const mapStateToProps = (state, props) => {
  const { patientId } = props;
  const { variantColumns, consequenceColumns } = selectors.getValues(state);
  const { projectId } = projectSelectors.getCurrentProject(state, patientId);

  const filters = selectors.getFilterValues(state);
  return {
    projectId,
    configMode: CONFIG_MODE.CONFIG_EDIT,
    filterError: selectors.getFilterErrorState(
      state,
      constants.SNV_TABLE_CONFIG_FORM
    ),
    initialValues: {
      ...filters,
      prioritisation: selectors.getPrioritisationValues(state),
      columns: {
        variantColumns,
        consequenceColumns,
      },
    },
  };
};

const SNVTableConfig = reduxForm({
  form: constants.SNV_TABLE_CONFIG_FORM,
  enableReinitialize: true,
})(ConfigForm);

SNVTableConfig.propTypes = {
  patientId: PropTypes.number.isRequired,
  isCustomDataTabEnabled: PropTypes.bool,
  initialValues: ConfigFormValuesProp.isRequired,
};

export default connect(mapStateToProps, null)(SNVTableConfig);
