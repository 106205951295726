import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  IconLoading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "pattern-library";

import { useFirstRender } from "common/utils";
import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import { useApiToken } from "../hooks";

export const ApiTokenModal = memo(
  ({ apiToken, loadingStatus, onCloseHandler, refreshToken }) => {
    const firstRender = useFirstRender();

    useEffect(() => {
      if (firstRender && loadingStatus === "failure") {
        refreshToken();
      }
    }, [firstRender, loadingStatus, refreshToken]);

    const renderModalBody = () => {
      if (loadingStatus === "loading") {
        return (
          <div className="text-center">
            <IconLoading loading />
          </div>
        );
      }
      if (loadingStatus === "failure") {
        return (
          <div className="text-center">
            <div>Failed to load required data.</div>
            <Button onClick={refreshToken}>Retry</Button>
          </div>
        );
      }
      return (
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <strong>API Token:</strong>
            </div>
            <div className="col-sm-9 text-right">{apiToken}</div>
          </div>
        </div>
      );
    };

    return (
      <Modal className="api-token-modal" show close={onCloseHandler}>
        <ModalHeader close={onCloseHandler}>
          <h3>User Profile</h3>
        </ModalHeader>
        <ModalBody className={`status-${loadingStatus}`}>
          {renderModalBody()}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseHandler}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
);

ApiTokenModal.propTypes = {
  apiToken: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  loadingStatus: PropTypes.string.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  refreshToken: PropTypes.func.isRequired,
};

export default memo(() => {
  const dispatch = useDispatch();
  const { apiToken, loadingStatus, refreshToken } = useApiToken();

  const onCloseHandler = useCallback(() => {
    dispatch(hideModal(modalTypes.API_TOKEN_MODAL));
  }, [dispatch]);

  return (
    <ApiTokenModal
      apiToken={apiToken}
      onCloseHandler={onCloseHandler}
      loadingStatus={loadingStatus}
      refreshToken={refreshToken}
    />
  );
});
