import {
  ComparisonOutcomes,
  Fields,
} from "modules/sequenceVariantsSummary/constants";

import { ThirdpartySoftwareVersions } from "../patient/types";

export interface SpliceDataApi {
  adaScore: number | null;
  denovo: number;
  diff: number;
  mesPercentChange: number;
  motifType: string;
  rScore: number;
  rfScore: number;
  seq: string;
  snvId: number;
  spliceAdaScore: number | null;
  spliceAiDeltaPosition: Array<number>;
  spliceAiDeltaScore: Array<number>;
  spliceAiMaxScore: number;
  spliceMaxEntScore: number | null;
  spliceMotifType: string;
  spliceMutationId: number;
  spliceRfScore: number | null;
  spliceSeq: string;
  spliceSiteId: number;
  spliceSsfScore: number;
  spliceStrand: string;
  spliceType: string;
  ssfDiff: number;
  ssfPercentChange: number | null;
  ssfRScore: number;
  startRel: number;
  strand: string;
}

export interface SequenceVariantSummary {
  gene: {
    name?: string;
    hiScore?: number;
    hiRgb?: string;
  };
  snv?: {
    genotype?: string;
    depth?: number;
    afMax?: number;
    score?: number;
    inheritance?: string;
    denovoStatus?: string;
    transcriptName?: string;
    vepConsequence?: string;
    hgvsC?: string;
    hgvsP?: string;
    siftPrediction?: string;
    siftScore?: number;
    polyphenPrediction?: string;
    polyphenScore?: number;
    refAllele?: string;
    altAllele?: string;
    codons?: string;
    aminoAcid?: string;
    proteinPosition?: number;
    gerp?: number;
    readsplit?: string;
    filter?: string[];
    revelScore?: number;
    rsId?: string;
    cosmicId?: string;
    hgmdId?: string;
    espId?: string;
    comparisonOutcome?: keyof typeof ComparisonOutcomes;
    splice?: Array<SpliceDataApi>;
  };
}

export interface SequenceVariantState {
  loading: boolean;
  data: null | SequenceVariantSummary;
  otherPatientsSnvCounts?: AsyncData<OtherPatientsSnvCounts>;
}

export enum SUMMARY_STATE_PATH {
  VARIANTS = "variants",
  AUDIT = "audit",
}

export type SequenceVariantSummaryState = {
  [key in SUMMARY_STATE_PATH]: SequenceVariantState;
};

export interface SummaryField {
  id: Fields;
  key: string | JSX.Element;
  value: string;
  colspan?: boolean;
}

export interface SummaryRequestParameters {
  geneName: string;
  patientId: string | number;
  transcriptId?: string | number | null;
  variantId?: string | number | null;
}

export interface SummaryActionMeta {
  path: SUMMARY_STATE_PATH;
}

export interface SummaryProps {
  loading: boolean;
  data: SummaryData;
  thirdpartySoftwareVersions: ThirdpartySoftwareVersions;
  isSpliceAiFeatureEnabled: boolean;
  path: SUMMARY_STATE_PATH;
  isVariantPanel: boolean;
  variantPanelConfig: Array<Array<string>>;
}

export interface SummaryData {
  name?: string;
  hiScore?: number;
  hiRgb?: string;
  genotype?: string;
  depth?: number;
  maxAf?: number;
  qualityScore?: number;
  inheritance?: string;
  denovoStatus?: string;
  transcriptName?: string;
  vepConsequence?: string;
  hgvsC?: string;
  hgvsP?: string;
  siftPrediction?: string;
  siftScore?: number;
  polyphenPrediction?: string;
  polyphenScore?: number;
  refAllele?: string;
  altAllele?: string;
  codons?: string;
  aminoAcid?: string;
  proteinPosition?: number;
  gerp?: number;
  readSplit?: string;
  qcStatus?: string[];
  revel?: number;
  rsId?: string;
  cosmicId?: string;
  hgmdId?: string;
  espId?: string;
  comparisonOutcome?: keyof typeof ComparisonOutcomes;
  splice?: Array<SpliceDataApi>;
}

export interface SummaryValues {
  [key: string]: string | number | null | undefined;
}

export interface ColocatedVariantsIds {
  rsId?: string;
  cosmicId?: string;
  hgmdId?: string;
  espId?: string;
}

export interface ColocatedVariantsProps {
  geneName?: string;
  colocatedVariantsIds: ColocatedVariantsIds;
}

export interface ColocatedVariantSchema {
  idProperty: string;
  idPrefix?: string;
  removeIdPrefix?: boolean;
  href: (id: string) => string;
}

export interface LinkObject {
  href: string;
  label: string;
}

export interface OtherPatientsSnvCountsRequest {
  geneName: string;
  patientId: string | number;
  variantId: string | number;
}

export interface SNVCount {
  otherCount: string | number;
  totalCount: string | number;
}

export interface OtherPatientsSnvCounts {
  otherProjects: SNVCount;
  thisProject: SNVCount;
  nonProjects: SNVCount;
  allProjectsTotalCount: SNVCount;
}

export interface OtherPatientsSnvCountsRow {
  description: string;
  counts: string;
}

export interface OtherPatientsSnvCountsUI {
  data: Array<OtherPatientsSnvCountsRow>;
  href: string;
}

export interface AminoAcidProps {
  value?: string;
}

export interface SpliceSitesDiffRowArgs {
  score: number | null;
  rScore: number;
  diff: number;
  percentChange: number | null;
  lost: boolean;
  label: string;
  software: string;
  spliceType: string;
  spliceMotifType;
  denovo: number;
}

export interface SpliceRowArgs {
  label: string;
  rScore?: number;
  software: string;
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions;
  getValue: () => number | JSX.Element;
  getDetailedLabel?: () => string | JSX.Element;
}

export interface SpliceSitesRow {
  label: string | JSX.Element;
  value: string | number | JSX.Element;
}

export interface SpliceSitesProps {
  data: Array<SpliceDataApi>;
  isSpliceAiFeatureEnabled: boolean;
  thirdpartySoftwareVersions?: ThirdpartySoftwareVersions;
}
