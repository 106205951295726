import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";
import { DragSource, DropTarget } from "react-dnd";

import { itemShape, wellIdShape } from "./utils";

export const itemType = "wellItem";

const itemSource = {
  beginDrag(props) {
    const { index, wellId, data } = props;
    return {
      data,
      index,
      // last visited well that should be updated after visiting a new well
      wellId,
      // the well the item originally belongs to. Used to track the original item placement
      initialWellId: wellId,
    };
  },
  isDragging({ data: { id } }, monitor) {
    const {
      data: { id: draggedItemId },
    } = monitor.getItem();
    return id === draggedItemId;
  },
  endDrag(props, monitor) {
    const sourceItem = monitor.getItem() || {};
    const { endDrag } = props;
    endDrag(sourceItem);
  },
};

const itemTarget = {};

class Item extends PureComponent {
  static displayName = "Item";

  static propTypes = {
    /**
     * draggable item data
     */
    data: itemShape.isRequired,
    /**
     * the identifier of the well the item belongs to
     */
    wellId: wellIdShape,
    /**
     * the flag that indicates that the element is being dragged
     */
    isDragging: PropTypes.bool.isRequired,
    /**
     * function that must be used inside the component to assign the drag source role to a node
     */
    connectDragSource: PropTypes.func.isRequired,
    /**
     * function that must be used inside the component to mark mark any React element as the droppable node
     */
    connectDropTarget: PropTypes.func.isRequired,
    /**
     * a custom class name that applies css rules to draggable items
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const {
      data,
      wellId,
      className,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    if (isNil(wellId) || isNil(data)) {
      throw new Error("Item data is invalid");
    }

    const {
      text,
      category: {
        name: categoryName,
        context: categoryContext = "default",
      } = {},
    } = data;

    return connectDragSource(
      connectDropTarget(
        <div
          className={classNames("sortable-well-item", className, {
            "sortable-well-item-dragging": isDragging,
          })}
        >
          <span className="sortable-well-item-text">{text}</span>
          {!isNil(categoryName) && (
            <span className={classNames("label", `label-${categoryContext}`)}>
              {categoryName}
            </span>
          )}
        </div>
      )
    );
  }
}

const collectTarget = connect => ({
  connectDropTarget: connect.dropTarget(),
});

const collectSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

export default DropTarget(
  itemType,
  itemTarget,
  collectTarget
)(DragSource(itemType, itemSource, collectSource)(Item));
