import { path } from "ramda";
import React from "react";

import { Icon } from "pattern-library";

export const getValue = (data, key) => {
  const value = path(key.split("."), data);
  if (value !== null && value !== undefined) {
    if (typeof value === "boolean") {
      return value === true ? <Icon type="check" /> : <Icon type="unchecked" />;
    }

    return value;
  }
  return "No data available";
};
