/**
 * This component keeps inner content "sticky" to the left border of
 * the browser window when the page is scrolled right.
 * It based on `position: sticky` + `left: 0`, so we need to keep the root HTML element of
 * this component equal to `document.documentElement.scrollWidth`.
 */

import classnames from "classnames";
import { debounce } from "debounce";
import { FunctionComponent, useEffect, useRef } from "react";

import styles from "./StickyToLeftWrapper.module.scss";
import { StickyToLeftWrapperProps } from "./StickyToLeftWrapper.types";

// There is some code outside of component for optimization purposes:
// - We want to minimize re-rendering caused by changing state on React side;
// - We want to have only one event listener for all instances;
// - We want to have only one mutation observer for all instances;

const calcAndSaveScrollbarWidth = () => {
  document.documentElement.style.setProperty(
    "--sticky-to-left-scrollbar-width",
    window.innerWidth - document.documentElement.clientWidth + "px"
  );
};

export const updateRootElements = debounce((): void => {
  calcAndSaveScrollbarWidth();
  Object.values(rootElements).forEach(root => (root.style.width = "auto")); // reset width to calculate
  Object.values(rootElements).forEach(
    root => (root.style.width = document.documentElement.scrollWidth + "px")
  );
}, 250);
const updateNewRootElement = (root: HTMLDivElement): void => {
  calcAndSaveScrollbarWidth();
  root.style.width = document.documentElement.scrollWidth + "px";
};

// There is a mix of DOM mutation caused by React and Legacy code,
// we need to catch them somehow.
export const observer = new MutationObserver(updateRootElements);

// Root HTML elements of all components.
const rootElements: { [id: number]: HTMLDivElement } = {};
// Proxy is used to detect the first instance creation or the last one deletion.
const rootElementsProxy = new Proxy(rootElements, {
  set(target, prop, el) {
    target[prop] = el;
    updateNewRootElement(el);

    if (Object.keys(rootElements).length === 1) {
      window.addEventListener("resize", updateRootElements);
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["hidden"],
      });
    }
    return true;
  },

  deleteProperty(target, prop) {
    delete target[prop];

    if (Object.keys(rootElements).length === 0) {
      window.removeEventListener("resize", updateRootElements);
      observer.disconnect();
    }
    return true;
  },
});

const StickyToLeftWrapper: FunctionComponent<StickyToLeftWrapperProps> = ({
  children,
  className,
  outerPadding,
}) => {
  const idRef = useRef<number>(performance.now());
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current: id } = idRef;
    const { current: root } = rootRef;
    if (root === null) {
      return;
    }
    rootElementsProxy[id] = root;
    return () => {
      delete rootElementsProxy[id];
    };
  }, [idRef, rootRef]);

  const outerPaddingSize =
    outerPadding === true ? 15 : (outerPadding as number);

  return (
    <div
      className={classnames(styles.component, className)}
      ref={rootRef}
      style={{ marginLeft: -outerPaddingSize + "px" }}
    >
      <div
        className={styles.componentSticky}
        style={{
          paddingLeft: outerPaddingSize + "px",
          paddingRight: outerPaddingSize + "px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default StickyToLeftWrapper;
