import React, { PureComponent } from "react";
import { components } from "react-select";

import { Case } from "./Case";

const { SingleValue: SelectSingleValue } = components;

export class SingleValue extends PureComponent {
  static displayName = "SingleValue";

  render() {
    return (
      <SelectSingleValue {...this.props}>
        <Case {...this.props} />
      </SelectSingleValue>
    );
  }
}
