import deepFreeze from "deep-freeze";

export const NAMESPACE: "REFN0CALL" = "REFN0CALL";

export const filtration = Object.freeze({
  ALL: "ALL",
  REF: "REF",
  NOCALL: "NOCALL",
});

export const tableColums = deepFreeze([
  {
    Header: "Genotype",
    accessor: "callType",
    id: "CALL_TYPE",
  },
  {
    Header: "Chr",
    accessor: "chrom",
    id: "CHROM",
  },
  {
    Header: "Start",
    accessor: "pos",
    id: "POS",
  },
  {
    Header: "End",
    accessor: "end",
    id: "END",
  },
  {
    Header: "Gene",
    accessor: "geneName",
    id: "GENE_NAME",
  },
  {
    Header: "HGVSc",
    accessor: "hgvsC",
    id: "HGVS_C",
  },
  {
    Header: "HGVSp",
    accessor: "hgvsP",
    id: "HGVS_P",
  },
  {
    Header: "Read depth",
    accessor: "minReadDepth",
    id: "MIN_READ_DEPTH",
  },
  {
    Header: "GQ score",
    accessor: "minGq",
    id: "MIN_GQ",
  },
]);

export const INITIAL_PER_PAGE_COUNT = 10;

export const PAGINATION_PAGES_AHEAD = 3;

export const perPageOptions = {
  10: "10",
  30: "30",
  60: "60",
  120: "120",
};
