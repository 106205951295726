import React, { useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";

import { ConfirmationModal } from "pattern-library";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import { removeGeneFromGenePanel, searchGenes } from "../../../../actions";
import { getSearchGeneQuery } from "../../../../selectors";

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.DELETE_GENE_FROM_GENE_PANEL_MODAL, {
//      projectId: 123,
//      genePanelId: 123,
//      geneName: "AAAA",
//    });

interface Props extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
  geneName: string;
}

export const DeleteGeneFromGenePanelModal = ({
  projectId,
  genePanelId,
  geneName,
  removeGeneFromGenePanel,
  hideModal,
  searchGenes,
  addGeneSearchQuery,
}: Props) => {
  const onCloseHandler = useCallback(() => {
    hideModal(modalTypes.DELETE_GENE_FROM_GENE_PANEL_MODAL);
  }, [hideModal]);

  const onConfirm = useCallback(() => {
    removeGeneFromGenePanel(projectId, genePanelId, geneName);
    if (!!addGeneSearchQuery) {
      searchGenes(projectId, genePanelId, addGeneSearchQuery);
    }
    onCloseHandler();
  }, [
    projectId,
    genePanelId,
    geneName,
    removeGeneFromGenePanel,
    onCloseHandler,
    addGeneSearchQuery,
    searchGenes,
  ]);

  return (
    <ConfirmationModal
      confirmationText="Are you sure you want to remove this gene?"
      showConfirmationModal
      isDeleteModal
      closeText="No"
      confirmText="Yes"
      yesClickHandler={onConfirm}
      closeClickHandler={onCloseHandler}
      className="remove-gene-from-gene-panel-modal"
    />
  );
};

const mapStateToProps = state => ({
  addGeneSearchQuery: getSearchGeneQuery(state),
});

const mapDispatchToProps = {
  removeGeneFromGenePanel: removeGeneFromGenePanel.start,
  hideModal,
  searchGenes: searchGenes.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeleteGeneFromGenePanelModal);
