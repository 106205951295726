import Genoverse from "genoverse";
import { isEmpty, isNil } from "ramda";

import { aminoAcids, Colors } from "../utils";

import {
  View as TranscriptsView,
  Model as TranscriptsModel,
  addExons,
} from "./Transcripts";

// The code in this file was moved over with minimal changes from the old track

export const parseData = function (data, featuresById) {
  addExons(data, featuresById);
};

export const ViewConfig = {
  colors: {
    default: Colors.SILVER,
    A: Colors.GREEN,
    T: Colors.LIGHT_RED,
    G: Colors.LIGHT_YELLOW,
    C: Colors.DARK_CYAN,
  },
  labelColors: {
    default: Colors.BLACK,
    A: Colors.WHITE,
    T: Colors.WHITE,
    C: Colors.WHITE,
  },
  proteinInfo: {
    ...aminoAcids,
  },
  proteinLabelColors: {
    default: Colors.BLACK,
    H: Colors.WHITE,
    K: Colors.WHITE,
    R: Colors.WHITE,
    D: Colors.WHITE,
    E: Colors.WHITE,
    F: Colors.WHITE,
    W: Colors.WHITE,
    Y: Colors.WHITE,
    Z: Colors.WHITE,
    X: Colors.WHITE,
    "*": Colors.WHITE,
  },
  featureHeight: 20,
  featureMargin: { top: 35, right: 0, bottom: 45, left: 0 },

  constructor(...args) {
    this.base.apply(this, args);

    const lowerCase = this.prop("lowerCase");

    this.labelWidth = {};
    this.widestLabel = lowerCase ? "w" : "W";
    this.labelYOffset = (this.featureHeight + (lowerCase ? 0 : 1)) / 2;

    if (lowerCase) {
      for (const key in this.colors) {
        this.colors[key.toLowerCase()] = this.colors[key];
      }

      for (const labelColorsKey in this.labelColors) {
        this.colors[labelColorsKey.toLowerCase()] = this.colors[labelColorsKey];
      }
    }
  },

  draw(features, featureContext, labelContext, scale) {
    this.base(features, featureContext, labelContext, scale);

    featureContext.textBaseline = "middle";
    featureContext.textAlign = "left";

    if (!this.labelWidth[this.widestLabel]) {
      this.labelWidth[this.widestLabel] =
        Math.ceil(this.context.measureText(this.widestLabel).width) + 1;
    }

    const width = Math.max(scale, this.minScaledWidth);

    features.forEach(feature => {
      this.drawProtein(feature, featureContext, scale, width);
    });
  },

  drawExonSequence(
    feature,
    parentFeature,
    context,
    scale,
    width,
    exonNumber,
    cdnaStart
  ) {
    const drawLabels = this.labelWidth[this.widestLabel] < width - 1;

    let start, bp;

    [...Array(feature.sequence.length)].forEach((_, i) => {
      start = parentFeature.position[scale].X + i * scale;
      start = start + (feature.start - parentFeature.start) * scale; // Shift to exon position

      if (start < -scale || start > context.canvas.width) {
        return;
      }

      // Make transparent if outside coding region
      if (
        feature.start + i <= parentFeature.cds_start ||
        feature.start + i >= parentFeature.cds_end
      ) {
        context.globalAlpha = 0.5;
      } else {
        context.globalAlpha = 1;
      }

      bp = feature.sequence.charAt(i);

      context.fillStyle = this.colors[bp] || this.colors.default;
      context.fillRect(
        start,
        parentFeature.position[scale].Y,
        width,
        this.featureHeight
      );

      if (!this.labelWidth[bp]) {
        this.labelWidth[bp] = Math.ceil(context.measureText(bp).width) + 1;
      }

      if (drawLabels) {
        context.fillStyle = this.labelColors[bp] || this.labelColors.default;
        context.fillText(
          bp,
          start + (width - this.labelWidth[bp]) / 2,
          parentFeature.position[scale].Y + this.labelYOffset
        );

        let cdnaPos;
        //codon coordinates
        if (parentFeature.strand < 0) {
          // cdnaPos = cdnaStart;
          cdnaPos = 1 + feature.sequence.length - i + cdnaStart;
        } else {
          cdnaPos = 1 + i + cdnaStart;
        }

        //There is no 0 in natural counting of bp
        if (cdnaPos <= 0) {
          cdnaPos -= 1;
        }

        context.fillStyle = Colors.MINE_SHAFT;

        if (
          width >
          Math.ceil(context.measureText(parentFeature.sequence.length).width)
        ) {
          context.fillText(
            cdnaPos, //Local codon coordinates
            start +
              (width - Math.ceil(context.measureText(cdnaPos).width) + 1) / 2,
            parentFeature.position[scale].Y +
              this.labelYOffset -
              this.featureHeight -
              2
          );
        }
      }
    });
  },

  drawProteinSequence(feature, context, scale, width) {
    const sortFn = function (a, b) {
      return parseInt(a) - parseInt(b);
    };
    const exonStarts = feature.exon_starts.split(",").sort(sortFn);
    const exonEnds = feature.exon_ends.split(",").sort(sortFn);

    let posProtein = 0;
    let isOverlap = false;
    let frameShift = 0;

    const drawLabels = this.labelWidth[this.widestLabel] < width - 1;

    const proteinLabelWidth = 3 * width;

    context.globalAlpha = 1;

    [...Array(exonStarts.length)].forEach((_, i) => {
      // Check the exon is within coding region
      if (exonEnds[i] > feature.cds_start && exonStarts[i] < feature.cds_end) {
        let exonStart = exonStarts[i];
        let exonEnd = exonEnds[i];
        //Calculate protein sequence for exon
        exonStart =
          feature.cds_start > exonStart ? feature.cds_start : exonStart;
        exonEnd = feature.cds_end < exonEnd ? feature.cds_end : exonEnd;

        // Length of codons for translation, remember to add 1 to get actual length
        const exonLength = 1 + parseInt(exonEnd) - parseInt(exonStart);

        // Minus codons translated in previous exon
        const translatedLength = !isOverlap
          ? exonLength
          : exonLength - (3 - frameShift);

        // Triplet of codons per amino acid
        let proteinLength = Math.ceil(translatedLength / 3);

        if (isOverlap) {
          // If there is an overlap with the PREVIOUS exon protein sequence
          // Shift the protein position back by one (to repeat the protein label partially included the end of the previous exon)
          posProtein = posProtein - 1;

          // And Expand the protein length by 1 to compensate for the shift
          proteinLength = proteinLength + 1;
        }

        // const proteinSequence = proteinLength > 0 ? feature.protein.substring(posProtein, proteinLength + posProtein) : '';
        const proteinSequence =
          proteinLength > 0
            ? feature.protein.substr(posProtein, proteinLength)
            : "";

        //Draw protein sequence
        [...Array(proteinSequence.length)].forEach((_, j) => {
          // Shift to exon position, taking into account shift of previous exon
          let start = (exonStart - feature.start - frameShift) * scale;
          start += feature.position[scale].X + j * scale * 3;

          if (start < -scale || start > context.canvas.width) {
            return;
          }

          const aa = proteinSequence.charAt(j);

          context.fillStyle = this.proteinInfo[aa].color;
          context.fillRect(
            start,
            feature.position[scale].Y + this.featureHeight,
            proteinLabelWidth,
            this.featureHeight + 4
          );

          if (!this.labelWidth[aa]) {
            this.labelWidth[aa] = Math.ceil(context.measureText(aa).width) + 1;
          }

          if (drawLabels) {
            context.fillStyle =
              this.proteinLabelColors[aa] || this.proteinLabelColors.default;
            context.fillText(
              this.proteinInfo[aa].code,
              start +
                width +
                (width -
                  this.labelWidth[aa] * this.proteinInfo[aa].code.length) /
                  2,
              feature.position[scale].Y +
                this.labelYOffset +
                this.featureHeight +
                2
            );

            let proteinLocalPos;
            //protein coordinates
            if (feature.strand < 0) {
              proteinLocalPos = feature.protein.length - (posProtein + j);
            } else {
              proteinLocalPos = posProtein + j + 1;
            }
            context.fillStyle = Colors.MINE_SHAFT;

            if (
              3 * width >
              Math.ceil(context.measureText(feature.protein_length).width)
            ) {
              context.fillText(
                proteinLocalPos,
                start +
                  width +
                  (width -
                    Math.ceil(context.measureText(proteinLocalPos).width) +
                    1) /
                    2,
                feature.position[scale].Y +
                  this.labelYOffset +
                  2 * this.featureHeight +
                  2 * 2
              );
            }
          }
        });

        // Shift by protein length
        posProtein = posProtein + proteinLength;

        isOverlap = translatedLength % 3 !== 0;
        frameShift = translatedLength % 3;
      }
    });
  },

  drawProtein(feature, context, scale, width) {
    //If there isn't any protein data then return
    //without filling in any detail other than the transcript architecture
    if (!feature.protein) {
      return;
    }

    let local_cdna_pos = 0;

    // Loop for each exon
    if (!isNil(feature.exons) && !isEmpty(feature.exons)) {
      //Note: all exons have been sorted by start position in the model
      if (feature.strand < 0) {
        // Reverse strand
        feature.exons
          .slice()
          .reverse()
          .forEach((exon, i) => {
            if (exon.sequence && exon.start < feature.cds_end) {
              //Calculate local start cdna coordinate
              if (parseInt(local_cdna_pos) === 0) {
                local_cdna_pos = -(
                  1 +
                  parseInt(exon.end) -
                  parseInt(feature.cds_end)
                );
              }

              this.drawExonSequence(
                exon,
                feature,
                context,
                scale,
                width,
                feature.exons.length - i, //Exon number (not reversed)
                local_cdna_pos
              );

              //Increment cdna pos by length of sequence
              local_cdna_pos = local_cdna_pos + exon.sequence.length;
            }
          });
      } else {
        // Forward strand
        feature.exons.forEach((exon, i) => {
          if (exon.sequence && exon.end > feature.cds_start) {
            //Calculate local cdna coordinate
            if (parseInt(local_cdna_pos) === 0) {
              local_cdna_pos = -(feature.cds_start - exon.start);
            }

            this.drawExonSequence(
              exon,
              feature,
              context,
              scale,
              width,
              i + 1, //Exon number
              local_cdna_pos
            );

            //Increment cdna pos by length of sequence
            local_cdna_pos = local_cdna_pos + exon.sequence.length;
          }
        });
      }
    }
    this.drawProteinSequence(feature, context, scale, width);
  },
};

export const Model = TranscriptsModel.extend({
  parseData(data, chr) {
    this.base(data, chr);
    const { featuresById } = this;
    parseData(data, featuresById);
  },
});

export const Track = Genoverse.Track.extend({
  model: Model,
  view: TranscriptsView.extend({ ...ViewConfig }),
  10000: {
    view: TranscriptsView,
  },
});
