// @flow
import { is } from "ramda";

// generic method for reading a raw chunk at any arbitrary offset/end
export function getChunk(
  file: File,
  offset: number,
  end: number
): Promise<FileReader> {
  return new Promise((resolve, reject) => {
    if (isNaN(offset) || isNaN(end)) {
      return reject(`Invalid range given: ${offset}-${end}`);
    }

    if (end > file.size) {
      return reject("end is past end of file!");
    }

    const reader = new FileReader();
    reader.onloadend = e => {
      if (e.target.readyState !== reader.DONE) {
        return reject(`Bad status: ${e.target.readyState}`);
      }

      if (is(ArrayBuffer, reader.result)) {
        resolve(reader);
      }
      reject("The result is not an ArrayBuffer");
    };

    reader.readAsArrayBuffer(file.slice(offset, end));
  });
}

export function typedArraysEqual(arr1: Uint8Array, arr2: Uint8Array): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (arr1.some((item, index) => arr2[index] !== item)) return false;

  return true;
}
