// @flow
import { createAction } from "../utils";

import * as constants from "./constants";

type ToggleTermVisibilityAction = FSA<
  typeof constants.TOGGLE_TERM_VISIBILITY,
  string
>;

export const toggleTermAsVisible = (
  nodeId: string
): ToggleTermVisibilityAction =>
  createAction(constants.TOGGLE_TERM_VISIBILITY, nodeId);

type RemoveHpoTermAction = FSA<typeof constants.REMOVE_HPO_TERM, string>;

export const removeHpoTerm = (code: string): RemoveHpoTermAction =>
  createAction(constants.REMOVE_HPO_TERM, code);

type AddHpoTermAction = FSA<
  typeof constants.ADD_HPO_TERM,
  { code: string, phenotype: string, presence: string, del: string }
>;

export const addHpoTerm = (data: {
  hpoTermId: string,
  name: string,
}): AddHpoTermAction => {
  const { hpoTermId: code, name: phenotype } = data;

  return createAction(constants.ADD_HPO_TERM, {
    code,
    phenotype,
    presence: "Present",
  });
};

type TryAddHpoTermAction = FSA<
  typeof constants.TRY_ADD_HPO_TERM,
  { code: string, phenotype: string, presence: string, del: string }
>;

export const tryAddHpoTerm = (data: {
  hpoTermId: string,
  name: string,
}): TryAddHpoTermAction => {
  const { hpoTermId: code, name: phenotype } = data;

  return createAction(constants.TRY_ADD_HPO_TERM, {
    code,
    phenotype,
    presence: "Present",
  });
};

type SetSelectedHpoTermsAction = FSA<
  typeof constants.SET_SELECTED_HPO_TERMS,
  Array<{
    code: string,
    phenotype: string,
  }>
>;

export const setSelectedHpoTerms = (
  terms: Array<{
    code: string,
    phenotype: string,
  }>
): SetSelectedHpoTermsAction =>
  createAction(constants.SET_SELECTED_HPO_TERMS, terms);

type SetAllHpoTermsAction = FSA<typeof constants.SET_ALL_HPO_TERMS, Array<{}>>;

export const setAllHpoTerms = (hpoTerms: Array<{}>): SetAllHpoTermsAction =>
  createAction(constants.SET_ALL_HPO_TERMS, hpoTerms);

type HasLoadedAction = FSA<typeof constants.HAS_LOADED>;

export const hasLoaded = (): HasLoadedAction =>
  createAction(constants.HAS_LOADED);

type StartInitAction = FSA<
  typeof constants.START_INIT,
  {},
  { projectId: number | string }
>;

export const startInit = (projectId: number | string): StartInitAction =>
  createAction(constants.START_INIT, {}, { projectId });

type HpoTermsLoadFailedAction = FSA<
  typeof constants.HPO_TERMS_LOAD_FAILED,
  string
>;

export const hpoTermsLoadFailed = (message: string): HpoTermsLoadFailedAction =>
  createAction(constants.HPO_TERMS_LOAD_FAILED, message);

type SetVisibleNodeIdsAction = FSA<
  typeof constants.SET_VISIBLE_NODE_IDS,
  Array<string>
>;

export const setVisibleNodeIds = (
  ids: Array<string>
): SetVisibleNodeIdsAction => createAction(constants.SET_VISIBLE_NODE_IDS, ids);

type SetExpandedNodeIdsAction = FSA<
  typeof constants.SET_EXPANDED_NODE_IDS,
  Array<string>
>;

export const setExpandedNodeIds = (
  ids: Array<string>
): SetExpandedNodeIdsAction =>
  createAction(constants.SET_EXPANDED_NODE_IDS, ids);

type SetQueryAction = FSA<typeof constants.SET_QUERY, string>;

export const setQuery = (query: string = ""): SetQueryAction =>
  createAction(constants.SET_QUERY, query);

type FilterHPOTerms = FSA<typeof constants.FILTER_HPO_TERMS>;

export const filterHpoTerms = (): FilterHPOTerms =>
  createAction(constants.FILTER_HPO_TERMS);

type ResetHPOTermsTree = FSA<typeof constants.RESET_HPO_TERMS_TREE>;

export const resetHPOTermsTree = (): ResetHPOTermsTree =>
  createAction(constants.RESET_HPO_TERMS_TREE);

export type Action =
  | ToggleTermVisibilityAction
  | RemoveHpoTermAction
  | AddHpoTermAction
  | TryAddHpoTermAction
  | SetSelectedHpoTermsAction
  | SetAllHpoTermsAction
  | HasLoadedAction
  | HpoTermsLoadFailedAction
  | StartInitAction
  | SetVisibleNodeIdsAction
  | SetExpandedNodeIdsAction
  | SetQueryAction
  | FilterHPOTerms
  | ResetHPOTermsTree;
