import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";

import { Table } from "pattern-library/elements/table7";
import { DraggableTable } from "pattern-library/elements/table7/DraggableTable";

export const MetadataTable = memo(
  ({
    filter,
    items = [],
    columns = [],
    title,
    draggable = false,
    onDropRow,
    ...otherTableProps
  }) => {
    const [filterValue, setFilterValue] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
      setFilteredItems(filter(items, filterValue));
    }, [items, filterValue, setFilteredItems, filter]);

    const onFilterChange = useCallback(
      ({ filter = "" }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    const onDropRowHandler = useCallback(
      (row, newIndex) => {
        if (filteredItems.length === items.length) {
          return onDropRow(row, ++newIndex);
        }
        if (newIndex === filteredItems.length - 1) {
          return onDropRow(row, items.length);
        }
        return onDropRow(
          row,
          items.findIndex(({ id }) => id === filteredItems[newIndex + 1].id)
        );
      },
      [onDropRow, filteredItems, items]
    );

    const getRowId = row => row.id;

    const props = {
      title,
      fetchData: onFilterChange,
      columns,
      data: filteredItems,
      showPagination: false,
      autoResetPage: false,
      loading: false,
      ...otherTableProps,
    };

    if (draggable) {
      return (
        <DraggableTable
          showTitleInfo
          enableFilter
          disableSortBy
          {...props}
          onDropRow={onDropRowHandler}
          getRowId={getRowId}
        />
      );
    } else {
      return <Table showTitleInfo enableFilter disableSortBy {...props} />;
    }
  }
);

MetadataTable.propTypes = {
  items: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
  filter: PropTypes.func.isRequired,
};
