import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Tooltip } from "pattern-library";

import { formatCriteriaName } from "../../utils";

const TOOLTIP_DISABLED_ACMG =
  "You do not have permission to select or amend ACMG criteria. Please contact your project administrator if this is incorrect";

export default class AcmgCriteria extends PureComponent {
  static propTypes = {
    patientVariant: PropTypes.shape({
      criteria: PropTypes.array,
    }).isRequired,
    criteria: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    classificationType: PropTypes.object,
    lockedCell: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleClick() {
    const {
      updateCriteriaToAdd,
      criteria: { id: criteriaId },
      locked = true,
      disabled,
    } = this.props;

    if (!locked && !disabled) {
      updateCriteriaToAdd(criteriaId);
    }
  }

  render() {
    const {
      patientVariant: { selectedCriteria = [] },
      criteria,
      criteria: { id: criteriaId },
      classificationType = {},
      lockedCell = true,
      disabled,
    } = this.props;

    let {
      criteria: { name: criteriaName },
    } = this.props;

    let style = "primary";
    const currentVariantCriteria = selectedCriteria.find(
      item => item.criteriaId === criteriaId
    );

    if (currentVariantCriteria) {
      style = classificationType.style;

      criteriaName = formatCriteriaName(currentVariantCriteria, criteria);
    } else if (lockedCell || disabled) {
      style = "default";
    }

    const renderedCriteria = (
      <span
        onClick={this.handleClick}
        className={`label label-${style} ${
          disabled || lockedCell === true ? "locked-cell" : ""
        }`}
      >
        {criteriaName}
      </span>
    );

    if (disabled) {
      return (
        <Tooltip content={TOOLTIP_DISABLED_ACMG} placement="top">
          {renderedCriteria}
        </Tooltip>
      );
    }

    return renderedCriteria;
  }
}
