import { Field, useField } from "formik";
import React, { memo } from "react";

import { Divider } from "pattern-library";

import { FormikFormField } from "../../../forms/components";

export const CustomPatientTab = memo(({ isInherited }) => {
  const [{ value: display }] = useField("customPatientTab.display");
  return (
    <>
      <Field
        component={FormikFormField}
        name="customPatientTab.display"
        label="Display"
        type="checkbox"
        description="Check to display custom Patient iframe"
        disabled={isInherited}
      />
      <Divider direction="horizontal" />
      <Field
        component={FormikFormField}
        name="customPatientTab.label"
        label="Tab label"
        description="Label displayed on tab"
        disabled={isInherited || !display}
      />
      <Divider direction="horizontal" />
      <Field
        component={FormikFormField}
        name="customPatientTab.url"
        label="URL"
        description="Iframe hyperlink (Secure https://)"
        disabled={isInherited || !display}
      />
    </>
  );
});
