import { FILE_EXTENSIONS } from "modules/utils/files/readers/constants";
import { FILE_READER_TYPES } from "modules/utils/files/readers/types";

import { genderOptions } from "./components/componentUtils";
import { FORM_FILE_TYPES } from "./types";

export const MODULE_NAME = "interpretationRequests";
export const INIT_IR = "INIT_IR";
export const SUBMIT_IR = "SUBMIT_IR";
export const SET_PROJECT_REQUEST_STATUS = "SET_PROJECT_REQUEST_STATUS";
export const SET_IR_SUBMISSION_STATUS = "SET_IR_SUBMISSION_STATUS";
export const RESET_IR_SUBMISSION_STATUS = "RESET_IR_SUBMISSION_STATUS";
export const SET_PROTOCOLS = "SET_PROTOCOLS";
export const SET_SAMPLE_TYPES = "SET_SAMPLE_TYPES";
export const SET_CNV_CALLING_REQUEST_STATUS = "SET_CNV_CALLING_REQUEST_STATUS";
export const RESET_CNV_CALLING_REQUEST_STATUS =
  "RESET_CNV_CALLING_REQUEST_STATUS";
export const SET_BAITSETS = "SET_BAITSETS";
export const SET_FILES_PROCESSING = "SET_FILES_PROCESSING";

export const COLUMNS = {
  name: "Sample ID",
  isProband: "Proband",
  sex: "Sex",
  affectionStatus: "Affection Status",
  motherName: "Mother ID",
  fatherName: "Father ID",
  familyName: "Family",
  fileTypes: "Sample Files",
  sampleType: "Sample Type",
  protocol: "Protocol",
  tumourType: "Tumour Type",
  dateOfBirth: "Date of Birth",
  files: "Files",
  neoplasticCellularity: "Neoplastic Cellularity (%)",
  baitset: "Baitset",
};

export const FORM_FILE_TYPES_TO_READER_TYPE = {
  VCF: FILE_READER_TYPES.VCF,
  SNV_VCF: FILE_READER_TYPES.SNV_VCF,
  SV_VCF: FILE_READER_TYPES.SV_VCF,
  CNV_VCF: FILE_READER_TYPES.CNV_VCF,
  BAM: FILE_READER_TYPES.BAM,
  FASTQ: FILE_READER_TYPES.FASTQ,
};

export const FILE_TYPES_ARRAY = [
  {
    name: FORM_FILE_TYPES.VCF,
    title: "VCF",
    fileConfigs: [
      {
        type: "vcf",
        subtype: "snv",
        extension: "snv.vcf.gz",
      },
    ],
  },
  {
    name: FORM_FILE_TYPES.SNV_VCF,
    title: "SNV VCF",
    fileConfigs: [
      {
        type: "vcf",
        subtype: "snv",
        extension: "snv.vcf.gz",
        indexFileExt: "tbi",
      },
    ],
  },
  {
    name: FORM_FILE_TYPES.SV_VCF,
    title: "SV VCF",
    fileConfigs: [
      {
        type: "vcf",
        subtype: "sv",
        extension: "sv.vcf.gz",
        indexFileExt: "tbi",
      },
    ],
  },
  {
    name: FORM_FILE_TYPES.CNV_VCF,
    title: "CNV VCF",
    fileConfigs: [
      {
        type: "vcf",
        subtype: "cnv",
        extension: "cnv.vcf.gz",
        indexFileExt: "tbi",
      },
    ],
  },
  {
    name: FORM_FILE_TYPES.BAM,
    title: "BAM",
    fileConfigs: [
      {
        type: "bam",
        subtype: "standard",
        extension: "bam",
        indexFileExt: "bai",
      },
    ],
  },
  {
    name: FORM_FILE_TYPES.FASTQ,
    title: "FASTQ",
    fileConfigs: [
      {
        type: "fastq",
        subtype: "r1",
        extension: "r1.fastq",
      },
      {
        type: "fastq",
        subtype: "r2",
        extension: "r2.fastq",
      },
    ],
  },
];

export const FILE_TYPES = FILE_TYPES_ARRAY.reduce((acc, fileType) => {
  acc[fileType.name] = fileType;
  return acc;
}, {});

export const FILE_TYPE_OPTIONS_ON_PREM = [
  FILE_TYPES[FORM_FILE_TYPES.SNV_VCF],
  FILE_TYPES[FORM_FILE_TYPES.SV_VCF],
  FILE_TYPES[FORM_FILE_TYPES.CNV_VCF],
  FILE_TYPES[FORM_FILE_TYPES.BAM],
];

export const FILE_TYPE_OPTIONS_SAAS = [
  FILE_TYPES[FORM_FILE_TYPES.VCF],
  FILE_TYPES[FORM_FILE_TYPES.BAM],
  FILE_TYPES[FORM_FILE_TYPES.FASTQ],
];

export const ALLOWED_FILE_EXTENSIONS = {
  SAAS: [
    ...FILE_EXTENSIONS.VCF,
    ...FILE_EXTENSIONS.BAM,
    ...FILE_EXTENSIONS.FASTQ,
    ...FILE_EXTENSIONS.CSV,
  ],
  ON_PREM: [
    ...FILE_EXTENSIONS.SNV_VCF,
    ...FILE_EXTENSIONS.SV_VCF,
    ...FILE_EXTENSIONS.CNV_VCF,
    ...FILE_EXTENSIONS.BAM,
    ...FILE_EXTENSIONS.CSV,
  ],
};

export const ONE_MB = 1048576;
export const VCF_FILE_SIZE_THRESHOLD = 1048576;

export const CSV_EMPTY_VALUES = ["", undefined, null];

export const UNSUPPORTED_FILE_TYPE = "Unsupported file types are found:";
export const INVALID_FAMILY_NAME =
  "Invalid family_name provided for samples with the following IDs:";

export const VALID_SEX_VALUES_HINT = `Valid inputs are: ${Object.values(
  genderOptions
).join(", ")}`;
