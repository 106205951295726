// @flow
import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as constants from "./constants";

const uiPath = (...items: any) => path([constants.NAMESPACE, "ui", ...items]);
const dataPath = (...items: any) =>
  path([constants.NAMESPACE, "data", ...items]);

export const isLoading = uiPath("loading");

export const getFields = dataPath("fields");
export const getInheritedFields = dataPath("inheritedFields");
export const getCategories = dataPath("categories");
export const getInheritedCategories = dataPath("inheritedCategories");

export const getSelectedMetadata = uiPath("metadataInfo", "metadata");
export const getSelectedMetadataMode = uiPath("metadataInfo", "mode");

export const getFieldsCount = createSelector(
  getFields,
  (fields = []) => fields.length
);

export const getCategoriesCount = createSelector(
  getCategories,
  (categories = []) => categories.length
);

export const getCategoriesValues = createSelector(
  getCategories,
  (categories = []) =>
    categories.reduce((acc, { id, name }) => {
      acc[id] = name;
      return acc;
    }, {})
);
