import { decamelizeKeys } from "humps";

import { ProjectCuratedVariantList } from "../../../curatedVariantsLists/types";

import {
  EMPTY_AUTOMATION_SETTINGS,
  EMPTY_SETTINGS,
  ENTITY_TYPE,
} from "./constants";
import type {
  ProjectFeaturesSettingsForm,
  ProjectFeatureSettingsForm,
  AutomationSettingsForm,
} from "./types";

export const convertCVLListToSelectFormat = (
  cvlList: Array<Partial<ProjectCuratedVariantList>>
): Array<DropdownOption> =>
  cvlList.reduce((accum: Array<DropdownOption>, cvl) => {
    const { curatedVariantListId, name } = cvl;
    if (curatedVariantListId && name) {
      accum.push({
        value: Number(curatedVariantListId),
        label: name,
        //This will automatically add react-select__option--is-disabled css class to Select option
        //and not eligible CVLs will be hidden
        isDisabled: !isCVLEligibleForAutomatedAnnotationDecisions(cvl),
      });
    }
    return accum;
  }, []);

//Explicit comparison to false is done to allow existing CVLs
//with undefined isEligibleForAutomatedAnnotationDecisions property to be treated as eligible
export const isCVLEligibleForAutomatedAnnotationDecisions = (
  cvl?: Partial<ProjectCuratedVariantList> | null
) => cvl && cvl.isEligibleForAutomatedAnnotationDecisions !== false;

export const convertReportServiceTemplatesToSelectFormat = (
  reportServiceTemplates: ReportServiceTemplate[]
): Array<DropdownOption> =>
  reportServiceTemplates.reduce(
    (accum: Array<DropdownOption>, { id, attributes: { template_name } }) => {
      accum.push({ value: id, label: template_name });
      return accum;
    },
    []
  );

const getFeatureSettings = <T>(
  projectFeature: ProjectFeatureSettings<T>,
  emptySettings = {}
): ProjectFeatureSettingsForm => {
  const { isInherited, settings } = projectFeature;
  return {
    isInherited,
    ...emptySettings,
    ...settings,
  };
};

export const toValues = (
  attributes?: ProjectFeaturesSettings | null
): ProjectFeaturesSettingsForm => {
  const values: ProjectFeaturesSettingsForm = {};
  if (attributes) {
    for (const [settingName, settingValue] of Object.entries(attributes)) {
      if (settingValue) {
        values[settingName] = getFeatureSettings(
          settingValue,
          EMPTY_SETTINGS[settingName]
        );
      }
    }
  }
  return values;
};

const prepareSettings = (
  values: AutomationSettingsForm
): AutomationSettingsPayload => {
  const { isInherited, ...settings } = values;
  const { enabled } = values;

  const newSettings: AutomationSettingsPayload = decamelizeKeys(
    enabled
      ? { ...EMPTY_AUTOMATION_SETTINGS, ...settings }
      : { ...EMPTY_AUTOMATION_SETTINGS }
  );
  if (!newSettings.automated_reporting_enabled) {
    newSettings.report_template_id = null;
  }
  return newSettings;
};

const prepareSettingsPayload = <T>(
  values: ProjectFeatureSettingsForm
): ProjectFeatureSettingsPayload<T> => {
  const { isInherited, ...settings } = values;

  return {
    is_inherited: isInherited,
    settings: decamelizeKeys(settings),
  };
};

export const fromValues = (
  values: ProjectFeaturesSettingsForm,
  projectId: number
): ProjectFeaturesSettingsPayload => {
  const { automation, autoAcmg } = values;
  const attributes: ProjectFeaturesSettingsAttributesPayload = {};
  if (automation) {
    const { isInherited } = automation;
    attributes.automation = {
      settings: prepareSettings(automation),
      is_inherited: isInherited,
    };
  }
  if (autoAcmg) {
    attributes.auto_acmg = prepareSettingsPayload(autoAcmg);
  }

  return {
    data: {
      id: projectId,
      type: ENTITY_TYPE,
      attributes,
    },
  };
};
