import Genoverse from "genoverse";

export const Controller = Genoverse.Track.Controller.extend({
  populateMenu(feature) {
    this.track.getConfig("filter").populateMenu(feature);
  },
});

export const View = Genoverse.Track.View.extend({
  featureHeight: 5,
  labels: true,
  repeatLabels: true,
  bump: true,
});

export const Model = Genoverse.Track.Model.extend({
  insertFeature(feature) {
    feature.label = feature.name;

    if (feature.hi_rgb) {
      feature.hiColor = `rgb(${feature.hi_rgb})`;
    }

    this.base(feature);
  },
});

export const Track = Genoverse.Track.extend({
  id: "congenica-genes",
  name: "Genes",
  namespace: "Congenica.Gene",
  category: "Genes",
  tags: [
    "Genes",
    "HI",
    "Haplo-insufficiency",
    "Developmental",
    "DDG2P",
    "OMIM",
  ],
  height: 100,
  resizable: true,
  model: Model,
  view: View,
  controller: Controller,
});
