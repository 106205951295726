import { path } from "ramda";

import { RootState } from "common/store";

export const dataPathForModule =
  moduleName =>
  (...args) =>
  (state: RootState) =>
    path([moduleName, "data", ...args])(state);

export const uiPathForModule =
  moduleName =>
  (...args) =>
  (state: RootState) =>
    path([moduleName, "ui", ...args])(state);
