import { useField } from "formik";
import PropTypes from "prop-types";
import { concat, equals, trim } from "ramda";
import React, { memo, useCallback } from "react";

import { FormField } from "pattern-library";
import { inputsColSizes } from "pattern-library/modules/form-field/FormField";

import { useEventHandlers } from "./utils";

const formatGroupLabel = data => data.group;

const getValue = (options = [], value) => {
  if (!value) {
    return value;
  }
  let result = options.filter(o =>
    value instanceof Array ? value.includes(o.value) : equals(value, o.value)
  );
  options.forEach(option => {
    if (option.options) {
      const items = getValue(option.options, value);
      if (items) {
        if (items instanceof Array) {
          if (items.length > 0) {
            result = concat(result, items);
          }
        } else {
          result.push(items);
        }
      }
    }
  });
  return result.length === 1 ? result[0] : result;
};

const FormikFormField = memo(properties => {
  const {
    form: { setFieldTouched, setFieldValue, submitCount },
    field: { name },
    narrow = false,
    onChange: userOnChange,
    onBlur: userOnBlur,
    normalize,
    trimOnBlur = false,
    ...props
  } = properties;

  const [field, { error, touched }] = useField(name);
  const [onChange, onBlur, componentValue] = useEventHandlers(properties);

  const propsForFormField = {
    ...field,
    touched: submitCount > 0 || touched,
    error,
    ...props,
    onChange,
    onBlur,
    value: componentValue,
  };

  const { value, options } = propsForFormField;

  if (props.type === "checkbox") {
    propsForFormField.checked = field.value;
    propsForFormField.onChange = event => {
      const value = !!event.target.checked;
      setFieldValue(name, value);
    };
  }

  // hooks need to be used at the function top level
  const setTouched = useCallback(() => {
    if (!touched) {
      setFieldTouched(name, true);
    }
  }, [setFieldTouched, touched, name]);

  const setValue = useCallback(
    val => {
      // a custom event that contains only the target value is used
      setFieldValue(name, val);
    },
    [setFieldValue, name]
  );

  if (["checkboxes", "file"].includes(props.type)) {
    propsForFormField.onBlur = setTouched;
    propsForFormField.onChange = setValue;
  }

  if (props.type === "select") {
    // react-select uses isDisabled instead of disabled
    propsForFormField.isDisabled = props.disabled;
    delete propsForFormField.disabled;
    if (!propsForFormField.formatGroupLabel)
      propsForFormField.formatGroupLabel = formatGroupLabel;
  }

  const datePickerOnChange = useCallback(
    value => {
      setFieldValue(name, value);
      // and then any onchange the consumer wants to call
      if (userOnChange) {
        userOnChange(value);
      }
    },
    [setFieldValue, name, userOnChange]
  );

  if (props.type === "datepicker" || props.type === "date") {
    propsForFormField.onChange = datePickerOnChange;
  }

  const selectOnChange = useCallback(
    value => {
      setFieldValue(
        name,
        value instanceof Array
          ? value.map(({ value: result }) => result)
          : value && value.value
          ? value.value
          : value
      );
      // and then any onchange the consumer wants to call
      if (userOnChange) {
        userOnChange(value);
      }
    },
    [setFieldValue, name, userOnChange]
  );

  if (props.type === "select") {
    propsForFormField.onChange = selectOnChange;
    propsForFormField.value = getValue(options, value);
  }

  const onInputBlur = useCallback(
    async e => {
      const { value } = e.target;
      const trimmedValue = trim(value);
      e.target.value = trimmedValue;
      await onBlur(e);
      if (trimmedValue !== value) {
        setFieldValue(name, trimmedValue);
      }
    },
    [name, setFieldValue, onBlur]
  );

  if (props.type === "text" && trimOnBlur) {
    propsForFormField.onBlur = onInputBlur;
  }

  delete propsForFormField.changeOnBlur;

  return <FormField {...propsForFormField} narrow={narrow} />;
});

FormikFormField.displayName = "FormikFormField";

FormikFormField.propTypes = {
  /**
   * An object containing onChange, onBlur, name, and value of the field
   * see https://jaredpalmer.com/formik/docs/api/field
   */
  field: PropTypes.object.isRequired,
  /**
   * Formik state and helpers
   * see https://jaredpalmer.com/formik/docs/api/field
   */
  form: PropTypes.object.isRequired,
  /**
   * See description about the prop there:
   * `sapientia-frontend/src/pattern-library/modules/form-field/FormField.js`
   */
  narrow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(inputsColSizes),
  ]),
  /**
   * onChange handler
   */
  onChange: PropTypes.func,
  /**
   * Flag indicates whether to handle onChange on onBlur stage
   * Field value will be cached and won't be written to the form until blur event
   */
  changeOnBlur: PropTypes.bool,
};

export default FormikFormField;
