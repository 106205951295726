import { connect } from "react-redux";
import { reduxForm, InjectedFormProps } from "redux-form";

import { SV_FILTERS_FORM } from "../constants";
import * as selectors from "../selectors";

import SVConfigEditor from "./SVConfigEditor";
import SVLocationContainer from "./SVLocationContainer";

interface Props {
  projectId: number;
  filterData?: Index;
  submit?: () => void;
  patientId: number;
}

export const SVFilters = ({
  projectId,
  handleSubmit,
  submit,
  patientId,
}: Props & InjectedFormProps<Index, Props>) => (
  <form onSubmit={handleSubmit}>
    <div className="sv-form-filters">
      <SVLocationContainer onChange={submit} patientId={patientId} />
      <SVConfigEditor projectId={projectId} onChange={submit} />
    </div>
  </form>
);

const ConnectedForm = reduxForm<Index, Props>({
  form: SV_FILTERS_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SVFilters);

const mapStateToProps = state => ({
  initialValues: selectors.getFilters(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);
