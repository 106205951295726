import { isNil, isEmpty } from "ramda";
import React, { memo } from "react";

import {
  Icon,
  Loading,
  Pane,
  GeneScore,
  Tooltip,
  LegacyLink,
} from "pattern-library";

import {
  afFormat,
  formatInSilicoScores,
  formatVariantColumnValue,
} from "modules/config/utils";
import { getVepConsequenceLabel } from "modules/utils/common";
import { granthamSubstitution } from "modules/utils/grantham";
import variants from "modules/variants/reducer.data";

import QCStatus from "../variants/components/columns/QCStatus";

import AminoAcid from "./components/AminoAcid";
import ColocatedVariants from "./components/ColocatedVariants";
import OtherPatientsCounts from "./components/OtherPatientsCounts";
import SpliceSite from "./components/SpliceSite";
import { getFieldBaseHref } from "./components/utils";
import {
  Cases,
  Fields,
  Icons,
  Labels,
  Order,
  PrimaryFields,
  HIDE_IF_NULL,
  ColspanFields,
  GeneFields,
  SnvFields,
  VARIANT_PANEL_KEYS,
} from "./constants";
import {
  SummaryData,
  SummaryField,
  SummaryProps,
  SummaryRequestParameters,
  SummaryValues,
} from "./types";

const getValues = (values: SummaryValues) => {
  const list = Object.entries(values).filter(([_, value]) => !isNil(value));
  if (list.length) {
    return list.map(([key, value]) => (
      <div key={key} data-testid={key}>
        {value}
      </div>
    ));
  }
};

const getKey = (
  field: Fields,
  props: SummaryProps & SummaryRequestParameters
) => {
  const icon = Icons[field];
  const label = Labels[field];

  if (icon) {
    const { type, tooltip } = icon;
    const content = typeof tooltip === "string" ? tooltip : tooltip(props);
    return (
      <>
        <span className="icon-label">{label}</span>
        <Tooltip content={content} placement="bottom">
          <Icon type={type} data-testid={`${field}-icon`} />
        </Tooltip>
      </>
    );
  }
  return label;
};

const getValue = (
  field: Fields,
  data: SummaryData,
  props: SummaryProps & SummaryRequestParameters
): string => {
  let value;
  const {
    patientId,
    geneName,
    variantId,
    transcriptId,
    thirdpartySoftwareVersions,
    isSpliceAiFeatureEnabled,
    path,
  } = props;
  switch (field) {
    case Fields.GENE_NAME:
      value = data.name;
      break;
    case Fields.HI_SCORE:
      value = !isNil(data.hiScore) ? (
        <GeneScore
          value={data.hiScore}
          color={data.hiRgb ? `rgb(${data.hiRgb})` : null}
        />
      ) : null;
      break;

    case Fields.GENOTYPE:
      value = data.genotype;
      break;
    case Fields.HGVSC:
      value = data.hgvsC;
      break;
    case Fields.HGVSP:
      value = data.hgvsP;
      break;
    case Fields.TRANSCRIPT:
      value = data.transcriptName;
      break;
    case Fields.DEPTH:
      value = data.depth;
      break;
    case Fields.MAX_AF:
      const linkProps = {
        href: `${getFieldBaseHref({
          patientId,
          geneName,
          variantId,
          transcriptId,
        })}af`,
        attrs: { additionalContainerClasses: "modal2" },
      };
      value = !isNil(data.maxAf) ? (
        <LegacyLink {...linkProps}>{afFormat(data.maxAf)}</LegacyLink>
      ) : null;
      break;
    case Fields.COLOCATED_VARIANTS:
      value = (
        <ColocatedVariants
          colocatedVariantsIds={{
            rsId: data.rsId,
            cosmicId: data.cosmicId,
            hgmdId: data.hgmdId,
            espId: data.espId,
          }}
          geneName={data.name}
        />
      );
      break;
    case Fields.OTHER_PATIENTS_SNV_COUNTS:
      value = variantId ? (
        <OtherPatientsCounts
          patientId={patientId}
          variantId={variantId}
          path={path}
          transcriptId={transcriptId}
          geneName={geneName}
        />
      ) : null;
      break;
    case Fields.QUALITY_SCORE:
      value = formatVariantColumnValue(data.qualityScore);
      break;
    case Fields.CODONS:
      value = data.codons;
      break;
    case Fields.AMINO_ACID:
      value = <AminoAcid value={data.aminoAcid} />;
      break;
    case Fields.GRANTHAM_SUBSTITUTION:
      const { score, classification } = granthamSubstitution(
        data?.aminoAcid?.split("/")
      );
      value = score ? `${score} (${classification})` : null;
      break;
    case Fields.PROTEIN_POSITION:
      value = data.proteinPosition;
      break;
    case Fields.GERP:
      value = formatVariantColumnValue(data.gerp, 2);
      break;
    case Fields.READ_SPLIT:
      value = data.readSplit;
      break;
    case Fields.REVEL:
      value = formatInSilicoScores(data.revel);
      break;
    case Fields.QC_STATUS:
      value = <QCStatus filter={data.qcStatus} />;
      break;
    case Fields.POLYPHEN_PREDICTION:
      value = getValues({
        polyphenPrediction: data.polyphenPrediction,
        polyphenScore: formatInSilicoScores(data.polyphenScore),
      });
      break;
    case Fields.SIFT_PREDICTION:
      value = getValues({
        siftPrediction: data.siftPrediction,
        siftScore: formatInSilicoScores(data.siftScore),
      });
      break;
    case Fields.VEP_CONSEQUENCE:
      value = getVepConsequenceLabel(data.vepConsequence);
      break;
    case Fields.INHERITANCE:
      value = getValues({
        inheritance: data.inheritance
          ? variants.allInheritanceValues[data.inheritance]
          : "",
        denovoStatus: data.denovoStatus,
      });
      break;
    case Fields.BASE_CHANGE:
      if (data.refAllele && data.altAllele) {
        value = `${data.refAllele} > ${data.altAllele}`;
      } else {
        value = data.refAllele || data.altAllele;
      }
      break;
    case Fields.COMPARISON_OUTCOME:
      const comparisonLinkProps = {
        href: `${getFieldBaseHref({
          patientId,
          geneName,
          variantId,
          transcriptId,
        })}comparison`,
        attrs: { additionalContainerClasses: "modal2" },
      };

      const comparisonDisplay = data.comparisonOutcome
        ? variants.allFamilyComparisonValues[data.comparisonOutcome] ||
          data.comparisonOutcome
        : "";

      value = data.comparisonOutcome ? (
        <LegacyLink {...comparisonLinkProps}>{comparisonDisplay}</LegacyLink>
      ) : null;
      break;
    case Fields.SPLICE_SITE:
      value = data.splice ? (
        <SpliceSite
          data={data.splice}
          isSpliceAiFeatureEnabled={isSpliceAiFeatureEnabled}
          thirdpartySoftwareVersions={thirdpartySoftwareVersions}
        />
      ) : null;
      break;
    default:
      console.error(`Summary Field: ${field}: have no binding to state value`);
  }
  return !isNil(value) ? value : Labels[Cases.DATA_NOT_FOUND];
};

const byOrder = (a, b) => Order.indexOf(a) - Order.indexOf(b);

const getFields = (
  props: SummaryProps & SummaryRequestParameters
): Array<Fields> => {
  const { transcriptId, variantId } = props;
  return transcriptId && variantId ? SnvFields : GeneFields;
};

const getData = (
  data: SummaryData,
  props: SummaryProps & SummaryRequestParameters
): Array<SummaryField> =>
  getFields(props)
    .sort(byOrder)
    .map(field => ({
      id: field,
      key: getKey(field, props),
      value: getValue(field, data, props),
      colspan: ColspanFields.includes(field),
    }))
    .filter(({ value, id }) => value || !HIDE_IF_NULL.includes(id));

const getVariantPanelFields = (variantPanelKeys: Array<string>, allFields) =>
  variantPanelKeys
    .map(variantPanelKey =>
      allFields.find(({ id }) => id === VARIANT_PANEL_KEYS[variantPanelKey])
    )
    .filter(Boolean);

function getPanelStructure(
  data: SummaryData,
  props: SummaryProps & SummaryRequestParameters
) {
  const { isVariantPanel, variantPanelConfig } = props;
  const primaryData: SummaryField[] = [];
  const restData: SummaryField[] = [];

  const fields = getData(data, props);

  if (isVariantPanel) {
    const [leftColumnConfig = [], rightColumnConfig = []] =
      variantPanelConfig || [];

    primaryData.push(...getVariantPanelFields(leftColumnConfig, fields));
    restData.push(...getVariantPanelFields(rightColumnConfig, fields));
  } else {
    fields.forEach(element =>
      PrimaryFields.includes(element.id)
        ? primaryData.push(element)
        : restData.push(element)
    );
  }

  return { primaryData, restData };
}

const Summary = memo((props: SummaryProps & SummaryRequestParameters) => {
  const { loading, data } = props;

  if (loading) {
    return <Loading />;
  }

  const { primaryData, restData } = getPanelStructure(data, props);

  return (
    <div
      data-testid="sequence-variants-summary"
      className="sequence-variants-summary row"
    >
      {!isEmpty(primaryData) && (
        <div className="col-md-6" data-testid="summary-left-column">
          <Pane data={primaryData} border />
        </div>
      )}
      {!isEmpty(restData) && (
        <div className="col-md-6" data-testid="summary-right-column">
          <Pane data={restData} border />
        </div>
      )}
    </div>
  );
});

export default Summary;
