import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { ComposableTabs, Tab } from "pattern-library";

import {
  getFoundGenesCount,
  getNotFoundGenesCount,
} from "../../../../selectors";

import GenesFoundTable from "./GenesFoundTable";
import GenesNotFoundTable from "./GenesNotFoundTable";
import NameAndDescriptionForm from "./NameAndDescriptionForm";

interface Props {
  projectId: number;
  tempGenePanelId: number;
  onClose: () => void;
}

const UploadGenesTabs = ({
  projectId,
  tempGenePanelId,
  onClose,
  foundGenesCount,
  notFoundGenesCount,
}: Props & PropsFromRedux) => {
  const tabs = useMemo(
    () => [
      {
        component: GenesFoundTable,
        name: "Genes found",
        count: foundGenesCount,
        props: { projectId, tempGenePanelId, onClose },
      },
      {
        component: GenesNotFoundTable,
        name: "Genes not found",
        count: notFoundGenesCount,
        props: { projectId, tempGenePanelId, onClose },
      },
      {
        component: NameAndDescriptionForm,
        name: "Name and description",
        props: { projectId, tempGenePanelId, onClose },
      },
    ],
    [projectId, tempGenePanelId, onClose, foundGenesCount, notFoundGenesCount]
  );

  return (
    <ComposableTabs navStyle>
      {tabs.map(tab => {
        const {
          name,
          component: Component,
          props = {} as Props,
          ...rest
        } = tab;
        return (
          <Tab key={name} name={name} {...rest}>
            <Component {...props} />
          </Tab>
        );
      })}
    </ComposableTabs>
  );
};

const mapStateToProps = state => ({
  foundGenesCount: getFoundGenesCount(state),
  notFoundGenesCount: getNotFoundGenesCount(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UploadGenesTabs);
