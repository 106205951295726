import React, { useCallback, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import {
  ComposableTabs,
  Tab,
  Modal,
  ModalBody,
  MODAL_SIZES,
} from "pattern-library";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";
import { getCurrentProject } from "modules/project/selectors";

import { clearAddState } from "../../actions";

import AddNewGenePanelTab from "./tabs/AddNewGenePanelTab";
import CopyExistingPanelTab from "./tabs/CopyExistingPanelTab";
import UploadGenePanelTab from "./tabs/UploadGenePanelTab";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.ADD_GENE_PANEL_MODAL);

type AddGenePanelModalProps = PropsFromRedux;

const AddGenePanelModal = ({
  currentProject,
  hideModal,
  clearAddState,
}: AddGenePanelModalProps) => {
  const projectId = (currentProject && currentProject.projectId) || 0; // Zero means project is not in redux state

  const onCloseHandler = useCallback(() => {
    hideModal(modalTypes.ADD_GENE_PANEL_MODAL);
    clearAddState();
  }, [hideModal, clearAddState]);

  const { ensemblVersion: activeEnsemblVersion } = useEnsemblVersion();

  const tabs = useMemo(() => {
    const onClose = onCloseHandler;
    return [
      {
        component: AddNewGenePanelTab,
        name: "Add new panel",
        props: {
          projectId,
          onClose,
          initialValues: { ensemblVersion: activeEnsemblVersion },
        },
      },
      {
        component: CopyExistingPanelTab,
        name: "Copy existing panel",
        props: { projectId, onClose },
      },
      {
        component: UploadGenePanelTab,
        name: "Upload gene panel file",
        props: { projectId, onClose },
      },
    ];
  }, [projectId, onCloseHandler, activeEnsemblVersion]);

  if (projectId === 0) {
    return (
      <Modal show close={onCloseHandler} size={MODAL_SIZES.XL}>
        <ModalBody>
          <div className="pull-right">
            <button
              type="button"
              className="close"
              onClick={onCloseHandler}
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div>
            <h4>Project is not loaded properly</h4>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal show close={onCloseHandler} size={MODAL_SIZES.XL}>
      <ModalBody>
        <div className="pull-right">
          <button
            type="button"
            className="close"
            onClick={onCloseHandler}
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
        </div>

        <h4 className="header-container">
          Add gene panel{" "}
          <div className="header-description">
            {" "}
            {currentProject && (
              <small title={currentProject.code}>{currentProject.code}</small>
            )}
          </div>
        </h4>

        <ComposableTabs navStyle>
          {tabs.map(tab => {
            const { name, component: Component, props = {} } = tab;
            return (
              <Tab key={name} name={name}>
                <Component {...(props as any)} />
              </Tab>
            );
          })}
        </ComposableTabs>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => ({
  currentProject: getCurrentProject(state),
});
const mapDispatchToProps = { hideModal, clearAddState };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddGenePanelModal);
