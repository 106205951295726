import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Icon } from "../../elements";

import Footer from "./Footer";
import MetaInfo from "./MetaInfo";

export default class Article extends PureComponent {
  static displayName = "Article";

  static propTypes = {
    /**
     * Article view mode
     */
    articleState: PropTypes.oneOf(["normal", "stripped-down"]).isRequired,

    /**
     * Article title
     */
    title: PropTypes.string.isRequired,

    /**
     * Article content
     */
    content: PropTypes.node.isRequired,

    /**
     * Article authors
     */
    authors: PropTypes.string.isRequired,

    /**
     * Article journal
     */
    journal: PropTypes.string.isRequired,

    /**
     * Article doi
     */
    doi: PropTypes.string.isRequired,

    /**
     * Article category
     */
    category: PropTypes.string.isRequired,

    /**
     * Determines whether the bookmark is set or not
     */
    bookmarked: PropTypes.bool,

    /**
     * Bookmark info
     */
    bookmarkInfo: PropTypes.shape({
      /**
       * Date of a bookmark setting
       */
      date: PropTypes.string.isRequired,
      /**
       * Time of a bookmark setting
       */
      time: PropTypes.string.isRequired,
      /**
       * Author of a bookmark setting
       */
      author: PropTypes.string.isRequired,
    }),

    /**
     * link click handler
     *  - stripped down: entire thing is a link
     *  - normal: Title is clickable
     */
    onLinkClick: PropTypes.func,

    /**
     * bookmark flag click handler that should modify section bookmarked/not bookmarked state
     */
    onBookmarkClick: PropTypes.func,

    /**
     * metadata (key value pairs)
     * possible keys:
     *  - created
     *  - updated
     *  - xml
     *  - url
     *  - date_publication
     *  - date_publication_year
     *  - pubmed_publication_id
     *  - pmid
     */
    metadata: PropTypes.shape({
      pmid: PropTypes.number,
      date_publication_year: PropTypes.string,
    }).isRequired,

    /**
     * Content to render inside the component with bookmarked property is true
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    bookmarked: false,
    bookmarkInfo: false,
    onBookmarkClick: false,
    onLinkClick: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onLinkClick(e) {
    e.preventDefault();
    const { onLinkClick } = this.props;
    if (onLinkClick !== false) onLinkClick();
  }

  onCommentsKeyDown(e) {
    if (e.keyCode === 13) {
      const {
        addComments,
        metadata: { pmid },
      } = this.props;
      e.preventDefault();
      const { comments } = this.state;
      if (e.ctrlKey) {
        this.setState({
          comments: `${comments}\n`,
        });
      } else {
        addComments(pmid, comments);
      }
    }
  }

  render() {
    const {
      articleState,
      title,
      content,
      bookmarked,
      children,
      ...otherProps
    } = this.props;

    if (articleState === "normal") {
      return (
        <div className="article article-normal">
          <MetaInfo
            mode="normal"
            onLinkClick={this.onLinkClick}
            bookmarked={bookmarked}
            {...otherProps}
          />
          <div className="article-line article-normal-line" />
          <div className="article-content article-normal-content">
            <div className="article-normal-title" onClick={this.onLinkClick}>
              {title}
            </div>
            <div>{content}</div>
            <Footer className="article-normal-footer" {...otherProps} />
            {bookmarked === true && children}
          </div>
        </div>
      );
    }
    return (
      <div className="article article-stripped-down" onClick={this.onLinkClick}>
        <div className="article-line article-stripped-down-line" />
        <div className="article-content article-stripped-down-content">
          {bookmarked === true && (
            <Icon
              type="bookmark"
              className="article-flag article-stripped-down-flag"
            />
          )}
          <div className="article-stripped-down-title">{title}</div>
        </div>
      </div>
    );
  }
}
