import { Field, useField } from "formik";
import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";
import { connect } from "react-redux";

import { FormikFormField } from "modules/forms/components";
import {
  getProtocolsAsOptions,
  getSampleTypesAsOptions,
} from "modules/interpretationRequests/selectors";
import { getMetadataFields } from "modules/metadata/selectors";

export const MetadataForm = memo(
  ({
    selectedRowNumber,
    fields,
    protocols,
    sampleTypes,
    disabled = false,
    cnvAnalysisRequested,
  }) => {
    const formFields = useMemo(() => {
      const renderField = field => {
        const { key, name, required, field_name, type, ...rest } = field;
        const props = {
          key,
          id: `samples.${selectedRowNumber}.metadata.${name}`,
          name: `samples.${selectedRowNumber}.metadata.${name}`,
          type,
          required,
          component: FormikFormField,
          label: field_name,
          disabled,
          ...rest,
        };
        if (type === "select") {
          props.isMulti = false;
        }
        return <Field narrow {...props} />;
      };

      const result = fields.map(renderField);
      result.unshift(
        <Field
          narrow
          key={`samples.${selectedRowNumber}.protocol`}
          id={`samples.${selectedRowNumber}.protocol`}
          name={`samples.${selectedRowNumber}.protocol`}
          type="dropdown"
          label="Protocol"
          disabled={disabled}
          required={cnvAnalysisRequested}
          canBeEmpty
          component={FormikFormField}
          options={protocols}
        />
      );
      result.unshift(
        <Field
          narrow
          key={`samples.${selectedRowNumber}.sampleType`}
          id={`samples.${selectedRowNumber}.sampleType`}
          name={`samples.${selectedRowNumber}.sampleType`}
          type="dropdown"
          label="Sample Type"
          disabled={disabled}
          required={cnvAnalysisRequested}
          canBeEmpty
          component={FormikFormField}
          options={sampleTypes}
        />
      );
      return result;
    }, [
      selectedRowNumber,
      cnvAnalysisRequested,
      fields,
      disabled,
      protocols,
      sampleTypes,
    ]);

    const prepareFields = fields => {
      const middleOfFields = Math.ceil(fields.length / 2);
      const firstFields = fields.slice(0, middleOfFields);
      const secondFields = fields.slice(middleOfFields);

      return {
        firstFields,
        secondFields,
      };
    };

    const { firstFields = [], secondFields = [] } = useMemo(
      () => prepareFields(formFields),
      [formFields]
    );

    return (
      <div className="form-horizontal">
        <div className="row">
          <div className="col-xs-6">{firstFields}</div>
          <div className="col-xs-6">{secondFields}</div>
        </div>
      </div>
    );
  }
);

const MetadataFormContainer = props => {
  const { selectedRowNumber } = props;
  const [{ value: cnvAnalysisRequested }] = useField(
    `samples.${selectedRowNumber}.cnvAnalysisRequested`
  );

  return (
    <MetadataForm cnvAnalysisRequested={cnvAnalysisRequested} {...props} />
  );
};

const mapStateToProps = state => ({
  fields: getMetadataFields(state),
  protocols: getProtocolsAsOptions(state),
  sampleTypes: getSampleTypesAsOptions(state),
});

MetadataForm.displayName = "MetadataForm";

MetadataForm.propTypes = {
  /**
   * Selected sample index
   */
  selectedRowNumber: PropTypes.number.isRequired,
  /**
   * Metadata fields
   */
  fields: PropTypes.array,
  /**
   * the options for the Protocol field
   */
  protocols: PropTypes.object.isRequired,
  /**
   * the options for the Sample type field
   */
  sampleTypes: PropTypes.object.isRequired,
  /**
   * if true metadata fields should not be editable
   */
  disabled: PropTypes.bool.isRequired,
  /**
   * indicates if CNV analysis request should be issued
   */
  cnvAnalysisRequested: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(MetadataFormContainer);
