import { Track } from "../core/StructuralVariantsGnomAD";
import { Colors } from "../utils";

const Constants = {
  GNOMAD_API: "https://gnomad.broadinstitute.org",
  GNOMAD_PREFIX: "gnomAD-SV_v2",
  GNOMAD_DATA_SET: "gnomad_sv_r2_1",
  GNOMAD_MAX_CONSEQUENCES: 3,
  LOW_AF_THRESHOLD: 0.01,
};

// Types, PopulationTypes, EvidenceTypes are mapped to API keys

const Types = {
  BREAKEND: "BND",
  DELETION: "DEL",
  DUPLICATION: "DUP",
  MULTI_CNV: "MCNV",
  INSERTION: "INS",
  COMPLEX: "CPX",
  INVERSION: "INV",
  TRANSLOCATION: "CTX",
};

const PopulationTypes = {
  AFRICAN_AMERICAN: "afr",
  LATINO: "amr",
  EAST_ASIAN: "eas",
  EUROPEAN: "eur",
  OTHER: "oth",
};

const EvidenceTypes = {
  B_ALLELE_FREQUENCY: "BAF",
  PAIRED_END: "PE",
  READ_DEPTH: "RD",
  SPLIT_READS: "SR",
};

const Labels = {
  DELETION: "Deletion",
  DUPLICATION: "Duplication",
  OTHER: "Insertion / Inversion / Complex variants / Other",
  POPULATION: "Population",
  ALLELE_FREQUENCY: "Allele Frequency",
  ALLELE_COUNT: "Allele Count",
  ALLELE_NUMBER: "Allele Number",
  NUMBER_OF_HOMOZYGOTES: "Number of Homozygotes",
  POPULATION_AFR: "African & Afr. American",
  POPULATION_AMR: "Latino",
  POPULATION_EAS: "East Asian",
  POPULATION_EUR: "European",
  POPULATION_OTH: "Other",
  ALL: "All",
  LOW_AF: "Allele Frequency below 1%",
  DELETIONS_ONLY: "Deletions only",
  DUPLICATION_ONLY: "Duplications only",
};

const Classifications = {
  [Types.BREAKEND]: "Breakend",
  [Types.DELETION]: "Deletion",
  [Types.DUPLICATION]: "Duplication",
  [Types.MULTI_CNV]: "Multi CNV",
  [Types.INSERTION]: "Insertion",
  [Types.COMPLEX]: "Complex",
  [Types.INVERSION]: "Inversion",
  [Types.TRANSLOCATION]: "Translocation",
};

const Evidences = {
  [EvidenceTypes.B_ALLELE_FREQUENCY]: "Normalized B-allele frequency",
  [EvidenceTypes.PAIRED_END]: "Anomalous paired-end reads",
  [EvidenceTypes.READ_DEPTH]: "Read depth",
  [EvidenceTypes.SPLIT_READS]: "Split reads",
};

const Controls = {
  TYPE: "typeFilter",
  FREQUENCY: "frequencyFilter",
};

const TypeFilters = {
  ALL: 1,
  DELETION: 2,
  DUPLICATION: 3,
};

const FrequencyFilters = {
  ALL: 1,
  LOW_AF: 2,
};

const createGnomadLink = id =>
  `${Constants.GNOMAD_API}/variant/${id}?dataset=${Constants.GNOMAD_DATA_SET}`;

const createGnomadName = (chr, id) =>
  id.replace(`${Constants.GNOMAD_PREFIX}.${chr}_`, "");

const createTitle = ({ chr, id }) => ({
  label: createGnomadName(chr, id),
  url: createGnomadLink(createGnomadName(chr, id)),
  target: "_blank",
});

const createConsequences = ({ chr, id, consequences }) => {
  const link = createGnomadLink(createGnomadName(chr, id));
  const result = [];
  const target = "_blank";
  consequences.every((name, i) => {
    if (i < Constants.GNOMAD_MAX_CONSEQUENCES) {
      return result.push({
        label: name,
        url: link,
        target,
      });
    }
    const hiddenCount = consequences.length - Constants.GNOMAD_MAX_CONSEQUENCES;
    result.push({
      label: `and ${hiddenCount} more`,
      url: link,
      target,
    });
    return false;
  });
  return result;
};

const createEvidences = ({ evidences }) =>
  evidences.map(evidence => ({
    label: Evidences[evidence] || evidence,
  }));

const createClassification = ({ svtype }) => ({
  label: Classifications[svtype] || svtype,
});

export const createTrackConfig = () => ({
  id: "Congenica.StructuralVariantsGnomAD",
  namespace: "StructuralVariantsGnomAD",
  name: "gnomAD (v2.1): Structural variants",
  info: "The gnomAD structural variant (SV) v2.1 data set includes 10,847 genomes from unrelated individuals sequenced as part of various disease-specific and population genetic studies.",
  url: "/webapi/entities/reference_data/gnomad/sv/2.1?chr=__CHR__&start=__START__&end=__END__",
  breakdownUrl:
    "/webapi/entities/reference_data/gnomad/sv/2.1/breakdown?chr=__CHR__&start=__START__&end=__END__&id=__ID__",
  threshold: 2e7,
  dataRequestLimit: 2e7,
  populationLabels: [
    Labels.POPULATION,
    Labels.ALLELE_FREQUENCY,
    Labels.ALLELE_COUNT,
    Labels.ALLELE_NUMBER,
    Labels.NUMBER_OF_HOMOZYGOTES,
  ],
  populations: [
    { prefix: PopulationTypes.AFRICAN_AMERICAN, name: Labels.POPULATION_AFR },
    { prefix: PopulationTypes.LATINO, name: Labels.POPULATION_AMR },
    { prefix: PopulationTypes.EAST_ASIAN, name: Labels.POPULATION_EAS },
    { prefix: PopulationTypes.EUROPEAN, name: Labels.POPULATION_EUR },
    { prefix: PopulationTypes.OTHER, name: Labels.POPULATION_OTH },
  ],
  controls: [
    `<select data-control="${Controls.TYPE}">
      <option value="${TypeFilters.ALL}">${Labels.ALL}</option>;;
      <option value="${TypeFilters.DELETION}">${Labels.DELETION}</option>;
      <option value="${TypeFilters.DUPLICATION}">${Labels.DUPLICATION}</option>;
    </select>`,
    `<select data-control="${Controls.FREQUENCY}">
      <option value="${FrequencyFilters.ALL}">${Labels.ALL}</option>;
      <option value="${FrequencyFilters.LOW_AF}">${Labels.LOW_AF}</option>;
    </select>`,
  ],
  defaultConfig: {
    typeFilter: TypeFilters.ALL,
    frequencyFilter: FrequencyFilters.ALL,
  },
  configSettings: {
    typeFilter: {
      [TypeFilters.ALL]: {
        featureFilter: false,
      },
      [TypeFilters.DELETION]: {
        featureFilter: ({ svtype }) => svtype === Types.DELETION,
      },
      [TypeFilters.DUPLICATION]: {
        featureFilter: ({ svtype }) => svtype === Types.DUPLICATION,
      },
      [TypeFilters.OTHER]: {
        featureFilter: ({ svtype }) =>
          ![Types.DELETION, Types.DUPLICATION].includes(svtype),
      },
    },
    frequencyFilter: {
      [FrequencyFilters.ALL]: {
        featureFilter: false,
      },
      [FrequencyFilters.LOW_AF]: {
        featureFilter: ({ af }) => af < Constants.LOW_AF_THRESHOLD,
      },
    },
  },
  menuElements: {
    title: createTitle,
    consequence: createConsequences,
    evidence: createEvidences,
    classification: createClassification,
  },
  colors: [
    {
      predicate: ({ svtype }) => svtype === Types.DELETION,
      legend: Labels.DELETION,
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: ({ svtype }) => svtype === Types.DUPLICATION,
      legend: Labels.DUPLICATION,
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    {
      predicate: () => true,
      legend: Labels.OTHER,
      color: Colors.GREY,
      labelColor: Colors.WHITE,
    },
  ],
});

export const StructuralVariantsGnomAD = () => Track.extend(createTrackConfig());
