import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { memo, useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect, ConnectedProps } from "react-redux";

import { Alert, Breadcrumbs, Loading } from "pattern-library";

import { getSingleProject } from "modules/project/selectors";
import { isCVLEligibleForAutomatedAnnotationDecisions } from "modules/projectSettings/components/automation/utils";

import catalystApi from "../../api/catalyst-api";
import congenicaApi from "../../api/congenica-api";
import { ProjectCuratedVariantList } from "../curatedVariantsLists/types";
import {
  isMetadataLoadCompleted,
  isMetadataLoading,
} from "../metadata/selectors";

import { initIR } from "./actions";
import IRForm from "./components/IRForm";
import { isIRv2, isProjectLoadCompleted, isProjectLoading } from "./selectors";

import useProjectAutomationCVLs from "hooks/useProjectAutomationCVLs";

const UploadBreadcrumbs = ({ project }) => {
  const { code, projectId } = project || {};

  return (
    <>
      {code && (
        <Breadcrumbs
          crumbs={[
            {
              url: "/projects",
              name: "Projects",
            },
            {
              url:
                (window as any).isStandaloneReact === false
                  ? `/projects#my-projects/${projectId}`
                  : `/projects/${projectId}/patients`,
              name: code,
            },
            {
              name: "IR Submission",
            },
          ]}
        />
      )}
    </>
  );
};

UploadBreadcrumbs.propTypes = {
  project: PropTypes.shape({
    code: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

interface ProjectAutomationCvlWarningProps {
  projectId: number;
  projectCurrentUser?: ProjectCurrentUser;
}
const ProjectAutomationCvlWarning = ({
  projectId,
  projectCurrentUser,
}: ProjectAutomationCvlWarningProps) => {
  const { canAdminProject = false, tabs = {} } = projectCurrentUser || {};
  const isAutomationTabAvailableForCurrentUser =
    canAdminProject && tabs.automation;

  const projectSettingsLink = (
    <>
      Please add valid CVL to{" "}
      <a href={`/projects/${projectId}/settings/automation`}>
        Project Automation
      </a>
    </>
  );

  return (
    <div className="header">
      <Alert severity="warning" icon>
        &nbsp; The project&apos;s Automation CVL contains outdated data. New
        interpretation requests cannot be submitted until the Automation CVL is
        fixed.{" "}
        {isAutomationTabAvailableForCurrentUser
          ? projectSettingsLink
          : "Please contact your Project Administrator."}
      </Alert>
    </div>
  );
};

const { useGetProjectFeaturesSettingsQuery } = congenicaApi;
const { useGetProjectCurrentUserQuery } = catalystApi;

interface IRContainerProps extends ConnectedIRContainerProps {
  featuresSettings?: ProjectFeaturesSettings;
  featuresSettingsLoading: boolean;
  featuresSettingsLoaded: boolean;
  cvlList: Array<ProjectCuratedVariantList>;
  cvlListLoading?: boolean;
  cvlListLoaded?: boolean;
  projectCurrentUser?: ProjectCurrentUser;
  projectCurrentUserLoading: boolean;
  projectCurrentUserLoaded: boolean;
}
export const IRContainer = memo(
  ({
    projectId,
    project,
    initIR,
    isProjectLoading,
    isMetadataLoading,
    isProjectLoadCompleted,
    isMetadataLoadCompleted,
    isIRv2,
    featuresSettings,
    featuresSettingsLoading,
    featuresSettingsLoaded,
    cvlList = [],
    cvlListLoading,
    cvlListLoaded,
    projectCurrentUser,
    projectCurrentUserLoading,
    projectCurrentUserLoaded,
  }: IRContainerProps) => {
    const shouldCheckProjectAutomationCVL = useMemo(
      () => featuresSettings?.automation?.settings?.enabled,
      [featuresSettings]
    );

    const isProjectAutomationCVLEligibleForAutomatedAnnotationDecisions =
      useMemo(() => {
        if (!shouldCheckProjectAutomationCVL) return true;
        const projectAutomationCVL = cvlList.find(
          ({ curatedVariantListId }) =>
            Number(
              featuresSettings?.automation?.settings?.curatedVariantListId
            ) === Number(curatedVariantListId)
        );
        return isCVLEligibleForAutomatedAnnotationDecisions(
          projectAutomationCVL
        );
      }, [featuresSettings, cvlList, shouldCheckProjectAutomationCVL]);

    const isDataLoading = useMemo(() => {
      if (isNil(isIRv2)) {
        return true;
      }
      if (!isIRv2) {
        return (
          isProjectLoading ||
          isMetadataLoading ||
          featuresSettingsLoading ||
          cvlListLoading ||
          projectCurrentUserLoading
        );
      } else {
        return isProjectLoading;
      }
    }, [
      isIRv2,
      isProjectLoading,
      isMetadataLoading,
      featuresSettingsLoading,
      cvlListLoading,
      projectCurrentUserLoading,
    ]);

    const isDataLoadCompleted = useMemo(() => {
      if (isNil(isIRv2)) {
        return false;
      }
      if (!isIRv2) {
        return (
          isProjectLoadCompleted &&
          isMetadataLoadCompleted &&
          ((shouldCheckProjectAutomationCVL &&
            featuresSettingsLoaded &&
            cvlListLoaded &&
            projectCurrentUserLoaded) ||
            !shouldCheckProjectAutomationCVL)
        );
      } else {
        return isProjectLoadCompleted;
      }
    }, [
      isIRv2,
      isProjectLoadCompleted,
      isMetadataLoadCompleted,
      featuresSettingsLoaded,
      cvlListLoaded,
      projectCurrentUserLoaded,
      shouldCheckProjectAutomationCVL,
    ]);

    useEffect(() => {
      if (projectId) {
        initIR(projectId);
      }
    }, [projectId, initIR]);

    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          {project && <UploadBreadcrumbs project={project} />}
          {isDataLoading && <Loading />}
          {!isDataLoading &&
            isDataLoadCompleted &&
            !isProjectAutomationCVLEligibleForAutomatedAnnotationDecisions && (
              <ProjectAutomationCvlWarning
                projectCurrentUser={projectCurrentUser}
                projectId={projectId}
              />
            )}
          {!isDataLoading &&
            isDataLoadCompleted &&
            isProjectAutomationCVLEligibleForAutomatedAnnotationDecisions && (
              <div className="container ir-container">
                <h4>Interpretation request</h4>
                <IRForm projectId={projectId} />
              </div>
            )}
        </div>
      </DndProvider>
    );
  }
);

interface ConnectedIRContainerProps extends PropsFromRedux {
  projectId: number;
  [key: string]: any;
}

export const ConnectedIRContainer = (props: ConnectedIRContainerProps) => {
  const { projectId, isIRv2 } = props;
  const {
    data: featuresSettings,
    isLoading: featuresSettingsLoading,
    isSuccess: featuresSettingsLoaded = false,
  } = useGetProjectFeaturesSettingsQuery(
    { projectId },
    { skip: isIRv2 !== false }
  );

  const {
    cvlList = [],
    isLoading: cvlListLoading,
    isSuccess: cvlListLoaded = false,
  } = useProjectAutomationCVLs({ projectId }, { skip: isIRv2 !== false });

  const {
    data: projectCurrentUser,
    isLoading: projectCurrentUserLoading,
    isSuccess: projectCurrentUserLoaded = false,
  } = useGetProjectCurrentUserQuery({ projectId }, { skip: isIRv2 !== false });

  return (
    <IRContainer
      {...props}
      {...{
        featuresSettings,
        featuresSettingsLoading,
        featuresSettingsLoaded,
        cvlList,
        cvlListLoading,
        cvlListLoaded,
        projectCurrentUser,
        projectCurrentUserLoading,
        projectCurrentUserLoaded,
      }}
    />
  );
};

const mapStateToProps = (state, { projectId }) => ({
  project: getSingleProject(state, projectId),
  isProjectLoading: isProjectLoading(state),
  isMetadataLoading: isMetadataLoading(state),
  isProjectLoadCompleted: isProjectLoadCompleted(state),
  isMetadataLoadCompleted: isMetadataLoadCompleted(state),
  isIRv2: isIRv2(state),
});

const mapDispatchToProps = {
  initIR,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectedIRContainer);
