//column names
export const USER_NAME_FIELD = "userName";
export const USER_EMAIL_FIELD = "userEmail";
export const USER_ACTION_FIELD = "userAction";
export const ACTION_ENTITY_FIELD = "actionEntity";

export const ACTION_DATE_FIELD = "created";

//Audit entities
export const auditActionEntities = {
  CVL: "curated_variant_list",
  CURATED_VARIANT: "curated_variant",
  PROJECT_CVL: "project_curated_variant_list",
  USER_CVL: "user_curated_variant_list",
};

//Audit actions
export const auditActions = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};
