import { isNil } from "ramda";
import React, { memo, useMemo } from "react";

import { Modal, Button, Tooltip } from "pattern-library";
import { Icon } from "pattern-library/elements/base";

import { CSVUploadGroupedError, YupValidationError } from "../types";
import { getFieldNameFormPath } from "../utils";

interface ValidationModalProps {
  show: boolean;
  errors?: Array<YupValidationError>;
  closeModal: () => void;
}

function addValues(value: string | Array<string>, values: Set<string>) {
  if (isNil(value)) {
    return;
  }
  if (typeof value === "string") {
    values.add(value);
  } else {
    value.forEach(item => {
      values.add(item);
    });
  }
}

export const getGroupedErrors = (
  errors: Array<YupValidationError> = []
): Array<CSVUploadGroupedError> => {
  const groupedErrors: Array<CSVUploadGroupedError> = [];
  errors.forEach(
    ({ path, type, params: { value, showValues = true, hint }, message }) => {
      const field = getFieldNameFormPath(path);
      if (!field) {
        return;
      }
      const groupedError = groupedErrors.find(
        ({ name, type: typeGrouped }) => type === typeGrouped && field === name
      );
      if (groupedError) {
        addValues(value, groupedError.values);
      } else {
        const values = new Set<string>();
        addValues(value, values);
        groupedErrors.push({
          name: field,
          type,
          values,
          showValues,
          message,
          hint,
        });
      }
    }
  );
  return groupedErrors;
};

const ValidationModal = ({
  show,
  closeModal,
  errors = [],
}: ValidationModalProps) => {
  const groupedErrors: Array<CSVUploadGroupedError> = useMemo(
    () => getGroupedErrors(errors),
    [errors]
  );
  return (
    <Modal close={closeModal} show={show} className="ir-validation-modal">
      <div className="alert alert-error">
        <h5 className="text-center">CSV upload error</h5>
        <div className="text-danger">
          <div className="attention-message">
            <span>
              <i className="fa fa-info-circle" aria-hidden="true" />
            </span>
            <span>
              Errors were detected in the uploaded CSV. Please fix them and
              re-upload the file.
            </span>
          </div>

          {groupedErrors.map(
            ({ type, name, message, values, showValues, hint }) => (
              <div key={`${name}.${type}`} className="bg-warning padded-block">
                <strong>{message}</strong>
                {showValues && ` ${[...values].join(", ")}`}
                {hint && (
                  <Tooltip content={hint} placement="bottom">
                    <Icon
                      type="infoCircle"
                      className="pull-right"
                      data-testid={`${name}-${type}-error-hint`}
                    />
                  </Tooltip>
                )}
              </div>
            )
          )}
        </div>
        <div className="button-panel text-center">
          <Button type="button" context="primary" onClick={closeModal}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ValidationModal);
