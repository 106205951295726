import classNames from "classnames";
import printJS from "print-js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { Button, Icon, Label, Link } from "pattern-library";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styles from "./SinglePagePDFViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `/web-workers/pdf.worker.min.js`;

const options = {
  cMapUrl: `cmaps/`,
  cMapPacked: true,
};

export const DOWNLOAD = "download";
export const PRINT = "print";

const blobPdfFromBase64String = base64String => {
  const byteArray = Uint8Array.from(
    atob(base64String.replace("data:application/pdf;base64,", "")),
    c => c.charCodeAt(0)
  );
  return new Blob([byteArray], { type: "application/pdf" });
};

const printPDF = blob => {
  printJS(URL.createObjectURL(blob));
};

const validBase64String = str => {
  if (!str) {
    return false;
  }
  try {
    window.atob(str.replace("data:application/pdf;base64,", ""));
    return true;
  } catch (e) {
    // something failed

    // if you want to be specific and only catch the error which means
    // the base 64 was invalid, then check for 'e.code === 5'.
    // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
    return false;
  }
};

const validURL = str => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const print = file => {
  if (typeof file === "object" || validURL(file)) {
    //TODO: we plan continue development of this component
    console.log("Not implemented yet");
  } else {
    printPDF(blobPdfFromBase64String(file));
  }
};

export function SinglePagePDFViewer({
  className,
  fileName,
  file,
  scale = 150,
  actions = [DOWNLOAD, PRINT],
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [pageScale, setPageScale] = useState(scale);

  useEffect(() => {
    setPageNumber(1);
  }, [file]);

  useEffect(() => {
    setPageScale(scale);
  }, [scale]);

  const printFile = useCallback(() => {
    print(file);
  }, [file]);

  const printDisabled = useMemo(() => !validBase64String(file), [file]);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  return (
    <div className={classNames(className, styles.pdf_viewer)}>
      <div className={styles.pdf_viewer__toolbar}>
        <div className={styles.pdf_viewer__toolbar__left}>
          <Label>{fileName}</Label>
        </div>
        <div className={styles.pdf_viewer__toolbar__center}>
          <Button
            className={styles.pdf_viewer__toolbar__button}
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            ‹
          </Button>
          <span className={styles.pdf_viewer__toolbar__label}>
            {pageNumber} of {numPages}
          </span>
          <Button
            className={styles.pdf_viewer__toolbar__button}
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            ›
          </Button>
          <div className={styles.pdf_viewer__toolbar__divider} />
          <Button
            className={styles.pdf_viewer__toolbar__button}
            disabled={pageScale === 10}
            onClick={() => setPageScale(pageScale - 10)}
          >
            -
          </Button>
          <span className={styles.pdf_viewer__toolbar__label}>
            {pageScale}%
          </span>
          <Button
            className={styles.pdf_viewer__toolbar__button}
            onClick={() => setPageScale(pageScale + 10)}
          >
            +
          </Button>
        </div>
        <div className={styles.pdf_viewer__toolbar__right}>
          {actions.includes(DOWNLOAD) && (
            <Link
              disabled={!file}
              href={file}
              download={fileName ? fileName : "Document.pdf"}
            >
              <Button
                className={styles.pdf_viewer__toolbar__button}
                title="Download"
              >
                <Icon type="save" />
              </Button>
            </Link>
          )}
          {actions.includes(PRINT) && (
            <Button
              disabled={!file || printDisabled}
              className={styles.pdf_viewer__toolbar__button}
              title="Print"
              onClick={printFile}
            >
              <Icon type="print" />
            </Button>
          )}
        </div>
      </div>
      <div className={styles.pdf_viewer__container}>
        <div className={styles.pdf_viewer__container__document}>
          <Document
            options={options}
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page scale={pageScale / 100} pageNumber={pageNumber} />
          </Document>
        </div>
      </div>
    </div>
  );
}
