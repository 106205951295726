import deepFreeze from "deep-freeze";
import { camelize } from "humps";
import { concat, mergeWith, path, uniq } from "ramda";

import { ARIADNE_KEYS } from "modules/ariadne/constants";
import { EXAC_EXOMES_FIELDS } from "modules/exac/constants";
import { GNOMAD_EXOMES_FIELDS } from "modules/gnomad/constants";
import {
  SPLICE_AI_FEATURE_FLAG,
  REVEL_FEATURE_FLAG,
  AUTO_ACMG_FEATURE,
  GNOMAD_CS_FEATURE,
  GNOMAD_EXOMES_FEATURE,
  EXAC_EXOMES_FEATURE,
  ARIADNE_PREDICTION_FEATURE,
} from "modules/project/constants";

import {
  AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
  CUSTOM_PENETRANCE_KEY,
  CUSTOM_TIER_KEY,
  REVEL_FEATURE_KEYS,
  REVEL_KEY,
  SPLICE_AI_FIELDS_KEYS,
  GNOMAD_CS_FIELDS_KEYS,
} from "./reducer.data";

const ariadneFeatureFlaggedKeys = Object.values(ARIADNE_KEYS);
const gnomadExomesFeatureFlaggedKeys = Object.values(GNOMAD_EXOMES_FIELDS).map(
  value => value.key
);
const exacExomesFeatureFlaggedKeys = Object.values(EXAC_EXOMES_FIELDS).map(
  value => value.key
);

/**
 * a configuration object that specifies config fields dependent on a specific flag
 */

export const DEFAULT_EXCLUSIONS = deepFreeze({
  filters: [],
  prioritisation: [],
  hiddenColumns: {
    variantColumns: [],
    consequenceColumns: [],
  },
  disabledColumns: {
    variantColumns: [],
    consequenceColumns: [],
  },
});

export const SNV_ANNOTATION_FILE_FEATURE = "snvAnnotationFile";
export const PATIENT_HAS_TIERING_DATA = "hasCustomDataTiers";
export const PATIENT_HAS_REVEL_SCORE = "hasRevelScore";
export const PATIENT_HAS_ACMG_SUGGESTIONS = "hasAutoAcmgSuggestions";
export const PATIENT_HAS_ARIADNE_PREDICTIONS = `cacheColumns.${ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING}`;
export const PATIENT_HAS_GNOMAD_CONSTRAINT_SCORES = "hasGnomadConstraintScores";

export const PATIENT_FEATURE_DATA_FIELDS = "featureDataFields";
export const PROJECT_FEATURES = "features";

export const getFeatureDataFields = (object, key) =>
  path([PATIENT_FEATURE_DATA_FIELDS, key], object);

export const isFeatureEnabledByDataFields = (object, key, supportedFields) => {
  const featureDataFields = getFeatureDataFields(object, key);
  return featureDataFields?.some(value =>
    supportedFields.includes(camelize(value))
  );
};

export const updateEnabledFeatureFlagConfigByDataFields = (
  object,
  key,
  featureCfg,
  supportedFields
) => {
  const featureDataFields = path([PATIENT_FEATURE_DATA_FIELDS], object);
  if (!featureDataFields) {
    return featureCfg;
  }
  const featureDataFieldsCamelCase = path([key], featureDataFields).map(value =>
    camelize(value)
  );
  const missingFields = supportedFields.filter(
    value => !featureDataFieldsCamelCase?.includes(value)
  );
  const variantColumnsWithOuter =
    featureCfg.disabledColumns.variantColumns.concat(missingFields);
  const filtersWithOuter = featureCfg.filters.concat(missingFields);
  featureCfg = mergeExclusions(featureCfg, {
    disabledColumns: {
      variantColumns: variantColumnsWithOuter,
    },
    filters: filtersWithOuter,
  });
  return featureCfg;
};

export const getProjectFeatureEnabledFlag = (project, feature) =>
  path([PROJECT_FEATURES, ...feature.split(".")], project);

export const getPatientFeatureEnabledFlag = (patient, feature) => {
  if (feature === GNOMAD_EXOMES_FEATURE) {
    return isFeatureEnabledByDataFields(
      patient,
      GNOMAD_EXOMES_FEATURE,
      gnomadExomesFeatureFlaggedKeys
    );
  } else if (feature === EXAC_EXOMES_FEATURE) {
    // A solution in UI level to support the fact, that ExAC exome fields can be found both
    // under `exac_exomes` and `snv_annotation_file`. The new patients will have ExAC under `exac_exomes`
    // while old patients, who were introduced prior to appearance of this feature flag, will have ExAC
    // values held under `snv_annotation_file` feature flag. We need to lookup both flags to determine,
    // whether patient has ExAC feature data. If patient data has `exac_exomes` feature key - we
    // automatically assume, that this patient is non-legacy
    const hasExacExomes = Boolean(
      getFeatureDataFields(patient, EXAC_EXOMES_FEATURE)
    );
    const featureToCheck = hasExacExomes
      ? EXAC_EXOMES_FEATURE
      : SNV_ANNOTATION_FILE_FEATURE;

    return isFeatureEnabledByDataFields(
      patient,
      featureToCheck,
      exacExomesFeatureFlaggedKeys
    );
  }
  return path(feature.split("."), patient);
};

export const updateEnabledPatientConfig = (patient, feature, featureCfg) => {
  if (feature === GNOMAD_EXOMES_FEATURE) {
    return updateEnabledFeatureFlagConfigByDataFields(
      patient,
      GNOMAD_EXOMES_FEATURE,
      featureCfg,
      gnomadExomesFeatureFlaggedKeys
    );
  } else if (feature === EXAC_EXOMES_FEATURE) {
    // ExAC implementation solving the same problem as in `getPatientFeatureEnabledFlag`.
    // We disable filters/columns based on whether patient has ExAC data in `exac_exomes`
    // or `snv_annotation_file` feature flag
    const hasExacExomes = Boolean(
      getFeatureDataFields(patient, EXAC_EXOMES_FEATURE)
    );
    const featureToCheck = hasExacExomes
      ? EXAC_EXOMES_FEATURE
      : SNV_ANNOTATION_FILE_FEATURE;

    return updateEnabledFeatureFlagConfigByDataFields(
      patient,
      featureToCheck,
      featureCfg,
      exacExomesFeatureFlaggedKeys
    );
  }
  return featureCfg;
};

export const PATIENT_CONFIG = {
  [PATIENT_HAS_TIERING_DATA]: {
    filters: [CUSTOM_TIER_KEY, CUSTOM_PENETRANCE_KEY],
    hiddenColumns: {
      variantColumns: [CUSTOM_TIER_KEY],
    },
  },
  [PATIENT_HAS_REVEL_SCORE]: {
    prioritisation: [REVEL_KEY],
    filters: [REVEL_KEY],
  },
  [PATIENT_HAS_ACMG_SUGGESTIONS]: {
    filters: [AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY],
  },
  [PATIENT_HAS_ARIADNE_PREDICTIONS]: {
    disabledColumns: {
      consequenceColumns: ariadneFeatureFlaggedKeys,
    },
    prioritisation: ariadneFeatureFlaggedKeys,
  },
  [PATIENT_HAS_GNOMAD_CONSTRAINT_SCORES]: {
    filters: GNOMAD_CS_FIELDS_KEYS,
  },
  [EXAC_EXOMES_FEATURE]: {
    disabledColumns: {
      variantColumns: exacExomesFeatureFlaggedKeys,
    },
    filters: exacExomesFeatureFlaggedKeys,
  },
  [GNOMAD_EXOMES_FEATURE]: {
    disabledColumns: {
      variantColumns: gnomadExomesFeatureFlaggedKeys,
    },
    filters: gnomadExomesFeatureFlaggedKeys,
  },
};

export const PROJECT_CONFIG = {
  [SPLICE_AI_FEATURE_FLAG]: {
    filters: SPLICE_AI_FIELDS_KEYS,
    hiddenColumns: {
      variantColumns: SPLICE_AI_FIELDS_KEYS,
    },
    prioritisation: SPLICE_AI_FIELDS_KEYS,
  },
  [REVEL_FEATURE_FLAG]: {
    filters: REVEL_FEATURE_KEYS,
    hiddenColumns: {
      consequenceColumns: REVEL_FEATURE_KEYS,
    },
    prioritisation: REVEL_FEATURE_KEYS,
  },
  [AUTO_ACMG_FEATURE]: {
    hiddenColumns: {
      variantColumns: [AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY],
    },
    prioritisation: [AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY],
    filters: [AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY],
  },
  [GNOMAD_CS_FEATURE]: {
    filters: GNOMAD_CS_FIELDS_KEYS,
  },
  [ARIADNE_PREDICTION_FEATURE]: {
    hiddenColumns: {
      consequenceColumns: ariadneFeatureFlaggedKeys,
    },
    prioritisation: ariadneFeatureFlaggedKeys,
  },
};

const mergeExclusions = (to, from) =>
  mergeWith(
    (a, b) => (Array.isArray(a) ? uniq(concat(a, b)) : mergeExclusions(a, b)),
    to,
    from
  );

export const getConfigExclusionsForObject = (
  object,
  config,
  isEnabled,
  updateEnabledConfig
) =>
  Object.entries(config).reduce(
    (result, [key, value]) => {
      if (object) {
        const flag = isEnabled(object, key);
        if (!flag) {
          result = mergeExclusions(result, value);
        } else if (updateEnabledConfig) {
          result = updateEnabledConfig(object, key, result);
        }
      }
      return result;
    },
    { ...DEFAULT_EXCLUSIONS }
  );

export const getConfigExclusionsForProject = (project: Project) => {
  const configExclusions = getConfigExclusionsForObject(
    project,
    PROJECT_CONFIG,
    getProjectFeatureEnabledFlag
  );
  return configExclusions;
};

export const getPatientExclusions = (patient: Patient) =>
  getConfigExclusionsForObject(
    patient,
    PATIENT_CONFIG,
    getPatientFeatureEnabledFlag,
    updateEnabledPatientConfig
  );

const getPatientSplicingExclusions = (patient: Patient) => {
  if (patient.splicingFilters) {
    const fieldsVisibility = patient.splicingFilters;
    const exclusions = Object.keys(fieldsVisibility).filter(
      key => fieldsVisibility[key] === false
    );
    return {
      filters: exclusions,
      prioritisation: exclusions,
      disabledColumns: {
        variantColumns: exclusions,
      },
    };
  }
  return DEFAULT_EXCLUSIONS;
};

export const getConfigExclusionsForPatient = (patient: Patient) =>
  patient
    ? [
        getPatientExclusions(patient),
        getPatientSplicingExclusions(patient),
      ].reduce(mergeExclusions, DEFAULT_EXCLUSIONS)
    : DEFAULT_EXCLUSIONS;

export const calculateConfigExclusions = (
  project: Project,
  patient?: Patient
) => {
  const configExclusionsForProject = getConfigExclusionsForProject(project);
  if (patient) {
    const configExclusionsForPatient = getConfigExclusionsForPatient(patient);
    return mergeExclusions(
      configExclusionsForProject,
      configExclusionsForPatient
    );
  }
  return configExclusionsForProject;
};
