import { EXON_RANK_MENU_LABEL } from "../../constants";
import { Track } from "../core/TranscriptProtein";
import {
  getEnsemblUrl,
  getExonRank,
  getOnClickFeatures,
  isForwardTranscribedGene,
} from "../utils";

import { TRANSCRIPT_COLORS } from "./Transcripts";

export function getMenu(features, genome, chr, x) {
  if (features.length) {
    let menu = null;
    features.some(feature => {
      const exonWithRank = getExonRank(feature, chr, x);
      if (exonWithRank) {
        const { exon, rank } = exonWithRank;
        menu = {
          title:
            feature.logic_name === "ensembl"
              ? `<a target="_blank" href="${getEnsemblUrl(
                  genome
                )}/Homo_sapiens/Transcript/Summary?t=${feature.id}">${
                  feature.id
                }</a>`
              : `<a target="_blank" href="http://www.ncbi.nlm.nih.gov/gene/?term=${feature.id}">${feature.id}</a>`,
          [EXON_RANK_MENU_LABEL]: rank,
          Location: `${chr}:${exon.start}-${exon.end}`,
          Strand: isForwardTranscribedGene(feature) ? "+ve" : "-ve",
          Length: `${exon.end - exon.start + 1} bp`,
          "Protein length": feature.protein_length,
        };
        return true;
      }
      return false;
    });
    return menu;
  }
}

export const TranscriptProtein = ({ patientId, transcriptId, genome, chr }) =>
  Track.extend({
    namespace: "Congenica.TranscriptProtein",
    url: `/transcript/patient/${patientId}/transcript/${transcriptId}/protein.json?chr=__CHR__&start=__START__&end=__END__`,
    height: 150,
    id: "transcriptProteinSingle",
    name: "Protein",
    info: "Reference cDNA and amino acid sequences numbered according to HGVS nomenclature.",
    category: "Genes",
    tags: ["Genes", "Transcripts", "Ensembl", "Structure"],
    repeatLabels: true,
    colors: TRANSCRIPT_COLORS,
    click(e) {
      const { features, x } = getOnClickFeatures.bind(this)(e);
      const menu = getMenu(features, genome, chr, x);
      if (menu) {
        this.browser.makeMenu(menu, e, this.track);
      }
    },
  });
