import React, { PureComponent } from "react";
import { ReactTableDefaults } from "react-table";

import { Table } from "../../elements";

import DataTableFooter from "./DataTableFooter";

/**
 * @deprecated
 * please use elements/table7/Table.js
 */
export default class DataTable extends PureComponent {
  static displayName = "DataTable";

  render() {
    const {
      className = "",
      showPagination = true,
      PaginationComponent = DataTableFooter,
      ...otherProps
    } = this.props;

    return (
      <Table
        PaginationComponent={PaginationComponent}
        showPagination={showPagination}
        column={{ ...ReactTableDefaults.column, className: "" }}
        className={className}
        {...otherProps}
      />
    );
  }
}
