import React, { memo, useCallback, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button, Icon } from "pattern-library";

import { searchGenes } from "../../../../actions";
import { SEARCH_GENE_FOR_GENE_PANEL_FORM } from "../../../../constants";
import { GeneListProps } from "../../../../types";
import EditNotification from "../../EditNotification";

import SearchGenesTable from "./SearchGenesTable";

const SearchTab = memo(
  ({
    projectId,
    genePanelId,
    canEdit,
    handleSubmit,
    searchGenes,
  }: GeneListProps &
    PropsFromRedux &
    InjectedFormProps<Index, GeneListProps & PropsFromRedux>) => {
    const [lastQuery, setLastQuery] = useState("");

    const onFormSubmit = useCallback(
      ({ query }) => {
        if (!!query) {
          searchGenes(projectId, genePanelId, query);
        }
        setLastQuery(query ?? "");
      },
      [projectId, genePanelId, searchGenes]
    );

    if (!canEdit)
      return (
        <EditNotification projectId={projectId} genePanelId={genePanelId} />
      );
    return (
      <div className="row">
        <div className="col-xs-12">
          <form id="gene-search" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="input-group col-xs-10">
                <Field
                  component="input"
                  name="query"
                  className="form-control col-xs-5 query"
                  placeholder="Please enter a search phrase"
                />
                <span className="input-group-btn">
                  <Button type="submit">
                    <Icon type="search" />
                  </Button>
                </span>
              </div>
            </div>
          </form>
          <div id="gene-search-results-list">
            {lastQuery.length > 0 ? (
              <SearchGenesTable
                projectId={projectId}
                genePanelId={genePanelId}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  searchGenes: searchGenes.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  reduxForm<Index, GeneListProps>({
    form: SEARCH_GENE_FOR_GENE_PANEL_FORM,
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(SearchTab)
);
