import PropTypes from "prop-types";
import React, { memo } from "react";

import { Tooltip } from "pattern-library";

const RemovalCell = memo(
  ({ index, fieldsDisabled, removeRowHandler, tooltip }) => {
    const removeRow = e => {
      e.stopPropagation();
      if (!fieldsDisabled) {
        removeRowHandler(index);
      }
    };

    if (fieldsDisabled) {
      return null;
    }

    return (
      <Tooltip content={tooltip} placement="right" trigger="mouseenter">
        <span>
          <i
            className="glyphicon glyphicon-trash text-danger"
            onClick={removeRow}
            aria-hidden="true"
          />
        </span>
      </Tooltip>
    );
  }
);

RemovalCell.propTypes = {
  index: PropTypes.number.isRequired,
  fieldsDisabled: PropTypes.bool.isRequired,
  removeRowHandler: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default RemovalCell;
