import PropTypes from "prop-types";
import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";

import { Modal, Button } from "pattern-library";

import { hasCNVRequestFailed, getCNVCallingRequestError } from "../selectors";

const TITLE = "CNV Analysis Request Failed";

export const CNVFailedModal = ({
  cnvFailed,
  errorMessage = "Unknown error",
}) => {
  const [show, setShow] = useState(false);
  const close = () => {
    setShow(false);
  };

  useEffect(() => {
    if (cnvFailed) {
      setShow(true);
    }
  }, [cnvFailed]);

  if (!cnvFailed) {
    return null;
  }

  return (
    <Modal title={TITLE} close={close} show={show}>
      <div className="alert alert-error">
        <h5 className="text-center">{TITLE}</h5>
        <div className="text-success">
          <div className="attention-message">
            <span>
              <i className="fa fa-info-circle" aria-hidden="true" />
            </span>
            <span>Interpretation Request is successfully submitted</span>
          </div>
        </div>
        <div className="text-danger">
          <div className="attention-message">
            <span>
              <i className="fa fa-info-circle" aria-hidden="true" />
            </span>
            <span>
              An error has occurred during CNV analysis request submission:
            </span>
          </div>
          <div className="bg-warning padded-block">{errorMessage}</div>
        </div>
        <div className="button-panel text-center">
          <Button type="button" context="primary" onClick={close}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CNVFailedModal.propTypes = {
  errorMessage: PropTypes.string,
  cnvFailed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  cnvFailed: hasCNVRequestFailed(state),
  errorMessage: getCNVCallingRequestError(state),
});

export const CNVFailedModalMemoized = memo(CNVFailedModal);

export default connect(mapStateToProps, null)(CNVFailedModal);
