import classNames from "classnames";
import PropTypes from "prop-types";
import { memoizeWith, identity } from "ramda";
import React, { PureComponent } from "react";
import { Tooltip, Legend, PieChart, Pie, Cell } from "recharts";

export default class PieGraph extends PureComponent {
  static displayName = "PieGraph";

  static propTypes = {
    /**
     * Parameter defining whether to show animation
     */
    isAnimationActive: PropTypes.bool,
    /**
     * Parameter defining whether to show tooltips
     */
    showTooltip: PropTypes.bool,
    /**
     * Parameter defining whether to show legend
     */
    showLegend: PropTypes.bool,
    /**
     * The start angle of first sector.
     */
    startAngle: PropTypes.number,
    /**
     * The end angle of last sector, which should be unequal to startAngle.
     */
    endAngle: PropTypes.number,
    /**
     * Parameter defining whether to show half circles or full
     */
    halfCircles: PropTypes.bool,
    /**
     * Data to render chart
     */
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        color: PropTypes.string,
      })
    ).isRequired,
    /**
     * Class to apply to the chart component
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    isAnimationActive: false,
    className: "",
    width: 800,
    height: 600,
    showTooltip: true,
    showLegend: true,
    startAngle: 0,
    endAngle: 180,
    halfCircles: false,
  };

  static calcOuterRadius(width, height) {
    const halfWidth = width / 2;
    return halfWidth > height ? height * 0.65 : halfWidth * 0.65;
  }

  static calcCy(width, height) {
    return height * 0.8;
  }

  render() {
    const {
      isAnimationActive,
      data,
      width,
      height,
      className,
      showTooltip,
      showLegend,
      startAngle,
      endAngle,
      halfCircles,
      ...otherProps
    } = this.props;

    const cy =
      endAngle > 180
        ? "50%"
        : memoizeWith(identity, PieGraph.calcCy)(width, height);
    const outerRadius =
      endAngle > 180
        ? "80%"
        : memoizeWith(identity, PieGraph.calcOuterRadius)(width, height);
    const innerRadius = endAngle > 180 ? "50%" : outerRadius / 2;

    return (
      <PieChart
        className={classNames(className, "graph-chart")}
        width={width}
        height={height}
        {...otherProps}
      >
        {showTooltip === true && <Tooltip />}
        {showLegend === true && <Legend />}
        <Pie
          isAnimationActive={isAnimationActive}
          dataKey="value"
          cx="50%"
          cy={cy}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={halfCircles ? innerRadius : 0}
          data={data}
          label
          {...otherProps}
        >
          {data.map(entry => (
            <Cell key={entry.name} fill={entry.color} {...otherProps} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}
