import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class ArticleProperty extends PureComponent {
  static displayName = "ArticleProperty";

  static propTypes = {
    /**
     * Field label
     */
    label: PropTypes.string.isRequired,
    /**
     * Field value
     */
    value: PropTypes.node.isRequired,

    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
  };

  render() {
    const { label, value, className, ...otherProps } = this.props;

    return (
      <div
        className={classNames("article-property", className)}
        {...otherProps}
      >
        <div className="article-property-label">{label}:</div>
        <div className="article-property-value">{value}</div>
      </div>
    );
  }
}
