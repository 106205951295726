const URLS = {
  assembly_agnostic: {
    "congenica.jira": "https://jira.congenica.net/browse/",
    doi: "https://doi.org/",
    "ebi.interpro": "http://www.ebi.ac.uk/interpro/entry/",
    expasy: "http://enzyme.expasy.org/EC/",
    "genenames.symbol_rpt":
      "https://www.genenames.org/data/gene-symbol-report/#!/symbol/",
    "google.scholar": "https://scholar.google.com/scholar?hl=en&q=",
    "google.search": "https://www.google.com/search?q=",
    hpo_term: "https://hpo.jax.org/app/browse/term/",
    "omim.entry": "https://omim.org/entry/",
    "omim_api.entry": "https://api.omim.org/api/entry",
    "omim_api.genemap_search": "https://api.omim.org/api/geneMap/search",
    polyphen: "http://genetics.bwh.harvard.edu/pph2/",
    provean: "http://provean.jcvi.org/genome_submit.php",
    pubmed: "http://www.ncbi.nlm.nih.gov/pubmed/",
    pubmed_fetch:
      "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed",
    pubmed_search:
      "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed",
    "ucsc.hggene": "http://genome.cse.ucsc.edu/cgi-bin/hgGene?hgg_gene=",
    uniprot: "https://www.uniprot.org/uniprot/",
  },

  grch37: {
    "gene_summary.ensembl":
      "https://grch37.ensembl.org/Homo_sapiens/Gene/Summary?g=",
    "gene_summary.refseq": "https://www.ncbi.nlm.nih.gov/gene/?term=",
    "transcript_summary.ensembl":
      "https://grch37.ensembl.org/Homo_sapiens/Transcript/Summary?db=core;t=",
    "transcript_summary.refseq": "https://www.ncbi.nlm.nih.gov/nuccore/",
  },

  grch38: {
    "gene_summary.ensembl":
      "https://aug2017.archive.ensembl.org/Homo_sapiens/Gene/Summary?g=",
    "gene_summary.refseq": "https://www.ncbi.nlm.nih.gov/gene/?term=",
    "transcript_summary.ensembl":
      "https://aug2017.archive.ensembl.org/Homo_sapiens/Transcript/Summary?db=core;t=",
    "transcript_summary.refseq": "https://www.ncbi.nlm.nih.gov/nuccore/",
  },
};

export const ExternalUrl = (name: String, assembly?: String) => {
  let assemblyUrls = {};
  if (assembly) assemblyUrls = URLS[assembly];
  const allUrls = { ...assemblyUrls, ...URLS.assembly_agnostic };
  return allUrls[name];
};
