export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const titleCase = s =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );

export const removePrefix = (value, prefix) =>
  value.replace(new RegExp(`^${prefix}`, "g"), "");
