import PropTypes from "prop-types";
import React from "react";

import "./rating.scss";

export const RatingStar = ({ score, showEmpty }) => (
  <div
    aria-label={`${score * 100}% filled star`}
    className="star-rating"
    style={{
      "--rating": score,
      "--empty-color": showEmpty ? "lightgrey" : "transparent",
    }}
  />
);

RatingStar.propTypes = {
  score: PropTypes.number,
  showEmpty: PropTypes.bool,
};

export const Stars = ({ score = 0, maximum, precision, showEmpty }) => {
  const integerScore = Math.floor(Math.abs(score));
  const fractionalScore = Math.min(score - integerScore, precision);
  const fullStarsToDisplay = Math.min(integerScore, maximum);
  const scoreTooLarge = score > maximum;

  // Setup the integer part of all of the stars
  const arrayOfFullStars = Array.from(
    { length: fullStarsToDisplay },
    (_, index) => <RatingStar score={1} showEmpty key={index} />
  );

  if (fractionalScore && !scoreTooLarge) {
    arrayOfFullStars.push(
      <RatingStar
        score={fractionalScore}
        showEmpty={showEmpty}
        key="fractional-star"
      />
    );
  }

  if (!showEmpty || scoreTooLarge) {
    return arrayOfFullStars;
  }

  const numberOfEmptyStars = maximum - integerScore;

  // If we had a fractional part of the score, then we correct for this in the empty stars
  // otherwise, for example, 2.5/5 stars would incorrectly show:
  //   2 full stars
  //   1 half star
  //   3 full stars
  const correction = fractionalScore ? 1 : 0;
  const arrayOfEmptyStars = Array.from(
    { length: numberOfEmptyStars - correction },
    (_, index) => <RatingStar score={0} showEmpty key={`${index}-empty`} />
  );

  return arrayOfFullStars.concat(arrayOfEmptyStars);
};

Stars.propTypes = {
  score: PropTypes.number,
  showEmpty: PropTypes.bool,
  maximum: PropTypes.number,
  precision: PropTypes.number,
  className: PropTypes.string,
};

export const Rating = ({
  maximum = 5,
  score,
  showScore = false,
  showEmpty = true,
  showMaximum = false,
  // What's the maximum partial rating we will fill in?
  // This helps us cap out 4.99 (for example), so it doesn't look like 5 filled star
  starPrecision = 0.75,
  scorePrecision = 2,
  className,
}) => {
  // Whole number will have 0 precision as there is no point showing zeros
  scorePrecision = score % 1 === 0 ? 0 : scorePrecision;

  // We don't support fractional maximum stars, so round down
  const roundedMaximum = Math.floor(maximum);
  const maximumDisplay = showMaximum ? `/${roundedMaximum}` : "";
  const scoreDisplay =
    Math.min(score, roundedMaximum).toFixed(scorePrecision) || "0";
  const scores = `${scoreDisplay}${maximumDisplay}`;
  return (
    <span aria-label={`Rating score: ${scores}`} className={className}>
      <span>
        <Stars
          score={score}
          maximum={roundedMaximum}
          precision={starPrecision}
          showEmpty={showEmpty}
        />
      </span>
      {showScore && (
        <div>
          <small className="text-muted">({scores})</small>
        </div>
      )}
    </span>
  );
};

Rating.propTypes = {
  score: PropTypes.number,
  showEmpty: PropTypes.bool,
  maximum: PropTypes.number,
  precision: PropTypes.number,
  starPrecision: PropTypes.number,
  showScore: PropTypes.bool,
  showMaximum: PropTypes.bool,
};
