// @flow

import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { getSinglePatient } from "modules/patient/selectors";

import type { ConfigStateValues } from "../config/flow-types";
import * as configSelectors from "../config/selectors";
import { getConfigExclusions } from "../config/selectors";
import { isFormPristine, snvPresetToConfig } from "../config/utils";
import { getCurrentProject } from "../project/selectors";
import * as presetUtils from "../utils/filter-presets";

import { MODULE_NAME } from "./constants";
import type { SNVPresetsStateBranch } from "./flow-types";

export const uiPath = (...items: any) => path([MODULE_NAME, "ui", ...items]);
export const dataPath = (...items: any) =>
  path([MODULE_NAME, "data", ...items]);

export const getSNVPresets = (state: SNVPresetsStateBranch): Array<SNVPreset> =>
  dataPath("presets")(state);

export const getDefaultPreset = createSelector(
  getSNVPresets,
  presetUtils.getDefaultPreset
);

export const getSNVSelectFieldPresets = createSelector(
  getSNVPresets,
  (presets: Array<SNVPreset>) => presets.map(p => presetUtils.presetToOption(p))
);

export const getSelectedPresetId = (state: SNVPresetsStateBranch): number =>
  uiPath("selectedPresetId")(state);

export const getSelectedPresetOption = createSelector(
  getSelectedPresetId,
  getSNVPresets,
  presetUtils.findPresetById
);

export const isDefaultPresetSelected = createSelector(
  getDefaultPreset,
  getSelectedPresetId,
  (defaultPreset: SNVPreset, selectedPresetId: number) => {
    if (!selectedPresetId || !defaultPreset) {
      return false;
    }

    return defaultPreset.id === selectedPresetId;
  }
);

export const isSelectedPresetCustomized = createSelector(
  getCurrentProject,
  (state: SNVPresetsStateBranch, patientId: number) =>
    getSinglePatient(state, patientId),
  state => configSelectors.getValues(state),
  (state: SNVPresetsStateBranch): ?SNVPreset => {
    const option: SelectItem<SNVPreset> = getSelectedPresetOption(state);
    return option ? option.value : null;
  },
  getConfigExclusions,
  (
    project: Project,
    patient: Patient | null,
    currentConfig: ConfigStateValues,
    currentPreset: ?SNVPreset,
    configExclusions
  ) => {
    if (
      !patient ||
      !currentPreset ||
      !currentConfig ||
      !project ||
      project.projectId !== patient.projectId
    ) {
      return false;
    }

    const presetConfig = snvPresetToConfig(
      currentPreset,
      project,
      patient,
      configExclusions
    );
    return !isFormPristine(currentConfig, presetConfig);
  }
);
