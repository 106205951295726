import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";

import { fetchCamelizeData, processErrors } from "../utils";
import { FetchDataResponse } from "../utils/fetchData";

// TODO: Use API filtering instead of client side filtering for these -
// however, this may be moved to RTK Query before it happens in here, so easiest to leave this as is for the time being
const ACTIVE_DATA_VERSIONS_URL = `${API_ENTITIES_BASE_URL}/thirdparty_data_versions?filter[is_active]=true`;
const ACTIVE_SOFTWARE_VERSIONS_URL = `${API_ENTITIES_BASE_URL}/thirdparty_software_versions?filter[is_active]=true`;

const versionTypeMap = {
  thirdparty_data: "Data",
  thirdparty_software: "Software",
};

const featuresToFilter = ["ENSEMBL", "VEP_CACHE", "VEP"];

const filterVersions = ({ attributes: { name } }) =>
  featuresToFilter.indexOf(name) >= 0;

export const getDataVersions = async () => {
  const { ok, payload, errors }: FetchDataResponse<any> =
    await fetchCamelizeData(ACTIVE_DATA_VERSIONS_URL);

  if (!ok || errors) {
    const details = processErrors(errors) || "";
    throw new Error(`Couldn't retrieve reference data versions.${details}`);
  }

  const { data = [] } = payload;
  return data
    .filter(filterVersions)
    .map(({ attributes: { name, version } }) => ({
      name,
      version,
      type: versionTypeMap.thirdparty_data,
    }));
};

export const getSoftwareVersions = async () => {
  const { ok, payload, errors }: FetchDataResponse<any> =
    await fetchCamelizeData(ACTIVE_SOFTWARE_VERSIONS_URL);

  if (!ok || errors) {
    const details = processErrors(errors) || "";
    throw new Error(
      `Couldn't retrieve third party software versions.${details}`
    );
  }

  const { data = [] } = payload;
  return data
    .filter(filterVersions)
    .map(({ attributes: { name, version } }) => ({
      name,
      version,
      type: versionTypeMap.thirdparty_software,
    }));
};
