export type GenePanel = {
  id: number,
  name: string,
  description: string,
  parentProject: string,
  basePanel: string,
  patients: number,
  genes: number,
  updated: date,
  archived: boolean,
};
export type GenePanelInfo = {
  genesCount: number,
  patientCount: number,
  title: string,
  description: string,
  genePanelId: number,
};

export const PatientOperation = Object.freeze({
  ADD_ONE: "ADD_ONE",
  REMOVE_ONE: "REMOVE_ONE",
  ADD_ALL: "ADD_ALL",
  REMOVE_ALL: "REMOVE_ALL",
});

export type PatientOperationType = $Values<typeof PatientOperation>;
