import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Link } from "pattern-library";

import ModuleVersion from "./ModuleVersion";
import { getModuleName, getUserGuideUsLink } from "./selectors";

const USA = ({ userGuideLink, moduleName }: PropsFromRedux) => (
  <>
    <div className="form-group">
      <ModuleVersion />
    </div>
    <div className="form-group">
      Consult {moduleName}{" "}
      <Link href={userGuideLink} target="_blank" rel="noopener noreferrer">
        User Guide
      </Link>
    </div>
  </>
);

const mapStateToProps = state => ({
  userGuideLink: getUserGuideUsLink(state),
  moduleName: getModuleName(state),
});

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(USA);
