import { isEmpty } from "ramda";
import React, { memo } from "react";

import { LabelField } from "pattern-library";

import { LOCATION_TYPES } from "modules/projectSettings/components/filterPresets/constants";

import * as moduleTypes from "../types";

const parseGenePanels = genePanels =>
  genePanels.map(({ genePanelTitle }) => genePanelTitle).join(", ");

const parseGenes = genes => genes.map(({ label }) => label).join(", ");

const parseLocation = location => LOCATION_TYPES[location].label;

const mapTermsAndLabels = terms =>
  terms.reduce(
    (termsMap, { value, label }) => termsMap.set(value, label),
    new Map()
  );

const getDictionaryMap = dictionaries =>
  Object.keys(dictionaries).reduce((map, dictionaryName) => {
    const dictionary = dictionaries[dictionaryName];

    map.set(dictionaryName, mapTermsAndLabels(dictionary));

    return map;
  }, new Map());

const fieldsConfig = [
  {
    property: "genotype",
    label: "Genotype",
    dictionaryName: "genotypes",
  },
  {
    property: "size",
    label: "Size",
    dictionaryName: "sizes",
  },
  {
    property: "location",
    label: "Location",
    parser: parseLocation,
  },
  {
    property: "genes",
    label: "Specific genes",
    parser: parseGenes,
  },
  {
    property: "genePanel",
    label: "Gene panels",
    parser: parseGenePanels,
  },
  {
    property: "tier",
    label: "Tier",
    dictionaryName: "tier",
  },
  {
    property: "inheritance",
    label: "Inheritance",
    dictionaryName: "inheritance",
  },
  {
    property: "bayesFactor",
    label: "Bayes factor",
  },
  {
    property: "gnomadAf",
    label: "gnomAD Allele Frequency",
  },
  {
    property: "quality",
    label: "Quality",
    dictionaryName: "qualities",
  },
  {
    property: "subtype",
    label: "Type",
    dictionaryName: "subtypes",
  },
  {
    property: "cvl",
    label: "Curated variant lists",
    dictionaryName: "cvls",
  },
];

type Props = {
  preset: moduleTypes.FilterPresetConfig | { [item: string]: any };
  dictionaries: { [item: string]: any };
  gnomadFeatureEnabled?: boolean;
  cvls?: Array<any>;
};

export const FilterPresetInfo = memo(
  ({ preset = {}, dictionaries = {}, gnomadFeatureEnabled }: Props) => {
    const dictionariesMap = getDictionaryMap(dictionaries);

    const gnomadFilter = ({ property }) =>
      !(!gnomadFeatureEnabled && property === "gnomadAf");

    const renderFields = preset =>
      fieldsConfig
        .filter(gnomadFilter)
        .map(({ property, label, dictionaryName, parser }) => {
          const value = preset[property];

          if (parser && value) {
            const parsedValues = parser(value) || "-";
            return (
              <LabelField key={property} label={label} value={parsedValues} />
            );
          }

          const arrayOfValues = Array.isArray(value) ? value : [value];

          const transformedValues =
            arrayOfValues
              .map(value => {
                const map = dictionariesMap.get(dictionaryName) || new Map();

                return map.get(value) || value;
              })
              .join(", ") || "-";

          return (
            <LabelField
              key={property}
              data-testid={property}
              label={label}
              value={transformedValues}
            />
          );
        });

    return !isEmpty(preset) ? <>{renderFields(preset)}</> : null;
  }
);

FilterPresetInfo.displayName = "FilterPresetInfo";

export default FilterPresetInfo;
