import { isNil, toUpper } from "ramda";
import React from "react";

import { Link } from "../../../../pattern-library/elements/base";
import { formatCVLDate } from "../../utils";

import { ActionValue } from "./ActionValue";
import * as constants from "./constants";
import { isCuratedVariantCreateAction } from "./utils";

export const getColumns = curatedVariantListName => [
  {
    Header: "User",
    accessor: constants.USER_NAME_FIELD,
    Cell: ({
      row: {
        original: {
          [constants.USER_NAME_FIELD]: userName,
          [constants.USER_EMAIL_FIELD]: userEmail,
        },
        original: action,
      },
    }) => {
      if (!isNil(userName)) {
        return (
          <Link
            href={`mailto:${userEmail}?subject=About the Congenica curated list '${curatedVariantListName}'&body=Dear ${userName},`}
          >
            {userName}
          </Link>
        );
      } else if (isCuratedVariantCreateAction(action)) {
        return (
          <span className="audit-empty-user-text">
            Curated variant has been edited. User not available
          </span>
        );
      }
      return null;
    },
  },
  {
    Header: "User action",
    accessor: ({ [constants.USER_ACTION_FIELD]: userAction }) =>
      toUpper(userAction),
    disableSortBy: true,
  },
  {
    Header: "Entity",
    accessor: constants.ACTION_ENTITY_FIELD,
    disableSortBy: true,
  },
  {
    Header: "Old value",
    disableSortBy: true,
    Cell: ({
      row: {
        original: { formattedDelta: { before = [] } = {} },
      },
    }) => <ActionValue fields={before} />,
  },
  {
    Header: "New value",
    disableSortBy: true,
    Cell: ({
      row: {
        original: { formattedDelta: { after = [] } = {} },
      },
    }) => <ActionValue fields={after} />,
  },
  {
    Header: "Date",
    accessor: constants.ACTION_DATE_FIELD,
    sortType: "datetime",
    Cell: ({
      row: {
        original: { [constants.ACTION_DATE_FIELD]: actionDate },
      },
    }) => formatCVLDate(actionDate),
  },
];
