// @flow
import { set } from "dot-prop-immutable";
import { camelize } from "humps";

import type { Action } from "./actions";
import { actionType } from "./actions";
import type { GenePanel, GenePanelInfo } from "./flow-types";
import { convertGenePanelPatientsResponse } from "./utils";

type AddState = {
  new: {
    added: boolean,
    error: boolean,
  },
  upload: {
    processing: boolean,
    loadingData: boolean,
    tempGenePanelId?: number,
    tempGenePanel?: GenePanel,
    genesByName: {
      [geneName: string]: Gene,
    },
    foundGeneNames: Array<string>,
    notFoundGeneNames: Array<string>,
    foundGenesCount: number,
    notFoundGenesCount: number,
    editGene?: {
      gene: Gene,
      keys: Array<string>,
      fields: {
        [FieldName: string]: any,
      },
    },
  },
};

type EditGenePanelState = {
  genePanelInfo?: GenePanelInfo,
  projectPatients: Array<Patient>,
  patients: { [patientId: string]: number },
  writePermission?: string,
  genesByName: {
    [geneName: string]: Gene,
  },
  geneNames: Array<string>,
  loading: boolean,
  searchResult: Array<{}>,
  searchTableFields: {
    [geneName: string]: {
      [fieldName: string]: boolean | string | number,
    },
  },
  searchQuery: string,
  genesSearchInProgress: boolean,
  editGene?: {
    gene: Gene,
    keys: Array<string>,
    fields: {
      [FieldName: string]: any,
    },
  },
  patientListLoading: boolean,
};

export type State = {
  add: AddState,
  edit: EditGenePanelState,
  +ui: {
    +loading: boolean,
  },
  +data: {
    +geneInfo?: {
      gene: Gene,
      keys: Array<string>,
      skip_cols: {
        [col_name: string]: number,
      },
    },
    +genePanelData: Array<GenePanel>,
    +parentGenePanelData: Array<GenePanel>,
  },
};

export const initialAddState: AddState = {
  new: {
    added: false,
    error: false,
  },
  upload: {
    processing: false,
    tempGenePanelId: undefined, //I use to load always the same tempgene Panel for testing
    loadingData: false,
    genesByName: {},
    foundGeneNames: [],
    foundGenesCount: 0,
    notFoundGeneNames: [],
    notFoundGenesCount: 0,
  },
};

export const initialEditGenePanelState: EditGenePanelState = {
  projectPatients: [],
  patients: {},
  genesByName: {},
  geneNames: [],
  searchResult: [],
  searchTableFields: {},
  searchQuery: "",
  loading: false,
  genesSearchInProgress: false,
  patientListLoading: false,
};

export const initialState: State = {
  add: initialAddState,
  edit: initialEditGenePanelState,
  ui: {
    loading: false,
  },
  data: {
    genePanelData: [],
    parentGenePanelData: [],
  },
};

const transformFieldsOptions = fields => {
  const updatedFields = fields;
  Object.keys(fields).forEach(fieldKey => {
    if (fields[fieldKey].options) {
      updatedFields[fieldKey].options = fields[fieldKey].options.map(
        option => ({
          value: option.value,
          label: option.name,
        })
      );
    }
  });

  return updatedFields;
};

const setValue = (path: string, value: any) => state =>
  set(state, `${path}`, value);

export default function reducer(
  state: State = initialState,
  { type, payload }: Action
): State {
  switch (type) {
    case actionType.FETCH_GENE_PANELS_START:
      return set(
        set(state, "data", {
          ...state.data,
          genePanelData: [],
          parentGenePanelData: [],
        }),
        "ui.loading",
        true
      );
    case actionType.FETCH_GENE_PANELS_SUCCESS:
      return set(
        set(state, "data", { ...state.data, ...payload }),
        "ui.loading",
        false
      );
    case actionType.FETCH_GENE_SUCCESS:
      return set(state, "data.geneInfo", {
        ...payload,
        keys: payload.keys.map(camelize),
      });
    case actionType.FETCH_EDIT_TEMP_GENE_SUCCESS:
      return set(state, "add.upload.editGene", {
        ...payload,
        fields: transformFieldsOptions(payload.fields),
        keys: payload.keys.map(camelize),
      });
    case actionType.FETCH_EDIT_GENE_SUCCESS:
      return set(state, "edit.editGene", {
        ...payload,
        fields: transformFieldsOptions(payload.fields),
        keys: payload.keys.map(camelize),
      });
    case actionType.RESET_EDIT_TEMP_GENE_STATE:
      return set(state, "add.upload.editGene", undefined);
    case actionType.RESET_EDIT_GENE_STATE:
      return set(state, "edit.editGene", undefined);
    case actionType.SET_SEARCH_GENE_FORM_FIELD_VALUE:
      return set(
        state,
        `edit.searchTableFields.${payload.geneName}.${payload.fieldName}`,
        payload.value
      );
    case actionType.SEARCH_GENE_START:
      return set(state, "edit.genesSearchInProgress", true);
    case actionType.SEARCH_GENE_SUCCESS:
      const {
        genes: { genes, panelGenes },
        query,
      } = payload;
      return [
        setValue("edit.searchResult", {
          genes,
          panelGenes: Object.keys(panelGenes).map(key => key.toUpperCase()),
        }),
        setValue("edit.searchQuery", query),
        setValue("edit.genesSearchInProgress", false),
      ].reduce((state, f) => f(state), { ...state });
    case actionType.SEARCH_GENE_FAILURE:
      return set(state, "edit.genesSearchInProgress", false);
    case actionType.FETCH_GENE_PANELS_FAILURE:
      return set(
        set(state, "data", {
          ...state.data,
          genePanelData: [],
          parentGenePanelData: [],
        }),
        "ui.loading",
        false
      );
    case actionType.FETCH_GENE_PANEL_INFO_START:
      return set(state, "edit.loading", true);
    case actionType.FETCH_GENE_PANEL_INFO_SUCCESS:
      const { genePanel } = payload;
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
          genePanelInfo: genePanel,
        },
      };
    case actionType.UPDATE_GENE_PANEL_INFO_SUCCESS:
      return set(state, "edit.genePanelInfo", {
        ...state.edit.genePanelInfo,
        ...payload,
      });
    case actionType.FETCH_GENE_PANEL_INFO_FAILURE:
      return set(state, "edit.loading", false);
    case actionType.FETCH_GENE_PANEL_GENE_LIST_SUCCESS:
      return [
        setValue("edit.genesByName", {
          ...state.edit.genesByName,
          ...payload.reduce((accumulate, gene) => {
            accumulate[gene.name] = gene;
            return accumulate;
          }, {}),
        }),
        setValue(
          "edit.geneNames",
          payload.map(gene => gene.name)
        ),
      ].reduce((state, f) => f(state), { ...state });
    case actionType.REMOVE_GENE_FROM_GENE_PANEL_SUCCESS:
      const newGenesCount = state.edit.genePanelInfo
        ? state.edit.genePanelInfo.genesCount - 1
        : 0;
      return [
        setValue(
          // Decreases geneCount in tab label
          "edit.genePanelInfo.genesCount",
          newGenesCount
        ),
        setValue(
          "edit.geneNames",
          state.edit.geneNames.filter(geneName => geneName !== payload.geneName)
        ),
      ].reduce((state, f) => f(state), { ...state });
    case actionType.FETCH_GENE_PANEL_PATIENT_LIST_START:
      return {
        ...state,
        edit: {
          ...state.edit,
          patientListLoading: true,
        },
      };
    case actionType.FETCH_GENE_PANEL_PATIENT_LIST_SUCCESS:
      const {
        projectPatients,
        genePanelPatients: patients,
        genePanelWritePermission: writePermissions,
      } = payload;
      return {
        ...state,
        edit: {
          ...state.edit,
          patients,
          writePermissions,
          patientListLoading: false,
          projectPatients: convertGenePanelPatientsResponse(projectPatients),
        },
      };
    case actionType.FETCH_GENE_PANEL_PATIENT_LIST_FAILURE:
      return {
        ...state,
        edit: {
          ...state.edit,
          patientListLoading: false,
        },
      };
    case actionType.SET_ADD_NEW_GENE_PANEL_IS_ADDED:
      return set(state, "add.new.added", payload);
    case actionType.SET_ADD_NEW_GENE_PANEL_ERROR:
      return set(state, "add.new.error", payload);
    case actionType.FETCH_TEMP_GENE_PANEL_SUCCESS:
      return set(state, "add.upload.tempGenePanel", payload);
    case actionType.UPDATE_TEMP_GENE_PANEL_SUCCESS:
      return set(state, "add.upload.tempGenePanel", {
        ...state.add.upload.tempGenePanel,
        ...payload,
      });
    case actionType.SET_GENE_IN_FOUND_GENES:
      const tempGene = state.add.upload.genesByName[payload.geneName];
      if (!tempGene) return state;
      return set(state, `add.upload.genesByName.${payload.geneName}`, {
        ...tempGene,
        ...payload.data,
      });
    case actionType.SET_GENE_IN_GENES_LIST:
      const gene = state.edit.genesByName[payload.geneName];
      if (!gene) return state;
      return set(state, `edit.genesByName.${payload.geneName}`, {
        ...gene,
        ...payload.data,
      });
    case actionType.FETCH_TEMP_GENE_PANEL_FOUND_GENES_SUCCESS:
      return [
        setValue("add.upload.genesByName", {
          ...state.add.upload.genesByName,
          ...payload.reduce((accumulate, gene) => {
            accumulate[gene.name] = gene;
            return accumulate;
          }, {}),
        }),
        setValue(
          "add.upload.foundGeneNames",
          payload.map(gene => gene.name)
        ),
        setValue("add.upload.foundGenesCount", payload.length),
      ].reduce((state, f) => f(state), { ...state });
    case actionType.FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_SUCCESS:
      return [
        setValue("add.upload.genesByName", {
          ...state.add.upload.genesByName,
          ...payload.reduce((accumulate, gene) => {
            accumulate[gene.name] = gene;
            return accumulate;
          }, {}),
        }),
        setValue(
          "add.upload.notFoundGeneNames",
          payload.map(gene => gene.name)
        ),
        setValue("add.upload.notFoundGenesCount", payload.length),
      ].reduce((state, f) => f(state), { ...state });
    case actionType.UPLOAD_NEW_GENE_PANEL_SUCCESS:
      return set(state, "add.upload.tempGenePanelId", payload);
    case actionType.SET_UPLOAD_NEW_GENE_PANEL_PROCESSING:
      return set(state, "add.upload.processing", payload);
    case actionType.SET_TEMP_GENE_PANEL_LOADING:
      return set(state, "add.upload.loadingData", payload);
    case actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_SUCCESS:
      return [
        setValue(
          "add.upload.foundGenes",
          state.add.upload.foundGeneNames.filter(
            geneName => state.add.upload.genesByName[geneName].name !== payload
          )
        ),
        setValue(
          "add.upload.foundGenesCount",
          state.add.upload.foundGenesCount - 1
        ),
      ].reduce((state, f) => f(state), { ...state });
    case actionType.CLEAR_ADD_STATE:
      return set(state, "add", initialAddState);
    case actionType.CLEAR_EDIT_GENE_PANEL_STATE:
      return set(state, "edit", initialEditGenePanelState);
    default:
      return state;
  }
}
