import React, { memo } from "react";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { FEATURE_NAMES_MAP } from "../constants";

interface VersionsProps {
  versions?: Array<Version>;
  loading?: boolean;
}

const versionSummaryColumns = [
  {
    Header: "Feature",
    accessor: ({ name }) => FEATURE_NAMES_MAP[name] || name,
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Version",
    accessor: "version",
  },
];

export const Versions = ({ versions = [], loading = false }: VersionsProps) => (
  <div data-testid="versions-table">
    <Table
      title="Current reference data versions"
      titleHelpText="The below data will be used for any newly processed samples"
      columns={versionSummaryColumns}
      data={versions}
      autoResetPage={false}
      noDataText="No versions data available."
      loading={loading}
      showPagination={versions.length > DEFAULT_PAGE_SIZE}
      enableFilter
      showTitleInfo
      manualGlobalFilter={false}
    />
  </div>
);

export default memo(Versions);
