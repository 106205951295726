import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Table, DEFAULT_PAGE_SIZE } from "pattern-library/elements/table7";

import { loadExistingLists } from "../actions";
import { DISPLAY_MODE_ADD_EXISTING } from "../constants";
import {
  getDisplayMode,
  getExistingCuratedLists,
  getProjectId,
} from "../selectors";
import { ProjectCuratedLists } from "../types";

import AddExistingList from "./AddExistingList";

const columns = [
  {
    Header: "Curated variant list",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Action",
    Cell: row => {
      const {
        row: { original: cvl },
      } = row;
      return (
        <div className="add-existing-list-action">
          <AddExistingList curatedList={cvl} />
        </div>
      );
    },
    disableSortBy: true,
  },
];

export const CVLExistingLists = ({
  existingCuratedLists,
  loadExistingLists,
  displayMode,
  projectId,
}: PropsFromRedux) => {
  const [filterValue, setFilterValue] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<
    Array<ProjectCuratedLists>
  >([]);

  const filter = (items, filterValue) =>
    items.filter(
      item =>
        item.name.toUpperCase().includes(filterValue.toUpperCase()) ||
        (item.description &&
          item.description.toUpperCase().includes(filterValue.toUpperCase()))
    );

  useEffect(() => {
    if (displayMode === DISPLAY_MODE_ADD_EXISTING) {
      loadExistingLists(projectId);
    }
  }, [displayMode, loadExistingLists, projectId]);

  useEffect(() => {
    setFilteredItems(filter(existingCuratedLists, filterValue));
  }, [existingCuratedLists, filterValue, setFilteredItems]);

  const onFilterChange = useCallback(
    ({ filter = "" }) => {
      setFilterValue(filter);
    },
    [setFilterValue]
  );

  return (
    <div className="curated-lists-view-existing" data-testid="existing-lists">
      <Table
        columns={columns}
        fetchData={onFilterChange}
        data={filteredItems}
        showPagination={filteredItems.length > DEFAULT_PAGE_SIZE}
        pageSizeOptions={[10, 25, 50, 100]}
        enableFilter
        title="Curated variant lists you have access to"
        sortBy={[
          {
            id: "name",
            desc: false,
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  existingCuratedLists: getExistingCuratedLists(state),
  displayMode: getDisplayMode(state),
  projectId: getProjectId(state),
});

const mapDispatchToProps = {
  loadExistingLists,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLExistingLists);
