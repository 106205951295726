export const ENTITY_TYPE = "multiple_project_feature_settings";

export const EMPTY_AUTOMATION_SETTINGS = {
  enabled: false,
  curatedVariantListId: null,
  moiRestrictionEnabled: true,
  automatedReportingEnabled: false,
  reportTemplateId: null,
  refNoCallEnabled: false,
};
export const EMPTY_AUTO_ACMG_SETTINGS = {
  enabled: false,
};

export const EMPTY_SETTINGS = {
  automation: EMPTY_AUTOMATION_SETTINGS,
  autoAcmg: EMPTY_AUTO_ACMG_SETTINGS,
};
