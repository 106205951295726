// @flow
import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import * as constants from "./constants";

type State = {
  +ui: {
    +loaded: boolean,
    +projectTypesForChildProjectsLoaded: boolean,
    +projectTypesForChildProjects: Array<ProjectType>,
  },
  +data: Array<{}>,
};

export const initialState: State = {
  ui: {
    loaded: false,
    projectTypesForChildProjectsLoaded: false,
    projectTypesForChildProjects: [],
  },
  data: [],
};
const setValue = (path: string, value: any) => state =>
  set(state, `${path}`, value);

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case constants.READ_PROJECTS:
      return set(state, "ui.loaded", false);

    case constants.READ_PROJECTS_SUCCESS:
      return [
        setValue("data", action.payload),
        setValue("ui.loaded", true),
      ].reduce((state, f) => f(state), { ...state });

    case constants.READ_PROJECTS_FAILURE:
      return [setValue("data", []), setValue("ui.loaded", true)].reduce(
        (state, f) => f(state),
        { ...state }
      );

    case constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS:
      return [
        setValue("ui.projectTypesForChildProjects", []),
        setValue("ui.projectTypesForChildProjectsLoaded", false),
      ].reduce((state, f) => f(state), { ...state });

    case constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_FAILURE:
      return set(state, "ui.projectTypesForChildProjectsLoaded", true);

    case constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_SUCCESS:
      return [
        setValue("ui.projectTypesForChildProjects", action.payload),
        setValue("ui.projectTypesForChildProjectsLoaded", true),
      ].reduce((state, f) => f(state), { ...state });

    default:
      return state;
  }
}
