import Genoverse from "genoverse";

import { createMenu, createTable } from "../utils";

export const Controller = {
  makeMenu(feature) {
    const { menuElements, populationLabels, populations } = this.track;
    const { quality, evidences, consequences, start, end, af, length } =
      feature;

    const Labels = {
      title: "title",
      location: "Location",
      class: "Class",
      qualityScore: "Quality Score",
      alleleFrequency: "Allele Frequency",
      size: "Length",
      evidence: "Evidence",
      consequence: "Consequence",
      tableDivider: "<!--- Table Divider--->",
      consequenceDivider: "<!--- Consequence Divider--->",
    };

    const Identifiers = {
      title: "title",
      location: "location",
      class: "class",
      qualityScore: "quality_score",
      alleleFrequency: "allele_frequency",
      size: "size",
      evidence: "evidence",
      consequence: "consequence",
      tableDivider: "table_divider",
      consequenceDivider: "consequence_divider",
    };

    const menuOrder = [
      Identifiers.title,
      Identifiers.location,
      Identifiers.size,
      Identifiers.class,
      Identifiers.qualityScore,
      Identifiers.alleleFrequency,
      Identifiers.evidence,
      Identifiers.consequenceDivider,
      Identifiers.consequence,
      Identifiers.tableDivider,
    ];

    const applyPrecision = value => Number(value).toFixed(6);

    const menuItems = [
      {
        id: Identifiers.title,
        label: Labels.title,
        value: menuElements.title(feature),
      },
      {
        id: Identifiers.location,
        label: Labels.location,
        value: `${this.browser.chr}:${start}-${end}`,
      },
      {
        id: Identifiers.class,
        label: Labels.class,
        value: menuElements.classification(feature),
      },
      {
        id: Identifiers.qualityScore,
        label: Labels.qualityScore,
        value: quality,
      },
      {
        id: Identifiers.alleleFrequency,
        label: Labels.alleleFrequency,
        value: applyPrecision(af),
      },
      {
        id: Identifiers.tableDivider,
        label: Labels.tableDivider,
        value: "<br>",
      },
    ];

    if (length && length > 0) {
      menuItems.push({
        id: Identifiers.size,
        label: Labels.size,
        value: `${Number(length).toLocaleString()} bp`,
      });
    }

    if (evidences && evidences.length > 0) {
      menuItems.push({
        id: Identifiers.evidence,
        label: Labels.evidence,
        value: menuElements.evidence(feature),
      });
    }

    if (consequences && consequences.length > 0) {
      menuItems.push(
        {
          id: Identifiers.consequenceDivider,
          label: Labels.consequenceDivider,
          value: "<br>",
        },
        {
          id: Identifiers.consequence,
          label: Labels.consequence,
          value: menuElements.consequence(feature),
        }
      );
    }

    const byMenuOrder = (a, b) =>
      menuOrder.indexOf(a.id) - menuOrder.indexOf(b.id);

    menuItems.sort(byMenuOrder);

    const menu = createMenu(menuItems, { divider: ", " });

    const format = value =>
      value === undefined || value === null ? "N/A" : value;

    const createTableRow = (name, prefix) => [
      name,
      applyPrecision(feature[`${prefix}_af`]),
      format(feature[`${prefix}_ac`]),
      format(feature[`${prefix}_an`]),
      format(feature[`${prefix}_hom`]),
    ];

    const createTableTotals = () => [
      "<b>Total</b>",
      `<b>${applyPrecision(feature.af)}</b>`,
      `<b>${format(feature.ac)}</b>`,
      `<b>${format(feature.an)}</b>`,
      `<b>${format(feature.hom)}</b>`,
    ];

    const table = createTable({
      head: populationLabels,
      body: [
        ...populations.map(({ prefix, name }) => createTableRow(name, prefix)),
        createTableTotals(),
      ],
    });

    return { ...menu, ...table };
  },

  populateMenu(feature) {
    const { breakdownUrl } = this.track;
    const { id, chr, start, end } = feature;
    const params = {
      __CHR__: chr,
      __START__: start,
      __END__: end,
      __ID__: id,
    };

    let url = breakdownUrl;

    Object.entries(params).forEach(
      ([key, value]) => (url = url.replace(key, value))
    );

    return new Promise(resolve =>
      fetch(url)
        .then(response => response.json())
        .then(response => {
          const { id, attributes } = response.data;
          const feature = { id, ...attributes };
          const menu = this.makeMenu(feature);
          resolve(menu);
        })
    );
  },
};

export const Model = {
  parseData(response, chr, start, end) {
    this.base(response.data, chr, start, end);
  },

  insertFeature(feature) {
    const data = {
      id: feature.id,
      chr: feature.chr,
      ...feature.attributes,
    };

    const { color, legend, labelColor } = (this.track.colors || []).find(
      color => color.predicate(data)
    );

    this.base({
      ...data,
      color,
      legend,
      labelColor,
    });
  },
};

export const Config = {
  id: "Congenica.StructuralVariantsGnomAD",
  namespace: "Congenica.StructuralVariantsGnomAD",
  name: "gnomAD (v2.1): Structural variants",
  category: "Structural variants",
  tags: ["gnomAD", "Structural variants"],
  height: 100,
  featureHeight: 10,
  legend: true,
  bump: true,
  model: Model,
  controller: Controller,
};

export const Track = Genoverse.Track.extend({
  ...Config,
  model: Genoverse.Track.Model.extend(Config.model),
  controller: Genoverse.Track.Controller.extend(Config.controller),
});
