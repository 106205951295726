import { camelizeKeys } from "humps";
import React from "react";

import { Loading } from "pattern-library";

import { GenoverseConfig } from "modules/genoverse/GenoverseBrowser/GenoverseBrowser.types";

import GenoverseBrowser from "../genoverse/GenoverseBrowser/GenoverseBrowser";

import { getTracksLibrary } from "./trackLibraryHelpers";

import congenicaApi from "api/congenica-api";
import { useEnsemblVersion } from "hooks/useEnsemblVersion";

export type CongenicaGenoverseBrowserProps = {
  config: GenoverseConfig;
};

const { useGetPatientQuery } = congenicaApi;

const CongenicaGenoverseBrowser: React.FC<CongenicaGenoverseBrowserProps> = ({
  config,
}) => {
  const { data: patientConfig } = useGetPatientQuery({
    patientId: config.patientId,
  });

  const { ensemblVersion, isSuccess: isEnsemblVersionLoaded } =
    useEnsemblVersion(config.patientId);

  if (!patientConfig || (!ensemblVersion && !isEnsemblVersionLoaded)) {
    return <Loading />;
  }

  const camelCasedPatient: PatientEntity = camelizeKeys(patientConfig);

  const extendedConfig = {
    ...config,
    ensemblVersion,
  };

  const tracksLibrary = getTracksLibrary(extendedConfig, camelCasedPatient);
  return (
    <GenoverseBrowser
      className="congenica-genoverse-browser"
      config={{
        ...extendedConfig,
        tracksLibrary,
        saveable: true,
        saveKey: `congenicaGenoverse-${config.type}`,
        storageType: "localStorage",
      }}
    />
  );
};

export default CongenicaGenoverseBrowser;
