import { decamelizeKeys } from "humps";

import {
  CvlListResponse,
  ProjectCuratedVariantList,
} from "modules/curatedVariantsLists/types";
import { flattenCVLs } from "modules/curatedVariantsLists/utils";
import { getVepConsequenceLabel } from "modules/utils/common";
import { CheckboxOption } from "modules/utils/prop-types/CheckboxOptionProp";

import {
  flattenApiList,
  flattenApiResponse,
  getApiListData,
  getFilterParams,
  getJsonApiAttrs,
} from "./utils/common";
import { prepareRoles } from "./utils/users";

import baseApi from "./index";

const baseUrl = "/webapi";

const congenicaApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * System config
     */
    getSystemConfig: builder.query<SystemConfig, void>({
      query: () => `${baseUrl}/utils/config`,
      extraOptions: { camelize: true },
    }),

    /**
     * Patient endpoints
     */
    getPatient: builder.query<any, PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}`,
      }),
      transformResponse: getJsonApiAttrs,
    }),

    /**
     * VEP consequences
     */
    getVEPConsequences: builder.query<CheckboxOption[], void>({
      query: () => ({
        url: `${baseUrl}/entities/vep_consequences`,
      }),
      transformResponse: (resp: APIEntityArray) =>
        resp.data.map(({ id }) => ({
          key: id,
          label: getVepConsequenceLabel(id),
        })),
    }),

    /**
     * Third Party Data & Software endpoints
     */
    getThirdPartyDataVersions: builder.query<
      Version[],
      ThirdPartyFilterOptions
    >({
      query: filterOptions => ({
        url: `${baseUrl}/entities/thirdparty_data_versions`,
        params: getFilterParams(filterOptions),
      }),
      transformResponse: flattenApiList,
    }),
    getThirdPartySoftwareVersions: builder.query<
      Version[],
      ThirdPartyFilterOptions
    >({
      query: filterOptions => ({
        url: `${baseUrl}/entities/thirdparty_software_versions`,
        params: getFilterParams(filterOptions),
      }),
      transformResponse: flattenApiList,
    }),
    getProjectSettings: builder.query<ProjectSettings, ProjectDependant>({
      query: ({ projectId }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/settings/defaults`,
      }),
      transformResponse: flattenApiResponse,
      extraOptions: {
        camelize: true,
      },
    }),
    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: `${baseUrl}/entities/current_user`,
      }),
      transformResponse: flattenApiResponse,
    }),
    getAuthProvidersByUser: builder.query<AuthProvider[], { userId: number }>({
      query: ({ userId }) => ({
        url: `${baseUrl}/entities/authentication_providers/users/${userId}`,
      }),
      transformResponse: flattenApiList,
      extraOptions: {
        camelize: true,
      },
    }),
    getAuthProviders: builder.query<AuthProvider[], void>({
      query: () => ({
        url: `${baseUrl}/entities/authentication_providers`,
      }),
      transformResponse: flattenApiList,
      extraOptions: {
        camelize: true,
      },
    }),
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: `${baseUrl}/entities/projects/roles`,
      }),
      transformResponse: prepareRoles,
      extraOptions: {
        camelize: true,
      },
    }),
    getLatestReportProposal: builder.query<ReportProposal, PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/report_proposal/latest`,
        validateStatus: response =>
          response.status === 200 || response.status === 404,
      }),
      transformResponse: getJsonApiAttrs,
      providesTags: _ => ["ReportProposal"],
      extraOptions: {
        camelize: true,
      },
    }),
    getPatientReports: builder.query<PatientReport[], PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/reports`,
      }),
      transformResponse: (response: { data }) => response.data,
      providesTags: _ => ["PatientStatus"],
      extraOptions: {
        camelize: true,
      },
    }),
    setReportProposal: builder.mutation<
      ReportProposal,
      Partial<ReportProposal> & Required<PatientDependant>
    >({
      query({ patientId, ...body }) {
        return {
          url: `${baseUrl}/entities/patients/${patientId}/report_proposal`,
          method: "POST",
          body: JSON.stringify({
            data: {
              type: "report_proposal",
              attributes: decamelizeKeys(body),
            },
          }),
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
        };
      },
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["ReportProposal"],
    }),
    getOncologyQCMetrics: builder.query<OncologyQcData, PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/oncology/qc_metrics`,
        validateStatus: response =>
          response.status === 200 || response.status === 404,
      }),
      transformResponse: getJsonApiAttrs,
      extraOptions: {
        camelize: true,
      },
    }),

    getProjectFeaturesSettings: builder.query<
      ProjectFeaturesSettings,
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/features/settings`,
      }),
      transformResponse: getJsonApiAttrs,
      extraOptions: {
        camelize: true,
      },
      providesTags: ["ProjectFeaturesSettings"],
    }),

    saveProjectFeaturesSettings: builder.mutation<
      ProjectFeaturesSettings,
      ProjectDependant & ProjectFeaturesSettingsPayload
    >({
      query: ({ projectId, ...payload }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/features/settings`,
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
      }),
      extraOptions: {
        camelize: true,
      },
      transformResponse: getJsonApiAttrs,
      invalidatesTags: ["ProjectFeaturesSettings"],
    }),

    getReportTemplates: builder.query<
      Array<ReportServiceTemplate>,
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/report_service_templates`,
      }),
      transformResponse: getApiListData,
    }),

    getProjectCuratedLists: builder.query<
      Array<ProjectCuratedVariantList>,
      ProjectDependant & { filterOptions?: CVLsFilterOptions }
    >({
      query: ({ projectId, filterOptions = {} }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/curated_variant_lists`,
        params: getFilterParams(filterOptions),
      }),
      extraOptions: {
        camelize: true,
      },
      transformResponse: (response: CvlListResponse) =>
        flattenCVLs(getApiListData(response)),
    }),

    getOncologyVariantsSNVs: builder.query<OncologySNV[], PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/oncology/variants/snvs`,
      }),
      transformResponse: flattenApiList,
    }),

    getOncologyVariantsCNVs: builder.query<OncologyCNV[], PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/oncology/variants/cnvs`,
      }),
      transformResponse: flattenApiList,
    }),
  }),
});

export default congenicaApi;
