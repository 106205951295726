import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getFormValues } from "modules/forms/selectors";
import { getSelectedPresetOption } from "modules/structuralVariantsPresets/selectors";

import * as constants from "./constants";
import { getGenePanelsOptions } from "./utils";

import useAvailablePatientGenePanels from "hooks/usePatientGenePanels";

/**
 * Gets options for Gene panels dropdown in SV filter sidebar
 */
const usePatientGenePanelsOptions = patientId => {
  const genePanelsResponse = useAvailablePatientGenePanels(patientId);
  const { genePanels } = genePanelsResponse;
  const selectedPanelsIds = useSelector(state =>
    getFormValues(state, constants.SV_FILTERS_FORM, "genePanel")
  );
  const selectedFilterPreset = useSelector(getSelectedPresetOption);

  return useMemo(
    () =>
      getGenePanelsOptions(genePanels, selectedPanelsIds, selectedFilterPreset),
    [genePanels, selectedPanelsIds, selectedFilterPreset]
  );
};

export default usePatientGenePanelsOptions;
