import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";

import Divider from "pattern-library/elements/base/Divider";
import { inputsColSizes } from "pattern-library/modules/form-field/FormField";

import ReduxFormField from "modules/forms/components/ReduxFormField";
import Message from "modules/messages/components/Message";

import styles from "./config-section.module.scss";

const renderControls = (controls, inputsColSize, className, showDividers) =>
  controls.map((control, index, arr) => {
    const {
      description,
      descriptionPosition,
      key,
      label,
      name,
      options,
      type,
      ...rest
    } = control;

    const showDivider = showDividers !== false && index + 1 !== arr.length;
    const identifier = key || name || label;

    const fieldProps = {
      id: control.key,
      name: identifier,
      descriptionPosition: descriptionPosition || "underLabel",
      label,
      type,
      description,

      // Pass options and items props, as the prop names aren't consistent for radio buttons/checkboxes
      options,
      items: options,
      ...rest,
    };

    return (
      <div key={control.key}>
        <Field
          narrow={inputsColSize}
          component={ReduxFormField}
          className={className}
          {...fieldProps}
        />

        {showDivider && <Divider />}
      </div>
    );
  });

export const ConfigSection = ({
  header,
  subtitle,
  disabled,
  information,
  controls,
  inputsColSize = 8,
  className,
  showDividers = true,
}) => {
  const {
    header: infoHeader,
    description: infoDescription,
    icon,
  } = information || {};
  const messageContent = (
    <div className="flex">
      {icon}
      <div>
        <strong>{infoHeader}</strong>
        <div>{infoDescription}</div>
      </div>
    </div>
  );
  return (
    <fieldset
      data-testid="config-section"
      disabled={disabled}
      className="form-horizontal"
    >
      {header && (
        <h4 className={styles.header}>
          {header}{" "}
          {subtitle && <small className="text-muted">{subtitle}</small>}
        </h4>
      )}

      {(infoHeader || infoDescription) && (
        <div className={styles.configSectionInfo}>
          <Message message={messageContent} level="secondary" />
        </div>
      )}

      {renderControls(controls || [], inputsColSize, className, showDividers)}
    </fieldset>
  );
};

ConfigSection.propTypes = {
  header: PropTypes.string,
  disabled: PropTypes.bool,
  information: PropTypes.shape({
    header: PropTypes.string,
    description: PropTypes.string,
  }),
  // TODO: Add proper prop types for field config here
  controls: PropTypes.arrayOf(PropTypes.any),
  inputsColSize: PropTypes.oneOf(inputsColSizes),
  className: PropTypes.string,
};
