import classnames from "classnames";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import styles from "./WideContentWrapper.module.scss";
import { WideContentWrapperTypes } from "./WideContentWrapper.types";

// The component that adds horizontal scrollbar inside it, if inner content requires it
// (and prevent adding horizontal scrollbar for whole page).
const WideContentWrapper: FunctionComponent<WideContentWrapperTypes> = ({
  children,
  className,
  useScrollableToClassNames = true,
}) => {
  const scrollableEl = useRef<HTMLDivElement>(null);

  const [reachedEdges, setReachedEdges] = useState({
    left: true, // is horizontal scroll on the extreme left position
    right: true, // is horizontal scroll on the extreme right position
  });

  const calcReachedEdges = () => {
    const el = scrollableEl.current;
    if (!el) {
      return;
    }
    setReachedEdges({
      left: el.scrollLeft <= 0,
      right: el.scrollLeft + el.offsetWidth >= el.scrollWidth,
    });
  };

  useEffect(calcReachedEdges, [scrollableEl, setReachedEdges]);

  const classNames = classnames(styles.component, className, {
    [styles.scrollableToLeft]: useScrollableToClassNames && !reachedEdges.left,
    [styles.scrollableToRight]:
      useScrollableToClassNames && !reachedEdges.right,
  });

  // `max-width` and `overflow-x` styles are important for this component and `style` attr is used intentionally,
  // so that there is an opportunity to test it with snapshots.
  return (
    <div
      className={classNames}
      data-testid="wide-content-wrapper"
      style={{ maxWidth: "100%" }}
    >
      <div
        ref={scrollableEl}
        style={{ maxWidth: "100%", overflowX: "auto" }}
        onScroll={calcReachedEdges}
      >
        {children}
      </div>
    </div>
  );
};

export default WideContentWrapper;
