import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Link from "../../elements/base/Link";

export default class ListItem extends PureComponent {
  static displayName = "ListItem";

  static propTypes = {
    /**
     * Function to invoke when the list item is clicked
     */
    handleClick: PropTypes.func,
    /**
     * The page index to pass to the handleClick function
     */
    page: PropTypes.number.isRequired,
    /**
     * Underlying link's destination
     */
    href: PropTypes.string.isRequired,
    /**
     * Child nodes to render
     */
    children: PropTypes.node.isRequired,
    /**
     * Class to apply to the link component
     */
    linkClass: PropTypes.string,
    /**
     * Class to apply to the li element
     */
    liClass: PropTypes.string,
    /**
     * Link label
     */
    linkLabel: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleClick(e) {
    e.preventDefault();
    const { handleClick, page } = this.props;
    if (typeof handleClick === "function") {
      handleClick(page);
    }
  }

  render() {
    const { href, children, linkClass, liClass, linkLabel } = this.props;

    return (
      <li className={liClass}>
        <Link
          onClick={this.handleClick}
          href={href}
          className={linkClass}
          aria-label={linkLabel}
        >
          {children}
        </Link>
      </li>
    );
  }
}
