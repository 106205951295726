import { camelizeKeys } from "humps";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { error } from "modules/messages/actions";

import { actionType, fetchAcmgAuditInfo, fetchAuditInfo } from "./actions";
import {
  acmgAuditInfoRequest,
  cvlRequest,
  genePanelsRequest,
  patientDetailsRequest,
} from "./api";

export function* init() {
  yield takeLatest(actionType.FETCH_AUDIT_START, handleFetchAuditInfo);
  yield takeLatest(actionType.FETCH_ACMG_AUDIT_START, handleFetchAcmgAuditInfo);
}

export function checkResponse(
  response,
  parameterName = "audit information",
  action = "Cannot load"
): Payload {
  const { ok, payload } = response;
  if (ok) {
    return camelizeKeys(payload);
  } else {
    throw new Error(
      `${action} ${parameterName}${
        response.statusText ? "\nDetails: " + response.statusText : ""
      }`
    );
  }
}

export function* handleFetchAuditInfo({
  payload: patientId,
}: ReturnType<typeof fetchAuditInfo.start>) {
  try {
    const [cvlResponse, patientDetailsResponse, genePanelsResponse] = yield all(
      [
        call(cvlRequest, patientId),
        call(patientDetailsRequest, patientId),
        call(genePanelsRequest, patientId),
      ]
    );

    const cvlPayload = checkResponse(cvlResponse, "curated variant list");
    const {
      data: { id, attributes = { name: "" } },
    } = cvlPayload;
    const cvl = { id, ...attributes };

    const genePanelsPayload = checkResponse(
      genePanelsResponse,
      "patient details"
    );
    const genePanels = genePanelsPayload.data.map(
      ({ attributes }) => attributes
    );

    const patientDetailsPayload = checkResponse(
      patientDetailsResponse,
      "gene panels"
    );
    const {
      data: { attributes: patientDetails },
    } = patientDetailsPayload;

    yield put(fetchAuditInfo.success({ cvl, genePanels, patientDetails }));
  } catch ({ message }) {
    yield put(fetchAuditInfo.failure());
    yield put(error(message ? message : "Cannot load audit information"));
  }
}

export function* handleFetchAcmgAuditInfo({
  payload: patientId,
}: ReturnType<typeof fetchAcmgAuditInfo.start>) {
  try {
    const response = yield call(acmgAuditInfoRequest, patientId);
    const { data: { attributes: acmgAuditDetails } = {} } = checkResponse(
      response,
      "ACMG audit"
    );
    yield put(fetchAcmgAuditInfo.success(acmgAuditDetails));
  } catch ({ message }) {
    yield put(fetchAcmgAuditInfo.failure());
    yield put(error(message));
  }
}
