import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { range } from "ramda";
import React, { PureComponent } from "react";

import ListItem from "./ListItem";

export default class Pagination extends PureComponent {
  static displayName = "Pagination";

  static propTypes = {
    /**
     * The current page index
     */
    pageIndex: PropTypes.number,
    /**
     * The total number of pages
     */
    pageCount: PropTypes.number,
    /**
     * The function invoked on page change
     */
    onPageChange: PropTypes.func.isRequired,
    /**
     * The maximum number of pages to the right and left of the selected
     */
    pagesAhead: PropTypes.number,
  };

  static defaultProps = {
    pageIndex: 0,
    pageCount: 0,
    pagesAhead: 5,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handlePageChange(page) {
    const { pageIndex, pageCount, onPageChange } = this.props;

    if (page >= 0 && page < pageCount && page !== pageIndex) onPageChange(page);
  }

  render() {
    const { pageIndex, pageCount, pagesAhead } = this.props;

    const pagesElements = range(0, pageCount)
      .map(i => {
        if (
          i === 0 ||
          i === pageCount - 1 ||
          Math.abs(i - pageIndex) <= pagesAhead
        ) {
          return (
            <ListItem
              key={i}
              linkLabel="page link"
              liClass={classNames({ active: pageIndex === i })}
              handleClick={this.handlePageChange}
              page={i}
              href="#"
            >
              {i + 1}
            </ListItem>
          );
        } else if (
          (i === 1 || i === pageCount - 2) &&
          Math.abs(i - pageIndex) >= pagesAhead
        ) {
          return (
            <ListItem
              key={i}
              linkClass="disabled"
              linkLabel="disabled"
              page={0}
              href="#"
            >
              …
            </ListItem>
          );
        } else {
          return null;
        }
      })
      .filter(i => i !== null);

    return (
      <ul className="pagination">
        <ListItem
          liClass={classNames({ disabled: pageIndex <= 0 })}
          handleClick={this.handlePageChange}
          page={pageIndex - 1}
          href="#"
          linkLabel="Previous"
        >
          Previous
        </ListItem>
        {pagesElements}
        <ListItem
          liClass={classNames({ disabled: pageIndex >= pageCount - 1 })}
          handleClick={this.handlePageChange}
          page={pageIndex + 1}
          href="#"
          linkLabel="Next"
        >
          Next
        </ListItem>
      </ul>
    );
  }
}
