import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Modal, ModalBody, ModalHeader, MODAL_SIZES } from "pattern-library";

import SNVTableConfig from "./components/SNVTableConfig";

export default class ConfigContainer extends PureComponent {
  static propTypes = {
    close: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { close, show, patientId } = this.props;

    return (
      <Modal
        id="snv-table-config-modal"
        data-testid="snv-table-config-modal"
        close={close}
        show={show}
        size={MODAL_SIZES.XL}
      >
        <ModalHeader className="config-modal-header" close={close}>
          Configuration
        </ModalHeader>
        <ModalBody>
          <form name="snv-table-config-edit">
            <SNVTableConfig patientId={patientId} />
          </form>
        </ModalBody>
      </Modal>
    );
  }
}
