// @flow
import { attr } from "redux-orm";

import BaseModel from "./BaseModel";

class Gene extends BaseModel {
  static modelName = "Gene";

  static fields = {
    geneId: attr(),
    defaultTranscriptId: attr(),
    description: attr(),
    hiRgb: attr(),
    hiScore: attr(),
    morbidId: attr(),
    name: attr(),
    oldName: attr(),
    omimId: attr(),
    synonyms: attr(),
    variants: attr(),
    // This is something that is on this model but declared on another model
    // decisions: many('Decision')
  };

  static options = {
    idAttribute: "geneId",
  };

  // This calls the snv endpoint which returns gene's
  // facepalm
  // snv endpoint is actually searching the geneToVariant join table
  static getMethodUrlMap() {
    return {
      CREATE: `${this.basePath}/${this.modelName.toLowerCase()}`,
      READ: `${this.basePath}/patient/:id/snv`,
      UPDATE: `${this.basePath}/${this.modelName.toLowerCase()}/:id`,
      DELETE: `${this.basePath}/${this.modelName.toLowerCase()}/:id`,
      LIST: `${this.basePath}/${this.modelName.toLowerCase()}`,
    };
  }

  static parse(data: { variants: Array<{}>, geneId: number }) {
    return this.upsert(data);
  }

  toString() {
    return this.name;
  }
}

export default Gene;
