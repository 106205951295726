import catalystApi from "api/catalyst-api";

const { useGetPatientGenePanelsQuery } = catalystApi;

/**
 * Retrieves gene panels available for a given patient
 */
const useAvailablePatientGenePanels = (patientId: number) =>
  useGetPatientGenePanelsQuery(
    { patientId },
    {
      selectFromResult: result => ({
        ...result,
        genePanels: result.data,
      }),
    }
  );

export default useAvailablePatientGenePanels;
