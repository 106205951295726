export const UK_REGION = "UK";
export const EU_REGION = "EU";

//description text
export const IVD_TEXT = "In vitro diagnostic medical device";
export const TRAINED_PERSONNEL_WARNING_TEXT =
  "This software is to be used by trained personnel only";
export const ADDRESS_TEXT =
  "Congenica Ltd.\nBiodata Innovation Centre,\nWellcome Genome Campus,\nHinxton,\nCambridgeshire,\nCB10 1DR,\nUnited Kingdom.";
export const EC_REP_TEXT =
  "Acorn Regulatory\nKnockmorris\nCahir\nCo. Tipperary\nIreland\nE21R766";

export const MODULE_CONFIG_MAP = {
  rareDisease: {
    name: "Rare Disease",
    moduleNumber: "001",
  },
  oncology: {
    name: "Oncology",
    moduleNumber: "002",
  },
};
