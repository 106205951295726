import { isNil } from "ramda";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Pane, Loading } from "pattern-library";

import { getPatient, reinitOncologyPatientOverview } from "modules/patient";
import { tumourTypeRenderer } from "modules/patients/components/columns";

import styles from "./Container.module.scss";

interface Props extends PropsFromRedux {
  patientId: number;
}
export const Container = ({
  patientId,
  reinitOncologyPatientOverview,
  patient,
}: Props) => {
  const {
    reference,
    oncology: { sex = "", baitset = "", neoplasticCellularity = null } = {},
    oncology = {},
  } = patient || {};
  useEffect(() => {
    if (reinitOncologyPatientOverview) reinitOncologyPatientOverview(patientId);
  }, [patientId, reinitOncologyPatientOverview]);

  if (isNil(patient)) {
    return <Loading />;
  }

  return (
    <div className={styles.patient__overview__oncology_container}>
      <Pane
        border={false}
        data={[
          {
            key: "Sample ID",
            value: reference,
          },
          {
            key: "Sex",
            value: sex,
          },
          {
            key: "Baitset",
            value: baitset,
          },
          {
            key: "Tumour type",
            value: tumourTypeRenderer(oncology),
          },
          {
            key: "Neoplastic Cellularity (%)",
            value: neoplasticCellularity,
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  patient: getPatient(state),
});

const mapDispatchToProps = {
  reinitOncologyPatientOverview,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Container);
