// @flow
import {
  BLOCK_UI_SYSTEM_CONFIG,
  SET_AUTH_PROVIDERS,
  SET_SYSTEM_CONFIG,
} from "./constants";

export type SystemConfigState = {
  config: SystemConfig,
  authProviders?: Array<AuthProvider>,
  blockUI: boolean,
};

export const initialState = {
  config: {
    sapientiaAssembly: undefined,
    sentieonAvailable: false,
    referenceGenome: undefined,
    cloudProvider: undefined,
    ceMarking: undefined,
    oncologyAllowThirdPartyVariantCalling: false,
    versions: {},
  },
  authProviders: undefined,
  blockUI: false,
};

export default function reducer(
  state: SystemConfigState = initialState,
  action: any
) {
  const { type, payload } = action;

  switch (type) {
    case SET_SYSTEM_CONFIG:
      return {
        ...state,
        config: {
          ...payload,
        },
      };
    case SET_AUTH_PROVIDERS:
      return {
        ...state,
        authProviders: payload,
      };
    case BLOCK_UI_SYSTEM_CONFIG:
      return {
        ...state,
        blockUI: true,
      };
    default:
      return state;
  }
}
