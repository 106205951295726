import { isNil } from "ramda";

type AminoAcid = string;

type GranthamClassification =
  | "conservative"
  | "moderately conservative"
  | "moderately radical"
  | "radical";

export const granthamMatrixOrder: AminoAcid[] = [
  "A",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "K",
  "L",
  "M",
  "N",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "V",
  "W",
  "Y",
];

/*
  Lookup matrix of Grantham scores

  The lower half of the matrix is not needed as it's symmetrical when filled in
  We ignore this and instead alphabetically sort the amino acids

  Original Perl implementation was taken from:
  https://github.com/Congenica/sapientia-web/issues/1299#issuecomment-144344452
    ->
  https://gist.github.com/arq5x/5408712
 */
// prettier-ignore
const granthamMatrix: Record<AminoAcid, number[]> = {
  /* eslint-disable prettier/prettier */
  //   A    C    D    E    F    G    H    I    K    L    M    N    P    Q    R    S    T    V    W    Y
  A: [0  , 195, 126, 107, 113, 60 , 86 , 94 , 106, 96 , 84 , 111, 27 , 91 , 112, 99 , 58 , 64 , 148, 112],
  C: [0  , 0  , 154, 170, 205, 159, 174, 198, 202, 198, 196, 139, 169, 154, 180, 112, 149, 192, 215, 194],
  D: [0  , 0  , 0  , 45 , 177, 94 , 81 , 168, 101, 172, 160, 23 , 108, 61 , 96 , 65 , 85 , 152, 181, 160],
  E: [0  , 0  , 0  , 0  , 140, 98 , 40 , 134, 56 , 138, 126, 42 , 93 , 29 , 54 , 80 , 65 , 121, 152, 122],
  F: [0  , 0  , 0  , 0  , 0  , 153, 100, 21 , 102, 22 , 28 , 158, 114, 116, 97 , 155, 103, 50 , 40 , 22 ],
  G: [0  , 0  , 0  , 0  , 0  , 0  , 98 , 135, 127, 138, 127, 80 , 42 , 87,  125, 56 , 59 , 109, 184, 147],
  H: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 94 , 32 , 99 , 87 , 68 , 77 , 24 , 29 , 89 , 47 , 84 , 115, 83 ],
  I: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 102, 5  , 10 , 149, 95 , 109, 97 , 142, 89 , 29 , 61 , 33 ],
  K: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 107, 95 , 94 , 103, 53 , 26 , 121, 78 , 97 , 110, 85 ],
  L: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 15 , 153, 98 , 113, 102, 145, 92 , 32 , 61 , 36 ],
  M: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 142, 87 , 101, 91 , 135, 81 , 21 , 67 , 36 ],
  N: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 91 , 46 , 86 , 46 , 65 , 133, 174, 143],
  P: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 76 , 103, 74 , 38,  68 , 147, 110],
  Q: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 43 , 68 , 42 , 96 , 130, 99 ],
  R: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 110, 71 , 96 , 101, 77 ],
  S: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 58 , 124, 177, 144],
  T: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 69 , 128, 92 ],
  V: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 88 , 55 ],
  W: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 37 ],
  Y: [0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  , 0  ],
  /* eslint-enable prettier/prettier */
};

/*
 * Lookup matrix converted into 2D hashmap of scores, i.e.:

    {
      A: {
        A: 0,
        C: 195,
        D: 126,
        ...
      },
      C: {
        C: 0,
        D: 126,
        ...
      }
    }
 */
const granthamHashMap: Record<
  AminoAcid,
  Record<AminoAcid, number>
> = Object.entries(granthamMatrix).reduce((accum, [aminoAcid, scores]) => {
  const mappedScores = scores.reduce((scoreAccum, currentScore, index) => {
    const otherAminoAcid = granthamMatrixOrder[index];
    if (aminoAcid <= otherAminoAcid) scoreAccum[otherAminoAcid] = currentScore;
    return scoreAccum;
  }, {});

  accum[aminoAcid] = mappedScores;

  return accum;
}, {});

export const granthamScore = (aminoAcids: AminoAcid[]): number | null => {
  const [aminoAcid1, aminoAcid2] = aminoAcids
    .map(amino => amino.toUpperCase())
    .sort();

  if (!aminoAcid1 || !aminoAcid2) {
    return null;
  }

  const score = granthamHashMap?.[aminoAcid1]?.[aminoAcid2];
  return isNil(score) ? null : score;
};

export const granthamClassification = (
  score: number | null
): GranthamClassification | null => {
  if (!score && score !== 0) {
    return null;
  }

  if (score <= 50) {
    return "conservative";
  } else if (score > 50 && score <= 100) {
    return "moderately conservative";
  } else if (score > 100 && score <= 150) {
    return "moderately radical";
  } else {
    return "radical";
  }
};

export const granthamSubstitution = (
  aminoAcids: AminoAcid[] = []
): { score: number | null; classification: GranthamClassification | null } => {
  const score = granthamScore(aminoAcids);
  const classification = granthamClassification(score);

  return {
    score,
    classification,
  };
};
