import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  always,
  symmetricDifference,
  identity,
  isNil,
  memoizeWith,
  range,
} from "ramda";
import React, { isValidElement, PureComponent } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import SortableWell from "./SortableWell";
import { groupShape, wellShape } from "./utils";

const sortableWellsPropTypes = {
  /**
   * function that is called upon wells list changes. Updated wells data is passed as a param
   */
  onChange: PropTypes.func.isRequired,
  /**
   * array of elements
   */
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([groupShape, wellShape, PropTypes.object])
  ),
  /**
   * specifies the axis (horizontal or vertical) the wells are aligned along
   */
  direction: PropTypes.oneOf(["row", "column"]),
  /**
   * a custom class name that applies css rules to the top level element
   */
  className: PropTypes.string,
  /**
   * a custom class name that applies css rules to draggable items
   */
  itemClassName: PropTypes.string,
};

const sortableWellsDefaultPropTypes = {
  data: [],
  direction: "row",
  onChange: always(),
  className: "",
  itemClassName: "",
};

export default class SortableWells extends PureComponent {
  static displayName = "Wells";

  static propTypes = sortableWellsPropTypes;

  static defaultProps = sortableWellsDefaultPropTypes;

  static makeKey() {
    return range(0, 5).reduce(
      ({ possible, current }) => ({
        possible,
        current: `${current}${possible.charAt(
          Math.floor(Math.random() * possible.length)
        )}`,
      }),
      {
        possible:
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
        current: "",
      }
    );
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      data: [],
      initialData: [],
    };
  }

  static getDerivedStateFromProps(
    { data = [] },
    { data: currentData = [], initialData = [] }
  ) {
    const newData = data.reduce((memo, elem) => {
      if (!isValidElement(elem)) {
        if (elem.hasOwnProperty("wellId")) {
          memo.push(elem);
        } else {
          elem.items.forEach(subElem => memo.push(subElem));
        }
      }
      return memo;
    }, []);

    // update state if the number of wells or items values have changed
    if (newData.length !== currentData.length) {
      return {
        data: newData,
        initialData: newData,
      };
    }

    const newInitialItems = newData.map(({ items }) => items);
    const currentInitialItems = initialData.map(({ items }) => items);
    let initialDataDifferent = false;

    if (newInitialItems.length === currentInitialItems.length) {
      initialDataDifferent = newInitialItems
        .map(
          (items, index) =>
            symmetricDifference(currentInitialItems[index], items).length
        )
        .reduce((acc, curr) => acc + curr, 0);
    }

    if (initialDataDifferent) {
      return {
        data: newData,
        initialData: newData,
      };
    }

    return null;
  }

  getWellIds = memoizeWith(identity, (data = []) =>
    data.map(({ wellId }) => wellId)
  );

  getGroupKey = memoizeWith(
    identity,
    items =>
      `wells-group ${items.reduce(
        (memo, { wellId }) => `${memo}-${wellId}`,
        []
      )}`
  );

  findWellById(data, targetWellId) {
    const wellsNumber = data.length;
    for (let wellIndex = 0; wellIndex < wellsNumber; wellIndex++) {
      const well = data[wellIndex];
      const { wellId } = well;
      if (wellId === targetWellId) {
        return { index: wellIndex, well };
      }
    }

    return { index: -1 };
  }

  replaceAtIndex(data, index, updatedItem) {
    return [...data.slice(0, index), updatedItem, ...data.slice(index + 1)];
  }

  insertAtIndex(array, index, newItem) {
    return [...array.slice(0, index), newItem, ...array.slice(index)];
  }

  /**
   * helper function used in moveToWell
   */
  insertToWell(dataCopy, transferItem, targetWellId, targetItemIndex) {
    const { index: targetWellIndex, well: targetWell } = this.findWellById(
      dataCopy,
      targetWellId
    );
    const { items: targetWellItems } = targetWell;

    // add the transferred item to the target well
    if (isNil(targetItemIndex)) {
      // if the target well is specified, the item is not already there,
      // but the insertion index is not specified - something is wrong
      throw new Error(
        `targetItemIndex is not specified for well with id=${targetWellId}`
      );
    }
    const updatedTargetWell = {
      ...targetWell,
      items: this.insertAtIndex(targetWellItems, targetItemIndex, transferItem),
    };
    // update the data copy with a new target well
    dataCopy[targetWellIndex] = updatedTargetWell;
  }

  /**
   * Function responsible for moving an item between wells.
   * Items will be added to a target well if needed and removed from a previous well if needed
   * The initial drag source item will be kept before the drag end by checking endDrag param
   * and comparing initial well id with target and previous visited well ids
   * @param draggedItemData - the dragged source item information: data: { id }, initialWellId, wellId
   * @param targetWellId - the well the item visited. Not specified if item should not be added anywhere
   * @param targetItemIndex - the index in the target well where the item should be inserted
   * @param endDrag - if true, remove the original drag source item if possible.
   * For correct dnd behaviour 'endDrag' must be set to true only on dragend event
   */
  moveToWell(draggedItemData, targetWellId, targetItemIndex, endDrag = false) {
    const {
      data: { id: itemId },
      initialWellId,
      wellId: previousHoverWellId,
    } = draggedItemData;

    const { onChange } = this.props;

    this.setState(({ data = [] }) => {
      const { index, well: initialWell } = this.findWellById(
        data,
        initialWellId
      );
      const { items: initialWellItems } = initialWell || {};
      if (index === -1) {
        // if dragged to a well from a different well list, do nothing
        return data;
      }

      // Remove item preview from a previous visited well.
      // If endDrag is true and target well is not the initial source well, remove the original item
      const updatedData = this.removeItem(
        data,
        draggedItemData,
        targetWellId !== initialWellId && endDrag
      );

      const itemMovedBetweenWells =
        !isNil(targetWellId) && previousHoverWellId !== targetWellId;

      // if the item was dropped on another well add it to the that target well
      // if target well is the initial well - the item is already there
      const itemMovedToNonInitialWell =
        itemMovedBetweenWells && targetWellId !== initialWellId;
      if (itemMovedToNonInitialWell) {
        const transferItem = initialWellItems.find(({ id }) => id === itemId);

        this.insertToWell(
          updatedData,
          transferItem,
          targetWellId,
          targetItemIndex
        );

        // mutate DnD monitor for performance reasons as suggested by the documentation
        draggedItemData.index = targetItemIndex;
        draggedItemData.wellId = targetWellId;
      }

      const itemMovedToInitialWell =
        itemMovedBetweenWells && targetWellId === initialWellId;
      //just restore the item in the initial well, its in the DOM, move it to a new position if needed
      if (itemMovedToInitialWell) {
        const restoredIndex = initialWellItems.findIndex(
          ({ id }) => id === itemId
        );
        if (restoredIndex === -1) {
          throw new Error(
            `cannot find drag source item in well with id=${initialWellId}`
          );
        }
        draggedItemData.index = restoredIndex;
        draggedItemData.wellId = initialWellId;

        // if mouse hovering over a different position, move the item
        if (restoredIndex !== targetItemIndex) {
          this.changeItemPosition(
            updatedData,
            targetItemIndex,
            targetWellId,
            draggedItemData
          );
        }
      }

      // notify the parent component
      if (itemMovedToNonInitialWell && !endDrag) {
        // clear the initial item, parent component shouldn't know about the internals
        // dictated by html DnD implementation
        onChange(
          this.removeFromWellByItemId(updatedData, initialWell, index, itemId)
        );
      } else {
        onChange(updatedData);
      }

      // update the component state
      return {
        data: updatedData,
      };
    });
  }

  /**
   * removes the item from the given well and returns the wells data copy with the updated well
   * @param data - the list of wells
   * @param sourceWell - the well to remove the item from
   * @param sourceWellIndex - the sourceWell index to eliminate additinal well search by id
   * @param itemId - the id of the item to delete
   */
  removeFromWellByItemId(data, sourceWell, sourceWellIndex, itemId) {
    const { items: sourceWellItems } = sourceWell;
    const updatedItems = sourceWellItems.filter(({ id }) => id !== itemId);

    // remove the item from the source well
    const updatedSourceWell = { ...sourceWell, items: updatedItems };
    const updatedData = this.replaceAtIndex(
      data,
      sourceWellIndex,
      updatedSourceWell
    );

    return updatedData;
  }

  /**
   * removes the item preview from a visited well, removes the original drag source item if needed
   * @param data - the list of wells
   * @param draggedItemData - the information about dragged item
   * @param removeFromSource - if true remove the original dragged item from its source well
   */
  removeItem(data, draggedItemData, removeFromSource = false) {
    const {
      data: { id: itemId },
      wellId: previousHoverWellId,
      initialWellId,
    } = draggedItemData;
    // Find the well the item has visited before if it is not the initial well.
    // If the item has visited only its initial well before the current one, it should not be deleted,
    // because we need to keep the original item for DnD to work properly
    const itemVisitedOtherWellBefore = previousHoverWellId !== initialWellId;
    const { index: previousHoverWellIndex, well: previousHoverWell } =
      itemVisitedOtherWellBefore
        ? this.findWellById(data, previousHoverWellId)
        : {};

    // remove original item
    if (removeFromSource) {
      // remove drag item old preview only if the item has moved from a non-initial well
      if (itemVisitedOtherWellBefore) {
        const { index: initialWellIndex, well: initialWell } =
          this.findWellById(data, initialWellId);
        return this.removeFromWellByItemId(
          data,
          initialWell,
          initialWellIndex,
          itemId
        );
      }
      // if item is only in the original well, do not remove it
      return [...data];
    }

    // if item hasn't visited any other well after the original one, return the data copy,
    // otherwise remove the item preview from the previously visited well
    return !itemVisitedOtherWellBefore
      ? [...data]
      : this.removeFromWellByItemId(
          data,
          previousHoverWell,
          previousHoverWellIndex,
          itemId
        );
  }

  /**
   * a helper function used in moveItem and moveToWell
   */
  changeItemPosition(dataCopy, hoverIndex, currentWellId, draggedItemData) {
    const {
      index: currentIndex,
      data: { id: itemId },
    } = draggedItemData;
    const { index: currentWellIndex, well: currentWell } = this.findWellById(
      dataCopy,
      currentWellId
    );
    const { items } = currentWell;
    const dragItem = items[currentIndex];

    // remove the dragged item
    const newData = items.filter(({ id }) => id !== itemId);
    // insert it into hoverIndex
    newData.splice(hoverIndex, 0, dragItem);
    const updatedWell = { ...currentWell, items: newData };

    dataCopy[currentWellIndex] = updatedWell;

    // dnd-monitor item mutation for performance reasons
    draggedItemData.index = hoverIndex;
  }

  /**
   * function that handles moving item to a new position in the same well
   * @param hoverIndex - a target index where the item will be placed at
   * @param currentWellId - the well the item is in
   * @param draggedItemData - the information about dragged item
   */
  moveItem(hoverIndex, currentWellId, draggedItemData) {
    if (isNil(currentWellId)) {
      return;
    }

    const { onChange } = this.props;

    this.setState(({ data = [] }) => {
      // remove item old preview if the item has moved from a different well
      const updatedData = [...data];
      this.changeItemPosition(
        updatedData,
        hoverIndex,
        currentWellId,
        draggedItemData
      );

      // notify the parent component
      onChange(updatedData);

      // update the component state
      return {
        data: updatedData,
      };
    });
  }

  /**
   * called on drag end. removes item preview from the previous visited well, removes the original drag item if needed
   * @param sourceItem - the information about the dragged item
   * @param removeFromSource - if true remove the original dragged item from its source well
   */
  endDrag(sourceItem) {
    this.moveToWell(sourceItem, null, -1, true);
  }

  renderElement(allowedSourceWellIds, node) {
    const { data = [] } = this.state;

    if (isValidElement(node)) return node;

    if (node.hasOwnProperty("wellId")) {
      return this.renderWell(
        allowedSourceWellIds,
        data.find(elem => elem.wellId === node.wellId)
      );
    }
    return this.renderGroup(allowedSourceWellIds, node);
  }

  renderWell(
    allowedSourceWellIds,
    { wellId, title, columns = 3, items, className, itemClassName }
  ) {
    return (
      <div className={classNames(className, `col-md-${columns}`)} key={wellId}>
        {!isNil(title) && <div className="sortable-well-header">{title}</div>}
        <SortableWell
          data={items}
          id={wellId}
          moveItem={this.moveItem}
          moveToWell={this.moveToWell}
          endDrag={this.endDrag}
          allowedSourceWellIds={allowedSourceWellIds}
          itemClassName={itemClassName}
        />
      </div>
    );
  }

  renderGroup(allowedSourceWellIds, { title, items, columns, className }) {
    const { data = [] } = this.state;
    return (
      <div className={`col-md-${columns}`} key={this.getGroupKey(items)}>
        <div className="row">
          {!isNil(title) && (
            <div className="col-md-12 sortable-well-group-header">{title}</div>
          )}
          <div className={classNames("col-md-12", className)}>
            <div className="row">
              {items.map(item =>
                this.renderWell(
                  allowedSourceWellIds,
                  data.find(elem => elem.wellId === item.wellId)
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data: structure = [], direction, className } = this.props;
    const { data = [] } = this.state;
    // list of allowed wells to accept data from
    const allowedSourceWellIds = this.getWellIds(data);

    if (!data.length) {
      return null;
    }

    const isVertical = direction === "column";

    return (
      <DndProvider backend={HTML5Backend}>
        <div className={classNames(className, "wells-list")}>
          {structure.map(node => {
            if (isVertical) {
              return (
                <div key={SortableWells.makeKey()}>
                  <div className="col-md-12">
                    {this.renderElement(allowedSourceWellIds, node)}
                  </div>
                </div>
              );
            } else {
              return this.renderElement(allowedSourceWellIds, node);
            }
          })}
        </div>
      </DndProvider>
    );
  }
}
