import PropTypes from "prop-types";
import React from "react";

import { Rating } from "pattern-library";

import { ARIADNE_KEYS } from "modules/ariadne/constants";

const PredictionConfidenceColumn = props => {
  const { transcript, className } = props;

  const score = transcript[ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING] || null;

  return (
    <div className={className}>
      {score !== null && <Rating score={score} showEmpty maximum={5} />}
    </div>
  );
};

PredictionConfidenceColumn.propTypes = {
  className: PropTypes.string,
  transcript: PropTypes.shape({
    [ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING]: PropTypes.number,
  }),
};

export default PredictionConfidenceColumn;
