import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

import Input from "./Input";

export default class Radio extends PureComponent {
  static displayName = "Radio";

  static propTypes = {
    /**
     * Class to apply to the radio input
     */
    className: PropTypes.string,
    /**
     * Class to apply to the container div
     */
    containerClass: PropTypes.string,
    /**
     * Name for the input
     */
    name: PropTypes.string.isRequired,
    /**
     * true, if the radio button is selected
     */
    checked: PropTypes.bool.isRequired,
    /**
     * Text or react element for the label
     */
    label: PropTypes.node,
  };

  static defaultProps = {
    className: "",
    containerClass: "",
    label: "",
  };

  render() {
    const {
      label,
      containerClass,
      inputWrapper: InputWrapper = null,
      wrapperArgs = {},
      ...otherProps
    } = this.props;

    const input = <Input type="radio" {...otherProps} />;

    return (
      <div
        className={classNames("radio", containerClass, {
          "font-weight-bold": otherProps.checked,
        })}
      >
        <label>
          {isNil(InputWrapper) ? (
            input
          ) : (
            <InputWrapper {...wrapperArgs}>{input}</InputWrapper>
          )}{" "}
          {label}
        </label>
      </div>
    );
  }
}
