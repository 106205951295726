import React, { PureComponent } from "react";

import QCStatus from "./QCStatus";

export default class QCStatusColumn extends PureComponent {
  render() {
    const {
      variant: { filter = [] },
      header,
    } = this.props;

    const clazz = header.toLowerCase().replace(/ /g, "_");
    return (
      <div className={`variant-column variant-column--${clazz} ${clazz}`}>
        <QCStatus filter={filter} className="variant-column-label" />
      </div>
    );
  }
}
