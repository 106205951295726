import React from "react";

import { VariantPathogenicity, Rating } from "pattern-library";

import { ARIADNE_KEYS, ARIADNE_VALUES } from "modules/ariadne/constants";

import {
  pathogenicitySortFunction,
  phenotypeContributionSortFunction,
} from "../utils";

import styles from "./AriadneFilterSidebar.module.scss";

const getContributionsString = contributions =>
  contributions
    .sort(phenotypeContributionSortFunction)
    .map(
      contribution =>
        ARIADNE_VALUES.C_2_P[contribution.toUpperCase()] || contribution
    )
    .join(", ");

export const AriadneFilterSidebar = ({ predictions }) => {
  if (!predictions) return null;

  const hasPathogenicityValue =
    predictions[ARIADNE_KEYS.PREDICTED_PATHOGENICITY] &&
    Array.isArray(predictions[ARIADNE_KEYS.PREDICTED_PATHOGENICITY]) &&
    predictions[ARIADNE_KEYS.PREDICTED_PATHOGENICITY].length;

  const hasConfidenceRating =
    predictions[ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING] &&
    predictions[ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING] !== "any";

  const hasContribution =
    predictions[ARIADNE_KEYS.PREDICTED_C2P] &&
    Array.isArray(predictions[ARIADNE_KEYS.PREDICTED_C2P]) &&
    predictions[ARIADNE_KEYS.PREDICTED_C2P].length;

  const hasContributionExtended =
    predictions[ARIADNE_KEYS.PREDICTED_C2P_EXTENDED] &&
    Array.isArray(predictions[ARIADNE_KEYS.PREDICTED_C2P_EXTENDED]) &&
    predictions[ARIADNE_KEYS.PREDICTED_C2P_EXTENDED].length;

  return (
    <div className={styles.container}>
      {hasPathogenicityValue ? (
        <div className={styles.group}>
          <span className={styles.label}>Predicted pathogenicity:</span>
          <div className={styles.value}>
            {predictions[ARIADNE_KEYS.PREDICTED_PATHOGENICITY]
              .sort(pathogenicitySortFunction)
              .map(item => (
                <VariantPathogenicity
                  key={item}
                  className={styles.item}
                  level={item}
                />
              ))}
          </div>
        </div>
      ) : null}
      {hasConfidenceRating ? (
        <div className={styles.group}>
          <div className={styles.label}>Confidence rating:</div>
          <div className={styles.value}>
            <Rating
              showScore={false}
              score={predictions[ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING]}
              starPrecision={0}
              className={styles.score}
            />
            and up
          </div>
        </div>
      ) : null}
      {hasContribution ? (
        <div className={styles.group}>
          <div className={styles.label}>
            Contribution to primary phenotypes:
          </div>
          <div className={styles.value}>
            {getContributionsString(predictions[ARIADNE_KEYS.PREDICTED_C2P])}
          </div>
        </div>
      ) : null}
      {hasContributionExtended ? (
        <div className={styles.group}>
          <div className={styles.label}>
            Contribution to extended primary phenotypes:
          </div>
          <div className={styles.value}>
            {getContributionsString(
              predictions[ARIADNE_KEYS.PREDICTED_C2P_EXTENDED]
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
