import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo } from "react";

import {
  ACCEPTED_FILE_TYPES_MESSAGE,
  VALID_FILE_COMBINATIONS_MESSAGE,
} from "./componentConstants";

const FileChecklist = memo(({ samples = [], isModal = false }) => {
  const expectedFiles = [];
  samples.forEach(({ fileTypes, name: sampleName }) => {
    const { vcf, bam, fastq } = fileTypes || {};
    const resultingFileTypes = [];
    if (vcf) {
      resultingFileTypes.push("VCF");
    }

    if (bam) {
      resultingFileTypes.push("BAM");
    }

    if (fastq) {
      resultingFileTypes.push("FASTQ R1");
      resultingFileTypes.push("FASTQ R2");
    }

    resultingFileTypes.forEach(fileType => {
      expectedFiles.push(
        `${fileType} file for ${sampleName || "unknown sample id"}`
      );
    });
  });

  const listClasses = classNames("file-checklist-container", {
    "bg-warning": isModal,
  });

  return (
    <div className={listClasses}>
      <div className="file-checklist">
        {!isModal && (
          <div className="file-checklist-header">
            <h5>Expected SFTP file checklist</h5>
          </div>
        )}
        {!expectedFiles.length && (
          <div className="no-files-message">
            <span className="message">No files yet, add a patient first</span>
          </div>
        )}
        {expectedFiles.length > 0 &&
          expectedFiles.map((fileName, ind) => {
            const key = `file-entry-${ind}`;
            return (
              <div key={key} className="file-entry">
                <i className="fa fa-file-archive-o" aria-hidden="true" />
                <span className="file-entry-name">{fileName}</span>
              </div>
            );
          })}
      </div>
      {!isModal && (
        <div className="footer-note text-danger">
          <span>
            <i className="fa fa-info-circle" aria-hidden="true" />
          </span>
          <span>
            Please upload the files specified above to the SFTP server after the
            IR submission
          </span>
          <span>
            <i className="fa fa-info-circle" aria-hidden="true" />
          </span>
          <span>{ACCEPTED_FILE_TYPES_MESSAGE}</span>
          <span>
            <i className="fa fa-info-circle" aria-hidden="true" />
          </span>
          <span>{VALID_FILE_COMBINATIONS_MESSAGE}</span>
        </div>
      )}
    </div>
  );
});

FileChecklist.propTypes = {
  /**
   * isModal is true if the component is part of the modal success message
   */
  isModal: PropTypes.bool,
  /**
   * the list of samples in an interpretation request
   */
  samples: PropTypes.arrayOf(
    PropTypes.shape({
      vcf: PropTypes.bool,
      bam: PropTypes.bool,
      fastq: PropTypes.bool,
      name: PropTypes.string,
    })
  ),
};

export default FileChecklist;
