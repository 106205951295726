import { react as autoBind } from "auto-bind";
import PapaParse from "papaparse";

import { findDuplicates, notEmpty } from "modules/utils/array";
import {
  csvDataToObjectsArray,
  camelizeIgnoreUpperCase,
} from "modules/utils/common";

import { BaseReader, CSVFileResult } from "./types";

export default class CSVReader implements BaseReader {
  file: File;
  result: CSVFileResult;

  constructor(file: File) {
    this.file = file;
    autoBind(this);
  }

  async getResult(): Promise<CSVFileResult> {
    return new Promise<CSVFileResult>((resolve, reject) => {
      if (this.result) {
        resolve({
          ...this.result,
        });
      } else {
        const fileName = this.file.name;
        PapaParse.parse(this.file, {
          complete(result): void {
            const data = result?.data as Array<Array<string>>;
            const [csvRawHeaders = []] = data;
            const duplicatedColumns = findDuplicates(csvRawHeaders);
            if (notEmpty(duplicatedColumns)) {
              reject(
                new Error(
                  `There are duplicated columns: ${duplicatedColumns.join(
                    ", "
                  )}`
                )
              );
            }
            resolve({
              fileName,
              data: csvDataToObjectsArray(data, true),
              headers: csvRawHeaders.map(header =>
                camelizeIgnoreUpperCase(header)
              ),
            });
          },
          error(err) {
            reject(err);
          },
          skipEmptyLines: true,
        });
      }
    });
  }
}
