import { fk, attr } from "redux-orm";

import BaseModel from "./BaseModel";

class Decision extends BaseModel {
  static modelName = "Decision";

  static fields = {
    id: attr(),
    gene: fk("Gene", "decisions"),
    // TODO: This is wrong, this needs to hook up to the patient variant id not the patient
    // patient: fk('Patient', 'decisions'),
  };

  static options = {
    idAttribute: "patientVariantDecisionId",
  };

  static getMethodUrlMap() {
    return {
      CREATE: `${this.basePath}/${this.modelName.toLowerCase()}`,
      READ: `${this.basePath}/patient/:id/gene-decision-variant`,
      UPDATE: `${this.basePath}/${this.modelName.toLowerCase()}/:id`,
      DELETE: `${this.basePath}/${this.modelName.toLowerCase()}/:id`,
      LIST: `${this.basePath}/${this.modelName.toLowerCase()}`,
    };
  }

  static parse(decision) {
    return this.upsert({
      ...decision,
      gene: decision.geneId,
    });
  }

  toString() {
    return this.name;
  }
}

export default Decision;
