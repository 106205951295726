import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { refreshIRStatus, resetDataFile } from "../actions";
import {
  DATA_FILES_STATUS_INTERVAL,
  FILE_STATUSES_STYLE,
  STATUSES,
} from "../constants";
import { getDataFilesByIrId } from "../selectors";
import { transferErrorExists } from "../utils";

import DataFileMessages from "./DataFileMessages";
import { getDataFilesColumns } from "./columns";

const DataFiles = memo(
  ({ irId, refreshIRStatus, resetDataFile, dataFiles }) => {
    const [items, setItems] = useState([]);

    const onResetDataFile = useCallback(
      id => {
        resetDataFile(id);
      },
      [resetDataFile]
    );

    useEffect(() => {
      if (dataFiles) {
        setItems(Object.values(dataFiles).map(dataFile => dataFile));
      } else {
        setItems([]);
      }
    }, [dataFiles]);

    // Create a function that will render our row sub components
    const renderRowSubComponent = useCallback(
      ({ row: { original: dataFile } }) => (
        <DataFileMessages dataFile={dataFile} />
      ),
      []
    );

    const needRefresh = items =>
      items.findIndex(
        dataFile =>
          !transferErrorExists(dataFile) &&
          (dataFile.status !== STATUSES.PROMISED ||
            dataFile.status !== STATUSES.PROMISED_NAMELESS)
      ) !== -1;

    useEffect(() => {
      const interval = needRefresh(items)
        ? setInterval(() => {
            refreshIRStatus(irId);
          }, DATA_FILES_STATUS_INTERVAL)
        : null;
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [irId, refreshIRStatus, items]);

    const getRowProps = (
      _,
      {
        row,
        row: {
          original: dataFile,
          original: { id, status, validationMessages },
        },
      }
    ) => ({
      key: id,
      className: classNames(FILE_STATUSES_STYLE[status.toUpperCase()], {
        danger: transferErrorExists(dataFile),
        required_refresh:
          status === STATUSES.PROMISED || status === STATUSES.PROMISED_NAMELESS,
        clickable: validationMessages || transferErrorExists(dataFile),
      }),
      onClick: () => {
        if (validationMessages || transferErrorExists(dataFile)) {
          row.toggleRowExpanded();
        }
      },
    });

    return items.length > 0 ? (
      <Table
        title="Files:"
        columns={getDataFilesColumns(onResetDataFile)}
        data={items}
        showPagination={dataFiles.length > DEFAULT_PAGE_SIZE}
        autoResetPage={false}
        getRowProps={getRowProps}
        renderRowSubComponent={renderRowSubComponent}
      />
    ) : null;
  }
);

DataFiles.propTypes = {
  dataFiles: PropTypes.object,
};

const mapDispatchToProps = {
  refreshIRStatus: refreshIRStatus.start,
  resetDataFile: resetDataFile.start,
};

const mapStateToProps = (state, props) => {
  const { irId } = props;
  return {
    dataFiles: getDataFilesByIrId(state, irId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataFiles);
