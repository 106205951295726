import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Link from "../../elements/base/Link";

export default class LinkCrumb extends PureComponent {
  static displayName = "LinkCrumb";

  static propTypes = {
    /**
     * Text inside component
     */
    name: PropTypes.string.isRequired,
    /**
     * The location the crumb points to
     */
    url: PropTypes.string.isRequired,
  };

  render() {
    const { name, url } = this.props;
    return (
      <div key={name} className="breadcrumbs-crumb">
        <Link key={name} href={url}>
          {name}
        </Link>
      </div>
    );
  }
}
