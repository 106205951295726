import convertStringArrayToFormField from "../convertStringArrayToFormField";

const ethnicities = [
  {
    group: "White",
    options: ["British", "Irish", "Any other White background"],
  },
  {
    group: "Mixed",
    options: [
      "White and Black Carribean",
      "White and Black African",
      "White and Asian",
      "Any other mixed background",
    ],
  },
  {
    group: "Asian or Asian British",
    options: [
      "Indian",
      "Pakistani",
      "Bangladeshi",
      "Any other Asian background",
    ],
  },
  {
    group: "Black or Black British",
    options: ["Carribean", "African", "Any other Black background"],
  },
  {
    group: "Other Ethnic Groups",
    options: ["Chinese", "Any other ethic background"],
  },
];

export const ethnicityFormFields = ethnicities.map(ethnicity => ({
  ...ethnicity,
  options: convertStringArrayToFormField(ethnicity.options),
}));

export default ethnicities;
