import { fetchCamelizeData } from "../utils";

export const buildVariantCountURL = (filters, patientId, geneId) => {
  if (!filters && !geneId) {
    throw new Error("either filters or a gene id must be present");
  }

  if (!patientId) {
    throw new Error("patient id is not set");
  }
  const params = ["page=1"];
  if (filters) {
    params.push(`filters=${filters}`);
  }

  if (geneId) {
    params.push(`gene_id=${geneId}`);
  }
  const queryParams = params.join("&");
  return `/catalyst/api/patient/${patientId}/gene-snv-count?${queryParams}`;
};

export function getGeneVariantCount(filters, patientId, geneId) {
  const url = buildVariantCountURL(filters, patientId, geneId);
  return fetch(url);
}

export const fetchVariantAnnotationData = async (
  patientId,
  snvId,
  transcriptId,
  feature
) =>
  fetchCamelizeData(
    `/catalyst/api/patient/${patientId}/variants/snv/${snvId}/transcript/${transcriptId}/annotations?feature=${feature}`
  );
