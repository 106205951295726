import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Icon from "../../elements/base/Icon";

import ArticleProperty from "./ArticleProperty";

export default class MetaInfo extends PureComponent {
  static displayName = "MetaInfo";

  static propTypes = {
    /**
     * view mode
     */
    mode: PropTypes.oneOf(["normal", "reference"]),

    /**
     * Article category
     */
    category: PropTypes.string,

    /**
     * Determines whether the bookmark is set or not
     */
    bookmarked: PropTypes.bool,

    /**
     * Bookmark info
     */
    bookmarkInfo: PropTypes.shape({
      /**
       * Date of a bookmark setting
       */
      date: PropTypes.string.isRequired,
      /**
       * Time of a bookmark setting
       */
      time: PropTypes.string.isRequired,
      /**
       * Author of a bookmark setting
       */
      author: PropTypes.string.isRequired,
    }),

    /**
     * link click handler
     */
    onLinkClick: PropTypes.func,

    /**
     * bookmark flag click handler that should modify section bookmarked/not bookmarked state
     */
    onBookmarkClick: PropTypes.func,

    /**
     * metadata (key value pairs)
     * possible keys:
     *  - created
     *  - updated
     *  - xml
     *  - url
     *  - date_publication
     *  - date_publication_year
     *  - pubmed_publication_id
     *  - pmid
     */
    metadata: PropTypes.shape({
      pmid: PropTypes.number,
      date_publication_year: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    bookmarked: false,
    mode: "normal",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onBookmarkClick(e) {
    e.preventDefault();
    const {
      onBookmarkClick,
      metadata: { pmid },
    } = this.props;
    if (onBookmarkClick) {
      onBookmarkClick(pmid);
    }
  }

  render() {
    const {
      mode,
      bookmarked,
      bookmarkInfo: { date, time, author },
      onLinkClick,
      category,
    } = this.props;

    return (
      <div className={`article-meta-info article-${mode}-meta-info`}>
        <div
          className={classNames(
            "article-meta-info-row",
            {
              "article-reference-meta-info-row": mode === "reference",
            },
            {
              "article-normal-meta-info-row-action": mode === "normal",
            }
          )}
        >
          <Icon
            type="bookmark"
            className={classNames("article-flag", "article-normal-flag", {
              "article-flag-bookmarked": bookmarked === true,
            })}
            onClick={this.onBookmarkClick}
          />

          {mode === "reference" && (
            <Icon
              type="link"
              className="article-reference-meta-info-link"
              onClick={onLinkClick}
            />
          )}

          {mode === "normal" && (
            <div
              className={classNames(
                "article-normal-meta-info-row-action-label",
                {
                  "article-normal-meta-info-row-action-label-bookmarked":
                    bookmarked === true,
                }
              )}
            >
              {bookmarked === true ? "Remove Flag" : "Flag Article"}
            </div>
          )}
        </div>
        {mode === "normal" && (
          <>
            <div className="article-meta-info-row article-normal-meta-info-row-fixed-height ">
              {bookmarked === true && (
                <ArticleProperty
                  label="Flagged"
                  value={
                    <span>
                      <b>{date}</b>, {time} by <b>{author}</b>
                    </span>
                  }
                />
              )}
            </div>
            <div className="article-meta-info-row">
              <span className="glyphicon glyphicon-tag" />
              <ArticleProperty
                className="article-property-category"
                label="Category"
                value={category}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}
