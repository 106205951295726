import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { getFormValues } from "modules/forms/selectors";

import ReduxFormField from "../../../forms/components/ReduxFormField";
import { CheckboxGroupValueProp } from "../../../utils/prop-types/CheckboxGroupValueProp";
import { CheckboxOptionArrayProp } from "../../../utils/prop-types/CheckboxOptionProp";
import {
  INHERITANCE_FIELD,
  MOI_FIELD,
  DE_NOVO_STATUS_FIELD,
  FAMILY_FIELD,
} from "../../constants";
import * as selectors from "../../selectors";

export class InheritanceForm extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.string,
    selectedInheritance: CheckboxGroupValueProp,
    selectedDenovoStatus: CheckboxGroupValueProp,
    [INHERITANCE_FIELD]: CheckboxOptionArrayProp,
    [DE_NOVO_STATUS_FIELD]: CheckboxOptionArrayProp,
    [FAMILY_FIELD]: CheckboxOptionArrayProp,
    [MOI_FIELD]: CheckboxOptionArrayProp,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  componentDidUpdate() {
    const { selectedDenovoStatus = [], change } = this.props;
    const hasDenovo = this.hasDenovoInheritance();
    const hasDenovoStatus = selectedDenovoStatus && selectedDenovoStatus.length;
    if (!hasDenovo && hasDenovoStatus) {
      change(DE_NOVO_STATUS_FIELD, []);
    }
  }

  hasDenovoInheritance() {
    const { selectedInheritance = [] } = this.props;
    if (!selectedInheritance) {
      // getFormValues currently returns false if there is no data yet
      return false;
    }
    return !!selectedInheritance.find(
      key =>
        key === "de-novo" || key === "de-novo-fp" || key === "putative de-novo"
    );
  }

  render() {
    const {
      disabled = false,
      [INHERITANCE_FIELD]: inheritanceField,
      [MOI_FIELD]: moiField,
      [FAMILY_FIELD]: familyField,
      [DE_NOVO_STATUS_FIELD]: deNovoStatusField,
    } = this.props;
    return (
      <fieldset disabled={disabled}>
        <Field
          component={ReduxFormField}
          type="checkboxgroup"
          name={INHERITANCE_FIELD}
          narrow={9}
          label="Inheritance"
          options={inheritanceField}
        />
        <Field
          component={ReduxFormField}
          type="checkboxgroup"
          name={MOI_FIELD}
          narrow={9}
          label="Consistency with mode of inheritance"
          options={moiField}
        />
        <div className="form-group">
          <h4>Note</h4>
          <p>
            <em>
              Compound heterozygous variants are called as such if two or more
              variants are present in the same gene, and have a MAF of &lt;
              0.02. Maternal/Paternal, or parental/de novo inheritance also must
              be observed, and they must have a predicted VEP consequence more
              severe than a non-coding variant.
            </em>
          </p>
        </div>
        <Field
          component={ReduxFormField}
          type="checkboxgroup"
          label="Family Comparisons"
          name={FAMILY_FIELD}
          narrow={9}
          options={familyField}
        />
        <Field
          component={ReduxFormField}
          type="checkboxgroup"
          label="De novo Status"
          name={DE_NOVO_STATUS_FIELD}
          narrow={9}
          disabled={!this.hasDenovoInheritance()}
          options={deNovoStatusField}
        />
        <div className="form-group">
          <h4>Note</h4>
          <p>
            <em>
              De novo gear determines the likelihood that a mutation is truly de
              novo or if there is evidence of the allele in either parent. Each
              de novo is assigned a confidence level or other tag to indicate
              analysis status.
            </em>
          </p>
        </div>
      </fieldset>
    );
  }
}

const mapStateToProps = (state, props) => ({
  selectedInheritance: getFormValues(state, props.form, INHERITANCE_FIELD),
  selectedDenovoStatus: getFormValues(state, props.form, DE_NOVO_STATUS_FIELD),
  ...selectors.getInheritanceOptions(state),
});

export default connect(mapStateToProps, null)(InheritanceForm);
