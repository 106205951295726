// @flow
import type { RequestData } from "../feedback/flow-types";
import { fetchPerlEndpointData, getFormData } from "../utils/fetchData";
import type { FetchDataResponse } from "../utils/fetchData";

export const postFeedback = (
  requestData: RequestData
): Promise<FetchDataResponse<any>> =>
  fetchPerlEndpointData(`/feedback`, {
    method: "POST",
    body: getFormData({ ...requestData, url: location.href }),
  });
