import { createSelector } from "@reduxjs/toolkit";
import { groupBy, path } from "ramda";

import { CELL_VALUE_MAP } from "modules/patients/components/columns";
import { getCurrentProjectTypeInternalName } from "modules/project/selectors";
import {
  formatDate,
  guessDate,
  supportedDateFormats,
} from "modules/utils/index";
import { filterProcessor } from "modules/utils/table";

import * as constants from "./constants";

const uiPath = (...items) => [constants.NAME, "ui", ...items];
const dataPath = (...items) => [constants.NAME, "data", ...items];

export const getPatientsFilter = path(uiPath("patientsFilter"));
export const isLoading = path(uiPath("loading"));
export const isReportsModalVisible = path(uiPath("reportsModalVisible"));

export const getPatients = path(dataPath("projectData", "patients"));
export const getMetaData = path(dataPath("projectData", "metadata"));

export const getReportsPatientId = path(dataPath("reports", "patientId"));
export const getReportData = path(dataPath("reports", "data"));
export const getReportIncluded = path(dataPath("reports", "included"));

export const getReports = createSelector(
  getReportData,
  getReportIncluded,
  (data = [], included = []) => {
    const idToIncluded = groupBy(({ id }) => id)(included);
    return data.map(
      ({
        id,
        attributes: { created, updated, variantCount, templateUuid },
      }) => {
        const {
          attributes: { templateName },
        } = idToIncluded[templateUuid][0];
        return {
          id,
          templateName,
          variantCount,
          created,
          updated,
        };
      }
    );
  }
);

export const prepareData = (patients = [], metadata = []) =>
  patients.reduce((accumulator, patient) => {
    const item = {
      ...patient,
      metadataValues: patient.metadataValues
        ? JSON.parse(patient.metadataValues)
        : [],
      phenotypeNames: patient.phenotypeNames
        ? JSON.parse(patient.phenotypeNames)
        : [],
      genePanelTitles: patient.genePanelTitles
        ? JSON.parse(patient.genePanelTitles)
        : [],
      familyRefs: patient.familyRefs ? JSON.parse(patient.familyRefs) : [],
      updated: new Date(patient.updated * 1000),
    };
    item.metadataFilter = [];
    metadata.forEach(({ id }) => {
      if (item.metadataValues && item.metadataValues[id]) {
        item.metadataFilter.push(item.metadataValues[id]);
      }
    });
    accumulator.push(item);
    return accumulator;
  }, []);

export const getData = createSelector(
  getPatients,
  getMetaData,
  (patients = [], metadata = []) => prepareData(patients, metadata)
);

const COLUMNS = {
  rare_disease: [
    "reference",
    "genePanelTitles",
    "status",
    "phenotypeNames",
    "cnvAnalysis",
    "protocolName",
    "sampleTypeName",
    "familyRefs",
    "reports",
    "updated",
  ],
  oncology: [
    "reference",
    "tumourType",
    "baitset",
    "status",
    "updated",
    "neoplasticCellularity",
  ],
};

const getCellValueMap = (properties, metadata) => {
  const cellValueMap = {
    ...CELL_VALUE_MAP,
  };
  for (const key of properties) {
    const meta = metadata.find(({ name }) => `metadata_${name.trim()}` === key);
    if (meta) {
      cellValueMap[key] = value => {
        const { metadataValues = {} } = value;
        let metadataValue = metadataValues[meta.id] || "";
        if (meta.type === "Date") {
          //we have to guess date format since different formats are supported by the system and dates are stored as strings
          //(see comments in https://jira.congenica.net/browse/SAP-13385)
          const foundDate = guessDate(metadataValue, supportedDateFormats);
          if (foundDate) {
            metadataValue = formatDate(foundDate);
          }
        }
        return metadataValue;
      };
    }
  }
  return cellValueMap;
};
export const filter = (data, filterValue, metadata, projectType) => {
  if (filterValue === "") return data;
  const properties = COLUMNS[projectType];
  for (const { name } of metadata) {
    properties.push(`metadata_${name.trim()}`);
  }
  return filterProcessor(
    properties,
    null,
    getCellValueMap(properties, metadata)
  )(data, filterValue);
};

export const getFilteredData = createSelector(
  getData,
  getPatientsFilter,
  getMetaData,
  getCurrentProjectTypeInternalName,
  filter
);
