import { Track } from "../../core/SNV/Patient";
import { getRelativeTrackBaseConfig } from "../../utils";

export const createTrackConfig = ({
  snvId,
  patientId,
  trackType,
  trackTypeId,
  transcriptId,
  genome,
} = {}) => ({
  id: "Congenica.SNV.Patient",
  namespace: "Congenica.SNV.Patient",
  name: "My Patient: SNVs",
  category: "My Patient",
  info: "The current patient SNV aligned to the reference sequence. The spots underneath the variant indicate zygosity: one spot is displayed if the patient is heterozygous for this variant; two spots indicate homozygosity or hemizygosity.",
  height: 90,
  tags: ["My patient", "DECIPHER"],
  snvId: parseInt(snvId),
  url:
    `/patient/${patientId}` +
    (trackType ? `/track/${trackType}` : "") +
    "/variants.json?chr=__CHR__&start=__START__&end=__END__" +
    (trackTypeId ? `&trackTypeId=${trackTypeId}` : "") +
    (transcriptId ? `&transcript_id=${transcriptId}` : ""),
  xhrFields: { withCredentials: true },
  labels: "overlay",
  legend: true,
  genome,
});

export const createRelativeTrackConfig = (
  config,
  { patient = {}, relation } = {}
) => {
  const { reference, patientId } = patient;
  const baseConfig = createTrackConfig({
    ...config,
    patientId,
  });

  return {
    ...baseConfig,
    ...getRelativeTrackBaseConfig("Congenica.SNV.Patient", relation, patientId),
    name: `${relation} (${reference}): SNVs`,
    info: "Sequence variants observed in patient relative",
    legend: false,
  };
};

export const Patient = config => Track.extend(createTrackConfig(config));

export const RelativeSNVs = (config, relative = {}) =>
  Track.extend(createRelativeTrackConfig(config, relative));
