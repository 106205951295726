import { react as autoBind } from "auto-bind";
import { Field } from "formik";
import { not, isNil } from "ramda";
import React, { PureComponent } from "react";

import { Loading } from "pattern-library";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

export default class InheritanceToggle extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      loaded: false,
      display: false,
    };
  }

  componentDidMount() {
    this.checkIfRootProject();
  }

  onChange(e) {
    const { onChange, submitForm } = this.props;
    submitForm();
    if (not(isNil(onChange))) onChange(e);
  }

  async checkIfRootProject() {
    const { projectId } = this.props;

    const response = await fetch(`/webapi/entities/projects/${projectId}`);
    const {
      data: {
        attributes: { is_root: isRoot },
      },
    } = await response.json();

    this.setState({
      loaded: true,
      display: !isRoot,
    });
  }

  render() {
    const { loaded, display } = this.state;
    const { label = "Inherit settings?", narrow = 9 } = this.props;

    if (loaded === false) return <Loading />;
    if (display === false) return null;

    return (
      <Field
        name="isInherited"
        narrow={narrow}
        label={label}
        component={FormikFormField}
        type="toggle"
        onChange={this.onChange}
        id="isInherited"
      />
    );
  }
}
