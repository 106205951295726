import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { getModuleVersionText } from "./selectors";

export const ModuleVersion = ({ versionText }: PropsFromRedux) => (
  <span>{versionText}</span>
);

const mapStateToProps = state => ({
  versionText: getModuleVersionText(state),
});

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModuleVersion);
