import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Button } from "pattern-library";

import { addNewCVL, showExistingCVLs } from "../actions";
import { SNV_CVL_VARIANTS_TYPE } from "../constants";
import {
  canAddNewCVL,
  existingListsAvailable,
  isSuperAdminOrProjectAdmin,
} from "../selectors";

const CVLActions = ({
  addNewCVL,
  showExistingCVLs,
  canAddNewCVL,
  addExistingPermitted,
  existingListsAvailable,
}: PropsFromRedux) => (
  <>
    {addExistingPermitted && (
      <Button
        type="button"
        className="btn"
        onClick={showExistingCVLs}
        disabled={!existingListsAvailable}
      >
        Add existing curated list
      </Button>
    )}
    {canAddNewCVL && (
      <Button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          addNewCVL(SNV_CVL_VARIANTS_TYPE);
        }}
      >
        Add new SNV curated list
      </Button>
    )}
  </>
);

const mapStateToProps = state => ({
  /**
   * true if add existing list action is generally allowed by the user's permissions
   */
  addExistingPermitted: isSuperAdminOrProjectAdmin(state),
  /**
   * true if there is at least one existing list to add to the project
   */
  existingListsAvailable: existingListsAvailable(state),
  canAddNewCVL: canAddNewCVL(state),
});

const mapDispatchToProps = {
  addNewCVL,
  showExistingCVLs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLActions);
