import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import ConfigForm from "../../config/components/ConfigForm";
import * as constants from "../../config/constants";
import { CONFIG_MODE } from "../../config/constants";
import { ConfigFormValuesProp } from "../../config/prop-types";
import * as selectors from "../../config/selectors";
import { adjustFilters } from "../../config/utils";

const mapStateToProps = (state, { preset, patientId }) => {
  const {
    attributes: {
      config: { filters, prioritisation, columns },
    },
  } = preset;

  const patient = selectors.getCurrentPatient(state, patientId);
  return {
    isDisabled: true,
    configMode: CONFIG_MODE.PRESET_VIEW,
    isCustomDataTabEnabled: true,
    initialValues: {
      // no need to explicitly specify filter values exclusions:
      // patient-specific exclusions do not apply (show the preset as is),
      // project exclusions are already controlled by the fields visibility
      ...adjustFilters(filters, patient, []),
      prioritisation,
      columns,
    },
  };
};

const SNVPresetView = reduxForm({
  form: constants.SNV_PRESET_VIEW_FORM,
  enableReinitialize: true,
})(ConfigForm);

SNVPresetView.propTypes = {
  patientId: PropTypes.number.isRequired,
  initialValues: ConfigFormValuesProp.isRequired,
  isCustomDataTabEnabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(SNVPresetView);
