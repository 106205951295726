import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { Modal, MODAL_SIZES, ModalBody, ModalHeader } from "pattern-library";

import { modalTypes } from "modules/modalRegistry/constants";

import { hideModal } from "../modalRegistry/actions";

import AboutModalContent from "./components/AboutModalContent";

export const AboutModal = ({ closeModal }) => (
  <Modal className="about-modal" show close={closeModal} size={MODAL_SIZES.L}>
    <ModalHeader close={closeModal}>
      <h3>About</h3>
    </ModalHeader>
    <ModalBody>
      <AboutModalContent />
    </ModalBody>
  </Modal>
);

AboutModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideModal(modalTypes.ABOUT_MODAL)),
});

export default connect(null, mapDispatchToProps)(AboutModal);
