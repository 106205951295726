type PanelDef = {
  id: string,
  text: string,
  category: {
    name: string,
    className: ?string,
  },
};

const mapCategoryToContext = {
  snv: "warning",
  gene: "danger",
};

const definedVariantPanelValues: Array<PanelDef> = [
  {
    id: "vep_consequence",
    text: "VEP consequence",
    category: { name: "snv" },
  },
  {
    id: "genotype",
    text: "Genotype",
    category: { name: "snv" },
  },
  {
    id: "colocated_variants",
    text: "Co-located variants",
    category: { name: "snv" },
  },
  {
    id: "base_change",
    text: "Base change",
    category: { name: "snv" },
  },
  {
    id: "protein_position",
    text: "Protein position",
    category: { name: "snv" },
  },
  {
    id: "grantham",
    text: "Grantham substitution",
    category: { name: "snv" },
  },
  {
    id: "transcript_name",
    text: "Transcript",
    category: { name: "snv" },
  },
  {
    id: "hgvs_c",
    text: "HGVSc",
    category: { name: "snv" },
  },
  {
    id: "comparison_outcome",
    text: "Comparison outcome",
    category: { name: "snv" },
  },
  {
    id: "hgvs_p",
    text: "HGVSp",
    category: { name: "snv" },
  },
  {
    id: "amino_acid",
    text: "Amino acid",
    category: { name: "snv" },
  },
  {
    id: "max_af",
    text: "MAX AF",
    category: { name: "snv" },
  },
  {
    id: "inheritance",
    text: "Inheritance",
    category: { name: "snv" },
  },
  {
    id: "polyphen",
    text: "PolyPhen prediction",
    category: { name: "snv" },
  },
  {
    id: "codons",
    text: "Codons",
    category: { name: "snv" },
  },
  {
    id: "filter",
    text: "QC status",
    category: { name: "snv" },
  },
  {
    id: "quality",
    text: "Quality score",
    category: { name: "snv" },
  },
  {
    id: "gene_name",
    text: "Gene name",
    category: { name: "gene" },
  },
  {
    id: "other_patients",
    text: "Other patients with this variant",
    category: { name: "snv" },
  },
  {
    id: "splice_site",
    text: "Splice site",
    category: { name: "snv" },
  },
  {
    id: "gene_hi_score",
    text: "Haploinsufficiency score",
    category: { name: "gene" },
  },
  {
    id: "sift",
    text: "SIFT prediction",
    category: { name: "snv" },
  },
  {
    id: "gerp",
    text: "GERP",
    category: { name: "snv" },
  },
  {
    id: "read_split",
    text: "Reads split",
    category: { name: "snv" },
  },
  {
    id: "depth",
    text: "Depth",
    category: { name: "snv" },
  },
  {
    id: "revel",
    text: "REVEL",
    category: { name: "snv" },
    feature: "revel",
  },
];

const calculateVariantPanelFields = (features = {}) =>
  definedVariantPanelValues.reduce((accum, value) => {
    if (!value.feature || (value.feature && features[value.feature])) {
      const { name } = value.category;
      const mappedContext = mapCategoryToContext[name];
      accum.set(value.id, {
        ...value,
        category: {
          ...value.category,
          context: mappedContext,
        },
      });
    }

    return accum;
  }, new Map());

export default calculateVariantPanelFields;
