import {
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  REQUEST_COMPLETION_STATUSES,
  SKIPPED_STATUS,
  SUCCESS_STATUS,
} from "./constants";

export const isRequestCompleted = requestStatus =>
  REQUEST_COMPLETION_STATUSES.includes(requestStatus);

export const isRequestSuccessful = requestStatus =>
  requestStatus === SUCCESS_STATUS;

export const isRequestSkipped = requestStatus =>
  requestStatus === SKIPPED_STATUS;

export const isRequestInProgress = requestStatus =>
  requestStatus === IN_PROGRESS_STATUS;

export const hasRequestFailed = requestStatus =>
  requestStatus === FAILURE_STATUS;
