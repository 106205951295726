import * as d3 from "d3";
import { get } from "dot-prop-immutable";
import $, { default as jQuery } from "jquery";
import nv from "nvd3";

import { QC } from "./qc";

export function loadFastQC(json: FastQCJson) {
  $("[data-method-passfail-jsonlookup]").each(function () {
    $(this).removeClass("pass fail warn");
    $(this).addClass(get(json, $(this).data("method-passfail-jsonlookup")));
  });

  nv.addGraph(() => {
    const chart = nv.models
      .pieChart()
      .x(d => d.key)
      .y(d => d.count)
      .donut(true)
      .showLegend(false)
      .labelsOutside(true)
      .growOnHover(true)
      .valueFormat(i => parseInt(i));

    const data = [
      { key: "Passed", count: $(".method.pass").length, color: "#3c763d" },
      { key: "Warnings", count: $(".method.warn").length, color: "#8a6d3b" },
      { key: "Failed", count: $(".method.fail").length, color: "#a94442" },
    ];

    d3.select("#pass-meter svg").datum(data).call(chart);

    return chart;
  });

  QC.populateDattributes($("div.fastqc"), json);

  const sequence_quality_scores =
    json["Per sequence quality scores"].contents.Count;
  if (!jQuery.isEmptyObject(sequence_quality_scores)) {
    nv.addGraph(() => {
      const data = [
        {
          values: Object.keys(sequence_quality_scores).map(i => ({
            x: parseInt(i),
            // We use Number to parse the quality scores, as parseInt doesn't handle scientific notation in the way we need here
            // SAP-19141 was related to a bug where one of the scores was "1.145703E7"
            y: Number(sequence_quality_scores[i]),
          })),
          key: "Sequences",
        },
      ];

      const chart1 = nv.models.lineChart().options({
        transitionDuration: 300,
        useInteractiveGuideline: true,
      });

      // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
      chart1.xAxis
        .axisLabel("Mean sequence quality (Phred Score)")
        .tickFormat(i => i);

      chart1.yAxis.axisLabel("Number of reads").tickFormat(i => parseInt(i));

      d3.select("#quality-score svg").datum(data).call(chart1);

      nv.utils.windowResize(chart1.update);
      return chart1;
    });
  }

  const per_base_content = json["Per base sequence content"].contents;
  if (!jQuery.isEmptyObject(per_base_content)) {
    nv.addGraph(() => {
      // sort the regions so that they come out right
      const regions = Object.keys(per_base_content.A).sort(
        (a, b) => QC.normalizeRegionString(a) - QC.normalizeRegionString(b)
      );
      const tickmap = {};
      Object.keys(per_base_content.A).forEach(i => {
        tickmap[QC.normalizeRegionString(i)] = i;
      });

      const data = [
        {
          values: regions.map(i => ({
            x: i,
            y: parseFloat(per_base_content.A[i]),
          })),
          key: "A",
          color: QC.defaults.colours.A,
        },
        {
          values: regions.map(i => ({
            x: i,
            y: parseFloat(per_base_content.T[i]),
          })),
          key: "T",
          color: QC.defaults.colours.T,
        },
        {
          values: regions.map(i => ({
            x: i,
            y: parseFloat(per_base_content.G[i]),
          })),
          key: "G",
          color: QC.defaults.colours.G,
        },
        {
          values: regions.map(i => ({
            x: i,
            y: parseFloat(per_base_content.C[i]),
          })),
          key: "C",
          color: QC.defaults.colours.C,
        },
      ];

      const chart = nv.models
        .lineChart()
        .options({
          transitionDuration: 300,
          useInteractiveGuideline: true,
        })
        .x(d => QC.normalizeRegionString(d.x));

      // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
      chart.xAxis
        .axisLabel("Position in read (bp)")
        .tickFormat(i => tickmap[i]);

      chart.yAxis
        .tickFormat(i => parseFloat(i).toFixed(2))
        .axisLabel("Base content (%)");

      d3.select("#base-content svg").datum(data).call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const length_distribution =
    json["Sequence Length Distribution"].contents.Count;
  if (!jQuery.isEmptyObject(length_distribution)) {
    nv.addGraph(() => {
      // sort the regions so that they come out right
      const regions = Object.keys(length_distribution).sort(
        (a, b) => QC.normalizeRegionString(a) - QC.normalizeRegionString(b)
      );
      const tickmap = {};
      Object.keys(length_distribution).forEach(i => {
        tickmap[QC.normalizeRegionString(i)] = i;
      });

      const data = [
        {
          values: regions.map(i => ({
            x: i,
            y: parseFloat(length_distribution[i]),
          })),
          key: "A",
        },
      ];

      const chart = nv.models
        .lineChart()
        .options({
          transitionDuration: 300,
          useInteractiveGuideline: true,
        })
        .showLegend(false)
        .x(d => QC.normalizeRegionString(d.x));
      // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
      chart.xAxis.axisLabel("Read Length (bp)").tickFormat(i => tickmap[i]);

      chart.yAxis.axisLabel("Reads").tickFormat(i => parseInt(i));

      d3.select("#sequence-length svg").datum(data).call(chart);
      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const per_base_sequence_quality = json["Per base sequence quality"].contents;
  if (!jQuery.isEmptyObject(per_base_sequence_quality)) {
    nv.addGraph(() => {
      // sort the regions so that they come out right
      const regions = Object.keys(per_base_sequence_quality.Median).sort(
        (a, b) => QC.normalizeRegionString(a) - QC.normalizeRegionString(b)
      );
      const tickmap = {};
      const data: Array<any> = [];
      regions.forEach(i => {
        tickmap[QC.normalizeRegionString(i)] = i;

        data.push({
          label: i,
          values: {
            Q1: per_base_sequence_quality["Lower Quartile"][i],
            Q2: per_base_sequence_quality.Median[i],
            Q3: per_base_sequence_quality["Upper Quartile"][i],
            whisker_low: per_base_sequence_quality["10th Percentile"][i],
            whisker_high: per_base_sequence_quality["90th Percentile"][i],
          },
          pos: i,
          color: QC.defaults.colours.secondary,
        });
      });

      const chart = nv.models
        .boxPlotChart()
        .x(d => QC.normalizeRegionString(d.pos))
        .y(d => d.values.Q3)
        .maxBoxWidth(75) // prevent boxes from being incredibly wide
        .yDomain([0, 45])
        .margin({ bottom: 58 });

      chart.xAxis
        .axisLabel("Position in read (bp)")
        .tickFormat(i => tickmap[i])
        .rotateLabels(-35);

      chart.yAxis.axisLabel("Score").tickFormat(i => parseInt(i));

      d3.select("#bp-sequence-quality svg").datum(data).call(chart);
      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const per_sequence_gc_content =
    json["Per sequence GC content"].contents.Count;
  if (!jQuery.isEmptyObject(per_sequence_gc_content)) {
    nv.addGraph(() => {
      // sort the regions so that they come out right
      const regions = Object.keys(per_sequence_gc_content).sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );

      const data = [
        {
          values: regions.map(i => ({
            x: parseInt(i),
            y: parseFloat(per_sequence_gc_content[i]),
          })),
          key: "GC Count per read",
        },
        //{
        //  key: "Theoretical Distribution",
        //  values: regions.map(function(i){ return { x: parseInt(i), y: parseFloat(per_sequence_gc_content[i]) } }),
        //}
      ];

      const chart = nv.models.lineChart().options({
        transitionDuration: 300,
        useInteractiveGuideline: true,
      });

      // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
      chart.xAxis.axisLabel("Mean GC Content (%)");

      chart.yAxis.tickFormat(i => parseInt(i));

      d3.select("#gc-content svg").datum(data).call(chart);
      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const per_base_n_content = json["Per base N content"].contents["N-Count"];
  if (!jQuery.isEmptyObject(per_base_n_content)) {
    nv.addGraph(() => {
      // sort the regions so that they come out right
      const regions = Object.keys(per_base_n_content).sort(
        (a, b) => QC.normalizeRegionString(a) - QC.normalizeRegionString(b)
      );
      const tickmap = {};
      Object.keys(per_base_n_content).forEach(i => {
        tickmap[QC.normalizeRegionString(i)] = i;
      });

      const data = [
        {
          values: regions.map(i => ({
            x: i,
            y: parseFloat(per_base_n_content[i]),
          })),
          key: "%N",
        },
      ];

      const chart = nv.models
        .lineChart()
        .options({
          transitionDuration: 300,
          useInteractiveGuideline: true,
        })
        .x(d => QC.normalizeRegionString(d.x));
      // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
      chart.xAxis
        .axisLabel("Position in read (bp)")
        .tickFormat(i => tickmap[i]);

      chart.yAxis
        .tickFormat(i => parseFloat(i).toPrecision(2))
        .axisLabel("Base content (%)");

      d3.select("#n-content svg").datum(data).call(chart);
      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const dupe_levels = json["Sequence Duplication Levels"].contents;
  if (!jQuery.isEmptyObject(dupe_levels)) {
    nv.addGraph(() => {
      const buckets = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ">10",
        ">50",
        ">100",
        ">500",
        ">1k",
        ">5k",
        ">10k+",
      ];
      const data = [
        {
          values: buckets.map((i, j) => ({
            x: j,
            y: parseFloat(dupe_levels["Percentage of deduplicated"][i]),
          })),
          key: "% Deduplicated reads",
        },
        {
          values: buckets.map((i, j) => ({
            x: j,
            y: parseFloat(dupe_levels["Percentage of total"][i]),
          })),
          key: "% Total reads",
        },
      ];

      const chart = nv.models.lineChart().options({
        transitionDuration: 300,
        useInteractiveGuideline: true,
      });

      // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
      chart.xAxis
        .axisLabel("Sequence duplication level")
        .tickFormat(i => buckets[i]);

      chart.yAxis.tickFormat(i => parseFloat(i).toPrecision(2) + "%");

      d3.select("#sequence-duplications svg").datum(data).call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const adapter_content = json["Adapter Content"].contents;
  if (!jQuery.isEmptyObject(adapter_content)) {
    nv.addGraph(() => {
      // sort the regions so that they come out right
      const regions = Object.keys(
        adapter_content["Illumina Universal Adapter"]
      ).sort(
        (a, b) => QC.normalizeRegionString(a) - QC.normalizeRegionString(b)
      );
      const tickmap = {};
      Object.keys(adapter_content["Illumina Universal Adapter"]).forEach(i => {
        tickmap[QC.normalizeRegionString(i)] = i;
      });

      const data: Array<{
        key: string;
        values: Array<{ x: string; y: number }>;
      }> = [];

      function addAdapterContentGraph(graphName) {
        const adapterContentGraph = adapter_content[graphName];
        if (!jQuery.isEmptyObject(adapterContentGraph)) {
          data.push({
            values: regions.map(i => ({
              x: i,
              y: parseFloat(adapterContentGraph[i]),
            })),
            key: graphName,
          });
        }
      }

      addAdapterContentGraph("Illumina Universal Adapter");
      addAdapterContentGraph("Illumina Small RNA Adapter");
      addAdapterContentGraph("Illumina Small RNA 3' Adapter");
      addAdapterContentGraph("Illumina Small RNA 5' Adapter");
      addAdapterContentGraph("Nextera Transposase Sequence");
      addAdapterContentGraph("SOLID Small RNA Adapter");

      const chart = nv.models
        .lineChart()
        .margin({ left: 90 })
        .options({
          transitionDuration: 300,
          useInteractiveGuideline: true,
        })
        .showLegend(false)
        .x(d => QC.normalizeRegionString(d.x));

      // chart sub-models (ie. xAxis, yAxis, etc) when accessed directly, return themselves, not the parent chart, so need to chain separately
      chart.xAxis
        .axisLabel("Position in read (bp)")
        .tickFormat(i => tickmap[i]);

      chart.yAxis
        .width(90)
        .tickFormat(i => parseFloat(i).toPrecision(2) + "%")
        .axisLabel("Base content (%)");

      d3.select("#adapter-content svg").datum(data).call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }
}
