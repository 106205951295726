import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export class Well extends PureComponent {
  static displayName = "Well";

  static propTypes = {
    /**
     * The items to display in a carousel
     */
    children: PropTypes.node.isRequired,
    /**
     * Class to apply to the form-group div
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children, ...otherProps } = this.props;
    return (
      <div className={classNames("well", className)} {...otherProps}>
        {children}
      </div>
    );
  }
}
