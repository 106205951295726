// @flow
import { createAction } from "../utils";

import * as constants from "./constants";
import type { DraftData, FeedbackType, RequestData } from "./flow-types";

export const saveFeedbackDraftAction = (
  draftType: FeedbackType,
  draftData: DraftData
) => createAction(constants.SAVE_FEEDBACK_DRAFT, { draftType, draftData });
export const resetFeedbackDraftAction = (draftType: FeedbackType) =>
  createAction(constants.RESET_FEEDBACK_DRAFT, { draftType });

export const submitFeedbackAction = (
  feedbackType: FeedbackType,
  requestData: RequestData
) => createAction(constants.SUBMIT_FEEDBACK, { feedbackType, requestData });
export const submitFeedbackSuccess = () =>
  createAction(constants.SUBMIT_FEEDBACK_SUCCESS);
export const submitFeedbackFailure = () =>
  createAction(constants.SUBMIT_FEEDBACK_FAILURE);
export const resetSubmitFeedbackStatus = () =>
  createAction(constants.RESET_SUBMIT_FEEDBACK_STATUS);
