import { useMemo, useEffect } from "react";

import congenicaApi from "api/congenica-api";

const ENSEMBL_THIRDPARTY = "ENSEMBL";

export const getEnsemblVersion = (annotationSources: AnnotationSource[]) => {
  const ensemblAnnotationData = annotationSources.find(
    ({ attribute, type }) => attribute === ENSEMBL_THIRDPARTY && type === "data"
  );

  return ensemblAnnotationData?.value;
};

const getSystemEnsemblVersion = (annotationSources: Version[]) => {
  const ensemblAnnotationData = annotationSources.find(
    ({ name }) => name === ENSEMBL_THIRDPARTY
  );

  return ensemblAnnotationData?.version;
};

const { useLazyGetPatientQuery, useLazyGetThirdPartyDataVersionsQuery } =
  congenicaApi;

/**
 * Retrieves the patient's Ensembl version (if patient ID is passed),
 * or the system's active Ensembl version
 */
export const useEnsemblVersion = (patientId?: number | null) => {
  const [getPatient, patientResponse] = useLazyGetPatientQuery({
    selectFromResult: result => ({
      ...result,
      ensemblVersion: getEnsemblVersion(result.data?.annotation_sources || []),
    }),
  });

  const [getSystemVersions, systemResponse] =
    useLazyGetThirdPartyDataVersionsQuery({
      selectFromResult: result => ({
        ...result,
        ensemblVersion: getSystemEnsemblVersion(result?.data || []),
      }),
    });

  useEffect(() => {
    if (patientId) {
      getPatient({ patientId }, true);
    } else {
      // If don't we have a patient ID, call and get the system version instead
      getSystemVersions({ name: "ENSEMBL", is_active: true }, true);
    }
  }, [getPatient, getSystemVersions, patientId]);

  return useMemo(
    // If we have a patient ID defined, then always return the API response
    // If we didn't pass one in, then we can return the system version
    () => (patientId ? patientResponse : systemResponse),
    [patientId, patientResponse, systemResponse]
  );
};
