import { createAction } from "../utils";

import * as constants from "./constants";
import { PresetDictionaries, SVPresetsInfo } from "./types";

export type LoadPresetsAction = FSA<
  typeof constants.LOAD_PRESETS,
  Index,
  { projectId: number }
>;

export const loadPresets = (projectId: number): LoadPresetsAction =>
  createAction(constants.LOAD_PRESETS, {}, { projectId });

type SetPresetsInfoAction = FSA<
  typeof constants.SET_PRESETS_INFO,
  Partial<SVPresetsInfo>
>;

export const setPresetsInfo = (
  presetsInfo: Partial<SVPresetsInfo>
): SetPresetsInfoAction =>
  createAction(constants.SET_PRESETS_INFO, presetsInfo);

type SelectPresetAction = FSA<
  typeof constants.SELECT_PRESET,
  { presetId: number }
>;

export const selectPreset = (presetId: number): SelectPresetAction =>
  createAction(constants.SELECT_PRESET, { presetId });

type ResetToDefaultPresetAction = FSA<typeof constants.RESET_TO_DEFAULT_PRESET>;

export const resetToDefaultPreset = (): ResetToDefaultPresetAction =>
  createAction(constants.RESET_TO_DEFAULT_PRESET);

export type LoadDictionariesAction = FSA<
  typeof constants.LOAD_DICTIONARIES,
  Index,
  { projectId: number }
>;

export const loadDictionaries = (projectId: number): LoadDictionariesAction =>
  createAction(constants.LOAD_DICTIONARIES, {}, { projectId });

type SetPresetDictionariesAction = FSA<
  typeof constants.SET_DICTIONARIES,
  PresetDictionaries,
  { projectId: number }
>;

export const setPresetDictionaries = (
  presetDictionaries: PresetDictionaries
): SetPresetDictionariesAction =>
  createAction(constants.SET_DICTIONARIES, presetDictionaries);

export type Action =
  | LoadPresetsAction
  | SetPresetsInfoAction
  | SelectPresetAction
  | ResetToDefaultPresetAction
  | LoadDictionariesAction
  | SetPresetDictionariesAction;
