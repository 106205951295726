// @flow
import { createAction } from "../utils";

import * as constants from "./constants";
import { type FilteredVariantInfoByGene } from "./reducer";

type SetFilterValuesAction = FSA<typeof constants.SET_FILTER_VALUE>;

export const setFilterValues = (filterConfig: {}): SetFilterValuesAction => ({
  type: constants.SET_FILTER_VALUE,
  payload: filterConfig,
  meta: {},
});

type InitialiseAction = FSA<
  typeof constants.START_INIT,
  {},
  { patientId: number }
>;

export const initialise = (
  patientId: number,
  projectId?: number
): InitialiseAction => ({
  type: constants.START_INIT,
  payload: {},
  meta: {
    patientId,
    projectId,
  },
});

type InitialisedAction = FSA<typeof constants.VARIANTS_INIT>;

export const initialised = (): InitialisedAction =>
  createAction(constants.VARIANTS_INIT);

type ReloadVariantsAction = FSA<
  typeof constants.RELOAD_VARIANTS,
  {},
  { patientId: number }
>;

export const reloadVariants = (
  patientId: number,
  searchParams?: {}
): ReloadVariantsAction => {
  const action = {
    type: constants.RELOAD_VARIANTS,
    payload: {},
    meta: {
      patientId,
    },
  };

  if (searchParams) {
    action.payload.searchParams = searchParams;
  }
  return action;
};

type ReloadVariantPageAction = FSA<
  typeof constants.RELOAD_VARIANT_PAGE,
  {},
  { patientId: number }
>;

export const reloadVariantPage = (
  patientId: number,
  searchParams?: {}
): ReloadVariantPageAction => {
  const action = {
    type: constants.RELOAD_VARIANT_PAGE,
    payload: {},
    meta: {
      patientId,
    },
  };

  if (searchParams) {
    action.payload.searchParams = searchParams;
  }
  return action;
};

type ToggleVariantExpandedAction = FSA<
  typeof constants.TOGGLE_VARIANT_EXPANDED,
  { variantId: number }
>;

export const toggleVariantExpanded = (
  variantId: number
): ToggleVariantExpandedAction => ({
  type: constants.TOGGLE_VARIANT_EXPANDED,
  payload: {
    variantId,
  },
  meta: {},
});

type SetActiveGenesAction = FSA<
  typeof constants.SET_ACTIVE_GENES,
  Array<number>
>;

export const setActiveGenes = (
  genes: Array<number> = []
): SetActiveGenesAction => ({
  type: constants.SET_ACTIVE_GENES,
  payload: genes,
  meta: {},
});

type StartLoadingVariantsAction = FSA<typeof constants.LOADING_VARIANTS>;

export const startLoadingVariants = (): StartLoadingVariantsAction => ({
  type: constants.LOADING_VARIANTS,
  payload: {},
  meta: {},
});

type LoadingVariantsCompletedAction = FSA<
  typeof constants.LOADING_VARIANTS_COMPLETED
>;

export const loadingVariantsCompleted = (): LoadingVariantsCompletedAction => ({
  type: constants.LOADING_VARIANTS_COMPLETED,
  payload: {},
  meta: {},
});

type SetActiveTranscriptAction = FSA<
  typeof constants.SET_ACTIVE_TRANSCRIPT,
  {
    transcriptId: number,
  }
>;

export const setActiveTranscript = (
  transcriptId: number
): SetActiveTranscriptAction => ({
  type: constants.SET_ACTIVE_TRANSCRIPT,
  payload: {
    transcriptId,
  },
  meta: {},
});

type SetActiveVariantPanelValuesAction = FSA<
  typeof constants.SET_ACTIVE_PANEL_VALUES,
  {
    geneName: string,
    geneId: number,
    variantId: number | boolean,
    patientVariantId: number | boolean,
    transcriptId: ?number,
  }
>;

export const setActiveVariantPanelValues = (
  geneName: string,
  geneId: number,
  variantId: number | boolean = false,
  patientVariantId: number | boolean = false,
  transcriptId: ?number
): SetActiveVariantPanelValuesAction => ({
  type: constants.SET_ACTIVE_PANEL_VALUES,
  payload: {
    geneName,
    geneId,
    variantId,
    patientVariantId,
    transcriptId,
  },
  meta: {},
});

type SelectContextForVariantPanelAction = FSA<
  typeof constants.SELECT_CONTEXT_FOR_VARIANT_PANEL,
  {
    patientId: number,
    geneId: number,
    geneName?: string,
    variantId?: number,
    patientVariantId?: number,
    transcriptId?: number,
  }
>;

export const selectContextForVariantPanel = (
  patientId: number,
  geneId: number,
  geneName: string,
  variantId: number,
  patientVariantId: number,
  transcriptId: number
): SelectContextForVariantPanelAction => ({
  type: constants.SELECT_CONTEXT_FOR_VARIANT_PANEL,
  payload: {
    patientId,
    geneName,
    geneId,
    variantId,
    patientVariantId,
    transcriptId,
  },
  meta: {},
});

export type ResetVariantPanelAction = FSA<typeof constants.RESET_VARIANT_PANEL>;

export const resetVariantPanel = (): ResetVariantPanelAction => ({
  type: constants.RESET_VARIANT_PANEL,
  payload: {},
  meta: {},
});

type SetPageAction = FSA<typeof constants.SET_PAGE, number>;

export const setPage = (page: number): SetPageAction =>
  createAction(constants.SET_PAGE, page);

type SetPagerAction = FSA<
  typeof constants.SET_PAGER,
  {
    firstItem: number,
    lastItem: number,
    lastPage: number,
    totalItems: number,
  }
>;

export const setPager = (pager: {
  firstItem: number,
  lastItem: number,
  lastPage: number,
  totalItems: number,
}): SetPagerAction => createAction(constants.SET_PAGER, pager);

type GetGeneVariantCountAction = FSA<
  typeof constants.GET_PANEL_GENES_VARIANT_COUNT,
  {
    list: [{ gene_id: number, variant_count: number }],
    success: boolean,
  }
>;

export const getGeneVariantCount = (variantCount: {
  list: Array<{}>,
  success: boolean,
}): GetGeneVariantCountAction =>
  createAction(constants.GET_PANEL_GENES_VARIANT_COUNT, variantCount);

type SetFilteredVariantsByGeneAction = FSA<
  typeof constants.SET_FILTERED_VARIANTS_BY_GENE,
  { genes: Array<{}>, replace: boolean }
>;

export const setFilteredVariantsByGene = (
  genes: Array<{}>,
  replace: boolean = true
): SetFilteredVariantsByGeneAction =>
  createAction(constants.SET_FILTERED_VARIANTS_BY_GENE, {
    genes,
    replace,
  });

type SetFilteredVariantInfoByGeneAction = FSA<
  typeof constants.SET_FILTERED_VARIANT_INFO_BY_GENE,
  FilteredVariantInfoByGene
>;

export const setFilteredVariantInfoByGene = (
  filteredVariantInfoByGene: FilteredVariantInfoByGene
): SetFilteredVariantInfoByGeneAction =>
  createAction(
    constants.SET_FILTERED_VARIANT_INFO_BY_GENE,
    filteredVariantInfoByGene
  );

type SetFilteredOutVariantsDisplayedAction = FSA<
  typeof constants.SET_FILTERED_OUT_VARIANTS_DISPLAYED,
  boolean
>;

export const setFilteredOutVariantsDisplayed = (
  filteredOutVariantsDisplayed: boolean
): SetFilteredOutVariantsDisplayedAction =>
  createAction(
    constants.SET_FILTERED_OUT_VARIANTS_DISPLAYED,
    filteredOutVariantsDisplayed
  );

type GetGeneFromFilterPanelAction = FSA<
  typeof constants.GET_GENE_FROM_FILTER_PANEL,
  { geneId: number, patientId: number }
>;

export const getGeneFromFilterPanel = (
  patientId: number,
  geneId: number
): GetGeneFromFilterPanelAction =>
  createAction(constants.GET_GENE_FROM_FILTER_PANEL, { patientId, geneId });

type SearchGeneByNameFromUrlAction = FSA<
  typeof constants.SEARCH_GENE_BY_NAME_FROM_URL,
  string
>;

export const searchGeneByNameFromUrl = (
  geneName: string,
  patientId: number
): SearchGeneByNameFromUrlAction =>
  createAction(constants.SEARCH_GENE_BY_NAME_FROM_URL, { geneName, patientId });

type SetAllVariantsCountAction = FSA<
  typeof constants.SET_ALL_VARIANTS_COUNT,
  { list: Array<{ variant_count: number }> }
>;

export const setAllVariantsCount = (payload: { list: Array<{}> }) =>
  createAction(constants.SET_ALL_VARIANTS_COUNT, payload);
export const loadFullGene = (
  patientId: number,
  geneId: number,
  variantsReady: boolean = false
) =>
  createAction(constants.LOAD_FULL_GENE, {
    patientId,
    geneId,
    variantsReady,
  });

export const loadGeneVariantsFullCount = (patientId: number, geneId: number) =>
  createAction(constants.LOAD_ALL_VARIANTS_COUNT, { geneId, patientId });

export const resetActiveGene = () => createAction(constants.RESET_ACTIVE_GENE);

type SetGeneToFocusAction = FSA<typeof constants.SET_GENE_TO_FOCUS, ?number>;
export const setGeneToFocus = (geneId: ?number): SetGeneToFocusAction =>
  createAction(constants.SET_GENE_TO_FOCUS, geneId);

export const addConfigPanelGene = (gene: Gene) =>
  createAction(constants.ADD_CONFIG_PANEL_GENE, gene);

export type Action =
  | SetFilterValuesAction
  | InitialiseAction
  | InitialisedAction
  | ReloadVariantsAction
  | ToggleVariantExpandedAction
  | SetActiveGenesAction
  | StartLoadingVariantsAction
  | LoadingVariantsCompletedAction
  | SetActiveTranscriptAction
  | SetActiveVariantPanelValuesAction
  | ResetVariantPanelAction
  | SetPageAction
  | SetPagerAction
  | GetGeneVariantCountAction
  | SetFilteredVariantsByGeneAction
  | SetFilteredVariantInfoByGeneAction
  | SetFilteredOutVariantsDisplayedAction
  | SearchGeneByNameFromUrlAction
  | SelectContextForVariantPanelAction
  | SetGeneToFocusAction
  | SetAllVariantsCountAction;
