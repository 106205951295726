import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Link } from "pattern-library";

import { getInstructionsForUseLink, getModuleName } from "./selectors";

export const InstructionsLink = ({
  instructionsForUseLink,
  moduleName,
}: PropsFromRedux) => (
  <span>
    Consult {moduleName}{" "}
    <Link
      href={instructionsForUseLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      instructions for use
    </Link>
  </span>
);

InstructionsLink.displayName = "InstructionsLink";

const mapStateToProps = state => ({
  instructionsForUseLink: getInstructionsForUseLink(state),
  moduleName: getModuleName(state),
});

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(InstructionsLink);
