import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class FormControlWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    narrow: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const {
      narrow = false,
      label = "",
      className = "",
      children,
      ...otherProps
    } = this.props;

    return (
      <div
        className={classNames(className, {
          "col-xs-8": narrow === true && label.length > 0,
          "col-xs-10": narrow === false && label.length > 0,
          "col-xs-12": label.length === 0,
        })}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}
