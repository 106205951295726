import { concat } from "ramda";
import { useState, Component } from "react";
import {
  withRouter,
  RouteProps,
  RedirectProps,
  RouteComponentProps,
} from "react-router";
import { Route, Redirect } from "react-router-dom";

type TabElement = {
  name: string;
  url: string;
  hash: string;
  component: typeof Component;
  componentProps: Index;
  routeUrl: string;
  exact: boolean;
  redirect: RedirectProps;
};

interface Props {
  baseUrl: string;
  routePath: string;
  stopUnmount: boolean;
  tab: TabElement;
  location: {
    pathname: string;
    hash: string;
  };
}

export const RouteTabContent = (props: RouteComponentProps & Props) => {
  const [renderedOnce, setRenderedOnce] = useState(false);
  const {
    tab: {
      routeUrl,
      url = "",
      exact,
      name,
      component,
      componentProps,
      redirect,
    },
    routePath,
    baseUrl,
    location: { pathname, hash },
    stopUnmount,
  } = props;
  const fullUrl = concat(routePath, routeUrl || url);

  const routeProps: RouteProps & { key: string; className: string } = {
    exact,
    key: `tab-content-${name}`,
    path: fullUrl,
    className: `tab-pane ${pathname.match(fullUrl) ? "active" : ""}`,
    component,
  };

  // This is the react router way of doing things, it doesn't cause excess re-renders
  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/basic-components.md#route-rendering-props
  if (componentProps) {
    delete routeProps.component;

    routeProps.render = props => {
      const Component = component;

      return <Component {...props} {...componentProps} />;
    };
  }

  if (redirect && baseUrl + redirect.from === pathname) {
    return (
      <Redirect
        to={baseUrl + redirect.to + hash}
        push={redirect.push}
        from={baseUrl + redirect.from}
      />
    );
  } else {
    const {
      tab: { component: Component },
    } = props;

    if (Component && stopUnmount) {
      const routeUrlToCheck = concat(baseUrl, routeUrl || url);
      const isCurrentRoute = pathname.match(routeUrlToCheck);

      // It prevents from doing unnecesary endpoint calls
      // when tab is closed initially
      if (!isCurrentRoute && !renderedOnce) return null;

      if (!renderedOnce) {
        setRenderedOnce(true);
      }
      return (
        <div hidden={!isCurrentRoute}>
          <Component {...componentProps} {...props} />
        </div>
      );
    }

    return <Route {...routeProps} />;
  }
};

RouteTabContent.displayName = "RouteTabContent";

RouteTabContent.defaultProps = {
  component: null,
  componentsProps: {},
  routeUrl: "",
  redirect: null,
  exact: false,
  tab: undefined,
};

export default withRouter(RouteTabContent);
