// @flow

import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import {
  FILTER_PRESET_REMOVED,
  SET_FILTER_DICTIONARIES,
  SET_FILTER_PRESETS,
  SET_FILTER_PRESETS_REQUEST_STATUS,
  SET_INHERITANCE_SETTINGS,
  SET_INHERITANCE_SETTINGS_SUBMIT_STATUS,
  SET_PRESET_REMOVE_STATUS,
  SET_PRESET_SUBMIT_STATUS,
  SET_SELECTED_PRESET,
} from "./constants";
import type { State } from "./types";

export const initialState: State = {
  ui: {
    filterPresetsRequestStatus: null,
    inheritanceSettingsSubmitStatus: null,
    filterPresetSubmitStatus: null,
    filterPresetRemoveStatus: null,
  },
  data: {
    isConfigurationInherited: null,
    filterPresets: [],
    selectedPreset: null,
    dictionaries: {
      genotypes: [],
      sizes: [],
      inheritance: [],
      qualities: [],
      subtypes: [],
      tiers: [],
    },
  },
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case SET_FILTER_PRESETS_REQUEST_STATUS:
      return set(state, "ui.filterPresetsRequestStatus", action.payload);
    case SET_FILTER_PRESETS:
      if (state.data.selectedPreset) {
        const selectedPresetTitle = state.data.selectedPreset.title; //extracted here to avoid flow error
        const filterPresets = action.payload;

        //title is checked here because when we turn on/off inheritance preset's id is changed
        const selectedPreset = filterPresets.find(
          ({ title }) => title === selectedPresetTitle
        );
        const updateData = {
          ...state.data,
          filterPresets,
          selectedPreset,
        };
        return set(state, "data", updateData);
      } else {
        return set(state, "data.filterPresets", action.payload);
      }
    case FILTER_PRESET_REMOVED:
      const updatedData = {
        ...state.data,
        filterPresets: state.data.filterPresets.filter(
          preset => preset.id !== action.payload
        ),
        selectedPreset: null,
      };
      return set(state, "data", updatedData);
    case SET_PRESET_SUBMIT_STATUS:
      return set(state, "ui.filterPresetSubmitStatus", action.payload);
    case SET_SELECTED_PRESET:
      return set(state, "data.selectedPreset", action.payload);
    case SET_PRESET_REMOVE_STATUS:
      return set(state, "ui.filterPresetRemoveStatus", action.payload);
    case SET_FILTER_DICTIONARIES:
      return set(state, "data.dictionaries", action.payload);
    case SET_INHERITANCE_SETTINGS:
      return set(
        state,
        "data.isConfigurationInherited",
        action.payload ? action.payload.isInherited : null
      );
    case SET_INHERITANCE_SETTINGS_SUBMIT_STATUS:
      return set(state, "ui.inheritanceSettingsSubmitStatus", action.payload);
    default:
      return state;
  }
}
