import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import styles from "./Modal.module.scss";

export const MODAL_SIZES = Object.freeze({
  STANDARD: "size-standard",
  L: "size-l",
  XL: "size-xl",
});

export default class Modal extends PureComponent {
  static displayName = "Modal";

  static propTypes = {
    /**
     * Class to apply to the modal container
     */
    className: PropTypes.string,
    /**
     * The content to render in the modal
     */
    children: PropTypes.node.isRequired,
    /**
     * Whether the modal is active or not
     */
    show: PropTypes.bool.isRequired,
    /**
     * The function to close the modal
     *
     * Normally it changes state to update the value of show
     */
    close: PropTypes.func.isRequired,

    /**
     * Size of modal dialog
     */
    size: PropTypes.oneOf(Object.values(MODAL_SIZES)),
  };

  static defaultProps = {
    className: "",
    size: MODAL_SIZES.STANDARD,
  };

  constructor(props) {
    super(props);

    if (props.show === true) document.body.classList.add("modal-open");

    autoBind(this);
  }

  componentDidUpdate() {
    const { show } = this.props;
    const bodyHasModalOpen = document.body.classList.contains("modal-open");
    const bodyClassList = document.body.classList;

    if (bodyHasModalOpen && show === false) {
      bodyClassList.remove("modal-open");
    } else if (!bodyHasModalOpen && show === true) {
      bodyClassList.add("modal-open");
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  handleClose(e) {
    const { close } = this.props;
    if (e.target.classList.contains("modal") && close) {
      close();
    }
  }

  render() {
    const { className, children, close, show, size, style, ...otherProps } =
      this.props;
    const activeClass = { in: show };

    return (
      <div className={classNames("modal-container", activeClass)}>
        <div
          {...otherProps}
          onClick={this.handleClose}
          className={classNames("modal", activeClass, className)}
        >
          <div
            className={classNames("modal-dialog", styles.modalDialog, {
              // We shouldn't add class name for "standard" modals to prevent updating many snapshots.
              [size]: size !== MODAL_SIZES.STANDARD,
            })}
          >
            <div className="modal-content">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}
