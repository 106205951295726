//@flow

import type { Saga } from "redux-saga";
import { put, call } from "redux-saga/effects";

import { error, errors, success, message } from "modules/messages/actions";
import type { FetchDataResponse } from "modules/utils/fetchData";

const HEADER_MESSAGE_LEVELS: Array<
  "success" | "info" | "warning" | "error" | "danger"
> = ["success", "info", "warning", "error"];

export const processHeaders = function* ({ messages = {} }: any): any {
  let hasMessages = false;
  for (let i = 0; i < HEADER_MESSAGE_LEVELS.length; i++) {
    let level = HEADER_MESSAGE_LEVELS[i];
    const msg = messages[level];
    if (level === "error") {
      level = "danger";
    }
    if (msg) {
      hasMessages = true;
      yield put(message(level, msg));
    }
  }
  return hasMessages;
};

export const processSuccess = function* (
  response: FetchDataResponse<any>,
  successMessage: ?string
): Saga<void> {
  const headersHasMessages = yield call(processHeaders, response);
  if (!headersHasMessages) {
    const {
      meta: {
        message: successMessageFromBackend = "Operation completed successfully",
      } = {},
    } = response.payload || {};
    yield put(success(successMessage || successMessageFromBackend));
  }
};

export function* processError(
  response: FetchDataResponse<any>,
  defaultErrorMessage: string = "Unknown error"
): Saga<void> {
  const headersHasMessages = yield call(processHeaders, response);
  if (!headersHasMessages) {
    const { errors: errorMessages = [] } = response;
    yield put(
      errorMessages.length > 0
        ? errors(errorMessages)
        : error(defaultErrorMessage)
    );
  }
}
