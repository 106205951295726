import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";

const Search = memo(({ value, onChange }) => {
  const handleChange = useCallback(
    ({ target: { value } }) => {
      onChange(value);
    },
    [onChange]
  );

  const handleIconClick = useCallback(() => {
    if (value) {
      onChange("");
    }
  }, [value, onChange]);

  return (
    <div className="form-group">
      <div className="input-group">
        <input
          type="text"
          name="query"
          className="form-control"
          value={value}
          onChange={handleChange}
          placeholder="Search item"
        />
        <span className="input-group-btn">
          <button
            type="button"
            className={`btn ${value ? "btn-primary" : "btn-default"}`}
          >
            <i
              onClick={handleIconClick}
              className={`glyphicon ${
                value ? "glyphicon-remove" : "glyphicon-search"
              }`}
            />
          </button>
        </span>
      </div>
    </div>
  );
});

Search.displayName = "Search";

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Search;
