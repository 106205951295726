import React, { memo } from "react";

import { ComposableTabs, Tab } from "pattern-library";

import SVColumnsForm from "./SVColumnsForm";
import SVConfigEditor from "./SVConfigEditor";

const SVPresetTabs = memo(props => (
  <ComposableTabs navStyle={false}>
    <Tab name="Filters">
      <SVConfigEditor {...props} />
    </Tab>
    <Tab name="Columns">
      <SVColumnsForm {...props} />
    </Tab>
  </ComposableTabs>
));

SVPresetTabs.displayName = "SVPresetTabs";

export default SVPresetTabs;
