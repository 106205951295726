import { path } from "ramda";
import { call, put, takeLatest } from "redux-saga/effects";

import { error } from "modules/messages/actions";
import { fetchCamelizeData } from "modules/utils/fetchData";

import { actionType, fetchTrackConfig } from "./actions";

export function* init() {
  yield takeLatest(actionType.FETCH_TRACK_CONFIG_START, handleTrackConfig);
}

function* handleTrackConfig({ payload: patientId }) {
  try {
    const result = yield call(
      fetchCamelizeData,
      `/patient/${patientId}/variants/curated_variant/track_config`
    );
    if (result.ok) {
      yield put(fetchTrackConfig.success(path(["payload"], result) || []));
    } else {
      yield put(fetchTrackConfig.failure());
    }
  } catch (e) {
    yield put(fetchTrackConfig.failure());
    yield put(error(e));
  }
}
