import React, { PureComponent } from "react";

export default class VariantColumn extends PureComponent {
  render() {
    const {
      variant: {
        snv: { chr, start, refAllele, altAllele },
      },
    } = this.props;

    const end = start + refAllele.length - 1;

    return (
      <div className="variant-column variant-column--variant variant">
        <div className="transcript-row location-row">
          <p className="transcript-column transcript-chr">{chr}</p>
          <div className="transcript-column">
            <p className="transcript-position">{start.toLocaleString("en")}</p>
            <p className="transcript-position">{end.toLocaleString("en")}</p>
          </div>
        </div>
        <div className="transcript-row">
          <p className="transcript-allele">
            {refAllele} &gt; {altAllele}
          </p>
        </div>
      </div>
    );
  }
}
