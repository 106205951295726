import { Progress, UploaderProps } from "../types";

export abstract class BaseUploader {
  file: File;
  onStart: () => void;
  onProgress: (progress: Progress) => void;
  onError: (error: Error) => void;
  onSuccess: () => void;

  protected constructor({
    file,
    onStart,
    onProgress,
    onError,
    onSuccess,
  }: UploaderProps) {
    if (!file) throw new Error("File is not selected!");

    this.file = file;

    this.onStart = onStart
      ? onStart
      : () => console.log(`Start uploading file ${file.name}`);
    this.onProgress = onProgress
      ? onProgress
      : (progress: Progress) => console.log(JSON.stringify(progress, null, 2));
    this.onError = onError
      ? onError
      : (error: Error) => console.log(error.message);
    this.onSuccess = onSuccess
      ? onSuccess
      : () => console.log(`File ${file.name} successfully uploaded`);
  }

  abstract start(): Promise<any>;
}
