import React, { memo, useState, useCallback } from "react";
import { connect } from "react-redux";

import * as actions from "../../../hpoTerms/actions";
import SearchBar from "../SearchBar";

const HPOTermsSearchBar = memo(({ setQuery, filterHpoTerms }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = useCallback(
    e => {
      e.preventDefault();
      setQuery(searchTerm);
      filterHpoTerms();
    },
    [searchTerm, setQuery, filterHpoTerms]
  );

  return (
    <SearchBar
      setSearchTerm={setSearchTerm}
      onSearch={onSearch}
      id="hpoTermsSearchTerm"
      placeholder="Enter a search term. At least 2 symbols"
    />
  );
});

const mapDispatchToProps = {
  filterHpoTerms: actions.filterHpoTerms,
  setQuery: actions.setQuery,
};

export default connect(null, mapDispatchToProps)(HPOTermsSearchBar);
