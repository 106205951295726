export const NAMESPACE = "projectInterpretationRequests" as const;

export const IR_DELETE_FORM = "IR_DELETE_FORM" as const;

export const ALREADY_DELETED = {
  canDelete: false,
  msg: "Request has already been deleted",
};
export const ROW_STATUSES = {
  FINISHED_PROCESSING: "success",
  READY_TO_PROCESS: "info",
  PROCESSING_STALLED: "warning",
  PROCESSING: "info",
  DELETED: "",
  ERROR: "danger",
  VALIDATION_FAILED: "danger",
  BULK_PATIENT_UPLOAD_COMPLETE: "",
};

export const TRANSFER_STATUS_MAP = {
  PROMISED_NAMELESS: "Awaiting Submission",
  FAILED_VALIDATION: "Failed Validation",
};

export const DATA_FILES_STATUS_INTERVAL = 5000;

export const STATUSES = {
  PROMISED: "Promised",
  PROMISED_NAMELESS: "Promised_Nameless",
  FAILED_VALIDATION: "Failed_Validation",
  UPLOADED: "Uploaded",
  PROCESS: "PROCESS",
  DELETED: "DELETED",
};

export const FILE_STATUSES_STYLE = {
  UPLOADED: "success",
  FAILED_VALIDATION: "danger",
  PROCESS: "info",
};
