import Genoverse from "genoverse";

const formatConsequence = consequenceType => {
  const consequense = consequenceType.replace(/_/g, " ");
  return consequense.charAt(0).toUpperCase() + consequense.slice(1);
};

export const Track = Genoverse.Track.dbSNP.extend({
  namespace: "Congenica.dbSNP",
  category: "Population",
  tags: ["Population", "Common", "dbSNP", "Ensembl"],
  resizeable: true,
  height: 35,
  featureHeight: 15,
  populateMenu(feature) {
    return {
      title: `<a target="_blank" href="https://www.ncbi.nlm.nih.gov/snp/${feature.id}">${feature.id}</a>`,
      Consequence: feature.consequence,
      "Alt alleles": feature.alleles.join(", "),
      Location: `${feature.chr}:${feature.start}-${feature.end}`,
    };
  },
  insertFeature(feature) {
    feature.chr = feature.seq_region_name;
    feature.consequence = formatConsequence(feature.consequence_type);
    const colorProps = this.prop("colorMap")[feature.legend];
    this.base({
      ...feature,
      ...colorProps,
    });
  },

  // Override default dbSNP track settings
  5000: {
    // more than 5k
    bump: true,
  },
  1: {
    // > 1 base-pair, but less then 5k
    bump: true,
  },
});
