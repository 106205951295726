import { isNil, flatten } from "ramda";
import { takeLatest, takeEvery, select, put, call } from "redux-saga/effects";

import { createFormTypeString } from "../forms/utils";
import { info } from "../messages/actions";

import * as actions from "./actions";
import * as constants from "./constants";
import * as selectors from "./selectors";

export function* init() {
  yield takeLatest(
    [constants.FILTER_HPO_TERMS, createFormTypeString(constants.NAME)],
    updateVisibilityFlag
  );
  yield takeEvery(constants.TRY_ADD_HPO_TERM, tryAddHpoTerm);
  yield takeLatest(constants.START_INIT, initialise);
}

export function* initialise(action) {
  const response = yield call(
    fetch,
    `/catalyst/api/project/${action.meta.projectId}/hpo/terms-and-modifiers`,
    { credentials: "include" }
  );
  const result = yield call([response, response.json]);

  if (result.data) {
    let formattedData = result.data.map(
      ({ child_id, parent_id, name, acc }) => ({
        hpoTermId: acc,
        termId: child_id,
        name,
        parentId: child_id !== constants.TOP_LEVEL_TERM ? parent_id : undefined,
      })
    );

    formattedData = formattedData.map(item => ({
      ...item,
      isParent: !isNil(
        formattedData.find(child => child.parentId === item.termId)
      ),
    }));

    yield put(actions.setAllHpoTerms(formattedData));
    yield put(actions.hasLoaded());
  } else {
    yield put(actions.hpoTermsLoadFailed(result));
  }
}

export function* updateVisibilityFlag() {
  const matchingChildIds = yield select(selectors.getMatchingChildIds);
  yield put(actions.setVisibleNodeIds(matchingChildIds));

  const nested = matchingChildIds.map(nodeId =>
    nodeId.split("/").map((c, i, a) => a.slice(0, i + 1).join("/"))
  );

  const nodeIdsToExpand = [...new Set(flatten(nested))].sort();
  yield put(actions.setExpandedNodeIds(nodeIdsToExpand));
}

export function* tryAddHpoTerm(action) {
  // Access store/state here
  const selectedHpoTerms = yield select(selectors.selectedHPOTermsSelector);

  // Test for duplication
  if (selectedHpoTerms.some(term => term.code === action.payload.code)) {
    // Duplicate, tell user
    yield put(info(`This HPO Term (${action.payload.code}) was already added`));
  } else {
    // No duplicate, add
    yield put(
      actions.addHpoTerm({
        hpoTermId: action.payload.code,
        name: action.payload.phenotype,
      })
    );
  }
}
