// @flow
import { isNil } from "ramda";

/*
 * We specifically have to use ! instead of R.not here as flow doesn't read it
 * properly and doesn't refine the type
 */
export default function notIsNil(expr: mixed): boolean %checks {
  return !isNil(expr);
}
