import { Colors } from "./colors";

export const labelStyle = {
  position: "absolute",
  "font-size": "11px",
  right: "2px",
  "font-family": "Verdana",
  "text-shadow": `0 1px 0 ${Colors.LABEL_SHADOW}`,
  "font-weight": "bold",
};
