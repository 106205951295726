import { call, put, takeEvery, fork } from "redux-saga/effects";

import * as snvAPI from "modules/api/snvs";
import { error } from "modules/messages/actions";

import { actionType, fetchAriadneBreakdownData } from "./actions";

const tasksByRequestId = {};

// $FlowFixMe
export function* init() {
  // $FlowFixMe
  yield takeEvery(
    actionType.FETCH_ARIADNE_BREAKDOWN_DATA_START,
    watchGetAriadneBreakdownData
  );
}

/*
 * It cancels only requests with same requestId and running at the same time.
 * Needed to remove request data build up
 */
function* watchGetAriadneBreakdownData(params) {
  const { requestId } = params.payload;
  if (tasksByRequestId[requestId] && tasksByRequestId[requestId].isRunning()) {
    tasksByRequestId[requestId].cancel();
  }
  tasksByRequestId[requestId] = yield fork(
    handleFetchAriadneBreakdownData,
    params
  );
}

export function* handleFetchAriadneBreakdownData({
  payload: { requestId, patientId, patientSNVId, transcriptId },
}) {
  try {
    const result = yield call(
      snvAPI.fetchVariantAnnotationData,
      patientId,
      patientSNVId,
      transcriptId,
      "ariadne"
    );
    if (result.ok) {
      const { payload: data } = result;
      yield put(fetchAriadneBreakdownData.success(requestId, data));
      return;
    }

    yield put(fetchAriadneBreakdownData.failure(requestId));
    yield put(error("Coudn't retrieve variant annotations data"));
  } catch ({ message }) {
    yield put(fetchAriadneBreakdownData.failure(requestId));
    yield put(error(message));
  }
}
