// @flow
import { set, toggle } from "dot-prop-immutable";

import { COMMON_ERROR_MESSAGE } from "../../common/constants";

import type { Action } from "./actions";
import * as constants from "./constants";
import type { MessagesPayload } from "./flow-types";

export type Message = {
  message: string,
  level: "danger" | "warning" | "info" | "success",
  dismissed: boolean,
};

export type LevelsMap = {
  success: number,
  info: number,
  danger: number,
  warning: number,
};

export type MessageState = {
  ui: {
    collapsed: boolean,
  },
  data: {
    levels: LevelsMap,
    messages: Array<Message>,
  },
};

export const initialState: MessageState = {
  ui: {
    collapsed: false,
  },
  data: {
    levels: {
      default: 0,
      info: 1,
      success: 2,
      warning: 3,
      danger: 4,
    },
    messages: [],
  },
};

export default function reducer(
  state: MessageState = initialState,
  action: Action
) {
  switch (action.type) {
    case constants.SET_MESSAGE:
      const { message, messageType } = action.payload;
      return {
        ...state,
        ui: { ...state.ui, collapsed: false },
        data: {
          ...state.data,
          messages: [
            ...state.data.messages,
            { message, level: messageType, dismissed: false },
          ],
        },
      };

    case constants.SET_MESSAGES:
      const { messagesList, messageType: level }: MessagesPayload =
        action.payload;
      const res = messagesList.map(({ detail }) => ({
        message: detail,
        level,
        dismissed: false,
      }));
      if (!res.length && level === "danger") {
        res.push({
          message: COMMON_ERROR_MESSAGE,
          level,
          dismissed: false,
        });
      }
      return {
        ...state,
        ui: { ...state.ui, collapsed: false },
        data: {
          ...state.data,
          messages: [...state.data.messages, ...res],
        },
      };

    case constants.DISMISS_MESSAGE:
      return set(state, `data.messages.${action.payload}.dismissed`, true);

    case constants.TOGGLE_COLLAPSE:
      return toggle(state, "ui.collapsed");

    case constants.COLLAPSE:
      return set(state, "ui.collapsed", true);

    case constants.DISMISS_ALL:
      const messages: Array<Message> = state.data.messages.map(
        (message: {
          message: string,
          level: "danger" | "warning" | "info" | "success",
        }): Message => ({
          ...message,
          dismissed: true,
        })
      );

      return set(state, "data.messages", messages);

    default:
      return state;
  }
}
