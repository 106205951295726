import Genoverse from "genoverse";

import { isValidAllele, Colors } from "../../utils";
import {
  SequenceVariantsView,
  Track as SequenceVariants,
} from "../SequenceVariants";
import {
  SequenceVariationModel,
  SequenceVariationView,
} from "../SequenceVariation";

import { click } from "./Patient";

// The code in this file was moved over with minimal changes from the old track
export const populateMenu = (feature, showError) => {
  const popup = {
    title: feature.key,
  };

  // TODO: how to handle this? Should we error if the field order isn't specify or just pass all the feature values through?
  let popupDetails = { ...feature };

  if (feature.info.field_order) {
    const info = feature.info.field_order.reduce((accumulator, item) => {
      if (item in feature.info) {
        accumulator[item] = feature.info[item];
      }

      return accumulator;
    }, {});

    popupDetails = Object.assign(popup, info);
  }

  if (Object.keys(feature.samples).length > 1) {
    // Deal with multiple samples here, we do not support them
    showError(
      "This region has multiple samples at this variation. Genoverse does not support this."
    );
  } else if (Object.keys(feature.samples).length === 1) {
    const sample = Object.entries(feature.samples)[0][1];

    const format = feature.format.reduce((accumulator, item) => {
      if (item in sample) {
        accumulator[item] = sample[item];
      }

      return accumulator;
    }, {});

    popupDetails = Object.assign(popup, format);
  }

  return popupDetails;
};

export const Controller = SequenceVariants.prototype.controller
  .extend()
  .implement(
    Genoverse.Track.Controller.Sequence.extend({
      populateMenu(feature) {
        return this.base(feature, isValidAllele(feature) ? {} : feature);
      },
    })
  );

export const Track = SequenceVariants.extend({
  controller: Controller,
  receiveData(data, chr, start, end) {
    const apiMap = {
      CHROM: "chr",
      POS: "start",
      ALT: "alt_allele",
      REF: "ref_allele",
      // the .id column on a feature is used by genoverse in a dictionary
      // so it is essentially a protected column - never set it.
      ID: "vcf_variant_id",
    };

    if (data.vcf) {
      data = data.vcf.region.map(region => {
        const reducedRegion = Object.entries(region).reduce(
          (accumulator, variant) => {
            // Normalise the key using the map above if we need to
            const [firstEl, secondEl] = variant;
            const key =
              firstEl in apiMap ? apiMap[firstEl] : firstEl.toLowerCase();

            accumulator[key] = secondEl;

            return accumulator;
          },
          {}
        );

        const parsedStart = parseInt(reducedRegion.start, 10);
        reducedRegion.start = parsedStart;
        const [firstAltAllele] = reducedRegion.alt_allele;
        reducedRegion.alt_allele = firstAltAllele;

        reducedRegion.end =
          parsedStart +
          Math.max(
            reducedRegion.alt_allele.length,
            reducedRegion.ref_allele.length
          ) -
          1;

        return reducedRegion;
      });
    }
    this.base(data, chr, start, end);
  },

  insertFeature(feature) {
    const genotypeValues = Object.entries(feature.samples)[0][1].GT.split("/");
    let genotype = "";

    if (
      genotypeValues[0] !== "-" &&
      genotypeValues[1] !== "-" &&
      (genotypeValues[0] !== "0" || genotypeValues[1] !== "0")
    ) {
      if (genotypeValues[0] === genotypeValues[1]) {
        genotype = "Homozygous";
      } else {
        genotype = "Heterozygous";
      }
    }

    this.base({
      ...feature,
      sequence: feature.alt_allele,
      snvId: this.prop("snvId"),
      outlineColor: Colors.BLACK,
      color: Colors.BLACK,
      genoTypeColor: Colors.BLUE,
      legendColor: Colors.WHITE,
      genotype,
    });
  },

  click,

  populateMenu(feature) {
    return populateMenu(feature, this.track.controller.showError);
  },

  1: {
    featureHeight: 15,
    model: SequenceVariationModel.extend({}),
    view: SequenceVariationView.extend({ showLegend: true }),
  },
  1000: {
    model: Genoverse.Track.Model,
    view: SequenceVariantsView.extend({ showLegend: true }),
  },
});
