import { NavData } from "./Header.types";

const defaultLinkData: NavData = {
  admin: {
    action: "/catalyst/admin",
    icon: "eye-open",
    label: "Admin Page",
  },
  // TODO: Implement login modal natively in React app.
  login: {
    action: "/login",
    icon: "log-in",
    label: "Login",
  },
  logout: {
    action: "/webapi/logout",
    icon: "log-out",
    label: "Sign out",
  },
  profile: {
    action: null,
    label: "Profile",
  },
  projects: {
    action: "/projects",
    label: "My Projects",
  },
};

export default defaultLinkData;
