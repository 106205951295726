import PropTypes from "prop-types";
import React, { memo } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "pattern-library";

const ConfirmationModal = memo(
  ({
    confirmationText,
    confirmationTitle = "Confirmation",
    closeClickHandler,
    isDeleteModal = false, // This prop makes confirm button "danger" type. And flips button places so that confirm is to the right side
    isWarningModal = false, // This prop makes confirm button "warning" type. And flips button places so that confirm is to the right side
    showConfirmationModal = false,
    yesClickHandler,
    closeText = "No",
    confirmText = "Yes",
  }) => {
    const getContext = () => {
      if (isDeleteModal) return "danger";
      if (isWarningModal) return "warning";
      return "primary";
    };

    const confirmButton = (
      <Button
        key="confirmButton"
        type="button"
        context={getContext()}
        className="button"
        onClick={yesClickHandler}
      >
        {confirmText}
      </Button>
    );

    const cancelButton = (
      <Button
        type="button"
        className="button"
        onClick={closeClickHandler}
        key="cancelButton"
      >
        {closeText}
      </Button>
    );

    return (
      <Modal show={showConfirmationModal} close={closeClickHandler}>
        <ModalHeader close={closeClickHandler}>{confirmationTitle}</ModalHeader>
        <ModalBody>{confirmationText}</ModalBody>
        <ModalFooter>
          {isDeleteModal || isWarningModal
            ? [cancelButton, confirmButton]
            : [confirmButton, cancelButton]}
        </ModalFooter>
      </Modal>
    );
  }
);

ConfirmationModal.displayName = "ConfirmationModal";

ConfirmationModal.propTypes = {
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  confirmationTitle: PropTypes.string,
  isDeleteModal: PropTypes.bool,
  showConfirmationModal: PropTypes.bool,
  yesClickHandler: PropTypes.func.isRequired,
  closeClickHandler: PropTypes.func.isRequired,
  closeText: PropTypes.string,
  confirmText: PropTypes.string,
};

export default ConfirmationModal;
