import { camelizeKeys } from "humps";
import { call, put, takeLatest } from "redux-saga/effects";

import { error, success } from "modules/messages/actions";
import { getOrReloadCurrentProject } from "modules/project/saga";
import { processErrors } from "modules/utils";
import {
  API_ENTITIES_BASE_URL,
  CATALYST_BASE_URL,
} from "modules/utils/baseUrls";

import { getPatientsVisibleMetadataFields } from "../api/patientMetadata";
import { processError } from "../utils/sagas";

import * as actions from "./actions";
import * as constants from "./constants";

export function* init() {
  yield takeLatest(constants.INIT, initialise);
  yield takeLatest(constants.RESET_PATIENT_STATUS, resetPatientStatus);
  yield takeLatest(constants.LOAD_REPORTS, loadReports);
}

export function* initialise({ payload: projectId }) {
  try {
    yield call(getOrReloadCurrentProject, projectId);

    const patientsMetadataResponse = yield call(
      getPatientsVisibleMetadataFields,
      projectId
    );
    const { ok, payload } = patientsMetadataResponse;
    let patientsMetadata = [];
    if (ok) {
      patientsMetadata = payload.map(({ id, fieldName, fieldType }) => ({
        id,
        name: fieldName,
        type: fieldType,
      }));
    } else {
      yield call(
        processError,
        patientsMetadataResponse,
        "Cannot load patients metadata"
      );
    }
    const response = yield call(
      fetch,
      `${CATALYST_BASE_URL}/project/${projectId}/patients`,
      { credentials: "include" }
    );
    const result = yield call([response, response.json]);
    if (result.errors) {
      yield put(error(`Cannot load patients:${processErrors(result.errors)}`));
    } else {
      const projectData = {
        ...camelizeKeys(result),
        metadata: patientsMetadata,
      };
      yield put(actions.setProjectData(projectData));
    }
  } catch (e) {
    yield put(error(e));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* resetPatientStatus({ payload: { patientId, projectId } }) {
  const response = yield call(fetch, `/patient/${patientId}/reset/status`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
  });

  if (response.ok) {
    yield put(actions.initialise(projectId));
    yield put(success("Patient status reset"));
  } else {
    yield put(error("Can not reset patient status"));
  }
}

export function* loadReports({ payload: patientId }) {
  try {
    const response = yield call(
      fetch,
      `${API_ENTITIES_BASE_URL}/patients/${patientId}/reports?include=report_service_template`,
      {
        credentials: "include",
      }
    );
    const result = yield call([response, response.json]);
    if (result.errors) {
      yield put(error(`Cannot load reports:${processErrors(result.errors)}`));
    } else {
      const { data, included } = camelizeKeys(result);
      yield put(actions.setReports({ patientId, data, included }));
    }
  } catch (e) {
    yield put(error(e));
  } finally {
    yield put(actions.setLoading(false));
  }
}
