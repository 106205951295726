import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";

import Checkbox from "../Checkbox";

import { getChecked, getCheckboxesValue } from "./utils";

/**
 * a controlled component representing a set of checkboxes
 * WARNING: intended use is Formik forms. There are no explicit dependencieds on formik
 * but the component has been tested and applied only in FormikFormField
 */
const Checkboxes = memo(
  ({ onChange, onBlur, options = [], value, disabled = false, className }) => {
    const handleCheckboxOnChange = useCallback(
      e => {
        onChange(getCheckboxesValue(options, value, e.target.name));
      },
      [options, value, onChange]
    );

    const handleCheckboxOnBlur = useCallback(() => {
      onBlur();
    }, [onBlur]);

    return (
      <div className="checkboxes">
        {options.map(({ name, label }) => (
          <Checkbox
            className={className}
            disabled={disabled}
            onChange={handleCheckboxOnChange}
            onBlur={handleCheckboxOnBlur}
            key={name}
            name={name}
            label={label}
            checked={getChecked(value, name)}
          />
        ))}
      </div>
    );
  }
);

Checkboxes.displayName = "Checkboxes";

Checkboxes.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default Checkboxes;
