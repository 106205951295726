import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";
import Select from "react-select";

import { Option } from "./Option";
import { SingleValue } from "./SingleValue";

export class Cases extends PureComponent {
  static displayName = "Cases";

  static propTypes = {
    /**
     * Selected case
     */
    selectedCase: PropTypes.string,

    /**
     * change the text displayed when no option is selected
     */
    placeholder: PropTypes.string,

    /**
     *  specify the options the user can select from
     */
    cases: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * value of a case
         */
        value: PropTypes.string.isRequired,
        /**
         * Text of a case
         */
        label: PropTypes.string,
        /**
         * The color of a case
         */
        color: PropTypes.string.isRequired,
      })
    ).isRequired,

    /**
     * Function to handle the selection change
     */
    handleChange: PropTypes.func.isRequired,

    /**
     * Disable the control
     */
    isDisabled: PropTypes.bool.isRequired,

    /**
     * Class to apply to the  component
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleChange(selectedOption) {
    const { handleChange } = this.props;
    handleChange(selectedOption.value);
  }

  render() {
    const { cases, selectedCase, placeholder, className, isDisabled } =
      this.props;

    const value = cases.find(item => item.value === selectedCase);
    return (
      <Select
        isDisabled={isDisabled}
        isClearable={false}
        placeholder={` - ${placeholder} - `}
        components={{ Option, SingleValue }}
        className={classNames("decision-cases", className)}
        options={cases}
        onChange={this.handleChange}
        value={isNil(value) ? null : value}
      />
    );
  }
}
