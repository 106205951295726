import classNames from "classnames";
import React, { memo, useMemo } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { InfoList, Radio } from "pattern-library";

import { getGeneOptions } from "modules/api/genes";
import ReduxFormField from "modules/forms/components/ReduxFormField";
import { normaliseGenomicRegion } from "modules/forms/rules";
import { getFormValues } from "modules/forms/selectors";
import { CheckboxOption } from "modules/utils/prop-types/CheckboxOptionProp";

import * as selectors from "../../selectors";

import GenePanels from "./GenePanels";

import { useEnsemblVersion } from "hooks/useEnsemblVersion";

type GenesLocationFormProps = {
  disabled?: boolean;
  patientId?: number | null;
  change: any;
  genePanels: (CheckboxOption & { ensemblVersion?: string })[];
  location: "genes" | "coords" | "panels";
  coords: string;
  ensemblVersion?: string;
};

/**
 * This function validates the genomic region coordinates and prevents the form
 * submitting if invalid coordinates present (i.e. user clicks out of the modal)
 * Undefined must be returned for valid entries as per the redux-form docs here:
 * https://redux-form.com/8.3.0/examples/fieldlevelvalidation
 * @param {string} val The string value to validate
 * @returns true if invalid, undefined if valid
 */
export const validateCoords = (val: string) =>
  val && !/^(chr)(X|Y|MT|[1-9]|1[0-9]|2[0-2]):\d+-\d+$/i.test(val)
    ? true
    : undefined;

/**
 * Takes the normalised genomic coordinates and checks for any missing
 * components. Normalisation step prevents invalid chromosomes.
 * Note: These regexes are not for validating the coordinates and are
 * intentionally different.
 * @returns An array of rules with pass/fail status
 */
export const generateRuleStates = (inputCoords: string) => {
  const rules: Record<string, (arg: string) => boolean> = {
    "Starts with chr": coords => coords.startsWith("chr"),
    "The selected chromosome is valid, 1-22, X, Y or MT": coords =>
      /chr[1-9|X|Y|MT]{1,2}.*/.test(coords),
    "There is a colon after the chromosome": coords =>
      /chr[0-9|X|Y|MT]{1,2}:.*/.test(coords),
    "A start region has been defined": coords =>
      /chr[0-9|X|Y|MT]{1,2}:[0-9]+.*/.test(coords),
    "A dash separates the start and end region": coords =>
      /chr[0-9|X|Y|MT]{1,2}:[0-9]+-.*/.test(coords),
    "An end region has been defined": coords =>
      /chr[0-9|X|Y|MT]{1,2}:[0-9]+-[0-9]+/.test(coords),
  };

  return Object.entries(rules).map(([text, rule]) => {
    const isRuleValid = rule(inputCoords);
    return {
      text,
      icon: isRuleValid ? "tick" : "cross",
      className: classNames(
        { "icon--success": isRuleValid },
        { "icon--fail": !isRuleValid }
      ),
    };
  });
};

const getRadioButton = (
  isViewMode: boolean,
  value: string,
  label: string | JSX.Element,
  checked: boolean
) => {
  // config view mode and edit mode coexist in the DOM
  // we have to set different radio groups names for the same fields in each instance of the config.
  // it's not possible to have multiple radiobuttons checked if they have the same name
  // even if they are in separate DOM trees (form, fieldset, etc.)
  if (isViewMode) {
    return (
      <Radio
        key={`radio-${value}`}
        value="panels"
        name="locationView"
        label={label}
        readOnly
        checked={checked}
      />
    );
  }

  return (
    <Field
      name="location"
      minimal
      component={ReduxFormField}
      type="radio"
      value={value}
      boxLabel={label}
    />
  );
};

export const GenesLocationForm: React.FC<GenesLocationFormProps> = ({
  disabled = false,
  patientId,
  change,
  genePanels,
  location,
  coords = "",
  ensemblVersion,
}) => {
  const filteredGenePanels = useMemo(
    () =>
      genePanels.filter(panel =>
        // Only filter the panels if there is an ensembl_version property on them
        // (so this works with and without the API changes)
        panel.ensemblVersion ? panel.ensemblVersion === ensemblVersion : true
      ),
    [genePanels, ensemblVersion]
  );

  const showGenePanels = Boolean(patientId);

  const specificGenesLabel = (
    <>
      Specific Genes
      {ensemblVersion && <small> (Ensembl release {ensemblVersion})</small>}
    </>
  );

  return (
    <fieldset disabled={disabled}>
      <h5>Only show variants in:</h5>
      {getRadioButton(
        disabled,
        "genes",
        specificGenesLabel,
        location === "genes"
      )}
      {location === "genes" && (
        <Field
          component={ReduxFormField}
          type="select"
          async
          noResultsText="No genes found"
          key="no-genes-found-field"
          name="genes"
          placeholder="Type to search genes..."
          loadOptions={getGeneOptions(ensemblVersion)}
        />
      )}

      {getRadioButton(
        disabled,
        "coords",
        "Genomic Region",
        location === "coords"
      )}
      {location === "coords" && [
        <Field
          key="coords"
          normalize={normaliseGenomicRegion}
          component={ReduxFormField}
          type="text"
          name="coords"
          validate={validateCoords}
          hideErrorMessage
        />,
        <p key="coords-help-block" className="help-block">
          eg. chr11:450000-1200000
        </p>,
        <InfoList key="info-coords" data={generateRuleStates(coords)} />,
      ]}

      {getRadioButton(disabled, "panels", "Gene Panels", location === "panels")}
      {location === "panels" && showGenePanels && (
        <GenePanels change={change} genePanels={filteredGenePanels} />
      )}
      {location === "panels" && !showGenePanels && (
        <div>
          <p>
            <em>
              Gene panels can be assigned to individual patients either on the
              individual&apos;s <strong>Patient Overview</strong> tab or via the
              gene panel&apos;s <strong>Patients</strong> tab.
            </em>
          </p>
        </div>
      )}
    </fieldset>
  );
};

/**
 * Connected version of GenesLocationForm with RTK Query calls and to abstract away Ensembl version logic
 */
const ConnectedGenesLocationForm: React.FC<GenesLocationFormProps> = props => {
  const { patientId } = props;
  const { ensemblVersion } = useEnsemblVersion(patientId);

  return <GenesLocationForm {...props} ensemblVersion={ensemblVersion} />;
};

const mapStateToProps = (state, { form, patientId }) => ({
  genePanels: selectors.getGenePanelsCheckBoxItems(state, patientId),
  coords: getFormValues(state, form, "coords"),
  location: getFormValues(state, form, "location"),
});

export default connect(mapStateToProps, null)(memo(ConnectedGenesLocationForm));
