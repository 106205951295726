import classNames from "classnames";
import { isNil, sort } from "ramda";
import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";

import { Dropdown, Label, LoadingOverlay } from "pattern-library";

import { warning } from "modules/messages/actions";
import { RoleAccessibleComponent } from "modules/utils/security/RoleAccessibleComponent";

import PDFReport, { reportParams } from "./PDFReport";
import ReportProposalEditor from "./ReportProposalEditor";
import Actions from "./actions/Actions";
import { JurisdictionSelector } from "./components/JurisdictionSelector";
import {
  ACTION_FAIL_QC,
  ACTION_FAIL_SAMPLE,
  ACTION_PASS_QC,
  ACTION_UNDO_QC,
  REVIEW_COMPLETE,
} from "./constants";
import { useOncologyReportData } from "./useOncologyReportData";

import catalystApi from "api/catalyst-api";
import congenicaApi from "api/congenica-api";

export const Context = createContext<any>("OncologyReport");

const { useGetJurisdictionsQuery } = catalystApi;
const { useSetReportProposalMutation } = congenicaApi;
interface Props {
  projectId: number;
  patientId: number;
}

export const Container: FC<Props> = ({ projectId, patientId }) => {
  const dispatch = useDispatch();

  const [templateUuid, setUuidTemplate] = useState<string | null>(null);

  const [selectedJurisdiction, setSelectedJurisdiction] = useState<
    string | null
  >(null);

  const [data, isDataLoading] = useOncologyReportData(patientId, projectId);

  const {
    reports,
    templates,
    patientStatus: { status },
    latestReportProposal: {
      reportServiceTemplateUuid,
      reportSummary,
      jurisdiction,
    },
  } = data;

  const sortedTemplates = useMemo(
    () => sort((a, b) => b.updated - a.updated, templates),
    [templates]
  );

  const [setReportProposal, { isLoading: isSetReportProposalLoading }] =
    useSetReportProposalMutation();

  const isLoading = useMemo(
    () => isDataLoading || isSetReportProposalLoading,
    [isDataLoading, isSetReportProposalLoading]
  );

  const { jurisdictions } = useGetJurisdictionsQuery(
    { projectId },
    {
      selectFromResult: ({ data }) => ({
        jurisdictions: data ?? [],
      }),
    }
  );

  const defaultJurisdiction = useMemo(
    () => jurisdictions.find(({ projectDefault }) => projectDefault),
    [jurisdictions]
  );

  const { id: reportId } = useMemo(
    () =>
      reports?.length
        ? //we use sorting because should use the latest report
          [...reports].sort(
            (a, b) => b.attributes.updated - a.attributes.updated
          )[0]
        : ({} as PatientReport),
    [reports]
  );

  useEffect(() => {
    if (jurisdiction) {
      setSelectedJurisdiction(jurisdiction);
    } else {
      setSelectedJurisdiction(
        defaultJurisdiction ? defaultJurisdiction.jurisdiction : "EU"
      );
    }
  }, [jurisdiction, defaultJurisdiction]);

  useEffect(() => {
    if (reportServiceTemplateUuid) {
      if (sortedTemplates && sortedTemplates.length) {
        if (
          sortedTemplates.find(
            ({ templateUuid }) => templateUuid === reportServiceTemplateUuid
          )
        ) {
          setUuidTemplate(reportServiceTemplateUuid);
        } else {
          dispatch(
            warning(
              `Report template with id "${reportServiceTemplateUuid}" doesn't exist. First from list will be used.\nClick "Save" if you want to link the selected template to report`
            )
          );
          setUuidTemplate(sortedTemplates[0].templateUuid);
        }
      } else {
        setUuidTemplate(reportServiceTemplateUuid);
      }
    } else {
      setUuidTemplate(
        sortedTemplates && sortedTemplates.length > 0
          ? sortedTemplates[0].templateUuid
          : null
      );
    }
  }, [sortedTemplates, reportServiceTemplateUuid, dispatch]);

  const reportTemplatesOptions = useMemo(
    () =>
      sortedTemplates.map(({ templateName, templateUuid }) => ({
        value: templateUuid,
        label: templateName,
      })),
    [sortedTemplates]
  );

  const onChangeReportTemplate = useCallback(
    ({ currentTarget: { value } }) => {
      setReportProposal({
        patientId,
        reportServiceTemplateUuid: value,
        reportSummary: reportSummary || "",
        jurisdiction: jurisdiction || defaultJurisdiction?.jurisdiction,
      });
    },
    [
      defaultJurisdiction?.jurisdiction,
      jurisdiction,
      patientId,
      reportSummary,
      setReportProposal,
    ]
  );

  const onChangeJurisdiction = useCallback(
    (newJurisdiction: string) => {
      if (templateUuid)
        setReportProposal({
          patientId,
          reportServiceTemplateUuid: templateUuid,
          reportSummary: reportSummary || "",
          jurisdiction: newJurisdiction,
        });
    },
    [patientId, reportSummary, setReportProposal, templateUuid]
  );

  useEffect(() => {
    if (!isNil(window.Sapientia))
      window.Sapientia.getContent("#patient-title-status");
  }, [status]);

  return (
    <LoadingOverlay data-testid="loading" loading={isLoading}>
      <Context.Provider value={{ projectId, patientId }}>
        <div
          data-testid="oncology-report-container"
          className="container-fluid"
        >
          <div className="row">
            <div className="col-md-6 oncology-report__controls">
              <form
                data-testid="report-templates-form"
                className="form-horizontal"
              >
                {status && status !== REVIEW_COMPLETE && (
                  <div className="row">
                    <Label
                      htmlFor="reportTemplate"
                      className={classNames("control-label", "col-sm-3")}
                    >
                      Report Template
                    </Label>
                    <div className="col-sm-9">
                      <RoleAccessibleComponent
                        disabledOnNoRole
                        permittedRoles={["createReportSummary"]}
                        projectId={projectId}
                      >
                        <Dropdown
                          name="reportTemplate"
                          data-testid="reportTemplate"
                          value={templateUuid}
                          emptyPlaceholder="Select a report template"
                          onChange={onChangeReportTemplate}
                          options={reportTemplatesOptions}
                        />
                      </RoleAccessibleComponent>

                      <small className="text-muted">
                        Select from available templates associated with the
                        project
                      </small>
                    </div>
                  </div>
                )}
                <JurisdictionSelector
                  jurisdiction={selectedJurisdiction}
                  onChange={onChangeJurisdiction}
                  projectId={projectId}
                  disabled={status === REVIEW_COMPLETE || !templateUuid}
                />
              </form>
              <div className="row">
                <ReportProposalEditor
                  className="col-md-12"
                  templateUuid={templateUuid}
                  selectedJurisdiction={selectedJurisdiction}
                  projectId={projectId}
                />
              </div>

              <Actions
                patientId={patientId}
                reportId={reportId}
                skip={[
                  ACTION_FAIL_QC,
                  ACTION_PASS_QC,
                  ACTION_UNDO_QC,
                  ACTION_FAIL_SAMPLE,
                ]}
              />
            </div>
            <div className="oncology-report__report col-md-6">
              {status && (
                <PDFReport
                  status={status}
                  patientId={patientId}
                  reportId={reportId}
                  {...reportParams(status)}
                />
              )}
            </div>
          </div>
        </div>
      </Context.Provider>
    </LoadingOverlay>
  );
};

export default Container;
