// @flow
import { createAction } from "../utils";

import * as constants from "./constants";
import type { AcmgSuggestion } from "./flow-types";

type LockAction = FSA<
  typeof constants.ACMG_LOCK,
  {},
  {
    variantId: number,
    patientId: number,
    transcriptId: number,
  }
>;

export const lock = ({
  variantId,
  patientId,
  transcriptId,
}: {
  variantId: number,
  patientId: number,
  transcriptId: number,
}): LockAction =>
  createAction(
    constants.ACMG_LOCK,
    {},
    {
      variantId,
      patientId,
      transcriptId,
    }
  );

type LockSuccessAction = FSA<typeof constants.ACMG_LOCK_SUCCESS>;

export const lockSucceeded = (): LockSuccessAction =>
  createAction(constants.ACMG_LOCK_SUCCESS);

type LockOrUnlockFailedAction = FSA<
  typeof constants.ACMG_LOCK_OR_UNLOCK_FAILED
>;

export const lockOrUnlockFailed = (
  payload: {},
  meta: {}
): LockOrUnlockFailedAction =>
  createAction(constants.ACMG_LOCK_OR_UNLOCK_FAILED, payload, meta);

type UnlockAction = FSA<typeof constants.ACMG_UNLOCK>;

export const unlock = (options: {}): UnlockAction =>
  createAction(constants.ACMG_UNLOCK, {}, options);

type UnlockSuccessAction = FSA<typeof constants.ACMG_UNLOCK_SUCCESS>;

export const unlockSucceeded = (): UnlockSuccessAction =>
  createAction(constants.ACMG_UNLOCK_SUCCESS);

type MarkAsNoEvidenceAction = FSA<
  typeof constants.ACMG_NO_EVIDENCE_FOR_CATEGORY
>;

export const markAsNoEvidence = (options: {}): MarkAsNoEvidenceAction =>
  createAction(constants.ACMG_NO_EVIDENCE_FOR_CATEGORY, {}, options);

type ToggleOverlayAction = FSA<typeof constants.ACMG_TOGGLE_OVERLAY>;

export const toggleOverlay = (): ToggleOverlayAction =>
  createAction(constants.ACMG_TOGGLE_OVERLAY);

type ShowWarningsModalAction = FSA<typeof constants.ACMG_SHOW_WARNINGS_MODAL>;

export const showConfirmationModal = (): ShowWarningsModalAction =>
  createAction(constants.ACMG_SHOW_WARNINGS_MODAL);

type HideWarningsModalAction = FSA<typeof constants.ACMG_HIDE_WARNINGS_MODAL>;

export const hideConfirmationModal = (): HideWarningsModalAction =>
  createAction(constants.ACMG_HIDE_WARNINGS_MODAL);

type ResetCriteriaToAddAction = FSA<
  typeof constants.ACMG_RESET_CRITERIA_TO_ADD
>;

export const resetCriteriaToAdd = () =>
  createAction(constants.ACMG_RESET_CRITERIA_TO_ADD);

type UpdateCriteriaToAddAction = FSA<
  typeof constants.ACMG_PREPARE_TO_ADD_CRITERIA,
  number
>;

export const updateCriteriaToAdd = (id: number): UpdateCriteriaToAddAction =>
  createAction(constants.ACMG_PREPARE_TO_ADD_CRITERIA, id);

type SetCanLockAction = FSA<typeof constants.ACMG_SET_CAN_LOCK, boolean>;

export const setCanLock = (canLock: boolean): SetCanLockAction =>
  createAction(constants.ACMG_SET_CAN_LOCK, canLock);

type SetDecisionAlreadySetAgainstAnotherTranscriptAction = FSA<
  typeof constants.ACMG_IS_DECISION_ALREADY_SET_AGAINST_ANOTHER_TRANSCRIPT,
  boolean
>;

export const setDecisionAlreadySetAgainstAnotherTranscript = (
  isDecisionAlreadySet: boolean
): SetDecisionAlreadySetAgainstAnotherTranscriptAction =>
  createAction(
    constants.ACMG_IS_DECISION_ALREADY_SET_AGAINST_ANOTHER_TRANSCRIPT,
    isDecisionAlreadySet
  );

type SetClassificationLoadingAction = FSA<
  typeof constants.ACMG_LOADING_CLASSIFICATION,
  boolean
>;

export const setClassificationLoading = (): SetClassificationLoadingAction =>
  createAction(constants.ACMG_LOADING_CLASSIFICATION);

type SetClassificationLoadedAction = FSA<
  typeof constants.ACMG_LOADED_CLASSIFICATION
>;

export const setClassificationLoaded = (): SetClassificationLoadedAction =>
  createAction(constants.ACMG_LOADED_CLASSIFICATION);

type MarkAsLoadedAction = FSA<
  typeof constants.ACMG_MARK_AS_LOADED,
  PatientVariant
>;

export const markAsLoaded = (
  patientVariant: PatientVariant
): MarkAsLoadedAction =>
  createAction(constants.ACMG_MARK_AS_LOADED, patientVariant);

type UpdateVariantACMGAction = FSA<
  typeof constants.UPDATE_VARIANT_ACMG,
  PatientVariant
>;

export const updateVariantACMG = (
  patientVariant: PatientVariant
): UpdateVariantACMGAction =>
  createAction(constants.UPDATE_VARIANT_ACMG, patientVariant);

type ReadAcmgClassificationListAction = FSA<
  typeof constants.READ_ACMGCLASSIFICATION_LIST
>;

export const readAcmgClassificationList =
  (): ReadAcmgClassificationListAction =>
    createAction(constants.READ_ACMGCLASSIFICATION_LIST);

type CreateAcmgClassificationListAction = FSA<
  typeof constants.CREATE_ACMGCLASSIFICATION_LIST,
  {}
>;

export const createAcmgClassificationList = (
  payload: { list: Array<AcmgClassification> } = { list: [] }
): CreateAcmgClassificationListAction =>
  createAction(constants.CREATE_ACMGCLASSIFICATION_LIST, payload.list);

type ReadAcmgCriteriaListAction = FSA<typeof constants.READ_ACMGCRITERIA_LIST>;

export const readAcmgCriteriaList = (): ReadAcmgCriteriaListAction =>
  createAction(constants.READ_ACMGCRITERIA_LIST);

type CreateAcmgCriteriaListAction = FSA<
  typeof constants.CREATE_ACMGCRITERIA_LIST,
  {}
>;

export const createAcmgCriteriaList = (
  payload: { list: Array<AcmgCriteria> } = { list: [] }
): CreateAcmgCriteriaListAction =>
  createAction(constants.CREATE_ACMGCRITERIA_LIST, payload.list);

type LoadSuggestedAcmgCriteria = FSA<
  typeof constants.LOAD_SUGGESTED_ACMG_CRITERIA,
  PatientVariant
>;

export const loadSuggestedAcmgCriteria = (
  patientId: string,
  variantId: string,
  geneId: string
): LoadSuggestedAcmgCriteria =>
  createAction(constants.LOAD_SUGGESTED_ACMG_CRITERIA, {
    patientId,
    variantId,
    geneId,
  });

type SuggestedAcmgCriteriaLoaded = FSA<
  typeof constants.SUGGESTED_ACMG_CRITERIA_LOADED,
  AcmgSuggestion
>;

export const suggestedAcmgCriteriaLoaded = (acmgSuggestion: AcmgSuggestion) =>
  createAction(constants.SUGGESTED_ACMG_CRITERIA_LOADED, acmgSuggestion);

type AcceptSuggestedAcmgCriteria = FSA<
  typeof constants.USE_SUGGESTED_ACMG_CRITERIA,
  PatientVariant
>;

export const acceptSuggestedAcmgCriteria = (
  patientId: string,
  variantId: string,
  geneId: string,
  selectedCriteria: Array<string>
): AcceptSuggestedAcmgCriteria =>
  createAction(constants.USE_SUGGESTED_ACMG_CRITERIA, {
    patientId,
    variantId,
    geneId,
    selectedCriteria,
  });

type RejectSuggestedAcmgCriteria = FSA<
  typeof constants.REJECT_SUGGESTED_ACMG_CRITERIA,
  PatientVariant
>;

export const rejectSuggestedAcmgCriteria = (
  patientId: string,
  variantId: string,
  geneId: string
): RejectSuggestedAcmgCriteria =>
  createAction(constants.REJECT_SUGGESTED_ACMG_CRITERIA, {
    patientId,
    variantId,
    geneId,
  });

type SuggestedAcmgCriteriaProcessing = FSA<
  typeof constants.SUGGESTED_ACMG_CRITERIA_PROCESSING,
  boolean
>;

export const suggestedAcmgCriteriaProcessing = (
  processing: boolean
): SuggestedAcmgCriteriaProcessing =>
  createAction(constants.SUGGESTED_ACMG_CRITERIA_PROCESSING, processing);

export type Action =
  | LockAction
  | LockSuccessAction
  | LockOrUnlockFailedAction
  | UnlockAction
  | UnlockSuccessAction
  | MarkAsNoEvidenceAction
  | ToggleOverlayAction
  | ShowWarningsModalAction
  | HideWarningsModalAction
  | ResetCriteriaToAddAction
  | UpdateCriteriaToAddAction
  | SetCanLockAction
  | SetDecisionAlreadySetAgainstAnotherTranscriptAction
  | SetClassificationLoadingAction
  | SetClassificationLoadedAction
  | MarkAsLoadedAction
  | UpdateVariantACMGAction
  | ReadAcmgClassificationListAction
  | CreateAcmgClassificationListAction
  | ReadAcmgClassificationListAction
  | CreateAcmgClassificationListAction
  | ReadAcmgCriteriaListAction
  | CreateAcmgCriteriaListAction
  | LoadSuggestedAcmgCriteria
  | SuggestedAcmgCriteriaLoaded
  | AcceptSuggestedAcmgCriteria
  | SuggestedAcmgCriteriaProcessing;
