import { headers, fetchCamelizeData } from "modules/utils";
import { flattenApiEntity } from "modules/utils/formatApiResponse";

import { API_ENTITIES_BASE_URL } from "../utils/baseUrls";

export const getVariantPanelSettings = async projectId => {
  const response = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/settings/variant_panel`
  );

  if (!response.ok) {
    throw response.statusText;
  }

  return flattenApiEntity(response.payload);
};

export const saveVariantPanelSettings = async (projectId, ids) => {
  const response = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/settings/variant_panel`,
    {
      method: "PATCH",
      body: JSON.stringify({
        data: {
          type: "variant_panel_settings",
          id: projectId,
          attributes: {
            value: ids,
            is_inherited: false,
          },
        },
      }),
      headers,
    }
  );

  if (!response.ok) {
    throw new Error("Can't update Variant Panel settings");
  }

  return flattenApiEntity(response.payload);
};
