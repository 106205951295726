import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getOrReloadLicences } from "./actions";
import Licences from "./components/Licences";
import { getLicences, isLoading } from "./selectors";

export const LicencesContainer = ({
  licences,
  loading,
  getOrReloadLicences,
}) => {
  useEffect(() => {
    getOrReloadLicences();
  }, [getOrReloadLicences]);
  return <Licences licences={licences} loading={loading} />;
};

const mapStateToProps = state => ({
  loading: isLoading(state),
  licences: getLicences(state),
});

const mapDispatchToProps = {
  getOrReloadLicences,
};

export default connect(mapStateToProps, mapDispatchToProps)(LicencesContainer);
