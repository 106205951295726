export const NAME = "projects" as const;

export const READ_PROJECTS = "READ_PROJECTS" as const;
export const READ_PROJECTS_SUCCESS = "READ_PROJECTS_SUCCESS" as const;
export const READ_PROJECTS_FAILURE = "READ_PROJECTS_FAILURE" as const;

export const READ_PROJECT_TYPES_FOR_CHILD_PROJECTS =
  "READ_PROJECT_TYPES_FOR_CHILD_PROJECTS" as const;
export const READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_SUCCESS =
  "READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_SUCCESS" as const;
export const READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_FAILURE =
  "READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_FAILURE" as const;

export const RARE_DISEASE_PROJECT_TYPE = "rare_disease";
export const ONCOLOGY_PROJECT_TYPE = "oncology";
