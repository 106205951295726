import { assemblyToReferenceGenome } from "modules/systemConfig/utils";
import { fetchCamelizeData } from "modules/utils";
import { API_UTILS_BASE_URL } from "modules/utils/baseUrls";

export const getSystemConfig = async () => {
  const { payload } = await fetchCamelizeData(`${API_UTILS_BASE_URL}/config`);

  const { sapientiaAssembly, ...rest } = payload;

  return {
    ...rest,
    sapientiaAssembly,
    referenceGenome: assemblyToReferenceGenome(sapientiaAssembly),
  };
};
