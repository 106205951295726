import { FC, useCallback, useContext, useMemo } from "react";

import { Button, Icon } from "pattern-library";

import { titleCase } from "modules/utils/string";

import catalystApi from "../../../api/catalyst-api";
import { Context } from "../Container";

const { useSetPatientStatusMutation } = catalystApi;

const Action: FC<{
  disabled?: boolean;
  action: string;
}> = ({ disabled = false, action }) => {
  const { patientId } = useContext(Context);
  const [setPatientStatus] = useSetPatientStatusMutation();

  const clickHandler = useCallback(() => {
    setPatientStatus({
      patientId,
      action,
    });
  }, [action, patientId, setPatientStatus]);

  const params = useMemo(() => {
    let context = "success";
    let icon = "okSign";

    if (action.includes("undo")) {
      context = "default";
      icon = "repeat";
    } else if (action.includes("reject")) {
      context = "danger";
      icon = "cross";
    } else if (action.includes("fail")) {
      context = "danger";
      icon = "cross";
    }

    return {
      text: titleCase(action).replace(" Qc", " QC"),
      context,
      icon,
    };
  }, [action]);

  return (
    <Button
      data-testid={`${action}-button`}
      disabled={disabled}
      onClick={clickHandler}
      context={params.context}
    >
      <Icon type={params.icon} className="oncology-report__button__icon" />
      {params.text}
    </Button>
  );
};

export default Action;
