import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  createCNVAnalysisRequest,
  getSampleTypes,
  postInterpretationRequest,
} from "modules/api";
import { getBaitsets, getProtocols } from "modules/api/projects";
import {
  READ_PROJECT,
  READ_PROJECT_FAILURE,
  READ_PROJECT_SUCCESS,
} from "modules/project/constants";
import { getOrReloadCurrentProject } from "modules/project/saga";
import {
  isOnPrem as isOnPremSelector,
  getDefaultSampleFilesRootFolder,
} from "modules/systemConfig/selectors";

import {
  COMMON_ERROR_MESSAGE,
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SKIPPED_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";
import { startInit as initHpoTermsData } from "../hpoTerms/actions";
import { HPO_TERMS_LOAD_FAILED } from "../hpoTerms/constants";
import { error } from "../messages/actions";
import { fetchMetadataFields } from "../metadata/actions";
import { FETCH_METADATA_FIELDS_FAILED } from "../metadata/constants";

import {
  InitIRAction,
  resetCNVCallingRequestStatus,
  resetIRSubmissionStatus,
  setBaitsets,
  setCNVCallingRequestStatus,
  setIRSubmissionStatus,
  setProjectRequestStatus,
  setProtocols,
  setSampleTypes,
  SubmitIRAction,
} from "./actions";
import { INIT_IR, SUBMIT_IR } from "./constants";
import {
  hasCNVRequestFailed,
  isIRFullSubmissionInProgress,
  isIRRequestSuccessful,
  isIRv2 as isIRv2Selector,
} from "./selectors";
import { extractReferencePanels, prepareInterpretationRequest } from "./utils";

export function* init() {
  yield takeLatest(SUBMIT_IR, submitInterpretationRequest);
  yield takeLatest(INIT_IR, initIR);
  yield takeLatest(FETCH_METADATA_FIELDS_FAILED, loadingIRDataFailed);
  yield takeLatest(HPO_TERMS_LOAD_FAILED, loadingHpoTermsFailed);

  yield takeLatest(READ_PROJECT, projectReadStarted);
  yield takeLatest(READ_PROJECT_FAILURE, projectReadFailed);
  yield takeLatest(READ_PROJECT_SUCCESS, projectInitSuccess);
}

export function* initIR({ payload: { projectId } }: InitIRAction) {
  yield call(getOrReloadCurrentProject, projectId);
  const isIRv2 = yield select(isIRv2Selector);
  yield put(resetIRSubmissionStatus());
  if (!isIRv2) {
    yield put(resetCNVCallingRequestStatus());

    yield put(fetchMetadataFields(projectId));
    yield put(initHpoTermsData(projectId));

    yield all([call(fetchSampleTypes), call(fetchProtocols, projectId)]);
  } else {
    yield call(fetchBaitsets, projectId);
  }
}

export function* fetchProtocols(projectId) {
  try {
    const response = yield call(getProtocols, projectId);
    const responseBody = yield call([response, response.json]);
    if (response.ok) {
      yield put(setProtocols(responseBody.data));
    } else {
      yield call(loadingProtocolsFailed, responseBody.error);
    }
  } catch (e) {
    yield call(loadingProtocolsFailed, e);
  }
}

export function* fetchBaitsets(projectId) {
  try {
    const response = yield call(getBaitsets, projectId);
    if (response.ok) {
      yield put(setBaitsets(response.payload.baitsets));
    } else {
      yield call(loadingBaitsetsFailed, response.statusText);
    }
  } catch (e) {
    yield call(loadingBaitsetsFailed, e);
  }
}

export function* fetchSampleTypes() {
  try {
    const response = yield call(getSampleTypes);
    const responseBody = yield call([response, response.json]);
    if (response.ok) {
      yield put(setSampleTypes(responseBody.data));
    } else {
      yield call(loadingSampleTypesFailed, responseBody.error);
    }
  } catch (e) {
    yield call(loadingSampleTypesFailed, e);
  }
}

export function* setIRSubmissionFailed(errorMessage) {
  yield put(error(errorMessage));
  yield put(setIRSubmissionStatus(FAILURE_STATUS));
  yield put(setCNVCallingRequestStatus(SKIPPED_STATUS));
}

export function* requestCNVCallingAnalysis(formIR) {
  const cnvPayload = extractReferencePanels(formIR);
  if (cnvPayload.length) {
    yield put(setCNVCallingRequestStatus(IN_PROGRESS_STATUS));
    try {
      const response = yield call(createCNVAnalysisRequest, cnvPayload);
      const body = yield call([response, response.json]);

      if (response.ok) {
        const cnvError = buildErrorMsgForInvalidReferencePatient(body);
        yield put(setCNVCallingRequestStatus(SUCCESS_STATUS, cnvError));
      } else {
        yield put(setCNVCallingRequestStatus(FAILURE_STATUS, body.error));
      }
    } catch (e) {
      yield put(setCNVCallingRequestStatus(FAILURE_STATUS, e.message));
    }
  } else {
    yield put(setCNVCallingRequestStatus(SKIPPED_STATUS));
  }
}

// If CNV analysis is requested, IR submission is a two-staged sequential process due to API limits
export function* submitFullIRWorkflow(payload, isIRv2) {
  const isOnPrem = yield select(isOnPremSelector);
  const defaultSampleFilesRootFolder = yield select(
    getDefaultSampleFilesRootFolder
  );
  yield put(setIRSubmissionStatus(IN_PROGRESS_STATUS));
  const interpretationRequest = prepareInterpretationRequest(
    payload,
    isIRv2,
    isOnPrem,
    defaultSampleFilesRootFolder
  );
  try {
    const response = yield call(
      postInterpretationRequest,
      interpretationRequest
    );
    const body = yield call([response, response.json]);

    if (response.ok) {
      yield put(setIRSubmissionStatus(SUCCESS_STATUS));
      if (!isIRv2) {
        yield call(requestCNVCallingAnalysis, payload);
      }
    } else {
      yield call(setIRSubmissionFailed, body.error);
    }
  } catch (e) {
    yield call(setIRSubmissionFailed, "Unknown error");
  }
}

export function* submitInterpretationRequest({ payload }: SubmitIRAction) {
  const isIRv2 = yield select(isIRv2Selector);

  const isSubmissionInProgress = yield select(isIRFullSubmissionInProgress);
  if (isSubmissionInProgress) {
    return;
  }
  const irRequestSuccessful = yield select(isIRRequestSuccessful);
  if (isIRv2) {
    yield call(submitFullIRWorkflow, payload, isIRv2);
  } else {
    const cnvRequestFailed = yield select(hasCNVRequestFailed);
    if (irRequestSuccessful && cnvRequestFailed) {
      // resubmit just CNV calling request
      yield call(requestCNVCallingAnalysis, payload);
    } else {
      yield call(submitFullIRWorkflow, payload, isIRv2);
    }
  }
}

function* loadingIRDataFailed() {
  yield put(error(COMMON_ERROR_MESSAGE));
}

function* loadingHpoTermsFailed() {
  yield put(error("HPO Terms loading failed."));
}

export function* loadingProtocolsFailed(message) {
  const m = message || "Unknown error";
  yield put(error(`Protocols loading failed:\n${m}`));
  yield put(setProtocols([]));
}

export function* loadingBaitsetsFailed(message) {
  const m = message || "Unknown error";
  yield put(error(`Baitsets loading failed:\n${m}`));
  yield put(setBaitsets([]));
}

export function* loadingSampleTypesFailed(message) {
  const m = message || "Unknown error";
  yield put(error(`Sample types loading failed:\n${m}`));
  yield put(setSampleTypes([]));
}

function* projectReadStarted() {
  yield put(setProjectRequestStatus(IN_PROGRESS_STATUS));
}

function* projectInitSuccess() {
  yield put(setProjectRequestStatus(SUCCESS_STATUS));
}

function* projectReadFailed() {
  yield put(setProjectRequestStatus(FAILURE_STATUS));
  yield loadingIRDataFailed();
}

/*
Builds an error message e.g:

Patients not included in the CNV analysis for dp_snv33:
 - dp_snv1-9 (different sample type to target patient)
Patients not included in the CNV analysis for dp_snv34:
 - dp_s10-1 (no sample type, no protocol, sex does not match target patient, no bam file)
 - dp_snv1-9 (different sample type to target patient)

 */
const buildErrorMsgForInvalidReferencePatient = responseBody => {
  if (!responseBody || !responseBody.invalid_reference_patients) {
    return null;
  }

  const refPatientsErrors = responseBody.invalid_reference_patients
    .map(item => {
      const target = item.target_patient;

      const refErrors = item.reference_patients
        .map(
          reference => `- ${reference.patient} (${reference.errors.join(", ")})`
        )
        .join("\n ");

      return !!refErrors
        ? `Patients not included in the CNV analysis for ${target}:\n ${refErrors}`
        : null;
    })
    .join("\n");

  return refPatientsErrors || null;
};
