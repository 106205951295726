// @flow

import { isEmpty, isNil } from "ramda";

export const filterProcessor =
  <T>(
    includedProperties?: Array<string>,
    getPropertyMap: T => {} | null,
    cellValueMap?: { [string]: () => {} }
  ): ((data: Array<T>, filterValue: string) => Array<T>) =>
  (data: Array<T> = [], filterValue: string): Array<T> => {
    if (isNil(filterValue) || isEmpty(filterValue)) {
      return data;
    }
    return data.filter(field => {
      const keys = includedProperties ? includedProperties : Object.keys(field);
      for (const key of keys) {
        let mappedValue: any = field[key];
        if (getPropertyMap) {
          const propertyMap = getPropertyMap(field);
          if (propertyMap.hasOwnProperty(key)) {
            mappedValue = propertyMap[key](mappedValue);
          }
        } else if (cellValueMap) {
          if (cellValueMap.hasOwnProperty(key)) {
            mappedValue = cellValueMap[key](field);
          }
        }
        if (
          mappedValue &&
          mappedValue
            .toString()
            .toUpperCase()
            .includes(filterValue.toUpperCase())
        ) {
          return true;
        }
      }
      return false;
    });
  };

export const getFetchRequestParams = ({
  pageIndex,
  sortedBy: [sortByColumn],
  columns,
  ...rest
}: any): TableRequestParams => {
  const requestParams = {
    pageNumber: pageIndex + 1,
    ...rest,
  };
  if (sortByColumn) {
    const { id, desc } = sortByColumn;
    requestParams.sort = id;
    requestParams.desc = desc;
  }
  if (columns) {
    requestParams.columns = columns.map(
      ({ id, disableSortBy, disableFilters }) => ({
        data: id,
        orderable: disableSortBy !== true,
        searchable: disableFilters !== true,
        search: { regex: false },
      })
    );
  }
  return requestParams;
};
