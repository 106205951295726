import React from "react";
import { connect } from "react-redux";

import LegacySapientiaTemplate from "modules/legacy/LegacySapientiaTemplate";

import * as selectors from "../selectors";

import StrRecord from "./StrRecord";

export const StrPanel = ({ patientId, strRecord }) => {
  const { variantId } = strRecord;

  const url = `/patient/${patientId}/variant/str/${variantId}/decision/options`;

  return (
    <div className="str-panel">
      <StrRecord strRecord={strRecord} />
      <LegacySapientiaTemplate
        templateUrl={url}
        containerClassName="col-md-4"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  patientId: selectors.getPatientId(state),
});

export default connect(mapStateToProps)(StrPanel);
