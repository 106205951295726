import PropTypes from "prop-types";
import { isEmpty, isNil } from "ramda";
import React from "react";
import { Field } from "redux-form";

import convertStringArrayToFormField from "../../utils/convertStringArrayToFormField";

import ReduxFormField from "./ReduxFormField";

const flattenGroupOptions = (groupOptions = []) =>
  groupOptions.map(groupOption => groupOption.options).flat();

export const formatSelectValue = (modelValue, allOptions) => {
  if (!isNil(allOptions) && !isEmpty(allOptions)) {
    return Array.isArray(modelValue)
      ? allOptions.filter(option =>
          // Sometimes an id can be sent by one endpoint as numeric string, and another as a number.
          modelValue.some(value => String(value) === String(option.value))
        )
      : allOptions.find(option => option.value === modelValue);
  } else {
    return Array.isArray(modelValue)
      ? convertStringArrayToFormField(modelValue)
      : {
          value: modelValue,
          label: modelValue,
        };
  }
};

export const parseSelectValue = controlValue => {
  if (isNil(controlValue)) {
    return controlValue;
  }
  return Array.isArray(controlValue)
    ? controlValue.map(v => v.value)
    : controlValue.value;
};

/**
 * A wrapper around Redux Field with select as an underlying component.
 * The component abstracts away the issue of a React Select
 * that returns an object instead of a selected primitive value
 */
const SelectReduxField = props => {
  const {
    name,
    required = false,
    narrow = false,
    isMulti = false,
    options,
    label,
    hasGroups = false,
    isDisabled = false,
    ...rest
  } = props;

  return (
    <Field
      name={name}
      required={required}
      narrow={narrow}
      component={ReduxFormField}
      type="select"
      isMulti={isMulti}
      options={options}
      label={label}
      isDisabled={isDisabled}
      parse={parseSelectValue}
      format={modelValue =>
        formatSelectValue(
          modelValue,
          hasGroups ? flattenGroupOptions(options) : options
        )
      }
      {...rest}
    />
  );
};

const Option = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool,
});

const OptionGroup = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(Option).isRequired,
});

SelectReduxField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  narrow: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(Option),
    PropTypes.arrayOf(OptionGroup),
  ]),
  label: PropTypes.string,
  hasGroups: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectReduxField;
