import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

import { getProjectSettings } from "../../selectors";

export const AutoAcmgEditor = ({ isAcmgEnabled = false }) => {
  const {
    values: {
      autoAcmg: { isInherited },
    },
  } = useFormikContext();

  const autoAcmgTooltip = useMemo(() => {
    if (!isInherited && isAcmgEnabled !== true) {
      return "To enable Auto-ACMG first enable ACMG evidence in the Project defaults tab";
    }
    return "Enable ACMG suggestions for ACMG evidence tab";
  }, [isInherited, isAcmgEnabled]);

  return (
    <Field
      type="checkbox"
      name="autoAcmg.enabled"
      component={FormikFormField}
      label="Auto-ACMG suggested criteria"
      title={autoAcmgTooltip}
      disabled={isInherited || isAcmgEnabled !== true}
      narrow
      data-testid="autoAcmg"
    />
  );
};

AutoAcmgEditor.displayName = "AutoAcmgEditor";
AutoAcmgEditor.propTypes = {
  isAcmgEnabled: PropTypes.bool,
};

const mapStateToProps = state => {
  const { acmg: { enabled: acmgEnabled } = {} } =
    getProjectSettings(state) || {};
  return {
    isAcmgEnabled: acmgEnabled || false,
  };
};

const ConnectedWrapper = connect(mapStateToProps, undefined)(AutoAcmgEditor);

export default ConnectedWrapper;
