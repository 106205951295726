import React, { PureComponent } from "react";

export class NotFoundMessage extends PureComponent {
  render() {
    return (
      <div id="page-message">
        <div className="alert alert-error">
          The URL you have entered is invalid
        </div>
      </div>
    );
  }
}

export default NotFoundMessage;
