import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Icon from "./Icon";

export default class IconLoading extends PureComponent {
  static displayName = "IconLoading";

  static propTypes = {
    /**
     * The type of icon to render with loading == false
     */
    icon: PropTypes.string,

    /**
     * It parameter determines whether or no to  show the rotating spinner
     */
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  render() {
    const { loading, icon } = this.props;

    if (loading === true) {
      return <Icon type="spinner" />;
    }
    return icon ? <Icon type={icon} /> : null;
  }
}
