import PropTypes from "prop-types";
import { path } from "ramda";
import React, { memo, useState } from "react";
import { connect } from "react-redux";

import { Button, Icon } from "pattern-library";

import FilterPresetModal from "modules/structuralVariantsPresets/components/FilterPresetModal";
import * as selectors from "modules/structuralVariantsPresets/selectors";

export const ViewPresetButton = memo(
  ({
    projectId,
    selectedPresetOption,
    curatedVariantLists,
    collapsed = false,
  }) => {
    const [showPresetsModal, setShowPresetsModal] = useState(false);
    const closeModal = () => {
      setShowPresetsModal(false);
    };

    const openModal = () => {
      setShowPresetsModal(true);
    };

    const { label, value } = selectedPresetOption || {};
    const filters = path(["attributes", "config", "filters"])(value) || {};
    const columns = path(["attributes", "config", "columns"])(value) || [];
    return (
      <>
        <Button context="info" className="btn-sm" onClick={openModal}>
          <Icon type="newWindow" />
          {!collapsed && "View preset"}
        </Button>

        {selectedPresetOption && (
          <FilterPresetModal
            projectId={projectId}
            name={label}
            preset={filters}
            columns={columns}
            show={showPresetsModal}
            close={closeModal}
            cvls={curatedVariantLists}
          />
        )}
      </>
    );
  }
);

const mapStateToProps = state => ({
  selectedPresetOption: selectors.getSelectedPresetOption(state),
});

ViewPresetButton.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  curatedVariantLists: PropTypes.objectOf(
    PropTypes.shape({
      curated_variant_list_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      name: PropTypes.string,
    })
  ).isRequired,
  collapsed: PropTypes.bool,
};

export default connect(mapStateToProps, null)(ViewPresetButton);
