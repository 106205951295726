import { react as autoBind } from "auto-bind";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import InputGroup from "pattern-library/elements/form/InputGroup";

export class SearchBar extends PureComponent {
  static displayName = "SearchBar";

  static propTypes = {
    /**
     * The value of the textbox
     */
    value: PropTypes.string.isRequired,
    /**
     * Callback triggered when the input value changed
     */
    searchChanged: PropTypes.func.isRequired,
    /**
     * Flag to show/hide results
     */
    showResult: PropTypes.bool,
    /**
     * Results that is shown in the results area
     */
    results: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Id of the result item
         */
        id: PropTypes.string.isRequired,
        /**
         * Date of the result item
         */
        date: PropTypes.number.isRequired,
        /**
         * Run id(if the item is a sample)
         */
        run: PropTypes.string,
      })
    ),
    /**
     * Component to show result item
     */
    RenderElement: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * Invoked when onBlur triggered on input
     */
    onBlur: PropTypes.func,
    /**
     * Invoked when onFocus triggered on input
     */
    onFocus: PropTypes.func,
    onItemClick: PropTypes.func,
  };

  static defaultProps = {
    value: "",
    results: [],
    showResult: false,
    searchChanged: () => {},
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onChange({ currentTarget: { value } }) {
    const { searchChanged } = this.props;
    searchChanged(value);
  }

  onBlur() {
    const { onBlur = false } = this.props;
    if (onBlur) onBlur();
  }

  onFocus() {
    const { onFocus = false } = this.props;
    if (onFocus) onFocus();
  }

  selectResult(result) {
    const { selectResult } = this.props;
    selectResult(result);
  }

  render() {
    const { value, showResults, results, RenderElement, onItemClick } =
      this.props;

    return (
      <div className="search-bar">
        <InputGroup
          value={value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          icon="search"
          containerClass="search-container"
          className="search"
          placeholder="Enter a search term"
          hasButton
        />
        <div className={classnames("results", { hidden: !showResults })}>
          {results.map(r => (
            <RenderElement
              key={`search-result-${r.id}`}
              item={r}
              onClick={onItemClick}
            />
          ))}
        </div>
      </div>
    );
  }
}
