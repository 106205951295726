import React from "react";

import { Actions, Link } from "pattern-library";

import {
  STATUSES,
  TRANSFER_STATUS_MAP,
} from "modules/projectInterpretationRequests/constants";
import { transferErrorExists } from "modules/projectInterpretationRequests/utils";
import { formatDate, sortDates } from "modules/utils";

export const getIRSColumns = setIRForDelete => [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Reference",
    accessor: "reference",
  },
  {
    Header: "Last Audit Status",
    accessor: "status",
  },
  {
    Header: "Pending Files",
    accessor: "pendingFiles",
  },
  {
    Header: "User",
    accessor: "userEmail",
  },
  {
    Header: "Created",
    accessor: ({ created }) => formatDate(created),
    id: "created",
    sortType: sortDates,
  },
  {
    Header: "Delete",
    id: "actions",
    disableSortBy: true,
    Cell: ({
      row,
      row: {
        original: {
          id,
          canDelete: { canDelete, msg },
          status,
          lastAudit,
        },
      },
    }) => {
      const info = lastAudit ? lastAudit.info : null;
      if (canDelete) {
        const actions = [
          {
            label: "Delete Interpretation Request",
            className: "btn btn-danger delete-ir-modal",
            "data-testid": `ir-delete-action-${id}`,
            action: e => {
              e.stopPropagation();
              row.toggleRowExpanded(false);
              setIRForDelete(id);
            },
          },
        ];
        return <Actions sticky justifyContent="start" actions={actions} />;
      }
      if (status === STATUSES.PROCESS) {
        return (
          <>
            <strong>Can not delete:</strong> {msg}
          </>
        );
      } else if (status === STATUSES.DELETED) {
        return info;
      }
      return null;
    },
  },
];

export const getDataFilesColumns = onResetDataFile => [
  {
    Header: "Sample ID",
    accessor: "patientRef",
    Cell: ({
      row: {
        original: { patientId, patientRef },
      },
    }) => <Link href={`/patient/${patientId}`}>{patientRef}</Link>,
  },
  {
    Header: "Filename",
    accessor: "name",
  },
  {
    Header: "File Type",
    accessor: ({ type, subtype }) => `${type}-${subtype}`,
  },
  {
    Header: "File Size (bytes)",
    accessor: ({ size }) => (size ? size : "-"),
  },
  {
    Header: "File Transfer Status",
    accessor: ({ status, transferProgress }) => {
      if (status !== STATUSES.PROMISED) {
        return TRANSFER_STATUS_MAP[status.toUpperCase()] || status;
      }
      const transferIsInProgress =
        transferProgress !== "" && parseInt(transferProgress) >= 0;
      return transferIsInProgress
        ? `Transferring (${transferProgress}%)`
        : "Registered";
    },
  },
  {
    Header: "Uploader",
    accessor: "uploader",
  },
  {
    Header: "Validation Message",
    accessor: dataFile => {
      const { validationMessages } = dataFile;
      let messagesCount = validationMessages ? validationMessages.length : 0;
      if (transferErrorExists(dataFile)) {
        ++messagesCount;
      }
      return messagesCount;
    },
  },
  {
    Header: "Last Update",
    accessor: ({ updated }) => formatDate(updated),
    id: "updated",
    sortType: sortDates,
  },
  {
    Header: "Reset",
    id: "reset",
    disableSortBy: true,
    Cell: ({
      row,
      row: {
        original: { id, status },
      },
    }) => {
      if (status === STATUSES.FAILED_VALIDATION) {
        return null;
      }
      return (
        <Link
          title="Delete our copy of this file so it can be re-uploaded"
          href=""
          onClick={e => {
            e.stopPropagation();
            row.toggleRowExpanded(false);
            onResetDataFile(id);
          }}
        >
          <span className="glyphicon glyphicon-remove-circle text-danger project-irs-table-sub-component-btn-reset clickable" />
        </Link>
      );
    },
  },
];
