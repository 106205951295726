import { decamelizeKeys } from "humps";
import { isNil } from "ramda";
import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  select,
  take,
} from "redux-saga/effects";

import {
  getCuratedListsOptions,
  getProjectCuratedLists,
  getProjectCuratedListDetails,
  getProjectCuratedListAudit,
  getProjectCuratedListVariants,
  getProjectCuratedListVariantsExport,
  getProjectUnrelatedCuratedLists,
  getExistingListsAvailability,
  removeCVL,
  removeCVLFromProject,
} from "modules/api/curatedVariantLists";
import { getOrReloadCurrentUser } from "modules/auth/saga";
import { readProject, readProjectCurrentUser } from "modules/project/actions";
import {
  READ_PROJECT_CURRENT_USER_FAILURE,
  READ_PROJECT_CURRENT_USER_SUCCESS,
  READ_PROJECT_FAILURE,
  READ_PROJECT_SUCCESS,
} from "modules/project/constants";
import { downloadFile } from "modules/utils";

import {
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";
import {
  isRequestInProgress,
  isRequestSuccessful,
} from "../../common/selectors";
import type { CVLListType } from "../../common/types";
import { error, errors, success } from "../messages/actions";
import { fetchData, FetchDataResponse } from "../utils/fetchData";

import {
  setCuratedLists,
  setExistingLists,
  setCvlOptions,
  startLoading,
  stopLoading,
  resetDisplayMode,
  setExistingListStatus,
  setSelectedCVL,
  setNeedUpdate,
  setSelectedVariants,
  startVariantsLoading,
  endVariantsLoading,
  setExistingListsAvailable,
  startCVLAuditLoading,
  endCVLAuditLoading,
  setCVLAudit,
  startExportLoading,
  endExportLoading,
  setExportModalInvalidData,
  setExportModalValidData,
  hideExportModal,
  setResetDisplayMode,
} from "./actions";
import type {
  AddExistingListAction,
  GetCVLAuditAction,
  GetCVLDetailsAction,
  GetCVLVariantsAction,
  InitCuratedListsAction,
  RemoveCVLAction,
  RemoveCVLFromProjectAction,
  SubmitCVLAction,
  ExportCVLVariantsAction,
} from "./actions";
import * as constants from "./constants";
import { EXPORT_MISSING_MANDATORY_FIELD_STATUS } from "./constants";
import {
  getExistingCuratedList,
  getProjectId,
  getResetDisplayMode,
} from "./selectors";
import type {
  CuratedVariantListResponseEntry,
  CvlListResponse,
  CvlOptionsResponse,
  ProjectCuratedVariantList,
} from "./types";
import { flattenCVL, flattenCVLs } from "./utils";

export function* init() {
  yield takeLatest(constants.INIT_CURATED_LISTS, initCuratedLists);
  yield takeEvery(constants.SUBMIT_CVL, submitCVL);
  yield takeEvery(constants.ADD_EXISTING_LIST, addExistingCVL);
  yield takeEvery(constants.LOAD_EXISTING_LISTS, loadExistingLists);
  yield takeEvery(
    constants.REMOVE_CVL_FROM_PROJECT,
    removeCVLFromCurrentProject
  );
  yield takeLatest(constants.GET_CVL_DETAILS, getCVLDetails);
  yield takeLatest(constants.GET_CVL_VARIANTS, getCVLVariants);
  yield takeEvery(constants.REMOVE_CVL, removeCVLSaga);
  yield takeLatest(constants.GET_CVL_AUDIT, getCVLAudit);
  yield takeLatest(constants.EXPORT_CVL_VARIANTS, exportCVLVariants);
}

export function* initCuratedLists(action: InitCuratedListsAction) {
  const { projectId }: { projectId: number } = action.payload;
  yield put(startLoading());
  const shouldResetDisplayMode = yield select(getResetDisplayMode);
  if (shouldResetDisplayMode) {
    yield put(resetDisplayMode());
  }
  yield put(readProject(projectId, true));
  yield take([READ_PROJECT_SUCCESS, READ_PROJECT_FAILURE]);
  yield put(readProjectCurrentUser(projectId));
  yield take([
    READ_PROJECT_CURRENT_USER_SUCCESS,
    READ_PROJECT_CURRENT_USER_FAILURE,
  ]);
  yield call(getOrReloadCurrentUser);
  yield all([
    call(checkExistingListsAvailability, projectId),
    call(loadCuratedLists, projectId),
    call(loadCVLOptions, projectId),
  ]);
  yield put(setResetDisplayMode(true));
  yield put(stopLoading());
}

const updateCountLabel = (count: number) => {
  // a workaround to update the Perl managed tab header count
  document
    .querySelectorAll(
      ".curated_variant_list.project-curated-variant-lists:not(.tab-pane)"
    )
    .forEach(el => {
      el.innerHTML = count + "";
    });
};

export function* loadCuratedLists(projectId: number) {
  try {
    const response: FetchDataResponse<CvlListResponse> = yield call(
      getProjectCuratedLists,
      projectId
    );
    yield put(setNeedUpdate(false));
    if (response.ok) {
      const cvls: Array<CuratedVariantListResponseEntry> = response.payload
        ? response.payload.data
        : [];
      yield put(setCuratedLists(flattenCVLs(cvls)));
      updateCountLabel(cvls.length);
    } else {
      yield put(setCuratedLists([]));
      updateCountLabel(0);
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(error(e.message));
  }
}

export function* loadCVLOptions(projectId: number) {
  try {
    const response: FetchDataResponse<CvlOptionsResponse> = yield call(
      getCuratedListsOptions,
      projectId
    );
    if (response.ok) {
      const payload: CvlOptionsResponse = response.payload
        ? response.payload
        : {
            data: {
              attributes: {},
            },
          };
      yield put(setCvlOptions(payload));
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(error(e.message));
  }
}

const excludeKeys = [
  "updated",
  "created",
  "curated_data_schema",
  "project_count",
  "user_count",
  "is_curator",
  "validation_status",
  "filter_presets",
  "variant_count",
  "is_reference",
  "owner_email",
];

export const saveCVL = (
  cvl: ProjectCuratedVariantList,
  projectId: number
): Promise<FetchDataResponse<any>> => {
  const { csvFile, curatedVariantListId } = cvl;
  const createNew = !curatedVariantListId;
  const formData: FormData = new FormData();
  Object.entries(decamelizeKeys(cvl)).forEach(([key, value]) => {
    if (excludeKeys.includes(key)) {
      return;
    }
    if (key === "curators" && Array.isArray(value)) {
      value.forEach(curator => {
        formData.append("curators", curator);
      });
    } else if (key === "csv_file") {
      if (csvFile) {
        const blob = new Blob([csvFile], { type: csvFile.type });
        formData.append(key, blob);
      }
    } else {
      const convertedValue: any =
        typeof value === "boolean" ? (value ? 1 : 0) : value;
      if (!isNil(convertedValue)) {
        formData.append(key, convertedValue);
      }
    }
  });
  const action = createNew ? "create" : "update";
  const method = createNew ? "POST" : "PUT";
  const idPart = curatedVariantListId ? `/${curatedVariantListId}` : "";
  return fetchData(
    `/catalyst/api/project/${projectId}/curated_variant_list/${action}${idPart}`,
    {
      method,
      body: formData,
    }
  );
};

export function* submitCVL(action: SubmitCVLAction) {
  yield put(startLoading());
  const projectId = yield select(getProjectId);
  try {
    const cvl: ProjectCuratedVariantList = action.payload;
    const { csvFile } = cvl;
    const response: FetchDataResponse<any> = yield call(
      saveCVL,
      cvl,
      projectId
    );
    if (response.ok) {
      if (!isNil(csvFile)) {
        yield put(
          success(
            `Curated list ${cvl.name} is successfully sent for processing`
          )
        );
      } else {
        yield put(success(`Curated list ${cvl.name} has been saved`));
      }
      yield put(setNeedUpdate(true));
      yield put(resetDisplayMode());
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(error(e.message));
  }
  yield put(stopLoading());
}

export function* loadExistingLists() {
  yield put(startLoading());
  const projectId = yield select(getProjectId);
  try {
    const response: FetchDataResponse<CvlListResponse> = yield call(
      getProjectUnrelatedCuratedLists,
      projectId
    );
    if (response.ok) {
      const cvls: Array<CuratedVariantListResponseEntry> = response.payload
        ? response.payload.data
        : [];
      yield put(setExistingLists(flattenCVLs(cvls)));
    } else {
      yield put(setExistingLists([]));
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(error(e.message));
  }
  yield put(stopLoading());
}

export function* checkExistingListsAvailability(projectId: number) {
  try {
    const response: FetchDataResponse<{
      data?: { attributes?: { exist?: boolean } };
    }> = yield call(getExistingListsAvailability, projectId);
    const { ok, payload } = response;
    const exist = !!payload?.data?.attributes?.exist;
    if (ok) {
      yield put(setExistingListsAvailable(exist));
    } else {
      yield put(setExistingListsAvailable(false));
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(setExistingListsAvailable(false));
    yield put(error(e.message));
  }
}

export const addExistingCvlToProject = (
  curatedVariantListId: string,
  cvlType: CVLListType,
  showPathogenicity: boolean,
  projectId: number
): Promise<FetchDataResponse<void>> => {
  const payload = {
    data: {
      id: curatedVariantListId,
      type: "curated_variant_list",
      attributes: {
        type: cvlType,
        show_pathogenicity: showPathogenicity,
      },
    },
  };
  return fetchData(
    `/webapi/entities/projects/${projectId}/relationships/curated_variant_list`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/vnd.api+json",
      },
    }
  );
};

export function* addExistingCVL(action: AddExistingListAction) {
  const projectId = yield select(getProjectId);
  const { curatedVariantListId, type, showPathogenicity } = action.payload;
  const cvl: ProjectCuratedVariantList = yield select(
    getExistingCuratedList,
    curatedVariantListId
  );
  const { name, addToProjectStatus } = cvl;
  if (
    isRequestInProgress(addToProjectStatus) ||
    isRequestSuccessful(addToProjectStatus)
  ) {
    return;
  }
  try {
    yield put(setExistingListStatus(curatedVariantListId, IN_PROGRESS_STATUS));

    const response: FetchDataResponse<any> = yield call(
      addExistingCvlToProject,
      curatedVariantListId,
      type,
      showPathogenicity,
      projectId
    );
    if (response.ok) {
      yield put(setExistingListStatus(curatedVariantListId, SUCCESS_STATUS));
      yield put(
        success(
          `${name} curated list has been successfully added to the project`
        )
      );
      yield put(setNeedUpdate(true));
    } else {
      yield put(setExistingListStatus(curatedVariantListId, FAILURE_STATUS));
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(setExistingListStatus(curatedVariantListId, FAILURE_STATUS));
    yield put(error(e.message));
  }
}

export function* removeCVLFromCurrentProject(
  action: RemoveCVLFromProjectAction
) {
  yield put(startLoading());
  const projectId = yield select(getProjectId);
  try {
    const { curatedVariantListId, name } = action.payload;
    const response: FetchDataResponse<any> = yield call(
      removeCVLFromProject,
      projectId,
      curatedVariantListId
    );
    if (response.ok) {
      yield put(
        success(`Curated list ${name} is successfully removed from the project`)
      );
      yield call(checkExistingListsAvailability, projectId);
      yield call(loadCuratedLists, projectId);
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(error(e.message));
  }
  yield put(stopLoading());
}

export function* getCVLDetails(action: GetCVLDetailsAction) {
  yield put(startLoading());
  const projectId = yield select(getProjectId);
  try {
    const {
      curatedVariantListId,
      originProjectId,
      currentProjectId = projectId,
    } = action.payload;
    const response: FetchDataResponse<any> = yield call(
      getProjectCuratedListDetails,
      currentProjectId,
      curatedVariantListId,
      originProjectId
    );
    if (response.ok) {
      yield put(
        setSelectedCVL(
          response.payload ? flattenCVL(response.payload.data) : null
        )
      );
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(error(e.message));
  }
  yield put(stopLoading());
}

export function* getCVLVariants(action: GetCVLVariantsAction) {
  yield put(startVariantsLoading());
  try {
    const { curatedVariantListId, projectId, requestParams } = action.payload;
    const response: PaginatedTableResponse<CuratedListVariant> = yield call(
      getProjectCuratedListVariants,
      projectId,
      curatedVariantListId,
      requestParams
    );
    if (response.errors) {
      yield put(errors(response.errors));
      yield put(setSelectedVariants(null));
    } else {
      yield put(setSelectedVariants(response));
    }
  } catch (e) {
    yield put(error(e.message));
    yield put(setSelectedVariants(null));
  }
  yield put(endVariantsLoading());
}

export function* removeCVLSaga(action: RemoveCVLAction) {
  yield put(startLoading());
  const projectId = yield select(getProjectId);
  try {
    const { curatedVariantListId, name } = action.payload;
    const response: FetchDataResponse<any> = yield call(
      removeCVL,
      curatedVariantListId
    );
    if (response.ok) {
      yield put(success(`Curated list ${name} is successfully removed`));
      yield call(loadCuratedLists, projectId);
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(error(e.message));
  }
  yield put(stopLoading());
}

export function* getCVLAudit(action: GetCVLAuditAction) {
  yield put(startCVLAuditLoading());
  const projectId = yield select(getProjectId);
  try {
    const { curatedVariantListId, originProjectId, requestParams } =
      action.payload;
    const response: PaginatedTableResponse<CVLAuditAction> = yield call(
      getProjectCuratedListAudit,
      projectId,
      curatedVariantListId,
      originProjectId,
      requestParams
    );
    if (response.errors) {
      yield put(errors(response.errors));
      yield put(setCVLAudit(null));
    } else {
      yield put(setCVLAudit(response));
    }
  } catch (e) {
    yield put(error(e.message));
    yield put(setCVLAudit(null));
  }
  yield put(endCVLAuditLoading());
}

export function* exportCVLVariants(action: ExportCVLVariantsAction) {
  yield put(startExportLoading());
  try {
    const { curatedVariantListId, includeErroneous } = action.payload;
    const response: FetchDataResponse<any> = yield call(
      getProjectCuratedListVariantsExport,
      curatedVariantListId,
      includeErroneous
    );
    if (response.ok) {
      yield put(setExportModalValidData(curatedVariantListId));
      yield call(downloadFile, response.file);
      yield put(hideExportModal());
    } else if (response.status === EXPORT_MISSING_MANDATORY_FIELD_STATUS) {
      yield put(setExportModalInvalidData(curatedVariantListId));
      yield put(endExportLoading());
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
      yield put(endExportLoading());
    }
  } catch (e) {
    yield put(error(e.message));
    yield put(endExportLoading());
  }
}
