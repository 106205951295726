import { createSelector } from "@reduxjs/toolkit";
import { groupBy, path, pick, prop } from "ramda";
import { getFormMeta } from "redux-form";

import { ARIADNE_KEYS } from "modules/ariadne/constants";
import { isNumber, minAndMaxNumber } from "modules/forms/rules";
import * as patientSelectors from "modules/patient/selectors";
import * as projectSelectors from "modules/project/selectors";
import {
  getCurrentProject,
  getCurrentProjectFeatures,
  isAutoAcmgActiveOnCurrentProject,
} from "modules/project/selectors";

import {
  getReduxFormErrorSelector,
  getReduxFormValueSelector,
} from "../forms/form-specific-selectors";
import { getFormValues } from "../forms/selectors";
import { TRIO_INHERITANCE_FEATURE } from "../project/constants";
import * as systemConfigSelectors from "../systemConfig/selectors";
import {
  CVL_BLACKLIST,
  CVL_KNOWLEDGEBASE,
  CVL_WHITELIST,
  cvlFilterKeys,
} from "../utils/enum/cvlFilterKeys";

import * as constants from "./constants";
import { AUTO_ACMG_TAB } from "./constants";
import {
  calculateConfigExclusions,
  getConfigExclusionsForProject,
  getConfigExclusionsForPatient,
} from "./feature-flag-config";
import {
  AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
  CUSTOM_PENETRANCE_KEY,
  CUSTOM_TIER_KEY,
} from "./reducer.data";
import { State, ConfigExclusions, ConfigValues, StatePatient } from "./types";
import {
  arrayHasAnyValidNumber,
  hasAnyElements,
  getPopulationLabelByAssembly,
  hasAnyTruthyValues,
  hasAnyValidNumbers,
  retainRegisteredFields,
  populationGroupToSuffix,
} from "./utils";

const optionDisabled = "This option is unavailable for the selected patient";

const getDisabledProps = disabled =>
  disabled && {
    disabled,
    tooltip: {
      content: optionDisabled,
      placement: "left",
      trigger: "mouseenter",
    },
  };

export const getColumnsLoaded = path([constants.NAME, "ui", "columnsLoaded"]);

export const getFilterValues = state =>
  path([constants.NAME, "data", "values", "filters"])(state);

export const getValues = (state, stateBranch = constants.NAME) =>
  path<ConfigValues>([stateBranch, "data", "values"])(state);

export const getFilterDefaults = path([constants.NAME, "data", "defaults"]);

export const getPatientEntity = path<StatePatient>([constants.NAME, "patient"]);
export const isPatientEntityLoading = path<boolean>([
  constants.NAME,
  "patient",
  "loading",
]);
export const getPatientEntityData = path([constants.NAME, "patient", "data"]);

export const getPatientAnnotationSources = createSelector(
  getPatientEntityData,
  (patientEntityData?: PatientEntity): Array<AnnotationSource> => {
    if (!patientEntityData) return [];

    const { annotationSources } = patientEntityData;
    if (!annotationSources) return [];

    return annotationSources;
  }
);

export const getSinglePatientAnnotationSource = createSelector(
  (_, attribute: string) => attribute,
  getPatientAnnotationSources,
  (targetAttribute, annotationSources) =>
    annotationSources.find(({ attribute }) => attribute === targetAttribute)
);

export const getCurrentPatient = patientSelectors.getSinglePatient;

export const getConfigExclusions = createSelector(
  projectSelectors.getCurrentProject,
  (state, patientId) => patientSelectors.getSinglePatient(state, patientId),
  (project, patient) => calculateConfigExclusions(project, patient)
);

export const getAriadnePredictions = createSelector(getFilterValues, filters =>
  pick(
    [
      ARIADNE_KEYS.PREDICTED_PATHOGENICITY,
      ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING,
      ARIADNE_KEYS.PREDICTED_C2P,
      ARIADNE_KEYS.PREDICTED_C2P_EXTENDED,
    ],
    filters
  )
);

/**
 * @returns currently selected project curated variants lists.
 * the lists with a different projectId are inherited from parent projects
 * but considered a part of the current project and should not be filtered
 */
export const getCurrentProjectCuratedLists = createSelector(
  projectSelectors.getCurrentProject,
  (currentProject: Project) => prop("curatedVariantLists", currentProject) || []
);

export const getSNVTableCVLOptions = createSelector(
  getCurrentProjectCuratedLists,
  (state, cvlFilterStateKey) => cvlFilterKeys[cvlFilterStateKey],
  (lists: Array<CuratedVariantList> = [], cvlType) =>
    lists.reduce(
      (
        accumulator: Array<{ key: number; label: string }>,
        list: CuratedVariantList
      ) => {
        const { variantType, type, curatedVariantListId: key, name } = list;
        if (variantType === "SNV" && type === cvlType) {
          accumulator.push({
            key,
            label: name,
          });
        }
        return accumulator;
      },
      []
    )
);

export const getCuratedListsOptions = state => ({
  curatedListKnowledgebase: getSNVTableCVLOptions(state, CVL_KNOWLEDGEBASE),
  curatedListBlacklist: getSNVTableCVLOptions(state, CVL_BLACKLIST),
  curatedListWhitelist: getSNVTableCVLOptions(state, CVL_WHITELIST),
});

export const getFilterGenePanels = (state: State) =>
  getFilterValues(state)?.genePanels;

export const getFiltersLocation = (state: State) =>
  getFilterValues(state)?.location;

export const getPrioritisationValues = (
  state,
  stateBranch = [constants.NAME, "data", "values"]
) => path([...stateBranch, "prioritisation"])(state);

export const getInheritanceOptions = createSelector(
  getFilterDefaults,
  getCurrentProjectFeatures,
  (filterDefaults: any, projectFeatures) => {
    const {
      allInheritanceValues,
      allModeOfInheritanceValues,
      allDenovoStatusValues,
      allFamilyComparisonValues,
    } = filterDefaults;

    if (projectFeatures[TRIO_INHERITANCE_FEATURE]) {
      const index = allInheritanceValues.findIndex(
        option => option.key === "de-novo-fp"
      );
      allInheritanceValues[index].label = "Putative de-novo / de-novo-fp";
    }

    return {
      [constants.INHERITANCE_FIELD]: allInheritanceValues,
      [constants.MOI_FIELD]: allModeOfInheritanceValues,
      [constants.DE_NOVO_STATUS_FIELD]: allDenovoStatusValues,
      [constants.FAMILY_FIELD]: allFamilyComparisonValues,
    };
  }
);

export const selectAllDefaultPopulationAc = state =>
  getFilterDefaults(state)?.allPopulationAc;

export const selectAllDefaultPopulationAf = state =>
  getFilterDefaults(state)?.allPopulationAf;

export const getAllScores = createSelector(
  (state: State) => getFilterDefaults(state)?.allScoresValues,
  (scores: Array<any>) =>
    Object.values(scores).reduce((accumulator, section) => {
      const keys = section.reduce((acc, { key }) => {
        acc.push(key);
        return acc;
      }, []);
      accumulator.push(...keys);
      return accumulator;
    }, [])
);

export const getProjectConfigExclusions = createSelector(
  projectSelectors.getCurrentProject,
  getConfigExclusionsForProject
);

export const getAllPopulationAc = createSelector(
  selectAllDefaultPopulationAc,
  getConfigExclusions,
  (populationAc: Array<any>, { filters: filtersToHide }) =>
    populationAc.reduce((reducedPopulationArray, population) => {
      const { key } = population;

      if (!filtersToHide.includes(key)) {
        reducedPopulationArray.push(population);
      }

      return reducedPopulationArray;
    }, [])
);

const getAllPopulationAf = createSelector(
  selectAllDefaultPopulationAf,
  getConfigExclusions,
  (populationAf: any, { filters: filtersToHide }) =>
    populationAf.filter(population => !filtersToHide.includes(population.key))
);

// Gets all population AF options, with the label adjusted for the current assembly
export const getAllPopulationAfWithAssemblyLabel = createSelector(
  getAllPopulationAf,
  systemConfigSelectors.getAssemblyConfig,
  (populationAf: any, assembly) =>
    populationAf.map(population => ({
      ...population,
      label: getPopulationLabelByAssembly(
        population.key,
        population.label,
        assembly
      ),
    }))
);

export const getConfigFormPopulationAf = createSelector(
  getAllPopulationAf,
  systemConfigSelectors.getAssemblyConfig,
  (populationAf, assembly) =>
    populationAf.map(population => {
      const { group, label, comparator: prefix } = population;
      const suffix = populationGroupToSuffix(group, assembly);
      const placeholder = suffix ? `${label} (${suffix})` : label;
      return {
        ...population,
        prefix,
        placeholder,
        validate: isNumber,
        normalize: minAndMaxNumber(0, 1),
      };
    })
);

export const getPatientConfigExclusions = createSelector(
  getCurrentPatient,
  getConfigExclusionsForPatient
);

const getPatientPrioritisationExclusions = createSelector(
  getCurrentPatient,
  patient =>
    patient ? getConfigExclusionsForPatient(patient).prioritisation : null
);

export const getPrioritisationDefaults = createSelector(
  path([constants.NAME, "data", "defaults", "prioritisationDefaults"]),
  getProjectConfigExclusions,
  getPatientPrioritisationExclusions,
  (
    prioritisationDefaults: any,
    projectConfigExclusions: ConfigExclusions,
    patientPrioritisationExclusions
  ) => {
    if (!prioritisationDefaults) {
      return prioritisationDefaults;
    }

    const filteredPrioritisation = prioritisationDefaults.filter(
      ({ key }) => !projectConfigExclusions.prioritisation.includes(key)
    );

    if (!patientPrioritisationExclusions) {
      return filteredPrioritisation;
    }

    return filteredPrioritisation.map(({ tooltip, ...item }) => {
      const disabled = patientPrioritisationExclusions.includes(item.key);

      return {
        ...item,
        ...(tooltip && { tooltip }),
        ...getDisabledProps(disabled),
      };
    });
  }
);

export const getSpecificGenes = createSelector(
  state => getFilterValues(state)?.genes,
  (genes = []) => genes
);
export const getCoords = createSelector(
  path([constants.NAME, "data", "values", "filters", "coords"]),
  (coords = "") => coords
);

export const getColumnsDefaults = createSelector(
  state => getFilterDefaults(state)?.variantColumns,
  state => getFilterDefaults(state)?.consequenceColumns,
  systemConfigSelectors.getAssemblyConfig,
  (state: State, patientId) => getConfigExclusions(state, patientId),
  (
    variantColumnsDefaults: any,
    consequenceColumnsDefaults: any,
    assembly,
    configExclusions
  ) => {
    const {
      hiddenColumns: {
        variantColumns: variantColumnsToExclude,
        consequenceColumns: consequenceColumnsToExclude,
      },
      disabledColumns: {
        variantColumns: variantColumnsToDisable,
        consequenceColumns: consequenceColumnsToDisable,
      },
    } = configExclusions;

    return variantColumnsDefaults.reduce(
      (accumulator, { key, header, tooltip, subtitle }) => {
        if (key === "__CONSEQUENCES_TABLE__") {
          const conseqCols = consequenceColumnsDefaults
            .filter(
              ({ key: consequenceColumnKey }) =>
                !consequenceColumnsToExclude.includes(consequenceColumnKey)
            )
            .map(
              ({
                key: consequenceColumnKey,
                header: consequenceColumnHeader,
                tooltip: consequenceColumnTooltip,
              }) => {
                const colDisabled =
                  consequenceColumnsToDisable.includes(consequenceColumnKey);

                return {
                  key: consequenceColumnKey,
                  label: consequenceColumnHeader,
                  ...(consequenceColumnTooltip && {
                    tooltip: consequenceColumnTooltip,
                  }),
                  ...getDisabledProps(colDisabled),
                };
              }
            );

          accumulator.push({
            key,
            label: "Per transcript columns",
            options: conseqCols,
          });
        } else {
          const isIncluded =
            !variantColumnsToExclude.includes(key) &&
            (key !== CUSTOM_TIER_KEY ||
              (key === CUSTOM_TIER_KEY && isCustomDataTabEnabled));
          if (isIncluded) {
            const disabled = variantColumnsToDisable.includes(key);

            accumulator.push({
              key,
              subtitle,
              ...(tooltip && { tooltip }),
              ...getDisabledProps(disabled),
              label: getPopulationLabelByAssembly(key, header, assembly),
            });
          }
        }
        return accumulator;
      },
      []
    );
  }
);

export const getTableColumns = createSelector(
  state => getFilterDefaults(state)?.variantColumns,
  state => getFilterDefaults(state)?.consequenceColumns,
  path([constants.NAME, "data", "values", "variantColumns"]),
  path([constants.NAME, "data", "values", "consequenceColumns"]),
  systemConfigSelectors.getAssemblyConfig,
  (
    variantColumnsDefaults: Array<any> = [],
    consequenceColumnsDefaults: Array<Index<Index>> = [],
    variantColumns: Array<any> = [],
    consequenceColumns: Array<any> = [],
    assembly
  ) => {
    const key2VariantColumnDefault = groupBy(({ key }) => key)(
      variantColumnsDefaults
    );
    const key2ConsequenceColumnDefault = groupBy(({ key }) => key)(
      consequenceColumnsDefaults
    );
    return variantColumns.reduce((accumulator, key) => {
      if (key === "__CONSEQUENCES_TABLE__") {
        const columns = consequenceColumns.reduce(
          (consequenceColumns, consequenceColumnKey) => {
            const consequenceColumnDefault: any =
              key2ConsequenceColumnDefault[consequenceColumnKey][0];
            consequenceColumns.push({
              ...consequenceColumnDefault,
            });
            return consequenceColumns;
          },
          []
        );
        if (columns.length > 0) {
          accumulator.push({
            key,
            header: "Per transcript columns",
            component: "ConsequencesTable",
            columns,
          });
        }
      } else {
        const variantColumnDefault: any = key2VariantColumnDefault[key][0];
        const { header } = variantColumnDefault;
        const tableColumn = {
          ...variantColumnDefault,
          header: getPopulationLabelByAssembly(key, header, assembly),
        };

        accumulator.push(tableColumn);
      }
      return accumulator;
    }, []);
  }
);

export const getCurrentProjectId = createSelector(
  getCurrentProject,
  (project: Project) => {
    if (project) {
      return project.projectId;
    }

    return null;
  }
);

export const getPatientQcFilterStringsForCurrentProject = createSelector(
  projectSelectors.getCurrentProject,
  (state, projectId, ignore) => ignore,
  (state, projectId, ignore, formName) =>
    getFormValues(state, formName, "filterExclude"),
  (state, projectId, ignore, formName) =>
    getFormValues(state, formName, "filterInclude"),
  (
    { patientQcFilterStrings = [] }: any,
    ignore,
    filterExclude,
    filterInclude
  ) => {
    // Get the selected options from the current filter boxes
    const fieldsToCheck = ["filterInclude", "filterExclude"];
    const props = { filterExclude, filterInclude };

    let currentSelectedValues: Array<any> = [];

    fieldsToCheck
      // get the field value
      .map(field => field !== ignore && props[field])
      // filter out undefined
      .filter(val => val !== undefined && val.length > 0)
      // push just the values into the currentSelectedValues array
      .forEach(arr => {
        currentSelectedValues = currentSelectedValues.concat(
          arr.map(({ value }) => value)
        );
      });

    // return the filter options but with an additional key: disabled: true
    // if that value has been selected in another select
    return patientQcFilterStrings.map(val => ({
      ...val,
      disabled: currentSelectedValues.indexOf(val.value) !== -1,
    }));
  }
);

const toGenePanelCheckboxItem = genePanel => {
  const { archived, genePanelId, title, isPatientGenePanel, ensemblVersion } =
    genePanel;
  return {
    key: genePanelId,
    label: title,
    archived: !!archived,
    isPatientGenePanel,
    ensemblVersion,
  };
};

export const getGenePanelsCheckBoxItems = createSelector(
  getCurrentPatient,
  patient => {
    if (patient && patient.project) {
      const { genePanels = [] } = patient.project;
      const { genePanels: patientGenePanels = [] } = patient;

      const patientPanelIds = new Set(
        (patientGenePanels || []).map(({ genePanelId }) => genePanelId)
      );

      const allPanels = genePanels.reduce(
        (panelsHash, panel) => {
          if (patientPanelIds.has(panel.genePanelId)) {
            panelsHash.patientPanels.push({
              ...panel,
              isPatientGenePanel: true,
            });
          } else {
            panelsHash.projectPanels.push({
              ...panel,
              isPatientGenePanel: false,
            });
          }

          return panelsHash;
        },
        {
          projectPanels: [],
          patientPanels: [],
        }
      );

      const res = [...allPanels.patientPanels, ...allPanels.projectPanels];

      return res.map(panel => toGenePanelCheckboxItem(panel));
    }
    return [];
  }
);

export const isCustomDataTabEnabled = createSelector(
  (state, { patientId }) => {
    if (!patientId) {
      return null;
    }
    return patientSelectors.getSinglePatient(state, patientId);
  },
  patient => {
    if (!patient) {
      return true;
    }

    return patient.hasCustomDataTiers;
  }
);

export const getFilterCategoriesWithValues = createSelector(
  (state, form) => getReduxFormValueSelector(form)(state) || {},
  getAllPopulationAfWithAssemblyLabel,
  getAllPopulationAc,
  (state, form) => (state.form[form] || {}).registeredFields,
  (initialFormValue, populationAf, populationAc, registeredFields) => {
    // the invisible (not registered) fields should not affect the result
    const formValue = retainRegisteredFields(
      initialFormValue,
      registeredFields
    );
    const {
      genes,
      coords = [],
      genePanels,
      vepConsequence,
      zygosity,
      polyphenScore,
      siftScore,
      revelScore,
      maxEntScan,
      rf,
      ada,
      spliceAi,
      cssf,
      pliScore,
      loeufScore,
      missenseZScore,
      inheritance,
      moi,
      familyComparisons,
      denovoStatus,
      depth,
      score,
      filterInclude = [],
      filterExclude = [],
      [AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY]: suggestedAcmgPathogenicity,
    } = formValue;

    const hasAriadneValues =
      hasAnyElements(
        formValue[ARIADNE_KEYS.PREDICTED_PATHOGENICITY],
        formValue[ARIADNE_KEYS.PREDICTED_C2P],
        formValue[ARIADNE_KEYS.PREDICTED_C2P_EXTENDED]
      ) ||
      hasAnyTruthyValues(formValue[ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING]);

    const checkKeysForAnyValidNumber = arr =>
      arrayHasAnyValidNumber(arr.map(({ key }) => formValue[key]));

    return {
      ARIADNE: hasAriadneValues,
      "Genes Location": hasAnyElements(genes, coords, genePanels),
      "Population frequency": checkKeysForAnyValidNumber(populationAf),
      "Population allele count": checkKeysForAnyValidNumber(populationAc),
      Consequence: hasAnyElements(vepConsequence),
      Zygosity: hasAnyElements(zygosity),
      Scores: hasAnyValidNumbers(
        polyphenScore,
        siftScore,
        maxEntScan,
        rf,
        ada,
        spliceAi,
        cssf,
        revelScore,
        pliScore,
        loeufScore,
        missenseZScore
      ),
      Inheritance: hasAnyElements(
        inheritance,
        moi,
        familyComparisons,
        denovoStatus
      ),
      Quality:
        hasAnyValidNumbers(depth, score) ||
        hasAnyElements(filterInclude, filterExclude),
      Custom: hasAnyElements(
        formValue[CUSTOM_TIER_KEY],
        formValue[CUSTOM_PENETRANCE_KEY]
      ),
      [AUTO_ACMG_TAB]: hasAnyElements(suggestedAcmgPathogenicity),
    };
  }
);

export const getFilterCategoriesWithErrors = createSelector(
  (state, form) => getReduxFormErrorSelector(form)(state) || {},
  (state, form) => getFormMeta(form)(state) || {},
  getAllScores,
  getAllPopulationAfWithAssemblyLabel,
  getAllPopulationAc,
  (formErrors, metaData, scores, populationAf, populationAc) => {
    const errors: any = Object.entries(formErrors).reduce(
      (acc, [key, value]) => {
        const { touched = false } = metaData[key] || {};
        acc[key] = value && touched;
        return acc;
      },
      {}
    );
    const { coords } = errors;

    const checkKeysForAnyTruthyValue = arr =>
      hasAnyTruthyValues(...arr.map(({ key }) => errors[key]));

    return {
      "Genes Location": hasAnyTruthyValues(coords),
      Scores: hasAnyTruthyValues(...scores.map(key => errors[key])),
      "Population frequency": checkKeysForAnyTruthyValue(populationAf),
      "Population allele count": checkKeysForAnyTruthyValue(populationAc),
    };
  }
);

export const getFilterErrorState = createSelector(
  getFilterCategoriesWithErrors,
  filterErrors => Object.values(filterErrors).some(Boolean)
);

export const getActivePrioritisationValuesSection = createSelector(
  getPrioritisationValues,
  getPrioritisationDefaults,
  (selectedPrioritisation: any, allOptions) => {
    const values = allOptions
      .filter(({ key }) => selectedPrioritisation.includes(key))
      .sort(
        (a, b) =>
          selectedPrioritisation.indexOf(a.key) -
          selectedPrioritisation.indexOf(b.key)
      );
    return {
      type: "array",
      label: "Prioritisation",
      values,
    };
  }
);

export const isAutoAcmgTabEnabled = createSelector(
  isAutoAcmgActiveOnCurrentProject,
  (isAutoAcmgActive: boolean) => isAutoAcmgActive
);

export const isAriadneEnabled = createSelector(
  projectSelectors.isAriadnePredictionActiveOnCurrentProject,
  patientSelectors.isAriadnePredictionsDataAvailable,
  (isAriadneEnabled: boolean, isAriadneDataAvailable: any) =>
    !!(isAriadneEnabled && isAriadneDataAvailable)
);

export const hasAriadneFilters = createSelector(
  isAriadneEnabled,
  getAriadnePredictions,
  (isAriadneEnabled, predictions) => {
    if (!isAriadneEnabled) {
      return false;
    }

    return !!(
      (predictions[ARIADNE_KEYS.PREDICTED_PATHOGENICITY] &&
        Array.isArray(predictions[ARIADNE_KEYS.PREDICTED_PATHOGENICITY]) &&
        predictions[ARIADNE_KEYS.PREDICTED_PATHOGENICITY].length) ||
      predictions[ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING] ||
      predictions[ARIADNE_KEYS.PREDICTED_C2P] ||
      predictions[ARIADNE_KEYS.PREDICTED_C2P_EXTENDED]
    );
  }
);

export const getSuggestedPathogenicityValues = createSelector(
  getFilterDefaults,
  getPatientConfigExclusions,
  (
    { allSuggestedPathogenicityValues }: any,
    { filters: patientFiltersExclusions }
  ) => {
    if (
      !patientFiltersExclusions ||
      patientFiltersExclusions.indexOf(AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY) <
        0
    ) {
      return allSuggestedPathogenicityValues;
    }
    return allSuggestedPathogenicityValues.map(item => ({
      ...item,
      disabled: true,
      tooltip: {
        placement: "left",
        content: "This option is unavailable for selected patient",
        trigger: "mouseenter",
      },
    }));
  }
);
