import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as presetUtils from "modules/utils/filter-presets";

import {
  isRequestCompleted,
  isRequestInProgress,
} from "../../common/selectors";
import { findPresetById, presetToOption } from "../utils/filter-presets";

import { MODULE_NAME } from "./constants";
import { SvPreset } from "./types";

export const uiPath = (...items: Array<string>) =>
  path([MODULE_NAME, "ui", ...items]);
export const dataPath = (...items: Array<string>) =>
  path([MODULE_NAME, "data", ...items]);

const getPresets = state => dataPath("presets")(state);

export const getSelectFieldPresets = createSelector(
  getPresets,
  (presets: Array<SvPreset>) =>
    presets.map(svPreset => presetToOption(svPreset))
);

export const getSelectedPresetId = state => uiPath("selectedPresetId")(state);

export const getSelectedPresetOption = createSelector(
  getSelectedPresetId,
  getPresets,
  findPresetById
);

const presetsRequestStatus = (state): RequestSubmissionStatus =>
  uiPath("presetsRequestStatus")(state);

export const arePresetsLoading = (state): boolean =>
  isRequestInProgress(presetsRequestStatus(state));

export const hasPresetLoadingCompleted = (state): boolean =>
  isRequestCompleted(presetsRequestStatus(state));

export const getPresetDictionaries = state =>
  dataPath("presetDictionaries")(state);

export const getDefaultPreset = createSelector(
  getPresets,
  presetUtils.getDefaultPreset
);

export const isDefaultPresetSelected = createSelector(
  getDefaultPreset,
  getSelectedPresetId,
  (defaultPreset: SvPreset, selectedPresetId: number) => {
    if (!selectedPresetId || !defaultPreset) {
      return false;
    }

    return defaultPreset.id === selectedPresetId;
  }
);
