import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import FormControlWrapper from "./FormControlWrapper";
import FormGroup from "./FormGroup";
import Label from "./Label";

export default class Textarea extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.object,
    required: PropTypes.bool,
    narrow: PropTypes.bool,
  };

  render() {
    const { label, input = {}, required = false, narrow = false } = this.props;

    return (
      <FormGroup {...this.props}>
        <Label label={label} required={required} narrow={narrow} />
        <FormControlWrapper label={label} narrow={narrow}>
          <textarea className="form-control" {...input} />
        </FormControlWrapper>
      </FormGroup>
    );
  }
}
