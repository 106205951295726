import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { equals } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Icon, GeneColumn, GeneScoresColumn } from "pattern-library";

import * as actions from "../actions";
import * as selectors from "../selectors";
import { choosePathogenicity } from "../selectors";

import VariantRow from "./VariantRow";

export class VariantsTableRow extends PureComponent {
  static propTypes = {
    gene: PropTypes.shape({
      name: PropTypes.string.isRequired,
      geneId: PropTypes.number.isRequired,
      variants: PropTypes.array,
    }),
    setFilteredOutVariantsDisplayed: PropTypes.func.isRequired,
    variantPanelGene: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    variantPanelPatientVariant: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { setFilteredOutVariantsDisplayed } = this.props;
    setFilteredOutVariantsDisplayed(false);
  }

  componentDidUpdate({ gene: { geneId: prevGeneId } }) {
    const {
      gene: { geneId },
      setFilteredOutVariantsDisplayed,
    } = this.props;
    if (prevGeneId !== geneId) {
      setFilteredOutVariantsDisplayed(false);
    }
  }

  getFilteredOutVariantsCount() {
    const { activeGeneAllVariantsCount, activeVariants = [] } = this.props;
    if (!activeGeneAllVariantsCount) {
      // if all variants count is unknown yet or equals to 0
      return 0;
    }
    return activeGeneAllVariantsCount - activeVariants.length;
  }

  loadGene(e) {
    e.stopPropagation();

    const {
      patientId,
      selectContextForVariantPanel,
      gene: { geneId, name },
    } = this.props;

    return selectContextForVariantPanel(patientId, geneId, name);
  }

  filteredOutVariantsClick(e) {
    e.stopPropagation();
    const {
      gene: { geneId, variants = [] },
      patientId,
      loadFullGene,
      filteredOutVariantsDisplayed,
      activeGeneAllVariantsCount,
      setFilteredOutVariantsDisplayed,
    } = this.props;
    if (filteredOutVariantsDisplayed) {
      setFilteredOutVariantsDisplayed(false);
    } else {
      const variantsReady = variants.length === activeGeneAllVariantsCount;
      loadFullGene(patientId, geneId, variantsReady);
    }
  }

  render() {
    const {
      gene: { name, geneId, variants = [], decisions = [] },
      variantPanelGene,
      variantPanelPatientVariant,
      gene,
      activeVariants = [],
      expandedGeneId,
      filteredOutVariantsDisplayed,
      patientId,
    } = this.props;

    const filteredVariants = variants.filter(
      ({ variantId, patientVariantId }) =>
        activeVariants.some(activeVariant =>
          equals(activeVariant, { variantId, patientVariantId })
        )
    );
    const filteredOutVariantCount = this.getFilteredOutVariantsCount();

    return (
      <div
        key={`gene-${name}`}
        className={classNames("gene-row", {
          "gene-row--is-active": variantPanelGene === name,
        })}
      >
        <GeneColumn
          {...gene}
          onClick={this.loadGene}
          pathogenicity={choosePathogenicity(decisions)}
        />
        <GeneScoresColumn {...gene} onClick={this.loadGene} />
        <div className="variant-container">
          {(filteredOutVariantsDisplayed ? variants : filteredVariants).map(
            variant => {
              const { variantId, patientVariantId } = variant;

              return (
                <VariantRow
                  key={`${name}-${variantId}`}
                  isFilteredOut={
                    !filteredVariants.find(v => v.variantId === variantId)
                  }
                  isActive={variantPanelPatientVariant === patientVariantId}
                  gene={gene}
                  variant={variant}
                  patientId={patientId}
                />
              );
            }
          )}
          {geneId === expandedGeneId && filteredOutVariantCount > 0 && (
            <span
              className="variant-filtered-out"
              onClick={this.filteredOutVariantsClick}
            >
              <Icon
                type={filteredOutVariantsDisplayed ? "left" : "right"}
                className="chevron"
              />
              {`${
                filteredOutVariantsDisplayed ? "Hide" : "Show"
              } ${filteredOutVariantCount} filtered out variant${
                filteredOutVariantCount > 1 ? "s" : ""
              }`}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    activeVariants: selectors.getFilteredVariantsOfGene(state, props),
    expandedGeneId: selectors.activeVariantPanelGene(state),
    activeGeneAllVariantsCount: selectors.activeGeneAllVariantsCount(state),
    filteredOutVariantsDisplayed:
      selectors.getFilteredOutVariantsDisplayed(state),
    activeVariantPanelVariant: selectors.activeVariantPanelVariant(state),
    activeVariantPanelTranscript: selectors.activeVariantPanelTranscript(state),
    variantPanelGene: selectors.activeVariantPanelGeneName(state),
    variantPanelPatientVariant:
      selectors.activeVariantPanelPatientVariant(state),
  }),
  {
    selectContextForVariantPanel: actions.selectContextForVariantPanel,
    setFilteredOutVariantsDisplayed: actions.setFilteredOutVariantsDisplayed,
    loadFullGene: actions.loadFullGene,
  }
)(VariantsTableRow);
