import React, { memo } from "react";

export const ReferencePanelsTableHeader = () => (
  <thead>
    <tr>
      <th>&nbsp;</th>
      <th>Project ID</th>
      <th>Sample ID</th>
      <th>&nbsp;</th>
    </tr>
  </thead>
);

export default memo(ReferencePanelsTableHeader);
