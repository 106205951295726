import React, { memo } from "react";

import { FormattedField } from "../../types";

interface ActionValueProps {
  fields: Array<FormattedField>;
}

export const ActionValue = memo(({ fields }: ActionValueProps) => (
  <>
    {fields.map(({ label, value }) => (
      <div key={label}>
        {label}: {value}
      </div>
    ))}
  </>
));
