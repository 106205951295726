import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import MetaInfo from "./MetaInfo";

export default class ArticleRef extends PureComponent {
  static displayName = "ArticleRef";

  static propTypes = {
    /**
     * Article title
     */
    title: PropTypes.string.isRequired,
    /**
     * Article authors
     */
    authors: PropTypes.string.isRequired,
    /**
     * Article source
     */
    source: PropTypes.string.isRequired,

    /**
     * Determines whether the bookmark is set or not.
     */
    bookmarked: PropTypes.bool,
    /**
     * link click handler
     *  - reference: Title and link icon is clickable
     */
    onLinkClick: PropTypes.func,

    /**
     * Article url
     */
    articleUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    bookmarked: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onLinkClick(e) {
    e.preventDefault();
    const { onLinkClick, articleUrl } = this.props;
    if (onLinkClick) onLinkClick(articleUrl);
  }

  render() {
    const { title, authors, source, ...otherProps } = this.props;

    return (
      <div className="article article-reference">
        <div className="article-line article-reference-line" />
        <MetaInfo
          mode="reference"
          {...otherProps}
          onLinkClick={this.onLinkClick}
        />
        <div className="article-content article-reference-content">
          <div className="article-reference-title" onClick={this.onLinkClick}>
            {title}
          </div>
          <div className="article-authors article-reference-authors">
            {authors}
          </div>
          <div className="article-source article-reference-source">
            {source}
          </div>
        </div>
      </div>
    );
  }
}
