// @flow
import { flatten } from "ramda";

/**
 * group geneToGenePanel objects with the same gene.geneId
 * @param genePanels - list of panels. Each panel contains a geneToGenePanel list representing gene with additional info
 */
export const groupGenesById = (
  genePanels: Array<GenePanel> = []
): GeneToGenePanelGroups => {
  //$FlowFixMe
  const geneToGenePanels: Array<GeneToGenePanel> = flatten(
    genePanels.map(gp => gp.geneToGenePanel)
  );
  return geneToGenePanels.reduce(
    (obj: GeneToGenePanelGroups, geneToGenePanel: GeneToGenePanel) => {
      const {
        gene: { geneId },
      } = geneToGenePanel;
      const groupedGenes = obj[geneId];
      if (!groupedGenes) {
        obj[geneId] = [geneToGenePanel];
      } else {
        groupedGenes.push(geneToGenePanel);
      }
      return obj;
    },
    {}
  );
};

/**
 * merge condition and MOI props of multiple genes with the same geneId
 */
export const mergeGenes = (
  geneWrappersByGeneId: GeneToGenePanelGroups,
  geneId: number
): ?InheritanceMode => {
  if (!geneWrappersByGeneId || !geneId) {
    return null;
  }
  const geneDuplicates = geneWrappersByGeneId[geneId];
  if (!geneDuplicates) {
    return null;
  }

  const mergeResult = geneDuplicates.reduce(
    (obj, geneToGenePanel: GeneToGenePanel) => {
      const {
        condition,
        xLinked = 0,
        autosomalRecessive = 0,
        autosomalDominant = 0,
      } = geneToGenePanel || {};
      if (condition) {
        obj.condition.push(condition);
      }
      obj.xLinked = obj.xLinked + xLinked;
      obj.autosomalRecessive = obj.autosomalRecessive + autosomalRecessive;
      obj.autosomalDominant = obj.autosomalDominant + autosomalDominant;
      return obj;
    },
    {
      geneId,
      //$FlowFixMe
      condition: [],
      xLinked: 0,
      autosomalRecessive: 0,
      autosomalDominant: 0,
    }
  );
  mergeResult.condition = mergeResult.condition.join(", ");
  return mergeResult;
};
