import classNames from "classnames";
import PropTypes from "prop-types";
import { isEmpty, isNil } from "ramda";
import React, { memo } from "react";

import ListItem from "./ListItem";

const List = memo(
  ({
    items = [],
    customListItem: CustomListItem,
    keyPropName,
    valuePropName,
    categoryName,
    className,
    selectedItem,
    onClick,
    isItemHighlighted,
    emptyText,
  }) => (
    <ul className={classNames("list", className)}>
      {items.map(item => (
        <li
          key={`list-item-${item[keyPropName]}`}
          className={classNames("list-item", {
            selected:
              !isNil(selectedItem) &&
              selectedItem[keyPropName] === item[keyPropName],
          })}
        >
          {CustomListItem && <CustomListItem item={item} onClick={onClick} />}
          {!CustomListItem && (
            <ListItem
              item={item}
              valuePropName={valuePropName}
              onClick={onClick}
              categoryName={categoryName}
              isHighlighted={isItemHighlighted}
            />
          )}
        </li>
      ))}
      {isEmpty(items) && (
        <li className={classNames("list-item empty-list")}>
          <ListItem
            item={{
              text: isNil(emptyText) ? "The list is empty" : emptyText,
            }}
            valuePropName="text"
          />
        </li>
      )}
    </ul>
  )
);

List.displayName = "List";

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  customListItem: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.func,
  ]),
  keyPropName: PropTypes.string.isRequired,
  valuePropName(props, propName) {
    if (!props.customListItem && !props[propName]) {
      return new Error(
        `The prop ${propName} is marked as required in List, but its value is ${props[propName]}`
      );
    }
  },
  categoryName: PropTypes.string,
  className: PropTypes.string,
  selectedItem: PropTypes.object,
  onClick: PropTypes.func,
  isItemHighlighted: PropTypes.func,
  emptyText: PropTypes.string,
};

export default List;
