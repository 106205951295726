import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class NoDataComponent extends PureComponent {
  static displayName = "NoData";
  static propTypes = {
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
    /**
     * Content to render inside the component
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
  };
  render() {
    const { children, className, ...rest } = this.props;

    return (
      <div className={classNames(className, "table-no-data")} {...rest}>
        {children}
      </div>
    );
  }
}
