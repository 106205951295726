import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { LoadingOverlay } from "pattern-library";
import { Table } from "pattern-library/elements/table7";

import { formatLength, toDecimalPlaces } from "modules/utils/format";
import { filterProcessor } from "modules/utils/table";

import { fetchPatientOverlaps } from "../../actions";
import { getLoading, getPatientOverlaps } from "../../selectors";

import { columns } from "./columns";

const getPropertyMap = () => ({
  absLength: value => formatLength(value),
  overlapPercent: value => toDecimalPlaces(value),
});

export const PATIENT_OVERLAPS_FILTER_PROCESSOR = filterProcessor(
  null,
  getPropertyMap
);

const PatientOverlap = ({
  patientId,
  patientSvId,
  fetchPatientOverlaps,
  patientOverlaps,
  loading,
}) => {
  useEffect(() => {
    fetchPatientOverlaps(patientId, patientSvId);
  }, [fetchPatientOverlaps, patientId, patientSvId]);

  const [filterValue, setFilterValue] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    // Change the value used for filtering
    // so users can enter start/end positions with or without commas
    const sanitisedFilterValue = filterValue.replace(/,/g, "");
    setFilteredItems(
      PATIENT_OVERLAPS_FILTER_PROCESSOR(patientOverlaps, sanitisedFilterValue)
    );
  }, [patientOverlaps, filterValue, setFilteredItems]);

  const onFilterChange = useCallback(
    ({ filter = "" }) => {
      setFilterValue(filter);
    },
    [setFilterValue]
  );

  return (
    <LoadingOverlay loading={loading}>
      <Table
        className="structural-variants-patient-overlap-table"
        fetchData={onFilterChange}
        columns={columns}
        data={filteredItems}
        autoResetPage={false}
        showPagination
        enableFilter
      />
    </LoadingOverlay>
  );
};

const mapStateToProps = state => ({
  patientOverlaps: getPatientOverlaps(state),
  loading: getLoading(state),
});

const mapDispatchToProps = {
  fetchPatientOverlaps: fetchPatientOverlaps.start,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientOverlap);
