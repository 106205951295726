//todo: temporary solution - only for development purposes
import { FilterData } from "./types";

export const FILTER_DATA_MOCK: FilterData = {
  tier: {
    title: "Tier",
    multiple: 1,
    values: [
      { label: "TIERA", value: "TIERA" },
      { label: "TIERB", value: "TIERB" },
      { value: "TIERNULL", label: "TIERNULL" },
    ],
  },
  genePanel: {
    title: "Gene panel",
    values: [
      {
        label: "GPM.cerebellar_hypoplasia",
        value: 935,
        title: "GPM.cerebellar_hypoplasia",
        gene_panel_id: 935,
      },
      {
        value: 2710,
        title: "Imported gene panel - Paediatric Opthalmic.txt: Demo",
        label: "Imported gene panel - Paediatric Opthalmic.txt: Demo",
        gene_panel_id: 2710,
      },
      {
        label: "PCD: Demo",
        title: "PCD: Demo",
        value: 963,
        gene_panel_id: 963,
      },
      {
        gene_panel_id: 950,
        label: "PCH_exomiser_OMIM_top_genes: Demo",
        value: 950,
        title: "PCH_exomiser_OMIM_top_genes: Demo",
      },
      {
        label: "PGx genes: Demo",
        title: "PGx genes: Demo",
        value: 933,
        gene_panel_id: 933,
      },
    ],
    multiple: 1,
  },
  bayesFactor: {
    title: "Bayes Factor",
  },
  gnomadAf: {
    title: "gnomAD Allele Frequency",
  },
  included: {
    multiple: 1,
    values: [
      {
        value: "COMMON",
        label: "COMMON",
      },
      {
        value: "DGV_common_GAIN",
        label: "DGV_common_GAIN",
      },
      {
        label: "DGV_common_LOSS",
        value: "DGV_common_LOSS",
      },
      {
        label: "L10kb",
        value: "L10kb",
      },
      {
        label: "MaxDepth",
        value: "MaxDepth",
      },
      {
        label: "MaxMQ0Frac",
        value: "MaxMQ0Frac",
      },
      {
        label: "MinGQ",
        value: "MinGQ",
      },
      {
        value: "consensus_cnv_GAIN",
        label: "consensus_cnv_GAIN",
      },
      {
        value: "consensus_cnv_LOSS",
        label: "consensus_cnv_LOSS",
      },
      {
        value: "q10",
        label: "q10",
      },
    ],
    title: "Include variants with the following tags",
  },
  keyword: {
    multiple: 1,
    values: [
      {
        value: "COMMON",
        label: "COMMON",
      },
      {
        value: "DGV_common_GAIN",
        label: "DGV_common_GAIN",
      },
      {
        label: "DGV_common_LOSS",
        value: "DGV_common_LOSS",
      },
      {
        label: "L10kb",
        value: "L10kb",
      },
      {
        label: "MaxDepth",
        value: "MaxDepth",
      },
      {
        label: "MaxMQ0Frac",
        value: "MaxMQ0Frac",
      },
      {
        label: "MinGQ",
        value: "MinGQ",
      },
      {
        value: "consensus_cnv_GAIN",
        label: "consensus_cnv_GAIN",
      },
      {
        value: "consensus_cnv_LOSS",
        label: "consensus_cnv_LOSS",
      },
      {
        value: "q10",
        label: "q10",
      },
    ],
    title: "Exclude variants with the following tags",
  },
  genotype: {
    title: "Genotype",
    values: [
      {
        label: "Homozygous",
        value: "Homozygous",
      },
      {
        label: "Heterozygous",
        value: "Heterozygous",
      },
      {
        label: "Hemizygous",
        value: "Hemizygous",
      },
      {
        value: "Not Specified",
        label: "Not Specified",
      },
    ],
    multiple: 1,
  },
  cvl: {
    title: "Curated variant lists",
    values: [
      {
        label: "Decipher CNVs 2018-11",
        variant_type: "CNV",
        name: "Decipher CNVs 2018-11",
        value: "86",
        overlap_length_threshold: 0.8,
        show_pathogenicity: 1,
        curated_variant_list_id: 86,
        type: "Knowledgebase",
      },
      {
        curated_variant_list_id: 88,
        show_pathogenicity: 1,
        type: "Knowledgebase",
        value: "88",
        overlap_length_threshold: 0.8,
        name: "Decipher CNVs 2018-11 Pathogenic, Likely pathogenic variants",
        variant_type: "CNV",
        label: "Decipher CNVs 2018-11 Pathogenic, Likely pathogenic variants",
      },
      {
        variant_type: "CNV",
        label: "Decipher CNVs 2018-11 Uncertain significance variants",
        name: "Decipher CNVs 2018-11 Uncertain significance variants",
        overlap_length_threshold: 0.8,
        value: "89",
        type: "Knowledgebase",
        curated_variant_list_id: 89,
        show_pathogenicity: 1,
      },
      {
        type: "Knowledgebase",
        curated_variant_list_id: 90,
        show_pathogenicity: 1,
        overlap_length_threshold: 0.8,
        value: "90",
        name: "Decipher CNVs 2018-11 Benign, Likely benign variants",
        label: "Decipher CNVs 2018-11 Benign, Likely benign variants",
        variant_type: "CNV",
      },
    ],
    multiple: 1,
  },
  size: {
    values: [
      {
        label: "Any Size",
        value: "0",
      },
      {
        label: "Over 100bp",
        value: "100",
      },
      {
        value: "2000",
        label: "Over 2kbp",
      },
      {
        label: "Over 10kbp",
        value: "10000",
      },
      {
        label: "Over 25kbp",
        value: "25000",
      },
      {
        value: "50000",
        label: "Over 50kbp",
      },
      {
        value: "100000",
        label: "Over 100kbp",
      },
      {
        label: "Over 200kbp",
        value: "200000",
      },
      {
        label: "Over 500kbp",
        value: "500000",
      },
      {
        value: "1000000",
        label: "Over 1Mbp",
      },
      {
        value: "5000000",
        label: "Over 5Mbp",
      },
    ],
    title: "Size",
  },
  quality: {
    values: [
      {
        value: "0",
        label: "Any quality",
      },
      {
        label: "Over 50",
        value: "50",
      },
      {
        value: "100",
        label: "Over 100",
      },
      {
        label: "Over 200",
        value: "200",
      },
      {
        label: "Over 300",
        value: "300",
      },
      {
        label: "Over 400",
        value: "400",
      },
      {
        label: "Over 500",
        value: "500",
      },
      {
        label: "Over 600",
        value: "600",
      },
      {
        value: "700",
        label: "Over 700",
      },
      {
        label: "Over 800",
        value: "800",
      },
      {
        value: "900",
        label: "Over 900",
      },
      {
        value: "1000",
        label: "Over 1000",
      },
    ],
    title: "Quality",
  },
  inheritance: {
    multiple: 1,
    values: [
      {
        label: "paternal",
        value: "paternal",
      },
      {
        value: "maternal",
        label: "maternal",
      },
      {
        value: "biparental",
        label: "biparental",
      },
      {
        value: "de-novo",
        label: "de-novo",
      },
      {
        label: "phase unknown",
        value: "phase unknown",
      },
    ],
    title: "Inheritance",
  },
  subtype: {
    title: "Type",
    values: [
      {
        value: "SNP",
        label: "SNP",
      },
      {
        label: "InDel",
        value: "InDel",
      },
      {
        label: "Insertion",
        value: "Insertion",
      },
      {
        value: "Deletion",
        label: "Deletion",
      },
      {
        value: "CNV gain",
        label: "CNV gain",
      },
      {
        label: "CNV loss",
        value: "CNV loss",
      },
      {
        value: "Duplication",
        label: "Duplication",
      },
      {
        value: "Translocation",
        label: "Translocation",
      },
      {
        value: "Inversion",
        label: "Inversion",
      },
    ],
    multiple: 1,
  },
};
