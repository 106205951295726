// @flow
// This is the function which handles form level validation
// it takes an object which details the fields to build
// and an array of validation functions to call
import { isNil } from "ramda";

import chromosomeList from "../utils/enum/chromosomeList";

const validate =
  (
    fields: Array<{ name: string, validate: Array<(values?: string) => mixed> }>
  ) =>
  (values?: { [string]: string }): { [string]: string } => {
    // Define the initial object
    const errors = {};

    if (isNil(values)) return {};

    // Loop over the fields and build the individual error messages
    fields.forEach(({ name, validate = [] }) => {
      const error = [];

      // Run each validation function and push it into the error array
      validate.forEach(validationFn => {
        error.push(validationFn(values ? values[name] : values));
      });

      // convert the array to a string and set the value on the errors object
      errors[name] = error.join(", ");
    });

    // return all the errors for the fields
    return errors;
  };

export default validate;

// These are standard validation rules to use with redux form fields
// You use them by importing the rules you need and then
// passing them to the validate prop in an array like:
//    <Field validate={[required, numeric]} />

export const isTruthy = (message: string) => (value: mixed) =>
  value ? undefined : message;

export const required = isTruthy("This field is required");

export const normalisePositiveInteger = (value: any, previousValue: any) => {
  if (/^\d*$/.test(value)) {
    // positive integer and ''
    return value;
  }
  return previousValue;
};

// Validator function for positive and negative decimal numbers
// Used by redux-form, which expects valid fields to return 'undefined'
export const isNumber = (value: string) => {
  if (value) {
    return /^-?\d+(\.\d+)?$/.test(value) ? undefined : "Please enter a number";
  }
  return undefined;
};

// Validator function for positive and negative integers
// Used by redux-form, which expects valid fields to return 'undefined'
export const isInteger = (value: string) => {
  if (value) {
    return /^-?\d+$/.test(value) ? undefined : "Please enter a whole number";
  }
  return undefined;
};

const checkValueIsInRange = (value: string, min: number, max?: number) => {
  if (isNaN(value)) {
    return false;
  }

  const minToUse = parseFloat(min);
  const maxToUse = isNil(max) ? parseFloat(min + 1) : parseFloat(max);
  const valueToUse = parseFloat(value);

  if (isNil(max)) {
    return minToUse <= valueToUse && valueToUse < maxToUse;
  } else {
    return minToUse <= valueToUse && valueToUse <= maxToUse;
  }
};

// Normalisers
const normaliseNumbers =
  (min: number, max?: number) => (value: string, previousValue: any) => {
    if (/^0+\d/.test(value)) {
      //no leading zeros
      return previousValue;
    }

    if (checkValueIsInRange(value, min, max)) {
      return value;
    }

    if (min < 0 && value === "-") return "-";
    if (min >= 0 && value === "-") return "";
    if (value === "") return "";
    return previousValue;
  };

export const minAndMaxNumber = (min: number, max: number) => {
  if (min > max)
    throw new Error(
      "Minimum value cannot be greater than the maximum value to normalise"
    );

  return normaliseNumbers(min, max);
};

export const minNumber = (min: number) => normaliseNumbers(min);

export const normaliseGenomicRegion = (
  value: string,
  previousValue: string
) => {
  if (!value) {
    return value;
  } else if (!previousValue || value.length > previousValue.length) {
    // typing forward
    const prefixes: Array<string> = chromosomeList.map(chr => `chr${chr}:`);

    // test that the value string either starts with chrX: or one of c, ch,chr etc...
    const startMatches = prefixes.some(
      i => i.startsWith(value) || value.startsWith(i)
    );

    if (!startMatches) {
      return previousValue;
    }

    if (value.indexOf(":") !== -1) {
      if (value.indexOf("-") !== -1) {
        if (/^chr..?:\d+-\d*$/i.test(value) === false) {
          return previousValue;
        }
      } else {
        if (/^chr..?:\d*-?$/i.test(value) === false) {
          return previousValue;
        }
      }
    }
  }
  return value;
};
