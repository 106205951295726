import React from "react";

const HaploinsufficiencyIndex = ({ hiIndex, hiRgb }) => {
  if (!hiIndex) return "-";
  const [red, green, blue] = hiRgb || [];
  return (
    <span
      className="hi-index"
      style={{ "--red": red, "--green": green, "--blue": blue }}
    >
      {hiIndex}%
    </span>
  );
};

export default HaploinsufficiencyIndex;
