import React from "react";

import { Modal, ModalBody, ModalHeader } from "pattern-library";

export default ({ title, close, show, children, ...rest }) => (
  <Modal close={close} show={show} {...rest}>
    <ModalHeader close={close}>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
  </Modal>
);
