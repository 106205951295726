import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Icon } from "../../elements/base";

export default class InfoField extends PureComponent {
  static displayName = "InfoField";

  static propTypes = {
    /**
     * Text inside component
     */
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    /**
     * The type of icon to render
     */
    type: PropTypes.string,

    /**
     * The position of icon to render
     */
    position: PropTypes.oneOf(["left", "right"]),
  };

  render() {
    const { text, type, position, ...rest } = this.props;

    return (
      <div className="info-field">
        {type !== undefined && position === "left" && (
          <Icon type={type} {...rest} />
        )}
        <span
          className={classNames(
            "name",
            { "name-left-icon": position === "left" },
            { "name-right-icon": position === "right" }
          )}
        >
          {text}
        </span>
        {type !== undefined && position === "right" && (
          <Icon type={type} {...rest} />
        )}
      </div>
    );
  }
}
