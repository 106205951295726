import { takeLatest, take, put, call, all } from "redux-saga/effects";

import { readProject } from "modules/project/actions";
import { READ_PROJECT_SUCCESS } from "modules/project/constants";

import {
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from "../../../../common/constants";
import { error, success } from "../../../messages/actions";

import {
  filterPresetRemoved,
  setFilterPresets,
  setFilterPresetsRequestStatus,
  setPresetSubmitStatus,
  setSelectedPreset,
  setPresetRemoveStatus,
  setInheritanceSettings,
  setFilterDictionaries,
  setInheritanceSettingsSubmitStatus,
} from "./actions";
import {
  removeProjectPreset,
  requestFilterDictionaries,
  requestFilterPresets,
  requestInheritanceSettings,
  saveInheritanceSettings,
  saveProjectPreset,
} from "./apiUtils";
import {
  INIT_FILTER_PRESETS,
  REMOVE_FILTER_PRESET,
  SUBMIT_FILTER_PRESET,
  SUBMIT_INHERITANCE_SETTINGS,
} from "./constants";

export function* init() {
  yield takeLatest(INIT_FILTER_PRESETS, initFilterPresets);
  yield takeLatest(REMOVE_FILTER_PRESET, removeFilterPreset);
  yield takeLatest(SUBMIT_FILTER_PRESET, submitFilterPreset);
  yield takeLatest(SUBMIT_INHERITANCE_SETTINGS, submitInheritanceSettings);
}

export function* initFilterPresets({ meta: { projectId } }) {
  yield put(setFilterPresetsRequestStatus(IN_PROGRESS_STATUS));
  yield call(fetchInheritanceSettings, projectId);
  yield put(readProject(projectId, true));
  yield take(READ_PROJECT_SUCCESS);
  yield all([
    call(fetchFilterPresets, projectId),
    call(fetchFilterDictionaries, projectId),
  ]);
}

export function* fetchInheritanceSettings(projectId) {
  const response = yield call(requestInheritanceSettings, projectId);
  if (response.ok) {
    yield put(setInheritanceSettings(response.payload));
  } else {
    const m = response.error || "Unknown error";
    yield put(error(`Filter presets inheritance flag loading failed:\n${m}`));
    yield put(setInheritanceSettings({}));
  }
}

export function* fetchFilterPresets(projectId) {
  yield put(setFilterPresetsRequestStatus(IN_PROGRESS_STATUS));
  const response = yield call(requestFilterPresets, projectId);
  if (response.ok) {
    yield put(setFilterPresetsRequestStatus(SUCCESS_STATUS));
    yield put(setFilterPresets(response.payload.data));
  } else {
    const m = response.error || "Unknown error";
    yield put(error(`Filter presets loading failed:\n${m}`));
    yield put(setFilterPresetsRequestStatus(FAILURE_STATUS));
    yield put(setFilterPresets([]));
  }
}

export function* fetchFilterDictionaries(projectId) {
  const response = yield call(requestFilterDictionaries, projectId);
  if (response.ok) {
    yield put(setFilterDictionaries(response.payload));
  } else {
    const m = response.error || "Unknown error";
    yield put(error(`Filters values loading failed:\n${m}`));
    yield put(setFilterDictionaries([]));
  }
}

export function* removeFilterPreset({
  meta: { projectId, filterPresetId, filterPresetTitle },
}) {
  yield put(setPresetRemoveStatus(IN_PROGRESS_STATUS));
  const response = yield call(removeProjectPreset, projectId, filterPresetId);
  if (response.ok) {
    yield put(filterPresetRemoved(filterPresetId));
    yield put(setPresetRemoveStatus(SUCCESS_STATUS));
    yield put(success(`Filter preset '${filterPresetTitle}' deleted`));
  } else {
    const m = response.error || "Unknown error";
    yield put(error(`Filter preset remove failed:\n${m}`));
    yield put(setPresetRemoveStatus(FAILURE_STATUS));
  }
}

export function* submitFilterPreset({ meta: { projectId, filterPreset } }) {
  yield put(setPresetSubmitStatus(IN_PROGRESS_STATUS));
  const response = yield call(saveProjectPreset, projectId, filterPreset);
  if (response.ok) {
    yield put(setPresetSubmitStatus(SUCCESS_STATUS));
    yield call(fetchFilterPresets, projectId);
    yield put(setSelectedPreset(response.payload));
    yield put(success(`Filter preset '${response.payload.title}' saved`));
  } else {
    const m = response.error || "Unknown error";
    yield put(error(`Filter preset submit failed:\n${m}`));
    yield put(setPresetSubmitStatus(FAILURE_STATUS));
  }
}

export function* submitInheritanceSettings({
  meta: { projectId, isConfigurationInherited },
}) {
  yield put(setInheritanceSettingsSubmitStatus(IN_PROGRESS_STATUS));
  const response = yield call(
    saveInheritanceSettings,
    projectId,
    isConfigurationInherited
  );
  if (response.ok) {
    yield put(setInheritanceSettingsSubmitStatus(SUCCESS_STATUS));
    yield put(setInheritanceSettings(response.payload));
    yield call(fetchFilterPresets, projectId);
  } else {
    const m = response.error || "Unknown error";
    yield put(
      error(`Filter presets inheritance configuration submit failed:\n${m}`)
    );
    yield put(setInheritanceSettingsSubmitStatus(FAILURE_STATUS));
  }
}
