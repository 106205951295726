import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { Button, ButtonGroup, GeneButton } from "pattern-library";

export class GenePanelButton extends Component {
  static propTypes = {
    gene: PropTypes.object.isRequired,
    selectGene: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  selectGene() {
    const {
      selectGene,
      gene: { geneId },
    } = this.props;
    selectGene(geneId);
  }

  render() {
    const {
      gene,
      gene: { geneId, variantCount: { variant_count: varCount = "0" } = {} },
    } = this.props;

    return (
      <ButtonGroup
        size="sm"
        key={geneId}
        onClick={this.selectGene}
        className="btn-group--with-margin"
      >
        <GeneButton gene={gene} />
        <Button className="gene-panel-button" context="info">
          {varCount}
        </Button>
      </ButtonGroup>
    );
  }
}
