import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { getSingleACMGCriteria } from "../../selectors";
import { formatCriteriaName } from "../../utils";

export class Item extends PureComponent {
  static propTypes = {
    criteria: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      comment: PropTypes.string,
    }).isRequired,
    locked: PropTypes.bool,
    canEdit: PropTypes.bool,
    updateCriteriaToAdd: PropTypes.func,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleEdit() {
    const {
      updateCriteriaToAdd,
      criteria: { criteriaId },
    } = this.props;

    updateCriteriaToAdd(criteriaId);
  }

  render() {
    const {
      criteria,
      criteria: { description, comment },
      originalCriteria,
      locked = true,
      canEdit = true,
      disabled,
    } = this.props;

    const buttonProps = {
      disabled: disabled || locked || !canEdit,
      onClick: this.handleEdit,
    };

    return (
      <div className="list-group-item">
        <h5 className="list-group-item-heading">
          {formatCriteriaName(criteria, originalCriteria)}
        </h5>
        <p>
          <em>{description}</em>
        </p>
        {comment ? (
          <blockquote className="clearfix">
            <button
              type="button"
              {...buttonProps}
              className="btn btn-default pull-right"
            >
              Edit
            </button>
            <p>{comment}</p>
          </blockquote>
        ) : (
          <button type="button" {...buttonProps} className="btn btn-default">
            Edit
          </button>
        )}
      </div>
    );
  }
}

export default connect((state, props) => ({
  originalCriteria: getSingleACMGCriteria(state, props.criteria.criteriaId),
}))(Item);
