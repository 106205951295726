// @flow
import { set } from "dot-prop-immutable";

import { type Action, actionType } from "./actions";

export type State = {
  data: { +patientConfig?: any, +trackConfig: Array<any> },
};

const initialState: State = {
  data: { trackConfig: [] },
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case actionType.FETCH_TRACK_CONFIG_SUCCESS:
      return set(state, "data.trackConfig", action.payload);
    case actionType.FETCH_TRACK_CONFIG_FAILURE:
      return set(state, "data.trackConfig", []);
    default:
      return state;
  }
}
