import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Icon, LabelField, Link, Button, Tooltip } from "pattern-library";

import {
  exportCvlVariants,
  getCVLDetails,
  setResetDisplayMode,
} from "../actions";
import * as constants from "../constants";
import { canExportCVL } from "../selectors";
import { Curator, ProjectCuratedVariantList } from "../types";
import {
  formatCVLDate,
  getCvlTypeLabel,
  getLastCuratorEmailLink,
  getYesNoLabel,
  getExportButtonTooltip,
  shouldShowEligibleForAutomatedAnnotationDecisionsWarning,
} from "../utils";

const getCuratorsLabel = (curators: Array<Curator> = []) =>
  curators.map(({ fullName }) => fullName).join(", ");

const goToCVLInParentProject = (
  cvlId,
  originProjectId,
  getCVLDetails,
  setResetDisplayMode
) => {
  setResetDisplayMode(false);
  getCVLDetails(cvlId, originProjectId, originProjectId);
};

const getParentProjectField = (cvl, getCVLDetails, setResetDisplayMode) => {
  const {
    [constants.CAN_VIEW_IN_ORIGIN_PROJECT_FIELD]: canView,
    [constants.CAN_EDIT_IN_ORIGIN_PROJECT_FIELD]: canEdit,
    [constants.ORIGIN_PROJECT_ID_FIELD]: originProjectId,
    [constants.PARENT_PROJECT_FIELD]: originProjectName,
    [constants.ID_FIELD]: cvlId,
  } = cvl;
  const parentProjectLabel = "Parent project";
  const cvlUrlInParentProject = window.Sapientia
    ? `#my-projects/${originProjectId}/curated-variant-list`
    : `/projects/${originProjectId}/curated-variant-lists?cvlId=${cvlId}`;
  if (canEdit || canView) {
    return (
      <div className="form-group row">
        <label className="col-sm-6">{parentProjectLabel}</label>
        <div className="col-sm-6">
          <Link
            href={cvlUrlInParentProject}
            onClick={() =>
              goToCVLInParentProject(
                cvlId,
                originProjectId,
                getCVLDetails,
                setResetDisplayMode
              )
            }
          >
            {originProjectName}
            {canEdit && (
              <>
                &nbsp;
                <Icon type="pencil" />
              </>
            )}
          </Link>
        </div>
      </div>
    );
  }
  return <LabelField value={originProjectName} label={parentProjectLabel} />;
};

interface CVLViewFormProps extends PropsFromRedux {
  cvl: ProjectCuratedVariantList;
}

const getEligibleForAutomatedAnnotationDecisionsWarning = cvl =>
  shouldShowEligibleForAutomatedAnnotationDecisionsWarning(
    cvl[constants.AUTOMATION_FIELD],
    cvl[constants.ELIGIBLE_FOR_AUTOMATED_ANNOTATION_DECISIONS_FIELD]
  )
    ? {
        warning: (
          <>
            <i className="fa fa-warning" />{" "}
            {constants.ELIGIBLE_FOR_AUTOMATION_WARNING}
          </>
        ),
      }
    : null;

export const CVLViewForm = ({
  cvl,
  getCVLDetails,
  exportCvlVariants,
  canExport,
  setResetDisplayMode,
}: CVLViewFormProps) => (
  <div className="col-md-7" data-testid="cvl-view-form">
    <LabelField
      value={cvl[constants.NAME_FIELD]}
      label="Curated variant list name"
    />
    <LabelField value={cvl[constants.DESCRIPTION_FIELD]} label="Description" />
    <LabelField
      value={cvl[constants.VARIANTS_TYPE_FIELD]}
      label="Variants type"
    />
    {cvl[constants.PARENT_PROJECT_FIELD] &&
      getParentProjectField(cvl, getCVLDetails, setResetDisplayMode)}
    <LabelField
      value={getYesNoLabel(cvl[constants.AUTOMATION_FIELD])}
      label="Automation"
      {...getEligibleForAutomatedAnnotationDecisionsWarning(cvl)}
    />
    <LabelField
      value={getCvlTypeLabel(cvl[constants.LIST_TYPE_FIELD])}
      label="List type"
    />
    <LabelField
      value={getYesNoLabel(cvl[constants.SHOW_PATHOGENICITY_FIELD])}
      label="Show pathogenicity"
    />
    <LabelField
      value={getCuratorsLabel(cvl[constants.CURATORS_FIELD])}
      label="Curators"
    />
    <LabelField value={cvl[constants.PROJECT_COUNT_FIELD]} label="Projects" />
    <div className="form-group row">
      <label className="col-sm-6">Owner</label>
      <div className="col-sm-6">
        <Link
          href={`mailto:${
            cvl[constants.OWNER_EMAIL_FIELD]
          }?subject=About the Congenica curated list '${
            cvl[constants.NAME_FIELD]
          }'&body=Dear ${cvl[constants.OWNER_NAME_FIELD]},`}
        >
          {cvl[constants.OWNER_NAME_FIELD]}
        </Link>
      </div>
    </div>
    <LabelField
      value={formatCVLDate(cvl[constants.LAST_UPDATED_FIELD])}
      label="Last updated"
    />
    <div className="form-group row">
      <label className="col-sm-6">Last curated by</label>
      <div className="col-sm-6">{getLastCuratorEmailLink(cvl)}</div>
    </div>
    <LabelField
      value={cvl[constants.VARIANT_COUNT_FIELD]}
      label="Number of variants"
    />
    {canExport && (
      <Tooltip
        content={getExportButtonTooltip(cvl.variantCount)}
        placement="right"
      >
        <div className="curated-lists-export">
          <Button
            type="button"
            context="primary"
            onClick={() =>
              cvl.curatedVariantListId
                ? exportCvlVariants(cvl.curatedVariantListId)
                : null
            }
            disabled={cvl.variantCount === 0}
            data-testid="export"
          >
            <Icon type="downloadAlt" /> Export
          </Button>
        </div>
      </Tooltip>
    )}
  </div>
);

const mapStateToProps = (state, { cvl }) => ({
  canExport: canExportCVL(state, cvl),
});

const mapDispatchToProps = {
  exportCvlVariants,
  getCVLDetails,
  setResetDisplayMode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLViewForm);
