import { camelizeKeys } from "humps";

import { ENTITY_NOT_FOUND } from "modules/utils";

import { variantsRequest } from "./api";

export class VariantService {
  constructor() {
    this.worker = new Worker("/web-workers/ref-no-call.js");
    this._totalCount = 0;
  }

  async initialize(patientId) {
    const response = await variantsRequest(patientId);
    if (response.ok) {
      const { payload } = await this.processMessage({
        type: "SET_INITIAL_DATA",
        payload: response.payload,
      });
      this._totalCount = payload.totalCount;
    } else {
      const { errors = [] } = response.payload || {};
      const entityNotFoundError = errors.find(
        ({ code }) => +code === ENTITY_NOT_FOUND
      );
      if (entityNotFoundError) {
        await this.processMessage({
          type: "SET_INITIAL_DATA",
          payload: { data: [] },
        });
        this._totalCount = 0;
      } else {
        throw new Error(
          `Cannot load variants${
            response.statusText ? "\nDetails: " + response.statusText : ""
          }`
        );
      }
    }
  }

  async getVariants(params) {
    const { payload } = await this.processMessage({
      type: "GET_VARIANTS",
      payload: params,
    });
    this._totalCount = payload.totalCount;
    return this.formatVariantList(payload.variants);
  }

  processMessage(message) {
    return new Promise((resolve, reject) => {
      try {
        this.worker.postMessage(message);
        this.worker.addEventListener(
          "message",
          ({ data }) => {
            if (data.type.startsWith(message.type)) {
              resolve(data);
            }
          },
          false
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  terminate() {
    this.worker.terminate();
  }

  formatVariantList(variants) {
    return variants.map(({ attributes }) =>
      camelizeKeys(
        Object.fromEntries(
          Object.entries(attributes).map(([k, v]) => [k.toLowerCase(), v])
        )
      )
    );
  }

  getTotalCount() {
    return this._totalCount;
  }
}
