import classNames from "classnames";
import React, { memo } from "react";

export interface QCStatusProps {
  filter?: Array<string>;
  className?: string;
}

const QCStatus = ({ filter = [], className }: QCStatusProps) => (
  <>
    {filter.map(singleFilter => (
      <span
        key={singleFilter}
        className={classNames("label label-default tag", className)}
      >
        {singleFilter}
      </span>
    ))}
  </>
);

export default memo(QCStatus);
