import React, { FC, memo, useEffect, useRef, useState } from "react";

import { fetchData } from "modules/utils";

import { FetchDataResponse } from "../../utils/fetchData";
import { loadFastQC } from "../fastqc";

interface Props {
  patientId: number;
}

export const FastQC: FC<Props> = memo(({ patientId }: Props) => {
  const [fullFastQCJson, setFullFastQCJson] = useState<FastQCResponse>({});
  const fastQCPicker = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    const loadData = async () => {
      const result: FetchDataResponse<FastQCResponse> = await fetchData(
        `/patient/${patientId}/qc/fastqc/json`
      );
      if (result.ok && result.payload) {
        setFullFastQCJson(result.payload);
        if (fastQCPicker.current)
          loadFastQC(result.payload[fastQCPicker.current.value]);
      }
    };
    if (fastQCPicker) loadData();
  }, [fastQCPicker, patientId]);

  const onFastQCChange = ({ target: { value } }) => {
    loadFastQC(fullFastQCJson[value]);
  };

  return (
    <>
      <div className="fastqc">
        <div id="fastqc-stats" className="row">
          <h2>FastQC Stats</h2>
          <div className="col-md-9">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th>File</th>
                  <td>
                    <select
                      aria-label="fastqc-picker"
                      id="fastqc-picker"
                      className="form-control disabled"
                      onChange={onFastQCChange}
                      ref={fastQCPicker}
                    >
                      {Object.keys(fullFastQCJson).map(i => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>File type</th>
                  <td data-json-source="Basic Statistics.contents.Value.File type" />
                </tr>
                <tr>
                  <th>Encoding</th>
                  <td data-json-source="Basic Statistics.contents.Value.Encoding" />
                </tr>
                <tr>
                  <th>Total Sequences</th>
                  <td data-json-source="Basic Statistics.contents.Value.Total Sequences" />
                </tr>
                <tr>
                  <th>Sequences flagged as poor quality</th>
                  <td data-json-source="Basic Statistics.contents.Value.Sequences flagged as poor quality" />
                </tr>
                <tr>
                  <th>Read Length (bp)</th>
                  <td data-json-source="Basic Statistics.contents.Value.Sequence length" />
                </tr>
                <tr>
                  <th>%GC</th>
                  <td data-json-source="Basic Statistics.contents.Value.%GC" />
                </tr>
              </tbody>
            </table>
          </div>
          <div id="pass-meter" className="col-md-3">
            <svg />
          </div>
        </div>

        <div
          id="bp-sequence-quality"
          className="method"
          data-method-passfail-jsonlookup="Per base sequence quality.status"
        >
          <h2>
            Per base sequence quality <small />
          </h2>
          <svg />
        </div>

        <div
          id="quality-score"
          className="method"
          data-method-passfail-jsonlookup="Per read quality scores.status"
        >
          <h2>
            Per sequence quality scores <small />
          </h2>
          <svg />
        </div>

        <div
          id="base-content"
          className="method"
          data-method-passfail-jsonlookup="Per base sequence content.status"
        >
          <h2>
            Per base sequence content <small />
          </h2>
          <svg />
        </div>

        <div
          id="sequence-length"
          className="method"
          data-method-passfail-jsonlookup="Sequence Length Distribution.status"
        >
          <h2>
            Read length distribution <small />
          </h2>
          <svg />
        </div>

        <div
          id="gc-content"
          className="method"
          data-method-passfail-jsonlookup="Per sequence GC content.status"
        >
          <h2>
            Per sequence GC content <small />
          </h2>
          <svg />
        </div>

        <div
          id="n-content"
          className="method"
          data-method-passfail-jsonlookup="Per base N content.status"
        >
          <h2>
            Per base N content <small />
          </h2>
          <svg />
        </div>

        <div
          id="sequence-duplications"
          className="method"
          data-method-passfail-jsonlookup="Sequence Duplication Levels.status"
        >
          <h2>
            Sequence Duplication Levels <small />
          </h2>
          <svg />
        </div>

        <div
          id="adapter-content"
          className="method"
          data-method-passfail-jsonlookup="Adapter Content.status"
        >
          <h2>
            Adapter Content <small />
          </h2>
          <svg />
        </div>

        <div
          id="kmer-content"
          className="method"
          data-method-passfail-jsonlookup="Kmer Content.status"
        >
          <h2>
            Kmer Content <small />
          </h2>
          <table className="table table-striped">
            <thead>
              <tr data-json-rowkeysource="Kmer Content.contents.PValue.$keys">
                <th data-json-rowsource="$key">Sequence</th>
                <th data-json-rowsource="Kmer Content.contents.Count.$key">
                  Count
                </th>
                <th data-json-rowsource="Kmer Content.contents.PValue.$key">
                  PValue
                </th>
                <th data-json-rowsource="Kmer Content.contents.Obs/Exp Max.$key">
                  Obs/Exp Max
                </th>
                <th data-json-rowsource="Kmer Content.contents.Max Obs/Exp Position.$key">
                  Max Obs/Exp Position
                </th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </>
  );
});
