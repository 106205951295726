// @flow
import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import { actionType } from "./actions";
import type { User, UserInfo } from "./flow-types";

type State = {
  +users: {
    projectUsers: Array<User>,
    inheritedUsers: Array<User>,
  },
  +existingUsers: { loading: boolean, data: Array<User> },
  +ui: {
    +userInfo: UserInfo,
    +currentUserAuthProviders: Array<AuthProvider>,
    loading: boolean,
    doubleUserCheck: boolean,
    existingUserLoading: boolean,
  },
};

export const initialState: State = {
  ui: {
    userInfo: {},
    loading: false,
    doubleUserCheck: false,
    currentUserAuthProviders: [],
    existingUserLoading: false,
  },
  users: {
    projectUsers: [],
    inheritedUsers: [],
  },
  existingUsers: { loading: false, data: [] },
};

const setValue = (path: string, value: any) => state =>
  set(state, `${path}`, value);

const setUserInfo = userInfo => setValue("ui.userInfo", userInfo);

export default function reducer(
  state: State = initialState,
  { type, payload }: Action
): State {
  switch (type) {
    //----PROJECT_USERS----
    case actionType.FETCH_USERS_START:
      return [
        setUserInfo({}),
        setValue("users.projectUsers", []),
        setValue("users.inheritedUsers", []),
      ].reduce((state, f) => f(state), { ...state });

    case actionType.FETCH_USERS_SUCCESS:
      return [
        setValue("users.projectUsers", payload.projectUsers),
        setValue("users.inheritedUsers", payload.inheritedUsers),
      ].reduce((state, f) => f(state), { ...state });

    case actionType.FETCH_USERS_FAILURE:
      return [
        setValue("users.projectUsers", []),
        setValue("users.inheritedUsers", []),
      ].reduce((state, f) => f(state), { ...state });

    //----EXISTING_USERS----
    case actionType.FETCH_EXISTING_USERS_START:
      return [
        setValue("existingUsers.data", []),
        setValue("existingUsers.loading", true),
      ].reduce((state, f) => f(state), { ...state });

    case actionType.FETCH_EXISTING_USERS_SUCCESS:
      return [
        setValue("existingUsers.data", payload),
        setValue("existingUsers.loading", false),
      ].reduce((state, f) => f(state), { ...state });

    case actionType.FETCH_EXISTING_USERS_FAILURE:
      return [
        setValue("existingUsers.data", []),
        setValue("existingUsers.loading", false),
      ].reduce((state, f) => f(state), { ...state });
    //----CURRENT USER AUTH PROVIDERS----
    case actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_SUCCESS:
      return [setValue("ui.currentUserAuthProviders", payload)].reduce(
        (state, f) => f(state),
        { ...state }
      );
    case actionType.FETCH_CURRENT_USER_AUTH_PROVIDERS_FAILURE:
      return [setValue("ui.currentUserAuthProviders", [])].reduce(
        (state, f) => f(state),
        { ...state }
      );
    //------------

    case actionType.SET_USER_INFO:
      return setUserInfo(payload)(state);

    case actionType.SET_PROJECT_USERS_LOADING:
      return set(state, "ui.loading", payload);

    case actionType.SET_DOUBLE_USER_CHECK:
      return set(state, "ui.doubleUserCheck", payload);

    case actionType.FETCH_EXISTING_USER_DETAILS_START:
      return set(state, "ui.existingUserLoading", true);

    case actionType.FETCH_EXISTING_USER_DETAILS_SUCCESS:
      return set(state, "ui.existingUserLoading", false);

    case actionType.FETCH_EXISTING_USER_DETAILS_FAILURE:
      return set(state, "ui.existingUserLoading", false);

    default:
      return state;
  }
}
