import PropTypes from "prop-types";
import React, { PureComponent, createContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as actions from "../actions";
import * as selectors from "../selectors";

import HPOTermListItem from "./HPOTermListItem";
import HPOTermsNode from "./HPOTermsNode";

export const SelectedHPOTermsContext = createContext("SelectedHPOTermsContext");

export class HPOTermList extends PureComponent {
  static propTypes = {
    root: PropTypes.shape({
      data: PropTypes.object,
      children: PropTypes.array.isRequired,
    }).isRequired,
    toggleTermAsVisible: PropTypes.func.isRequired,
  };

  render() {
    const { query, selectedHPOTerms, ...rest } = this.props;

    return (
      <SelectedHPOTermsContext.Provider value={{ query, selectedHPOTerms }}>
        <div className="hpo-terms-tree">
          <HPOTermsNode {...rest} RenderComponent={HPOTermListItem} />
        </div>
      </SelectedHPOTermsContext.Provider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  root: selectors.getHpoTermsTree,
  expandedNodeIds: selectors.getExpandedNodeIds,
  visibleNodeIds: selectors.getVisibleNodeIds,
  query: selectors.getLowerCaseQuery,
  selectedHPOTerms: selectors.selectedHPOTermsSelector,
});

const mapDispatchToProps = {
  onDropped: actions.tryAddHpoTerm,
  toggleTermAsVisible: actions.toggleTermAsVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(HPOTermList);
