import { Track } from "../core/Log2Ratio";
import { getRelativeTrackBaseConfig } from "../utils";

const axesLimits = [-2, 2];

const trackInfo = `The Log2Ratio is calculated as the ratio between the observed normalized coverage of the target sample and the expected coverage (reference samples). The values are capped between ${JSON.stringify(
  axesLimits
)}`;

const getTrackConfig = ({ patientId } = {}) => ({
  id: "Congenica.Log2Ratio",
  namespace: "Congenica.Log2Ratio",
  name: "Log2Ratio",
  info: trackInfo,
  url: `/webapi/entities/patient/${patientId}/log2ratios_for_region?chr=__CHR__&start=__START__&end=__END__`,
  range: axesLimits,
  capValues: true,
  0: {
    scatterSize: 2.5,
  },
  10000: {
    scatterSize: 1.5,
  },
  1000000: {
    scatterSize: 1,
  },
  10000000: {
    scatterSize: 0.15,
  },
});

export const Log2Ratio = config => Track.extend(getTrackConfig(config));

export const RelativeLog2Ratio = ({
  patient: { patientId, reference } = {},
  relation,
} = {}) =>
  Track.extend({
    ...getTrackConfig({ patientId }),
    ...getRelativeTrackBaseConfig("Congenica.Log2Ratio", relation, patientId),
    name: `${relation} (${reference}): Log2Ratio`,
    configName: ["Log2Ratio", `Relation: ${relation} (${reference})`],
    info: `Log2ratio for patients relative: ${trackInfo}`,
  });
