import React from "react";

import InstructionsLink from "./components/regulatoryInfo/InstructionsLink";
import ModuleVersion from "./components/regulatoryInfo/ModuleVersion";
import {
  ADDRESS_TEXT,
  EC_REP_TEXT,
  EU_REGION,
  IVD_TEXT,
  TRAINED_PERSONNEL_WARNING_TEXT,
} from "./constants";

export const getCEMarkings = region => {
  const ceMarkings = [
    {
      icon: "ceMark",
      text: <ModuleVersion />,
      key: "ModuleVersion",
    },
    {
      icon: "instructions",
      text: <InstructionsLink />,
      key: "InstructionsLink",
    },
    {
      icon: "ivd",
      text: IVD_TEXT,
    },
    {
      icon: "caution",
      text: TRAINED_PERSONNEL_WARNING_TEXT,
    },
    {
      icon: "manufacturer",
      text: ADDRESS_TEXT,
    },
  ];
  if (region === EU_REGION) {
    ceMarkings.push({
      icon: "ecRep",
      text: EC_REP_TEXT,
    });
  }
  return ceMarkings.map(info => ({
    ...info,
    className: "icon-large",
  }));
};
