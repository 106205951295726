import React from "react";
import { Field } from "redux-form";

import { ReduxFormField } from "modules/forms/components";
import { configForm, TIER_OPTIONS } from "modules/str/constants";

const tierOptions = Object.keys(TIER_OPTIONS)
  .sort()
  .map(key => ({ key, label: TIER_OPTIONS[key] }));

export default () => (
  <Field
    component={ReduxFormField}
    type="checkboxgroup"
    label="Tiers"
    name={configForm.FILTER_TIERS}
    options={tierOptions}
  />
);
