// @flow
import { set } from "dot-prop-immutable";

import {
  FAILURE_STATUS,
  IDLE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";

import * as constants from "./constants";
import type { State } from "./flow-types";

export const initialState: State = {
  ui: { feedbackSubmittingState: IDLE_STATUS },
  data: { bugReportDraft: null, feedbackDraft: null },
};

export default function reducer(
  state: State = initialState,
  action: { payload: any, type?: string } = { payload: {} }
) {
  const { payload, type } = action;

  switch (type) {
    case constants.SAVE_FEEDBACK_DRAFT: {
      const { draftType, draftData } = payload;
      return set(state, `data.${draftType + "Draft"}`, draftData);
    }

    case constants.RESET_FEEDBACK_DRAFT: {
      const { draftType } = payload;
      return set(state, `data.${draftType + "Draft"}`, null);
    }

    case constants.SUBMIT_FEEDBACK:
      return set(state, "ui.feedbackSubmittingState", IN_PROGRESS_STATUS);

    case constants.SUBMIT_FEEDBACK_SUCCESS:
      return set(state, "ui.feedbackSubmittingState", SUCCESS_STATUS);

    case constants.SUBMIT_FEEDBACK_FAILURE:
      return set(state, "ui.feedbackSubmittingState", FAILURE_STATUS);

    case constants.RESET_SUBMIT_FEEDBACK_STATUS:
      return set(state, "ui.feedbackSubmittingState", IDLE_STATUS);

    default:
      return state;
  }
}
