import { createAction } from "../utils";

import * as constants from "./constants";
import type { DictionaryItem } from "./types";

export type InitIRAction = FSA<typeof constants.INIT_IR, { projectId: number }>;

export const initIR = (projectId: number): InitIRAction =>
  createAction(constants.INIT_IR, { projectId });

export type SubmitIRAction = FSA<typeof constants.SUBMIT_IR, any>;

export const submitInterpretationRequest = (values: any): SubmitIRAction =>
  createAction(constants.SUBMIT_IR, values);

type SetProjectRequestStatusAction = FSA<
  typeof constants.SET_PROJECT_REQUEST_STATUS,
  RequestSubmissionStatus
>;

export const setProjectRequestStatus = (
  status: RequestSubmissionStatus
): SetProjectRequestStatusAction =>
  createAction(constants.SET_PROJECT_REQUEST_STATUS, status);

type SetIRSubmissionStatusAction = FSA<
  typeof constants.SET_IR_SUBMISSION_STATUS,
  RequestSubmissionStatus
>;

export const setIRSubmissionStatus = (
  status: RequestSubmissionStatus
): SetIRSubmissionStatusAction =>
  createAction(constants.SET_IR_SUBMISSION_STATUS, status);

type ResetIRSubmissionStatusAction = FSA<
  typeof constants.RESET_IR_SUBMISSION_STATUS
>;

export const resetIRSubmissionStatus = (): ResetIRSubmissionStatusAction =>
  createAction(constants.RESET_IR_SUBMISSION_STATUS);

type SetProtocolsAction = FSA<
  typeof constants.SET_PROTOCOLS,
  Array<DictionaryItem>
>;

export const setProtocols = (
  protocols: Array<DictionaryItem>
): SetProtocolsAction => createAction(constants.SET_PROTOCOLS, protocols);

type SetSampleTypesAction = FSA<
  typeof constants.SET_SAMPLE_TYPES,
  Array<DictionaryItem>
>;

export const setSampleTypes = (
  sampleTypes: Array<DictionaryItem>
): SetSampleTypesAction =>
  createAction(constants.SET_SAMPLE_TYPES, sampleTypes);

type SetCNVCallingRequestStatusAction = FSA<
  typeof constants.SET_CNV_CALLING_REQUEST_STATUS,
  StatusPayload
>;

export const setCNVCallingRequestStatus = (
  requestStatus: RequestSubmissionStatus,
  error?: string
): SetCNVCallingRequestStatusAction =>
  createAction(constants.SET_CNV_CALLING_REQUEST_STATUS, {
    requestStatus,
    error,
  });

type ResetCNVCallingStatusAction = FSA<
  typeof constants.RESET_CNV_CALLING_REQUEST_STATUS
>;

export const resetCNVCallingRequestStatus = (): ResetCNVCallingStatusAction =>
  createAction(constants.RESET_CNV_CALLING_REQUEST_STATUS);

type SetBaitsetsAction = FSA<typeof constants.SET_BAITSETS, Array<string>>;

export const setBaitsets = (baitsets: Array<string>): SetBaitsetsAction =>
  createAction(constants.SET_BAITSETS, baitsets);

type SetFilesProcessingAction = FSA<
  typeof constants.SET_FILES_PROCESSING,
  boolean
>;

export const setFilesProcessing = (
  filesProcessing: boolean
): SetFilesProcessingAction =>
  createAction(constants.SET_FILES_PROCESSING, filesProcessing);

export type Action =
  | InitIRAction
  | SubmitIRAction
  | SetProjectRequestStatusAction
  | SetIRSubmissionStatusAction
  | ResetIRSubmissionStatusAction
  | SetSampleTypesAction
  | SetProtocolsAction
  | SetCNVCallingRequestStatusAction
  | ResetCNVCallingStatusAction
  | SetBaitsetsAction
  | SetFilesProcessingAction;
