import classnames from "classnames";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Button,
  LoadingOverlay,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "pattern-library";

import { createProject } from "modules/api/projects";
import {
  checkedForLoggedInUser,
  isCurrentUserAdmin,
} from "modules/auth/selectors";
import {
  readProjects,
  readProjectTypesForChildProjects,
} from "modules/projects/actions";
import {
  getProjectTypesForChildProjects,
  projectTypesForChildProjectsLoaded,
} from "modules/projects/selectors";
import { processErrors } from "modules/utils";

import { error, success } from "../../../messages/actions";
import ProjectDetailsEditor, {
  getAddValidationSchema,
} from "../projectDetails/ProjectDetailsEditor";

import styles from "./AddProject.module.scss";

export const AddProject = ({
  projectId,
  projectName,
  isCurrentUserSuperAdmin,
  error,
  success,
  readProjects,
  readProjectTypesForChildProjects,
  projectTypesForChildProjectsLoaded,
  projectTypesForChildProjects,
  isInitialDataLoaded,
  onProjectAdded,
}) => {
  const addProjectPhrase = `Add project under ${projectName}`;

  const [isShowModal, setShowModal] = useState(false);

  useEffect(() => {
    readProjectTypesForChildProjects(projectId);
  }, [readProjectTypesForChildProjects, projectId]);

  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async values => {
    const response = await createProject(values, projectId);

    if (!response.ok) {
      const { errors } = await response.json();
      error(`Error creating a new project${processErrors(errors)}`);
      return;
    }
    success("New Project saved successfully");

    const {
      data: { id },
    } = await response.json();

    // Handle behaviour when adding a Project in legacy environment (Perl & Sapientia.js)
    // TODO: SAP-17642 - clean up this component and old behaviour
    if (!isNil(window.Sapientia)) {
      // Reload projects
      const hash = ["my-projects", id, "patients"].join("/");
      window.Sapientia.setURL("hash", hash);
      window.Sapientia.getContent(".project");
      window.Sapientia.updateURL();
      return;
    }

    hideModal();
    // TODO: Implement more efficient way to refresh projects, with better state management
    // This would mean when adding a project, we just append the response of the newly created project to the state/array
    // and we don't have to re-call the API to get the latest
    readProjects();
    onProjectAdded(id);
  };

  return (
    <>
      <Button
        id="btn-add-project"
        className={classnames("btn btn-primary add", styles.component)}
        onClick={showModal}
      >
        {addProjectPhrase}
      </Button>
      {isShowModal && (
        <Modal show close={hideModal} className="addProjectModal">
          <LoadingOverlay
            loading={
              projectTypesForChildProjectsLoaded !== true ||
              isInitialDataLoaded !== true
            }
          >
            <Formik
              initialValues={{
                code: "",
                description: "",
                projectTypeInternalName:
                  projectTypesForChildProjects.length === 1
                    ? projectTypesForChildProjects[0].internalName
                    : undefined,
              }}
              enableReinitialize
              validationSchema={getAddValidationSchema(
                isCurrentUserSuperAdmin,
                projectTypesForChildProjects
              )}
              id="project"
              onSubmit={handleSubmit}
            >
              {({ dirty, values: { projectTypeInternalName } }) => (
                <Form>
                  <ModalHeader
                    key="leave-form-warning-header"
                    close={hideModal}
                  >
                    <h3>{addProjectPhrase}</h3>
                  </ModalHeader>
                  <ModalBody key="leave-form-warning-body">
                    <span
                      className="display-help"
                      data-help-name="Project/Add"
                    />
                    <ProjectDetailsEditor
                      projectType={projectTypeInternalName}
                    />
                  </ModalBody>
                  <ModalFooter key="leave-form-warning-footer">
                    <Button onClick={hideModal} type="button">
                      Close
                    </Button>
                    <Button context="primary" type="submit" disabled={!dirty}>
                      Save
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </LoadingOverlay>
        </Modal>
      )}
    </>
  );
};

AddProject.displayName = "AddProject";

AddProject.propTypes = {
  /**
   * The id of a selected project
   */
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The name of a selected project
   */
  projectName: PropTypes.string,
  /**
   * redux error action creator
   */
  error: PropTypes.func,
  /**
   * redux success action creator
   */
  success: PropTypes.func,
};

const mapStateToProps = state => ({
  isCurrentUserSuperAdmin: isCurrentUserAdmin(state),
  projectTypesForChildProjectsLoaded: projectTypesForChildProjectsLoaded(state),
  projectTypesForChildProjects: getProjectTypesForChildProjects(state),
  isInitialDataLoaded: checkedForLoggedInUser(state),
});

const mapDispatchToProps = {
  success,
  error,
  readProjects,
  readProjectTypesForChildProjects,
};

const ConnectedWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProject);

export default ConnectedWrapper;
