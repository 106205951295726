import React, { memo, useCallback, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Button, Icon } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { addGeneToGenePanel, setGeneFormFieldValue } from "../../../../actions";
import {
  getSearchGeneQuery,
  getSearchGeneResult,
  getSearchGeneFieldValue,
  getSearchGeneFields,
  isGenesSearchInProgress,
} from "../../../../selectors";

const highlightSearchResult = (text, query) => {
  if (!text || !text.replaceAll) return null;
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text.replaceAll(
          query.toUpperCase(),
          `<strong>${query.toUpperCase()}</strong>`
        ),
      }}
    />
  );
};

const tableFieldConnector = connect(
  (state, ownProps: TableFieldOwnProps) => ({
    value: getSearchGeneFieldValue(
      state,
      ownProps.geneName,
      ownProps.fieldName
    ),
  }),
  { setGeneFormFieldValue }
);

type TableFieldPropsFromRedux = ConnectedProps<typeof tableFieldConnector>;
interface TableFieldOwnProps {
  geneName: string;
  fieldName: string;
}

const TableField = tableFieldConnector(
  ({
    geneName,
    fieldName,
    value,
    setGeneFormFieldValue,
  }: TableFieldOwnProps & TableFieldPropsFromRedux) => {
    const handleChange = useCallback(() => {
      setGeneFormFieldValue(geneName, fieldName, !!value ? 0 : 1);
    }, [setGeneFormFieldValue, geneName, fieldName, value]);
    return (
      <div style={{ textAlign: "center" }}>
        <input type="checkbox" checked={!!value} onChange={handleChange} />
      </div>
    );
  }
);

const addGeneButtonConnector = connect(
  (state, ownProps: AddGeneButtonOwnProps) => ({
    fields: getSearchGeneFields(state, ownProps.geneName),
  }),
  { addGeneToGenePanel: addGeneToGenePanel.start }
);

type AddGeneButtonPropsFromRedux = ConnectedProps<
  typeof addGeneButtonConnector
>;
interface AddGeneButtonOwnProps {
  geneName: string;
  projectId: number;
  genePanelId: number;
}
const AddGeneButton = addGeneButtonConnector(
  ({
    projectId,
    genePanelId,
    geneName,
    fields,
    addGeneToGenePanel,
  }: AddGeneButtonOwnProps & AddGeneButtonPropsFromRedux) => {
    const [submitting, setSubmitting] = useState(false);
    const handleClick = useCallback(() => {
      addGeneToGenePanel(projectId, genePanelId, geneName, fields);
      setSubmitting(true);
    }, [projectId, genePanelId, geneName, fields, addGeneToGenePanel]);

    return (
      <Button
        className="btn-xs"
        context={submitting ? "danger" : "primary"}
        disabled={submitting}
        onClick={handleClick}
      >
        {submitting ? (
          "Adding gene..."
        ) : (
          <>
            {" "}
            <Icon type="plus" /> Add gene
          </>
        )}
      </Button>
    );
  }
);

interface SearchGenesTableProps extends SearchGenesTablePropsFromRedux {
  projectId: number;
  genePanelId: number;
}
const SearchGenesTable = memo(
  ({
    genes,
    query,
    projectId,
    genePanelId,
    isGenesSearchInProgress,
  }: SearchGenesTableProps) => {
    const columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "name",
          id: "name",
          headerStyle: { width: "81px" },
          Cell: ({ row: { original } }) => {
            const { name } = original;
            return highlightSearchResult(name || "", query);
          },
        },
        {
          Header: "Old",
          accessor: "oldName",
          headerStyle: { width: "108px" },
          Cell: ({ row: { original } }) => {
            const { oldName } = original;
            return highlightSearchResult(oldName || "", query);
          },
        },
        {
          Header: "Synonyms",
          accessor: "synonyms",
          Cell: ({ row: { original } }) => {
            const { synonyms } = original;
            return highlightSearchResult(synonyms || "", query);
          },
        },
        {
          Header: "Autosomal Recessive",
          id: "autosomalRecessive",
          headerStyle: { width: "71px" },
          Cell: ({ row: { original } }) => {
            const { name, autosomalRecessive, inPanel } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  {autosomalRecessive ? <Icon type="tick" /> : null}
                </div>
              );
            }
            return (
              <TableField geneName={name} fieldName="autosomal_recessive" />
            );
          },
        },
        {
          Header: "Autosomal Dominant",
          id: "autosomalDominant",
          headerStyle: { width: "71px" },
          Cell: ({ row: { original } }) => {
            const { name, autosomalDominant, inPanel } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  {autosomalDominant ? <Icon type="tick" /> : null}
                </div>
              );
            }
            return (
              <TableField geneName={name} fieldName="autosomal_dominant" />
            );
          },
        },
        {
          Header: "X Linked",
          id: "xLinked",
          headerStyle: { width: "71px" },
          Cell: ({ row: { original } }) => {
            const { name, inPanel, xLinked } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  {xLinked ? <Icon type="tick" /> : null}
                </div>
              );
            }
            return <TableField geneName={name} fieldName="x_linked" />;
          },
        },
        {
          Header: "Actions",
          id: "actions",
          headerStyle: { width: "79px" },
          Cell: ({ row: { original } }) => {
            const { name, inPanel } = original;
            if (inPanel) {
              return (
                <div style={{ textAlign: "center" }}>
                  <Icon type="tick" /> In Panel
                </div>
              );
            }

            return (
              <AddGeneButton
                projectId={projectId}
                genePanelId={genePanelId}
                geneName={name}
              />
            );
          },
        },
      ],
      [query, projectId, genePanelId]
    );

    return (
      <div>
        <Table
          showTitleInfo
          title={`Gene match [${query}]`}
          columns={columns}
          data={genes}
          showPagination={genes.length > DEFAULT_PAGE_SIZE}
          autoResetPage={false}
          loading={isGenesSearchInProgress}
          sortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
      </div>
    );
  }
);

const mapStateToProps = state => ({
  genes: getSearchGeneResult(state),
  query: getSearchGeneQuery(state),
  isGenesSearchInProgress: isGenesSearchInProgress(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SearchGenesTablePropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchGenesTable);
