import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import { connect } from "react-redux";

import { Icon, Select } from "pattern-library";

import { VIEW_PRESET_INFORMATION } from "../../common/constants";
import { FilterPresetOptionProp } from "../utils/prop-types/FilterPresetOptionProp";

import { selectSNVPreset } from "./actions";
import SNVPresetInfoModal from "./components/SNVPresetInfoModal";
import * as selectors from "./selectors";

export const SNVPresetsContainer = memo(
  ({
    presetOptions = [],
    selectedPresetOption,
    selectSNVPreset,
    patientId,
    isPresetCustomized = false,
  }) => {
    const [showPresetModal, setPresetModalOpen] = useState(false);
    const openPresetsModal = () => {
      setPresetModalOpen(true);
    };

    const closePresetsModal = () => {
      setPresetModalOpen(false);
    };

    const onChange = selection => {
      selectSNVPreset(selection.value, patientId);
    };

    return (
      <div className="snv-presets">
        <div>
          <strong>Filter Presets</strong>
        </div>
        <div className="d-flex snv-presets-wrapper">
          <div className="d-flex">
            <div className="snv-presets-select">
              <Select
                options={presetOptions}
                isSearchable
                name="snv-preset-select"
                isMulti={false}
                onChange={onChange}
                value={selectedPresetOption}
              />
            </div>
            {selectedPresetOption && (
              <div className="snv-presets-view">
                <Icon
                  type="listAlt"
                  clickable
                  title={VIEW_PRESET_INFORMATION}
                  onClick={openPresetsModal}
                />
                <SNVPresetInfoModal
                  patientId={patientId}
                  close={closePresetsModal}
                  show={showPresetModal}
                  preset={selectedPresetOption.value}
                />
              </div>
            )}
          </div>
          {isPresetCustomized && (
            <div className="text-primary">
              <i className="fa fa-exclamation-triangle" />{" "}
              <em>The selected preset has been customised</em>
            </div>
          )}
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state, { patientId }) => ({
  isPresetCustomized: selectors.isSelectedPresetCustomized(state, patientId),
  presetOptions: selectors.getSNVSelectFieldPresets(state),
  selectedPresetOption: selectors.getSelectedPresetOption(state),
});

const mapDispatchToProps = {
  selectSNVPreset,
};

SNVPresetsContainer.propTypes = {
  patientId: PropTypes.number.isRequired,
  selectSNVPreset: PropTypes.func.isRequired,
  /**
   * presets select field options with label and value properties
   */
  presetOptions: PropTypes.arrayOf(FilterPresetOptionProp),
  /**
   * the currently selected option from the presetOptions
   */
  selectedPresetOption: FilterPresetOptionProp,
  /**
   * true if preset filters where modified by the user
   */
  isPresetCustomized: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SNVPresetsContainer);
