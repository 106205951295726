import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { Tooltip } from "pattern-library";

import { resetConfig } from "../../config/actions";
import {
  isDefaultPresetSelected,
  isSelectedPresetCustomized,
} from "../../sequenceVariantsPresets/selectors";

export const ResetIcon = ({ patientId, resetConfig, disabled }) => {
  const handleResetConfig = () => {
    if (!disabled) {
      resetConfig(patientId);
    }
  };

  return (
    <Tooltip
      content="Reset to the default preset"
      placement="right"
      trigger="mouseenter"
    >
      <span
        onClick={handleResetConfig}
        className={classNames("glyphicon glyphicon-refresh", { disabled })}
      />
    </Tooltip>
  );
};

const mapStateToProps = (state, { patientId }) => ({
  disabled:
    isDefaultPresetSelected(state) &&
    !isSelectedPresetCustomized(state, patientId),
});

const mapDispatchToProps = {
  resetConfig,
};

ResetIcon.propTypes = {
  patientId: PropTypes.number.isRequired,
  resetConfig: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetIcon);
