// @flow

import { createAction } from "../utils";

import * as constants from "./constants";
import type { SNVPresetsInfo } from "./flow-types";

type SetSNVPresetsInfoAction = FSA<
  typeof constants.SET_SNV_PRESETS_INFO,
  SNVPresetsInfo
>;

export const setSNVPresetsInfo = (
  presetsInfo: SNVPresetsInfo
): SetSNVPresetsInfoAction =>
  createAction(constants.SET_SNV_PRESETS_INFO, presetsInfo);

export type SelectSNVPresetAction = FSA<
  typeof constants.SELECT_SNV_PRESET,
  { preset: SNVPreset },
  { patientId: number }
>;

export const selectSNVPreset = (
  preset: SNVPreset,
  patientId: number
): SelectSNVPresetAction =>
  createAction(constants.SELECT_SNV_PRESET, { preset }, { patientId });

export type Action = SetSNVPresetsInfoAction | SelectSNVPresetAction;
