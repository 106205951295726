// @flow
import { createSelector } from "@reduxjs/toolkit";
import { equals } from "ramda";

import { mergeGenes } from "data-layer/entities";
import { getCurrentPatientDecisions } from "modules/decisions/selectors";
import { getReduxFormValueSelector } from "modules/forms/form-specific-selectors";
import { getSinglePatient } from "modules/patient/selectors";
import { dataPathForModule, uiPathForModule } from "modules/utils/selectors";
import {
  choosePathogenicity,
  combineActiveGenePanels,
} from "modules/variants/selectors";

import * as projectSelectors from "../project/selectors";
import { getProjectArchivedGenePanelsMap } from "../variants/utils";

import {
  MODULE_NAME,
  configForm,
  TIER_OPTIONS,
  NO_GENE_NAME_VALUE,
} from "./constants";
import type { Str } from "./flow-types";
import type { State } from "./reducer";

const dataPath = dataPathForModule(MODULE_NAME);
const uiPath = uiPathForModule(MODULE_NAME);

export const getLoading = uiPath("loading");
export const getStrRecordLoading = uiPath("singleStrLoading");

export const getTotalCount = dataPath("totalCount");

export const configInitialized = dataPath("config", "initialized");
export const getConfigDefaultValues = dataPath("config", "defaults");
export const getConfigValues = dataPath("config", "values");
export const getPatientId = dataPath("patientId");
export const getConfigTimestamp = dataPath("config", "timestamp");
export const getDecisionTimestamp = dataPath("decisionTimestamp");

export const getGeneStrCounts = dataPath("geneStrCounts");

export const getGenePanels = dataPath("genePanels");
export const getGeneWrappersByGeneId = dataPath("geneWrappersByGeneId");

export const getConfigFormValues = (state: State) => {
  const values = getReduxFormValueSelector(configForm.FORM_NAME)(state) || {};
  return {
    filters: {
      [configForm.FILTER_GENE_PANELS]: values[configForm.FILTER_GENE_PANELS],
      [configForm.FILTER_TIERS]: values[configForm.FILTER_TIERS],
    },
  };
};

export const getProjectGenePanels = createSelector(
  getGenePanels,
  projectSelectors.getCurrentProject,
  (panels, currentProject) => {
    const genePanelsArchivedMap =
      getProjectArchivedGenePanelsMap(currentProject);

    return panels.map(panel => ({
      ...panel,
      archived: genePanelsArchivedMap.get(panel.genePanelId),
    }));
  }
);

export const getActiveGenePanels = createSelector(
  getProjectGenePanels,
  getGeneStrCounts,
  getCurrentPatientDecisions,
  (genePanels, geneStrCount, decisionGroups: DecisionGroups) => {
    const geneVariantCount = geneStrCount.map(
      ({ geneId: gene_id, strCount: variant_count }) => ({
        gene_id,
        variant_count,
      })
    );
    return combineActiveGenePanels(
      genePanels,
      { list: geneVariantCount },
      decisionGroups
    );
  }
);

export const mapStr = (
  str: Str,
  decisionGroups: DecisionGroups,
  geneWrappersByGeneId: {}
): Str => {
  const { gene } = str;
  const geneId = Number.parseInt(gene.geneId);

  const decisions: Array<Decision> =
    decisionGroups.decisionsByVariantId[str.variantId] || [];
  const pathogenicity = choosePathogenicity(decisions);

  const moiData = mergeGenes(geneWrappersByGeneId, geneId);

  return {
    ...str,
    gene: {
      ...gene,
      ...moiData,
      name: gene.name || NO_GENE_NAME_VALUE,
      pathogenicity,
    },
  };
};

export const getStrs = createSelector(
  dataPath("strs"),
  getCurrentPatientDecisions,
  getGeneWrappersByGeneId,
  (
    strs: Array<Str>,
    decisionGroups: DecisionGroups,
    geneWrappersByGeneId: {}
  ) => strs.map(str => mapStr(str, decisionGroups, geneWrappersByGeneId))
);

export const getSingleStr = createSelector(
  dataPath("singleStr"),
  getCurrentPatientDecisions,
  getGeneWrappersByGeneId,
  (str: Str, decisionGroups: DecisionGroups, geneWrappersByGeneId: {}) =>
    str && mapStr(str, decisionGroups, geneWrappersByGeneId)
);

export const isCustomDataEnabled = (state: any) => {
  const patientId = getPatientId(state);
  const patient = getSinglePatient(state, patientId);
  return patient ? patient.hasStrCustomDataTiers : false;
};

export const getSelectedFilters = createSelector(
  getConfigValues,
  ({ filters }) => {
    const tiers = filters[configForm.FILTER_TIERS].map(key => ({
      label: TIER_OPTIONS[key],
    }));
    return [{ label: "Tiers", type: "array", values: tiers }];
  }
);

export const configValuesNotModified = createSelector(
  getConfigValues,
  getConfigDefaultValues,
  (values, defaults) => equals(values, defaults)
);
