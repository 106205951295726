export default function createAction<T, P, M = Index>(
  type: T,
  payload: P = {} as P,
  meta: M = {} as M
) {
  return {
    type,
    payload,
    meta,
  };
}
