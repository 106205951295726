import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class TheadComponent extends PureComponent {
  static displayName = "Thead";

  static propTypes = {
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
    /**
     * Content to render inside the component
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <thead className={className} {...rest}>
        {children}
      </thead>
    );
  }
}
