import { react as autoBind } from "auto-bind";
import React, { PureComponent } from "react";

import { TreeNode } from "pattern-library";

import HPOTermsNode from "./HPOTermsNode";

export class HPOTermsTreeNode extends PureComponent {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleClick() {
    const {
      toggleTermAsVisible,
      root: { id },
    } = this.props;

    toggleTermAsVisible(id);
  }

  render() {
    const {
      expandedNodeIds,
      visibleNodeIds,
      root: { id, children },
    } = this.props;

    if (
      // visibleNodeIds holds a list of ids of nodes with name or description matching containing the query string
      // e.g. ['0/1', '0/0/2/1']
      // if there are no visible nodes ie. no nodes with ids equal to or spanning the start of the ones in the list nothing is rendered
      visibleNodeIds.length &&
      !visibleNodeIds.some(
        nodeId => nodeId === id || nodeId.startsWith(`${id}/`)
      )
    ) {
      return null;
    }

    const expanded = expandedNodeIds.includes(id);

    return (
      <TreeNode
        {...this.props}
        expanded={expanded}
        isParent={children.length > 0}
        TreeComponent={HPOTermsNode}
        handleClick={this.handleClick}
      />
    );
  }
}

export default HPOTermsTreeNode;
