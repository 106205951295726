import React from "react";

import ModalLayout from "./ModalLayout";

export default ({ close, show, imagePath }) => (
  <ModalLayout
    close={close}
    show={show}
    title="STR image"
    className="str-image-modal"
  >
    <img src={imagePath} className="str-image" alt="" />
  </ModalLayout>
);
