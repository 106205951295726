// @flow
import { createAction } from "../utils";

import * as constants from "./constants";

type SetMetadataAction = FSA<typeof constants.SET_METADATA, any>;

export const setMetadata = (metadata: any): SetMetadataAction =>
  createAction(constants.SET_METADATA, metadata);

type HasMadeRequestAction = FSA<typeof constants.HAS_MADE_REQUEST>;

export const hasMadeRequest = (): HasMadeRequestAction =>
  createAction(constants.HAS_MADE_REQUEST);

type FetchMetadataFieldsAction = FSA<typeof constants.HAS_MADE_REQUEST>;

export const fetchMetadataFields = (
  projectId: number | string
): FetchMetadataFieldsAction =>
  createAction(constants.FETCH_METADATA_FIELDS, {}, { projectId });

type SetMetadataFieldsAction = FSA<typeof constants.HAS_MADE_REQUEST>;

export const setMetadataFields = (
  fields: {},
  projectId: number | string
): SetMetadataFieldsAction =>
  createAction(constants.SET_METADATA_FIELDS, fields, { projectId });

type MetadataLoadFailedAction = FSA<
  typeof constants.FETCH_METADATA_FIELDS_FAILED,
  string
>;

export const metadataLoadFailed = (message: string): MetadataLoadFailedAction =>
  createAction(constants.FETCH_METADATA_FIELDS_FAILED, message);

export type Action =
  | SetMetadataAction
  | HasMadeRequestAction
  | SetMetadataFieldsAction
  | MetadataLoadFailedAction
  | FetchMetadataFieldsAction;
