import { camelizeKeys } from "humps";

import { OncologySecondaryPipelineField } from "./types";

const objectKeysToLowerCase = function (input: any): any {
  if (typeof input !== "object") return input;
  if (Array.isArray(input)) return input.map(objectKeysToLowerCase);
  return Object.keys(input).reduce((newObj, key) => {
    const val = input[key];
    const newVal =
      typeof val === "object" && val !== null
        ? objectKeysToLowerCase(val)
        : val;
    newObj[key.toLowerCase()] = newVal;
    return newObj;
  }, {});
};

export function processQC(obj: any): any {
  return camelizeKeys(objectKeysToLowerCase(obj));
}

export const getGeneCoverageQcByThreshold = (
  threshold: number,
  geneCoverageQcList: Array<GeneCoverageQc>,
  coverageThreshold?: number
): Array<GeneCoverageQcGene> => {
  if (geneCoverageQcList) {
    const geneCoverageQc = geneCoverageQcList.find(
      ({ coverageThreshold: key }: GeneCoverageQc) => key === coverageThreshold
    );
    if (geneCoverageQc) {
      return geneCoverageQc.genes
        .filter(({ coveredPct }) => coveredPct <= threshold)
        .sort((a, b) => {
          if (a.coveredPct > b.coveredPct) {
            return 1;
          }
          if (a.coveredPct < b.coveredPct) {
            return -1;
          }
          return 0;
        });
    }
  }
  return [];
};

export function isOncologyQCDataOutOfRange(
  qc: Partial<OncologyQcData>,
  oncologySecondaryPipelineDetails: Array<OncologySecondaryPipelineField>
): boolean {
  const keys = Object.keys(qc);
  return Boolean(
    keys.find(key => {
      const rule = oncologySecondaryPipelineDetails.find(
        ({ field }) => field === key
      )?.rule;
      return rule && qc[key] && !rule(Number(qc[key]));
    })
  );
}
