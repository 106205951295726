// @flow
import { createAction } from "../utils";

import * as constants from "./constants";

type ReadProjectsAction = FSA<typeof constants.READ_PROJECTS>;

export const readProjects = (): ReadProjectsAction =>
  createAction(constants.READ_PROJECTS);

export type ReadProjectsSuccessAction = FSA<
  typeof constants.READ_PROJECTS_SUCCESS,
  Array<{}>
>;

export const readProjectsSuccess = (
  projects: Array<{}>
): ReadProjectsSuccessAction =>
  createAction(constants.READ_PROJECTS_SUCCESS, projects);

export type ReadProjectsFailureAction = FSA<
  typeof constants.READ_PROJECTS_FAILURE
>;

export const readProjectsFailure = (): ReadProjectsFailureAction =>
  createAction(constants.READ_PROJECTS_FAILURE);

type ReadProjectTypesForChildProjectsAction = FSA<
  typeof constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS,
  number | string
>;

export const readProjectTypesForChildProjects = (
  projectId: number | string
): ReadProjectTypesForChildProjectsAction =>
  createAction(constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS, projectId);

export type ReadProjectTypesForChildProjectsSuccessAction = FSA<
  typeof constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_SUCCESS,
  Array<ProjectType>
>;

export const readProjectTypesForChildProjectsSuccess = (
  projectTypes: Array<ProjectType>
): ReadProjectTypesForChildProjectsSuccessAction =>
  createAction(
    constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_SUCCESS,
    projectTypes
  );

export type ReadProjectTypesForChildProjectsFailureAction = FSA<
  typeof constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_FAILURE,
  {}
>;

export const readProjectTypesForChildProjectsFailure =
  (): ReadProjectTypesForChildProjectsFailureAction =>
    createAction(constants.READ_PROJECT_TYPES_FOR_CHILD_PROJECTS_FAILURE);

export type Action =
  | ReadProjectsAction
  | ReadProjectsSuccessAction
  | ReadProjectsFailureAction
  | ReadProjectTypesForChildProjectsAction
  | ReadProjectTypesForChildProjectsSuccessAction
  | ReadProjectTypesForChildProjectsFailureAction;
