import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import AcmgCriteriaListItem from "./Item";

export default class AcmgCriteriaListSection extends PureComponent {
  static propTypes = {
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      criteria: PropTypes.array,
    }).isRequired,
    canEdit: PropTypes.bool,
    locked: PropTypes.bool,
    updateCriteriaToAdd: PropTypes.func,
  };

  render() {
    const {
      category: { name, criteria = [] },
      canEdit,
      locked,
      updateCriteriaToAdd,
      disabled,
    } = this.props;

    if (criteria.length > 0) {
      return (
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">{name}</h3>
          </div>
          <div className="list-group">
            {criteria.map(criteria => (
              <AcmgCriteriaListItem
                updateCriteriaToAdd={updateCriteriaToAdd}
                canEdit={canEdit}
                locked={locked}
                key={criteria.criteriaId}
                criteria={criteria}
                disabled={disabled}
              />
            ))}
          </div>
        </div>
      );
    }
    return null;
  }
}
