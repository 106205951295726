import React, { Component } from "react";

import { COMMON_ERROR_MESSAGE } from "./common/constants";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

// TODO: make this a global component somewhere
export default class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    // Removed error just for lint. Leaving commented version for future use
    // this.state = { error: "", hasError: false };
    this.state = { hasError: false };
  }

  // Removed error just for lint. Leaving commented version for future use
  // componentDidCatch(error) {
  componentDidCatch() {
    // Display fallback UI
    // Removed error just for lint. Leaving commented version for future use
    // this.setState({ error, hasError: true });
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // TODO: Connect this to sentry or something?
    // logErrorToMyService(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return <h1>{COMMON_ERROR_MESSAGE}</h1>;
    }
    return children;
  }
}
