import React from "react";

import { Link } from "pattern-library";
import { Table } from "pattern-library/elements/table7";

import { GNOMAD_SV_MAP } from "modules/structuralVariants/constants";

const getGnomadUrl = (id: string, version = "gnomad_sv_r2_1"): string =>
  `https://gnomad.broadinstitute.org/variant/${id}?dataset=${version}`;

const getRowProps = (_, { row, instance }) => {
  const isLastRow = row.index === instance.data.length - 1;
  return {
    className: isLastRow ? "font-weight-bold" : "",
  };
};

interface PopulationFrequenciesProps {
  populations: any;
  gnomadVariantId?: string;
}

export const PopulationFrequenciesTable = ({
  populations = [],
  gnomadVariantId,
}: PopulationFrequenciesProps) => {
  // Perform custom sort on the populations so the "all" element always ends up last
  const sortedPopulations = populations.sort(
    ({ population: pop1 }, { population: pop2 }) => {
      if (pop1 === "all") return 1;
      if (pop2 === "all") return -1;
      return pop1 > pop2 ? 1 : -1;
    }
  );

  const columns = [
    {
      Header: "Population",
      accessor: "population",
      Cell: ({
        row: {
          original: { population },
        },
      }) => (
        <div data-testid={`population-${population}`}>
          {GNOMAD_SV_MAP[population] || population}
        </div>
      ),
    },
    { Header: "Allele count", accessor: "alleleCount" },
    { Header: "Allele number", accessor: "alleleNumber" },
    { Header: "Homozygotes", accessor: "homozygotes" },
    { Header: "Allele frequency", accessor: "alleleFrequency" },
  ];

  return (
    <>
      <h4>
        Population frequencies: <span className="text-muted">(gnomAD)</span>
      </h4>
      {gnomadVariantId && (
        <p data-testid="gnomad-af-sv-id">
          Matched gnomAD structural variant:{" "}
          <Link
            href={getGnomadUrl(gnomadVariantId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {gnomadVariantId}
          </Link>
        </p>
      )}
      <Table
        columns={columns}
        data={sortedPopulations}
        disableSortBy
        getRowProps={getRowProps}
      />
    </>
  );
};
