import classNames from "classnames";
import PropTypes from "prop-types";
import {
  memoizeWith,
  identity,
  not,
  isEmpty,
  equals,
  symmetricDifference,
} from "ramda";
import React, { memo, useState, useCallback, useContext } from "react";
import { useDrag } from "react-dnd";

import { Link } from "pattern-library";

import * as constants from "../constants";

import { SelectedHPOTermsContext } from "./HPOTermList";

const hpoTermListItemShouldNotUpdate = (prevProps, nextProps) => {
  const prev = {
    isVisible: prevProps.isVisible,
    expanded: prevProps.expanded,
    hovered: prevProps.hovered,
  };

  const next = {
    isVisible: nextProps.isVisible,
    expanded: nextProps.expanded,
    hovered: nextProps.hovered,
  };

  return (
    equals(prev, next) &&
    !hpoTermsDifference(
      prevProps.selectedHPOTerms,
      nextProps.selectedHPOTerms
    ).includes(nextProps.hpoTermId)
  );
};

export const HPOTermListItem = memo(
  ({
    name = "",
    isVisible = true,
    isParent = false,
    expanded = false,
    hpoTermId,
    query,
    selectedHPOTerms,
    hovered = false,
    onMouseOver,
  }) => {
    let nameToRender = name;

    if (
      query &&
      not(isEmpty(query)) &&
      name.toLowerCase().indexOf(query.toLowerCase()) > -1
    ) {
      const indexToSlice = name.toLowerCase().indexOf(query.toLowerCase());
      const indexToStop = indexToSlice + query.length;
      nameToRender = (
        <span>
          {name.slice(0, indexToSlice)}
          <em>{name.slice(indexToSlice, indexToStop)}</em>
          {name.slice(indexToStop)}
        </span>
      );
    }

    // TODO: Fix conditional hook usage
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dragSource = hovered ? useDragSource({ name, hpoTermId }) : null;

    return (
      <div
        className={classNames({ hide: !isVisible })}
        ref={dragSource}
        onMouseOver={onMouseOver}
      >
        <Link
          className={classNames(
            memoizeWith(identity, isTermSelected)(
              selectedHPOTerms,
              hpoTermId
            ) && "bold"
          )}
        >
          <i
            className={classNames(
              "hpo-term-list-item-icon",
              "glyphicon",
              isParent && expanded && ["glyphicon-folder-open", "open"],
              isParent && !expanded && ["glyphicon-folder-close", "closed"],
              !isParent && "glyphicon-list-alt"
            )}
          />
          {nameToRender}
          <small className="hpo-terms-code">[{hpoTermId}]</small>
        </Link>
      </div>
    );
  },
  hpoTermListItemShouldNotUpdate
);

const hpoTermsDifference = (
  prevSelectedHPOTerms = [],
  nextSelectedHPOTerms = []
) => {
  const prevSelectedHPOs = prevSelectedHPOTerms.map(({ code }) => code);
  const nextSelectedHPOs = nextSelectedHPOTerms.map(({ code }) => code);
  return symmetricDifference(prevSelectedHPOs, nextSelectedHPOs);
};

const useDragSource = ({ name, hpoTermId }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, drag] = useDrag({
    item: { type: constants.HPO_TERM_LIST_ITEM },
    begin: () => ({ name, hpoTermId }),
    isDragging: monitor => monitor.isDragging(),
  });

  return drag;
};

const isTermSelected = (selectedHPOTerms, hpoTermId) =>
  selectedHPOTerms.findIndex(({ code }) => code === hpoTermId) !== -1;

const HPOTermListItemWrapper = memo(props => {
  const [hovered, setHovered] = useState(false);

  const mouseOver = useCallback(() => {
    setHovered(true);
  }, [setHovered]);

  const { query, selectedHPOTerms } = useContext(SelectedHPOTermsContext);

  return (
    <HPOTermListItem
      {...props}
      query={query}
      selectedHPOTerms={selectedHPOTerms}
      hovered={hovered}
      onMouseOver={mouseOver}
    />
  );
});

HPOTermListItemWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  isVisible: PropTypes.bool,
  isParent: PropTypes.bool.isRequired,
  hpoTermId: PropTypes.string,
};

export default HPOTermListItemWrapper;
