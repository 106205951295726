import { isEmpty } from "ramda";
import React, { memo } from "react";

import { Link } from "pattern-library";

import { ColocatedVariantsProps, LinkObject } from "../types";

import { getColocatedVariantsLinksGroups } from "./utils";

const renderColocatedVariantLink = ({ href, label }: LinkObject) => (
  <React.Fragment key={label}>
    <Link href={href} target="blank">
      {label}
    </Link>{" "}
  </React.Fragment>
);

const renderColocatedVariantTypeLinks = (
  linksGroup: Array<LinkObject>,
  key
) => <div key={key}>{linksGroup.map(renderColocatedVariantLink)}</div>;

export const ColocatedVariants = ({
  geneName,
  colocatedVariantsIds,
}: ColocatedVariantsProps) => {
  const linksGroups = getColocatedVariantsLinksGroups(
    colocatedVariantsIds,
    geneName
  );

  if (isEmpty(linksGroups)) {
    return <span>-</span>;
  }

  return <>{linksGroups.map(renderColocatedVariantTypeLinks)}</>;
};

export default memo(ColocatedVariants);
