export const GNOMAD_EXOMES_FIELDS = {
  AC: {
    key: "gnomadExomesAc",
    label: "GnomAD exomes AC",
  },
  AC_AFR: {
    key: "gnomadExomesAcAfr",
    label: "GnomAD exomes AC (African/African American)",
  },
  AC_AMR: {
    key: "gnomadExomesAcAmr",
    label: "GnomAD exomes AC (Latino/Admixed American)",
  },
  AC_ASJ: {
    key: "gnomadExomesAcAsj",
    label: "GnomAD exomes AC (Ashkenazi Jewish)",
  },
  AC_EAS: {
    key: "gnomadExomesAcEas",
    label: "GnomAD exomes AC (East Asian)",
  },
  AC_FIN: {
    key: "gnomadExomesAcFin",
    label: "GnomAD exomes AC (Finnish)",
  },
  AC_NFE: {
    key: "gnomadExomesAcNfe",
    label: "GnomAD exomes AC (Non-Finnish European)",
  },
  AC_SAS: {
    key: "gnomadExomesAcSas",
    label: "GnomAD exomes AC (South Asian)",
  },
  AC_OTH: {
    key: "gnomadExomesAcOth",
    label: "GnomAD exomes AC (Other)",
  },
  AF: {
    key: "gnomadExomesAf",
    label: "All populations",
  },
  AF_AFR: {
    key: "gnomadExomesAfAfr",
    label: "African/African American",
  },
  AF_AMR: {
    key: "gnomadExomesAfAmr",
    label: "Latino/Admixed American",
  },
  AF_ASJ: {
    key: "gnomadExomesAfAsj",
    label: "Ashkenazi Jewish",
  },
  AF_EAS: {
    key: "gnomadExomesAfEas",
    label: "East Asian",
  },
  AF_FIN: {
    key: "gnomadExomesAfFin",
    label: "Finnish",
  },
  AF_NFE: {
    key: "gnomadExomesAfNfe",
    label: "Non-Finnish European",
  },
  AF_SAS: {
    key: "gnomadExomesAfSas",
    label: "South Asian",
  },
  AF_OTH: {
    key: "gnomadExomesAfOth",
    label: "Other",
  },
};

export const GNOMAD_EXOMES_AF_FIELDS = Object.keys(GNOMAD_EXOMES_FIELDS)
  .filter(field => field.startsWith("AF"))
  .map(field => GNOMAD_EXOMES_FIELDS[field]);

export const GNOMAD_EXOMES_AC_FIELDS = Object.keys(GNOMAD_EXOMES_FIELDS)
  .filter(field => field.startsWith("AC"))
  .map(field => GNOMAD_EXOMES_FIELDS[field]);
