import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";

import { getFileTypesOptions } from "modules/interpretationRequests";
import { FormFileTypes, IrSample } from "modules/interpretationRequests/types";

import { ADD_BUTTON_NAME } from "../../componentConstants";

import SamplesTableHeader from "./SamplesTableHeader";
import SamplesTableRow from "./SamplesTableRow";

export const noDataText = (
  <div className="empty-table-label">
    <div>{`Use "${ADD_BUTTON_NAME}" to add a patient row.`}</div>
  </div>
);

interface Props extends PropsFromRedux {
  /**
   * the list of samples in an interpretation request
   */
  samples: Array<IrSample>;
  selectedRow?: number;
  fieldsDisabled: boolean;
  setSelectedRow: (row: number) => void;
  removeRowHandler: (row: number) => void;
  fileTypesOptions: Array<FormFileTypes>;
}

/**
 * Dynamic samples table
 * uses a simple HTML table directly instead of pattern library components for performance reasons
 */
export const SamplesTable: FC<Props> = ({
  samples = [],
  selectedRow,
  fieldsDisabled,
  removeRowHandler,
  setSelectedRow,
  fileTypesOptions,
}: Props) => (
  <>
    <table className="ir-table-v2 table table-bordered table-striped table-condensed">
      <SamplesTableHeader fileTypesOptions={fileTypesOptions} />
      <tbody>
        {samples.map((_, index) => {
          const key = `sample-row-${index}`;
          return (
            <SamplesTableRow
              key={key}
              samples={samples}
              index={index}
              isActiveRow={index === selectedRow}
              fieldsDisabled={fieldsDisabled}
              removeRowHandler={removeRowHandler}
              setSelectedRow={setSelectedRow}
              fileTypesOptions={fileTypesOptions}
            />
          );
        })}
      </tbody>
    </table>
    {!samples.length && noDataText}
  </>
);

const mapStateToProps = state => ({
  fileTypesOptions: getFileTypesOptions(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SamplesTable);
