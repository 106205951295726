import Genoverse from "genoverse";

import { Model } from "../common/BinSize";
import { Colors } from "../utils";

export const Controller = Genoverse.Track.Controller.extend({
  setName(name, configName) {
    if (this.track.coverageThreshold) {
      if (configName.length) {
        configName = [...configName, `${this.track.coverageThreshold}x`];
      } else {
        configName = [name, `${this.track.coverageThreshold}x`];
      }
    }
    this.base(name, configName);
  },
});

export const View = Genoverse.Track.View.extend({
  draw(features, featureContext, labelContext, scale) {
    this.base(features, featureContext, labelContext, scale);
    if (this.track.coverageThreshold) {
      featureContext.fillStyle = Colors.RED;
      featureContext.fillRect(0, this.track.coverageThreshold, this.width, 1);
    }
  },
  drawFeature({ x, width, score }, featureContext) {
    featureContext.fillStyle = Colors.BLACK;
    featureContext.fillRect(x, 0, width, score * this.track.scoreMultiplier);
  },
});

export const Track = Genoverse.Track.extend({
  height: 100,
  id: "coverage",
  name: "Coverage",
  namespace: "Congenica.Coverage",
  labels: false,
  bump: false,
  scoreMultiplier: 1,
  controller: Controller,
  model: Model,
  view: View,
});
