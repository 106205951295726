import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

import Button from "./Button";

export class ButtonDropdown extends Component {
  static displayName = "ButtonDropdown";

  static propTypes = {
    /**
     * The context class to apply to the button element
     */
    context: PropTypes.string,
    /**
     * Classnames to apply to the button on top of the context classes
     */
    className: PropTypes.string,
    /**
     * Classnames to apply to the top dropdown component
     */
    containerClassName: PropTypes.string,
    /**
     * Array rendered as list elements
     */
    list: PropTypes.array.isRequired,
    /**
     * Classnames to apply to the dropdown expanded component
     */
    menuClassName: PropTypes.string,
    /**
     * Dropdown content
     */
    children: PropTypes.node,
    /**
     * Dropdown content with passed closeDropdown method
     */
    render: PropTypes.func,
    /**
     * called when Dropdown is called
     */
    onClose: PropTypes.func,
    /**
     * Direction of dropdown alignment. Default empty string aligns dropdown to left
     */
    dropdownPosition: PropTypes.string,
    /**
     * Content of button
     */
    btnContent: PropTypes.node.isRequired,
    /**
     * if true the dropdown is inactive
     */
    disabled: PropTypes.bool,
    /**
     * when set, removes the padding around the `<li>` items within the dropdown
     */
    removePadding: PropTypes.bool,
    /**
     * method called when the dropdown is opened
     */
    onOpen: PropTypes.func,
  };

  static defaultProps = {
    context: "default",
    className: "",
    list: [],
    dropdownPosition: "",
    btnContent: "",
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
    };
    autoBind(this);
  }

  closeDropdown() {
    const { onClose } = this.props;
    this.setState({ showDropdown: false });
    if (onClose) {
      onClose();
    }
  }

  handleClickOutside() {
    this.closeDropdown();
  }

  toggleDropdown(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
    this.props.onOpen?.();
  }

  shouldRenderContent() {
    const { children, render: renderProp } = this.props;
    return children || renderProp;
  }

  renderList() {
    const { list, menuClassName, dropdownPosition, removePadding } = this.props;
    const liStyles = removePadding ? { style: { padding: 0 } } : {};
    return (
      <ul
        className={classNames("dropdown-menu", menuClassName, {
          "dropdown-menu-right": dropdownPosition === "right",
        })}
      >
        {list.map(({ content, ...rest }) => (
          <li {...rest} className="button-dropdown-list-item" {...liStyles}>
            {content}
          </li>
        ))}
      </ul>
    );
  }

  renderContent() {
    const {
      menuClassName,
      children,
      render: renderProp,
      dropdownPosition,
    } = this.props;
    return (
      <div
        className={classNames("dropdown-menu", menuClassName, {
          "dropdown-menu-right": dropdownPosition === "right",
        })}
      >
        {renderProp ? renderProp(this.closeDropdown) : children}
      </div>
    );
  }

  render() {
    const { context, className, containerClassName, btnContent, disabled } =
      this.props;
    const { showDropdown } = this.state;

    return (
      <div
        className={classNames(
          "button-dropdown-group",
          containerClassName,
          showDropdown && "open"
        )}
        onClick={!this.shouldRenderContent() ? this.toggleDropdown : undefined}
      >
        <Button
          aria-label={this.props["aria-label"]}
          disabled={disabled}
          context={context}
          onClick={this.shouldRenderContent() ? this.toggleDropdown : undefined}
          className={classNames("button-dropdown-toggle", className)}
        >
          {btnContent}
          <span className="caret" />
        </Button>
        {this.shouldRenderContent() ? this.renderContent() : this.renderList()}
      </div>
    );
  }
}

export default onClickOutside(ButtonDropdown);
