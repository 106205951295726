import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Loading, DisabledOverlay, Tooltip } from "pattern-library";

import { AUTO_ACMG_FEATURE } from "modules/project/constants";
import { isProjectFeatureActive } from "modules/project/selectors";
import { setActiveVariantPanelValues } from "modules/variants/actions";

import { readAcmgClassificationList, readAcmgCriteriaList } from "../actions";
import { ACMG_GRID_SUGGESTIONS_DISABLED_TOOLTIP } from "../constants";
import {
  getPopulatedSelectedCriteriaByCategories,
  getCurrentPatientVariant,
  getSuggestedCriteriaSelector,
  getSuggestedClassificationSelector,
  isAcmgCriteriaEditDisabledDueToSuggestions,
} from "../selectors";

import CriteriaList from "./CriteriaList";
import AcmgGrid from "./Grid";
import AcmgSuggestionPanel from "./Suggestions";

const withDisabledOverlay = (container, isDisabled, disabledTooltip) => {
  if (isDisabled) {
    return (
      <Tooltip content={disabledTooltip}>
        <DisabledOverlay>{container}</DisabledOverlay>
      </Tooltip>
    );
  }
  return container;
};

export const AcmgTab = ({
  geneName,
  geneId,
  variantId,
  patientId,
  patientVariantId,
  transcriptId,
  disabled,
  criteria,
  loaded,
  isAcmgCriteriaEditDisabledDueToSuggestions,
  suggestedCriteria,
  suggestedClassification,
  isAutoAcmgActive,
  readAcmgClassification,
  readAcmgCriteria,
  setActiveVariantPanelValues,
}) => {
  useEffect(() => {
    readAcmgClassification();
  }, [readAcmgClassification]);

  useEffect(() => {
    readAcmgCriteria();
  }, [readAcmgCriteria]);

  useEffect(() => {
    if (
      geneName &&
      geneId &&
      variantId &&
      patientId &&
      patientVariantId &&
      transcriptId
    ) {
      setActiveVariantPanelValues(
        geneName,
        geneId,
        variantId,
        patientVariantId,
        transcriptId
      );
    }
  }, [
    geneName,
    geneId,
    variantId,
    patientId,
    patientVariantId,
    transcriptId,
    setActiveVariantPanelValues,
  ]);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <div className="row">
      {criteria.length > 0 && (
        <div className="col-xs-3">
          <CriteriaList disabled={disabled} />
        </div>
      )}
      <div className={classNames({ "col-xs-9": criteria.length })}>
        {isAutoAcmgActive && (
          <AcmgSuggestionPanel
            suggestedCriteria={suggestedCriteria}
            suggestedClassification={suggestedClassification}
          />
        )}
        {withDisabledOverlay(
          <AcmgGrid
            patientId={patientId}
            activeVariant={variantId}
            disabled={disabled}
          />,
          isAcmgCriteriaEditDisabledDueToSuggestions,
          ACMG_GRID_SUGGESTIONS_DISABLED_TOOLTIP
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  criteria: getPopulatedSelectedCriteriaByCategories(state),
  loaded: getCurrentPatientVariant(state),
  isAutoAcmgActive: isProjectFeatureActive(state, AUTO_ACMG_FEATURE),
  isAcmgCriteriaEditDisabledDueToSuggestions:
    isAcmgCriteriaEditDisabledDueToSuggestions(state),
  suggestedCriteria: getSuggestedCriteriaSelector(state),
  suggestedClassification: getSuggestedClassificationSelector(state),
});

const mapDispatchToProps = {
  readAcmgClassification: readAcmgClassificationList,
  readAcmgCriteria: readAcmgCriteriaList,
  setActiveVariantPanelValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcmgTab);

AcmgTab.propTypes = {
  geneName: PropTypes.string,
  geneId: PropTypes.number,
  variantId: PropTypes.number,
  patientId: PropTypes.number,
  patientVariantId: PropTypes.number,
  transcriptId: PropTypes.number,
  disabled: PropTypes.bool,
};
