import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LoadingOverlay } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { getCurrentProject } from "modules/project/selectors";
import { formatDate } from "modules/utils";
import notIsNil from "modules/utils/notIsNil";

import {
  fetchProjectIRs,
  initialize,
  resetProjectIRForDelete,
  setProjectIRForDelete,
} from "./actions";
import Audit from "./components/Audit";
import DataFiles from "./components/DataFiles";
import DeleteIRModal from "./components/DeleteIRModal";
import Patients from "./components/Patients";
import { getIRSColumns } from "./components/columns";
import { ROW_STATUSES } from "./constants";
import {
  getAudit,
  getDataFiles,
  getIrForDelete,
  getIRS,
  getPatients,
  isLoading,
} from "./selectors";

const identity = val => val;
const propertyMap = {
  created: formatDate,
  canDelete: ({ msg }) => msg,
  lastAudit: lastAudit => (lastAudit ? lastAudit.info : null),
};

const filter = (
  data: Array<ProjectInterpretationRequest> = [],
  filterValue: string
): Array<ProjectInterpretationRequest> =>
  data.filter(ir => {
    for (const [key, value] of Object.entries(ir)) {
      const valueMapper: any => mixed = propertyMap[key] || identity;
      const mappedValue: any = valueMapper(value);
      if (
        mappedValue &&
        mappedValue.toString().toUpperCase().includes(filterValue.toUpperCase())
      ) {
        return true;
      }
    }
    return false;
  });

export const ProjectIRsContainer = memo(
  ({
    initialize,
    projectId,
    irs,
    audit = {},
    patients = {},
    dataFiles = {},
    isLoading,
    currentProject,
    irForDelete,
    setIRForDelete,
    resetIRForDelete,
  }) => {
    const { code: projectName } = currentProject || {};

    useEffect(() => {
      initialize(projectId);
    }, [initialize, projectId]);

    const getAuditById = useCallback(irId => audit[irId], [audit]);
    const getPatientsById = useCallback(irId => patients[irId], [patients]);
    const getDataFilesById = useCallback(irId => dataFiles[irId], [dataFiles]);

    // Create a function that will render our row sub components
    const renderRowSubComponent = useCallback(
      ({
        row: {
          original: { id },
        },
      }) => (
        <div>
          {audit && <Audit audit={(audit || {})[id]} />}
          {patients && <Patients patients={(patients || {})[id]} />}
          {dataFiles && <DataFiles irId={id} />}
        </div>
      ),
      [audit, patients, dataFiles]
    );

    const getRowProps = (
      _,
      {
        row,
        row: {
          original: { id, status },
        },
      }
    ) => ({
      key: id,
      className: classNames(ROW_STATUSES[status.toUpperCase()], {
        clickable:
          getAuditById(id) || getPatientsById(id) || getDataFilesById(id),
      }),

      onClick: () => {
        if (getAuditById(id) || getPatientsById(id) || getDataFilesById(id)) {
          row.toggleRowExpanded();
        }
      },
    });
    const columns = getIRSColumns(setIRForDelete);

    const [filterValue, setFilterValue] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    const onCloseDeleteIRModal = useCallback(() => {
      resetIRForDelete();
    }, [resetIRForDelete]);

    useEffect(() => {
      setFilteredItems(filter(irs, filterValue));
    }, [irs, filterValue, setFilteredItems]);

    const onFilterChange = useCallback(
      ({ filter = "" }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    return (
      <LoadingOverlay data-testid="irs-loading-overlay" loading={isLoading}>
        <div>
          <Table
            columns={columns}
            className="project-irs-table"
            title={`Interpretation Requests in ${projectName}`}
            showTitleInfo
            fetchData={onFilterChange}
            enableFilter
            data={filteredItems}
            showPagination={filteredItems.length > DEFAULT_PAGE_SIZE}
            autoResetPage={false}
            getRowProps={getRowProps}
            sortBy={[
              {
                id: "id",
                desc: false,
              },
            ]}
            renderRowSubComponent={renderRowSubComponent}
            autoResetExpanded={false}
          />
          <DeleteIRModal
            data-testid="delete-ir-modal"
            projectId={projectId}
            show={notIsNil(irForDelete)}
            close={onCloseDeleteIRModal}
          />
        </div>
      </LoadingOverlay>
    );
  }
);

ProjectIRsContainer.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapDispatchToProps = {
  initialize,
  fetchProjectIR: fetchProjectIRs.start,
  setIRForDelete: setProjectIRForDelete,
  resetIRForDelete: resetProjectIRForDelete,
};

const mapStateToProps = createStructuredSelector({
  currentProject: getCurrentProject,
  irs: getIRS,
  irForDelete: getIrForDelete,
  patients: getPatients,
  audit: getAudit,
  dataFiles: getDataFiles,
  isLoading,
});

ProjectIRsContainer.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectIRsContainer);
