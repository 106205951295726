import Genoverse from "genoverse";

export const sortByStart = (a, b) => a.start - b.start;

//Moved from TranscriptProtein
export const addExons = function (data, featuresById) {
  data
    .filter(i => i.feature_type === "exon")
    .sort(sortByStart)
    .forEach(feature => {
      const { id, Parent } = feature;
      if (featuresById[Parent] && !featuresById[Parent].exons[id]) {
        featuresById[Parent].exons.push(feature);
        featuresById[Parent].exons[id] = feature;
      }
    });
};

const getSwitchTranscriptLink = (
  { start, end, transcript_id },
  browser,
  snvPosStr
) => {
  // Check if transcript overlaps snvPos
  // If overlap, add link to toggle
  const snvPos = parseInt(snvPosStr);
  const transcript = browser.container
    .parents(".variant-details")
    .find(".snv-transcript-toggle");
  if (snvPos > 0 && start <= snvPos && end >= snvPos) {
    if (
      transcript.find("option[value='" + transcript_id + "']:enabled").length >
      0
    ) {
      return {
        label: "Switch to this transcript",
        //Should be changed when SNV page will be moved to react
        onClick: `window.Sapientia.switchVariantTranscript('${transcript_id}', $(this).parents('.variant-details').find('.snv-transcript-toggle'));`,
      };
    }
  }
};

export const Controller = Genoverse.Track.Controller.extend({
  createMenu(feature, menuItems, menuLinks) {
    const createUrl = (link, title) =>
      `<a target="_blank" rel="noopener noreferrer" href="${link}">${title}</a>`;

    const createOnClickLink = ({ label, onClick }) =>
      `<a style="cursor:pointer;" onClick="${onClick}">${label}</a>`;

    const concatLinks = links =>
      links.map(({ label, url }) => createUrl(url, label)).join("");

    const createLink = (menuLink, title) => {
      if (typeof menuLink === "function") {
        return createUrl(menuLink(feature), title);
      }
      if (typeof menuLink === "object" && menuLink.hasOwnProperty("onClick")) {
        return createOnClickLink(menuLink, title);
      }
      return title;
    };

    return menuItems
      .filter(({ value }) => value !== undefined)
      .map(({ label, key, value }) => ({
        [label]: Array.isArray(value)
          ? concatLinks(value)
          : createLink(menuLinks[key], value),
      }))
      .reduce(
        (menu, element) => ({
          ...menu,
          ...element,
        }),
        {}
      );
  },

  getFeatureMenu(feature) {
    const { id, start, end, gene_panels } = feature;
    const { menuLinks, snvPos } = this.track;

    // In legacy TranscriptSapientia we display with +-1 for unknown reasons
    // Leaving to match old track display
    const length = `${end - start + 1} bp`;
    const location = `${this.browser.chr} : ${start - 1} - ${end - 1}`;
    const panel = Array.isArray(gene_panels)
      ? menuLinks.panel(feature)
      : undefined;

    const view = getSwitchTranscriptLink(feature, this.browser, snvPos);
    menuLinks.view = view;

    const menuElements = [
      {
        key: "title",
        label: "title",
        value: id,
      },
      {
        key: "location",
        label: "Location",
        value: location,
      },
      {
        key: "length",
        label: "Length",
        value: length,
      },
      {
        key: "panel",
        label: "Panel",
        value: panel,
      },
      {
        key: "view",
        label: "View",
        value: view,
      },
    ];

    return this.createMenu(feature, menuElements, menuLinks);
  },
});

export const ModelConfig = {
  parseData(data, chr) {
    const { featuresById } = this;
    const ids = [];
    const Types = {
      Transcript: "transcript",
      Cds: "cds",
      Exon: "exon",
    };
    const sortOrder = {
      [Types.Transcript]: 1,
      [Types.Cds]: 2,
      [Types.Exon]: 3,
    };

    data
      .sort(
        (left, right) =>
          sortOrder[left.feature_type] - sortOrder[right.feature_type]
      )
      .forEach(feature => {
        const { id, feature_type, Parent, start, end, external_name, strand } =
          feature;

        if (feature_type === Types.Transcript) {
          if (!featuresById[id]) {
            let label = `${external_name} ${id}`;
            label = strand > 0 ? `${label} \u25b6` : `\u25c0 ${label}`; // Left & Right triangles

            this.insertFeature({
              ...feature,
              chr: feature.chr || chr,
              cdsStart: Infinity,
              cdsEnd: -Infinity,
              exons: [],
              subFeatures: [],
              label,
            });
          }
          ids.push(id);
        }

        if (feature_type === Types.Cds && featuresById[Parent]) {
          const parent = featuresById[Parent];
          parent.cdsStart = Math.min(parent.cdsStart, start);
          parent.cdsEnd = Math.max(parent.cdsEnd, end);
        }

        if (
          feature_type === Types.Exon &&
          featuresById[Parent] &&
          !featuresById[Parent].exons[id]
        ) {
          const parent = featuresById[Parent];
          if (end < parent.cdsStart || start > parent.cdsEnd) {
            parent.subFeatures.push({
              ...feature,
              utr: true,
            });
          } else {
            if (start < parent.cdsStart) {
              parent.subFeatures.push({
                ...feature,
                utr: true,
                end: parent.cdsStart,
              });
            }

            parent.subFeatures.push({
              ...feature,
              start: Math.max(start, parent.cdsStart),
              end: Math.min(end, parent.cdsEnd),
              strand: parent.strand,
            });

            if (end > parent.cdsEnd) {
              parent.subFeatures.push({
                ...feature,
                utr: true,
                start: parent.cdsEnd,
              });
            }
          }
        }
      });

    addExons(data, featuresById);

    ids.forEach(id => {
      featuresById[id].subFeatures.sort((a, b) => a.start - b.start);
    });
  },
};

export const Model = Genoverse.Track.Model.Transcript.extend(ModelConfig);

export const ViewConfig = {
  setFeatureColor(feature) {
    Genoverse.Track.View.Gene.Ensembl.prototype.setFeatureColor(feature);

    const colorConfig = (this.track.colors || []).find(({ predicate }) =>
      predicate(feature)
    );

    if (colorConfig) {
      feature.color = colorConfig.color;
      feature.labelColor = colorConfig.color;
    }

    (feature.subFeatures || []).forEach(subFeature => {
      if (subFeature.utr) {
        subFeature.color = false;
        subFeature.borderColor = feature.color;
      }
    });
  },
};

export const View = Genoverse.Track.View.Transcript.Ensembl.extend(ViewConfig);

export const Track = Genoverse.Track.extend({
  id: "Congenica.Transcripts",
  namespace: "Congenica.Transcripts",
  name: "Transcripts",
  info: "The default transcripts track",
  category: "Genes",
  tags: ["Genes", "Transcripts", "Ensembl", "Structure"],
  repeatLabels: true,
  height: 150,
  controller: Controller,
  model: Model,
  view: View,
});
