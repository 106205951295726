import React, { useMemo } from "react";
import { connect } from "react-redux";

import GenePanels from "modules/config/components/forms/GenePanels";
import * as configSelectors from "modules/config/selectors";
import * as selectors from "modules/str/selectors";

import TabsLayout from "../TabsLayout";

import CustomData from "./CustomData";

const getTabs = ({ isCustomDataEnabled, genePanels }) =>
  [
    {
      name: "Gene panels",
      component: GenePanels,
      componentProps: {
        genePanels,
      },
    },
    isCustomDataEnabled
      ? {
          name: "Custom",
          component: CustomData,
        }
      : null,
  ].filter(Boolean);

export const Filters = props => {
  const { isCustomDataEnabled, genePanels } = props;

  const tabsToUse = useMemo(
    () => getTabs({ isCustomDataEnabled, genePanels }),
    [isCustomDataEnabled, genePanels]
  );

  return <TabsLayout stacked tabs={tabsToUse} {...props} />;
};

const mapStateToProps = state => {
  const patientId = selectors.getPatientId(state);
  return {
    isCustomDataEnabled: selectors.isCustomDataEnabled(state),
    genePanels: configSelectors.getGenePanelsCheckBoxItems(state, patientId),
  };
};

export default connect(mapStateToProps)(Filters);
