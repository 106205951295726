import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { WideContentWrapper } from "pattern-library/elements/base";

import styles from "./Modal.module.scss";

export default class ModalBody extends PureComponent {
  static displayName = "ModalBody";

  static propTypes = {
    /**
     * Class to apply to the modal-body div
     */
    className: PropTypes.string,
    /**
     * Content to render inside the modal body
     */
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children, ...otherProps } = this.props;

    return (
      <div
        {...otherProps}
        className={classNames("modal-body", styles.modalBody, className)}
      >
        <WideContentWrapper
          className={styles.wideContentWrapper}
          useScrollableToClassNames={false}
        >
          {children}
        </WideContentWrapper>
      </div>
    );
  }
}
