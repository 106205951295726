import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { Checkbox } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { formatDateTime } from "modules/utils";
import { getFetchRequestParams } from "modules/utils/table";

import { fetchReportTemplates, toggleTemplate } from "../actions";
import {
  getReportTemplates,
  getReportTemplatesLoading,
  getAllReportTemplatesLoading,
  getEnabledTemplates,
} from "../selectors";

const getColumns = ({
  projectId,
  onTemplateToggle,
  templatesLoading,
  enabledTemplates,
}) => {
  const columns = [
    {
      Header: "Name",
      accessor: "templateName",
      id: "template_name",
    },
    {
      Header: "Description",
      accessor: "templateDescription",
      id: "template_description",
    },
    {
      Header: "Template ID",
      accessor: "templateUuid",
      id: "template_uuid",
    },
    {
      Header: "Updated",
      accessor: "updated",
      id: "updated",
      Cell: ({
        row: {
          original: { updated },
        },
      }) => formatDateTime(updated),
    },
    {
      Header: "Show in project",
      // The Saving... label is appearing when a user changes the checkbox status here.
      // It cause changing the width of the column, so this affects the whole table.
      // So `min-width` was added here.
      headerStyle: { minWidth: "7em" },
      disableFilters: true,
      accessor: "actions", //we need it here to enable sorting
      Cell: ({
        row: {
          original: { isInherited, templateUuid },
        },
      }) => {
        const templateLoading = templatesLoading[templateUuid];
        const [templateEnabled, setTemplateEnabled] = useState(
          // Fallback to `false` otherwise the input will switch between uncontrolled -> controlled
          enabledTemplates[templateUuid] || false
        );

        const handleChange = e => {
          setTemplateEnabled(e.target.checked);

          onTemplateToggle({
            projectId,
            templateUuid,
            enabled: e.target.checked,
          });
        };

        const savingLabel = templateLoading && "Saving...";
        const inheritedLabel = isInherited && "(inherited)";

        return (
          <Checkbox
            containerClass="report-templates__checkbox"
            labelClass="report-templates__label"
            disabled={isInherited || templateLoading}
            data-testid={`report-template-${templateUuid}`}
            key={templateUuid}
            name={templateUuid}
            onChange={handleChange}
            value={templateEnabled}
            label={savingLabel || inheritedLabel}
          />
        );
      },
    },
  ];
  return columns.map((column, index) => {
    const { accessor } = column;
    if (accessor === "actions") {
      return {
        ...column,
        id: index,
      };
    }
    return column;
  });
};

export const ReportTemplates = ({
  projectId,
  templates: { list = [], totalCount } = {},
  loading = true,
  toggleTemplate,
  getReportTemplates,
  templatesLoading = {},
  enabledTemplates = {},
}) => {
  const columns = getColumns({
    projectId,
    onTemplateToggle: toggleTemplate,
    templatesLoading,
    enabledTemplates,
  });

  const fetchData = useCallback(
    args => {
      getReportTemplates(
        projectId,
        getFetchRequestParams({
          ...args,
          columns,
          // required so that the back end can match dates
          utc_offset: new Date().getTimezoneOffset(),
          // date_format_1 specified in perl.
          "convert[updated]": "date_format_1",
        })
      );
    },
    [getReportTemplates, projectId, columns]
  );

  return (
    <Table
      enableFilter
      loading={loading}
      title="Report Templates"
      columns={columns}
      data={list}
      showPagination={totalCount > DEFAULT_PAGE_SIZE}
      pageSizeOptions={[10, 25, 50, 100]}
      fetchData={fetchData}
      manualPagination
      totalCount={totalCount}
      manualSortBy
      sortBy={[
        {
          id: "updated",
          desc: true,
        },
      ]}
    />
  );
};

const mapStateToProps = state => ({
  loading: getReportTemplatesLoading(state),
  templates: getReportTemplates(state) || {},
  templatesLoading: getAllReportTemplatesLoading(state),
  enabledTemplates: getEnabledTemplates(state),
});

const mapDispatchToProps = {
  toggleTemplate: toggleTemplate.start,
  getReportTemplates: fetchReportTemplates.start,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTemplates);
