// @flow

import type { Action } from "./actions";
import * as constants from "./constants";
import type { State } from "./flow-types";
import * as utils from "./utils";

export const initialState: State = {
  data: {
    currentPatientDecisions: {
      decisionsByVariantId: {},
      decisionsByGeneId: {},
      decisionsById: {},
    },
  },
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SET_CURRENT_PATIENT_DECISIONS:
      return utils.groupDecisions(state, action.payload);
    case constants.DELETE_VARIANT_DECISION:
      const { variantId, decisionType } = action.payload;
      return utils.deleteVariantDecision(state, variantId, decisionType);
    case constants.UPDATE_VARIANT_DECISION:
      return utils.updateVariantDecision(state, action.payload);

    default:
      return state;
  }
}
