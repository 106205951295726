import React, { FC, memo, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Tabs } from "pattern-library";

import {
  NotFoundMessage,
  RouteTabContent,
  RouteTabHeader,
} from "layout/partials";
import {
  OncologyExploreSequenceVariants,
  OncologyExploreCopyNumberVariants,
} from "modules/oncologyExplore";

interface Props {
  patientId: number;
}

function getTabSet(patientId: number): Array<any> {
  return [
    {
      name: "Copy number variants",
      url: "/copy-number-variants",
      className: "pull-right",
      component: OncologyExploreCopyNumberVariants,
      componentProps: {
        patientId,
      },
    },
    {
      name: "Sequence variants",
      url: "/sequence-variants",
      className: "pull-right",
      component: OncologyExploreSequenceVariants,
      componentProps: {
        patientId,
      },
    },
  ];
}

const OncologyExploreContainer: FC<Props> = ({ patientId }) => {
  const urlPrefix = `/patient/${patientId}/oncology-explore`;

  const tabSet = useMemo(() => getTabSet(patientId), [patientId]);

  return (
    <div className="container-fluid">
      {patientId && (
        <Switch>
          <Route exact path={urlPrefix}>
            <Redirect to={`${urlPrefix}/sequence-variants`} />
          </Route>
          <Tabs
            tabComponent={RouteTabHeader}
            contentComponent={RouteTabContent}
            tabs={tabSet}
            baseUrl={urlPrefix}
            routePath="/patient/:patientId/oncology-explore"
          />
          <Route component={NotFoundMessage} />
        </Switch>
      )}
    </div>
  );
};

export default memo(OncologyExploreContainer);
