import classNames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";

import { DataTableFooter, Loading, Tooltip } from "pattern-library";

import { SuggestionBanner } from "modules/ariadne/components/SuggestionBanner";
import * as configActions from "modules/config/actions";
import * as configConstants from "modules/config/constants";
import * as configSelectors from "modules/config/selectors";
import CuratedVariantModal from "modules/curatedVariant/components/CuratedVariantModal";
import Message from "modules/messages/components/Message";
import { scrollRefIntoView } from "modules/utils/common";

import * as actions from "../actions";
import * as selectors from "../selectors";

import VariantPanel from "./VariantPanel";
import VariantsTableHeader from "./VariantsTableHeader";
import VariantsTableRow from "./VariantsTableRow";

const renderHeaders = columns =>
  columns.map(({ columns: nestedColumns = [], header, tooltip, key }) => {
    if (nestedColumns.length > 0) {
      // Map over any nested columns and recursively render them
      return renderHeaders(nestedColumns);
    }

    if (tooltip) {
      return (
        <Tooltip {...tooltip} placement="top" key={key}>
          <VariantsTableHeader colKey={key} header={header} key={key} />
        </Tooltip>
      );
    }

    return <VariantsTableHeader colKey={key} header={header} key={key} />;
  });

interface VariantsTableProps extends PropsFromRedux {
  patientId: string | number;
}

export const VariantsTable = ({
  columns,
  genes,
  loaded,
  page,
  pager: { firstItem, lastItem, lastPage, totalItems },
  totalVariants,
  patientId,
  activeGene,
  resetVariantPanel,
  setPage,
  reloadVariantPage,
  applyAriadneSuggestions,
  isAriadneEnabled,
  isAnyAriadneFilterActive,
  geneToFocus,
}: VariantsTableProps) => {
  const geneHeaderRef = useRef<HTMLDivElement | null>(null);
  const rowToFocusRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (geneHeaderRef && geneHeaderRef.current) {
      scrollRefIntoView(rowToFocusRef, geneHeaderRef.current.clientHeight);
    }
  }, [rowToFocusRef, geneHeaderRef, geneToFocus, activeGene]);

  const onScroll = useCallback(
    e => {
      if (geneHeaderRef && geneHeaderRef.current) {
        geneHeaderRef.current.scrollLeft = e.target.scrollLeft;
      }
    },
    [geneHeaderRef]
  );

  const setPageAndReloadVariants = useCallback(
    page => {
      resetVariantPanel();
      setPage(page + 1);
      reloadVariantPage(patientId);
    },
    [patientId, resetVariantPanel, setPage, reloadVariantPage]
  );

  const setAriadneSuggestions = useCallback(() => {
    applyAriadneSuggestions(patientId, false);
  }, [applyAriadneSuggestions, patientId]);

  const renderNoVariantsMessage = useCallback(() => {
    const noVariantsMessage = "No variants were returned by your filters";

    return isAriadneEnabled && !isAnyAriadneFilterActive ? (
      <SuggestionBanner
        applyFilters={setAriadneSuggestions}
        className="no-variants-found"
        level="warning"
      >
        <div>{noVariantsMessage}</div>
      </SuggestionBanner>
    ) : (
      <Message
        id="VariantsTable_no-variants-found"
        level="warning"
        message={noVariantsMessage}
      />
    );
  }, [isAriadneEnabled, isAnyAriadneFilterActive, setAriadneSuggestions]);

  const pageIndex = page - 1;

  return (
    <div className="variants-table">
      <CuratedVariantModal patientId={patientId} />
      <div className={classNames({ "variant-table-overlay": !loaded })}>
        {loaded || <Loading />}
      </div>

      <div className="gene-table">
        <div className="gene-header" ref={geneHeaderRef}>
          <h4>
            {firstItem} to {lastItem} of {totalItems} genes ({totalVariants}{" "}
            sequence variants)
          </h4>
          <div className="gene-header-row">
            <div className="variant-column gene-header-cell">Gene</div>
            <div className="variant-column gene-header-cell">Gene Scores</div>
            {renderHeaders(columns)}
          </div>
        </div>
        <div className="gene-container" onScroll={onScroll}>
          {loaded && genes.length === 0 && renderNoVariantsMessage()}
          {genes.map(gene =>
            gene.geneId === geneToFocus ? (
              <div key={`gene-row-${gene.geneId}`} ref={rowToFocusRef}>
                <VariantsTableRow gene={gene} patientId={patientId} />
              </div>
            ) : (
              <VariantsTableRow
                key={`gene-row-${gene.geneId}`}
                gene={gene}
                patientId={patientId}
              />
            )
          )}
        </div>
      </div>
      {activeGene && <VariantPanel patientId={patientId} />}
      {!activeGene && genes.length > 0 && (
        <div>
          <div className="text-center">
            <DataTableFooter
              showTotalCount={false}
              page={pageIndex}
              pages={lastPage}
              onPageChange={setPageAndReloadVariants}
              data={new Array(totalItems)}
              pageSizeOptions={[10]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  genes: selectors.getCurrentVisibleGenes(state),
  totalVariants: selectors.getTotalGeneVariantsCount(state),
  loaded: selectors.isVariantsTableLoaded(state),
  page: selectors.page(state),
  pager: selectors.pager(state),
  columns: configSelectors.getTableColumns(state),
  activeGene: selectors.getSelectedGene(state),
  isAriadneEnabled: configSelectors.isAriadneEnabled(state),
  isAnyAriadneFilterActive: configSelectors.getFilterCategoriesWithValues(
    state,
    configConstants.SNV_TABLE_CONFIG_FORM
  ).ARIADNE,
  geneToFocus: selectors.geneToFocus(state),
});

const mapDispatchToProps = {
  resetVariantPanel: actions.resetVariantPanel,
  setPage: actions.setPage,
  reloadVariantPage: actions.reloadVariantPage,
  applyAriadneSuggestions: configActions.applyAriadneSuggestions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VariantsTable);
