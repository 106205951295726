export const NAME = "config";

export const GENE_PANELS_FIELD = "genePanels";
export const INHERITANCE_FIELD = "inheritance";
export const DE_NOVO_STATUS_FIELD = "denovoStatus";
export const MOI_FIELD = "moi";
export const FAMILY_FIELD = "familyComparisons";
export const CONSEQUENCE_FIELD = "vepConsequence";

// Actions
export const UPDATE_CONFIG_VALUES = `${NAME}/UPDATE_CONFIG_VALUES`;

export const APPLY_ARIADNE_SUGGESTIONS = `${NAME}/APPLY_ARIADNE_SUGGESTIONS`;
export const RESET_CONFIG = `${NAME}/RESET_CONFIG`;
export const UPDATE_CONFIG = `${NAME}/UPDATE_CONFIG`;

// Forms
export const SNV_TABLE_CONFIG_FORM = "SNVTableConfigForm";
export const SNV_PRESET_VIEW_FORM = "SNV_PRESET_VIEW_FORM";

// Patient Entity

export const FETCH_PATIENT_CONFIG_ENTITY_START =
  "FETCH_PATIENT_CONFIG_ENTITY_START";
export const FETCH_PATIENT_CONFIG_ENTITY_SUCCESS =
  "FETCH_PATIENT_CONFIG_ENTITY_SUCCESS";
export const FETCH_PATIENT_CONFIG_ENTITY_FAILURE =
  "FETCH_PATIENT_CONFIG_ENTITY_FAILURE";

//mode
const PRESET_EDIT = "PRESET_EDIT";
const PRESET_VIEW = "PRESET_VIEW";
const CONFIG_EDIT = "CONFIG_EDIT";
export const CONFIG_MODE = {
  PRESET_EDIT,
  PRESET_VIEW,
  CONFIG_EDIT,
};

//Tabs
export const AUTO_ACMG_TAB = "Auto ACMG";

// Reference Data Legacy Versions
export const EXAC_LEGACY_VERSION = "v0.3";
export const GNOMAD_LEGACY_VERSION = "v2.0.2";

// VEP Consequences
export const FETCH_VEP_CONSEQUENCES_START = "FETCH_VEP_CONSEQUENCES_START";
export const FETCH_VEP_CONSEQUENCES_SUCCESS = "FETCH_VEP_CONSEQUENCES_SUCCESS";
export const FETCH_VEP_CONSEQUENCES_FAILURE = "FETCH_VEP_CONSEQUENCES_FAILURE";
