import React, { useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { CLOUD_PROVIDER_ALIBABA } from "common/constants";
import { StickyToLeftFooter } from "layout/partials";
import * as authActions from "modules/auth/actions";
import * as authSelectors from "modules/auth/selectors";
import IRContainer from "modules/interpretationRequests/IRContainer";
import ErrorContainer from "modules/messages/Container";
import { ModalRegistry } from "modules/modalRegistry";
import { showModal } from "modules/modalRegistry/actions";
import { registry } from "modules/modalRegistry/config";
import { modalTypes } from "modules/modalRegistry/constants";
import {
  getCEMarking,
  getCloudProvider,
  getSystemConfigBlockUI,
} from "modules/systemConfig";
import { UIOverflow } from "modules/systemConfig/components";

import { SHA } from "./config/default";
import { AuthLayout } from "./layout";
import NotFoundLayout from "./layout/NotFoundLayout";
import PatientLayout from "./layout/PatientLayout";
import ProjectsLayout from "./layout/ProjectsLayout";
import BrowserTitle from "./layout/partials/BrowserTitle";
import { PartialNavData } from "./layout/partials/Footer/Footer.types";
import { getUserManualLink } from "./modules/about/components/regulatoryInfo/selectors";

/*
  Encoded version of:
  Copyright © 2020 苏州康剑尼科生物科技有限公司. All rights reserved. 苏ICP备: 19037342号

  TODO: (Eventually) Generalise this logic so a custom footer can be configured more easily
*/
const customChinaFooter = (
  <p data-testid="custom-china-footer">
    Copyright &copy; 2020
    &#x82cf;&#x5dde;&#x5eb7;&#x5251;&#x5c3c;&#x79d1;&#x751f;&#x7269;&#x79d1;&#x6280;&#x6709;&#x9650;&#x516c;&#x53f8;.
    All rights reserved.{" "}
    <a href="https://beian.miit.gov.cn" target="_new">
      &#x82cf;ICP&#x5907;: 19037342&#x53f7;
    </a>
  </p>
);

export const App = ({
  getCurrentUser,
  blockUI,
  cloudProvider,
  ceMarking,
  currentUser,
  userManual,
  showModal,
}: PropsFromRedux) => {
  const footerNavData = useMemo<PartialNavData>(() => {
    const navData: any = {
      about: {
        action: () => showModal(modalTypes.ABOUT_MODAL),
      },
      bugReport: {
        action: () =>
          showModal(modalTypes.FEEDBACK_MODAL, { type: "bugReport" }),
      },
      feedback: {
        action: () =>
          showModal(modalTypes.FEEDBACK_MODAL, { type: "feedback" }),
      },
    };
    if (userManual) {
      navData.userManual = { action: userManual };
    }
    return navData;
  }, [userManual, showModal]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const customFooter =
    cloudProvider === CLOUD_PROVIDER_ALIBABA ? customChinaFooter : null;

  return (
    <div className="app-wrapper">
      <BrowserTitle />
      <AuthLayout className="app-content">
        <div id="content" className="container">
          <UIOverflow visible={blockUI} center>
            Loading system configuration error. Please try to reload the page
          </UIOverflow>
          <DndProvider backend={HTML5Backend}>
            <Switch>
              <Route
                exact
                path="/projects/:projectId/ir-submission"
                render={props => (
                  <IRContainer
                    {...props}
                    projectId={props.match.params.projectId}
                  />
                )}
              />
              <Route exact path="/">
                <Redirect to="/projects" />
              </Route>
              <Route path="/projects" component={ProjectsLayout} />
              <Route path="/projects/:projectId" component={ProjectsLayout} />
              <Route path="/patient/:patientId" component={PatientLayout} />
              <Route component={NotFoundLayout} />
            </Switch>
          </DndProvider>
          <ErrorContainer />
        </div>
      </AuthLayout>
      {!blockUI && (
        <>
          <ModalRegistry registry={registry} />
          <StickyToLeftFooter
            ceMarking={ceMarking}
            customFooter={customFooter}
            navData={footerNavData}
            shaHash={SHA}
            userLoggedIn={!!currentUser}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: authSelectors.currentUser(state),
  blockUI: getSystemConfigBlockUI(state),
  cloudProvider: getCloudProvider(state),
  ceMarking: getCEMarking(state),
  userManual: getUserManualLink(state),
});

const mapDispatchToProps = {
  showModal,
  getCurrentUser: authActions.isUserLoggedIn,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(App));
