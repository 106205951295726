// @flow
import { createAction } from "../utils";

import type { VariantRequestParams, VariantsData } from "./flow-types";

export const actionType = Object.freeze({
  FETCH_VARIANTS_START: "FETCH_VARIANTS_START",
  FETCH_VARIANTS_SUCCESS: "FETCH_VARIANTS_SUCCESS",
  FETCH_VARIANTS_FAILURE: "FETCH_VARIANTS_FAILURE",
});

export const fetchVariants: AsyncActionCreator = {
  start: (
    params: VariantRequestParams
  ): FSA<typeof actionType.FETCH_VARIANTS_START, VariantRequestParams> =>
    createAction(actionType.FETCH_VARIANTS_START, params),
  success: (
    data: VariantsData
  ): FSA<typeof actionType.FETCH_VARIANTS_SUCCESS, VariantsData> =>
    createAction(actionType.FETCH_VARIANTS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_VARIANTS_FAILURE> =>
    createAction(actionType.FETCH_VARIANTS_FAILURE),
};

export type Action = Return<
  fetchVariants.start | fetchVariants.success | fetchVariants.failure
>;
