import classnames from "classnames";
import React, { useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Button, LoadingOverlay, Tooltip } from "pattern-library";

import styles from "./CVLContainer.module.scss";
import {
  initCuratedLists,
  getCVLDetails,
  setResetDisplayMode,
  resetDisplayMode,
} from "./actions";
import CVLActions from "./components/CVLActions";
import CVLExportModal from "./components/CVLExportModal";
import CVLTable from "./components/CVLTable";
import CVLView from "./components/CVLView";
import { DISPLAY_MODE_NONE } from "./constants";
import {
  isDataLoading,
  getProjectName,
  getInheritedCVLs,
  getProjectOwnCVLs,
  getDisplayMode,
  needUpdate,
} from "./selectors";

interface CVLContainerProps extends PropsFromRedux {
  projectId: number;
  projectName: string;
  location: { search?: string };
}

export const CVLContainer = ({
  projectId,
  projectName,
  initCuratedLists,
  loading,
  needUpdate,
  projectOwnCVLs,
  inheritedCVLs,
  displayMode,
  setResetDisplayMode,
  getCVLDetails,
  location: { search = "" } = {},
  resetDisplayMode,
}: CVLContainerProps) => {
  const refreshData = useCallback(() => {
    initCuratedLists(projectId);
  }, [initCuratedLists, projectId]);

  useEffect(() => {
    const cvlId = new URLSearchParams(search).get("cvlId");
    if (cvlId) {
      setResetDisplayMode(false);
      getCVLDetails(cvlId, projectId, projectId);
    }
  }, [search, projectId, getCVLDetails, setResetDisplayMode]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  useEffect(
    () => () => {
      resetDisplayMode();
    },
    [resetDisplayMode]
  );

  useEffect(() => {
    if (displayMode === DISPLAY_MODE_NONE && needUpdate) {
      refreshData();
    }
  }, [displayMode, needUpdate, refreshData]);

  const cvlTables = (
    <>
      <div
        className={classnames(
          "clearfix",
          styles.componentRefreshButtonContainer
        )}
      >
        <Tooltip content="Get latest curated lists information">
          <Button className="btn-info" onClick={refreshData} disabled={loading}>
            <i className="glyphicon glyphicon-refresh" /> Refresh curated lists
          </Button>
        </Tooltip>
      </div>
      <div className="dataTables_info own-lists">
        <CVLTable
          items={projectOwnCVLs}
          isLoading={loading}
          title={`Curated variant lists in project ${projectName}`}
          projectId={projectId}
        />
        <div
          className={classnames(
            "table-buttons inline-buttons clearfix",
            styles.componentActionButtonsContainer
          )}
        >
          <CVLActions />
        </div>
      </div>

      <hr />

      <div className="dataTables_info parent-lists">
        <CVLTable
          items={inheritedCVLs}
          isLoading={loading}
          isProjectOwnTable={false}
          title="Curated variant lists in parent projects"
          projectId={projectId}
        />
      </div>
    </>
  );

  return (
    <div className={classnames("curated-lists", styles.component)}>
      <LoadingOverlay loading={loading}>
        {displayMode !== DISPLAY_MODE_NONE && <CVLView />}
        {displayMode === DISPLAY_MODE_NONE && cvlTables}
      </LoadingOverlay>
      <CVLExportModal />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { projectId } = props;
  return {
    displayMode: getDisplayMode(state),
    loading: isDataLoading(state),
    needUpdate: needUpdate(state),
    projectOwnCVLs: getProjectOwnCVLs(state),
    inheritedCVLs: getInheritedCVLs(state),
    projectName: getProjectName(state, projectId),
  };
};

const mapDispatchToProps = {
  initCuratedLists,
  setResetDisplayMode,
  getCVLDetails,
  resetDisplayMode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLContainer);
