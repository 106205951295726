import React from "react";

import { Dropdown } from "pattern-library";

type EnsemblFilterProps = {
  id: string;
  versions: Version[];
  onChange: (selectedVersion: string | "ALL") => unknown;
};

const EnsemblFilter: React.FC<EnsemblFilterProps> = ({
  id,
  versions,
  onChange,
}) => {
  const ensemblVersions = versions.map(({ version, is_active }) => {
    const suffix = is_active ? " (current system version)" : "";
    return {
      value: version,
      label: `Ensembl ${version}${suffix}`,
    };
  });

  const options = [
    {
      value: "ALL",
      label: "All versions",
    },
    ...ensemblVersions,
  ];

  return (
    <>
      <label htmlFor={id}>Filter by Ensembl version:</label>
      <Dropdown
        id={id}
        className="mb-0"
        options={options}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          onChange(e.currentTarget.value)
        }
      />
    </>
  );
};

export default EnsemblFilter;
