import { isNil } from "ramda";

import { toDecimalPlaces } from "modules/utils/format";

export const colors = {
  GREEN: "#00FF00",
  ORANGE: "#FF7400",
  RED: "#FF0000",
  YELLOW: "#FFCC00",
};

export const getPLiScoreColor = value =>
  isNil(value) ? null : value < 0.9 ? colors.GREEN : colors.ORANGE;

export const getLOEUFScoreColor = value => {
  if (isNil(value)) {
    return null;
  }
  if (value < 0.33) {
    return colors.RED;
  }
  if (value < 0.66) {
    return colors.ORANGE;
  }
  if (value < 1.0) {
    return colors.YELLOW;
  }
  return colors.GREEN;
};

export const getMissenseZScoreColor = value =>
  isNil(value) ? null : value > 3.09 ? colors.ORANGE : colors.GREEN;

export const formatConstraintsScore = value => toDecimalPlaces(value, 3);
