export const NAMESPACE = "PATIENT_METADATA" as const;

export const METADATA_FIELD_FORM = "METADATA_FIELD_FORM" as const;
export const METADATA_CATEGORY_FORM = "METADATA_CATEGORY_FORM" as const;

export const ADD_METADATA_FIELD = "ADD_METADATA_FIELD" as const;
export const EDIT_METADATA_FIELD = "EDIT_METADATA_FIELD" as const;
export const DELETE_METADATA_FIELD = "DELETE_METADATA_FIELD" as const;

export const ADD_METADATA_CATEGORY = "ADD_METADATA_CATEGORY" as const;
export const EDIT_METADATA_CATEGORY = "EDIT_METADATA_CATEGORY" as const;
export const DELETE_METADATA_CATEGORY = "DELETE_METADATA_CATEGORY" as const;

export const FIELD = "FIELD" as const;
export const CATEGORY = "CATEGORY" as const;

export const FIELD_TYPES = {
  Text: "Text",
  TextArea: "TextArea",
  Date: "Date",
  "Select::Age": "Select::Age",
  "Select::Ethnicity": "Select::Ethnicity",
  "Select::Sex": "Select::Sex",
  "Select::ParentalPhenotype": "Select::ParentalPhenotype",
};
