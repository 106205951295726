import PropTypes from "prop-types";
import React, { memo } from "react";

import { Link } from "pattern-library";

import { AuditInfo } from "modules/audit";

const getTSVFileUrl = patientId => `/webapi/entities/patients/${patientId}/tsv`;

const Header = memo(({ patientId }) => (
  <>
    <h4>Genotype</h4>
    <div className="ref-no-call__header-block">
      <AuditInfo patientId={patientId} />
      <div className="ref-no-call__header-block-spacer" />
      <div className="ref-no-call__header-block-right">
        <Link href={getTSVFileUrl(patientId)} target="_blank">
          <i className="glyphicon glyphicon-export" />
          &nbsp; Export data as TSV file
        </Link>
      </div>
    </div>
  </>
));

Header.displayName = "Header";

Header.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default Header;
