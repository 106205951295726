import React, { memo } from "react";

import { InfoField, InfoList } from "pattern-library";

import { EU_REGION } from "../../constants";
import { getCEMarkings } from "../../utils";

const EU = () => {
  const oneColumnMarkings = [];
  const twoColumnsMarkings = getCEMarkings(EU_REGION).filter(marking => {
    const { icon } = marking;
    if (!["manufacturer", "ecRep"].includes(icon)) {
      oneColumnMarkings.push(marking);
      return false;
    }
    return true;
  });
  return (
    <>
      <InfoList data={oneColumnMarkings} />
      <div className="info-list row">
        {twoColumnsMarkings.map(({ icon, text, className }) => (
          <div key={text} className="col-xs-6">
            <InfoField
              text={text}
              type={icon}
              position="left"
              className={className}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(EU);
