import { useEffect, useState, useCallback } from "react";

const TEXT_FIELD_TYPES = ["number", "text", "password", "email", "textarea"];

export const useEventHandlers = props => {
  const {
    changeOnBlur = false,
    field: {
      value: fieldValue,
      onChange: fieldOnChange,
      onBlur: fieldOnOnBlur,
    },
    onChange: userOnChange,
    onBlur: userOnBlur,
    normalize,
  } = props;

  const [componentValue, setComponentValue] = useState(fieldValue);

  const normalizeValue = useCallback(
    (value, previousValue) =>
      normalize ? normalize(value, previousValue) : value,
    [normalize]
  );

  useEffect(() => setComponentValue(fieldValue), [fieldValue]);

  const setComponentValueOnChange = useCallback(
    async ({ target: { value } }) => {
      setComponentValue(normalizeValue(value, componentValue));
    },
    [normalizeValue, componentValue]
  );

  const onChangeOnBlur = useCallback(
    async e => {
      e.target.value = componentValue || "";
      // Call formik's onChange and onBlur
      await fieldOnChange(e);
      await fieldOnOnBlur(e);
      // and then any onChange and onBlur handlers the consumer wants to call
      if (userOnChange) {
        await userOnChange(e);
      }
      if (userOnBlur) {
        userOnBlur(e);
      }
    },
    [componentValue, fieldOnChange, fieldOnOnBlur, userOnChange, userOnBlur]
  );

  const onChange = useCallback(
    async e => {
      // Call formik's on change
      await fieldOnChange(e);
      // and then any onchange the consumer wants to call
      if (userOnChange) {
        userOnChange(e);
      }
    },
    [fieldOnChange, userOnChange]
  );

  const onBlur = useCallback(
    async e => {
      await fieldOnOnBlur(e);
      if (userOnBlur) {
        userOnBlur(e);
      }
    },
    [fieldOnOnBlur, userOnBlur]
  );

  if (changeOnBlur && TEXT_FIELD_TYPES.includes(props.type)) {
    return [setComponentValueOnChange, onChangeOnBlur, componentValue];
  } else {
    return [onChange, onBlur, componentValue];
  }
};
