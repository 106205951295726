import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";

import catalystApi from "api/catalyst-api";

const { useLazyGetProjectQuery, useLazyGetPatientAndTabsQuery } = catalystApi;

const asNumber = value => {
  const result = Number(value);
  return Number.isNaN(result) ? null : result;
};

export const getProjectIdOrPatientId = (
  pathname: string
): [number | null, number | null] => {
  let projectId: number | null = null;
  let patientId: number | null = null;
  if (pathname) {
    const parts = pathname.replaceAll("#", "/").split("/");
    let idx = parts.findIndex(part => part.includes("project"));
    if (idx !== -1) {
      projectId = asNumber(parts[idx + 1]);
    } else {
      idx = parts.findIndex(part => part.includes("patient"));
      if (idx !== -1) {
        patientId = asNumber(parts[idx + 1]);
      }
    }
  }

  return [projectId, patientId];
};

//useLocation doesn't work in perl application. window.location.pathname already equals "/" in tests
const usePathname = () => {
  try {
    return useLocation()?.pathname;
  } catch (_) {
    return window.location.pathname;
  }
};

export const useCurrentProject = () => {
  const pathname = usePathname();
  const [projectId, patientId] = useMemo(
    () => getProjectIdOrPatientId(pathname),
    [pathname]
  );

  const [getPatient, { data: { patient = null } = {} }] =
    useLazyGetPatientAndTabsQuery();

  const [getProject, response] = useLazyGetProjectQuery();

  useEffect(() => {
    if (!projectId && patientId) {
      getPatient({ patientId });
    }
  }, [getPatient, patientId, projectId]);

  useEffect(() => {
    const id = projectId || patient?.projectId;
    if (id) {
      getProject({ projectId: id });
    }
  }, [projectId, patient, getProject]);

  return useMemo(() => response, [response]);
};
