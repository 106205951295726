import React, { PureComponent } from "react";

export default class Divider extends PureComponent {
  static displayName = "Divider";

  render() {
    return (
      <i className="fa fa-angle-double-right text-muted breadcrumbs-divider" />
    );
  }
}
