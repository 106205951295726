import PropTypes from "prop-types";
import React, { memo } from "react";
import { Field } from "redux-form";

import { CheckboxGroup } from "pattern-library";

import { SV_COLUMNS } from "../constants";

const ColumnsCheckboxGroup = ({
  disabled,
  meta: { pristine },
  input: { value = [], onChange },
  options,
}) => {
  const changeHandler = value => {
    const orderedValue = SV_COLUMNS.map(({ key }) => key).filter(key =>
      value.includes(key)
    );
    onChange(orderedValue);
  };

  return (
    <CheckboxGroup
      label="Columns"
      options={options}
      onChange={changeHandler}
      disabled={disabled}
      value={value}
      valueSort={false}
      pristine={pristine}
    />
  );
};

export const SVColumnsForm = memo(({ isDisabled = false }) => (
  <Field
    component={ColumnsCheckboxGroup}
    name="config.columns"
    disabled={isDisabled}
    options={SV_COLUMNS}
  />
));

SVColumnsForm.displayName = "SVColumnsForm";

SVColumnsForm.propTypes = {
  isDisabled: PropTypes.bool,
};

export default SVColumnsForm;
