import React, { useState, useEffect } from "react";

import { Input } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import legacyApi from "../../../api/legacy-api";

const { useGetProjectVersionsQuery } = legacyApi;

export const VersionSummary = React.memo(({ versionSummaryData, loading }) => {
  const [filteredVersionData, setFilteredVersionData] =
    useState(versionSummaryData);
  useEffect(() => {
    setFilteredVersionData(versionSummaryData);
  }, [versionSummaryData]);

  const handleFilterChange = ({ target: { value } }) => {
    const filterValue = value.toUpperCase();
    setFilteredVersionData(
      versionSummaryData.filter(
        item =>
          (item.label && item.label.toUpperCase().includes(filterValue)) ||
          (item.value && item.value.toUpperCase().includes(filterValue))
      )
    );
  };

  const versionSummaryColumns = [
    {
      Header: "Feature",
      accessor: "label",
    },
    {
      Header: "Version",
      accessor: "value",
    },
  ];

  return (
    <div>
      <div className="table-header">
        <h4 className="table-header-info">Version Summary</h4>
        <Input
          className="table-header-filter"
          placeholder="Filter..."
          onChange={handleFilterChange}
        />
      </div>
      <Table
        columns={versionSummaryColumns}
        data={filteredVersionData}
        autoResetPage={false}
        noDataText="No version data available."
        loading={loading}
        showPagination={filteredVersionData.length > DEFAULT_PAGE_SIZE}
      />
    </div>
  );
});

export const VersionSummaryContainer = React.memo(({ projectId }) => {
  const { data: versionSummaryData, isLoading } = useGetProjectVersionsQuery({
    projectId,
  });

  return (
    <VersionSummary
      versionSummaryData={versionSummaryData || []}
      loading={isLoading}
    />
  );
});
