import classNames from "classnames";
import { Field, FieldArray } from "formik";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { connect } from "react-redux";

import { Button } from "pattern-library";

import { FormikFormField } from "modules/forms/components";
import * as messageActions from "modules/messages/actions";

import { hasCNVRequestFailed } from "../../selectors";

import { processFiles } from "./CSVFileReader";
import { ReferencePanelTable } from "./table/ReferencePanelTable";

const CNVCallingFormContent = memo(props => {
  const {
    error,
    warning,
    info,
    cnvAnalysisRequested,
    references,
    selectedRowNumber,
    push: addReference,
    remove,
    disabled,
    cnvRequestFailed,
  } = props;
  // Reference panels are disabled if CNV analyis is not requested.
  // If CNV analysis is requested, reference panels should be disabled if
  // - the rules for "normal" IR fields apply (see IRFormGrid for details)
  // - CNV request failed.
  // Users should be able resubmit only CNV after an IR is successfully created

  // cnvRequestFailed is a boolean wrapper for non-binary status -
  // hence it's necessary to check if cnvRequestFailed is true.
  // false in this case means any status other than failure
  const referencePanelTableDisabled = cnvRequestFailed
    ? false
    : !cnvAnalysisRequested || disabled;

  const addEmptyReference = useCallback(
    e => {
      e.preventDefault();
      if (referencePanelTableDisabled) {
        return;
      }
      addReference({
        projectId: "",
        name: "",
      });
    },
    [referencePanelTableDisabled, addReference]
  );

  const [{ canDrop, isOver }, drop] = useDrop({
    canDrop: () => !referencePanelTableDisabled,
    accept: [NativeTypes.FILE],

    async drop(item, monitor) {
      processFiles(
        monitor.getItem().files || [],
        addReference,
        info,
        warning,
        error
      );
    },

    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <div className="form-horizontal">
      <div className="col-xs-6">
        <div className="row">
          <Field
            narrow
            id={`samples.${selectedRowNumber}.cnvAnalysisRequested`}
            name={`samples.${selectedRowNumber}.cnvAnalysisRequested`}
            type="checkbox"
            component={FormikFormField}
            label="Perform CNV Analysis"
            disabled={disabled}
          />
        </div>
        <div className="row">
          <strong>CNV Reference Panel</strong>
        </div>
        <div
          ref={drop}
          className={classNames(
            {
              "dnd-target-area-active": isActive,
              "ref-panels-empty": !references.length,
            },
            "dnd-target-area",
            "ref-panels",
            "row"
          )}
        >
          <ReferencePanelTable
            references={references}
            selectedRowNumber={selectedRowNumber}
            remove={remove}
            disabled={referencePanelTableDisabled}
          />
        </div>
        <div className="row">
          <Button
            className="pull-right"
            disabled={referencePanelTableDisabled}
            onClick={addEmptyReference}
            type=""
          >
            Add Reference
          </Button>
        </div>
      </div>
    </div>
  );
});

CNVCallingFormContent.propTypes = {
  /**
   * Selected sample index
   */
  selectedRowNumber: PropTypes.number.isRequired,
  /**
   * indicates if form fields and buttons should be disabled
   */
  disabled: PropTypes.bool.isRequired,
  /**
   * true if CNV analysis checkbox is ticked for the currently selected sample
   */
  cnvAnalysisRequested: PropTypes.bool.isRequired,
  /**
   * a list of reference panels for the currently selected sample
   * Can be empty if CNV analysis is not requested
   */
  references: PropTypes.array.isRequired,
  /**
   * Formik-supplied API to add an item to an array
   */
  push: PropTypes.func.isRequired,
  /**
   * Formik-supplied API to remove an item from an array by index
   */
  remove: PropTypes.func.isRequired,
  /**
   * true if CNV request status = failed. False if the request has any other status
   */
  cnvRequestFailed: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  cnvRequestFailed: hasCNVRequestFailed(state),
});
const mapDispatchToProps = {
  info: messageActions.info,
  error: messageActions.error,
  warning: messageActions.warning,
};

const CNVCallingFormContentWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(CNVCallingFormContent);

const CNVCallingForm = props => {
  const { selectedRowNumber } = props;
  return (
    <FieldArray name={`samples.${selectedRowNumber}.references`}>
      {({
        push,
        remove,
        form: {
          values: { samples = [] },
        },
      }) => {
        const sample = samples[selectedRowNumber];
        const { cnvAnalysisRequested, references } = sample;
        return (
          <CNVCallingFormContentWrapper
            cnvAnalysisRequested={cnvAnalysisRequested}
            references={references}
            push={push}
            remove={remove}
            {...props}
          />
        );
      }}
    </FieldArray>
  );
};

CNVCallingForm.propTypes = {
  /**
   * Selected sample index
   */
  selectedRowNumber: PropTypes.number.isRequired,
  /**
   * indicates if form fields and buttons should be disabled
   */
  disabled: PropTypes.bool.isRequired,
  /**
   * the id of the project a new IR is created in
   */
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CNVCallingForm;
