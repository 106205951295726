export const NAME = "patients" as const;
export const INIT = "PATIENTS_INIT" as const;
export const SET_PROJECT_DATA = "SET_PROJECT_DATA" as const;
export const SET_PATIENT_FILTER = "SET_PATIENT_FILTER" as const;
export const RESET_PATIENT_STATUS = "RESET_PATIENT_STATUS" as const;
export const SET_REPORTS_MODAL_VISIBLE = "SET_REPORTS_MODAL_VISIBLE" as const;
export const LOAD_REPORTS = "LOAD_REPORTS" as const;
export const SET_REPORTS = "SET_REPORTS" as const;

export const INIT_COMPLETE = "INIT_COMPLETE" as const;
export const SET_LOADING = "SET_PATIENTS_LOADING" as const;
