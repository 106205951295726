import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { not } from "ramda";
import React, { PureComponent } from "react";

import Tree from "./Tree";

export default class TreeNode extends PureComponent {
  static propTypes = {
    TreeComponent: PropTypes.func,
    root: PropTypes.shape({
      data: PropTypes.object,
      children: PropTypes.array.isRequired,
    }).isRequired,
    RenderComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.element,
      PropTypes.func,
    ]),
    handleClick: PropTypes.func,
    expanded: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { handleClick, root } = this.props;
    if (handleClick) {
      handleClick(root);
    }
  }

  render() {
    const {
      TreeComponent = Tree,
      RenderComponent,
      root: { id, children, data },
      expanded = false,
    } = this.props;
    const hasChildren = children.length > 0;

    return (
      <div onClick={this.handleClick}>
        <RenderComponent
          {...data}
          hasChildren={hasChildren}
          expanded={expanded}
          id={id}
        />
        <div
          className={classNames("tree-node", {
            "tree-node-expanded": expanded,
            "tree-node-collapsed": not(expanded),
          })}
        >
          {expanded && <TreeComponent {...this.props} />}
        </div>
      </div>
    );
  }
}
