import React from "react";

import ProjectSettingsInitialisationWrapper from "./components/ProjectSettingsInitialisationWrapper";

export const submitAndResetFormik = (values, formikBag) => {
  const {
    props: { handleSubmit },
  } = formikBag;
  handleSubmit(values, formikBag);
};

export const withProjectSettingsWrapper = ({
  entityType,
  component,
  ...rest
}) => {
  if (!entityType || !component)
    throw new Error(
      "Wrapper is missing a required property, path, entityType, or component"
    );

  return props => (
    <ProjectSettingsInitialisationWrapper
      {...rest}
      {...props}
      entityType={entityType}
      component={component}
    />
  );
};
