export const metadataAffectionStatus = [
  {
    name: "affected",
    label: "Affected",
    value: "Affected with related or similar phenotype",
  },
  { name: "unaffected", label: "Unaffected", value: "Unaffected" },
  { name: "unknown", label: "Unknown", value: "Of unknown phenotype" },
];

export default [
  { name: "affected", label: "Affected" },
  { name: "unaffected", label: "Unaffected" },
  { name: "unknown", label: "Unknown" },
];
