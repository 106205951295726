export const NAMESPACE = "modalsRegistry";

export const modalTypes = {
  ADD_GENE_PANEL_MODAL: "ADD_GENE_PANEL_MODAL",
  EXPORT_GENE_PANEL_MODAL: "EXPORT_GENE_PANEL_MODAL",
  DELETE_GENE_PANEL_MODAL: "DELETE_GENE_PANEL_MODAL",
  ARCHIVE_GENE_PANEL_MODAL: "ARCHIVE_GENE_PANEL_MODAL",
  GENE_INFO_MODAL: "GENE_INFO_MODAL",
  EDIT_TEMPORARY_GENE_MODAL: "EDIT_TEMPORARY_GENE_MODAL",
  EDIT_GENE_MODAL: "EDIT_GENE_MODAL",
  EDIT_GENE_PANEL_MODAL: "EDIT_GENE_PANEL_MODAL",
  DELETE_GENE_FROM_GENE_PANEL_MODAL: "DELETE_GENE_FROM_GENE_PANEL_MODAL",
  API_TOKEN_MODAL: "API_TOKEN_MODAL",
  FEEDBACK_MODAL: "FEEDBACK_MODAL",
  ABOUT_MODAL: "ABOUT_MODAL",
};
