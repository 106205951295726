import PropTypes from "prop-types";
import React from "react";

export const FormFieldDescription = ({ description }) => (
  <div data-testid="form-field-description">
    <small className="text-muted">{description}</small>
  </div>
);

FormFieldDescription.propTypes = {
  description: PropTypes.string.isRequired,
};
