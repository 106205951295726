import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { LoadingOverlay, Tabs } from "pattern-library";

import {
  NotFoundMessage,
  RouteTabContent,
  RouteTabHeader,
} from "layout/partials";

import { initialise } from "./actions";
import Categories from "./components/Categories";
import Fields from "./components/Fields";
import { getCategoriesCount, getFieldsCount, isLoading } from "./selectors";

const getTabs = (projectId, fieldsCount, categoriesCount) => [
  {
    name: "Fields",
    component: Fields,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/fields`,
    count: fieldsCount,
  },
  {
    name: "Categories",
    component: Categories,
    componentProps: {
      projectId: `${projectId}`,
    },
    url: `/categories`,
    count: categoriesCount,
  },
];

const Container = ({
  projectId,
  initialise,
  isLoading,
  categoriesCount,
  fieldsCount,
}) => {
  useEffect(() => {
    initialise(projectId);
  }, [initialise, projectId]);

  const tabs = useMemo(
    () => getTabs(projectId, fieldsCount, categoriesCount),
    [projectId, categoriesCount, fieldsCount]
  );
  return (
    <LoadingOverlay data-testid="metadata-loading-overlay" loading={isLoading}>
      <div>
        {projectId && (
          <Switch>
            <Route
              exact
              path={`/projects/${projectId}/settings/patient-metadata`}
            >
              <Redirect
                to={`/projects/${projectId}/settings/patient-metadata/fields`}
              />
            </Route>
            <Tabs
              tabs={tabs}
              baseUrl={`/projects/${projectId}/settings/patient-metadata`}
              routePath="/projects/:projectId/settings/patient-metadata"
              tabComponent={RouteTabHeader}
              contentComponent={RouteTabContent}
            />
            <Route component={NotFoundMessage} />
          </Switch>
        )}
      </div>
    </LoadingOverlay>
  );
};

Container.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const mapStateToProps = createStructuredSelector({
  isLoading,
  fieldsCount: getFieldsCount,
  categoriesCount: getCategoriesCount,
});

const mapDispatchToProps = {
  initialise,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
