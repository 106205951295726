import PropTypes from "prop-types";
import { isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { NoDataComponent } from "pattern-library";

import { error } from "../messages/actions";
import { fetchCamelizeData } from "../utils";

import HpoData from "./components/HpoData";
import MoiPane from "./components/MoiPane";
import NoScoresData from "./components/NoScoresData";
import VariantData from "./components/VariantData";

export const Exomiser = ({ patientId, geneId, variantId }) => {
  const [data, setData] = useState({});
  const [message, setMessage] = useState("Fetching Exomiser scores...");

  const url = (patientId, geneId, variantId) =>
    `/webapi/entities/patient_exomiser/patient_id/${patientId}/gene_id/${geneId}/variant_id/${variantId}`;

  useEffect(() => {
    async function fetchData() {
      const response = await fetchCamelizeData(
        url(patientId, geneId, variantId),
        undefined,
        true
      );

      if (response.ok) {
        const {
          payload: {
            data: { attributes },
          },
        } = response;
        setData(attributes);
      } else {
        const { errors: [{ detail, code }] = [] } = response;

        if (code === "2000") {
          setMessage(detail);
        } else {
          const errorMsg = "Unable to load Exomiser data";
          setMessage(errorMsg);
          error(errorMsg);
        }
      }
    }
    fetchData();
  }, [patientId, geneId, variantId]);

  const getExomiserComponent = () => {
    if (isEmpty(data)) {
      return (
        <NoDataComponent
          className="exomiser-no-data"
          data-testid="exomiser-no-data"
        >
          {message}
        </NoDataComponent>
      );
    }
    const { noScoresData } = data;
    if (noScoresData) {
      return (
        <NoScoresData data-testid="exomiser-no-scores-data" {...noScoresData} />
      );
    }
    const { moiData = {}, variantData = {}, hpoData = [] } = data;
    return (
      <div className="exomiser-row" data-testid="exomiser-scores-data">
        <MoiPane data={moiData} className="exomiser-data" />
        <VariantData data={variantData} className="exomiser-data" />
        <HpoData data={hpoData} className="exomiser-data" />
      </div>
    );
  };

  return <div className="exomiser container">{getExomiserComponent()}</div>;
};

Exomiser.propTypes = {
  patientId: PropTypes.string.isRequired,
  geneId: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
};

export default connect(undefined, { error })(Exomiser);
