// @flow
declare type FormField = {
  name: string,
  value: string,
  label: string,
};
export default function convertStringArrayToFormField(
  inputArray: Array<string>
): Array<FormField> {
  return inputArray.map((key: string) => ({
    name: key,
    value: key,
    label: key,
  }));
}
