import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { GeneColumn } from "pattern-library";
import { Icon } from "pattern-library/elements/base";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import CustomTier from "modules/variants/components/columns/CustomTier";

import * as actions from "../actions";
import { PageParams } from "../flow-types";
import * as selectors from "../selectors";

import ImageModal from "./ImageModal";
import StrDetails from "./StrDetails";

export const getColumns = showImage => [
  {
    Header: "Gene Names",
    Cell: ({
      row: {
        original: { gene },
      },
    }) => <GeneColumn {...gene} />,
  },
  {
    Header: "Chromosome",
    accessor: "chromosome",
  },
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "End",
    accessor: "end",
  },
  {
    Header: "Repeat Unit",
    accessor: "repeatUnit",
  },
  {
    Header: "Reference Repeat length",
    accessor: "referenceRepeatCount",
  },
  {
    Header: "Patient Repeat length",
    accessor: "patientRepeatCount",
  },
  {
    Header: "Confidence Interval",
    accessor: "confidenceInterval",
  },
  {
    Header: "Genotype",
    accessor: "genotype",
  },
  {
    Header: "Tier",
    Cell: ({
      row: {
        original: { tieringData: tiers },
      },
    }) => {
      const customData = JSON.stringify({ tiers });
      return <CustomTier variant={{ customData }} showTranscriptName={false} />;
    },
  },
  {
    Header: "Image",
    Cell: ({
      row: {
        original: { imagePath },
      },
    }) => {
      const onClick = e => {
        e.stopPropagation();
        showImage(imagePath);
      };

      if (!imagePath) {
        return null;
      }
      return (
        <div className="image-icon">
          <Icon type="blank" onClick={onClick} />
        </div>
      );
    },
  },
];

const getPageParams = ({ pageIndex, pageSize }): PageParams => ({
  pageNumber: pageIndex + 1,
  pageSize,
});

export const StrTable = ({
  isLoading = false,
  strs = [],
  totalCount,
  fetchStrs,
  configTimestamp,
  variantId,
  geneId,
  unsetSingleStr,
  fetchSingleStr,
  strRecord,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const [selectedVariant, selectVariant] = useState();

  const [isImageVisible, setImageVisible] = useState(false);
  const [imagePath, setImagePath] = useState();

  const showImage = useCallback(path => {
    setImagePath(path);
    setImageVisible(true);
  }, []);

  const closeImage = useCallback(() => {
    setImageVisible(false);
  }, []);

  const columns = useMemo(() => getColumns(showImage), [showImage]);

  // Reset table on configuration change
  useEffect(() => {
    setPageIndex(0);
    selectVariant(null);
  }, [configTimestamp]);

  useEffect(() => {
    const variant = variantId ? { variantId, geneId } : null;
    selectVariant(variant);
  }, [variantId, geneId]);

  const fetchTableData = useCallback(({ pageIndex, pageSize }) => {
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  }, []);

  useEffect(() => {
    const pageParams = getPageParams({ pageIndex, pageSize });
    fetchStrs(pageParams);
  }, [fetchStrs, pageIndex, pageSize, configTimestamp]);

  useEffect(() => {
    if (selectedVariant) {
      fetchSingleStr(selectedVariant);
    } else {
      unsetSingleStr();
    }
  }, [fetchSingleStr, unsetSingleStr, selectedVariant]);

  const onRowClick = useCallback(
    (
      _,
      {
        row: {
          original: {
            variantId,
            gene: { geneId },
          },
        },
      }
    ) => ({
      key: `${variantId}-${geneId}`,
      onClick: () => {
        selectVariant({ variantId, geneId });
      },
    }),
    [selectVariant]
  );

  const closeDetails = useCallback(() => {
    selectVariant(null);
  }, []);

  return (
    <>
      {!strRecord && (
        <Table
          className="str-table"
          columns={columns}
          data={strs}
          totalCount={totalCount}
          loading={isLoading}
          fetchData={fetchTableData}
          showPagination={totalCount > DEFAULT_PAGE_SIZE}
          manualPagination
          getRowProps={onRowClick}
          initialState={{ pageIndex }}
          disableSortBy
        />
      )}
      {strRecord && (
        <div className="str-details">
          <StrDetails
            closeDetails={closeDetails}
            columns={columns}
            strRecord={strRecord}
          />
        </div>
      )}
      <ImageModal
        show={isImageVisible}
        close={closeImage}
        imagePath={imagePath}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectors.getLoading,
  strs: selectors.getStrs,
  strRecord: selectors.getSingleStr,
  totalCount: selectors.getTotalCount,
  configTimestamp: selectors.getConfigTimestamp,
});

const mapDispatchToProps = {
  fetchStrs: actions.fetchStrs.start,
  fetchSingleStr: actions.fetchSingleStr.start,
  unsetSingleStr: actions.unsetSingleStr,
};

export default connect(mapStateToProps, mapDispatchToProps)(StrTable);
