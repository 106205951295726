import { createAction } from "modules/utils";

import type { VariantHistoryRecord } from "./flow-types";

export const actionType = Object.freeze({
  FETCH_VARIANT_HISTORY_START: "FETCH_VARIANT_HISTORY_START",
  FETCH_VARIANT_HISTORY_SUCCESS: "FETCH_VARIANT_HISTORY_SUCCESS",
  FETCH_VARIANT_HISTORY_FAILURE: "FETCH_VARIANT_HISTORY_FAILURE",
});

export const fetchVariantHistory: AsyncActionCreator = {
  start: (
    requestId: string,
    patientId: number,
    patientVariantId: number,
    variantId: number,
    variantType: string,
    geneName: string
  ): FSA<
    typeof actionType.FETCH_VARIANT_HISTORY_START,
    {
      requestId: string,
      patientId: number,
      patientVariantId: number,
      variantId: number,
      variantType: string,
      geneName: string,
    }
  > =>
    createAction(actionType.FETCH_VARIANT_HISTORY_START, {
      requestId,
      patientId,
      patientVariantId,
      variantId,
      variantType,
      geneName,
    }),
  success: (
    requestId: string,
    historyRecords: Array<VariantHistoryRecord>
  ): FSA<
    typeof actionType.FETCH_VARIANT_HISTORY_SUCCESS,
    {
      requestId: string,
      historyRecords: Array<VariantHistoryRecord>,
    }
  > =>
    createAction(actionType.FETCH_VARIANT_HISTORY_SUCCESS, {
      requestId,
      historyRecords,
    }),
  failure: (): FSA<typeof actionType.FETCH_VARIANT_HISTORY_FAILURE> =>
    createAction(actionType.FETCH_VARIANT_HISTORY_FAILURE),
};

export type Action =
  | fetchVariantHistory.start
  | fetchVariantHistory.success
  | fetchVariantHistory.failure;
