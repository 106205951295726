import { getUserRoles } from "./utils/users";

import baseApi from "./index";

const legacyApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProjectVersions: builder.query<any, ProjectDependant>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/versions`,
      }),
      extraOptions: {
        camelize: true,
        legacy: true,
      },
    }),
    getExistingUsers: builder.query<User[], ProjectDependant>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/admin/user/add/existing`,
      }),
      extraOptions: {
        legacy: true,
      },
      transformResponse: (response: Record<string, unknown>[]): User[] =>
        response.map(
          ({ email, fullname, user_id }: LegacyUser): User => ({
            email,
            full_name: fullname,
            id: user_id,
          })
        ),
    }),
    getExistingUserDetails: builder.query<
      ProjectUser,
      ProjectDependant & { userId: number }
    >({
      query: ({ projectId, userId }) => ({
        url: `/project/${projectId}/admin/user/${userId}/add_permissions`,
      }),
      transformResponse: ({
        project_user: {
          visibility_level_id,
          fullname,
          user_id,
          access_level,
          authentication_provider_id,
          email,
        },
        project: { code },
        access_levels = [],
      }): ProjectUser => ({
        id: user_id,
        full_name: fullname,
        visibility_level_id: visibility_level_id
          ? Number(visibility_level_id)
          : visibility_level_id,
        project_code: code,
        roles: getUserRoles(access_levels),
        access_level,
        authentication_provider_id,
        email,
      }),
      extraOptions: {
        legacy: true,
      },
    }),
    addProjectUser: builder.mutation<
      void,
      ProjectUserRequest & Required<ProjectDependant>
    >({
      query({ projectId, ...body }) {
        return {
          url: `/project/${projectId}/admin/user/add`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      extraOptions: {
        legacy: true,
      },
    }),
    editProjectUser: builder.mutation<
      void,
      ProjectUserRequest & Required<ProjectDependant> & { userId: number }
    >({
      query({ projectId, userId, ...body }) {
        return {
          url: `/project/${projectId}/admin/user/${userId}/edit`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      extraOptions: {
        legacy: true,
      },
    }),
    addPermissions: builder.mutation<
      void,
      ProjectUserPermissionsRequest & ProjectDependant & { userId: number }
    >({
      query({ projectId, userId, ...body }) {
        return {
          url: `/project/${projectId}/admin/user/${userId}/add_permissions`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      extraOptions: {
        legacy: true,
      },
    }),
    removeProjectUser: builder.mutation<
      any,
      ProjectDependant & { userId: number }
    >({
      query({ projectId, userId }) {
        return {
          url: `/project/${projectId}/admin/user/${userId}/delete`,
          method: "POST",
        };
      },
    }),
    getFastQC: builder.query<any, PatientDependant>({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/qc/fastqc/json`,
      }),
    }),
    getVcfQC: builder.query<any, PatientDependant>({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/qc/vcf/json`,
      }),
    }),

    getExistingGenePanelsForProject: builder.query<
      { genePanels: CopyProjectGenePanel[]; writePermissions: boolean },
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/gene-panel/add/copy`,
      }),
      transformResponse: (resp: any) => ({
        ...resp,
        writePermissions: Boolean(resp.writePermissions),
      }),
      extraOptions: {
        camelize: true,
        legacy: true,
      },
    }),

    addExistingGenePanelToProject: builder.mutation<
      any,
      ProjectDependant & { genePanelId: number }
    >({
      query: ({ genePanelId, projectId }) => ({
        url: `/project/${projectId}/gene-panel/add/${genePanelId}`,
        method: "POST",
        // Text handler is needed, as the legacy endpoint returns HTML when posted to,
        // regardless of the legacy Accept header being passed
        responseHandler: "text",
      }),
    }),

    getProjectGenePanels: builder.query<
      {
        genePanels: ProjectGenePanel[];
        inheritedGenePanels: ProjectGenePanel[];
      },
      ProjectDependant & { archived?: boolean }
    >({
      query: ({ projectId, archived }) => ({
        url: `/project/${projectId}/gene-panels`,
        params: {
          show_archived: archived ? "1" : "0",
        },
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
    }),
  }),
});

export default legacyApi;
