import AboutModal from "modules/about/AboutModal";
import AuthTokenModal from "modules/auth/components/ApiTokenModal";
import { FeedbackModal } from "modules/feedback";
import ArchiveGenePanelModal from "modules/projectGenePanels/components/ArchiveGenePanelModal";
import DeleteGenePanelModal from "modules/projectGenePanels/components/DeleteGenePanelModal";
import ExportGenePanelModal from "modules/projectGenePanels/components/ExportGenePanelModal";
import GeneInfoModal from "modules/projectGenePanels/components/GeneInfoModal";
import AddGenePanelModal from "modules/projectGenePanels/components/add/AddGenePanelModal";
import EditTempGeneModal from "modules/projectGenePanels/components/add/tabs/upload/EditTempGeneModal";
import EditGenePanelModal from "modules/projectGenePanels/components/edit/EditGenePanelModal";
import EditGeneModal from "modules/projectGenePanels/components/edit/tabs/gene-tabs/EditGeneModal";
import RemoveGeneFromGenePanelModal from "modules/projectGenePanels/components/edit/tabs/gene-tabs/RemoveGeneFromGenePanelModal";

import { modalTypes } from "./constants";

export const registry = Object.freeze({
  [modalTypes.EDIT_GENE_PANEL_MODAL]: EditGenePanelModal,
  [modalTypes.DELETE_GENE_FROM_GENE_PANEL_MODAL]: RemoveGeneFromGenePanelModal,
  [modalTypes.ADD_GENE_PANEL_MODAL]: AddGenePanelModal,
  [modalTypes.GENE_INFO_MODAL]: GeneInfoModal,
  [modalTypes.EXPORT_GENE_PANEL_MODAL]: ExportGenePanelModal,
  [modalTypes.DELETE_GENE_PANEL_MODAL]: DeleteGenePanelModal,
  [modalTypes.ARCHIVE_GENE_PANEL_MODAL]: ArchiveGenePanelModal,
  [modalTypes.EDIT_TEMPORARY_GENE_MODAL]: EditTempGeneModal,
  [modalTypes.EDIT_GENE_MODAL]: EditGeneModal,
  [modalTypes.API_TOKEN_MODAL]: AuthTokenModal,
  [modalTypes.FEEDBACK_MODAL]: FeedbackModal,
  [modalTypes.ABOUT_MODAL]: AboutModal,
});
