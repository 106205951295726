import { react as autoBind } from "auto-bind";
import { identity, memoizeWith } from "ramda";
import React, { PureComponent } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { Tabs } from "pattern-library";

import {
  NotFoundMessage,
  RouteTabContent,
  RouteTabHeader,
} from "../../../layout/partials";
import LegacySapientia from "../../legacy/LegacySapientiaTemplate";

export class PatientMetadataTabs extends PureComponent {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { match } = this.props;
    const { projectId } = match.params;
    const projectUrl = `/project/${projectId}`;
    const tabs = memoizeWith(identity, () => [
      {
        name: "Fields",
        url: `/fields`,
        countUrl: `${projectUrl}/admin/settings/metadata/count`,
        component: LegacySapientia,
        componentProps: {
          templateUrl: `${projectUrl}/admin/settings/metadata/list`,
        },
      },
      {
        name: "Categories",
        url: `/categories`,
        countUrl: `${projectUrl}/admin/settings/metadata/category/count`,
        component: LegacySapientia,
        componentProps: {
          templateUrl: `${projectUrl}/admin/settings/metadata/category/list`,
        },
      },
    ])(projectUrl);

    const url = `/projects/${projectId}/settings/patient-metadata`;

    return (
      <div>
        <Switch>
          <Tabs
            tabs={tabs}
            routeUrl={url}
            baseUrl={url}
            routePath="/projects/:projectId/settings/patient-metadata"
            tabComponent={RouteTabHeader}
            contentComponent={RouteTabContent}
          />
          <Route component={NotFoundMessage} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PatientMetadataTabs);
