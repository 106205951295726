import React, { useState } from "react";

export const Layout = ({
  leftSideComponent: LeftSideComponent,
  rightSideComponent: RightSideComponent,
  ...props
}) => {
  const [leftBarCollapsed, setLeftBarCollapsed] = useState(false);
  const expandCollapseLeftBar = () => {
    setLeftBarCollapsed(!leftBarCollapsed);
  };

  return (
    <div className="container">
      <div className="row variant-table-container">
        <div className={`col-md-2 ${leftBarCollapsed ? "hide" : ""}`}>
          <LeftSideComponent
            {...props}
            expandCollapse={expandCollapseLeftBar}
          />
        </div>
        <div className={leftBarCollapsed ? "col-md-12" : "col-md-10"}>
          <RightSideComponent {...props} />
        </div>
        <span
          onClick={expandCollapseLeftBar}
          className={`glyphicon variant-table-expand glyphicon-chevron-right ${
            leftBarCollapsed ? "" : "hide"
          }`}
        />
      </div>
    </div>
  );
};

export default Layout;
