import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { LabelField } from "pattern-library";

import { getValue } from "../utils";

const MoiData = ({ data = {}, className }) => (
  <div className={classNames(className, "exomiser-pane-data")}>
    <LabelField
      label="Combined Score"
      value={getValue(data, "combinedScore")}
    />
    <LabelField
      label="Phenotype Score"
      value={getValue(data, "phenotypeScore")}
    />
    <LabelField
      label="Mean Variant Score"
      value={getValue(data, "geneVariantScore")}
    />
  </div>
);

MoiData.propTypes = {
  /**
   * Additional CSS class for rendering the component
   */
  className: PropTypes.string,
  /**
   * Data for a given MOI
   */
  data: PropTypes.shape({
    combinedScore: PropTypes.number,
    phenotypeScore: PropTypes.number,
    geneVariantScore: PropTypes.number,
    contributingVariant: PropTypes.bool,
    isHighestMoi: PropTypes.bool,
  }),
};

export default MoiData;
