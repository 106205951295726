// @flow
import { createAction } from "../utils";

import * as constants from "./constants";
import type { ProjectData, Reports } from "./reducer";

type InitialiseAction = FSA<typeof constants.INIT, number>;

export const initialise = (projectId: number): InitialiseAction =>
  createAction(constants.INIT, projectId);

type ResetPatientStatusAction = FSA<
  typeof constants.INIT,
  { projectId: number, patientId: number }
>;

export const resetPatientStatus = (info: {
  projectId: number,
  patientId: number,
}): ResetPatientStatusAction =>
  createAction(constants.RESET_PATIENT_STATUS, info);

type SetProjectDataAction = FSA<typeof constants.SET_PROJECT_DATA, ProjectData>;
export const setProjectData = (data: ProjectData): InitialiseAction =>
  createAction(constants.SET_PROJECT_DATA, data);

type InitialisedAction = FSA<typeof constants.INIT_COMPLETE>;

export const initialised = (): InitialisedAction =>
  createAction(constants.INIT_COMPLETE);

type SetPatientsFilterAction = FSA<typeof constants.SET_PATIENT_FILTER, string>;

export const setPatientsFilter = (
  filterValue: string
): SetPatientsFilterAction =>
  createAction(constants.SET_PATIENT_FILTER, filterValue);

type SetReportsModalVisibleAction = FSA<
  typeof constants.SET_REPORTS_MODAL_VISIBLE,
  boolean
>;

export const setReportsModalVisible = (
  isVisible: boolean
): SetReportsModalVisibleAction =>
  createAction(constants.SET_REPORTS_MODAL_VISIBLE, isVisible);

type LoadReportsAction = FSA<typeof constants.LOAD_REPORTS, number>;

export const loadReports = (patientId: number): LoadReportsAction =>
  createAction(constants.LOAD_REPORTS, patientId);

type SetReportsAction = FSA<typeof constants.SET_REPORTS, Reports>;

export const setReports = (reports: Reports): SetReportsAction =>
  createAction(constants.SET_REPORTS, reports);

type SetLoadingAction = FSA<typeof constants.SET_LOADING, boolean>;

export const setLoading = (loading: boolean): SetLoadingAction =>
  createAction(constants.SET_LOADING, loading);

export type Action =
  | InitialiseAction
  | SetProjectDataAction
  | ResetPatientStatusAction
  | SetPatientsFilterAction
  | SetReportsModalVisibleAction
  | LoadReportsAction
  | SetReportsAction
  | SetLoadingAction
  | InitialisedAction;
