export function commaFormatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const thresh = 1000;
const units = ["kbp", "Mbp", "Gbp"];

export function formatLength(length) {
  if (Math.abs(length) < thresh) {
    return length + "bp";
  }
  let u = -1;
  do {
    length /= thresh;
    ++u;
  } while (Math.abs(length) >= thresh && u < units.length - 1);
  return length.toFixed(1) + "" + units[u];
}

export const toDecimalPlaces = (
  item: string | number,
  decimalPlaces: number = 2
) => {
  if (isNaN(Number.parseFloat(item))) {
    return item;
  }
  return Number.parseFloat(item).toFixed(decimalPlaces);
};

export const toPercentage = (value, precision = 2) =>
  (parseFloat(value) * 100).toFixed(precision);
