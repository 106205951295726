// @flow
import { createAction } from "modules/utils";

import { actionType } from "./constants";
import type {
  StrsPage,
  PageParams,
  ConfigValuesTypes,
  Str,
  Variant,
  GeneStrCounts,
} from "./flow-types";

export const fetchStrs: AsyncActionCreator = {
  start: (
    payload: PageParams
  ): FSA<typeof actionType.FETCH_STRS_START, PageParams> =>
    createAction(actionType.FETCH_STRS_START, payload),

  success: (
    payload: StrsPage
  ): FSA<typeof actionType.FETCH_STRS_SUCCESS, StrsPage> =>
    createAction(actionType.FETCH_STRS_SUCCESS, payload),

  failure: (): FSA<typeof actionType.FETCH_STRS_FAILURE> =>
    createAction(actionType.FETCH_STRS_FAILURE),
};

export const fetchSingleStr: AsyncActionCreator = {
  start: (
    payload: Variant
  ): FSA<typeof actionType.FETCH_SINGLE_STR_START, number> =>
    createAction(actionType.FETCH_SINGLE_STR_START, payload),

  success: (
    payload: Str
  ): FSA<typeof actionType.FETCH_SINGLE_STR_SUCCESS, Str> =>
    createAction(actionType.FETCH_SINGLE_STR_SUCCESS, payload),

  failure: (): FSA<typeof actionType.FETCH_SINGLE_STR_FAILURE> =>
    createAction(actionType.FETCH_SINGLE_STR_FAILURE),
};

export type ConfigurationActionCreator = {
  init: ActionCreator,
  ready: ActionCreator,
  setDefaultValues: ActionCreator,
  setValues: ActionCreator,
  reset: ActionCreator,
  save: ActionCreator,
};

export const configuration: ConfigurationActionCreator = {
  init: (payload: {
    patientId: number,
    variantId: number,
  }): FSA<
    typeof actionType.CONFIG_INIT,
    { patientId: number, variantId: number }
  > => createAction(actionType.CONFIG_INIT, payload),

  ready: (): FSA<typeof actionType.CONFIG_READY> =>
    createAction(actionType.CONFIG_READY),

  setDefaultValues: (
    values: ConfigValuesTypes
  ): FSA<typeof actionType.CONFIG_SET_DEFAULT_VALUES, ConfigValuesTypes> =>
    createAction(actionType.CONFIG_SET_DEFAULT_VALUES, values),

  setValues: (
    values: ConfigValuesTypes
  ): FSA<typeof actionType.CONFIG_SET_VALUES, ConfigValuesTypes> =>
    createAction(actionType.CONFIG_SET_VALUES, values),

  reset: (): FSA<typeof actionType.CONFIG_RESET> =>
    createAction(actionType.CONFIG_RESET),

  save: (): FSA<typeof actionType.CONFIG_SAVE> =>
    createAction(actionType.CONFIG_SAVE),
};

export type SetPatientId = FSA<typeof actionType.SET_PATIENT_ID, number>;
export const setPatientId = (patientId: number): SetPatientId =>
  createAction(actionType.SET_PATIENT_ID, patientId);

export type UnsetSingleStr = FSA<typeof actionType.UNSET_SINGLE_STR>;
export const unsetSingleStr = (): UnsetSingleStr =>
  createAction(actionType.UNSET_SINGLE_STR);

export type SetGeneStrCounts = FSA<
  typeof actionType.SET_GENE_STR_COUNTS,
  GeneStrCounts
>;
export const setGeneStrCounts = (
  geneStrCounts: GeneStrCounts
): SetGeneStrCounts =>
  createAction(actionType.SET_GENE_STR_COUNTS, geneStrCounts);

export type SetGenePanels = FSA<
  typeof actionType.SET_GENE_PANELS,
  Array<GenePanel>
>;
export const setGenePanels = (genePanels: Array<GenePanel>): SetGenePanels =>
  createAction(actionType.SET_GENE_PANELS, genePanels);

export type FilterByGene = FSA<typeof actionType.FILTER_BY_GENE, number>;
export const filterByGene = (geneId: number): FilterByGene =>
  createAction(actionType.FILTER_BY_GENE, geneId);

export type SetGeneWrappersByGene = FSA<
  typeof actionType.SET_GENE_WRAPPERS_BY_GENE,
  Array<GenePanel>
>;
export const setGeneWrappersByGene = (
  genePanels: Array<GenePanel>
): SetGeneWrappersByGene =>
  createAction(actionType.SET_GENE_WRAPPERS_BY_GENE, genePanels);

export type Action =
  | fetchStrs.start
  | fetchStrs.success
  | fetchStrs.failure
  | fetchSingleStr.start
  | fetchSingleStr.success
  | fetchSingleStr.failure
  | configuration.init
  | configuration.ready
  | configuration.setDefaultValues
  | configuration.setValues
  | configuration.save
  | configuration.reset
  | SetPatientId
  | UnsetSingleStr
  | SetGeneStrCounts
  | SetGenePanels
  | SetGeneWrappersByGene
  | FilterByGene;
