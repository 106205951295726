import { fetchCamelizeData } from "../utils";
import { API_ENTITIES_BASE_URL } from "../utils/baseUrls";

export const getAuthProviders = async () => {
  const { payload } = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/authentication_providers`
  );

  return payload.data;
};

export const getAuthProviderByUser = async userId => {
  const response = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/authentication_providers/users/${userId}`
  );
  const { data } = response.payload || { data: [] };
  response.payload = data;
  return response;
};
