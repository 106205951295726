import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { cvlSelector } from "modules/audit";
import { selectTrackConfig } from "modules/genoverse";
import { extendTrack } from "modules/utils/genoverse";

export class Browser extends PureComponent {
  static displayName = "Browser";

  static propTypes = {
    patientId: PropTypes.number.isRequired,
    chrom: PropTypes.string,
    pos: PropTypes.number,
    cvl: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.innerRef = React.createRef();
  }

  componentDidMount() {
    this.prepareBrowser();
  }

  prepareBrowser = () => {
    const {
      patientId,
      chrom,
      pos,
      cvl: {
        id: cvlTrackId,
        name: cvlTrackName,
        description: cvlTrackDescription,
      } = {},
      trackConfig = [],
    } = this.props;
    if (!chrom || !pos) {
      return;
    }

    const tracks = [
      extendTrack(
        window.Genoverse.Track.SNV.Patient,
        {
          url: window.Sapientia.withPrefix(
            `/patient/${patientId}/variants.json?chr=__CHR__&start=__START__&end=__END__`
          ),
          height: 90,
          info: "The current patient SNV aligned to the reference sequence. The spots underneath the variant indicate zygosity: one spot is displayed is the patient is heterozygous for this variant; two spots indicate homozygosity or hemizygosity.",
        },
        "patientSNV"
      ),
      extendTrack(
        window.Genoverse.Track.Coverage,
        {
          url: `/catalyst/api/patient/${patientId}/files/bigwig_coverage?chr=__CHR__&start=__START__&end=__END__`,
          info: "An inverted histogram showing the read depth at each base. The red line shows the customisable minimum coverage threshold. Generated from BigWig file",
          name: "Coverage",
          id: "coverage",
        },
        "Coverage"
      ),
      extendTrack(
        window.Genoverse.Track.PairedReads,
        {
          id: "relativeBAM",
          name: "Sequence alignment",
          url: `/catalyst/api/patient/${patientId}/files/bam_reads?chr=__CHR__&start=__START__&end=__END__`,
          info: "The pile up of individual sequence reads in patient relative.",
        },
        "Sequence alignment"
      ),
    ];
    if (cvlTrackId) {
      const trackParams = {
        info:
          cvlTrackDescription || `Curated variants from list: ${cvlTrackName}`,
        id: `CuratedList${cvlTrackId}`,
        name: cvlTrackName,
        urlParams: {
          curated_variant_list_id: cvlTrackId,
          patient_id: patientId,
        },
      };
      let trackType = window.Genoverse.Track.SNV.CuratedList;
      const config = trackConfig.find(
        ({ curatedVariantListId }) => cvlTrackId === "" + curatedVariantListId
      );
      if (config) {
        if (config.genoverseTrackClass) {
          // This reducer gets the object from the namespace string
          // eg a lookup to get from "Genoverse.Track.SNV.CuratedList.Blah" to the real Genoverse.Track.SNV.CuratedList.Blah
          const foundTrack = config.genoverseTrackClass
            .split(".")
            .reduce((obj, i) => obj[i], window);
          if (foundTrack) {
            trackType = foundTrack;
          }
        } else if (config.isAutomatedAnnotationDecisions) {
          trackType = window.Genoverse.Track.SNV.CuratedList;
        }
      }
      tracks.push(trackType.extend(trackParams));
    }

    const centerPos = parseInt(pos, 10);
    window.genoverse = new window.Genoverse({
      container: this.innerRef.current,
      width: this.innerRef.current.clientWidth,
      genome: window.referenceGenome === "hg38" ? window.grch38 : window.grch37,
      useHash: false,
      urlParamTemplate: false,
      chr: chrom,
      start: centerPos - 32,
      end: centerPos + 32,
      patientId: parseInt(patientId, 10),
      tracksLibrary: tracks,
      tracks: [window.Genoverse.Track.Scalebar].concat(tracks),
      plugins: [
        "controlPanel",
        "karyotype",
        "trackControls",
        "resizer",
        "tooltips",
        "fileDrop",
        "focusRegion",
        "fullscreen",
      ],
      saveable: true,
      storageType: "localStorage",
    });
    window.genoverse.addHighlight({
      start: centerPos,
      end: centerPos,
      label: "NoCall position",
      removable: true,
    });
  };

  render() {
    const { chrom, pos } = this.props;
    return chrom && pos ? (
      <div ref={this.innerRef} className="genoverse" />
    ) : null;
  }
}

const mapStateToProps = createStructuredSelector({
  cvl: cvlSelector,
  trackConfig: selectTrackConfig,
});

export default connect(mapStateToProps, null)(Browser);
