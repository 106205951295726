import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class Label extends PureComponent {
  static displayName = "Label";

  static propTypes = {
    /**
     * Text or react element for the label
     */
    children: PropTypes.node.isRequired,
    /**
     * Is the field required
     */
    required: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
  };

  render() {
    const { children, required } = this.props;

    return (
      <label {...this.props}>
        {children} {required && "*"}
      </label>
    );
  }
}
