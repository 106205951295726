export const NAME = "auth";

export const USER_LOGGING_IN = `${NAME}/USER_LOGGING_IN`;

export const USER_LOGGED_IN = `${NAME}/USER_LOGGED_IN`;
export const USER_LOGIN_FAILED = `${NAME}/USER_LOGIN_FAILED`;

export const LOAD_INITIAL_USER_FAILED = `${NAME}/LOAD_INITIAL_USER_FAILED`;

export const IS_USER_LOGGED_IN = `${NAME}/IS_USER_LOGGED_IN`;

export const LOGIN_FORM = "LOGIN_FORM";

export const FETCH_PROFILE_API_TOKEN = "FETCH_PROFILE_API_TOKEN";
export const FETCH_PROFILE_API_TOKEN_SUCCESS =
  "FETCH_PROFILE_API_TOKEN_SUCCESS";
export const FETCH_PROFILE_API_TOKEN_FAILURE =
  "FETCH_PROFILE_API_TOKEN_FAILURE";
export const RESET_PROFILE_API_TOKEN = "RESET_PROFILE_API_TOKEN";
