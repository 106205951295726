import Genoverse from "genoverse";

import { isValidAllele, isFeatureExcluded } from "../../utils";
import {
  SequenceVariantsView,
  Track as SequenceVariants,
} from "../SequenceVariants";
import {
  SequenceVariationModel,
  SequenceVariationView,
} from "../SequenceVariation";

export function click(e) {
  const buffer = 3;
  const { pageX } = e;
  const x =
    pageX -
    this.container.parent().offset().left +
    this.browser.scaledStart -
    buffer;
  //Expand region from top of track to height of track - basically the entire vertical band
  const f = this[
    e.target.className === "labels" ? "labelPositions" : "featurePositions"
  ]
    .search({ x, y: 0, w: 2 * buffer, h: this.track.height })
    .sort((a, b) => {
      if (a.isCurrent && !b.isCurrent) {
        return -1;
      } else if (!a.isCurrent && b.isCurrent) {
        return 1;
      } else if (a.alt_allele && !b.alt_allele) {
        return -1;
      } else if (!a.alt_allele && b.alt_allele) {
        return 1;
      } else {
        return 0;
      }
    })[0];

  if (f) {
    if (isValidAllele(f)) {
      //Only display menu for snv
      this.browser.makeMenu(f, e, this.track);
    }
  }
}

export const insertFeature = (feature, snvId, colorMap) => {
  const { alt_allele, vep_category, patient_snv_id } = feature;
  const colorProp = isFeatureExcluded(feature)
    ? colorMap.Excluded.color
    : colorMap[vep_category].color;
  const params = {
    sequence: alt_allele,
    legend: isFeatureExcluded(feature)
      ? `${vep_category} (Excluded)`
      : vep_category,
    snvId,
    isCurrent: parseInt(patient_snv_id) === parseInt(snvId),
    outlineColor: colorProp,
    color: colorProp,
    genoTypeColor: colorProp,
    legendColor: colorProp,
  };

  const color = isFeatureExcluded(feature)
    ? colorMap.Excluded
    : colorMap[vep_category];
  return {
    ...feature,
    ...color,
    ...params,
  };
};

export const Controller = SequenceVariants.prototype.controller
  .extend()
  .implement(
    Genoverse.Track.Controller.Sequence.extend({
      populateMenu(feature) {
        return this.base(feature, isValidAllele(feature) ? {} : feature);
      },
    })
  );

export const Track = SequenceVariants.extend({
  controller: Controller,

  click,

  setMVC(...args) {
    this.base.apply(this, args);

    if (this.legendTrack) {
      this.legendTrack[this.view.showLegend ? "enable" : "disable"]();
    }
  },

  insertFeature(feature) {
    const { snvId } = this.track;
    this.base(insertFeature(feature, snvId, this.prop("colorMap")));
  },

  1: {
    featureHeight: 15,
    model: SequenceVariationModel.extend({}),
    view: SequenceVariationView.extend({ showLegend: true }),
  },

  1000: {
    model: Genoverse.Track.Model,
    view: SequenceVariantsView.extend({ showLegend: true }),
  },
});
