import React, { memo, useEffect, useState } from "react";

import { DatePicker } from "pattern-library";

interface DateRangeFilterProps {
  onDateRangeChange: (dateFrom, dateTo) => void;
}

const DateRangeFilter = memo(({ onDateRangeChange }: DateRangeFilterProps) => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  useEffect(() => {
    onDateRangeChange(dateFrom, dateTo);
  }, [dateFrom, dateTo, onDateRangeChange]);

  return (
    <div
      className="dataTables_filter custom-filter"
      data-testid="dateRangeFilter"
    >
      <label>Action date: </label>
      <DatePicker
        value={dateFrom}
        onChange={setDateFrom}
        maxDate={dateTo}
        isClearable
      />
      <label> - </label>
      <DatePicker
        value={dateTo}
        onChange={setDateTo}
        minDate={dateFrom}
        isClearable
      />
    </div>
  );
});

export default DateRangeFilter;
