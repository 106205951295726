import React from "react";

import { MODAL_SIZES } from "pattern-library";

import ModalLayout from "../ModalLayout";

import ConfigContainer from "./ConfigContainer";

export default ({ close, show }) => (
  <ModalLayout
    close={close}
    show={show}
    title="Configuration"
    size={MODAL_SIZES.L}
  >
    <ConfigContainer />
  </ModalLayout>
);
