import { react as autoBind } from "auto-bind";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { Tabs } from "pattern-library";

import * as patientSelectors from "modules/patient/selectors";

import { getVariantsTabset } from "./Tabset/VariantsTabset";
import { RouteTabContent, RouteTabHeader } from "./partials";

class VariantsLayout extends PureComponent {
  static displayName = "VariantsLayout";

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { patientId, projectId, tabs } = this.props;
    return (
      <Switch>
        <Route exact path={`/patient/${patientId}/variants`}>
          <Redirect to={`/patient/${patientId}/variants/sequence`} />
        </Route>
        <Tabs
          tabComponent={RouteTabHeader}
          contentComponent={RouteTabContent}
          tabs={getVariantsTabset(patientId, projectId, tabs)}
          baseUrl={`/patient/${patientId}/variants`}
          routePath="/patient/:patientId/variants"
        />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  tabs: patientSelectors.getTabs(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VariantsLayout);
