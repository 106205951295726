import { not, isEmpty } from "ramda";

/**
 * Immutable move item
 */
export const arrayMove = (arr, from, to) => {
  const clone = [...arr];
  Array.prototype.splice.call(
    clone,
    to,
    0,
    Array.prototype.splice.call(clone, from, 1)[0]
  );
  return clone;
};

export const notEmpty = (arr: Array<any>): boolean => not(isEmpty(arr));

export const findDuplicates = <T>(arr: Array<T> = []): Array<T> =>
  arr.filter((item, index) => arr.indexOf(item) !== index);
