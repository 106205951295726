//@flow
import { createAction } from "../utils";

import { type ModalPayload } from "./flow-types";

export const actionsTypes = Object.freeze({
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
});

export type ShowModalAction = FSA<typeof actionsTypes.SHOW_MODAL, ModalPayload>;
export const showModal = (type: string, props?: any): ShowModalAction =>
  createAction(actionsTypes.SHOW_MODAL, { type, props });

export type HideModalAction = FSA<
  typeof actionsTypes.HIDE_MODAL,
  {
    type: string,
  }
>;
export const hideModal = (type: string): HideModalAction =>
  createAction(actionsTypes.HIDE_MODAL, { type });

export type Action = ShowModalAction | HideModalAction;
