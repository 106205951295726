import { set } from "dot-prop-immutable";

import { modifyStateWith } from "modules/utils/reducers";

import { Action, actionType } from "./actions";
import { State, STATUS } from "./types";

export const initialState: State = {
  file: null,
  status: STATUS.EMPTY,
};

const setFile = value => state => set(state, "file", value);
const setStatus = value => state => set(state, "status", value);

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case actionType.SET_FILE: {
      if (action.payload) {
        return modifyStateWith(
          setFile(action.payload),
          setStatus(STATUS.SELECTED)
        )(state);
      }
      return modifyStateWith(
        setFile(action.payload),
        setStatus(STATUS.EMPTY)
      )(state);
    }
    case actionType.CHANGE_PROGRESS:
      return set(state, "progress", action.payload);
    case actionType.CHANGE_STATUS:
      return set(state, "status", action.payload);
    default:
      return state;
  }
}
