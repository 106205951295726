import PropTypes from "prop-types";
import React, { useState, memo } from "react";

import { Link, Tabs } from "pattern-library";

import CNVCallingForm from "./CNVCalling/CNVCallingForm";
import MetadataForm from "./MetadataForm";
import GenePanelsForm from "./genePanels/GenePanelsForm";
import HPOTermsForm from "./hpoTerms/HPOTermsForm";

const getTabs = isOnPrem =>
  [
    {
      name: "HPO Terms",
      content: HPOTermsForm,
    },
    {
      name: "Gene Panels",
      content: GenePanelsForm,
    },
    {
      name: "Metadata",
      content: MetadataForm,
    },
    isOnPrem
      ? null
      : {
          name: "CNV Calling",
          content: CNVCallingForm,
        },
  ].filter(Boolean);

const SampleDetails = memo(
  ({ selectedRowNumber, disabled = false, projectId, isOnPrem = false }) => {
    const [active, setActive] = useState(0);
    const tabs = getTabs(isOnPrem);

    const onTabClick = e => {
      setActive(tabs.findIndex(({ name }) => name === e.target.text));
    };

    const Tab = ({ tab }) => <Link onClick={onTabClick}>{tab.name}</Link>;
    const Content = ({ tab }) => (
      <tab.content
        selectedRowNumber={selectedRowNumber}
        disabled={disabled}
        projectId={projectId}
      />
    );

    return (
      <Tabs
        tabs={tabs}
        active={active}
        tabComponent={Tab}
        contentComponent={Content}
      />
    );
  }
);

SampleDetails.propTypes = {
  /**
   * index of selected sample
   */
  selectedRowNumber: PropTypes.number,
  /**
   * true if editing IR fields is disabled
   */
  disabled: PropTypes.bool,

  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default SampleDetails;
