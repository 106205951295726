import { createAction } from "modules/utils";

export const actionType = Object.freeze({
  FETCH_ARIADNE_BREAKDOWN_DATA_START: "FETCH_ARIADNE_BREAKDOWN_DATA_START",
  FETCH_ARIADNE_BREAKDOWN_DATA_SUCCESS: "FETCH_ARIADNE_BREAKDOWN_DATA_SUCCESS",
  FETCH_ARIADNE_BREAKDOWN_DATA_FAILURE: "FETCH_ARIADNE_BREAKDOWN_DATA_FAILURE",
});

export const fetchAriadneBreakdownData: AsyncActionCreator = {
  start: (
    requestId: string,
    patientId: number,
    patientSNVId: number,
    transcriptId: string
  ): FSA<
    typeof actionType.FETCH_ARIADNE_BREAKDOWN_DATA_START,
    {
      requestId: string,
      patientId: number,
      patientSNVId: number,
      transcriptId: string,
    }
  > =>
    createAction(actionType.FETCH_ARIADNE_BREAKDOWN_DATA_START, {
      requestId,
      patientId,
      patientSNVId,
      transcriptId,
    }),
  success: (
    requestId: string,
    data: any
  ): FSA<
    typeof actionType.FETCH_ARIADNE_BREAKDOWN_DATA_SUCCESS,
    { requestId: string, data: any }
  > =>
    createAction(actionType.FETCH_ARIADNE_BREAKDOWN_DATA_SUCCESS, {
      requestId,
      data,
    }),
  failure: (
    requestId: string
  ): FSA<typeof actionType.FETCH_ARIADNE_BREAKDOWN_DATA_FAILURE, string> =>
    createAction(actionType.FETCH_ARIADNE_BREAKDOWN_DATA_FAILURE, requestId),
};

export type Action = Return<
  | fetchAriadneBreakdownData.start
  | fetchAriadneBreakdownData.success
  | fetchAriadneBreakdownData.failure
>;
