import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { isValid, touch } from "redux-form";

import { Loading } from "pattern-library";

import { readPatient } from "modules/patient/actions";
import * as patientSelectors from "modules/patient/selectors";

import ConfigModal from "../config/Container";
import { updateConfig } from "../config/actions";
import * as constants from "../config/constants";
import { getReduxFormErrorSelector } from "../forms/form-specific-selectors";

import * as actions from "./actions";
import { default as FilterSidebar } from "./components/FilterSidebar";
import VariantsTable from "./components/VariantsTable";
import * as selectors from "./selectors";

export class VariantsContainer extends PureComponent {
  static propTypes = {
    initialise: PropTypes.func.isRequired,
    ready: PropTypes.bool,
    init: PropTypes.bool,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    isConfigValid: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    ready: false,
    init: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      collapsed: false,
      isModalShowing: false,
    };
  }

  componentDidMount() {
    const { init, initialise, readPatient, patientId } = this.props;
    readPatient(patientId);

    if (init === false) {
      initialise(patientId);
    }
  }

  componentDidUpdate(prevProps) {
    const { initialise, readPatient, patientId } = this.props;
    if (prevProps.patientId !== patientId) {
      readPatient(patientId);
      initialise(patientId);
    }
  }

  closeModal() {
    const { patientId, updateConfig, isConfigValid, errorFields, touch } =
      this.props;
    if (isConfigValid) {
      updateConfig(patientId);
      this.setState({ isModalShowing: false });
    } else {
      touch(constants.SNV_TABLE_CONFIG_FORM, ...Object.keys(errorFields));
    }
  }

  toggleCollapsed() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  }

  showModal() {
    this.setState({ isModalShowing: true });
  }

  render() {
    const { patientId, ready } = this.props;
    const { collapsed, isModalShowing } = this.state;

    if (ready === true) {
      return (
        <div className="container">
          <div className="row variant-table-container">
            <div className={`col-md-2 sticky ${collapsed ? "hide" : ""}`}>
              <FilterSidebar
                patientId={patientId}
                collapseSidebar={this.toggleCollapsed}
                showModal={this.showModal}
              />
            </div>
            <div className={collapsed ? "col-md-12" : "col-md-10"}>
              <VariantsTable patientId={patientId} />
            </div>
            <span
              onClick={this.toggleCollapsed}
              className={`glyphicon variant-table-expand glyphicon-chevron-right ${
                collapsed ? "" : "hide"
              }`}
            />
          </div>
          <ConfigModal
            key="config-modal"
            close={this.closeModal}
            show={isModalShowing}
            patientId={patientId}
          />
        </div>
      );
    }
    return <Loading />;
  }
}

const mapStateToProps = (state, props) => {
  const patient = patientSelectors.getSinglePatient(state, props.patientId);
  return {
    init: selectors.isInitialised(state),
    ready: selectors.isLoaded(state),
    patient: patient || false,
    isConfigValid: isValid(constants.SNV_TABLE_CONFIG_FORM)(state),
    errorFields: getReduxFormErrorSelector(constants.SNV_TABLE_CONFIG_FORM)(
      state
    ),
  };
};

const mapDispatchToProps = {
  initialise: actions.initialise,
  readPatient,
  updateConfig,
  touch,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantsContainer);
