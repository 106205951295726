import { useMemo } from "react";

import catalystApi from "api/catalyst-api";
import congenicaApi from "api/congenica-api";

const {
  useGetPatientStatusQuery,
  useGetAvailableTemplatesForProjectQuery,
  useGetPatientAndTabsQuery,
  useGetSignificantStatusChangeQuery,
} = catalystApi;

const { useGetLatestReportProposalQuery, useGetPatientReportsQuery } =
  congenicaApi;

export const useOncologyReportData = (
  patientId: number,
  projectId: number
): [
  {
    reports: PatientReport[];
    templates: ReportTemplate[];
    patientStatus: PatientStatus;
    latestReportProposal: ReportProposal;
  },
  boolean
] => {
  const { data: reports = [], isLoading: isGetPatientReportsLoading } =
    useGetPatientReportsQuery({ patientId });

  const {
    data: templates = [],
    isLoading: isGetAvailableTemplatesForProjectLoading,
  } = useGetAvailableTemplatesForProjectQuery({ projectId });

  const { isLoading: isGetPatientLoading } = useGetPatientAndTabsQuery({
    patientId,
  });

  const { isLoading: isGetSignificantStatusLoading } =
    useGetSignificantStatusChangeQuery({ patientId });

  const {
    data: patientStatus = {} as PatientStatus,
    isLoading: isPatientStatusLoading,
  } = useGetPatientStatusQuery({ patientId });

  const {
    data: latestReportProposal = {} as ReportProposal,
    isLoading: isLatestReportProposalLoading,
  } = useGetLatestReportProposalQuery({ patientId });

  const isLoading = useMemo(
    () =>
      isPatientStatusLoading ||
      isLatestReportProposalLoading ||
      isGetAvailableTemplatesForProjectLoading ||
      isGetPatientLoading ||
      isGetSignificantStatusLoading ||
      isGetPatientReportsLoading,
    [
      isPatientStatusLoading,
      isLatestReportProposalLoading,
      isGetAvailableTemplatesForProjectLoading,
      isGetPatientLoading,
      isGetSignificantStatusLoading,
      isGetPatientReportsLoading,
    ]
  );

  return [
    { reports, templates, patientStatus, latestReportProposal },
    isLoading,
  ];
};
