// @flow
import { set } from "dot-prop-immutable";

import {
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";

import type { Action } from "./actions";
import * as constants from "./constants";

export type HPOTerm = {
  hpoTermId: string,
  termId: number,
  parentId: number,
  name: string,
  description?: string,
  expanded?: boolean,
};

export type SelectedHPOTerm = {
  code: string,
  phenotype: string,
  presence: string,
  del: string,
};

type State = {
  +ui: {
    +selectedHPOTerms: Array<SelectedHPOTerm>,
    +loaded: boolean,
    +expandedNodeIds: Array<string>,
    +visibleNodeIds: Array<string>,
    +query: string,
    +status: ?RequestSubmissionStatus,
  },
  +data: {
    terms: Array<HPOTerm>,
  },
};

export const initialState: State = {
  data: {
    terms: [],
  },
  ui: {
    selectedHPOTerms: [],
    loaded: false,
    expandedNodeIds: ["0"],
    visibleNodeIds: [],
    query: "",
    status: null,
  },
};

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case constants.TOGGLE_TERM_VISIBILITY:
      const id = action.payload;
      const ids = state.ui.expandedNodeIds;
      const updated = ids.includes(id)
        ? ids.filter(i => i !== id)
        : [...ids, id].sort();
      return set(state, "ui.expandedNodeIds", updated);

    case constants.REMOVE_HPO_TERM:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedHPOTerms: state.ui.selectedHPOTerms.filter(
            term => term.code !== action.payload
          ),
        },
      };

    case constants.ADD_HPO_TERM:
      const oldHPOList = state.ui.selectedHPOTerms;
      const newHPOTerm = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          selectedHPOTerms: oldHPOList.concat([newHPOTerm]),
        },
      };

    case constants.SET_SELECTED_HPO_TERMS:
      return set(
        state,
        "ui.selectedHPOTerms",
        action.payload.map(({ code, phenotype }) => ({
          code,
          phenotype,
          presence: "Present",
          del: "",
        }))
      );

    case constants.SET_ALL_HPO_TERMS:
      return set(state, "data.terms", action.payload);

    case constants.HAS_LOADED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          loaded: true,
          status: SUCCESS_STATUS,
        },
      };
    }

    case constants.SET_VISIBLE_NODE_IDS:
      return set(state, "ui.visibleNodeIds", action.payload);

    case constants.SET_EXPANDED_NODE_IDS:
      return set(state, "ui.expandedNodeIds", action.payload);

    case constants.SET_QUERY:
      return set(state, "ui.query", action.payload);

    case constants.RESET_HPO_TERMS_TREE:
      const { expandedNodeIds, visibleNodeIds, query } = initialState.ui;
      const updatedUi = {
        ...state.ui,
        expandedNodeIds,
        visibleNodeIds,
        query,
      };
      return set(state, "ui", updatedUi);

    case constants.START_INIT:
      return set(state, "ui.status", IN_PROGRESS_STATUS);

    case constants.HPO_TERMS_LOAD_FAILED:
      return set(state, "ui.status", FAILURE_STATUS);

    default:
      return state;
  }
}
