import React, { memo } from "react";

import { SpliceSitesProps } from "../types";

import { getSpliceData } from "./utils";

const SpliceSite = ({
  data = [],
  isSpliceAiFeatureEnabled,
  thirdpartySoftwareVersions,
}: SpliceSitesProps) => (
  <table
    className="table table-condensed table-hover"
    data-testid="spliceSiteTable"
  >
    <tbody>
      {getSpliceData(
        data,
        isSpliceAiFeatureEnabled,
        thirdpartySoftwareVersions
      ).map(({ label, value }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={index}>
          <td className="col-xs-6">
            <strong>{label}</strong>
          </td>
          <td>{value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default memo(SpliceSite);
