import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Pagination } from "..";

export const MAX_ROWS_COUNT = Number.MAX_SAFE_INTEGER;

// TODO: This component is very specific to react table
// we should look to abstract this out to be a bit more generic
export default class DataTableFooter extends PureComponent {
  static propTypes = {
    /**
     * The current page index
     */
    page: PropTypes.number,
    /**
     * The total number of pages
     */
    pages: PropTypes.number,
    /**
     * Function to handle the page number change
     */
    onPageChange: PropTypes.func.isRequired,
    /**
     * Data to display in the table
     */
    data: PropTypes.array,
    /**
     * The number of items per page
     */
    pageSize: PropTypes.number,
    /**
     * Function to handle the page size change
     */
    onPageSizeChange: PropTypes.func,
    /**
     * The available page sizes that you can change to
     */
    pageSizeOptions: PropTypes.array,
    /**
     * Show the dropdown to change the number of items per page
     */
    showPageSizeOptions: PropTypes.bool,
    /**
     * The text to display in the page size dropdown
     */
    rowsText: PropTypes.string,

    /**
     * total count of data
     */
    totalCount: PropTypes.number,
    /**
     * show total count of data
     */
    showTotalCount: PropTypes.bool,
  };

  static defaultProps = {
    page: 0,
    pages: 0,
    data: [],
    pageSize: 10,
    pageSizeOptions: ["5", "10"],
    showPageSizeOptions: false,
    rowsText: "rows",
    totalCount: -1,
    showTotalCount: true,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  changePageSize(e) {
    const { onPageSizeChange } = this.props;

    if (onPageSizeChange) {
      onPageSizeChange(Number(e.target.value));
    }
  }

  render() {
    const {
      page: pageIndex,
      pages: pageCount,
      onPageChange,
      data: { length: localCount },
      totalCount,
      pageSize,
      pageSizeOptions,
      showPageSizeOptions,
      rowsText,
      showTotalCount,
    } = this.props;

    const itemCount = totalCount === -1 ? localCount : totalCount;
    const fromNumber = itemCount ? pageIndex * pageSize + 1 : 0;

    const toNumber = Math.min((pageIndex + 1) * pageSize, itemCount);

    return (
      <nav className="table-footer row">
        <div className="col-xs-12 col-sm-3">
          {showTotalCount && (
            <p>
              Showing{" "}
              <span className="ws-nowrap">
                {`${fromNumber} to ${toNumber} of ${itemCount}`}
              </span>{" "}
              entries
            </p>
          )}
        </div>
        <div className="col-xs-12 col-sm-6">
          <Pagination {...{ pageIndex, pageCount, onPageChange }} />
        </div>
        <div className="col-xs-12 col-sm-3">
          {showPageSizeOptions && (
            <span className="select-wrap table-footer__page-size-options -pageSizeOptions">
              <select
                className="table-footer-select form-control input-sm"
                onChange={this.changePageSize}
                value={pageSize}
              >
                {pageSizeOptions.map(option => {
                  const optionText = option === MAX_ROWS_COUNT ? "All" : option;
                  return (
                    <option key={option} value={option}>
                      {`${optionText}${rowsText ? " " : ""}${rowsText}`}
                    </option>
                  );
                })}
              </select>
            </span>
          )}
        </div>
      </nav>
    );
  }
}
