import React, { PureComponent } from "react";

export default class FamilyComparisonsColumn extends PureComponent {
  render() {
    const { variant, header } = this.props;

    // TODO: refactor this mess, it was copied from the old code
    const familyComparisons = {
      POSSIBLE_CAUSAL: "Possibly causal",
      POSSIBLE_CAUSAL_DOMINANT: "Possible causal if dominant",
      POSSIBLE_CAUSAL_X_LINKED: "Possible causal if X-linked",
      POSSIBLE_CAUSAL_ARCH:
        "Possible causal if autosomal recessive compound het",
      UNLIKELY_CAUSAL: "Unlikely causal",
    };

    if (!variant.familyComparisons) {
      return (
        <div
          className={`variant-column ${header
            .toLowerCase()
            .replace(/ /g, "_")}`}
        />
      );
    }
    const priority = {
      POSSIBLE_CAUSAL: 0,
      POSSIBLE_CAUSAL_DOMINANT: 1,
      POSSIBLE_CAUSAL_X_LINKED: 2,
      POSSIBLE_CAUSAL_ARCH: 3,
      UNLIKELY_CAUSAL: 4,
    };
    const priorityInv = {
      0: "POSSIBLE_CAUSAL",
      1: "POSSIBLE_CAUSAL_DOMINANT",
      2: "POSSIBLE_CAUSAL_X_LINKED",
      3: "POSSIBLE_CAUSAL_ARCH",
      4: "UNLIKELY_CAUSAL",
    };
    const text =
      familyComparisons[
        priorityInv[
          Math.max(...variant.familyComparisons.map(name => priority[name]))
        ]
      ];
    return (
      <div
        className={`variant-column ${header.toLowerCase().replace(/ /g, "_")}`}
      >
        {text}
      </div>
    );
  }
}
