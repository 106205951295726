import { isNil } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { getEndOfTheDay, getStartOfTheDay } from "modules/utils";

import { getFetchRequestParams } from "../../../utils/table";
import { getCVLAudit } from "../../actions";
import { ID_FIELD, NAME_FIELD, ORIGIN_PROJECT_ID_FIELD } from "../../constants";
import { getSelectedCVLAudit, isCVLAuditLoading } from "../../selectors";
import {
  CVLAuditFormattedAction,
  Field,
  ProjectCuratedVariantList,
} from "../../types";

import DateRangeFilter from "./DateRangeFilter";
import { getColumns } from "./columns";
import { ACTION_DATE_FIELD } from "./constants";
import { formatCVLAuditList, getCVCuratedDataFields } from "./utils";

interface CVLAuditProps extends PropsFromRedux {
  cvl: ProjectCuratedVariantList;
}

export const CVLAudit = ({
  cvl,
  cvlAudit,
  isLoading = false,
  getCVLAudit,
}: CVLAuditProps) => {
  const {
    [NAME_FIELD]: curatedVariantListName,
    [ID_FIELD]: curatedVariantListId,
    [ORIGIN_PROJECT_ID_FIELD]: originProjectId,
  } = cvl;

  const totalCount = cvlAudit ? cvlAudit.totalCount : 0;

  const columns = useMemo(
    () => getColumns(curatedVariantListName),
    [curatedVariantListName]
  );
  const [requestParams, setRequestParams] = useState<any>({});
  const [formattedActions, setFormattedActions] = useState<
    Array<CVLAuditFormattedAction>
  >([]);
  const [curatedDataSchemaFields, setCuratedDataSchemaFields] = useState<
    Array<Field>
  >([]);

  useEffect(() => {
    const list = cvlAudit ? cvlAudit.list : [];
    setFormattedActions(formatCVLAuditList(list, curatedDataSchemaFields));
  }, [setFormattedActions, curatedDataSchemaFields, cvlAudit]);

  useEffect(() => {
    if (cvl.curatedDataSchema) {
      setCuratedDataSchemaFields(getCVCuratedDataFields(cvl.curatedDataSchema));
    }
  }, [setCuratedDataSchemaFields, cvl]);

  const onDateRangeChange = useCallback(
    (dateFrom, dateTo) => {
      setRequestParams(r => ({
        ...r,
        dateFrom: getStartOfTheDay(dateFrom),
        dateTo: getEndOfTheDay(dateTo),
      }));
    },
    [setRequestParams]
  );

  const fetchData = useCallback(
    args => {
      setRequestParams({
        ...requestParams,
        ...getFetchRequestParams(args),
      });
    },
    [setRequestParams, requestParams]
  );

  useEffect(() => {
    const { pageNumber, pageSize } = requestParams;
    if (!isNil(pageNumber) && !isNil(pageSize) && curatedVariantListId) {
      getCVLAudit(curatedVariantListId, originProjectId, requestParams);
    }
  }, [getCVLAudit, curatedVariantListId, originProjectId, requestParams]);

  return (
    <div data-testid="cvl-audit">
      <Table
        columns={columns}
        data={formattedActions}
        title={`${curatedVariantListName} actions`}
        showPagination={totalCount > DEFAULT_PAGE_SIZE}
        pageSizeOptions={[10, 25, 50, 100]}
        autoResetPage={false}
        loading={isLoading}
        fetchData={fetchData}
        manualPagination
        totalCount={totalCount}
        manualSortBy
        enableFilter
        CustomFilterComponent={
          <DateRangeFilter onDateRangeChange={onDateRangeChange} />
        }
        sortBy={[
          {
            id: ACTION_DATE_FIELD,
            desc: true,
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  cvlAudit: getSelectedCVLAudit(state),
  isLoading: isCVLAuditLoading(state),
});

const mapDispatchToProps = {
  getCVLAudit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLAudit);
