import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class TdComponent extends PureComponent {
  static displayName = "Td";
  static propTypes = {
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
    /**
     * Content to render inside the component
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children, ...rest } = this.props;

    return (
      <td className={className} role="gridcell" {...rest}>
        {children}
      </td>
    );
  }
}
