import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import { reloadPatientReports } from "./actions";
import * as constants from "./constants";

type State = {
  data: {
    patient?: Patient | null;
    customIframe?: any;
    reports: Array<any>;
  };
  ui: {
    tabs: any;
  };
};

export const initialState: State = {
  data: {
    patient: null,
    customIframe: {},
    reports: [],
  },
  ui: {
    tabs: {},
  },
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.READ_PATIENT_SUCCESS:
      return set(state, "data.patient", action.payload);

    case constants.SET_PATIENT_TABS:
      return set(state, "ui.tabs", action.payload);

    case constants.SET_CUSTOM_IFRAME_CONFIG:
      return set(state, "data.customIframe", action.payload);

    case reloadPatientReports.SUCCESS_ACTION:
      return set(state, "data.reports", action.payload);

    default:
      return state;
  }
}
