export const toBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;
      if (!result || result instanceof ArrayBuffer) {
        return result;
      }
      // Use a regex to remove data url part
      const base64String = result.replace("data:", "").replace(/^.+,/, "");
      resolve(base64String);
    };
    reader.onerror = error => reject(error);
  });
