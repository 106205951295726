// @flow
import { set, toggle, merge } from "dot-prop-immutable";

import type { ResetVariantPanelAction } from "../variants/actions";
import * as variantConstants from "../variants/constants";

import type { Action } from "./actions";
import * as constants from "./constants";
import type { AcmgSuggestion } from "./flow-types";

type State = {
  ui: {
    currentSelectedCriteria: false | number,
    overlayActive: boolean,
    locked: boolean,
    canLock: boolean,
    makingLockRequest: boolean,
    showConfirmationModal: boolean,
    decisionAlreadySetAgainstAnotherTranscript: boolean,
    requestingClassification: boolean,
    patientVariant: ?PatientVariant,
    suggestedAcmgCriteriaProcessing: boolean,
    suggestedAcmgCriteria: ?AcmgSuggestion,
  },
  data: {
    acmgClassifications: Array<AcmgClassification>,
    acmgCriteria: Array<AcmgCriteria>,
  },
};

export const initialState = {
  ui: {
    currentSelectedCriteria: false,
    overlayActive: false,
    locked: false,
    canLock: true,
    makingLockRequest: false,
    requestingClassification: false,
    showConfirmationModal: false,
    decisionAlreadySetAgainstAnotherTranscript: false,
    patientVariant: null,
    suggestedAcmgCriteriaProcessing: false,
    suggestedAcmgCriteria: null,
  },
  data: {
    acmgClassifications: [],
    acmgCriteria: [],
  },
};

const fixACMGResponseValues = payload => {
  const { acmgLocked, noEvidenceCategories = [] } = payload;
  return {
    ...payload,
    // fix acmg value if it's a number or a string from response
    acmgLocked: !!acmgLocked,
    noEvidenceCategories: noEvidenceCategories.map(cat => {
      // a quick type fix for the object array response as the code expects an array of just ids
      if (cat.id) {
        return cat.id;
      }
      return cat;
    }),
  };
};

export default function reducer(
  state: State = initialState,
  action: Action | ResetVariantPanelAction
) {
  const { payload } = action;

  switch (action.type) {
    case constants.ACMG_MARK_AS_LOADED: {
      return merge(
        state,
        "ui.patientVariant",
        fixACMGResponseValues(action.payload)
      );
    }

    case constants.UPDATE_VARIANT_ACMG: {
      return merge(state, "ui.patientVariant", {
        ...state.ui.patientVariant,
        ...fixACMGResponseValues(action.payload),
      });
    }

    case variantConstants.RESET_VARIANT_PANEL:
      return set(state, "ui.patientVariant", null);

    case constants.CREATE_ACMGCLASSIFICATION_LIST:
      return set(state, "data.acmgClassifications", payload);

    case constants.CREATE_ACMGCRITERIA_LIST:
      return set(state, "data.acmgCriteria", payload);

    case constants.ACMG_LOADING_CLASSIFICATION:
      return set(state, "ui.requestingClassification", true);

    case constants.ACMG_LOADED_CLASSIFICATION:
      return set(state, "ui.requestingClassification", false);

    case constants.ACMG_PREPARE_TO_ADD_CRITERIA:
      return set(state, "ui.currentSelectedCriteria", payload);

    case constants.ACMG_RESET_CRITERIA_TO_ADD:
      return set(state, "ui.currentSelectedCriteria", false);

    case constants.ACMG_TOGGLE_OVERLAY:
      return toggle(state, "ui.overlayActive");

    case constants.ACMG_SHOW_WARNINGS_MODAL:
      return set(state, "ui.showConfirmationModal", true);

    case constants.ACMG_HIDE_WARNINGS_MODAL:
      return set(state, "ui.showConfirmationModal", false);

    case constants.ACMG_SET_CAN_LOCK:
      return set(state, "ui.canLock", payload);

    case constants.ACMG_UNLOCK:
    case constants.ACMG_LOCK:
      return set(state, "ui.makingLockRequest", true);

    case constants.ACMG_UNLOCK_SUCCESS:
      const stateNotMakingUnlockRequest = set(
        state,
        "ui.makingLockRequest",
        false
      );
      return set(stateNotMakingUnlockRequest, "ui.locked", false);

    case constants.ACMG_LOCK_SUCCESS:
      const stateNotMakingLockRequest = set(
        state,
        "ui.makingLockRequest",
        false
      );
      return set(stateNotMakingLockRequest, "ui.locked", true);

    case constants.ACMG_LOCK_OR_UNLOCK_FAILED:
      return set(state, "ui.makingLockRequest", false);

    case constants.ACMG_IS_DECISION_ALREADY_SET_AGAINST_ANOTHER_TRANSCRIPT:
      return set(
        state,
        "ui.decisionAlreadySetAgainstAnotherTranscript",
        payload
      );

    case constants.SUGGESTED_ACMG_CRITERIA_LOADED:
      return set(state, "ui.suggestedAcmgCriteria", payload);

    case constants.SUGGESTED_ACMG_CRITERIA_PROCESSING:
      return set(state, "ui.suggestedAcmgCriteriaProcessing", payload);

    default:
      return state;
  }
}
