import * as d3 from "d3";
import $, { default as jQuery } from "jquery";
import nv from "nvd3";

import { QC } from "./qc";

export const loadVcfQC = function (json: VCFResponse) {
  if (typeof json["SNV Count"] !== "undefined") {
    QC.populateDattributes($("div.vcf"), json);

    const snv_cutoff = 10;
    if (json["SNV Count"] < snv_cutoff) {
      $("#vcf-summary").after(
        "<p>VCF QC cannot be displayed because only " +
          json["SNV Count"] +
          " SNV(s) were found (" +
          snv_cutoff +
          " minimum required).</p>"
      );

      // Hide all the graphs
      $(".vcf .method").hide();

      // Hide the help button as it doesn't do anything when the graphs are hidden
      $("#qc .display-help").hide();
      return;
    }
  } else {
    // hide the table if the snv count isn't set
    $("#vcf-summary").hide();
  }

  const snp_alleles = json["SNP Alleles"];
  if (snp_alleles) {
    nv.addGraph(() => {
      const data = [
        {
          key: "SNP Alleles",
          values: Object.keys(snp_alleles)
            .map(i => ({ label: i, value: parseInt(snp_alleles[i]) }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        },
      ];

      const chart = QC.charts.barChart({
        horizontal: true,
        value_type: "int",
        xAxisLabel: "SNP",
        yAxisLabel: "Sequences",
      });

      d3.select("#snp-alleles svg").datum(data).call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const types = json.Types;
  if (types) {
    nv.addGraph(() => {
      const data = [
        {
          key: "SNP Alleles",
          values: [
            { label: "SNP", value: (types as Json).SNP || 0 },
            { label: "INS", value: (types as Json).INS || 0 },
            { label: "DEL", value: (types as Json).DEL || 0 },
            { label: "MIXED", value: (types as Json).MIXED || 0 },
            { label: "MNP", value: (types as Json).MNP || 0 },
          ],
        },
      ];
      const chart = QC.charts.barChart({
        horizontal: true,
      });

      chart.yAxis.tickFormat(d3.format("d"));

      d3.select("#types svg").datum(data).call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }

  const ranges = json.Ranges["1000000"];
  if (!jQuery.isEmptyObject(ranges)) {
    const data = {};
    Object.keys(ranges).forEach(i => {
      const parts = i.split("_");
      const chr = parts[0];
      const location = parseFloat(parts[1]) / 100000;
      const value = ranges[i];

      if (typeof data[chr] === "undefined") {
        data[chr] = {
          chr,
          things: [],
          min: 999999,
          max: -999999,
        };
      }

      data[chr].things.push({ pos: location, value });
      data[chr].min = Math.min(data[chr].min, location);
      data[chr].max = Math.max(data[chr].max, location);
    });
  }

  const indel_size = json["INDEL size"];
  if (!jQuery.isEmptyObject(indel_size)) {
    nv.addGraph(
      () => {
        const values = Object.keys(indel_size)
          .map(i => ({ x: parseInt(i), y: parseInt(indel_size[i]) }))
          .sort((a, b) => a.x - b.x);

        const data = [
          {
            key: "INDEL size",
            values,
          },
        ];

        const chart = QC.charts.lineChart({
          xAxisLabel: "INDEL Size",
          yAxisLabel: "Sequences",
        });

        const maxY = Math.max.apply(
          null,
          values.map(i => i.y)
        );

        chart.yDomain([0, Math.max(10, maxY)]);

        d3.select("#indel-size svg").datum(data).call(chart);

        return chart;
      },
      () => {
        d3.select("#indel-size svg")
          .selectAll(".tick")
          .filter(d => d === 0)
          .remove();
      }
    );
  }

  const known_variant = json["Known variant"];
  if (!jQuery.isEmptyObject(known_variant)) {
    nv.addGraph(() => {
      const data = Object.keys(known_variant).map(i => ({
        key: i,
        y: parseInt(known_variant[i]),
      }));

      const chart = QC.charts.pieChart();

      d3.select("#known-variants svg").datum(data).call(chart);

      return chart;
    });
  }

  const contigs = json.Contigs;
  if (!jQuery.isEmptyObject(contigs)) {
    nv.addGraph(() => {
      const order = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "X",
        "Y",
        "MT",
      ];
      const values = order.map(i => ({
        label: i,
        value: contigs[i] || contigs["chr" + i] || 0,
      }));

      const data = [
        {
          key: "Contigs",
          values,
          color: QC.defaults.colours.primary,
        },
      ];

      const chart = QC.charts.barChart({
        xAxisLabel: "Chromosome",
        yAxisLabel: "Number of Variants",
      });

      chart.yAxis.tickFormat(d3.format("d"));

      d3.select("#contigs svg").datum(data).call(chart);

      return chart;
    });
  }

  const allele_count = json["Allele Count"];
  if (!jQuery.isEmptyObject(allele_count)) {
    nv.addGraph(() => {
      const data = [
        {
          key: "Allele Count",
          values: Object.keys(allele_count)
            .sort()
            .map(i => ({
              label: i.toString(),
              value: parseInt(allele_count[i]),
            })),
        },
      ];

      const chart = QC.charts.barChart({
        horizontal: true,
      });

      chart.yAxis.tickFormat(d3.format("d"));

      d3.select("#allele-count svg").datum(data).call(chart);

      return chart;
    });
  }

  const ts_tv: TsTv = json["TS-TV"];
  if (!jQuery.isEmptyObject(ts_tv)) {
    const name_map = {
      TS: "Transition",
      TV: "Transversion",
    };
    nv.addGraph(() => {
      const data = Object.keys(ts_tv)
        .sort()
        .map(i => ({
          key: name_map[i],
          y: parseInt(ts_tv[i]),
        }));

      const chart = QC.charts.pieChart({ halfPie: true, externalLabels: true });

      chart.title("Ratio: " + (ts_tv.TS / ts_tv.TV).toFixed(2)).donut(true);

      d3.select("#tstv svg").datum(data).call(chart);

      return chart;
    });
  }

  const zygosity = json.Heterozygote;
  if (!jQuery.isEmptyObject(zygosity)) {
    nv.addGraph(() => {
      const data = Object.keys(zygosity)
        .sort()
        .map(i => ({
          key: i === "Het" ? "Heterozygous" : "Homozygous",
          y: parseInt(zygosity[i]),
        }));

      const chart = QC.charts.pieChart({
        halfPie: true,
        externalLabels: true,
      });

      d3.select("#zygosity svg").datum(data).call(chart);

      return chart;
    });
  }
};
