import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import ButtonLink from "./ButtonLink";

export default class Button extends Component {
  static displayName = "Button";

  static propTypes = {
    /**
     * The content of the button, whether it is text or a react element
     */
    children: PropTypes.node,
    /**
     * Classnames to apply to the button on top of the context classes
     */
    className: PropTypes.string,
    /**
     * The context class to apply to the button element
     */
    context: PropTypes.string,
    /**
     * This determines whether a button is a link or not
     */
    type: PropTypes.string,
    /**
     * This determines whether a ref to the element is created for interaction
     */
    createRef: PropTypes.bool,
    /**
     * This determines whether a button is disabled
     */
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    children: "",
    type: "",
    className: "",
    context: "default",
    createRef: false,
    disabled: false,
  };

  render() {
    const {
      createRef,
      context = "default",
      children,
      className,
      disabled,
      ...otherProps
    } = this.props;

    otherProps.className = classNames("btn", `btn-${context}`, className);

    if (createRef === true) {
      otherProps.ref = input => {
        this.buttonRef = input;
      };
    }

    if (otherProps.type !== "link") {
      return (
        <button type="button" {...otherProps} disabled={disabled}>
          {children || "Submit"}
        </button>
      );
    }
    return <ButtonLink {...otherProps}>{children}</ButtonLink>;
  }
}
