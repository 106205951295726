import _ from "lodash";

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
  const changes = (object, base) =>
    _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  return changes(object, base);
};

/**
 * Takes the source object, and performs a deep filter to remove any props
 * not present in the comparitor object
 * @param {Object} source     Object to be sanitized
 * @param {Object} comparitor Object to be used for comparison
 * @returns {Object}          Return a sanitized object
 */
export const sanitize = (source, comparitor) => {
  const filteredObj = _.pick(source, _.keysIn(comparitor));

  return _.reduce(
    filteredObj,
    (result, value, key) => {
      const isObject = _.isObject(value);
      const nested = isObject ? sanitize(value, comparitor[key]) : value;
      if (isObject && _.isEmpty(nested)) return;

      return {
        ...result,
        [key]: nested,
      };
    },
    {}
  );
};
