import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

import { Tooltip } from "pattern-library";

import Checkboxes from "modules/forms/components/checkboxes/Checkboxes";

import {
  Checkbox,
  DatePicker,
  Dropdown,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Radio,
  RadioButtons,
  Select,
  Textarea,
  Toggle,
} from "../../elements";
import { TooltipProps } from "../Tooltip";
import CheckboxGroup from "../checkbox-group/CheckboxGroup";
import { DraggableCheckboxes } from "../index";

import { FormFieldDescription } from "./FormFieldDescription";
import { FormFieldPresets } from "./presets";

const additionalTypes = Object.keys(FormFieldPresets);

export const inputsColSizes = [10, 9, 8, 7, 6];
export const types = [
  "number",
  "text",
  "password",
  "email",
  "file",
  "checkbox",
  "radio",
  "radiobuttons",
  "checkboxes",
  "checkboxgroup",
  "draggablecheckboxes",
  "dropdown",
  "select",
  "datepicker",
  "date",
  "textarea",
  "toggle",
  ...additionalTypes,
];
export const typeToComponentMap = {
  dndcheckboxes: DraggableCheckboxes,
  checkboxes: Checkboxes,
  checkboxgroup: CheckboxGroup,
  draggablecheckboxes: DraggableCheckboxes,
  text: Input,
  password: Input,
  file: Input,
  number: Input,
  email: Input,
  select: Select,
  dropdown: Dropdown,
  datepicker: DatePicker,
  date: DatePicker,
  textarea: Textarea,
  checkbox: Checkbox,
  radio: Radio,
  radiobuttons: RadioButtons,
  toggle: Toggle,
};

const DESCRIPTION_POSITIONS = {
  UNDER_LABEL: "underLabel",
  UNDER_INPUT: "underInput",
};

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

export default class FormField extends PureComponent {
  static displayName = "FormField";

  static propTypes = {
    /**
     * Label element(or string, number, etc)
     */
    label: PropTypes.node,
    /**
     * The Text or react element that appears beside the checkbox (defaults to empty string).
     */
    boxLabel: PropTypes.node,
    /**
     * Description of the control
     */
    description: PropTypes.string,
    /**
     * Description position
     */
    descriptionPosition: PropTypes.oneOf(Object.values(DESCRIPTION_POSITIONS)),
    /**
     * Type of the control
     */
    type: PropTypes.oneOf(types),
    /**
     * Narrow input field.
     * false (default): `col-sm-10`
     * true: `col-sm-8`
     * integer: `col-sm-${narrow}`
     */
    narrow: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(inputsColSizes),
    ]),
    /**
     * Is it a required field
     */
    required: PropTypes.bool,
    /**
     * Was the field touched by the user
     */
    touched: PropTypes.bool,
    /**
     * Name of the field
     */
    name: PropTypes.string,
    /**
     * Value of the field
     */
    value: PropTypes.any,
    /**
     * Set the warning
     */
    warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * Set the error
     */
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * Show error when field is untouched
     */
    showErrorIfUntouched: PropTypes.bool,
    /**
     * Class to apply to the FormField container
     */
    className: PropTypes.string,
    /**
     * Field focus status
     */
    active: PropTypes.bool,
    /**
     * Asynchronous validation status
     */
    asyncValidating: PropTypes.bool,
    /**
     * Field autofill status
     */
    autofilled: PropTypes.bool,
    /**
     * Has field been clicked on
     */
    visited: PropTypes.bool,
    /**
     * Validation status true
     */
    valid: PropTypes.bool,
    /**
     * Auto submission status
     */
    submitFailed: PropTypes.bool,
    /**
     * Is field value being currently submitted
     */
    submitting: PropTypes.bool,
    /**
     * Field modification status
     */
    pristine: PropTypes.bool,
    /**
     * Validation status false
     */
    invalid: PropTypes.bool,
    /**
     * Has field being touched
     */
    dirty: PropTypes.bool,
    /**
     * Item list for a RadioButtons component
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.any.isRequired,
        disabled: PropTypes.bool,
      })
    ),
    /**
     * Should hide error message
     */
    hideErrorMessage: PropTypes.bool,

    /**
     * Tooltip properties
     */
    tooltip: PropTypes.shape(TooltipProps),
  };

  static defaultProps = {
    label: "",
    description: "",
    descriptionPosition: DESCRIPTION_POSITIONS.UNDER_INPUT,
    narrow: false,
    required: false,
    touched: false,
    type: "text",
    value: null,
    warning: "",
    error: "",
    showErrorIfUntouched: false,
    className: "",
    boxLabel: null,
    active: false,
    asyncValidating: false,
    autofilled: false,
    visited: false,
    valid: true,
    submitFailed: false,
    submitting: false,
    pristine: true,
    invalid: false,
    dirty: false,
    hideErrorMessage: false,
    tooltip: null,
  };

  withTooltip(FieldComponent, fieldProperties) {
    const { tooltip, ...restProps } = fieldProperties;
    return () => (
      <Tooltip {...tooltip}>
        <FieldComponent {...restProps} />
      </Tooltip>
    );
  }

  render() {
    const {
      narrow,
      label,
      description,
      descriptionPosition,
      required,
      type,
      touched,
      warning,
      error,
      className,
      boxLabel,
      active,
      asyncValidating,
      autofilled,
      visited,
      valid,
      submitFailed,
      submitting,
      pristine,
      invalid,
      dirty,
      dispatch,
      prefix,
      icon,
      minimal,
      showErrorIfUntouched,
      hideErrorMessage,
      addon,
      normalise,
      ...rest
    } = this.props;

    const inputColSize = typeof narrow === "number" ? narrow : narrow ? 8 : 10;

    const { UNDER_LABEL, UNDER_INPUT } = DESCRIPTION_POSITIONS;

    let Control = typeToComponentMap[type];

    const isCheckbox = type === "checkbox" || type === "radio";

    // Prefix is for text content, icon is for icon content
    if (Control === Input && (!isNil(prefix) || !isNil(icon))) {
      Control = InputGroup;
      rest.prefix = prefix;
      rest.icon = icon;
    }

    const controlProperties = {
      ...rest,
      type,
    };

    const presetProps = FormFieldPresets[type];

    if (presetProps) {
      const newProps = {
        ...this.props,
        ...presetProps,
      };
      return <FormField {...newProps} />;
    }

    if (type === "checkboxgroup") {
      if (controlProperties.value === "") {
        // fix redux-form value substitution
        controlProperties.value = [];
      }
    }

    // TODO: Tidy up how we construct the props for controlProperties
    if (normalise) {
      controlProperties.normalise = normalise;
    }

    if (isCheckbox && !!boxLabel) {
      controlProperties.label = boxLabel;
    }

    if (type === "toggle" || type === "checkbox") {
      // we need input[type=checkbox]
      controlProperties.type = "checkbox";
      controlProperties.initial = !isNil(controlProperties.initial)
        ? controlProperties.initial.toString()
        : controlProperties.initial;
    }

    if (type === "draggablecheckboxes") {
      controlProperties.pristine = pristine;
    }

    if (isNil(Control))
      throw new Error(
        `Control not found for (${isNil(type) ? undefined : type})`
      );

    if (controlProperties.tooltip) {
      Control = this.withTooltip(Control, controlProperties);
    }

    if (type === "file") {
      delete controlProperties.value;
      controlProperties.onChange = adaptFileEventToValue(
        controlProperties.onChange
      );
      controlProperties.onBlur = adaptFileEventToValue(
        controlProperties.onBlur
      );
      controlProperties.className = "form-control"; // has to be passed explicitly
    }

    if (isCheckbox && minimal) {
      return (
        <>
          <Control {...controlProperties} />
          {!!description && descriptionPosition === UNDER_INPUT ? (
            <FormFieldDescription description={description} />
          ) : null}
        </>
      );
    }

    return (
      <FormGroup
        className={classNames(className, {
          // TODO: Investigate how we can tidy up the rows/columns for these FormGroups
          row: !addon,
          "has-warning": (touched || showErrorIfUntouched) && warning,
          "has-error": (touched || showErrorIfUntouched) && error,
        })}
      >
        {!!label && (
          <Label
            className={classNames(
              "control-label",
              `col-sm-${12 - inputColSize}`
            )}
            required={required}
            htmlFor={rest.name}
          >
            {label}
            {!!description && descriptionPosition === UNDER_LABEL ? (
              <FormFieldDescription description={description} />
            ) : null}
          </Label>
        )}
        <div
          className={classNames({
            "col-sm-12": !label,
            [`col-sm-${inputColSize}`]: label,
            "input-group": addon,
          })}
        >
          <>
            {addon && <span className="input-group-addon">{addon}</span>}
            <Control {...controlProperties} />
            {!!description && descriptionPosition === UNDER_INPUT ? (
              <FormFieldDescription description={description} />
            ) : null}
          </>
        </div>
        {!hideErrorMessage &&
          (touched || showErrorIfUntouched) &&
          (error || warning) && (
            <div
              className={classNames({
                "col-sm-12": !label,
                [`col-sm-offset-${12 - inputColSize}`]: label,
                [`col-sm-${inputColSize}`]: label,
              })}
            >
              <span className="help-block">{error || warning}</span>
            </div>
          )}
      </FormGroup>
    );
  }
}
