import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";

import LoadingComponent from "./LoadingComponent";
import NoDataComponent from "./NoDataComponent";
import TableComponent from "./TableComponent";
import TbodyComponent from "./TbodyComponent";
import TdComponent from "./TdComponent";
import TfootComponent from "./TfootComponent";
import ThComponent from "./ThComponent";
import TheadComponent from "./TheadComponent";
import TrComponent from "./TrComponent";

/**
 * @deprecated
 * please use elements/table7/Table.js
 */
export default class Table extends PureComponent {
  static displayName = "Table";

  static propTypes = {
    TableComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    TheadComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    TbodyComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    TrGroupComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    TrComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    ThComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    TdComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    TfootComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    LoadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    NoDataComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    showPagination: PropTypes.bool,
  };

  static defaultProps = {
    TableComponent,
    TheadComponent,
    TbodyComponent,
    TrGroupComponent: ({ children }) => children,
    TrComponent,
    ThComponent,
    TdComponent,
    TfootComponent,
    LoadingComponent,
    NoDataComponent,
    showPagination: false,
  };

  render() {
    const {
      className = "",
      TableComponent,
      TheadComponent,
      TbodyComponent,
      TrGroupComponent,
      TrComponent,
      ThComponent,
      TdComponent,
      TfootComponent,
      LoadingComponent,
      NoDataComponent,
      showPagination,
      ...otherProps
    } = this.props;

    return (
      <ReactTable
        TableComponent={TableComponent}
        TheadComponent={TheadComponent}
        TbodyComponent={TbodyComponent}
        TrGroupComponent={TrGroupComponent}
        TrComponent={TrComponent}
        ThComponent={ThComponent}
        TdComponent={TdComponent}
        TfootComponent={TfootComponent}
        LoadingComponent={LoadingComponent}
        NoDataComponent={NoDataComponent}
        showPagination={showPagination}
        column={{ ...ReactTableDefaults.column, className: "" }}
        className={classNames("table-container", className)}
        {...otherProps}
      />
    );
  }
}
