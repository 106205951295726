import { identity, memoizeWith } from "ramda";
import { put, takeLatest } from "redux-saga/effects";

import { error } from "modules/messages/actions";

import { VariantService } from "./VariantService";
import { actionType, fetchVariants } from "./actions";

const getVariantService = memoizeWith(identity, async (patientId: number) => {
  const service = new VariantService();
  await service.initialize(patientId);
  return service;
});

export default function* init() {
  yield takeLatest(actionType.FETCH_VARIANTS_START, handleFetchVariants);
}

function* handleFetchVariants({ payload }) {
  try {
    const variantService = yield getVariantService(payload.patientId);
    const variants = yield variantService.getVariants(payload);
    const totalCount = variantService.getTotalCount();
    yield put(
      fetchVariants.success({
        variants,
        totalCount,
      })
    );
  } catch ({ message }) {
    yield put(fetchVariants.failure());
    yield put(error(message ? message : "Cannot load variants"));
  }
}
