import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class PrimaryModalHeader extends PureComponent {
  static displayName = "PrimaryModalHeader";

  static propTypes = {
    /**
     * Class to apply to the modal-header div
     */
    className: PropTypes.string,
    /**
     * The function to execute when the close icon is clicked
     */
    close: PropTypes.func.isRequired,
    /**
     * Content to render inside the modal header
     */
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children, close, ...otherProps } = this.props;

    return (
      <div
        {...otherProps}
        className={classNames("primary-modal-header", className)}
      >
        <h3 className="header-container pull-left">{children}</h3>
        <div className="pull-right">
          <button
            type="button"
            className="close"
            onClick={close}
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
        </div>
      </div>
    );
  }
}
