import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as constants from "./constants";

const dataPath = (...args) => path([constants.NAMESPACE, "data", ...args]);
const uiPath = (...args) => path([constants.NAMESPACE, "ui", ...args]);

export const getIRS = dataPath("irs");
export const getPatients = dataPath("patients");
export const getAudit = dataPath("audit");
export const getDataFiles = dataPath("dataFiles");

export const isLoading = uiPath("loading");
export const isDeleting = uiPath("deleting");
export const getIrForDelete = uiPath("projectIRForDelete");

export const getAuditByIrId = createSelector(
  (state, irId) => irId,
  getAudit,
  (irId, audit = {}) => audit[irId]
);

export const getPatientsByIrId = createSelector(
  (state, irId) => irId,
  getPatients,
  (irId, patients = {}) => patients[irId]
);

export const getDataFilesByIrId = createSelector(
  (state, irId) => irId,
  getDataFiles,
  (irId, dataFiles = {}) => dataFiles[irId]
);
