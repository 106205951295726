import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class Textarea extends PureComponent {
  static displayName = "Textarea";

  static propTypes = {
    /**
     * Class to apply to the textarea
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, value, ...otherProps } = this.props;
    return (
      <textarea
        className={classNames("form-control", className)}
        value={value === null ? "" : value}
        {...otherProps}
      />
    );
  }
}
