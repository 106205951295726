import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as constants from "./constants";

export const configGenePanelsPath = path([
  constants.NAME,
  constants.CONFIG_GENE_PANELS,
]);

export const getGeneWrappersByGeneId = path([
  constants.NAME,
  "geneWrappersByGeneId",
]);

export const getConfigPanelGene = createSelector(
  getGeneWrappersByGeneId,
  (state, geneId) => geneId,
  (geneWrappers, geneId) => {
    const { gene } = geneWrappers[geneId][0];
    if (!gene) {
      return null;
    }

    return {
      ...gene,
      variants: [],
    };
  }
);
