import { EXON_RANK_MENU_LABEL } from "../../constants";
import { Track } from "../core/Transcripts";
import {
  Colors,
  Genomes,
  getEnsemblUrl,
  getExonRank,
  getOnClickFeatures,
} from "../utils";

export const Logics = {
  REFSEQ: "refseq",
  ENSEMBL: "ensembl",
  ALL: "all",
};

const Biotypes = {
  PROTEIN_CODING: "protein_coding",
  PROCESSED_TRANSCRIPT: "processed_transcript",
  PROCESSED_PSEUDOGENE: "processed_pseudogene",
  ANTISENSE: "antisense",
  SENSE_INTRONIC: "sense_intronic",
  PSEUDOGENE: "pseudogene",
  RNA: "RNA",
};

const LogicHash = {
  [Logics.ENSEMBL]: (genome, id) =>
    `${getEnsemblUrl(genome)}/Homo_sapiens/Transcript/Summary?t=${id}`,
};

const GenomeHash = {
  [Genomes.GRCH37]: id => `https://www.ncbi.nlm.nih.gov/gene/?term=${id}`,
  [Genomes.GRCH38]: id => `https://www.ncbi.nlm.nih.gov/nuccore/${id}`,
};

const getTitleUrl = (logic_name, genome, id) => {
  if (LogicHash[logic_name]) {
    return LogicHash[logic_name](genome, id);
  }

  if (GenomeHash[genome]) {
    return GenomeHash[genome](id);
  }
};

// TODO: This needs changing when it's written in full React App
const getPanelLinks = (gene_panels, patientId) =>
  gene_panels.map(({ gene_panel_id, gene_panel_title }) => ({
    label: gene_panel_title,
    url: `/patient/${patientId}/#genes-coverage/gene-panel-${gene_panel_id}`,
  }));

export const getMenu = (feature, chr, x, controller) => {
  const exonRank = getExonRank(feature, chr, x) || {};

  if (feature) {
    const featureMenu = controller.getFeatureMenu(feature);
    if (featureMenu) {
      if (exonRank) {
        featureMenu[EXON_RANK_MENU_LABEL] = exonRank.rank;
      }
      return {
        start: feature.start,
        end: feature.end,
        label: feature.label,
        color: feature.color,
        chr,
        ...featureMenu,
      };
    }
  }
  return null;
};

export const TRANSCRIPT_COLORS = [
  {
    predicate: ({ in_panel, is_default_transcript }) =>
      [in_panel, is_default_transcript].some(value => value === 1),
    color: Colors.RED,
  },
  {
    predicate: ({ logic_name }) => logic_name.startsWith(Logics.REFSEQ),
    color: Colors.BLUE,
  },
  {
    predicate: ({ logic_name }) => logic_name.includes(Logics.ENSEMBL),
    color: Colors.ORANGE,
  },
  {
    predicate: ({ biotype }) => biotype.includes(Biotypes.RNA),
    color: Colors.PURPLE,
  },
  {
    predicate: ({ biotype }) => biotype === Biotypes.PROTEIN_CODING,
    color: Colors.RED,
  },
  {
    predicate: ({ biotype }) =>
      [
        Biotypes.PROCESSED_TRANSCRIPT,
        Biotypes.ANTISENSE,
        Biotypes.SENSE_INTRONIC,
      ].includes(biotype),
    color: Colors.BLUE_DARK,
  },
  {
    predicate: ({ biotype }) =>
      [Biotypes.PSEUDOGENE, Biotypes.PROCESSED_PSEUDOGENE].includes(biotype),
    color: Colors.GREY,
  },
  {
    predicate: () => true,
    color: Colors.RED,
  },
];

export const Transcripts = ({
  patientId,
  genome,
  snvPos,
  ensemblVersion,
  chr,
} = {}) => {
  const allEnsemblTitle = `All (Ensembl ${ensemblVersion})`;
  return Track.extend({
    id: "Congenica.Transcripts",
    namespace: "Congenica.Transcripts",
    name: "Transcripts",
    info: "The default transcript is highlighted in red. The gene name and direction of transcription is indicated under each transcript",
    url: `/transcript/patient/${patientId}/all.json?chr=__CHR__&start=__START__&end=__END__`,
    snvPos,
    menuLinks: {
      title: ({ logic_name, id }) => getTitleUrl(logic_name, genome, id),
      panel: ({ gene_panels }) => getPanelLinks(gene_panels, patientId),
    },
    colors: TRANSCRIPT_COLORS,
    controls: [
      `<select data-control="filter">
        <option value="${Logics.REFSEQ}">RefSeq only</option>
        <option value="${Logics.ENSEMBL}">Ensembl ${ensemblVersion} only</option>
        <option value="${Logics.ALL}">${allEnsemblTitle}</option>
      </select>`,
    ],
    defaultConfig: {
      filter: Logics.REFSEQ,
    },
    configSettings: {
      filter: {
        [Logics.ENSEMBL]: {
          featureFilter: ({ logic_name }) => logic_name === Logics.ENSEMBL,
        },
        [Logics.REFSEQ]: {
          featureFilter: ({ logic_name }) => logic_name === Logics.REFSEQ,
        },
        [Logics.ALL]: {
          name: allEnsemblTitle,
        },
      },
    },
    click(e) {
      const { features, x } = getOnClickFeatures.bind(this)(e);

      const menu = getMenu(features[0], chr, x, this.track.controller);
      if (menu) {
        this.browser.makeMenu(menu, e, this.track);
      }
    },
  });
};
