import { Track } from "../core/dbSNP";
import { Colors, getEnsemblRestUrl } from "../utils";

export const dbSNP = ({ genome }) =>
  Track.extend({
    url:
      "/browser/proxy/dbSNP?proxy=" +
      encodeURIComponent(
        getEnsemblRestUrl(genome) +
          "/overlap/region/human/__CHR__:__START__-__END__?feature=variation;content-type=application/json"
      ),
    info: "Entries of variants overlapping this variant in the dbSNP database. Click on the bar to open pop-up links to the corresponding dbSNP records.",
    resizable: true,
    colorMap: {
      "Likely LOF": { color: Colors.RED, labelColor: Colors.WHITE },
      "Protein Altering": {
        color: Colors.ORANGE_DARK,
        labelColor: Colors.BLACK,
      },
      "Transcript amplification": {
        color: Colors.BLUE,
        labelColor: Colors.WHITE,
      },
      "In annotated regulatory region": {
        color: Colors.BLUE,
        labelColor: Colors.WHITE,
      },
      "In mRNA, not protein altering": {
        color: Colors.BLUE,
        labelColor: Colors.WHITE,
      },
      ncRNA: { color: Colors.BLUE, labelColor: Colors.WHITE },
      "Not in mRNA or annotation regulatory region": {
        color: Colors.BLUE,
        labelColor: Colors.WHITE,
      },
      "In UTR": { color: Colors.BLUE, labelColor: Colors.WHITE },
      Excluded: { color: Colors.SILVER, labelColor: Colors.BLACK },
      "Unknown consequence": {
        color: Colors.GREY,
        labelColor: Colors.BLACK,
      },
    },
  });
