// @flow

import { fetchCamelizeData } from "modules/utils";
import { CATALYST_BASE_URL } from "modules/utils/baseUrls";
import { buildDataTableQueryParams } from "modules/utils/fetchData";
import { formatPaginatedResponse } from "modules/utils/formatApiResponse";

const processTemplates = (templates): Array<ReportTemplate> =>
  templates.map(({ isInherited, updated, ...rest }) => ({
    ...rest,
    isInherited: Boolean(isInherited),
    // API epoch is off by factor of 1000
    updated: updated * 1000,
  }));

export const getReportTemplatesForProject = async (
  projectId: number,
  requestParams?: TableRequestParams
): Promise<PaginatedTableResponse<ReportTemplate>> => {
  const response = await fetchCamelizeData(
    `${CATALYST_BASE_URL}/project/${projectId}/report-templates?${buildDataTableQueryParams(
      requestParams
    )}`
  );

  return formatPaginatedResponse(response, processTemplates);
};

export const getAvailableTemplatesForProject = async (
  projectId: number,
  requestParams?: TableRequestParams
): Promise<PaginatedTableResponse<ReportTemplate>> => {
  const response = await fetchCamelizeData(
    `${CATALYST_BASE_URL}/project/${projectId}/report-templates/available?${buildDataTableQueryParams(
      requestParams
    )}`
  );

  return formatPaginatedResponse(response, processTemplates);
};

export const toggleTemplateForProject = async ({
  templateUuid,
  projectId,
  enabled,
}: ToggleTemplateParameters) => {
  const url = `${CATALYST_BASE_URL}/project/${projectId}/report_template/${templateUuid}`;

  // If we're toggling ON, then we need to POST, otherwise we DELETE
  const method = enabled ? "POST" : "DELETE";

  const response = await fetchCamelizeData(url, {
    method,
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return {
    templateUuid,
    projectId,
    enabled,
  };
};

export const createTemplate = async () => {
  throw Error("Not yet implemented!");
};
