import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import * as yup from "yup";

import {
  Button,
  ComposableTabs,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tab,
} from "pattern-library";

import validator from "modules/forms/validator";

import {
  addProjectUser,
  updateProjectUser,
  addPermissionsProjectUser,
  fetchCurrentUserAuthProviders,
} from "../actions";
import {
  USER_FORM,
  ADD_EXISTING_USER,
  ADD_PROJECT_USER,
  DELETE_PROJECT_USER,
  EDIT_PROJECT_USER,
} from "../constants";
import * as selectors from "../selectors";

import UserForm from "./UserForm";
import UsersLookupView from "./UsersLookupView";

const FULL_NAME_MESSAGE = "Please provide a valid user name";
const EMAIL_MESSAGE = "Please provide a valid email";

export const validationSchema = yup.object({
  fullname: yup
    .string("")
    .trim()
    .required(FULL_NAME_MESSAGE)
    .min(3, FULL_NAME_MESSAGE),
  email: yup.string("").trim().required(EMAIL_MESSAGE).email(EMAIL_MESSAGE),
});

export const UserModal = ({
  close,
  show,
  mode,
  handleSubmit,
  addProjectUser,
  updateProjectUser,
  addPermissionsProjectUser,
  isAdmin,
  reset,
  projectId,
  doubleUserCheck,
  fetchCurrentUserAuthProviders,
}) => {
  const tabs = [
    {
      name: "New",
      contentComponent: UserForm,
      props: { mode, isAdmin, doubleUserCheck },
    },
    {
      name: "Existing",
      contentComponent: UsersLookupView,
      props: { projectId },
    },
  ];

  useEffect(() => {
    if (mode !== ADD_PROJECT_USER) {
      fetchCurrentUserAuthProviders();
    }
  }, [mode, fetchCurrentUserAuthProviders]);

  const submitHandler = useCallback(
    values => {
      if (mode === ADD_PROJECT_USER) {
        addProjectUser(values);
      } else if (mode === ADD_EXISTING_USER) {
        addPermissionsProjectUser(values);
      } else {
        updateProjectUser(values);
      }
    },
    [mode, addProjectUser, updateProjectUser, addPermissionsProjectUser]
  );

  const onClose = useCallback(() => {
    close();
    reset();
  }, [reset, close]);
  return (
    <Modal data-testid="user-modal" close={onClose} show={show}>
      <ModalHeader close={onClose}>
        <Heading type="h3">
          {mode === ADD_PROJECT_USER && "Add project user"}
          {mode === ADD_EXISTING_USER && "Add user rights and permissions"}
          {mode === EDIT_PROJECT_USER && "Edit user rights and permissions"}
        </Heading>
      </ModalHeader>
      <ModalBody className="users-form-body">
        <form
          id={
            mode === ADD_PROJECT_USER
              ? "form_project_user_add"
              : "form_project_user"
          }
          onSubmit={handleSubmit(submitHandler)}
        >
          {mode !== ADD_PROJECT_USER && (
            <>
              <UserForm
                mode={mode}
                isAdmin={isAdmin}
                doubleUserCheck={doubleUserCheck}
              />
              <ModalFooter className="users-form-footer">
                <Button data-testid="submit" type="submit" context="primary">
                  Update
                </Button>
              </ModalFooter>
            </>
          )}
          {mode === ADD_PROJECT_USER && (
            <ComposableTabs navStyle>
              {tabs.map(({ name, contentComponent: Component, props }) => (
                <Tab key={name} name={name}>
                  <Component {...props} />
                  {name === "New" && (
                    <ModalFooter className="users-form-footer">
                      <Button
                        data-testid="submit"
                        type="submit"
                        context="primary"
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  )}
                </Tab>
              ))}
            </ComposableTabs>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
};

UserModal.propTypes = {
  close: PropTypes.func.isRequired,
  show: PropTypes.bool,
  mode: PropTypes.oneOf([
    ADD_PROJECT_USER,
    EDIT_PROJECT_USER,
    ADD_EXISTING_USER,
    DELETE_PROJECT_USER,
  ]),
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

const ConnectedForm = reduxForm({
  form: USER_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
  shouldAsyncValidate(params) {
    return params.trigger === "change" || params.trigger === "submit";
  },
  asyncValidate: values => validator(validationSchema)(values),
})(UserModal);

const mapStateToProps = state => {
  const selector = formValueSelector(USER_FORM);

  return {
    initialValues: selectors.getEditableUser(state),
    isAdmin: selector(state, "roles.admin"),
    mode: selectors.getUserMode(state),
  };
};

const mapDispatchToProps = {
  addProjectUser,
  updateProjectUser,
  fetchCurrentUserAuthProviders: fetchCurrentUserAuthProviders.start,
  addPermissionsProjectUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);
