import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import { clone, equals, isEmpty, isNil, last } from "ramda";
import React, { Fragment, PureComponent } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Label } from "../../elements";

import { DraggableItem, withTooltip } from "./DraggableItem";
import {
  DraggableCheckboxesProps,
  CheckboxTreeValueKeyProp,
  DraggableCheckboxesState,
  DraggableOption,
} from "./types";

const getValuesIndex = (values: Array<string> = [], { key }: { key: string }) =>
  values.indexOf(key);

export const sortOptions = (tree, values) =>
  tree.sort((a, b) => {
    if (a.options) {
      a.options = sortOptions(a.options, values);
    }
    if (b.options) {
      b.options = sortOptions(b.options, values);
    }

    const indexAInServerArray = getValuesIndex(values, a);
    const indexBInServerArray = getValuesIndex(values, b);

    if (indexAInServerArray === -1 && indexBInServerArray !== -1) return 1;
    if (indexBInServerArray === -1 && indexAInServerArray !== -1) return -1;
    return indexAInServerArray - indexBInServerArray;
  });

export default class DraggableCheckboxes extends PureComponent<
  DraggableCheckboxesProps,
  DraggableCheckboxesState
> {
  static populateKeys(nestedArr) {
    nestedArr.forEach((item, i) => {
      if (item.options) {
        DraggableCheckboxes.populateKeys(item.options);
      } else {
        item.key = i;
      }
    });
  }

  static updateValues(
    options: Array<DraggableOption> = [],
    values: Set<CheckboxTreeValueKeyProp> = new Set(),
    toggledItemKey
  ) {
    const newValues: Array<string> = [];
    options.forEach(({ key, options: children }: any) => {
      const optionWasChecked = values.has(key);
      if (children) {
        const childValues = DraggableCheckboxes.updateValues(
          children,
          values,
          toggledItemKey
        );
        if (!isEmpty(childValues)) {
          newValues.push(key);
          newValues.push(...childValues);
        }
      } else if (
        (optionWasChecked && toggledItemKey !== key) ||
        (!optionWasChecked && toggledItemKey === key)
      ) {
        newValues.push(key);
      }
    });

    return newValues;
  }

  static displayName = "DraggableCheckboxes";

  static defaultProps = {
    value: [],
    active: null,
    sortable: false,
    disabled: false,
    label: null,
    narrow: false,
    required: false,
  };

  static getDerivedStateFromProps(
    { options, value, pristine },
    { value: prevValue, hasValueChanged }
  ) {
    if (!pristine && pristine === hasValueChanged) {
      return {
        hasValueChanged: true,
      };
    }
    if (pristine && (hasValueChanged || !equals(value, prevValue))) {
      return {
        options: sortOptions(
          DraggableCheckboxes.getInitialStateFromProps({
            options,
          }),
          value
        ),
        value,
        hasValueChanged: false,
      };
    }
    return null;
  }

  static getInitialStateFromProps({ options }) {
    const newOptions = clone(options);
    if (options && options.length && isNil(options[0].key)) {
      // this is pre-populated options
      // if the options have no keys then populate them.
      DraggableCheckboxes.populateKeys(newOptions);
    }
    return newOptions;
  }

  static getItemByPath(data, path) {
    if (!data || path.length === 0) {
      return null;
    }
    if (path.length === 1) {
      return data[path[0]];
    }
    return DraggableCheckboxes.getItemByPath(
      data[path[0]].options,
      path.slice(1)
    );
  }

  constructor(props: DraggableCheckboxesProps) {
    super(props);

    const { value, pristine } = props;

    this.state = {
      options: sortOptions(
        DraggableCheckboxes.getInitialStateFromProps(props),
        value
      ),
      value,
      hasValueChanged: pristine, //need to sort selected items to the top on form reset
    };
    autoBind(this);
  }

  onChange() {
    this.updateValues();
  }

  moveItem(fromPath, toPath) {
    this.setState(
      ({ options = [], ...restState }) => {
        if (!equals(fromPath.slice(0, -1), toPath.slice(0, -1))) return;
        const newOptions: Array<DraggableOption> = clone(options);

        const initialPos = last(fromPath);
        const finalPos = last(toPath);
        const itemToMove = DraggableCheckboxes.getItemByPath(
          newOptions,
          fromPath
        );
        // find the array we want to update, update it out of place then replace
        // the original with the updated.
        const arrToUpdate =
          fromPath.length > 1
            ? DraggableCheckboxes.getItemByPath(
                newOptions,
                fromPath.slice(0, -1)
              ).options
            : newOptions;
        arrToUpdate.splice(initialPos, 1); // remove the element we're moving
        arrToUpdate.splice(finalPos, 0, itemToMove); // insert item into new pos

        return {
          ...restState,
          options: newOptions,
        };
      },
      () => {
        this.onChange();
      }
    );
  }

  toggleVisible(key) {
    this.updateValues(key);
  }

  updateValues(key?: string) {
    const { options = [] } = this.state;
    const { value = [], onChange } = this.props;
    const values = new Set(value);

    const newOptions = DraggableCheckboxes.updateValues(options, values, key);

    onChange(newOptions);
  }

  render() {
    const {
      label,
      description,
      sortable,
      disabled,
      narrow,
      required,
      otherProps,
    } = this.props;
    const { options = [] } = this.state;
    const { value } = this.props;
    const values = new Set(value);

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="draggable-checkbox-list">
          <fieldset disabled={disabled}>
            {!!label && (
              <Label
                className={classNames("control-label", {
                  "col-xs-4": narrow,
                  "col-xs-2": !narrow,
                })}
                required={required}
              >
                {label}
              </Label>
            )}

            <div
              className={classNames({
                "col-xs-12": !label,
                "col-xs-8": label && narrow,
                "col-xs-10": label && !narrow,
              })}
            >
              {options.map(
                (
                  {
                    options,
                    label,
                    key,
                    disabled: disabledOption,
                    tooltip,
                    subtitle,
                  },
                  index
                ) => {
                  const finalLabel = subtitle
                    ? `${label} (${subtitle})`
                    : label;
                  const item = (
                    <DraggableItem
                      {...otherProps}
                      path={[index]}
                      label={finalLabel}
                      options={options}
                      values={values}
                      sortable={sortable && !disabled && !disabledOption}
                      moveItem={this.moveItem}
                      toggleVisible={this.toggleVisible}
                      onDrop={this.onChange}
                      id={key}
                      disabled={disabledOption}
                    />
                  );

                  return (
                    <Fragment key={key}>{withTooltip(item, tooltip)}</Fragment>
                  );
                }
              )}
              {!!description && (
                <small className="text-muted">{description}</small>
              )}
            </div>
          </fieldset>
        </div>
      </DndProvider>
    );
  }
}
