import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as constants from "./constants";

const uiPath = (...items) => [constants.NAME, "ui", ...items];

export const isInitialised = path(uiPath("init"));
export const isLoaded = path(uiPath("loaded"));
export const page = path(uiPath("pagination", "page"));
export const pager = path(uiPath("pagination", "pager"));
export const getAllProjects = path([constants.NAME, "data"]);
export const getProjectsCount = state =>
  path([constants.NAME, "data"], state).length;
export const getProjectTypesForChildProjects = path(
  uiPath("projectTypesForChildProjects")
);
export const projectTypesForChildProjectsLoaded = path(
  uiPath("projectTypesForChildProjectsLoaded")
);

export const filterProjects = (projects, filterValue) => {
  if (!filterValue || filterValue === "") return projects;
  const searchTermWords = filterValue.toUpperCase().split(/\s+/);

  return projects.filter(
    ({
      attributes: {
        keywords = "",
        keywordsPath = "",
        description = "",
        patientCount,
        subProjectCount,
      },
    }) => {
      const toSearch = [
        description,
        keywords,
        patientCount,
        subProjectCount,
        keywordsPath,
      ]
        .join(" ")
        .toUpperCase();

      // Check that every word in the search term is contained
      // somewhere across all of the properties we are checking
      return searchTermWords.every(word => toSearch.includes(word));
    }
  );
};

export const getProjectType = createSelector(
  (state, projectId) => projectId,
  getAllProjects,
  (projectId, projects = []) => {
    const { attributes: { projectTypeInternalName } = {} } =
      projects.find(({ id }) => "" + projectId === "" + id) || {};
    return projectTypeInternalName;
  }
);
