import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { equals, last } from "ramda";
import React, { Fragment, PureComponent } from "react";
import { DragSource as dragSource, DropTarget as dropTarget } from "react-dnd";

import { Tooltip } from "pattern-library";

import { CheckboxTreeValueKeyProp } from "./prop-types";

const itemType = "checkbox";

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const itemSource = {
  beginDrag({ path }) {
    // Return the data describing the dragged item
    return { path };
  },

  endDrag(props, monitor) {
    const didDrop = monitor.didDrop();

    if (!didDrop) {
      props.onDrop();
    }
  },
};

const itemTarget = {
  hover(props, monitor, component) {
    const draggedItem = monitor.getItem();
    const draggedItemPath = draggedItem.path;
    const hoveredItemPath = props.path;
    const draggedItemIndex = last(draggedItemPath);
    const hoveredItemIndex = last(hoveredItemPath);

    // Don't replace options with themselves
    if (draggedItemIndex === hoveredItemIndex) {
      return;
    }

    // Don't replace options with ones not from the same ul.
    if (!equals(draggedItemPath.slice(0, -1), hoveredItemPath.slice(0, -1))) {
      return;
    }
    // Determine rectangle on screen
    const { node } = component;
    const hoverBoundingRect = node.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the options height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (draggedItemIndex < hoveredItemIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (draggedItemIndex > hoveredItemIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveItem(draggedItemPath, hoveredItemPath);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    draggedItem.path = hoveredItemPath;
  },

  drop({ onDrop }) {
    onDrop(); // bubble the drop up to the parent
  },
};

export const withTooltip = (component, tooltipProps) =>
  tooltipProps ? (
    <Tooltip {...tooltipProps}>
      <div>{component}</div>
    </Tooltip>
  ) : (
    component
  );

class DraggableItemWrapper extends PureComponent {
  static displayName = "DraggableItemWrapper";

  static propTypes = {
    /**
     * Path, as a list of indices to the object
     * Path reflects the nesting level of a given checkbox. It is built recursively by taking
     * the index of a parent list on each nesting level going top to bottom except level 0.
     * The last item is the index of a checkbox in the innermost list.
     * If there are no nested lists the path length is 1
     */
    path: PropTypes.array,
    /**
     * Label for this component
     */
    label: PropTypes.string.isRequired,
    /**
     * Property to determine whatever checkbox checked or unchecked
     */
    values: PropTypes.object,
    /**
     * array of  options
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: CheckboxTreeValueKeyProp,
        label: PropTypes.string.isRequired,
        options: PropTypes.array,
      })
    ),
    /**
     * function handling items sort within the same component
     */
    moveItem: PropTypes.func.isRequired,
    /**
     * Function for a toggle visibility according a path
     */
    toggleVisible: PropTypes.func.isRequired,
    /**
     * the flag that indicates that the element is being dragged
     */
    isDragging: PropTypes.bool.isRequired,
    /**
     * the flag that indicates isOver property
     */
    isOver: PropTypes.bool,
    /**
     * function that must be used inside the component to assign the drag source role to a node
     */
    connectDragSource: PropTypes.func.isRequired,
    /**
     * function that must be used inside the component to mark mark any React element as the droppable node
     */
    connectDropTarget: PropTypes.func.isRequired,
    /**
     * sortable property
     */
    sortable: PropTypes.bool,
    /**
     * item key
     */
    id: CheckboxTreeValueKeyProp,
  };

  static defaultProps = {
    path: [],
    options: [],
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  setItemRef(node) {
    this.node = node;
  }

  handleCheckboxToggle() {
    const { toggleVisible, id } = this.props;
    toggleVisible(id);
  }

  render() {
    const {
      label,
      path,
      options,
      isDragging,
      isOver,
      connectDragSource,
      connectDropTarget,
      sortable,
      values,
      id,
      disabled,
      ...otherProps
    } = this.props;

    const checked = values ? values.has(id) : false;

    const content = (
      <ul className="item-top-ul" ref={this.setItemRef}>
        <li
          className={classNames("list-item", "item-movable", {
            bold: checked,
            "is-over": isOver,
            "is-dragging": isDragging,
          })}
        >
          <label className="item-label">
            {sortable && (
              <i className="glyphicon glyphicon-resize-vertical item-arrow" />
            )}
            {options.length === 0 && (
              <input
                className="item-input"
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={this.handleCheckboxToggle}
              />
            )}
            {label}
          </label>
          {options.length > 0 &&
            options.map(
              (
                { options, label, key, disabled: disabledOption, tooltip },
                index
              ) => {
                const item = (
                  <DraggableItem
                    {...otherProps}
                    path={path.concat(index)}
                    label={label}
                    options={options}
                    values={values}
                    sortable={sortable && !disabled && !disabledOption}
                    id={key}
                    disabled={disabledOption}
                  />
                );

                return (
                  <Fragment key={key}>{withTooltip(item, tooltip)}</Fragment>
                );
              }
            )}
        </li>
      </ul>
    );

    if (!sortable) {
      return content;
    }

    return connectDragSource(connectDropTarget(content));
  }
}

export const DraggableItem = dropTarget(itemType, itemTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))(
  dragSource(itemType, itemSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(DraggableItemWrapper)
);
