import {
  fetchOtherPatientsSnvCounts,
  fetchSequenceVariantSummary,
  SequenceVariantsAction,
} from "./actions";
import {
  OtherPatientsSnvCounts,
  SequenceVariantState,
  SequenceVariantSummaryState,
  SUMMARY_STATE_PATH as STATE_PATH,
} from "./types";

const defaultVariantState: SequenceVariantState = {
  loading: true,
  data: null,
  otherPatientsSnvCounts: {
    data: null,
    loading: false,
  },
};

export const initialState: SequenceVariantSummaryState = {
  [STATE_PATH.VARIANTS]: defaultVariantState,
  [STATE_PATH.AUDIT]: defaultVariantState,
};

export const reducer = (
  state: SequenceVariantSummaryState = initialState,
  action: SequenceVariantsAction
) => {
  const path = action.meta?.path;
  const updateStateAtPath = (data): SequenceVariantSummaryState => {
    const pathObject = state[path];
    return {
      ...state,
      [path]: {
        ...pathObject,
        ...data,
      },
    };
  };

  switch (action.type) {
    case fetchSequenceVariantSummary.START_ACTION:
      return updateStateAtPath({
        loading: true,
        data: null,
      });
    case fetchSequenceVariantSummary.SUCCESS_ACTION:
      return updateStateAtPath({
        loading: false,
        data: action.payload,
      });
    case fetchSequenceVariantSummary.FAILURE_ACTION:
      return updateStateAtPath({
        loading: false,
      });

    case fetchOtherPatientsSnvCounts.START_ACTION:
      return updateStateAtPath({
        otherPatientsSnvCounts: {
          data: null,
          loading: true,
        },
      });
    case fetchOtherPatientsSnvCounts.FAILURE_ACTION:
      return updateStateAtPath({
        otherPatientsSnvCounts: {
          data: null,
          loading: false,
        },
      });
    case fetchOtherPatientsSnvCounts.SUCCESS_ACTION:
      const otherPatientsSnvCounts = action.payload as OtherPatientsSnvCounts;

      return updateStateAtPath({
        otherPatientsSnvCounts: {
          data: otherPatientsSnvCounts,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
