import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { PATIENT_FEATURE_DATA_FIELDS } from "modules/config/feature-flag-config";
import { getCurrentProject } from "modules/project/selectors";

import { NAMESPACE } from "./constants";

export const getPatient = path([NAMESPACE, "data", "patient"]);
export const getTabs = path([NAMESPACE, "ui", "tabs"]);
export const getCustomIframeConfig = path([NAMESPACE, "data", "customIframe"]);
export const getPatientReports = path([NAMESPACE, "data", "reports"]);
export const getThirdpartySoftwareVersions = path([
  NAMESPACE,
  "data",
  "patient",
  "thirdpartySoftwareVersions",
]);

export const isAriadnePredictionsDataAvailable = path([
  NAMESPACE,
  "data",
  "patient",
  "hasCongenicaAiPredictions",
]);

export const patientFeatureDataFields = path([
  NAMESPACE,
  "data",
  "patient",
  PATIENT_FEATURE_DATA_FIELDS,
]);

export const getSinglePatient = createSelector(
  (state, patientId) => patientId,
  getPatient,
  getCurrentProject,
  (patientId, patient: Patient | null, project) => {
    if (!patient || (patientId && patient.patientId !== Number(patientId))) {
      return null;
    }
    return {
      ...patient,
      project,
    };
  }
);
