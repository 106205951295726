import { react as autoBind } from "auto-bind";
import PropsTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { ReduxFormField } from "../../../forms/components";
import { CheckboxOptionArrayProp } from "../../../utils/prop-types/CheckboxOptionProp";
import { getFilterDefaults } from "../../selectors";

export class CustomDataForm extends PureComponent {
  static propTypes = {
    disabled: PropsTypes.bool,
    customTier: CheckboxOptionArrayProp.isRequired,
    customPenetrance: CheckboxOptionArrayProp.isRequired,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const {
      disabled = false,
      customTier = [],
      customPenetrance = [],
    } = this.props;
    return (
      <fieldset disabled={disabled}>
        <Field
          component={ReduxFormField}
          type="checkboxgroup"
          label="Tiers"
          name="customTier"
          narrow={9}
          options={customTier}
        />
        <Field
          component={ReduxFormField}
          type="checkboxgroup"
          label="Penetrance"
          name="customPenetrance"
          narrow={9}
          options={customPenetrance}
        />
      </fieldset>
    );
  }
}

const mapStateToProps = state => ({
  customTier: getFilterDefaults(state).allTierValues,
  customPenetrance: getFilterDefaults(state).allPenetranceValues,
});

export default connect(mapStateToProps)(CustomDataForm);
