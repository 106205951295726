import {
  hasRequestFailed,
  isRequestCompleted,
  isRequestSuccessful,
} from "../../common/selectors";

export const getUpdatedPresetsDataState = (
  state: FilterPresetsState,
  presetsInfo: FilterPresetsStatusInfo
) => {
  const { presets = [], requestStatus } = presetsInfo;
  if (!isRequestCompleted(requestStatus)) {
    // only completion statuses can change the data
    return state.data;
  }

  const updatedDataCopy = {
    ...state.data,
  };

  if (isRequestSuccessful(requestStatus)) {
    updatedDataCopy.presets = presets ? presets : []; //to make Flow happy
  }

  if (hasRequestFailed(requestStatus)) {
    updatedDataCopy.presets = [];
  }

  return updatedDataCopy;
};

export const getDefaultPreset = (
  presets: Array<FilterPresetBase>
): FilterPresetBase => {
  if (!presets || !presets.length) {
    return null;
  }
  const defaultPreset = presets.find(
    ({ attributes: { isDefault } }) => isDefault
  );
  if (defaultPreset) {
    return defaultPreset;
  }
  //fallback to the first preset
  return presets[0];
};

export const getDefaultPresetId = (
  presets: Array<FilterPresetBase>
): ?number => {
  const defaultPreset = getDefaultPreset(presets);
  if (defaultPreset) {
    return defaultPreset.id;
  }

  return null;
};

export const getUpdatedPresetsUIState = (
  state: FilterPresetsState,
  presetsInfo: FilterPresetsStatusInfo
) => {
  const { presets = [], requestStatus } = presetsInfo;
  const requestFailed = hasRequestFailed(requestStatus);
  const requestSucceeded = isRequestSuccessful(requestStatus);

  const updateUICopy = {
    ...state.ui,
    presetsRequestStatus: requestStatus,
  };

  if (requestSucceeded) {
    updateUICopy.selectedPresetId = getDefaultPresetId(presets);
  }

  if (requestFailed) {
    updateUICopy.selectedPresetId = null;
  }

  return updateUICopy;
};

export const presetToOption = (
  preset: FilterPresetBase
): SelectItem<FilterPresetBase> => {
  const {
    attributes: { title },
  } = preset;
  return {
    label: title,
    value: preset,
  };
};

export const findPresetById = (
  selectedPresetId: number,
  presets: Array<FilterPresetBase>
) => {
  if (!selectedPresetId) {
    return null;
  }

  const preset = presets.find(({ id }) => id === selectedPresetId);

  if (!preset) {
    return null;
  }

  return presetToOption(preset);
};
