import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class DisabledOverlay extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    show: PropTypes.bool,
  };

  render() {
    const { children, show = true } = this.props;
    return (
      <div
        className={classNames("loading-overlay", {
          "loading-overlay-active": show,
        })}
      >
        {show && <div className="message-overlay" />}
        {children}
      </div>
    );
  }
}
