import Genoverse from "genoverse";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { getTracksByKeys } from "../../genoverseBrowser/trackLibraryHelpers";

import { GenoverseConfig, GenoverseInstance } from "./GenoverseBrowser.types";

type GenoverseBrowserProps = {
  className?: string;
  config: GenoverseConfig;
};

const GenoverseBrowser: FunctionComponent<GenoverseBrowserProps> = ({
  className,
  config,
}) => {
  const genoverseContainer = useRef<HTMLDivElement>(null);
  const genoverseInstance = useRef<GenoverseInstance | null>(null);

  const destroyGenoverse = useCallback(() => {
    if (genoverseInstance.current) {
      genoverseInstance.current.destroy();
      genoverseInstance.current = null;
    }
    if (genoverseContainer.current) {
      genoverseContainer.current.style.width = "";
    }
  }, []);

  const initializeGenoverse = useCallback(() => {
    if (!config || !genoverseContainer.current) {
      return;
    }
    destroyGenoverse();

    const genome = config.genome || "grch37";
    const { width } = genoverseContainer.current.getBoundingClientRect();
    const { tracks: defaultTracksKeys = [], tracksLibrary } = config;

    genoverseInstance.current = new Genoverse({
      container: genoverseContainer.current,
      width,
      genome,
      useHash: false,
      urlParamTemplate: false,
      plugins: [
        "controlPanel",
        "karyotype",
        "trackControls",
        "resizer",
        "tooltips",
        "fileDrop",
        "focusRegion",
        "fullscreen",
      ],
      ...config,
      tracks: [
        Genoverse.Track.Scalebar,
        ...getTracksByKeys(tracksLibrary, defaultTracksKeys),
      ],
    });
  }, [config, destroyGenoverse]);

  const resizeGenoverse = useCallback(() => {
    if (genoverseContainer.current && genoverseInstance.current) {
      genoverseContainer.current.style.width = "";
      const { width } = genoverseContainer.current.getBoundingClientRect();
      genoverseInstance.current.setWidth(width);
    }
  }, []);

  useEffect(() => {
    initializeGenoverse();
    window.addEventListener("resize", resizeGenoverse);

    return () => {
      destroyGenoverse();
      window.removeEventListener("resize", resizeGenoverse);
    };
  }, [destroyGenoverse, initializeGenoverse, resizeGenoverse]);

  return (
    <div
      className={`${className} genoverse-container`}
      ref={genoverseContainer}
    />
  );
};

export default GenoverseBrowser;
