import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import ReduxFormField from "../../../forms/components/ReduxFormField";
import { getPrioritisationDefaults } from "../../selectors";

export const PrioritisationForm = ({ disabled = false, options }) => (
  <fieldset disabled={disabled} data-testid="config-prioritisation-form">
    <Field
      component={ReduxFormField}
      type="draggablecheckboxes"
      label="Prioritisation"
      name="prioritisation"
      sortable
      options={options}
    />
  </fieldset>
);

const mapStateToProps = (state, { patientId }) => ({
  options: getPrioritisationDefaults(state, patientId),
});

PrioritisationForm.propTypes = {
  disabled: PropTypes.bool,
};

export default connect(mapStateToProps)(PrioritisationForm);
