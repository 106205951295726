import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class TableComponent extends PureComponent {
  static displayName = "TableComponent";

  static propTypes = {
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
    /**
     * Content to render inside the component
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <div className="table-wrapper">
        <table
          className={classNames(
            "table",
            "table-bordered",
            "table-hover",
            "table-striped",
            className
          )}
          role="grid"
          {...rest}
        >
          {children}
        </table>
      </div>
    );
  }
}
