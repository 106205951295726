import React, { useEffect, useState } from "react";

import { Table, DEFAULT_PAGE_SIZE } from "pattern-library/elements/table7";

import { filterGenePanels } from "../utils";

import EnsemblFilter from "./EnsemblFilter";

type Props = {
  isLoading: boolean;
  genePanels: (ProjectGenePanel | CopyProjectGenePanel)[];
  columns: any;
  ensemblVersions: Version[];
  title: string;
  showTitleInfo?: boolean;
  className?: string;
  sortBy?: any;
  id: string;
};

const GenePanelsTable: React.FC<Props> = ({
  isLoading,
  genePanels,
  columns,
  ensemblVersions,
  title,
  showTitleInfo,
  className,
  sortBy,
  id,
}) => {
  const [filteredGenePanels, setFilteredGenePanels] =
    useState<typeof genePanels>(genePanels);

  const [keywordFilter, setKeywordFilter] = useState<string>("");
  const [ensemblFilter, setEnsemblFilter] = useState<string>("ALL");

  useEffect(() => {
    setFilteredGenePanels(
      filterGenePanels(genePanels, keywordFilter, ensemblFilter)
    );
  }, [keywordFilter, ensemblFilter, genePanels]);

  const handleKeywordFilterChange = ({ filter = "" }) => {
    setKeywordFilter(filter);
  };

  const handleEnsemblFilterChange = (version: string) =>
    setEnsemblFilter(version);

  // Only show the Ensembl filter option if there's more than one configured in the system
  const ensemblTableFilter =
    ensemblVersions.length > 1 ? (
      <div className="dataTables_filter custom-filter">
        <EnsemblFilter
          id={`ensemblFilter-${id}`}
          versions={ensemblVersions}
          onChange={handleEnsemblFilterChange}
        />
      </div>
    ) : null;

  return (
    <Table
      id={`genePanelsTable-${id}`}
      className={className}
      data={filteredGenePanels}
      columns={columns}
      loading={isLoading}
      enableFilter
      fetchData={handleKeywordFilterChange}
      autoResetPage={false}
      showPagination={genePanels && genePanels.length > DEFAULT_PAGE_SIZE}
      pageSizeOptions={[10, 25, 50, 100]}
      CustomFilterComponent={ensemblTableFilter}
      title={title}
      showTitleInfo={showTitleInfo}
      sortBy={sortBy}
    />
  );
};

export default GenePanelsTable;
