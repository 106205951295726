import Genoverse from "genoverse";

import { Colors } from "../utils/colors";

// The code in this file was moved over with minimal changes from the old track

const roundScore = (spliceScore, precision) =>
  typeof spliceScore === "number"
    ? spliceScore.toFixed(precision)
    : spliceScore;

export const Controller = Genoverse.Track.Controller.extend({
  populateMenu(feature, extras) {
    const menu = {};
    let entries = [];

    const emptyValues = { "Not Available": 1, "Not Calculated": 1 };
    const splicePrecision = 2;

    const menuTitle =
      feature.motif_type.charAt(0).toUpperCase() +
      feature.motif_type.substr(1).toLowerCase() +
      (feature.denovo ? " <em>(De novo)</em>" : "");

    let maxEntScore = feature.max_ent_score;

    if (!(maxEntScore in emptyValues)) {
      // If mutation destroys splice site, r_score becomes Delta notation
      const r_score =
        !feature.r_score && !feature.mutation_seq && feature.splice_mutation_id
          ? "&Delta;"
          : feature.r_score;
      const score_diff = feature.diff ? " (" + feature.diff + ")" : "";
      maxEntScore =
        feature.max_ent_score && r_score
          ? feature.max_ent_score + " => " + r_score + score_diff
          : feature.max_ent_score;
      // If denovo splice site, only display r_score in place of max_ent_score
      maxEntScore = feature.denovo ? "<em>" + r_score + "<em>" : maxEntScore;
    }

    /*
      The logic to calculate the ssf scores is the same as the slightly mad logic to above maxEntScan calculations.
      I am too scared to change it.

      My understanding is that there are four possible display outcomes for each feature:

        No splice mutation: splice_site.ssf_score
        Non-denovo splice mutation: splice_site.ssf_score => splice_mutation.ssf_r_score (splice_mutation.ssf_diff)
        Non-denovo splice mutation which destroys the splice site: splice_site.ssf_score => &Delta; (splice_mutation.ssf_diff) 
        Denovo splice mutation: <em>splice_mutation.r_score<em>

      Remember that splice_site is a fixed set of reference splice regions, and splice_mutation are
      patient variants found within those splice sites.
    */
    let { ssf_score } = feature;
    if (!(ssf_score in emptyValues)) {
      const isLostSpliceSite = ssf_score && feature.ssf_r_score === 0;
      const ssf_r_score = isLostSpliceSite ? "&Delta;" : feature.ssf_r_score;
      const ssf_score_diff = feature.ssf_diff
        ? " (" + roundScore(feature.ssf_diff, splicePrecision) + ") "
        : "";
      ssf_score =
        feature.ssf_score && ssf_r_score
          ? roundScore(feature.ssf_score, splicePrecision) +
            " => " +
            roundScore(ssf_r_score, splicePrecision) +
            ssf_score_diff
          : roundScore(feature.ssf_score, splicePrecision);
      // if denovo then display the r_score instead
      ssf_score = feature.denovo
        ? "<em>" + roundScore(ssf_r_score, splicePrecision) + "<em>"
        : ssf_score;
    }

    if (feature.motif_type) {
      entries = [
        ["title", menuTitle],
        ["Location", feature.chr + ":" + feature.start + "-" + feature.end],
        ["Strand", feature.strand],
        ["Sequence", feature.seq],
        ["MaxEntScan score", maxEntScore],
        ["ADA score", roundScore(feature.ada_score, splicePrecision)],
        ["RF score", roundScore(feature.rf_score, splicePrecision)],
        ["CSSF score", ssf_score],
      ];

      if (this.prop("spliceAiEnabled")) {
        entries.push([
          "Splice AI score",
          roundScore(feature.splice_ai_max_score, splicePrecision),
        ]);
      }
    }

    entries.forEach(entry => {
      if (typeof entry[1] !== "undefined") {
        const [label, value] = entry;
        menu[label] = value;
      }
    });

    return {
      ...menu,
      ...extras,
    };
  },
});

export const Model = Genoverse.Track.Model.extend({
  insertFeature(feature) {
    feature.start = parseInt(feature.start);
    feature.end = parseInt(feature.end);

    const motifColorMap = this.prop("colorMap")[feature.motif_type];
    const { color } = motifColorMap;
    feature.highlightColor = color;
    feature.outlineColor = color;

    feature.snvId = this.prop("snvId"); // SNV being viewed. Currently draws a marker on top of the SNV

    // Temperory adjustment for de novos while data is still being fixed in the back end
    // Set feature.end to feature.start + length of feature.seq
    if (feature.denovo && feature.seq && feature.start_rel) {
      // Shift postion by start_rel
      feature.start = feature.start - parseInt(feature.start_rel) - 1;
      feature.end = feature.start + feature.seq.length;
    }

    // One-based coordinate adjustment
    feature.start = feature.start + 1;

    feature.length = feature.end - feature.start;

    this.base({
      ...feature,
      ...motifColorMap,
    });
  },
});

export const SpliceSiteView = Genoverse.Track.View.extend({
  featureMargin: { top: 0, right: 0, bottom: 10, left: 0 },
  zeroLevelHeight: 50,
  labels: false,
  bump: false,

  drawFeature(feature, featureContext, labelContext, scale) {
    // Cut-off points
    const maxCT = 20; // Maximum cut-off point
    const minCT = -10; // Minimum cut-off point

    // Calculate barHeight
    const barHeightMax = 20; // Maximum bar height
    const barHeightMin = 2; // Minimum bar height

    const barScale = barHeightMax / (maxCT - minCT);
    let scoreAdjusted =
      feature.max_ent_score > 0
        ? Math.min(maxCT, feature.max_ent_score)
        : Math.max(minCT, feature.max_ent_score);
    scoreAdjusted = scoreAdjusted - minCT; // Shift upwards by minimum cut-off height (this ensures a positive adjusted score)

    // Set minimum height - Even 0 values will be drawn
    feature.height = Math.max(scoreAdjusted * barScale, barHeightMin);
    // Shift everything down by zero level
    // feature.y = feature.y + this.prop('zeroLevelHeight');

    // Cannot customise bump by view zoom level - fix here
    feature.y = this.prop("zeroLevelHeight");

    // Shift upwards
    feature.y = feature.y - feature.height;

    this.base(feature, featureContext, labelContext, scale);
  },
});

export const SequenceSpliceSiteView = Genoverse.Track.View.Sequence.extend({
  featureHeight: 30,
  featureMargin: { top: 40, right: 0, bottom: 30, left: 0 },
  bump: false,
  showSNV: true, // Display SNV overlay. Set to false to hide
  markerSize: 16, // Size of SNV 'marker'
  motifs: {
    "+": {
      // +ve strand
      donor: 3, // No. of BPs to shift to highlight motif (donor)
      acceptor: 18, // No. of BPs to shift to highlight motif (acceptor)
      score_donor: 0, // No. of BPs to shift to draw score bar (donor)
      score_acceptor: 2, // No. of BPs to shift to draw score bar (acceptor)
      lcase_donor: "right", // Set sequence letters to lower case on the right
      lcase_acceptor: "left", // Set sequence letters to lower case on the left
    },
    "-": {
      // -ve strand
      donor: 4, // No. of BPs to shift to highlight motif (donor)
      acceptor: 3, // No. of BPs to shift to highlight motif (acceptor)
      score_donor: 2, // No. of BPs to shift to draw score bar (donor)
      score_acceptor: 0, // No. of BPs to shift to draw score bar (acceptor)
      lcase_donor: "left", // Set sequence letters to lower case on the left
      lcase_acceptor: "right", // Set sequence letters to lower case on the right
    },
  },
  colors: {
    default: Colors.SILVER,
    A: Colors.GREEN,
    T: Colors.LIGHT_RED,
    G: Colors.LIGHT_YELLOW,
    C: Colors.DARK_CYAN,
    a: Colors.GREEN,
    t: Colors.LIGHT_RED,
    g: Colors.LIGHT_YELLOW,
    c: Colors.DARK_CYAN,
  },
  labelColors: {
    default: Colors.BLACK,
    A: Colors.WHITE,
    T: Colors.WHITE,
    C: Colors.WHITE,
    a: Colors.WHITE,
    t: Colors.WHITE,
    c: Colors.WHITE,
  },

  draw(features, featureContext, labelContext, scale) {
    const featureIds = {};
    const drawing = { seq: [], splicesite: [] };

    // 1. Separate splice site from sequence
    // Modify sequence letters (Set intron BPs to lowercase)

    features.forEach(feature => {
      const {
        start,
        snv_id,
        r_score,
        seq,
        motif_type,
        strand,
        alt_allele,
        snv_start,
      } = feature;
      const featureIdKey = start + ":" + snv_id + r_score;
      if (!featureIds[featureIdKey]) {
        if (seq) {
          const motif = this.prop("motifs")[strand];
          const spliceMotifBPShift = motif[motif_type];
          const spliceScoreShift = motif["score_" + motif_type];

          // Set sequence letters in introns to lowercase
          const shift = spliceMotifBPShift + spliceScoreShift;
          const sequenceLeft = seq.substring(0, shift);
          const sequenceRight = seq.substring(shift);

          const dirLCase = motif["lcase_" + motif_type];
          feature.seq =
            dirLCase === "left"
              ? sequenceLeft.toLowerCase() + sequenceRight
              : sequenceLeft + sequenceRight.toLowerCase();

          // Set SNV alt_allele to lowercase
          if (alt_allele && snv_start) {
            if (
              (snv_start - (start + shift) > 0 && dirLCase === "right") ||
              (snv_start - (start + shift) < 0 && dirLCase === "left")
            ) {
              feature.alt_allele = alt_allele.toLowerCase();
            }
          }
        }

        feature.sequence = motif_type ? feature.seq : feature.sequence;
        drawing[motif_type ? "splicesite" : "seq"].push(feature);

        featureIds[featureIdKey] = 1;
      }
      return feature;
    });

    // 2. Draw sequence
    this.base(drawing.splicesite, featureContext, labelContext, scale);
    // 3. Draw score bar
    this.drawScoreBar(drawing.splicesite, featureContext, scale);
    // 4. Highlight splice site motif region
    this.highlightSpliceSite(drawing.splicesite, featureContext, scale);

    // 5. Draw SNV
    if (this.prop("showSNV")) {
      this.drawSpliceSiteSNVs(drawing.splicesite, featureContext, scale);
    }
  },

  highlightSpliceSite(features, context, scale) {
    features.forEach(feature => {
      const position = feature.position[scale];

      const { highlightColor, outlineColor, strand, motif_type } = feature;
      const motif = this.prop("motifs")[strand];

      if (!highlightColor) {
        this.setHighlightColor(feature);
      }

      // Calculate position of motif to highlight
      const spliceMotifBPShift = motif[motif_type];
      const width = Math.max(scale, this.minScaledWidth);
      const motifBPSize = 2;
      const xPos = position.X + width * spliceMotifBPShift;

      context.fillStyle = highlightColor;
      context.strokeStyle = outlineColor;
      context.lineWidth = 2;

      // To do: Simplify with rect
      context.beginPath();
      context.moveTo(xPos, position.Y);
      context.lineTo(xPos, position.Y + this.featureHeight);
      context.lineTo(
        xPos + motifBPSize * width,
        position.Y + this.featureHeight
      );
      context.lineTo(xPos + motifBPSize * width, position.Y);
      context.closePath();
      context.stroke();

      context.lineWidth = 1;
      context.globalAlpha = 0.5;
      context.fill();
      context.globalAlpha = 1;
    });
  },

  drawScoreBar(features, context, scale) {
    features.forEach(feature => {
      // Cut-off points
      const maxCT = 20; // Maximum cut-off point
      const minCT = -10; // Minimum cut-off point

      // Calculate barHeight
      const barHeightMax = 20; // Maximum bar height
      const barHeightMin = 2; // Minimum bar height

      const barScale = barHeightMax / (maxCT - minCT);
      const {
        max_ent_score,
        r_score,
        snv_start,
        start,
        end,
        denovo,
        strand,
        outlineColor,
      } = feature;
      let scoreAdjusted =
        max_ent_score > 0
          ? Math.min(maxCT, max_ent_score)
          : Math.max(minCT, max_ent_score);
      scoreAdjusted = scoreAdjusted - minCT; // Shift upwards by minimum cut-off height
      const barHeight = Math.max(scoreAdjusted * barScale, barHeightMin); // Set minimum height - Even 0 values will be drawn

      const position = feature.position[scale];

      // Calculate position of motif to highlight
      const width = Math.max(scale, this.minScaledWidth);
      const motif = this.prop("motifs")[strand];
      const spliceMotifBPShift = motif[feature.motif_type];
      const spliceScoreShift = motif["score_" + feature.motif_type];
      const xPos = position.X + width * (spliceMotifBPShift + spliceScoreShift);

      const isSNVLeft =
        r_score &&
        snv_start - (start + spliceMotifBPShift + spliceScoreShift) <= 0;

      // Calculate score bar width
      let barWidth = width / 4;
      const barWidthMax = 4;
      barWidth = Math.max(1, barWidth); // At least 1 pixel wide
      barWidth = Math.min(barWidthMax, barWidth); // Maximum width

      // yPos: Bottom y position of score bar
      const yBuffer = 4; // Space between sequence and score bar
      const yPos = position.Y - yBuffer;

      // Draw score bar
      context.fillStyle = outlineColor;

      if (!denovo) {
        context.fillRect(xPos - barWidth / 2, yPos, barWidth, -barHeight); // Place rectangle in the boundary with the next bp
      }

      // Draw score label
      const drawScoreLabel = (end - start) * scale > this.featureHeight;
      let scoreLabelXPos;
      let scoreLabelYPos;

      if (drawScoreLabel) {
        const xBuffer = 4;
        scoreLabelXPos = isSNVLeft
          ? xPos + xBuffer + barWidth / 2
          : xPos - xBuffer - barWidth / 2;
        scoreLabelYPos = yPos - barHeight; // Level with score bar
        const scoreLabelHeight = 13;
        scoreLabelYPos = Math.min(yPos - scoreLabelHeight, scoreLabelYPos); // Ensure label does not overlap with bottom feature

        context.textAlign = isSNVLeft ? "left" : "right";

        if (!denovo) {
          context.fillText(max_ent_score, scoreLabelXPos, scoreLabelYPos);
        }
      }

      // If mutated score available, display alongside original score
      if (r_score) {
        // Draw SNV score bar
        let scoreAdjustedSNV =
          r_score > 0 ? Math.min(maxCT, r_score) : Math.max(minCT, r_score);
        scoreAdjustedSNV = scoreAdjustedSNV - minCT; // Shift upwards by minimum cut-off height
        const barHeightSNV = scoreAdjustedSNV * barScale;
        const xPosSNV = isSNVLeft
          ? xPos - (barWidth * 3) / 2
          : xPos + barWidth / 2;

        context.fillStyle = Colors.MINE_SHAFT;
        context.fillRect(xPosSNV, yPos, barWidth, -barHeightSNV); // Place rectangle in the boundary with the next bp

        if (drawScoreLabel) {
          scoreLabelXPos = isSNVLeft
            ? xPosSNV - barWidth / 2
            : xPosSNV + (barWidth * 3) / 2;
          context.textAlign = isSNVLeft ? "right" : "left";
          context.fillText(r_score, scoreLabelXPos, scoreLabelYPos);
        }
      }
    });
  },

  setHighlightColor(feature) {
    const { outlineColor } = feature;
    if (outlineColor) {
      feature.highlightColor = outlineColor;
    } else {
      feature.highlightColor = Colors.LIGHT_GREEN;
    }
  },

  /*** SNVs ***/
  drawSpliceSiteSNVs(features, context, scale) {
    context.textBaseline = "middle";
    context.textAlign = "left";

    if (!this.labelWidth[this.widestLabel]) {
      this.labelWidth[this.widestLabel] =
        Math.ceil(this.context.measureText(this.widestLabel).width) + 1;
    }

    const width = Math.max(scale, this.minScaledWidth);

    features.forEach(feature => {
      const { snv_id, denovo } = feature;
      if (snv_id) {
        const snvFeature = feature;
        const { alt_allele } = snvFeature;
        snvFeature.sequence_alt = alt_allele;
        snvFeature.length = alt_allele.length;

        if (denovo) {
          this.markSNVAlt(snvFeature, context, scale);
        } else {
          this.drawSNVSequence(snvFeature, context, scale, width);
          this.highlightSNV(snvFeature, context, scale);
        }
      }
    });
  },

  drawSNVSequence(feature, context, scale, width) {
    const drawLabels = this.labelWidth[this.widestLabel] < width - 1;
    let start, bp;

    [...Array(feature.sequence_alt.length)].forEach((e, i) => {
      start = feature.position[scale].X + i * scale;
      start = start + (feature.snv_start - feature.start) * scale; // Shift to SNV position

      if (start >= -scale && start <= context.canvas.width) {
        bp = feature.sequence_alt.charAt(i);
        const yPos = feature.position[scale].Y + 2 * this.featureHeight;

        context.fillStyle = this.colors[bp] || this.colors.default;
        context.fillRect(start, yPos, width, this.featureHeight);

        if (!this.labelWidth[bp]) {
          this.labelWidth[bp] = Math.ceil(context.measureText(bp).width) + 1;
        }

        if (drawLabels) {
          context.fillStyle = this.labelColors[bp] || this.labelColors.default;
          context.fillText(
            bp,
            start + (width - this.labelWidth[bp]) / 2,
            yPos + this.labelYOffset
          );
        }
      }
    });
  },

  markSNVAlt(feature, context, scale) {
    // Mark SNV alt allele only
    const position = feature.position[scale];
    const posX = position.X + (feature.snv_start - feature.start) * scale;

    context.fillStyle = feature.highlightColor;
    context.strokeStyle = feature.outlineColor;
    context.lineWidth = 2;

    //Highlight
    context.globalAlpha = 0.5;
    context.fillRect(
      posX,
      position.Y,
      feature.alt_allele.length * scale,
      this.featureHeight
    );

    //Outline
    context.globalAlpha = 1;
    context.strokeRect(
      posX,
      position.Y,
      feature.alt_allele.length * scale,
      this.featureHeight
    );

    //Draw arrow to mark current SNV
    if (parseInt(feature.snvId) === parseInt(feature.patient_snv_id)) {
      const posPointerX = posX + scale / 2;
      this.drawMapMarker(
        context,
        posPointerX,
        position.Y,
        this.prop("markerSize")
      );
    }
  },

  highlightSNV(feature, context, scale) {
    const position = feature.position[scale];

    const start = 0;
    const posX =
      position.X + (feature.snv_start - feature.start + start) * scale;
    const positionX = [
      posX,
      posX,
      posX + (feature.sequence_alt.length - start) * scale,
      posX + (feature.sequence_alt.length - start) * scale,
      posX + (feature.ref_allele.length - start) * scale,
      posX + (feature.ref_allele.length - start) * scale,
    ];
    const positionY = [
      position.Y,
      position.Y + 3 * this.featureHeight,
      position.Y + 3 * this.featureHeight,
      position.Y + 2 * this.featureHeight,
      position.Y + this.featureHeight,
      position.Y,
    ];

    context.fillStyle = feature.highlightColor;
    context.strokeStyle = feature.outlineColor;
    context.lineWidth = 2;

    context.beginPath();

    context.moveTo(positionX[0], positionY[0]);

    for (let i = 1; i < positionX.length; i++) {
      context.lineTo(positionX[i], positionY[i]);
    }

    context.closePath();

    context.stroke();

    context.lineWidth = 1;
    context.globalAlpha = 0.5;
    context.fill();
    context.globalAlpha = 1;

    //Draw arrow to mark current SNV
    if (parseInt(feature.snvId) === parseInt(feature.patient_snv_id)) {
      const posPointerX = positionX[0] + scale / 2;
      this.drawMapMarker(
        context,
        posPointerX,
        positionY[0],
        this.prop("markerSize")
      );
    }
  },

  drawMapMarker(context, x, y, markerSize) {
    context.save();
    context.font = markerSize + "px Glyphicons Halflings";
    context.fillText(
      String.fromCharCode(57442),
      x - markerSize / 2,
      y - markerSize / 2
    );
    context.restore();
  },
});

export const Track = Genoverse.Track.extend({
  bump: true,
  height: 35,
  featureHeight: 15,
  colorMap: {
    acceptor: { color: Colors.RED, labelColor: Colors.WHITE }, //red
    donor: { color: Colors.BLUE, labelColor: Colors.BLACK }, //blue
  },
  controller: Controller,
  model: Model,

  1: {
    // Show sequence
    view: SequenceSpliceSiteView.extend({}),
  },
  100: {
    // Show sequence but hide SNV
    view: SequenceSpliceSiteView.extend({ showSNV: false }),
  },
  1000: {
    // Simplified track with score bars of varying heights
    view: SpliceSiteView,
  },
});
