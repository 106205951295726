import PropTypes from "prop-types";
import React, { memo } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { ReduxFormField } from "modules/forms/components";
import { getCategoriesValues } from "modules/patientMetadata/selectors";

import {
  ADD_METADATA_FIELD,
  DELETE_METADATA_FIELD,
  EDIT_METADATA_FIELD,
  FIELD_TYPES,
} from "../constants";

const MetadataFieldForm = memo(({ mode, categories }) => (
  <div className="form-horizontal">
    <Field
      data-testid="fieldName"
      label="Name *"
      disabled={mode === DELETE_METADATA_FIELD}
      component={ReduxFormField}
      type="text"
      name="fieldName"
      narrow
    />
    {mode === ADD_METADATA_FIELD && (
      <Field
        label="Code *"
        data-testid="fieldCode"
        component={ReduxFormField}
        type="text"
        name="fieldCode"
        narrow
      />
    )}
    <Field
      label="Required?"
      className="patient_metadata-form-editor-toggle"
      disabled={mode === DELETE_METADATA_FIELD}
      data-testid="required"
      component={ReduxFormField}
      type="toggle"
      name="required"
      narrow
    />
    <Field
      label="Visible in list?"
      className="patient_metadata-form-editor-toggle"
      disabled={mode === DELETE_METADATA_FIELD}
      data-testid="visibleInList"
      component={ReduxFormField}
      type="toggle"
      name="visibleInList"
      narrow
    />
    <Field
      name="fieldType"
      label="Type"
      component={ReduxFormField}
      disabled={mode !== ADD_METADATA_FIELD}
      type="dropdown"
      options={FIELD_TYPES}
      narrow
    />
    <Field
      name="categoryId"
      label="Category"
      component={ReduxFormField}
      disabled={mode === DELETE_METADATA_FIELD}
      type="dropdown"
      options={categories}
      emptyPlaceholder="(Uncategorised)"
      canBeEmpty
      narrow
    />
  </div>
));

MetadataFieldForm.propTypes = {
  mode: PropTypes.oneOf([
    ADD_METADATA_FIELD,
    EDIT_METADATA_FIELD,
    DELETE_METADATA_FIELD,
  ]),
};

const mapStateToProps = state => ({
  categories: getCategoriesValues(state),
});

export default connect(mapStateToProps, null)(MetadataFieldForm);
