import PropTypes from "prop-types";
import React, { memo } from "react";

const Audit = memo(({ audit }) =>
  audit ? (
    <div className="project-irs-table-sub-component">
      <h4 className="project-irs-table-sub-component-header">Errors:</h4>
      <table className="table table-bordered table-striped">
        <tbody>
          <tr>
            <td className="project-irs-table-sub-component-column">{audit}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null
);

Audit.propTypes = {
  audit: PropTypes.string,
};

export default Audit;
