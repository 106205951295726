import {
  DATA_TYPE_BOOLEAN,
  DATA_TYPE_NUMBER,
  DATA_TYPE_STRING,
  ERROR_ACCESS,
  ERROR_KEY,
  ERROR_READ,
  ERROR_WRITE,
  MEMORY_STRATEGY_PERMANENT,
  MEMORY_STRATEGY_TEMPORARY,
} from "./constants";

export const StoreType = {
  permanent: MEMORY_STRATEGY_PERMANENT,
  temporary: MEMORY_STRATEGY_TEMPORARY,
};

export const DataType = {
  number: DATA_TYPE_NUMBER,
  boolean: DATA_TYPE_BOOLEAN,
  string: DATA_TYPE_STRING,
};

export const ErrorMessages = {
  key: ERROR_KEY,
  access: ERROR_ACCESS,
  read: ERROR_READ,
  write: ERROR_WRITE,
};
