import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Icon from "../../elements/base/Icon";

import Tip from "./Tip";

export default class Tutorial extends PureComponent {
  static propTypes = {
    tips: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        // ref: should be a node ref
        position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
      })
    ).isRequired,
    handleDisableSingleTip: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    handleNeverShowTips: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  };

  static defaultProps = {
    handleDisableSingleTip: false,
    handleNeverShowTips: false,
    active: 0,
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      active: 0,
      tips: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { active, tips } = nextProps;

    if (active === false) this.handleClose();

    if (tips) {
      this.setState({
        tips,
      });
    }
  }

  disabledSingleTip() {
    const { handleDisableSingleTip } = this.props;
    const { active } = this.state;
    if (handleDisableSingleTip)
      handleDisableSingleTip(active, this.handleClose);
  }

  neverShowTips() {
    const { handleNeverShowTips } = this.props;
    if (handleNeverShowTips) handleNeverShowTips();
    this.handleClose();
  }

  handlePreviousClick() {
    this.setState(prevState => ({
      active: prevState.active - 1,
    }));
  }

  handleNextClick() {
    this.setState(prevState => ({
      active: prevState.active + 1,
    }));
  }

  handleClose() {
    this.setState({
      active: -1,
    });
  }

  changeActive(e) {
    this.setState({
      active: e.target.dataset.index,
    });
  }

  render() {
    const {
      tips: unussedTips,
      handleDisableSingleTip,
      handleNeverShowTips,
      ...otherProps
    } = this.props;
    const { active, tips } = this.state;
    return (
      <div>
        {tips.map((item, index) => {
          const { children, name, ref, ...tipOtherProps } = item;

          if (index !== 0) tipOtherProps.showPrevious = true;
          if (index !== tips.length - 1) tipOtherProps.showNext = true;

          tipOtherProps.handlePreviousClick = this.handlePreviousClick;
          tipOtherProps.handleNextClick = this.handleNextClick;
          tipOtherProps.handleClose = this.handleClose;
          tipOtherProps.handleDisableSingleTip = this.disabledSingleTip;
          tipOtherProps.handleNeverShowTips = this.neverShowTips;

          return (
            <Tip
              key={`tip-${name}`}
              active={index === active}
              locationRef={ref}
              {...tipOtherProps}
            >
              {children}
            </Tip>
          );
        })}
        <div
          className={`${active > -1 ? "tutorial" : ""} tutorial-control`}
          {...otherProps}
        >
          <ul className="tutorial-control-progress">
            {tips.map((item, index) => (
              <li
                key={`progress-${item.name}`}
                data-index={index}
                onClick={this.changeActive}
                className={`${
                  active === index ? "is-active" : ""
                } tutorial-control-progress-item`}
              />
            ))}
          </ul>
          {active !== 0 && (
            <span
              onClick={this.handlePreviousClick}
              className="btn btn--small tip-previous"
            >
              <Icon type="left" />
              Previous tip
            </span>
          )}
          {active !== tips.length - 1 && (
            <span
              onClick={this.handleNextClick}
              className="btn btn--small tip-next"
            >
              Next tip
              <Icon type="right" />
            </span>
          )}
          <span
            id="tutorial-close"
            onClick={this.handleClose}
            className="btn btn--small"
          >
            <Icon type="cross" />
          </span>
        </div>
      </div>
    );
  }
}
