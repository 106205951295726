import PropTypes from "prop-types";
import React from "react";

import { ConfirmationModal } from "pattern-library";

export const AcmgDecisionModal = ({
  showConfirmationModal,
  yesClickHandler,
  closeClickHandler,
  locked,
  warnings,
}) => {
  const confirmationTitle =
    locked === false && warnings.length > 0
      ? "No evidence recorded against categories"
      : "Confirmation";

  const getConfirmationBody = () => {
    if (locked === false && warnings.length > 0) {
      return (
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              There is no evidence recorded for the following categories
            </h3>
          </div>
          <ul className="list-group">
            {warnings.map(warning => (
              <li
                key={warning.name}
                className="list-group-item list-group-item-warning"
              >
                {warning.name}
              </li>
            ))}
          </ul>
          <div className="panel-footer">
            Are you sure you still want to lock your decision?
          </div>
        </div>
      );
    }
    if (locked === true) {
      return <p>Are you sure you want to unlock this decision?</p>;
    }
    return <p>Are you sure you want to lock this decision?</p>;
  };

  return (
    <ConfirmationModal
      confirmationTitle={confirmationTitle}
      confirmationText={getConfirmationBody()}
      showConfirmationModal={showConfirmationModal}
      yesClickHandler={yesClickHandler}
      closeClickHandler={closeClickHandler}
      isWarningModal
    />
  );
};

AcmgDecisionModal.propTypes = {
  showConfirmationModal: PropTypes.bool.isRequired,
  yesClickHandler: PropTypes.func.isRequired,
  closeClickHandler: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  warnings: PropTypes.array,
};

export default AcmgDecisionModal;
