// @flow
import { camelize } from "humps";
import { contains } from "ramda";
import { createSelector } from "redux-orm";

import { getCurrentPatientDecisions } from "modules/decisions/selectors";
import { getCurrentProject } from "modules/project/selectors";
import { arrayToKeyValueObject } from "modules/utils";

import { getGeneWrappersByGeneId } from "../../entities";
import { activeVariantPanelGene, getActiveGenes } from "../../utils";
import orm from "../index";

/**
 * The variant transcript to display by default is identified by checking the following data:
 * a decision transcript if exists,
 * otherwise the preferred transcripts from gene panels
 * otherwise - a default transcript for a gene,
 * if no transcript is found then the first transcript if available is chosen
 * @param variantTranscripts
 * @param metadata
 */
const findDefaultVisibleTranscript = (variantTranscripts, metadata) => {
  const { decisionTranscript, preferredTranscripts, geneDefaultTranscript } =
    metadata;

  if (decisionTranscript) {
    return decisionTranscript;
  }

  const preferredTranscriptsEntries = Object.entries(preferredTranscripts);

  if (preferredTranscriptsEntries.length) {
    return preferredTranscriptsEntries[0][1];
  }

  if (geneDefaultTranscript) {
    return geneDefaultTranscript;
  }

  if (variantTranscripts.length) {
    return variantTranscripts[0];
  }

  return null;
};

const processTranscripts = (
  storedVariantTranscripts,
  defaultTranscriptId,
  preferredTranscriptIds,
  pathogenicVariantDecisions,
  geneTranscriptsById
) => {
  const variantTranscripts = [];
  let decisionTranscript = null;
  const preferredTranscripts = {};
  let geneDefaultTranscript = null;
  storedVariantTranscripts.forEach(vt => {
    // exclude transcripts associated with the current variant but not associated with the current gene
    const { transcriptId } = vt;
    const geneTranscript = geneTranscriptsById[transcriptId];
    if (!geneTranscript) {
      return;
    }

    const variantTranscript = { ...geneTranscript, ...vt };
    const pathogenicDecisionTranscript = pathogenicVariantDecisions.find(
      ({ transcriptId: decisionTranscriptId }) =>
        variantTranscript.transcriptId === decisionTranscriptId
    );

    if (pathogenicDecisionTranscript) {
      variantTranscript.isDecisionTranscript = true;
      decisionTranscript = variantTranscript;
    }

    if (preferredTranscriptIds.includes(transcriptId)) {
      variantTranscript.isPreferred = true;
      preferredTranscripts[transcriptId] = variantTranscript;
    }

    if (transcriptId === defaultTranscriptId) {
      variantTranscript.isGeneDefaultTranscriptId = true;
      geneDefaultTranscript = variantTranscript;
    }
    variantTranscripts.push(variantTranscript);
  });

  const defaultVisibleTranscript: any = findDefaultVisibleTranscript(
    variantTranscripts,
    { decisionTranscript, preferredTranscripts, geneDefaultTranscript }
  );
  if (defaultVisibleTranscript) {
    defaultVisibleTranscript.isDefaultVisibleTranscript = true;
  }

  return {
    variantTranscripts,
    defaultVisibleTranscript,
  };
};

const getPreferredTranscriptIds = (geneId, geneWrappersByGeneId) => {
  // Preferred transcript are set on gene panels - each gene in the panel can
  // have one or none preferred transcrips. The gene's preferred transcripts
  // are all the geneToGenePanel records with preferred transcripts set.
  const preferredTranscriptIds: Array<number> = [];
  const geneToGenePanels = geneWrappersByGeneId[geneId] || [];
  geneToGenePanels.forEach(({ geneId: _geneId, transcriptId }) => {
    if (_geneId === geneId && transcriptId) {
      preferredTranscriptIds.push(transcriptId);
    }
  });
  return preferredTranscriptIds;
};

const mapCuration = (curation, curatedVariantsLists) => {
  if (!curatedVariantsLists) {
    return {
      ...curation,
      curatedVariantList: {},
    };
  }

  const curatedVariantList = curatedVariantsLists.find(
    cvl => cvl.curatedVariantListId === curation.curatedVariantListId
  );
  return {
    ...curation,
    curatedVariantList,
  };
};

const processVariantDecisions = (
  decisionGroups: DecisionGroups,
  variantId: number
): {
  variantPathogenicDecisions: Array<Decision>,
  decisionsByTypes: { [type: DecisionType]: Decision },
} => {
  const decisions: Array<Decision> =
    decisionGroups.decisionsByVariantId[variantId] || [];

  const variantPathogenicDecisions: Array<Decision> = [];
  const decisionsByTypes: { [type: DecisionType]: Decision } = {};

  decisions.forEach((decision: Decision) => {
    const { decisionType } = decision;
    //$FlowFixMe
    decisionsByTypes[camelize(decisionType)] = decision;

    if (decisionType === "pathogenicity") {
      variantPathogenicDecisions.push(decision);
    }
  });

  return { variantPathogenicDecisions, decisionsByTypes };
};

export const processGene = (
  gene: Gene,
  geneWrappersByGeneId: {
    [geneId: number]: Array<{ geneId: number, transcriptId: number }>,
  },
  decisionGroups: DecisionGroups,
  curatedVariantsLists: Array<CuratedVariantList>
) => {
  const { geneId } = gene;
  const preferredTranscriptIds = getPreferredTranscriptIds(
    geneId,
    geneWrappersByGeneId
  );

  const transcripts = gene.transcripts || [];
  const geneTranscriptsById = arrayToKeyValueObject(
    transcripts,
    "transcriptId"
  );

  const variants = (gene.variants || []).map((variant: Variant) => {
    const { variantPathogenicDecisions, decisionsByTypes } =
      processVariantDecisions(decisionGroups, variant.variantId);

    const variantTranscripts = variant.variantTranscript || [];

    const {
      variantTranscripts: expandedTranscripts,
      defaultVisibleTranscript,
    } = processTranscripts(
      variantTranscripts,
      gene.defaultTranscriptId,
      preferredTranscriptIds,
      variantPathogenicDecisions,
      geneTranscriptsById
    );
    return {
      ...variant,
      ...decisionsByTypes,
      variantTranscripts,
      curations: variant.curations.map(curation =>
        mapCuration(curation, curatedVariantsLists)
      ),
      expandedTranscripts,
      defaultVisibleTranscript,
    };
  });

  const [{ pliScore, loeufScore, missenseZScore } = {}] = variants;

  return {
    //$FlowFixMe //TODO: remove this line when Gene ORM is deleted
    ...gene.ref,
    variants,
    transcripts,
    pliScore,
    loeufScore,
    missenseZScore,
  };
};

// returns only active (selected by the user on the variants table or fetched through API &
// set in the store) genes with its transcripts & variants with added fields
// adds the decision field to the variants & the first decision of each type in decisionTypes
export const getAllGenes = createSelector(
  orm,
  getActiveGenes,
  getGeneWrappersByGeneId,
  getCurrentPatientDecisions,
  getCurrentProject, // this is the temp import instead of CVLs to avoid issues importing modules into data-layer
  (session, ids, geneWrappersByGeneId, decisions, project) =>
    session.Gene.filter(({ geneId }) => contains(geneId, ids))
      .toModelArray()
      .map(gene =>
        processGene(
          gene,
          geneWrappersByGeneId,
          decisions,
          project.curatedVariantLists
        )
      )
);

export const getSingleGene = createSelector(
  orm,
  (state, id) => id,
  getGeneWrappersByGeneId,
  getCurrentPatientDecisions,
  getCurrentProject,
  (session, id, geneWrappersByGeneId, decisions, project) => {
    const gene = session.Gene.withId(id);
    if (!gene) {
      return null;
    }

    return processGene(
      gene,
      geneWrappersByGeneId,
      decisions,
      project.curatedVariantLists
    );
  }
);

export const getSelectedORMGene = createSelector(
  orm,
  activeVariantPanelGene,
  getGeneWrappersByGeneId,
  getCurrentPatientDecisions,
  getCurrentProject,
  (session, selectedGeneId, geneWrappersByGeneId, decisions, project) => {
    const ormGene = session.Gene.withId(selectedGeneId);
    if (!ormGene) {
      return null;
    }

    return processGene(
      ormGene,
      geneWrappersByGeneId,
      decisions,
      project.curatedVariantLists
    );
  }
);
