import PropTypes from "prop-types";

export const CheckboxOptionProp = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  archived: PropTypes.bool,
  // Optional array of any additional values to associate with the checkbox in question.
  // Primarily makes sense in a checkbox group - where one checkbox can count for multiple values
  values: PropTypes.array,
});

export type CheckboxOption = {
  key: string | number;
  label: JSX.Element | string;
  archived?: boolean;
  // Optional array of any additional values to associate with the checkbox in question.
  // Primarily makes sense in a checkbox group - where one checkbox can count for multiple values
  values?: any[];
};

export const CheckboxOptionArrayProp = PropTypes.arrayOf(CheckboxOptionProp);
