import { FC, memo } from "react";

import { ButtonGroup } from "pattern-library";

import catalystApi from "../../../api/catalyst-api";
import { REVIEW_COMPLETE } from "../constants";

import Action from "./Action";
import PatientDataDownload from "./PatientDataDownload";
import PatientGeneratedReportDownload from "./PatientGeneratedReportDownload";
import Status from "./Status";

const { useGetPatientStatusQuery } = catalystApi;

interface Props {
  skip?: Array<string>;
  patientId: number;
  reportId?: string;
}

const Actions: FC<Props> = ({ patientId, reportId, skip = [] }) => {
  const { status, validActions, isLoading } = useGetPatientStatusQuery(
    { patientId },
    {
      selectFromResult: ({
        data: { status, validActions = [] } = {},
        isLoading,
      }) => ({
        status,
        validActions,
        isLoading,
      }),
    }
  );

  return (
    <div className="row oncology-report__actions">
      <ButtonGroup className="col-md-12" size="sm" vertical={false}>
        <Status key="status" patientId={patientId} />
        <PatientDataDownload disabled={isLoading} patientId={patientId} />
        {status === REVIEW_COMPLETE && reportId && (
          <PatientGeneratedReportDownload
            patientId={patientId}
            reportId={reportId}
          />
        )}
        <>
          {validActions.map(action => {
            if (skip.indexOf(action) !== -1) {
              return null;
            }
            return <Action disabled={isLoading} key={action} action={action} />;
          })}
        </>
      </ButtonGroup>
    </div>
  );
};

export default memo<FC<Props>>(Actions);
