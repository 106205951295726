import convertStringArrayToFormField from "../convertStringArrayToFormField";

const options = ["unknown", "1st trimester", "2nd trimester", "3rd trimester"];

const months = Array.from(new Array(23), (x, i) => i);
const years = Array.from(new Array(99), (x, i) => i + 2);

months.forEach(i => {
  options.push(`${i} months`);
});
years.forEach(i => {
  options.push(`${i} years`);
});

options.push("100+ years");

export const ageFormFields = convertStringArrayToFormField(options);

export default options;
