import React, { useState, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { isDirty } from "redux-form";

import {
  Button,
  Icon,
  ComposableTabs,
  ConfirmationModal,
  Tab,
} from "pattern-library";

import { resetDisplayMode } from "../actions";
import {
  DISPLAY_MODE_ADD_EXISTING,
  DISPLAY_MODE_CVL,
  CURATED_LIST_FORM,
} from "../constants";
import {
  getDisplayMode,
  getSelectedCuratedList,
  isCurrentUserSuperAdmin,
  isSuperAdminOrProjectAdmin,
} from "../selectors";
import { isReferenceList, isProjectOwnList } from "../utils";

import CVLEditForm from "./CVLEditForm";
import CVLExistingLists from "./CVLExistingLists";
import CVLVariants from "./CVLVariants";
import CVLViewForm from "./CVLViewForm";
import CVLAudit from "./audit/CVLAudit";

const getCVLForm = (
  selectedCVL,
  isCurrentUserSuperAdmin,
  isSuperAdminOrProjectAdmin
) => {
  if (!selectedCVL.curatedVariantListId) {
    return <CVLEditForm cvl={selectedCVL} />;
  }
  const { variantCount } = selectedCVL;
  const canEditOverview =
    isProjectOwnList(selectedCVL) &&
    (isCurrentUserSuperAdmin ||
      (isSuperAdminOrProjectAdmin && !isReferenceList(selectedCVL)));
  const canViewAudit =
    (isProjectOwnList(selectedCVL) || isCurrentUserSuperAdmin) &&
    !isReferenceList(selectedCVL);
  const Component = canEditOverview ? CVLEditForm : CVLViewForm;
  return (
    <ComposableTabs>
      <Tab name="Overview">
        <Component cvl={selectedCVL} />
      </Tab>
      <Tab name="Variants" label={variantCount}>
        <CVLVariants cvl={selectedCVL} />
      </Tab>
      <Tab name="Audit" isHidden={!canViewAudit}>
        <CVLAudit cvl={selectedCVL} />
      </Tab>
    </ComposableTabs>
  );
};

export const CVLView = ({
  displayMode,
  formIsDirty,
  resetDisplayMode,
  selectedCVL,
  isCurrentUserSuperAdmin,
  isSuperAdminOrProjectAdmin,
}: PropsFromRedux) => {
  const [confirmation, setConfirmation] = useState(false);

  const handleBack = useCallback(() => {
    setConfirmation(formIsDirty);

    if (!formIsDirty) {
      return resetDisplayMode();
    }
  }, [resetDisplayMode, formIsDirty]);

  const backToListButton = (
    <Button
      type="button"
      className="btn btn-sm btn-icon-text"
      onClick={handleBack}
    >
      <Icon type="left" />
      Back to all curated variant lists
    </Button>
  );

  return (
    <>
      <div className="curated-lists-view">
        {backToListButton}
        {displayMode === DISPLAY_MODE_CVL &&
          getCVLForm(
            selectedCVL,
            isCurrentUserSuperAdmin,
            isSuperAdminOrProjectAdmin
          )}
        {displayMode === DISPLAY_MODE_ADD_EXISTING && <CVLExistingLists />}
      </div>
      <ConfirmationModal
        confirmationTitle="Unsaved changes to curated variant list"
        confirmationText="You have unsaved changes to your curated variant list. Do you want to discard these changes?"
        confirmText="Discard changes"
        closeText="Continue editing"
        showConfirmationModal={confirmation}
        closeClickHandler={() => {
          setConfirmation(false);
        }}
        yesClickHandler={resetDisplayMode}
      />
    </>
  );
};

const mapStateToProps = state => ({
  displayMode: getDisplayMode(state),
  formIsDirty: isDirty(CURATED_LIST_FORM)(state),
  selectedCVL: getSelectedCuratedList(state),
  isCurrentUserSuperAdmin: isCurrentUserSuperAdmin(state),
  isSuperAdminOrProjectAdmin: isSuperAdminOrProjectAdmin(state),
});

const mapDispatchToProps = {
  resetDisplayMode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLView);
