import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import * as projectSelectors from "modules/project/selectors";
import { getProjectCurrentUser } from "modules/project/selectors";

import { isCurrentUserAdmin } from "../auth/selectors";

import { MODULE_NAME } from "./constants";
import type {
  CvlOptions,
  DisplayMode,
  ProjectCuratedVariantList,
  State,
} from "./types";
import {
  getCvlTypeLabel,
  isInheritedCVL,
  isProjectOwnList,
  isReferenceList,
  isSNVType,
} from "./utils";

export const modulePath: (...path: Array<string>) => any = (...items: any) =>
  path([MODULE_NAME, ...items]);

export const getProjectId: (state: State) => number = modulePath("projectId");

export const isDataLoading: (state: State) => boolean = modulePath("isLoading");

export const variantsLoading: (state: State) => boolean =
  modulePath("variantsLoading");

export const needUpdate: (state: State) => boolean = modulePath("needUpdate");

export const getCuratedLists: (
  state: State
) => Array<ProjectCuratedVariantList> = modulePath("curatedLists");

export const existingListsAvailable: (state: State) => boolean = modulePath(
  "existingListsAvailable"
);

export const getExistingCuratedLists: (
  state: State
) => Array<ProjectCuratedVariantList> = modulePath("existingCuratedLists");

export const getResetDisplayMode: (state: State) => number =
  modulePath("resetDisplayMode");

export const getExistingCuratedList: (
  state: State,
  id: string
) => ProjectCuratedVariantList | undefined = createSelector(
  (state: State, id: string) => id,
  getExistingCuratedLists,
  (id: string, lists: Array<ProjectCuratedVariantList>) =>
    lists.find(
      (cvl: ProjectCuratedVariantList) => cvl.curatedVariantListId === id
    )
);

export const getSelectedCuratedList: (
  state: State
) => ProjectCuratedVariantList | null = modulePath("selectedList");

export const getSelectedVariants: (state: State) => CVLVariants | null =
  modulePath("selectedVariants");

export const getDisplayMode: (state: State) => DisplayMode =
  modulePath("displayMode");

export const getCvlOptions: (state: State) => CvlOptions =
  modulePath("cvlOptions");

export const getDisplayExportModal: (state: State) => boolean =
  modulePath("displayExportModal");

export const getExportInvalidData: (state: State) => boolean = modulePath(
  "exportHasInvalidData"
);

export const isExportLoading: (state: State) => boolean =
  modulePath("exportLoading");

export const getExportCVLId: (state: State) => string =
  modulePath("exportCVLId");

export const getSelectedCVLAudit: (state: State) => CVLAudit | null =
  modulePath("cvlAudit");

export const isCVLAuditLoading: (state: State) => boolean =
  modulePath("isCVLAuditLoading");

export const isCurrentUserSuperAdmin = createSelector(
  isCurrentUserAdmin,
  isSuperAdmin => isSuperAdmin
);

export const isSuperAdminOrProjectAdmin = createSelector(
  getProjectCurrentUser,
  (projectCurrentUser: ProjectCurrentUser) =>
    projectCurrentUser ? projectCurrentUser.canAdminProject : false
);

export const canRemoveFromProject = createSelector(
  isSuperAdminOrProjectAdmin,
  isAdmin => isAdmin
);

export const canRemoveFailedCVL = createSelector(
  isSuperAdminOrProjectAdmin,
  isAdmin => isAdmin
);

export const canAddNewCVL = createSelector(
  isSuperAdminOrProjectAdmin,
  isAdmin => isAdmin
);

export const canExportCVL = createSelector(
  isSuperAdminOrProjectAdmin,
  (state, cvl) => cvl,
  (isAdmin, cvl) => !isReferenceList(cvl) && isSNVType(cvl) && isAdmin
);

export const getExistingListsTypesOptions = createSelector(
  getCvlOptions,
  (cvlOptions: CvlOptions) => {
    const { listTypes = [] } = cvlOptions;
    return listTypes.reduce((acc, cur) => {
      const displayName = getCvlTypeLabel(cur.value);
      acc[cur.value] = displayName;
      return acc;
    }, {});
  }
);

export const getProjectName = createSelector(
  (state, projectId) => projectSelectors.getSingleProject(state, projectId),
  project => {
    const { code = "" } = project || {};
    return code;
  }
);

export const getProjectOwnCVLs = createSelector(
  getCuratedLists,
  (
    allCVLs: Array<ProjectCuratedVariantList>
  ): Array<ProjectCuratedVariantList> => allCVLs.filter(isProjectOwnList)
);

export const getInheritedCVLs = createSelector(
  getCuratedLists,
  (allCVLs: Array<ProjectCuratedVariantList>) => allCVLs.filter(isInheritedCVL)
);
