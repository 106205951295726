import { set } from "dot-prop-immutable";

import { IDLE_STATUS } from "../../common/constants";
import {
  getDefaultPresetId,
  getUpdatedPresetsDataState,
  getUpdatedPresetsUIState,
} from "../utils/filter-presets";

import { Action } from "./actions";
import * as constants from "./constants";
import { State, SVPresetsInfo } from "./types";

export const initialState: State = {
  data: {
    presets: [],
    presetDictionaries: {},
  },
  ui: {
    presetsRequestStatus: IDLE_STATUS,
    selectedPresetId: null,
  },
};

const updateSVPresetsInfo = (
  state,
  presetsInfo: Partial<SVPresetsInfo>
): State => {
  const ui = getUpdatedPresetsUIState(state, presetsInfo);
  const data = getUpdatedPresetsDataState(state, presetsInfo);

  return {
    ...state,
    ui,
    data,
  };
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SET_PRESETS_INFO:
      return updateSVPresetsInfo(state, action.payload);
    case constants.SELECT_PRESET:
      return set(state, "ui.selectedPresetId", action.payload.presetId);
    case constants.RESET_TO_DEFAULT_PRESET:
      return set(
        state,
        "ui.selectedPresetId",
        getDefaultPresetId(state.data.presets)
      );
    case constants.SET_DICTIONARIES:
      return set(state, "data.presetDictionaries", action.payload);
    default:
      return state;
  }
}
