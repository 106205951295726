import { decamelizeKeys } from "humps";
import { isEmpty } from "ramda";

import { headers } from "modules/utils";
import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";
import { fetchCamelizeData } from "modules/utils/fetchData";
import { flattenApiList } from "modules/utils/formatApiResponse";

const byPosition = (a, b) => a.position - b.position;
export const preparePatientMetadata = data =>
  data.sort(byPosition).map((item, index) => ({
    ...item,
    visiblePosition: index + 1,
  }));

export async function getPatientMetadata(
  projectId,
  metadata,
  fromAncestors,
  queryString,
  pageSize = 0
) {
  if (queryString && queryString.includes("page[size]")) {
    throw new Error(
      "Do not pass page size as query string, please use explicit parameter"
    );
  }
  const pageSizeQuery = `page[size]=${pageSize}`;
  const queryPart = queryString
    ? `?${queryString}&${pageSizeQuery}`
    : `?${pageSizeQuery}`;
  const result = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_${metadata}${
      fromAncestors ? "/from_ancestors" : ""
    }${queryPart}`
  );

  if (!result.ok) {
    return result;
  }
  const { data = [] } = result.payload;
  return {
    ...result,
    payload: preparePatientMetadata(flattenApiList(data)),
  };
}

export async function getPatientsVisibleMetadataFields(projectId) {
  return getPatientMetadata(
    projectId,
    "fields",
    false,
    "filter[visible_in_list]=true"
  );
}

export function addInheritedPatientMetadataField(projectId, id) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_fields/${id}/copy_from_ancestors`,
    {
      method: "POST",
      headers,
    }
  );
}

export function addAllInheritedPatientMetadataFields(projectId) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_fields/copy_from_ancestors`,
    {
      method: "POST",
      headers,
    }
  );
}

export function deletePatientMetadataField(projectId, id) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_fields/${id}`,
    {
      method: "DELETE",
    }
  );
}

export function addPatientMetadataField(
  projectId,
  { fieldName, fieldCode, visibleInList, required, fieldType, categoryId }
) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_fields`,
    {
      method: "POST",
      headers,
      body: JSON.stringify({
        data: {
          type: "project_patient_metadata_field",
          attributes: decamelizeKeys({
            fieldName,
            fieldCode,
            fieldType,
            visibleInList,
            required,
            categoryId: isEmpty(categoryId) ? null : categoryId,
          }),
        },
      }),
    }
  );
}

export function updatePatientMetadataField(
  projectId,
  { id, fieldName, visibleInList, required, categoryId, position }
) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_fields/${id}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify({
        data: {
          id,
          type: "project_patient_metadata_field",
          attributes: decamelizeKeys({
            fieldName,
            visibleInList,
            required: required || false,
            position,
            categoryId: isEmpty(categoryId) ? null : categoryId,
          }),
        },
      }),
    }
  );
}

export function addInheritedPatientMetadataCategory(projectId, id) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_categories/${id}/copy_from_ancestors`,
    {
      method: "POST",
      headers,
    }
  );
}

export function addAllInheritedPatientMetadataCategories(projectId) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_categories/copy_from_ancestors`,
    {
      method: "POST",
      headers,
    }
  );
}

export function deletePatientMetadataCategory(projectId, id) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_categories/${id}`,
    {
      method: "DELETE",
    }
  );
}

export function addPatientMetadataCategory(projectId, { name, visible }) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_categories`,
    {
      method: "POST",
      headers,
      body: JSON.stringify({
        data: {
          type: "project_patient_metadata_field_category",
          attributes: {
            name,
            visible,
          },
        },
      }),
    }
  );
}

export function updatePatientMetadataCategory(
  projectId,
  { id, name, visible, position }
) {
  return fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/patient_metadata_categories/${id}`,
    {
      method: "PATCH",
      headers,
      body: JSON.stringify({
        data: {
          id,
          type: "project_patient_metadata_field_category",
          attributes: {
            name,
            visible,
            position,
          },
        },
      }),
    }
  );
}
