export const NAMESPACE = "project";

export const READ_PROJECT = "READ_PROJECT";
export const READ_PROJECT_SUCCESS = "READ_PROJECT_SUCCESS";
export const READ_PROJECT_FAILURE = "READ_PROJECT_FAILURE";
export const READ_PROJECT_TAB_COUNTS = "READ_PROJECT_TAB_COUNTS";
export const READ_PROJECT_TAB_COUNTS_SUCCESS =
  "READ_PROJECT_TAB_COUNTS_SUCCESS";
export const READ_PROJECT_TAB_COUNTS_FAILURE =
  "READ_PROJECT_TAB_COUNTS_FAILURE";
export const READ_PROJECT_CURRENT_USER = "READ_PROJECT_CURRENT_USER";
export const READ_PROJECT_CURRENT_USER_SUCCESS =
  "READ_PROJECT_CURRENT_USER_SUCCESS";
export const READ_PROJECT_CURRENT_USER_FAILURE =
  "READ_PROJECT_CURRENT_USER_FAILURE";
export const RELOAD_CURRENT_PROJECT_CURRENT_USER =
  "RELOAD_CURRENT_PROJECT_CURRENT_USER";

//project feature flags
export const REVEL_FEATURE_FLAG = "revel";
export const SPLICE_AI_FEATURE_FLAG = "spliceAi";
export const AUTO_ACMG_FEATURE = "autoAcmg";
export const AUTOMATION_FEATURE = "automation";
export const GNOMAD_CS_FEATURE = "gnomadConstraintScores";
export const ARIADNE_PREDICTION_FEATURE = "ariadne";
export const GNOMAD_SV_FEATURE = "gnomadSv";
export const GNOMAD_EXOMES_FEATURE = "gnomadExomes";
export const EXAC_EXOMES_FEATURE = "exacExomes";
export const TRIO_INHERITANCE_FEATURE = "newTrioInheritance";
