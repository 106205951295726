import PropTypes from "prop-types";

export const CheckboxTreeValueKeyProp = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const CheckboxTreeOptionProp = PropTypes.shape({
  key: CheckboxTreeValueKeyProp.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
});

export const CheckboxTreeValueProp = PropTypes.arrayOf(
  CheckboxTreeValueKeyProp
);

export const DraggableCheckboxesProp = {
  /**
   * value as array of  options
   */
  options: PropTypes.arrayOf(CheckboxTreeOptionProp).isRequired,
  /**
   * checkboxes selected values
   */
  value: CheckboxTreeValueProp,
  /**
   * onChange handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * sortable property
   */
  sortable: PropTypes.bool,
  /**
   * disabled property
   */
  disabled: PropTypes.bool,
  /**
   * Label for this component
   */
  label: PropTypes.string,
  /**
   * Description of the control
   */
  description: PropTypes.string,
  /**
   * Narrow input field
   */
  narrow: PropTypes.bool,
  /**
   * Is it a required field
   */
  required: PropTypes.bool,
};
