import { call, put, select, takeLatest } from "redux-saga/effects";

import { getAuthProviders } from "modules/api/authProviders";
import { getSystemConfig } from "modules/api/systemConfig";
import { LOGIN_FORM } from "modules/auth/constants";
import { createFormTypeString } from "modules/forms/utils";

import {
  blockUISystemConfig,
  setAuthProviders,
  setSystemConfig,
} from "./actions";
import { LOAD_SYSTEM_CONFIG } from "./constants";
import {
  getAssemblyConfig,
  getAuthProviders as authProvidersSelector,
} from "./selectors";

export function* init() {
  yield takeLatest(LOAD_SYSTEM_CONFIG, initSystemConfig);
  yield takeLatest(createFormTypeString(LOGIN_FORM), initSystemConfig);
}

export function* initSystemConfig() {
  try {
    const assembly = yield select(getAssemblyConfig);
    if (assembly) return;
    const systemConfig = yield call(getSystemConfig);
    yield put(setSystemConfig(systemConfig));
  } catch (e) {
    yield put(blockUISystemConfig());
  }
}

export function* getOrReloadAuthProviders() {
  try {
    const stateAuthProviders = yield select(authProvidersSelector);
    if (stateAuthProviders) {
      return stateAuthProviders;
    }
    const authProviders = yield call(getAuthProviders);
    yield put(setAuthProviders(authProviders));
  } catch (e) {
    yield put(blockUISystemConfig());
  }
}
