import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { ReduxFormField } from "modules/forms/components";

import { AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY } from "../../reducer.data";
import { getSuggestedPathogenicityValues } from "../../selectors";

export const AutoAcmgForm = ({
  disabled = false,
  suggestedPathogenicityValues,
}) => (
  <fieldset disabled={disabled} data-testid="config-auto-acmg-form">
    <Field
      component={ReduxFormField}
      type="checkboxgroup"
      label="Suggested ACMG Pathogenicity"
      name={AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY}
      options={suggestedPathogenicityValues}
    />
  </fieldset>
);

const mapStateToProps = state => ({
  suggestedPathogenicityValues: getSuggestedPathogenicityValues(state),
});

export default connect(mapStateToProps)(AutoAcmgForm);
