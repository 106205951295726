import PropTypes from "prop-types";
import { isEmpty } from "ramda";
import React, { memo } from "react";

import { Tooltip, Tag } from "pattern-library";

import { formatCriteriaName } from "../../utils";

export const AcmgSuggestedCriteria = ({
  criteria,
  disabled,
  allCriteria,
  onChange,
  selected,
}) => {
  const {
    name,
    classificationType: { style } = {},
    description,
    comment,
  } = criteria;
  const tooltip = (
    <>
      <div>
        <strong>Description:</strong> {description}
      </div>
      <div>
        <strong>Comment:</strong> {comment}
      </div>
    </>
  );

  const criteriaName =
    allCriteria && !isEmpty(allCriteria)
      ? formatCriteriaName(
          criteria,
          allCriteria.find(item => item.name === name)
        )
      : name;

  return (
    !!criteria && (
      <>
        <input
          type="checkbox"
          id={name}
          checked={selected}
          onChange={onChange}
          disabled={disabled}
        />
        <label htmlFor={name} className="checkbox-label">
          <Tooltip content={tooltip} placement="bottom">
            <Tag context={style} className={`${disabled ? "locked-cell" : ""}`}>
              {criteriaName}
            </Tag>
          </Tooltip>
        </label>
      </>
    )
  );
};

export default memo(AcmgSuggestedCriteria);

AcmgSuggestedCriteria.propTypes = {
  criteria: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  allCriteria: PropTypes.array,
};
