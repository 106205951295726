import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";

import { Select } from "pattern-library";

import {
  loadPresets,
  selectPreset,
} from "modules/structuralVariantsPresets/actions";
import * as selectors from "modules/structuralVariantsPresets/selectors";
import { FilterPresetOptionProp } from "modules/utils/prop-types/FilterPresetOptionProp";

import ResetFiltersButton from "./ResetFiltersButton";
import ViewPresetButton from "./ViewPresetButton";

export const SVPresetsContainer = memo(
  ({
    projectId,
    loadPresets,
    presetOptions = [],
    presetsLoading,
    selectedPresetOption,
    selectPreset,
    curatedVariantLists,
    collapsed,
    onPresetChange,
  }) => {
    useEffect(() => {
      loadPresets(projectId);
    }, [loadPresets, projectId]);

    useEffect(() => {
      if (selectedPresetOption) {
        onPresetChange(selectedPresetOption);
      }
    }, [selectedPresetOption, onPresetChange]);

    const onChange = ({ value: { id } }) => {
      selectPreset(id);
    };

    return (
      <div>
        <label>Filter presets:</label>
        <Select
          options={presetOptions}
          onChange={onChange}
          isSearchable
          name="sv-preset-select"
          isMulti={false}
          isDisabled={presetsLoading}
          value={selectedPresetOption}
        />
        <div className="structural-variants-preset-footer">
          <ViewPresetButton
            projectId={projectId}
            curatedVariantLists={curatedVariantLists}
            collapsed={collapsed}
          />
          <ResetFiltersButton
            onPresetChange={onPresetChange}
            collapsed={collapsed}
          />
        </div>
      </div>
    );
  }
);

const mapStateToProps = state => ({
  presetOptions: selectors.getSelectFieldPresets(state),
  presetsLoading: selectors.arePresetsLoading(state),
  selectedPresetOption: selectors.getSelectedPresetOption(state),
  presetDictionaries: selectors.getPresetDictionaries(state),
});

const mapDispatchToProps = {
  loadPresets,
  selectPreset,
};

SVPresetsContainer.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  loadPresets: PropTypes.func.isRequired,
  onPresetChange: PropTypes.func.isRequired,
  /**
   * the flag indicating if the presets GET request is in progress
   */
  presetsLoading: PropTypes.bool.isRequired,
  /**
   * presets select field options with label and value properties
   */
  presetOptions: PropTypes.arrayOf(FilterPresetOptionProp),
  /**
   * the currently selected option from the presetOptions
   */
  selectedPresetOption: FilterPresetOptionProp,
  /**
   * the function that handles the preset selection
   */
  selectPreset: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SVPresetsContainer);
