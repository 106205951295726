export const NAME = "hpoTerms" as const;

export const REMOVE_HPO_TERM = "REMOVE_HPO_TERM" as const;
export const TRY_ADD_HPO_TERM = "TRY_ADD_HPO_TERM" as const;
export const ADD_HPO_TERM = "ADD_HPO_TERM" as const;
export const HPO_TERM_LIST_ITEM = "HPO_TERM_LIST_ITEM" as const;
export const SET_SELECTED_HPO_TERMS = "SET_SELECTED_HPO_TERMS" as const;
export const SET_ALL_HPO_TERMS = "SET_ALL_HPO_TERMS" as const;
export const HAS_LOADED = "HAS_LOADED_HPO_TERMS" as const;
export const START_INIT = "START_HPO_INIT" as const;
export const TOGGLE_TERM_VISIBILITY = "TOGGLE_TERM_VISIBILITY" as const;
export const HPO_TERMS_LOAD_FAILED = "HPO_TERMS_LOAD_FAILED" as const;
export const SET_VISIBLE_NODE_IDS = "SET_VISIBLE_NODE_IDS" as const;
export const SET_EXPANDED_NODE_IDS = "SET_EXPANDED_NODE_IDS" as const;
export const SET_QUERY = "SET_QUERY" as const;
export const FILTER_HPO_TERMS = "FILTER_HPO_TERMS" as const;
export const RESET_HPO_TERMS_TREE = "RESET_HPO_TERMS_TREE" as const;

export const TOP_LEVEL_TERM = 118 as const;
