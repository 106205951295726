import { camelizeKeys } from "humps";
import PropTypes from "prop-types";
import { identity, memoizeWith } from "ramda";
import React, { PureComponent } from "react";

import { groupCurations } from "../selectors";

import CuratedVariantsColumn from "./columns/CuratedVariantsColumn";

export class CuratedVariantsColumnWrapper extends PureComponent {
  static propTypes = {
    /**
     * patient's structural variant data
     */
    variant: PropTypes.object.isRequired,
    /**
     * an object of CVL ids as keys and CVL information like name, type and show_pathogenicity flag as values
     */
    curatedVariantLists: PropTypes.object.isRequired,
  };

  processVariant(variant, curatedVariantLists) {
    const { sv_cnv_curated_variants: svCuratedVariants = [] } = variant;
    const curations = svCuratedVariants.map(
      ({
        curated_variant: cVariant,
        curated_variant: { curated_data: cData },
      }) => {
        const curatedData = JSON.stringify(cData);
        const res = camelizeKeys(cVariant);
        const { curatedVariantListId } = res;
        const curatedVariantList = camelizeKeys(
          curatedVariantLists[curatedVariantListId] || {}
        );
        return {
          ...res,
          curatedData,
          curatedVariantList,
        };
      }
    );
    return {
      curations: groupCurations(curations),
    };
  }

  render() {
    const { variant, curatedVariantLists = {} } = this.props;
    return (
      <CuratedVariantsColumn
        header="sv-table-cvl"
        variant={memoizeWith(identity, this.processVariant)(
          variant,
          curatedVariantLists
        )}
      />
    );
  }
}
