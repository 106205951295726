import React from "react";

import { Rating } from "../rating";
import { VariantPathogenicity } from "../variant-pathogenicity";

const ratingsOptions = [4, 3, 2, 1].map(score => ({
  label: (
    <>
      <Rating maximum={5} score={score} showEmpty /> <span>and up</span>
    </>
  ),
  value: score,
  key: `${score}andup`,
}));

export const FormFieldPresets = {
  FiveStarRating: {
    type: "radiobuttons",
    items: [
      ...ratingsOptions,
      {
        label: "Any",
        value: 0,
        key: "any",
      },
    ],
  },
  Pathogenicity: {
    type: "checkboxgroup",
    boldSelected: false,
    options: ["Pathogenic", "Likely pathogenic", "Likely benign", "Benign"].map(
      pathogenicity => ({
        key: pathogenicity,
        label: <VariantPathogenicity level={pathogenicity} small />,
      })
    ),
  },
};
