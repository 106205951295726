import { FC, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Loading, Tabs } from "pattern-library";

import {
  ONCOLOGY_PROJECT_TYPE,
  RARE_DISEASE_PROJECT_TYPE,
} from "modules/projects/constants";

import { RouteTabContent, RouteTabHeader } from "../../layout/partials";

import { FastQC } from "./components/fastqc";
import Oncology from "./components/oncology";
import { VCF } from "./components/vcf";

import catalystApi from "api/catalyst-api";

const { useLazyGetProjectQuery } = catalystApi;

function getTabSet(patientId: number): Array<any> {
  return [
    {
      name: "VCF",
      url: "/vcf",
      component: VCF,
      className: "pull-right",
      componentProps: {
        patientId,
      },
    },
    {
      name: "FastQC",
      url: "/fastqc",
      component: FastQC,
      className: "pull-right",
      componentProps: {
        patientId,
      },
    },
  ];
}
interface Props {
  projectId: number;
  patientId: number;
}

const QCContainer: FC<Props> = ({ patientId, projectId }) => {
  const [
    getProject,
    { isLoading, data: { projectTypeInternalName } = {} as Project },
  ] = useLazyGetProjectQuery();

  useEffect(() => {
    if (projectId) {
      getProject({ projectId });
    }
  }, [projectId, getProject]);

  if (isLoading && !projectTypeInternalName) return <Loading />;

  if (projectTypeInternalName === RARE_DISEASE_PROJECT_TYPE) {
    const tabSet = getTabSet(patientId);
    return (
      <div className="container-fluid">
        <Switch>
          <Route exact path={`/patient/${patientId}/qc`}>
            <Redirect to={`/patient/${patientId}/qc/fastqc`} />
          </Route>
          <Tabs
            tabComponent={RouteTabHeader}
            contentComponent={RouteTabContent}
            tabs={tabSet}
            baseUrl={`/patient/${patientId}/qc`}
            routePath="/patient/:patientId/qc"
          />
        </Switch>
      </div>
    );
  } else if (projectTypeInternalName === ONCOLOGY_PROJECT_TYPE) {
    return <Oncology patientId={patientId} />;
  } else {
    return null;
  }
};

export default QCContainer;
