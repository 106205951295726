import { omit } from "ramda";
import React from "react";
import "proxy-polyfill";

const Link = props => {
  const { disabled } = props;
  if (disabled) {
    const cleanProps = omit(["href"], props);
    return <span {...cleanProps} />;
  }

  return <a {...props} />; // eslint-disable-line jsx-a11y/anchor-has-content
};

const initialConfig = {
  linkElement: Link,
  linkHrefMap: false,
};

const proxy = new Proxy(initialConfig, {
  get: (target, name) => target[name],
  set: (target, name, value) => {
    target[name] = value;
    return true;
  },
});

export default proxy;
