import type { Action } from "./actions";
import * as constants from "./constants";
import {
  DISPLAY_MODE_NONE,
  DISPLAY_MODE_CVL,
  DISPLAY_MODE_ADD_EXISTING,
} from "./constants";
import type { CvlOptions, CvlOptionsResponse, State } from "./types";
import { convertCvlOptions } from "./utils";

export const initialState: State = {
  isLoading: false,
  variantsLoading: false,
  projectId: null,
  curatedLists: [],
  existingListsAvailable: false,
  existingCuratedLists: [],
  needUpdate: false,
  selectedList: null,
  selectedVariants: null,
  displayMode: DISPLAY_MODE_NONE,
  cvlOptions: {},
  cvlAudit: null,
  isCVLAuditLoading: false,
  exportCVLId: null,
  displayExportModal: false,
  exportHasInvalidData: null,
  exportLoading: false,
  resetDisplayMode: true,
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case constants.SET_VARIANTS_LOADING:
      return {
        ...state,
        variantsLoading: action.payload.variantsLoading,
      };

    case constants.INIT_CURATED_LISTS:
      return {
        ...state,
        projectId: action.payload.projectId,
      };

    case constants.SET_CURATED_LISTS:
      return {
        ...state,
        curatedLists: action.payload.curatedLists,
      };

    case constants.SET_NEED_UPDATE:
      return {
        ...state,
        needUpdate: action.payload.needUpdate,
      };

    case constants.SET_EXISTING_LISTS_AVAILABLE:
      return {
        ...state,
        existingListsAvailable: action.payload.existingListsAvailable,
      };

    case constants.SET_EXISTING_LISTS:
      return {
        ...state,
        existingCuratedLists: action.payload.existingCuratedLists,
      };

    case constants.SET_EXISTING_LIST_STATUS:
      const {
        payload: { curatedVariantListId, addToProjectStatus },
      } = action;
      const { existingCuratedLists: lists } = state;
      const selectedIndex = lists.findIndex(
        ({ curatedVariantListId: id }) => curatedVariantListId === id
      );
      const listsCopy = [...lists];
      listsCopy[selectedIndex] = {
        ...lists[selectedIndex],
        addToProjectStatus,
      };
      return {
        ...state,
        existingCuratedLists: listsCopy,
      };

    case constants.SET_SELECTED_CVL:
      const { selectedCVL } = action.payload;
      return {
        ...state,
        selectedList: selectedCVL,
        displayMode: DISPLAY_MODE_CVL,
      };

    case constants.SHOW_EXISTING_CVLS:
      return {
        ...state,
        selectedList: null,
        displayMode: DISPLAY_MODE_ADD_EXISTING,
      };

    case constants.RESET_DISPLAY_MODE:
      return {
        ...state,
        selectedList: null,
        displayMode: DISPLAY_MODE_NONE,
      };

    case constants.SET_CVL_OPTIONS:
      const cvlOptionsResponse: CvlOptionsResponse = action.payload;

      const cvlOptions: CvlOptions = convertCvlOptions(
        cvlOptionsResponse.data.attributes
      );

      return {
        ...state,
        cvlOptions,
      };

    case constants.SET_SELECTED_VARIANTS:
      const { selectedVariants } = action.payload;
      return {
        ...state,
        selectedVariants,
      };

    case constants.SET_CVL_AUDIT_LOADING:
      const { auditLoading } = action.payload;
      return {
        ...state,
        isCVLAuditLoading: auditLoading,
      };

    case constants.SET_CVL_AUDIT:
      const { cvlAudit } = action.payload;
      return {
        ...state,
        cvlAudit,
      };

    case constants.SET_EXPORT_MODAL:
      const {
        curatedVariantListId: exportCVLId,
        displayExportModal,
        hasInvalidData: exportHasInvalidData,
      } = action.payload;
      return {
        ...state,
        exportCVLId,
        displayExportModal,
        exportHasInvalidData,
      };

    case constants.SET_EXPORT_LOADING:
      return {
        ...state,
        displayExportModal: action.payload.displayExportModal,
        exportLoading: action.payload.exportLoading,
      };

    case constants.SET_RESET_DISPLAY_MODE:
      return {
        ...state,
        resetDisplayMode: action.payload.resetDisplayMode,
      };

    default:
      return state;
  }
}
