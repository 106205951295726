import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import FormControlWrapper from "./FormControlWrapper";
import FormGroup from "./FormGroup";
import Label from "./Label";

export default class Select extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    input: PropTypes.object,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    required: PropTypes.bool,
    narrow: PropTypes.bool,
    containerClassName: PropTypes.string,
    disabled: PropTypes.bool,
  };

  render() {
    const {
      label = "",
      input = {},
      options = [],
      required = false,
      narrow = false,
      containerClassName,
      disabled = false,
    } = this.props;

    return (
      <FormGroup
        {...this.props}
        required={required}
        containerClassName={containerClassName}
      >
        {label && <Label label={label} required={required} narrow={narrow} />}
        <FormControlWrapper label={label} narrow={narrow}>
          <select className="form-control" {...input} disabled={disabled}>
            <option value="">Please select an option</option>
            {options.map(options => (
              <OptionGroup key={options.group || options.name} {...options} />
            ))}
          </select>
        </FormControlWrapper>
      </FormGroup>
    );
  }
}

class OptionGroup extends PureComponent {
  render() {
    const { group = false, options = [], ...rest } = this.props;

    if (group !== false) {
      return (
        <optgroup label={group}>
          {options.map(option => (
            <OptionGroup key={`${group}-${option.name}`} {...option} />
          ))}
        </optgroup>
      );
    }
    return <Option key={rest.name} {...rest} />;
  }
}

class Option extends PureComponent {
  render() {
    const { name, label, ...rest } = this.props;

    return (
      <option key={name} name={name} {...rest}>
        {label}
      </option>
    );
  }
}
