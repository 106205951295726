import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { useCallback } from "react";

import { Link } from "pattern-library";

const DefaultTabHeader = ({
  name,
  navLinkClassName,
  strong,
  onClick,
  href = "",
  count = false,
  label,
  icon,
}) => {
  const onTabClicked = useCallback(
    e => {
      if (!href) {
        e.preventDefault();
      }
      onClick(name);
    },
    [name, href, onClick]
  );

  const countElement = Number.isInteger(count) && (
    <span className="ajax nocache label label-default">{count}</span>
  );

  const resultName = strong ? <strong>{name}</strong> : name;

  return (
    <Link href={href} className={navLinkClassName} onClick={onTabClicked}>
      {icon} {resultName} {countElement}
      {!isNil(label) && (
        <>
          <span className="label label-default">{label}</span>
        </>
      )}
    </Link>
  );
};

export const TabHeaderPropTypes = {
  name: PropTypes.string.isRequired,
  /**
   * classname for the tab header link or a custom header component if specified
   */
  className: PropTypes.string,
  /**
   * if the tab has value it will be hightlighted
   */
  hasValue: PropTypes.bool,
  /**
   * Flag to load the count number or a number to display
   */
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  /**
   * if true, the tab header text will be emboldened
   */
  strong: PropTypes.bool,
  /**
   * tab's label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * tab's icon
   */
  icon: PropTypes.element,
};

DefaultTabHeader.propTypes = TabHeaderPropTypes;

export default DefaultTabHeader;
