import lodash from "lodash";

import {
  FetchDataResponse,
  fetchPerlEndpointData,
} from "modules/utils/fetchData";

import { SummaryRequestParameters } from "../sequenceVariantsSummary/types";
import { processErrors } from "../utils";

export const getSummary = ({
  patientId,
  geneName,
  transcriptId,
  variantId,
}: SummaryRequestParameters) => {
  const optionalParams: Array<string> = [];
  if (transcriptId) {
    optionalParams.push(`/transcript/${transcriptId}`);
  }
  if (variantId) {
    optionalParams.push(`/snv/${variantId}`);
  }
  return fetchPerlEndpointData(
    `/patient/${patientId}/variants/gene/${geneName}${optionalParams.join(
      ""
    )}/summary`
  );
};

export const getOtherPatientsSnvCounts = async (
  patientId,
  variantId,
  geneName
) => {
  const { ok, payload, errors }: FetchDataResponse<any> =
    await fetchPerlEndpointData(
      `/catalyst/api/patient/${patientId}/variants/snv/${variantId}/gene/${geneName}/allele_counts`
    );

  if (!ok || errors) {
    const details = processErrors(errors) || "";
    throw new Error(
      `Couldn't retrieve other patients with this variant counts.${details}`
    );
  }

  return Object.entries(payload).reduce((acc, [key, count]) => {
    const [projectType, countTypeUpperFirst] = key.split(
      /(TotalCount|OtherCount)/
    );
    const countType = lodash.lowerFirst(countTypeUpperFirst);
    acc[projectType] = acc[projectType] || {};
    acc[projectType][countType] = count;
    return acc;
  }, {});
};
