// @flow

import { path } from "ramda";

import { MODULE_NAME } from "./constants";
import type { State } from "./flow-types";
import { findInDecisionGroups } from "./utils";

export const dataPath = (...items: any) =>
  path([MODULE_NAME, "data", ...items]);

export const getCurrentPatientDecisions = (state: {}): DecisionGroups =>
  dataPath("currentPatientDecisions")(state);

export const findDecision = (
  state: { decisions: State },
  variantId: number,
  decisionType: DecisionType
) => {
  const allDecisions: DecisionGroups = getCurrentPatientDecisions(state);

  return findInDecisionGroups(allDecisions, variantId, decisionType) || null;
};
