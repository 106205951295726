// @flow

import { fetchCamelizeData } from "../utils";
import { CATALYST_BASE_URL, API_ENTITIES_BASE_URL } from "../utils/baseUrls";
import type { FetchDataResponse } from "../utils/fetchData";
import { fetchPerlEndpointData } from "../utils/fetchData";

export function getCurrentUser() {
  return fetch(`${API_ENTITIES_BASE_URL}/current_user`, {
    credentials: "include",
  });
}

export type ApiTokenFetchDataResponse = FetchDataResponse<{
  apiToken: string,
}>;

export function getProfileApiToken(): Promise<ApiTokenFetchDataResponse> {
  return fetchPerlEndpointData("/user-profile");
}

export function getProjectCurrentUser(
  projectId: number | string,
  args: Object
) {
  return fetchCamelizeData(
    `${CATALYST_BASE_URL}/project/${projectId}/current_user`,
    args
  );
}
