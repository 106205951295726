import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { getModals } from "./selectors";

const ModalRegistry = ({ modals, registry }) => {
  const portalTarget = document.getElementById("modal");

  const modalsList = useMemo(
    () =>
      modals.map(({ type: modalType, props }, index) => {
        const Component = registry[modalType];
        return Component ? (
          <Component
            key={modalType}
            {...props}
            style={{ zIndex: 10 + index }}
          />
        ) : null;
      }),
    [modals, registry]
  );

  return portalTarget ? ReactDOM.createPortal(modalsList, portalTarget) : null;
};

PropTypes.PropTypes = {
  registry: PropTypes.object.required,
  modals: PropTypes.object,
};

const mapStateToProps = state => ({
  modals: getModals(state),
});

export default connect(mapStateToProps)(ModalRegistry);
