import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { getThirdpartySoftwareVersions } from "modules/patient/selectors";

import {
  getCurrentProjectVariantPanelConfig,
  isSpliceAiActiveOnCurrentProject,
} from "../project/selectors";

import Summary from "./Summary";
import { getOrReloadSequenceVariantSummary } from "./actions";
import { getSummaryStatePath } from "./components/utils";
import {
  getSequenceVariantsSummaryData,
  isSequenceVariantsSummaryDataLoading,
} from "./selectors";

interface SummaryContainerProps extends PropsFromRedux {
  geneName: string;
  patientId: string | number;
  transcriptId?: string | number;
  variantId?: string | number;
  prefix: string | null;
  isVariantPanel?: boolean;
}

export const SummaryContainer = (props: SummaryContainerProps) => {
  const {
    patientId,
    geneName,
    transcriptId,
    variantId,
    loadData,
    prefix,
    isVariantPanel = false,
  } = props;
  const path = useMemo(() => getSummaryStatePath(prefix), [prefix]);
  useEffect(() => {
    if (patientId && geneName) {
      loadData(
        {
          patientId,
          geneName,
          transcriptId,
          variantId,
        },
        { path }
      );
    }
  }, [loadData, patientId, geneName, transcriptId, variantId, path]);

  return <Summary {...props} path={path} isVariantPanel={isVariantPanel} />;
};

const mapStateToProps = (state, { prefix }) => {
  const path = getSummaryStatePath(prefix);
  return {
    loading: isSequenceVariantsSummaryDataLoading(state, path),
    data: getSequenceVariantsSummaryData(state, path),
    thirdpartySoftwareVersions: getThirdpartySoftwareVersions(state),
    isSpliceAiFeatureEnabled: isSpliceAiActiveOnCurrentProject(state),
    variantPanelConfig: getCurrentProjectVariantPanelConfig(state),
  };
};

const mapDispatchToProps = {
  loadData: getOrReloadSequenceVariantSummary,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SummaryContainer);
