import PropTypes from "prop-types";
import { isEmpty, isNil } from "ramda";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { ReduxFormField } from "modules/forms/components";
import SelectReduxField from "modules/forms/components/SelectReduxField";

import { FILTER_FIELDS } from "../constants";
import * as selectors from "../selectors";
import { tuneKey } from "../utils";

export const createSelectField = (
  name,
  options,
  onChange,
  isMulti = true,
  hasGroups = false
) => (
  <SelectReduxField
    name={`${name}`}
    isMulti={isMulti}
    hasGroups={hasGroups}
    options={options}
    onChange={onChange}
    isDisabled={isNil(options) || isEmpty(options)}
  />
);

export const createCheckboxgroupField = (name, options, onChange) => (
  <Field
    name={`${name}`}
    options={options}
    type="checkboxgroup"
    component={ReduxFormField}
    onChange={onChange}
  />
);

export const createNumberField = (name, options, onChange, config) => (
  <Field
    name={`${name}`}
    options={options}
    type="text"
    component={ReduxFormField}
    onChange={onChange}
    className={config.className}
    addon={config.addon}
    placeholder={config.placeholder}
    normalize={config.normalize}
  />
);

const SVConfigEditor = ({
  filterData = {},
  genePanelOptions = [],
  includedTagOptions = [],
  excludedTagOptions = [],
  onChange,
}) => {
  const onChangeHandler = useCallback(() => {
    setTimeout(() => {
      onChange();
    }, 0);
  }, [onChange]);

  const getField = (
    name,
    type,
    options,
    isMulti = false,
    hasGroups = false,
    config = {}
  ) => {
    if (type === "checkboxgroup") {
      return createCheckboxgroupField(name, options, onChangeHandler);
    }

    if (type === "number") {
      return createNumberField(name, options, onChangeHandler, config);
    }

    return createSelectField(
      name,
      options,
      onChangeHandler,
      isMulti,
      hasGroups
    );
  };

  return (
    <div className="structural-variants-filter-row row">
      {FILTER_FIELDS.map(
        ({ key, type = "select", hasGroups = false, config }) => {
          const filter = filterData[key];
          if (!filter) {
            return null;
          }
          const { values = [], title, multiple } = filter;
          let options = values;

          const optionsHash = {
            included: includedTagOptions,
            keyword: excludedTagOptions,
            genePanel: genePanelOptions,
          };
          options = optionsHash[key] || options;

          options = options.map(({ label, value }) => ({
            label,
            value,
            key: value,
          }));

          return (
            <div key={`${key}`} className="col-sm-6 col-md-12">
              <label>{title}:</label>
              <div>
                {getField(
                  tuneKey(key),
                  type,
                  options,
                  multiple === 1,
                  hasGroups,
                  config
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

SVConfigEditor.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func,
};

const mapStateToProps = (state, { projectId }) => ({
  filterData: selectors.getFilterData(state),
  genePanelOptions: selectors.getFilterGenePanelOptions(state, projectId),
  includedTagOptions: selectors.getIncludedTagsOptions(state),
  excludedTagOptions: selectors.getExcludedTagsOptions(state),
});

export default connect(mapStateToProps, null)(SVConfigEditor);
