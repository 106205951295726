import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { CLOUD_PROVIDER_ON_PREM } from "common/constants";

import { NAME } from "./constants";
import { GenomeAssemblyType } from "./types";

export const getAssemblyConfig = createSelector(
  path([NAME, "config", "sapientiaAssembly"]),
  (assembly): GenomeAssemblyType =>
    (typeof assembly === "string"
      ? assembly.toLowerCase()
      : assembly) as GenomeAssemblyType
);

export const isSentieonAvailable = path([NAME, "config", "sentieonAvailable"]);
export const getSystemConfigBlockUI = path([NAME, "blockUI"]);
export const getCloudProvider = path([NAME, "config", "cloudProvider"]);
export const getAuthProviders = state => path([NAME, "authProviders"], state);
export const getCEMarking = path([NAME, "config", "ceMarking"]);
export const getUserGuide = createSelector(
  path([NAME, "config", "userGuide"]),
  userGuideUrlSuffix => (userGuideUrlSuffix ? userGuideUrlSuffix : {})
);
export const getAppVersions = path([NAME, "config", "versions"]);
export const oncologyAllowThirdPartyVariantCalling = path([
  NAME,
  "config",
  "oncologyAllowThirdPartyVariantCalling",
]);

export const getAuthProvidersOptions = createSelector(
  getAuthProviders,
  (authProviders = []) =>
    authProviders.reduce(
      (acc, currentAuthProvider) => {
        acc[currentAuthProvider.id] = currentAuthProvider.attributes.name;
        return acc;
      },
      { internal: "Internal" }
    )
);

export const isOnPrem = createSelector(
  getCloudProvider,
  cloudProvider => cloudProvider === CLOUD_PROVIDER_ON_PREM
);

export const getSampleFilesRootFolders = state =>
  path([NAME, "config", "sampleFilesRootFolders"])(state) || [];

export const getDefaultSampleFilesRootFolder = createSelector(
  getSampleFilesRootFolders,
  rootFolders => (rootFolders.length > 0 ? rootFolders[0] : null)
);
