import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { Button, ButtonGroup } from "pattern-library";

import * as variantSelectors from "modules/variants/selectors";

import {
  acceptSuggestedAcmgCriteria,
  rejectSuggestedAcmgCriteria,
} from "../../actions";
import {
  ACMG_SUGGESTION_STATUS_PENDING,
  ACMG_SUGGESTION_STATUS_ACCEPTED,
  ACMG_SUGGESTION_STATUS_INACTIVE,
} from "../../constants";
import * as selectors from "../../selectors";

import AcmgSuggestedCriteria from "./AcmgSuggestedCriteria";

import congenicaApi from "api/congenica-api";

const { useGetPatientQuery } = congenicaApi;

export const AcmgSuggestionPanel = ({
  suggestedCriteria,
  suggestedClassification,
  status = "pending",
  patientVariant,
  geneId,
  transcriptName,
  allCriteria,
  canEdit,
  processing,
  acceptSuggestedCriteria,
  rejectSuggestedCriteria,
  showAssociatedTranscript,
}) => {
  const isSelected = status =>
    [ACMG_SUGGESTION_STATUS_PENDING, ACMG_SUGGESTION_STATUS_ACCEPTED].includes(
      status
    );

  const getSelected = selected => {
    const keys = Object.keys(selected);
    return keys.filter(c => selected[c]);
  };

  const hasNoneSelected = selected => getSelected(selected).length === 0;

  const initialState = suggestedCriteria.reduce(
    (acc, criteria) => ({
      ...acc,
      [criteria.name]: isSelected(criteria.status),
    }),
    {}
  );
  const [checkedCriteria, setCheckedCriteria] = useState(initialState);

  const { patientId, id } = patientVariant || {};
  const acceptCriteria = useCallback(() => {
    acceptSuggestedCriteria(
      patientId,
      id,
      geneId,
      getSelected(checkedCriteria)
    );
  }, [acceptSuggestedCriteria, patientId, id, geneId, checkedCriteria]);

  const rejectCriteria = useCallback(() => {
    rejectSuggestedCriteria(patientId, id, geneId);
  }, [rejectSuggestedCriteria, patientId, id, geneId]);

  const handleOnChange = event => {
    const { id, checked } = event.target;
    setCheckedCriteria({
      ...checkedCriteria,
      [id]: !!checked,
    });
  };

  const getActions = () => {
    if (status === ACMG_SUGGESTION_STATUS_PENDING) {
      return (
        <ButtonGroup className="pull-right">
          <Button
            type="button"
            context="primary"
            onClick={acceptCriteria}
            disabled={
              !canEdit || processing || hasNoneSelected(checkedCriteria)
            }
            data-testid="use-acmg-suggestions"
          >
            Use
          </Button>
          <Button
            type="button"
            onClick={rejectCriteria}
            disabled={!canEdit || processing}
            data-testid="reject-acmg-suggestions"
          >
            Reject all
          </Button>
        </ButtonGroup>
      );
    }
    if (status === ACMG_SUGGESTION_STATUS_INACTIVE) {
      return (
        <div className="alert alert-warning" role="alert">
          <i className="fa fa-warning" />
          &nbsp;ACMG suggestions were already used or rejected for this variant
          on another gene
        </div>
      );
    }
    return (
      <p className="pull-right lead">
        {status === ACMG_SUGGESTION_STATUS_ACCEPTED
          ? "Criteria Used"
          : "Criteria Rejected"}
      </p>
    );
  };

  return (
    suggestedCriteria.length > 0 && (
      <div className="acmg-suggestion-panel">
        <div className="col-xs-4">
          <div className="row">
            <div className="col-xs-7">
              <strong>Automated ACMG Suggestions:</strong>
            </div>
            <div className="col-xs-5">
              {suggestedClassification
                ? suggestedClassification
                : "Uncertain Significance"}
            </div>
          </div>
          {showAssociatedTranscript && (
            <div className="row">
              <div className="col-xs-7">
                <strong>Associated Transcript:</strong>
              </div>
              <div className="col-xs-5">{transcriptName}</div>
            </div>
          )}
        </div>
        <div className="col-xs-4">
          <div>
            {suggestedCriteria.map(singleCriteria => (
              <AcmgSuggestedCriteria
                key={singleCriteria.name}
                criteria={singleCriteria}
                disabled={
                  !canEdit ||
                  status !== ACMG_SUGGESTION_STATUS_PENDING ||
                  processing
                }
                allCriteria={allCriteria}
                onChange={handleOnChange}
                selected={checkedCriteria[singleCriteria.name]}
              />
            ))}
          </div>
          {status === ACMG_SUGGESTION_STATUS_PENDING && canEdit && (
            <div className="hint">
              <i>Click on a criterion to add/remove it from usage</i>
            </div>
          )}
        </div>
        <div className="col-xs-4">{getActions()}</div>
      </div>
    )
  );
};

const mapStateToProps = state => ({
  status: selectors.getSuggestedCriteriaStatusSelector(state),
  patientVariant: selectors.getCurrentPatientVariant(state),
  canEdit: selectors.canEdit(state),
  processing: selectors.isSuggestedAcmgProcessing(state),
  allCriteria: selectors.getACMGCriteria(state),
  geneId: variantSelectors.activeVariantPanelGene(state),
  transcriptName: selectors.getSuggestedTranscriptSelector(state),
});

const mapDispatchToProps = {
  acceptSuggestedCriteria: acceptSuggestedAcmgCriteria,
  rejectSuggestedCriteria: rejectSuggestedAcmgCriteria,
};

const ConnectedAcmgSuggestionPanel = props => {
  const { data, isLoading } = useGetPatientQuery({
    patientId: props.patientVariant?.patientId,
  });

  if (isLoading) return null;

  // Only show the associated transcript in the ACMG panel
  // if we can't find a third party record for Exomiser ACMG Annotation,
  // as the Exomiser annotation uses a mismatched Ensembl version
  const showAssociatedTranscript = !data?.annotation_sources.find(
    ({ attribute }) => attribute === "EXOMISER_ACMG_ANNOTATION"
  );

  return (
    <AcmgSuggestionPanel
      {...props}
      showAssociatedTranscript={showAssociatedTranscript}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedAcmgSuggestionPanel);

AcmgSuggestionPanel.propTypes = {
  suggestedCriteria: PropTypes.arrayOf(PropTypes.object),
  suggestedClassification: PropTypes.string,
  status: PropTypes.string,
  canEdit: PropTypes.bool,
  processing: PropTypes.bool,
  patientVariant: PropTypes.object,
  geneId: PropTypes.number,
};
