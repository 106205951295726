import { useMemo } from "react";

import catalystApi from "api/catalyst-api";
import congenicaApi from "api/congenica-api";

const {
  useGetPatientStatusQuery,
  useGetPatientAndTabsQuery,
  useGetSignificantStatusChangeQuery,
} = catalystApi;

const { useGetOncologyQCMetricsQuery } = congenicaApi;

export const useOncologyReportData = (
  patientId: number
): [
  {
    qc: Partial<OncologyQcData>;
    patientStatus: Partial<PatientStatus>;
    significantStatusChanges: Partial<SignificantStatusChange>;
    qcJsonFileName: string;
  },
  boolean,
  boolean
] => {
  const {
    data: qc = {},
    isLoading: qcLoading,
    error,
  } = useGetOncologyQCMetricsQuery({
    patientId,
  });

  const {
    data: significantStatusChanges = {},
    isLoading: significantStatusChangeLoading,
  } = useGetSignificantStatusChangeQuery({ patientId });

  const {
    data: patientStatus = {} as PatientStatus,
    isLoading: statusLoading,
  } = useGetPatientStatusQuery({ patientId });

  const {
    data: { patient: { reference = null } = {} } = {},
    isLoading: patientLoading,
  } = useGetPatientAndTabsQuery({ patientId });

  const qcJsonFileName = useMemo(
    () =>
      `${
        reference && reference.length <= 200 ? reference : patientId
      }_QC_metrics.json`,
    [patientId, reference]
  );

  const isLoading = useMemo(
    () =>
      statusLoading ||
      significantStatusChangeLoading ||
      qcLoading ||
      patientLoading,
    [patientLoading, qcLoading, significantStatusChangeLoading, statusLoading]
  );

  const isQCMetricsUnavailable = useMemo<boolean>(
    //404 is valid status but data will be empty in this case
    () => !isLoading && !error && Object.keys(qc).length === 0,
    [error, qc, isLoading]
  );

  return [
    { qc, significantStatusChanges, patientStatus, qcJsonFileName },
    isLoading,
    isQCMetricsUnavailable,
  ];
};
