import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Header, Footer } from "./partials";

export default class StandardLayout extends PureComponent {
  static displayName = "StandardLayout";

  static propTypes = {
    /**
     * The primary page content to load
     */
    children: PropTypes.node.isRequired,
  };

  render() {
    const { user, children } = this.props;

    return (
      <div className="app-wrapper">
        <div className="app-content">
          <Header user={user} />
          <div className="container-fluid ">
            <div id="content" className="container">
              {children}
            </div>
          </div>
        </div>
        <Footer userLoggedIn={!!user} />
      </div>
    );
  }
}
