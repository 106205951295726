import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Icon from "../../elements/base/Icon";

export default class ModalHeader extends PureComponent {
  static displayName = "ModalHeader";

  static propTypes = {
    /**
     * Class to apply to the modal-header div
     */
    className: PropTypes.string,
    /**
     * The function to execute when the close icon is clicked
     */
    close: PropTypes.func.isRequired,
    /**
     * Content to render inside the modal header
     */
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children, close, ...otherProps } = this.props;

    return (
      <div {...otherProps} className={classNames("modal-header", className)}>
        <div onClick={close} className="close">
          <Icon className="modal-header-close" type="cross" />
        </div>
        {children}
      </div>
    );
  }
}
