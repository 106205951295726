import { Track } from "../core/StructuralVariantsCuratedList";
import { Colors } from "../utils";

const MAX_DECIPHER_LINKS = 10;

const getDecipherLinks = ({ curated_data: { PATIENT: ids } }) =>
  (ids || []).slice(0, MAX_DECIPHER_LINKS).map(id => ({
    label: id,
    url: `https://decipher.sanger.ac.uk/patient/${id}#genotype`,
    target: "_blank",
  }));

// Will not work in standalone React (depends on Sapientia modal)
const getActions = ({ patient_id }, { curated_variant_id }) => {
  const data = `{ action: 'modal-ajax', href:'/patient/${patient_id}/variants/curated_variant/${curated_variant_id}'}`;
  const options = "{ fireContentLoaded: true }";
  return [
    "", // Add Space
    `<a href="javascript:void(0)" onclick="Sapientia.events.loadModal(${data}, ${options})">Show all curated data</a>`,
  ];
};

const oneOfVariantClass =
  variants =>
  ({ curated_data: { VARIANT_CLASS } }) =>
    variants.includes(VARIANT_CLASS);

export const createTrackConfig = ({
  name,
  description,
  curated_variant_list_id,
}) => ({
  id: `Congenica.StructuralVariantsCuratedList.${curated_variant_list_id}`,
  namespace: `Congenica.StructuralVariantsCuratedList`,
  name: name || "SV Curated List",
  info: description || "",
  tags: ["SV", "Curated List", "Decipher CNV"],
  category: "SV Curated List",
  url: `/API/Variant/CuratedVariantList.json?chr=__CHR__&start=__START__&end=__END__`,
  featureMenu: {
    actions: getActions,
    decipherLinks: feature => getDecipherLinks(feature),
  },
  featureParams: [
    {
      predicate: oneOfVariantClass([
        "Amplification",
        "Duplication",
        "Triplication",
        "Gain",
        "Duplication/Triplication",
      ]),
      legend: "Gain",
      color: Colors.BLUE_DARK,
    },
    {
      predicate: oneOfVariantClass(["Deletion"]),
      legend: "Loss",
      color: Colors.RED,
    },
    {
      predicate: () => true,
      legend: "Unknown",
      color: Colors.MINE_SHAFT,
    },
  ],
});

export const StructuralVariantsCuratedList = (config = {}) =>
  Track.extend(createTrackConfig(config));
