import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import FormControlWrapper from "./FormControlWrapper";
import FormGroup from "./FormGroup";
import Label from "./Label";

export default class Input extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    input: PropTypes.object,
    required: PropTypes.bool,
    narrow: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  render() {
    const {
      label = "",
      input = {},
      required = false,
      narrow = false,
      type = "",
      disabled = false,
    } = this.props;

    return (
      <FormGroup {...this.props}>
        {label && <Label label={label} required={required} narrow={narrow} />}
        <FormControlWrapper label={label} narrow={narrow}>
          <input
            className="form-control"
            {...input}
            type={type}
            disabled={disabled}
          />
        </FormControlWrapper>
      </FormGroup>
    );
  }
}
