import PropTypes from "prop-types";

import { CheckboxGroupValueProp } from "../utils/prop-types/CheckboxGroupValueProp";

const QualityFilterProp = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  })
);

export const ConfigFormFilterValues = {
  curatedListKnowledgebase: CheckboxGroupValueProp,
  curatedListBlacklist: CheckboxGroupValueProp,
  curatedListWhitelist: CheckboxGroupValueProp,
  vepConsequence: CheckboxGroupValueProp,
  customTier: CheckboxGroupValueProp,
  customPenetrance: CheckboxGroupValueProp,
  location: PropTypes.string.isRequired,
  genes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  coords: PropTypes.string,
  genePanels: CheckboxGroupValueProp,

  afMax: PropTypes.string,
  afExac: PropTypes.string,
  afExacAfr: PropTypes.string,
  afExacAmr: PropTypes.string,
  afExacEas: PropTypes.string,
  afExacFin: PropTypes.string,
  afExacNfe: PropTypes.string,
  afExacSas: PropTypes.string,
  afUk10k: PropTypes.string,
  afKg: PropTypes.string,

  depth: PropTypes.string,
  score: PropTypes.string,
  filterInclude: QualityFilterProp,
  filterExclude: QualityFilterProp,

  inheritance: CheckboxGroupValueProp,
  moi: CheckboxGroupValueProp,
  denovoStatus: CheckboxGroupValueProp,
  familyComparisons: CheckboxGroupValueProp,

  polyphenScore: PropTypes.string,
  siftScore: PropTypes.string,

  zygosity: CheckboxGroupValueProp,
};

export const ConfigFormColumnValues = {
  consequenceColumns: PropTypes.arrayOf(PropTypes.string),
  variantColumns: PropTypes.arrayOf(PropTypes.string),
};

export const ConfigFormValues = {
  ...ConfigFormFilterValues,
  columns: ConfigFormColumnValues.isRequired,
  prioritisation: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const ConfigFormValuesProp = PropTypes.shape(ConfigFormValues);
