import { FieldArray } from "formik";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";

import parseDecimalInt from "../../../utils/parseDecimalInt";

import { genePanelShape } from "./propTypes";

const TableContainer = memo(
  ({
    addRecord,
    removeRecord,
    selectedRecords,
    genePanels,
    tableComponent,
  }) => {
    const addGenePanel = useCallback(
      genePanelId => addRecord(parseDecimalInt(genePanelId)),
      [addRecord]
    );

    const removeGenePanel = useCallback(
      genePanelId => {
        const index = selectedRecords.findIndex(
          record => record === parseDecimalInt(genePanelId)
        );
        if (index >= 0) {
          removeRecord(parseDecimalInt(index));
        }
      },
      [selectedRecords, removeRecord]
    );

    const flaggedData = genePanels.map(panel => ({
      ...panel,
      isAdded: selectedRecords.includes(panel.genePanelId),
    }));

    return tableComponent({
      genePanels: flaggedData,
      addGenePanel,
      removeGenePanel,
    });
  }
);

const GenePanelsTableContainer = memo(
  ({ selectedRowNumber, genePanels, children: tableComponent }) => (
    <FieldArray name={`samples.${selectedRowNumber}.genePanelIds`}>
      {({
        push: addRecord,
        remove: removeRecord,
        form: {
          values: { samples = [] },
        },
      }) => (
        <TableContainer
          addRecord={addRecord}
          removeRecord={removeRecord}
          selectedRecords={samples[selectedRowNumber].genePanelIds}
          genePanels={genePanels}
          tableComponent={tableComponent}
        />
      )}
    </FieldArray>
  )
);

GenePanelsTableContainer.propTypes = {
  selectedRowNumber: PropTypes.number,
  genePanels: PropTypes.arrayOf(genePanelShape).isRequired,
  children: PropTypes.func.isRequired,
};

export default GenePanelsTableContainer;
