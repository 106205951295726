import { createSelector } from "@reduxjs/toolkit";
import { isNil } from "ramda";

/**
 * @deprecated use standard redux-form getFormValues,
 * mind modules/forms/form-specific-selectors.js
 */
export const getFormValues = createSelector(
  [
    state => state.form,
    (state, formName) => formName,
    (state, formName, ...otherValues) => otherValues,
  ],
  (formsBranch: {}, formName: string, otherValues: Array<string> = []): any => {
    const formState = formsBranch[formName];
    // if the form isn't yet initialise then return false
    if (isNil(formState) || !formState.values) {
      return false;
    }

    // At this point the form exists so the values should as well if things are being done correctly
    if (otherValues.length > 0) {
      let valueToReturn: Object = formState.values;
      otherValues.map(value => (valueToReturn = valueToReturn[value]));
      return valueToReturn;
    }

    // We assume at this point you just want all the form values
    const formStateValues = formState.values || {};
    // include missing empty fields
    const { registeredFields = {} } = formState;
    Object.keys(registeredFields).forEach(key => {
      if (!formStateValues[key]) {
        formStateValues[key] = undefined;
      }
    });

    return formStateValues;
  }
);
