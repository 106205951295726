import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const FilterSection = ({ section, isNested = false }) => {
  const { label: header, values: content, type } = section;

  if (!content || !content.length) {
    return null;
  }

  let filterSectionValue;

  if (type === "array") {
    filterSectionValue = (
      <ul className="filter-section-value">
        {content.map(({ label }) => (
          <li key={label}>
            <span className="glyphicon glyphicon-ok" /> {label}
          </li>
        ))}
      </ul>
    );
  } else if (type === "nested") {
    filterSectionValue = content.map(nestedSection => (
      <FilterSection
        section={nestedSection}
        key={nestedSection.label}
        isNested
      />
    ));
  } else {
    filterSectionValue = content.map(({ label, comparator, colon, values }) => (
      <p key={label} className="filter-section-wrapper">
        {label}
        {colon} {comparator} <strong>{values}</strong>
      </p>
    ));
  }
  return (
    <div
      key={header}
      className={classNames("filter-section", {
        nested: isNested,
      })}
    >
      <h2 className="filter-section-header">{header}</h2>
      {filterSectionValue}
    </div>
  );
};

FilterSection.propTypes = {
  section: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        comparator: PropTypes.string,
        colon: PropTypes.string,
        values: PropTypes.string,
      })
    ),
  }),
};

export default FilterSection;
