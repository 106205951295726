// @flow
import { pickAll } from "ramda";

import { configForm } from "modules/str/constants";
import type {
  FetchStrsRequestParams,
  RawStrItem,
  RawStrsResponse,
  Str,
  StrsPage,
  ApiResult,
  FetchGeneStrCountRequestParams,
  GeneStrCounts,
  RawGeneStrCountResponse,
} from "modules/str/flow-types";
import { fetchCamelizeData } from "modules/utils";
import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";
import type { FetchDataResponse } from "modules/utils/fetchData";
import { buildTableQueryParams } from "modules/utils/fetchData";

function adjustFilters(filters: any = {}) {
  const filterItems = {};

  const genePanels = filters[configForm.FILTER_GENE_PANELS] || [];
  if (!!genePanels.length) {
    filterItems["filter[gene_panel_ids]"] = genePanels.join(",");
  }

  const tiers = filters[configForm.FILTER_TIERS] || [];
  if (!!tiers.length) {
    filterItems["filter[tiers]"] = tiers.join(",");
  }

  const { variantId, geneId } = filters;

  if (variantId) {
    filterItems["filter[variant_id]"] = variantId;
  }

  if (geneId) {
    filterItems["filter[gene_id]"] = geneId;
  }

  return filterItems;
}

const extractStrs = (data: Array<RawStrItem>): Array<Str> =>
  data.map(
    ({ attributes: { gene, referenceImage, tieringData, ...rest }, id }) => {
      const variantAttrs = pickAll(
        [
          "chromosome",
          "position",
          "end",
          "repeatUnit",
          "referenceRepeatCount",
          "patientRepeatCount",
          "confidenceInterval",
          "genotype",
        ],
        rest
      );

      const geneAttrs =
        gene && gene.data
          ? {
              ...pickAll(
                ["name", "synonyms", "morbidId", "omimId", "genePanelIds"],
                gene.data.attributes
              ),
              geneId: gene.data.id,
            }
          : {};

      const imagePath =
        referenceImage && referenceImage.data
          ? referenceImage.data.attributes.url
          : null;

      return {
        ...variantAttrs,
        gene: geneAttrs,
        variantId: id,
        imagePath,
        tieringData: tieringData || [],
      };
    }
  );

export async function getStrs({
  projectId,
  patientId,
  pageParams,
  filters,
}: FetchStrsRequestParams): ApiResult<StrsPage> {
  const queryParams = buildTableQueryParams({
    ...pageParams,
    ...adjustFilters(filters),
  });

  const rawResponse: FetchDataResponse<RawStrsResponse> =
    await fetchCamelizeData(
      `${API_ENTITIES_BASE_URL}/projects/${projectId}/patients/${patientId}/strs?${queryParams}`
    );

  if (rawResponse.ok && rawResponse.payload) {
    const {
      data,
      meta: { count },
    } = rawResponse.payload;

    return {
      list: extractStrs(data),
      totalCount: count,
    };
  }

  const { errors } = rawResponse;
  return { errors };
}

export async function getGeneStrCounts(
  requestParams: FetchGeneStrCountRequestParams
): ApiResult<GeneStrCounts> {
  const { projectId, patientId, genePanelIds } = requestParams;

  const queryParams = `filter[gene_panel_ids]=${genePanelIds.join(",")}`;

  const rawResponse: FetchDataResponse<RawGeneStrCountResponse> =
    await fetchCamelizeData(
      `${API_ENTITIES_BASE_URL}/projects/${projectId}/patients/${patientId}/gene-str-count?${queryParams}`
    );

  if (rawResponse.ok && rawResponse.payload) {
    const {
      payload: { data },
    } = rawResponse;

    return data.map(({ id: geneId, attributes: { strCount } }) => ({
      geneId,
      strCount,
    }));
  }

  const { errors } = rawResponse;
  return { errors };
}
