import classNames from "classnames";
import React, { PureComponent } from "react";

export default class Tag extends PureComponent {
  render() {
    const {
      className,
      context = "default",
      children,
      ...otherProps
    } = this.props;
    return (
      <span
        className={classNames("tag label", `label-${context}`, className)}
        {...otherProps}
      >
        {children}
      </span>
    );
  }
}
