import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { formatDateTime } from "modules/utils/formatDate";

import DateLabel from "../date-label/DateLabel";

export default class TimelineComment extends PureComponent {
  static displayName = "TimelineComment";

  static propTypes = {
    /**
     * The author of the comment
     */
    username: PropTypes.string.isRequired,
    /**
     * Date of a comment
     */
    timestamp: PropTypes.number.isRequired,
    /**
     * Show or hide <TimelineDateLabel />
     */
    showDate: PropTypes.bool,
  };

  static defaultProps = {
    showDate: true,
  };

  render() {
    const { timestamp, children, username, showDate } = this.props;

    return (
      <div className="timeline-comment row">
        <div className="timeline-comment-date col-xs-2">
          {showDate && (
            <DateLabel className="pull-right" timestamp={timestamp} />
          )}
        </div>
        <div className="timeline-comment-container col-xs-10">
          <div className="timeline-comment-container-title">
            <span className="timeline-comment-container-title-username">
              {username}
            </span>
            <span className="timeline-comment-container-title-day">
              {formatDateTime(timestamp)}
            </span>
          </div>
          <div className="timeline-comment-container-body">{children}</div>
        </div>
      </div>
    );
  }
}
