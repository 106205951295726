import { prop } from "ramda";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { SortableWells, LoadingOverlay, Button } from "pattern-library";

import { getCurrentProjectFeatures } from "modules/project/selectors";
import calculateVariantPanelFields from "modules/utils/enum/variantPanelFields";

import { fetchVariantPanel, saveVariantPanel } from "../actions";
import {
  getVariantPanel,
  getVariantPanelLoadingError,
  getVariantPanelLoading,
} from "../selectors";

const getId = prop("id");

export const VariantPanel = ({
  projectId,
  loadingError,
  variantPanelSettings,
  features,
  saveVariantPanel,
  getVariantPanel,
  loading,
}) => {
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const [unusedSaved, setUnusedSaved] = useState([]);
  const [leftSaved, setLeftSaved] = useState([]);
  const [rightSaved, setRightSaved] = useState([]);

  const calculatedFields = useMemo(
    () => calculateVariantPanelFields(features),
    [features]
  );

  // Map array of field IDs from backend to corresponding full config/options,
  // and remove those that don't have a corresponding config
  // (e.g. those that have been turned off due to a feature requirement)
  const getMappedFields = useCallback(
    ids =>
      ids.reduce((accum, id) => {
        const mappedField = calculatedFields.get(id);

        if (mappedField) {
          accum.push(mappedField);
        }

        return accum;
      }, []),
    [calculatedFields]
  );

  const onChange = ([, left, right]) => {
    setLeft(left.items);
    setRight(right.items);
  };

  const onSave = useCallback(() => {
    const toSave = [left.map(getId), right.map(getId)];
    saveVariantPanel(toSave, projectId);
  }, [left, projectId, right, saveVariantPanel]);

  useEffect(() => {
    getVariantPanel(projectId);
  }, [getVariantPanel, projectId]);

  useEffect(() => {
    if (!variantPanelSettings || loading) {
      return;
    }
    const [savedLeft, savedRight] = variantPanelSettings;

    const alreadySetValues = savedLeft.concat(savedRight);

    const mappedLeft = getMappedFields(savedLeft);
    const mappedRight = getMappedFields(savedRight);
    const unset = [...calculatedFields.values()].filter(
      ({ id }) => !alreadySetValues.includes(id)
    );

    setLeft(mappedLeft);
    setRight(mappedRight);
    setLeftSaved(mappedLeft);
    setRightSaved(mappedRight);
    setUnusedSaved(unset);
  }, [
    calculatedFields,
    projectId,
    variantPanelSettings,
    features,
    getMappedFields,
    loading,
  ]);

  if (loadingError) {
    return (
      <p>
        Unable to load variant panel settings. Please try again later or contact
        support if this persists
      </p>
    );
  }

  return (
    <LoadingOverlay loading={loading}>
      <div className="row">
        <SortableWells
          className="clearfix"
          data={[
            {
              wellId: "1",
              title: "Available fields",
              className: "available-list ",
              columns: 4,
              items: unusedSaved,
              itemClassName:
                "settings-variant-panel__item settings-variant-panel__item--available",
            },
            {
              wellId: "2",
              title: "Column 1",
              columns: 4,
              items: leftSaved,
            },
            {
              wellId: "3",
              title: "Column 2",
              columns: 4,
              items: rightSaved,
            },
          ]}
          onChange={onChange}
        />
      </div>
      <div className="row">
        <div className="col-md-12">
          <Button
            disabled={loading}
            onClick={onSave}
            context="primary"
            className="pull-right"
          >
            Save panel structure
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = state => ({
  variantPanelSettings: getVariantPanel(state),
  features: getCurrentProjectFeatures(state),
  loading: getVariantPanelLoading(state),
  loadingError: getVariantPanelLoadingError(state),
});

const mapDispatchToProps = {
  getVariantPanel: fetchVariantPanel.start,
  saveVariantPanel: saveVariantPanel.start,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantPanel);
