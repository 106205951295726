import { memo } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import validator from "../../../forms/validator";

import PresetBaseForm from "./PresetBaseForm";
import SVPresetTabs from "./SV/SVTabs";
import * as constants from "./constants";
import * as selectors from "./selectors";
import { getValidationSchema } from "./validation";

type Props = {
  projectId: number | string;
  className?: string;
  isDisabled?: boolean;
  isDefaultDisabled?: boolean;
  submitCallback: FormSubmitCallback;
  filterPresets?: ReturnType<selectors.getSNVFilterPresets>;
};

export const SVPresetForm = memo((props: Props) => {
  const { submitCallback } = props;

  return (
    <div>
      <PresetBaseForm {...props} submitPreset={submitCallback}>
        <SVPresetTabs {...props} />
      </PresetBaseForm>
    </div>
  );
});

SVPresetForm.displayName = "SVPresetForm";

const mapStateToProps = state => ({
  isSubmitting: selectors.isPresetSubmitInProgress(state),
  filterPresets: selectors.getSVFilterPresets(state),
  initialValues: selectors.getSelectedPreset(state),
});

const connectedForm = reduxForm({
  form: constants.FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,

  asyncValidate: (values, _, props: Props) =>
    validator(getValidationSchema(props.filterPresets))(values),
})(SVPresetForm);

export default connect(mapStateToProps, null)(connectedForm);
