import lodash from "lodash";
import React, { memo } from "react";

interface DetailedLabelProps {
  label: string;
  spliceType?: string;
  spliceMotifType: string;
  denovo?: number;
  lost?: boolean;
}
const ScoreDetailedLabel = ({
  label,
  spliceType,
  spliceMotifType,
  denovo,
  lost,
}: DetailedLabelProps) => (
  <>
    {[label, lodash.upperFirst(spliceMotifType), "mutation"].join(" ")}
    {spliceType && <em>{` ${spliceType}`}</em>}
    {lost && (
      <em>
        <br />
        (loss)
      </em>
    )}
    {Boolean(denovo) && (
      <em>
        <br />
        (de novo)
      </em>
    )}
  </>
);

export default memo(ScoreDetailedLabel);
