import { set, lensPath } from "ramda";
import React, { useCallback } from "react";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

/**
 see https://confluence.congenica.net/display/DES/GUI+Data+Submission+-+High+Level+Design#GUIDataSubmission-HighLevelDesign-SamplesDataRestrictionsandValidationvalidation_rules
 */
export const ParentField = props => {
  const {
    field: { name },
    form: {
      values: { samples = [] },
      values,
      setValues,
    },
  } = props;

  //name is a string like "samples.2.motherName"
  const targetPath = name.split(".");
  //targetPath is a array like ["samples", "2", "motherName"]
  //and we need to parse index as number
  targetPath[1] = Number.parseInt(targetPath[1]);
  const index = targetPath[1];

  const { familyName } = samples[index];

  const onChange = useCallback(
    ({ target: { value: targetValue } }) => {
      if (!!targetValue) {
        //we will accumulate changes for a batch update
        const changes = [];

        const parentIndex = samples.findIndex(
          ({ name }) => name === targetValue
        );
        const { name: parentName, familyName: parentFamilyName } =
          samples[parentIndex];

        if (targetPath[2] === "motherName") {
          changes.push({
            path: ["samples", parentIndex, "sex"],
            value: "Female",
          });
        } else {
          changes.push({
            path: ["samples", parentIndex, "sex"],
            value: "Male",
          });
        }

        if (!parentFamilyName) {
          changes.push({
            path: ["samples", parentIndex, "familyName"],
            value: familyName,
          });
        } else {
          const siblingIndex = samples.findIndex(
            (
              {
                motherName: sampleMother,
                fatherName: sampleFather,
                familyName: sampleFamilyName,
              },
              sampleIndex
            ) => {
              if (parentIndex === sampleIndex || index === sampleIndex)
                return false;
              if (sampleMother === parentName || sampleFather === parentName) {
                return sampleFamilyName !== familyName;
              }
              return false;
            }
          );
          if (siblingIndex === -1) {
            changes.push({
              path: ["samples", parentIndex, "familyName"],
              value: familyName,
            });
          } else {
            changes.push({
              path: ["samples", index, "familyName"],
              value: samples[siblingIndex].familyName,
            });
          }
        }

        if (changes.length > 0) {
          let newValues = { ...values };
          changes.forEach(({ path, value }) => {
            //Returns the result of "setting" the portion of the given data structure focused by the given lens to the given value.
            newValues = set(lensPath(path), value, newValues);
          });
          newValues = set(lensPath(targetPath), targetValue, newValues);
          setValues(newValues);
        }
      }
    },
    [familyName, index, samples, setValues, targetPath, values]
  );

  return <FormikFormField onChange={onChange} {...props} />;
};
