import { CATALYST_BASE_URL } from "modules/utils/baseUrls";
import {
  fetchCamelizeData,
  fetchPerlEndpointData,
  getFormData,
} from "modules/utils/fetchData";

export const getGenePanelsLists = (projectId, archived = false) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panels?show_archived=${archived ? "1" : "0"}`
  );

export const getGenePanelInfo = (projectId, genePanelId) =>
  fetchPerlEndpointData(`/project/${projectId}/gene-panel/${genePanelId}`);

export const updateGenePanelInfo = (projectId, genePanelId, data) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/${genePanelId}/info`,
    { method: "POST", body: getFormData(data) }
  );

export const getGenePanelPatientList = (projectId, genePanelId) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/${genePanelId}/patients/list`
  );

export const getGenePanelGenesList = (projectId, genePanelId) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/${genePanelId}/genes/list`
  );

export const copyGenePanel = (projectId, genePanelId) =>
  fetchCamelizeData(`/project/${projectId}/gene-panel/add/${genePanelId}`, {
    method: "POST",
  });

export const addPatientToGenePanel = (projectId, genePanelId, patientId) =>
  fetchCamelizeData(
    `/project/${projectId}/gene-panel/${genePanelId}/patient/${patientId}/add`,
    { method: "POST" }
  );

export const removePatientFromGenePanel = (projectId, genePanelId, patientId) =>
  fetchCamelizeData(
    `/project/${projectId}/gene-panel/${genePanelId}/patient/${patientId}/delete`,
    { method: "POST" }
  );

export const addAllPatientsToGenePanel = (projectId, genePanelId) =>
  fetchCamelizeData(
    `/project/${projectId}/gene-panel/${genePanelId}/patients/add/all`,
    { method: "POST" }
  );

export const removeAllPatientsFromGenePanel = (projectId, genePanelId) =>
  fetchCamelizeData(
    `/project/${projectId}/gene-panel/${genePanelId}/patients/delete/all`,
    { method: "POST" }
  );

export const removeGeneFromGenePanel = (projectId, genePanelId, geneName) =>
  fetchCamelizeData(
    `/project/${projectId}/gene-panel/${genePanelId}/gene/${geneName}/delete`,
    { method: "POST" }
  );

export const removeGenePanel = (projectId, genePanelId) =>
  fetchCamelizeData(`/project/${projectId}/gene-panel/${genePanelId}/delete`, {
    method: "POST",
  });

export const archiveGenePanel = (projectId, genePanelId) =>
  fetchCamelizeData(`/project/${projectId}/gene-panel/${genePanelId}/archive`, {
    method: "POST",
  });

export const exportGenePanel = (
  projectId,
  genePanelId,
  { panelContent, panelFormat }
) => {
  const body = getFormData({
    "panel-content": panelContent,
    "panel-format": panelFormat,
  });
  return fetchCamelizeData(
    `/project/${projectId}/gene-panel/${genePanelId}/export`,
    { method: "POST", body },
    undefined,
    true
  );
};

export const addNewGenePanel = (
  projectId,
  title,
  description,
  ensemblVersion
) => {
  const body = getFormData({
    title,
    description,
    ensembl_version: ensemblVersion,
  });
  return fetchPerlEndpointData(`/project/${projectId}/gene-panel/add/new`, {
    method: "POST",
    body,
  });
};

export const uploadGenePanel = (projectId, csvFormat, file, ensemblVersion) => {
  const body = getFormData({
    csv_format: csvFormat,
    gene_panel_file: file,
    ensembl_version: ensemblVersion,
  });
  return fetchCamelizeData(
    `/project/${projectId}/gene-panel/add/upload/react`,
    {
      method: "POST",
      body,
      headers: {
        credentials: "include",
      },
    }
  );
};

export const getTempGenePanelFoundGenes = (projectId, tempGenePanelId) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/genes`
  );

export const getTempGenePanelNotFoundGenes = (projectId, tempGenePanelId) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/genes/not/found`
  );

export const getTempGenePanel = (projectId, tempGenePanelId) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/info`
  );

export const updateTempGenePanel = (projectId, tempGenePanelId, data) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/info`,
    { method: "POST", body: getFormData(data) }
  );

export const removeGeneFromTempGenePanel = (
  projectId,
  tempGenePanelId,
  geneId
) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/gene/${geneId}/delete`,
    {
      method: "POST",
      headers: {
        credentials: "include",
      },
    }
  );

export const saveTempGenePanel = (projectId, tempGenePanelId) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/save`,
    {
      method: "POST",
      headers: {
        credentials: "include",
      },
    }
  );

export const searchGenes = (projectId, genePanelId, query) =>
  fetchPerlEndpointData(
    `/gene/search?project_id=${projectId}&gene_panel_id=${genePanelId}&query=${encodeURI(
      query
    )}`,
    undefined,
    true
  );

export const addGeneToGenePanel = (
  projectId,
  genePanelId,
  geneName,
  data,
  query
) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/${genePanelId}/gene/${geneName}/add`,
    {
      method: "POST",
      body: getFormData({ ...(data || {}), query, no_redirect: 1 }),
    }
  );

export const fetchEditGeneDataOnTempGenePanel = (
  projectId,
  tempGenePanelId,
  geneName
) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/gene/${geneName}/edit`
  );

export const fetchEditGeneDataOnGenePanel = (
  projectId,
  genePanelId,
  geneName
) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/${genePanelId}/gene/${geneName}/edit`
  );

export const updateEditGeneDataOnTempGenePanel = (
  projectId,
  tempGenePanelId,
  geneName,
  data
) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/gene/${geneName}/edit`,
    {
      method: "POST",
      body: getFormData(data),
    }
  );

export const updateEditGeneDataOnGenePanel = (
  projectId,
  genePanelId,
  geneName,
  data
) =>
  fetchPerlEndpointData(
    `/project/${projectId}/gene-panel/${genePanelId}/gene/${geneName}/edit`,
    {
      method: "POST",
      body: getFormData(data),
    }
  );

export const getExistingGenePanels = projectId =>
  fetchPerlEndpointData(`/project/${projectId}/gene-panel/add/copy`);

export const addExistingGenePanel = (projectId, genePanelId) =>
  fetchPerlEndpointData(`/project/${projectId}/gene-panel/add/${genePanelId}`, {
    method: "POST",
    headers: {
      credentials: "include",
    },
  });

const parsePatientGenePanels = genePanels =>
  genePanels.map(panel => ({
    ...panel,
    isPatientGenePanel: Boolean(panel.isPatientGenePanel),
    archived: Boolean(panel.archived),
    genePanelId: Number(panel.genePanelId),
  }));

export const getPatientsProjectGenePanels = async patientId => {
  const result = await fetchCamelizeData(
    `${CATALYST_BASE_URL}/patient/${patientId}/project/gene-panels`
  );

  if (!result.ok) {
    return result;
  }

  return {
    ...result,
    payload: parsePatientGenePanels(result.payload.genePanels),
  };
};
