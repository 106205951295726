// @flow
import { isNil } from "ramda";

import EOF from "../EOF";
import { FileParseError } from "../errors";

import SimpleFileReader from "./SimpleFileReader";

class VCFReader {
  file: File;
  fileReader: SimpleFileReader;
  // variables for caching file reads
  _header_parsed: boolean = false;
  _headerLines: Array<string> = [];
  _sampleName: string = "";

  // file must be a file object like event from file upload onChange
  constructor(file: File) {
    this.file = file;
    this.fileReader = new SimpleFileReader(file);
  }

  async parseFileForHeader() {
    let result: EOF | Array<string> | void;

    while (isNil(result)) {
      const line: string | void | EOF = await this.fileReader.getNextLine();

      if (line instanceof EOF) {
        result = line;
        break;
      }

      if (typeof line === "string") {
        this._headerLines.push(line);

        if (this.lineIsFinalHeader(line)) {
          this._header_parsed = true;
          result = this._headerLines;
        }
      }
    }

    return result;
  }

  // will call user callback with undefined and err string on failure
  async getHeader() {
    if (this._header_parsed) {
      // we already have the lines so call the user provided callback
      // immediately
      return this._headerLines;
    } else {
      return await this.parseFileForHeader();
    }
  }

  lineIsHeader(vcfLine: string): boolean {
    return vcfLine.charAt(0) === "#";
  }

  lineIsFinalHeader(vcfLine: string) {
    // the last header line in a VCF file must begin with #CHROM
    return vcfLine.substring(0, 6) === "#CHROM";
  }

  // async method to read the header and extract the sample name from it
  // if there was an error getting it then error string will be sent to the user callback
  async getResult() {
    // if we have the sample name cached return that immediately
    if (this._sampleName) {
      return {
        name: this.file.name,
        id: this._sampleName,
      };
    } else {
      const error = false;
      const headerLines = await this.getHeader();

      if (headerLines instanceof EOF)
        throw new Error(
          "Reached the end of the file before finding #CHROM header line"
        );
      if (isNil(headerLines))
        throw new Error("Something went wrong reading the file");

      // if we got the header fine then try and get a sample name
      if (!error) {
        try {
          const finalLine = headerLines[headerLines.length - 1];

          this._sampleName = this.extractSampleName(finalLine);

          return {
            name: this.file.name,
            id: this._sampleName,
          };
        } catch (sampleErr) {
          throw new Error(sampleErr.message);
        }
      }
    }
  }

  extractSampleName(vcfLine: string) {
    if (this.lineIsFinalHeader(vcfLine)) {
      const row = vcfLine.split("\t");
      if (row.length !== 10) {
        // we will need a way to give user friendly errors
        throw new FileParseError(
          "Expecting 10 columns in VCF header line, but got " + row.length
        );
      } else {
        return row[row.length - 1];
      }
    } else {
      throw new FileParseError(
        "Expecting final VCF header line to begin with #CHROM, not " + vcfLine
      );
    }
  }
}

export default VCFReader;
