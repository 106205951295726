import { isEmpty, isNil } from "ramda";

import { fetchCamelizeData } from "modules/utils/fetchData";

export const fetchVariantHistory = (
  patientId,
  patientVariantId,
  variantId,
  variantType,
  geneName
) => {
  let url = "";

  if (isEmpty(patientVariantId) || isNil(patientVariantId)) {
    url =
      isEmpty(geneName) || isNil(geneName)
        ? `/patient/${patientId}/variant/${variantType}/${variantId}/audit.json`
        : `/patient/${patientId}/variant/${variantType}/${variantId}/gene/${geneName}/audit.json`;
  } else {
    url =
      isEmpty(geneName) || isNil(geneName)
        ? `/patient/${patientId}/variant/${patientVariantId}/audit.json`
        : `/patient/${patientId}/variant/${patientVariantId}/gene/${geneName}/audit.json`;
  }

  return fetchCamelizeData(url);
};
