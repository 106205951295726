import { isEmpty, isNil, compose } from "ramda";
import { put, call, select } from "redux-saga/effects";

import createAction from "./createAction";

import { normalise } from ".";

const queryParamsToString = (queryParams = {}) =>
  Object.entries(queryParams)
    .map(([key, value]) => {
      if (typeof value === "object") {
        value = encodeURIComponent(JSON.stringify(value));
      }
      return `${key}=${value}`;
    })
    .join("&");

const buildUrl = (action, urlTemplate) => {
  const { meta: { pathParams = {}, queryParams = {} } = {} } = action;

  let url = urlTemplate;
  // set path params
  // pathParams is an object of keys equal to names of substitution parameters and values equal to substitution values
  // e.g. if urlTemplate is '/webapi/entities/projects/:projectId' and pathParams are {projectId: 2}}
  // the resulting url is /webapi/entities/projects/2
  Object.entries(pathParams).forEach(([key, value]) => {
    url = url.replace(":" + key, value);
  });

  // add query params
  const queryParamsString = queryParamsToString(queryParams);
  if (queryParamsString.length) {
    url = `${url}?${queryParamsString}`;
  }

  return url;
};

const buildRequestParams = (httpRequestMethod, action) => {
  const { payload } = action;

  const params = {
    method: httpRequestMethod,
  };

  if (!isEmpty(payload)) {
    params.body = JSON.stringify(payload);
  }

  return params;
};

const errorActionCreator = ({
  httpRequestMethod,
  modelName,
  modelId,
  error,
}) => {
  let actionOperation;
  switch (httpRequestMethod) {
    case "GET":
      actionOperation = "READ";
      break;
    case "POST":
      actionOperation = "CREATE";
      break;
    case "PUT":
      actionOperation = "UPDATE";
      break;
    case "DELETE":
      actionOperation = "DELETE";
      break;
    default:
      throw new Error(`Unsupported HTTP request method ${httpRequestMethod}`);
  }
  const type = `${actionOperation}_${modelName}${
    modelId ? "" : "_LIST"
  }_ERROR`.toUpperCase();
  return createAction(type, error, { modelId });
};

const createExistsAction = ({ modelName, modelId }) =>
  createAction(`${modelName}${modelId ? "" : "_LIST"}_EXISTS`.toUpperCase());

/**
 * A simple saga generator. Can be used for loading/creating/deleting a list of all entities of a type or a single entity
 * @param modelName - an ORM Model name set in Model.modelName field
 * @param urlTemplate - a url template with substitution variables, e.g. webapi/entities/projects/:projectId.
 * The substitution values are specified in an action object. See buildUrl function for details
 * @param httpRequestMethod - an HTTP request method
 * @param mapData - function that maps data to a new format
 * @param nextActionCreator - an action creator that accepts an http response data after a successful http request
 * @param existsChecker - a selector used to verify if data present in state
 * @return {Function}
 */
export default function createSaga({
  modelName,
  urlTemplate,
  httpRequestMethod = "GET",
  mapData = (data: {}) => data,
  nextActionCreator,
  existsChecker = () => null,
}) {
  const preprocessData = compose(normalise, mapData);

  return function* (action) {
    const {
      meta: { modelId, rewriteExisting = false },
    } = action;
    if (!rewriteExisting) {
      const existingData = yield select(existsChecker, modelId);
      if (!isEmpty(existingData) && !isNil(existingData)) {
        console.log("data exists, no http request needed");
        return yield put(createExistsAction({ modelName, modelId }));
      }
    }

    const url = buildUrl(action, urlTemplate);
    const params = buildRequestParams(httpRequestMethod, action);

    try {
      const response = yield call(fetch, url, params);
      if (response.ok) {
        const body = yield call([response, response.json]);
        // normalise and change data format
        const processedBody = preprocessData(body);

        if (nextActionCreator) {
          return yield put(nextActionCreator(processedBody));
        }
      } else {
        return yield put(
          errorActionCreator({
            httpRequestMethod,
            modelName,
            modelId,
            error: response,
          })
        );
      }
    } catch (error) {
      return yield put(
        errorActionCreator({
          httpRequestMethod,
          modelName,
          modelId,
          error,
        })
      );
    }
  };
}
