import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { configForm } from "../../constants";
import { getConfigValues } from "../../selectors";

import ConfigForm from "./ConfigForm";

const mapStateToProps = state => {
  const { filters } = getConfigValues(state);
  return {
    initialValues: {
      ...filters,
    },
  };
};

const ConfigContainer = reduxForm({
  form: configForm.FORM_NAME,
  enableReinitialize: true,
})(ConfigForm);

export default connect(mapStateToProps)(ConfigContainer);
