import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { memo } from "react";

const GeneScore = ({ value, label = "", color }) =>
  !isNil(value) ? (
    <p>
      {color && (
        <span
          style={{
            backgroundColor: color,
          }}
          className="gene-score"
        />
      )}{" "}
      {label} {value}
    </p>
  ) : null;

GeneScore.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  color: PropTypes.string,
};

export default memo(GeneScore);
