import { path } from "ramda";

import * as constants from "./constants";

export const uiPath = (...items: any) => path([constants.NAME, "ui", ...items]);
export const dataPath = (...items: any) =>
  path([constants.NAME, "data", ...items]);

export const isLoggingIn = uiPath("loggingIn");
export const hasLoginFailed = uiPath("loginFailed");
export const checkedForLoggedInUser = uiPath("loadedInitialUser");
export const currentUser = dataPath("user");
export const isCurrentUserAdmin = dataPath("user", "admin");
