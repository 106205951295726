import { createAction } from "../utils";

import type { AcmgAuditInfo, AuditInfo } from "./types";

export const actionType = Object.freeze({
  FETCH_AUDIT_START: "FETCH_AUDIT_START",
  FETCH_AUDIT_SUCCESS: "FETCH_AUDIT_SUCCESS",
  FETCH_AUDIT_FAILURE: "FETCH_AUDIT_FAILURE",
  FETCH_ACMG_AUDIT_START: "FETCH_ACMG_AUDIT_START",
  FETCH_ACMG_AUDIT_SUCCESS: "FETCH_ACMG_AUDIT_SUCCESS",
  FETCH_ACMG_AUDIT_FAILURE: "FETCH_ACMG_AUDIT_FAILURE",
});

export const fetchAuditInfo: AsyncActionCreator = {
  start: (
    patientId: number
  ): FSA<typeof actionType.FETCH_AUDIT_START, number> =>
    createAction(actionType.FETCH_AUDIT_START, patientId),
  success: (
    data: AuditInfo
  ): FSA<typeof actionType.FETCH_AUDIT_SUCCESS, AuditInfo> =>
    createAction(actionType.FETCH_AUDIT_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_AUDIT_FAILURE> =>
    createAction(actionType.FETCH_AUDIT_FAILURE),
};

export const fetchAcmgAuditInfo: AsyncActionCreator = {
  start: (
    patientId: number
  ): FSA<typeof actionType.FETCH_ACMG_AUDIT_START, number> =>
    createAction(actionType.FETCH_ACMG_AUDIT_START, patientId),
  success: (
    data: AcmgAuditInfo
  ): FSA<typeof actionType.FETCH_ACMG_AUDIT_SUCCESS, AcmgAuditInfo> =>
    createAction(actionType.FETCH_ACMG_AUDIT_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_ACMG_AUDIT_FAILURE> =>
    createAction(actionType.FETCH_ACMG_AUDIT_FAILURE),
};

export type Action =
  | ReturnType<typeof fetchAuditInfo.start>
  | ReturnType<typeof fetchAuditInfo.success>
  | ReturnType<typeof fetchAuditInfo.failure>
  | ReturnType<typeof fetchAcmgAuditInfo.start>
  | ReturnType<typeof fetchAcmgAuditInfo.success>
  | ReturnType<typeof fetchAcmgAuditInfo.failure>;
