import classnames from "classnames";
import React, { useEffect, useCallback, useState, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  Button,
  Actions,
  LoadingOverlay,
  Icon,
  Divider,
} from "pattern-library";

import type { User } from "modules/auth/reducer";
import { currentUser } from "modules/auth/selectors";
import { showModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";
import {
  getCurrentProject,
  getProjectCurrentUser,
} from "modules/project/selectors";
import { formatDate } from "modules/utils";

import styles from "./GenePanels.module.scss";
import { fetchGenePanels } from "./actions";
import GenePanelsTable from "./components/GenePanelsTable";
import { getGenePanels, getParentGenePanels, getLoading } from "./selectors";

import congenicaApi from "api/congenica-api";

const { useGetThirdPartyDataVersionsQuery } = congenicaApi;

type Props = {
  projectId: number | string;
  loading: boolean;
  genePanels: ProjectGenePanel[];
  parentGenePanels: ProjectGenePanel[];
  fetchGenePanels: (...args) => void;
  currentProject: Project;
  projectCurrentUser: ProjectCurrentUser;
  showModal: (modalType: string, modalPayload?: any) => any;
  currentUser: User | false;
};

export const GenePanels: React.FC<Props> = ({
  loading,
  projectId,
  genePanels,
  parentGenePanels,
  fetchGenePanels,
  currentProject,
  projectCurrentUser,
  currentUser,
  showModal,
}) => {
  const { code } = currentProject || { code: "" };
  const [showArchived, setShowArchived] = useState(false);
  const toggleShowArchived = () => setShowArchived(!showArchived);

  const { data: ensemblVersions = [], isLoading: ensemblVersionsLoading } =
    useGetThirdPartyDataVersionsQuery({
      name: "ENSEMBL",
    });

  const getGenePanelActions = useCallback(
    (code, genePanel, inherited) => {
      const actions = [
        {
          icon: "pencil",
          className: "btn-default btn-xs",
          action: () => {
            showModal(modalTypes.EDIT_GENE_PANEL_MODAL, {
              projectId,
              genePanelId: genePanel.genePanelId,
              inherited,
            });
          },
          tooltip: {
            content: "Edit panel",
          },
          isDisabled: Boolean(genePanel.archived),
        },
        {
          icon: "export",
          className: "btn-default btn-xs",
          action: () => {
            showModal(modalTypes.EXPORT_GENE_PANEL_MODAL, {
              projectId,
              genePanelId: genePanel.genePanelId,
            });
          },
          tooltip: {
            content: "Export panel",
          },
        },
      ];

      if (!inherited) {
        actions.push(
          {
            icon: "briefcase",
            className: "btn-default btn-xs",
            action: () => {
              showModal(modalTypes.ARCHIVE_GENE_PANEL_MODAL, {
                projectId,
                genePanelId: genePanel.genePanelId,
              });
            },
            tooltip: {
              content: "Archive panel",
            },
            isDisabled: Boolean(genePanel.archived),
          },
          {
            icon: "trash",
            className: "btn-default btn-xs",
            action: () => {
              showModal(modalTypes.DELETE_GENE_PANEL_MODAL, {
                projectId,
                genePanelId: genePanel.genePanelId,
              });
            },
            tooltip: {
              content: "Remove panel",
            },
          }
        );
      }
      return <Actions sticky group justifyContent="start" actions={actions} />;
    },
    [showModal, projectId]
  );

  const getColumns = useCallback(
    (inherited, currentUser, showEnsemblVersion) => {
      const genePanelsColumns: any[] = [
        {
          Header: "ID",
          accessor: "genePanelId",
          headerClassName: "col-md-1",
        },
        {
          Header: "Gene panel",
          accessor: "title",
          headerClassName: "col-md-2",
        },
        showEnsemblVersion
          ? {
              Header: "Ensembl version",
              accessor: "ensemblVersion",
            }
          : null,
        {
          Header: "Description",
          accessor: "description",
          headerClassName: "col-md-2",
        },
      ];

      if (inherited) {
        genePanelsColumns.push({
          Header: "Parent project",
          accessor: "projectCode",
          headerClassName: "col-md-1",
        });
      }

      genePanelsColumns.push(
        {
          Header: "Base panel",
          accessor: "basePanelTitle",
          headerClassName: "col-md-2",
        },
        {
          Header: "Patients",
          accessor: "patientsCount",
          headerClassName: "col-md-1",
          Cell: ({
            row: {
              original: { patientsCount, totalPatientsCount },
            },
          }) =>
            `${patientsCount}${
              totalPatientsCount > patientsCount
                ? ` ( ${totalPatientsCount} in all projects )`
                : ""
            }`,
        },
        {
          Header: "Genes",
          accessor: "genesCount",
          headerClassName: "col-md-1",
        },
        {
          Header: "Last updated",
          accessor: "updated",
          headerClassName: "col-md-1",
          Cell: ({
            row: {
              original: { updated },
            },
          }) => formatDate(updated * 1000),
        }
      );

      if (showArchived) {
        genePanelsColumns.push({
          Header: "Archived",
          accessor: "archived",
          headerClassName: "col-md-1",
          Cell: ({
            row: {
              original: { archived },
            },
          }) => {
            const iconClass = archived ? "tick" : "cross";
            return <Icon type={iconClass} />;
          },
          disableGlobalFilter: true,
        });
      }

      if (
        (currentUser && currentUser.admin) ||
        (projectCurrentUser &&
          projectCurrentUser.permissions &&
          projectCurrentUser.permissions.addEditGenePanels)
      ) {
        genePanelsColumns.push({
          Header: "Actions",
          headerClassName: "col-md-2",
          accessor: genePanel =>
            getGenePanelActions(code, genePanel, inherited),
          disableSortBy: true,
          disableGlobalFilter: true,
        });
      }

      return genePanelsColumns.filter(Boolean);
    },
    [code, getGenePanelActions, projectCurrentUser, showArchived]
  );

  useEffect(() => {
    fetchGenePanels(projectId, showArchived);
  }, [projectId, fetchGenePanels, showArchived]);

  const showEnsemblVersionsColumn = useMemo(
    () => ensemblVersions.length > 1,
    [ensemblVersions]
  );
  const genePanelsColumns = getColumns(
    false,
    currentUser,
    showEnsemblVersionsColumn
  );
  const parentGenePanelColumns = getColumns(
    true,
    currentUser,
    showEnsemblVersionsColumn
  );
  const genePanelsTitle = `Gene Panels in ${code}`;

  const genePanelTables = (
    <div className={styles.component}>
      <div id="project-gene-panels-table_wrapper">
        <GenePanelsTable
          genePanels={genePanels}
          columns={genePanelsColumns}
          title={genePanelsTitle}
          ensemblVersions={ensemblVersions}
          isLoading={loading}
          id="projectGenePanels"
        />
        <div className={classnames("clearfix", styles.componentButtons)}>
          <div className="table-buttons inline-buttons">
            <Button
              context="primary"
              onClick={() => {
                showModal(modalTypes.ADD_GENE_PANEL_MODAL);
              }}
            >
              Add gene panel
            </Button>
            <Button onClick={toggleShowArchived}>
              {showArchived ? "Hide archived" : "Show archived"}
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-4">
        <Divider />
      </div>
      <GenePanelsTable
        genePanels={parentGenePanels}
        columns={parentGenePanelColumns}
        title="Gene Panels in parent projects"
        ensemblVersions={ensemblVersions}
        isLoading={loading}
        id="parentGenePanels"
      />
    </div>
  );

  return (
    <LoadingOverlay loading={loading || ensemblVersionsLoading}>
      {genePanelTables}
    </LoadingOverlay>
  );
};

const mapStateToProps = createStructuredSelector({
  genePanels: getGenePanels,
  parentGenePanels: getParentGenePanels,
  loading: getLoading,
  currentProject: getCurrentProject,
  projectCurrentUser: getProjectCurrentUser,
  currentUser,
});

const mapDispatchToProps = {
  fetchGenePanels: fetchGenePanels.start,
  showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenePanels) as React.FC<Pick<Props, "projectId">>;
