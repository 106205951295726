import { Field, FieldArray } from "formik";
import React, { PureComponent } from "react";

import { Heading, Label, Checkbox, Divider } from "pattern-library";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

export default class Coverage extends PureComponent {
  render() {
    const { isInherited, coverage = [] } = this.props;
    const coverageSet = [1, 10, 20, 30, 40, 50];

    return (
      <div className="form-horizontal">
        <Heading className="accordion-section-subheader" type="h4">
          Gene coverage
        </Heading>
        <div className="row form-group">
          <Label className="control-label col-sm-3">Default depths</Label>
          <div className="col-sm-9">
            <div className="checkbox-list">
              <FieldArray
                name="geneCoverage.coverage"
                render={arrayHelpers => (
                  <div className="checkbox-list">
                    {coverageSet.map(label => {
                      const index = coverage.indexOf(label);
                      return (
                        <Checkbox
                          label={`${label}x`}
                          key={`${label}x`}
                          name={`${label}x`}
                          disabled={isInherited}
                          value={index >= 0}
                          onChange={e => {
                            if (e.target.checked) arrayHelpers.push(label);
                            else {
                              arrayHelpers.remove(index);
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              />
            </div>
            <small className="text-muted">
              Which coverage depths appear by default on the gene coverage page
            </small>
          </div>
        </div>
        <Divider />
        <Field
          name="geneCoverage.locked"
          key="locked"
          component={FormikFormField}
          label="Locked"
          narrow={9}
          disabled={isInherited}
          description="Block users from modifying this filter"
          type="checkbox"
        />
        <Divider />
        <Field
          name="geneCoverage.defaultDepth"
          component={FormikFormField}
          label="Custom coverage depth"
          narrow={9}
          disabled={isInherited}
          description="Set up an arbitrary coverage depth to show if the coverage depth you require is not available"
          type="number"
          inputFilter={/^\d*$/}
        />
        <Heading className="accordion-section-subheader" type="h4">
          Coverage statistic
        </Heading>

        <Field
          name="coverageStats.bufferLength"
          component={FormikFormField}
          label="Exon buffer length"
          narrow={9}
          disabled={isInherited}
          description="How many basepairs to include before and after each exon when calculating coverage statistics. (Default: 5)"
          type="number"
          inputFilter={/^\d*$/}
        />
        <Divider />

        <Field
          name="coverageStats.coverageRegions"
          component={FormikFormField}
          label="Coding regions"
          narrow={9}
          disabled={isInherited}
          description="How much of a transcript checked when calculating coverage"
          type="dropdown"
          options={{
            coding_only: "Coding regions only",
            coding_exons:
              "All coding exons (including their non-coding regions)",
            full_exons: "All exons (including non-coding)",
          }}
        />
      </div>
    );
  }
}
