import React from "react";

import { Tag, Tooltip, Actions } from "pattern-library";

import {
  DELETE_PROJECT_USER,
  EDIT_PROJECT_USER,
} from "modules/projectUsers/constants";
import { formatDate } from "modules/utils";

export const canEdit = (user, currentUser, projectCurrentUser) =>
  currentUser.admin ||
  (currentUser.id !== user.id &&
    user.accessLevel !== "admin" &&
    projectCurrentUser.canAdminProject);

export const rolesProcessor = roles => {
  if (roles) {
    return roles.map(({ name, label, description }) => (
      <Tooltip key={name} placement="left" content={description}>
        <Tag className={`role-${label.replace(/\s+/g, "-").toLowerCase()}`}>
          {label}
        </Tag>
      </Tooltip>
    ));
  }
  return null;
};

export const lastLoginProcessor = lastlogin =>
  lastlogin ? formatDate(lastlogin * 1000) : "-";

const visibilityLevelProcessor = (
  visibilityLevelName,
  visibilityLevelDescription
) =>
  visibilityLevelName ? (
    <Tooltip placement="left" content={visibilityLevelDescription}>
      <p>{visibilityLevelName}</p>
    </Tooltip>
  ) : (
    ""
  );

export const getColumns = (
  inherited,
  patientVisibilityEnabled,
  currentUser,
  projectCurrentUser,
  setUserInfo
) => {
  const columns = [
    {
      Header: "Name",
      accessor: "fullname",
    },
    {
      Header: "Email",
      accessor: "email",
    },
  ];

  if (inherited) {
    columns.push({
      Header: "Parent project",
      accessor: "projectCode",
    });
  }

  columns.push(
    {
      Header: "Access",
      disableSortBy: true,
      accessor: ({ roles = [] }) => rolesProcessor(roles),
    },
    {
      Header: "Auth Provider",
      accessor: ({ authenticationProviderName }) =>
        authenticationProviderName || "Internal",
      id: "authenticationProviderName",
    },
    {
      Header: "Last Login",
      accessor: "lastlogin",
      id: "lastlogin",
      Cell: ({
        row: {
          original: { lastlogin },
        },
      }) => lastLoginProcessor(lastlogin),
    }
  );

  if (patientVisibilityEnabled) {
    columns.push({
      Header: "Visibility Levels",
      accessor: ({ visibilityLevelName, visibilityLevelDescription }) =>
        visibilityLevelProcessor(
          visibilityLevelName,
          visibilityLevelDescription
        ),
      id: "visibilityLevelId",
    });
  }

  if (!inherited) {
    columns.push({
      Header: "Actions",
      id: "actions",
      disableSortBy: true,
      accessor: user => {
        if (canEdit(user, currentUser, projectCurrentUser)) {
          const actions = [
            {
              icon: "pencil",
              "data-testid": "edit-user-btn",
              className: "btn-default btn-xs",
              action: e => {
                e.stopPropagation();
                setUserInfo({ ...user }, EDIT_PROJECT_USER);
              },
            },
            {
              icon: "trash",
              "data-testid": "delete-user-btn",
              className: "btn-default btn--small",
              action: e => {
                e.stopPropagation();
                setUserInfo({ ...user }, DELETE_PROJECT_USER);
              },
            },
          ];
          return <Actions sticky justifyContent="start" actions={actions} />;
        }
      },
    });
  }
  return columns;
};
