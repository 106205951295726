// @flow

import { omit, pick } from "ramda";

import type { ConfigExclusions } from "../../../config/flow-types";
import { adjustColumns } from "../../../config/utils";

import { PRESET_ATTRIBUTES_NAMES } from "./constants";
import type {
  FilterPreset,
  SNVFilterPreset,
  FilterPresetBase,
  SNVFilterPresetFormValues,
} from "./types";

export const convertFormValues = (
  presetFormValues: SNVFilterPresetFormValues
): FilterPreset => {
  const { columns, prioritisation } = presetFormValues;

  const filters = omit(
    [...PRESET_ATTRIBUTES_NAMES, "columns", "prioritisation"],
    presetFormValues
  );

  // it'd be better to fix ramda pick function declaration but flow doesn't support module extension (https://github.com/facebook/flow/issues/396)
  // $FlowFixMe
  const presetBase: FilterPresetBase = pick(
    PRESET_ATTRIBUTES_NAMES,
    presetFormValues
  );

  return {
    ...presetBase,
    config: {
      columns,
      filters,
      prioritisation,
    },
  };
};

export const filterSNVExclusions = (
  snvPreset: SNVFilterPreset,
  exclusions: ConfigExclusions
) => {
  const { prioritisation: prioritisationExcl, filters: filtersExcl } =
    exclusions;
  const { config } = { ...snvPreset };
  const { filters, columns, prioritisation } = config;

  return {
    ...snvPreset,
    config: {
      ...config,
      columns: adjustColumns(columns, exclusions),
      filters: Object.keys(filters)
        .filter(
          key =>
            !filtersExcl.includes(key) ||
            (filtersExcl.includes(key) && Array.isArray(filters[key]))
        )
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: filtersExcl.includes(key) ? [] : filters[key],
          }),
          {}
        ),
      prioritisation: prioritisation.filter(
        p => !prioritisationExcl.includes(p)
      ),
    },
  };
};
