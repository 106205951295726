import { bindActionCreators } from "@reduxjs/toolkit";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Modal, ModalHeader, ModalBody, MODAL_SIZES } from "pattern-library";

import { submitForm } from "../../forms/actions";
import * as actions from "../actions";
import * as selectors from "../selectors";

import AddEvidenceForm from "./Forms/AddEvidenceForm";

export class AddEvidenceModal extends PureComponent {
  render() {
    const { criteria, selectedCriteria = [], resetCriteriaToAdd } = this.props;
    if (!criteria) {
      return null;
    }

    // If we are updating criteria we need to use the previously set values
    // otherwise we take them from the criteria
    const currentSelectedCriteria = selectedCriteria.filter(
      item => item.criteriaId === criteria.id
    );
    const strength = currentSelectedCriteria.length
      ? currentSelectedCriteria[0].strength.id
      : criteria.strength.id;
    const comment = currentSelectedCriteria.length
      ? currentSelectedCriteria[0].comment
      : "";

    let type = "Add";

    // Check if we have already added this item and are just updating the comment and strength
    selectedCriteria.forEach(item => {
      if (item.criteriaId === criteria.id) {
        type = "Update";
      }
    });

    return (
      <Modal
        size={MODAL_SIZES.L}
        close={resetCriteriaToAdd}
        show={criteria.id !== false}
      >
        <ModalHeader close={resetCriteriaToAdd}>
          {type} evidence for {criteria.name}
        </ModalHeader>
        <ModalBody>
          <AddEvidenceForm
            initialValues={{ criteriaStrength: strength, comment }}
            close={resetCriteriaToAdd}
            {...this.props}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(
  createStructuredSelector({
    criteria: selectors.getCriteriaToAdd, // The current one to interact with
    selectedCriteria: selectors.getSelectedCriteriaSelector, // The current added criteria
  }),
  dispatch =>
    bindActionCreators(
      {
        ...actions,
        submitForm,
      },
      dispatch
    )
)(AddEvidenceModal);
