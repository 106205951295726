import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getOrReloadVersions } from "./actions";
import Versions from "./components/Versions";
import { getVersionsSummary, isVersionsSummaryLoading } from "./selectors";

export const VersionsContainer = ({
  versions,
  loading,
  getOrReloadVersions,
}) => {
  useEffect(() => {
    getOrReloadVersions();
  }, [getOrReloadVersions]);
  return <Versions versions={versions} loading={loading} />;
};

const mapStateToProps = state => ({
  loading: isVersionsSummaryLoading(state),
  versions: getVersionsSummary(state),
});

const mapDispatchToProps = {
  getOrReloadVersions,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionsContainer);
