import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Slidedown from "../../elements/base/Slidedown";

export default class AccordionSectionContent extends PureComponent {
  static displayName = "AccordionSectionContent";

  static propTypes = {
    /**
     * section content
     */
    children: PropTypes.node,
    /**
     * specified if a section is expanded/collapsed
     */
    isOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  render() {
    const { isOpen, children } = this.props;

    return (
      <Slidedown>
        {isOpen && <div className="accordion-section-content">{children}</div>}
      </Slidedown>
    );
  }
}
