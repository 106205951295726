import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { InfoField } from "pattern-library";

export default class InfoList extends PureComponent {
  static displayName = "InfoList";

  static propTypes = {
    /**
     * list of info items
     */
    data: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Text inside info field
         */
        text: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
          .isRequired,
        /**
         * The type of icon to render inside info field
         */
        icon: PropTypes.string.isRequired,
        /**
         * The classname of info field
         */
        className: PropTypes.string,
      })
    ).isRequired,
  };

  render() {
    const { data } = this.props;
    return (
      <ul className="info-list">
        {data.map(({ icon, text, className, key }) => (
          <li key={key || text}>
            <InfoField
              text={text}
              type={icon}
              position="left"
              className={className}
            />
          </li>
        ))}
      </ul>
    );
  }
}
