import { createAction } from "modules/utils";

import { STATUS } from "./types";

export const actionType = Object.freeze({
  SET_FILE: "SET_FILE",
  CHANGE_STATUS: "CHANGE_STATUS",
  CHANGE_PROGRESS: "CHANGE_PROGRESS",
});

export type SetFileAction = FSA<typeof actionType.SET_FILE, File | null>;
export const setFile = (file: File | null): SetFileAction =>
  createAction(actionType.SET_FILE, file);

export type ChangeStatusAction = FSA<typeof actionType.CHANGE_STATUS, STATUS>;
export const changeStatus = (status: STATUS): ChangeStatusAction =>
  createAction(actionType.CHANGE_STATUS, status);

export type ChangeProgressAction = FSA<
  typeof actionType.CHANGE_PROGRESS,
  number
>;
export const changeProgress = (progress: number): ChangeProgressAction =>
  createAction(actionType.CHANGE_PROGRESS, progress);

export type Action = SetFileAction | ChangeStatusAction | ChangeProgressAction;
