import React, { useState, useCallback, memo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button } from "pattern-library";

import { updateTempGenePanel } from "../../../../actions";
import { UPLOAD_GENE_PANEL_INFO_FORM_NAME } from "../../../../constants";
import {
  getTemporaryGenePanel,
  getTemporaryGenePanelFormData,
} from "../../../../selectors";
import { TempGenePanel } from "../../../../types";

interface Props extends PropsFromRedux {
  projectId: number;
  tempGenePanelId: number;
}

const NameAndDescriptionForm = memo(
  ({
    handleSubmit,
    updateTempGenePanel,
    tempGenePanel,
    projectId,
    tempGenePanelId,
  }: Props & InjectedFormProps<Index, Props>) => {
    const [editing, setEditing] = useState(false);

    const onFormSubmit = useCallback(
      ({ title, description }) => {
        updateTempGenePanel(projectId, tempGenePanelId, { title, description });
        setEditing(false);
      },
      [updateTempGenePanel, projectId, tempGenePanelId, setEditing]
    );

    if (!tempGenePanel) return null;

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div id="info">
          <table className="table table-striped table-hover">
            <tbody>
              <tr>
                <td className="col-md-6">Gene panel</td>
                <td className="col-md-6">
                  {editing ? (
                    <Field
                      name="title"
                      className="form-control"
                      type="text"
                      component="input"
                      value={tempGenePanel.title}
                    />
                  ) : (
                    tempGenePanel.title
                  )}
                </td>
              </tr>

              <tr>
                <td className="col-md-6">Description</td>
                <td className="col-md-6">
                  {editing ? (
                    <Field
                      name="description"
                      className="form-control"
                      component="textarea"
                      value={tempGenePanel.description}
                    />
                  ) : (
                    tempGenePanel.description
                  )}
                </td>
              </tr>
              {!editing && tempGenePanel.ensemblVersion && (
                <tr>
                  <td className="col-md-6">Ensembl version</td>
                  <td className="col-md-6">{tempGenePanel.ensemblVersion}</td>
                </tr>
              )}
            </tbody>
          </table>
          {editing ? (
            <div className="pull-right">
              <Button
                type="button"
                onClick={() => {
                  setEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button context="primary" type="submit">
                Save panel
              </Button>
            </div>
          ) : (
            <div className="pull-right">
              <Button
                context="primary"
                type="button"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Edit panel
              </Button>
            </div>
          )}
          <div className="clearfix" />
        </div>
      </form>
    );
  }
);

const mapStateToProps = state => ({
  tempGenePanel: getTemporaryGenePanel(state) as TempGenePanel,
  initialValues: getTemporaryGenePanelFormData(state),
});

const mapDispatchToProps = {
  updateTempGenePanel: updateTempGenePanel.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  reduxForm<Index, Props>({
    form: UPLOAD_GENE_PANEL_INFO_FORM_NAME,
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(NameAndDescriptionForm)
);
