// Create menu items and generate links
export const createMenu = (items, options) => {
  const settings = {
    divider: "; <br />",
    target: "_blank",
    ...options,
  };

  const attemptCreateLink = value => {
    const { label, url, target = settings.target } = value;
    if (label && url) {
      return `<a href="${url}" target="${target}">${label}</a>`;
    }
    return label || value;
  };

  const parseValue = value => {
    if (Array.isArray(value)) {
      return value
        .map(element => attemptCreateLink(element))
        .join(settings.divider);
    }
    return value ? attemptCreateLink(value) : value;
  };

  return items
    .filter(({ value }) => value !== undefined)
    .map(({ label, value }) => ({
      [label]: parseValue(value),
    }))
    .reduce(
      (menu, element) => ({
        ...menu,
        ...element,
      }),
      {}
    );
};

/*
 * Display Table inside menu
 * head: [table-header-col1, table-header-col2],
 * body: [
 *  [table-row1-col1, table-row1-col2],
 *  [table-row2-col1, table-row2-col2]
 * ]
 */
export const createTable = ({ head, body }) => {
  const reducer = (accumulator, current) => {
    const [key, ...body] = current;
    accumulator[key] = body;
    return accumulator;
  };
  return [
    head.map(col => `<b>${col}</b>`),
    ...body.map(row => row.map(col => `<span>${col}</span>`)),
  ].reduce(reducer, {});
};

export const createBadge = color => {
  const props = {
    border: "solid white 1px",
    float: "left",
    width: "1.5em",
    height: "1em",
    margin: "0 4px 0 0",
    "background-color": color,
  };
  const style = Object.entries(props)
    .map(([key, value]) => `${key}:${value};`)
    .join("");
  return `<div style="${style}"></div>`;
};
