import { createAction } from "modules/utils";

import * as constants from "./constants";
import { ConfigValues, PatientEntityPayload } from "./types";

export const fetchPatientEntity = {
  start: (
    patientId: number
  ): FSA<
    typeof constants.FETCH_PATIENT_CONFIG_ENTITY_START,
    { patientId: number }
  > =>
    createAction(constants.FETCH_PATIENT_CONFIG_ENTITY_START, {
      patientId,
    }),
  success: (
    data: PatientEntity
  ): FSA<
    typeof constants.FETCH_PATIENT_CONFIG_ENTITY_SUCCESS,
    PatientEntityPayload
  > => createAction(constants.FETCH_PATIENT_CONFIG_ENTITY_SUCCESS, { data }),
  failure: (): FSA<typeof constants.FETCH_PATIENT_CONFIG_ENTITY_FAILURE> =>
    createAction(constants.FETCH_PATIENT_CONFIG_ENTITY_FAILURE),
};

type UpdateConfigValuesAction = FSA<
  typeof constants.UPDATE_CONFIG_VALUES,
  ConfigValues
>;
export type ApplyAriadneSuggestions = FSA<
  typeof constants.APPLY_ARIADNE_SUGGESTIONS,
  { keepGenePanels: boolean; patientId: string | number }
>;
export type ResetConfigAction = FSA<
  typeof constants.RESET_CONFIG,
  Index,
  { patientId: string | number }
>;
export type UpdateConfigAction = FSA<
  typeof constants.UPDATE_CONFIG,
  Index,
  { patientId: string | number }
>;

export const applyAriadneSuggestions = (
  patientId: number | string,
  keepGenePanels = true
): ApplyAriadneSuggestions =>
  createAction(constants.APPLY_ARIADNE_SUGGESTIONS, {
    keepGenePanels,
    patientId,
  });

export const resetConfig = (patientId: number): ResetConfigAction =>
  createAction(
    constants.RESET_CONFIG,
    {},
    {
      patientId,
    }
  );

export const updateConfig = (patientId: string | number): UpdateConfigAction =>
  createAction(constants.UPDATE_CONFIG, {}, { patientId });

export const updateConfigValues = (
  values: ConfigValues
): UpdateConfigValuesAction =>
  createAction(constants.UPDATE_CONFIG_VALUES, values);

export type Action =
  | ApplyAriadneSuggestions
  | UpdateConfigValuesAction
  | ResetConfigAction
  | UpdateConfigAction
  | ReturnType<typeof fetchPatientEntity.start>
  | ReturnType<typeof fetchPatientEntity.success>
  | ReturnType<typeof fetchPatientEntity.failure>;
