// @flow
import { not } from "ramda";

export const apiAction = (
  modelName: string,
  payload: {},
  type: string = "READ",
  meta: {} = {},
  saga: string = "EVERY"
) => {
  const acceptableVerbs = ["CREATE", "READ", "UPDATE", "DELETE", "LIST"];

  // if an unacceptable verb is used
  if (not(acceptableVerbs.includes(type))) {
    throw new Error(`Unknown API verb type of ${type} passed to apiAction`);
  }

  return {
    type: `API${saga === "LATEST" ? "/LATEST" : ""}/${type}`,
    payload,
    meta: {
      modelName,
      ...meta,
    },
  };
};
