import { Model } from "redux-orm";

export default class BaseModel extends Model {
  static basePath = "/catalyst/api";
  static useId = ["READ", "UPDATE", "DELETE"];
  static useRelatedId = ["READ"];

  static methodMap = {
    CREATE: "post",
    READ: "get",
    UPDATE: "put",
    DELETE: "delete",
    LIST: "get",
  };

  static getMethodUrlMap() {
    const apiResourceName = this.getAPIModelName();
    return {
      CREATE: `${this.basePath}/${apiResourceName}`,
      READ: `${this.basePath}/${apiResourceName}/:id/:relatedModelName/:relatedModelId`,
      UPDATE: `${this.basePath}/${apiResourceName}/:id`,
      DELETE: `${this.basePath}/${apiResourceName}/:id`,
      LIST: `${this.basePath}/${apiResourceName}`,
    };
  }

  static getAPIModelName() {
    return this.modelName.toLowerCase();
  }

  static getAPIOptions(
    verb,
    id = false,
    RelatedModel = false,
    relatedModelId = false
  ) {
    const methodUrlMap = this.getMethodUrlMap();
    const method = this.methodMap[verb];
    let url = methodUrlMap[verb];

    if (this.useId.includes(verb)) {
      if (id === false) {
        throw new Error(
          `You need to specify an ID when using the verb ${verb}`
        );
      }

      url = url.replace(":id", id);

      if (RelatedModel) {
        const relatedModelName = RelatedModel.getAPIModelName();
        if (
          this.useId.indexOf(verb) > -1 &&
          relatedModelName &&
          relatedModelId
        ) {
          url = url.replace(
            ":relatedModelName/:relatedModelId",
            `${relatedModelName}/${relatedModelId}`
          );
        } else if (this.useId.indexOf(verb) > -1 && relatedModelName) {
          url = url.replace(
            ":relatedModelName/:relatedModelId",
            relatedModelName
          );
        }
      } else {
        url = url.replace(":relatedModelName/:relatedModelId", "");
      }
    }

    return {
      url,
      params: {
        method,
        credentials: "include",
      },
    };
  }
}
