// @flow
import { createAction } from "../utils";

export const actionType = Object.freeze({
  FETCH_TRACK_CONFIG_START: "FETCH_TRACK_CONFIG_START",
  FETCH_TRACK_CONFIG_SUCCESS: "FETCH_TRACK_CONFIG_SUCCESS",
  FETCH_TRACK_CONFIG_FAILURE: "FETCH_TRACK_CONFIG_FAILURE",
});

export const fetchTrackConfig: AsyncActionCreator = {
  start: (
    patientId: number
  ): FSA<typeof actionType.FETCH_TRACK_CONFIG_START, number> =>
    createAction(actionType.FETCH_TRACK_CONFIG_START, patientId),
  success: (
    data: Array<any>
  ): FSA<typeof actionType.FETCH_TRACK_CONFIG_SUCCESS, Array<any>> =>
    createAction(actionType.FETCH_TRACK_CONFIG_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_TRACK_CONFIG_FAILURE> =>
    createAction(actionType.FETCH_TRACK_CONFIG_FAILURE),
};

export type Action =
  | fetchTrackConfig.start
  | fetchTrackConfig.success
  | fetchTrackConfig.failure;
