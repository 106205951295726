import classNames from "classnames";
import React, { PureComponent } from "react";
import { SlideDown } from "react-slidedown";

export default class Slidedown extends PureComponent {
  render() {
    const { className, ...otherProps } = this.props;
    return (
      <SlideDown
        className={classNames("slidedown", className)}
        {...otherProps}
      />
    );
  }
}
