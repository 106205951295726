import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import { head } from "ramda";
import React, { PureComponent } from "react";

import { Tooltip, Button, Icon, ButtonDropdown } from "pattern-library";

import { getPathogenicityClass } from "../../utils";

const icons = { Black: "eyeClose", White: "eyeOpen" };

export default class RenderCuration extends PureComponent {
  static propTypes = {
    curations: PropTypes.arrayOf(
      PropTypes.shape({
        curatedData: PropTypes.string,
        curatedVariantId: PropTypes.number,
        curatedVariantList: PropTypes.object,
        curatedVariantListId: PropTypes.number,
        name: PropTypes.string,
        pathogenicity: PropTypes.string,
        variant: PropTypes.number,
        variantId: PropTypes.number,
      })
    ),
  };

  static defaultProps = {
    curations: [],
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getIcon(type) {
    return icons[type] || "flag";
  }

  curatedVariantClicked(e) {
    e.stopPropagation();
    const { onClick } = this.props;
    const curatedVariantIdEl = e.target.closest("[data-curated-variant-id]");
    if (curatedVariantIdEl) {
      onClick(curatedVariantIdEl.dataset.curatedVariantId);
    }
  }

  render() {
    const { curations } = this.props;
    const [
      {
        curatedVariantId,
        curatedVariantList: { name, type, showPathogenicity },
        name: singleCurationName,
        pathogenicity,
      },
    ] = curations;

    const pathogenicityClass = `curated_variants--${getPathogenicityClass(
      showPathogenicity,
      pathogenicity
    )}`;

    const listItems = curations.map(
      ({
        curatedVariantId,
        name: curatedVariantName,
        curatedVariantList: { name: curatedVariantListName, type },
      }) => ({
        key: `${curatedVariantListName}-${curatedVariantName}`,
        type: curatedVariantListName,
        onClick: this.curatedVariantClicked,
        content: (
          <div data-curated-variant-id={curatedVariantId}>
            <Icon type={this.getIcon(type)} className={pathogenicityClass} />
            <span className="curated-variants-item">
              <b>{curatedVariantListName}</b> - {curatedVariantName}
            </span>
          </div>
        ),
      })
    );

    return (
      <>
        {listItems.length === 1 && (
          <Tooltip
            content={
              <>
                <b>{name}</b> - {singleCurationName}
              </>
            }
            placement="left"
          >
            <Button
              data-curated-variant-id={curatedVariantId}
              onClick={head(listItems).onClick}
              className="curated-variants-btn"
            >
              <Icon type={this.getIcon(type)} className={pathogenicityClass} />
            </Button>
          </Tooltip>
        )}
        {listItems.length > 1 && (
          <ButtonDropdown
            className="curated-variants-btn"
            list={listItems}
            btnContent={
              <span className={pathogenicityClass}>{listItems.length}</span>
            }
            dropdownPosition="right"
          />
        )}
      </>
    );
  }
}
