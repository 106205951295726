// @flow
import { createAction } from "../utils";

import * as constants from "./constants";

type SetCuratedVariantVisibleModalAction = FSA<
  typeof constants.SET_CURATED_VARIANT_VISIBLE_MODAL,
  Boolean
>;

export const setCuratedVariantVisibleModal = (
  payload: boolean
): SetCuratedVariantVisibleModalAction =>
  createAction(constants.SET_CURATED_VARIANT_VISIBLE_MODAL, payload);

type SetCurationIdCuratedVariantModalAction = FSA<
  typeof constants.SET_CURATION_ID_CURATED_VARIANT_MODAL,
  number
>;

export const setCurationIdCuratedVariantModal = (
  payload: number
): SetCurationIdCuratedVariantModalAction =>
  createAction(constants.SET_CURATION_ID_CURATED_VARIANT_MODAL, payload);

export type Action =
  | SetCuratedVariantVisibleModalAction
  | SetCurationIdCuratedVariantModalAction;
