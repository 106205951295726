import React, { useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";
import { getAssemblyConfig } from "modules/systemConfig/selectors";

import {
  fetchEditGene,
  updateEditGene,
  resetEditGeneState,
  searchGenes,
} from "../../../../actions";
import {
  getEditGene,
  getEditGeneKeys,
  getEditGeneFields,
  getEditGeneFormValues,
  getSearchGeneQuery,
} from "../../../../selectors";
import EditGeneForm from "../../../EditGeneForm";

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.EDIT_GENE_MODAL, {
//      projectId: 123,
//      genePaneId: 123,
//      geneName: "AARS",
//    });

interface Props extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
  geneName: string;
}

const EditGeneModal = ({
  projectId,
  genePanelId,
  geneName,
  hideModal,
  fetchEditGene,
  gene,
  fields,
  columns,
  assembly,
  updateEditGene,
  resetEditGeneState,
  initialValues,
  searchGenes,
  addGeneSearchQuery,
}: Props) => {
  useEffect(() => {
    fetchEditGene(projectId, genePanelId, geneName);
    return () => {
      resetEditGeneState();
    };
  }, [projectId, genePanelId, geneName, fetchEditGene, resetEditGeneState]);

  const hideModalHandler = useCallback(() => {
    hideModal(modalTypes.EDIT_GENE_MODAL);
  }, [hideModal]);

  return initialValues ? (
    <EditGeneForm
      projectId={projectId}
      genePanelId={genePanelId}
      geneName={geneName}
      updateGene={updateEditGene}
      gene={gene}
      fields={fields}
      columns={columns}
      assembly={assembly}
      initialValues={initialValues}
      hideModal={hideModalHandler}
      addGeneSearchQuery={addGeneSearchQuery}
      searchGenes={searchGenes}
    />
  ) : null;
};

const mapStateToProps = state => ({
  gene: getEditGene(state),
  columns: getEditGeneKeys(state),
  fields: getEditGeneFields(state),
  assembly: getAssemblyConfig(state),
  initialValues: getEditGeneFormValues(state),
  addGeneSearchQuery: getSearchGeneQuery(state),
});
const mapDispatchToProps = {
  hideModal,
  fetchEditGene: fetchEditGene.start,
  updateEditGene: updateEditGene.start,
  resetEditGeneState,
  searchGenes: searchGenes.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditGeneModal);
