import { guessDate } from "modules/utils";
import { camelizeIgnoreUpperCase } from "modules/utils/common";
import { metadataAffectionStatus } from "modules/utils/enum/affectionStatus";

import { FORM_FILE_TYPES } from "../types";

import { affectionStatusOptions, genderOptions } from "./componentUtils";

export const equalIgnoreCase = (a: string, b: string): boolean =>
  a?.toUpperCase() === b?.toUpperCase();

export const toBoolean = numberValue => numberValue && numberValue !== "0";
export const toArray = csvValue => (csvValue ? csvValue.split(/[\s,; ]+/) : []);
export const toIntArray = csvValue =>
  csvValue ? toArray(csvValue).map(value => parseInt(value)) : [];
export const getHpoTermsByCodes = (codes, allHpoTerms) =>
  allHpoTerms.reduce((acc, { hpoTermId, name }) => {
    if (
      codes.includes(hpoTermId) &&
      !acc.some(({ code }) => code === hpoTermId)
    ) {
      acc.push({
        code: hpoTermId,
        phenotype: name,
      });
    }
    return acc;
  }, []);

export const formatHpoTerms = (csvValue, allHpoTerms) =>
  getHpoTermsByCodes(toArray(csvValue), allHpoTerms);

export const formatFileType = csvValue => Boolean(csvValue);

export const findCaseInsensitive = (list, value) =>
  list.find(item => equalIgnoreCase(item, value));

export const formatSex = value =>
  findCaseInsensitive(Object.values(genderOptions), value);

export const formatAffectedStatus = value =>
  findCaseInsensitive(Object.values(affectionStatusOptions), value);

export const metadataFormats = {
  "Select::Sex": formatSex,
  Date: value => guessDate(value)?.toDate(),
  "Select::ParentalPhenotype": value => {
    const status = metadataAffectionStatus.find(({ label }) =>
      equalIgnoreCase(label, value)
    );
    return status?.value;
  },
};

export const getMetadataFormatFunction = fieldType =>
  metadataFormats[fieldType];

const formatSampleType = (value, sampleTypeNames) =>
  findCaseInsensitive(sampleTypeNames, value);

export const getCsvHeadersMap = ({
  allHpoTerms = [],
  sampleTypeNames = [],
  isOnPrem,
  metadataFields = [],
}) => ({
  name: { name: "name", required: true },
  sex: {
    name: "sex",
    required: true,
    format: formatSex,
  },
  hpoTerms: {
    name: "hpoTerms",
    format: csvValue => formatHpoTerms(csvValue, allHpoTerms),
  },
  affectionStatus: {
    name: "affectionStatus",
    required: true,
    format: formatAffectedStatus,
  },
  isProband: { name: "isProband", required: true, format: toBoolean },
  familyName: { name: "familyName" },
  motherName: { name: "motherName" },
  fatherName: { name: "fatherName" },
  genePanelIds: { name: "genePanelIds", format: toIntArray },
  sampleType: {
    name: "sampleType",
    format: value => formatSampleType(value, sampleTypeNames),
  },
  protocol: { name: "protocol" },
  cnvAnalysisRequested: {
    name: "cnvAnalysisRequested",
    format: toBoolean,
  },
  bam: { name: `fileTypes.${FORM_FILE_TYPES.BAM}`, format: formatFileType },
  fastqR1: {
    name: `fileTypes.${FORM_FILE_TYPES.FASTQ}`,
    format: formatFileType,
  },
  fastqR2: {
    name: `fileTypes.${FORM_FILE_TYPES.FASTQ}`,
    format: formatFileType,
  },
  vcfCnv: {
    name: `fileTypes.${FORM_FILE_TYPES.CNV_VCF}`,
    format: formatFileType,
  },
  vcfSnv: {
    name: `fileTypes.${
      isOnPrem ? FORM_FILE_TYPES.SNV_VCF : FORM_FILE_TYPES.VCF
    }`,
    format: formatFileType,
  },
  vcfSv: {
    name: `fileTypes.${FORM_FILE_TYPES.SV_VCF}`,
    format: formatFileType,
  },
  //name=allocated_to
  ...metadataFields.reduce((acc, { name, required, fieldType }) => {
    //name=allocatedTo
    acc[camelizeIgnoreUpperCase(name)] = {
      name: `metadata.${name}`, //name=metadata.allocated_to
      required,
      format: getMetadataFormatFunction(fieldType),
    };
    return acc;
  }, {}),
});
