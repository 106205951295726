import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";

import { GeneButton } from "pattern-library";

import { GENES_DISPLAYED } from "../constants";

export const getGeneName = gene => {
  const { name } = typeof gene === "string" ? { name: gene } : gene;
  return name;
};

const HiGeneNameColumn = memo(
  ({ genes = [], compact = true, className = "" }) => {
    const [sortedGenes, setSortedGenes] = useState([]);
    useEffect(() => {
      if (genes) {
        setSortedGenes(
          genes.sort((gene1, gene2) =>
            getGeneName(gene1).localeCompare(getGeneName(gene2))
          )
        );
      }
    }, [genes]);

    if (!genes || !genes.length) {
      return "-";
    }
    return (
      <div
        className={classNames(
          "structural-variants-table-column-hi-gene-names",
          className
        )}
      >
        {sortedGenes.map((gene, index) => {
          const name = getGeneName(gene);

          if (compact && genes.length > GENES_DISPLAYED) {
            if (index === GENES_DISPLAYED) {
              return (
                <div key="_more_">{`... + ${
                  genes.length - GENES_DISPLAYED
                } more`}</div>
              );
            }
            if (index > GENES_DISPLAYED) {
              return null;
            }
          }
          return <GeneButton key={`gene-${name}`} simplified gene={gene} />;
        })}
      </div>
    );
  }
);

HiGeneNameColumn.propTypes = {
  genes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        morbidId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        pathogenicity: PropTypes.string,
      }),
    ])
  ),
  compact: PropTypes.bool,
  className: PropTypes.string,
};

export default HiGeneNameColumn;
