import { isEmpty } from "ramda";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { getLicences } from "modules/api/licences";
import { error } from "modules/messages/actions";

import { fetchLicences } from "./actions";
import * as actions from "./actions";
import { getLicences as getLicencesSelector } from "./selectors";

export function* init(): Generator<any, void, any> {
  yield takeLatest(
    actions.actionType.GET_OR_RELOAD_LICENCES,
    getOrReloadLicences
  );
}

export function* getOrReloadLicences(): Generator<any, void, any> {
  try {
    const loadedLicences = yield select(getLicencesSelector);
    if (loadedLicences && !isEmpty(loadedLicences)) {
      return;
    }
    yield put(fetchLicences.start());

    const licences: Array<SoftwareLicence> = yield call(getLicences);

    yield put(fetchLicences.success(licences));
  } catch ({ message }) {
    yield put(fetchLicences.failure());
    yield put(error(message));
  }
}
