import React, { useCallback } from "react";

export const Tab = ({ tab, onClick, href = "" }) => {
  const { navLinkClassName, strong } = tab;
  let { name } = tab;
  const onTabClicked = useCallback(
    e => {
      if (!href) {
        e.preventDefault();
      }
      onClick(tab);
    },
    [tab, href, onClick]
  );

  if (strong) {
    name = <strong>{name}</strong>;
  }

  return (
    <a href={href} className={navLinkClassName} onClick={onTabClicked}>
      {name}
    </a>
  );
};
