import React, { memo } from "react";

import { Button, Loading } from "pattern-library";
import { Table } from "pattern-library/elements/table7";

import styles from "./AnnotationSourcesTable.module.scss";

import congenicaApi from "api/congenica-api";

const FETCH_FAILURE_MESSAGE = "Cannot load annotation sources details.";

const TEST_ID = "annotation-source-table";

const sourcesMap = {
  VEP_CACHE: "VEP",
  ENSEMBL: "Ensembl",
  GNOMAD_EXOMES: "gnomAD Exomes",
} as const;

const typeMap: Record<AnnotationSource["type"], string> = {
  data: "Data",
  software: "Software",
};

type AnnotationSourcesTableProps = {
  patientId: number;
};

const { useGetPatientQuery } = congenicaApi;

const EXCLUDED_ANNOTATION_SOURCES = ["EXOMISER_ACMG_ANNOTATION"];

const AnnotationSourcesTable: React.FC<AnnotationSourcesTableProps> = memo(
  ({ patientId }) => {
    const { data, isLoading, isFetching, error, refetch } = useGetPatientQuery({
      patientId,
    });

    const renderContent = () => {
      if (isLoading || isFetching) return <Loading />;

      if (error) {
        return (
          <div className={styles.failureMessage}>
            <div>{FETCH_FAILURE_MESSAGE}</div>
            <Button onClick={() => refetch()}>Retry</Button>
          </div>
        );
      }

      const columns = [
        {
          Header: "Source",
          accessor: ({ attribute }) => sourcesMap[attribute] || attribute,
        },
        { Header: "Type", accessor: ({ type }) => typeMap[type] || type },
        { Header: "Version", accessor: "value" },
      ];

      const annotationSources: AnnotationSource[] = (
        data?.annotation_sources || []
      ).filter(
        // Filter the list of displayed sources, as some are included in the API response
        // for functional/code reasons, but the product decision is not to show then in the table
        ({ attribute }) => !EXCLUDED_ANNOTATION_SOURCES.includes(attribute)
      );

      return (
        <Table
          className={styles.componentTable}
          columns={columns}
          data={annotationSources}
        />
      );
    };

    return (
      <div data-testid={TEST_ID}>
        <h4 className={styles.componentHeader}>Patient Annotation Sources</h4>
        {renderContent()}
      </div>
    );
  }
);

export default AnnotationSourcesTable;
