import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";

import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tag,
  Tooltip,
} from "pattern-library";

import { ReduxFormField } from "modules/forms/components";
import { getRoles } from "modules/roles";

import { deleteProjectUser } from "../actions";
import * as constants from "../constants";
import * as selectors from "../selectors";

export const rolesProcessor = (allRoles = [], roles = {}) =>
  allRoles.map(({ name, label, description }) => {
    if (roles[name]) {
      return (
        <Tooltip key={name} placement="left" content={description}>
          <Tag className={`role-${label.replace(/\s+/g, "-").toLowerCase()}`}>
            {label}
          </Tag>
        </Tooltip>
      );
    }
    return null;
  });

export const DeleteUserModal = ({
  handleSubmit,
  close,
  show,
  deleteProjectUser,
  roles,
  allRoles,
  projectName,
}) => {
  const submitHandler = useCallback(
    values => {
      const { projectId, userId } = values;
      deleteProjectUser(projectId, userId);
    },
    [deleteProjectUser]
  );

  return (
    <Modal data-testid="delete-user-modal" close={close} show={show}>
      <ModalHeader close={close}>
        <strong>{`Remove user from project "${projectName}"?`}</strong>
      </ModalHeader>
      <ModalBody>
        <fieldset className="d-table">
          <Label>Name</Label>
          <Field
            disabled
            component={ReduxFormField}
            type="text"
            name="fullname"
          />
          <Label>Email Address</Label>
          <Field disabled component={ReduxFormField} type="text" name="email" />
          <Label>Project</Label>
          <Field
            disabled
            component={ReduxFormField}
            type="text"
            name="projectName"
          />
          <Label>Access</Label>
          <div className="form-group row col-sm-12">
            {rolesProcessor(allRoles, roles)}
          </div>
        </fieldset>
      </ModalBody>
      <ModalFooter>
        <Button type="button" className="button" onClick={close}>
          Cancel
        </Button>
        <Button
          type="button"
          className="button btn-danger"
          onClick={handleSubmit(submitHandler)}
        >
          Remove user
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteUserModal.propTypes = {
  close: PropTypes.func,
  show: PropTypes.bool,
};

const ConnectedForm = reduxForm({
  form: constants.USER_DELETE_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(DeleteUserModal);

const mapStateToProps = state => {
  const selector = formValueSelector(constants.USER_DELETE_FORM);
  return {
    initialValues: selectors.getEditableUser(state),
    projectName: selector(state, "projectName"),
    roles: selector(state, "roles"),
    allRoles: getRoles(state),
  };
};

const mapDispatchToProps = {
  deleteProjectUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);
