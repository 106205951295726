import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";

const RadioButtons = memo(props => {
  const { items, isInline, condensed } = props;
  let inputValue = "";
  let name = "";
  let onChange;
  let onBlur;
  if (props.input) {
    inputValue = props.input.value;
    name = props.input.name;
    onBlur = props.input.onBlur;
    onChange = props.input.onChange;
  } else {
    inputValue = props.value;
    onBlur = props.onBlur;
    name = props.name;
    onChange = props.onChange;
  }

  const handleChange = useCallback(
    ({ target: { value } }) => {
      onChange(items[value].value);
    },
    [onChange, items]
  );

  const handleBlur = useCallback(() => {
    if (typeof onBlur === "function") {
      onBlur();
    }
  }, [onBlur]);

  return (
    <>
      {items.map(({ label, value, disabled }, index) => {
        const id = `${name}_${index}`;
        const checked = value === inputValue;
        return (
          <div
            className={classNames({
              [`radiobuttons-item${isInline ? "-inline" : ""}`]: !condensed,
              "radiobuttons-cursor-pointer": !checked,
            })}
            key={id}
          >
            <label
              className={classNames(
                "form-check-label",
                "radiobuttons-label",
                { "radiobuttons-label-disabled": disabled },
                { "radiobuttons-label-checked": checked }
              )}
            >
              <input
                type="radio"
                value={index}
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
                name={name}
                id={id}
                className="form-check-input"
                onBlur={handleBlur}
              />
              {label}
            </label>
          </div>
        );
      })}
    </>
  );
});

RadioButtons.displayName = "RadioButtons";

RadioButtons.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
  }),
  isInline: PropTypes.bool,
  condensed: PropTypes.bool,
};

export default RadioButtons;
