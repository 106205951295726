import * as d3 from "d3";
import { get } from "dot-prop-immutable";
import $ from "jquery";
import nv from "nvd3";

export const QC = {
  defaults: {
    colours: {
      primary: "#0B61A7",
      secondary: "#FF6700",
      A: "green",
      T: "red",
      G: "black",
      C: "blue",
    },
  },
  charts: {
    barChart(params?: Json) {
      // Set defaults here
      const options = {
        horizontal: false,
        staggerLabels: false,
        colours: QC.defaults.colours.primary,
        value_type: false,
        xAxisLabel: false,
        yAxisLabel: false,
        legend: false,
      };

      $.extend(options, params);

      const baseChartFunction = options.horizontal
        ? nv.models.multiBarHorizontalChart
        : nv.models.discreteBarChart;

      const chart = baseChartFunction
        .call()
        .x(d => d.label)
        .y(d => d.value)
        .showValues(true)
        .duration(250)
        .showLegend(options.legend);

      chart.color([options.colours]);

      if (!options.horizontal) {
        chart.staggerLabels(options.staggerLabels);
      } else {
        // Hide the stack controls by default
        chart.showControls(false);
      }

      chart.valueFormat(i => parseInt(i));

      if (options.xAxisLabel) {
        chart.xAxis.axisLabel(options.xAxisLabel);
      }
      if (options.yAxisLabel) {
        chart.yAxis.axisLabel(options.yAxisLabel);
      }

      return chart;
    },
    lineChart(params?: Json) {
      // Set defaults here
      const options = {
        xAxisLabel: false,
        yAxisLabel: false,
        legend: false,
      };

      $.extend(options, params);

      const chart = nv.models
        .lineChart()
        .options({
          transitionDuration: 300,
          useInteractiveGuideline: true,
        })
        .showLegend(options.legend);

      if (options.xAxisLabel) {
        chart.xAxis.axisLabel(options.xAxisLabel);
      }
      if (options.yAxisLabel) {
        chart.yAxis.axisLabel(options.yAxisLabel);
      }

      return chart;
    },

    pieChart(params?: Json) {
      // Set defaults here
      const options = {
        halfPie: false,
        externalLabels: false,
      };

      $.extend(options, params);

      const chart = nv.models
        .pieChart()
        .x(d => d.key)
        .y(d => d.y)
        .valueFormat(d3.format(".0f"))
        .showLegend(false);

      if (options.halfPie) {
        chart.pie
          .startAngle(d => d.startAngle / 2 - Math.PI / 2)
          .endAngle(d => d.endAngle / 2 - Math.PI / 2);
      }
      if (options.externalLabels) {
        chart.pie.labelsOutside(true);
      }

      return chart;
    },
  },
  normalizeRegionString(region: string) {
    const parts = region.split("-");
    if (parts.length === 1) {
      return parseInt(region);
    }
    const start = parseInt(parts[0]);
    const end = parseInt(parts[1]);

    return start + (end - start) * 0.5;
  },
  populateDattributes(container: any, json: Json) {
    $("[data-json-source]", container).each(function () {
      $(this).text(get(json, $(this).data("json-source")));
    });

    $("table [data-json-rowkeysource]", container).each(function () {
      const row_keys = get(json, $(this).data("json-rowkeysource"));
      if (!row_keys) {
        return;
      }
      const $cols = $(this).find("th[data-json-rowsource]");
      const $tbody = $(this).parents("table").find("tbody");

      $tbody.find("tr").remove();

      row_keys.forEach(key => {
        let html = "<tr>";

        $cols.each(function () {
          const val_lookup = $(this)
            .data("json-rowsource")
            .replace("$key", key);
          const val =
            $(this).data("json-rowsource") === "$key"
              ? key
              : get(json, val_lookup);

          html += "<td>" + val + "</td>";
        });

        html += "</tr>";

        $tbody.append(html);
      });
    });
  },
};
