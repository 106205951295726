import React, { PureComponent } from "react";

import { Tree } from "pattern-library";

import HPOTermsTreeNode from "./HPOTermsTreeNode";

export default class HPOTermsNode extends PureComponent {
  render() {
    return <Tree {...this.props} TreeNodeComponent={HPOTermsTreeNode} />;
  }
}
