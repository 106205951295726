import React from "react";

import { Button } from "pattern-library";
import { Table } from "pattern-library/elements/table7";

import CommentsTab from "./CommentsTab";
import StrPanel from "./StrPanel";
import TabsLayout from "./TabsLayout";
import VariantHistoryTab from "./VariantHistoryTab";

const tabs = [
  {
    name: "Comments",
    component: CommentsTab,
  },
  {
    name: "Variant history",
    component: VariantHistoryTab,
  },
];

export const StrDetails = ({ closeDetails, strRecord, columns }) => (
  <>
    <Table
      className="single-str-table"
      columns={columns}
      data={[strRecord]}
      disableSortBy
    />
    <Button
      context="link"
      onClick={closeDetails}
      className="expand-table-button"
    >
      <span className="caret false" />
      {" Expand table"}
    </Button>
    <StrPanel strRecord={strRecord} />
    <TabsLayout tabs={tabs} variantId={strRecord.variantId} />
  </>
);

export default StrDetails;
