import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Loading } from "pattern-library";

import {
  error as errorAction,
  success as successAction,
} from "modules/messages/actions";
import { readProject } from "modules/project/actions";
import { isSentieonAvailable } from "modules/systemConfig/selectors";

import { getOrReloadProjectSettings } from "../actions";

import AutomationForm from "./automation/AutomationForm";
import { EMPTY_SETTINGS } from "./automation/constants";
import {
  convertCVLListToSelectFormat,
  convertReportServiceTemplatesToSelectFormat,
  fromValues,
  toValues,
} from "./automation/utils";

import congenicaApi from "api/congenica-api";
import useProjectAutomationCVLs from "hooks/useProjectAutomationCVLs";

export const AutomationContext = createContext(false);

interface AutomationProps {
  /**
   * id of a selected project
   */
  projectId: number;
}

const {
  useGetProjectFeaturesSettingsQuery,
  useSaveProjectFeaturesSettingsMutation,
  useGetReportTemplatesQuery,
} = congenicaApi;

export const Automation = ({
  projectId,
  getOrReloadProjectSettings,
  isSentieonAvailable = false,
  successAction,
  reloadCurrentProject,
}: AutomationProps & PropsFromRedux) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const automationForm = useRef(null);

  useEffect(() => {
    if (getOrReloadProjectSettings) {
      getOrReloadProjectSettings(projectId);
    }
  }, [projectId, getOrReloadProjectSettings]);

  const {
    featuresSettings = {
      ...EMPTY_SETTINGS,
    },
    featuresSettingsLoaded = false,
    featuresSettingsError = false,
  } = useGetProjectFeaturesSettingsQuery(
    { projectId },
    {
      selectFromResult: ({ data, error, isSuccess }) => ({
        featuresSettings: toValues(data),
        featuresSettingsError: error,
        featuresSettingsLoaded: isSuccess,
      }),
    }
  );

  const {
    cvlList = [],
    error: cvlListError = false,
    isSuccess: cvlListLoaded = false,
  } = useProjectAutomationCVLs({ projectId });

  const cvlOptions = useMemo(
    () => convertCVLListToSelectFormat(cvlList),
    [cvlList]
  );

  const {
    reportServiceTemplates = [],
    reportServiceTemplatesError = false,
    reportServiceTemplatesLoaded = false,
  } = useGetReportTemplatesQuery(
    { projectId },
    {
      selectFromResult: ({ data, error, isSuccess }) => ({
        reportServiceTemplates: convertReportServiceTemplatesToSelectFormat(
          data || []
        ),
        reportServiceTemplatesError: error,
        reportServiceTemplatesLoaded: isSuccess,
      }),
    }
  );

  const toggleConfirmationModal = useCallback(() => {
    setShowConfirmationModal(!showConfirmationModal);
  }, [setShowConfirmationModal, showConfirmationModal]);

  const [
    saveProjectFeaturesSettings,
    { isSuccess: isSaveFeaturesSettingsSuccess },
  ] = useSaveProjectFeaturesSettingsMutation();

  useEffect(() => {
    if (isSaveFeaturesSettingsSuccess) {
      setShowConfirmationModal(false);
      reloadCurrentProject(projectId, true);
      successAction("Automation Settings saved successfully");
    }
  }, [
    projectId,
    isSaveFeaturesSettingsSuccess,
    setShowConfirmationModal,
    reloadCurrentProject,
    successAction,
  ]);

  const handleSubmit = useCallback(
    (values, { resetForm, setSubmitting }) => {
      const { automation } = values;
      if (automation) {
        const { enabled: automationEnabled, isInherited, ...rest } = automation;
        if (
          !automationEnabled &&
          !showConfirmationModal &&
          Object.values(rest).some(value => !!value)
        ) {
          setSubmitting(false);
          toggleConfirmationModal();
          return;
        }
      }
      saveProjectFeaturesSettings({
        ...fromValues(values, projectId),
        projectId,
      })
        .unwrap()
        .then(payload => {
          if (automationForm?.current) {
            resetForm({ values: toValues(payload) });
          }
        });
    },
    [
      projectId,
      showConfirmationModal,
      toggleConfirmationModal,
      saveProjectFeaturesSettings,
    ]
  );

  const isError = useMemo(
    () => featuresSettingsError || cvlListError || reportServiceTemplatesError,
    [featuresSettingsError, cvlListError, reportServiceTemplatesError]
  );
  const isLoaded = useMemo(
    () =>
      featuresSettingsLoaded && cvlListLoaded && reportServiceTemplatesLoaded,
    [featuresSettingsLoaded, cvlListLoaded, reportServiceTemplatesLoaded]
  );

  if (isError) {
    return <p>Unable to load the Automation Settings</p>;
  }

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <AutomationContext.Provider value={isSentieonAvailable as boolean}>
      <AutomationForm
        initialValues={featuresSettings}
        toggleConfirmationModal={toggleConfirmationModal}
        showConfirmationModal={showConfirmationModal}
        cvlList={cvlOptions}
        reportServiceTemplates={reportServiceTemplates}
        handleSubmit={handleSubmit}
        ref={automationForm}
      />
    </AutomationContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector({
  isSentieonAvailable,
});

const mapDispatchToProps = {
  successAction,
  errorAction,
  getOrReloadProjectSettings,
  reloadCurrentProject: readProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Automation);
