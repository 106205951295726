export const NAMESPACE = "feedback" as const;

export const SAVE_FEEDBACK_DRAFT = "SAVE_FEEDBACK_DRAFT" as const;
export const RESET_FEEDBACK_DRAFT = "RESET_FEEDBACK_DRAFT" as const;

export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK" as const;
export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS" as const;
export const SUBMIT_FEEDBACK_FAILURE = "SUBMIT_FEEDBACK_FAILURE" as const;
export const RESET_SUBMIT_FEEDBACK_STATUS =
  "RESET_SUBMIT_FEEDBACK_STATUS" as const;
