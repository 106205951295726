// @flow
import { FileParseError } from "../errors";
import { getChunk } from "../util";

import BgzipBlock from "./BgzipBlock";

declare type BgZipHeader = {
  start?: number,
  end?: number,
  id1: number,
  id2: number,
  cm: number,
  flg: number,
  mtime: number,
  xfl: number,
  os: number,
  xlen: number,
  rawArrayBuffer?: ArrayBuffer,
  subheader: {
    si1: number,
    si2: number,
    slen: number,
    bsize: number,
  },
};

// this class provides the raw methods necessary to decompress
// and validate blocks in a bgzip file.
// a higher level interface will be provided to make streaming
// a bgzip file easier
export default class BgzipDecompressor {
  file: File;

  static MAGIC: { id1: 0x1f, id2: 0x8b } = { id1: 0x1f, id2: 0x8b };

  static GZIPFLAGS: {
    FTEXT: 0x01,
    FHCRC: 0x02,
    FEXTRA: 0x04,
    FNAME: 0x08,
    FCOMMENT: 0x10,
  } = {
    FTEXT: 0x01,
    FHCRC: 0x02,
    FEXTRA: 0x04,
    FNAME: 0x08,
    FCOMMENT: 0x10,
  };

  static BGZIPMAGIC: {
    si1: 66,
    si2: 67,
  } = { si1: 66, si2: 67 };

  static BGZIPFOOTER = new Uint8Array([
    0x1f, 0x8b, 0x08, 0x04, 0x00, 0x00, 0x00, 0x00, 0x00, 0xff, 0x06, 0x00,
    0x42, 0x43, 0x02, 0x00, 0x1b, 0x00, 0x03, 0x00, 0x00, 0x00, 0x00, 0x00,
    0x00, 0x00, 0x00, 0x00,
  ]);

  static HEADERSIZE = 18;

  /*
    See section 4 in the sam spec for details of bgzip:
    http://samtools.github.io/hts-specs/SAMv1.pdf
  */
  constructor(file: File) {
    this.file = file;

    if (this.file.size === 0) {
      throw new Error("Empty file provided");
    }
  }

  get finalBlockEnd() {
    // because there is always a blank bgzip block at the end of a
    // bgzip file, the useful eof is actually this
    return this.file.size - BgzipDecompressor.BGZIPFOOTER.length;
  }

  parseBlockHeader(arrayBuffer: ArrayBuffer): BgZipHeader {
    const dv = new DataView(arrayBuffer);

    const id1 = dv.getUint8(0);
    const id2 = dv.getUint8(1);

    const cm = dv.getUint8(2); // Compression Method

    // flags
    // we expect: 0x04	FEXTRA	The file contains extra fields
    // as this has the gzip data
    const flg = dv.getUint8(3);

    const mtime = dv.getUint32(4, true); // Last modification time
    const xfl = dv.getUint8(8); // Compression flags
    const os = dv.getUint8(9); // Operating system used to create file

    // if FEXTRA flag is set this will contain the size of the extra fields
    // in the header. bgzip should only have one extra header so this will
    // be the same size
    const xlen = dv.getUint16(10, true);

    // in theory if people put other extra headers in here this would break,
    // we'd need to parse all the extra headers properly and extract BC
    const subheader = {
      // bgzip magic header, should be the same as BC
      si1: dv.getUint8(12), // 66 i.e B
      si2: dv.getUint8(13), // 67 i.e C
      slen: dv.getUint16(14, true), // size of the field (bsize)
      // bsize is the size of the compressed data to follow - 1
      bsize: dv.getUint16(16, true),
    };

    if (
      id1 !== BgzipDecompressor.MAGIC.id1 ||
      id2 !== BgzipDecompressor.MAGIC.id2
    ) {
      throw new FileParseError(
        `Provided file ${this.file.name} is not a gzip file`
      );
    }

    // we have to check this before we try and get xlen as xlen is only present
    // if this flag is set
    if (!flg && BgzipDecompressor.GZIPFLAGS.FEXTRA) {
      throw new FileParseError(
        `Provided file ${this.file.name} is not a valid bgzip file: FEXTRA flag not set`
      );
    }

    if (xlen !== 6) {
      throw new FileParseError(
        "Unexpected xlen in header: should be 6 for bgzip"
      );
    }

    if (
      subheader.si1 !== BgzipDecompressor.BGZIPMAGIC.si1 ||
      subheader.si2 !== BgzipDecompressor.BGZIPMAGIC.si2
    ) {
      throw new FileParseError(
        "Provided file " +
          this.file.name +
          " is not a valid bgzip file: BC field missing from header"
      );
    }

    return {
      id1,
      id2,
      cm,
      flg,
      mtime,
      xfl,
      os,
      xlen,
      subheader,
    };
  }

  // used to get the header at a specific offset (a bgzip file has many)
  // blocks each with their own header
  async getBlockHeader(offset: number = 0): Promise<BgZipHeader | string> {
    const reader: FileReader = await getChunk(
      this.file,
      offset,
      offset + BgzipDecompressor.HEADERSIZE
    );
    const { result } = reader;

    if (typeof result === "string") {
      throw new Error("The reader received a string instead of an ArrayBuffer");
    }

    const header = this.parseBlockHeader(result);
    // add some useful data for working with the block
    header.rawArrayBuffer = result;
    header.start = offset;
    header.end = offset + BgzipDecompressor.HEADERSIZE;

    return header;
  }

  async getBgzipBlock(offset: number) {
    // we can't read a block in one go beacuse we don't know how big it is,
    // so read the header first to get blockSize
    const header: BgZipHeader | string = await this.getBlockHeader(offset);

    if (typeof header === "string") {
      throw new Error(header);
    }

    // we now know how big the block is so fetch the whole thing
    const reader: FileReader = await getChunk(
      this.file,
      offset,
      offset + header.subheader.bsize + 1
    );

    const { result } = reader;

    if (typeof result === "string") {
      throw new Error("The reader received a string instead of an ArrayBuffer");
    }

    return new BgzipBlock(header, result);
  }
}
