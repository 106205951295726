// @flow
import { set } from "dot-prop-immutable";

import { type Action } from "./actions";
import * as constants from "./constants";

type CuratedVariantModal = {
  visible: false,
};

export type State = {
  ui: { variantRowModal: CuratedVariantModal },
  data: { curatedVariantModalCurationId: ?number },
};

const initialState: State = {
  ui: {
    variantRowModal: {
      visible: false,
    },
  },
  data: { curatedVariantModalCurationId: null },
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SET_CURATED_VARIANT_VISIBLE_MODAL:
      return set(state, "ui.variantRowModal.visible", action.payload || false);

    case constants.SET_CURATION_ID_CURATED_VARIANT_MODAL:
      return set(state, "data.curatedVariantModalCurationId", action.payload);
    default:
      return state;
  }
}
