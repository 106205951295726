export enum STATUS {
  EMPTY = "EMPTY",
  SELECTED = "SELECTED",
  UPLOADING = "UPLOADING",
  CORRUPTED = "CORRUPTED",
  UPLOADED = "UPLOADED",
}

export type State = {
  file: File | null;
  status: STATUS;
  progress?: number;
};

export type InitializationUploadInput = {
  name: string;
};

export type AWSFileDataOutput = {
  fileId: string;
  fileKey: string;
};

export type AWSMultipartFileDataInput = {
  fileId: string;
  fileKey: string;
  parts: number;
};

export type AWSPart = {
  PartNumber: number;
  fileKey: string;
};

export type AWSUploadedPart = {
  PartNumber: number;
  ETag: string;
};

export type FinalizationMultiPartInput = {
  fileId: string;
  fileKey: string;
  parts: Array<AWSUploadedPart>;
};

export type Progress = {
  sent: number;
  total: number;
  percentage: number;
};
export type UploaderProps = {
  file?: File;
  onStart?: () => void;
  onProgress?: (progress: Progress) => void;
  onError?: (error: Error) => void;
  onSuccess?: () => void;
};

export type S3UploaderProps = {
  chunkSize?: number;
  // number of parallel uploads
  threadsQuantity?: number;
  initializeMultipartUploadUrl: string;
  getMultipartPreSignedUrlsUrl: string;
  finalizeMultipartUploadUrl: string;
} & UploaderProps;
