import { createSelector } from "@reduxjs/toolkit";
import { path, isNil, isEmpty } from "ramda";

import { RootState } from "common/store";
import { ONCOLOGY_PROJECT_TYPE } from "modules/projects/constants";

import { getAppVersions } from "../systemConfig/selectors";
import { AppVersions } from "../systemConfig/types";

import {
  ARIADNE_PREDICTION_FEATURE,
  AUTO_ACMG_FEATURE,
  NAMESPACE,
  SPLICE_AI_FEATURE_FLAG,
} from "./constants";

export const getCurrentProject = (state: RootState) =>
  path([NAMESPACE, "data"])(state);

export const getCurrentProjectTabCounts = path([NAMESPACE, "counts"]);

export const getPatientsCount = path([NAMESPACE, "counts", "patients"]);

export const getUsersCount = path([NAMESPACE, "counts", "users"]);

export const getCuratedVariantListsCount = path([
  NAMESPACE,
  "counts",
  "curatedVariantLists",
]);

export const getInterpretationRequestsCount = path([
  NAMESPACE,
  "counts",
  "interpretationRequests",
]);

export const getGenePanelsCount = path([NAMESPACE, "counts", "genePanel"]);

export const isRootProject = createSelector(getCurrentProject, project =>
  project ? project.isRoot === 1 : false
);

export const getSingleProject = createSelector(
  (_: RootState, projectId: string | number) => projectId,
  getCurrentProject,
  (projectId, project) => {
    if (!project || (projectId && project.projectId !== Number(projectId))) {
      return null;
    }
    return project;
  }
);

export const getProjectCurrentUser = path([NAMESPACE, "currentUser"]);
export const getProjectCurrentUserRoles = createSelector(
  getProjectCurrentUser,
  (currentUser: ProjectCurrentUser) => {
    const { canAdminProject, roles = {} } = currentUser || {};
    return canAdminProject ? { ...roles, admin: true } : roles;
  }
);

export const getCurrentProjectFeatures = createSelector(
  getCurrentProject,
  project => (project ? project.features : {})
);

export const isProjectFeatureActive = createSelector(
  (_, feature) => feature,
  getCurrentProjectFeatures,
  (feature, projectFeatures) => projectFeatures[feature] || false
);

export const isAutoAcmgActiveOnCurrentProject = createSelector(
  getCurrentProjectFeatures,
  (projectFeatures: ProjectFeatures) => projectFeatures[AUTO_ACMG_FEATURE]
);

export const isAriadnePredictionActiveOnCurrentProject = createSelector(
  getCurrentProjectFeatures,
  (projectFeatures: ProjectFeatures) =>
    projectFeatures[ARIADNE_PREDICTION_FEATURE]
);

export const isSpliceAiActiveOnCurrentProject = createSelector(
  getCurrentProjectFeatures,
  (projectFeatures: ProjectFeatures) => projectFeatures[SPLICE_AI_FEATURE_FLAG]
);

export const getCurrentProjectTypeId = path([
  NAMESPACE,
  "data",
  "projectTypeId",
]);

export const getCurrentProjectTypeInternalName = path([
  NAMESPACE,
  "data",
  "projectTypeInternalName",
]);

export const isCurrentProjectOncology = createSelector(
  getCurrentProjectTypeInternalName,
  projectTypeInternalName => projectTypeInternalName === ONCOLOGY_PROJECT_TYPE
);

const getVersionText = (versionLabel, version) =>
  isNil(version) || isEmpty(version)
    ? "Congenica"
    : `${versionLabel} v${version}`;

export const getAppVersionText = createSelector(
  getAppVersions,
  isCurrentProjectOncology,
  (appVersions: AppVersions, isOncology) => {
    const { rareDisease, oncology } = appVersions || {};
    return isOncology
      ? getVersionText("Congenica Oncology", oncology)
      : getVersionText("Congenica Rare Disease", rareDisease);
  }
);

export const getCurrentProjectVariantPanelConfig = createSelector(
  getCurrentProject,
  project => project?.variantPanelConfig
);
