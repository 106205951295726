import PropTypes from "prop-types";
import React from "react";

import { VariantPathogenicity } from "pattern-library";

import { ARIADNE_KEYS } from "modules/ariadne/constants";

const PredictedPathogenicity = ({ className, transcript }) => {
  const computedDecision = transcript[ARIADNE_KEYS.PREDICTED_PATHOGENICITY];
  return (
    <div className={className}>
      <VariantPathogenicity
        level={
          computedDecision && computedDecision.toLowerCase().replace(" ", "_")
        }
        small
      />
    </div>
  );
};

PredictedPathogenicity.propTypes = {
  className: PropTypes.string,
  transcript: PropTypes.shape({
    [ARIADNE_KEYS.PREDICTED_PATHOGENICITY]: PropTypes.oneOf([
      "Likely benign",
      "Benign",
      "Likely pathogenic",
      "Pathogenic",
    ]),
  }),
};

export default PredictedPathogenicity;
