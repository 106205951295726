import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Field,
  reduxForm,
  formValueSelector,
  InjectedFormProps,
} from "redux-form";

import { LabelField, Icon, Button, Link, Tooltip } from "pattern-library";

import { CURATED_LIST_TYPE_KNOWLEDGEBASE } from "../../../common/constants";
import FormActions from "../../forms/components/FormActions";
import ReduxFormField from "../../forms/components/ReduxFormField";
import SelectReduxField from "../../forms/components/SelectReduxField";
import validator from "../../forms/validator";
import { submitCVL, exportCvlVariants } from "../actions";
import * as constants from "../constants";
import { ELIGIBLE_FOR_AUTOMATION_WARNING } from "../constants";
import {
  canExportCVL,
  getCuratedLists,
  getCvlOptions,
  isDataLoading,
} from "../selectors";
import {
  formatCVLDate,
  getLastCuratorEmailLink,
  getExportButtonTooltip,
  shouldShowEligibleForAutomatedAnnotationDecisionsWarning,
} from "../utils";
import { getCvlValidationSchema } from "../validation";

interface CVLEditFormProps extends PropsFromRedux {
  submitCVL: any;
}

const automationMeta = {
  meta: {
    touched: true,
    warning: (
      <>
        <i className="fa fa-warning" /> {ELIGIBLE_FOR_AUTOMATION_WARNING}
      </>
    ),
  },
};

export const CVLEditForm = ({
  cvl: {
    projectCount,
    updated,
    variantCount,
    curatedVariantListId,
    [constants.ELIGIBLE_FOR_AUTOMATED_ANNOTATION_DECISIONS_FIELD]:
      eligibleForAutomatedAnnotationDecisions,
  },
  change,
  pristine,
  reset,
  isSubmitting,
  handleSubmit,
  submitCVL,
  cvlOptions = {},
  automation,
  cvl,
  exportCvlVariants,
  canExport,
}: CVLEditFormProps & InjectedFormProps<Index, CVLEditFormProps>) => {
  const saved = !!curatedVariantListId;
  const onAutomationChange = useCallback(
    (event, value) => {
      if (value) {
        change(constants.SHOW_PATHOGENICITY_FIELD, true);
        change(constants.LIST_TYPE_FIELD, CURATED_LIST_TYPE_KNOWLEDGEBASE);
      }
    },
    [change]
  );

  const automationWarning = useMemo(
    () =>
      shouldShowEligibleForAutomatedAnnotationDecisionsWarning(
        automation,
        eligibleForAutomatedAnnotationDecisions
      )
        ? automationMeta
        : {},
    [automation, eligibleForAutomatedAnnotationDecisions]
  );

  return (
    <form
      onSubmit={handleSubmit(submitCVL)}
      className="form-horizontal"
      data-testid="cvl-form"
    >
      <div className="row">
        <div className="col-md-8">
          <Field
            name="name"
            required
            narrow
            component={ReduxFormField}
            type="text"
            label="Curated variant list name"
            trimOnBlur
          />
        </div>
        <div className="col-md-8">
          <Field
            name="description"
            required={false}
            narrow
            component={ReduxFormField}
            type="textarea"
            label="Description"
          />
        </div>
        <div className="col-md-8">
          <SelectReduxField
            name="variantType"
            required
            narrow
            isDisabled
            options={cvlOptions.variantTypes}
            label="Variants type"
          />
        </div>
        <div className="col-md-8">
          <Field
            name={constants.AUTOMATION_FIELD}
            component={ReduxFormField}
            type="checkbox"
            narrow
            disabled={saved}
            label="Automation"
            onChange={onAutomationChange}
            {...automationWarning}
          />
        </div>

        <div className="col-md-8">
          <SelectReduxField
            name={constants.LIST_TYPE_FIELD}
            required
            narrow
            options={cvlOptions.listTypes}
            label="List type"
            isDisabled={automation === true}
          />
        </div>
        <div className="col-md-8">
          <Field
            name={constants.SHOW_PATHOGENICITY_FIELD}
            narrow
            component={ReduxFormField}
            type="checkbox"
            label="Show pathogenicity"
          />
        </div>

        {saved && (
          <div className="col-md-8">
            <LabelField isFormField value={projectCount} label="Projects" />
          </div>
        )}

        <div className="col-md-8">
          <SelectReduxField
            name="curators"
            required={false}
            narrow
            isMulti
            options={cvlOptions.curators}
            label="Curators"
          />
        </div>
        {saved && (
          <>
            <div className="col-md-8">
              <div className="form-group row">
                <label className="col-sm-4">Owner</label>
                <div className="col-sm-8">
                  <Link
                    href={`mailto:${
                      cvl[constants.OWNER_EMAIL_FIELD]
                    }?subject=About the Congenica curated list '${
                      cvl[constants.NAME_FIELD]
                    }'&body=Dear ${cvl[constants.OWNER_NAME_FIELD]},`}
                  >
                    {cvl[constants.OWNER_NAME_FIELD]}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <LabelField
                isFormField
                value={formatCVLDate(updated)}
                label="Last updated"
              />
            </div>
            <div className="col-md-8">
              <div className="form-group row">
                <label className="col-sm-4 last-curator">Last curated by</label>
                <div className="col-sm-8">{getLastCuratorEmailLink(cvl)}</div>
              </div>
            </div>
          </>
        )}

        {saved && (
          <div className="col-md-8">
            <LabelField
              isFormField
              value={variantCount}
              label="Number of variants"
            />
          </div>
        )}

        {!saved && (
          <div className="col-md-8">
            <Field
              name="csvFile"
              required={false}
              narrow
              component={ReduxFormField}
              type="file"
              accept="text/csv"
              label="List content"
            />
            <p>
              <em>
                Note that only Comma Separated Value (CSV) files will be
                accepted.
              </em>
            </p>
          </div>
        )}
      </div>
      <div className={classNames({ "curated-lists-footer": saved })}>
        {saved && canExport && (
          <Tooltip
            content={getExportButtonTooltip(variantCount)}
            placement="right"
          >
            <div>
              <Button
                type="button"
                context="primary"
                onClick={() => exportCvlVariants(curatedVariantListId)}
                disabled={variantCount === 0}
                data-testid="export"
              >
                <Icon type="downloadAlt" /> Export
              </Button>
            </div>
          </Tooltip>
        )}
        {!saved && (
          <Link
            className="btn btn-primary"
            data-testid="download-cvl-template"
            role="button"
            href="/webapi/entities/curated-variant-list-templates/snv/csv"
            download
          >
            <Icon type="downloadAlt" /> Download template
          </Link>
        )}
        <FormActions
          isSubmitting={isSubmitting}
          reset={reset}
          pristine={pristine}
        />
      </div>
    </form>
  );
};

export const ConnectedForm = reduxForm<Index, CVLEditFormProps>({
  form: constants.CURATED_LIST_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
  asyncValidate: (values, dispatch, { curatedLists }) =>
    validator(getCvlValidationSchema(curatedLists))(values),
})(CVLEditForm);

const formSelector = formValueSelector(constants.CURATED_LIST_FORM);
const mapStateToProps = (state, { cvl }) => {
  const { curators = [] } = cvl;

  const initialValues = {
    ...cvl,
    curators: curators.map(({ id }) => id),
  };
  return {
    initialValues,
    cvl,
    isSubmitting: isDataLoading(state),
    cvlOptions: getCvlOptions(state),
    curatedLists: getCuratedLists(state),
    automation: formSelector(state, constants.AUTOMATION_FIELD),
    canExport: canExportCVL(state, cvl),
  };
};

const mapDispatchToProps = {
  submitCVL,
  exportCvlVariants,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectedForm);
