import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class DateLabel extends PureComponent {
  static displayName = "DateLabel";

  static propTypes = {
    timestamp: PropTypes.number.isRequired,
  };

  render() {
    const { timestamp, className } = this.props;
    const date = moment(timestamp);

    return (
      <div className={classNames("date-label", className)}>
        <div className="date-label-day">{date.format("D")}</div>
        <div className="date-label-month">{date.format("MMM")}</div>
      </div>
    );
  }
}
