export const EXAC_EXOMES_FIELDS = {
  AF: {
    key: "afExac",
    label: "All populations",
  },
  AF_AFR: {
    key: "afExacAfr",
    label: "African & African American",
  },
  AF_AMR: {
    key: "afExacAmr",
    label: "American",
  },
  AF_EAS: {
    key: "afExacEas",
    label: "East Asian",
  },
  AF_FIN: {
    key: "afExacFin",
    label: "Finnish",
  },
  AF_NFE: {
    key: "afExacNfe",
    label: "Non-Finnish European",
  },
  AF_SAS: {
    key: "afExacSas",
    label: "South Asian",
  },
};
