import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";

import Icon from "../../elements/base/Icon";

export default class Tip extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array,
      PropTypes.string,
    ]).isRequired,
    locationRef: PropTypes.any.isRequired,
    active: PropTypes.bool,
    buffer: PropTypes.number,
    position: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    showPrevious: PropTypes.bool,
    showNext: PropTypes.bool,
    handlePreviousClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    handleNextClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    handleClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    handleDisableSingleTip: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    handleNeverShowTips: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  };

  static defaultProps = {
    position: "left",
    active: false,
    className: "",
    buffer: 10,
    showPrevious: false,
    showNext: false,
    handlePreviousClick: false,
    handleNextClick: false,
    handleClose: false,
    handleDisableSingleTip: false,
    handleNeverShowTips: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      containerRef: false,
      style: {
        maxWidth: "300px",
      },
    };
  }

  componentDidUpdate() {
    const { locationRef } = this.props;
    const { containerRef } = this.state;
    if (locationRef && containerRef) {
      const containerRefBoundingBox = containerRef.getBoundingClientRect();

      if (
        containerRefBoundingBox.height === 0 &&
        containerRefBoundingBox.width === 0
      ) {
        setTimeout(this.calculatePosition, 10);
      } else {
        this.calculatePosition();
      }
    }
  }

  calculatePosition() {
    const { locationRef, position, buffer } = this.props;
    const { style, containerRef } = this.state;
    if (!style.left && locationRef && containerRef) {
      /* eslint-disable react/no-find-dom-node */
      const locationDom = ReactDOM.findDOMNode(locationRef);
      /* eslint-enable react/no-find-dom-node */
      const rect = locationDom.getBoundingClientRect();
      const containerRefBoundingBox = containerRef.getBoundingClientRect();
      let newStyle = {};

      if (
        containerRefBoundingBox.width > 0 &&
        containerRefBoundingBox.height > 0
      ) {
        // Default Case is top
        switch (position) {
          case "left":
            let left =
              parseInt(rect.left, 10) -
              parseInt(containerRefBoundingBox.width, 10) -
              buffer;
            left = Math.max(left, 0);
            newStyle = {
              position: "absolute",
              top:
                rect.top + (rect.height - containerRefBoundingBox.height) / 2,
              left,
              maxWidth: "300px",
            };
            break;

          case "right":
            left = parseInt(rect.left, 10) + parseInt(rect.width, 10) + buffer;
            left = left < 0 ? 0 : left;
            newStyle = {
              position: "absolute",
              top:
                rect.top + (rect.height - containerRefBoundingBox.height) / 2,
              left,
              maxWidth: "300px",
            };
            break;

          case "bottom":
            left = parseInt(rect.left, 10) + parseInt(rect.width, 10) + buffer;
            left = left < 0 ? 0 : left;
            newStyle = {
              position: "absolute",
              top: `${
                parseInt(rect.top, 10) + parseInt(rect.height, 10) + buffer
              }px`,
              left:
                rect.left + (rect.width - containerRefBoundingBox.width) / 2,
              maxWidth: "300px",
            };
            break;

          case "top":
          default:
            newStyle = {
              position: "absolute",
              top: `${
                parseInt(rect.top, 10) -
                parseInt(containerRefBoundingBox.height, 10) -
                buffer
              }px`,
              left:
                rect.left + (rect.width - containerRefBoundingBox.width) / 2,
              maxWidth: "300px",
            };
            break;
        }

        this.setState({
          style: newStyle,
        });
      }
    }
  }

  createContainerRef(container) {
    const { containerRef } = this.state;
    if (!containerRef) {
      this.setState({
        containerRef: container,
      });
    }
  }

  render() {
    const {
      showPrevious,
      showNext,
      active,
      locationRef,
      buffer,
      position,
      className,
      children,
      handleDisableSingleTip,
      handleNeverShowTips,
      handleNextClick,
      handlePreviousClick,
      handleClose,
      ...otherProps
    } = this.props;
    const { style } = this.state;

    return (
      <div
        ref={this.createContainerRef}
        style={style}
        className={classNames("tip", { "is-visible": className }, className)}
        {...otherProps}
      >
        {handleClose && (
          <Icon onClick={handleClose} type="cross" className="tip-close" />
        )}
        {children}
        <div>
          {showPrevious && handlePreviousClick && (
            <span onClick={handlePreviousClick} className="btn btn--small">
              Previous
            </span>
          )}
          {handleDisableSingleTip && (
            <span
              onClick={handleDisableSingleTip}
              className="btn btn--small tip-hide"
            >
              Don&apos;t show this again
            </span>
          )}
          {showNext && handleNextClick && (
            <span onClick={handleNextClick} className="btn btn--small">
              Next
            </span>
          )}
          {handleNeverShowTips && (
            <span
              onClick={handleNeverShowTips}
              className="btn btn--small tip-never-show"
            >
              Don&apos;t show tips
            </span>
          )}
        </div>
      </div>
    );
  }
}
