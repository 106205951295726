import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Actions } from "pattern-library";

const FormActions = ({ pristine, isSubmitting = false, reset }) => (
  <Actions
    actions={[
      {
        label: "Reset",
        className: classNames("btn btn-info"),
        disabled: pristine || isSubmitting,
        action: reset,
        type: "button",
      },
      {
        label: isSubmitting ? "Submitting" : "Save",
        disabled: pristine || isSubmitting,
        className: classNames("btn", {
          "btn-primary": !isSubmitting,
          "btn-info": isSubmitting,
        }),
        type: "submit",
      },
    ]}
  />
);

FormActions.propTypes = {
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default FormActions;
