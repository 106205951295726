import classNames from "classnames";
import React, { useState } from "react";

import { Tooltip } from "pattern-library";

import { replaceLineBreaksWithBR } from "common/utils";

import { auditTypes } from "./constants";

const AuditTitleAbstract = ({ title, abstract }) => {
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="audit-item-article-title">
        {title}
        {!!abstract && <span className="caret" onClick={toggleHandler} />}
      </div>
      {open && (
        <div
          className="audit-item-article-abstract"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: abstract,
          }}
        />
      )}
    </>
  );
};

const AuditArticle = ({
  article: { title, abstract, authors, pmid, url } = {},
}) => (
  <div className="audit-item-article row">
    <div className="audit-item-article-content col-md-11">
      <AuditTitleAbstract title={title} abstract={abstract} />
      <div className="audit-item-article-authors">{authors}</div>
    </div>
    <div className="audit-item-article-options col-md-1">
      {pmid || url ? (
        <a
          className="audit-item-article-hyperlink"
          href={pmid ? `http://www.ncbi.nlm.nih.gov/pubmed/${pmid}` : url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="glyphicon glyphicon-link" />
        </a>
      ) : null}
    </div>
  </div>
);

const AuditArticleComment = ({
  item: { article: { title } = {}, auditValue },
}) => (
  <p>
    <Tooltip content={title} placement="right">
      <a
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: `<i class="glyphicon glyphicon-comment"/> ${replaceLineBreaksWithBR(
            auditValue
          )}`,
        }}
      />
    </Tooltip>
  </p>
);

export const AuditDescription = ({ item, showAssociatedTranscript }) => {
  const { article, auditType, auditValue, suggestedTranscript, criteria } =
    item;
  // If article
  if (auditType === "variant_article" && article) {
    return <AuditArticle article={article} />;
  }

  // If article comment
  if (auditType === "variant_article_comment") {
    return <AuditArticleComment item={item} />;
  }

  let auditLabelComponent = null;
  let auditIconComponent = null;
  let criteriaLabels = null;
  let transcriptLabel = null;

  const typeResult = auditTypes[auditType];
  if (typeResult) {
    const { label, icon } = typeResult;
    const auditLabel = auditValue ? `${label}: ${auditValue}` : label;
    auditLabelComponent = <strong>{auditLabel}</strong>;

    const auditIconClass = icon;
    if (auditIconClass) {
      auditIconComponent = <i className={auditIconClass} />;
    }
  } else {
    if (auditValue) {
      auditIconComponent = (
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: replaceLineBreaksWithBR(auditValue),
          }}
        />
      );
    }
  }

  // ACMG Criteria
  const renderAcmg = [
    "acmg",
    "acmg_suggestions_used",
    "acmg_suggestions_rejected",
  ].includes(auditType);

  if (renderAcmg && criteria && criteria.length > 0) {
    criteriaLabels = (
      <div>
        {criteria.map(({ codeFormatted, displayStyle }) => (
          <span
            key={codeFormatted}
            style={{ marginRight: "2px" }}
            className={classNames([
              "label",
              {
                [`label-${displayStyle}`]: !!displayStyle,
              },
            ])}
          >
            {codeFormatted}
          </span>
        ))}
      </div>
    );
  }

  const renderAcmgSuggestion = [
    "acmg_suggestions_used",
    "acmg_suggestions_rejected",
  ].includes(auditType);
  if (showAssociatedTranscript && renderAcmgSuggestion && suggestedTranscript) {
    transcriptLabel = (
      <div style={{ marginTop: "2px" }}>
        <span>Associated transcript: {suggestedTranscript}</span>
      </div>
    );
  }

  return (
    <>
      {auditIconComponent} {auditLabelComponent}
      {criteriaLabels}
      {transcriptLabel}
    </>
  );
};
