import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class FormGroup extends PureComponent {
  static displayName = "FormGroup";

  static propTypes = {
    /**
     * The content of the form group, whether it is text or a react element
     */
    children: PropTypes.node.isRequired,
    /**
     * Class to apply to the form-group div
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, children, ...otherProps } = this.props;

    return (
      <div className={classNames("form-group", className)} {...otherProps}>
        {children}
      </div>
    );
  }
}
