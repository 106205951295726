import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { Tooltip } from "pattern-library";

import FilterSection from "modules/variants/components/FilterSection";
import GeneSidePanel from "modules/variants/components/GeneSidePanel";

import * as actions from "../actions";
import * as selectors from "../selectors";

import ResetIcon from "./ResetIcon";
import ConfigModal from "./config/ConfigModal";

export const FilterSidebar = ({
  filterValues = [],
  expandCollapse,
  resetConfigValues,
  saveConfigValues,
  activeGenePanels = [],
  patientId,
  filterByGene,
  configValuesNotModified,
}) => {
  const [isConfigModalVisible, setConfigModalVisible] = useState(false);

  const showConfig = useCallback(() => {
    setConfigModalVisible(true);
  }, []);

  const closeConfig = useCallback(() => {
    saveConfigValues();
    setConfigModalVisible(false);
  }, [saveConfigValues]);

  const resetConfig = useCallback(() => {
    resetConfigValues();
  }, [resetConfigValues]);

  const selectGeneFromPanel = useCallback(
    (patientId, geneId) => {
      filterByGene(geneId);
    },
    [filterByGene]
  );

  return (
    <div className="filter-sidebar">
      <div className="filter-sidebar-title">
        <p className="filter-sidebar-title-text">Configuration</p>
        <div className="filter-sidebar-title-icons">
          <Tooltip content="Collapse" placement="right" trigger="mouseenter">
            <span
              onClick={expandCollapse}
              className="glyphicon glyphicon-chevron-left"
            />
          </Tooltip>
          <ResetIcon onClick={resetConfig} disabled={configValuesNotModified} />
          <Tooltip content="Edit config" placement="right" trigger="mouseenter">
            <span className="glyphicon glyphicon-cog" onClick={showConfig} />
          </Tooltip>
        </div>
      </div>
      {!!activeGenePanels.length && (
        <div>
          <h2 className="filter-section-header">Gene Panels</h2>
          {activeGenePanels.map(panel => (
            <GeneSidePanel
              panel={panel}
              key={panel.genePanelId}
              patientId={patientId}
              selectContextForVariantPanel={selectGeneFromPanel}
            />
          ))}
        </div>
      )}
      <ul className="filter-section-value">
        {filterValues.map(section => (
          <FilterSection section={section} key={section.label} />
        ))}
      </ul>
      <ConfigModal show={isConfigModalVisible} close={closeConfig} />
    </div>
  );
};

const mapStateToProps = state => ({
  activeGenePanels: selectors.getActiveGenePanels(state),
  patientId: selectors.getPatientId(state),
  filterValues: selectors.getSelectedFilters(state),
  configValuesNotModified: selectors.configValuesNotModified(state),
});

const mapDispatchToProps = {
  resetConfigValues: actions.configuration.reset,
  saveConfigValues: actions.configuration.save,
  filterByGene: actions.filterByGene,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSidebar);
