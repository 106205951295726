// @flow
import { createSelector } from "@reduxjs/toolkit";
import { camelizeKeys } from "humps";
import { path } from "ramda";

import { FAILURE_STATUS, IN_PROGRESS_STATUS } from "../../common/constants";
import { isRequestCompleted } from "../../common/selectors";
import { required } from "../forms/rules";

import * as constants from "./constants";

const uiPath = (...items: any) => path([constants.NAME, "ui", ...items]);
export const getData = path([constants.NAME, "data", "metadataFields"]);
const getMetadataRequestStatus = uiPath("metadataRequestStatus");
export const hasMadeRequest = (state: any) => !!getMetadataRequestStatus(state);

export const hasMetadataRequestFailed = (state: any) =>
  getMetadataRequestStatus(state) === FAILURE_STATUS;

export const isMetadataLoading = (state: any) =>
  getMetadataRequestStatus(state) === IN_PROGRESS_STATUS;

export const isMetadataLoadCompleted = (state: any) =>
  isRequestCompleted(getMetadataRequestStatus(state));

export const getMetadataFields = createSelector(
  getData,
  (
    data: Array<{ required?: boolean }> = []
  ): Array<{
    required?: boolean,
    validate?: Array<Function>,
  }> =>
    data.map(({ required: requiredProp, ...item }) => {
      if (requiredProp === true) {
        return {
          ...item,
          required: requiredProp,
          validate: [required],
        };
      }

      return {
        ...item,
        required: requiredProp,
      };
    })
);

export const getCamelizedMetadataFields = createSelector(
  getMetadataFields,
  (fields = []) => camelizeKeys(fields)
);

export const getMetadata = path([constants.NAME, "data", "metadata"]);

export const hasRequiredMetadataFields = createSelector(
  getMetadataFields,
  (
    metadataFields: Array<{
      required?: boolean,
      validate?: Array<Function>,
    }> = []
  ): boolean => metadataFields.some(({ required = false }) => required)
);
