import _ from "lodash";
import React, { memo, useState } from "react";

import { getOncoTree } from "modules/api/interpretationRequest";
import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

const DEBOUNCE_DELAY = 400;

export const TumourTypeField = memo(props => {
  const [options, setOptions] = useState([]);

  const loadOncoTree = _.debounce((query, callback) => {
    getOncoTree(query).then(resp => callback(resp));
  }, DEBOUNCE_DELAY);

  const handleChange = params => {
    setOptions(params ? [params] : []);
  };

  return (
    <FormikFormField
      type="select"
      async
      isClearable
      placeholder="Type to search tumour type..."
      loadOptions={loadOncoTree}
      onChange={handleChange}
      options={options}
      cacheOptions={false}
      isMulti={false}
      classNamePrefix="irSelect"
      {...props}
    />
  );
});
