import { memo } from "react";

import { LabelField } from "pattern-library";

import { SV_COLUMNS } from "modules/projectSettings/components/filterPresets/constants";

type Props = {
  gnomadFeatureEnabled?: boolean;
  columns: Array<string>;
};

export const FilterPresetColumnsInfo = memo(
  ({ columns = [], gnomadFeatureEnabled }: Props) => {
    const gnomadFilter = col => !(!gnomadFeatureEnabled && col === "gnomad_af");

    const transformedValues = columns
      .filter(gnomadFilter)
      .map(column => {
        const columnsData = SV_COLUMNS.find(col => col.key === column);
        if (!columnsData) {
          return `Column (${column}) not found`;
        }
        return columnsData.label;
      })
      .join(", ");
    return (
      <LabelField
        key="Columns"
        data-testid="Columns"
        label="Columns"
        value={transformedValues}
      />
    );
  }
);

FilterPresetColumnsInfo.displayName = "FilterPresetColumnsInfo";

export default FilterPresetColumnsInfo;
