import React, { FC, useCallback, useEffect, useState } from "react";
import { Column } from "react-table-7";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

export const FILTER_PLACEHOLDER = "Filter by Gene...";

export type VariantType = OncologyCNV | OncologySNV;

export interface CommonProps {
  patientId: number;
  data?: VariantType[];
  isLoading?: boolean;
  columns: Column[];
  title: string;
}

const filter = (
  data: Array<VariantType> = [],
  filterValue: string
): Array<VariantType> => {
  if (!filterValue) return data;
  return data.filter(({ gene }) =>
    gene.toUpperCase().includes(filterValue.toUpperCase())
  );
};

export const CommonContainer: FC<CommonProps> = ({
  title,
  isLoading = false,
  columns,
  data,
}) => {
  const [filterValue, setFilterValue] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<VariantType[]>([]);

  useEffect(() => {
    if (data) setFilteredItems(filter(data, filterValue));
  }, [data, filterValue, setFilteredItems]);

  const onFilterChange = useCallback(
    ({ filter = "" }) => {
      setFilterValue(filter);
    },
    [setFilterValue]
  );
  return (
    <Table
      showTitleInfo
      title={title}
      fetchData={onFilterChange}
      enableFilter
      columns={columns}
      data={filteredItems}
      showPagination={filteredItems.length > DEFAULT_PAGE_SIZE}
      autoResetPage={false}
      loading={isLoading}
      filterPlaceholder={FILTER_PLACEHOLDER}
    />
  );
};
