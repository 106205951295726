import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";

import { error } from "modules/messages/actions";

import { normalizeError } from "api/utils/common";

export const UNKNOWN_ERROR = "Unknown error";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload) {
        api.dispatch(error(`${normalizeError(action.payload).message}`));
      } else {
        api.dispatch(
          error(action.error?.message ? action.error?.message : UNKNOWN_ERROR)
        );
      }
    }

    return next(action);
  };
