import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Heading, LabelField } from "pattern-library";

import { getValue } from "../utils";

const VariantData = ({ data, className }) => (
  <div>
    <div
      className={classNames(className, "exomiser-pane", "exomiser-pane-data")}
    >
      <LabelField
        label="Individual Variant Score"
        value={getValue(data, "variantScore")}
      />
      <LabelField
        label="On IncludeList"
        className="exomiser-gap"
        value={getValue(data, "whiteListed")}
      />
      <LabelField
        className="exomiser-gap-below"
        label="ClinVar"
        value={getValue(data, "clinvar")}
      />
      <Heading className="accordion-section-subheader" type="h4">
        Pathogenicity Data
      </Heading>
      <LabelField
        label="Most Pathogenic Score"
        value={getValue(data, "pathogenicityData.mostPathogenicScore")}
      />
      <LabelField
        label="Polyphen2"
        value={getValue(data, "pathogenicityData.polyphen")}
      />
      <LabelField
        label="Mutation Taster"
        value={getValue(data, "pathogenicityData.mutationTaster")}
      />
      <LabelField
        label="SIFT"
        value={getValue(data, "pathogenicityData.sift")}
      />
      {data.isCaddEnabled && (
        <LabelField
          label="CADD"
          value={getValue(data, "pathogenicityData.cadd")}
        />
      )}
    </div>
  </div>
);

VariantData.propTypes = {
  /**
   * Object containing all variant-specific data for this variant
   */
  data: PropTypes.shape({
    variantScore: PropTypes.number,
    whiteListed: PropTypes.bool,
    clinvar: PropTypes.bool,
    pathogenicityData: PropTypes.shape({
      mostPathogenicScore: PropTypes.number,
      polyphen: PropTypes.number,
      mutationTaster: PropTypes.number,
      sift: PropTypes.number,
    }),
  }),
  /**
   * Additional CSS class for rendering the component
   */
  className: PropTypes.string,
};

export default VariantData;
