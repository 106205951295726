import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Icon, IconLoading, Link } from "pattern-library";

import { formatDateTime } from "modules/utils";

import { fetchAuditInfo } from "../actions";
import { auditInfoSelector } from "../selectors";
import { CVLProps, PatientDetails } from "../types";

const THRESHOLD = 5;

interface Props extends PropsFromRedux {
  patientId: number;
}

export const AuditInfo: FC<Props> = ({
  patientId,
  fetchAuditInfo,
  auditInfo,
}: Props) => {
  useEffect(() => {
    fetchAuditInfo(patientId);
  }, [fetchAuditInfo, patientId]);

  const [genePanelCollapsed, setGenePanelCollapsed] = useState<boolean>(true);

  const {
    loading,
    data: {
      patientDetails: { automatedDecisionTime } = {} as PatientDetails,
      cvl: { name: CVLName } = {} as CVLProps,
      genePanels = [],
    },
  } = auditInfo;

  const visibleGenePanels = useMemo<Array<GenePanel>>(() => {
    if (genePanels.length > THRESHOLD && genePanelCollapsed) {
      return genePanels.slice(0, THRESHOLD - 1);
    }
    return genePanels;
  }, [genePanels, genePanelCollapsed]);

  const onExpandClick = useCallback(() => {
    setGenePanelCollapsed(!genePanelCollapsed);
  }, [genePanelCollapsed, setGenePanelCollapsed]);

  const generateGenePanelsToolTip = useCallback(() => {
    if (genePanels.length > THRESHOLD && genePanelCollapsed) {
      return genePanels
        .slice(THRESHOLD - 1)
        .reduce((acc, { title }) => (acc += `${title}\n`), "");
    }
    return "";
  }, [genePanels, genePanelCollapsed]);

  return (
    <div className="audit__info">
      <div className="audit__info-section">
        <div className="audit__info-left">Automated decision time:</div>
        <div className="audit__info-right">
          {!!loading && <IconLoading loading />}
          {!loading && automatedDecisionTime
            ? formatDateTime(automatedDecisionTime * 1000)
            : ""}
        </div>
      </div>
      <div className="audit__info-section">
        <div className="audit__info-left">Curated variant list:</div>
        <div className="audit__info-right">
          {!!loading && <IconLoading loading />}
          {!loading && CVLName}
        </div>
      </div>
      <div className="audit__info-section">
        <div className="audit__info-left">Gene panels:</div>
        <div className="audit__info-right">
          {!!loading && <IconLoading loading />}
          {!loading &&
            visibleGenePanels.map(({ title, version }) => (
              <span key={title} className="text-nowrap">
                {title}
                {version && <span> (version {version})</span>}
              </span>
            ))}
          {!loading && genePanels.length > THRESHOLD && genePanelCollapsed && (
            <Link title={generateGenePanelsToolTip()} onClick={onExpandClick}>
              <Icon type="collapseDown" />
              {` ${genePanels.length - THRESHOLD} more`}
            </Link>
          )}
          {!loading && genePanels.length > THRESHOLD && !genePanelCollapsed && (
            <Link title={generateGenePanelsToolTip()} onClick={onExpandClick}>
              <Icon type="collapseUp" />
              &nbsp;Collapse
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  auditInfo: auditInfoSelector,
});

const mapDispatchToProps = {
  fetchAuditInfo: fetchAuditInfo.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AuditInfo);
