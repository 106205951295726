export default function callLogin(email, password) {
  const formData = new FormData();

  formData.append("email", email);
  formData.append("password", password);

  return fetch("/dancer/login", {
    method: "POST",
    credentials: "include",
    body: formData,
  });
}
