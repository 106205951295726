import classNames from "classnames";
import { camelizeKeys } from "humps";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Divider, Dropdown, Tooltip, Loading } from "pattern-library";

import {
  initialize,
  onSvFilterChange,
  onSvColumnsChange,
  setSVViewMode,
} from "./actions";
import ResetFiltersButton from "./components/ResetFiltersButton";
import SVFilters from "./components/SVFilters";
import SVPresetsContainer from "./components/SVPresets";
import ToggleCollapseButton from "./components/ToggleCollapseButton";
import ViewPresetButton from "./components/ViewPresetButton";
import { VIEW_AS } from "./constants";
import { getViewMode } from "./selectors";
import { FilterData, ViewMode } from "./types";
import { filterPatientGenePanels, prepareFilters } from "./utils";

import usePatientGenePanels from "hooks/usePatientGenePanels";

export const LEGACY_FILTER_ID = "sv-filter-sidebar";
export const LEGACY_CONNECTOR_ID = "legacy-sv-applied-filters-connector";

type Props = {
  projectId: number;
  patientId: number;
  curatedVariantLists:
    | {
        curated_variant_list_id: number | string;
        name: string;
      }
    | Index;

  filterData: FilterData;
  /**
   * the event that is listened in the legacy code to update the preset selection
   */
  selectPresetEvent: string;
  /**
   * the event that is listened in the legacy code to update the filter
   */
  filterChangeEvent: string;
  /**
   * the event that is listened in the legacy code to update the view mode
   */
  viewMode: ViewMode;
  viewModeChangeEvent: string;
  initialize: typeof initialize;
  onSvFilterChange: typeof onSvFilterChange;
  onSvColumnsChange: typeof onSvColumnsChange;
  setSVViewMode: typeof setSVViewMode;
};

export const SVFilterSidebar = ({
  projectId,
  patientId,
  initialize,
  curatedVariantLists = {},
  filterData,
  onSvFilterChange,
  onSvColumnsChange,
  viewMode,
  setSVViewMode,
}: Props) => {
  const [collapsed, setCollapsed] = useState(false);

  const { genePanels: patientGenePanels, isSuccess: patientGenePanelsLoaded } =
    usePatientGenePanels(patientId);

  const dispatchFilterCollapseStatus = collapseStatus => {
    const element = document.getElementById(LEGACY_FILTER_ID);
    if (!element) {
      return;
    }
    element.dispatchEvent(
      new CustomEvent<{ details: boolean }>("filterCollapseStatusEvent", {
        detail: collapseStatus,
      })
    );
  };

  const dispatchViewModeChanged = eventData => {
    const element = document.getElementById(LEGACY_CONNECTOR_ID);
    if (element) {
      element.dispatchEvent(
        new CustomEvent("viewModeChangeEvent", {
          bubbles: true,
          detail: eventData,
        })
      );
    }
  };

  useEffect(() => {
    initialize(projectId, patientId, filterData);
  }, [initialize, projectId, patientId, filterData]);

  const onViewModeChange = ({ target: { value } }) => {
    setSVViewMode(value);
    dispatchViewModeChanged(value);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    dispatchFilterCollapseStatus(!collapsed);
  };

  const submitHandler = useCallback(
    values => {
      onSvFilterChange(values);
    },
    [onSvFilterChange]
  );

  const filterPresetChangeHandler = useCallback(
    selectedFilterPreset => {
      if (!patientGenePanelsLoaded) {
        return;
      }
      const { filters = {}, columns = [] } =
        selectedFilterPreset?.value?.attributes?.config || {};
      onSvColumnsChange(columns);

      const { genePanel } = filters;
      const patientFilters = {
        ...filters,
        genePanel: filterPatientGenePanels(genePanel, patientGenePanels),
      };
      submitHandler(prepareFilters(patientFilters));
    },
    [
      submitHandler,
      onSvColumnsChange,
      patientGenePanelsLoaded,
      patientGenePanels,
    ]
  );

  if (!patientGenePanelsLoaded) {
    return <Loading />;
  }

  return (
    <div
      /*This id is necessary for communicating with the Perl SV table code, as that isn't yet written in React*/
      id={LEGACY_CONNECTOR_ID}
      className={classNames({
        "structural-variants": !collapsed,
        "structural-variants-collapsed": collapsed,
      })}
    >
      {collapsed && (
        <>
          <Tooltip content="Expand filter sidebar">
            <ToggleCollapseButton
              collapsed={collapsed}
              toggleCollapse={toggleCollapse}
            />
          </Tooltip>
          <Tooltip content="View filter preset">
            <ViewPresetButton
              projectId={projectId}
              curatedVariantLists={curatedVariantLists}
              collapsed={collapsed}
            />
          </Tooltip>
          <Tooltip content="Reset filters">
            <ResetFiltersButton
              onPresetChange={filterPresetChangeHandler}
              collapsed={collapsed}
            />
          </Tooltip>
        </>
      )}
      <div className="structural-variants-filter">
        <div className="structural-variants-btn-collapse">
          <Tooltip content="Collapse filter sidebar">
            <ToggleCollapseButton
              collapsed={collapsed}
              toggleCollapse={toggleCollapse}
            />
          </Tooltip>
        </div>
        <div
          className="structural-variants-filter-row row"
          style={{ alignItems: "flex-end" }}
        >
          <div className="col-sm-6 col-md-12">
            <label>View as:</label>
            <Dropdown
              value={viewMode}
              options={VIEW_AS}
              onChange={onViewModeChange}
            />
            <Divider />
          </div>
          <div className="col-sm-6 col-md-12">
            <SVPresetsContainer
              projectId={projectId}
              curatedVariantLists={curatedVariantLists}
              onPresetChange={filterPresetChangeHandler}
            />
            <Divider />
          </div>
        </div>
        <SVFilters
          patientId={patientId}
          projectId={projectId}
          filterData={camelizeKeys(filterData)}
          onSubmit={submitHandler}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  viewMode: getViewMode(state),
});

const mapDispatchToProps = {
  initialize,
  onSvFilterChange,
  onSvColumnsChange,
  setSVViewMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(SVFilterSidebar);
