import { set } from "dot-prop-immutable";

import { IDLE_STATUS } from "../../common/constants";
import {
  getUpdatedPresetsDataState,
  getUpdatedPresetsUIState,
} from "../utils/filter-presets";

import type { Action } from "./actions";
import * as constants from "./constants";
import type { State } from "./flow-types";

export const initialState: State = {
  data: {
    presets: [],
  },
  ui: {
    presetsRequestStatus: IDLE_STATUS,
    selectedPresetId: null,
  },
};

const updateSNVPresetsInfo = (
  state: State,
  presetsInfo: FilterPresetsStatusInfo
): State => {
  const ui = getUpdatedPresetsUIState(state, presetsInfo);
  const data = getUpdatedPresetsDataState(state, presetsInfo);

  return {
    ...state,
    ui,
    data,
  };
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SET_SNV_PRESETS_INFO:
      return updateSNVPresetsInfo(state, action.payload);
    case constants.SELECT_SNV_PRESET:
      return set(state, "ui.selectedPresetId", action.payload.preset.id);
    default:
      return state;
  }
}
