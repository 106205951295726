import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import type { User } from "../flow-types";

import { lastLoginProcessor } from "./columns";

const identity = val => val;
const propertyMap = {
  lastlogin: lastLoginProcessor,
  roles: roles =>
    roles && roles.map(({ label }) => label.toUpperCase()).join(", "),
  admin: admin => (Boolean(admin) ? "Administrator" : ""),
};

const filter = (data: Array<User> = [], filterValue: string): Array<User> =>
  data.filter(user => {
    for (const [key, value] of Object.entries(user)) {
      const valueMapper: any => mixed = propertyMap[key] || identity;
      const mappedValue: any = valueMapper(value);
      if (
        mappedValue &&
        mappedValue.toString().toUpperCase().includes(filterValue.toUpperCase())
      ) {
        return true;
      }
    }
    return false;
  });

export const UsersTable = memo(
  ({
    items = [],
    columns = [],
    isLoading = false,
    title,
    ...otherTableProps
  }) => {
    const [filterValue, setFilterValue] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
      setFilteredItems(filter(items, filterValue));
    }, [items, filterValue, setFilteredItems]);

    const onFilterChange = useCallback(
      ({ filter = "" }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    return (
      <Table
        showTitleInfo
        title={title}
        fetchData={onFilterChange}
        enableFilter
        columns={columns}
        data={filteredItems}
        showPagination={filteredItems.length > DEFAULT_PAGE_SIZE}
        autoResetPage={false}
        loading={isLoading}
        sortBy={[
          {
            id: "fullname",
            desc: false,
          },
        ]}
        {...otherTableProps}
      />
    );
  }
);

UsersTable.propTypes = {
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
};
