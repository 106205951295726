import { createSelector } from "@reduxjs/toolkit";
import { decamelize } from "humps";
import { isEmpty } from "ramda";

import { isCurrentUserAdmin } from "../auth/selectors";

import {
  snvCountsAddedToVariantPanel,
  otherPatientsSnvCountsSchema,
} from "./components/utils";
import { NAMESPACE } from "./constants";
import {
  OtherPatientsSnvCounts,
  OtherPatientsSnvCountsRow,
  OtherPatientsSnvCountsUI,
  SequenceVariantSummary,
  SUMMARY_STATE_PATH,
  SummaryData,
} from "./types";

const getStateObject = (state, path: SUMMARY_STATE_PATH) =>
  state[NAMESPACE][path] || {};
const getLoading = (state, path: SUMMARY_STATE_PATH) =>
  getStateObject(state, path).loading;
export const getData = (
  state,
  path: SUMMARY_STATE_PATH
): SequenceVariantSummary | null => getStateObject(state, path).data;
export const isOtherPatientsSnvCountsLoading = (
  state,
  path: SUMMARY_STATE_PATH
): boolean =>
  getStateObject(state, path).otherPatientsSnvCounts?.loading || false;
const getOtherPatientsSnvCountsData = (
  state,
  path: SUMMARY_STATE_PATH
): OtherPatientsSnvCounts | null =>
  getStateObject(state, path).otherPatientsSnvCounts?.data;

export const isSequenceVariantsSummaryDataLoading = createSelector(
  getLoading,
  isLoading => isLoading
);

export const getSequenceVariantsSummaryData = createSelector(
  getData,
  (data: SequenceVariantSummary): SummaryData => ({
    name: data?.gene.name,
    hiScore: data?.gene.hiScore,
    hiRgb: data?.gene.hiRgb,
    genotype: data?.snv?.genotype,
    depth: data?.snv?.depth,
    maxAf: data?.snv?.afMax,
    qualityScore: data?.snv?.score,
    denovoStatus: data?.snv?.denovoStatus,
    transcriptName: data?.snv?.transcriptName,
    hgvsC: data?.snv?.hgvsC,
    hgvsP: data?.snv?.hgvsP,
    siftPrediction: data?.snv?.siftPrediction,
    siftScore: data?.snv?.siftScore,
    polyphenPrediction: data?.snv?.polyphenPrediction,
    polyphenScore: data?.snv?.polyphenScore,
    refAllele: data?.snv?.refAllele,
    altAllele: data?.snv?.altAllele,
    codons: data?.snv?.codons,
    aminoAcid: data?.snv?.aminoAcid,
    proteinPosition: data?.snv?.proteinPosition,
    gerp: data?.snv?.gerp,
    readSplit: data?.snv?.readsplit,
    qcStatus: data?.snv?.filter,
    revel: data?.snv?.revelScore,
    inheritance: data?.snv?.inheritance,
    vepConsequence: data?.snv?.vepConsequence,
    rsId: data?.snv?.rsId,
    cosmicId: data?.snv?.cosmicId,
    hgmdId: data?.snv?.hgmdId,
    espId: data?.snv?.espId,
    splice: data?.snv?.splice,
    comparisonOutcome: data?.snv?.comparisonOutcome,
  })
);

export const getOtherPatientsSnvCounts = createSelector(
  getOtherPatientsSnvCountsData,
  isCurrentUserAdmin,
  (
    data: OtherPatientsSnvCounts,
    isAdmin: boolean
  ): OtherPatientsSnvCountsUI => {
    const href = [`summary/other-patients/tabs`];
    const queryParams: Array<string> = [];
    const list: Array<OtherPatientsSnvCountsRow> = [];
    otherPatientsSnvCountsSchema.forEach(({ name, description, isHidden }) => {
      const counts = data?.[name];
      if (!counts || isHidden?.(isAdmin)) {
        return;
      }
      const { otherCount, totalCount } = counts;
      if (snvCountsAddedToVariantPanel(counts)) {
        const decamelizedName = decamelize(name);
        queryParams.push(`${decamelizedName}_other_count=${otherCount}`);
        queryParams.push(`${decamelizedName}_total_count=${totalCount}`);
      }

      list.push({
        description,
        counts: `${counts.otherCount} out of ${counts.totalCount}`,
      });
    });
    if (!isEmpty(queryParams)) {
      href.push(queryParams.join("&"));
    }
    return {
      data: list,
      href: href.join("?"),
    };
  }
);
