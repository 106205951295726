import { camelizeKeys } from "humps";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  getGenePanelsLists,
  removeGenePanel,
  archiveGenePanel,
  exportGenePanel,
  addNewGenePanel,
  uploadGenePanel,
  getTempGenePanel,
  getTempGenePanelFoundGenes,
  getTempGenePanelNotFoundGenes,
  removeGeneFromTempGenePanel,
  saveTempGenePanel,
  updateTempGenePanel,
  fetchEditGeneDataOnTempGenePanel,
  updateEditGeneDataOnTempGenePanel,
  fetchEditGeneDataOnGenePanel,
  updateEditGeneDataOnGenePanel,
  getGenePanelInfo,
  getGenePanelPatientList,
  getGenePanelGenesList,
  updateGenePanelInfo,
  removeGeneFromGenePanel,
  copyGenePanel,
  addAllPatientsToGenePanel,
  removeAllPatientsFromGenePanel,
  addPatientToGenePanel,
  removePatientFromGenePanel,
  searchGenes,
  addGeneToGenePanel,
} from "modules/api/genePanels";
import { fetchGene } from "modules/api/genes";
import { error } from "modules/messages/actions";
import { reloadProject } from "modules/project/saga";
import { downloadFile } from "modules/utils/fileDownload";
import { processHeaders } from "modules/utils/sagas";

import * as actions from "./actions";
import { PatientOperation, type PatientOperationType } from "./flow-types";
import * as selectors from "./selectors";

export function* init() {
  yield takeLatest(
    actions.actionType.FETCH_GENE_PANELS_START,
    reloadProjectGenePanels
  );
  yield takeLatest(
    actions.actionType.RELOAD_EDIT_GENE_PANEL_DATA,
    reloadEditGenePanelDataSaga
  );
  yield takeLatest(
    actions.actionType.FETCH_GENE_PANEL_INFO_START,
    reloadProjectGenePanelInfo
  );
  yield takeLatest(
    actions.actionType.COPY_GENE_PANELS_START,
    copyGenePanelSaga
  );
  yield takeLatest(
    actions.actionType.ADD_GENE_TO_GENE_PANEL_START,
    addGeneToGenePanelSaga
  );
  yield takeLatest(
    actions.actionType.UPDATE_GENE_PANEL_INFO_START,
    updateGenePanelInfoSaga
  );
  yield takeLatest(
    actions.actionType.FETCH_GENE_PANEL_PATIENT_LIST_START,
    reloadProjectGenePanelPatients
  );
  yield takeLatest(
    actions.actionType.EXECUTE_GENE_PANEL_PATIENT_OPERATION_START,
    executeGenePanelPatientOperationSaga
  );
  yield takeLatest(
    actions.actionType.FETCH_GENE_PANEL_GENE_LIST_START,
    reloadProjectGenePanelGeneList
  );
  yield takeLatest(
    actions.actionType.REMOVE_GENE_FROM_GENE_PANEL_START,
    removeGeneFromGenePanelSaga
  );
  yield takeLatest(
    actions.actionType.REMOVE_GENE_PANELS_START,
    removeProjectGenePanel
  );
  yield takeLatest(
    actions.actionType.ARCHIVE_GENE_PANEL_START,
    archiveProjectGenePanel
  );
  yield takeLatest(
    actions.actionType.EXPORT_GENE_PANEL_START,
    exportProjectGenePanelSaga
  );
  yield takeLatest(
    actions.actionType.ADD_NEW_GENE_PANEL_START,
    addNewProjectGenePanel
  );
  yield takeLatest(
    actions.actionType.UPLOAD_NEW_GENE_PANEL_START,
    uploadNewProjectGenePanel
  );
  yield takeLatest(
    actions.actionType.FETCH_ALL_UPLOADED_GENE_PANEL_DATA,
    reloadTempGenePanelData
  );
  yield takeLatest(
    actions.actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_START,
    removeGeneFromTempGenePanelSaga
  );
  yield takeLatest(
    actions.actionType.SAVE_TEMP_GENE_PANEL_START,
    saveTempGenePanelSaga
  );
  yield takeLatest(
    actions.actionType.UPDATE_TEMP_GENE_PANEL_START,
    updateTempGenePanelSaga
  );
  yield takeLatest(actions.actionType.FETCH_GENE_START, fetchGeneSaga);
  yield takeLatest(
    actions.actionType.FETCH_EDIT_TEMP_GENE_START,
    fetchEditTempGeneSaga
  );
  yield takeLatest(actions.actionType.FETCH_EDIT_GENE_START, fetchEditGeneSaga);
  yield takeLatest(actions.actionType.SEARCH_GENE_START, searchGeneSaga);
  yield takeLatest(
    actions.actionType.UPDATE_EDIT_TEMP_GENE_START,
    updateEditTempGeneSaga
  );
  yield takeLatest(
    actions.actionType.UPDATE_EDIT_GENE_START,
    updateEditGeneSaga
  );
}

export function* reloadProjectGenePanels({ payload }) {
  const { projectId, archived = false } = payload;
  try {
    yield call(reloadProject, {
      payload: {
        projectId,
        rewriteExisting: false,
      },
    });
    const result: FetchDataResponse<any> = yield call(
      getGenePanelsLists,
      projectId,
      archived
    );
    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            "Failed to get Project Gene Panels"
        )
      );
      yield put(actions.fetchGenePanels.failure());
      return;
    }

    const { genePanels, inheritedGenePanels } = result.payload || {
      genePanels: [],
      inheritedGenePanels: [],
    };

    yield put(
      actions.fetchGenePanels.success({
        genePanelData: genePanels,
        parentGenePanelData: inheritedGenePanels,
      })
    );
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchGenePanels.failure());
  }
}

export function* fetchGeneSaga({ payload }) {
  try {
    const { geneName, ensemblVersion } = payload;
    const result: FetchDataResponse<any> = yield call(
      fetchGene,
      geneName,
      ensemblVersion
    );
    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            `Failed to get gene "${geneName}"`
        )
      );
      yield put(actions.fetchGene.failure());
      return;
    }

    yield put(actions.fetchGene.success(result.payload));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchGene.failure());
  }
}

export function* fetchEditTempGeneSaga({
  payload: { projectId, tempGenePanelId, geneName },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      fetchEditGeneDataOnTempGenePanel,
      projectId,
      tempGenePanelId,
      geneName
    );
    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            `Failed to get gene "${geneName}"`
        )
      );
      yield put(actions.fetchEditTempGene.failure());
      return;
    }

    yield put(actions.fetchEditTempGene.success(result.payload));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchEditTempGene.failure());
  }
}

export function* fetchEditGeneSaga({
  payload: { projectId, genePanelId, geneName },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      fetchEditGeneDataOnGenePanel,
      projectId,
      genePanelId,
      geneName
    );
    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            `Failed to get gene "${geneName}"`
        )
      );
      yield put(actions.fetchEditGene.failure());
      return;
    }

    yield put(actions.fetchEditGene.success(result.payload));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchEditGene.failure());
  }
}

export function* addGeneToGenePanelSaga({
  payload: { projectId, genePanelId, geneName, data },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      addGeneToGenePanel,
      projectId,
      genePanelId,
      geneName,
      data
    );

    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            `Failed to add gene("${geneName}") to gene panel`
        )
      );
      yield put(actions.addGeneToGenePanel.failure());
      return;
    }

    yield put(actions.addGeneToGenePanel.success(result.payload));

    // Reload nessesary data
    const query = yield select(selectors.getSearchGeneQuery);
    yield put(actions.searchGenes.start(projectId, genePanelId, query));
    yield put(actions.fetchGenePanelGeneList.start(projectId, genePanelId));
    yield put(actions.fetchGenePanelInfo.start(projectId, genePanelId));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.addGeneToGenePanel.failure());
  }
}

export function* searchGeneSaga({
  payload: { projectId, genePanelId, query },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      searchGenes,
      projectId,
      genePanelId,
      query
    );
    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(
        error(result.error || result.statusText || "Failed to find genes")
      );
      yield put(actions.searchGenes.failure());
      return;
    }

    yield put(actions.searchGenes.success(query, result.payload));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.searchGenes.failure());
  }
}

export function* reloadEditGenePanelDataSaga({
  payload: { projectId, genePanelId },
}) {
  yield put(actions.fetchGenePanelInfo.start(projectId, genePanelId));
  yield put(actions.fetchGenePanelPatientList.start(projectId, genePanelId));
  yield put(actions.fetchGenePanelGeneList.start(projectId, genePanelId));
}

export function* reloadProjectGenePanelInfo({
  payload: { projectId, genePanelId },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      getGenePanelInfo,
      projectId,
      genePanelId
    );

    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            "Failed to get Project Gene Panel Info"
        )
      );
      yield put(actions.fetchGenePanelInfo.failure());
      return;
    }

    const data = result.payload || {};

    yield put(actions.fetchGenePanelInfo.success(data));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchGenePanelInfo.failure());
  }
}

export function* updateGenePanelInfoSaga({
  payload: { projectId, genePanelId, data },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      updateGenePanelInfo,
      projectId,
      genePanelId,
      data
    );
    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.updateGenePanelInfo.failure());
      return;
    }
    yield put(actions.updateGenePanelInfo.success(data));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.updateGenePanelInfo.failure());
  }
}

export function* copyGenePanelSaga({ payload: { projectId, genePanelId } }) {
  try {
    const result: FetchDataResponse<any> = yield call(
      copyGenePanel,
      projectId,
      genePanelId
    );
    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.copyGenePanel.failure());
      return;
    }

    yield put(actions.fetchGenePanels.start(projectId));
    yield put(actions.copyGenePanel.success());
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.copyGenePanel.failure());
  }
}

export function* reloadProjectGenePanelPatients({
  payload: { projectId, genePanelId },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      getGenePanelPatientList,
      projectId,
      genePanelId
    );
    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            "Failed to get Project Gene Panel Patients"
        )
      );
      yield put(actions.fetchGenePanelPatientList.failure());
      return;
    }

    const data = result.payload || {};

    yield put(actions.fetchGenePanelPatientList.success(data));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchGenePanelPatientList.failure());
  }
}

export function* executeGenePanelPatientOperationSaga({
  payload: { operation, projectId, genePanelId, patientId },
}: {
  payload: {
    operation: PatientOperationType,
    projectId: number,
    genePanelId: number,
    patientId?: number,
  },
}) {
  try {
    const genericError = "Failed to process Patient for this Gene Panel";
    let result: FetchDataResponse<any> = { error: genericError };

    switch (operation) {
      case PatientOperation.ADD_ONE:
        result = yield call(
          addPatientToGenePanel,
          projectId,
          genePanelId,
          patientId
        );
        break;
      case PatientOperation.REMOVE_ONE:
        result = yield call(
          removePatientFromGenePanel,
          projectId,
          genePanelId,
          patientId
        );
        break;
      case PatientOperation.ADD_ALL:
        result = yield call(addAllPatientsToGenePanel, projectId, genePanelId);
        break;
      case PatientOperation.REMOVE_ALL:
        result = yield call(
          removeAllPatientsFromGenePanel,
          projectId,
          genePanelId
        );
        break;
      default:
        break;
    }

    yield call(processHeaders, result);

    if (!result || !result.ok) {
      yield put(error(result.error || result.statusText || genericError));
      yield put(actions.executeGenePanelPatientOperation.failure());
      return;
    }

    yield put(
      actions.executeGenePanelPatientOperation.success(
        operation,
        projectId,
        genePanelId,
        patientId
      )
    );
    yield put(actions.reloadEditGenePanelData(projectId, genePanelId));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.executeGenePanelPatientOperation.failure());
  }
}

export function* reloadProjectGenePanelGeneList({
  payload: { projectId, genePanelId },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      getGenePanelGenesList,
      projectId,
      genePanelId
    );
    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            "Failed to get Project Gene Panel Gene List"
        )
      );
      yield put(actions.fetchGenePanelGeneList.failure());
      return;
    }

    const { genes } = result.payload || { genes: [] };

    yield put(actions.fetchGenePanelGeneList.success(genes));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchGenePanelGeneList.failure());
  }
}

export function* removeGeneFromGenePanelSaga({
  payload: { projectId, genePanelId, geneName },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      removeGeneFromGenePanel,
      projectId,
      genePanelId,
      geneName
    );

    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.removeGeneFromGenePanel.failure());
      return;
    }

    yield put(
      actions.removeGeneFromGenePanel.success(projectId, genePanelId, geneName)
    );
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.removeGeneFromGenePanel.failure());
  }
}

export function* updateEditTempGeneSaga({
  payload: { projectId, tempGenePanelId, geneName, data },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      updateEditGeneDataOnTempGenePanel,
      projectId,
      tempGenePanelId,
      geneName,
      data
    );

    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            `Failed to update gene "${geneName}"`
        )
      );
      yield put(actions.updateEditTempGene.failure());
      return;
    }

    yield put(
      actions.updateEditTempGene.success(
        projectId,
        tempGenePanelId,
        geneName,
        data
      )
    );
    yield put(actions.setGeneInFoundGenes(geneName, camelizeKeys(data)));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.updateEditTempGene.failure());
  }
}

export function* updateEditGeneSaga({
  payload: { projectId, genePanelId, geneName, data },
}) {
  const transformedData = {
    ...data,
    autosomal_recessive: !!data.autosomal_recessive,
    autosomal_dominant: !!data.autosomal_dominant,
    x_linked: !!data.x_linked,
  };
  try {
    const result: FetchDataResponse<any> = yield call(
      updateEditGeneDataOnGenePanel,
      projectId,
      genePanelId,
      geneName,
      transformedData
    );

    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(
        error(
          result.error ||
            result.statusText ||
            `Failed to update gene "${geneName}"`
        )
      );
      yield put(actions.updateEditGene.failure());
      return;
    }

    yield put(
      actions.updateEditGene.success(projectId, genePanelId, geneName, data)
    );
    yield put(actions.setGeneInGenesList(geneName, camelizeKeys(data)));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.updateEditGene.failure());
  }
}

export function* reloadTempGenePanelData({
  payload: { projectId, tempGenePanelId },
}) {
  try {
    yield put(actions.setTempGenePanelLoading(true));
    const tempGenePanel: FetchDataResponse<{
      genePanel: GenePanel,
    }> = yield call(getTempGenePanel, projectId, tempGenePanelId);

    const foundGenes: FetchDataResponse<{
      genes: Array<Gene>,
    }> = yield call(getTempGenePanelFoundGenes, projectId, tempGenePanelId);

    const notFoundGenes: FetchDataResponse<{
      genes: Array<Gene>,
    }> = yield call(getTempGenePanelNotFoundGenes, projectId, tempGenePanelId);

    if (!tempGenePanel.ok || !tempGenePanel.payload.genePanel) {
      yield put(actions.fetchTempGenePanel.failure());
    }

    if (!foundGenes.ok) {
      yield put(actions.fetchTempGenePanelFoundGenes.failure());
    }

    if (!notFoundGenes.ok) {
      yield put(actions.fetchTempGenePanelNotFoundGenes.failure());
    }

    if (
      !tempGenePanel.ok ||
      !tempGenePanel.payload.genePanel ||
      !foundGenes.ok ||
      !notFoundGenes.ok
    )
      yield put(error("Failed to get Temp Gene panel data"));

    if (tempGenePanel.ok && tempGenePanel.payload.genePanel) {
      // NOTE: fileBlob is too big for redux state and totaly unnessary, probably
      // need to remove from response too
      const { fileBlob, ...cleanGenePanel } = tempGenePanel.payload.genePanel;
      yield put(actions.fetchTempGenePanel.success(cleanGenePanel));
    }

    if (foundGenes.ok) {
      yield put(
        actions.fetchTempGenePanelFoundGenes.success(foundGenes.payload.genes)
      );
    }

    if (notFoundGenes.ok) {
      yield put(
        actions.fetchTempGenePanelNotFoundGenes.success(
          notFoundGenes.payload.genes
        )
      );
    }
    yield put(actions.setTempGenePanelLoading(false));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.fetchTempGenePanel.failure());
    yield put(actions.fetchTempGenePanelFoundGenes.failure());
    yield put(actions.fetchTempGenePanelNotFoundGenes.failure());
  }
}

export function* removeProjectGenePanel({
  payload: { projectId, genePanelId },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      removeGenePanel,
      projectId,
      genePanelId
    );
    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.removeGenePanel.failure());
      return;
    }

    yield put(actions.fetchGenePanels.start(projectId));
    yield put(actions.removeGenePanel.success(projectId, genePanelId));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.removeGenePanel.failure());
  }
}

export function* archiveProjectGenePanel({
  payload: { projectId, genePanelId },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      archiveGenePanel,
      projectId,
      genePanelId
    );

    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.archiveGenePanel.failure());
      return;
    }

    yield put(actions.fetchGenePanels.start(projectId));
    yield put(actions.archiveGenePanel.success(projectId, genePanelId));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.archiveGenePanel.failure());
  }
}

export function* exportProjectGenePanelSaga({
  payload: { projectId, genePanelId, data },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      exportGenePanel,
      projectId,
      genePanelId,
      data
    );

    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.exportGenePanel.failure());
      return;
    }

    const { file } = result;
    if (file) {
      yield call(downloadFile, file);
    }

    yield put(actions.exportGenePanel.success());
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.exportGenePanel.failure());
  }
}

export function* removeGeneFromTempGenePanelSaga({
  payload: { projectId, tempGenePanelId, geneName },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      removeGeneFromTempGenePanel,
      projectId,
      tempGenePanelId,
      geneName
    );
    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.removeGeneFromTempGenePanel.failure());
      return;
    }
    yield put(actions.removeGeneFromTempGenePanel.success(geneName));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.removeGeneFromTempGenePanel.failure());
  }
}

export function* updateTempGenePanelSaga({
  payload: { projectId, tempGenePanelId, data },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      updateTempGenePanel,
      projectId,
      tempGenePanelId,
      data
    );
    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.updateTempGenePanel.failure());
      return;
    }
    yield put(actions.updateTempGenePanel.success(data));
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.updateTempGenePanel.failure());
  }
}

export function* saveTempGenePanelSaga({
  payload: { projectId, tempGenePanelId },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      saveTempGenePanel,
      projectId,
      tempGenePanelId
    );
    yield call(processHeaders, result);

    if (!result.ok || result.messages.error) {
      yield put(actions.saveTempGenePanel.failure());
      return;
    }
    yield put(actions.fetchGenePanels.start(projectId));
    yield put(actions.saveTempGenePanel.success());
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.saveTempGenePanel.failure());
  }
}

export function* addNewProjectGenePanel({
  payload: { projectId, title = "", description = "", ensemblVersion = "" },
}) {
  try {
    const result: FetchDataResponse<any> = yield call(
      addNewGenePanel,
      projectId,
      title,
      description,
      ensemblVersion
    );

    yield call(processHeaders, result);

    if (!result.ok) {
      yield put(actions.setAddNewGenePanelError(true));
      yield put(actions.addNewGenePanel.failure());
      return;
    }

    if (result.messages.success) {
      yield put(actions.fetchGenePanels.start(projectId));
      yield put(actions.setAddNewGenePanelIsAdded(true));
      yield put(actions.addNewGenePanel.success());
    }
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.addNewGenePanel.failure());
  }
}

export function* uploadNewProjectGenePanel({
  payload: { projectId, csvFormat, file, ensemblVersion },
}) {
  try {
    yield put(actions.setUploadGenePanelProcessing(true));
    const result: FetchDataResponse<any> = yield call(
      uploadGenePanel,
      projectId,
      csvFormat,
      file,
      ensemblVersion
    );
    yield put(actions.setUploadGenePanelProcessing(false));

    if (
      !result.ok ||
      result.messages.error ||
      (result.payload && result.payload.error)
    ) {
      yield put(
        error(
          result.error ||
            result.messages.error ||
            "Failed to Upload New Project Gene Panel"
        )
      );
      yield put(actions.uploadNewGenePanel.failure());
      return;
    }

    yield put(
      actions.uploadNewGenePanel.success(result.payload.tempGenePanelId)
    );
  } catch (e) {
    yield put(error(e.message));
    yield put(actions.uploadNewGenePanel.failure());
  }
}
