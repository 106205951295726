import React, { memo } from "react";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

interface LicencesProps {
  licences?: Array<SoftwareLicence>;
  loading?: boolean;
}

const columns = [
  {
    Header: "Software",
    accessor: "softwareName",
  },
  {
    Header: "Licence",
    accessor: "licence",
  },
];

export const Licences = ({ licences = [], loading = false }: LicencesProps) => (
  <div data-testid="licences-table">
    <Table
      title="Software and Licences"
      columns={columns}
      data={licences}
      autoResetPage
      noDataText="No licences data available."
      loading={loading}
      showPagination={licences.length > DEFAULT_PAGE_SIZE}
      enableFilter
      showTitleInfo
      manualGlobalFilter={false}
    />
  </div>
);

export default memo(Licences);
