export const appendString = (string, append, variable) =>
  variable ? string + append : string;

const appendStrings = (start, arrayOfStrings, append) => {
  const string = arrayOfStrings.reduce((accumulator, item) => {
    const [append, variable] = item;

    accumulator = appendString(accumulator, append, variable);

    return accumulator;
  }, start);

  return appendString(string, append, append);
};

export default appendStrings;
