//@flow

import type { Saga } from "redux-saga";
import { call } from "redux-saga/effects";

import * as api from "modules/api/patientMetadata";
import * as actions from "modules/patientMetadata/actions";

export const PROCESSORS = {
  FIELD: {
    [actions.actionType.ADD_METADATA](
      projectId,
      metadata
    ): (projectId: number, metadata: MetadataField) => Saga<void> {
      return function* () {
        return yield call(api.addPatientMetadataField, projectId, metadata);
      };
    },
    [actions.actionType.UPDATE_METADATA](
      projectId,
      metadata
    ): (projectId: number, metadata: MetadataField) => Saga<void> {
      return function* () {
        return yield call(api.updatePatientMetadataField, projectId, metadata);
      };
    },
    [actions.actionType.DELETE_METADATA](
      projectId,
      { id }
    ): (projectId: number, metadata: MetadataField) => Saga<void> {
      return function* () {
        return yield call(api.deletePatientMetadataField, projectId, id);
      };
    },
    [actions.actionType.ADD_INHERITED_METADATA](
      projectId,
      { id }
    ): (projectId: number, metadata: MetadataField) => Saga<void> {
      return function* () {
        return yield call(api.addInheritedPatientMetadataField, projectId, id);
      };
    },
    [actions.actionType.ADD_ALL_INHERITED_METADATA](
      projectId
    ): (projectId: number) => Saga<void> {
      return function* () {
        return yield call(api.addAllInheritedPatientMetadataFields, projectId);
      };
    },
  },
  CATEGORY: {
    [actions.actionType.ADD_METADATA](
      projectId,
      metadata
    ): (projectId: number, metadata: MetadataCategory) => Saga<void> {
      return function* () {
        return yield call(api.addPatientMetadataCategory, projectId, metadata);
      };
    },
    [actions.actionType.UPDATE_METADATA](
      projectId,
      metadata
    ): (projectId: number, metadata: MetadataCategory) => Saga<void> {
      return function* () {
        return yield call(
          api.updatePatientMetadataCategory,
          projectId,
          metadata
        );
      };
    },
    [actions.actionType.DELETE_METADATA](
      projectId,
      { id }
    ): (projectId: number, metadata: MetadataCategory) => Saga<void> {
      return function* () {
        return yield call(api.deletePatientMetadataCategory, projectId, id);
      };
    },
    [actions.actionType.ADD_INHERITED_METADATA](
      projectId,
      { id }
    ): (projectId: number, metadata: MetadataCategory) => Saga<void> {
      return function* () {
        return yield call(
          api.addInheritedPatientMetadataCategory,
          projectId,
          id
        );
      };
    },
    [actions.actionType.ADD_ALL_INHERITED_METADATA](
      projectId
    ): (projectId: number) => Saga<void> {
      return function* () {
        return yield call(
          api.addAllInheritedPatientMetadataCategories,
          projectId
        );
      };
    },
  },
};
