import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class Divider extends PureComponent {
  static displayName = "Divider";

  static propTypes = {
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    className: PropTypes.string,
  };

  static defaultProps = { direction: "horizontal" };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { direction, className } = this.props;
    return (
      <div
        className={classNames("divider", className, {
          "divider-horizontal": direction === "horizontal",
          "divider-vertical": direction === "vertical",
        })}
      />
    );
  }
}
