import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

export const getColumns = () => [
  {
    Header: "Source",
    accessor: "source",
  },
  {
    Header: "Frequency",
    accessor: "frequency",
  },
];

const FrequencyDetails = ({ knownFrequencies = [] }) => {
  const columns = useMemo(() => getColumns(), []);
  return (
    <div>
      <Table
        title="Known frequencies"
        columns={columns}
        data={knownFrequencies}
        showPagination={knownFrequencies.length > DEFAULT_PAGE_SIZE}
        autoResetPage={false}
        enableFilter
        manualGlobalFilter={false}
        sortBy={[
          {
            id: "source",
            desc: false,
          },
        ]}
      />
    </div>
  );
};

FrequencyDetails.propTypes = {
  /**
   * Array containing known frequencies data
   */
  knownFrequencies: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      frequency: PropTypes.number,
    })
  ),
};

export default memo(FrequencyDetails);
