import PropTypes from "prop-types";
import React, { memo } from "react";

import { List } from "pattern-library";

const FailedFilters = ({ failedFilterTypes }) => {
  const list = (failedFilterTypes || []).map(type => ({ type }));
  return <List keyPropName="type" valuePropName="type" items={list} />;
};

FailedFilters.propTypes = {
  /**
   * Array containing constants for failed filters
   */
  failedFilterTypes: PropTypes.arrayOf(PropTypes.string),
};

export default memo(FailedFilters);
