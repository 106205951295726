// @flow
import { set, merge } from "dot-prop-immutable";

import type { Action } from "./actions";
import * as constants from "./constants";
import data from "./reducer.data";
import { getActiveVariantsByGenes } from "./utils";

export type FilteredVariantsByGene = {
  [number]: Array<{
    variantId: number,
    patientVariantId: number,
  }>,
};

export type FilteredVariantInfoByGene = {
  [number]: {
    variantCount: number,
  },
};

export type Gene = {
  geneId: number,
  name: string,
  variants: Array<{
    variantId: number,
    curations: Array<{
      curatedVariantList: { showPathogenicity: string },
      pathogenicity: string,
    }>,
    expandedTranscripts: Array<{
      transcriptId: number,
      isDefaultVisibleTranscript: Boolean,
    }>,
    defaultVisibleTranscript: {
      transcriptId: number,
    },
    variantTranscripts: Array<{
      transcriptId: number,
      vepConsequence: string,
    }>,
  }>,
  ariadneComputedDecision?:
    | "Likely benign"
    | "Benign"
    | "Likely pathogenic"
    | "Pathogenic",
};

/**
 "transcriptId": 136924,
 "patientVariantDecisionId": 108481,
 "value": "Pathogenic",
 "decisionType": "pathogenicity",
 "geneId": 494,
 "variantId": 3018631,
 "gene": 494,
 "transcript": 136924,
 "variant": "494-3018631"
 */
export type Decision = {
  +patientVariantDecisionId: number,
  +geneId: number,
  +value: string,
  +decisionType: string,
  +transcriptId: number,
  gene?: any,
  transcript?: any,
  variant?: any,
};

export type Curation = {
  pathogenicity: string,
  curatedVariantList: { showPathogenicity: string },
};

export type State = {
  +ui: {
    +init: boolean,
    +loaded: boolean,
    +expandedVariants: { [number]: true },
    variantsLoaded: boolean,
    geneIdToFocus: ?number,
    activeGeneId: ?number,
    activeGeneName: string | boolean,
    activeVariant: number | boolean,
    activePatientVariant: number | boolean,
    activeTranscript: ?number,
    // the number of variants (including both filtered and unfiltered ones) in an active gene
    activeGeneAllVariantsCount: ?number,
    pagination: {
      page: number,
      pager?: {
        firstItem: number,
        lastItem: number,
        lastPage: number,
        totalItems: number,
      },
    },
    +filteredVariantInfoByGene: FilteredVariantInfoByGene,
    +filteredOutVariantsDisplayed: boolean,
  },
  +data: {
    +activeGenes: Array<number>,
    +variantCount: {
      list: Array<{
        gene_id: number,
        variant_count: number,
      }>,
      success: boolean,
    },
    +filteredVariantsByGene: FilteredVariantsByGene,
    +defaults: {},
  },
};

export const initialState: State = {
  ui: {
    init: false,
    loaded: false,
    expandedVariants: {},
    variantsLoaded: false,
    geneIdToFocus: null,
    activeGeneId: null,
    activeGeneName: false,
    activeVariant: false,
    activePatientVariant: false,
    activeTranscript: null,
    activeGeneAllVariantsCount: null,
    pagination: {
      page: 1,
    },
    filteredVariantInfoByGene: {},
    filteredOutVariantsDisplayed: false,
  },
  data: {
    activeGenes: [],
    variantCount: {
      list: [],
      success: true,
    },
    filteredVariantsByGene: {},
    // Move some of the static data out into another file to make it clear
    // which data is changed as opposed to what is just used to render options
    defaults: data,
  },
};

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case constants.VARIANTS_INIT:
      return {
        ...state,
        ui: {
          ...state.ui,
          loaded: true,
          variantsLoaded: true,
        },
      };

    case constants.START_INIT:
      return {
        ...state,
        ui: {
          ...state.ui,
          init: true,
        },
      };

    case constants.SET_ACTIVE_GENES:
      return {
        ...state,
        data: {
          ...state.data,
          activeGenes: action.payload,
        },
      };

    case constants.SET_ACTIVE_TRANSCRIPT: {
      const { transcriptId } = action.payload;

      const { activeTranscript } = state.ui;

      if (activeTranscript === transcriptId) return state;

      return {
        ...state,
        ui: {
          ...state.ui,
          activeTranscript: transcriptId,
        },
      };
    }

    case constants.SET_ACTIVE_PANEL_VALUES:
      const { geneName, geneId, variantId, patientVariantId, transcriptId } =
        action.payload;

      const {
        activeGeneName,
        activeGeneId,
        activeVariant,
        activePatientVariant,
        activeTranscript,
      } = state.ui;

      const areEqual =
        activeGeneName === geneName &&
        activeGeneId === geneId &&
        activeVariant === variantId &&
        activePatientVariant === patientVariantId &&
        activeTranscript === transcriptId;

      if (areEqual) return state;

      return {
        ...state,
        ui: {
          ...state.ui,
          activeGeneName: geneName,
          activeGeneId: geneId,
          activeVariant: variantId,
          activePatientVariant: patientVariantId,
          activeTranscript: transcriptId,
        },
      };

    case constants.SET_GENE_TO_FOCUS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          geneIdToFocus: action.payload,
        },
      };
    }

    case constants.RESET_VARIANT_PANEL:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeGeneName: false,
          activeGeneId: null,
          activeVariant: false,
          activePatientVariant: false,
          activeTranscript: null,
          activeGeneAllVariantsCount: null,
        },
      };

    case constants.SET_ALL_VARIANTS_COUNT: {
      const {
        payload: { list: [countInfo] = [] },
      } = action;
      const { variant_count: variantsCount } = countInfo || {};
      return {
        ...state,
        ui: {
          ...state.ui,
          activeGeneAllVariantsCount: variantsCount,
        },
      };
    }

    case constants.TOGGLE_VARIANT_EXPANDED: {
      const { variantId } = action.payload;
      const expandedVariants = { ...state.ui.expandedVariants };
      const isExpanded: boolean = expandedVariants[variantId] || false;
      if (isExpanded) {
        delete expandedVariants[variantId];
      } else {
        expandedVariants[variantId] = true;
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          expandedVariants,
        },
      };
    }

    case constants.LOADING_VARIANTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          variantsLoaded: false,
        },
      };

    case constants.LOADING_VARIANTS_COMPLETED:
      return {
        ...state,
        ui: {
          ...state.ui,
          variantsLoaded: true,
        },
      };

    case constants.SET_PAGE:
      return set(state, "ui.pagination.page", action.payload);

    case constants.SET_PAGER:
      return set(state, "ui.pagination.pager", action.payload);

    case constants.GET_PANEL_GENES_VARIANT_COUNT:
      return set(state, "data.variantCount", action.payload);

    case constants.SET_FILTERED_VARIANTS_BY_GENE:
      const { genes, replace } = action.payload;
      const filtered = getActiveVariantsByGenes(genes);
      const operation = replace ? set : merge;
      return operation(state, "data.filteredVariantsByGene", filtered);

    case constants.SET_FILTERED_VARIANT_INFO_BY_GENE:
      return set(state, "ui.filteredVariantInfoByGene", action.payload);

    case constants.SET_FILTERED_OUT_VARIANTS_DISPLAYED:
      return set(state, "ui.filteredOutVariantsDisplayed", action.payload);

    default:
      return state;
  }
}
