import React, { useCallback, useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import {
  ComposableTabs,
  Tab,
  Modal,
  ModalBody,
  LoadingOverlay,
  MODAL_SIZES,
} from "pattern-library";

import { hideModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import {
  clearEditGenePanelState,
  reloadEditGenePanelData,
} from "../../actions";
import {
  isGenePanelInfoLoading,
  getGenePanelInfo,
  getGenePanelCounts,
} from "../../selectors";
import { EditGenePanelTabProps } from "../../types";

import GenesTab from "./tabs/GenesTab";
import OverviewTab from "./tabs/OverviewTab";
import PatientsTab from "./tabs/PatientsTab";

// NOTE: calling from modal registry need to pass this data:
//    showModal(modalTypes.EDIT_GENE_PANEL_MODAL, { projectId: 123, genePanelId: 123 });

interface Props extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
  inherited?: boolean;
}

const EditGenePanelModal = ({
  projectId,
  genePanelId,
  hideModal,
  clearEditGenePanelState,
  reloadEditGenePanelData,
  isLoading,
  genePanelInfo,
  tabCounts,
  inherited = false,
}: Props) => {
  useEffect(() => {
    reloadEditGenePanelData(projectId, genePanelId);
  }, [projectId, genePanelId, reloadEditGenePanelData]);

  const onCloseHandler = useCallback(() => {
    hideModal(modalTypes.EDIT_GENE_PANEL_MODAL);
    clearEditGenePanelState();
  }, [hideModal, clearEditGenePanelState]);

  const tabs = useMemo(() => {
    const canEdit = !tabCounts.patientCount;
    const props: EditGenePanelTabProps = {
      projectId,
      genePanelId,
      canEdit,
    };

    return [
      {
        hidden: inherited,
        component: OverviewTab,
        name: "Overview",
        props,
      },
      {
        hidden: inherited,
        component: GenesTab,
        name: "Genes",
        count: tabCounts.geneCount,
        props,
      },
      {
        component: PatientsTab,
        count: tabCounts.patientCount,
        name: "Patients",
        props,
      },
    ];
  }, [genePanelId, projectId, tabCounts, inherited]);

  return (
    <Modal show close={onCloseHandler} size={MODAL_SIZES.XL}>
      <LoadingOverlay
        data-testid="edit-gene-panel-modal-loading-overlay"
        loading={isLoading}
      >
        <ModalBody>
          <div className="pull-right">
            <button
              type="button"
              className="close"
              onClick={onCloseHandler}
              data-dismiss="modal"
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          {genePanelInfo ? (
            <h4 className="header-container">
              {`${genePanelInfo.title}`}
              <div className="header-description">
                {" "}
                <small title={genePanelInfo.description}>
                  {genePanelInfo.description}
                </small>
              </div>
            </h4>
          ) : null}

          <ComposableTabs navStyle>
            {tabs
              .filter(({ hidden }) => !hidden)
              .map(tab => {
                const { name, component: Component, props, count } = tab;
                return (
                  <Tab key={name} name={name} count={count}>
                    <Component {...props} />
                  </Tab>
                );
              })}
          </ComposableTabs>
        </ModalBody>
      </LoadingOverlay>
    </Modal>
  );
};

const mapStateToProps = state => ({
  genePanelInfo: getGenePanelInfo(state),
  tabCounts: getGenePanelCounts(state),
  isLoading: isGenePanelInfoLoading(state),
});

const mapDispatchToProps = {
  hideModal,
  clearEditGenePanelState,
  reloadEditGenePanelData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditGenePanelModal);
