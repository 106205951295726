import PropTypes from "prop-types";
import React, { memo } from "react";

import { Button, Icon } from "pattern-library";

export const ToggleCollapseButton = memo(
  ({ collapsed = false, toggleCollapse }) => {
    const icon = collapsed ? "chevronRight" : "chevronLeft";
    return (
      <Button
        onClick={toggleCollapse}
        className="btn-sm"
        data-testid="collapse-filter-btn"
      >
        <Icon type={icon} />
      </Button>
    );
  }
);

ToggleCollapseButton.propTypes = {
  collapsed: PropTypes.bool,
  toggleCollapse: PropTypes.func.isRequired,
};

export default ToggleCollapseButton;
