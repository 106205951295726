import Genoverse from "genoverse";
import $ from "jquery";

import { Model } from "../common/BinSize";
import { Colors, labelStyle } from "../utils";

export const Controller = Genoverse.Track.Controller.extend({
  // Disable Menu on click
  addUserEventHandlers: () => {},
  setName(name, configName) {
    this.base(name, configName);
    this.label
      .find(".gv-name")
      .css({ top: "10px", left: "5px", position: "relative" });
    this.label
      .css({
        cursor: "pointer",
      })
      .attr(
        "title",
        "Mapability is measure of how often the sequence found at the particular location will align within the whole genome."
      );

    $("<div>High</div>")
      .css({ ...labelStyle, top: "0px" })
      .appendTo(this.label);

    $("<div>Low</div>")
      .css({
        ...labelStyle,
        bottom: "6px",
      })
      .appendTo(this.label);
  },
});

export const View = Genoverse.Track.View.extend({
  draw(features, featureContext, labelContext, scale) {
    const { height } = this.track;

    this.base(features, featureContext, labelContext, scale);
    featureContext.fillStyle = Colors.RED;
    featureContext.fillRect(0, height, this.width, 1);
  },
  drawFeature({ x, score, width }, featureContext) {
    const { height } = this.track;

    featureContext.fillStyle = Colors.BLACK;
    featureContext.fillRect(x, (1 - score) * height, width, score * height);
  },
});

export const Track = Genoverse.Track.extend({
  name: "Mappability",
  namespace: "Congenica.Mappability",
  info: "Visualisation of read mappability to a genomic region",
  isMenuDisabled: true,
  height: 120,
  labels: false,
  bump: false,
  controller: Controller,
  model: Model,
  view: View,
});
