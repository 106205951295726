import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Tooltip from "../../modules/Tooltip";

import Checkbox from "./Checkbox";

export default class Toggle extends PureComponent {
  static displayName = "Toggle";

  static propTypes = {
    /**
     * Class to apply to the toggle input
     */
    className: PropTypes.string,
    /**
     * Name for the checkbox input
     */
    name: PropTypes.string.isRequired,
    /**
     * Text or react element for the label
     */
    label: PropTypes.node,
  };

  static defaultProps = {
    className: "",
    label: "",
  };

  render() {
    const { label, name, className, tooltip, ...otherProps } = this.props;

    if (tooltip) {
      otherProps.inputWrapper = Tooltip;
      otherProps.wrapperArgs = {
        container: "div",
        ...tooltip,
      };
    }

    return (
      <Checkbox
        className={classNames("toggle", className)}
        label={label}
        name={name}
        containerClass="toggle-container"
        {...otherProps}
      />
    );
  }
}
