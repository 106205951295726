// @flow

import { createSelector } from "@reduxjs/toolkit";
import { isEmpty, isNil, path, uniqBy } from "ramda";

import { isAutoAcmgActiveOnCurrentProject } from "modules/project/selectors";
import { arrayToKeyValueObject } from "modules/utils";

import * as variantSelectors from "../variants/selectors";

import { NAME, ACMG_SUGGESTION_STATUS_PENDING } from "./constants";
import type {
  PatientVariantExt,
  SelectedCriteria,
  SuggestedCriteria,
  AcmgSuggestion,
} from "./flow-types";
import { sortByCriteriaData, strengthMap } from "./utils";

// This is to pull the current selector out
export const getCurrentSelectedCriteriaSelector: (state: {}) =>
  | boolean
  | string = path([NAME, "ui", "currentSelectedCriteria"]);
export const getOverlayActiveSelector: (state: {}) => boolean = path([
  NAME,
  "ui",
  "overlayActive",
]);
export const getLoadedPatientVariant: (state: {}) => PatientVariant = path([
  NAME,
  "ui",
  "patientVariant",
]);

export const isAbleToLock: (state: {}) => boolean = path([
  NAME,
  "ui",
  "canLock",
]);
export const isMakingLockRequest: (state: {}) => boolean = path([
  NAME,
  "ui",
  "makingLockRequest",
]);
export const isRequestingClassification: (state: {}) => boolean = path([
  NAME,
  "ui",
  "requestingClassification",
]);
export const isConfirmationModalActive: (state: {}) => boolean = path([
  NAME,
  "ui",
  "showConfirmationModal",
]);
export const isDecisionAlreadySetAgainstAnotherTranscript: (state: {}) => boolean =
  path([NAME, "ui", "decisionAlreadySetAgainstAnotherTranscript"]);

export const getACMGClassifications: (state: {}) => Array<AcmgClassification> =
  path([NAME, "data", "acmgClassifications"]);

export const getACMGCriteria: (state: {}) => Array<AcmgCriteria> = path([
  NAME,
  "data",
  "acmgCriteria",
]);

export const getSuggestedACMGCriteria: (state: {}) => AcmgSuggestion = path([
  NAME,
  "ui",
  "suggestedAcmgCriteria",
]);

export const isSuggestedAcmgProcessing: (state: {}) => boolean = path([
  NAME,
  "ui",
  "suggestedAcmgCriteriaProcessing",
]);

export const getACMGCriteriaGroupById: (state: {}) => ?{
  [string]: AcmgCriteria,
} = createSelector(getACMGCriteria, criteriaList => {
  if (!criteriaList || !criteriaList.length) {
    return null;
  }
  return arrayToKeyValueObject(criteriaList, "id");
});

export const getSingleACMGCriteria: (state: {}, id: string) => ?AcmgCriteria =
  createSelector(
    (state, id) => id,
    getACMGCriteriaGroupById,
    (id: string, acmgCriteriaGroupById: ?{ [string]: AcmgCriteria }) => {
      if (!acmgCriteriaGroupById) {
        return null;
      }
      const acmgCriteria = acmgCriteriaGroupById[id];
      return acmgCriteria ? { ...acmgCriteria } : null;
    }
  );

export const getStrength: (state: {}, strengthId: string) => ?Strength =
  createSelector(
    getACMGClassifications,
    (state, strengthId) => strengthId,
    (acmgClassifications: Array<AcmgClassification>, strengthId: string) =>
      //flatMap is not supported yet
      //$FlowFixMe
      acmgClassifications
        .flatMap(c => c.type.strengths)
        .find(s => s.id === Number(strengthId))
  );

export const getAllClassificationTypes: (state: {}) => Array<ClassificationType> =
  createSelector(
    getACMGClassifications,
    (acmgClassifications: Array<AcmgClassification>) => {
      const types = acmgClassifications.map(c => {
        const { type }: { type: ClassificationType } = c;
        return {
          ...type,
          strengths: type.strengths.sort((a, b) => {
            if (strengthMap[a.name] > strengthMap[b.name]) {
              return 1;
            } else if (strengthMap[a.name] < strengthMap[b.name]) {
              return -1;
            }
            return 0;
          }),
        };
      });
      return uniqBy(a => a.id, types);
    }
  );

export const getCurrentPatientVariant: (state: {}) => ?PatientVariant =
  createSelector(
    getLoadedPatientVariant,
    variantSelectors.activeVariantPanelVariant,
    (patientVariant: PatientVariant, variantId: number) => {
      if (
        patientVariant &&
        variantId &&
        Number(patientVariant.id) === variantId
      ) {
        return patientVariant;
      }

      return null;
    }
  );

export const getPatientVariantDetails: (state: {}) => ?PatientVariantExt =
  createSelector(
    getCurrentPatientVariant,
    getACMGCriteriaGroupById,
    (
      patientVariant: ?PatientVariant,
      acmgCriteriaGroupById: ?{ [string]: AcmgCriteria }
    ) => {
      if (!patientVariant || !acmgCriteriaGroupById) {
        return null;
      }
      const criteriaList: Array<SelectedCriteria> =
        patientVariant.selectedCriteria.map(selected => {
          const { criteriaId } = selected;
          const { name, description, classificationType, category } =
            acmgCriteriaGroupById ? acmgCriteriaGroupById[criteriaId] : {};
          // Keep the custom strength param, don't overwrite it
          return {
            ...selected,
            name,
            description,
            classificationType,
            category,
          };
        });
      return { ...patientVariant, selectedCriteria: criteriaList };
    }
  );

export const getSelectedCriteriaSelector: (state: {}) => Array<SelectedCriteria> =
  createSelector(getPatientVariantDetails, patientVariant => {
    if (patientVariant) {
      return patientVariant.selectedCriteria || [];
    }
    return [];
  });

export const getSuggestedCriteriaSelector: (state: {}) => ?Array<SuggestedCriteria> =
  createSelector(getSuggestedACMGCriteria, acmgSuggestion => {
    if (acmgSuggestion && acmgSuggestion.suggestedCriteria) {
      return acmgSuggestion.suggestedCriteria.sort(sortByCriteriaData) || [];
    }
    return [];
  });

export const getSuggestedClassificationSelector: (state: {}) => ?string =
  createSelector(getSuggestedACMGCriteria, acmgSuggestion => {
    if (acmgSuggestion && acmgSuggestion.suggestedClassification) {
      return acmgSuggestion.suggestedClassification.name;
    }
    return null;
  });

export const getSuggestedCriteriaStatusSelector: (state: {}) => ?string =
  createSelector(getSuggestedACMGCriteria, acmgSuggestion => {
    if (acmgSuggestion) {
      return acmgSuggestion.suggestedCriteriaStatus;
    }
    return null;
  });

export const getSuggestedTranscriptSelector: (state: {}) => ?string =
  createSelector(getSuggestedACMGCriteria, acmgSuggestion => {
    if (acmgSuggestion) {
      return acmgSuggestion.suggestedTranscript;
    }
    return null;
  });

export const isLocked: (state: {}) => boolean = createSelector(
  getLoadedPatientVariant,
  patientVariant => !!(patientVariant && patientVariant.acmgLocked)
);

export const canEdit: (state: {}) => boolean = createSelector(
  isLocked,
  isDecisionAlreadySetAgainstAnotherTranscript,
  (locked, decisionMade) => !locked && !decisionMade
);

export const getCriteriaToAdd: (state: {}) => ?AcmgCriteria = createSelector(
  getACMGCriteriaGroupById,
  getCurrentSelectedCriteriaSelector,
  (allCriteria: ?{ [string]: AcmgCriteria }, id: boolean | string) => {
    if (!id || !allCriteria || typeof id !== "string" || !allCriteria[id]) {
      return null;
    }
    return { ...allCriteria[id] };
  }
);

/**
 * get categories with related criteria array
 */
export const getAllCategories: (state: {}) => Array<Object> = createSelector(
  getACMGCriteria,
  (allCriteria: Array<AcmgCriteria>) => {
    if (!allCriteria) {
      return [];
    }

    const criteriaByCategoryId: Object = {};
    const categoriesById: Object = {};

    allCriteria.forEach(criteria => {
      const {
        category,
        category: { id, name: categoryName } = {},
        classificationType: { id: cTypeId },
      } = criteria;
      categoriesById[id] = category;
      const categoryCriteria = criteriaByCategoryId[id];
      const newCriteria = {
        ...criteria,
        category: categoryName,
        classificationType: cTypeId,
      };
      if (!categoryCriteria) {
        criteriaByCategoryId[id] = [newCriteria];
      } else {
        categoryCriteria.push(newCriteria);
      }
    });
    const categoryIds = Object.keys(categoriesById).sort(
      (a, b) => Number.parseInt(a) - Number.parseInt(b)
    );

    return categoryIds.map(id => {
      const category = categoriesById[id];
      const criteria = criteriaByCategoryId[id];
      return { ...category, criteria };
    });
  }
);

export const getPopulatedSelectedCriteriaByCategories: (state: {}) => Array<Object> =
  createSelector(
    getSelectedCriteriaSelector,
    getAllCategories,
    (populatedSelectedCriteria, categories) => {
      const categoriesToReturn = [];

      for (const category of categories) {
        const categoryClone = { ...category };
        const selectedCriteriaInThisCategory = populatedSelectedCriteria.filter(
          item => item.category && item.category.name === category.name
        );

        if (selectedCriteriaInThisCategory.length) {
          categoryClone.criteria = selectedCriteriaInThisCategory;

          categoriesToReturn.push(categoryClone);
        }
      }

      return categoriesToReturn;
    }
  );

export const getPopulatedClassificationTypes: (state: {}) => Array<ClassificationType> =
  createSelector(getAllClassificationTypes, classifications => {
    if (classifications.length === 0) return [];
    // This orders the classifications and strengths properly
    return [
      { ...classifications[1] },
      {
        ...classifications[0],
        strengths: classifications[0].strengths.reverse(),
      },
    ];
  });

export const getWarnings: (state: {}) => Array<Object> = createSelector(
  getAllCategories,
  getPopulatedSelectedCriteriaByCategories,
  getCurrentPatientVariant,
  (categories, categoriesWithCriteria, patientVariant) => {
    if (!patientVariant) {
      return [];
    }
    const categoriesWithCriteriaIds = categoriesWithCriteria.map(
      item => item.id
    );

    const categoryIdsThatHaveCriteriaOrNoEvidence =
      categoriesWithCriteriaIds.concat(patientVariant.noEvidenceCategories);

    const categoryValues = categories;

    if (categoryValues) {
      return categoryValues.filter(
        item => !categoryIdsThatHaveCriteriaOrNoEvidence.includes(item.id)
      );
    }
    return [];
  }
);

export const hasSuggestedCriteria: (state: {}) => boolean = createSelector(
  getSuggestedACMGCriteria,
  isAutoAcmgActiveOnCurrentProject,
  (acmgSuggestion, isAutoAcmgActive) => {
    if (isAutoAcmgActive && acmgSuggestion) {
      const { suggestedCriteria } = acmgSuggestion;
      return !isNil(suggestedCriteria) && !isEmpty(suggestedCriteria);
    }
    return false;
  }
);

export const hasSuggestedCriteriaPending: (state: {}) => boolean =
  createSelector(
    getSuggestedACMGCriteria,
    isAutoAcmgActiveOnCurrentProject,
    (acmgSuggestion, isAutoAcmgActive) => {
      if (isAutoAcmgActive && acmgSuggestion) {
        const { suggestedCriteriaStatus } = acmgSuggestion;
        return suggestedCriteriaStatus === ACMG_SUGGESTION_STATUS_PENDING;
      }
      return false;
    }
  );

export const isAcmgCriteriaEditDisabledDueToSuggestions: (state: {}) => boolean =
  createSelector(
    hasSuggestedCriteriaPending,
    getSelectedCriteriaSelector,
    (
      hasSuggestedCriteriaPending: boolean,
      selectedCriteria: Array<SelectedCriteria>
    ) => hasSuggestedCriteriaPending && isEmpty(selectedCriteria)
  );
