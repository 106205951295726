import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo } from "react";

import { Button, MorbidBadge } from "pattern-library";

const GeneButton = memo(({ gene, simplified = false }) => {
  if (!gene) return "-";
  const {
    name,
    morbidId,
    pathogenicity = "",
  } = typeof gene === "string" ? { name: gene } : gene;

  const params = simplified
    ? {
        className: "gene-name gene-name--no-pathogenicity",
      }
    : {
        context: "primary",
        className: classNames(
          {
            [`btn--${pathogenicity}`]: pathogenicity,
          },
          "gene-panel-button"
        ),
      };

  return (
    <Button {...params}>
      {name}
      {morbidId && <MorbidBadge />}
    </Button>
  );
});

GeneButton.propTypes = {
  gene: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      morbidId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      pathogenicity: PropTypes.string,
    }),
  ]),
  /**
   * Appearance with white background and blue border without pathogenicity will use if simplified property
   * is set as true. This is SV table behaviour.  The appearance for SNV table will be used in another case.
   */
  simplified: PropTypes.bool,
};

export default GeneButton;
