import React, { memo } from "react";

import GeneScore from "./GeneScore";
import {
  formatConstraintsScore,
  getPLiScoreColor,
  getLOEUFScoreColor,
  getMissenseZScoreColor,
} from "./utils";

const GeneScoresColumn = ({
  hiScore,
  hiRgb,
  pliScore,
  loeufScore,
  missenseZScore,
  onClick,
}) => (
  <div className="gene gene-scores-column" onClick={onClick}>
    <GeneScore
      value={formatConstraintsScore(pliScore)}
      label="pLI"
      color={getPLiScoreColor(pliScore)}
    />
    <GeneScore value={hiScore} label="HI" color={`rgb(${hiRgb})`} />
    <GeneScore
      value={formatConstraintsScore(loeufScore)}
      label="LOEUF"
      color={getLOEUFScoreColor(loeufScore)}
    />
    <GeneScore
      value={formatConstraintsScore(missenseZScore)}
      label="Missense Z"
      color={getMissenseZScoreColor(missenseZScore)}
    />
  </div>
);

export default memo(GeneScoresColumn);
