// @flow
import { react as autoBind } from "auto-bind";
import { isNil } from "ramda";

import type BgzipBlock from "./BgzipBlock";
import BgzipDecompressor from "./BgzipDecompressor";

class ChunkedBgzipFileReader {
  file: File;
  _currentFilePos: number = 0;
  _decompressor: BgzipDecompressor;

  constructor(file: File) {
    if (file.size === 0) {
      throw new Error("Empty file provided");
    }

    this.file = file;

    this._decompressor = new BgzipDecompressor(this.file);
    autoBind(this);
  }

  eof() {
    return this._currentFilePos >= this._decompressor.finalBlockEnd;
  }

  async getNextChunk() {
    // raise an error if someone accidentally reads past the end of the file
    // it is the callers responsibility to check if it has been reached
    if (this.eof()) {
      throw new Error("getNextChunk called but EOF has been reached");
    }

    const block: BgzipBlock | void = await this._decompressor.getBgzipBlock(
      this._currentFilePos
    );

    if (isNil(block)) throw new Error("bgzip block is undefined");

    // update position to be at the start of the next block
    this._currentFilePos += block.size;
    return block.text;
  }
}

export default ChunkedBgzipFileReader;
