import { prop } from "ramda";

import * as constants from "./constants";

export type LoadingStatus = "pending" | "loading" | "failure" | "success";

export type User = {
  id: number;
  admin: boolean;
  email: string;
  name: string;
};

export type State = {
  ui: {
    loggingIn: boolean;
    loginFailed: boolean;
    loadedInitialUser: boolean;
    apiTokenLoadingStatus: LoadingStatus;
  };
  data: {
    user: false | User;
    apiToken: false | string;
  };
};

export const initialState: State = {
  ui: {
    loggingIn: false,
    loginFailed: false,
    loadedInitialUser: false,
    apiTokenLoadingStatus: "pending",
  },
  data: {
    user: false,
    apiToken: false,
  },
};

export default function reducer(
  state: State = initialState,
  action: { payload: any; type?: string } = { payload: {} }
) {
  const { type, payload = {} } = action;

  const result = prop<string, any>("result", payload) || {};

  switch (type) {
    case constants.USER_LOGGING_IN:
      return {
        ...state,
        ui: {
          ...state.ui,
          loggingIn: true,
        },
      };

    case constants.USER_LOGGED_IN:
      return {
        ...state,
        ui: {
          ...state.ui,
          loggingIn: false,
          loadedInitialUser: true,
        },
        data: {
          ...state.data,
          user: result,
        },
      };

    case constants.USER_LOGIN_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          loggingIn: false,
          loginFailed: true,
        },
      };

    case constants.LOAD_INITIAL_USER_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadedInitialUser: true,
        },
      };

    case constants.FETCH_PROFILE_API_TOKEN:
      return {
        ...state,
        ui: { ...state.ui, apiTokenLoadingStatus: "loading" },
      };

    case constants.FETCH_PROFILE_API_TOKEN_SUCCESS:
      return {
        ...state,
        ui: { ...state.ui, apiTokenLoadingStatus: "success" },
        data: { ...state.data, apiToken: result },
      };

    case constants.FETCH_PROFILE_API_TOKEN_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, apiTokenLoadingStatus: "failure" },
      };

    case constants.RESET_PROFILE_API_TOKEN:
      return {
        ...state,
        ui: { ...state.ui, apiTokenLoadingStatus: "pending" },
      };

    default:
      return state;
  }
}
