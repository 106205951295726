import React, { useEffect } from "react";
import { connect } from "react-redux";

import { fetchAllUploadedGenePanelData } from "../../../actions";
import {
  getTemporaryGenePanelId,
  isProcessingGenePanels,
} from "../../../selectors";

import UploadForm from "./upload/UploadForm";
import UploadGenesTabs from "./upload/UploadGenesTabs";

type Props = {
  projectId: number;
  tempGenePanelId?: number;
  isProcessing: boolean;
  fetchAllUploadedGenePanelData: any;
  onClose: any;
};

const UploadGenePanelTab: React.FC<Props> = ({
  projectId,
  tempGenePanelId,
  isProcessing,
  fetchAllUploadedGenePanelData,
  onClose,
}) => {
  useEffect(() => {
    if (projectId && tempGenePanelId) {
      fetchAllUploadedGenePanelData(projectId, tempGenePanelId);
    }
  }, [projectId, tempGenePanelId, fetchAllUploadedGenePanelData]);

  if (isProcessing) {
    return <div className="form_processing">Processing</div>;
  }

  return (
    <>
      {tempGenePanelId ? (
        <UploadGenesTabs
          projectId={projectId}
          tempGenePanelId={tempGenePanelId}
          onClose={onClose}
        />
      ) : (
        <UploadForm projectId={projectId} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  tempGenePanelId: getTemporaryGenePanelId(state),
  isProcessing: isProcessingGenePanels(state),
});

const mapDispatchToProps = {
  fetchAllUploadedGenePanelData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadGenePanelTab);
