import { createAction } from "../utils";
import { asyncActionCreatorGenerator } from "../utils/async";

export const actionType = Object.freeze({
  GET_OR_RELOAD_LICENCES: "GET_OR_RELOAD_LICENCES",
  FETCH_LICENCES: "FETCH_LICENCES",
});

export const fetchLicences: AsyncActionCreatorEx = asyncActionCreatorGenerator<
  void,
  Array<SoftwareLicence>
>(actionType.FETCH_LICENCES);

export const getOrReloadLicences = (): FSA<
  typeof actionType.GET_OR_RELOAD_LICENCES
> => createAction(actionType.GET_OR_RELOAD_LICENCES);

export type Action =
  | ReturnType<typeof getOrReloadLicences>
  | ReturnType<typeof fetchLicences.start>
  | ReturnType<typeof fetchLicences.success>
  | ReturnType<typeof fetchLicences.failure>;
