import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";
import { DropTarget } from "react-dnd";

import DraggableItem, { itemType } from "./DraggableItem";
import {
  dataShape,
  wellIdShape,
  findHoverPosition,
  needToMoveItem,
} from "./utils";

class Well extends PureComponent {
  static displayName = "Well";

  static propTypes = {
    /**
     * well items list
     */
    data: dataShape,
    /**
     * well identifier used to determine the type of item action
     */
    id: wellIdShape,
    /**
     * function handling items sort within the same well
     */
    moveItem: PropTypes.func.isRequired,
    /**
     * Function that handles moving an item between wells.
     */
    moveToWell: PropTypes.func.isRequired,
    /**
     * Function that is called on drag end: removes item preview from the previous visited well,
     * removes the original drag item if needed
     */
    endDrag: PropTypes.func.isRequired,
    /**
     * a custom class name that applies css rules to draggable items
     */
    itemClassName: PropTypes.string,
    /**
     * the array of string ids of the wells the current well accepts items from
     */
    allowedSourceWellIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    data: [],
    itemClassName: "",
    allowedSourceWellIds: [],
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isHovered: false,
    };
  }

  /**
   * ref callback to set the ref to the item that is used in react-dnd hover function to find dimensions
   * Warning: cannot use React.createRef because react-dnd implementation expects the function
   * See https://github.com/react-dnd/react-dnd/issues/998
   */
  setItemRef(node) {
    this.node = node;
  }

  handleHover(hovered) {
    const { isHovered } = this.state;
    if (isHovered !== hovered) {
      this.setState({ isHovered: hovered });
    }
  }

  handleDragLeave(e) {
    // Dragging over well child nodes causes intermediate dragleave events
    // even though the cursor is over the well.
    // To filter them check if mouse is in well bounds
    const { pageX, pageY } = e || window.event;
    const { top, bottom, left, right } = this.node.getBoundingClientRect();
    //respect vertical scroll
    const topY = top + window.scrollY;
    const bottomY = bottom + window.scrollY;
    // the strict comparison without equality sign should be used because dragleave event is fired on node edges
    const isMouseOverWell =
      topY < pageY && pageY < bottomY && left < pageX && pageX < right;

    this.handleHover(isMouseOverWell);
  }

  handleDragEnter() {
    this.handleHover(true);
  }

  render() {
    const {
      id,
      data,
      endDrag,
      itemClassName,
      connectDropTarget,
      canDrop,
      isOver,
    } = this.props;

    if (isNil(id)) {
      throw new Error("well must have an id");
    }

    const { isHovered } = this.state;

    return connectDropTarget(
      <div
        onDragLeave={this.handleDragLeave}
        onDragEnter={this.handleDragEnter}
        ref={this.setItemRef}
        className={classNames("sortable-well-container", {
          "sortable-well-container-active": canDrop && isOver,
          "sortable-well-container-not-allowed": !canDrop && isOver,
          "sortable-well-container-hide-preview": !isHovered,
        })}
      >
        {data.map((item, i) => (
          <DraggableItem
            type={id}
            key={item.id}
            index={i}
            endDrag={endDrag}
            wellId={id}
            data={item}
            className={itemClassName}
          />
        ))}
      </div>
    );
  }
}

const itemTarget = {
  hover(props, monitor, component) {
    // Note from documentation: we're mutating the monitor item here by setting index prop
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.

    // For the DnD to work properly drag source item is not deleted from the original well DOM, it is hidden when necessary

    if (!component) {
      return null;
    }
    const sourceItem = monitor.getItem();
    const {
      initialWellId,
      data: { id: itemId },
      wellId: previousHoverWellId,
    } = sourceItem;

    const { data = [] } = props;
    const isItemInWell = !!data.find(({ id }) => itemId === id);

    const { moveToWell, moveItem, id: currentWellId } = props;

    const itemNodes = component.node.childNodes;

    // find the position the mouse is over. It is used to determine where the item should be moved
    // if a node is found, then mouse is over an item and that node is also returned
    const { node, hoverIndex } = findHoverPosition(
      itemNodes,
      monitor,
      isItemInWell
    );

    if (hoverIndex === -1) {
      // since we are in hover event, hover index must be found in any case
      throw new Error(
        `could not find hover position in well with id=${currentWellId}`
      );
    }

    const movedBackToInitialWell =
      isItemInWell &&
      currentWellId === initialWellId &&
      currentWellId !== previousHoverWellId;

    // if the item is not in well or it moved back to its initial well,
    // remove old preview from previous visited well and restore/insert an item in the current well
    if (!isItemInWell || movedBackToInitialWell) {
      // add the item to the well at hoverIndex, no other actions needed at this step
      moveToWell(sourceItem, currentWellId, hoverIndex);
      return;
    }

    // the item was in well before, check if it should be repositioned to match mouse coordinates
    if (needToMoveItem(node, monitor, hoverIndex, currentWellId)) {
      moveItem(hoverIndex, currentWellId, sourceItem);
      return;
    }

    const { wellId: lastVisitedWellId } = sourceItem;
    if (currentWellId !== lastVisitedWellId) {
      throw new Error(
        `well with id = ${currentWellId} is not marked visited. Item well id = ${lastVisitedWellId}`
      );
    }
  },
  canDrop(props, monitor) {
    const { initialWellId: dragItemWellId } = monitor.getItem();
    if (isNil(dragItemWellId)) {
      return false;
    }
    const { allowedSourceWellIds = [] } = props;
    return allowedSourceWellIds.includes(dragItemWellId);
  },
  drop(props) {
    const { id: targetWellId } = props;

    return {
      wellId: targetWellId,
    };
  },
};

export default DropTarget(itemType, itemTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(Well);
