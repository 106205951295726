export const NAME = "metadata" as const;

//Forms
export const METADATA_FORM = "META_DATA" as const;

export const SET_METADATA = "SET_METADATA" as const;
export const HAS_MADE_REQUEST = "HAS_MADE_REQUEST" as const;
export const SET_METADATA_FIELDS = "SET_METADATA_FIELDS" as const;
export const FETCH_METADATA_FIELDS = "FETCH_METADATA_FIELDS" as const;
export const FETCH_METADATA_FIELDS_FAILED =
  "FETCH_METADATA_FIELDS_FAILED" as const;
