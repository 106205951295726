import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { ConfirmationModal } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { getCVLDetails, removeCVLFromProject, removeCVL } from "../actions";
import { ID_FIELD, ORIGIN_PROJECT_ID_FIELD } from "../constants";
import { isSuperAdminOrProjectAdmin } from "../selectors";
import { ProjectCuratedVariantList } from "../types";
import { filterCVLList, isCVLStatusFailed, isCVLStatusSuccess } from "../utils";

import { getInheritedColumns, getProjectOwnColumns } from "./columns";

import congenicaApi from "api/congenica-api";

interface CVLTableProps extends PropsFromRedux {
  isLoading: boolean;
  items: Array<ProjectCuratedVariantList>;
  title: string;
  isProjectOwnTable?: boolean;
  projectId: number;
}

const {
  useGetProjectFeaturesSettingsQuery,
  useGetThirdPartyDataVersionsQuery,
} = congenicaApi;

export const CVLTable = memo(
  ({
    items = [],
    isLoading = false,
    isProjectOwnTable = true,
    errorReportAvailable = false,
    title,
    removeCVLFromProject,
    removeCVL,
    getCVLDetails,
    projectId,
  }: CVLTableProps) => {
    const [filterValue, setFilterValue] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] =
      useState(false);
    const [selectedCVL, setSelectedCVL] = useState(null);

    const { data: projectFeaturesSettings } =
      useGetProjectFeaturesSettingsQuery({
        projectId,
      });
    const cvlIdUsedForAutomation =
      projectFeaturesSettings?.automation?.settings?.curatedVariantListId;

    const { data: ensemblVersions = [], isLoading: ensemblVersionsLoading } =
      useGetThirdPartyDataVersionsQuery({
        name: "ENSEMBL",
      });

    const showEligibleForAutomatedAnnotationDecisionsColumn = useMemo(
      () => ensemblVersions.length > 1,
      [ensemblVersions]
    );

    const onRemoveCVLFromProject = useCallback(
      cvl => {
        setSelectedCVL(cvl);
        setShowRemoveConfirmationModal(true);
      },
      [setSelectedCVL, setShowRemoveConfirmationModal]
    );

    const columns = useMemo(
      () =>
        isProjectOwnTable
          ? getProjectOwnColumns(
              onRemoveCVLFromProject,
              errorReportAvailable,
              cvlIdUsedForAutomation,
              showEligibleForAutomatedAnnotationDecisionsColumn
            )
          : getInheritedColumns(
              cvlIdUsedForAutomation,
              showEligibleForAutomatedAnnotationDecisionsColumn
            ),
      [
        isProjectOwnTable,
        onRemoveCVLFromProject,
        errorReportAvailable,
        cvlIdUsedForAutomation,
        showEligibleForAutomatedAnnotationDecisionsColumn,
      ]
    );

    useEffect(() => {
      setFilteredItems(filterCVLList(items, filterValue));
    }, [items, filterValue, setFilteredItems]);

    const getRowProps = useCallback(
      (_, { row: { original: cvl } }) => {
        const { curatedVariantListId, validationStatus, parentProjectName } =
          cvl;
        return {
          key: `${curatedVariantListId}${parentProjectName}`,
          onClick: ({ target }) => {
            if (
              target.nodeName === "TD" &&
              isCVLStatusSuccess(validationStatus)
            ) {
              getCVLDetails(cvl[ID_FIELD], cvl[ORIGIN_PROJECT_ID_FIELD]);
            }
          },
          className: `curated-lists-${validationStatus.toLowerCase()}`,
        };
      },
      [getCVLDetails]
    );

    const onFilterChange = useCallback(
      ({ filter = "" }) => {
        setFilterValue(filter);
      },
      [setFilterValue]
    );

    const removeCvlInAction = useCallback(() => {
      if (!selectedCVL) {
        return;
      }
      const { curatedVariantListId, name, validationStatus } = selectedCVL;
      const removeAction = isCVLStatusFailed(validationStatus)
        ? removeCVL
        : removeCVLFromProject;
      removeAction(curatedVariantListId, name);
      setShowRemoveConfirmationModal(false);
      setSelectedCVL(null);
    }, [selectedCVL, removeCVLFromProject, removeCVL]);

    return (
      <div>
        <Table
          columns={columns}
          data={filteredItems}
          fetchData={onFilterChange}
          enableFilter
          title={title}
          showTitleInfo
          showPagination={filteredItems.length > DEFAULT_PAGE_SIZE}
          pageSizeOptions={[10, 25, 50, 100]}
          autoResetPage={false}
          noDataText="No CVL assigned for curation"
          loading={isLoading || ensemblVersionsLoading}
          loadingText=""
          getRowProps={getRowProps}
          sortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
        <ConfirmationModal
          confirmationText="Are you sure you want to remove this Curated variant list?"
          showConfirmationModal={showRemoveConfirmationModal}
          yesClickHandler={removeCvlInAction}
          closeClickHandler={() => setShowRemoveConfirmationModal(false)}
          className="remove-preset-confirm-modal"
        />
      </div>
    );
  }
);

const mapStateToProps = state => ({
  errorReportAvailable: isSuperAdminOrProjectAdmin(state),
});

const mapDispatchToProps = {
  removeCVLFromProject,
  removeCVL,
  getCVLDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLTable);
