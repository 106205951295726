import PropTypes from "prop-types";
import React, { memo, useMemo, createContext, useEffect } from "react";
import { connect } from "react-redux";

import { setSelectedHpoTerms } from "modules/hpoTerms/actions";

import { getFileTypesOptions } from "../../selectors";
import { getRelativesPerSample } from "../../utils";
import { BLANK_ROW_OPTION, FILE_OPTION } from "../componentConstants";

import SamplesTableHeader from "./SamplesTableHeader";
import SamplesTableRow from "./SamplesTableRow";

export const noDataText = (
  <div className="empty-table-label">
    <h4>
      <b>No samples in this interpretation request yet</b>
    </h4>
    <p>
      Click <b>{FILE_OPTION}</b>, or drag and drop files to create multiple
      patients at once, using the interpretation request CSV template or sample
      files to prepopulate sample IDs.
    </p>
    <p>
      Click <b>{BLANK_ROW_OPTION}</b> to add a blank patient row and manually
      fill out new patient information.
    </p>
  </div>
);

export const SamplesTableContext = createContext("SamplesTable");

/**
 * Dynamic samples table
 * uses a simple HTML table directly instead of pattern library components for performance reasons
 */
export const SamplesTable = memo(
  ({
    samples = [],
    selectedRow,
    fieldsDisabled,
    removeRowHandler,
    setSelectedRow,
    setSelectedHpoTerms,
    fileTypesOptions = [],
  }) => {
    const relativesPerSamples = useMemo(
      () => getRelativesPerSample(samples),
      [samples]
    );

    // TODO: Fix hooks violation here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedHpoTerms = samples[selectedRow]
      ? samples[selectedRow].hpoTerms
      : [];

    useEffect(() => {
      if (setSelectedHpoTerms) {
        setSelectedHpoTerms(
          (selectedHpoTerms || []).map(({ code, phenotype }) => ({
            code,
            phenotype,
          }))
        );
      }
    }, [selectedHpoTerms, setSelectedHpoTerms]);

    return (
      <SamplesTableContext.Provider value={{ relativesPerSamples }}>
        <table className="table table-bordered table-striped table-condensed">
          <SamplesTableHeader fileTypesOptions={fileTypesOptions} />
          <tbody>
            {samples.map((sample, index) => {
              const key = `sample-row-${index}`;
              return (
                <SamplesTableRow
                  key={key}
                  sample={sample}
                  index={index}
                  samples={samples}
                  isActiveRow={index === selectedRow}
                  fieldsDisabled={fieldsDisabled}
                  removeRowHandler={removeRowHandler}
                  setSelectedRow={setSelectedRow}
                  fileTypesOptions={fileTypesOptions}
                />
              );
            })}
          </tbody>
        </table>
        {!samples.length && noDataText}
      </SamplesTableContext.Provider>
    );
  }
);

SamplesTable.propTypes = {
  samples: PropTypes.array.isRequired,
  selectedRow: PropTypes.number,
  fieldsDisabled: PropTypes.bool.isRequired,
  setSelectedRow: PropTypes.func.isRequired,
  removeRowHandler: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  fileTypesOptions: getFileTypesOptions(state),
});

const mapDispatchToProps = {
  setSelectedHpoTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(SamplesTable);
