import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";

import { InputGroup } from "pattern-library";

const SearchBar = memo(
  ({ setSearchTerm, onSearch, id, placeholder = "Enter a search term" }) => {
    const onChange = useCallback(
      ({ currentTarget: { value } }) => {
        setSearchTerm(value);
      },
      [setSearchTerm]
    );

    const keyPressed = useCallback(
      e => {
        if (e.key === "Enter" && onSearch) {
          onSearch(e);
        }
      },
      [onSearch]
    );

    return (
      <div className="search-bar">
        <InputGroup
          onChange={onChange}
          onButtonClick={onSearch}
          onKeyPress={keyPressed}
          icon="search"
          containerClass="search-container"
          className="search"
          placeholder={placeholder}
          id={id}
          data-testid={id}
          hasButton
        />
      </div>
    );
  }
);

SearchBar.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SearchBar;
