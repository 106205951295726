import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";

import { Input, Textarea } from "pattern-library";

import styles from "./LimitedInput.module.scss";
import { codePointsCount } from "./utils";

interface Props {
  limit: number;
  component: Input | Textarea;
  handleChange: (value: string) => void;
  value?: string;
}

const LimitedInput: FC<Props> = ({
  limit,
  handleChange,
  component: Component,
  value = "",
  ...props
}: Props) => {
  const onChange = ({ target: { value: newValue } }: any) => {
    if (codePointsCount(newValue) <= limit) {
      handleChange(newValue);
    } else {
      setShowWarning(true);
    }
  };

  const [showWarning, setShowWarning] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowWarning(false);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Component value={value} onChange={onChange} {...props} />
      <small
        className={classNames("text-muted", styles.info)}
      >{`${codePointsCount(value)} characters from ${limit} possible`}</small>
      <div
        className={classNames(
          "message-body-container",
          styles.warning,
          showWarning ? styles.visible : styles.notVisible
        )}
      >
        <div className="alert alert-danger message-enter-done">
          {`Summary text character limit is ${limit}`}
        </div>
      </div>
    </div>
  );
};

export default LimitedInput;
