import { react as autoBind } from "auto-bind";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

import { FormField } from "pattern-library";

const getValue = (value, isMulti) => {
  if (isNil(value) && isMulti === true) {
    return [];
  }
  return value;
};

export default class ReduxFormField extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onSelectBlur() {
    const { input, isMulti = true } = this.props;
    return input.onBlur(getValue(input.value, isMulti));
  }

  onSelectChange(value) {
    const { input, isMulti = true } = this.props;
    return input.onChange(getValue(value, isMulti));
  }

  onInputBlur() {
    const { input } = this.props;
    return input.onBlur(input.value ? input.value.trim() : input.value);
  }

  render() {
    const { input, meta, trimOnBlur, ...rest } = this.props;
    if (rest.type === "select") {
      rest.onChange = this.onSelectChange;
      rest.onBlur = this.onSelectBlur;
    }

    if (rest.type === "text" && trimOnBlur) {
      rest.onBlur = this.onInputBlur;
    }

    return <FormField {...input} {...rest} {...meta} />;
  }
}
