import React from "react";

export const Pedigree = ({ patientId }) => {
  if (!patientId) return null;
  return (
    <iframe
      id="pedigree-iframe"
      title="Pedigree"
      className="panogram-container"
      scrolling="no"
      src={`/pedigree.htm?data-patient-id=${patientId}`}
    />
  );
};
