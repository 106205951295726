import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { ButtonDropdown, Icon, Tooltip, LegacyLink } from "pattern-library";

import {
  SNV_CVL_VARIANTS_TYPE,
  SUCCESS_STATUS,
} from "modules/curatedVariantsLists/constants";
import { ProjectCuratedVariantList } from "modules/curatedVariantsLists/types";

import catalystApi from "api/catalyst-api";
import congenicaApi from "api/congenica-api";
import { useEnsemblVersion } from "hooks/useEnsemblVersion";

const { useGetProjectCuratedListsQuery } = congenicaApi;
const { useLazyGetGeneQuery } = catalystApi;

type CommonProps = {
  patientId: number;
  /**
   * Along with `variantHash`, used to determine if this variant is present on a given CVL
   */
  variantId: number;
  /**
   * Used as an identifier when a variant is being added to a CVL for the first time
   */
  patientVariantId: number;
  /**
   * Legacy hash of CVLs/variant IDs, mapped to curated variant ID
   *
   * e.g.
   *
   * ```
   * {"{curated_list_id}_{variant_id}" : curated_variant_id}
   * ```
   */
  variantHash: Record<string, number>;
  /**
   * Gene symbol of the variant in question. Used to find out whether the gene is present in the
   * latest system version of Ensembl
   */
  geneSymbol: string;
};

type Props = CommonProps & {
  /**
   * Curated variant lists to show in the add/edit dropdown
   */
  cvls: ProjectCuratedVariantList[];
  ensemblVersion: string;
};

type ConnectedProps = CommonProps & {
  projectId: number;
};

const VariantCVLAudit: React.FC<Props> = ({
  patientId,
  variantHash,
  cvls,
  variantId,
  patientVariantId,
  geneSymbol,
  ensemblVersion,
}) => {
  // Split fetching and selecting from the API, so we can trigger the API call from one given instance
  // of the component, but other instances can automatically see if there is data for one
  const [getGene, { isFetching: geneLoading }] = useLazyGetGeneQuery();
  const selectGene = useMemo(
    () => catalystApi.endpoints.getGene.select({ geneSymbol, ensemblVersion }),
    [geneSymbol, ensemblVersion]
  );
  const { data: gene, isSuccess } = useSelector(selectGene);

  /**
   * Was the gene symbol missing from the API response for the version of Ensembl?
   * If so, we assume that it's been removed from the Ensembl data, and therefore
   * you probably shouldn't be using this for an Automation CVL
   */
  const isGeneMissingInLatestEnsembl = useMemo(
    () => isSuccess && !gene,
    [gene, isSuccess]
  );

  const filteredCvls = useMemo(
    () =>
      cvls?.filter(cvl => {
        const variantExistsOnCvl = Boolean(
          variantHash[`${cvl.curatedVariantListId}_${variantId}`]
        );
        if (
          cvl.isAutomatedAnnotationDecisions &&
          isGeneMissingInLatestEnsembl &&
          !variantExistsOnCvl
        )
          return false;
        return true;
      }),
    [cvls, isGeneMissingInLatestEnsembl, variantHash, variantId]
  );

  const dropdownContent = useMemo(() => {
    // We have to show a loading state so we don't have a jump when some CVLs (potentially)
    // disappear from the list, due to a missing gene in the version of Ensembl
    if (geneLoading) {
      return [
        {
          key: "loading",
          content: (
            <>
              <Icon type="spinner" /> Loading...
            </>
          ),
        },
      ];
    }
    if (filteredCvls.length === 0) {
      return [{ key: "no-cvls", content: <>No valid CVLs found</> }];
    }
    return filteredCvls.map(({ curatedVariantListId, name }) => {
      const cvlVariantId = variantHash[`${curatedVariantListId}_${variantId}`];
      const variantExistsOnCvl = Boolean(cvlVariantId);
      const iconType = variantExistsOnCvl ? "pencil" : "plus";

      const iconLabel = variantExistsOnCvl
        ? "Edit variant in CVL"
        : "Add variant to CVL";

      const legacyModalLink = variantExistsOnCvl
        ? `/patient/${patientId}/variants/curated_variant/${cvlVariantId}`
        : `/patient/${patientId}/curated_variant/new_from_patientsnv/${curatedVariantListId}/${patientVariantId}`;

      return {
        key: curatedVariantListId,
        content: (
          <LegacyLink href={legacyModalLink}>
            <Icon type={iconType} aria-label={iconLabel} />
            {name}
          </LegacyLink>
        ),
      };
    });
  }, [
    filteredCvls,
    geneLoading,
    patientId,
    patientVariantId,
    variantHash,
    variantId,
  ]);
  const label = "Add/edit curated variant lists";
  const cannotAddToAutoCvlsLabel = `This variant can't be added to Automation CVLs, as the ${geneSymbol} gene is not present in the latest Ensembl version used by the application`;

  const handleButtonClick = () => {
    getGene({ geneSymbol, ensemblVersion }, true);
  };

  const styles: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  };

  return (
    <div style={styles}>
      <Tooltip content={label}>
        <ButtonDropdown
          aria-label={label}
          btnContent={<Icon type="plus" />}
          list={dropdownContent}
          dropdownPosition="right"
          removePadding={filteredCvls.length > 0 && !geneLoading}
          onOpen={handleButtonClick}
        />
      </Tooltip>
      {isGeneMissingInLatestEnsembl ? (
        <Tooltip content={cannotAddToAutoCvlsLabel}>
          <Icon type="warning" aria-label={cannotAddToAutoCvlsLabel} />
        </Tooltip>
      ) : null}
    </div>
  );
};

const ConnectedVariantCVLAudit: React.FC<ConnectedProps> = ({
  patientId,
  projectId,
  variantHash,
  variantId,
  patientVariantId,
  geneSymbol,
}) => {
  const { data, isLoading: cvlsLoading } = useGetProjectCuratedListsQuery(
    {
      projectId,
      // This filtering is not yet working on API - client side filtering is being done instead
      filterOptions: {
        variant_type: SNV_CVL_VARIANTS_TYPE,
        is_reference: false,
        validation_status: SUCCESS_STATUS,
      },
    },
    {
      selectFromResult: result => ({
        ...result,
        data: result.data?.filter(
          // TODO: Remove client side filtering once SAP-35287 is complete
          cvl =>
            !cvl.isReference &&
            cvl.variantType === SNV_CVL_VARIANTS_TYPE &&
            cvl.validationStatus === SUCCESS_STATUS
        ),
      }),
    }
  );

  const { ensemblVersion, isLoading: ensemblLoading } = useEnsemblVersion();

  if (ensemblLoading || cvlsLoading) return <Icon type="spinner" />;

  return (
    <VariantCVLAudit
      cvls={data || []}
      patientId={patientId}
      variantId={variantId}
      patientVariantId={patientVariantId}
      variantHash={variantHash}
      geneSymbol={geneSymbol}
      ensemblVersion={ensemblVersion as string}
    />
  );
};

export default ConnectedVariantCVLAudit;
