import { set } from "dot-prop-immutable";

import { IDLE_STATUS } from "../../common/constants";
import { hasRequestFailed, isRequestSuccessful } from "../../common/selectors";

import type { Action } from "./actions";
import {
  SET_IR_SUBMISSION_STATUS,
  RESET_IR_SUBMISSION_STATUS,
  SET_PROJECT_REQUEST_STATUS,
  SET_PROTOCOLS,
  SET_SAMPLE_TYPES,
  SET_CNV_CALLING_REQUEST_STATUS,
  RESET_CNV_CALLING_REQUEST_STATUS,
  SET_BAITSETS,
  SET_FILES_PROCESSING,
} from "./constants";
import type { State } from "./types";

export const initialState: State = {
  ui: {
    irSubmissionStatus: null,
    projectRequestStatus: null,
    cnvCallingRequestStatus: IDLE_STATUS,
    cnvCallingRequestError: null,
    filesProcessing: false,
  },
  data: {
    protocols: [],
    baitsets: [],
    sampleTypes: [],
  },
};

const updateCNVCallingRequestStatus = (
  state: State,
  payload: StatusPayload
) => {
  const { requestStatus: cnvCallingRequestStatus, error = null } = payload;
  let cnvCallingRequestError: string | null = null;
  if (hasRequestFailed(cnvCallingRequestStatus)) {
    cnvCallingRequestError = error || "Unknown error";
  }
  if (isRequestSuccessful(cnvCallingRequestStatus)) {
    cnvCallingRequestError = error;
  }

  return {
    ...state,
    ui: {
      ...state.ui,
      cnvCallingRequestStatus,
      cnvCallingRequestError,
    },
  };
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case SET_PROJECT_REQUEST_STATUS:
      return set(state, "ui.projectRequestStatus", action.payload);
    case SET_IR_SUBMISSION_STATUS:
      return set(state, "ui.irSubmissionStatus", action.payload);
    case RESET_IR_SUBMISSION_STATUS:
      return set(state, "ui.irSubmissionStatus", null);
    case SET_PROTOCOLS:
      return set(state, "data.protocols", action.payload);
    case SET_SAMPLE_TYPES:
      return set(state, "data.sampleTypes", action.payload);
    case SET_CNV_CALLING_REQUEST_STATUS:
      return updateCNVCallingRequestStatus(state, action.payload);
    case RESET_CNV_CALLING_REQUEST_STATUS:
      return updateCNVCallingRequestStatus(state, {
        requestStatus: IDLE_STATUS,
      });
    case SET_BAITSETS:
      return set(state, "data.baitsets", action.payload);
    case SET_FILES_PROCESSING:
      return set(state, "ui.filesProcessing", action.payload);
    default:
      return state;
  }
}
