// @flow

export const reportsText = (
  reportsCount: number = 0,
  reportsWithVariantsCount: number = 0,
  reportsWithoutVariantsCount: number = 0
): string => {
  let text: string = "";
  if (reportsCount > 0) {
    text += reportsCount === 1 ? "View report" : `View ${reportsCount} reports`;
    const unknown: number =
      reportsCount - (reportsWithVariantsCount + reportsWithoutVariantsCount);
    if (unknown > 0) {
      text += `\n(${reportsWithoutVariantsCount} without variants,\n${unknown} unknown)`;
    } else if (reportsWithoutVariantsCount > 0) {
      text += `\n(${reportsWithoutVariantsCount} without variants)`;
    }
  }
  return text;
};
