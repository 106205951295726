import { createSelector } from "@reduxjs/toolkit";

import { getData, getPatientsFilter } from "modules/patients/selectors";
import { formatDate } from "modules/utils";
import { filterProcessor } from "modules/utils/table";

import {
  PATIENT_LAST_UPDATED_FIELD,
  PATIENTS_EXPORT_SEARCH_FIELDS,
} from "./constants";

export const getPatientsExportFilteredData = createSelector(
  getData,
  getPatientsFilter,
  (data: Array<any>, filterValue: string) =>
    filterProcessor(PATIENTS_EXPORT_SEARCH_FIELDS, () => ({
      [PATIENT_LAST_UPDATED_FIELD]: value =>
        value ? formatDate(value) : value,
    }))(data, filterValue)
);
