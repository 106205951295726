import classNames from "classnames";
import React, { FC } from "react";

interface Props {
  className?: string;
  /**
   * Key value object used to render the option elements
   * E.G. [{ 'option-one': 'Option One' }]
   * Or ['option-one', 'option-two']
   *
   * Or array [{value: 'option-one', label: 'Option One'}]
   */
  options: Record<string, string> | Array<{ value: string; label: string }>;
  /**
   * It determines is component accept empty value
   */
  canBeEmpty?: boolean;
  emptyPlaceholder?: string;
  value?: string;
}

const Dropdown: FC<Props> = ({
  options,
  canBeEmpty = false,
  className = "",
  value,
  emptyPlaceholder = "Select an option",
  ...otherProps
}: Props) => (
  <select
    value={value === null ? "" : value}
    className={classNames("form-control", className)}
    {...otherProps}
  >
    {canBeEmpty && <option value="">{emptyPlaceholder}</option>}
    {!canBeEmpty && (
      <option disabled value="">
        {emptyPlaceholder}
      </option>
    )}
    {!Array.isArray(options) &&
      Object.entries(options).map(([key, descriptor]) => (
        <option key={key} value={key}>
          {descriptor}
        </option>
      ))}
    {Array.isArray(options) &&
      options.map((option: { value: string; label: string } | string) => {
        const { value, label } =
          typeof option === "string"
            ? { value: option, label: option }
            : option;
        return (
          <option key={value} value={value}>
            {label}
          </option>
        );
      })}
  </select>
);

export default Dropdown;
