import React from "react";

import { Link, Tag } from "pattern-library";

import "moment/locale/en-gb";
import {
  commaFormatNumber,
  formatLength,
  toDecimalPlaces,
} from "modules/utils/format";

const renderAsTags = items => (
  <div className="structural-variants-patient-overlap-table-tags-column">
    {items.map(value => (
      <Tag key={value}>{value}</Tag>
    ))}
  </div>
);

export const columns = [
  {
    Header: "#",
    accessor: "name",
    Cell: ({
      row: {
        original: { name, patientId, affectedStatus },
      },
    }) => (
      <Link href={`/patient/${patientId}`}>
        {name}
        {affectedStatus && ` (${affectedStatus})`}
      </Link>
    ),
  },
  {
    Header: "Project",
    accessor: "projectCode",
  },
  {
    Header: "HPO Terms",
    accessor: "hpoTerms",
    Cell: ({
      row: {
        original: { hpoTerms },
      },
    }) => renderAsTags(hpoTerms),
  },
  {
    Header: "Gene Panels",
    accessor: "genePanelNames",
    Cell: ({
      row: {
        original: { genePanelNames },
      },
    }) => renderAsTags(genePanelNames),
  },
  {
    Header: "Start",
    accessor: "start",
    Cell: ({
      row: {
        original: { start },
      },
    }) => commaFormatNumber(start),
  },
  {
    Header: "End",
    accessor: "end",
    Cell: ({
      row: {
        original: { end },
      },
    }) => commaFormatNumber(end),
  },
  {
    Header: "Length",
    accessor: "absLength",
    Cell: ({
      row: {
        original: { absLength },
      },
    }) => formatLength(absLength),
  },
  {
    Header: "Type",
    accessor: "subtype",
  },
  {
    Header: "Copy Number",
    accessor: "copyNumber",
  },
  {
    Header: "Variant Overlap",
    accessor: "overlapPercent",
    Cell: ({
      row: {
        original: { overlapPercent },
      },
    }) => `${toDecimalPlaces(overlapPercent)}%`,
  },
  {
    Header: "Quality",
    accessor: "quality",
  },
  {
    Header: "Bayes Factor",
    accessor: "bayesFactor",
  },
  {
    Header: "Pathogenicity",
    accessor: "pathogenicity",
  },
];
