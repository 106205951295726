import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class Heading extends PureComponent {
  static displayName = "Heading";

  static propTypes = {
    /**
     * The content to render in the heading, text or react element
     */
    children: PropTypes.node.isRequired,
    /**
     * Available types for Heading component
     */
    type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  };

  static defaultProps = {
    type: "h1",
  };

  render() {
    const { type: Type, children, ...rest } = this.props;

    return <Type {...rest}>{children}</Type>;
  }
}
