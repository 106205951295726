// @flow
import { createAction } from "modules/utils";

export const actionType = Object.freeze({
  INIT: "PATIENT_METADATA_INIT",

  FETCH_PATIENT_METADATA_FIELDS_START: "FETCH_PATIENT_METADATA_FIELDS_START",
  FETCH_PATIENT_METADATA_FIELDS_SUCCESS:
    "FETCH_PATIENT_METADATA_FIELDS_SUCCESS",
  FETCH_PATIENT_METADATA_FIELDS_FAILURE:
    "FETCH_PATIENT_METADATA_FIELDS_FAILURE",

  FETCH_PATIENT_METADATA_CATEGORIES_START:
    "FETCH_PATIENT_METADATA_CATEGORIES_START",
  FETCH_PATIENT_METADATA_CATEGORIES_SUCCESS:
    "FETCH_PATIENT_METADATA_CATEGORIES_SUCCESS",
  FETCH_PATIENT_METADATA_CATEGORIES_FAILURE:
    "FETCH_PATIENT_METADATA_CATEGORIES_FAILURE",

  SET_LOADING: "SET_LOADING",
  SET_METADATA_INFO: "SET_METADATA_INFO",
  RESET_METADATA_INFO: "RESET_METADATA_INFO",

  ADD_METADATA: "ADD_METADATA",
  UPDATE_METADATA: "UPDATE_METADATA",
  DELETE_METADATA: "DELETE_METADATA",
  ADD_INHERITED_METADATA: "ADD_INHERITED_METADATA",
  ADD_ALL_INHERITED_METADATA: "ADD_ALL_INHERITED_METADATA",
});

export type InitialiseAction = FSA<typeof actionType.INIT, number>;
export const initialise = (projectId: number): InitialiseAction =>
  createAction(actionType.INIT, projectId);

export const fetchPatientMetadataFields: AsyncActionCreator = {
  start: (
    projectId: number
  ): FSA<typeof actionType.FETCH_PATIENT_METADATA_FIELDS_START, number> =>
    createAction(actionType.FETCH_PATIENT_METADATA_FIELDS_START, projectId),
  success: (data: {
    fields: Array<MetadataField>,
    inheritedFields: Array<MetadataField>,
  }): FSA<
    typeof actionType.FETCH_PATIENT_METADATA_FIELDS_SUCCESS,
    { fields: Array<MetadataField>, inheritedFields: Array<MetadataField> }
  > => createAction(actionType.FETCH_PATIENT_METADATA_FIELDS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_PATIENT_METADATA_FIELDS_FAILURE> =>
    createAction(actionType.FETCH_PATIENT_METADATA_FIELDS_FAILURE),
};

export const fetchPatientMetadataCategories: AsyncActionCreator = {
  start: (
    projectId: number
  ): FSA<typeof actionType.FETCH_PATIENT_METADATA_CATEGORIES_START, number> =>
    createAction(actionType.FETCH_PATIENT_METADATA_CATEGORIES_START, projectId),
  success: (
    data: Array<MetadataField>
  ): FSA<
    typeof actionType.FETCH_PATIENT_METADATA_CATEGORIES_SUCCESS,
    Array<MetadataField>
  > => createAction(actionType.FETCH_PATIENT_METADATA_CATEGORIES_SUCCESS, data),
  failure: (): FSA<
    typeof actionType.FETCH_PATIENT_METADATA_CATEGORIES_FAILURE
  > => createAction(actionType.FETCH_PATIENT_METADATA_CATEGORIES_FAILURE),
};

export type SetLoadingAction = FSA<typeof actionType.SET_LOADING, boolean>;
export const setLoading = (loading: boolean): SetLoadingAction =>
  createAction(actionType.SET_LOADING, loading);

type SetMetadataInfoAction = FSA<
  typeof actionType.SET_METADATA_INFO,
  MetadataField | MetadataCategory
>;
export const setMetadataInfo = (
  metadata: MetadataField | MetadataCategory,
  mode: string
): SetMetadataInfoAction =>
  createAction(actionType.SET_METADATA_INFO, { metadata, mode });

export const resetMetadataInfo = () =>
  createAction(actionType.SET_METADATA_INFO, undefined);

export type DeleteMetadataAction = FSA<
  typeof actionType.DELETE_METADATA,
  {
    type: "FIELD" | "CATEGORY",
    metadata: MetadataField | MetadataCategory,
  }
>;
export const deleteMetadata = (
  type: "FIELD" | "CATEGORY",
  metadata: MetadataField | MetadataCategory
): DeleteMetadataAction =>
  createAction(actionType.DELETE_METADATA, {
    type,
    metadata,
  });

export type AddMetadataAction = FSA<
  typeof actionType.ADD_METADATA,
  {
    type: "FIELD" | "CATEGORY",
    metadata: MetadataField | MetadataCategory,
  }
>;
export const addMetadata = (
  type: "FIELD" | "CATEGORY",
  metadata: MetadataField | MetadataCategory
): AddMetadataAction =>
  createAction(actionType.ADD_METADATA, {
    type,
    metadata,
  });

export type UpdateMetadataAction = FSA<
  typeof actionType.UPDATE_METADATA,
  {
    type: "FIELD" | "CATEGORY",
    metadata: MetadataField | MetadataCategory,
    successMessage: ?string,
  }
>;
export const updateMetadata = (
  type: "FIELD" | "CATEGORY",
  metadata: MetadataField | MetadataCategory,
  successMessage: ?string
): UpdateMetadataAction =>
  createAction(actionType.UPDATE_METADATA, {
    type,
    metadata,
    successMessage,
  });

export type AddInheritedMetadataAction = FSA<
  typeof actionType.ADD_INHERITED_METADATA,
  {
    type: "FIELD" | "CATEGORY",
    metadata: MetadataField | MetadataCategory,
  }
>;
export const addInheritedMetadata = (
  type: "FIELD" | "CATEGORY",
  metadata: MetadataField | MetadataCategory
): AddInheritedMetadataAction =>
  createAction(actionType.ADD_INHERITED_METADATA, {
    type,
    metadata,
  });

export type AddAllInheritedMetadataAction = FSA<
  typeof actionType.ADD_ALL_INHERITED_METADATA,
  "FIELD" | "CATEGORY"
>;
export const addAllInheritedMetadata = (
  type: "FIELD" | "CATEGORY"
): AddAllInheritedMetadataAction =>
  createAction(actionType.ADD_ALL_INHERITED_METADATA, type);

export type Action =
  | fetchPatientMetadataFields.start
  | fetchPatientMetadataFields.success
  | fetchPatientMetadataFields.failure
  | fetchPatientMetadataCategories.start
  | fetchPatientMetadataCategories.success
  | fetchPatientMetadataCategories.failure
  | InitialiseAction
  | SetLoadingAction
  | SetMetadataInfoAction
  | AddMetadataAction
  | UpdateMetadataAction
  | DeleteMetadataAction
  | AddInheritedMetadataAction
  | AddAllInheritedMetadataAction;
