import PropTypes from "prop-types";
import React from "react";

import { allowedIcons, Icon } from "../../elements";

import styles from "./Alert.module.scss";

export const AlertSize = {
  small: "small",
  medium: "medium",
  large: "large",
};

export const AlertIcons = {
  primary: "exclamationSign",
  success: "tick",
  error: "cross",
  warning: "warningSign",
  info: "infoSign",
};

export const AlertAlignment = {
  top: "top",
  center: "center",
  bottom: "bottom",
};

export const AlertSeverity = {
  primary: "primary",
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

const renderIcon = (icon, severity) => {
  if (icon === true) {
    return <Icon data-testid="alert-icon" type={AlertIcons[severity]} />;
  }
  if (icon in allowedIcons) {
    return <Icon data-testid="alert-icon" type={icon} />;
  }
  return icon;
};

const renderClose = onClose => (
  <button
    data-testid="alert-close-button"
    className="close"
    type="button"
    aria-pressed="false"
    tabIndex="0"
    onClick={onClose}
  >
    &#x2715;
  </button>
);

export const Alert = ({
  id,
  severity,
  size,
  alignment,
  icon,
  children,
  actions,
  onClose,
}) => (
  <div
    id={id}
    data-testid="alert"
    className={`${styles.alert} ${styles[alignment]} ${styles[severity]} ${styles[size]}`}
  >
    {icon && <div className={styles.icon}>{renderIcon(icon, severity)}</div>}
    {children && <div className={styles.content}>{children}</div>}
    {(actions || onClose) && (
      <div className={styles.actions}>
        {actions}
        {onClose && renderClose(onClose)}
      </div>
    )}
  </div>
);

Alert.defaultProps = {
  severity: AlertSeverity.primary,
  size: AlertSize.medium,
  alignment: AlertAlignment.center,
};

Alert.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
    PropTypes.oneOf([
      ...Object.values(AlertIcons),
      ...Object.keys(allowedIcons),
    ]),
  ]),
  alignment: PropTypes.oneOf(Object.values(AlertAlignment)),
  severity: PropTypes.oneOf(Object.values(AlertSeverity)),
  size: PropTypes.oneOf(Object.values(AlertSize)),
  actions: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
};
