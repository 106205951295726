import { fetchCamelizeData } from "modules/utils";
import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";
import { flattenApiEntity } from "modules/utils/formatApiResponse";

export const getProjectSettings = async projectId => {
  const { ok, payload } = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/projects/${projectId}/settings/defaults`
  );

  if (!ok) {
    throw new Error("Couldn't retrieve project settings!");
  }

  const { value, ...rest } = flattenApiEntity(payload);

  return {
    ...rest,
    ...value,
  };
};
