import React, { memo } from "react";

import { InfoList } from "pattern-library";

import { UK_REGION } from "../../constants";
import { getCEMarkings } from "../../utils";

const UK = () => {
  const ceMarkings = getCEMarkings(UK_REGION);
  return <InfoList data={ceMarkings} />;
};

export default memo(UK);
