// @flow
import { createAction } from "modules/utils";

export const actionTypes = Object.freeze({
  FETCH_REPORT_TEMPLATES_START: "FETCH_REPORT_TEMPLATES_START",
  FETCH_REPORT_TEMPLATES_SUCCESS: "FETCH_REPORT_TEMPLATES_SUCCESS",
  FETCH_REPORT_TEMPLATES_FAILURE: "FETCH_REPORT_TEMPLATES_FAILURE",

  SET_PROJECT_SETTINGS_LOADING: "SET_PROJECT_SETTINGS_LOADING",

  FETCH_PROJECT_SETTINGS_START: "FETCH_PROJECT_SETTINGS_START",
  FETCH_PROJECT_SETTINGS_SUCCESS: "FETCH_PROJECT_SETTINGS_SUCCESS",
  FETCH_PROJECT_SETTINGS_FAILURE: "FETCH_PROJECT_SETTINGS_FAILURE",

  GET_OR_RELOAD_PROJECT_SETTINGS: "GET_OR_RELOAD_PROJECT_SETTINGS",

  TOGGLE_TEMPLATE_START: "TOGGLE_TEMPLATE_START",
  TOGGLE_TEMPLATE_SUCCESS: "TOGGLE_TEMPLATE_SUCCESS",
  TOGGLE_TEMPLATE_FAILURE: "TOGGLE_TEMPLATE_FAILURE",

  FETCH_VARIANT_PANEL_START: "FETCH_VARIANT_PANEL_START",
  FETCH_VARIANT_PANEL_SUCCESS: "FETCH_VARIANT_PANEL_SUCCESS",
  FETCH_VARIANT_PANEL_FAILURE: "FETCH_VARIANT_PANEL_FAILURE",

  SAVE_VARIANT_PANEL_START: "SAVE_VARIANT_PANEL_START",
  SAVE_VARIANT_PANEL_SUCCESS: "SAVE_VARIANT_PANEL_SUCCESS",
  SAVE_VARIANT_PANEL_FAILURE: "SAVE_VARIANT_PANEL_FAILURE",

  SET_VARIANT_PANEL_LOADING: "SET_VARIANT_PANEL_LOADING",
  SET_VARIANT_PANEL_LOADING_ERROR: "SET_VARIANT_PANEL_LOADING_ERROR",

  SET_PROJECT_AUTO_ACMG_SETTINGS_LOADING:
    "SET_PROJECT_AUTO_ACMG_SETTINGS_LOADING",

  CHECK_PROJECT_HAS_INHERITORS: "CHECK_PROJECT_HAS_INHERITORS",
  SET_PROJECT_HAS_INHERITORS: "SET_PROJECT_HAS_INHERITORS",
});

declare type FSAToggleTemplate = FSA<
  typeof actionTypes.TOGGLE_TEMPLATE_START,
  ToggleTemplateParameters
>;

export const fetchReportTemplates: AsyncActionCreator = {
  start: (
    projectId: number,
    requestParams?: TableRequestParams
  ): FSA<typeof actionTypes.FETCH_REPORT_TEMPLATES_START, number> =>
    createAction(actionTypes.FETCH_REPORT_TEMPLATES_START, {
      projectId,
      requestParams,
    }),

  success: ({
    reportTemplates,
    projectId,
  }): FSA<typeof actionTypes.FETCH_REPORT_TEMPLATES_SUCCESS, ReportTemplates> =>
    createAction(actionTypes.FETCH_REPORT_TEMPLATES_SUCCESS, reportTemplates, {
      projectId,
    }),

  failure: (): FSA<typeof actionTypes.FETCH_REPORT_TEMPLATES_FAILURE> =>
    createAction(actionTypes.FETCH_REPORT_TEMPLATES_FAILURE),
};

export type SetProjectSettingsLoadingAction = FSA<
  typeof actionTypes.SET_PROJECT_SETTINGS_LOADING,
  boolean
>;
export const setProjectSettingsLoading = (
  loading: boolean
): SetProjectSettingsLoadingAction =>
  createAction(actionTypes.SET_PROJECT_SETTINGS_LOADING, loading);

export const fetchProjectSettings: AsyncActionCreator = {
  start: (
    projectId: number
  ): FSA<typeof actionTypes.FETCH_PROJECT_SETTINGS_START, number> =>
    createAction(actionTypes.FETCH_PROJECT_SETTINGS_START, projectId),

  success: (
    data: ProjectSettings
  ): FSA<typeof actionTypes.FETCH_PROJECT_SETTINGS_SUCCESS, ProjectSettings> =>
    createAction(actionTypes.FETCH_PROJECT_SETTINGS_SUCCESS, data),

  failure: (): FSA<typeof actionTypes.FETCH_PROJECT_SETTINGS_FAILURE> =>
    createAction(actionTypes.FETCH_PROJECT_SETTINGS_FAILURE),
};

export const toggleTemplate: AsyncActionCreator = {
  start: (togglePayload: ToggleTemplateParameters): FSAToggleTemplate =>
    createAction(actionTypes.TOGGLE_TEMPLATE_START, togglePayload),

  success: (
    togglePayload: ToggleTemplateParameters
  ): FSA<typeof actionTypes.TOGGLE_TEMPLATE_SUCCESS, Array<ReportTemplate>> =>
    createAction(actionTypes.TOGGLE_TEMPLATE_SUCCESS, togglePayload),

  failure: (
    togglePayload: ToggleTemplateParameters
  ): FSA<typeof actionTypes.TOGGLE_TEMPLATE_FAILURE> =>
    createAction(actionTypes.TOGGLE_TEMPLATE_FAILURE, togglePayload),
};

export const fetchVariantPanel: AsyncActionCreator = {
  start: (projectId: number): FSAToggleTemplate =>
    createAction(actionTypes.FETCH_VARIANT_PANEL_START, projectId),

  success: (
    projectId: number
  ): FSA<
    typeof actionTypes.FETCH_VARIANT_PANEL_SUCCESS,
    Array<ReportTemplate>
  > => createAction(actionTypes.FETCH_VARIANT_PANEL_SUCCESS, projectId),

  failure: (
    projectId: number
  ): FSA<typeof actionTypes.FETCH_VARIANT_PANEL_FAILURE> =>
    createAction(actionTypes.FETCH_VARIANT_PANEL_FAILURE, projectId),
};

export const saveVariantPanel: AsyncActionCreator = {
  start: (
    variantPanel,
    projectId
  ): FSA<typeof actionTypes.SAVE_VARIANT_PANEL_START, number> =>
    createAction(actionTypes.SAVE_VARIANT_PANEL_START, variantPanel, projectId),

  success: (): FSA<
    typeof actionTypes.SAVE_VARIANT_PANEL_SUCCESS,
    Array<ReportTemplate>
  > => createAction(actionTypes.SAVE_VARIANT_PANEL_SUCCESS),

  failure: (): FSA<typeof actionTypes.SAVE_VARIANT_PANEL_FAILURE> =>
    createAction(actionTypes.SAVE_VARIANT_PANEL_FAILURE),
};

export type SetVariantPanelLoadingAction = FSA<
  typeof actionTypes.SET_VARIANT_PANEL_LOADING,
  boolean
>;

export const setVariantPanelLoading = (
  loading: boolean
): SetVariantPanelLoadingAction =>
  createAction(actionTypes.SET_VARIANT_PANEL_LOADING, loading);

export const setVariantPanelLoadingError = (
  error: boolean
): SetVariantPanelLoadingAction =>
  createAction(actionTypes.SET_VARIANT_PANEL_LOADING_ERROR, error);

export type GetOrReloadProjectSettings = FSA<
  typeof actionTypes.GET_OR_RELOAD_PROJECT_SETTINGS,
  { projectId: number }
>;
export const getOrReloadProjectSettings = (
  projectId: number
): GetOrReloadProjectSettings =>
  createAction(actionTypes.GET_OR_RELOAD_PROJECT_SETTINGS, { projectId });

export type CheckProjectHasInheritors = FSA<
  typeof actionTypes.CHECK_PROJECT_HAS_INHERITORS,
  number
>;

export const checkProjectHasInheritors = (
  projectId: number
): CheckProjectHasInheritors =>
  createAction(actionTypes.CHECK_PROJECT_HAS_INHERITORS, projectId);

export type SetProjectHasInheritors = FSA<
  typeof actionTypes.SET_PROJECT_HAS_INHERITORS,
  string[]
>;

export const setProjectHasInheritors = (
  hasInheritors: string[]
): SetProjectHasInheritors =>
  createAction(actionTypes.SET_PROJECT_HAS_INHERITORS, hasInheritors);

export type Action =
  | fetchReportTemplates.start
  | fetchReportTemplates.success
  | fetchReportTemplates.failure
  | GetOrReloadProjectSettings
  | CheckProjectHasInheritors
  | SetProjectHasInheritors;
