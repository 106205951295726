import { createSelector } from "@reduxjs/toolkit";
import { path } from "ramda";

import { toPercentage } from "modules/utils/format";

import { NAMESPACE, ARIADNE_VALUES } from "./constants";

const { PATHOGENIC, LIKELY_PATHOGENIC, LIKELY_BENIGN, BENIGN } =
  ARIADNE_VALUES.PREDICTED_PATHOGENICITY;

export const getAriadneBreakdownData = requestId =>
  path([NAMESPACE, "breakdownData", requestId, "data"]);

export const isAriadneBreakdownDataLoading = requestId =>
  path([NAMESPACE, "breakdownData", requestId, "loading"]);

export const getAriadneTransformedBreakdownData = requestId =>
  createSelector(getAriadneBreakdownData(requestId), data => {
    if (!data) return data;

    const {
      ariadneScore: confidenceRating,
      ariadneComputedDecision: predictedPathogenicityLevel,
      ariadneProbabilityPathogenic,
      ariadneProbabilityLikelyPathogenic,
      ariadneProbabilityLikelyBenign,
      ariadneProbabilityBenign,
      ariadneComputedC2p,
      ariadneComputedSecondaryFindingC2p,
      ariadnePrimaryFinding,
      ariadneSecondaryFinding,
      ariadnePredictionJustification,
    } = data;

    const primaryFindings = ariadnePrimaryFinding.map(({ hpoName }) => hpoName);
    const secondaryFindings = ariadneSecondaryFinding.map(
      ({ hpoName }) => hpoName
    );

    const predictionJustification = ariadnePredictionJustification.map(
      ({ name, value, weight }) => ({
        name,
        weight,
        value,
      })
    );

    return {
      confidenceRating,
      predictedPathogenicityLevel,
      decisionProbabilities: {
        [PATHOGENIC]: toPercentage(ariadneProbabilityPathogenic),
        [LIKELY_PATHOGENIC]: toPercentage(ariadneProbabilityLikelyPathogenic),
        [LIKELY_BENIGN]: toPercentage(ariadneProbabilityLikelyBenign),
        [BENIGN]: toPercentage(ariadneProbabilityBenign),
      },
      contributionToPhenotype: [
        {
          phenotypes: primaryFindings,
          value: ariadneComputedC2p,
        },
        {
          extended: true,
          phenotypes: secondaryFindings,
          value: ariadneComputedSecondaryFindingC2p,
        },
      ],
      predictionJustification,
    };
  });
