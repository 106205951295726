import React, { useMemo } from "react";
import { connect } from "react-redux";

import { ComposableTabs, Tab } from "pattern-library";

import { LicencesContainer } from "modules/licences";
import { getCEMarking } from "modules/systemConfig/selectors";
import { VersionsContainer } from "modules/versions";

import RegulatoryInfo from "./regulatoryInfo/RegulatoryInfo";

const getTabs = tabs =>
  tabs.map(({ name, contentComponent: Component, props }) => (
    <Tab key={name} name={name}>
      <Component {...props} />
    </Tab>
  ));

export const AboutModalContent = ({ ceMarking }) => {
  const tabs = useMemo(
    () =>
      [
        ceMarking
          ? {
              name: "Regulatory Information",
              contentComponent: RegulatoryInfo,
            }
          : null,
        {
          name: "Versions",
          contentComponent: VersionsContainer,
        },
        {
          name: "Licences",
          contentComponent: LicencesContainer,
        },
      ].filter(Boolean),
    [ceMarking]
  );

  return (
    <div className="about-modal-content">
      <ComposableTabs>{getTabs(tabs)}</ComposableTabs>
    </div>
  );
};

const mapStateToProps = state => ({
  ceMarking: getCEMarking(state),
});

export default connect(mapStateToProps, null)(AboutModalContent);
