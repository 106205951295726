import { createSelector } from "@reduxjs/toolkit";
import { equals, isEmpty, isNil } from "ramda";

import { RootState } from "common/store";
import { getFormValues } from "modules/forms/selectors";
import { GNOMAD_SV_FEATURE } from "modules/project/constants";
import { isProjectFeatureActive } from "modules/project/selectors";
import {
  ANY_GENE_PANEL_ID,
  GNOMAD_AF_KEY,
  SV_COLUMNS,
} from "modules/projectSettings/components/filterPresets/constants";
import {
  addKeyProp,
  getGenePanelsOptions,
} from "modules/projectSettings/components/filterPresets/selectors";
import { getSelectedPresetOption } from "modules/structuralVariantsPresets/selectors";
import { dataPathForModule, uiPathForModule } from "modules/utils/selectors";

import * as constants from "./constants";
import {
  fromValuesToFilters,
  prepareFilters,
  isGenePanelOptionDisabled,
} from "./utils";

const uiPath = uiPathForModule(constants.MODULE_NAME);
const dataPath = dataPathForModule(constants.MODULE_NAME);

export const getFilteredColumns = createSelector(
  (_, backendColumns) => backendColumns,
  state => isProjectFeatureActive(state, GNOMAD_SV_FEATURE),
  (backendColumns, gnomadFeatureEnabled) => {
    const gnomadExcludeFilter = ({ key }) =>
      !(!gnomadFeatureEnabled && key === GNOMAD_AF_KEY);

    if (!backendColumns) {
      return SV_COLUMNS.filter(gnomadExcludeFilter);
    }

    return SV_COLUMNS.filter(gnomadExcludeFilter).filter(({ key }) =>
      backendColumns.includes(key)
    );
  }
);

export const getLoading = uiPath("loading");
export const getPatientOverlaps = dataPath("patientOverlaps");

export const getFilters = uiPath("filters");
export const getSVTableColumns = uiPath("columns");

export const getFilterData = dataPath("filterData");
export const getFilterValues = (state: RootState, filterName: string) =>
  dataPath("filterData", filterName, "values")(state);
export const getFilterOptions = createSelector(
  (state: RootState, filterName: string) => getFilterValues(state, filterName),
  (values = []) => addKeyProp(values)
);
export const getViewMode = uiPath("viewMode");

export const getLegacyFilters = createSelector(
  (_, backendFormat) => backendFormat,
  getFilters,
  (backendFormat, filters) => fromValuesToFilters(filters, backendFormat)
);

export const getFilterGenePanelOptions = createSelector(
  (state, projectId) => getGenePanelsOptions(state, projectId),
  state => getFormValues(state, constants.SV_FILTERS_FORM, "genePanel"),
  (genePanelOptions, formValue = []) => {
    const selectedPanelsIds = formValue ? formValue : [];
    const isAnyGeneSelected = selectedPanelsIds.some(
      panelId => panelId === ANY_GENE_PANEL_ID
    );
    const hasSelectedValues =
      !isNil(selectedPanelsIds) && !isEmpty(selectedPanelsIds);

    return genePanelOptions.map(groupOption => ({
      ...groupOption,
      options: groupOption.options.map(option => ({
        ...option,
        value: "" + option.value,
        isDisabled: isGenePanelOptionDisabled(
          "" + option.value,
          isAnyGeneSelected,
          hasSelectedValues
        ),
      })),
    }));
  }
);

export const isSelectedPresetCustomized = createSelector(
  getFilters,
  state => getSelectedPresetOption(state),
  (filter, selectedPresetOption: PresetOption<FilterPresetBase<any>>) => {
    const {
      value: { attributes: { config: { filters = {} } = {} } = {} } = {},
    } = selectedPresetOption || {};
    return !equals(filter, prepareFilters({ ...filters }));
  }
);

const TAG_FIELD_KEY = {
  INCLUDED: "included",
  EXCLUDED: "keyword",
};

const filterSvTags = (type: string) => (fields, filterValues) => {
  const fieldKey = type;
  const otherFieldKeyType =
    type === TAG_FIELD_KEY.INCLUDED
      ? TAG_FIELD_KEY.EXCLUDED
      : TAG_FIELD_KEY.INCLUDED;

  const otherFieldKey = `sv_${otherFieldKeyType}_filter`;

  if (!fields || !fields[fieldKey]) {
    return undefined;
  }

  return fields[fieldKey].values.filter(tag => {
    if (!filterValues || !filterValues[otherFieldKey]) {
      return true;
    }

    return !filterValues[otherFieldKey].includes(tag.value);
  });
};

export const getIncludedTagsOptions = createSelector(
  getFilterData,
  getFilters,
  filterSvTags(TAG_FIELD_KEY.INCLUDED)
);

export const getExcludedTagsOptions = createSelector(
  getFilterData,
  getFilters,
  filterSvTags(TAG_FIELD_KEY.EXCLUDED)
);
