import { bindActionCreators } from "@reduxjs/toolkit";
import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { createStructuredSelector } from "reselect";

import { parseDecimalInt } from "../utils";

import * as actions from "./actions";
import Message from "./components/Message";
import * as selectors from "./selectors";

export class MessagesContainer extends PureComponent {
  static propTypes = {
    level: PropTypes.string,
    activeMessages: PropTypes.array.isRequired,
    collapsed: PropTypes.bool.isRequired,
  };

  static alwaysVisibleLevels = ["danger", "warning"];
  isAboutToHide = false;

  constructor(props) {
    super(props);
    this.containerRef = createRef();
    autoBind(this);
    this.state = {
      isDismissing: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { activeMessages } = this.props;
    // When message count increases we want to rethink whether we are collapsing
    // or not.
    if (prevProps.activeMessages.length > activeMessages.length) {
      this.manageAutoHiding();
    } else {
      // We don't want to collapse when the message count decreases as the
      // user has just dismissed a message. So we remove any running timeout
      clearTimeout(this.isAboutToHide);
    }
  }

  manageAutoHiding() {
    const { level, collapse } = this.props;
    const { alwaysVisibleLevels } = MessagesContainer;

    // If we shouldn't be hiding but already have a timeout running - cancel it
    if (alwaysVisibleLevels.includes(level) && this.isAboutToHide) {
      clearTimeout(this.isAboutToHide);
    } else if (!alwaysVisibleLevels.includes(level)) {
      // otherwise if we need to think about hiding start the timeout and cancel
      // any previously running timout
      clearTimeout(this.isAboutToHide);
      this.isAboutToHide = setTimeout(collapse, 5000);
    }
  }

  closeMessage(e) {
    const { dismiss } = this.props;
    if (e.currentTarget.dataset.message) {
      dismiss(parseDecimalInt(e.currentTarget.dataset.message));
      this.setState({
        isDismissing: true,
      });

      setTimeout(() => {
        this.setState({
          isDismissing: false,
        });
      }, 750);
    }
  }

  dismissAll() {
    const { dismissAll } = this.props;
    this.setState({
      isDismissing: true,
    });
    dismissAll();

    setTimeout(() => {
      this.setState({
        isDismissing: false,
      });
    }, 750);
  }

  render() {
    const { level, activeMessages, toggleCollapse, collapsed } = this.props;
    const { isDismissing } = this.state;
    const containerStyle = {};

    // If we are collapsed we need to calculate heights to position the messages panel correctly
    if (collapsed && this.containerRef.current) {
      const refStyles = getComputedStyle(this.containerRef.current);
      const headerStyles = getComputedStyle(
        this.containerRef.current.children[0]
      );
      const height = parseDecimalInt(refStyles.height);
      const marginBottom = parseDecimalInt(refStyles.marginBottom);
      const headerHeight = parseDecimalInt(headerStyles.height);
      containerStyle.bottom = `-${height + marginBottom - headerHeight}px`;
    }

    return (
      <div
        ref={this.containerRef}
        style={containerStyle}
        className={classNames("panel", "message-container", {
          [`panel-${level}`]: level,
          "message-container--is-collapsed": collapsed,
          hidden: !isDismissing && activeMessages.length < 1,
        })}
      >
        <div className="panel-heading">
          <h3 className="panel-title">
            {collapsed ? (
              activeMessages.length
            ) : (
              <span>
                Messages
                <span
                  onClick={this.dismissAll}
                  className="btn btn-default btn-xs btn-outline pull-right"
                >
                  Collapse All
                </span>
              </span>
            )}
            <span
              onClick={toggleCollapse}
              className="glyphicon glyphicon-chevron-down pull-right"
            />
          </h3>
        </div>
        <div className="panel-body">
          <TransitionGroup className="message-body-container">
            {activeMessages.map(message => (
              <CSSTransition
                key={`message-${message.id}`}
                timeout={750}
                classNames="message"
              >
                <Message
                  {...message}
                  key={message.id}
                  id={String(message.id)}
                  closeMessage={this.closeMessage}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    level: selectors.getMostImportantMessageLevel,
    activeMessages: selectors.getActiveMessages,
    collapsed: selectors.isCollapsed,
  }),
  dispatch => bindActionCreators(actions, dispatch)
)(MessagesContainer);
