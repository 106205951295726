import React, { memo } from "react";

import { Checkbox } from "pattern-library";

interface PatientCustomFilterProps {
  hideReviewCompletePrefs: boolean;
  onHideReviewCompleteChange: (boolean) => void;
}
const PatientCustomFilter = ({
  hideReviewCompletePrefs,
  onHideReviewCompleteChange,
}: PatientCustomFilterProps) => (
  <div className="dataTables_filter custom-filter">
    <Checkbox
      name="hideReviewComplete"
      checked={hideReviewCompletePrefs}
      label='Hide "Review complete" samples'
      onChange={e => onHideReviewCompleteChange(e.target.checked)}
    />
  </div>
);

export default memo(PatientCustomFilter);
