// @flow

import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import * as constants from "./constants";

type State = {
  data: Array<Role>,
};

export const initialState: State = {
  data: [],
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.READ_ROLES_SUCCESS:
      return set(state, "data", action.payload);

    default:
      return state;
  }
}
