import { attr } from "redux-orm";

import BaseModel from "./BaseModel";

class GenePanel extends BaseModel {
  static modelName = "GenePanel";

  static fields = {
    genePanelId: attr(),
    externalId: attr(),
    description: attr(),
    title: attr(),
    version: attr(),
    // This is defined on the other model
    // patients: many('Patient', 'genePanels'),
  };

  static options = {
    idAttribute: "genePanelId",
  };

  static getMethodUrlMap() {
    return {
      CREATE: `${this.basePath}/gene-panel`,
      READ: `${this.basePath}/gene-panel/:id`,
      UPDATE: `${this.basePath}/gene-panel/:id`,
      DELETE: `${this.basePath}/gene-panel/:id`,
      LIST: `${this.basePath}/gene-panel`,
    };
  }

  static parse(genePanel) {
    return this.upsert(genePanel);
  }

  toString() {
    return this.name;
  }
}

export default GenePanel;
