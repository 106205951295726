import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Button, Icon, Accordion } from "pattern-library";

import { GenePanelButton } from "./GenePanelButton";

export class GeneSidePanel extends PureComponent {
  static propTypes = {
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      allGenes: false,
    };
  }

  toggleAllGenes() {
    this.setState(state => ({
      allGenes: !state.allGenes,
    }));
  }

  geneButtonClicked(geneId) {
    const {
      panel: { genes = [] },
      patientId,
      selectContextForVariantPanel,
    } = this.props;

    const gene = genes.find(({ geneId: geneDataId }) => geneDataId === geneId);

    if (gene) {
      selectContextForVariantPanel(patientId, geneId, false);
    }
  }

  render() {
    const {
      panel: { archived, title, genes = [] },
    } = this.props;

    const { allGenes } = this.state;

    const panelButtonsComponent = (
      <>
        {genes.map(gene =>
          gene.active === true || allGenes === true ? (
            <GenePanelButton
              key={gene.geneId}
              gene={gene}
              selectGene={this.geneButtonClicked}
            />
          ) : null
        )}
        <Button className="panel-btn" onClick={this.toggleAllGenes}>
          {!allGenes && (
            <>
              <Icon type="right" /> View all genes ({genes.length})
            </>
          )}
          {allGenes && (
            <>
              <Icon type="left" /> Collapse genes
            </>
          )}
        </Button>
      </>
    );

    const accordionData = [
      {
        sectionName: title,
        content: panelButtonsComponent,
        ...(archived && {
          sectionSubtitle: (
            <div>
              <small className="text-muted"> (archived)</small>
            </div>
          ),
        }),
      },
    ];

    return (
      <Accordion data={accordionData} className="gene-panel-group">
        <span className="panel-badge badge">{genes.length}</span>
      </Accordion>
    );
  }
}

export default GeneSidePanel;
