import { createAction } from "../utils";

import {
  BLOCK_UI_SYSTEM_CONFIG,
  LOAD_SYSTEM_CONFIG,
  SET_AUTH_PROVIDERS,
  SET_SYSTEM_CONFIG,
} from "./constants";

type LoadSystemConfigAction = FSA<typeof LOAD_SYSTEM_CONFIG, void>;
export const loadSystemConfig = (): LoadSystemConfigAction =>
  createAction(LOAD_SYSTEM_CONFIG);

type SetSystemConfigAction = FSA<typeof SET_SYSTEM_CONFIG, SystemConfig>;
export const setSystemConfig = (config: SystemConfig): SetSystemConfigAction =>
  createAction(SET_SYSTEM_CONFIG, config);

type SetAuthProvidersAction = FSA<
  typeof SET_AUTH_PROVIDERS,
  Array<AuthProvider>
>;
export const setAuthProviders = (
  providers: Array<AuthProvider>
): SetAuthProvidersAction => createAction(SET_AUTH_PROVIDERS, providers);

type BlockUISystemConfigAction = FSA<typeof BLOCK_UI_SYSTEM_CONFIG, void>;
export const blockUISystemConfig = (): BlockUISystemConfigAction =>
  createAction(BLOCK_UI_SYSTEM_CONFIG);
