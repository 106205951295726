import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { LegacyLink, Loading } from "pattern-library";

import { fetchOtherPatientsSnvCounts } from "../actions";
import {
  getOtherPatientsSnvCounts,
  isOtherPatientsSnvCountsLoading,
} from "../selectors";
import { OtherPatientsSnvCountsRequest, SUMMARY_STATE_PATH } from "../types";

import { getFieldBaseHref } from "./utils";

type OtherPatientsCountsProps = OtherPatientsSnvCountsRequest &
  PropsFromRedux & {
    transcriptId?: string | number | null;
    path: SUMMARY_STATE_PATH;
  };

export const OtherPatientsCounts = ({
  patientId,
  variantId,
  transcriptId,
  geneName,
  path,
  snvCountsObject: { data, href },
  loading,
  loadData,
}: OtherPatientsCountsProps) => {
  useEffect(() => {
    if (patientId && geneName && variantId) {
      loadData(
        {
          patientId,
          geneName,
          variantId,
        },
        { path }
      );
    }
  }, [loadData, patientId, geneName, variantId, path]);

  const modalHref = useMemo(
    () =>
      `${getFieldBaseHref({
        patientId,
        geneName,
        variantId,
        transcriptId,
      })}${href}`,
    [patientId, geneName, variantId, transcriptId, href]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div data-testid="other-patients-snv-counts-table">
      <LegacyLink href={modalHref} attrs={{ fireContentLoaded: true }}>
        <table className="table table-condensed table-hover">
          <tbody>
            {data.map(({ description, counts }) => (
              <tr key={description}>
                <td className="text-right col-xs-5">
                  <strong>{counts}</strong>
                </td>
                <td>{description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </LegacyLink>
    </div>
  );
};

const mapStateToProps = (state, { path }) => ({
  loading: isOtherPatientsSnvCountsLoading(state, path),
  snvCountsObject: getOtherPatientsSnvCounts(state, path),
});

const mapDispatchToProps = {
  loadData: fetchOtherPatientsSnvCounts.start,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OtherPatientsCounts);
