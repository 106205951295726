// @flow
import { call, put, takeLatest } from "redux-saga/effects";

import { postFeedback } from "../api/feedback";
import type { FetchDataResponse } from "../utils/fetchData";
import { processError, processSuccess } from "../utils/sagas";

import {
  submitFeedbackSuccess,
  submitFeedbackFailure,
  resetSubmitFeedbackStatus,
  resetFeedbackDraftAction,
} from "./actions";
import * as constants from "./constants";
import type { FeedbackType, RequestData } from "./flow-types";

export function* init(): Generator<any, void, void> {
  yield takeLatest(constants.SUBMIT_FEEDBACK, submitFeedback);
}

export function* submitFeedback({
  payload: { feedbackType, requestData },
}: {
  payload: {
    feedbackType: FeedbackType,
    requestData: RequestData,
  },
}): Generator<any, void, FetchDataResponse<any>> {
  const response = yield call(postFeedback, requestData);

  if (response.ok) {
    yield call(processSuccess, response);
    yield put(submitFeedbackSuccess());
    yield put(resetFeedbackDraftAction(feedbackType));
  } else {
    yield call(processError, response);
    yield put(submitFeedbackFailure());
  }

  yield put(resetSubmitFeedbackStatus());
}
