import { createAction } from "../utils";

import * as constants from "./constants";
import { FilterData, Filters, PatientOverlap, ViewMode } from "./types";

export type InitializeAction = (
  projectId: string | number,
  patientId: string | number,
  filterData?: FilterData
) => FSA<
  typeof constants.INIT,
  {
    projectId: string | number;
    patientId: string | number;
    filterData?: FilterData;
  }
>;

export const initialize: InitializeAction = (
  projectId,
  patientId,
  filterData
) => createAction(constants.INIT, { projectId, patientId, filterData });

export type OnSvFilterChangeAction = FSA<
  typeof constants.ON_SV_FILTER_CHANGE,
  { values: Filters }
>;
export const onSvFilterChange = (values: {
  values: Filters;
}): OnSvFilterChangeAction =>
  createAction(constants.ON_SV_FILTER_CHANGE, values);

export type OnSvColumnsChangeAction = FSA<
  typeof constants.ON_SV_COLUMNS_CHANGE,
  Array<string>
>;
export const onSvColumnsChange = (
  columns: Array<string>
): OnSvColumnsChangeAction =>
  createAction(constants.ON_SV_COLUMNS_CHANGE, columns);

export type SetFiltersAction = FSA<
  typeof constants.SET_SV_FILTERS,
  { values: Filters }
>;

export const setFilters = (filters: { values: Filters }): SetFiltersAction =>
  createAction(constants.SET_SV_FILTERS, filters);

export type SetFilterColumnsAction = FSA<
  typeof constants.SET_SV_FILTER_COLUMNS,
  Array<string>
>;

export const setFilterColumns = (
  columns: Array<string>
): SetFilterColumnsAction =>
  createAction(constants.SET_SV_FILTER_COLUMNS, columns);

export type SetFilterDataAction = FSA<
  typeof constants.SET_SV_FILTER_DATA,
  FilterData
>;

export const setFilterData = (filterData: FilterData): SetFilterDataAction =>
  createAction(constants.SET_SV_FILTER_DATA, filterData);

export const fetchPatientOverlaps = {
  start: (
    patientId: number,
    patientSvId: number
  ): FSA<
    typeof constants.FETCH_PATIENT_OVERLAPS_START,
    { patientId: number; patientSvId: number }
  > =>
    createAction(constants.FETCH_PATIENT_OVERLAPS_START, {
      patientId,
      patientSvId,
    }),
  success: (data: {
    patients: Array<PatientOverlap>;
  }): FSA<
    typeof constants.FETCH_PATIENT_OVERLAPS_SUCCESS,
    {
      patients: Array<PatientOverlap>;
    }
  > => createAction(constants.FETCH_PATIENT_OVERLAPS_SUCCESS, data),
  failure: (): FSA<typeof constants.FETCH_PATIENT_OVERLAPS_FAILURE> =>
    createAction(constants.FETCH_PATIENT_OVERLAPS_FAILURE),
};

export type SetLoadingAction = FSA<typeof constants.SET_LOADING, boolean>;
export const setLoading = (loading: boolean): SetLoadingAction =>
  createAction(constants.SET_LOADING, loading);

export type SetSVViewModeAction = FSA<
  typeof constants.SET_SV_VIEW_MODE,
  ViewMode
>;

export const setSVViewMode = (viewMode: ViewMode): SetSVViewModeAction =>
  createAction(constants.SET_SV_VIEW_MODE, viewMode);

export type Action =
  | ReturnType<InitializeAction>
  | SetFiltersAction
  | SetFilterDataAction
  | SetFilterColumnsAction
  | ReturnType<typeof fetchPatientOverlaps.start>
  | ReturnType<typeof fetchPatientOverlaps.success>
  | ReturnType<typeof fetchPatientOverlaps.failure>
  | SetLoadingAction
  | SetSVViewModeAction;
