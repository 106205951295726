import { createAction } from "../utils";

export const actionType = Object.freeze({
  GET_OR_RELOAD_VERSIONS: "GET_OR_RELOAD_VERSIONS",
  FETCH_VERSIONS_START: "FETCH_VERSIONS_START",
  FETCH_VERSIONS_SUCCESS: "FETCH_VERSIONS_SUCCESS",
  FETCH_VERSIONS_FAILURE: "FETCH_VERSIONS_FAILURE",
});

export const getOrReloadVersions = (): FSA<
  typeof actionType.GET_OR_RELOAD_VERSIONS
> => createAction(actionType.GET_OR_RELOAD_VERSIONS);

export const fetchVersions = {
  start: (): FSA<typeof actionType.FETCH_VERSIONS_START> =>
    createAction(actionType.FETCH_VERSIONS_START),
  success: (
    data: Array<Version>
  ): FSA<typeof actionType.FETCH_VERSIONS_SUCCESS, Array<Version>> =>
    createAction(actionType.FETCH_VERSIONS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_VERSIONS_FAILURE> =>
    createAction(actionType.FETCH_VERSIONS_FAILURE),
};

export type Action =
  | ReturnType<typeof getOrReloadVersions>
  | ReturnType<typeof fetchVersions.start>
  | ReturnType<typeof fetchVersions.success>
  | ReturnType<typeof fetchVersions.failure>;
