import { Field } from "formik";
import React, { PureComponent } from "react";

import { Heading, Divider } from "pattern-library";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

export default class VisibilityLevels extends PureComponent {
  static propTypes = {};

  render() {
    const { isInherited } = this.props;

    return (
      <div className="form-horizontal">
        <Field
          name="patientVisibility.enabled"
          component={FormikFormField}
          label="Enable patient visibility levels"
          narrow={9}
          disabled={isInherited}
          type="checkbox"
          data-testid="visibility-checkbox"
        />
        <Heading className="accordion-section-subheader" type="h4">
          Level1
        </Heading>
        <Field
          name="visibilityLevels.1.name"
          component={FormikFormField}
          label="Name"
          narrow={9}
          disabled={isInherited}
          type="text"
          data-testid="visibilityLevels.1.name"
        />
        <Divider />
        <Field
          name="visibilityLevels.1.description"
          component={FormikFormField}
          label="Description"
          narrow={9}
          disabled={isInherited}
          type="text"
          data-testid="visibilityLevels.1.description"
        />
        <Heading className="accordion-section-subheader" type="h4">
          Level2
        </Heading>
        <Field
          name="visibilityLevels.2.name"
          component={FormikFormField}
          label="Name"
          narrow={9}
          disabled={isInherited}
          type="text"
          data-testid="visibilityLevels.2.name"
        />
        <Divider />
        <Field
          name="visibilityLevels.2.description"
          component={FormikFormField}
          label="Description"
          narrow={9}
          disabled={isInherited}
          type="text"
          data-testid="visibilityLevels.2.description"
        />
      </div>
    );
  }
}
