import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Icon from "../elements/base/Icon";
import Link from "../elements/base/Link";

export default class Promo extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { className, icon, label, linkUrl, linkText, ...otherProps } =
      this.props;

    otherProps.className = `promo ${className}`;

    return (
      <div {...otherProps}>
        <div className="row">
          <div className="col-md-3 col--no-margin promo-icon-container">
            <Icon type={icon} />
          </div>
          <div className="col-md-9 col--no-margin promo-label-container">
            <p className="promo-label">{label}</p>
            <Link href={linkUrl}>{linkText}</Link>
          </div>
        </div>
      </div>
    );
  }
}
