export const VALID_FILE_COMBINATIONS_MESSAGE =
  "Valid file combinations: FASTQ, BAM, VCF + BAM, VCF";
export const ACCEPTED_FILE_TYPES_MESSAGE =
  "Accepted file formats: VCF (bgzip), BAM and FASTQ (bgzip or gzip)";
export const FILE_TYPES_WARNING = "Invalid file combinations selected";

export const ADD_BUTTON_NAME = "Add Patient";
export const BLANK_ROW_OPTION = "Add blank patient row";
export const FILE_OPTION = "Add patients";
export const FILE_OPTION_TOOLTIP =
  "Create multiple patient rows at once by using interpretation request CSV template or sample files to add rows with sample IDs";
export const DOWNLOAD_IR_CSV_TEMPLATE = "Interpretation request CSV template";

export const IR_ID_ALLOWED_CHARS_MESSAGE =
  "Only letters, numbers, spaces and symbols (-_.) are allowed in IR ID field";

export const INCORRECT_PARENT_MESSAGE = "Incorrect parent";

export const ACCEPTED_FILE_TYPES_MESSAGE_V2 =
  "Accepted file formats: FASTQ (bgzip or gzip)";

export const ON_PREM_MESSAGES = {
  SPECIFIED_FILES_PRESENT:
    "Please ensure the files specified above are present on the NFS mounted directory prior to interpretation request submission",
  VALID_FILE_COMBINATIONS:
    "If a BAM file is selected, at least one VCF must also be provided",
  ACCEPTED_FILE_TYPES: "Accepted file formats: VCF (bgzip) or BAM",
};
