/**
 * build the composite checkboxes component value to pass to the enclosing form
 * @param options - checkboxes list configuration
 * @param currentValue - the current composite value before the latest change event
 * @param changeEventTarget - the name of the
 * @return {{}}
 */
export const getCheckboxesValue = (
  options,
  currentValue,
  changeEventTarget
) => {
  // collect all options into an object
  const defaultValues = options.reduce((accumulator, { name }) => {
    accumulator[name] = false;
    return accumulator;
  }, {});

  // override the default values by existing checkbox values
  const result = { ...defaultValues, ...currentValue };

  // toggle the target checkbox
  result[changeEventTarget] = !result[changeEventTarget];

  return result;
};

export const getChecked = (value, name) => {
  if (!value) {
    return false;
  }

  return !!value[name];
};
