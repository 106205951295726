import React, { useCallback } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { filterProcessor } from "modules/utils/table";

import {
  addAllInheritedMetadata,
  addInheritedMetadata,
  resetMetadataInfo,
  setMetadataInfo,
  updateMetadata,
} from "../actions";
import {
  ADD_METADATA_CATEGORY,
  CATEGORY,
  DELETE_METADATA_CATEGORY,
  EDIT_METADATA_CATEGORY,
} from "../constants";
import {
  getCategories,
  getInheritedCategories,
  getSelectedMetadataMode,
} from "../selectors";

import AddMetadataButton from "./AddMetadataButton";
import { MetadataCategoryModal } from "./MetadataModal";
import { MetadataTable } from "./MetadataTable";
import { useMetadataActions } from "./useMetadataActions";

const getPropertyMap = ({ inherited }) => ({
  visible: value => (value ? "Yes" : "No"),
  position: value => ++value,
  projectCode: value => (inherited ? value : "-"),
});

const includedInheritedCategoriesProperties = [
  "name",
  "projectCode",
  "visible",
];
const includedCategoriesProperties = [
  "position",
  ...includedInheritedCategoriesProperties,
];

export const inheritedCategoriesFilter = filterProcessor(
  includedInheritedCategoriesProperties,
  getPropertyMap
);
export const categoriesFilter = filterProcessor(
  includedCategoriesProperties,
  getPropertyMap
);

const Categories = ({
  mode,
  resetMetadataInfo,
  setMetadataInfo,
  addInheritedMetadata,
  addAllInheritedMetadata,
  updateMetadata,
  categories,
  inheritedCategories,
}) => {
  const {
    closedModal,
    onAddPatientMetadata,
    onAddAllInheritedMetadata,
    columns,
    inheritedColumns,
  } = useMetadataActions(
    CATEGORY,
    resetMetadataInfo,
    setMetadataInfo,
    addInheritedMetadata,
    addAllInheritedMetadata
  );

  const showModal = [
    ADD_METADATA_CATEGORY,
    EDIT_METADATA_CATEGORY,
    DELETE_METADATA_CATEGORY,
  ].includes(mode);

  const onDropRow = useCallback(
    (row, newIndex) => {
      updateMetadata(
        CATEGORY,
        { ...row, position: newIndex },
        "Metadata category position saved"
      );
    },
    [updateMetadata]
  );

  return (
    <div>
      <MetadataTable
        filter={categoriesFilter}
        columns={columns}
        title="Patient metadata categories"
        items={categories}
        draggable
        onDropRow={onDropRow}
      />
      <AddMetadataButton
        content="Add patient metadata category"
        action={onAddPatientMetadata}
      />

      <MetadataTable
        filter={inheritedCategoriesFilter}
        columns={inheritedColumns}
        title="Patient metadata categories in parent projects"
        items={inheritedCategories}
      />
      {inheritedCategories.length > 0 && (
        <AddMetadataButton
          content="Add all categories to project"
          action={onAddAllInheritedMetadata}
        />
      )}
      {showModal && (
        <MetadataCategoryModal show={showModal} close={closedModal} />
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  mode: getSelectedMetadataMode,
  categories: getCategories,
  inheritedCategories: getInheritedCategories,
});

const mapDispatchToProps = {
  resetMetadataInfo,
  setMetadataInfo,
  addInheritedMetadata,
  addAllInheritedMetadata,
  updateMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
