import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import ArticleProperty from "./ArticleProperty";

export default class Footer extends PureComponent {
  static displayName = "Footer";

  static propTypes = {
    /**
     * Article authors
     */
    authors: PropTypes.string.isRequired,
    /**
     * Article journal
     */
    journal: PropTypes.string.isRequired,
    /**
     * Article doi
     */
    doi: PropTypes.string.isRequired,

    /**
     * metadata (key value pairs)
     * possible keys:
     *  - created
     *  - updated
     *  - xml
     *  - url
     *  - date_publication
     *  - date_publication_year
     *  - pubmed_publication_id
     *  - pmid
     */
    metadata: PropTypes.shape({
      pmid: PropTypes.number,
      date_publication_year: PropTypes.string,
    }).isRequired,

    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
  };

  render() {
    const {
      authors,
      doi,
      journal,
      className,
      metadata: { date_publication_year },
    } = this.props;

    return (
      <div className={className}>
        <ArticleProperty label="Authors" value={authors} />
        <div className="article-property">
          <ArticleProperty
            className="article-property-journal"
            label="Journal"
            value={`${journal} (${date_publication_year})`}
          />
          <ArticleProperty label="DOI" value={doi} />
        </div>
      </div>
    );
  }
}
