import { Field } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import * as yup from "yup";

import { isCurrentUserAdmin } from "modules/auth/selectors";
import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";
import {
  PROJECT_DETAILS_MODE_ADD,
  PROJECT_DETAILS_MODE_EDIT,
} from "modules/projectSettings/constants";
import {
  ONCOLOGY_PROJECT_TYPE,
  RARE_DISEASE_PROJECT_TYPE,
} from "modules/projects/constants";
import { getProjectTypesForChildProjects } from "modules/projects/selectors";

/**
 *
 * This field should be visible for admin and for rare disease project type
 * on 'Add Project' dialog and for admin and for rare disease and container(project type is null) project types
 * on 'Project Details project settings' tab
 */
const isPrivateAfVisible = (
  mode,
  projectTypeInternalName,
  isCurrentUserSuperAdmin
) => {
  if (mode === PROJECT_DETAILS_MODE_ADD) {
    return (
      projectTypeInternalName === RARE_DISEASE_PROJECT_TYPE &&
      isCurrentUserSuperAdmin
    );
  }
  return (
    isCurrentUserSuperAdmin && projectTypeInternalName !== ONCOLOGY_PROJECT_TYPE
  );
};

export const getAddValidationSchema = (
  isCurrentUserSuperAdmin,
  projectTypesForChildProjects
) =>
  yup.object({
    code: yup.string("").required("This field is required"),
    description: yup.string("").required("This field is required"),
    projectTypeInternalName: yup.string("").when(() => {
      if (
        projectTypesForChildProjects &&
        projectTypesForChildProjects.length > 1
      ) {
        return yup.string().required("This field is required");
      }
    }),
    privateAf: yup.boolean().when(["projectTypeInternalName"], {
      is: projectTypeInternalName =>
        isPrivateAfVisible(
          PROJECT_DETAILS_MODE_ADD,
          projectTypeInternalName,
          isCurrentUserSuperAdmin
        ),
      then: yup.boolean().required("This field is required"),
    }),
  });

export const getEditValidationSchema = (
  isCurrentUserSuperAdmin,
  projectTypeInternalName
) =>
  yup.object({
    code: yup.string("").required("This field is required"),
    description: yup.string("").required("This field is required"),
    privateAf: yup.boolean().when(() => {
      if (
        isPrivateAfVisible(
          PROJECT_DETAILS_MODE_EDIT,
          projectTypeInternalName,
          isCurrentUserSuperAdmin
        )
      ) {
        return yup.boolean().required("This field is required");
      }
    }),
  });

export const ProjectDetailsEditor = ({
  isCurrentUserSuperAdmin,
  projectTypesForChildProjects = [],
  mode = PROJECT_DETAILS_MODE_ADD,
  projectType,
}) => {
  const isProjectTypeInternalNameFieldVisible = useMemo(() => {
    if (mode === PROJECT_DETAILS_MODE_ADD) {
      return projectTypesForChildProjects.length > 1;
    }
    return projectType;
  }, [mode, projectType, projectTypesForChildProjects]);

  return (
    <div className="form-horizontal">
      <Field
        type="text"
        component={FormikFormField}
        label="Name"
        name="code"
        required
        narrow
      />
      <Field
        type="textarea"
        component={FormikFormField}
        name="description"
        label="Description"
        rows="5"
        required
        narrow
      />
      {isProjectTypeInternalNameFieldVisible && (
        <Field
          required
          type={mode === PROJECT_DETAILS_MODE_EDIT ? "text" : "dropdown"}
          label="Project type"
          name="projectTypeInternalName"
          component={FormikFormField}
          disabled={mode === PROJECT_DETAILS_MODE_EDIT}
          narrow
          data-testid="internalName"
          options={
            mode === PROJECT_DETAILS_MODE_EDIT
              ? null
              : projectTypesForChildProjects.reduce(
                  (acc, { internalName, name }) => {
                    acc[internalName] = name;
                    return acc;
                  },
                  {}
                )
          }
        />
      )}

      {isPrivateAfVisible(mode, projectType, isCurrentUserSuperAdmin) && (
        <Field
          required
          type="dropdown"
          label="Privacy"
          name="privateAf"
          component={FormikFormField}
          narrow
          options={{
            false: "Public project",
            true: "Private project (Patients not included in AF count)",
          }}
        />
      )}
    </div>
  );
};

ProjectDetailsEditor.displayName = "ProjectDetailsEditor";

ProjectDetailsEditor.propTypes = {
  mode: PropTypes.oneOf([PROJECT_DETAILS_MODE_ADD, PROJECT_DETAILS_MODE_EDIT]),
};

const mapStateToProps = state => ({
  isCurrentUserSuperAdmin: isCurrentUserAdmin(state),
  projectTypesForChildProjects: getProjectTypesForChildProjects(state),
});

export default connect(mapStateToProps, undefined)(ProjectDetailsEditor);
