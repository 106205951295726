import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Loading,
  Rating,
  VariantPathogenicity,
  CongenicaAILogo,
} from "pattern-library";
import { Table } from "pattern-library/elements/table7";

import { GraphForWeights } from "modules/ariadne/components/GraphForWeights";
import { ARIADNE_VALUES, ARIADNE_STRINGS } from "modules/ariadne/constants";
import Message from "modules/messages/components/Message";

import { fetchAriadneBreakdownData } from "../../actions";
import {
  getAriadneTransformedBreakdownData,
  isAriadneBreakdownDataLoading,
} from "../../selectors";

import styles from "./BreakdownOfAriadne.module.scss";

const { C_2_P, PREDICTED_PATHOGENICITY } = ARIADNE_VALUES;

const BreakdownOfAriadne = ({
  confidenceRating,
  contributionToPhenotype,
  predictionJustification,
  decisionProbabilities,
  predictedPathogenicityLevel,
  transcriptLabel,
}) => {
  const [about, setAbout] = useState(true);

  const renderAbout = () => {
    if (!about) {
      return (
        <button
          className={classnames("display-help", styles.aboutCaller)}
          type="button"
          onClick={() => setAbout(true)}
        >
          <i className="glyphicon glyphicon-info-sign has-help" />
        </button>
      );
    }

    const message = (
      <div className="flex">
        <CongenicaAILogo />
        <div>
          <strong>{ARIADNE_STRINGS.FILTER.INFORMATION_HEADER}</strong>
          <div>{ARIADNE_STRINGS.ARIADNE_DESC}</div>
        </div>
      </div>
    );
    return (
      <Message
        id="breakdown-of-ariadne"
        className={styles.about}
        closeMessage={() => setAbout(false)}
        level="secondary"
        message={message}
      />
    );
  };

  const renderContributionToPhenotype = () => {
    const hasSomePhenotypeContribution = contributionToPhenotype.some(
      ({ phenotypes }) => phenotypes && phenotypes.length
    );
    if (
      !hasSomePhenotypeContribution ||
      !contributionToPhenotype ||
      !contributionToPhenotype.length
    ) {
      return <p>This variant doesn&apos;t have a predicted contribution.</p>;
    }

    return contributionToPhenotype.map(({ extended, phenotypes, value }) => {
      if (!phenotypes || !phenotypes.length) {
        return null;
      }

      return (
        <Fragment key={value + String(phenotypes).slice(0, 20)}>
          <div>
            This variant has a predicted <strong>{value.toLowerCase()}</strong>{" "}
            contribution to the following {extended ? "extended" : ""} primary
            phenotypes:
          </div>
          <ul>
            {phenotypes.map(p => (
              <li key={p}>{p}</li>
            ))}
          </ul>
        </Fragment>
      );
    });
  };

  const renderDecisionProbabilities = () => {
    const props = {
      className: classnames(styles.table, styles.decProbTable),
      columns: [{ accessor: "key" }, { accessor: "value" }],
      data: Object.keys(decisionProbabilities).map(key => ({
        key,
        value: decisionProbabilities[key] + "%",
      })),
      getRowProps: (_, { row }) => ({
        className: classnames({
          [styles.decProbRow_current]:
            row.original.key === predictedPathogenicityLevel,
        }),
      }),
      TheadComponent: () => null,
    };

    return <Table {...props} />;
  };

  return (
    <div className={styles.component} data-testid="breakdown-of-ariadne">
      <div className={styles.section}>
        <h3>Congenica AI predictions:</h3>
        {renderAbout()}
      </div>

      <div className={styles.section}>
        <h4>Predicted variant pathogenicity:</h4>
        <p>
          The predicted pathogenicity with the highest probability
          {transcriptLabel && (
            <>
              {" "}
              (for transcript <strong>{transcriptLabel}</strong>)
            </>
          )}
          :
        </p>
        <p>
          <VariantPathogenicity
            level={predictedPathogenicityLevel.toLowerCase().replace(" ", "_")}
          />
        </p>
        <div>The confidence rating in this pathogenicity prediction is:</div>
        <Rating score={confidenceRating} showMaximum showScore />
      </div>
      <hr />

      <div className={styles.section}>
        <h4>Contribution to phenotypes:</h4>
        {renderContributionToPhenotype()}
      </div>
      <hr />

      <div className={styles.section}>
        <h4>Decision probabilities:</h4>
        <p className="text-muted">
          Predicted probability for each type of decision
        </p>
        {renderDecisionProbabilities()}
      </div>
      <hr />

      <div className={styles.section}>
        <h4>Prediction justification:</h4>
        <p className="text-muted">
          A breakdown of how different variant attributes contributed towards
          the predicted pathogenicity
        </p>
        <GraphForWeights data={predictionJustification} />
      </div>
    </div>
  );
};

BreakdownOfAriadne.propTypes = {
  confidenceRating: PropTypes.number.isRequired,
  contributionToPhenotype: PropTypes.arrayOf(
    PropTypes.shape({
      extended: PropTypes.bool,
      phenotypes: PropTypes.arrayOf(PropTypes.string),
      value: PropTypes.oneOf(Object.values(C_2_P)).isRequired,
    })
  ),
  predictionJustification: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })
  ),
  decisionProbabilities: PropTypes.shape(
    Object.values(PREDICTED_PATHOGENICITY).reduce((acc, key) => {
      acc[key] = PropTypes.number;
      return acc;
    }, {})
  ).isRequired,
  predictedPathogenicityLevel: PropTypes.oneOf(
    Object.values(PREDICTED_PATHOGENICITY)
  ).isRequired,
};

export const BreakdownOfAriadneContainer = React.memo(
  ({ containerId, patientId, patientSNVId, transcriptId, transcriptLabel }) => {
    const isLoading = useSelector(isAriadneBreakdownDataLoading(containerId));
    const data = useSelector(getAriadneTransformedBreakdownData(containerId));
    const dispatch = useDispatch();

    useEffect(() => {
      if (patientId && patientSNVId && transcriptId) {
        dispatch(
          fetchAriadneBreakdownData.start(
            containerId,
            patientId,
            patientSNVId,
            transcriptId
          )
        );
      }
    }, [dispatch, patientId, patientSNVId, transcriptId, containerId]);

    if (isLoading) return <Loading />;

    if (!data) return null;

    return <BreakdownOfAriadne {...data} transcriptLabel={transcriptLabel} />;
  }
);

export default BreakdownOfAriadne;
