import PropTypes from "prop-types";
import { isNil, path, prop } from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import { Breadcrumbs, Loading, Tabs } from "pattern-library";

import { readPatient } from "modules/patient/actions";
import * as patientSelectors from "modules/patient/selectors";
import { parseDecimalInt } from "modules/utils";

import getPatientTabset from "./Tabset/PatientTabset";
import { RouteTabContent, RouteTabHeader } from "./partials";

const getBreadcrumbs = (patient = {}) => {
  const { project = {}, projectId, reference } = patient;

  return [
    {
      name: "Projects",
      url: "/projects",
    },
    {
      name: project.code,
      url: `/projects/${projectId}`,
    },
    {
      name: reference,
    },
  ];
};

export const PatientLayout = ({
  patient = null,
  patientId,
  tabs,
  customIframe,
  readPatient,
}) => {
  useEffect(() => {
    readPatient(patientId);
  }, [patientId, readPatient]);

  if (isNil(prop("project", patient))) {
    return <Loading />;
  }

  const tabset = getPatientTabset({
    patientId,
    projectId: patient.projectId,
    tabs,
    customIframe,
  });

  const breadcrumbs = getBreadcrumbs(patient);

  return (
    <div className="patient-layout">
      <div key="patient-breadcrumbs" className="patient-layout-header">
        <Breadcrumbs
          className="patient-layout-breadcrumbs"
          crumbs={breadcrumbs}
        />
        <div className="patient-layout-status">
          Status: <strong>{patient.status}</strong>
        </div>
      </div>
      <Switch>
        <Route exact path={`/patient/${patientId}`}>
          <Redirect to={`/patient/${patientId}/overview`} />
        </Route>
        <Tabs
          tabComponent={RouteTabHeader}
          contentComponent={RouteTabContent}
          tabs={tabset}
          baseUrl={`/patient/${patientId}`}
          routePath="/patient/:patientId"
        />
      </Switch>
    </div>
  );
};

PatientLayout.propTypes = {
  patientId: PropTypes.number.isRequired,
  patient: PropTypes.oneOfType([
    PropTypes.shape({
      reference: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      patientId: PropTypes.number.isRequired,
      projectId: PropTypes.number.isRequired,
      project: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
    }),
    PropTypes.bool,
  ]).isRequired,
  tabs: PropTypes.shape({}),
  customIframe: PropTypes.shape({}),
  readPatient: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const idParam = path(["match", "params", "patientId"], props);
  const patientId = parseDecimalInt(idParam);
  const patient = patientSelectors.getSinglePatient(state, patientId);
  return {
    tabs: patientSelectors.getTabs(state),
    customIframe: patientSelectors.getCustomIframeConfig(state),
    patientId,
    patient: patient && patient.patientId ? patient : false,
  };
};

const mapDispatchToProps = {
  readPatient,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PatientLayout));
