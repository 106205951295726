import PropTypes from "prop-types";

export const SNVPresetProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  attributes: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
});

export const FilterPresetOptionProp = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
});
