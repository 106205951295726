import { createSelector } from "@reduxjs/toolkit";

import legacyApi from "api/legacy-api";

const { useGetProjectGenePanelsQuery } = legacyApi;

export const combineProjectGenePanels = createSelector(
  result => result.data?.genePanels,
  result => result.data?.inheritedGenePanels,
  (genePanels = [], inheritedGenePanels = []) => [
    ...genePanels,
    ...inheritedGenePanels,
  ]
);

/**
 * Retrieves gene panels from legacy API endpoint and combined project and inherited panels together
 */
const useProjectGenePanels = ({
  projectId,
}: ProjectDependant & { archived?: boolean }) => {
  const response = useGetProjectGenePanelsQuery(
    {
      projectId: Number(projectId),
    },
    {
      selectFromResult: result => ({
        ...result,
        genePanels: combineProjectGenePanels(result),
      }),
    }
  );

  return response;
};

export default useProjectGenePanels;
