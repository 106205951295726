import { takeLatest } from "redux-saga/effects";

import { CATALYST_BASE_URL } from "modules/utils/baseUrls";

import createSaga from "../../utils/createSaga";

import * as actions from "./actions";
import * as constants from "./constants";

export function* init() {
  yield takeLatest(constants.READ_VARIANT_OLD, fetchPatientVariant);
}

export const fetchPatientVariant = createSaga({
  urlTemplate: `${CATALYST_BASE_URL}/patient/:patientId/variant/:variantId`,
  modelName: "Variant",
  nextActionCreator: actions.createOrmVariantOld,
});
