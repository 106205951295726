import { useCallback, useMemo } from "react";

import { ADD_METADATA_CATEGORY, ADD_METADATA_FIELD, FIELD } from "../constants";

import {
  getCategoriesColumns,
  getFieldsColumns,
  getInheritedCategoriesColumns,
  getInheritedFieldsColumns,
} from "./columns";

export const useMetadataActions = (
  type,
  resetMetadataInfo,
  setMetadataInfo,
  addInheritedMetadata,
  addAllInheritedMetadataFields
) => ({
  closedModal: useCallback(() => resetMetadataInfo(), [resetMetadataInfo]),
  onAddPatientMetadata: useCallback(
    () =>
      setMetadataInfo(
        type === FIELD ? { fieldType: "Text" } : {},
        type === FIELD ? ADD_METADATA_FIELD : ADD_METADATA_CATEGORY
      ),
    [type, setMetadataInfo]
  ),
  onAddAllInheritedMetadata: useCallback(
    () => addAllInheritedMetadataFields(type),
    [type, addAllInheritedMetadataFields]
  ),
  columns: useMemo(
    () =>
      type === FIELD
        ? getFieldsColumns(setMetadataInfo)
        : getCategoriesColumns(setMetadataInfo),
    [type, setMetadataInfo]
  ),
  inheritedColumns: useMemo(
    () =>
      type === FIELD
        ? getInheritedFieldsColumns(addInheritedMetadata)
        : getInheritedCategoriesColumns(addInheritedMetadata),
    [type, addInheritedMetadata]
  ),
});
