import classNames from "classnames";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

import Input from "./Input";

export default class Checkbox extends PureComponent {
  static displayName = "Checkbox";

  static propTypes = {
    /**
     * Class to apply to the checkbox input
     */
    className: PropTypes.string,
    /**
     * Class to apply to the container div
     */
    containerClass: PropTypes.string,
    /**
     * Name for the input
     */
    name: PropTypes.string.isRequired,
    /**
     * Text or react element for the label
     */
    label: PropTypes.node,
    /**
     * Class to apply to the label
     */
    labelClass: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    containerClass: "",
    label: "",
    labelClass: "",
  };

  render() {
    const {
      label,
      labelClass,
      containerClass,
      inputWrapper: InputWrapper = null,
      wrapperArgs = {},
      ...otherProps
    } = this.props;

    const input = <Input type="checkbox" {...otherProps} />;

    return (
      <div className={classNames("checkbox", containerClass)}>
        <label className={labelClass}>
          {isNil(InputWrapper) ? (
            input
          ) : (
            <InputWrapper {...wrapperArgs}>{input}</InputWrapper>
          )}{" "}
          {label}
        </label>
      </div>
    );
  }
}
