import { camelizeKeys } from "humps";
import { compose } from "ramda";
import {
  call,
  put,
  all,
  takeEvery,
  takeLatest,
  select,
} from "redux-saga/effects";

import { convertNulls, convertIds } from "../utils";

import * as constants from "./constants";
import { getORMSession } from "./selectors";

export function* apiFetch(action) {
  const {
    type,
    payload = { query: {} },
    meta: {
      modelName,
      modelId,
      relatedModelName = false,
      relatedModelId = false,
    },
  } = action;
  // verb = CREATE READ UPDATE DELETE LIST
  const VERB = type.split("/")[type.split("/").length - 1];
  let RelatedModel = false;

  const session = yield select(getORMSession);

  const ModelClass = session.schema.registry.find(item => {
    if (item.modelName === modelName) {
      return item;
    }
    return undefined;
  });

  if (relatedModelName) {
    RelatedModel = session.schema.registry.find(
      item => item.modelName === relatedModelName
    );
  }

  if (!ModelClass) {
    throw new Error("Could not find the model class in the registry");
  }

  // TODO: remove the reference to the payload here
  const id = modelId || payload.id;

  const apiOption = ModelClass.getAPIOptions(
    VERB,
    id,
    RelatedModel,
    relatedModelId
  );

  const { params, ...fetchParams } = apiOption;
  let { url } = apiOption;

  if (payload.query && Object.entries(payload.query).length) {
    const queries = Object.entries(payload.query);

    const builtQueries = queries.map(queryItem => {
      const key = queryItem[0];
      let value = queryItem[1];

      if (typeof value === "object") {
        value = encodeURIComponent(JSON.stringify(value));
      }

      return `${key}=${value}`;
    });

    url = `${url}?${builtQueries.join("&")}`;
  }

  const response = yield call(fetch, url, {
    ...params,
    ...fetchParams,
  });

  const modelNameUppercase = ModelClass.modelName.toUpperCase();

  if (response.ok) {
    const body = yield call([response, response.json]);
    const normaliseBody = compose(convertNulls, convertIds, camelizeKeys);

    if (RelatedModel) {
      return yield put({
        type: `API_${VERB}_${modelNameUppercase}_${RelatedModel.modelName.toUpperCase()}_SUCCEEDED`,
        payload: normaliseBody(body),
      });
    }
    return yield put({
      type: `API_${VERB}_${modelNameUppercase}_SUCCEEDED`,
      payload: normaliseBody(body),
    });
  }
  return yield put({
    type: `API_${VERB}_${modelNameUppercase}_FAILED`,
    payload,
    meta: { error: response.error },
  });
}

export default function* root() {
  yield all([
    takeLatest(constants.API_LATEST_READ, apiFetch),
    takeLatest(constants.API_LATEST_LIST, apiFetch),

    takeEvery(constants.API_CREATE, apiFetch),
    takeEvery(constants.API_READ, apiFetch),
    takeEvery(constants.API_UPDATE, apiFetch),
    takeEvery(constants.API_DELETE, apiFetch),
    takeEvery(constants.API_LIST, apiFetch),
  ]);
}
