import React, { useCallback } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { filterProcessor } from "modules/utils/table";

import {
  addAllInheritedMetadata,
  addInheritedMetadata,
  resetMetadataInfo,
  setMetadataInfo,
  updateMetadata,
} from "../actions";
import {
  ADD_METADATA_FIELD,
  DELETE_METADATA_FIELD,
  EDIT_METADATA_FIELD,
  FIELD,
} from "../constants";
import {
  getFields,
  getInheritedFields,
  getSelectedMetadataMode,
} from "../selectors";

import AddMetadataButton from "./AddMetadataButton";
import { MetadataFieldModal } from "./MetadataModal";
import { MetadataTable } from "./MetadataTable";
import { useMetadataActions } from "./useMetadataActions";

const getPropertyMap = ({ inherited }) => ({
  visibleInList: value => (value ? "Yes" : "No"),
  required: value => (value ? "Yes" : "No"),
  position: value => ++value,
  projectCode: value => (inherited ? value : "-"),
});

const includedInheritedFieldsProperties = [
  "fieldName",
  "fieldCode",
  "projectCode",
  "visibleInList",
  "required",
  "fieldType",
  "categoryName",
];
const includedFieldsProperties = [
  "position",
  ...includedInheritedFieldsProperties,
];

export const inheritedFieldsFilter = filterProcessor(
  includedInheritedFieldsProperties,
  getPropertyMap
);
export const fieldsFilter = filterProcessor(
  includedFieldsProperties,
  getPropertyMap
);

const Fields = ({
  mode,
  resetMetadataInfo,
  setMetadataInfo,
  addInheritedMetadata,
  addAllInheritedMetadata,
  updateMetadata,
  fields,
  inheritedFields,
}) => {
  const {
    closedModal,
    onAddPatientMetadata,
    onAddAllInheritedMetadata,
    columns,
    inheritedColumns,
  } = useMetadataActions(
    FIELD,
    resetMetadataInfo,
    setMetadataInfo,
    addInheritedMetadata,
    addAllInheritedMetadata
  );

  const showModal = [
    ADD_METADATA_FIELD,
    EDIT_METADATA_FIELD,
    DELETE_METADATA_FIELD,
  ].includes(mode);

  const onDropRow = useCallback(
    (row, newIndex) => {
      updateMetadata(
        FIELD,
        { ...row, position: newIndex },
        "Metadata field position saved"
      );
    },
    [updateMetadata]
  );

  return (
    <div>
      <MetadataTable
        filter={fieldsFilter}
        columns={columns}
        title="Patient metadata fields"
        items={fields}
        draggable
        onDropRow={onDropRow}
      />
      <AddMetadataButton
        content="Add patient metadata field"
        action={onAddPatientMetadata}
      />

      <MetadataTable
        filter={inheritedFieldsFilter}
        columns={inheritedColumns}
        title="Patient metadata fields in parent projects"
        items={inheritedFields}
      />
      {inheritedFields.length > 0 && (
        <AddMetadataButton
          content="Add all fields to project"
          action={onAddAllInheritedMetadata}
        />
      )}

      {showModal && <MetadataFieldModal show={showModal} close={closedModal} />}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  mode: getSelectedMetadataMode,
  fields: getFields,
  inheritedFields: getInheritedFields,
});

const mapDispatchToProps = {
  resetMetadataInfo,
  setMetadataInfo,
  addInheritedMetadata,
  addAllInheritedMetadata,
  updateMetadata,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fields);
