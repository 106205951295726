import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import { isNil, isEmpty } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as yup from "yup";

import { Accordion, Actions, ConfirmationModal } from "pattern-library";

import { readProject } from "modules/project/actions";
import { sanitize } from "modules/utils/objects";

import { checkProjectHasInheritors, fetchProjectSettings } from "../actions";
import * as constants from "../constants";
import { projectHasInheritors } from "../selectors";
import { submitAndResetFormik, withProjectSettingsWrapper } from "../util";

import InheritanceToggle from "./InheritanceToggle";
import {
  VisibilityLevels,
  Miscellaneous,
  Coverage,
  Decisions,
} from "./projectDefaults/index";

export const validationSchema = yup.object({
  isInherited: yup.boolean(),
  patientVisibility: yup.object({ enabled: yup.boolean() }),
  visibilityLevels: yup
    .object()
    .when(["isInherited", "patientVisibility.enabled"], {
      is: (isInherited, visibilityEnabled) => !isInherited && visibilityEnabled,
      then: yup.object({
        1: yup.object({
          name: yup
            .string()
            .required("This field is required")
            .max(100, "Must be 100 characters or less"),
          description: yup.string().required("This field is required"),
        }),
        2: yup.object({
          name: yup
            .string()
            .required("This field is required")
            .max(100, "Must be 100 characters or less"),
          description: yup.string().required("This field is required"),
        }),
      }),
    })
    .when(["isInherited", "patientVisibility.enabled"], {
      is: (isInherited, visibilityEnabled) =>
        !isInherited && !visibilityEnabled,
      then: yup.object({
        1: yup.object({
          name: yup.string("").max(100, "Must be 100 characters or less"),
        }),
        2: yup.object({
          name: yup.string("").max(100, "Must be 100 characters or less"),
        }),
      }),
    }),
  coverageStats: yup.object().when("isInherited", {
    is: false,
    then: yup.object({
      bufferLength: yup
        .number()
        .nullable(true)
        .transform(v => (v === "" || isNaN(v) ? null : v))
        .min(0, "Must be at least 0")
        .required("This field is required"),
    }),
  }),
  maxAfFilter: yup.object().when("isInherited", {
    is: false,
    then: yup.object({
      loadLimit: yup
        .number()
        .nullable(true)
        .transform(v => (v === "" || isNaN(v) ? null : v))
        .min(0, "Must be at least 0")
        .max(1, "Must be no more 1"),
    }),
  }),
  geneCoverage: yup.object().when("isInherited", {
    is: false,
    then: yup.object({
      defaultDepth: yup
        .number()
        .nullable(true)
        .transform(v => (v === "" || isNaN(v) ? null : v))
        .min(2, "Must be at least 2")
        .required("This field is required"),
    }),
  }),
});

export class ProjectDefaults extends PureComponent {
  static displayName = "ProjectDefaults";

  static propTypes = {
    /**
     * Submit handler. This should be passed to <form onSubmit={props.handleSubmit}>...</form>
     * see https://jaredpalmer.com/formik/docs/api/formik#handlesubmit-e-reactformevent-htmlformevent-void
     */
    handleSubmit: PropTypes.func,
    /**
     * Trigger a form submission. The promise will be rejected if form is invalid.
     * see https://jaredpalmer.com/formik/docs/api/formik#handlesubmit-e-reactformevent-htmlformevent-void
     */
    submitForm: PropTypes.func,
    /**
     *  Returns true if values are not deeply equal from initial values, false otherwise.
     *  dirty is a readonly computed property and should not be mutated directly.
     *  see https://jaredpalmer.com/formik/docs/api/formik#dirty-boolean
     */
    dirty: PropTypes.bool,
    /**
     * Submitting state of the form. Returns true if submission is in progress and false otherwise.
     * IMPORTANT: Formik will set this to true as soon as submission is attempted.
     * https://jaredpalmer.com/formik/docs/api/formik#issubmitting-boolean
     */
    submitting: PropTypes.bool,
    /**
     * values of a form
     */
    values: PropTypes.shape({
      isInherited: PropTypes.bool,
      geneCoverage: PropTypes.shape({
        defaultDepth: PropTypes.number,
        locked: PropTypes.bool,
        coverage: PropTypes.arrayOf(PropTypes.number),
      }),
      visibilityLevels: PropTypes.shape({
        1: PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        }),
        2: PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
        }),
      }),
      patientVisibility: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      forceDeleteProjects: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      doubleUserCheck: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
      maxAfFilter: PropTypes.shape({
        loadLimit: PropTypes.number,
      }),
    }),
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { confirmationVisible: false };
  }

  componentDidMount() {
    const { projectId, getOrReloadCurrentProject, checkProjectHasInheritors } =
      this.props;
    getOrReloadCurrentProject(projectId, true);
    checkProjectHasInheritors(projectId);
  }

  componentDidUpdate(prevProps) {
    const { submitError: wasSubmitError } = prevProps.status || {};
    const { status: { submitError: isSubmitError } = {} } = this.props;

    if (isSubmitError && !wasSubmitError) {
      this.revertInheritanceOnCancel();
    }
  }

  closeConfirmation() {
    this.setState({
      confirmationVisible: false,
    });
  }

  showConfirmation() {
    this.setState({
      confirmationVisible: true,
    });
  }

  confirmSave() {
    const { submitForm } = this.props;
    submitForm();
    this.closeConfirmation();
  }

  cancelSave() {
    this.revertInheritanceOnCancel();
    this.closeConfirmation();
  }

  revertInheritanceOnCancel() {
    const {
      values: { isInherited },
      initialValues: { isInherited: initialInherited },
      setFieldValue,
    } = this.props;

    if (isInherited !== initialInherited) {
      setFieldValue("isInherited", initialInherited);
    }
  }

  submitWithConfirmation(checkValid = true) {
    const { projectHasInheritors, submitForm, isValid } = this.props;
    if (projectHasInheritors && (!checkValid || isValid)) {
      this.showConfirmation();
    } else {
      submitForm();
    }
  }

  switchInheritance() {
    const {
      values: { isInherited },
      submitForm,
    } = this.props;
    if (isInherited) {
      this.submitWithConfirmation(false);
    } else {
      submitForm();
    }
  }

  render() {
    const {
      values: { isInherited, geneCoverage: { coverage = [] } = {} },
      handleSubmit,
      dirty,
      submitting,
      projectId,
      errors,
      touched,
    } = this.props;

    const { confirmationVisible } = this.state;

    const touchedErrors = sanitize(errors, touched);

    return (
      <>
        <form onSubmit={handleSubmit} className="form-horizontal clearfix">
          <InheritanceToggle
            submitForm={this.switchInheritance}
            projectId={projectId}
          />
          <div className="row">
            <Accordion
              className="col-md-6"
              data={[
                {
                  sectionName: "Coverage",
                  content: (
                    <Coverage isInherited={isInherited} coverage={coverage} />
                  ),
                  showError:
                    touchedErrors &&
                    (!isNil(touchedErrors.coverageStats) ||
                      !isNil(touchedErrors.geneCoverage)),
                },
                {
                  sectionName: "Decisions",
                  content: <Decisions isInherited={isInherited} />,
                },
              ]}
            />
            <Accordion
              className="col-md-6"
              data={[
                {
                  sectionName: "Visibility Levels",
                  content: <VisibilityLevels isInherited={isInherited} />,
                  showError:
                    touchedErrors && !isNil(touchedErrors.visibilityLevels),
                },
                {
                  sectionName: "Miscellaneous",
                  content: <Miscellaneous isInherited={isInherited} />,
                  showError: touchedErrors && !isNil(touchedErrors.maxAfFilter),
                },
              ]}
            />
          </div>
        </form>
        <ConfirmationModal
          confirmationText="This will affect all child projects that have inherited settings."
          showConfirmationModal={confirmationVisible}
          closeText="Cancel"
          confirmText="Ok"
          yesClickHandler={this.confirmSave}
          closeClickHandler={this.cancelSave}
        />
        <Actions
          sticky
          actions={[
            {
              label: submitting ? "Submitting" : "Save",
              disabled: isInherited || !dirty || submitting,
              id: "save-project-defaults-btn",
              className: classNames("btn", {
                "btn-primary": !submitting,
                "btn-info": submitting,
              }),
              action: this.submitWithConfirmation,
            },
          ]}
        />
      </>
    );
  }
}

export const FormikForm = withFormik({
  validationSchema,
  mapPropsToValues: ({
    initialValues: {
      visibilityLevels: { 1: a = {}, 2: b = {} } = {},
      ...values
    },
  } = {}) => ({
    visibilityLevels: {
      1: { name: "", description: "", ...a },
      2: { name: "", description: "", ...b },
    },
    ...values,
  }),
  handleSubmit: (values, formikBag) => {
    submitAndResetFormik(
      {
        ...values,
        // Allow to remove visibility levels SAP-22503
        visibilityLevels: Object.keys(values.visibilityLevels).reduce(
          (acc, key) => {
            const level = values.visibilityLevels[key];
            if (!isEmpty(level.name) || !isEmpty(level.description)) {
              acc[key] = level;
            }
            return acc;
          },
          {}
        ),
      },
      {
        ...formikBag,
        resetForm: ({
          values: {
            visibilityLevels: { 1: a = {}, 2: b = {} } = {},
            ...values
          },
        }) => {
          formikBag.resetForm({
            values: {
              visibilityLevels: {
                1: { name: "", description: "", ...a },
                2: { name: "", description: "", ...b },
              },
              ...values,
            },
          });
        },
      }
    );
  },
  displayName: constants.PROJECT_DEFAULTS_FORM,
})(ProjectDefaults);

const WithProjectSettingsWrapper = withProjectSettingsWrapper({
  path: "defaults",
  entityType: "default_settings",
  component: FormikForm,
});

const mapStateToProps = state => ({
  projectHasInheritors: projectHasInheritors(
    state,
    constants.SETTINGS_TYPES.DEFAULTS
  ),
});

const mapDispatchToProps = {
  onSuccessUpdate: fetchProjectSettings.success,
  checkProjectHasInheritors,
  getOrReloadCurrentProject: readProject,
};

const ConnectedInitialisationWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithProjectSettingsWrapper);

export default ConnectedInitialisationWrapper;
