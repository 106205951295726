import PropTypes from "prop-types";
import React, { memo } from "react";

import ReferencePanelsTableHeader from "./ReferencePanelsTableHeader";
import ReferencePanelsTableRow from "./ReferencePanelsTableRow";

export const ReferencePanelTable = memo(
  ({
    references,
    selectedRowNumber,
    disabled = false,
    remove: removeRowHandler,
  }) => (
    <>
      <table className="table table-bordered table-striped table-condensed ref-panels">
        <ReferencePanelsTableHeader />
        <tbody>
          {references.map((reference, index) => {
            const key = `reference-panel-${index}`;
            return (
              <ReferencePanelsTableRow
                key={key}
                index={index}
                sampleIndex={selectedRowNumber}
                disabled={disabled}
                removeRowHandler={removeRowHandler}
              />
            );
          })}
        </tbody>
      </table>
      {!references.length && (
        <div className="empty-table-label">
          Manually enter CNV sample data or drag and drop CSV file onto the
          table
        </div>
      )}
    </>
  )
);

ReferencePanelTable.propTypes = {
  /**
   * set of reference patients
   */
  references: PropTypes.arrayOf(
    PropTypes.shape({
      // https://github.com/facebook/react/issues/13752#issuecomment-524264893
      // the html standard requires empty fields (including number types) to set values = ''
      // so despite projectId being a number we have to deal with strings occasionally
      projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ).isRequired,
  /**
   * true if editing IR fields is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Index of sample
   */
  selectedRowNumber: PropTypes.number.isRequired,
};
