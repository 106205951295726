import { createAction } from "../utils";
import { asyncActionCreatorGenerator } from "../utils/async";

import {
  OtherPatientsSnvCounts,
  OtherPatientsSnvCountsRequest,
  SequenceVariantSummary,
  SummaryRequestParameters,
  SummaryActionMeta,
} from "./types";

export const actionType = Object.freeze({
  FETCH_OTHER_PATIENTS_SNV_COUNTS: "FETCH_OTHER_PATIENTS_SNV_COUNTS",
  GET_OR_RELOAD_SEQUENCE_VARIANT_SUMMARY:
    "GET_OR_RELOAD_SEQUENCE_VARIANT_SUMMARY",
  FETCH_SEQUENCE_VARIANT_SUMMARY: "FETCH_SEQUENCE_VARIANT_SUMMARY",
});

export const getOrReloadSequenceVariantSummary = (
  payload: SummaryRequestParameters,
  meta: SummaryActionMeta
): FSA<
  typeof actionType.GET_OR_RELOAD_SEQUENCE_VARIANT_SUMMARY,
  SummaryRequestParameters,
  SummaryActionMeta
> =>
  createAction(
    actionType.GET_OR_RELOAD_SEQUENCE_VARIANT_SUMMARY,
    payload,
    meta
  );

export const fetchSequenceVariantSummary: AsyncActionCreatorEx =
  asyncActionCreatorGenerator<
    SummaryRequestParameters,
    SequenceVariantSummary,
    SummaryActionMeta
  >(actionType.FETCH_SEQUENCE_VARIANT_SUMMARY);

export const fetchOtherPatientsSnvCounts: AsyncActionCreatorEx =
  asyncActionCreatorGenerator<
    OtherPatientsSnvCountsRequest,
    OtherPatientsSnvCounts,
    SummaryActionMeta
  >(actionType.FETCH_OTHER_PATIENTS_SNV_COUNTS);

export type SequenceVariantsAction =
  | ReturnType<typeof getOrReloadSequenceVariantSummary>
  | ReturnType<typeof fetchSequenceVariantSummary.start>
  | ReturnType<typeof fetchSequenceVariantSummary.success>
  | ReturnType<typeof fetchSequenceVariantSummary.failure>
  | ReturnType<typeof fetchOtherPatientsSnvCounts.start>
  | ReturnType<typeof fetchOtherPatientsSnvCounts.success>
  | ReturnType<typeof fetchOtherPatientsSnvCounts.failure>;
