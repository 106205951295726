import Genoverse from "genoverse";

import { Colors } from "../utils";

export const Controller = {
  createMenu(feature, menuItems, menuLinks) {
    const createLink = (urlGenerator, title) =>
      urlGenerator ? `<a href="${urlGenerator(feature)}">${title}</a>` : title;

    return menuItems
      .filter(({ value }) => value !== undefined)
      .map(({ label, key, value }) => ({
        [label]: createLink(menuLinks[key], value),
      }))
      .reduce(
        (menu, element) => ({
          ...menu,
          ...element,
        }),
        {}
      );
  },

  populateMenu(feature) {
    const { start, end, label, mean_ratio, inheritance, patient_id, variant } =
      feature;
    const { menuLinks } = this.track;

    // In legacy SV we display length from API
    // Leaving to match old track display
    const location = `${this.browser.chr} : ${start} - ${end}`;
    const length = `${Math.abs(variant.length)} bp`;
    const menuElements = [
      {
        key: "title",
        label: "title",
        value: `(${location}) ${label}`,
      },
      {
        key: "patient",
        label: "Patient",
        value: patient_id,
      },
      {
        key: "length",
        label: "Length",
        value: length,
      },
      {
        key: "location",
        label: "Location",
        value: location,
      },
      {
        key: "mean_ratio",
        label: "Mean ratio",
        value: mean_ratio,
      },
      {
        key: "inheritance",
        label: "Inheritance",
        value: inheritance,
      },
    ];

    return this.createMenu(feature, menuElements, menuLinks);
  },
};

export const Model = {
  insertFeature(feature) {
    if (this.featuresById[feature.id]) {
      return;
    }

    const { start, end, subtype } = feature.variant;
    const { color, labelColor } =
      (this.track.colors || []).find(color => color.predicate(feature)) ||
      this.track.defaultColors;

    this.base({
      ...feature,
      start,
      end,
      label: subtype,
      legend: subtype,
      color,
      labelColor,
    });
  },
};

export const Config = {
  id: "Congenica.StructuralVariants",
  namespace: "Congenica.StructuralVariants",
  name: "Structural variants",
  info: "The default structural variants track",
  category: "Structural variants",
  tags: ["Structural variants"],
  forceMenu: true,
  height: 120,
  featureHeight: 10,
  xhrFields: {
    withCredentials: true,
  },
  labels: "overlay",
  legend: true,
  bump: true,
  repeatLabels: false,
  featureMargin: {
    top: 20,
    right: 0,
    bottom: 0,
    left: 0,
  },
  menuLinks: {},
  defaultColors: {
    color: Colors.BLUE,
    labelColor: Colors.WHITE,
  },
  model: Model,
  controller: Controller,
};

export const Track = Genoverse.Track.extend({
  ...Config,
  model: Genoverse.Track.Model.extend(Config.model),
  controller: Genoverse.Track.Controller.extend(Config.controller),
});
