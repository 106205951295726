import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

import {
  ARIADNE_FIELD_TO_TITLE_MAP,
  ARIADNE_FIELD_TO_SHORT_TITLE_MAP,
} from "modules/ariadne/constants";

import styles from "./GraphForWeights.module.scss";
import { renderRechartsCellsWithCalculatedColour } from "./utils";

const TEST_ID = "ariadne-graph-for-weights";
const BAR_HEIGHT = 34;
// Right margin should be 10, otherwise text will be lightly truncated 🤷‍
const MARGIN_RIGHT = 10;
// Left margin should be 2, otherwise left bar's stroke will be truncated.
const MARGIN_LEFT = 2;

export const GraphForWeights = ({
  className,
  data,
  height,
  isAnimationActive = true,
  width = 1000,
  yLabelsBlockWidth = 250,
}) => {
  if (!height) {
    height = data.length * BAR_HEIGHT + 22; // 22 - size of X axis with labels
  }
  const graphWidth = width - yLabelsBlockWidth - MARGIN_RIGHT - MARGIN_LEFT;

  const transformedData = useMemo(
    () =>
      data
        .slice()
        .sort((a, b) => b.weight - a.weight)
        .map(row => {
          const { name } = row;
          const tooltipLabel = ARIADNE_FIELD_TO_TITLE_MAP[name] || name;
          const yLabel =
            ARIADNE_FIELD_TO_SHORT_TITLE_MAP[name] ||
            ARIADNE_FIELD_TO_TITLE_MAP[name] ||
            name;
          return {
            ...row,
            tooltipLabel,
            yLabel,
          };
        }),
    [data]
  );

  const renderTooltip = ({ payload }) => {
    if (!payload[0]) {
      return null;
    }
    const { payload: data } = payload[0];

    return (
      <div className={styles.container__tooltip}>
        <table>
          <tbody>
            <tr>
              <th>Attribute:</th>
              <td>{data.tooltipLabel}</td>
            </tr>
            <tr>
              <th>Value:</th>
              <td>{data.value}</td>
            </tr>
            <tr>
              <th>Probability:</th>
              <td>{data.weight}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div
      className={classnames(styles.container, className)}
      data-testid={TEST_ID}
    >
      <div
        className={styles.container__topBorder}
        style={{ marginLeft: `${MARGIN_LEFT}px`, width: `${graphWidth}px` }}
      />

      <BarChart
        barCategoryGap="13%"
        data={transformedData}
        height={height}
        layout="vertical"
        margin={{ top: 0, right: MARGIN_RIGHT, bottom: 0, left: MARGIN_LEFT }}
        width={width}
      >
        <CartesianGrid horizontal={false} />
        <XAxis
          domain={[0, 1]}
          ticks={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
          type="number"
        />
        <YAxis
          dataKey="yLabel"
          orientation="right"
          type="category"
          width={yLabelsBlockWidth}
        />
        <Bar dataKey="weight" isAnimationActive={isAnimationActive}>
          {renderRechartsCellsWithCalculatedColour({
            data: transformedData,
            idKey: "name",
            dataKey: "weight",
          })}
        </Bar>
        <Tooltip
          content={renderTooltip}
          cursor={{ fill: "rgba(153,153,153,.2)" }}
          isAnimationActive={false}
        />
      </BarChart>

      <div
        className={styles.container__caption}
        style={{ width: `${graphWidth}px` }}
      >
        Attribute probability towards Pathogenicity
      </div>
    </div>
  );
};

GraphForWeights.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  height: PropTypes.number,
  isAnimationActive: PropTypes.bool,
  width: PropTypes.number,
  yLabelsBlockWidth: PropTypes.number,
};
