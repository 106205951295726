import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  BarChart,
  ErrorBar,
} from "recharts";

export default class BarGraph extends PureComponent {
  static displayName = "BarGraph";

  static propTypes = {
    /**
     * Parameter defining whether to show animation
     */
    isAnimationActive: PropTypes.bool,
    /**
     * Parameter defining whether to show tooltips
     */
    showTooltip: PropTypes.bool,
    /**
     * Parameter defining whether to show legend
     */
    showLegend: PropTypes.bool,
    /**
     * Parameter to determine the layout of bars in the chart. Layout will be vertical if sideways === true.
     * Otherwise - horizontal
     */
    sideways: PropTypes.bool,
    /**
     * Data to render chart
     */
    data: PropTypes.shape({
      bars: PropTypes.arrayOf(
        PropTypes.shape({
          dataKey: PropTypes.string.isRequired,
          color: PropTypes.string,
        })
      ),
      values: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    /**
     * Class to apply to the chart component
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    isAnimationActive: false,
    className: "",
    width: 800,
    height: 600,
    showTooltip: true,
    showLegend: true,
    sideways: false,
  };

  render() {
    const {
      data: { bars, values },
      width,
      height,
      className,
      showTooltip,
      showLegend,
      sideways,
      isAnimationActive,
      ...otherProps
    } = this.props;

    return (
      <BarChart
        className={classNames(className, "graph-chart")}
        width={width}
        height={height}
        data={values}
        layout={sideways === true ? "vertical" : "horizontal"}
        {...otherProps}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {sideways === false && <XAxis dataKey="name" />}
        {sideways === false && <YAxis />}

        {sideways === true && <XAxis type="number" />}
        {sideways === true && <YAxis dataKey="name" type="category" />}

        {showTooltip === true && <Tooltip />}
        {showLegend === true && <Legend />}
        {bars.map(bar => (
          <Bar
            isAnimationActive={isAnimationActive}
            key={bar.dataKey}
            dataKey={bar.dataKey}
            fill={bar.color}
            {...otherProps}
          >
            <ErrorBar
              dataKey="error"
              width={4}
              strokeWidth={2}
              stroke={bar.errorColor}
              direction={sideways === true ? "x" : "y"}
              {...otherProps}
            />
          </Bar>
        ))}
      </BarChart>
    );
  }
}
