import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import "./variant-pathogenicity.scss";

export const VariantPathogenicity = ({
  level = null,
  small = false,
  className,
}) => {
  if (!level) {
    return null;
  }

  const snakeCasedLevel = level.toLowerCase().replace(/\s/g, "_");

  const text = level
    .split("_")
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(" ");

  const classes = classnames(
    "variant-pathogenicity-badge",
    `variant-pathogenicity-badge--${snakeCasedLevel}`,
    small && "variant-pathogenicity-badge--size-small",
    className
  );

  return <span className={classes}>{text}</span>;
};

VariantPathogenicity.propTypes = {
  level: PropTypes.oneOf([
    "pathogenic",
    "likely_pathogenic",
    "likely_benign",
    "benign",

    "Pathogenic",
    "Likely pathogenic",
    "Likely benign",
    "Benign",
  ]),
  small: PropTypes.bool,
  className: PropTypes.string,
};
