//@flow

import { isEmpty, path } from "ramda";
import { call, put } from "redux-saga/effects";

import { getFilterPresets } from "modules/api/filterPresets";

import {
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";
import { error, errors } from "../messages/actions";
import { SNV } from "../projectSettings/components/filterPresets/constants";
import type { FetchDataResponse } from "../utils/fetchData";

import { setSNVPresetsInfo } from "./actions";
import type { SNVPresetsPayload } from "./flow-types";

const noPresetsError = "No presets found";

export function* init(): Generator<any, void, any> {}

export function* loadSNVPresets(
  projectId: number
): Generator<any, ?FetchDataResponse<SNVPresetsPayload>, any> {
  yield put(setSNVPresetsInfo({ requestStatus: IN_PROGRESS_STATUS }));

  try {
    const response: FetchDataResponse<SNVPresetsPayload> = yield call(
      getFilterPresets,
      {
        projectId,
        variantType: SNV,
        active: true,
      }
    );

    const presets = path(["payload", "data"], response);
    const { errors: errorMessages = [] } = response;

    if (response.ok && !isEmpty(presets)) {
      yield put(setSNVPresetsInfo({ presets, requestStatus: SUCCESS_STATUS }));
    } else {
      yield put(setSNVPresetsInfo({ requestStatus: FAILURE_STATUS }));
      yield put(error(noPresetsError));
    }

    if (errorMessages.length) {
      yield put(errors(errorMessages));
    }

    return response;
  } catch (e) {
    yield put(setSNVPresetsInfo({ requestStatus: FAILURE_STATUS }));
    yield put(error(e.message));
  }
}
