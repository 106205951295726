import React, { FC, memo, useEffect } from "react";

import { fetchData } from "modules/utils";
import { FetchDataResponse } from "modules/utils/fetchData";

import { loadVcfQC } from "../vcf";

interface Props {
  patientId: number;
}

export const VCF: FC<Props> = memo(({ patientId }: Props) => {
  useEffect(() => {
    const loadData = async () => {
      const result: FetchDataResponse<any> = await fetchData(
        `/patient/${patientId}/qc/vcf/json`
      );
      if (result.ok) {
        loadVcfQC(result.payload);
      }
    };
    loadData();
  }, [patientId]);

  return (
    <div className="vcf">
      <h1>VCF QC</h1>
      <div id="vcf-summary">
        <table className="table table-striped vcf-summary-table">
          <tbody>
            <tr>
              <th>SNVs</th>
              <td data-json-source="SNV Count" />
            </tr>
            <tr>
              <th className="vcf-summary-table-row">
                <abbr title="Genotype is missing. This is usually because the variant has been filtered out using our pipeline. Most often this is because of too low sequencing depth.">
                  Congenica QC Filter Count
                </abbr>
              </th>
              <td data-json-source="Sapientia QC Filter Count" />
            </tr>
            <tr>
              <th>
                <abbr title="Filtered out due to the filters set in the VCF file">
                  User Supplied Filter Count
                </abbr>
              </th>
              <td data-json-source="User Supplied Filter Count" />
            </tr>
          </tbody>
        </table>
      </div>
      <div id="snp-alleles" className="method">
        <h2>SNP Alleles</h2>
        <svg />
      </div>
      <div id="indel-size" className="method">
        <h2>INDEL Size</h2>
        <svg />
      </div>
      <div id="contigs" className="method">
        <h2>Chromosomes</h2>
        <svg />
      </div>
      <div className="row">
        <div id="allele-count" className="method col-md-6">
          <h2>Allele Count</h2>
          <svg />
        </div>
        <div id="types" className="method col-md-6">
          <h2>Variant types</h2>
          <svg />
        </div>
      </div>
      <div className="row">
        <div id="tstv" className="method col-md-6">
          <h2>Transition/Transversion</h2>
          <svg />
        </div>
        <div id="zygosity" className="method col-md-6">
          <h2>Zygosity</h2>
          <svg />
        </div>
      </div>
    </div>
  );
});
