export const temporaryStorage = () => ({
  getItem: key => sessionStorage.getItem(key),
  setItem: (key, value) => {
    if (value === undefined) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  },
});

export const permanentStorage = () => ({
  getItem: key => localStorage.getItem(key),
  setItem: (key, value) => {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  },
});
