import React, { PureComponent } from "react";

import NotFoundMessage from "./partials/NotFoundMessage";

export class NotFoundLayout extends PureComponent {
  render() {
    return <NotFoundMessage />;
  }
}

export default NotFoundLayout;
