// It's impossible to use flow here due to lack of the `useCallback` function
// in the react declaration file (actual for the 0.80 flow version).
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchProfileApiToken, resetProfileApiToken } from "./actions";
// import type { LoadingStatus, State } from "./reducer";

const useEnsureApiTokenWillLoaded = () => {
  const dispatch = useDispatch();
  const {
    ui: { apiTokenLoadingStatus },
  } /*: State */ = useSelector(state => state.auth);

  if (apiTokenLoadingStatus === "pending") {
    dispatch(fetchProfileApiToken());
  }
};

// type ApiTokenData = {
//   apiToken: false | string,
//   loadingStatus: LoadingStatus,
//   refreshToken: () => void
// };

export const useApiToken = () /*: ApiTokenData */ => {
  useEnsureApiTokenWillLoaded();

  const dispatch = useDispatch();

  const {
    data: { apiToken },
    ui: { apiTokenLoadingStatus },
  } /* : State */ = useSelector(state => state.auth);

  const refreshToken = useCallback(() => {
    dispatch(resetProfileApiToken());
  }, [dispatch]);

  return { apiToken, loadingStatus: apiTokenLoadingStatus, refreshToken };
};
