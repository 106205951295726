export const formatCriteriaName = (
  variantCriteria = { name: "", strength: {} },
  criteria
) => {
  const { name, strength: { id: strengthId, prefix } = {} } = variantCriteria;
  const { strength: { id: originalStrengthId } = {} } = criteria;

  // Original criteria refers to the ACMGCriteria model, criteria is the
  // VariantCriteria model
  const hasModifiedStrength = originalStrengthId !== strengthId;

  if (hasModifiedStrength && prefix) return `${name}_${prefix}`;
  return name;
};

export const strengthMap = {
  "Stand Alone": 1,
  "Very Strong": 2,
  Strong: 3,
  Moderate: 4,
  Supporting: 5,
};

export const sortByCriteriaData = (a, b) => {
  const {
    category: { id: firstCat },
    strength: { id: firstStrength = 0 } = {},
    classificationType: { id: firstClass = 0 } = {},
    name: firstName = "",
  } = a || 0;
  const {
    category: { id: secondCat },
    strength: { id: secondStrength = 0 } = {},
    classificationType: { id: secondClass = 0 } = {},
    name: secondName = "",
  } = b || 0;

  // Sort by Category ASC, then Class type DESC,
  // then Strength ASC, then name ASC
  return (
    firstCat - secondCat ||
    secondClass - firstClass ||
    firstStrength - secondStrength ||
    firstName.localeCompare(secondName)
  );
};
