import React from "react";
import { Cell } from "recharts";

export const DEFAULT_HIGH_VALUE_COLOURS = {
  fill: "#fff3f4",
  stroke: "#ff0000",
};
export const DEFAULT_LOW_VALUE_COLOURS = { fill: "#f6f9fc", stroke: "#428bca" };

export const renderRechartsCellsWithCalculatedColour = ({
  borderValue = 0.5,
  data,
  dataKey = "value",
  highValueColours = DEFAULT_HIGH_VALUE_COLOURS,
  idKey = "id",
  lowValueColours = DEFAULT_LOW_VALUE_COLOURS,
}) =>
  data.map(entity => (
    <Cell
      key={`key-${entity[idKey]}`}
      fill={
        entity[dataKey] < borderValue
          ? lowValueColours.fill
          : highValueColours.fill
      }
      stroke={
        entity[dataKey] < borderValue
          ? lowValueColours.stroke
          : highValueColours.stroke
      }
    />
  ));
