import React, { memo } from "react";

import { CommonContainer, CommonProps } from "../components/CommonContainer";

import congenicaApi from "api/congenica-api";

const { useGetOncologyVariantsSNVsQuery } = congenicaApi;

export const FILTER_PLACEHOLDER = "Filter by Gene...";

const columns = [
  {
    Header: "Gene",
    accessor: "gene",
    id: "gene",
  },
  {
    Header: "Chromosome",
    accessor: "chrom",
    id: "chrom",
  },
  {
    Header: "Position",
    accessor: "pos",
    id: "pos",
  },
  {
    Header: "Ref",
    accessor: "ref",
    id: "ref",
  },
  {
    Header: "Alt",
    accessor: "alt",
    id: "alt",
  },
  {
    Header: "VAF",
    accessor: "vaf",
    id: "vaf",
  },
  {
    Header: "Coding Change",
    accessor: "coding_change",
    id: "coding_change",
  },
  {
    Header: "Protein Change",
    accessor: "protein_change",
    id: "protein_change",
  },
  {
    Header: "Variant Type",
    accessor: "variant_type",
    id: "variant_type",
  },
  {
    Header: "Gene Type",
    accessor: "gene_type",
    id: "gene_type",
  },
];

const ConnectedContainer: React.FC<CommonProps> = props => {
  const { patientId } = props;
  const { isLoading, data } = useGetOncologyVariantsSNVsQuery({
    patientId,
  });

  return (
    <CommonContainer
      {...props}
      title="Sequence variants"
      data={data}
      columns={columns}
      isLoading={isLoading}
    />
  );
};

export default memo(ConnectedContainer);
