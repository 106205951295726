import Genoverse from "genoverse";

import { View } from "../common/Scatter";
import { Colors } from "../utils";

export const Controller = Genoverse.Track.Controller.Graph.extend({
  // Disable Menu on click
  addUserEventHandlers: () => {},
});

export const Model = Genoverse.Track.Model.extend({
  parseData(data, chr) {
    const {
      data: {
        attributes: { coords },
      },
    } = data;

    for (let i = 0; i < coords.length; i++) {
      const [start, end, value] = coords[i];
      this.insertFeature({
        id: `${start}-${end}`,
        chr,
        start,
        end,
        value,
      });
    }
  },
});

export const Track = Genoverse.Track.Graph.extend({
  id: "Congenica.Log2Ratio",
  namespace: "Congenica.Log2Ratio",
  name: "Log2Ratio",
  info: "The default Log2Ratio track",
  type: "Scatter",
  fill: false,
  height: 150,
  lineWidth: 1,
  model: Model,
  view: View,
  controller: Controller,
  scatterSize: 1,
  featureMargin: { top: 0, left: 0, right: 0, bottom: 0 },
  colors: [
    {
      predicate: () => true,
      color: Colors.BLACK,
    },
  ],
});
