// @flow
import { set } from "dot-prop-immutable";

import { groupGenesById } from "data-layer/entities";
import * as legacyConstants from "modules/legacy/constants";
import { modifyStateWith } from "modules/utils/reducers";

import type { Action } from "./actions";
import { actionType, configForm } from "./constants";
import type {
  ConfigValuesTypes,
  GeneStrCounts,
  Str,
  StrsPage,
} from "./flow-types";

export type State = {
  +ui: {
    +loading: boolean,
  },
  +data: {
    +patientId: ?number,
    +strs: Array<Str>,
    +totalCount: number,
    +config: {
      +initialized: boolean,
      +values: ConfigValuesTypes,
      +defaults: ConfigValuesTypes,
      +timestamp: number,
    },
    +singleStr: ?Str,
    +decisionTimestamp: number,
    +geneStrCounts: GeneStrCounts,
    +genePanels: Array<GenePanel>,
    +geneWrappersByGeneId: GeneToGenePanelGroups,
  },
};

export const initialState: State = {
  ui: {
    loading: false,
  },
  data: {
    patientId: null,
    strs: [],
    totalCount: 0,
    config: {
      initialized: false,
      values: {
        filters: {
          [configForm.FILTER_GENE_PANELS]: [],
          [configForm.FILTER_TIERS]: [],
          geneId: null,
        },
      },
      defaults: {
        filters: {
          [configForm.FILTER_GENE_PANELS]: [],
          [configForm.FILTER_TIERS]: [],
          geneId: null,
        },
      },
      timestamp: Date.now(),
    },
    singleStr: null,
    /*
    will be updated by legacy action on decisions change.
    'decisionTimestamp' itself will be required to trigger VariantHistory tab data refresh
    */
    decisionTimestamp: Date.now(),
    geneStrCounts: [],
    genePanels: [],
    geneWrappersByGeneId: {},
  },
};

const setLoading = loading => state => set(state, "ui.loading", loading);

const setStrsData = strsData => state => set(state, "data.strs", strsData);

const setStrsCount = totalCount => state =>
  set(state, "data.totalCount", totalCount);

const setConfigValues = values => state =>
  set(state, "data.config.values", values);

const setFilterGeneId = geneId => state =>
  set(state, "data.config.values.filters.geneId", geneId);

const updateConfigTimeStamp = () => state =>
  set(state, "data.config.timestamp", Date.now());

const setSingleStr = str => state => set(state, "data.singleStr", str);

export default function reducer(
  state: State = initialState,
  { type, payload }: Action
): State {
  switch (type) {
    case actionType.FETCH_STRS_START:
      return setLoading(true)(state);

    case actionType.FETCH_STRS_SUCCESS:
      const { list, totalCount }: StrsPage = payload;

      return modifyStateWith(
        setLoading(false),
        setStrsData(list),
        setStrsCount(totalCount)
      )(state);

    case actionType.FETCH_STRS_FAILURE:
      return modifyStateWith(
        setLoading(false),
        setStrsData([]),
        setStrsCount(0)
      )(state);

    case actionType.UNSET_SINGLE_STR:
      return setSingleStr(null)(state);

    case actionType.FETCH_SINGLE_STR_SUCCESS:
      return setSingleStr(payload)(state);

    case actionType.CONFIG_READY:
      return set(state, "data.config.initialized", true);

    case actionType.CONFIG_SET_DEFAULT_VALUES:
      return set(state, "data.config.defaults", payload);

    case actionType.CONFIG_SET_VALUES:
      return modifyStateWith(
        setConfigValues(payload),
        updateConfigTimeStamp()
      )(state);

    case actionType.SET_PATIENT_ID:
      return set(state, "data.patientId", payload);

    case legacyConstants.PROCESS_LEGACY_STR_DECISION_VALUES:
      return set(state, "data.decisionTimestamp", Date.now());

    case actionType.SET_GENE_STR_COUNTS:
      return set(state, "data.geneStrCounts", payload);

    case actionType.SET_GENE_PANELS:
      return set(state, "data.genePanels", payload);

    case actionType.SET_GENE_WRAPPERS_BY_GENE:
      return set(state, "data.geneWrappersByGeneId", groupGenesById(payload));

    case actionType.FILTER_BY_GENE:
      return modifyStateWith(
        setFilterGeneId(payload),
        updateConfigTimeStamp()
      )(state);

    default:
      return state;
  }
}
