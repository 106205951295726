import classNames from "classnames";
import React, { memo } from "react";

interface CheckboxGroupButtonsProps {
  selectText?: string;
  onSelect: () => any;
  clearText?: string;
  onClear: () => any;
  className?: string;
}

export const CheckboxGroupButtons = ({
  selectText = "Select all",
  onSelect,
  clearText = "Clear",
  onClear,
  className = "",
}: CheckboxGroupButtonsProps) => (
  <div
    className={classNames(
      "btn-toolbar",
      "pull-right",
      "panels-toolbar",
      className
    )}
  >
    <div className="btn-group btn-group-sm">
      <button
        type="button"
        className="btn btn-sm btn-default"
        onClick={onSelect}
      >
        {selectText}
      </button>
    </div>
    <div className="btn-group btn-group-sm">
      <button
        type="button"
        className="btn btn-sm btn-default"
        onClick={onClear}
      >
        {clearText}
      </button>
    </div>
  </div>
);

export default memo(CheckboxGroupButtons);
