import React, { PureComponent } from "react";
import { components } from "react-select";

import { Case } from "./Case";

const { Option: SelectOption } = components;

export class Option extends PureComponent {
  static displayName = "Option";

  render() {
    return (
      <SelectOption {...this.props}>
        <Case {...this.props} />
      </SelectOption>
    );
  }
}
