import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import FormControlWrapper from "./FormControlWrapper";
import FormGroup from "./FormGroup";
import Label from "./Label";

export default class Checkbox extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    input: PropTypes.object,
    required: PropTypes.bool,
    narrow: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  render() {
    const {
      label = "",
      input = {},
      required = false,
      narrow = false,
      disabled = false,
      onChange,
      onBlur,
      name = "",
      checked,
    } = this.props;

    return (
      <FormGroup {...this.props}>
        <div className="form-check text-center">
          {label && <Label label={label} reguired={required} narrow={narrow} />}
          <FormControlWrapper label={label} narrow={narrow}>
            <input
              className="form-check"
              {...input}
              checked={checked}
              type="checkbox"
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
            />
          </FormControlWrapper>
        </div>
      </FormGroup>
    );
  }
}
