export const NAMESPACE = "ariadneState";

export const ARIADNE_KEYS = {
  PREDICTED_PATHOGENICITY: "ariadneComputedDecision",
  PREDICTED_CONFIDENCE_RATING: "ariadneScore",
  PREDICTED_C2P: "ariadneComputedC2p",
  PREDICTED_C2P_EXTENDED: "ariadneComputedSecondaryFindingC2p",
};

export const ARIADNE_VALUES = {
  C_2_P: {
    FULL: "Full",
    PARTIAL: "Partial",
    UNCERTAIN: "Uncertain",
    NONE: "None",
  },
  PREDICTED_PATHOGENICITY: {
    PATHOGENIC: "Pathogenic",
    LIKELY_PATHOGENIC: "Likely pathogenic",
    LIKELY_BENIGN: "Likely benign",
    BENIGN: "Benign",
  },
};

export const ARIADNE_STRINGS = {
  ARIADNE: "Congenica AI",
  ARIADNE_DESC:
    "Congenica AI is a machine learning tool that predicts which variants are causative for a rare disease patient, given their phenotypes",
  PREDICTED_PATHOGENICITY_HEADER: "Predicted pathogenicity (Congenica AI)",
  CONFIDENCE_SCORE_HEADER: "Prediction confidence rating (Congenica AI)",
  PREDICTED_C2P_HEADER:
    "Predicted contribution to primary phenotypes (Congenica AI)",
  PREDICTED_C2P_EXTENDED_HEADER:
    "Predicted contribution to extended primary phenotypes (Congenica AI)",
  PREDICTED_PATHOGENICITY_DESC:
    "The pathogenicity classification with the highest probability for each transcript, predicted by Congenica AI",
  CONFIDENCE_SCORE_DESC:
    "5-star rating score that reflects the degree of confidence in the predicted pathogenicity",
  PREDICTED_PATHOGENICITY_DESC_ORDER:
    "Variants will be sorted in the following order:",
  PREDICTED_PATHOGENICITY_DESC_ORDER_LIST: [
    ARIADNE_VALUES.PREDICTED_PATHOGENICITY.PATHOGENIC,
    ARIADNE_VALUES.PREDICTED_PATHOGENICITY.LIKELY_PATHOGENIC,
    ARIADNE_VALUES.PREDICTED_PATHOGENICITY.LIKELY_BENIGN,
    ARIADNE_VALUES.PREDICTED_PATHOGENICITY.BENIGN,
  ].join(", "),
  CONFIDENCE_SCORE_DESC_ORDER:
    "Variants will be sorted in descending order of confidence",
  PREDICTED_C2P_DESC:
    "The variant’s predicted contribution to the HPO terms of the patient",
  PREDICTED_C2P_EXTENDED_DESC:
    "The variant’s predicted contribution to additional phenotypes (potentially related) to the primary phenotypes",
  PREDICTED_C2P_DESC_ORDER: "Results will be sorted in the following order:",
  PREDICTED_C2P_DESC_ORDER_LIST: [
    ARIADNE_VALUES.C_2_P.FULL,
    ARIADNE_VALUES.C_2_P.PARTIAL,
    ARIADNE_VALUES.C_2_P.UNCERTAIN,
    ARIADNE_VALUES.C_2_P.NONE,
  ].join(", "),
  FILTER: {
    HEADER: "Congenica AI predictions:",
    INFORMATION_HEADER: "What is this?",
    CONTROL: {
      PREDICTED_PATHOGENICITY: "Predicted pathogenicity",
      PREDICTED_PATHOGENICITY_DESC:
        "The pathogenicity classification with the highest probability for the variant on the default transcript, predicted by Congenica AI",
      PREDICTED_CONTRIBUTION: "Predicted contribution to primary phenotypes",
      PREDICTED_CONTRIBUTION_DESC:
        "The variant’s predicted contribution to the HPO terms of the patient (if available)",
      PREDICTED_CONTRIBUTION_EXTENDED:
        "Predicted contribution to extended primary phenotypes",
      PREDICTED_CONTRIBUTION_EXTENDED_DESC:
        "The variant's predicted contribution to additional phenotypes, potentially related to the primary phenotypes",
      PREDICTION_CONFIDENCE_RATING: "Prediction confidence rating",
      PREDICTION_CONFIDENCE_RATING_DESC:
        "5-star rating score that reflects the degree of confidence in the predicted pathogenicity\n\nRatings of 3 stars or above represent good quality predictions",
    },
  },
};

export const ARIADNE_FIELD_TO_TITLE_MAP = {
  af_max: "MAX AF",
  gerp: "GERP score",
  rf_score: "RF splicing score",
  ada_score: "ADA splicing score",
  vep_consequence: "VEP consequence",
  polyphen_score: "PolyPhen score",
  sift_score: "SIFT score",
  hi_score: "HI score",
  grantham_score: "Grantham substitution",
  clinvar_pathogenicity: "ClinVar pathogenicity",
  q1: "Non coding variant",
  q2: "Variant in a translated region but it does not change the amino acid",
  q3: "Amino acid change in protein with no known Pfam functional domain",
  q4: "Amino acid change in protein with known Pfam functional domain",
  revel: "REVEL score",
  mvp: "MVP score",
  ponp2_prob_path: "PON-P2 score",
  mpc_score: "MPC score",
  gnomad_oe_lof: "gnomAD pLoF o/e score",
  gnomad_synonymous_z_score: "gnomAD synonymous z-score",
  gnomad_missense_z_score: "gnomAD missense z-score",
  gnomad_af: "gnomAD allele frequency",
};

export const ARIADNE_FIELD_TO_SHORT_TITLE_MAP = {
  q2: "Synonymous change",
  q3: "Amino acid change in protein with no known functional domain",
  q4: "Amino acid change in protein with known functional domain",
  gnomad_af: "gnomAD AF",
};
