export const MODULE_NAME = "strs" as const;

export const actionType = Object.freeze({
  FETCH_STRS_START: `${MODULE_NAME}/FETCH_STRS_START`,
  FETCH_STRS_SUCCESS: `${MODULE_NAME}/FETCH_STRS_SUCCESS`,
  FETCH_STRS_FAILURE: `${MODULE_NAME}/FETCH_STRS_FAILURE`,

  FETCH_SINGLE_STR_START: `${MODULE_NAME}/FETCH_SINGLE_STR_START`,
  FETCH_SINGLE_STR_SUCCESS: `${MODULE_NAME}/FETCH_SINGLE_STR_SUCCESS`,
  FETCH_SINGLE_STR_FAILURE: `${MODULE_NAME}/FETCH_SINGLE_STR_FAILURE`,

  CONFIG_INIT: `${MODULE_NAME}/CONFIG_INIT`,
  CONFIG_READY: `${MODULE_NAME}/CONFIG_READY`,
  CONFIG_SET_DEFAULT_VALUES: `${MODULE_NAME}/CONFIG_SET_DEFAULT_VALUES`,
  CONFIG_SET_VALUES: `${MODULE_NAME}/CONFIG_SET_VALUES`,
  CONFIG_RESET: `${MODULE_NAME}/CONFIG_RESET`,
  CONFIG_SAVE: `${MODULE_NAME}/CONFIG_SAVE`,

  SET_PATIENT_ID: `${MODULE_NAME}/SET_PATIENT_ID`,

  UNSET_SINGLE_STR: `${MODULE_NAME}/UNSET_SINGLE_STR`,
  SET_GENE_STR_COUNTS: `${MODULE_NAME}/SET_GENE_STR_COUNTS`,
  SET_GENE_PANELS: `${MODULE_NAME}/SET_GENE_PANELS`,
  SET_GENE_WRAPPERS_BY_GENE: `${MODULE_NAME}/SET_GENE_WRAPPERS_BY_GENE`,
  FILTER_BY_GENE: `${MODULE_NAME}/FILTER_BY_GENE`,
});

export const configForm = Object.freeze({
  FORM_NAME: "strConfigForm",
  FILTER_GENE_PANELS: "genePanels",
  FILTER_TIERS: "tiers",
});

export const DEFAULT_TIERS = ["TIER1", "TIER2"];
export const TIER_OPTIONS = {
  TIER1: "Tier 1",
  TIER2: "Tier 2",
};

export const NO_GENE_NAME_VALUE = "STR not overlapping gene transcript";
