import { createAction } from "modules/utils";

export const asyncActionCreatorGenerator = <START_ARG, DATA, META = Index>(
  ACTION: string
): AsyncActionCreatorEx => {
  const START_ACTION = `${ACTION}_START`;
  const SUCCESS_ACTION = `${ACTION}_SUCCESS`;
  const FAILURE_ACTION = `${ACTION}_FAILURE`;

  return {
    START_ACTION,
    SUCCESS_ACTION,
    FAILURE_ACTION,
    start: (args: START_ARG, meta?: META): FSA<string, START_ARG, META> =>
      createAction(START_ACTION, args, meta),
    success: (data?: DATA, meta?: META): FSA<string, DATA, META> =>
      createAction(SUCCESS_ACTION, data, meta),
    failure: (error?: any, meta?: META): FSA<string, any, META> =>
      createAction(FAILURE_ACTION, error, meta),
  };
};
