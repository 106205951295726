import classnames from "classnames";
import React, { PureComponent } from "react";

import { MessageProps } from "./Message.types";

export default class Message extends PureComponent<MessageProps> {
  render() {
    const {
      id,
      level = "default",
      className,
      closeMessage,
      message,
    } = this.props;

    const closeButton = (() => {
      if (!closeMessage) {
        return null;
      }

      return (
        <button
          type="button"
          className="close"
          data-message={id}
          onClick={closeMessage}
        >
          <span aria-hidden>&times;</span>
        </button>
      );
    })();

    return (
      <div className={classnames(`alert alert-${level}`, className)}>
        {closeButton}
        {message}
      </div>
    );
  }
}
