import { PureComponent } from "react";
import { connect } from "react-redux";

import { loadSystemConfig } from "./actions";
import { getAssemblyConfig } from "./selectors";

export class Container extends PureComponent {
  componentDidMount() {
    const { loadSystemConfig } = this.props;
    loadSystemConfig();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default connect(
  state => ({
    getAssemblyConfig: getAssemblyConfig(state),
  }),
  { loadSystemConfig }
)(Container);
