export const affectionStatusOptions = {
  Affected: "Affected",
  Unaffected: "Unaffected",
  Unknown: "Unknown",
};

export const genderOptions = {
  Male: "Male",
  Female: "Female",
  Unknown: "Unknown",
};
