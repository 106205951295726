import classNames from "classnames";
import PropTypes from "prop-types";
import { identity, memoizeWith } from "ramda";
import React, { PureComponent } from "react";

import Divider from "./Divider";
import LinkCrumb from "./LinkCrumb";
import TextCrumb from "./TextCrumb";

export default class Breadcrumbs extends PureComponent {
  static displayName = "Breadcrumbs";

  static propTypes = {
    /**
     * Crumbs to show in the component
     */
    crumbs: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Text inside component
         */
        name: PropTypes.string.isRequired,
        /**
         * The location the crumb points to
         */
        url: PropTypes.string,
      })
    ).isRequired,
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  getChildren(crumbs) {
    return crumbs.reduce((memo, { name, url = false }, index, array) => {
      if (url === false) {
        memo.push(<TextCrumb key={name} name={name} />);
      } else {
        memo.push(<LinkCrumb key={name} url={url} name={name} />);
      }
      if (index + 1 < array.length) {
        memo.push(<Divider key={`divider-${name}`} />);
      }
      return memo;
    }, []);
  }

  render() {
    const { crumbs, className } = this.props;
    const children = memoizeWith(identity, this.getChildren)(crumbs);
    return (
      <div className={classNames("breadcrumbs", className)}>{children}</div>
    );
  }
}
