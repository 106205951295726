import { camelizeKeys } from "humps";
import { takeLatest, select, put, call } from "redux-saga/effects";

import { updateVariantDecision } from "../decisions/actions";
import { findDecision } from "../decisions/selectors";
import { fetchDecisionsForPatient } from "../variants/saga";

import * as constants from "./constants";

export function* init() {
  yield takeLatest(
    constants.PROCESS_LEGACY_DECISION_VALUES,
    processLegacyDecisionValues
  );
}

export function* processLegacyDecisionValues(action) {
  const { decisionType, decisionVal, params } = action.payload;
  const parsedParams = camelizeKeys(params);
  let { patientId, variantId, transcriptId } = parsedParams;
  variantId = parseInt(variantId);
  transcriptId = parseInt(transcriptId);
  patientId = parseInt(patientId);

  const decision = yield select(findDecision, variantId, decisionType);

  if (decision) {
    yield put(
      updateVariantDecision({
        value: decisionVal,
        transcriptId,
        variantId,
        decisionType,
      })
    );
  } else {
    // This handles creating decisions we don't have yet
    yield call(fetchDecisionsForPatient, { meta: { patientId } });
  }
}
