export const IN_PROGRESS_STATUS = "IN_PROGRESS";
export const SUCCESS_STATUS = "SUCCESS";
export const FAILURE_STATUS = "FAILURE";
export const IDLE_STATUS = "IDLE";
// used when a request should not be called according to the app logic
export const SKIPPED_STATUS = "SKIPPED";

export const REQUEST_STATUS = Object.freeze({
  PENDING: IDLE_STATUS,
  PROGRESS: IN_PROGRESS_STATUS,
  SUCCESS: SUCCESS_STATUS,
  FAILURE: FAILURE_STATUS,
});

export const REQUEST_COMPLETION_STATUSES = [SUCCESS_STATUS, FAILURE_STATUS];
export const COMMON_ERROR_MESSAGE = "Something went wrong.";
export const SUPPORT_EMAIL = "support@congenica.com";
export const VIEW_PRESET_INFORMATION = "View preset information";
export const NAME_TAKEN = "Name is already taken";

export const CURATED_LIST_TYPE_KNOWLEDGEBASE = "Knowledgebase";
export const CURATED_LIST_TYPE_BLACK = "Blacklist";
export const CURATED_LIST_TYPE_WHITE = "Whitelist";

export const CLOUD_PROVIDER_ALIBABA = "alibaba";
export const CLOUD_PROVIDER_AWS = "aws";
export const CLOUD_PROVIDER_ON_PREM = "disabled";
