// @flow
export default function createAction(
  type: string,
  payload?: {} = {},
  meta?: {
    queryParams?: {},
    pathParams?: {},
    /**
     * the id specified in case a single entity is requested
     */
    modelId?: string,
  } = {}
): {} {
  return {
    type,
    payload,
    meta,
  };
}
