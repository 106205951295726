import { path } from "ramda";

import {
  NAMESPACE,
  REPORT_TEMPLATES,
  SETTINGS,
  VARIANT_PANEL,
} from "./constants";

const dataPathSubmodule = (subModule, ...args) =>
  path([NAMESPACE, subModule, "data", ...args]);

const uiPathSubmodule = (subModule, ...args) =>
  path([NAMESPACE, subModule, "ui", ...args]);

export const getReportTemplates = state =>
  dataPathSubmodule(REPORT_TEMPLATES)(state);

export const getReportTemplatesLoading = state =>
  uiPathSubmodule(REPORT_TEMPLATES, "loading")(state);

export const getAllReportTemplatesLoading = state =>
  uiPathSubmodule(REPORT_TEMPLATES, "templatesLoading")(state);

export const getEnabledTemplates = state =>
  uiPathSubmodule(REPORT_TEMPLATES, "enabledTemplates")(state);

export const getProjectSettingsLoading = state =>
  uiPathSubmodule(SETTINGS, "loading")(state);

export const getProjectSettings = state => dataPathSubmodule(SETTINGS)(state);

export const getVariantPanel = state => dataPathSubmodule(VARIANT_PANEL)(state);

export const getVariantPanelLoading = state =>
  uiPathSubmodule(VARIANT_PANEL, "loading")(state);

export const getVariantPanelLoadingError = state =>
  uiPathSubmodule(VARIANT_PANEL, "loadingError")(state);

export const projectHasInheritors = (state, settingsType) => {
  const inheritedTypes = path([NAMESPACE, "misc", "projectInheritors"], state);
  return inheritedTypes.includes(settingsType);
};
