export const NAME = "variants" as const;

// Actions
export const START_INIT = "START_VARIANTS_INIT" as const;
export const VARIANTS_INIT = "VARIANTS_INIT" as const;

export const SET_ACTIVE_GENES = "SET_ACTIVE_GENES" as const;
export const SET_FILTER_VALUE = "SET_FILTER_VALUE" as const;

export const RELOAD_VARIANTS = "RELOAD_VARIANTS" as const;
export const RELOAD_VARIANT_PAGE = "RELOAD_VARIANT_PAGE" as const;

export const TOGGLE_VARIANT_EXPANDED = "TOGGLE_VARIANT_EXPANDED" as const;

export const LOADING_VARIANTS = "LOADING_VARIANTS" as const;
export const LOADING_VARIANTS_COMPLETED = "LOADING_VARIANTS_COMPLETED" as const;
export const EXPAND_VARIANT = "EXPAND_VARIANT" as const;
export const COLLAPSE_VARIANT = "COLLAPSE_VARIANT" as const;

export const SET_ACTIVE_TRANSCRIPT = "SET_ACTIVE_TRANSCRIPT" as const;
export const SET_ACTIVE_PANEL_VALUES = "SET_ACTIVE_PANEL_VALUES" as const;
export const RESET_VARIANT_PANEL = "RESET_VARIANT_PANEL" as const;
export const SET_PAGE = "SET_PAGE" as const;
export const SET_PAGER = "SET_PAGER" as const;
export const GET_PANEL_GENES_VARIANT_COUNT =
  "GET_PANEL_GENES_VARIANT_COUNT" as const;
export const SET_ACTIVE_VARIANTS_BY_GENE =
  "SET_ACTIVE_VARIANTS_BY_GENE" as const;
export const SET_FILTERED_VARIANTS_BY_GENE =
  "SET_FILTERED_VARIANTS_BY_GENE" as const;
export const SET_FILTERED_VARIANT_INFO_BY_GENE =
  "SET_FILTERED_VARIANT_INFO_BY_GENE" as const;
export const SET_FILTERED_OUT_VARIANTS_DISPLAYED =
  "SET_FILTERED_OUT_VARIANTS_DISPLAYED" as const;
export const GET_PATIENT_VARIANTS_BY_GENE =
  "GET_PATIENT_VARIANTS_BY_GENE" as const;
export const GET_GENE_FROM_FILTER_PANEL = "GET_GENE_FROM_FILTER_PANEL" as const;
export const SEARCH_GENE_BY_NAME_FROM_URL =
  "SEARCH_GENE_BY_NAME_FROM_URL" as const;

export const SELECT_CONTEXT_FOR_VARIANT_PANEL =
  "SELECT_CONTEXT_FOR_VARIANT_PANEL" as const;
export const SET_ALL_VARIANTS_COUNT = "SET_ALL_VARIANTS_COUNT" as const;
export const LOAD_FULL_GENE = "LOAD_FULL_GENE" as const;
export const LOAD_ALL_VARIANTS_COUNT = "LOAD_ALL_VARIANTS_COUNT";
export const RESET_ACTIVE_GENE = "RESET_ACTIVE_GENE";
export const SET_GENE_TO_FOCUS = "SET_GENE_TO_FOCUS";
export const ADD_CONFIG_PANEL_GENE = "ADD_CONFIG_PANEL_GENE" as const;

export const API_READ_DECISION_SUCCEEDED = "API_READ_DECISION_SUCCEEDED";
export const API_READ_GENEPANEL_SUCCEEDED = "API_READ_GENEPANEL_SUCCEEDED";
export const API_READ_GENE_SUCCEEDED = "API_READ_GENE_SUCCEEDED";
