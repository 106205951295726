import { DataType, StoreType } from "./definitions";

export const Preferences = {
  patientsListPageSize: "preferences.patients.list.pageSize",
  patientsHideReviewComplete: "preferences.patients.list.hideReviewComplete",
};

export const PreferencesMetadata = {
  [Preferences.patientsListPageSize]: {
    memory: StoreType.temporary,
    type: DataType.number,
  },
  [Preferences.patientsHideReviewComplete]: {
    memory: StoreType.temporary,
    type: DataType.boolean,
  },
};
