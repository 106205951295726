import PropTypes from "prop-types";
import { prop } from "ramda";
import React from "react";

export const SpecificGenesFilterSidebar = ({ genes }) => (
  <div className="filter-sidebar-specific-genes">
    <span className="title">Genes:</span>
    <strong>{genes.map(prop("label")).join(", ")}</strong>
  </div>
);

SpecificGenesFilterSidebar.propTypes = {
  genes: PropTypes.array.isRequired,
};
