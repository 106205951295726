import type { Action } from "./actions";
import { actionType } from "./actions";

type State = {
  breakdownData: {
    [requestId: string]: {
      data: any,
      loading: boolean,
    },
  },
};

export const initialState: State = {
  breakdownData: {},
};

export default function reducer(
  state: State = initialState,
  { type, payload }: Action = {}
): State {
  switch (type) {
    case actionType.FETCH_ARIADNE_BREAKDOWN_DATA_START:
      return {
        ...state,
        breakdownData: {
          ...state.breakdownData,
          [payload.requestId]: {
            data: null,
            loading: true,
          },
        },
      };
    case actionType.FETCH_ARIADNE_BREAKDOWN_DATA_SUCCESS:
      return {
        ...state,
        breakdownData: {
          ...state.breakdownData,
          [payload.requestId]: {
            data: payload.data,
            loading: false,
          },
        },
      };
    case actionType.FETCH_ARIADNE_BREAKDOWN_DATA_FAILURE:
      return {
        ...state,
        breakdownData: {
          ...state.breakdownData,
          [payload]: {
            data: null,
            loading: false,
          },
        },
      };
    default:
      return state;
  }
}
