import classNames from "classnames";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";
import * as yup from "yup";

import {
  Button,
  IconLoading,
  ConfirmationModal,
  Accordion,
} from "pattern-library";

import { AutomationContext } from "modules/projectSettings/components/Automation";
import * as constants from "modules/projectSettings/constants";
import { submitAndResetFormik } from "modules/projectSettings/util";
import { sanitize } from "modules/utils/objects";

import AutoAcmgEditor from "./AutoAcmgEditor";
import { AutomationEditor } from "./AutomationEditor";

const getValidationSchema = cvlList =>
  yup.object({
    enabled: yup.boolean(),
    automatedReportingEnabled: yup.boolean(),
    moiRestrictionEnabled: yup.boolean(),
    curatedVariantListId: yup
      .string()
      .nullable()
      .when(["enabled"], {
        is: true,
        then: yup
          .string()
          .required("Please select a curated variant list")
          .test(
            "cvlEligibleForAutomatedAnnotationDecisions",
            "Selected CVL contains outdated Ensembl data. Please select another one",
            curatedVariantListId => {
              const selectedCVL = cvlList.find(
                ({ value }) => Number(curatedVariantListId) === Number(value)
              );
              return !selectedCVL?.isDisabled;
            }
          ),
      }),
    reportTemplateId: yup
      .string()
      .nullable()
      .when(["enabled", "automatedReportingEnabled"], {
        is: true,
        then: yup.string().required("Please select a report template"),
      }),
  });

export class AutomationForm extends PureComponent {
  static propTypes = {
    cvlList: PropTypes.array.isRequired,
    reportServiceTemplates: PropTypes.array.isRequired,
    toggleConfirmationModal: PropTypes.func.isRequired,
    showConfirmationModal: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
  };

  render() {
    const {
      cvlList,
      reportServiceTemplates,
      toggleConfirmationModal,
      showConfirmationModal,
      handleSubmit,
      isSubmitting,
      dirty,
      initialValues: featuresSettings,
      errors,
      touched,
    } = this.props;

    const touchedErrors = sanitize(errors, touched);

    const featuresSections = {
      automation: {
        sectionName: "Automation",
        content: (
          <AutomationEditor
            cvlList={cvlList}
            reportServiceTemplates={reportServiceTemplates}
          />
        ),
        showError: touchedErrors && !isNil(touchedErrors.automation),
      },
      autoAcmg: {
        sectionName: "Auto ACMG",
        content: <AutoAcmgEditor />,
      },
    };

    const sections = Object.entries(featuresSections).reduce(
      (result, [featureName, section]) => {
        if (featuresSettings[featureName]) {
          result.push(section);
        }
        return result;
      },
      []
    );

    return (
      <form
        onSubmit={handleSubmit}
        className="col-sm-10 col-md-8 col-lg-6 form-horizontal clearfix automation-form"
      >
        <div>
          <Accordion data={sections} />
        </div>
        <div className="pull-right">
          {isSubmitting && (
            <div className={classNames("ref-automation-actions-item")}>
              <IconLoading loading />
            </div>
          )}
          <Button
            disabled={!dirty || isSubmitting}
            type="submit"
            className={isSubmitting ? "btn-info" : "btn-primary"}
          >
            Save
          </Button>
        </div>
        <AutomationContext.Consumer>
          {isSentieonAvailable => (
            <ConfirmationModal
              closeClickHandler={toggleConfirmationModal}
              showConfirmationModal={showConfirmationModal}
              yesClickHandler={handleSubmit}
              confirmationText={
                <p>
                  The <strong>Curated variant list</strong>,{" "}
                  {isSentieonAvailable ? (
                    <>
                      <strong>Genotype Ref Calling</strong>,{" "}
                    </>
                  ) : null}
                  <strong>MOI restriction setting</strong>,{" "}
                  <strong>Automated Reporting</strong> and{" "}
                  <strong>Report Template</strong> values will be cleared or set
                  to default values. Continue?
                </p>
              }
            />
          )}
        </AutomationContext.Consumer>
      </form>
    );
  }
}

export default withFormik({
  validationSchema: ({ initialValues: { automation } = {}, cvlList }) => {
    const validationObject = {};
    if (automation) {
      validationObject.automation = getValidationSchema(cvlList);
    }
    return yup.object(validationObject);
  },
  validateOnMount: true,
  mapPropsToValues: ({ initialValues } = {}) => initialValues,
  mapPropsToTouched: () => ({ automation: { curatedVariantListId: true } }),
  handleSubmit: (...props) => {
    submitAndResetFormik(...props);
  },
  displayName: constants.AUTOMATION_FORM,
})(AutomationForm);
