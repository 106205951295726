import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import AcmgCriteria from "./Criteria";

export default class AcmgGridRow extends PureComponent {
  static propTypes = {
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    criteria: PropTypes.array,
    classificationTypes: PropTypes.array,
    patientId: PropTypes.number,
    variantId: PropTypes.number,
    patientVariant: PropTypes.shape({
      patientId: PropTypes.number,
      variantId: PropTypes.number,
      noEvidenceCategories: PropTypes.array,
      criteria: PropTypes.array,
    }).isRequired,
    locked: PropTypes.bool,
    canEdit: PropTypes.bool,
    updateCriteriaToAdd: PropTypes.func,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleClick() {
    const {
      markAsNoEvidence,
      category: { id: categoryId },
      patientId,
      variantId,
      locked = true,
    } = this.props;

    if (!locked) {
      markAsNoEvidence({
        patientId,
        variantId,
        categoryId,
      });
    }
  }

  render() {
    const {
      category: { name: categoryName, id: categoryId } = {},
      criteria = [],
      classificationTypes = [],
      patientVariant,
      patientVariant: { selectedCriteria = [], noEvidenceCategories = [] },
      locked = false,
      canEdit = true,
      updateCriteriaToAdd,
      disabled,
    } = this.props;

    let lockNoEvidence = false;
    let lockAllCellsForThisCategory = false;

    const criteriaAlreadyAddedForThisCategory = selectedCriteria.find(
      criteria => criteria.category.id === categoryId
    );

    if (criteriaAlreadyAddedForThisCategory || locked) {
      lockNoEvidence = true;
    }

    if (noEvidenceCategories.indexOf(categoryId) > -1 || locked) {
      lockAllCellsForThisCategory = true;
    }

    const noEvidenceDisabled = Boolean(lockNoEvidence || disabled);

    return (
      <tr>
        <th>{categoryName}</th>
        {classificationTypes.map(classificationType =>
          classificationType.strengths.map(strength => (
            // key is made up of the category name, classificationType and strength so it is unique
            <td
              key={`${categoryId}-${classificationType.id}-${strength.id}`}
              className="criteria-cell"
            >
              {criteria.map(singleCriteria => {
                if (
                  singleCriteria.strength.id === strength.id &&
                  singleCriteria.classificationType === classificationType.id
                ) {
                  return (
                    <AcmgCriteria
                      patientVariant={patientVariant}
                      key={singleCriteria.id}
                      updateCriteriaToAdd={updateCriteriaToAdd}
                      criteria={singleCriteria}
                      locked={
                        locked ||
                        lockAllCellsForThisCategory ||
                        canEdit === false
                      }
                      lockedCell={
                        lockAllCellsForThisCategory || canEdit === false
                      }
                      classificationType={classificationType}
                      disabled={disabled}
                    />
                  );
                }
                return null;
              })}
            </td>
          ))
        )}
        <td className="criteria-cell">
          <input
            type="checkbox"
            checked={noEvidenceCategories.indexOf(categoryId) > -1}
            onChange={this.handleClick}
            disabled={noEvidenceDisabled}
          />
        </td>
      </tr>
    );
  }
}
