import classNames from "classnames";
import { always } from "ramda";
import React, { useCallback } from "react";

import { Tooltip } from "pattern-library";

export const ResetIcon = ({
  tooltip = "Reset to the default values",
  onClick = always(),
  disabled = false,
}) => {
  const onResetClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [onClick, disabled]);

  return (
    <Tooltip content={tooltip} placement="right" trigger="mouseenter">
      <span
        onClick={onResetClick}
        className={classNames("glyphicon glyphicon-refresh", { disabled })}
      />
    </Tooltip>
  );
};

export default ResetIcon;
