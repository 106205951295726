import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Link from "../base/Link";

export default class ButtonLink extends PureComponent {
  static displayName = "ButtonLink";

  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: "",
  };

  render() {
    const { children, ...otherProps } = this.props;

    return <Link {...otherProps}>{children || "Submit"}</Link>;
  }
}
