import { createSelector } from "@reduxjs/toolkit";

import { isCurrentProjectOncology } from "modules/project/selectors";
import { getAppVersions, getUserGuide } from "modules/systemConfig/selectors";
import { AppVersions, UserGuides } from "modules/systemConfig/types";

import { MODULE_CONFIG_MAP } from "../../constants";

export const getModuleVersionText = createSelector(
  getAppVersions,
  isCurrentProjectOncology,
  (appVersions: AppVersions, isOncology) => {
    const { rareDisease, oncology } = appVersions || {};

    const versionConfigMap = {
      oncology: {
        ...MODULE_CONFIG_MAP.oncology,
        version: oncology,
      },
      rareDisease: {
        ...MODULE_CONFIG_MAP.rareDisease,
        version: rareDisease,
      },
    };

    const productModule = isOncology
      ? versionConfigMap.oncology
      : versionConfigMap.rareDisease;

    const cleanVersionPartNumber = (productModule.version || "")
      .split("+")[0]
      .replace(/\./g, "");

    return `Congenica ${productModule.name} (Part number ${productModule.moduleNumber}-${cleanVersionPartNumber})`;
  }
);

export const getModuleName = createSelector(
  isCurrentProjectOncology,
  isOncology => {
    const moduleConfig = isOncology
      ? MODULE_CONFIG_MAP.oncology
      : MODULE_CONFIG_MAP.rareDisease;
    return moduleConfig.name;
  }
);

export const getCurrentProjectUserGuideLinks = createSelector(
  getUserGuide,
  isCurrentProjectOncology,
  (userGuides: UserGuides, isOncology) => {
    const { rareDisease, oncology } = userGuides;
    return isOncology ? oncology : rareDisease;
  }
);

export const getInstructionsForUseLink = createSelector(
  getCurrentProjectUserGuideLinks,
  userGuideLinks => userGuideLinks?.instructionsForUse
);

export const getUserGuideUsLink = createSelector(
  getCurrentProjectUserGuideLinks,
  userGuideLinks => userGuideLinks?.userGuideUs
);

export const getUserManualLink = createSelector(
  getCurrentProjectUserGuideLinks,
  userGuideLinks => userGuideLinks?.userGuide
);
