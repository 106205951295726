import classNames from "classnames";
import { withFormik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";

import { Actions, ConfirmationModal } from "pattern-library";

import { FormikFormField } from "modules/forms/components";

import { checkProjectHasInheritors } from "../actions";
import { SETTINGS_TYPES } from "../constants";
import { projectHasInheritors } from "../selectors";
import { submitAndResetFormik, withProjectSettingsWrapper } from "../util";

import InheritanceToggle from "./InheritanceToggle";

const salesForceCustomerIdRegex = new RegExp(/^[a-zA-Z0-9]{18}$/);
const invalidCustomerId =
  "SalesForce ID must be a 18 character long alphanumeric string";

export const validationSchema = yup.object({
  isInherited: yup.boolean(),
  value: yup
    .string()
    .nullable(true)
    .when("isInherited", {
      is: false,
      then: yup.string().matches(salesForceCustomerIdRegex, invalidCustomerId),
    }),
});

const CustomerSettings = ({
  projectId,
  values: { isInherited },
  initialValues,
  dirty,
  submitting,
  submitForm,
  handleSubmit,
  isValid,
  projectHasCustomerInheritors,
  checkProjectHasInheritors,
  setFieldValue,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  useEffect(() => {
    if (projectId) checkProjectHasInheritors(projectId);
  }, [checkProjectHasInheritors, projectId]);

  const cancelInheritance = () => {
    setShowConfirmationModal(false);
    setFieldValue("isInherited", initialValues.isInherited);
  };

  const closeModalAndSubmit = () => {
    setShowConfirmationModal(false);
    submitForm();
  };

  const submitWithConfirmation = () => {
    if (projectHasCustomerInheritors) {
      setShowConfirmationModal(true);
    } else {
      submitForm();
    }
  };

  const toggleInheritance = () => {
    if (isInherited && projectHasCustomerInheritors) {
      setShowConfirmationModal(true);
    } else {
      submitForm();
    }
  };

  const enableInheritanceWarning = (
    <>
      The current project has child projects.
      <br />
      <br />
      Enabling inheritance or updating customer settings will affect any child
      projects with inheritance enabled
    </>
  );

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-4">
          <ConfirmationModal
            confirmationText={enableInheritanceWarning}
            showConfirmationModal={showConfirmationModal}
            closeText="Cancel"
            confirmText="Confirm"
            yesClickHandler={closeModalAndSubmit}
            closeClickHandler={cancelInheritance}
          />
          <InheritanceToggle
            projectId={projectId}
            label="Inherit Customer details from parent project?"
            narrow={8}
            submitForm={toggleInheritance}
          />
          <Field
            component={FormikFormField}
            name="value"
            id="value"
            label="Customer ID"
            type="text"
            narrow={8}
            placeholder="SalesForce customer ID"
            disabled={isInherited}
          />
          <Actions
            actions={[
              {
                label: submitting ? "Saving..." : "Save",
                disabled: !isValid || isInherited || !dirty || submitting,
                className: classNames("btn", {
                  "btn-primary": !submitting,
                  "btn-info": submitting,
                }),
                action: submitWithConfirmation,
                type: "button",
              },
            ]}
          />
        </div>
      </div>
    </form>
  );
};

const submitCustomerSettings = (values, formikBag) =>
  submitAndResetFormik(values, formikBag);

const FormikForm = withFormik({
  displayName: "customerForm",
  mapPropsToValues: ({ initialValues }) => initialValues,
  validationSchema,
  handleSubmit: submitCustomerSettings,
})(CustomerSettings);

const mapStateToProps = state => ({
  projectHasCustomerInheritors: projectHasInheritors(
    state,
    SETTINGS_TYPES.CUSTOMER
  ),
});

const mapDispatchToProps = { checkProjectHasInheritors };

const withWrapper = withProjectSettingsWrapper({
  component: FormikForm,
  path: "customer",
  entityType: "customer_settings",
});

export default connect(mapStateToProps, mapDispatchToProps)(withWrapper);
