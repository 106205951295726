import { ARIADNE_STRINGS, ARIADNE_KEYS } from "modules/ariadne/constants";
import { EXAC_EXOMES_FIELDS } from "modules/exac/constants";
import { minAndMaxNumber } from "modules/forms/rules";
import { GNOMAD_EXOMES_FIELDS } from "modules/gnomad/constants";
import { ComparisonOutcomes } from "modules/sequenceVariantsSummary/constants";
import { toDecimalPlaces } from "modules/utils/format";

import { ucfirst } from "../utils";

import { afFormat, exomiserFormat, formatInSilicoScores } from "./utils";

/*
See https://confluence.congenica.net/display/DES/Splicing+Improvements+-+High+Level+Design
999,999,999 and 111,111,111 are 'magic' numbers as score values to indicate 'denovo' and 'loss'
 */
const DENOVO_PERCENTAGE_CHANGE = 999999999;
const LOSS_PERCENTAGE_CHANGE = 111111111;
const SPLICING_PERCENTAGE_ABBR = {
  [DENOVO_PERCENTAGE_CHANGE]: "denovo",
  [LOSS_PERCENTAGE_CHANGE]: "loss",
};

const splicingPercentageFormat = (value: number): string => {
  if (value === undefined || value === null) {
    return "";
  }

  return SPLICING_PERCENTAGE_ABBR[value] || toDecimalPlaces(value) + "%";
};

const splicingDecimalFormat = (value: number): number => toDecimalPlaces(value);

export const CUSTOM_TIER_KEY = "customTier";
export const CUSTOM_PENETRANCE_KEY = "customPenetrance";

const MAX_ENT_SCAN_KEY = "maxEntScan";
const RF_KEY = "rf";
const ADA_KEY = "ada";
const SPLICE_AI_KEY = "spliceAi";
const CSSF_KEY = "cssf";

export const SPLICE_AI_FIELDS_KEYS = [SPLICE_AI_KEY];

export const SPLICING_FIELDS_KEYS = [
  MAX_ENT_SCAN_KEY,
  RF_KEY,
  ADA_KEY,
  SPLICE_AI_KEY,
  CSSF_KEY,
];

export const PLI_KEY = "pliScore";
export const LOEUF_KEY = "loeufScore";
export const MISSENSE_Z_KEY = "missenseZScore";

export const GNOMAD_CS_FIELDS_KEYS = [PLI_KEY, LOEUF_KEY, MISSENSE_Z_KEY];

// Magic numbers determined by range of data in initial data load
const MISSENSE_Z_MIN = -19;
const MISSENSE_Z_MAX = 11;

export const REVEL_KEY = "revelScore";
export const REVEL_FEATURE_KEYS = ["revelScore"];

export const CONSEQUENCES_TABLE_KEY = "__CONSEQUENCES_TABLE__";
export const TRANSCRIPT_KEY = "transcript";

const exacVariantColumns = Object.keys(EXAC_EXOMES_FIELDS).map(field => ({
  header: EXAC_EXOMES_FIELDS[field].label,
  accessor: ["snv", EXAC_EXOMES_FIELDS[field].key],
  format: afFormat,
  key: EXAC_EXOMES_FIELDS[field].key,
}));

const exacPopulationAfFilters = Object.keys(EXAC_EXOMES_FIELDS).map(field => ({
  key: EXAC_EXOMES_FIELDS[field].key,
  label: EXAC_EXOMES_FIELDS[field].label,
  group: "exac",
  comparator: "≤",
}));

const gnomadAcVariantColumns = Object.keys(GNOMAD_EXOMES_FIELDS)
  .filter(field => field.startsWith("AC"))
  .map(field => ({
    header: GNOMAD_EXOMES_FIELDS[field].label,
    accessor: [GNOMAD_EXOMES_FIELDS[field].key],
    key: GNOMAD_EXOMES_FIELDS[field].key,
  }));

const gnomadAfVariantColumns = Object.keys(GNOMAD_EXOMES_FIELDS)
  .filter(field => field.startsWith("AF"))
  .map(field => ({
    header: GNOMAD_EXOMES_FIELDS[field].label,
    accessor: [GNOMAD_EXOMES_FIELDS[field].key],
    format: afFormat,
    key: GNOMAD_EXOMES_FIELDS[field].key,
  }));

export const gnomadPopulationAcFilters = Object.keys(GNOMAD_EXOMES_FIELDS)
  .filter(field => field.startsWith("AC"))
  .map(field => ({
    key: GNOMAD_EXOMES_FIELDS[field].key,
    label: GNOMAD_EXOMES_FIELDS[field].label,
    comparator: "≤",
  }));

const gnomadPopulationAfFilters = Object.keys(GNOMAD_EXOMES_FIELDS)
  .filter(field => field.startsWith("AF"))
  .map(field => ({
    key: GNOMAD_EXOMES_FIELDS[field].key,
    label: GNOMAD_EXOMES_FIELDS[field].label,
    group: "gnomad",
    comparator: "≤",
  }));

const getTooltip = content => ({
  content: Array.isArray(content) ? content.join("\n\n") : content,
  placement: "left",
  trigger: "mouseenter",
});

export const AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY =
  "suggestedAcmgPathogenicity";

const data = {
  variantColumns: [
    {
      header: "Variant",
      component: "Variant",
      key: "variant",
    },
    {
      key: CONSEQUENCES_TABLE_KEY,
    },
    {
      header: "Curated Variants",
      component: "CuratedVariants",
      key: "curatedVariants",
    },
    {
      header: "Zygosity",
      accessor: "genotype",
      key: "zygosity",
    },
    {
      header: "Inheritance",
      accessor: "inheritance",
      format: (inheritance: string): string => ucfirst(inheritance),
      key: "inheritance",
    },
    {
      header: "De novo Status",
      accessor: "denovoStatus",
      key: "denovoStatus",
    },
    {
      header: "MOI",
      component: "Moi",
      key: "moi",
    },
    {
      header: "Max AF",
      accessor: ["snv", "afMax"],
      format: afFormat,
      key: "afMax",
    },
    ...exacVariantColumns,
    {
      header: "UK10K",
      accessor: ["snv", "afUk10k"],
      format: afFormat,
      key: "afUk10k",
    },
    {
      header: "1000G",
      accessor: ["snv", "afKg"],
      format: afFormat,
      key: "afKg",
    },
    {
      header: "QC Status",
      component: "QCStatus",
      key: "qcStatus",
    },
    {
      header: "Exomiser Gene Combined Score",
      accessor: ["exomiserScores", "EXOMISER_GENE_COMBINED_SCORE"],
      key: "exomiserGeneCombinedScore",
      parse: true,
      format: exomiserFormat,
    },
    {
      header: "Exomiser Gene Pheno Score",
      accessor: ["exomiserScores", "EXOMISER_GENE_PHENO_SCORE"],
      key: "exomiserGenePhenoScore",
      parse: true,
      format: exomiserFormat,
    },
    {
      header: "Exomiser Gene Variant Score",
      accessor: ["exomiserScores", "EXOMISER_GENE_VARIANT_SCORE"],
      key: "exomiserGeneVariantScore",
      parse: true,
      format: exomiserFormat,
    },
    {
      header: "Exomiser Variant Score",
      accessor: ["exomiserScores", "EXOMISER_VARIANT_SCORE"],
      key: "exomiserVariantScore",
      parse: true,
      format: exomiserFormat,
    },
    {
      header: "Pathogenicity",
      accessor: ["pathogenicity", "value"],
      key: "pathogenicity",
    },
    {
      header: "ACMG Pathogenicity",
      accessor: ["acmg", "value"],
      key: "pathogenicityACMG",
    },
    {
      header: "Suggested ACMG Pathogenicity",
      accessor: ["suggestedPathogenicity"],
      key: AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
    },
    {
      header: "Contribution to Phenotype",
      accessor: ["contributionToPhenotype", "value"],
      key: "contributionToPhenotype",
    },
    {
      header: "QC Depth",
      accessor: ["depth"],
      key: "qcDepth",
    },
    {
      header: "QC Score",
      accessor: ["score"],
      key: "qcScore",
    },
    {
      header: "Family Comparison",
      component: "FamilyComparisons",
      key: "familyComparisons",
    },
    {
      header: "Tier",
      component: "CustomTier",
      key: CUSTOM_TIER_KEY,
    },
    {
      header: "MaxEntScan",
      key: MAX_ENT_SCAN_KEY,
      accessor: "mesPercentChange",
      format: splicingPercentageFormat,
    },
    {
      header: "RF",
      key: RF_KEY,
      accessor: "rfScore",
      format: splicingDecimalFormat,
    },
    {
      header: "ADA",
      key: ADA_KEY,
      accessor: "adaScore",
      format: splicingDecimalFormat,
    },
    {
      header: "SpliceAI",
      key: SPLICE_AI_KEY,
      accessor: "spliceAiMaxScore",
      format: splicingDecimalFormat,
    },
    {
      header: "CSSF",
      key: CSSF_KEY,
      accessor: "ssfPercentChange",
      format: splicingPercentageFormat,
    },
    ...gnomadAcVariantColumns,
    ...gnomadAfVariantColumns,
  ],
  consequenceColumns: [
    {
      header: "Transcript",
      component: "Transcript",
      key: TRANSCRIPT_KEY,
      accessor: "name",
    },
    {
      header: "VEP Consequence",
      component: "VEP",
      key: "vepConsequence",
      accessor: "vepConsequence",
    },
    {
      header: "HGVSc",
      component: "HGVSc",
      key: "hgvsC",
      accessor: "hgvsC",
    },
    {
      header: "HGVSp",
      component: "HGVSp",
      key: "hgvsP",
      accessor: "hgvsP",
    },
    {
      header: "Polyphen Score",
      component: "PolyphenScore",
      key: "polyphenScore",
      accessor: "polyphenScore",
      format: formatInSilicoScores,
    },
    {
      header: "SIFT Score",
      component: "SIFTScore",
      key: "siftScore",
      accessor: "siftScore",
      format: formatInSilicoScores,
    },
    {
      header: "REVEL Score",
      component: "REVELScore",
      key: REVEL_KEY,
      accessor: REVEL_KEY,
      format: formatInSilicoScores,
    },
    {
      header: ARIADNE_STRINGS.PREDICTED_PATHOGENICITY_HEADER,
      component: "PredictedPathogenicity",
      key: ARIADNE_KEYS.PREDICTED_PATHOGENICITY,
      tooltip: getTooltip(ARIADNE_STRINGS.PREDICTED_PATHOGENICITY_DESC),
    },
    {
      header: ARIADNE_STRINGS.CONFIDENCE_SCORE_HEADER,
      component: "PredictionConfidence",
      key: ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING,
      tooltip: getTooltip(ARIADNE_STRINGS.CONFIDENCE_SCORE_DESC),
    },
    {
      header: ARIADNE_STRINGS.PREDICTED_C2P_HEADER,
      key: ARIADNE_KEYS.PREDICTED_C2P,
      accessor: ARIADNE_KEYS.PREDICTED_C2P,
      tooltip: getTooltip(ARIADNE_STRINGS.PREDICTED_C2P_DESC),
    },
    {
      header: ARIADNE_STRINGS.PREDICTED_C2P_EXTENDED_HEADER,
      key: ARIADNE_KEYS.PREDICTED_C2P_EXTENDED,
      accessor: ARIADNE_KEYS.PREDICTED_C2P_EXTENDED,
      tooltip: getTooltip(ARIADNE_STRINGS.PREDICTED_C2P_EXTENDED_DESC),
    },
  ],

  allScoresValues: {
    scoreFields: [
      {
        key: "polyphenScore",
        label: "Polyphen [0-1]",
        comparator: "≥",
        placeholder: "Polyphen (raw value)",
        normalize: minAndMaxNumber(0, 1),
      },
      {
        key: "siftScore",
        label: "SIFT [0-1]",
        comparator: "≤",
        placeholder: "SIFT (raw value)",
        normalize: minAndMaxNumber(0, 1),
      },
      {
        key: REVEL_KEY,
        label: "REVEL [0-1]",
        comparator: "≥",
        placeholder: "REVEL (raw value)",
        normalize: minAndMaxNumber(0, 1),
      },
    ],
    splicingFields: [
      {
        key: MAX_ENT_SCAN_KEY,
        label: "MaxEntScan [>0]",
        comparator: "≥",
        placeholder: "MaxEntScan (% change)",
        normalize: minAndMaxNumber(0, LOSS_PERCENTAGE_CHANGE - 1),
      },
      {
        key: RF_KEY,
        label: "RF [0-1]",
        comparator: "≥",
        placeholder: "RF Score (raw value)",
        normalize: minAndMaxNumber(0, 1),
      },
      {
        key: ADA_KEY,
        label: "ADA [0-1]",
        comparator: "≥",
        placeholder: "ADA Score (raw value)",
        normalize: minAndMaxNumber(0, 1),
      },
      {
        key: SPLICE_AI_KEY,
        label: "SpliceAI [0-1]",
        comparator: "≥",
        placeholder: "SpliceAI (raw value)",
        normalize: minAndMaxNumber(0, 1),
      },
      {
        key: CSSF_KEY,
        label: "CSSF [>0]",
        comparator: "≥",
        placeholder: "CSSF (% change)",
        normalize: minAndMaxNumber(0, LOSS_PERCENTAGE_CHANGE - 1),
      },
    ],
    constraintsFields: [
      {
        key: PLI_KEY,
        label: "pLI [0-1]",
        comparator: "≥",
        placeholder: "pLI (raw value)",
        normalize: minAndMaxNumber(0, 1),
      },
      {
        key: LOEUF_KEY,
        label: "LOEUF [0-2]",
        comparator: "≤",
        placeholder: "LOEUF (raw value)",
        normalize: minAndMaxNumber(0, 2),
      },
      {
        key: MISSENSE_Z_KEY,
        label: `Missense Z [${MISSENSE_Z_MIN}-${MISSENSE_Z_MAX}]`,
        comparator: "≥",
        placeholder: "Missense Z (raw value)",
        normalize: minAndMaxNumber(MISSENSE_Z_MIN, MISSENSE_Z_MAX),
      },
    ],
  },
  allZygosityValues: [
    {
      key: "Homozygous",
      label: "Homozygous",
    },
    {
      key: "Heterozygous",
      label: "Heterozygous",
    },
    {
      key: "Hemizygous",
      label: "Hemizygous",
    },
    {
      key: "Not Specified",
      label: "Not Specified",
    },
  ],
  allPopulationAc: [...gnomadPopulationAcFilters],
  allPopulationAf: [
    {
      key: "afMax",
      label: "Max AF",
      comparator: "≤",
      description:
        "The maximum allele frequency across all populations except Finnish and other small populations",
    },
    {
      key: "afUk10k",
      label: "UK10K",
      comparator: "≤",
    },
    {
      key: "afKg",
      label: "1000G",
      comparator: "≤",
    },
    ...gnomadPopulationAfFilters,
    ...exacPopulationAfFilters,
  ],
  prioritisationDefaults: [
    {
      key: ARIADNE_KEYS.PREDICTED_PATHOGENICITY,
      label: ARIADNE_STRINGS.PREDICTED_PATHOGENICITY_HEADER,
      tooltip: getTooltip([
        ARIADNE_STRINGS.FILTER.CONTROL.PREDICTED_PATHOGENICITY_DESC,
        ARIADNE_STRINGS.PREDICTED_PATHOGENICITY_DESC_ORDER,
        ARIADNE_STRINGS.PREDICTED_PATHOGENICITY_DESC_ORDER_LIST,
      ]),
    },
    {
      key: ARIADNE_KEYS.PREDICTED_CONFIDENCE_RATING,
      label: ARIADNE_STRINGS.CONFIDENCE_SCORE_HEADER,
      tooltip: getTooltip([
        ARIADNE_STRINGS.CONFIDENCE_SCORE_DESC,
        ARIADNE_STRINGS.CONFIDENCE_SCORE_DESC_ORDER,
      ]),
    },
    {
      key: ARIADNE_KEYS.PREDICTED_C2P,
      label: ARIADNE_STRINGS.PREDICTED_C2P_HEADER,
      tooltip: getTooltip([
        ARIADNE_STRINGS.PREDICTED_C2P_DESC,
        ARIADNE_STRINGS.PREDICTED_C2P_DESC_ORDER,
        ARIADNE_STRINGS.PREDICTED_C2P_DESC_ORDER_LIST,
      ]),
    },
    {
      key: ARIADNE_KEYS.PREDICTED_C2P_EXTENDED,
      label: ARIADNE_STRINGS.PREDICTED_C2P_EXTENDED_HEADER,
      tooltip: getTooltip([
        ARIADNE_STRINGS.PREDICTED_C2P_EXTENDED_DESC,
        ARIADNE_STRINGS.PREDICTED_C2P_DESC_ORDER,
        ARIADNE_STRINGS.PREDICTED_C2P_DESC_ORDER_LIST,
      ]),
    },
    {
      key: "afMax",
      label: "Max AF",
    },
    {
      key: "polyphenScore",
      label: "Polyphen Score",
    },
    {
      key: "siftScore",
      label: "SIFT Score",
    },
    {
      key: REVEL_KEY,
      label: "REVEL Score",
    },
    {
      key: MAX_ENT_SCAN_KEY,
      label: "MaxEntScan",
    },
    {
      key: RF_KEY,
      label: "RF",
    },
    {
      key: ADA_KEY,
      label: "ADA",
    },
    {
      key: SPLICE_AI_KEY,
      label: "SpliceAI",
    },
    {
      key: CSSF_KEY,
      label: "CSSF",
    },
    {
      key: "exomiserGeneCombinedScore",
      label: "Exomiser Gene Combined Score",
    },
    {
      key: "exomiserGenePhenoScore",
      label: "Exomiser Gene Pheno Score",
    },
    {
      key: "exomiserGeneVariantScore",
      label: "Exomiser Gene Variant Score",
    },
    {
      key: "exomiserVariantScore",
      label: "Exomiser Variant Score",
    },
    {
      key: AUTO_ACMG_SUGGESTED_PATHOGENICITY_KEY,
      label: "Suggested ACMG Pathogenicity",
    },
  ],
  allInheritanceValues: [
    {
      key: "paternal",
      label: "Paternal",
    },
    {
      key: "maternal",
      label: "Maternal",
    },
    {
      key: "biparental",
      label: "Biparental",
    },
    {
      key: "de-novo",
      label: "De novo",
    },
    {
      key: "de-novo-fp",
      label: "De novo false positive",
    },
    {
      key: "phase unknown",
      label: "Phase unknown",
    },
    {
      key: "indeterminable",
      label: "Indeterminable",
    },
  ],
  allFamilyComparisonValues: [
    {
      key: "POSSIBLE_CAUSAL",
      label: ComparisonOutcomes.POSSIBLE_CAUSAL,
    },
    {
      key: "POSSIBLE_CAUSAL_DOMINANT",
      label: ComparisonOutcomes.POSSIBLE_CAUSAL_DOMINANT,
    },
    {
      key: "POSSIBLE_CAUSAL_X_LINKED",
      label: ComparisonOutcomes.POSSIBLE_CAUSAL_X_LINKED,
    },
    {
      key: "POSSIBLE_CAUSAL_ARCH",
      label: ComparisonOutcomes.POSSIBLE_CAUSAL_ARCH,
    },
    {
      key: "UNLIKELY_CAUSAL",
      label: ComparisonOutcomes.UNLIKELY_CAUSAL,
    },
  ],
  allDenovoStatusValues: [
    {
      key: "LOW_CONF",
      label: "LOW_CONF",
    },
    {
      key: "MED_CONF",
      label: "MED_CONF",
    },
    {
      key: "HIGH_CONF",
      label: "HIGH_CONF",
    },
    {
      key: "DNG_PASS",
      label: "DNG_PASS",
    },
    {
      key: "ASSUMED_PARENTAL_GENOTYPE",
      label: "ASSUMED_PARENTAL_GENOTYPE",
    },
    {
      key: "DNG_ANALYSED",
      label: "DNG_ANALYSED",
    },
    {
      key: "DNG_NOT_RUN",
      label: "DNG_NOT_RUN",
    },
    {
      key: "MEDIUM_CONF",
      label: "MEDIUM_CONF",
    },
    {
      key: "NO_EXACT_MATCH_GENE_SPECIFIC",
      label: "NO_EXACT_MATCH_GENE_SPECIFIC",
    },
    {
      key: "PARENTAL_VARIANTS_ARE_NOT_EXACT_MATCHES",
      label: "PARENTAL_VARIANTS_ARE_NOT_EXACT_MATCHES",
    },
    {
      key: "HET_MALE_ON_X",
      label: "HET_MALE_ON_X",
    },
  ],
  allModeOfInheritanceValues: [
    {
      key: "ad",
      label: "Autosomal Dominant (AD)",
    },
    {
      key: "arh",
      label: "Autosomal Recessive, Homozygous (ARH)",
    },
    {
      key: "arch",
      label: "Autosomal Recessive, Compound Heterozygous (ARCH)",
    },
    {
      key: "xld",
      label: "X-Linked Dominant (XLD)",
    },
    {
      key: "xlr",
      label: "X-Linked Recessive (XLR)",
    },
    {
      key: "xlrch",
      label: "X-Linked Recessive, Compound Heterozygous (XLRCH)",
    },
  ],
  allTierValues: [
    {
      key: "TIER1",
      label: "Tier 1",
    },
    {
      key: "TIER2",
      label: "Tier 2",
    },
    {
      key: "TIER3",
      label: "Tier 3",
    },
  ],
  allPenetranceValues: [
    {
      key: "complete",
      label: "Complete",
    },
    {
      key: "incomplete",
      label: "Incomplete",
    },
  ],
  allQualityValues: [
    {
      key: "depth",
      label: "Depth",
      comparator: "≥",
    },
    {
      key: "score",
      label: "QC Score",
      comparator: "≥",
    },
    {
      key: "filterInclude",
      label: "Include QC Filters",
      colon: ":",
    },
    {
      key: "filterExclude",
      label: "Exclude QC Filters",
      colon: ":",
    },
  ],
  allSuggestedPathogenicityValues: [
    {
      key: "Pathogenic",
      label: "Pathogenic",
    },
    {
      key: "Likely pathogenic",
      label: "Likely pathogenic",
    },
    {
      key: "Uncertain significance",
      label: "Uncertain significance",
    },
    {
      key: "Likely benign",
      label: "Likely benign",
    },
    {
      key: "Benign",
      label: "Benign",
    },
    {
      key: "Not available",
      label: "No suggested ACMG pathogenicity available",
    },
  ],
};

export default data;
