export const NAME = "acmg" as const;

// Lock/unlock
export const ACMG_LOCK = "ACMG_LOCK" as const;
export const ACMG_UNLOCK = "ACMG_UNLOCK" as const;

export const ACMG_SET_CAN_LOCK = "ACMG_SET_CAN_LOCK" as const;
export const ACMG_IS_DECISION_ALREADY_SET_AGAINST_ANOTHER_TRANSCRIPT =
  "ACMG_IS_DECISION_ALREADY_SET_AGAINST_ANOTHER_TRANSCRIPT" as const;

// Criteria/noevidence
export const ACMG_PREPARE_TO_ADD_CRITERIA =
  "ACMG_PREPARE_TO_ADD_CRITERIA" as const;
export const ACMG_PREPARE_TO_REMOVE_CRITERIA =
  "ACMG_PREPARE_TO_REMOVE_CRITERIA" as const;

export const ACMG_RESET_CRITERIA_TO_ADD = "ACMG_RESET_CRITERIA_TO_ADD" as const;

export const ACMG_NO_EVIDENCE_FOR_CATEGORY =
  "ACMG_NO_EVIDENCE_FOR_CATEGORY" as const;

// Loading states for classifications
export const ACMG_LOADING_CLASSIFICATION =
  "ACMG_LOADING_CLASSIFICATION" as const;
export const ACMG_LOADED_CLASSIFICATION = "ACMG_LOADED_CLASSIFICATION" as const;

export const ACMG_MARK_AS_LOADED = "ACMG_MARK_AS_LOADED" as const;

export const UPDATE_VARIANT_ACMG = "UPDATE_VARIANT_ACMG" as const;

// Modals / overlays
export const ACMG_SHOW_WARNINGS_MODAL = "ACMG_SHOW_WARNINGS_MODAL" as const;
export const ACMG_HIDE_WARNINGS_MODAL = "ACMG_HIDE_WARNINGS_MODAL" as const;

export const ACMG_TOGGLE_OVERLAY = "ACMG_TOGGLE_OVERLAY" as const;

export const ACMG_LOCK_SUCCESS = "ACMG_LOCK_SUCCESS" as const;
export const ACMG_UNLOCK_SUCCESS = "ACMG_UNLOCK_SUCCESS" as const;
export const ACMG_LOCK_OR_UNLOCK_FAILED = "ACMG_LOCK_OR_UNLOCK_FAILED" as const;

// forms
export const ACMG_ADD_CRITERIA_EVIDENCE_FORM = "add-criteria-evidence";
export const ACMG_UPDATE_CRITERIA_EVIDENCE_FORM = "update-criteria-evidence";
export const ACMG_REMOVE_CRITERIA_EVIDENCE_FORM = "remove-criteria-evidence";

export const READ_ACMGCRITERIA_LIST = "READ_ACMGCRITERIA_LIST" as const;
export const CREATE_ACMGCRITERIA_LIST = "CREATE_ACMGCRITERIA_LIST" as const;

export const READ_ACMGCLASSIFICATION_LIST =
  "READ_ACMGCLASSIFICATION_LIST" as const;
export const CREATE_ACMGCLASSIFICATION_LIST =
  "CREATE_ACMGCLASSIFICATION_LIST" as const;

// ACMG Suggestion Statuses
export const ACMG_SUGGESTION_STATUS_PENDING = "pending" as const;
export const ACMG_SUGGESTION_STATUS_ACCEPTED = "accepted" as const;
export const ACMG_SUGGESTION_STATUS_REJECTED = "rejected" as const;
export const ACMG_SUGGESTION_STATUS_INACTIVE = "inactive" as const;

export const ACMG_GRID_SUGGESTIONS_DISABLED_TOOLTIP =
  "To select or amend ACMG criteria, please first Use or Reject Auto-suggested ACMG criteria";

// ACMG Suggestion Status Messages
export const SUGGESTED_ACMG_CRITERIA_USED =
  "Suggested ACMG Criteria successfully added to variant";

// ACMG Suggestion Actions
export const LOAD_SUGGESTED_ACMG_CRITERIA =
  "LOAD_SUGGESTED_ACMG_CRITERIA" as const;
export const SUGGESTED_ACMG_CRITERIA_LOADED =
  "SUGGESTED_ACMG_CRITERIA_LOADED" as const;
export const USE_SUGGESTED_ACMG_CRITERIA =
  "USE_SUGGESTED_ACMG_CRITERIA" as const;
export const REJECT_SUGGESTED_ACMG_CRITERIA =
  "REJECT_SUGGESTED_ACMG_CRITERIA" as const;
export const SUGGESTED_ACMG_CRITERIA_PROCESSING =
  "SUGGESTED_ACMG_CRITERIA_PROCESSING" as const;
