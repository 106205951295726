import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default class LineGraph extends PureComponent {
  static displayName = "LineGraph";

  static propTypes = {
    /**
     * Parameter defining whether to show animation
     */
    isAnimationActive: PropTypes.bool,
    /**
     * Parameter defining whether to show tooltips
     */
    showTooltip: PropTypes.bool,
    /**
     * Parameter defining whether to show legend
     */
    showLegend: PropTypes.bool,
    /**
     * Data to render chart
     */
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            category: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
            value: PropTypes.number.isRequired,
          })
        ),
      })
    ).isRequired,
    /**
     * Class to apply to the chart component
     */
    className: PropTypes.string,
  };

  static defaultProps = {
    isAnimationActive: false,
    className: "",
    width: 800,
    height: 600,
    showTooltip: true,
    showLegend: true,
  };

  render() {
    const {
      data,
      className,
      width,
      height,
      showTooltip,
      showLegend,
      isAnimationActive,
      ...otherProps
    } = this.props;

    return (
      <LineChart
        className={classNames(className, "graph-chart")}
        width={width}
        height={height}
        {...otherProps}
      >
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis
          dataKey="category"
          type="category"
          allowDuplicatedCategory={false}
        />
        <YAxis dataKey="value" />
        {showTooltip === true && <Tooltip />}
        {showLegend === true && <Legend />}
        {data.map(s => (
          <Line
            isAnimationActive={isAnimationActive}
            dataKey="value"
            data={s.data}
            stroke={s.color}
            name={s.name}
            key={s.name}
            {...otherProps}
          />
        ))}
      </LineChart>
    );
  }
}
