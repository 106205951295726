// @flow

import { getFormValues, getFormSyncErrors } from "redux-form";

import { configForm as StrConfig } from "modules/str/constants";

import {
  SNV_PRESET_VIEW_FORM,
  SNV_TABLE_CONFIG_FORM,
} from "../config/constants";
import { SNV_PRESET_FORM_NAME } from "../projectSettings/components/filterPresets/constants";

const formValueSelectors: { [string]: Function } = {
  [SNV_TABLE_CONFIG_FORM]: getFormValues(SNV_TABLE_CONFIG_FORM),
  [SNV_PRESET_VIEW_FORM]: getFormValues(SNV_PRESET_VIEW_FORM),
  [SNV_PRESET_FORM_NAME]: getFormValues(SNV_PRESET_FORM_NAME),
  [StrConfig.FORM_NAME]: getFormValues(StrConfig.FORM_NAME),
};

/**
 * return a known redux form value selector by form name
 * this is needed to avoid recreating form selectors in composed selectors,
 * otherwise the returned form value doesn't always reflect the latest form changes
 */
export const getReduxFormValueSelector = (formName: string): Function =>
  formValueSelectors[formName];

export const getReduxFormErrorSelector = (formName: string): Function =>
  getFormSyncErrors(formName);
