import React, { useState, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { InjectedFormProps, reduxForm } from "redux-form";

import { Button } from "pattern-library";

import { copyGenePanel } from "../../actions";
import { GENE_PANEL_EDIT_NOTIFICATION_FORM_NAME } from "../../constants";

export interface EditNotificationProps extends PropsFromRedux {
  projectId: number;
  genePanelId: number;
}

const EditNotification = ({
  projectId,
  genePanelId,
  copyGenePanel,
  handleSubmit,
}: EditNotificationProps & InjectedFormProps<Index, EditNotificationProps>) => {
  const [submitted, setSubmitted] = useState(false);

  const onFormSubmit = useCallback(() => {
    if (submitted) return;
    setSubmitted(true);
    copyGenePanel(projectId, genePanelId);
  }, [setSubmitted, projectId, genePanelId, copyGenePanel, submitted]);

  return (
    <div>
      <div className="alert alert-info alert-adding-gene-panel" role="alert">
        <p>
          Please note that you can&apos;t edit a gene panel with patients
          assigned:
        </p>
        <p>
          Instead click on the following button to copy an existing gene panel
          and modify the copy.
        </p>
        <form
          id={`add-gene-panel-${genePanelId}`}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Button
            type="submit"
            context={submitted ? "success" : "primary"}
            disabled={submitted ? "disabled" : undefined}
          >
            {submitted ? "Gene panel copied..." : "Copy gene panel"}
          </Button>
        </form>
      </div>
      <br />
    </div>
  );
};

const mapDispatchToProps = {
  copyGenePanel: copyGenePanel.start,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  reduxForm<Index, EditNotificationProps>({
    form: GENE_PANEL_EDIT_NOTIFICATION_FORM_NAME,
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(EditNotification)
);
