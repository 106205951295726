import type { Action } from "./actions";
import { actionType } from "./actions";
import type { State, VariantHistoryRecord } from "./flow-types";

export const initialState: State = {
  data: {},
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case actionType.FETCH_VARIANT_HISTORY_START:
      return unsetVariantHistoryList(state, action.payload);
    case actionType.FETCH_VARIANT_HISTORY_SUCCESS:
      return setVariantHistoryList(state, action.payload);
    default:
      return state;
  }
}

const setVariantHistoryList = (
  state: State,
  {
    requestId,
    historyRecords,
  }: {
    requestId: string,
    historyRecords: Array<VariantHistoryRecord>,
  }
) => ({
  ...state,
  data: {
    ...state.data,
    [requestId]: historyRecords,
  },
});

const unsetVariantHistoryList = (
  state: State,
  { requestId }: { requestId: string }
) => ({
  ...state,
  data: {
    ...state.data,
    [requestId]: [],
  },
});
