export const NAMESPACE = "PROJECT_SETTINGS" as const;

export const PROJECT_DEFAULTS_FORM = "PROJECT_DEFAULTS_FORM" as const;
export const ADMIN_CONFIG_FORM = "ADMIN_CONFIG_FORM" as const;
export const PROJECT_DETAILS_FORM = "PROJECT_DETAILS_FORM" as const;
export const AUTOMATION_FORM = "AUTOMATION_FORM" as const;

export const REPORT_TEMPLATES = "REPORT_TEMPLATES" as const;
export const SETTINGS = "SETTINGS" as const;
export const VARIANT_PANEL = "VARIANT_PANEL" as const;

export const PROJECT_DETAILS_MODE_EDIT = "edit";
export const PROJECT_DETAILS_MODE_ADD = "add";

export const SETTINGS_TYPES = {
  DEFAULTS: "DEFAULTS",
  CUSTOMER: "CUSTOMER",
};
