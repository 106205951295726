import React, { FC, memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Icon } from "pattern-library";

import { error, errors } from "modules/messages/actions";
import { downloadFile } from "modules/utils/fileDownload";

import { fetchData } from "../../../modules/utils";

import styles from "./Download.module.scss";

interface Props {
  className?: string;
  filename: string;
  caption?: string;
  url: string;
  params?: { [key in string]: string };
  dataTestId?: string;
  context?: "default" | "primary";
  disabled?: boolean;
  //supports Button and Link. These components should be rewritten for supporting Button | Link type
  Component: FC;
}

const Download: FC<Props> = ({
  Component = Button,
  context = "primary",
  filename,
  caption = "Download",
  url,
  dataTestId,
  params,
  disabled = false,
  className,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const download = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response: any = await fetchData(url, params, true);

      if (response.ok) {
        downloadFile({ ...response.file, filename });
      } else {
        const { errors: errorMessages = [] } = response;
        dispatch(errors(errorMessages));
      }
    } catch (e) {
      dispatch(error(e.message));
    } finally {
      setLoading(false);
    }
  }, [url, filename, params, dispatch, loading]);

  return (
    <Component
      className={className}
      disabled={disabled}
      context={context}
      onClick={download}
      data-testid={dataTestId}
    >
      <Icon
        className={styles.icon}
        type={loading ? "spinner" : "downloadAlt"}
      />
      {caption}
    </Component>
  );
};

export default memo<FC<Props>>(Download);
