import React, { useCallback, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { getFetchRequestParams } from "modules/utils/table";

import { getCVLVariants } from "../actions";
import { VARIANT_LAST_UPDATED_FIELD } from "../constants";
import {
  getProjectId,
  getSelectedVariants,
  variantsLoading,
} from "../selectors";
import { ProjectCuratedVariantList } from "../types";

import { getColumns } from "./variants-columns";

interface CVLVariantsProps extends PropsFromRedux {
  cvl: ProjectCuratedVariantList;
}
export const CVLVariants = ({
  cvl: { curatedVariantListId, curatedDataSchema, name },
  projectId,
  getCVLVariants,
  variants,
  isLoading,
}: CVLVariantsProps) => {
  const list = variants ? variants.list : [];
  const totalCount = variants ? variants.totalCount : 0;

  const columns = useMemo(
    () => getColumns(curatedDataSchema),
    [curatedDataSchema]
  );

  const fetchData = useCallback(
    args => {
      if (curatedVariantListId) {
        getCVLVariants(
          curatedVariantListId,
          projectId,
          getFetchRequestParams(args)
        );
      }
    },
    [getCVLVariants, curatedVariantListId, projectId]
  );

  return (
    <>
      <Table
        title={`Curated variants in ${name}`}
        columns={columns}
        data={list}
        showPagination={totalCount > DEFAULT_PAGE_SIZE}
        pageSizeOptions={[10, 25, 50, 100]}
        autoResetPage={false}
        loading={isLoading}
        loadingText=""
        fetchData={fetchData}
        manualPagination
        totalCount={totalCount}
        manualSortBy
        className="curated-lists-variants"
        enableFilter
        sortBy={[
          {
            id: VARIANT_LAST_UPDATED_FIELD,
            desc: true,
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = state => ({
  variants: getSelectedVariants(state),
  isLoading: variantsLoading(state),
  projectId: getProjectId(state),
});

const mapDispatchToProps = {
  getCVLVariants,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CVLVariants);
