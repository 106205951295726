import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Config from "../../config";

export default class LinkWrapper extends PureComponent {
  static displayName = "LinkWrapper";

  static propTypes = {
    /**
     * The location to link to
     */
    href: PropTypes.string,
    /**
     * The content to render in the link, text or react element
     */
    children: PropTypes.node.isRequired,
  };

  render() {
    const LinkElement = Config.linkElement;

    const { href, children, className, ...otherProps } = this.props;

    if (href) {
      if (Config.linkHrefMap) {
        otherProps[Config.linkHrefMap] = href;
      } else {
        otherProps.href = href;
      }
    } else {
      otherProps.role = "button";
    }

    if (className && className !== "") {
      otherProps.className = className;
    }

    return <LinkElement {...otherProps}>{children}</LinkElement>;
  }
}
