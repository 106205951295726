// @flow
import { set } from "dot-prop-immutable";

import type { Action } from "./actions";
import * as constants from "./constants";

export type ProjectData = {
  +patients: [],
  +metadata: [],
  +project: {},
};

export type Reports = {
  +patientId?: number,
  +data: [],
  +included: [],
};

type State = {
  +ui: {
    +loading: boolean,
    +patientsFilter: string,
    +reportsModalVisible: boolean,
  },
  +data: {
    +projectData: ProjectData,
    +reports: Reports,
  },
};

export const initialState: State = {
  ui: {
    loading: false,
    patientsFilter: "",
    reportsModalVisible: false,
  },
  data: {
    projectData: { patients: [], metadata: [], project: {} },
    reports: {
      data: [],
      included: [],
    },
  },
};

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case constants.INIT:
    case constants.LOAD_REPORTS:
      return set(state, "ui.loading", true);

    case constants.SET_LOADING:
      return set(state, "ui.loading", action.payload);

    case constants.SET_PROJECT_DATA:
      return set(state, "data.projectData", action.payload);

    case constants.SET_PATIENT_FILTER:
      return set(state, "ui.patientsFilter", action.payload);

    case constants.SET_REPORTS_MODAL_VISIBLE:
      return set(state, "ui.reportsModalVisible", action.payload);

    case constants.SET_REPORTS:
      return set(state, "data.reports", action.payload);

    default:
      return state;
  }
}
