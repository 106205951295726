import PropTypes from "prop-types";
import React from "react";

const ListItemLabel = ({ label }) => {
  if (!label) {
    return null;
  }
  return (
    <span className="list-item-label">
      <span className="label label-default">{label}</span>
    </span>
  );
};

ListItemLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ListItemLabel;
