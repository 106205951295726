import { Track } from "../core/StructuralVariants";
import { Colors, getRelativeTrackBaseConfig } from "../utils";

const checkVariantSubtype =
  subType =>
  ({ variant }) =>
    variant.subtype === subType;

export const createTrackConfig = ({ patientId }) => ({
  id: "Congenica.StructuralVariants",
  namespace: "Congenica.StructuralVariants",
  name: "My Patient: Structural variants",
  info: "Structural variants observed in the current patient Structural Variants are colour-coded in the genome browser according to type: Insertions are represented by black triangles, deletions are represented by red bars, CNV gains are represented by blue bars, CNV losses are represented by red bars, duplications are represented by blue bars and inversions are represented by gold bars",
  url: `/catalyst/api/patient/${patientId}/sv?chr=__CHR__&start=__START__&end=__END__`,
  menuLinks: {
    patient: ({ patient_id, id }) => `/patient/${patient_id}#variants/sv/${id}`,
  },
  colors: [
    {
      predicate: checkVariantSubtype("InDel"),
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkVariantSubtype("Insertion"),
      color: Colors.BLACK,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkVariantSubtype("Deletion"),
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkVariantSubtype("CNV gain"),
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkVariantSubtype("CNV loss"),
      color: Colors.RED,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkVariantSubtype("Duplication"),
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkVariantSubtype("Translocation"),
      color: Colors.BLUE,
      labelColor: Colors.WHITE,
    },
    {
      predicate: checkVariantSubtype("Inversion"),
      color: Colors.YELLOW,
      labelColor: Colors.BLACK,
    },
  ],
});

export const createRelativeTrackConfig = (relative = {}) => {
  const { patient = {}, relation } = relative;
  const { reference } = patient;
  const baseConfig = createTrackConfig(patient);
  const { patientId } = patient;

  return {
    ...baseConfig,
    ...getRelativeTrackBaseConfig(baseConfig.id, relation, patientId),
    name: `${relation} (${reference}): Structural variants`,
    configName: ["Structural variants", `Relation: ${relation} (${reference})`],
    info: "Structural variants observed in patient relative",
    height: 60,
  };
};

export const StructuralVariants = (config = {}) =>
  Track.extend(createTrackConfig(config));

export const RelativeStructuralVariants = (relative = {}) =>
  Track.extend(createRelativeTrackConfig(relative));
