// @flow

import { createSelector } from "@reduxjs/toolkit";
import { path, pick } from "ramda";

const NAMESPACE = "projectGenePanels";
const dataPath = (...args) => path([NAMESPACE, "data", ...args]);
const addPath = (...args) => path([NAMESPACE, "add", ...args]);
const editPath = (...args) => path([NAMESPACE, "edit", ...args]);
const uiPath = (...args) => path([NAMESPACE, "ui", ...args]);

export const getLoading = uiPath("loading");
export const getGenePanels = dataPath("genePanelData");
export const getParentGenePanels = dataPath("parentGenePanelData");

// ADD NEW GENE PANEL SELECTORS
export const isNewGenePanelAdded = addPath("new", "added");
export const isNewGenePanelHasError = addPath("new", "error");

// UPLOAD GENE PANEL SELECTORS
export const isProcessingGenePanels = addPath("upload", "processing");
export const getTemporaryGenePanelId = addPath("upload", "tempGenePanelId");
export const getTemporaryGenePanel = addPath("upload", "tempGenePanel");
export const getTemporaryGenePanelFormData = createSelector(
  getTemporaryGenePanel,
  tempGenePanel =>
    tempGenePanel && {
      title: tempGenePanel.title,
      description: tempGenePanel.description,
    }
);
export const getTempGeneByNameState = addPath("upload", "genesByName");
export const getFoundGeneNamesState = addPath("upload", "foundGeneNames");
export const getNotFoundGeneNamesState = addPath("upload", "notFoundGeneNames");

export const getFoundGenes = createSelector(
  getTempGeneByNameState,
  getFoundGeneNamesState,
  (genesByName, genesList) => genesList.map(geneName => genesByName[geneName])
);
export const getFoundGenesLoading = addPath("upload", "foundGenesLoading");
export const getNotFoundGenes = createSelector(
  getTempGeneByNameState,
  getNotFoundGeneNamesState,
  (genesByName, genesList) => genesList.map(geneName => genesByName[geneName])
);

export const getTempGenePanelLoading = addPath("upload", "loadingData");
export const getFoundGenesCount = addPath("upload", "foundGenesCount");
export const getNotFoundGenesCount = addPath("upload", "notFoundGenesCount");

export const getSingleGene = dataPath("geneInfo", "gene");
export const getSingleGeneColumnsToSkip = dataPath("geneInfo", "skipCols");
export const getSingleGeneKeys = dataPath("geneInfo", "keys");

export const getEditTempGene = addPath("upload", "editGene", "gene");
export const getEditTempGeneKeys = addPath("upload", "editGene", "keys");
export const getEditTempGeneFields = addPath("upload", "editGene", "fields");
export const getEditTempGeneFormValues = createSelector(
  getEditTempGene,
  getEditTempGeneKeys,
  getEditTempGeneFields,
  (gene, columns, fields) => {
    if (!gene || !columns || !fields) return undefined;
    const values = {};
    columns.forEach(column => {
      const field = fields[column];
      if (field && field.input && field.optional !== "No") {
        values[column] = gene[column] !== null ? gene[column] : false;
      }
    });
    return values;
  }
);

// Edit gene panel selectors
export const getGenePanelInfo = editPath("genePanelInfo");
export const getGenePanelCounts = createSelector(getGenePanelInfo, info =>
  info ? { patientCount: info.patientCount, geneCount: info.genesCount } : {}
);

export const getGenePanelInfoFormData = createSelector(
  getGenePanelInfo,
  info => {
    if (!info) return {};
    return pick(["title", "description"])(info);
  }
);
export const isGenePanelInfoLoading = editPath("loading");
export const getGenePanelPatients = editPath("patients");
export const getGenePanelProjectPatients = editPath("projectPatients");
export const isGenePanelPatientListLoading = editPath("patientListLoading");
export const getGenePanelPatientsCombined = createSelector(
  getGenePanelProjectPatients,
  getGenePanelPatients,
  (projectPatients, panelPatients) => {
    if (!projectPatients || !panelPatients) return [];

    return projectPatients.map(patient => ({
      ...patient,
      isSelected: !!panelPatients[patient.patientId],
    }));
  }
);

export const isAllGenePanelPatientsSelected = createSelector(
  getGenePanelProjectPatients,
  getGenePanelPatients,
  (projectPatients, panelPatients) => {
    if (!projectPatients || !panelPatients) return true;

    const selectedPatientsCount = projectPatients
      .map(patient => !!panelPatients[patient.patientId])
      .filter(Boolean).length;

    return selectedPatientsCount === projectPatients.length;
  }
);
export const getGenePanelWritePermissions = editPath("writePermissions");

export const getGeneByNameState = editPath("genesByName");
export const getGeneNamesState = editPath("geneNames");

export const getGenesList = createSelector(
  getGeneByNameState,
  getGeneNamesState,
  (genesByName, genesList) => genesList.map(geneName => genesByName[geneName])
);

export const getEditGene = editPath("editGene", "gene");
export const getEditGeneKeys = editPath("editGene", "keys");
export const getEditGeneFields = editPath("editGene", "fields");
export const getEditGeneFormValues = createSelector(
  getEditGene,
  getEditGeneKeys,
  getEditGeneFields,
  (gene, columns, fields) => {
    if (!gene || !columns || !fields) return undefined;
    const values = {};
    columns.forEach(column => {
      const field = fields[column];
      if (field && field.input && field.optional !== "No") {
        values[column] = gene[column] !== null ? gene[column] : false;
      }
    });
    return values;
  }
);

export const getSearchGeneResultGenes = editPath("searchResult", "genes");
export const getSearchGeneResultPanelGenes = editPath(
  "searchResult",
  "panelGenes"
);

export const getSearchGeneResult = createSelector(
  getSearchGeneResultGenes,
  getGeneByNameState,
  getGeneNamesState,
  (genes, panelGenes, panelGeneNames) => {
    if (!genes || !panelGenes) return [];
    return genes.map(gene => {
      const additionalData = {};
      if (!!panelGenes[gene.name]) {
        additionalData.autosomalRecessive =
          !!panelGenes[gene.name].autosomalRecessive;
        additionalData.autosomalDominant =
          !!panelGenes[gene.name].autosomalDominant;
        additionalData.xLinked = !!panelGenes[gene.name].xLinked;
      }
      return {
        ...gene,
        ...additionalData,
        inPanel: panelGeneNames.includes(gene.name),
      };
    });
  }
);

export const getSearchGeneQuery = editPath("searchQuery");
export const isGenesSearchInProgress = editPath("genesSearchInProgress");

export const getSearchGeneFields = (state: any, geneName: string) =>
  editPath("searchTableFields", geneName)(state);

export const getSearchGeneFieldValue = (
  state: any,
  geneName: string,
  fieldName: string
) => editPath("searchTableFields", geneName, fieldName)(state);
