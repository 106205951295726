import { set } from "dot-prop-immutable";

import { Action } from "./actions";
import * as constants from "./constants";
import { VIEW_AS_VALUES } from "./constants";
import { State } from "./types";

export const initialState: State = {
  data: {
    filterData: {},
    patientOverlaps: [],
  },
  ui: {
    loading: false,
    filters: {},
    columns: [],
    viewMode: VIEW_AS_VALUES.list,
  },
};

export default function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case constants.SET_SV_FILTERS:
      return set(state, "ui.filters", action.payload);
    case constants.SET_SV_FILTER_COLUMNS:
      return set(state, "ui.columns", action.payload);
    case constants.SET_SV_FILTER_DATA:
      return set(state, "data.filterData", action.payload);
    case constants.SET_LOADING:
      return set(state, "ui.loading", action.payload);
    case constants.FETCH_PATIENT_OVERLAPS_SUCCESS:
      return set(state, "data.patientOverlaps", action.payload);
    case constants.FETCH_PATIENT_OVERLAPS_FAILURE:
      return set(state, "data.patientOverlaps", []);
    case constants.SET_SV_VIEW_MODE:
      return set(state, "ui.viewMode", action.payload);
    default:
      return state;
  }
}
