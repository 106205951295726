export const NAME = "messages" as const;

export type MessageType = "danger" | "warning" | "info" | "success";

export const SET_MESSAGE = "SET_MESSAGE" as const;
export const DISMISS_MESSAGE = "DISMISS_MESSAGE" as const;
export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE" as const;
export const COLLAPSE = "COLLAPSE" as const;
export const DISMISS_ALL = "DISMISS_ALL" as const;

export const REQUIRED_FIELD_MESSAGE = "Required field";
export const SET_MESSAGES = "SET_MESSAGES" as const;
