export const MODULE_NAME = "curatedVariantsLists";
export const SET_LOADING = "SET_LOADING";
export const SET_VARIANTS_LOADING = "SET_VARIANTS_LOADING";
export const SET_CVL_AUDIT_LOADING = "SET_CVL_AUDIT_LOADING";
export const INIT_CURATED_LISTS = "INIT_CURATED_LISTS";
export const SET_CURATED_LISTS = "SET_CURATED_LISTS";
export const SET_SELECTED_CVL = "SET_SELECTED_CVL";
export const SHOW_EXISTING_CVLS = "SHOW_EXISTING_CVLS";
export const SNV_CVL_VARIANTS_TYPE = "SNV";
export const CNV_CVL_VARIANTS_TYPE = "CNV";
export const DISPLAY_MODE_NONE = "DISPLAY_MODE_NONE";
export const DISPLAY_MODE_CVL = "DISPLAY_MODE_CVL";
export const DISPLAY_MODE_ADD_EXISTING = "DISPLAY_MODE_ADD_EXISTING";
export const CURATED_LIST_FORM = "CURATED_LIST_FORM";
export const SUBMIT_CVL = "SUBMIT_CVL";
export const SET_CVL_OPTIONS = "SET_CVL_OPTIONS";
export const RESET_DISPLAY_MODE = "RESET_DISPLAY_MODE";
export const REMOVE_CVL_FROM_PROJECT = "REMOVE_CVL_FROM_PROJECT";
export const GET_CVL_DETAILS = "GET_CVL_DETAILS";
export const GET_CVL_VARIANTS = "GET_CVL_VARIANTS";
export const SET_SELECTED_VARIANTS = "SET_SELECTED_VARIANTS";
export const GET_CVL_AUDIT = "GET_CVL_AUDIT";
export const SET_CVL_AUDIT = "SET_CVL_AUDIT";
export const EXPORT_CVL_VARIANTS = "EXPORT_CVL_VARIANTS";
export const SET_EXPORT_MODAL = "SET_EXPORT_MODAL";
export const SET_EXPORT_LOADING = "SET_EXPORT_LOADING";
export const SET_RESET_DISPLAY_MODE = "SET_RESET_DISPLAY_MODE";

// CVL EXPORT CUSTOM HTTP ERROR CODE
export const EXPORT_MISSING_MANDATORY_FIELD_STATUS = 422;

// CVL EXPORT TOOLTIPS
export const EXPORT_TOOLTIP_DISABLED = "This list has no variants to export";
export const EXPORT_TOOLTIP_ENABLED =
  "Export list as Comma Separated Value (CSV) file";

//FIELD NAMES
export const ID_FIELD = "curatedVariantListId";
export const ORIGIN_PROJECT_ID_FIELD = "originProjectId";
export const NAME_FIELD = "name";
export const PARENT_PROJECT_FIELD = "parentProjectName";
export const DESCRIPTION_FIELD = "description";
export const VARIANTS_TYPE_FIELD = "variantType";
export const VARIANT_COUNT_FIELD = "variantCount";
export const PROJECT_COUNT_FIELD = "projectCount";
export const USER_COUNT_FIELD = "userCount";
export const VALIDATION_STATUS_FIELD = "validationStatus";
export const LAST_UPDATED_FIELD = "updated";
export const LAST_CURATOR_NAME_FIELD = "lastCuratorName";
export const OWNER_NAME_FIELD = "ownerName";
export const OWNER_EMAIL_FIELD = "ownerEmail";
export const AUTOMATION_FIELD = "isAutomatedAnnotationDecisions";
export const ELIGIBLE_FOR_AUTOMATED_ANNOTATION_DECISIONS_FIELD =
  "isEligibleForAutomatedAnnotationDecisions";
export const SHOW_PATHOGENICITY_FIELD = "showPathogenicity";
export const LIST_TYPE_FIELD = "type";
export const CURATORS_FIELD = "curators";
export const CAN_VIEW_IN_ORIGIN_PROJECT_FIELD = "canViewInOriginProject";
export const CAN_EDIT_IN_ORIGIN_PROJECT_FIELD = "canEditInOriginProject";
export const REMOVE_CVL = "REMOVE_CVL";
export const ELIGIBLE_FOR_AUTOMATION_WARNING =
  "CVL contains outdated Ensembl data and cannot be used for Automation";
export const CVL_SEARCH_FIELDS = [
  NAME_FIELD,
  PARENT_PROJECT_FIELD,
  DESCRIPTION_FIELD,
  LIST_TYPE_FIELD,
  VALIDATION_STATUS_FIELD,
  VARIANTS_TYPE_FIELD,
  VARIANT_COUNT_FIELD,
  PROJECT_COUNT_FIELD,
  USER_COUNT_FIELD,
  OWNER_NAME_FIELD,
  LAST_UPDATED_FIELD,
];

export const SET_EXISTING_LISTS_AVAILABLE = "SET_EXISTING_LISTS_AVAILABLE";
export const LOAD_EXISTING_LISTS = "LOAD_EXISTING_LISTS";
export const SET_EXISTING_LISTS = "SET_EXISTING_LISTS";
export const ADD_EXISTING_LIST = "ADD_EXISTING_LIST";
export const SET_EXISTING_LIST_STATUS = "SET_EXISTING_LIST_STATUS";
export const SET_NEED_UPDATE = "SET_NEED_UPDATE";

//CVL statuses
export const SUCCESS_STATUS = "Success";
export const FAILED_STATUS = "Failed";
export const LOADING_STATUS = "Loading";

// CVL Variant Field Names
export const VARIANT_NAME_FIELD = "name";
export const VARIANT_LAST_UPDATED_FIELD = "updated";
export const VARIANT_CHROMOSOME_FIELD = "chr";
export const VARIANT_START_POS_FIELD = "start";
export const VARIANT_END_POS_FIELD = "end";
export const VARIANT_REF_ALLELE_FIELD = "refAllele";
export const VARIANT_ALT_ALLELE_FIELD = "altAllele";
export const VARIANT_GENE_NAME_FIELD = "name";
export const VARIANT_GENE_HGNC_ID_FIELD = "hgncId";
export const VARIANT_PATHOGENICITY_FIELD = "pathogenicity";
export const VARIANT_LAST_CURATOR_NAME_FIELD = "fullName";
export const LAST_CURATOR_EMAIL_FIELD = "lastCuratorEmail";

export const CVL_STATUSES = [
  { value: "processing_complete", label: SUCCESS_STATUS },
  { value: "validation_failed", label: FAILED_STATUS },
  { value: "pending", label: LOADING_STATUS },
];
