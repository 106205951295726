import classNames from "classnames";
import { Field, FastField } from "formik";
import PropTypes from "prop-types";
import React, { memo } from "react";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

import { getPossibleParent } from "../../utils";
import { FamilyNameField } from "../FamilyNameField";
import { IdentifierField } from "../IdentifierField";
import { ParentField } from "../ParentField";
import RemovalCell from "../RemovalCell";
import { affectionStatusOptions, genderOptions } from "../componentUtils";

const SamplesTableRow = memo(
  ({
    sample,
    index,
    isActiveRow,
    setSelectedRow,
    fieldsDisabled,
    samples = [],
    removeRowHandler,
    fileTypesOptions = [],
  }) => {
    const { name, fileExists } = sample;

    const handleClick = rowNumber => () => {
      setSelectedRow(rowNumber);
    };

    const rowClasses = classNames({
      "ir-table-selected-row": isActiveRow,
    });

    return (
      <tr onClick={handleClick(index)} className={rowClasses}>
        <td className="number-cell">
          <span>{index + 1}</span>
        </td>
        <td>
          <Field
            key={`samples.${index}.name`}
            component={IdentifierField}
            name={`samples.${index}.name`}
            id={`samples.${index}.name`}
            disabled={!!fileExists || fieldsDisabled}
            type="text"
          />
        </td>
        <td>
          <FastField
            name={`samples.${index}.isProband`}
            id={`samples.${index}.isProband`}
            component={FormikFormField}
            type="checkbox"
            disabled={fieldsDisabled}
            className="table-cell-checkbox"
          />
        </td>
        <td>
          <Field
            name={`samples.${index}.sex`}
            component={FormikFormField}
            type="dropdown"
            options={genderOptions}
            disabled={
              fieldsDisabled ||
              !!samples.find(
                ({ motherName, fatherName }) =>
                  !!name && (name === motherName || name === fatherName)
              )
            }
          />
        </td>
        <td>
          <FastField
            name={`samples.${index}.affectionStatus`}
            component={FormikFormField}
            type="dropdown"
            options={affectionStatusOptions}
            disabled={fieldsDisabled}
          />
        </td>
        <td>
          <Field
            name={`samples.${index}.motherName`}
            component={ParentField}
            type="dropdown"
            options={getPossibleParent(samples, sample, true)}
            disabled={fieldsDisabled}
            canBeEmpty
          />
        </td>
        <td>
          <Field
            name={`samples.${index}.fatherName`}
            component={ParentField}
            type="dropdown"
            options={getPossibleParent(samples, sample, false)}
            disabled={fieldsDisabled}
            canBeEmpty
          />
        </td>
        <td>
          <Field
            name={`samples.${index}.familyName`}
            id={`samples.${index}.familyName`}
            component={FamilyNameField}
            placeholder="Family"
            type="text"
            disabled={fieldsDisabled}
          />
        </td>
        <td colSpan={fileTypesOptions.length}>
          <FastField
            name={`samples.${index}.fileTypes`}
            id={`samples.${index}.fileTypes`}
            component={FormikFormField}
            type="checkboxes"
            disabled={fieldsDisabled}
            options={fileTypesOptions}
            className="table-cell-checkbox"
          />
        </td>
        <td>
          <RemovalCell
            index={index}
            samples={samples}
            fieldsDisabled={fieldsDisabled}
            removeRowHandler={removeRowHandler}
            tooltip="Remove patient"
          />
        </td>
      </tr>
    );
  }
);

SamplesTableRow.propTypes = {
  sample: PropTypes.shape({
    name: PropTypes.string,
    fileExists: PropTypes.bool,
  }).isRequired,
  isActiveRow: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  setSelectedRow: PropTypes.func.isRequired,
  fieldsDisabled: PropTypes.bool.isRequired,
  samples: PropTypes.array.isRequired,
  removeRowHandler: PropTypes.func.isRequired,
};

export default SamplesTableRow;
