import { set } from "dot-prop-immutable";

import {
  FAILURE_STATUS,
  IN_PROGRESS_STATUS,
  SUCCESS_STATUS,
} from "../../common/constants";

import * as constants from "./constants";

export const initialState = {
  data: {
    metadataFields: [],
    metadata: {},
  },
  ui: {
    metadataRequestStatus: null,
  },
};

const setMetadata = (state, status, fields = []) => ({
  ...state,
  data: {
    ...state.data,
    metadataFields: fields,
  },
  ui: {
    ...state.ui,
    metadataRequestStatus: status,
  },
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.SET_METADATA:
      return set(state, "data.metadata", action.payload);

    case constants.HAS_MADE_REQUEST:
      return setMetadata(state, IN_PROGRESS_STATUS);

    case constants.SET_METADATA_FIELDS:
      return setMetadata(state, SUCCESS_STATUS, action.payload);

    case constants.FETCH_METADATA_FIELDS_FAILED:
      return setMetadata(state, FAILURE_STATUS);

    default:
      return state;
  }
}
