import { isNil, toUpper } from "ramda";
import React from "react";

import { Link } from "pattern-library";

import { CVL_STATUSES } from "../../constants";
import {
  CuratedDataSchema,
  CuratedDataSchemaItem,
  CVLAuditFormattedAction,
  Field,
  FormattedDelta,
  FormattedField,
  Patient,
} from "../../types";
import { getCuratedDataValue, getCvlTypeLabel } from "../../utils";

import { auditActionEntities, auditActions } from "./constants";

const formatPerlBoolean = (value: number | undefined): string =>
  value ? "yes" : "no";

const formatPatient = (patient: Patient): JSX.Element => {
  const { id, reference } = patient;
  return (
    <Link href={`/patient/${id}`} target="_blank" rel="noopener noreferrer">
      {reference}
    </Link>
  );
};

const formatCVLStatus = (apiValue: string): string => {
  const status = CVL_STATUSES.find(({ value }) => value === apiValue);
  return status ? status.label : apiValue;
};

const cvlConstantFields: Array<Field> = [
  { accessor: "name", label: "Name" },
  {
    accessor: "isAutomatedAnnotationDecisions",
    label: "Automation",
    formatValue: formatPerlBoolean,
  },
];
const cvlDeltaValuesFields: Array<Field> = [
  { accessor: "description", label: "Description" },
  { accessor: "status", label: "Status", formatValue: formatCVLStatus },
];

const cvConstantFields: Array<Field> = [
  { accessor: "name", label: "Variant Name" },
  { accessor: "geneName", label: "Gene name" },
  { accessor: "pathogenicity", label: "Pathogenicity" },
  {
    accessor: "patient",
    label: "Original patient",
    formatValue: formatPatient,
    optional: true,
  },
];
const cvDeltaValuesFields: Array<Field> = [];

const projectCVLConstantFields: Array<Field> = [
  { accessor: "type", label: "List type", formatValue: getCvlTypeLabel },
  {
    accessor: "showPathogenicity",
    label: "Show pathogenicity",
    formatValue: formatPerlBoolean,
  },
];
const projectCVLDeltaValuesFields: Array<Field> = [];

const userCVLConstantFields: Array<Field> = [
  { accessor: "userName", label: "Username" },
  {
    accessor: "role",
    label: "Role",
  },
];
const userCVLDeltaValuesFields: Array<Field> = [];

const entityFieldsMap = {
  [auditActionEntities.CVL]: {
    constant: cvlConstantFields,
    delta: cvlDeltaValuesFields,
    all: [...cvlConstantFields, ...cvlDeltaValuesFields],
  },
  [auditActionEntities.CURATED_VARIANT]: {
    constant: cvConstantFields,
    delta: cvDeltaValuesFields,
    all: [...cvConstantFields, ...cvDeltaValuesFields],
  },
  [auditActionEntities.PROJECT_CVL]: {
    constant: projectCVLConstantFields,
    delta: projectCVLDeltaValuesFields,
    all: [...projectCVLConstantFields, ...projectCVLDeltaValuesFields],
  },

  [auditActionEntities.USER_CVL]: {
    constant: userCVLConstantFields,
    delta: userCVLDeltaValuesFields,
    all: [...userCVLConstantFields, ...userCVLDeltaValuesFields],
  },
};

export const getCVCuratedDataFields = (
  curatedDataSchema: CuratedDataSchema
): Array<Field> =>
  Object.keys(curatedDataSchema).map((key: string) => {
    const schemaItem: CuratedDataSchemaItem = curatedDataSchema[key];
    const { code, type, title, items } = schemaItem;
    const { type: arrayItemsType } = items || {};

    return {
      accessor: code,
      label: title,
      formatValue: value => getCuratedDataValue(type, value, arrayItemsType),
    };
  });

const formatField = (field: Field, value: any): FormattedField => {
  const { formatValue, label } = field;
  return {
    label,
    value: formatValue ? formatValue(value) : value,
  };
};

const formatConstantFields = (
  fields: Array<Field>,
  valueObject: any
): Array<FormattedField> =>
  fields
    .filter(
      ({ accessor, optional }) => !(optional && isNil(valueObject[accessor]))
    )
    .map(field => {
      const { accessor } = field;
      return formatField(field, valueObject[accessor]);
    });

const formatDeltaFields = (
  fields: Array<Field>,
  valueObject: any,
  isOldValue: boolean
): Array<FormattedField> => {
  const deltaAttr = isOldValue ? "before" : "after";
  return fields
    .filter(({ accessor }) => valueObject.hasOwnProperty(accessor))
    .map(field => {
      const { accessor } = field;
      return formatField(field, valueObject[accessor][deltaAttr]);
    });
};

const processUpdateActionDelta = (
  formattedDelta,
  {
    actionEntity,
    relatedData = {},
    deltaForUi: { values: deltaValues = {} } = {},
  }: CVLAuditAction
) => {
  const entityFieldsSchema = entityFieldsMap[actionEntity];
  if (!entityFieldsSchema) {
    return;
  }
  formattedDelta.before = formatDeltaFields(
    entityFieldsSchema.all || [],
    deltaValues,
    true
  );
  formattedDelta.after = [
    ...formatConstantFields(entityFieldsSchema.constant || [], relatedData),
    ...formatDeltaFields(entityFieldsSchema.delta || [], deltaValues, false),
  ];
};

export const formatCVLAuditList = (
  list: Array<CVLAuditAction> = [],
  cvCuratedDataFields: Array<Field>
): Array<CVLAuditFormattedAction> =>
  list.map((action: CVLAuditAction) => {
    const {
      userAction,
      actionEntity,
      relatedData = {},
      deltaForUi: { curatedDataValues: deltaCuratedData = {} } = {},
    } = action;
    const formattedDelta: FormattedDelta = {
      before: [],
      after: [],
    };
    const entityFieldsSchema = entityFieldsMap[actionEntity];
    if (!entityFieldsSchema) {
      return {
        ...action,
        formattedDelta,
      };
    }

    const constantFields = entityFieldsSchema.constant || [];
    switch (userAction) {
      case auditActions.CREATE:
        formattedDelta.after = formatConstantFields(
          constantFields,
          relatedData
        );
        break;
      case auditActions.UPDATE:
        processUpdateActionDelta(formattedDelta, action);
        if (actionEntity === auditActionEntities.CURATED_VARIANT) {
          formattedDelta.before = formattedDelta.before.concat(
            formatDeltaFields(cvCuratedDataFields, deltaCuratedData, true)
          );
          formattedDelta.after = formattedDelta.after.concat(
            formatDeltaFields(cvCuratedDataFields, deltaCuratedData, false)
          );
        }
        break;
      case auditActions.DELETE:
        formattedDelta.before = formatConstantFields(
          constantFields,
          relatedData
        );
        break;
      default:
        break;
    }
    return {
      ...action,
      formattedDelta,
    };
  });

const equalIgnoreCase = (a: string, b: string): boolean =>
  toUpper(a) === toUpper(b);

export const isCuratedVariantCreateAction = ({
  actionEntity,
  userAction,
}: CVLAuditAction) =>
  equalIgnoreCase(actionEntity, auditActionEntities.CURATED_VARIANT) &&
  equalIgnoreCase(userAction, auditActions.CREATE);
