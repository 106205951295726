import { call, put, select, takeLatest } from "redux-saga/effects";

import * as api from "modules/api/sequenceVariants";
import { getOrReloadCurrentUser } from "modules/auth/saga";
import { error } from "modules/messages/actions";
import { getOrReloadCurrentPatient } from "modules/patient/saga";

import {
  actionType,
  fetchSequenceVariantSummary,
  fetchOtherPatientsSnvCounts,
  getOrReloadSequenceVariantSummary,
} from "./actions";
import { shouldReloadSummary } from "./components/utils";
import { getData } from "./selectors";
import { OtherPatientsSnvCounts } from "./types";

export function* init() {
  yield takeLatest(
    actionType.GET_OR_RELOAD_SEQUENCE_VARIANT_SUMMARY,
    getSequenceVariantsSummary
  );
  yield takeLatest(
    fetchOtherPatientsSnvCounts.START_ACTION,
    getOtherPatientsSnvCounts
  );
}

export function* getSequenceVariantsSummary({
  payload,
  meta,
}: ReturnType<typeof getOrReloadSequenceVariantSummary>) {
  try {
    const summaryData = yield select(getData, meta.path);
    if (!shouldReloadSummary(summaryData, payload)) {
      return;
    }

    yield put(fetchSequenceVariantSummary.start(payload, meta));
    yield call(getOrReloadCurrentPatient, payload.patientId);
    const response = yield call(api.getSummary, payload);
    if (!response.ok) {
      const { error: errorMessage } = response.payload;
      yield put(fetchSequenceVariantSummary.failure(errorMessage, meta));
      yield put(error(errorMessage));
      return;
    }
    yield put(fetchSequenceVariantSummary.success(response.payload, meta));
  } catch ({ message }) {
    yield put(fetchSequenceVariantSummary.failure(message, meta));
    yield put(error(message ? message : "Cannot load summary"));
  }
}

export function* getOtherPatientsSnvCounts({
  payload,
  meta,
}: ReturnType<typeof fetchOtherPatientsSnvCounts.start>): Generator<
  any,
  void,
  any
> {
  yield call(getOrReloadCurrentUser);
  const { patientId, variantId, geneName } = payload;
  try {
    const snvCounts: Array<OtherPatientsSnvCounts> = yield call(
      api.getOtherPatientsSnvCounts,
      patientId,
      variantId,
      geneName
    );

    yield put(fetchOtherPatientsSnvCounts.success(snvCounts, meta));
  } catch ({ message }) {
    yield put(fetchOtherPatientsSnvCounts.failure(message, meta));
    yield put(error(message));
  }
}
