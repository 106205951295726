import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { VariantColumn } from "pattern-library";

import * as ColumnComponents from "./columns";

export default class ColumnContainer extends PureComponent {
  static propTypes = {
    gene: PropTypes.shape({
      geneId: PropTypes.number.isRequired,
    }).isRequired,
    variant: PropTypes.shape({
      variantId: PropTypes.number.isRequired,
    }).isRequired,
    column: PropTypes.shape({
      key: PropTypes.string.isRequired,
      component: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      gene,
      variant,
      column: { key, component, ...columnProps },
    } = this.props;

    if (component && ColumnComponents[component]) {
      const Component = ColumnComponents[component];

      return (
        <Component
          key={`gene-${gene.geneId}-variant-${variant.variantId}-column-${key}`}
          className={`variant-column variant-column--${key}`}
          {...columnProps}
          variant={variant}
        />
      );
    }

    return (
      <VariantColumn
        key={`column-${key}-${gene.geneId}-${variant.variantId}`}
        {...columnProps}
        colKey={key}
        data={variant}
      />
    );
  }
}
