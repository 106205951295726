import { decamelize, camelize } from "humps";

const pathogenicityDecisionMapping = {
  pathogenic: 1,
  "likely pathogenic": 2,
  "unclear significance": 3,
  "uncertain significance": 3,
  benign: 5,
  "likely benign": 4,
  excluded: 6,
};

export function getClassForPathogenicity(
  decisions = [],
  type = "pathogenicity"
) {
  if (decisions.length) {
    const pathogenicities = [];
    decisions.forEach(({ decisionType, value }) => {
      if (decisionType === type && value) {
        pathogenicities.push(pathogenicityDecisionMapping[value.toLowerCase()]);
      }
    });
    if (!pathogenicities.length) {
      return "";
    }

    // get the lowest value
    const severity = Math.min(...pathogenicities);

    if (severity) {
      const entry = Object.entries(pathogenicityDecisionMapping).find(
        mapping => mapping[1] === severity
      );
      let pathogenicityClass = decamelize(camelize(entry[0]), {
        separator: "-",
      });

      if (pathogenicityClass === "unclear-significance") {
        pathogenicityClass = "uncertain-significance";
      }

      return pathogenicityClass;
    }
  }

  return "";
}
