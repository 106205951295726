import React from "react";

import AnnotationSourcesTable from "modules/annotationSources/components/AnnotationSourcesTable";
import VariantCVLAudit from "modules/patient/components/audit/rare-disease/VariantCVLAudit";

type Props = {
  patientId: number;
};

const mockVariantHash = {
  // Example of variant ID 456, on CVL ID 159, which has a corresponding CVL variant ID of 789:
  "159_456": 789,
};

const defaultVariantAuditProps = {
  projectId: 7,
  patientId: 525,
  variantId: 456,
  patientVariantId: 905754,
  variantHash: mockVariantHash,
  geneSymbol: "AARS2",
};

export const Container: React.FC<Props> = ({ patientId }) => (
  <div>
    <h1>Work in progress page - local development only</h1>
    <AnnotationSourcesTable patientId={patientId} />
    <h2>Example CVL audit functionality</h2>
    <h3>Example with gene that is present:</h3>
    <div style={{ paddingLeft: "300px" }}>
      <VariantCVLAudit {...defaultVariantAuditProps} />
    </div>

    <h3>Two examples with the same gene that is not present:</h3>
    <div style={{ paddingLeft: "300px", paddingBottom: "24px" }}>
      <VariantCVLAudit
        {...defaultVariantAuditProps}
        geneSymbol="SOMERANDOMGENE"
      />
    </div>
    <div style={{ paddingLeft: "300px" }}>
      <VariantCVLAudit
        {...defaultVariantAuditProps}
        geneSymbol="SOMERANDOMGENE"
      />
    </div>

    <h3>Examples where there are no eligible CVLs that can be used:</h3>
    <div style={{ paddingLeft: "300px", paddingBottom: "24px" }}>
      <VariantCVLAudit
        {...defaultVariantAuditProps}
        // This is the PROJECT_ID_RARE_DISEASE_MINIMAL ID,
        // but not imported as this is temporary functionality, and that file is in the test folder
        projectId={1000}
        variantHash={{}}
        geneSymbol="SOMEOTHERRANDOMGENE"
      />
    </div>
  </div>
);

export default Container;
