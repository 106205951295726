import { CSVFileRows } from "modules/utils/files/readers/types";

export type State = {
  ui: {
    irSubmissionStatus: RequestSubmissionStatus | null;
    projectRequestStatus: RequestSubmissionStatus | null;
    cnvCallingRequestStatus: RequestSubmissionStatus;
    cnvCallingRequestError: string | null;
    filesProcessing: boolean;
  };
  data: {
    protocols: Array<DictionaryItem>;
    sampleTypes: Array<DictionaryItem>;
    baitsets: Array<string>;
  };
};

export type DictionaryItem = {
  id: string;
  attributes: {
    name: string;
  };
};

export type SampleFileTypes = {
  [key in FORM_FILE_TYPES]?: boolean;
};

export type CnvReference = {
  projectId: number;
  name: string;
};

export type HpoTerm = {
  code: string;
  phenotype: string;
  presence: string;
};

export type SampleMetadata = {
  allocated_to: string;
  phenotype_summary: string;
  analysis_type: string;
  ETH: string;
  person_id: string;
  person_type: string;
};

export type IrSample = {
  name?: string;
  fileTypes?: SampleFileTypes;
  fileExists?: boolean;
  cnvAnalysisRequested?: boolean;
  genePanelIds?: Array<number>;
  hpoTerms?: Array<HpoTerm>;
  isProband?: boolean;
  metadata?: SampleMetadata;
  references?: Array<CnvReference>;
  sampleType?: string;
  files?: Array<IrFile>;
};

export type IrFile = {
  fileType: FileType;
  fileSubtype: FileSubType;
  fileUri?: string;
  fileName?: string;
  fileContents?: string;
  fileBase64Contents?: string;
};

export type FormFileTypes = {
  title: string;
  fileConfigs: Array<{
    type: FileType;
    subtype: FileSubType;
    extension: string;
  }>;
};

export type FileType = "vcf" | "bam" | "fastq";
export type FileSubType =
  | "snv"
  | "cnv"
  | "sv"
  | "standard"
  | "r1"
  | "r2"
  | "oncology_snvs_indels";

export enum FORM_FILE_TYPES {
  VCF = "vcf",
  SNV_VCF = "snv-vcf",
  SV_VCF = "sv-vcf",
  CNV_VCF = "cnv-vcf",
  BAM = "bam",
  FASTQ = "fastq",
}

export type SampleFileNamesGroupedByFileType = {
  [key in FORM_FILE_TYPES]?: Array<string>;
};

export interface SampleWithNameAndFiles {
  sampleName: string;
  files: SampleFileNamesGroupedByFileType;
}

export interface SamplesGroupedByName {
  [key: string]: SampleWithNameAndFiles;
}

export interface SampleFileTypesGroupedBySampleName {
  [key: string]: {
    fileTypesArray: Array<string>;
    sampleIndex: number;
  };
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export interface Baitset {
  name: string;
}

export type Baitsets = Record<string, Baitset>;

export interface CSVHeader {
  name: string;
  format?: () => any;
  required?: boolean;
}

export interface YupValidationError {
  name: string;
  path: string;
  type: string;
  message: string;
  params: {
    value: string | Array<string>;
    showValues?: boolean;
    hint?: string;
  };
}

export interface CSVUploadGroupedError {
  type: string;
  name: string;
  values: Set<string>;
  message: string;
  showValues: boolean;
  hint?: string;
}

export interface CSVValidationSchemaParams {
  csvRows: CSVFileRows;
  existingSamplesTypes: SampleFileTypesGroupedBySampleName;
  allHpoTerms: Array<{ hpoTermId: string; name: string }>;
  projectGenePanelsId: Array<number>;
  sampleTypeNames: Array<string>;
  protocolNames: Array<string>;
  isOnPrem: boolean;
  metadataFields: Array<{ name: string; required: boolean; fieldType: string }>;
}
