import { decamelize } from "humps";
import React from "react";

import { Link } from "pattern-library";

import {
  VARIANT_NAME_FIELD,
  VARIANT_CHROMOSOME_FIELD,
  VARIANT_START_POS_FIELD,
  VARIANT_END_POS_FIELD,
  VARIANT_LAST_UPDATED_FIELD,
  VARIANT_REF_ALLELE_FIELD,
  VARIANT_ALT_ALLELE_FIELD,
  VARIANT_GENE_HGNC_ID_FIELD,
  VARIANT_GENE_NAME_FIELD,
  VARIANT_PATHOGENICITY_FIELD,
  VARIANT_LAST_CURATOR_NAME_FIELD,
} from "../constants";
import { CuratedDataSchemaItem } from "../types";
import { formatCVLDate, getCuratedDataValue } from "../utils";

export const hiddenColumns = ["HGNC_ID", "GENE_NAME", "PATHOGENICITY"].map(c =>
  c.toUpperCase()
);

const getHeader = title => (
  <div className="curated-lists-fixed-header" title={title}>
    {title}
  </div>
);

const variantAccessor = code => `variant.${code}`;
const geneAccessor = code => `gene.${code}`;
const lastCuratorAccessor = code => `lastCurator.${code}`;

const getConstantColumn = (title, code) => ({
  Header: getHeader(title),
  id: decamelize(code),
  accessor: code,
});

const getUpdatedDateColumn = title => ({
  Header: getHeader(title),
  accessor: VARIANT_LAST_UPDATED_FIELD,
  sortType: "datetime",
  Cell: ({
    row: {
      original: { updated },
    },
  }) => formatCVLDate(updated),
});

const getLastCuratorColumn = title => ({
  Header: getHeader(title),
  accessor: lastCuratorAccessor(VARIANT_LAST_CURATOR_NAME_FIELD),
  id: "updater.fullname",
  Cell: ({
    row: {
      original: {
        lastCurator: { fullName, email },
      },
    },
  }) => (
    <Link
      href={`mailto:${email}?subject=About the Congenica curated list variant&body=Dear ${fullName},`}
    >
      {fullName}
    </Link>
  ),
});

export const getConstantColumns = () => [
  getConstantColumn("Chr", variantAccessor(VARIANT_CHROMOSOME_FIELD)),
  getConstantColumn("Start", variantAccessor(VARIANT_START_POS_FIELD)),
  getConstantColumn("End", variantAccessor(VARIANT_END_POS_FIELD)),
  getConstantColumn("Ref allele", variantAccessor(VARIANT_REF_ALLELE_FIELD)),
  getConstantColumn("Alt allele", variantAccessor(VARIANT_ALT_ALLELE_FIELD)),
  getConstantColumn("Variant name (HGVS)", VARIANT_NAME_FIELD),
  getConstantColumn("Gene name", geneAccessor(VARIANT_GENE_NAME_FIELD)),
  getConstantColumn("Gene ID (HGNC)", geneAccessor(VARIANT_GENE_HGNC_ID_FIELD)),
  getConstantColumn("Pathogenicity", VARIANT_PATHOGENICITY_FIELD),
  getUpdatedDateColumn("Last updated"),
  getLastCuratorColumn("Last curated by"),
];

const getDynamicColumn = (schemaItem: CuratedDataSchemaItem) => {
  const { code, type, title, items } = schemaItem;

  const { type: arrayItemsType } = items || {};

  return {
    Header: getHeader(title),
    accessor: ({ curatedData }) => {
      const value = curatedData[code];
      return getCuratedDataValue(type, value, arrayItemsType);
    },
    id: `curated_data.${code}`,
  };
};

const getDynamicColumns = curatedDataSchema =>
  curatedDataSchema
    .filter(({ code }) => !hiddenColumns.includes(code.toUpperCase()))
    .map(getDynamicColumn);

export const getColumns = curatedDataSchema => [
  ...getConstantColumns(),
  ...getDynamicColumns(curatedDataSchema),
];
