import { trim } from "ramda";
import React, { memo, useCallback } from "react";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";

export const IdentifierField = memo(props => {
  const {
    field: { value },
    form: {
      values: { samples = [] },
      setFieldValue,
    },
  } = props;

  const onChange = useCallback(
    e => {
      const {
        target: { value: targetValue },
      } = e;
      if (value) {
        samples.forEach(({ motherName, fatherName }, index) => {
          if (value === motherName) {
            setFieldValue(`samples.${index}.motherName`, trim(targetValue));
          }
          if (value === fatherName) {
            setFieldValue(`samples.${index}.fatherName`, trim(targetValue));
          }
        });
      }
    },
    [value, samples, setFieldValue]
  );

  return (
    <FormikFormField onChange={onChange} changeOnBlur {...props} trimOnBlur />
  );
});
