export const Colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED: "#B51919",
  LIGHT_RED: "#D92929",
  RED_BRIGHT: "#FF0000",
  PURPLE: "#8B668B",
  PURPLE_BRIGHT: "#EDC2C2",
  DARK_CYAN: "#0772A1",
  BLUE: "#428BCA",
  BLUE_DARK: "#0000FF",
  LIGHT_BLUE: "#7A82FF",
  CYAN: "#00FFFF",
  YELLOW: "#FFD700",
  LIGHT_YELLOW: "#FFDD73",
  ORANGE: "#CD9B1D",
  ORANGE_DARK: "#E36105",
  ORANGE_BRIGHT: "#FFA500",
  BROWN: "#C4784F",
  GREEN: "#00986A",
  LIGHT_GREEN: "#1DD300",
  LABEL_SHADOW: "rgba(0, 0, 0, 0.75)",
  MINE_SHAFT: "#333333",
  GREY: "#666666",
  DUSTY_GREY: "#999999",
  GREY_LIGHT: "#C2C2C2",
  SILVER: "#CCCCCC",
  WHISPER: "#EEEEEE",
};
