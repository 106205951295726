import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import Loading from "../elements/base/Loading";

export default class LoadingOverlay extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
    dataTestId: PropTypes.string,
  };

  render() {
    const { children, loading, dataTestId } = this.props;
    return (
      <div
        className={classNames("loading-overlay", {
          "loading-overlay-active": loading,
        })}
      >
        {loading && (
          <div className="message-overlay">
            <Loading data-testid={dataTestId} />
          </div>
        )}
        {children}
      </div>
    );
  }
}
