//@flow

import type { Saga } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import { getRoles } from "modules/api/users";
import { error, errors } from "modules/messages/actions";
import type { FetchDataResponse } from "modules/utils/fetchData";

import { readRolesFailure, readRolesSuccess } from "./actions";
import * as constants from "./constants";

export function* init(): Saga<void> {
  yield takeLatest(constants.READ_ROLES, fetchRoles);
}

export function* fetchRoles(): Saga<void> {
  try {
    const response: FetchDataResponse<Array<Role>> = yield call(getRoles);
    if (response.ok) {
      yield put(readRolesSuccess(response.payload || []));
    } else {
      const { errors: errorMessages = [] } = response;
      yield put(errors(errorMessages));
    }
  } catch (e) {
    yield put(readRolesFailure());
    yield put(error(e));
  }
}
