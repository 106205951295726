import { inflate } from "pako";
import { isNil, not } from "ramda";

import type BgZipHeader from "./BgzipDecompressor";

export default class BgzipBlock {
  _decompressedData: DataView | void;
  header: BgZipHeader;
  _footer: {
    crc32: number,
    dataSize: number,
  } | void;
  _rawBlock: ArrayBuffer;

  constructor(header: BgZipHeader, rawBlock: ArrayBuffer) {
    this.header = header;

    if (not(rawBlock instanceof ArrayBuffer)) {
      throw new Error(
        `rawBlock provided to BgzipBlock must be an ArrayBuffer, type ${typeof rawBlock} was passed`
      );
    }

    this._rawBlock = rawBlock;
  }

  get text() {
    const decoder = new TextDecoder();
    return decoder.decode(this.decompressedData);
  }

  get decompressedData() {
    // the maximum compressed or uncompressed data size is 64kb,
    // so this object will now be at least 128kb
    if (this._decompressedData === undefined) {
      // pako needs a Uint8Array
      this._decompressedData = inflate(new Uint8Array(this._rawBlock));
    }

    return this._decompressedData;
  }

  // the size of the block, i.e. header + compressed data + footer
  get size() {
    return this.header.subheader.bsize + 1;
  }

  get footer() {
    if (isNil(this._footer)) {
      const dv = new DataView(this._rawBlock);
      // footer is 8 bytes long
      this._footer = {
        crc32: dv.getUint32(this.size - 8, true),
        // this is the size of the uncompressed data
        dataSize: dv.getUint32(this.size - 4, true),
      };
    }

    return this._footer;
  }
}
