import classNames from "classnames";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Actions, Checkbox, Dropdown } from "pattern-library";

import { CURATED_LIST_TYPE_KNOWLEDGEBASE } from "common/constants";
import { isRequestInProgress, isRequestSuccessful } from "common/selectors";
import { CVLListType } from "common/types";

import { addExistingList } from "../actions";
import { getExistingListsTypesOptions } from "../selectors";
import { ProjectCuratedList } from "../types";

interface AddExistingListProps extends PropsFromRedux {
  curatedList: ProjectCuratedList;
}

export const AddExistingList = ({
  options,
  curatedList,
  addExistingList,
}: AddExistingListProps) => {
  const { curatedVariantListId, addToProjectStatus: status } = curatedList;
  const [listType, setListType] = useState<CVLListType>(
    CURATED_LIST_TYPE_KNOWLEDGEBASE
  );
  const [showPathogenicity, setShowPathogenicity] = useState<boolean>(true);

  const processing = isRequestInProgress(status);

  const listAdded = isRequestSuccessful(status);

  const AddControls = (
    <>
      <Dropdown
        options={options}
        className={classNames("cvl-type-dropdown")}
        value={listType}
        disabled={processing}
        onChange={e => setListType(e.target.value)}
      />
      <Checkbox
        name={curatedVariantListId}
        value={showPathogenicity}
        label="Show pathogenicity"
        disabled={processing}
        labelClass="add-existing-list-action__label"
        onChange={e => setShowPathogenicity(e.target.checked)}
      />
      <Actions
        actions={[
          {
            label: isRequestInProgress(status)
              ? "Adding curated variant list"
              : "Add list",
            disabled: processing,
            className: classNames("btn", {
              "btn-primary": !processing,
              "btn-danger": processing,
            }),
            type: "button",
            onClick: () =>
              addExistingList(
                curatedVariantListId,
                listType,
                showPathogenicity
              ),
          },
        ]}
      />
    </>
  );

  return (
    <>
      {listAdded && (
        <span className="curated-lists-view-existing-added">
          Curated variant list added
        </span>
      )}
      {!listAdded && AddControls}
    </>
  );
};

const mapStateToProps = state => ({
  options: getExistingListsTypesOptions(state),
});

const mapDispatchToProps = {
  addExistingList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddExistingList);
