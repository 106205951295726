// @flow
import { isEmpty, isNil } from "ramda";

import EOF from "modules/utils/files/EOF";
import SimpleFileReader from "modules/utils/files/readers/SimpleFileReader";

const processCSVFile = async (
  file: File,
  addReference: (reference: { projectId: string, name: string }) => void,
  info: (messageText: string) => void,
  warning: (messageText: string) => void
): Promise<void> => {
  const { name } = file;
  const fileReader = new SimpleFileReader(file);
  let result: EOF | Array<string> | void;

  let lineNumber: number = 1;
  const recordsWithInvalidValues: Array<number> = [];
  const recordsWithInvalidStructure: Array<number> = [];

  while (isNil(result)) {
    const line: EOF | Array<string> | void = await fileReader.getNextLine();

    if (line instanceof EOF) {
      result = line;
      break;
    }

    if (typeof line === "string") {
      const columns: Array<string> = line.split(",");
      if (columns.length < 2) {
        recordsWithInvalidStructure.push(lineNumber);
      } else {
        const [projectId, name] = columns.map(s => s.trim());
        if (!isEmpty(projectId) || !isEmpty(name)) {
          const isProjectIdValid = /^\d*$/.test(projectId);
          addReference({
            projectId: isProjectIdValid ? projectId : "",
            name,
          });
          if (!isProjectIdValid) {
            recordsWithInvalidValues.push(lineNumber);
          }
        } else {
          recordsWithInvalidStructure.push(lineNumber);
        }
      }
      lineNumber++;
    }
  }

  let message: string = `Records processed from file '${name}': ${
    lineNumber - 1
  }`;

  if (recordsWithInvalidStructure.length > 0) {
    message += `:\n- Records with invalid structure: ${recordsWithInvalidStructure.toString()}`;
  }
  if (recordsWithInvalidValues.length > 0) {
    message += `\n- Records with invalid data: ${recordsWithInvalidValues.toString()}`;
  }

  if (
    recordsWithInvalidStructure.length > 0 ||
    recordsWithInvalidValues.length > 0
  ) {
    warning(message);
  } else {
    info(message);
  }
};

const processFile = (
  file: File,
  addReference: (reference: { projectId: string, name: string }) => void,
  info: (messageText: string) => void,
  warning: (messageText: string) => void,
  error: (messageText: string) => void
): void => {
  const { name } = file;
  let extension: string;

  const exec: RegExp$matchResult | null = /(.*?)(.csv)/.exec(name);

  if (exec) {
    // eslint-disable-next-line
    extension = exec[2];
  } else {
    extension = name.substr(name.lastIndexOf("."));
  }

  // determine what type of file it is
  if (".csv" === extension) {
    processCSVFile(file, addReference, info, warning);
  } else {
    error(`Can not parse ${name}\nUnknown file type ${extension}`);
  }
};

export const processFiles = (
  files: Array<File>,
  addReference: (reference: { projectId: string, name: string }) => void,
  info: (messageText: string) => void,
  warning: (messageText: string) => void,
  error: (messageText: string) => void
): void => {
  files.forEach((file: File) =>
    processFile(file, addReference, info, warning, error)
  );
};
