import { CONFIG_GENE_PANELS, SET_CONFIG_GENE_PANELS } from "./constants";
import { groupGenesById } from "./utils";

export default function reducer(
  state = { [CONFIG_GENE_PANELS]: [], geneWrappersByGeneId: {} },
  action
): State {
  switch (action.type) {
    case SET_CONFIG_GENE_PANELS:
      const panels = action.payload.list;
      return {
        ...state,
        [CONFIG_GENE_PANELS]: panels,
        geneWrappersByGeneId: groupGenesById(panels),
      };

    default:
      return state;
  }
}
