export const NAMESPACE = "oncology-report";

export const SAMPLE_REGISTERED = "Sample registered";
export const PROCESSING = "Processing";
export const READY_FOR_REVIEW = "Ready for review";
export const PRIMARY_REVIEW = "Primary review";
export const FAILED = "Failed";
export const SECONDARY_REVIEW = "Secondary review";
export const FINAL_REVIEW_PENDING = "Final review pending";
export const REVIEW_COMPLETE = "Review complete";
export const REJECTED = "Rejected";
export const FILES_PENDING = "Files pending";
export const READY_TO_PROCESS = "Ready to process";
export const PROCESSING_FAILED = "Processing failed";
export const ARCHIVED = "Archived";
export const QC_CHECKED = "QC checked";

export const ACTION_UNDO_REVIEW_COMPLETE = "undo_review_complete";

export const ACTION_FAIL_QC = "fail_qc";
export const ACTION_PASS_QC = "pass_qc";
export const ACTION_UNDO_QC = "undo_qc";
export const ACTION_FAIL_SAMPLE = "fail_sample";
export const ACTION_APPROVE_REPORT = "approve_report";
export const ACTION_REJECT_REPORT = "reject_report";
export const ACTION_COMPLETE_REVIEW = "complete_review";
