// @flow
import { set } from "dot-prop-immutable";

import { actionType } from "./actions";
import type { Action } from "./actions";
import type { Variant } from "./flow-types";

type State = {
  +data: {
    +variants: AsyncData<Variant[]>,
    +totalCount: number,
  },
};

export const initialState: State = {
  data: {
    variants: { data: [], loading: false },
    totalCount: 0,
  },
};

export default function reducer(
  state: State = initialState,
  { type, payload }: Action = {}
): State {
  let newState;
  switch (type) {
    case actionType.FETCH_VARIANTS_START:
      newState = set(state, "data.variants", {
        data: [],
        loading: true,
      });
      return set(newState, "data.params", payload);

    case actionType.FETCH_VARIANTS_SUCCESS:
      newState = set(state, "data.variants", {
        data: payload.variants,
        loading: false,
      });
      return set(newState, "data.totalCount", payload.totalCount);

    case actionType.FETCH_VARIANTS_FAILURE:
      return set(state, "data.variants", {
        data: [],
        loading: false,
      });

    default:
      return state;
  }
}
