// @flow

import type { FetchDataResponse } from "./fetchData";

const getInvalidAttributes = attributes =>
  ["id", "type"].reduce((attrs, property) => {
    // If one of the properties is defined at all, then the API is "misbehaving"
    // and should not be returning this information
    if (typeof attributes[property] !== "undefined") {
      attrs.push(property);
    }

    return attrs;
  }, []);

export const flattenApiObject = (apiObject: APIObject): Object => {
  if (!apiObject) {
    return {};
  }
  const { id, attributes, type } = apiObject;
  const invalidAttributes = getInvalidAttributes(attributes);

  if (invalidAttributes.length) {
    throw new Error(
      `Invalid attribute(s) found on response: ${invalidAttributes.join(", ")}`
    );
  }

  return {
    ...attributes,
    id,
    type,
  };
};

export const flattenApiEntity = (apiEntity: APIEntity): Object => {
  if (!apiEntity) {
    return {};
  }

  return flattenApiObject(apiEntity.data);
};

export const flattenApiList = (apiList: APIList = []): Array<Object> =>
  apiList.map(flattenApiObject);

export const formatPaginatedResponse = (
  paginatedResponse: FetchDataResponse<any>,
  formatList?: Function
): PaginatedTableResponse<Object> => {
  const {
    ok,
    payload: { data, meta, recordsTotal } = {},
    errors = [],
  } = paginatedResponse;

  const count = meta ? meta.count : recordsTotal;

  if (ok) {
    return {
      list: formatList ? formatList(data) : flattenApiList(data),
      totalCount: count,
    };
  }
  return { errors };
};
