import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import { Field } from "redux-form";

import { Actions } from "pattern-library";

import ReduxFormField from "../../../forms/components/ReduxFormField";

export const PresetBaseForm = memo(props => {
  const {
    submitPreset,
    isDisabled,
    isDefaultDisabled,
    children,
    handleSubmit,
    pristine,
    reset,
    isSubmitting,
  } = props;

  const parsePresetAndSubmit = useCallback(
    preset => {
      preset.title = preset.title.trim();
      return submitPreset(preset);
    },
    [submitPreset]
  );

  return (
    <form onSubmit={handleSubmit(parsePresetAndSubmit)}>
      <div className="form-horizontal">
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <Field
              id="preset-title"
              name="title"
              label="Filter preset name"
              narrow
              component={ReduxFormField}
              type="text"
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-8 col-lg-6 text-primary inherited-preset-warning">
            {isDisabled && (
              <em>
                You cannot edit an inherited preset. Please return to the parent
                project to edit this preset
              </em>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 col-md-4 col-lg-3">
            <Field
              name="isActive"
              label="Active"
              narrow
              component={ReduxFormField}
              type="toggle"
              disabled={isDefaultDisabled}
              {...(isDefaultDisabled && {
                tooltip: {
                  content:
                    "Please make another preset default to make the current preset inactive",
                  placement: "right",
                },
              })}
            />
          </div>
          <div className="col-xs-6 col-md-4 col-lg-3">
            <Field
              name="isDefault"
              label="Default"
              narrow
              component={ReduxFormField}
              type="toggle"
              disabled={isDefaultDisabled}
              {...(isDefaultDisabled && {
                tooltip: {
                  content:
                    "Please make another preset default to make the current preset non-default",
                  placement: "right",
                },
              })}
            />
          </div>
        </div>
      </div>

      {children}

      <Actions
        actions={[
          {
            label: "Reset",
            className: classNames("btn btn-info"),
            disabled: pristine || isSubmitting,
            action: reset,
            type: "button",
          },
          {
            label: isSubmitting ? "Submitting" : "Save",
            disabled: pristine || isSubmitting,
            className: classNames("btn", {
              "btn-primary": !isSubmitting,
              "btn-info": isSubmitting,
            }),
            type: "submit",
          },
        ]}
      />
    </form>
  );
});

PresetBaseForm.displayName = "PresetBaseForm";
PresetBaseForm.propTypes = {
  submitPreset: PropTypes.func.isRequired,
};

export default PresetBaseForm;
