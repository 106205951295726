import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { PureComponent } from "react";

export class Case extends PureComponent {
  static displayName = "Case";
  static propTypes = {
    /**
     * Data to render inside the component
     */
    data: PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      color: PropTypes.string,
    }),
  };
  render() {
    const {
      data: { color, value, label },
    } = this.props;
    return (
      <div className="decision-cases-option">
        {!isNil(color) && (
          <div
            style={{ backgroundColor: color }}
            className="decision-cases-option-indicator"
          />
        )}
        <span>{isNil(label) ? value : label}</span>
      </div>
    );
  }
}
