import React, { FC, memo } from "react";

import { Download } from "pattern-library";

interface Props {
  disabled?: boolean;
  patientId: number;
  reportId: string;
}
const PatientGeneratedReportDownload: FC<Props> = ({
  patientId,
  reportId,
  disabled = false,
}) => (
  <Download
    url={`/patient/${patientId}/report/view/${reportId}`}
    filename={`${reportId}.pdf`}
    caption="Download Report"
    dataTestId="report-generated-download"
    context="default"
    disabled={disabled}
  />
);
export default memo(PatientGeneratedReportDownload);
