//@flow
import type { Saga } from "redux-saga";
import { call, put, takeEvery, fork } from "redux-saga/effects";

import * as api from "modules/api/variantHistory";
import type { FetchDataResponse } from "modules/utils";
import { processHeaders } from "modules/utils/sagas";

import * as actions from "./actions";
import type { VariantHistoryRecord } from "./flow-types";

const tasksByRequestId = {};

// $FlowFixMe
export function* init() {
  // $FlowFixMe
  yield takeEvery(
    actions.actionType.FETCH_VARIANT_HISTORY_START,
    watchGetVariantHistory
  );
}

/*
 * It cancels only requests with same requestId and running at the same time.
 * Needed to remove request data build up
 */
function* watchGetVariantHistory(params) {
  const { requestId } = params.payload;
  if (tasksByRequestId[requestId] && tasksByRequestId[requestId].isRunning()) {
    tasksByRequestId[requestId].cancel();
  }
  tasksByRequestId[requestId] = yield fork(getVariantHistory, params);
}

export function* getVariantHistory({
  payload: {
    requestId,
    patientId,
    patientVariantId,
    variantId,
    variantType,
    geneName,
  },
}: actions.InitialiseAction): Saga<void> {
  try {
    const result: FetchDataResponse<Array<VariantHistoryRecord>> = yield call(
      api.fetchVariantHistory,
      patientId,
      patientVariantId,
      variantId,
      variantType,
      geneName
    );
    yield call(processHeaders, result);
    if (!result.ok) {
      yield put(actions.fetchVariantHistory.failure());
      return;
    }

    yield put(actions.fetchVariantHistory.success(requestId, result.payload));
  } catch (e) {
    yield put(actions.fetchVariantHistory.failure());
  }
}
