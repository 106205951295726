import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class TrComponent extends PureComponent {
  static displayName = "Tr";

  static propTypes = {
    /**
     * Class to apply to the component
     */
    className: PropTypes.string,
    /**
     * Content to render inside the component
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { children, className, ...rest } = this.props;
    return (
      <tr className={className} role="row" {...rest}>
        {children}
      </tr>
    );
  }
}
