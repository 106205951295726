// @flow

class FASTQReader {
  file: File;

  constructor(file: File) {
    this.file = file;
  }

  getResult() {
    return {
      id: this.file.name.substring(0, this.file.name.indexOf("_")),
      name: this.file.name,
    };
  }
}

export default FASTQReader;
