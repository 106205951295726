//@flow

import type { Saga } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  getInterpretationRequest,
  getInterpretationRequests,
  removeProjectIR,
} from "modules/api";
import {
  dataFileReset,
  requestIRStatus,
} from "modules/api/interpretationRequest";
import { error, success, warning } from "modules/messages/actions";
import { getOrReloadCurrentProject } from "modules/project/saga";
import type { FetchDataResponse } from "modules/utils/fetchData";

import * as actions from "./actions";
import type { DeleteProjectIRAction, InitializeAction } from "./actions";

export function* init(): Saga<void> {
  yield takeLatest(actions.actionType.INIT, initialize);
  yield takeLatest(
    actions.actionType.FETCH_PROJECT_IRS_START,
    reloadInterpretationRequests
  );
  yield takeLatest(
    actions.actionType.DELETE_PROJECT_IR,
    deleteInterpretationRequest
  );
  yield takeLatest(actions.actionType.REFRESH_IR_STATUS_START, refreshIRStatus);
  yield takeLatest(actions.actionType.RESET_DATA_FILE_START, resetDataFile);
}

export function* initialize({
  payload: projectId,
}: InitializeAction): Saga<void> {
  yield call(getOrReloadCurrentProject, projectId);
  yield put(actions.fetchProjectIRs.start(projectId));
}

export function* reloadInterpretationRequests({
  payload: projectId,
}: actions.fetchProjectIRs.start): Saga<void> {
  try {
    yield put(actions.setProjectIRsLoading(true));
    const response: FetchDataResponse<ProjectInterpretationRequestsInfo> =
      yield call(getInterpretationRequests, projectId);
    if (response.ok) {
      yield put(actions.fetchProjectIRs.success(response.payload));
    } else {
      yield put(actions.fetchProjectIRs.failure());
      yield put(error("Cannot load interpretation requests"));
    }
  } catch (e) {
    yield put(actions.fetchProjectIRs.failure());
    yield put(error(e));
  } finally {
    yield put(actions.setProjectIRsLoading(false));
  }
}

export function* refreshIRStatus({
  payload: irId,
}: actions.refreshIRStatus.start): Saga<void> {
  try {
    const response: FetchDataResponse<{
      irId: number,
      dataFilesStatus: DataFilesStatus,
    }> = yield call(requestIRStatus, irId);
    if (response.ok) {
      yield put(actions.refreshIRStatus.success(response.payload));
    }
  } catch (e) {
    console.error("Unable to refresh files statuses");
    console.error(e.message);
  }
}

export function* resetDataFile({
  payload: dataFileId,
}: actions.resetDataFile.start): Saga<void> {
  try {
    const response: FetchDataResponse<any> = yield call(
      dataFileReset,
      dataFileId
    );
    if (response.ok) {
      yield put(actions.resetDataFile.success(dataFileId));
    } else {
      const { payload: { error: msg = "Unknown error" } = {} } = response;
      yield put(error(`Error Resetting Data File "${dataFileId}:\n${msg}`));
    }
  } catch (e) {
    yield put(error(e.message));
  }
}

export function* deleteInterpretationRequest({
  payload: { projectId, irId, reason },
}: DeleteProjectIRAction): Saga<void> {
  try {
    yield put(actions.setProjectIRDeleting(true));

    const response = yield call(removeProjectIR, irId, reason);
    if (response.ok) {
      yield put(
        success(
          `Interpretation requests "${irId}" is successfully removed from the project`
        )
      );
      yield call(updateInterpretationRequest, projectId, irId);
    } else {
      const { payload: { error: msg = "Unknown error" } = {} } = response;
      yield put(
        error(`Error Deleting Interpretation Request "${irId}:\n${msg}`)
      );
    }
  } catch (e) {
    yield put(error(e.message));
  } finally {
    yield put(actions.setProjectIRDeleting(false));
    yield put(actions.resetProjectIRForDelete());
  }
}

export function* updateInterpretationRequest(
  projectId: number | string,
  irId: number
): Saga<void> {
  try {
    const response: FetchDataResponse<InterpretationRequest> = yield call(
      getInterpretationRequest,
      irId
    );
    if (response.ok && response.payload) {
      yield put(actions.reloadProjectIR(response.payload));
    } else {
      throw new Error();
    }
  } catch (_) {
    yield put(
      warning(
        `Error updating interpretation request ${irId} - all interpretation requests will be reloaded"!`
      )
    );
    yield put(actions.fetchProjectIRs.start(projectId));
  }
}
