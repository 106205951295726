import classNames from "classnames";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Loading } from "pattern-library";
import { SinglePagePDFViewer } from "pattern-library/modules/pdf/SinglePagePDFViewer";

import { error } from "modules/messages/actions";
import { useStateSafe } from "modules/utils";

import catalystApi from "../../api/catalyst-api";
import congenicaApi from "../../api/congenica-api";

import { REVIEW_COMPLETE } from "./constants";

const { useGetPatientAndTabsQuery, useGetPatientStatusQuery } = catalystApi;
const { useGetLatestReportProposalQuery } = congenicaApi;

interface ReportParams {
  patientId: number;
  reportId?: string | null;
}

export function getGeneratedReport({
  patientId,
  reportId,
}: ReportParams): Promise<Response> {
  return fetch(`/patient/${patientId}/report/view/${reportId}`);
}

export function getReportPreview({
  patientId,
}: ReportParams): Promise<Response> {
  return fetch(`/patient/${patientId}/report/preview`, {
    credentials: "include",
    method: "POST",
  });
}

export function reportParams(status: Status): {
  className: string;
  dataTestId: string;
  fetchReport: (params: ReportParams) => Promise<Response>;
} {
  if (status === REVIEW_COMPLETE) {
    return {
      fetchReport: getGeneratedReport,
      dataTestId: "oncology-report",
      className: "oncology-report__report__document",
    };
  }
  return {
    fetchReport: getReportPreview,
    dataTestId: "oncology-report-preview",
    className: "oncology-report__report__preview__document",
  };
}
interface Props {
  className?: string;
  patientId: number;
  reportId?: string | null;
  dataTestId: string;
  status: Status;
  fetchReport: (params: ReportParams) => Promise<Response>;
}
const PDFReport = ({
  className,
  patientId,
  dataTestId,
  reportId,
  fetchReport,
}: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useStateSafe(false);
  const [pdf, setPdf] = useStateSafe(null);

  const { reference } = useGetPatientAndTabsQuery(
    { patientId },
    {
      selectFromResult: ({
        data: { patient: { reference } = {} as Patient } = {},
      }) => ({
        reference: reference ? reference : patientId,
      }),
    }
  );

  const { reportSummary, jurisdiction, reportServiceTemplateUuid } =
    useGetLatestReportProposalQuery(
      { patientId },
      {
        selectFromResult: ({
          data: { reportSummary, jurisdiction, reportServiceTemplateUuid } = {},
        }) => ({
          reportSummary,
          jurisdiction,
          reportServiceTemplateUuid,
        }),
      }
    );

  const { status } = useGetPatientStatusQuery(
    { patientId },
    {
      selectFromResult: ({ data: { status } = {} }) => ({
        status,
      }),
    }
  );

  const onError = useCallback(
    msg => {
      setPdf(null);
      dispatch(error(msg));
    },
    [setPdf, dispatch]
  );

  const reload = useCallback(async () => {
    if (patientId) {
      try {
        setLoading(true);
        const result = await fetchReport({
          patientId,
          reportId,
        });
        if (result.ok) {
          const blob = await result.blob();
          const reader = new FileReader();

          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const { result } = reader;
            if (
              typeof result === "string" &&
              result.includes("data:application/pdf")
            ) {
              setPdf(result);
            } else {
              onError("Wrong report pdf content");
            }
          };
          reader.onerror = function () {
            onError(`Failed to read pdf document!\n${reader.error}`);
          };
        } else {
          onError(
            `Error loading report\nStatus: ${result.status}${
              result.statusText ? ` Details: ` + result.statusText : ""
            }`
          );
        }
      } catch ({ message }) {
        onError(message);
      } finally {
        setLoading(false);
      }
    }
  }, [patientId, setLoading, fetchReport, reportId, setPdf, onError]);

  // We use AbortController for aborting fetch responses if change tab selection while pdf document stil is loading
  useEffect(() => {
    const abortController = new AbortController();
    reload();
    return () => {
      abortController.abort();
    };
  }, [
    patientId,
    reportId,
    reportSummary,
    jurisdiction,
    reportServiceTemplateUuid,
    status,
    reload,
  ]);

  return (
    <>
      <span data-testid={dataTestId} />
      {loading && <Loading />}
      {!loading && pdf && (
        <SinglePagePDFViewer
          className={classNames(className)}
          fileName={
            status === REVIEW_COMPLETE
              ? `${reportServiceTemplateUuid}.pdf`
              : `${reference}-report-preview.pdf`
          }
          file={pdf}
        />
      )}
    </>
  );
};

export default PDFReport;
