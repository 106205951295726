import { react as autoBind } from "auto-bind";
import classNames from "classnames";
import { withFormik } from "formik";
import { camelizeKeys } from "humps";
import { isNil } from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Actions, LoadingOverlay } from "pattern-library";

import { checkedForLoggedInUser } from "modules/auth/selectors";
import { readProject } from "modules/project/actions";

import * as constants from "../constants";
import { PROJECT_DETAILS_MODE_EDIT } from "../constants";
import { submitAndResetFormik, withProjectSettingsWrapper } from "../util";

import ProjectDetailsEditor, {
  getEditValidationSchema,
} from "./projectDetails/ProjectDetailsEditor";

export class ProjectDetails extends PureComponent {
  static displayName = "ProjectDetails";

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { readProject, projectId } = this.props;
    readProject(projectId);
  }

  render() {
    const {
      handleSubmit,
      dirty,
      submitting,
      isInitialDataLoaded,
      initialValues: { projectTypeInternalName },
    } = this.props;

    return (
      <LoadingOverlay loading={isInitialDataLoaded !== true}>
        <form
          onSubmit={handleSubmit}
          className="col-md-8 col-lg-6 form-horizontal clearfix"
        >
          <ProjectDetailsEditor
            mode={PROJECT_DETAILS_MODE_EDIT}
            projectType={projectTypeInternalName}
          />
          <Actions
            sticky
            actions={[
              {
                label: submitting ? "Submitting" : "Save",
                disabled: !dirty || submitting,
                className: classNames("btn", {
                  "btn-primary": !submitting,
                  "btn-info": submitting,
                }),
                type: "submit",
              },
            ]}
          />
        </form>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  isInitialDataLoaded: checkedForLoggedInUser(state),
});

const mapDispatchToProps = {
  readProject,
};

const ConnectedWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetails);

const FormikForm = withFormik({
  validationSchema: ({
    isCurrentUserSuperAdmin,
    initialValues: { projectTypeInternalName },
  }) =>
    getEditValidationSchema(isCurrentUserSuperAdmin, projectTypeInternalName),
  mapPropsToValues: ({ initialValues } = {}) => initialValues,
  handleSubmit: (...props) => {
    submitAndResetFormik(...props);
    if (!isNil(window.Sapientia)) window.Sapientia.getContent("#projects");
  },
  displayName: constants.PROJECT_DETAILS_FORM,
})(ConnectedWrapper);

export default withProjectSettingsWrapper({
  entityType: "projects",
  component: FormikForm,
  toValues: json => ({
    ...camelizeKeys(json.data.attributes),
  }),
  fromValues: (projectId, entityType, values) => {
    const { code, description, privateAf: private_af } = values;
    return {
      data: {
        id: projectId,
        type: entityType,
        attributes: {
          code,
          description,
          private_af,
        },
      },
    };
  },
  url: projectId => `/webapi/entities/projects/${projectId}`,
});
