import React from "react";

import STRContainer from "modules/str/Container";
import { default as SVColumnsDropdown } from "modules/structuralVariants/SVColumnsDropdown";
import SVFilterSidebar from "modules/structuralVariants/SVFilterSidebar";
import VariantsContainer from "modules/variants/Container";

export const getVariantsTabset = (patientId, projectId, tabs = {}) => [
  {
    name: "Sequence Variants",
    isHidden: !tabs.variantsSequence,
    component: VariantsContainer,
    componentProps: {
      patientId,
    },
    url: "/sequence",
    className: "pull-right",
    exact: false,
  },
  {
    name: "Structural Variants",
    isHidden: !tabs.variantsStructural,
    component: () => (
      <div className="flex">
        <SVFilterSidebar projectId={projectId} patientId={patientId} />
        <div className="flex-grow">
          <SVColumnsDropdown projectId={projectId} patientId={patientId} />
        </div>
      </div>
    ),
    componentProps: {
      patientId,
      projectId,
    },
    url: "/sv",
    className: "pull-right",
    exact: false,
  },
  {
    name: "Short tandem repeat variants",
    isHidden: !tabs.variantsShortTandemRepeats,
    component: STRContainer,
    componentProps: {
      patientId,
    },
    url: "/str",
    className: "pull-right",
    exact: false,
  },
];
