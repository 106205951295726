import classNames from "classnames";
import PropTypes from "prop-types";
import { path, sortBy } from "ramda";
import React, { useMemo, memo } from "react";

import { Link } from "pattern-library";
import { Table } from "pattern-library/elements/table7";

const generateSizeOptions = dataLength => {
  const maxOptionNumber = Math.min(5 * Math.ceil(dataLength / 5), 20);
  const options = [];
  for (let pageOption = 10; pageOption <= maxOptionNumber; pageOption += 5) {
    options.push(pageOption);
  }
  return options;
};

const sortByHpoQueryLabel = sortBy(path(["query", "label"]));

const defaultPageSize = 10;

const headers = [
  {
    Header: "Patient HPO provided",
    accessor: ({ query: { id, label, link } }) => (
      <Link href={link} target="_blank" rel="noopener noreferrer">
        {id}, {label}
      </Link>
    ),
  },
  {
    Header: "Disease HPO matched",
    accessor: ({ match: { id, label, link } }) => (
      <Link href={link} target="_blank" rel="noopener noreferrer">
        {id}, {label}
      </Link>
    ),
  },
];

export const HpoData = ({ data = [], className }) => {
  const pageSizeOptions = useMemo(
    () => generateSizeOptions(data.length),
    [data.length]
  );
  const sortedHpoTerms = useMemo(() => sortByHpoQueryLabel(data), [data]);
  return (
    <div>
      <Table
        className={classNames(className, "exomiser-pane")}
        columns={headers}
        data={sortedHpoTerms}
        defaultPageSize={defaultPageSize}
        showPagination={data.length > defaultPageSize}
        pageSizeOptions={pageSizeOptions}
        minRows={0}
        autoResetPage={false}
        noDataText="No HPO matches found"
      />
    </div>
  );
};

HpoData.propTypes = {
  /**
   * Array containing objects for each HPO query/match
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.object,
      match: PropTypes.object,
    })
  ),
};

export default memo(HpoData);
