import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { FormField } from "pattern-library";

export default class EmailField extends PureComponent {
  static displayName = "EmailField";

  static propTypes = {
    input: PropTypes.object.isRequired,
  };

  render() {
    const { input, meta, ...rest } = this.props;

    return <FormField {...input} {...meta} {...rest} />;
  }
}
