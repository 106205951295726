import { react as autoBind } from "auto-bind";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Loading } from "pattern-library";

import { showModal } from "modules/modalRegistry/actions";
import { modalTypes } from "modules/modalRegistry/constants";

import * as authSelectors from "../modules/auth/selectors";

import { Header, StickyToLeftHeader } from "./partials";

export class AuthLayout extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  showApiTokenModal() {
    const { showModal } = this.props;
    showModal(modalTypes.API_TOKEN_MODAL);
  }

  render() {
    const { checkedForLoggedInUser, className, user, children } = this.props;

    const dev = process.env.NODE_ENV === "development";

    // If we are waiting for find out if a user is authed show loading
    if (checkedForLoggedInUser === false) {
      return (
        <div className={className}>
          <Loading />
        </div>
      );
    }
    // If we know a user isn't authed, prompt them to login
    if (!user) {
      return (
        <div className={className}>
          <Header devLabel={dev} />
          <div className="row">
            <div className="col-sm-12">
              <div className="alert alert-error">
                <p>You need to log in</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // User is authed
    return (
      <div className={className}>
        <StickyToLeftHeader
          devLabel={dev}
          navData={{
            profile: {
              action: this.showApiTokenModal,
            },
          }}
          user={user}
        />
        <div className="container-fluid">{children}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: authSelectors.currentUser(state),
  checkedForLoggedInUser: authSelectors.checkedForLoggedInUser(state),
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
