import congenicaApi from "api/congenica-api";

const { useGetThirdPartyDataVersionsQuery } = congenicaApi;

const getEnsemblVersionLabel = ({ version, is_active }: Version) => {
  const label = `Ensembl ${version}`;
  if (is_active) {
    return `${label} (current system version)`;
  }
  return label;
};

const getEnsemblVersionOption = (version: Version) => ({
  label: getEnsemblVersionLabel(version),
  value: version?.version,
});

/**
 * Retrieves the Ensembl versions in the system in a format for use in dropdown/selects
 */
export const useEnsemblOptions = () =>
  useGetThirdPartyDataVersionsQuery(
    { name: "ENSEMBL" },
    {
      selectFromResult: result => ({
        ...result,
        ensemblOptions: (result?.data || []).map(version =>
          getEnsemblVersionOption(version)
        ),
        activeVersion: (result?.data || []).find(version => version.is_active)
          ?.version,
      }),
    }
  );
