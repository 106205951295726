import { call, put, select, take, takeLatest } from "redux-saga/effects";

import { getProjectCurrentUser } from "modules/api/currentUser";
import { getProject, getProjectTabCounts } from "modules/api/projects";
import { error } from "modules/messages/actions";

import * as actions from "./actions";
import * as constants from "./constants";
import { getCurrentProject } from "./selectors";

const isProjectExists = (project: any, projectId: number | string): boolean =>
  project && Number(project.projectId) === Number(projectId);

export function* init() {
  yield takeLatest(constants.READ_PROJECT, reloadProject);
  yield takeLatest(constants.READ_PROJECT_TAB_COUNTS, reloadProjectCounts);
  yield takeLatest(
    constants.READ_PROJECT_CURRENT_USER,
    reloadProjectCurrentUser
  );
  yield takeLatest(
    constants.RELOAD_CURRENT_PROJECT_CURRENT_USER,
    reloadCurrentProjectCurrentUser
  );
}

export function* reloadProject({
  payload: { projectId, rewriteExisting },
}: actions.ReadProjectAction) {
  try {
    if (!rewriteExisting) {
      const project = yield select(getCurrentProject);
      if (isProjectExists(project, projectId)) {
        return;
      }
    }
    const response = yield call(getProject, projectId, {
      credentials: "include",
    });

    if (!response.ok) {
      yield put(actions.readProjectFailure());
      yield put(error(response.payload.error));
      return;
    }

    const { project } = response.payload;
    yield put(actions.readProjectSuccess(project));

    yield put(actions.readProjectCurrentUser(projectId));
  } catch ({ message }) {
    yield put(actions.readProjectFailure());
    yield put(error(message ? message : "Cannot load project"));
  }
}

export function* reloadProjectCounts({
  payload: { projectId },
}: actions.ReadProjectCountAction) {
  try {
    const response = yield call(getProjectTabCounts, projectId, {
      credentials: "include",
    });
    if (!response.ok) {
      yield put(actions.readProjectCountFailure());
      yield put(error(response.payload.error));
      return;
    }

    yield put(actions.readProjectCountSuccess(response.payload));
  } catch ({ message }) {
    yield put(actions.readProjectCountFailure());
    yield put(error(message ? message : "Cannot load project tab counts"));
  }
}

export function* getOrReloadCurrentProject(projectId: number) {
  const project = yield select(getCurrentProject);
  if (isProjectExists(project, projectId)) {
    return project;
  }
  yield put(actions.readProject(projectId, false));

  const action: actions.ReadProjectSuccessAction = yield take(
    constants.READ_PROJECT_SUCCESS
  );
  return action.payload;
}

export function* reloadProjectCurrentUser({
  payload: { projectId },
}: actions.ReadProjectCurrentUserAction) {
  try {
    const response = yield call(getProjectCurrentUser, projectId, {
      credentials: "include",
    });
    if (!response.ok) {
      yield put(actions.readProjectCurrentUserFailure());
      yield put(error(response.payload.error));
      return;
    }

    yield put(actions.readProjectCurrentUserSuccess(response.payload));
  } catch ({ message }) {
    yield put(actions.readProjectCurrentUserFailure());
    yield put(error(message || "Cannot load project current user"));
  }
}

export function* reloadCurrentProjectCurrentUser() {
  const project = yield select(getCurrentProject);
  if (project) {
    yield put(actions.readProjectCurrentUser(project.projectId));
  }
}
