import {
  API_ENTITIES_BASE_URL,
  CATALYST_BASE_URL,
} from "modules/utils/baseUrls";
import {
  fetchCamelizeData,
  fetchPerlEndpointData,
} from "modules/utils/fetchData";

const prepareProjectInterpretationRequests = irs =>
  irs.map(ir => ({
    ...ir,
    canDelete: {
      ...ir.canDelete,
      canDelete: Boolean(ir.canDelete.canDelete),
    },
  }));

export async function getInterpretationRequests(projectId) {
  const result = await fetchPerlEndpointData(
    `/project/${projectId}/interpretation-requests`
  );
  if (result.ok) {
    const irs = result.payload.irs || [];
    return {
      ...result,
      payload: {
        ...result.payload,
        irs: prepareProjectInterpretationRequests(irs),
      },
    };
  }
  return result;
}

export function getInterpretationRequest(irId) {
  return fetchCamelizeData(
    `${CATALYST_BASE_URL}/interpretation_request/${irId}`
  );
}

export function postInterpretationRequest(interpretationRequest) {
  return fetch(`${CATALYST_BASE_URL}/interpretation_request_web`, {
    method: "POST",
    body: JSON.stringify(interpretationRequest),
    credentials: "include",
  });
}

export function removeProjectIR(irId, reason) {
  const formData = new FormData();
  formData.append("reason", reason);
  return fetchCamelizeData(
    `${CATALYST_BASE_URL}/interpretation_request/${irId}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
}

export async function requestIRStatus(irId) {
  const result = await fetchCamelizeData(
    `${API_ENTITIES_BASE_URL}/interpretation_requests/${irId}/data_files`
  );
  if (result.ok) {
    const extracted = {};
    const { payload: { data = [] } = {} } = result;
    data.forEach(({ id, attributes }) => {
      extracted[id] = { ...attributes };
    });
    return {
      ...result,
      payload: { irId, dataFilesStatus: extracted },
    };
  }
  return result;
}

export function dataFileReset(dataFileId) {
  return fetchCamelizeData(`${CATALYST_BASE_URL}/file/${dataFileId}/reset`);
}

export function createCNVAnalysisRequest(payload) {
  return fetch(`${CATALYST_BASE_URL}/cnv_analysis_request`, {
    method: "POST",
    body: JSON.stringify(payload),
    credentials: "include",
  });
}

export function getSampleTypes() {
  return fetch(`${API_ENTITIES_BASE_URL}/valid_sample_types`, {
    credentials: "include",
  });
}

export async function getOncoTree(substring) {
  if (substring.length >= 3) {
    const response = await fetchCamelizeData(
      `${CATALYST_BASE_URL}/reference/oncotree/search`,
      {
        method: "POST",
        body: JSON.stringify({
          substring,
        }),
        credentials: "include",
      }
    );
    if (response.ok) {
      const { searchResults } = response.payload || {};
      return searchResults
        ? searchResults.map(({ code, name }) => ({
            value: code,
            label: `${name} (${code})`,
          }))
        : [];
    }
  }

  return [];
}
