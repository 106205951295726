import { Field } from "formik";
import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Divider } from "pattern-library";

import { FormikFormField } from "../../../forms/components";
import { error } from "../../../messages/actions";

const data2options = data =>
  data.map(({ id, attributes: { name } }) => ({ label: name, value: id }));

export const normaliseMonitoredPath = (value = "", previousValue = "") => {
  if (!value) {
    return "";
  }
  return /^[a-zA-Z0-9_/]*$/.test(value)
    ? value.replace(/\/+/g, "/")
    : previousValue;
};

export const UploadService = memo(({ error, isInherited, enabled = false }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/webapi/entities/sftp_servers");

      if (response.ok) {
        const { data } = await response.json();
        setOptions(data2options(data));
      } else {
        error("Unable to load a list of servers");
      }
    }
    fetchData();
  }, [error]);

  return (
    <>
      <Field
        component={FormikFormField}
        name="dataFileUpload.enabled"
        label="Enable"
        type="checkbox"
        description="Check to enable SFTP based file upload"
        disabled={isInherited}
        data-testid="dataFileUploadCheckbox"
      />
      <Divider direction="horizontal" />
      <Field
        component={FormikFormField}
        name="dataFileUpload.monitoredPath"
        label="Monitored Path"
        normalize={normaliseMonitoredPath}
        description="Monitor this path in the SFTP server for uploaded files"
        disabled={isInherited || enabled !== true}
        data-testid="dataFileUploadMonitoredPath"
      />
      <Divider direction="horizontal" />
      <Field
        component={FormikFormField}
        name="dataFileUpload.uploadServer"
        label="Upload Server"
        type="select"
        options={options}
        isMulti={false}
        description="Select the SFTP server for this project"
        disabled={isInherited || enabled !== true}
      />
    </>
  );
});

const mapDispatchToProps = {
  error,
};

export default connect(null, mapDispatchToProps)(UploadService);
