import { createAction } from "../utils";

import {
  type GenePanel,
  type GenePanelInfo,
  type PatientOperationType,
} from "./flow-types";

export const actionType = Object.freeze({
  FETCH_GENE_START: "FETCH_GENE_START",
  FETCH_GENE_SUCCESS: "FETCH_GENE_SUCCESS",
  FETCH_GENE_FAILURE: "FETCH_GENE_FAILURE",
  FETCH_EDIT_TEMP_GENE_START: "FETCH_EDIT_TEMP_GENE_START",
  FETCH_EDIT_TEMP_GENE_SUCCESS: "FETCH_EDIT_TEMP_GENE_SUCCESS",
  FETCH_EDIT_TEMP_GENE_FAILURE: "FETCH_EDIT_TEMP_GENE_FAILURE",
  UPDATE_EDIT_TEMP_GENE_START: "UPDATE_EDIT_TEMP_GENE_START",
  UPDATE_EDIT_TEMP_GENE_SUCCESS: "UPDATE_EDIT_TEMP_GENE_SUCCESS",
  UPDATE_EDIT_TEMP_GENE_FAILURE: "UPDATE_EDIT_TEMP_GENE_FAILURE",
  RESET_EDIT_TEMP_GENE_STATE: "RESET_EDIT_TEMP_GENE_STATE",
  FETCH_GENE_PANELS_START: "FETCH_GENE_PANELS_START",
  FETCH_GENE_PANELS_SUCCESS: "FETCH_GENE_PANELS_SUCCESS",
  FETCH_GENE_PANELS_FAILURE: "FETCH_GENE_PANELS_FAILURE",
  REMOVE_GENE_FROM_TEMP_GENE_PANEL_START:
    "REMOVE_GENE_FROM_TEMP_GENE_PANEL_START",
  REMOVE_GENE_FROM_TEMP_GENE_PANEL_SUCCESS:
    "REMOVE_GENE_FROM_TEMP_GENE_PANEL_SUCCESS",
  REMOVE_GENE_FROM_TEMP_GENE_PANEL_FAILURE:
    "REMOVE_GENE_FROM_TEMP_GENE_PANEL_FAILURE",
  FETCH_GENE_PANEL_INFO_START: "FETCH_GENE_PANEL_INFO_START",
  FETCH_GENE_PANEL_INFO_SUCCESS: "FETCH_GENE_PANEL_INFO_SUCCESS",
  FETCH_GENE_PANEL_INFO_FAILURE: "FETCH_GENE_PANEL_INFO_FAILURE",
  FETCH_GENE_PANEL_GENE_LIST_START: "FETCH_GENE_PANEL_GENE_LIST_START",
  FETCH_GENE_PANEL_GENE_LIST_SUCCESS: "FETCH_GENE_PANEL_GENE_LIST_SUCCESS",
  FETCH_GENE_PANEL_GENE_LIST_FAILURE: "FETCH_GENE_PANEL_GENE_LIST_FAILURE",
  COPY_GENE_PANELS_START: "COPY_GENE_PANELS_START",
  COPY_GENE_PANELS_SUCCESS: "COPY_GENE_PANELS_SUCCESS",
  COPY_GENE_PANELS_FAILURE: "COPY_GENE_PANELS_FAILURE",

  EXECUTE_GENE_PANEL_PATIENT_OPERATION_START:
    "EXECUTE_GENE_PANEL_PATIENT_OPERATION_START",
  EXECUTE_GENE_PANEL_PATIENT_OPERATION_SUCCESS:
    "EXECUTE_GENE_PANEL_PATIENT_OPERATION_SUCCESS",
  EXECUTE_GENE_PANEL_PATIENT_OPERATION_FAILURE:
    "EXECUTE_GENE_PANEL_PATIENT_OPERATION_FAILURE",
  REMOVE_GENE_FROM_GENE_PANEL_START: "REMOVE_GENE_FROM_GENE_PANEL_START",
  REMOVE_GENE_FROM_GENE_PANEL_SUCCESS: "REMOVE_GENE_FROM_GENE_PANEL_SUCCESS",
  REMOVE_GENE_FROM_GENE_PANEL_FAILURE: "REMOVE_GENE_FROM_GENE_PANEL_FAILURE",
  FETCH_GENE_PANEL_PATIENT_LIST_START: "FETCH_GENE_PANEL_PATIENT_LIST_START",
  FETCH_GENE_PANEL_PATIENT_LIST_SUCCESS:
    "FETCH_GENE_PANEL_PATIENT_LIST_SUCCESS",
  FETCH_GENE_PANEL_PATIENT_LIST_FAILURE:
    "FETCH_GENE_PANEL_PATIENT_LIST_FAILURE",
  REMOVE_GENE_PANELS_START: "REMOVE_GENE_PANELS_START",
  REMOVE_GENE_PANELS_SUCCESS: "REMOVE_GENE_PANELS_SUCCESS",
  REMOVE_GENE_PANELS_FAILURE: "REMOVE_GENE_PANELS_FAILURE",
  ARCHIVE_GENE_PANEL_START: "ARCHIVE_GENE_PANEL_START",
  ARCHIVE_GENE_PANEL_SUCCESS: "ARCHIVE_GENE_PANEL_SUCCESS",
  ARCHIVE_GENE_PANEL_FAILURE: "ARCHIVE_GENE_PANEL_FAILURE",
  EXPORT_GENE_PANEL_START: "EXPORT_GENE_PANEL_START",
  EXPORT_GENE_PANEL_SUCCESS: "EXPORT_GENE_PANEL_SUCCESS",
  EXPORT_GENE_PANEL_FAILURE: "EXPORT_GENE_PANEL_FAILURE",
  ADD_NEW_GENE_PANEL_START: "ADD_NEW_GENE_PANEL_START",
  ADD_NEW_GENE_PANEL_SUCCESS: "ADD_NEW_GENE_PANEL_SUCCESS",
  ADD_NEW_GENE_PANEL_FAILURE: "ADD_NEW_GENE_PANEL_FAILURE",
  UPLOAD_NEW_GENE_PANEL_START: "UPLOAD_NEW_GENE_PANEL_START",
  UPLOAD_NEW_GENE_PANEL_SUCCESS: "UPLOAD_NEW_GENE_PANEL_SUCCESS",
  UPLOAD_NEW_GENE_PANEL_FAILURE: "UPLOAD_NEW_GENE_PANEL_FAILURE",
  SET_UPLOAD_NEW_GENE_PANEL_PROCESSING: "SET_UPLOAD_NEW_GENE_PANEL_PROCESSING",
  FETCH_TEMP_GENE_PANEL_FOUND_GENES_START:
    "FETCH_TEMP_GENE_PANEL_FOUND_GENES_START",
  FETCH_TEMP_GENE_PANEL_FOUND_GENES_SUCCESS:
    "FETCH_TEMP_GENE_PANEL_FOUND_GENES_SUCCESS",
  FETCH_TEMP_GENE_PANEL_FOUND_GENES_FAILURE:
    "FETCH_TEMP_GENE_PANEL_FOUND_GENES_FAILURE",
  FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_START:
    "FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_START",
  FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_SUCCESS:
    "FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_SUCCESS",
  FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_FAILURE:
    "FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_FAILURE",
  FETCH_TEMP_GENE_PANEL_START: "FETCH_TEMP_GENE_PANEL_START",
  FETCH_TEMP_GENE_PANEL_SUCCESS: "FETCH_TEMP_GENE_PANEL_SUCCESS",
  FETCH_TEMP_GENE_PANEL_FAILURE: "FETCH_TEMP_GENE_PANEL_FAILURE",
  UPDATE_TEMP_GENE_PANEL_START: "UPDATE_TEMP_GENE_PANEL_START",
  UPDATE_TEMP_GENE_PANEL_SUCCESS: "UPDATE_TEMP_GENE_PANEL_SUCCESS",
  UPDATE_TEMP_GENE_PANEL_FAILURE: "UPDATE_TEMP_GENE_PANEL_FAILURE",
  SAVE_TEMP_GENE_PANEL_START: "SAVE_TEMP_GENE_PANEL_START",
  SAVE_TEMP_GENE_PANEL_SUCCESS: "SAVE_TEMP_GENE_PANEL_SUCCESS",
  SAVE_TEMP_GENE_PANEL_FAILURE: "SAVE_TEMP_GENE_PANEL_FAILURE",
  FETCH_ALL_UPLOADED_GENE_PANEL_DATA: "FETCH_ALL_UPLOADED_GENE_PANEL_DATA",
  SET_ADD_NEW_GENE_PANEL_ERROR: "SET_ADD_NEW_GENE_PANEL_ERROR",
  SET_ADD_NEW_GENE_PANEL_IS_ADDED: "SET_ADD_NEW_GENE_PANEL_IS_ADDED",
  CLEAR_ADD_STATE: "CLEAR_ADD_STATE",
  SET_GENE_PANELS_FILTER: "SET_GENE_PANELS_FILTER",
  SET_PARENT_GENE_PANELS_FILTER: "SET_PARENT_GENE_PANELS_FILTER",
  SET_TEMP_GENE_PANEL_LOADING: "SET_TEMP_GENE_PANEL_LOADING",
  SET_GENE_IN_FOUND_GENES: "SET_GENE_IN_FOUND_GENES",
  SET_GENE_IN_GENES_LIST: "SET_GENE_IN_GENES_LIST",
  CLEAR_EDIT_GENE_PANEL_STATE: "CLEAR_EDIT_GENE_PANEL_STATE",
  RELOAD_EDIT_GENE_PANEL_DATA: "RELOAD_EDIT_GENE_PANEL_DATA",
  UPDATE_GENE_PANEL_INFO_START: "UPDATE_GENE_PANEL_INFO_START",
  UPDATE_GENE_PANEL_INFO_SUCCESS: "UPDATE_GENE_PANEL_INFO_SUCCESS",
  UPDATE_GENE_PANEL_INFO_FAILURE: "UPDATE_GENE_PANEL_INFO_FAILURE",
  FETCH_EDIT_GENE_START: "FETCH_EDIT_GENE_START",
  FETCH_EDIT_GENE_SUCCESS: "FETCH_EDIT_GENE_SUCCESS",
  FETCH_EDIT_GENE_FAILURE: "FETCH_EDIT_GENE_FAILURE",
  UPDATE_EDIT_GENE_START: "UPDATE_EDIT_GENE_START",
  UPDATE_EDIT_GENE_SUCCESS: "UPDATE_EDIT_GENE_SUCCESS",
  UPDATE_EDIT_GENE_FAILURE: "UPDATE_EDIT_GENE_FAILURE",
  RESET_EDIT_GENE_STATE: "RESET_EDIT_GENE_STATE",
  SEARCH_GENE_START: "SEARCH_GENE_START",
  SEARCH_GENE_SUCCESS: "SEARCH_GENE_SUCCESS",
  SEARCH_GENE_FAILURE: "SEARCH_GENE_FAILURE",
  ADD_GENE_TO_GENE_PANEL_START: "ADD_GENE_TO_GENE_PANEL_START",
  ADD_GENE_TO_GENE_PANEL_SUCCESS: "ADD_GENE_TO_GENE_PANEL_SUCCESS",
  ADD_GENE_TO_GENE_PANEL_FAILURE: "ADD_GENE_TO_GENE_PANEL_FAILURE",
  SET_SEARCH_GENE_FORM_FIELD_VALUE: "SET_SEARCH_GENE_FORM_FIELD_VALUE",
});

export const fetchGenePanels: AsyncActionCreator = {
  start: (
    projectId,
    archived = true
  ): FSA<
    typeof actionType.FETCH_GENE_PANELS_START,
    { projectId: number, archived: boolean }
  > =>
    createAction(actionType.FETCH_GENE_PANELS_START, {
      projectId,
      archived,
    }),
  success: (data: {
    genePanelData: Array<GenePanel>,
    parentGenePanelData: Array<GenePanel>,
  }): FSA<
    typeof actionType.FETCH_GENE_PANELS_SUCCESS,
    {
      genePanelData: Array<GenePanel>,
      parentGenePanelData: Array<GenePanel>,
    }
  > => createAction(actionType.FETCH_GENE_PANELS_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_GENE_PANELS_FAILURE> =>
    createAction(actionType.FETCH_GENE_PANELS_FAILURE),
};

export const fetchGene: AsyncActionCreator = {
  start: (
    geneName: string,
    ensemblVersion: string
  ): FSA<
    typeof actionType.FETCH_GENE_START,
    { geneName: string, ensemblVersion: string }
  > => createAction(actionType.FETCH_GENE_START, { geneName, ensemblVersion }),
  success: (data: {
    gene: Gene,
    skip_cols: {
      [col_name: string]: number,
    },
  }): FSA<
    typeof actionType.FETCH_GENE_SUCCESS,
    {
      gene: Gene,
      skip_cols: {
        [col_name: string]: number,
      },
    }
  > => createAction(actionType.FETCH_GENE_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_GENE_FAILURE> =>
    createAction(actionType.FETCH_GENE_FAILURE),
};

export const fetchEditTempGene: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number,
    geneName: string
  ): FSA<
    typeof actionType.FETCH_EDIT_TEMP_GENE_START,
    { projectId: number, tempGenePanelId: number, geneName: string }
  > =>
    createAction(actionType.FETCH_EDIT_TEMP_GENE_START, {
      projectId,
      tempGenePanelId,
      geneName,
    }),
  success: (data: {
    gene: Gene,
    columns: Array<string>,
    fields: Array<object>,
  }): FSA<
    typeof actionType.FETCH_EDIT_TEMP_GENE_SUCCESS,
    {
      gene: Gene,
      columns: Array<string>,
      fields: Array<object>,
    }
  > => createAction(actionType.FETCH_EDIT_TEMP_GENE_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_EDIT_TEMP_GENE_FAILURE> =>
    createAction(actionType.FETCH_EDIT_TEMP_GENE_FAILURE),
};

export const updateEditTempGene: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number,
    geneName: string,
    data: object
  ): FSA<
    typeof actionType.UPDATE_EDIT_TEMP_GENE_START,
    {
      projectId: number,
      tempGenePanelId: number,
      geneName: string,
      data: object,
    }
  > =>
    createAction(actionType.UPDATE_EDIT_TEMP_GENE_START, {
      projectId,
      tempGenePanelId,
      geneName,
      data,
    }),
  success: (
    projectId: number,
    tempGenePanelId: number,
    geneName: string,
    data: object
  ): FSA<
    typeof actionType.UPDATE_EDIT_TEMP_GENE_SUCCESS,
    {
      projectId: number,
      tempGenePanelId: number,
      geneName: string,
      data: object,
    }
  > =>
    createAction(actionType.UPDATE_EDIT_TEMP_GENE_SUCCESS, {
      projectId,
      tempGenePanelId,
      geneName,
      data,
    }),
  failure: (): FSA<typeof actionType.UPDATE_EDIT_TEMP_GENE_FAILURE> =>
    createAction(actionType.UPDATE_EDIT_TEMP_GENE_FAILURE),
};

export const fetchEditGene: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number,
    geneName: string
  ): FSA<
    typeof actionType.FETCH_EDIT_GENE_START,
    { projectId: number, genePanelId: number, geneName: string }
  > =>
    createAction(actionType.FETCH_EDIT_GENE_START, {
      projectId,
      genePanelId,
      geneName,
    }),
  success: (data: {
    gene: Gene,
    columns: Array<string>,
    fields: Array<object>,
  }): FSA<
    typeof actionType.FETCH_EDIT_GENE_SUCCESS,
    {
      gene: Gene,
      columns: Array<string>,
      fields: Array<object>,
    }
  > => createAction(actionType.FETCH_EDIT_GENE_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_EDIT_GENE_FAILURE> =>
    createAction(actionType.FETCH_EDIT_GENE_FAILURE),
};

export const addGeneToGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number,
    geneName: string,
    data: object
  ): FSA<
    typeof actionType.ADD_GENE_TO_GENE_PANEL_START,
    { projectId: number, genePanelId: number, geneName: string, data: object }
  > =>
    createAction(actionType.ADD_GENE_TO_GENE_PANEL_START, {
      projectId,
      genePanelId,
      geneName,
      data,
    }),
  success: (): FSA<typeof actionType.ADD_GENE_TO_GENE_PANEL_SUCCESS> =>
    createAction(actionType.ADD_GENE_TO_GENE_PANEL_SUCCESS),
  failure: (): FSA<typeof actionType.ADD_GENE_TO_GENE_PANEL_FAILURE> =>
    createAction(actionType.ADD_GENE_TO_GENE_PANEL_FAILURE),
};

export const searchGenes: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number,
    query: string
  ): FSA<
    typeof actionType.SEARCH_GENE_START,
    { projectId: number, genePanelId: number, query: string }
  > =>
    createAction(actionType.SEARCH_GENE_START, {
      projectId,
      genePanelId,
      query,
    }),
  success: (
    query: string,
    genes: Array<object>
  ): FSA<
    typeof actionType.SEARCH_GENE_SUCCESS,
    {
      query: string,
      genes: Array<object>,
    }
  > => createAction(actionType.SEARCH_GENE_SUCCESS, { query, genes }),
  failure: (): FSA<typeof actionType.SEARCH_GENE_FAILURE> =>
    createAction(actionType.SEARCH_GENE_FAILURE),
};

export const updateEditGene: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number,
    geneName: string,
    data: object
  ): FSA<
    typeof actionType.UPDATE_EDIT_GENE_START,
    {
      projectId: number,
      genePanelId: number,
      geneName: string,
      data: object,
    }
  > =>
    createAction(actionType.UPDATE_EDIT_GENE_START, {
      projectId,
      genePanelId,
      geneName,
      data,
    }),
  success: (
    projectId: number,
    genePanelId: number,
    geneName: string,
    data: object
  ): FSA<
    typeof actionType.UPDATE_EDIT_GENE_SUCCESS,
    {
      projectId: number,
      genePanelId: number,
      geneName: string,
      data: object,
    }
  > =>
    createAction(actionType.UPDATE_EDIT_GENE_SUCCESS, {
      projectId,
      genePanelId,
      geneName,
      data,
    }),
  failure: (): FSA<typeof actionType.UPDATE_EDIT_GENE_FAILURE> =>
    createAction(actionType.UPDATE_EDIT_GENE_FAILURE),
};

export const removeGeneFromTempGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number,
    geneName: string
  ): FSA<
    typeof actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_START,
    { projectId: number, tempGenePanelId: number, geneName: string }
  > =>
    createAction(actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_START, {
      projectId,
      tempGenePanelId,
      geneName,
    }),
  success: (
    geneName: string
  ): FSA<typeof actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_SUCCESS, string> =>
    createAction(actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_SUCCESS, geneName),
  failure: (): FSA<
    typeof actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_FAILURE
  > => createAction(actionType.REMOVE_GENE_FROM_TEMP_GENE_PANEL_FAILURE),
};

export const fetchTempGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number
  ): FSA<
    typeof actionType.FETCH_TEMP_GENE_PANEL_START,
    { projectId: number, tempGenePanelId: number }
  > =>
    createAction(actionType.FETCH_TEMP_GENE_PANEL_START, {
      projectId,
      tempGenePanelId,
    }),
  success: (
    data: GenePanel
  ): FSA<typeof actionType.FETCH_TEMP_GENE_PANEL_SUCCESS, Array<Gene>> =>
    createAction(actionType.FETCH_TEMP_GENE_PANEL_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_TEMP_GENE_PANEL_FAILURE> =>
    createAction(actionType.FETCH_TEMP_GENE_PANEL_FAILURE),
};

export const fetchGenePanelPatientList: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number
  ): FSA<
    typeof actionType.FETCH_GENE_PANEL_PATIENT_LIST_START,
    { projectId: number, genePanelId: number }
  > =>
    createAction(actionType.FETCH_GENE_PANEL_PATIENT_LIST_START, {
      projectId,
      genePanelId,
    }),
  success: (data: {
    projectPatients: Array<Patient>,
    genePanelPatients: { [patientId: number]: Patient },
    genePanelWritePermission: string,
  }): FSA<
    typeof actionType.FETCH_GENE_PANEL_PATIENT_LIST_SUCCESS,
    {
      projectPatients: Array<Patient>,
      genePanelPatients: { [patientId: number]: Patient },
      genePanelWritePermission: string,
    }
  > => createAction(actionType.FETCH_GENE_PANEL_PATIENT_LIST_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_GENE_PANEL_PATIENT_LIST_FAILURE> =>
    createAction(actionType.FETCH_GENE_PANEL_PATIENT_LIST_FAILURE),
};

export const executeGenePanelPatientOperation: AsyncActionCreator = {
  start: (
    operation: PatientOperationType,
    projectId: number,
    genePanelId: number,
    patientId?: number
  ): FSA<
    typeof actionType.EXECUTE_GENE_PANEL_PATIENT_OPERATION_START,
    {
      operation: PatientOperationType,
      projectId: number,
      genePanelId: number,
      patientId?: number,
    }
  > =>
    createAction(actionType.EXECUTE_GENE_PANEL_PATIENT_OPERATION_START, {
      operation,
      projectId,
      genePanelId,
      patientId,
    }),
  success: (
    operation: PatientOperationType,
    projectId: number,
    genePanelId: number,
    patientId?: number
  ): FSA<
    typeof actionType.EXECUTE_GENE_PANEL_PATIENT_OPERATION_SUCCESS,
    {
      operation: PatientOperationType,
      projectId: number,
      genePanelId: number,
      patientId?: number,
    }
  > =>
    createAction(actionType.EXECUTE_GENE_PANEL_PATIENT_OPERATION_SUCCESS, {
      operation,
      projectId,
      genePanelId,
      patientId,
    }),
  failure: (): FSA<
    typeof actionType.EXECUTE_GENE_PANEL_PATIENT_OPERATION_FAILURE
  > => createAction(actionType.EXECUTE_GENE_PANEL_PATIENT_OPERATION_FAILURE),
};

export const copyGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number
  ): FSA<
    typeof actionType.COPY_GENE_PANELS_START,
    { projectId: number, genePanelId: number }
  > =>
    createAction(actionType.COPY_GENE_PANELS_START, {
      projectId,
      genePanelId,
    }),
  success: (): FSA<typeof actionType.COPY_GENE_PANELS_SUCCESS> =>
    createAction(actionType.COPY_GENE_PANELS_SUCCESS),
  failure: (): FSA<typeof actionType.COPY_GENE_PANELS_FAILURE> =>
    createAction(actionType.COPY_GENE_PANELS_FAILURE),
};

export const fetchGenePanelInfo: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number
  ): FSA<
    typeof actionType.FETCH_GENE_PANEL_INFO_START,
    { projectId: number, genePanelId: number }
  > =>
    createAction(actionType.FETCH_GENE_PANEL_INFO_START, {
      projectId,
      genePanelId,
    }),
  success: (data: {
    genePanel: GenePanelInfo,
  }): FSA<
    typeof actionType.FETCH_GENE_PANEL_INFO_SUCCESS,
    {
      genePanel: GenePanelInfo,
    }
  > => createAction(actionType.FETCH_GENE_PANEL_INFO_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_GENE_PANEL_INFO_FAILURE> =>
    createAction(actionType.FETCH_GENE_PANEL_INFO_FAILURE),
};

export const updateTempGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number,
    data: object
  ): FSA<
    typeof actionType.UPDATE_TEMP_GENE_PANEL_START,
    { projectId: number, tempGenePanelId: number, data: object }
  > =>
    createAction(actionType.UPDATE_TEMP_GENE_PANEL_START, {
      projectId,
      tempGenePanelId,
      data,
    }),
  success: (
    data: object
  ): FSA<typeof actionType.UPDATE_TEMP_GENE_PANEL_SUCCESS, object> =>
    createAction(actionType.UPDATE_TEMP_GENE_PANEL_SUCCESS, data),
  failure: (): FSA<typeof actionType.UPDATE_TEMP_GENE_PANEL_FAILURE> =>
    createAction(actionType.UPDATE_TEMP_GENE_PANEL_FAILURE),
};

export const saveTempGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number
  ): FSA<
    typeof actionType.SAVE_TEMP_GENE_PANEL_START,
    { projectId: number, tempGenePanelId: number }
  > =>
    createAction(actionType.SAVE_TEMP_GENE_PANEL_START, {
      projectId,
      tempGenePanelId,
    }),
  success: (): FSA<typeof actionType.SAVE_TEMP_GENE_PANEL_SUCCESS> =>
    createAction(actionType.SAVE_TEMP_GENE_PANEL_SUCCESS),
  failure: (): FSA<typeof actionType.SAVE_TEMP_GENE_PANEL_FAILURE> =>
    createAction(actionType.SAVE_TEMP_GENE_PANEL_FAILURE),
};

export const fetchTempGenePanelFoundGenes: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number
  ): FSA<
    typeof actionType.FETCH_TEMP_GENE_PANEL_FOUND_GENES_START,
    { projectId: number, tempGenePanelId: number }
  > =>
    createAction(actionType.FETCH_TEMP_GENE_PANEL_FOUND_GENES_START, {
      projectId,
      tempGenePanelId,
    }),
  success: (
    data: Array<GeneToGenePanel>
  ): FSA<
    typeof actionType.FETCH_TEMP_GENE_PANEL_FOUND_GENES_SUCCESS,
    Array<GeneToGenePanel>
  > => createAction(actionType.FETCH_TEMP_GENE_PANEL_FOUND_GENES_SUCCESS, data),
  failure: (): FSA<
    typeof actionType.FETCH_TEMP_GENE_PANEL_FOUND_GENES_FAILURE
  > => createAction(actionType.FETCH_TEMP_GENE_PANEL_FOUND_GENES_FAILURE),
};

export const fetchTempGenePanelNotFoundGenes: AsyncActionCreator = {
  start: (
    projectId: number,
    tempGenePanelId: number
  ): FSA<
    typeof actionType.FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_START,
    { projectId: number, tempGenePanelId: number }
  > =>
    createAction(actionType.FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_START, {
      projectId,
      tempGenePanelId,
    }),
  success: (
    data: Array<GeneToGenePanel>
  ): FSA<
    typeof actionType.FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_SUCCESS,
    Array<GeneToGenePanel>
  > =>
    createAction(
      actionType.FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_SUCCESS,
      data
    ),
  failure: (): FSA<
    typeof actionType.FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_FAILURE
  > => createAction(actionType.FETCH_TEMP_GENE_PANEL_NOT_FOUND_GENES_FAILURE),
};

export const updateGenePanelInfo: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number,
    data: object
  ): FSA<
    typeof actionType.UPDATE_GENE_PANEL_INFO_START,
    { projectId: number, genePanelId: number, data: object }
  > =>
    createAction(actionType.UPDATE_GENE_PANEL_INFO_START, {
      projectId,
      genePanelId,
      data,
    }),
  success: (
    data: object
  ): FSA<typeof actionType.UPDATE_GENE_PANEL_INFO_SUCCESS, object> =>
    createAction(actionType.UPDATE_GENE_PANEL_INFO_SUCCESS, data),
  failure: (): FSA<typeof actionType.UPDATE_GENE_PANEL_INFO_FAILURE> =>
    createAction(actionType.UPDATE_GENE_PANEL_INFO_FAILURE),
};

export const fetchGenePanelGeneList: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number
  ): FSA<
    typeof actionType.FETCH_GENE_PANEL_GENE_LIST_START,
    { projectId: number, genePanelId: number }
  > =>
    createAction(actionType.FETCH_GENE_PANEL_GENE_LIST_START, {
      projectId,
      genePanelId,
    }),
  success: (
    data: Array<Gene>
  ): FSA<typeof actionType.FETCH_GENE_PANEL_GENE_LIST_SUCCESS, Array<Gene>> =>
    createAction(actionType.FETCH_GENE_PANEL_GENE_LIST_SUCCESS, data),
  failure: (): FSA<typeof actionType.FETCH_GENE_PANEL_GENE_LIST_FAILURE> =>
    createAction(actionType.FETCH_GENE_PANEL_GENE_LIST_FAILURE),
};

export const removeGeneFromGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number,
    geneName: string
  ): FSA<
    typeof actionType.REMOVE_GENE_FROM_GENE_PANEL_START,
    {
      projectId: number,
      genePanelId: number,
      geneName: string,
    }
  > =>
    createAction(actionType.REMOVE_GENE_FROM_GENE_PANEL_START, {
      projectId,
      genePanelId,
      geneName,
    }),
  success: (
    projectId: string,
    genePanelId: number,
    geneName: string
  ): FSA<
    typeof actionType.REMOVE_GENE_FROM_GENE_PANEL_SUCCESS,
    {
      projectId: number,
      genePanelId: number,
      geneName: string,
    }
  > =>
    createAction(actionType.REMOVE_GENE_FROM_GENE_PANEL_SUCCESS, {
      projectId,
      genePanelId,
      geneName,
    }),
  failure: (): FSA<typeof actionType.REMOVE_GENE_FROM_GENE_PANEL_FAILURE> =>
    createAction(actionType.REMOVE_GENE_FROM_GENE_PANEL_FAILURE),
};

export const removeGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number
  ): FSA<
    typeof actionType.REMOVE_GENE_PANELS_START,
    {
      projectId: number,
      genePanelId: number,
    }
  > =>
    createAction(actionType.REMOVE_GENE_PANELS_START, {
      projectId,
      genePanelId,
    }),
  success: (
    projectId: string,
    genePanelId: number
  ): FSA<
    typeof actionType.REMOVE_GENE_PANELS_SUCCESS,
    {
      projectId: number,
      genePanelId: number,
    }
  > =>
    createAction(actionType.REMOVE_GENE_PANELS_SUCCESS, {
      projectId,
      genePanelId,
    }),
  failure: (): FSA<typeof actionType.REMOVE_GENE_PANELS_FAILURE> =>
    createAction(actionType.REMOVE_GENE_PANELS_FAILURE),
};

export const archiveGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number
  ): FSA<
    typeof actionType.ARCHIVE_GENE_PANEL_START,
    {
      projectId: number,
      genePanelId: number,
    }
  > =>
    createAction(actionType.ARCHIVE_GENE_PANEL_START, {
      projectId,
      genePanelId,
    }),
  success: (
    projectId: string,
    genePanelId: number
  ): FSA<
    typeof actionType.ARCHIVE_GENE_PANEL_SUCCESS,
    {
      projectId: number,
      genePanelId: number,
    }
  > =>
    createAction(actionType.ARCHIVE_GENE_PANEL_SUCCESS, {
      projectId,
      genePanelId,
    }),
  failure: (): FSA<typeof actionType.ARCHIVE_GENE_PANEL_FAILURE> =>
    createAction(actionType.ARCHIVE_GENE_PANEL_FAILURE),
};
export const exportGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    genePanelId: number,
    data: { panelContent: string, panelFormat: string }
  ): FSA<
    typeof actionType.EXPORT_GENE_PANEL_START,
    {
      projectId: number,
      genePanelId: number,
      data: { panelContent: string, panelFormat: string },
    }
  > =>
    createAction(actionType.EXPORT_GENE_PANEL_START, {
      projectId,
      genePanelId,
      data,
    }),
  success: (): FSA<typeof actionType.EXPORT_GENE_PANEL_SUCCESS> =>
    createAction(actionType.EXPORT_GENE_PANEL_SUCCESS),
  failure: (): FSA<typeof actionType.EXPORT_GENE_PANEL_FAILURE> =>
    createAction(actionType.EXPORT_GENE_PANEL_FAILURE),
};

export const addNewGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    title: string,
    description: string,
    ensemblVersion: string
  ): FSA<
    typeof actionType.ADD_NEW_GENE_PANEL_START,
    {
      projectId: number,
      title: string,
      description: string,
      ensemblVersion: string,
    }
  > =>
    createAction(actionType.ADD_NEW_GENE_PANEL_START, {
      projectId,
      title,
      description,
      ensemblVersion,
    }),
  success: (): FSA<typeof actionType.ADD_NEW_GENE_PANEL_SUCCESS> =>
    createAction(actionType.ADD_NEW_GENE_PANEL_SUCCESS),
  failure: (): FSA<typeof actionType.ADD_NEW_GENE_PANEL_FAILURE> =>
    createAction(actionType.ADD_NEW_GENE_PANEL_FAILURE),
};

export const uploadNewGenePanel: AsyncActionCreator = {
  start: (
    projectId: number,
    csvFormat: string,
    file: object,
    ensemblVersion: string
  ): FSA<
    typeof actionType.UPLOAD_NEW_GENE_PANEL_START,
    {
      projectId: number,
      csvFormat: string,
      file: object,
      ensemblVersion: string,
    }
  > =>
    createAction(actionType.UPLOAD_NEW_GENE_PANEL_START, {
      projectId,
      csvFormat,
      file,
      ensemblVersion,
    }),
  success: (
    tempGenePanelId: number
  ): FSA<typeof actionType.UPLOAD_NEW_GENE_PANEL_SUCCESS> =>
    createAction(actionType.UPLOAD_NEW_GENE_PANEL_SUCCESS, tempGenePanelId),
  failure: (): FSA<typeof actionType.UPLOAD_NEW_GENE_PANEL_FAILURE> =>
    createAction(actionType.UPLOAD_NEW_GENE_PANEL_FAILURE),
};

type ResetEditTempGeneStateAction = FSA<
  typeof actionType.RESET_EDIT_TEMP_GENE_STATE
>;
export const resetEditTempGeneState = (): ResetEditTempGeneStateAction =>
  createAction(actionType.RESET_EDIT_TEMP_GENE_STATE);

type ResetEditGeneStateAction = FSA<typeof actionType.RESET_EDIT_GENE_STATE>;
export const resetEditGeneState = (): ResetEditGeneStateAction =>
  createAction(actionType.RESET_EDIT_GENE_STATE);

// ADD Actions

type ClearAddStateAction = FSA<typeof actionType.CLEAR_ADD_STATE>;
export const clearAddState = (): ClearAddStateAction =>
  createAction(actionType.CLEAR_ADD_STATE);

type SetAddNewGenePanelErrorAction = FSA<
  typeof actionType.SET_ADD_NEW_GENE_PANEL_ERROR,
  boolean
>;
export const setAddNewGenePanelError = (
  hasError: boolean
): SetAddNewGenePanelErrorAction =>
  createAction(actionType.SET_ADD_NEW_GENE_PANEL_ERROR, hasError);

type SetAddNewGenePanelIsAddedAction = FSA<
  typeof actionType.SET_ADD_NEW_GENE_PANEL_IS_ADDED,
  boolean
>;
export const setAddNewGenePanelIsAdded = (
  isAdded: boolean
): SetAddNewGenePanelIsAddedAction =>
  createAction(actionType.SET_ADD_NEW_GENE_PANEL_IS_ADDED, isAdded);

type SetUploadGenePanelProcessingAction = FSA<
  typeof actionType.SET_UPLOAD_NEW_GENE_PANEL_PROCESSING,
  boolean
>;
export const setUploadGenePanelProcessing = (
  isProcessing: boolean
): SetUploadGenePanelProcessingAction =>
  createAction(actionType.SET_UPLOAD_NEW_GENE_PANEL_PROCESSING, isProcessing);

type SetTempGenePanelLoadingAction = FSA<
  typeof actionType.SET_TEMP_GENE_PANEL_LOADING,
  boolean
>;
export const setTempGenePanelLoading = (
  isLoading: boolean
): SetTempGenePanelLoadingAction =>
  createAction(actionType.SET_TEMP_GENE_PANEL_LOADING, isLoading);

type FetchAllUploadedGenePanelDataAction = FSA<
  typeof actionType.FETCH_ALL_UPLOADED_GENE_PANEL_DATA,
  { projectId: number, tempGenePanelId: number }
>;
export const fetchAllUploadedGenePanelData = (
  projectId: number,
  tempGenePanelId: number
): FetchAllUploadedGenePanelDataAction =>
  createAction(actionType.FETCH_ALL_UPLOADED_GENE_PANEL_DATA, {
    projectId,
    tempGenePanelId,
  });
type SetGeneInFoundGenesAction = FSA<
  typeof actionType.SET_GENE_IN_FOUND_GENES,
  { geneName: string, data: any }
>;
export const setGeneInFoundGenes = (
  geneName: string,
  data: any
): SetGeneInFoundGenesAction =>
  createAction(actionType.SET_GENE_IN_FOUND_GENES, {
    geneName,
    data,
  });

// Edit gene panel actions

type ClearEditGenePanelStateAction = FSA<
  typeof actionType.CLEAR_EDIT_GENE_PANEL_STATE
>;
export const clearEditGenePanelState = (): ClearEditGenePanelStateAction =>
  createAction(actionType.CLEAR_EDIT_GENE_PANEL_STATE);

type ReloadEditGenePanelDataAction = FSA<
  typeof actionType.RELOAD_EDIT_GENE_PANEL_DATA,
  {
    projectId: number,
    genePanelId: number,
  }
>;
export const reloadEditGenePanelData = (
  projectId: number,
  genePanelId: number
): ReloadEditGenePanelDataAction =>
  createAction(actionType.RELOAD_EDIT_GENE_PANEL_DATA, {
    projectId,
    genePanelId,
  });

type SetGeneInGenesListAction = FSA<
  typeof actionType.SET_GENE_IN_GENES_LIST,
  { geneName: string, data: any }
>;
export const setGeneInGenesList = (
  geneName: string,
  data: any
): SetGeneInGenesListAction =>
  createAction(actionType.SET_GENE_IN_GENES_LIST, {
    geneName,
    data,
  });

type SetSearchGeneFormFieldValueAction = FSA<
  typeof actionType.SET_SEARCH_GENE_FORM_FIELD_VALUE,
  { geneName: string, fieldName: string, value: any }
>;
export const setGeneFormFieldValue = (
  geneName: string,
  fieldName: string,
  value: any
): SetGeneFormFieldValueAction =>
  createAction(actionType.SET_SEARCH_GENE_FORM_FIELD_VALUE, {
    geneName,
    fieldName,
    value,
  });

export type Action =
  | Return<
      fetchGenePanels.start | fetchGenePanels.success | fetchGenePanels.failure
    >
  | Return<fetchEditGene.start | fetchEditGene.success | fetchEditGene.failure>
  | Return<searchGenes.start | searchGenes.success | searchGenes.failure>
  | Return<
      updateEditGene.start | updateEditGene.success | updateEditGene.failure
    >
  | Return<
      | fetchEditTempGene.start
      | fetchEditTempGene.success
      | fetchEditTempGene.failure
    >
  | Return<
      | updateEditTempGene.start
      | updateEditTempGene.success
      | updateEditTempGene.failure
    >
  | Return<
      | addGeneToGenePanel.start
      | addGeneToGenePanel.success
      | addGeneToGenePanel.failure
    >
  | Return<
      | fetchGenePanelInfo.start
      | fetchGenePanelInfo.success
      | fetchGenePanelInfo.failure
    >
  | Return<
      | fetchGenePanelPatientList.start
      | fetchGenePanelPatientList.success
      | fetchGenePanelPatientList.failure
    >
  | Return<
      | executeGenePanelPatientOperation.start
      | executeGenePanelPatientOperation.success
      | executeGenePanelPatientOperation.failure
    >
  | Return<
      | fetchGenePanelGeneList.start
      | fetchGenePanelGeneList.success
      | fetchGenePanelGeneList.failure
    >
  | Return<
      | fetchGenePanelInfo.start
      | fetchGenePanelInfo.success
      | fetchGenePanelInfo.failure
    >
  | Return<copyGenePanel.start | copyGenePanel.success | copyGenePanel.failure>
  | Return<
      | removeGeneFromGenePanel.start
      | removeGeneFromGenePanel.success
      | removeGeneFromGenePanel.failure
    >
  | Return<
      | updateGenePanelInfo.start
      | updateGenePanelInfo.success
      | updateGenePanelInfo.failure
    >
  | Return<
      | fetchGenePanelPatientList.start
      | fetchGenePanelPatientList.success
      | fetchGenePanelPatientList.failure
    >
  | Return<
      | fetchGenePanelGeneList.start
      | fetchGenePanelGeneList.success
      | fetchGenePanelGeneList.failure
    >
  | Return<
      removeGenePanel.start | removeGenePanel.success | removeGenePanel.failure
    >
  | Return<
      | archiveGenePanel.start
      | archiveGenePanel.success
      | archiveGenePanel.failure
    >
  | Return<
      exportGenePanel.start | exportGenePanel.success | exportGenePanel.failure
    >
  | Return<
      addNewGenePanel.start | addNewGenePanel.success | addNewGenePanel.failure
    >
  | Return<
      | uploadNewGenePanel.start
      | uploadNewGenePanel.success
      | uploadNewGenePanel.failure
    >
  | Return<
      | fetchTempGenePanel.start
      | fetchTempGenePanel.success
      | fetchTempGenePanel.failure
    >
  | Return<
      | fetchTempGenePanelFoundGenes.start
      | fetchTempGenePanelFoundGenes.success
      | fetchTempGenePanelFoundGenes.failure
    >
  | Return<
      | removeGeneFromTempGenePanel.start
      | removeGeneFromTempGenePanel.success
      | removeGeneFromTempGenePanel.failure
    >
  | Return<
      | fetchTempGenePanelNotFoundGenes.start
      | fetchTempGenePanelNotFoundGenes.success
      | fetchTempGenePanelNotFoundGenes.failure
    >
  | Return<
      | fetchGenePanelPatientList.start
      | fetchGenePanelPatientList.success
      | fetchGenePanelPatientList.failure
    >
  | ClearEditGenePanelStateAction
  | ClearAddStateAction
  | SetAddNewGenePanelErrorAction
  | SetAddNewGenePanelIsAddedAction
  | SetUploadGenePanelProcessingAction
  | SetTempGenePanelLoadingAction
  | SetGeneInFoundGenesAction
  | SetGeneInGenesList
  | ResetEditGeneStateAction
  | ResetEditTempGeneStateAction
  | FetchAllUploadedGenePanelDataAction
  | ReloadEditGenePanelDataAction
  | SetSearchGeneFormFieldValueAction
  | SetGenePanelInfoAction;
