import PropTypes from "prop-types";
import React, { memo } from "react";

import { Modal, ModalHeader, ModalBody, MODAL_SIZES } from "pattern-library";

import { SNVPresetProp } from "../../utils/prop-types/FilterPresetOptionProp";

import SNVPresetView from "./SNVPresetView";

const SNVPresetInfoModal = memo(({ show, close, preset, patientId }) => (
  <Modal
    id="snv-preset-info-modal"
    size={MODAL_SIZES.L}
    close={close}
    show={show}
  >
    <ModalHeader className="config-modal-header" close={close}>
      <strong>{preset.attributes.title}</strong>
    </ModalHeader>
    <ModalBody>
      <SNVPresetView patientId={patientId} preset={preset} />
    </ModalBody>
  </Modal>
));

SNVPresetInfoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  preset: SNVPresetProp,
  patientId: PropTypes.number.isRequired,
};
export default SNVPresetInfoModal;
