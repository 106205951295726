import classnames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class ButtonGroup extends PureComponent {
  static displayName = "ButtonGroup";

  static propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "lg"]),
    vertical: PropTypes.bool,
  };

  static defaultProps = {
    size: null,
    vertical: false,
  };

  render() {
    const { children, size, vertical, className, ...otherProps } = this.props;

    return (
      <div
        className={classnames(
          {
            "btn-group-lg": size === "lg",
            "btn-group-sm": size === "sm",
            "btn-group-xs": size === "xs",
            "btn-group-vertical": vertical,
            "btn-group": !vertical,
          },
          className
        )}
        role="group"
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}
