import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useState } from "react";

import { useDebounce } from "../../../common/utils";
import { Input } from "../form";

const DEBOUNCE_DELAY = 400;

const FilterField = memo(({ onFilterChange, placeholder = "Filter..." }) => {
  const [filterValue, setFilterValue] = useState("");
  const debouncedSearchTerm = useDebounce(filterValue, DEBOUNCE_DELAY);

  useEffect(
    () => {
      onFilterChange(debouncedSearchTerm);
    },
    [debouncedSearchTerm, onFilterChange] // Only call effect if debounced search term changes
  );

  const onChange = useCallback(
    ({ target: { value } }) => {
      setFilterValue(value);
    },
    [setFilterValue]
  );

  return (
    <div className="dataTables_filter">
      <Input placeholder={placeholder} onChange={onChange} />
    </div>
  );
});

FilterField.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default FilterField;
