import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { DraggableCheckboxes } from "pattern-library";

import { CONFIG_MODE } from "../../constants";
import { getColumnsDefaults } from "../../selectors";
import { parseFlatColumnsValues, getFlatColumnsValues } from "../../utils";

const component = ({
  disabled,
  meta: { pristine },
  input: { value = [], onChange },
  options,
}) => {
  const onColumnsChange = flatValues =>
    onChange(parseFlatColumnsValues(flatValues));
  return (
    <DraggableCheckboxes
      label="Columns"
      options={options}
      onChange={onColumnsChange}
      sortable
      disabled={disabled}
      value={getFlatColumnsValues(value)}
      pristine={pristine}
    />
  );
};

export const ColumnsForm = ({ disabled = false, options }) => (
  <fieldset disabled={disabled} data-testid="config-columns-form">
    <Field component={component} name="columns" options={options} />
  </fieldset>
);

ColumnsForm.propTypes = {
  disabled: PropTypes.bool,
  patientId: PropTypes.number,
  configMode: PropTypes.oneOf(Object.keys(CONFIG_MODE)).isRequired,
};

const mapStateToProps = (state, { patientId, configMode }) => ({
  options: getColumnsDefaults(
    state,
    configMode === CONFIG_MODE.CONFIG_EDIT ? patientId : null
  ),
});

export default connect(mapStateToProps)(ColumnsForm);
