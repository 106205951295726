import PropTypes from "prop-types";

import { TabHeaderPropTypes } from "./DefaultTabHeader";

/**
 * An abstract wrapper used to describe tabs in ComposableTabs component and is not meant to be rendered.
 * @return {null}
 */
const Tab = () => null;

Tab.propTypes = {
  /**
   * tab header standard properties
   */
  ...TabHeaderPropTypes,
  /**
   * whether the tab should be hidden
   */
  isHidden: PropTypes.bool,
  /**
   * the tab content
   */
  children: PropTypes.node.isRequired,
};

export default Tab;
