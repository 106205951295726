import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class InputGroup extends PureComponent {
  static propTypes = {
    input: PropTypes.object,
    buttonComponent: PropTypes.node,
  };

  render() {
    const { input = {}, buttonComponent = "Submit" } = this.props;

    return (
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Search for..."
          {...input}
        />
        <span className="input-group-btn">
          <button className="btn btn-default" type="submit">
            {buttonComponent}
          </button>
        </span>
      </div>
    );
  }
}
