import classNames from "classnames";
import { Field } from "formik";
import React, { useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";
import { minAndMaxNumber } from "modules/forms/rules";
import { FORM_FILE_TYPES } from "modules/interpretationRequests";
import { getBaitsetsAsOptions } from "modules/interpretationRequests/selectors";

import { oncologyAllowThirdPartyVariantCalling } from "../../../../systemConfig";
import { FormFileTypes, IrSample, FileType } from "../../../types";
import { IdentifierField } from "../../IdentifierField";
import RemovalCell from "../../RemovalCell";
import { TumourTypeField } from "../../TumourTypeField";

interface Props extends PropsFromRedux {
  /**
   * the list of samples in an interpretation request
   */
  samples: Array<IrSample>;
  isActiveRow: boolean;
  index: number;
  setSelectedRow: (row: number) => void;
  fieldsDisabled: boolean;
  removeRowHandler: (row: number) => void;
  fileTypesOptions: Array<FormFileTypes>;
}

const SamplesTableRow = ({
  index,
  isActiveRow,
  setSelectedRow,
  fieldsDisabled,
  samples = [],
  removeRowHandler,
  baitsets,
  oncologyAllowThirdPartyVariantCalling,
}: Props) => {
  const handleClick = (rowNumber: number) => () => {
    setSelectedRow(rowNumber);
  };

  const rowClasses: string = classNames({
    "ir-table-selected-row": isActiveRow,
  });

  const fileType = useMemo<FileType | undefined>(
    () => samples?.[index].files?.[0].fileType,
    [samples, index]
  );

  return (
    <tr onClick={handleClick(index)} className={rowClasses}>
      <td className="number-cell">
        <span>{index + 1}</span>
      </td>
      <td>
        <Field
          key={`samples.${index}.name`}
          component={IdentifierField}
          name={`samples.${index}.name`}
          id={`samples.${index}.name`}
          disabled={fieldsDisabled}
          type="text"
        />
      </td>
      <td>
        <Field
          name={`samples.${index}.tumourType`}
          component={TumourTypeField}
          disabled={fieldsDisabled}
        />
      </td>
      <td>
        <Field
          name={`samples.${index}.neoplasticCellularity`}
          component={FormikFormField}
          type="number"
          normalize={minAndMaxNumber(0, 100)}
          //we should enter only numbers
          inputFilter={/^\d+$/}
          changeOnBlur
          disabled={fieldsDisabled}
        />
      </td>
      <td>
        <Field
          name={`samples.${index}.sex`}
          component={FormikFormField}
          type="dropdown"
          emptyPlaceholder="Select a sex"
          options={{
            Male: "Male",
            Female: "Female",
          }}
          disabled={fieldsDisabled}
        />
      </td>

      {fileType !== FORM_FILE_TYPES.VCF && (
        <td data-testid="baitset-cell">
          <Field
            name={`samples.${index}.baitset`}
            component={FormikFormField}
            emptyPlaceholder="Select a baitset"
            type="dropdown"
            options={baitsets}
            disabled={fieldsDisabled}
          />
        </td>
      )}
      {oncologyAllowThirdPartyVariantCalling && (
        <td>
          <Field
            name={`samples.${index}.files[0].fileType`}
            component={FormikFormField}
            type="radio"
            disabled={fieldsDisabled}
            value={FORM_FILE_TYPES.VCF}
            checked={fileType === FORM_FILE_TYPES.VCF}
          />
        </td>
      )}
      <td>
        <Field
          name={`samples.${index}.files[0].fileType`}
          component={FormikFormField}
          type="radio"
          disabled={fieldsDisabled}
          value={FORM_FILE_TYPES.FASTQ}
          checked={fileType === FORM_FILE_TYPES.FASTQ}
        />
      </td>
      <td>
        <RemovalCell
          index={index}
          samples={samples}
          fieldsDisabled={fieldsDisabled}
          removeRowHandler={removeRowHandler}
          tooltip="Remove patient"
        />
      </td>
    </tr>
  );
};

const mapStateToProps = state => ({
  baitsets: getBaitsetsAsOptions(state),
  oncologyAllowThirdPartyVariantCalling:
    oncologyAllowThirdPartyVariantCalling(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SamplesTableRow);
