import { decamelize } from "humps";
import { prop } from "ramda";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";

import { apiAction } from "data-layer/api/actions";
import { getSingleGene } from "data-layer/orm/selectors/GeneSelectors";
import { loadSuggestedAcmgCriteria } from "modules/acmg/saga";
import { getGeneVariantCount } from "modules/api/snvs";
import * as configSelectors from "modules/config/selectors";
import { isAriadneEnabled } from "modules/config/selectors";
import { READ_PATIENT_SUCCESS } from "modules/patient/constants";
import { AUTO_ACMG_FEATURE } from "modules/project/constants";
import {
  getCurrentProjectFeatures,
  isProjectFeatureActive,
} from "modules/project/selectors";

import { getConfigPanelGene, setConfigGenePanels } from "../../data-layer";
import * as entitiesActions from "../../data-layer/entities/actions";
import { loadPresets } from "../config/saga";
import * as decisionsActions from "../decisions/actions";
import {
  getSelectedPresetId,
  isSelectedPresetCustomized,
} from "../sequenceVariantsPresets/selectors";

import * as actions from "./actions";
import * as constants from "./constants";
import * as selectors from "./selectors";
import { normalizeCurrentFilter } from "./utils";

export function* init() {
  yield takeLatest(constants.START_INIT, initialise);
  yield takeLatest(constants.RELOAD_VARIANTS, reloadVariants);
  yield takeLatest(constants.RELOAD_VARIANT_PAGE, reloadVariantPage);
  yield takeLatest(READ_PATIENT_SUCCESS, setPatientPanels);
  yield takeLatest(
    constants.SELECT_CONTEXT_FOR_VARIANT_PANEL,
    loadVariantDetails
  );
  // TODO: API_*_SUCCEEDED should be removed together with redux-orm
  yield takeLatest(constants.API_READ_DECISION_SUCCEEDED, onDecisionsReady);
  yield takeLatest(constants.LOAD_FULL_GENE, loadFullGene);
  yield takeLatest(constants.LOAD_ALL_VARIANTS_COUNT, loadFullVariantsCount);
  yield takeLatest(constants.RESET_ACTIVE_GENE, resetActiveGene);
}

export function* onDecisionsReady(action) {
  yield put(decisionsActions.setCurrentPatientDecisions(action.payload.list));
}

export function* onGenePanelsReady(action) {
  yield put(entitiesActions.setConfigGenePanels(action.payload));
}

export function* initialise(action) {
  yield call(loadPresets, action.meta.patientId);
  yield all([
    call(fetchGenes, action),
    call(fetchDecisionsForPatient, action),
    call(fetchGenePanelVariantCount, action),
  ]);

  yield put(actions.initialised());
}

export function* reloadVariants(action) {
  // Update the UI to hide the current variants
  yield put(actions.startLoadingVariants());

  // Load everything
  yield all([
    call(fetchGenes, action),
    call(fetchGenePanels, action),
    call(fetchGenePanelVariantCount, action),
  ]);

  // Update the ui to tell it we are done
  yield put(actions.loadingVariantsCompleted());
}

export function* reloadVariantPage(action) {
  // Update the UI to hide the current variants
  yield put(actions.startLoadingVariants());

  // Load Genes
  yield call(fetchGenes, action);

  // Update the ui to tell it we are done
  yield put(actions.loadingVariantsCompleted());
}

export function* setPatientPanels(action) {
  const { payload: { genePanels = [] } = {} } = action;
  yield put(setConfigGenePanels({ list: genePanels }));

  return action.payload;
}

export function* loadFullVariantsCount({ payload: { patientId, geneId } }) {
  const response = yield call(getGeneVariantCount, null, patientId, geneId);
  const genesCount = yield call([response, response.json]);
  yield put(actions.setAllVariantsCount(genesCount));
}

/**
 * if gene is not in the previously loaded Variants table list,
 * request it or get from the sidebar
 */
export function* saveMissingGene(patientId, geneId) {
  // when choosing a gene from a sidebar
  const { payload: { list: genes = [] } = {} } = yield call(callApiForGenes, {
    patientId,
    page: 1,
    additionalQueryParams: { gene_id: geneId },
  });

  let filteredGenes = genes;
  // happens for genes from the sidebar with 0 variants
  if (!genes.length) {
    // pick the gene from the sidebar
    const configPanelGene = yield select(getConfigPanelGene, geneId);
    // put configPanelGene into genes list
    yield put(actions.addConfigPanelGene(configPanelGene));
    // use it to set genes to display
    filteredGenes = [configPanelGene];
  }

  yield put(actions.setFilteredVariantsByGene(filteredGenes, false));
}

export function* findGene(patientId, geneId) {
  let gene = yield select(getSingleGene, geneId);
  if (!gene) {
    // when choosing a gene from a sidebar
    yield call(saveMissingGene, patientId, geneId);

    while (!gene) {
      // this loop is necessary at the moment
      // there is no easy way to reuse getSingleGene and listen for guaranteed state updates from sagas
      // Must be deleted after getSingleGene is refactored
      gene = yield select(getSingleGene, geneId);
    }
  }

  return gene;
}

// load gene with all variants, not applying filters
export function* loadFullGene({
  payload: { patientId, geneId, variantsReady },
}) {
  if (!variantsReady) {
    yield put(actions.startLoadingVariants());
    const searchSettings = {
      patientId,
      page: 1,
      applyFilters: false,
      additionalQueryParams: { gene_id: geneId },
    };
    yield call(callApiForGenes, searchSettings);
    yield put(actions.loadingVariantsCompleted());
  }
  yield put(actions.setFilteredOutVariantsDisplayed(true));
}

export function* resetActiveGene() {
  if (window.unmountACMGComponents) {
    window.unmountACMGComponents();
  }

  yield put(actions.resetVariantPanel());
}

const getSelectedGeneParams = (gene, variantId) => {
  const { name: geneName, variants = [] } = gene;

  let selectedVariant = null;
  if (variantId) {
    // if a specific variant is selected
    selectedVariant = variants.find(({ variantId: vId }) => vId === variantId);
  } else {
    // if a gene is selected, select the first available variant
    selectedVariant = variants.length ? variants[0] : {};
    variantId = selectedVariant.variantId;
  }

  const { patientVariantId, defaultVisibleTranscript } = selectedVariant;
  const { transcriptId } = defaultVisibleTranscript || {};
  return {
    geneName,
    variantId,
    patientVariantId,
    transcriptId,
  };
};

/**
 * check if selected gene params (geneId, variantId, transcriptId) are about to change
 * @param action - gene selection action
 * @return true, if any of the params have changed, false - otherwise
 */
export function* geneSelectionParamsChanged(action) {
  const { geneId: newGeneId, variantId: newVariantId } = action.payload;

  const currentGeneId = yield select(selectors.activeVariantPanelGene);
  if (!currentGeneId || newGeneId !== currentGeneId) {
    return true;
  }

  const gene = yield select(getSingleGene, newGeneId);
  if (!gene) {
    return true;
  }

  const { variantId: newVariantIdToSelect, transcriptId: newTranscriptId } =
    getSelectedGeneParams(gene, newVariantId);

  const currentVariantId = yield select(selectors.activeVariantPanelVariant);
  const currentTranscriptId = yield select(
    selectors.activeVariantPanelTranscript
  );

  if (
    newVariantIdToSelect !== currentVariantId ||
    newTranscriptId !== currentTranscriptId
  ) {
    return true;
  }

  return false;
}

export function* loadVariantDetails(action) {
  const geneSelectionChanged = yield call(geneSelectionParamsChanged, action);
  if (!geneSelectionChanged) {
    return;
  }

  if (window.unmountACMGComponents) {
    window.unmountACMGComponents();
  }
  yield put(actions.startLoadingVariants());

  const { patientId, geneId, variantId: selectedVariantId } = action.payload;

  const [gene] = yield all([
    call(findGene, patientId, geneId),
    put(actions.loadGeneVariantsFullCount(patientId, geneId)),
  ]);
  const { geneName, variantId, patientVariantId, transcriptId } =
    getSelectedGeneParams(gene, selectedVariantId);
  yield put(actions.setGeneToFocus(geneId));
  yield put(
    actions.setActiveVariantPanelValues(
      geneName,
      geneId,
      variantId,
      patientVariantId,
      transcriptId
    )
  );

  const acmgSuggestionsActive = yield select(
    isProjectFeatureActive,
    AUTO_ACMG_FEATURE
  );
  if (variantId && acmgSuggestionsActive) {
    yield call(loadSuggestedAcmgCriteria, {
      payload: {
        patientId,
        variantId,
        geneId,
      },
    });
  }

  yield put(actions.loadingVariantsCompleted());
}

export function* fetchGenes(action) {
  yield put(actions.setGeneToFocus(null));
  const {
    payload: { searchParams = {} },
    meta: { patientId },
  } = action;
  const searchSettings = { ...searchParams, patientId };

  const { payload: { list: genes = [], pager = {} } = {} } = yield call(
    callApiForGenes,
    searchSettings
  );

  // Record these genes as the ones we are currently displaying
  yield put(actions.setActiveGenes(genes.map(prop("geneId"))));

  yield put(actions.setFilteredVariantsByGene(genes));

  yield put(actions.setPager(pager));
}

export function* callApiForGenes({
  patientId,
  filters: newFilters,
  prioritisation: newPrioritisation,
  customPage,
  applyFilters = true,
  additionalQueryParams = {},
}) {
  const prioritisation =
    newPrioritisation ||
    (yield select(configSelectors.getPrioritisationValues));

  const storedPage = yield select(selectors.page);

  const page = customPage || storedPage;
  const query = {
    page,
    order_by: prioritisation.map(item => decamelize(item)),
    ...additionalQueryParams,
  };

  if (applyFilters) {
    const currentFilter =
      newFilters || (yield select(configSelectors.getFilterValues));

    query.filters = normalizeCurrentFilter(currentFilter, {
      isAriadneEnabled: yield select(isAriadneEnabled),
      featureFlags: yield select(getCurrentProjectFeatures),
    });
    query.filter_preset_id = yield select(getSelectedPresetId);
    query.is_custom = yield select(isSelectedPresetCustomized, patientId);
  }

  yield put(
    apiAction(
      "Gene",
      {
        id: patientId,
        query,
      },
      "READ",
      {},
      "LATEST"
    )
  );

  return yield take(constants.API_READ_GENE_SUCCEEDED);
}

export function* fetchDecisionsForPatient(action) {
  yield put(
    apiAction("Decision", {
      id: action.meta.patientId,
    })
  );

  return yield take(constants.API_READ_DECISION_SUCCEEDED);
}

export function* fetchGenePanelVariantCount(action) {
  const {
    payload: { searchParams: { filters: newFilters } = {} },
  } = action;
  const filters = newFilters || (yield select(configSelectors.getFilterValues));
  const normalisedFilters = JSON.stringify(
    normalizeCurrentFilter(filters, {
      isAriadneEnabled: yield select(isAriadneEnabled),
      featureFlags: yield select(getCurrentProjectFeatures),
    })
  );

  const response = yield call(
    getGeneVariantCount,
    normalisedFilters,
    action.meta.patientId
  );
  const allGenes = yield call([response, response.json]);

  yield put(actions.getGeneVariantCount(allGenes));
}

export function* retrieveGenePanels(filteredPanelIds) {
  if (filteredPanelIds && filteredPanelIds.length) {
    yield put(
      apiAction("GenePanel", {
        id: filteredPanelIds.join(","),
      })
    );

    const result = yield take(constants.API_READ_GENEPANEL_SUCCEEDED);
    return result.payload;
  }

  return { list: [] };
}

export function* fetchGenePanels(action) {
  const {
    payload: { searchParams: { filters: newFilters } = {} },
  } = action;
  const filters = newFilters || (yield select(configSelectors.getFilterValues));
  const filteredPanelIds = filters.genePanels;

  if (filters.location === "panels") {
    const genePanels = yield call(retrieveGenePanels, filteredPanelIds);
    yield put(entitiesActions.setConfigGenePanels(genePanels));
  }
}
