// @flow

import { set } from "dot-prop-immutable";
import { find, propEq } from "ramda";

import type { State } from "./flow-types";

/**
 * conditionally update the value and the transcript of the given decision
 * @param decision - the decision that will be updated if it matches the criteria
 * @param update - the object with the updates to apply in the target decision
 * @return the updated decision if it matches the criteria, otherwise - the original decision
 */
const applyUpdate = (
  decision: Decision,
  update: ?DecisionInfo = null
): Decision => {
  const { variantId, decisionType, transcriptId, value } = update || {};
  if (
    variantId !== decision.variantId ||
    decisionType !== decision.decisionType
  ) {
    return decision;
  }
  return {
    ...decision,
    transcriptId,
    value,
  };
};

/**
 * group decisions by the most used criteria (decision id, variantId, geneId) for faster retrieval.
 * Object key lookup needs constant time, array loop - linear
 * @param decisions
 * @param excludeId - the id of the decision that should be removed
 * @param update - the decision info that needs to be updated
 * @return the new state with the {{decisionsById: {}, decisionsByVariantId: {}, decisionsByGeneId: {}}}
 */
export const groupDecisions = (
  state: State,
  decisions: Array<Decision>,
  excludeId: ?number = null,
  update: ?DecisionInfo = null
): State => {
  // NOTE: if regrouping from scratch gets too expensive, consider updating via Immutable.js
  // mutation is basically possible as well, but must be applied with caution
  const decisionsByVariantId: {} = {};
  const decisionsByGeneId: {} = {};
  const decisionsById: {} = {};

  (decisions || []).forEach((decision: Decision) => {
    const { patientVariantDecisionId, variantId, geneId } = decision;
    if (patientVariantDecisionId === excludeId) {
      return;
    }

    const decisionToPut = applyUpdate(decision, update);

    decisionsByVariantId[variantId] = decisionsByVariantId[variantId] || [];
    decisionsByVariantId[variantId].push(decisionToPut);

    decisionsByGeneId[geneId] = decisionsByGeneId[geneId] || [];
    decisionsByGeneId[geneId].push(decisionToPut);

    decisionsById[patientVariantDecisionId] = decisionToPut;
  });

  return set(state, "data.currentPatientDecisions", {
    decisionsByVariantId,
    decisionsByGeneId,
    decisionsById,
  });
};

export const findInDecisionGroups = (
  decisionGroups: DecisionGroups,
  variantId: number,
  decisionType: DecisionType
): ?Decision => {
  const { decisionsByVariantId } = decisionGroups;
  const variantDecisions = decisionsByVariantId[variantId];
  if (!variantDecisions) {
    return null;
  }

  return find(propEq("decisionType", decisionType))(variantDecisions);
};

const findDecision = (
  state: State,
  variantId: number,
  decisionType: DecisionType
): ?Decision => {
  const decisionGroups: DecisionGroups = state.data.currentPatientDecisions;
  return findInDecisionGroups(decisionGroups, variantId, decisionType);
};

const getDecisionsArray = (state: State): Array<Decision> => {
  const { decisionsById } = state.data.currentPatientDecisions;
  //$FlowFixMe
  return Object.values(decisionsById);
};

export const deleteVariantDecision = (
  state: State,
  variantId: number,
  decisionType: DecisionType
): State => {
  const decisionToDelete = findDecision(state, variantId, decisionType);
  if (!decisionToDelete) {
    return state;
  }

  return groupDecisions(
    state,
    getDecisionsArray(state),
    decisionToDelete.patientVariantDecisionId,
    null
  );
};

export const updateVariantDecision = (state: State, update: DecisionInfo) => {
  const { value, variantId, decisionType } = update;
  if (!value) {
    return deleteVariantDecision(state, variantId, decisionType);
  }

  return groupDecisions(state, getDecisionsArray(state), null, update);
};
