import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const LabelField = ({
  label,
  value,
  className,
  isFormField = false,
  warning,
}) => {
  const labelClasses = classNames({
    "col-sm-6": !isFormField,
    "col-sm-4": isFormField,
  });
  const valueColSize = isFormField ? 8 : 6;
  const valueClasses = classNames({
    "col-sm-6": !isFormField,
    "col-sm-8": isFormField,
  });
  return (
    <div
      className={classNames(className, "form-group row", {
        "has-warning": !!warning,
      })}
    >
      <label className={labelClasses}>{label}</label>
      <div className={valueClasses}>{value}</div>
      {!!warning && (
        <div
          className={classNames({
            "col-sm-12": !label,
            [`col-sm-offset-${12 - valueColSize}`]: label,
            [`col-sm-${valueColSize}`]: label,
          })}
        >
          <span className="help-block">{warning}</span>
        </div>
      )}
    </div>
  );
};

LabelField.propTypes = {
  className: PropTypes.string,
  /**
   * set to true to match the alignment of a FormField
   */
  isFormField: PropTypes.bool,
  /**
   * The text to display for the label
   */
  label: PropTypes.string,
  /**
   * The value of this field
   */
  value: PropTypes.node,
};

export default LabelField;
