import styles from "./VariantsTableHeader.module.scss";

type Props = {
  colKey: string;
  header: string;
  subtitle?: string;
};

const VariantsTableHeader = ({ colKey, header, subtitle }: Props) => (
  <div
    className={`variant-column variant-column--${colKey} ${styles.container}`}
    style={{ display: "block" }}
  >
    <div>{header}</div>
    {subtitle && (
      <div>
        <small className={styles.subtitle}>{subtitle}</small>
      </div>
    )}
  </div>
);

export default VariantsTableHeader;
