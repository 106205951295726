import React from "react";

import { ComposableTabs, Tab } from "pattern-library";

export type TabElement = {
  name: string,
  component: React.Component,
};

const getTabs = (tabs: Array<TabElement>, props: object) =>
  tabs.map(({ name, component: Component }) => (
    <Tab key={name} name={name}>
      <Component {...props} />
    </Tab>
  ));

const TabsLayout = ({
  navStyle = false,
  stacked = false,
  tabs = [],
  ...rest
}) => (
  <ComposableTabs navStyle={navStyle} stacked={stacked}>
    {getTabs(tabs, rest)}
  </ComposableTabs>
);

export default TabsLayout;
