import React from "react";

import MorbidBadge from "./MorbidBadge";
import OmimLinks from "./OmimLinks";

const GeneColumn = ({
  name,
  pathogenicity,
  synonyms,
  description,
  morbidId,
  omimId,
  condition,
  autosomalDominant,
  autosomalRecessive,
  xLinked,
  onClick,
}) => (
  <div className="gene gene-column" onClick={onClick}>
    <p className={`gene-name gene-name--${pathogenicity}`}>
      {name} {morbidId && omimId && <MorbidBadge />}
    </p>
    {synonyms && <p>Synonyms: {synonyms}</p>}
    {description && <p className="gene-description">{description}</p>}
    {omimId && (
      <p>
        OMIM: <OmimLinks ids={omimId} />
      </p>
    )}
    {morbidId && (
      <p>
        Morbid: <OmimLinks ids={morbidId} />
      </p>
    )}
    {condition && <p>Conditions: {condition}</p>}
    {!!autosomalDominant && (
      <p>
        <span className="label label-dark-danger">autosomal dominant</span>
      </p>
    )}
    {!!autosomalRecessive && (
      <p>
        <span className="label label-warning">autosomal recessive</span>
      </p>
    )}
    {!!xLinked && (
      <p>
        <span className="label label-dark-danger">x-linked</span>
      </p>
    )}
  </div>
);

export default GeneColumn;
