import { isEmpty } from "ramda";
import * as yup from "yup";

import { isSupportedDateFormat } from "modules/utils";
import { notEmpty } from "modules/utils/array";
import {
  decamelizeIgnoreUpperCase,
  camelizeIgnoreUpperCase,
} from "modules/utils/common";
import { metadataAffectionStatus } from "modules/utils/enum/affectionStatus";
import metadataAgeOptions from "modules/utils/enum/age";
import ethnicities from "modules/utils/enum/ethnicity";

import {
  CSV_EMPTY_VALUES,
  INVALID_FAMILY_NAME,
  UNSUPPORTED_FILE_TYPE,
  VALID_SEX_VALUES_HINT,
} from "../constants";
import { CSVValidationSchemaParams, Entries } from "../types";
import { getFieldNameFormPath } from "../utils";

import { affectionStatusOptions, genderOptions } from "./componentUtils";
import {
  equalIgnoreCase,
  formatFileType,
  toArray,
  toBoolean,
  toIntArray,
} from "./csvUtils";

yup.addMethod(
  yup.mixed,
  "unique",
  function (array: Array<any> = [], message?: string) {
    return this.test(
      "unique",
      message,
      value => array.indexOf(value) === array.lastIndexOf(value)
    );
  }
);

yup.addMethod(
  yup.mixed,
  "notAddedToPage",
  function (array: Array<any> = [], message?: string) {
    return this.test(
      "notAddedToPage",
      message,
      value => !array.includes(value)
    );
  }
);

function testMissingItems(testName: string, hint?: string) {
  return function (allItems: Array<string> = [], message?: string) {
    return this.test(testName, message, (values, context) => {
      const missingItems = values.filter(value => !allItems.includes(value));
      if (notEmpty(missingItems)) {
        return context.createError({
          params: { value: missingItems, hint },
        });
      }
      return true;
    });
  };
}

yup.addMethod(
  yup.array,
  "inHpoTerms",
  testMissingItems(
    "inHpoTerms",
    "These HPO terms were not found in the Congenica database. " +
      "This could be a typo, or sometimes recently added HPO terms are not yet included in Congenica. " +
      "You could remove or replace, e.g. with the parent HPO term from hpo.jax.org"
  )
);

yup.addMethod(
  yup.array,
  "inProjectGenePanels",
  testMissingItems("inProjectGenePanels")
);

yup.addMethod(
  yup.mixed,
  "oneOfCaseInsensitive",
  function (array: Array<any> = [], message?: string, hint?: string) {
    return this.test(
      "oneOfCaseInsensitive",
      message,
      (value, context) =>
        array.some(item => equalIgnoreCase(item, value)) ||
        context.createError({
          params: { hint },
        })
    );
  }
);

function testInstallationFileType(testName) {
  return function (
    installationTypeFlag: boolean,
    message: string = UNSUPPORTED_FILE_TYPE
  ) {
    return this.test(testName, message, function (value, context) {
      return (
        installationTypeFlag ||
        !formatFileType(value) ||
        context.createError({
          params: {
            value: decamelizeIgnoreUpperCase(getFieldNameFormPath(this.path)),
          },
        })
      );
    });
  };
}

yup.addMethod(
  yup.mixed,
  "onPremOnlyFileType",
  testInstallationFileType("onPremOnlyFileType")
);

yup.addMethod(
  yup.mixed,
  "saasOnlyFileType",
  testInstallationFileType("saasOnlyFileType")
);

yup.addMethod(
  yup.mixed,
  "existsInList",
  function (list: Array<any> = [], message?: string) {
    return this.test("existsInList", message, value => list.includes(value));
  }
);

function testParentSex(testName: string, expectedSex: string) {
  return function (csvRows: Array<any> = [], message?: string) {
    return this.test(testName, message, parentName => {
      if (!parentName) {
        return true;
      }
      const foundRow = csvRows.find(({ name }) => name === parentName);
      if (!foundRow) {
        return true;
      }
      const foundRowSex = foundRow?.sex;
      return equalIgnoreCase(foundRowSex, expectedSex);
    });
  };
}

yup.addMethod(
  yup.mixed,
  "maleParent",
  testParentSex("maleParent", genderOptions.Male)
);

yup.addMethod(
  yup.mixed,
  "femaleParent",
  testParentSex("femaleParent", genderOptions.Female)
);

yup.addMethod(
  yup.mixed,
  "familyName",
  function (csvRows: Array<any> = [], message: string = INVALID_FAMILY_NAME) {
    return this.test("familyName", message, function (familyName, context) {
      const parentsNames = [
        this.parent.motherName,
        this.parent.fatherName,
      ].filter(Boolean);

      if (isEmpty(parentsNames)) {
        return true;
      }

      const rowsWithInvalidFamilyName = csvRows.filter(
        ({ name, familyName: parentFamilyName }) =>
          parentsNames.includes(name) && parentFamilyName !== familyName
      );

      return (
        isEmpty(rowsWithInvalidFamilyName) ||
        context.createError({
          params: { value: rowsWithInvalidFamilyName.map(({ name }) => name) },
        })
      );
    });
  }
);

const metadataValidationSchema = name => ({
  Text: yup.string(),
  TextArea: yup.string(),
  "Select::Sex": yup
    .string()
    .oneOfCaseInsensitive(
      [...CSV_EMPTY_VALUES, ...Object.values(genderOptions)],
      `Invalid values for ${name} are found:`,
      VALID_SEX_VALUES_HINT
    ),
  Date: yup
    .string()
    .test(
      "validDate",
      "Unsupported date format:",
      value => !value || isSupportedDateFormat(value)
    ),
  "Select::ParentalPhenotype": yup
    .string()
    .oneOfCaseInsensitive(
      [
        ...CSV_EMPTY_VALUES,
        ...metadataAffectionStatus.map(({ label }) => label),
      ],
      `Invalid values for ${name} are found:`
    ),
  "Select::Age": yup
    .string()
    .oneOfCaseInsensitive(
      [...CSV_EMPTY_VALUES, ...metadataAgeOptions],
      `Invalid values for ${name} are found:`
    ),
  "Select::Ethnicity": yup
    .string()
    .oneOfCaseInsensitive(
      [
        ...CSV_EMPTY_VALUES,
        ...ethnicities.map(({ options }) => options).flat(),
      ],
      `Invalid values for ${name} are found:`
    ),
});

export const getCsvSchema = ({
  csvRows,
  existingSamplesTypes,
  allHpoTerms,
  projectGenePanelsId,
  sampleTypeNames,
  protocolNames,
  isOnPrem,
  metadataFields,
}: CSVValidationSchemaParams) =>
  yup.array(
    yup.object({
      name: yup
        .string()
        .unique(
          csvRows.map(({ name }) => name),
          "Duplicated Sample IDs are found:"
        )
        .notAddedToPage(
          Object.keys(existingSamplesTypes),
          "Records with identical Sample ID exist:"
        ),
      sex: yup
        .string()
        .oneOfCaseInsensitive(
          [...CSV_EMPTY_VALUES, ...Object.values(genderOptions)],
          "Invalid values for sex are found:",
          VALID_SEX_VALUES_HINT
        ),
      affectionStatus: yup
        .string()
        .oneOfCaseInsensitive(
          ["", ...Object.values(affectionStatusOptions)],
          "Invalid values for affection_status are found:"
        ),
      hpoTerms: yup
        .array()
        .transform((value, originalValue) => toArray(originalValue))
        .inHpoTerms(
          allHpoTerms.map(({ hpoTermId }) => hpoTermId),
          "Unknown HPO terms are found:"
        ),
      genePanelIds: yup
        .array()
        .transform((value, originalValue) => toIntArray(originalValue))
        .inProjectGenePanels(
          projectGenePanelsId,
          "Gene panels are not found for the project:"
        ),
      isProband: yup
        .string()
        .oneOf(
          [...CSV_EMPTY_VALUES, "0", "1"],
          "Invalid values for is_proband are found:"
        ),
      sampleType: yup
        .string()
        .oneOfCaseInsensitive(
          [...CSV_EMPTY_VALUES, ...sampleTypeNames],
          "Invalid values for sample_type are found:"
        ),
      protocol: yup
        .string()
        .oneOf(
          [...CSV_EMPTY_VALUES, ...protocolNames],
          "Protocols are not found for the project:"
        ),
      cnvAnalysisRequested: yup
        .string()
        .oneOf(
          [...CSV_EMPTY_VALUES, "0", "1"],
          "Invalid values for cnv_analysis_requested:"
        )
        .test(
          "saasOnly",
          "CNV Analysis can't be requested for the on-prem installation",
          (value, context) =>
            !isOnPrem ||
            !toBoolean(value) ||
            context.createError({
              params: { showValues: false },
            })
        ),
      fastqR1: yup.string().saasOnlyFileType(!isOnPrem),
      fastqR2: yup.string().saasOnlyFileType(!isOnPrem),
      vcfCnv: yup.string().onPremOnlyFileType(isOnPrem),
      vcfSv: yup.string().onPremOnlyFileType(isOnPrem),
      fatherName: yup
        .string()
        .existsInList(
          [...CSV_EMPTY_VALUES, ...csvRows.map(({ name }) => name)],
          "Unknown values for father_name are found:"
        )
        .maleParent(
          csvRows,
          `Sample IDs used in father_name should have ${genderOptions.Male} sex:`
        ),
      motherName: yup
        .string()
        .existsInList(
          [...CSV_EMPTY_VALUES, ...csvRows.map(({ name }) => name)],
          "Unknown values for mother_name are found:"
        )
        .femaleParent(
          csvRows,
          `Sample IDs used in mother_name should have ${genderOptions.Female} sex:`
        ),
      familyName: yup.string().familyName(csvRows),
      ...metadataFields.reduce((acc, { name, fieldType }) => {
        acc[camelizeIgnoreUpperCase(name)] =
          metadataValidationSchema(name)[fieldType];
        return acc;
      }, {}),
    })
  );

export const validateHeaders = ({
  csvName,
  csvHeaders,
  csvHeadersMap,
  error,
  warning,
}): boolean => {
  let headersValid = true;
  const expectedHeaders = Object.keys(csvHeadersMap);
  const missingRequiredColumns: Array<string> = [];
  const missingOptionalColumns: Array<string> = [];
  (Object.entries(csvHeadersMap) as Entries<any>).forEach(
    ([expectedHeader, headerConfig]) => {
      if (!csvHeaders.includes(expectedHeader)) {
        const { required } = headerConfig;
        (required ? missingRequiredColumns : missingOptionalColumns).push(
          expectedHeader
        );
      }
    }
  );

  const unknownHeaders = csvHeaders.filter(
    csvHeader => !expectedHeaders.includes(csvHeader)
  );
  if (notEmpty(unknownHeaders)) {
    warning(
      `${csvName} - unsupported columns are found: ${unknownHeaders
        .map(decamelizeIgnoreUpperCase)
        .join(
          ", "
        )}. Values from these columns won't be used in the IR submission`
    );
  }

  if (notEmpty(missingOptionalColumns)) {
    warning(
      `${csvName} - non mandatory columns are missing: ${missingOptionalColumns
        .map(decamelizeIgnoreUpperCase)
        .join(", ")}`
    );
  }

  if (notEmpty(missingRequiredColumns)) {
    error(
      `${csvName} - required columns are missing: ${missingRequiredColumns
        .map(decamelizeIgnoreUpperCase)
        .join(", ")}. Please add them to the IR csv and re-upload it once again`
    );
    headersValid = false;
  }

  return headersValid;
};
