import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { Actions } from "pattern-library";

import { getCVLDetails, exportCvlVariants } from "../actions";
import { canExportCVL } from "../selectors";
import { CVLAction, ProjectCuratedVariantList } from "../types";
import { getCuratedVariantsExportAction, getViewEditAction } from "../utils";

interface InheritedCVLRowActionsProps extends PropsFromRedux {
  cvl: ProjectCuratedVariantList;
}

export const InheritedCVLRowActions = ({
  cvl,
  getCVLDetails,
  canExportCVL,
  exportCvlVariants,
}: InheritedCVLRowActionsProps) => {
  const actions: Array<CVLAction> = [];

  const { curatedVariantListId, originProjectId, variantCount } = cvl;

  if (curatedVariantListId) {
    actions.push(
      getViewEditAction(getCVLDetails, curatedVariantListId, originProjectId)
    );
  }

  if (canExportCVL) {
    actions.push(
      getCuratedVariantsExportAction(
        exportCvlVariants,
        curatedVariantListId,
        variantCount
      )
    );
  }

  return <Actions actions={actions} justifyContent="space-around" />;
};

const mapStateToProps = (state, { cvl }) => ({
  canExportCVL: canExportCVL(state, cvl),
});

const mapDispatchToProps = {
  getCVLDetails,
  exportCvlVariants,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(InheritedCVLRowActions);
