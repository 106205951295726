import React, { PureComponent } from "react";

export default class MoiColumn extends PureComponent {
  render() {
    const { variant, header } = this.props;

    return (
      <div
        className={`variant-column ${header.toLowerCase().replace(/ /g, "_")}`}
      >
        {variant.moiAd === 1 && <span className="label label-default">AD</span>}
        {variant.moiArh === 1 && (
          <span className="label label-default">ARH</span>
        )}
        {variant.moiArch === 1 && (
          <span className="label label-default">ARCH</span>
        )}
        {variant.moiXld === 1 && (
          <span className="label label-default">XLD</span>
        )}
        {variant.moiXlr === 1 && (
          <span className="label label-default">XLR</span>
        )}
        {variant.moiXlrch === 1 && (
          <span className="label label-default">XLRCH</span>
        )}
        {variant.moiMt === 1 && <span className="label label-default">MT</span>}
      </div>
    );
  }
}
