import { path, sortWith, ascend, descend, compose, toLower } from "ramda";

import { fetchCamelizeData } from "../utils";
import { API_ENTITIES_BASE_URL } from "../utils/baseUrls";

export const alphaDefaultSort = sortWith([
  descend(path(["attributes", "isDefault"])),
  ascend(compose(toLower, path(["attributes", "title"]))),
  ascend(path(["attributes", "variantType"])),
]);

export const getPresetsURL = ({
  projectId,
  variantType,
  active,
  sort = "title",
}) => {
  const baseUrl = `${API_ENTITIES_BASE_URL}/projects/${projectId}/variant-filter-presets`;

  // TODO: Use library such as axios for cleaner HTTP requests & query params
  const queryParams = [
    variantType ? `filter[variant_type]=${variantType}` : null,
    active ? `filter[is_active]=${active}` : null,
    sort ? `sort=${sort}` : null,
  ]
    .filter(Boolean)
    .join("&");

  return `${baseUrl}?${queryParams}`;
};

export const getFilterPresets = async ({
  projectId,
  variantType,
  active,
  sort,
}) => {
  const url = getPresetsURL({ projectId, variantType, active, sort });

  try {
    const response = await fetchCamelizeData(url);
    const presets = path(["payload", "data"], response) || [];

    if (!response.ok) {
      return response;
    }

    return {
      ...response,
      payload: {
        ...response.payload,
        data: alphaDefaultSort(presets),
      },
    };
  } catch (err) {
    throw new Error(
      `Could not load ${variantType || "all"} filter presets: ${err}`
    );
  }
};
