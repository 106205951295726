import Genoverse from "genoverse";

export const Model = Genoverse.Track.Model.extend({
  parseURL(chr, start, end, url) {
    const binSize = Math.max(Math.ceil(this.browser.length / this.width), 1);
    this.urlParams = { ...this.urlParams, binSize };
    return this.base(chr, start, end, url);
  },
  // This is needed as Genoverse by default is intrested how
  // much of chromosome it can cover with data it currently have.
  // So this way you force it to redownload data in different binSize
  checkDataRange(chr, start, end) {
    const currentRegionSize = end - start;
    if (currentRegionSize !== this.prevRegionSize) {
      this.prevRegionSize = currentRegionSize;
      return false;
    }

    return this.base(chr, start, end);
  },
});
