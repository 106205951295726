import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Icon } from "../../elements";

export default class FileThumbnail extends PureComponent {
  static displayName = "FileThumbnail";

  static propTypes = {
    file: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["image", "document"]).isRequired,
      size: PropTypes.string.isRequired,
    }).isRequired,
    backgroundUrl: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    onFileClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    backgroundUrl: "",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  onFileClick() {
    const { onFileClick, file } = this.props;
    onFileClick(file);
  }

  remove(e) {
    const { file, onRemove } = this.props;
    e.stopPropagation();
    onRemove(file);
  }

  render() {
    const {
      backgroundUrl,
      file: { name, size, type },
    } = this.props;

    return (
      <div
        className="file-thumbnail"
        style={{ backgroundImage: `url(${backgroundUrl})` }}
        onClick={this.onFileClick}
      >
        <div className="file-thumbnail-content">
          <div className="file-thumbnail-labels">
            <span className="label label-info">{type}</span>
          </div>
          <div className="file-thumbnail-filename">{name}</div>
          <div className="file-thumbnail-filesize">{size}</div>
        </div>
        <Icon className="remove" type="cross" onClick={this.remove} />
      </div>
    );
  }
}
