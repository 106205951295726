import { useEffect, useRef, useState } from "react";

export function usePrevious<T = undefined>(value): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useFirstRender(): boolean {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}

export const makeSafeForCSS: (name: string) => string = name =>
  name.replace(/[^a-z0-9]/g, s => {
    const c = s.charCodeAt(0);
    if (c === 32) return "-";
    if (c >= 65 && c <= 90) return "-" + s.toLowerCase();
    return "__" + ("000" + c.toString(16)).slice(-4);
  });

export const replaceLineBreaksWithBR: (str: string) => string = str =>
  str.replace(/(\r\n|\r|\n)/g, "<br />");
