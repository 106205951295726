import { camelizeKeys } from "humps";
import param from "jquery-param";

export const getFormData = (obj: Index<string | Blob>) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(obj)) {
    formData.append(key, value);
  }
  return formData;
};

export type FetchDataResponse<T> = {
  ok: boolean;
  status?: number;
  statusText?: string;
  errors?: Array<any>;
  payload?: T | null;
  file?: SentFile;
  messages?: {
    error?: string;
    info?: string;
    success?: string;
    warning?: string;
  };
};

type SentFile = Partial<{ blob: Blob; filename: string }>;

const defaultParams: { credentials: RequestCredentials } = {
  credentials: "include",
};

export const fetchData = async <T>(
  url: string,
  params?: RequestInit,
  responseIsFile = false
): Promise<FetchDataResponse<T>> => {
  try {
    const response = await fetch(url, { ...defaultParams, ...params });
    const headerMessageLevels = ["success", "info", "warning", "error"];
    const headerMessages = {};
    const file: SentFile = {};
    const { ok, status, statusText, headers } = response;
    // This code handles legacy way of Perl sending success, failure messages inside headers.
    // These messages are collected and added into response. From there we need to decide to use them or not
    headerMessageLevels.forEach(level => {
      const message = headers.get(`x-message-${level}`);

      if (message) {
        headerMessages[level] = decodeURIComponent(message);
      }
    });

    if (responseIsFile) {
      const filenameHeader = headers.get("content-disposition");
      if (filenameHeader) {
        const [, filename] = filenameHeader.split("; filename=");
        file.filename = filename;
      }
      file.blob = await response.blob();
    }

    const result: FetchDataResponse<T> = {
      ok,
      status,
      statusText,
      messages: headerMessages,
      file,
    };
    try {
      const responseBody = await response.json();
      result.payload = responseBody;
      if (!ok) {
        result.errors = responseBody.errors;
      }
    } catch (error) {
      result.payload = null;
    }
    return result;
  } catch (error) {
    return { ok: false };
  }
};

export const fetchCamelizeData = async <T extends Index>(
  url: string,
  params?: RequestInit,
  skipUpperCase = false,
  responseIsFile = false
): Promise<FetchDataResponse<T>> => {
  const result = await fetchData<T>(url, params, responseIsFile);
  if (result.ok) {
    result.payload = camelizeKeys(
      result.payload,
      skipUpperCase
        ? (key, convert) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key))
        : undefined
    );
  }
  return result;
};

export const fetchPerlEndpointData = <T extends Index>(
  url: string,
  params?: RequestInit,
  skipUpperCase = false
): Promise<FetchDataResponse<T>> =>
  fetchCamelizeData(
    url,
    {
      ...params,
      headers: {
        Accept: "application/vnd.congenica.json",
      },
    },
    skipUpperCase
  );

export const buildTableQueryParams = ({
  pageNumber,
  pageSize,
  sort,
  desc,
  filter,
  ...rest
}: Partial<TableRequestParams>): string => {
  const queryObject: Index = {};

  if (pageNumber && pageSize) {
    queryObject.page = {
      size: pageSize,
      number: pageNumber,
    };
  }

  if (sort) {
    queryObject.sort = desc ? `-${sort}` : `${sort}`;
  }
  if (filter) {
    queryObject.filter = filter;
  }

  if (rest) {
    Object.assign(queryObject, rest);
  }

  return param(queryObject);
};

export const buildDataTableQuery = ({
  pageNumber,
  pageSize,
  sort,
  desc,
  filter,
  columns,
  ...rest
}: Partial<TableRequestParams> = {}): Partial<TableRequestParams> => {
  const queryObject: Index = {};

  if (pageNumber && pageSize) {
    queryObject.length = pageSize;
    queryObject.start = (pageNumber - 1) * pageSize;
  } else {
    //hacky way to get all records for those who doesn't use server-side pagination
    queryObject.length = 100000000000000;
  }

  if (columns) {
    queryObject.columns = columns;
  }
  if (sort && columns) {
    queryObject.order = [
      {
        column: columns.findIndex(({ data }) => sort === data),
        dir: desc ? "desc" : "asc",
      },
    ];
  }
  if (filter) {
    queryObject.search = {
      value: filter,
      regex: false,
    };
  }

  if (rest) {
    Object.assign(queryObject, rest);
  }

  return queryObject;
};

export const buildDataTableQueryParams = (
  params: Partial<TableRequestParams> = {}
): string => param(buildDataTableQuery(params));
