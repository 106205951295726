import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { memo, useContext, useEffect } from "react";

import { Divider } from "pattern-library";

import FormikFormField from "modules/forms/components/formikFormField/FormikFormField";
import { AutomationContext } from "modules/projectSettings/components/Automation";

export const getFieldName = name => `automation.${name}`;

const AutomationEditor = memo(({ cvlList, reportServiceTemplates }) => {
  const {
    values: {
      automation: {
        enabled,
        isInherited,
        automatedReportingEnabled,
        curatedVariantListId,
      },
    },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (!cvlList.some(({ value }) => value === curatedVariantListId)) {
      setFieldValue(getFieldName("curatedVariantListId"), null);
    }
  }, [cvlList, curatedVariantListId, setFieldValue]);

  const isSentieonAvailable = useContext(AutomationContext);
  return (
    <>
      <Field
        type="checkbox"
        component={FormikFormField}
        label="Automation"
        name={getFieldName("enabled")}
        disabled={isInherited}
        narrow
      />
      <Field
        type="select"
        component={FormikFormField}
        label="Curated variant list"
        name={getFieldName("curatedVariantListId")}
        id="curatedVariantListId"
        options={cvlList}
        isMulti={false}
        disabled={!enabled || isInherited}
        narrow
        className="project-automation-cvl"
      />
      <Field
        type="checkbox"
        component={FormikFormField}
        label="MOI Restriction"
        description="Enabling will ensure that auto-classification and reporting are only applied to variants that are consistent with the panel defined MOI"
        descriptionPosition="underInput"
        name={getFieldName("moiRestrictionEnabled")}
        disabled={!enabled || isInherited}
        narrow
      />
      <Divider />
      <Field
        type="checkbox"
        component={FormikFormField}
        label="Automated Reporting"
        name={getFieldName("automatedReportingEnabled")}
        disabled={!enabled || isInherited}
        narrow
      />
      <Field
        type="select"
        component={FormikFormField}
        label="Report Template"
        name={getFieldName("reportTemplateId")}
        id="reportTemplateId"
        options={reportServiceTemplates}
        isMulti={false}
        disabled={!enabled || !automatedReportingEnabled || isInherited}
        narrow
      />
      {isSentieonAvailable && (
        <>
          <Divider />
          <Field
            type="checkbox"
            component={FormikFormField}
            label="Genotype Ref Calling"
            name={getFieldName("refNoCallEnabled")}
            disabled={!enabled || isInherited}
            narrow
          />
        </>
      )}
    </>
  );
});

AutomationEditor.propTypes = {
  cvlList: PropTypes.array.isRequired,
  reportServiceTemplates: PropTypes.array.isRequired,
};

export { AutomationEditor };
