import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";

export default class Select extends PureComponent {
  static displayName = "Select";

  static propTypes = {
    /**
     * If you want to load options asynchronously, use the async option and provide a loadOptions Function.
     */
    async: PropTypes.bool,
    /**
     * Use the creatable Component (which wraps Select) to allow new options to be created if they do not already exist
     */
    creatable: PropTypes.bool,
    /**
     * Support multiple selected options
     */
    isMulti: PropTypes.bool,
    /**
     * Disable the control
     */
    disabled: PropTypes.bool,
    /**
     * Label to be displayed in the heading component.
     */
    label: PropTypes.node,
    /**
     * placeholder displayed when there are no matching search results or a falsy value to hide it (can also be a react component)
     */
    noResultsText: PropTypes.string,
    /**
     * These props are included with in both the Async and AsyncCreatable select.
     * The default set of options to show before the user starts searching.
     * When set to true, the results for loadOptions('') will be autoloaded.
     */
    autoload: PropTypes.bool,
    /**
     * These props are included with in both the Async and AsyncCreatable select.
     * If cacheOptions is truthy, then the loaded data will be cached.
     * The cache will remain until cacheOptions changes value.
     */
    cacheOptions: PropTypes.bool,

    /**
     * This is a validation result
     */
    hasError: PropTypes.bool,

    /**
     * This is a validation result
     */
    hasWarning: PropTypes.bool,
  };

  static defaultProps = {
    async: false,
    creatable: false,
    isMulti: true,
    disabled: false,
    autoload: false,
    cacheOptions: true,
    noResultsText: "No Results found",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  noOptionsMessage() {
    const { noResultsText } = this.props;
    return noResultsText;
  }

  renderWithSubLabel(option) {
    // For simplicity, return raw string instead of any HTML/JSX when there's no sub label
    if (!option.subLabel) return option.label;

    return (
      <>
        {option.label}
        {option.subLabel && (
          <span>
            {" "}
            <small className="text-muted">{option.subLabel}</small>
          </span>
        )}
      </>
    );
  }

  render() {
    const { creatable, async, autoload, cacheOptions, ...otherProps } =
      this.props;

    const selectProps = {
      ...otherProps,
      formatOptionLabel: this.renderWithSubLabel,
      formatGroupLabel: this.renderWithSubLabel,
      styles: {
        multiValueLabel: (provided, state) => ({
          ...provided,
          // Ensure that sublabels will show on a separate line to the rest of the label,
          // when displayed in a multi-select dropdown
          // Otherwise, they can end up being hidden when the label is too long
          ...(state.data.subLabel && { display: "grid" }),
        }),
      },
    };

    if (async && creatable) {
      return (
        <AsyncCreatableSelect
          className="react-select-container"
          classNamePrefix="react-select"
          defaultOptions={autoload}
          cacheOptions={cacheOptions}
          onBlur={this.onBlur}
          noOptionsMessage={this.noOptionsMessage}
          {...selectProps}
        />
      );
    } else if (async) {
      return (
        <AsyncSelect
          className="react-select-container"
          classNamePrefix="react-select"
          defaultOptions={autoload}
          cacheOptions={cacheOptions}
          onBlur={this.onBlur}
          noOptionsMessage={this.noOptionsMessage}
          {...selectProps}
        />
      );
    } else if (creatable) {
      return (
        <CreatableSelect
          className="react-select-container"
          classNamePrefix="react-select"
          onBlur={this.onBlur}
          noOptionsMessage={this.noOptionsMessage}
          {...selectProps}
        />
      );
    } else {
      return (
        <ReactSelect
          className="react-select-container"
          classNamePrefix="react-select"
          onBlur={this.onBlur}
          noOptionsMessage={this.noOptionsMessage}
          {...selectProps}
        />
      );
    }
  }
}
