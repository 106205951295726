import { react as autoBind } from "auto-bind";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import ReduxFormField from "modules/forms/components/ReduxFormField";
import { isInteger, minAndMaxNumber } from "modules/forms/rules";

import * as selectors from "../../selectors";

export class PopulationAcForm extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    populationAc: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        comparator: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { disabled = false, populationAc } = this.props;
    // Define max value for Int4 database type
    const maxInt = 2 ** 31 - 1;
    return (
      <fieldset disabled={disabled} className="form-horizontal">
        {populationAc.map(({ key, label, comparator }) => (
          <Field
            key={key}
            id={key}
            name={key}
            label={label}
            placeholder={label}
            narrow
            prefix={comparator}
            component={ReduxFormField}
            validate={isInteger}
            normalize={minAndMaxNumber(0, maxInt)}
          />
        ))}
        <div className="form-group row">
          <div className="col-xs-12">
            <h4>Note</h4>
            <p>
              <em>
                AC: the allele count across all populations except Finnish and
                other small populations.
              </em>
            </p>
          </div>
        </div>
      </fieldset>
    );
  }
}

const mapStateToProps = state => ({
  populationAc: selectors.getAllPopulationAc(state),
});

export default connect(mapStateToProps, null)(PopulationAcForm);
