import { fetchData } from "modules/utils";
import { FetchDataResponse } from "modules/utils/fetchData";

import {
  AWSFileDataOutput,
  AWSMultipartFileDataInput,
  AWSPart,
  FinalizationMultiPartInput,
  InitializationUploadInput,
} from "../../types";

export class Api {
  private readonly initializeMultipartUploadUrl: string;
  private readonly getMultipartPreSignedUrlsUrl: string;
  private readonly finalizeMultipartUploadUrl: string;

  constructor(
    initializeMultipartUploadUrl: string,
    getMultipartPreSignedUrlsUrl: string,
    finalizeMultipartUploadUrl: string
  ) {
    this.initializeMultipartUploadUrl = initializeMultipartUploadUrl;
    this.getMultipartPreSignedUrlsUrl = getMultipartPreSignedUrlsUrl;
    this.finalizeMultipartUploadUrl = finalizeMultipartUploadUrl;
  }

  async initializeMultipartUpload(
    initializationUploadInput: InitializationUploadInput
  ): Promise<FetchDataResponse<AWSFileDataOutput>> {
    return await fetchData(this.initializeMultipartUploadUrl, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(initializationUploadInput),
    });
  }

  async getMultipartPreSignedUrls(
    awsMultipartFileDataInput: AWSMultipartFileDataInput
  ): Promise<FetchDataResponse<{ parts: Array<AWSPart> }>> {
    return await fetchData(this.getMultipartPreSignedUrlsUrl, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(awsMultipartFileDataInput),
    });
  }

  async finalizeMultipartUpload(
    finalizationMultiPartInput: FinalizationMultiPartInput
  ): Promise<FetchDataResponse<any>> {
    return await fetchData(this.finalizeMultipartUploadUrl, {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalizationMultiPartInput),
    });
  }
}
